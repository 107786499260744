import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  CommonBG,
  CommonBorder,
  CommonText,
  ControlFilldBG,
  ControlLinedBorder,
  InfoBG,
  InfoBorder,
  InfoText,
  LegendStatusBG,
  TableBG,
} from 'ui/theme/Color';
import { SVGIcon } from 'components/buttons/IconSVG';
import { AvatarStyle } from './menu/components/HeadLinkList/Avatar';
import { ButtonSize, ButtonStyle, ButtonDesign } from 'components/buttons/ButtonStyle';

export const board = {
  table: css`
    width: 100%;
    height: 100%;
    table-layout: fixed;
    overflow: visible;
    & + div {
      border-left: none !important;
      border-right: none !important;
    }
    thead {
      border-top: 1px solid ${CommonBorder.Basic};
      border-bottom: 1px solid ${CommonBorder.Basic};
      height: 36px;
      background: ${TableBG.Header};
      color: ${CommonText.Basic};
      tr {
        th {
          padding: 0px;
          height: 36px;
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
          color: ${CommonText.Basic};
          &:first-child {
            padding-left: 16px;
          }
          &:last-child {
            padding-right: 16px;
          }
          span {
            position: relative;
          }
          button.align {
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -16px;
            width: 14px;
            height: 14px;
            &:before {
              content: '';
              clear: both;
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
              width: 14px;
              height: 14px;
              background-image: url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.26514 4.36056C7.26021 4.35562 7.25518 4.35085 7.25005 4.34625L7.25005 10.6532C7.25518 10.6486 7.26021 10.6439 7.26513 10.6389L8.69819 9.20417C8.79582 9.10642 8.95412 9.10642 9.05175 9.20417C9.14938 9.30192 9.14938 9.46039 9.05175 9.55814L7.61869 10.9929C7.45278 11.159 7.23682 11.2445 7.01942 11.2493C7.01303 11.2498 7.00657 11.25 7.00005 11.25C6.99356 11.25 6.98712 11.2498 6.98075 11.2493C6.76327 11.2445 6.54722 11.1591 6.38125 10.9929L4.94819 9.55814C4.85056 9.46039 4.85056 9.30192 4.94819 9.20417C5.04582 9.10642 5.20412 9.10642 5.30175 9.20417L6.7348 10.6389C6.73978 10.6439 6.74487 10.6487 6.75005 10.6534L6.75005 4.34611C6.74487 4.35076 6.73978 4.35557 6.73481 4.36056L5.30175 5.79531C5.20412 5.89305 5.04582 5.89305 4.94819 5.79531C4.85056 5.69756 4.85056 5.53908 4.94819 5.44134L6.38125 4.00658C6.72296 3.66447 7.27698 3.66447 7.61869 4.00658L9.05175 5.44134C9.14938 5.53908 9.14938 5.69756 9.05175 5.79531C8.95412 5.89305 8.79582 5.89305 8.69819 5.79531L7.26514 4.36056Z' fill='%235B5C5B'/%3E%3C/svg%3E%0A");
              background-repeat: no-repeat;
            }
            &:focus,
            &:active {
              outline: none !important;
            }
          }
        }
      }
    }
    tbody {
      tr {
        &:hover {
          background: ${TableBG.CellSelected};
        }
        td {
          padding: 0px;
          height: 36px;
          font-size: 13px;
          font-weight: 400;
          font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
          color: ${CommonText.Basic};
          border-bottom: 1px solid ${CommonBorder.Light};
          &.subject {
            width: calc(100% - 671px) !important;
            text-align: left;
            padding-left: 8px;
            cursor: pointer;
          }
          &.unread {
            position: relative;
            font-weight: bold;
            &:before {
              content: '';
              clear: both;
              display: inline-block;
              position: absolute;
              top: 10px;
              left: 2px;
              width: 4px;
              height: 4px;
              border-radius: 2px;
              background: ${CommonText.Error};
            }
          }
          &.important {
            color: ${CommonText.Primary};
          }
          &.check {
            width: 45px !important;
            padding-left: 16px;
            text-align: center;
          }
          &.number {
            width: 64px !important;
            text-align: center;
          }
          &.file {
            width: 80px !important;
            text-align: center;
          }
          &.writer {
            width: 150px !important;
            text-align: left;
            padding-left: 8px;
            .user {
              height: 100%;
              display: inline-flex;
              align-items: center;
              .avatar {
                width: 20px;
                height: 20px;
                margin-right: 4px;
                &.image {
                  ${AvatarStyle.photo};
                }
                &.initial {
                  ${AvatarStyle.initial};
                }
                &.initialInvers {
                  ${AvatarStyle.initialInvers};
                }
                &.icon {
                  ${AvatarStyle.icon};
                }
                &.iconInvers {
                  ${AvatarStyle.iconInvers};
                }
              }
            }
          }
          &.date {
            width: 120px !important;
            text-align: center;
          }
          &.view {
            width: 90px !important;
            text-align: right;
            padding-right: 8px;
          }
          &.recom {
            width: 90px !important;
            padding-right: 24px;
            text-align: right;
          }
          span.comment {
            display: inline=block;
            font-weight: normal;
            margin-left: 4px;
            color: ${CommonText.Secondary};
          }
          span.new {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: 0px 0px -4px 4px;
            border-radius: 8px;
            background: ${LegendStatusBG.ErrorNormal};
            position: relative;
            &:before {
              content: 'N';
              font-size: 11px;
              font-weight: 400;
              font-weight: normal;
              color: ${CommonText.Inverse};
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          span.file {
            width: 20px;
            height: 20px;
            &:focus,
            &:active {
              outline: none !important;
            }
            &:before {
              content: '';
              clear: both;
              display: inline-block;
              width: 20px;
              height: 20px;
              background: ${CommonText.Light};
              -webkit-mask: ${SVGIcon.IcoFile};
              mask-image: ${SVGIcon.IcoFile};
            }
          }
        }
      }
    }
  `,
  subject: css`
    width: 100%;
    height: auto;
    table-layout: fixed;
    overflow: visible;
    border: 1px solid ${CommonBorder.Basic};
    border-radius: 2px;
    background: ${CommonBG.Deep};
    margin-bottom: 4px;
    tr {
      td {
        font-size: 13px !important;
        border-bottom: none !important;
        font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
        padding: 6px 0px 6px;
        vertical-align: top;
        &:first-child {
          font-weight: bold;
          width: 100px;
          padding-left: 12px !important;
          padding-top: 12px;
        }
        &:nth-child(2) {
          padding-right: 12px !important;
        }
        span.dot {
          display: inline-block;
          position: relative;
          &:before {
            content: '';
            clear: both;
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            position: absolute;
            top: 3px;
            left: -6px;
            background: ${CommonText.Error};
          }
        }
        &.tag {
          display: flex;
          align-items: flex-start;
          div.help {
            input {
              width: 240px;
            }
          }
          button.check {
            margin-left: 4px;
            ${ButtonSize.md}
            ${ButtonStyle.outlineNormal}
            ${ButtonDesign.IconMD}
            &:before {
              -webkit-mask: ${SVGIcon.IcoCheck};
              mask-image: ${SVGIcon.IcoCheck};
            }
          }
          ul.tag {
            display: inline-flex;
            align-items: center;
            margin-top: 2px;
            height: 28px;
            margin-left: 24px;
            li {
              & + li {
                margin-left: 4px;
              }
              height: 28px;
              border-radius: 14px;
              background: ${ControlFilldBG.Basic};
              color: ${CommonText.Light};
              padding: 5px 30px 5px 14px;
              font-size: 12px;
              position: relative;
              button {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 6px;
                right: 6px;
                &:before {
                  content: '';
                  clear: both;
                  display: inline-block;
                  width: 16px;
                  height: 16px;
                  background: ${CommonText.Lighter};
                  -webkit-mask: ${SVGIcon.IcoCircleClose};
                  mask-image: ${SVGIcon.IcoCircleClose};
                }
              }
            }
          }
        }
      }
    }
    tr {
      &:first-child {
        td {
          padding-top: 12px !important;
          &:nth-child(2) {
            input {
              width: 100%;
            }
          }
        }
      }
      &:last-child {
        td {
          padding-bottom: 12px !important;
        }
      }
    }
  `,
};

export const panel = {
  div: css`
    border-top: 1px solid ${CommonBorder.Strong};
    &[data-panel-group-direction='horizontal'] {
      height: auto !important;
      overflow: visible !important;
      div[data-panel-group-direction='horizontal'] {
        width: 3px;
        background: ${CommonBorder.Strong};
        position: relative;
        &:before {
          content: '';
          clear: both;
          display: inline-block;
          width: 12px;
          height: 64px;
          border-radius: 6px;
          background: ${CommonBG.Basic};
          border: 1px solid ${CommonBorder.Basic};
          position: absolute;
          top: 368px;
          left: 50%;
          transform: translateX(-50%);
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
        }
        &:after {
          content: '';
          clear: both;
          display: inline-block;
          width: 6px;
          height: 4px;
          border-top: 3px solid transparent;
          border-right: 4px solid ${CommonText.Lighter};
          border-bottom: 3px solid transparent;
          border-left: 0px;
          position: absolute;
          top: 397px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 999;
        }
        &:focus,
        &:active {
          outline: none !important;
        }
      }
      div[data-panel] {
        width: calc((100% - 3px) / 2);
      }
    }
    &[data-panel-group-direction='vertical'] {
      div[data-panel-group-direction='vertical'] {
        height: 3px;
        background: ${CommonBorder.Strong};
        position: relative;
        &:before {
          content: '';
          clear: both;
          display: inline-block;
          width: 64px;
          height: 12px;
          border-radius: 6px;
          background: ${CommonBG.Basic};
          border: 1px solid ${CommonBorder.Basic};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
        }
        &:after {
          content: '';
          clear: both;
          display: inline-block;
          width: 6px;
          height: 4px;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent;
          border-bottom: 4px solid ${CommonText.Lighter};
          border-top: 0px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 999;
        }
        &:focus,
        &:active {
          outline: none !important;
        }
      }
      // div[data-panel-id=":r3:"],
      // div[data-panel-id=":r7:"] {
      //   display:flex;
      // }
    }
    div[data-panel-id=':r7:'] {
      padding: 12px 0 0 24px;
    }
    &.none {
      border-top: none;
      div[data-panel-id=':r7:'] {
        display: none;
      }
      div[data-panel-group-direction='horizontal'] {
        display: none;
      }
    }
  `,
};

export const BoardListTitleContent = styled.ul`
  border-top: 1px solid ${CommonBorder.Light};
  & + div {
    border-left: none !important;
    border-right: none !important;
  }
`;

export const ListTitleContet = styled.li`
  padding: 12px 16px;
  border-bottom: 1px solid ${CommonBorder.Light};
  &:hover {
    background: ${TableBG.CellSelected};
  }
`;

export const ListTitle = styled.div`
  height: 23px;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 4px;
  &.important {
    color: ${CommonText.Primary} !important;
  }
  &.unread {
    font-weight: bold;
    position: relative;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      position: absolute;
      top: 2px;
      left: -4px;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: ${CommonText.Error};
    }
  }
  span.new {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    border-radius: 8px;
    background: ${LegendStatusBG.ErrorNormal};
    position: relative;
    border: 1px solid ${CommonBorder.inverse};
    &:before {
      content: 'N';
      font-size: 11px;
      font-weight: 400;
      font-weight: normal;
      color: ${CommonText.Inverse};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const ListContent = styled.div`
  font-size: 12px;
  color: ${CommonText.Light};
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-bottom: 8px;
`;

export const ListWriter = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 12px;
  &.read {
    .division {
      margin-right: 24px;
    }
    .date {
      margin-right: 24px;
      span {
        display: inline-block;
        font-weight: bold;
        margin-right: 4px;
      }
    }
  }
  &.Comment {
    .avatar {
      width: 32px;
      height: 32px;
    }
    .nameGroup {
      display: inline-flex;
      align-items: center;
    }
  }
  .avatar {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    &.image {
      ${AvatarStyle.photo};
    }
    &.initial {
      ${AvatarStyle.initial};
    }
    &.initialInvers {
      ${AvatarStyle.initialInvers};
    }
    &.icon {
      ${AvatarStyle.icon};
    }
    &.iconInvers {
      ${AvatarStyle.iconInvers};
    }
  }
  .name {
    font-weight: bold;
    color: ${CommonText.Basic};
    margin-right: 4px;
  }
  .division,
  .date {
    margin-right: 12px;
    color: ${CommonText.Light};
  }
  .file {
    width: 20px;
    height: 20px;
    &:focus,
    &:active {
      outline: none !important;
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 20px;
      height: 20px;
      background: ${CommonText.Light};
      -webkit-mask: ${SVGIcon.IcoFile};
      mask-image: ${SVGIcon.IcoFile};
    }
  }
`;

export const Listoption = styled.div`
  margin-top: 4px;
  height: 20px;
  display: flex;
  align-items: center;
  span {
    height: 20px;
    vertical-align: top;
    display: inline-block;
    padding: 0px 4px 3px 4px;
    font-size: 12px;
    border: 1px solid ${InfoBorder.ColorB};
    background: ${InfoBG.ColorB};
    color: ${InfoText.ColorB};
    border-radius: 2px;
    & + span {
      margin-left: 4px;
    }
  }
`;

export const ListTitleView = styled.li`
  padding: 12px 16px;
  border-bottom: 1px solid ${CommonBorder.Light};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: ${TableBG.CellSelected};
  }
`;

export const ListLink = styled.div`
  display: block;
`;

export const ContentSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.read {
    justify-content: flex-start;
    div {
      display: inline-block;
      color: ${CommonText.Light};
      font-size: 13px;
      & + span {
        margin-left: 12px;
      }
    }
    button.reply {
      margin-left: 24px;
      font-size: 13px;
      color: ${CommonText.Basic};
      &:hover {
        text-decoration: underline;
        text-decoration-color: ${ControlLinedBorder.BasicHover};
        text-underline-position: under;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
  }
  span {
    position: relative;
    display: inline-block;
    color: ${CommonText.Light};
    font-size: 13px;
    padding-left: 24px;
    & + span {
      margin-left: 12px;
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 0;
      background: ${CommonText.Light};
      background-position: center center;
    }
    &:hover {
      text-decoration: underline;
      text-decoration-color: ${ControlLinedBorder.BasicHover};
      text-underline-position: under;
    }
    &:focus,
    &:active {
      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }
  .comment {
    &:before {
      -webkit-mask: ${SVGIcon.IcoComment};
      mask-image: ${SVGIcon.IcoComment};
    }
  }
  .view {
    &:before {
      -webkit-mask: ${SVGIcon.IcoEye};
      mask-image: ${SVGIcon.IcoEye};
    }
  }
  .heart {
    &:before {
      -webkit-mask: ${SVGIcon.IcoHeart};
      mask-image: ${SVGIcon.IcoHeart};
    }
  }
  .heartFill {
    color: ${CommonText.Basic};
    &:before {
      background: ${CommonText.ColorD};
      -webkit-mask: ${SVGIcon.IcoHeartFill};
      mask-image: ${SVGIcon.IcoHeartFill};
    }
  }
`;

export const ListImage = styled.div`
  width: 120px !important;
  img {
    width: 120px;
    height: 120px;
    border-radius: 4px;
  }
  & + div {
    margin-left: 16px;
    width: calc(100% - 120px);
  }
`;

export const ListInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.gallery {
    margin-top: 4px;
    width: 140px !important;
  }
  .name {
    font-size: 12px;
    color: ${CommonText.Basic};
  }
  .date {
    font-size: 12px;
    color: ${CommonText.Light};
  }
`;

export const GalleryList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  border-top: 1px solid ${CommonBorder.Light};
  & + div {
    border-left: none !important;
    border-right: none !important;
  }
`;

export const GalleryThum = styled.div`
  padding: 8px;
  width: 156px;
  max-width: 156px;
  min-height: 209px;
  max-height: 209px;
`;

export const GalleryTitle = styled.div`
  margin-top: 8px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.unread {
    font-weight: bold;
    position: relative;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      position: absolute;
      top: 2px;
      left: -4px;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: ${CommonText.Error};
    }
  }
  div {
    width: 100%;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.important {
      color: ${CommonText.Primary} !important;
    }
    &.new {
      width: calc(100% - 20px);
    }
  }
  span.new {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    border-radius: 8px;
    background: ${LegendStatusBG.ErrorNormal};
    position: relative;
    border: 1px solid ${CommonBorder.inverse};
    &:before {
      content: 'N';
      font-size: 11px;
      font-weight: 400;
      font-weight: normal;
      color: ${CommonText.Inverse};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const BoardReadBox = styled.div`
  width: 100%;
  border-top: 1px solid ${CommonBorder.Basic};
  border-bottom: 1px solid ${CommonBorder.Basic};
  padding: 4px 0px 12px;
  margin-bottom: 4px;
`;

export const BoardReadTitleBox = styled.div`
  padding: 12px 0px 24px;
  border-bottom: 1px solid ${CommonBorder.Basic};
  .title {
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    color: ${CommonText.Basic};
  }
`;

export const FileBox = styled.div`
  width: 100%;
  margin-top: 5px;
  border: 1px solid ${CommonBorder.Basic};
  padding: 12px;
  background: ${CommonBG.Deep};
`;

export const FileBoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: ${CommonText.Basic};
    display: inline-flex;
    align-items: center;
    span {
      display: inline-block;
      margin-left: 8px;
      font-size: 13px;
      font-weight: normal;
      .num {
        margin-left: 0px;
        color: ${CommonText.Primary};
      }
    }
  }
  button.down {
    ${ButtonSize.sm}
    ${ButtonStyle.outlineNormal}
    ${ButtonDesign.IconLfSM}
    &:before {
      -webkit-mask: ${SVGIcon.IcoDown};
      mask-image: ${SVGIcon.IcoDown};
    }
  }
`;

export const FileBoxList = styled.ul`
  margin-top: 4px;
  li {
    height: 32px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: ${CommonText.Secondary};
    padding-left: 24px;
    position: relative;
    & + li {
      margin-top: 4px;
    }
    a:link,
    a:visited,
    a:active {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
    span {
      display: inline-block;
      margin-left: 4px;
      font-size: 12px;
      color: ${CommonText.Lighter};
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 7px;
      left: 0;
      background: ${CommonText.Light};
    }
    &.excel {
      &:before {
        -webkit-mask: ${SVGIcon.IcoExcel};
        mask-image: ${SVGIcon.IcoExcel};
      }
    }
    &.ppt {
      &:before {
        -webkit-mask: ${SVGIcon.icoPpt};
        mask-image: ${SVGIcon.icoPpt};
      }
    }
    &.pdf {
      &:before {
        -webkit-mask: ${SVGIcon.IcoPdf};
        mask-image: ${SVGIcon.IcoPdf};
      }
    }
    &.file {
      &:before {
        -webkit-mask: ${SVGIcon.IcoEtc};
        mask-image: ${SVGIcon.IcoEtc};
      }
    }
    &.word {
      &:before {
        -webkit-mask: ${SVGIcon.IcoWord};
        mask-image: ${SVGIcon.IcoWord};
      }
    }
    &.zip {
      &:before {
        -webkit-mask: ${SVGIcon.IcoZip};
        mask-image: ${SVGIcon.IcoZip};
      }
    }
    &.img {
      &:before {
        -webkit-mask: ${SVGIcon.IcoImg};
        mask-image: ${SVGIcon.IcoImg};
      }
    }
  }
`;

export const ImgBox = styled.div`
  padding: 24px 0 12px;
`;

export const ContentBox = styled.div`
  margin: 12px 0px 48px;
  color: ${CommonText.Basic};
  .title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .text {
    font-size: 13px;
    line-height: 1.5;
  }
`;

export const CommentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 12px;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: ${CommonText.Basic};
    span {
      display: inline-block;
      margin-left: 8px;
      font-size: 14px;
      color: ${CommonText.Primary};
    }
  }
`;

export const CommentBox = styled.div`
  background: ${CommonBG.Deep};
  height: 164px;
  padding: 12px;
  .CommentWriter {
    display: flex;
    align-items: center;
    height: 20px;
    margin-bottom: 4px;
    .MuiFormGroup-root {
      margin-left: 24px;
      height: 28px;
      display: inline-block;
      flex-direction: initial;
      flex-wrap: initial;
      .MuiFormControlLabel-root {
        height: 28px;
        margin: 0px !important;
        width: 130px;
        display: inline-block;
        position: relative;
        .MuiSwitch-root {
          width: 32px;
          height: 20px;
          position: absolute;
          left: 86px;
          top: 4px;
          padding: 0px !important;
          .MuiSwitch-switchBase {
            padding: 0px !important;
            &.Mui-checked {
              transform: translateX(14px);
              .MuiSwitch-thumb {
                border: 2px solid ${CommonBG.Secondary};
              }
              & + .MuiSwitch-track {
                background: ${CommonBG.Secondary};
                &:before {
                  content: '';
                  clear: both;
                  display: inline-block;
                  width: 9px;
                  height: 6px;
                  position: absolute;
                  top: 6px;
                  left: 4px;
                  background-image: url("data:image/svg+xml,%3Csvg width='9' height='6' viewBox='0 0 9 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.44896 0.206475C8.73939 0.486031 8.73934 0.942722 8.44884 1.22221L3.69742 5.79364C3.55918 5.92664 3.3733 6.00004 3.18103 6C2.98876 5.99996 2.80291 5.92649 2.66473 5.79343L0.884435 4.07914C0.594058 3.79953 0.594208 3.34284 0.884753 3.06341C1.17083 2.78827 1.63143 2.7884 1.91735 3.06371L3.1814 4.2809L7.41637 0.206361C7.70239 -0.0688305 8.163 -0.0687815 8.44896 0.206475Z' fill='white'/%3E%3C/svg%3E%0A");
                  background-size: 9px 6px;
                  z-index: 5;
                }
              }
            }
            .MuiSwitch-thumb {
              width: 18px;
              height: 18px;
              background: ${CommonBG.Basic};
              border: 2px solid ${CommonBorder.Stronger};
              box-shadow: none !important;
            }
          }
          .MuiSwitch-track {
            opacity: 1 !important;
            width: 32px;
            height: 12px;
            border-radius: 6px;
            background: ${CommonBG.Stonger};
            margin-top: 3px;
          }
        }
        .MuiTypography-root {
          position: absolute;
          left: 0;
          top: 4px;
          font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
          font-size: 13px;
          font-weight: 400;
          color: ${CommonText.Basic};
        }
      }
    }
  }
`;

export const CommentTextarea = styled.div`
  width: 100%;
  border-radius: 2px;
  border: 1px solid ${CommonBorder.Strong};
  padding: 8px;
  background: ${CommonBG.Basic};
  textarea {
    border: none;
    outline: none;
    width: 100%;
    min-height: 60px;
    resize: none;
    font-size: 13px;
    line-height: 1.5;
    color: ${CommonText.Basic};
    &:placeholder {
      font-size: 13px;
      line-height: 1.5;
      color: ${CommonText.Lighter};
    }
    &:focus,
    &:active {
      border: none;
      outline: none;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(60, 62, 61, 0.4);
      width: 6px;
      border-radius: 3px;
    }
  }
  .texareaMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    span {
      font-size: 12px;
      font-weight: 400;
      color: ${CommonText.Light};
    }
    .textareBtn {
      display: inline-flex;
      align-items: center;
      height: 32px !important;
      button + button {
        margin-left: 8px;
      }
      .file {
        width: 32px;
        height: 32px;
        position: relative;
        &:focus,
        &:active {
          outline: none !important;
        }
        &:before {
          content: '';
          clear: both;
          display: inline-block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 6px;
          left: 6px;
          background: ${CommonText.Light};
          -webkit-mask: ${SVGIcon.IcoFile};
          mask-image: ${SVGIcon.IcoFile};
        }
      }
      .save {
        ${ButtonSize.md}
        ${ButtonStyle.containedPrimary}
        ${ButtonDesign.IconLfMD}
        &:before {
          -webkit-mask: ${SVGIcon.IcoCheck};
          mask-image: ${SVGIcon.IcoCheck};
        }
      }
    }
  }
`;

export const Commentlist = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    padding: 12px 0px;
    border-bottom: 1px solid ${CommonBorder.Light};
    .commentTItleBox {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .commentTItleBtn {
        display: inline-flex;
        align-items: center;
        button {
          &:focus,
          &:active {
            outline: none !important;
          }
          & + button {
            margin-left: 4px;
          }
        }
        .modify {
          ${ButtonSize.sm}
          ${ButtonStyle.unfiledNormal}
          ${ButtonDesign.IconSM}
          &:before {
            -webkit-mask: ${SVGIcon.IcoWrite};
            mask-image: ${SVGIcon.IcoWrite};
          }
        }
        .delete {
          ${ButtonSize.sm}
          ${ButtonStyle.unfiledNormal}
          ${ButtonDesign.IconSM}
          &:before {
            -webkit-mask: ${SVGIcon.IcoDelete};
            mask-image: ${SVGIcon.IcoDelete};
          }
        }
      }
    }
    .commentContent {
      width: 100%;
      padding: 12px 0 24px;
      font-size: 13px;
      line-height: 1.5;
      color: ${CommonText.Basic};
    }
    .commentMenu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .agreeBtnBox {
        display: inline-flex;
        align-items: center;
        font-size: 13px;
        color: ${CommonText.Basic};
        div + div {
          margin-left: 24px;
        }
      }
    }
  }
`;

export const CommentImgBox = styled.ul`
  margin-top: 12px;
  display: flex;
  align-items: center;
  li {
    position: relative;
    width: 144px;
    height: 96px;
    & + li {
      margin-left: 4px;
    }
    button {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 16px;
      height: 16px;
      z-index: 999;
      border-radius: 8px;
      background: ${CommonBG.Basic};
      &:before {
        content: '';
        clear: both;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 7px;
        display: inline-block;
        background: ${SVGIcon.IcoCircleClose};
        background-position: center center;
        background-size: 14px 14px;
        background-repeat: no-repeat;
      }
      &:focus,
      &:active {
        outline: none !important;
      }
    }
    img {
      border-radius: 4px;
    }
    &.file {
      border: 1px solid ${CommonBorder.Basic};
      border-radius: 4px;
      background: ${CommonBG.Deep};
      &:before {
        content: '';
        clear: both;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 32px;
        height: 32px;
        transform: translate(-50%, -50%);
        background: ${CommonText.Lighter};
        mask-position: center center;
        mask-repeat: no-repeat;
      }
    }
    &.pdf {
      &:before {
        -webkit-mask: ${SVGIcon.IcoPdf};
        mask-image: ${SVGIcon.IcoPdf};
      }
    }
    &.etc {
      &:before {
        -webkit-mask: ${SVGIcon.IcoEtc};
        mask-image: ${SVGIcon.IcoEtc};
      }
    }
    &.excel {
      &:before {
        -webkit-mask: ${SVGIcon.IcoExcel};
        mask-image: ${SVGIcon.IcoExcel};
      }
    }
    &.ppt {
      &:before {
        -webkit-mask: ${SVGIcon.icoPpt};
        mask-image: ${SVGIcon.icoPpt};
      }
    }
    &.word {
      &:before {
        -webkit-mask: ${SVGIcon.IcoWord};
        mask-image: ${SVGIcon.IcoWord};
      }
    }
    &.zip {
      &:before {
        -webkit-mask: ${SVGIcon.IcoZip};
        mask-image: ${SVGIcon.IcoZip};
      }
    }
  }
`;
