import React, { useState } from 'react';
import { MailSendRequest } from 'models/admin/Mail';
import { sendMail, getMailTemplate } from 'apis/admin/Mail';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

const MailSample = () => {
  const { t } = useTranslation();
  const [emailSubject, setEmailSubject] = useState<string>('');
  const [emailTo, setEmailTo] = useState<string>('');
  const [emailCc, setEmailCc] = useState<string>('');
  const [emailBcc, setEmailBcc] = useState<string>('');
  const [emailContent, setEmailContent] = useState<string>('');

  const handleSave = async () => {
    const emailRequest: MailSendRequest = {
      subject: emailSubject,
      toAddress: emailTo,
      ccAddress: emailCc,
      bccAddress: emailBcc,
      content: emailContent,
    };
    await sendMail(emailRequest);
  };

  const getEmailTemplate = async () => {
    await getMailTemplate('test-template').then((resp) => {
      setEmailContent(resp);
    });
  };

  return (
    <>
      <div>
        <section>
          <label htmlFor="emailTo"> {t('com.label.수신자', '수신자')} : </label>
          <input id="emailTo" value={emailTo} onChange={(e) => setEmailTo(e.target.value)}></input>
        </section>
        <section>
          <label htmlFor="emailCc"> {t('com.label.참조자', '참조자')} : </label>
          <input id="emailCc" value={emailCc} onChange={(e) => setEmailCc(e.target.value)}></input>
        </section>
        <section>
          <label htmlFor="emailBcc"> {t('com.label.숨김 참조자', '숨김 참조자')} : </label>
          <input
            id="emailBcc"
            value={emailBcc}
            onChange={(e) => setEmailBcc(e.target.value)}
          ></input>
        </section>
        <section>
          <label htmlFor="emailSubject"> {t('com.label.제목', '제목')} : </label>
          <input
            id="emailSubject"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
          ></input>
        </section>
      </div>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(emailContent) }}></div>
      <div className="buttons">
        <button onClick={getEmailTemplate}>
          {t('com.button.템플릿 불러오기', '템플릿 불러오기')}
        </button>
        <button onClick={handleSave}> {t('com.button.전송', '전송')}</button>
      </div>
    </>
  );
};

export default MailSample;
