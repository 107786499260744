/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { findElectricSummary } from 'apis/ut/UtMatrixRegist';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { hasRole } from 'utils/SessionUtil';

const ElectricSummaryContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const isUtWriter = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return (p.length > 0 && p[0].utmWriteYn === 'Y') || hasRole('ADM');
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);
  const isWrite = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP02';
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);
  const isWriteComplete = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP03';
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);

  useImperativeHandle(ref, () => ({
    searchElectricSummary: (condition) => {
      searchElectricSummary(condition);
    },
  }));

  const searchElectricSummary = (condition) => {
    findElectricSummary(condition).then((result) => {
      setRowData(result);
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      minWidth: 120,
      width: '*',
    },
    {
      binding: 'eqpPrdnLnQty',
      header: String(t('ut.label.라인수량', '라인수량')),
      minWidth: 120,
      width: '*',
    },
    {
      binding: 'vltgNvl',
      header: String(t('ut.label.전압', '전압')),
      align: 'center',
      minWidth: 120,
      width: '*',
    },
    {
      binding: 'elpwEqpQty',
      header: String(t('ut.label.설비수량', '설비수량')),
      align: 'right',
      minWidth: 120,
      width: '*',
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'od2EcNvl',
      header: String(t('ut.label.수용율 적용전', '수용율 적용전')),
      align: 'right',
      minWidth: 120,
      width: '*',
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'elecDmdRate',
      header: String(t('ut.label.수용율 적용 후', '수용율 적용 후')),
      align: 'right',
      minWidth: 120,
      width: '*',
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      header: String(t('ut.label.차이', '차이')),
      align: 'right',
      minWidth: 120,
      width: '*',
      cellTemplate: (params) =>
        `${((params.item.od2EcNvl || 0) - (params.item.elecDmdRate || 0)).toLocaleString()}`,
    },
  ];

  const handleSubmit = () => {
    props?.onSubmit?.();
  };

  const handleModify = () => {
    props?.onModify?.();
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.전기요약', '전기요약')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && condition?.prdnProcCdNm && condition?.dtalProcCdNm && (
            <div className="info warning">
              {condition?.utmNo} / {condition?.prdnProcCdNm} / {condition?.dtalProcCdNm}
            </div>
          )}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchElectricSummary(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        isReadOnly={true}
        isSelector={false}
        allowPinning={false}
      />
      <GlobalBtnGroup>
        {isWrite && isUtWriter && (
          <Button css={IconButton.button} className="save" onClick={handleSubmit}>
            {t('com.button.제출', '제출')}
          </Button>
        )}
        {isWriteComplete && isUtWriter && (
          <Button css={IconButton.button} className="save" onClick={handleModify}>
            {t('com.button.수정', '수정')}
          </Button>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default React.forwardRef(ElectricSummaryContent);
