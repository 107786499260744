import { useState, useEffect } from 'react';
import { CustomSelect } from 'components/selects/CustomSelect';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';

// 프로퍼티 타입 지정
CommonSelectBox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  onCallback: PropTypes.any,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
  keyword: PropTypes.any,
};

CommonSelectBox.defaultProps = {
  className: 'fullWidth',
};

export default function CommonSelectBox(props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<any>('');
  const [commonCode, setCommonCode] = useState<Code[]>([]);

  const handleOnChange = (e: any) => {
    setValue(e.target.value);
    const result = {
      id: props.id,
      value: e.target.value,
    };
    props.onCallback(result);
  };

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    (async function () {
      const result = await getCommonCodeNames(props.keyword);
      if (result != null) setCommonCode(result);
    })();
  }, [commonCode]);

  return (
    <CustomSelect className={props.className} value={value} onChange={handleOnChange}>
      <option value="">{t('com.label.전체', '전체')}</option>
      {commonCode.map((key) => (
        <option key={key.cmnCd} value={key.cmnCd}>
          {key.cmnCdNm}
        </option>
      ))}
    </CustomSelect>
  );
}
