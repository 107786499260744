/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { CustomInputWrap, CustomInputText } from 'components/inputs/CustomInput';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SearchCols } from 'components/layouts/SearchBox';
import { Button } from '@mui/material';
import { prototype } from 'events';

// 프로퍼티 타입 지정
SearchInputText.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  colSpan: PropTypes.number,
  isRefreah: PropTypes.bool,

  onChange: PropTypes.any,
  onClick: PropTypes.any,
  onKeyDown: PropTypes.any,
  findOnClick: PropTypes.any,
};

export default function SearchInputText(props) {
  const { t } = useTranslation();
  const [current, setCurrent] = useState<any>(props.defaultValue);
  const labelId = 'com.label.' + props.name;
  const labelText = '' + props.name;

  useEffect(() => {
    delBtnOnClick();
  }, [props.isRefreah]);

  useEffect(() => {
    setCurrent(props.defaultValue);
  }, [props.defaultValue]);

  const handleOnChange = (e: any) => {
    setCurrent(e.target.value);
    const result = {
      id: props.id,
      value: e.target.value,
    };
    props.onChange(result);
  };

  const delBtnOnClick = () => {
    setCurrent('');
    const result = {
      id: props.id,
      value: '',
    };
    props.onChange(result);
  };

  return (
    <>
      <label>{t(labelId, labelText)}</label>
      <CustomInputWrap>
        <CustomInputText
          className={props.className}
          value={current}
          defaultValue={props.defaultValue}
          onChange={handleOnChange}
          onKeyDown={props.onKeyDown}
          readOnly={props.className == 'find' ? true : false}
          placeholder={props.placeholder}
        ></CustomInputText>
        {/*{current && <Button className="buttonDel" disableRipple onClick={delBtnOnClick}></Button>}*/}
        {props.className == 'find' && (
          <Button className="buttonFind" disableRipple onClick={props.findOnClick}></Button>
        )}
      </CustomInputWrap>
    </>
  );
}
