import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  jsonData,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { GatingJudgementResultSummary } from 'models/gtng/GatingJudgementResult';
import { GatingResultReportAct } from 'models/gtng/GatingResultReportAct';
import { GatingJudgementResultDtl } from 'models/gtng/GatingJudgementResultDtl';
import { GatingJudgementResultDtlEquipment } from 'models/gtng/GatingJudgementResultDtlEquipment';

export const getProgressStatusCode = async (gatingId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getProgressStatusCode`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

// (임시)결재 상태 확인
export const getAprReqProgStatCd = async (gatingId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getAprReqProgStatCd`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

export const findGatingjudgementReportSummary = async (gatingId: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingjudgementReportSummary`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse<GatingJudgementResultSummary[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingJudgementResultSummary[];
};

// 차트
export const retrieveGtngEquipRsltChart = async (gatingId: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/retrieveGtngEquipRsltChart`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse<GatingResultReportAct[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingResultReportAct[];
};

// 차트
export const retrieveGtngEquipRsltChartSub = async (
  gatingId: string,
  gatingType2Value?: string
) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/retrieveGtngEquipRsltChartSub`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      gatingId: gatingId,
      gatingType2Value: gatingType2Value || '',
      lastFlag: '',
      tempView: '',
    }),
  };
  const response: CommonResponse<GatingResultReportAct[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingResultReportAct[];
};

export const saveGatingJudgementAct = async (saveData: GatingResultReportAct[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveGatingJudgementAct`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};

export const confirmGatingJudgementAct = async (saveData: GatingJudgementResultSummary) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/confirmGatingJudgementAct`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};

//김지환 작성 : 임시 결재요청 반려 시 APR_REQ_PROG_STAT_CD 상태 REJ로 UPDATE
export const updateTmpApprovalLineAfterReject = async (saveData: GatingJudgementResultSummary) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/updateTmpApprovalLineAfterReject`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

export const findGatingJudgementReportDtl = async (gatingId: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingJudgementReportDtl`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse<GatingJudgementResultDtl[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingJudgementResultDtl[];
};

export const findGatingJudgementReportDtlEquipment = async (gatingId: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingJudgementReportDtlEquipment`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse<jsonData> = await callApi(request);
  return response;
};

export const findGatingjudgementReportReqSummary = async (gatingId: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingjudgementReportReqSummary`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

export const retrieveGatingAprReqId = async (gatingId: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/retrieveGatingAprReqId`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

// export const findJudgementReqReportDtl = async (gatingId: any) => {
//   const request: CommonRequest = {
//     method: Method.GET,
//     url: `/v1/findJudgementReqReportDtl`,
//     serviceName: ServiceName.ELM_BE,
//     queryParams: new URLSearchParams({ gatingId: gatingId }),
//   };
//   const response: CommonResponse<GatingResultReportAct[]> = await callApi(request);
//   return (response.successOrNot === 'Y' ? response.data : null) as GatingResultReportAct[];
// };

// export const findJudgementReqReportActDtl = async (gatingId: any) => {
//   const request: CommonRequest = {
//     method: Method.GET,
//     url: `/v1/findJudgementReqReportActDtl`,
//     serviceName: ServiceName.ELM_BE,
//     queryParams: new URLSearchParams({ gatingId: gatingId }),
//   };
//   const response: CommonResponse<GatingResultReportAct[]> = await callApi(request);
//   return (response.successOrNot === 'Y' ? response.data : null) as GatingResultReportAct[];
// };
