import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { MpLine, MpLineCondition } from 'models/mp/MpLine';

/**
 * 라인 목록 조회
 * @param condition
 */
export const findMpLines = async (condition?: MpLineCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/line`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<MpLine[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : []) as MpLine[];
};

/**
 * 라인 설정 저장
 * @param itemId
 */
export const saveMpLines = async (mpLines: MpLine[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/line`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: mpLines,
  };

  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};
