/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { IconButton } from '../../components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from '../../components/layouts/SearchBox';
import { ComboBox } from '../../components/selects/ComboBox';
import { Code } from '../../models/common/CommonCode';
import { ExcpManagementPopUp } from 'pages/partner/excpmanagement/ExcpManagementPopUp';
import { getCommonCodes } from '../../apis/admin/CommonCode';
import { useTranslation } from 'react-i18next';
import {
  SubTitleLayout,
  SubTitleGroup,
  GlobalBtnGroup,
  ControlBtnGroup,
} from '../../components/layouts/ContentLayout';
import { ContentGrid } from '../../components/layouts/ContentGrid';
import { CustomInputText, CustomInputWrap } from '../../components/inputs/CustomInput';
import { ExcpPartnerUser, ExcpPartnerUserSearchCondition } from '../../models/admin/ExcpPartner';
import CustomSwitch from 'components/selects/CustomSwitch';
import {
  getExcpUserList,
  updateConfirmApproval,
  updateRejectApproval,
} from 'apis/admin/ExcpPartner';
import { hasRole } from 'utils/SessionUtil';
// Wijmo library
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CellType, CellRange, MergeManager } from '@grapecity/wijmo.grid';
import { getExcpList, compUserCount } from 'apis/admin/ExcpPartner';
import { useMessageBar } from 'hooks/useMessageBar';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { ManagementMode } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import CustomGrid from 'components/grids/CustomGrid';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import Datepicker, { DateRangeType } from 'react-tailwindcss-datepicker';
import ExcpUserRejectPopUp from './excpmanagement/ExcpUserRejectPopUp';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as input from '@grapecity/wijmo.input';
import { ExcpManagementReadPopUp } from './excpmanagement/ExcpManagementReadPopUp';
import { useSearchParams } from 'react-router-dom';

const ExcpUserManagementPage = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [rowData, setRowData] = useState<ExcpPartnerUser[]>([]);
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [hasAuth, setAuth] = useState(false);
  const [searchParams] = useSearchParams();
  const [isOpenExcpUserRejectPopUp, setOpenExcpUserRejectPopUp] = useState<boolean>(false);
  const { openCommonModal } = useCommonModal();
  const [isOpenExcpManagementPopUp, setOpenExcpManagementPopUp] = useState<boolean>(false);
  const [excpId, setExcpId] = useState<string>('');
  // const [handleRejectApprove, setHandleRejectApprove] = useState<string>('');
  console.log('searchParams', searchParams.get('userId'));
  const [partnerCondition, setPartnerCondition] = useState<ExcpPartnerUserSearchCondition>({
    userId: searchParams.get('userId') || '',
    userNm: '',
    startDate: '',
    endDate: '',
    userAprvYn: '',
  });

  const userAprvYnCondition: Code[] = [
    { cmnCd: 'N', cmnCdNm: '요청' },
    { cmnCd: 'Y', cmnCdNm: '승인완료' },
  ];

  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    // 관리자 권한 여부
    setAuth(hasRole('ADM'));
  }, []);

  useEffect(() => {
    if (searchParams.get('userId')) {
      handleSearch();
    }
  }, [searchParams]);

  const [colWidthMap, setColWidthMap] = useState<any>({
    userNm: 120,
    userId: 250,
    userTel: 150,
    excpNm: 200,
    dataInsDtm: 120,
    mgrUserNm: 250,
    idvlIfoCncYn: 100,
    secrPldgYn: 100,
    userAprvDtm: 120,
    userAprvYn: 120,
    rmk: 250,
  });

  const OnInitialized = (grid) => {
    setFlexRef(grid);
    new Selector(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      if (grid.rows[ht.row].isSelected == true) {
        grid.rows[ht.row].isSelected = false;
      } else {
        grid.rows[ht.row].isSelected = true;
      }

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      setflexItem(item);

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        setflexItem(item);
        setHitTest(ht);
        switch (e.target.id) {
          case 'btnExcpInfo':
            setOpenExcpManagementPopUp(true);
            setExcpId(item.excpId);
            break;
        }
      }
    });

    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });
  };

  const handleSearchParam = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPartnerCondition({
      ...partnerCondition,
      [name]: value,
    });
  };

  const handleApprove = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected < 1) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.사용자를 선택해 주세요.', '사용자를 선택해 주세요.'),
      });
      return false;
    }
    for (let i = 0; i < isSelected.length; i++) {
      if (isSelected[i].dataItem.userAprvYn === 'Y') {
        openMessageBar({
          type: 'error',
          content: t(
            'admin.label.가입처리가 완료된 사용자입니다.',
            '가입처리가 완료된 사용자입니다'
          ),
        });
        return false;
      }
    }
    openCommonModal({
      modalType: 'confirm',
      content: t('admin.msg.가입 처리 하시겠습니까?', '가입 처리 하시겠습니까?'),
      yesCallback: () => {
        const approvalData: ExcpPartnerUser[] = [];
        for (let i = 0; i < isSelected.length; i++) {
          approvalData.push(isSelected[i].dataItem);
        }
        console.log('approvalData', approvalData);
        updateConfirmApproval(approvalData).then((result) => {
          if (result !== null) {
            openMessageBar({
              type: 'confirm',
              content: t('admin.label.승인되었습니다.', '승인되었습니다.'),
            });
            handleSearch();
          } else {
            openMessageBar({
              type: 'error',
              content: t('admin.label.가입처리에 실패하였습니다.', '가입처리에 실패하였습니다.'),
            });
          }
        });
      },
    });
  };

  const handleReject = (msg) => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected === true);

    if (isSelected < 1) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.사용자를 선택해 주세요.', '사용자를 선택해 주세요.'),
      });
      return false;
    }
    for (let i = 0; i < isSelected.length; i++) {
      if (isSelected[i].dataItem.userAprvYn === 'Y') {
        openMessageBar({
          type: 'error',
          content: t(
            'admin.label.가입처리가 완료된 사용자입니다.',
            '가입처리가 완료된 사용자입니다'
          ),
        });
        return false;
      }
    }
    setOpenExcpUserRejectPopUp(true);
    if (msg !== 'noneMsg') {
      const rejectData: ExcpPartnerUser[] = [];
      for (let i = 0; i < isSelected.length; i++) {
        isSelected[i].dataItem.rmk = msg;
        rejectData.push(isSelected[i].dataItem);
      }
      console.log('rejectData', rejectData);
      updateRejectApproval(rejectData).then((result) => {
        if (result !== null) {
          openMessageBar({
            type: 'confirm',
            content: t('admin.label.반려되었습니다.', '반려되었습니다.'),
          });
          handleSearch();
        } else {
          openMessageBar({
            type: 'error',
            content: t('admin.label.반려처리에 실패하였습니다.', '반려처리에 실패하였습니다..'),
          });
        }
      });
    }
  };

  // useEffect(() => {
  //   console.log('거절', handleRejectApprove);
  //   const selectedRowNodes = flexRef.selectedRows;
  //   const isSelected = selectedRowNodes.filter((item) => item.isSelected);
  //   console.log('선택자들', isSelected);
  // }, [handleRejectApprove]);

  const handleResetCondition = () => {
    setPartnerCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as ExcpPartnerUserSearchCondition
    );
    // setPartnerCondition({
    //   userId: '',
    //   userNm: '',
    //   startDate: '',
    //   endDate: '',
    //   userAprvYn: 'N',
    // });
  };

  const exportFormat = (args) => {
    const p = args.panel;
    const row = args.row;
    const col = args.col;
    const xlsxCell = args.xlsxCell;
    if (p.columns[col].binding === 'gatingContIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingContTlIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingEqpContData') {
      const cell = args.getFormattedCell();
      xlsxCell.value = '';
    }
  };

  //다운로드 버튼
  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
      formatItem: exportFormat,
    });
    book.sheets[0].name = 'user';
    book.saveAsync(getExcelFileName(t('admin.label.협력업체사용자', '협력업체사용자')));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSearch();
  };

  const handleSearch = async () => {
    console.log('condition', partnerCondition);
    await getExcpUserList(partnerCondition).then((result) => {
      console.log(result);
      if (result === null) {
        openMessageBar({
          type: 'error',
          content: t(
            'admin.label.조회 가능한 데이터가 없습니다.',
            '조회 가능한 데이터가 없습니다.'
          ),
        });
      }
      setRowData(result);
    });
  };

  const userApvrYnDataMap = new DataMap(userAprvYnCondition, 'cmnCd', 'cmnCdNm');

  const LayoutDefinition = () => {
    return [
      {
        binding: 'excpUserSeq',
        visible: false,
      },
      {
        header: String(t('admin.label.사용자명', '사용자명')),
        binding: 'userNm',
        align: 'center',
        width: colWidthMap.userNm,
      },
      {
        header: String(t('admin.label.ID', '아이디')),
        binding: 'userId',
        width: colWidthMap.userId,
        align: 'left',
      },
      {
        header: String(t('admin.label.연락처', '연락처')),
        binding: 'userTel',
        width: colWidthMap.userTel,
      },
      {
        header: String(t('admin.label.업체명', '업체명')),
        binding: 'excpNm',
        align: 'left',
        width: colWidthMap.excpNm,
        cssClass: 'WijmoPopup',
        cellTemplate: (params) => {
          return `${params.value} <Button id="btnExcpInfo" />`;
        },
      },
      {
        header: String(t('admin.label.협력업체아이디', '협력업체아이디')),
        binding: 'excpId',
        visible: false,
      },
      {
        header: String(t('admin.grid.요청일', '요청일')),
        binding: 'dataInsDtm',
        align: 'center',
        width: colWidthMap.dataInsDtm,
        // cssClass: 'WijmoDate',
        // format: 'yyyy.MM.dd',
        // editor: new input.InputDate(document.createElement('div'), {
        //   format: 'yyyy.MM.dd',
        // }),
      },
      {
        header: String(t('admin.grid.업무담당자', '업무담당자')),
        binding: 'mgrUserNm',
        align: 'left',
        width: colWidthMap.mgrUserNm,
      },
      {
        header: String(t('admin.grid.개인정보동의', '개인정보동의')),
        binding: 'idvlIfoCncYn',
        width: colWidthMap.idvlIfoCncYn,
      },
      {
        header: String(t('admin.grid.보안정보서약서', '보안정보서약서')),
        binding: 'secrPldgYn',
        width: colWidthMap.secrPldgYn,
      },
      {
        header: String(t('admin.grid.승인일', '승인일')),
        binding: 'userAprvDtm',
        width: colWidthMap.userAprvDtm,
        // cssClass: 'WijmoDate',
        // format: 'yyyy.MM.dd',
        // editor: new input.InputDate(document.createElement('div'), {
        //   format: 'yyyy.MM.dd',
        // }),
      },
      {
        header: String(t('admin.grid.승인여부', '승인여부')),
        binding: 'userAprvYn',
        dataMap: userApvrYnDataMap,
        width: colWidthMap.userAprvYn,
      },
      {
        header: String(t('admin.grid.비고', '비고')),
        binding: 'rmk',
        width: colWidthMap.rmk,
        align: 'left',
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{t('com.label.사용자명', '사용자명')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    name="userNm"
                    value={partnerCondition.userNm}
                    onChange={handleSearchParam}
                    onKeyDown={handleKeyDown}
                    placeholder={String(
                      t('com.label.사용자명을 입력해 주세요.', '사용자명을 입력해 주세요.')
                    )}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.요청일', '요청일')}</label>
                {/* <CustomDatepicker
                  isRange={true} // 기간선택인 경우 생략가능
                  value={{
                    startDate: partnerCondition?.startDate || '',
                    endDate: partnerCondition?.endDate || '',
                  }}
                  onChange={(newValue) => {
                    setPartnerCondition((prev) => ({
                      ...prev,
                      startDate:
                        ((newValue as DateRangeType)?.startDate as string) || prev?.startDate,
                      endDate: ((newValue as DateRangeType)?.endDate as string) || prev?.endDate,
                    }));
                  }}
                /> */}
                <DatePickerWrap className="range">
                  <Datepicker
                    inputClassName="datepickerInput"
                    value={{
                      startDate: partnerCondition?.startDate || null,
                      endDate: partnerCondition?.endDate || null,
                    }}
                    onChange={(value) => {
                      setPartnerCondition({
                        ...partnerCondition,
                        startDate: String(value?.startDate || ''),
                        endDate: String(value?.endDate || ''),
                      });
                    }}
                    i18n={'ko'}
                    useRange={true}
                    placeholder="YYYY.MM.DD - YYYY.MM.DD"
                    displayFormat={'YYYY.MM.DD'}
                  />
                </DatePickerWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.아이디', '아이디')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    name="userId"
                    value={partnerCondition.userId}
                    onChange={handleSearchParam}
                    onKeyDown={handleKeyDown}
                    placeholder={String(
                      t('com.label.아이디를 입력해 주세요.', '아이디를 입력해 주세요.')
                    )}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                {/* <label>{t('com.label.협력업체명', '협력업체명')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    name="excpCoNm"
                    value={partnerCondition.excpCoNm}
                    onChange={handleSearchParam}
                    onKeyDown={handleKeyDown}
                    placeholder={String(
                      t('com.label.협력업체명을 입력해 주세요.', '협력업체명을 입력해 주세요.')
                    )}
                  ></CustomInputText>
                </CustomInputWrap> */}
                <label>{t('com.label.승인여부', '승인여부')}</label>
                <ComboBox
                  options={userAprvYnCondition}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={partnerCondition.userAprvYn}
                  onChange={(value) => {
                    setPartnerCondition({
                      ...partnerCondition,
                      userAprvYn: value,
                    });
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.협력업체사용자', '협력업체사용자')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={state.layoutDefinition}
        rowData={state.itemsSource}
        rowSelection="multiple"
        isReadOnly={true}
        align="center"
        initialized={OnInitialized}
        height={550}
      />
      <GlobalBtnGroup>
        <Button
          css={IconButton.button}
          className="confirm"
          onClick={() => handleReject('noneMsg')}
          disableRipple
        >
          {t('com.button.반려', '반려')}
        </Button>
        <Button css={IconButton.button} className="confirm" onClick={handleApprove} disableRipple>
          {t('com.button.가입처리(승인)', '가입처리(승인)')}
        </Button>
      </GlobalBtnGroup>
      {isOpenExcpUserRejectPopUp && (
        <ExcpUserRejectPopUp
          open={isOpenExcpUserRejectPopUp}
          title={t('com.label.반려사유', '반려사유')}
          close={() => setOpenExcpUserRejectPopUp(false)}
          rejectCallback={(msg) => {
            setOpenExcpUserRejectPopUp(false);
            handleReject(msg);
          }}
        />
      )}
      {isOpenExcpManagementPopUp && (
        <ExcpManagementReadPopUp
          open={isOpenExcpManagementPopUp}
          excpId={excpId}
          close={() => setOpenExcpManagementPopUp(false)}
        />
      )}
    </>
  );
};

export default ExcpUserManagementPage;
