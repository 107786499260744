/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { tb } from 'components/layouts/Table';
import { IconButton } from '../../../components/buttons/IconSVG';
import DialogContent from '@mui/material/DialogContent';
import Dropzone from 'components/dropzone/Dropzone';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from '../../../components/layouts/Dialog';
import { useTranslation } from 'react-i18next';
import { findFiles } from 'apis/admin/FileUpload';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
  SearchButtonWrap,
} from '../../../components/layouts/SearchBox';
import { CloseBtn } from '../../../components/buttons/CustomButton';
import { ComboBox } from 'components/selects/ComboBox';
import { Textarea } from 'components/inputs/CustomInput';
import { useCommonModal } from 'hooks/useCommonModal';
import { useLoading } from 'components/process/Loading';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { FileInfo } from 'models/admin/FileInfo';
import { saveTplwithHistory } from 'apis/admin/UploadTemplate';
import { BizName, FileTypeName } from 'models/common/Common';
import { useMessageBar } from 'hooks/useMessageBar';
import { CrudCode } from 'models/common/Edit';

interface SearchParam {
  userId?: string;
  empNm?: string;
  deptNm?: string;
  useYn?: string;
  isEditableFlag?: boolean;
}

type Props = {
  open: boolean;
  condition: object;
  close: () => void;
  //singleSelect?: boolean;
};

export const UploadTemplatePopUp = (props: any) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openLoading } = useLoading();
  const [isEditableFlag, setEditableFlag] = useState<boolean>(true);
  const [versionNo, setVersionNo] = useState<any>('');
  const [changeDesc, setChangeDesc] = useState<string>('');
  const [fileInfo, setFileInfo] = useState<any>({
    atchFileId: '',
    atchFileGrId: '',
  });
  const [selectedFile, setSelectedFile] = useState<FileInfo | null>(null);
  const dropzoneRef = useRef<any>(null);
  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    setVersionNo(
      props.condition?.versionNo ? (Number(props.condition?.versionNo) + 0.1).toFixed(1) : '1.0'
    );
  }, [props]);

  const handleItemChange = (value: string) => {
    setChangeDesc(value);
  };

  const handleItemSave = async () => {
    if (changeDesc.length > 400) {
      openMessageBar({
        type: 'error',
        content: t(
          'com.msg.변경내용에 최대 입력 400글자를 초과하였습니다.',
          '변경내용에 최대 입력 400글자를 초과하였습니다.'
        ),
      });
      return;
    }
    if (selectedFile) {
      openLoading(true);
      const fileSaveResponse: FileSaveResponse = await dropzoneRef.current
        ?.saveFiles()
        .finally(() => openLoading(false));
      if (fileSaveResponse.fileSaveResult == FileSaveResult.FAIL) return;
      const atchFileGrId = fileSaveResponse.atchFileGrId;
      let atchFileId = '';
      await findFiles(atchFileGrId).then((result: FileInfo[]) => {
        if (result && result.length > 0) {
          atchFileId = result[result.length - 1].atchFileId;
          setSelectedFile(result[result.length - 1]);
          // setFileInfo({ , atchFileGrId });
        }
      });
      saveTplwithHistory({
        tpCd: props.condition?.tpCd,
        tplType: props.condition?.tplType,
        tplFileId: props.condition?.tplFileId,
        versionNo: versionNo,
        chCont: changeDesc,
        atchFileId,
        atchFileGrId,
      }).then(() => {
        openMessageBar({
          type: 'error',
          content: t('com.label.저장되었습니다.', '저장되었습니다.'),
        });
        props.close();
      });
      props.setPopup(false);
    } else {
      openMessageBar({
        type: 'error',
        content: t('com.label.변경된 첨부파일이 없습니다.', '변경된 첨부파일이 없습니다.'),
      });
    }
  };

  // const uploadTemplate = async () => {
  //   openLoading(true);
  //   const fileSaveResponse: FileSaveResponse = await dropzoneRef.current
  //     ?.saveFiles()
  //     .finally(() => openLoading(false));
  //   if (fileSaveResponse.fileSaveResult == FileSaveResult.FAIL) return;
  //   const atchFileGrId = fileSaveResponse.atchFileGrId;
  //   let atchFileId = '';
  //   await findFiles(atchFileGrId).then((result: FileInfo[]) => {
  //     if (result && result.length > 0) {
  //       atchFileId = result[result.length - 1].atchFileId;
  //       setSelectedFile(result[result.length - 1]);
  //       setFileInfo({ atchFileId, atchFileGrId });
  //     }
  //   });
  // };

  return (
    <>
      <Dialog open={true} css={di.dialog} fullWidth className="md" maxWidth={false}>
        <TitleWrap>
          <h2>
            {t('admin.label.템플릿', '템플릿') + ' ' + t('com.button.Version Up', 'Version Up')}
          </h2>
          <CloseBtn
            onClick={() => {
              props.setPopup(false);
            }}
          ></CloseBtn>
        </TitleWrap>
        <DialogContent>
          <TableContainer css={tb.table}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <span className="dot">{t('admin.label.템플릿유형', '템플릿유형')}</span>
                  </TableCell>
                  <TableCell className="w500">
                    <ComboBox
                      placeholder={String(
                        t('admin.label.템플릿유형', '템플릿유형') +
                          t('admin.msg.을 선택해 주세요.', '을 선택해 주세요.')
                      )}
                      disabled
                      options={[
                        { cmnCd: props.condition?.tpCd, cmnCdNm: props.condition?.tplType },
                      ]}
                      defaultValue={props.condition?.tpCd}
                      onChange={() => true}
                    />
                  </TableCell>
                  <TableCell className="w500 noBg"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('admin.grid.버전', '버전')}</TableCell>
                  <TableCell colSpan={2}>
                    {props.condition?.versionNo ? props.condition?.versionNo + ' → ' : ''}
                    <b>{versionNo}</b>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className="dot">
                      {t('admin.label.템플릿', '템플릿') + t('com.label.업로드', '업로드')}
                    </span>
                  </TableCell>
                  <TableCell className="w500" colSpan={2}>
                    <Dropzone
                      atchFileGrIdInput={props.condition?.atchFileGrId}
                      singleSelect={true}
                      ref={dropzoneRef}
                      atchFilesHide={true}
                      atchFileTpCd={props.condition?.tpCd}
                      bizName={BizName.TPL}
                      // downloadOnly={selectedFile ? true : false}
                      onChange={(files) => {
                        // setSelectedFile(files);
                        if (files && files.length > 0) {
                          const file = files.filter((o) => o.crudKey === CrudCode.CREATE);
                          if (file.length > 0) {
                            setSelectedFile(files[0]);
                          }
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell style={{ textAlign: 'center' }}>
                    {t('com.label.변경', '변경') + ' ' + t('com.label.파일명', '파일명')}
                  </TableCell>
                  <TableCell className="w500" colSpan={2} style={{ textAlign: 'center' }}>
                    <b>{selectedFile?.atchFileNm}</b>
                  </TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell>{t('admin.grid.변경내용', '변경내용')}</TableCell>
                  <TableCell className="w500" colSpan={2}>
                    <Textarea
                      name="changeDesc"
                      value={changeDesc}
                      onChange={(e) => handleItemChange(e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        {isEditableFlag && (
          <PopupBtnGroup>
            <SearchButtonWrap>
              {/* <Button className="cancel" onClick={() => close()} disableRipple> */}
              <Button
                onClick={() => {
                  props.setPopup(false);
                }}
              >
                {t('com.button.취소', '취소')}
              </Button>
              <Button
                css={IconButton.button}
                className="save"
                onClick={handleItemSave}
                disableRipple
              >
                {t('com.button.저장', '저장')}
              </Button>
            </SearchButtonWrap>
          </PopupBtnGroup>
        )}
      </Dialog>
    </>
  );
};
