/** @jsxImportSource @emotion/react */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { GrayColor, StatusErrorColor } from 'ui/theme/Color';
import { SVGIcon } from 'components/buttons/IconSVG';
import { ButtonSize, ButtonStyle, ButtonDesign } from 'components/buttons/ButtonStyle';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const menuContext = useContext<MenuContextType>(MenuContext);

  return (
    <Construct>
      <div className="infoWrap">
        <div className="preParing">{t('com.label.작업중입니다.', '작업중입니다.')}</div>
        <div className="infoText">
          {t('com.label.해당 메뉴는 작업중입니다.', '해당 메뉴는 작업중입니다.')}
        </div>
        <Button onClick={() => menuContext.closeCurrentTab(menuContext)} disableRipple>
          {t('com.button.이전으로 돌아가기', '이전으로 돌아가기')}
        </Button>
      </div>
    </Construct>
  );
};

const Construct = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 170px);
  .infoWrap {
    text-align: center;
  }
  .preParing {
    font-size: 15px;
    font-weight: bold;
    line-height: 2.2;
    color: ${GrayColor.Gray500};
    position: relative;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 56px;
      height: 56px;
      background: ${StatusErrorColor.StatusError500};
      -webkit-mask: ${SVGIcon.IcoConstruct};
      mask-image: ${SVGIcon.IcoConstruct};
      position: absolute;
      top: -64px;
      left: calc(50% - 28px);
    }
  }
  .infoText {
    margin: 8px 0px 16px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: ${GrayColor.Gray500};
  }
  button {
    ${ButtonSize.lg};
    ${ButtonStyle.outlineNormal};
    ${ButtonDesign.labelLG};
  }
`;
export default NotFoundPage;
