/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  StatusDot,
} from 'components/layouts/ContentLayout';
import { GatingProgressStatus } from 'models/gtng/GatingProgressStatus';
import { GatingContListPopUp } from '../popup/GatingContListPopUp';
import { IconButton } from 'components/buttons/IconSVG';
import { getExcelFileName } from 'utils/ExcelUtil';
import CustomGrid from 'components/grids/CustomGrid';

/**
 * Gating 진척관리 > Gating 진행 현황 그리드
 * @param props
 * @constructor
 */
const GatingProgressStatusGrid = ({ subTitle, progressStatusData }: any) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<GatingProgressStatus[]>([]);
  const navigate = useNavigate();
  const [initUserId, setInitUserId] = useState<string>(''); //검색할 파라미터 상태관리
  const [popup, setPopup] = useState<any>(false); //팝업창 오픈, 클로즈 상태관리
  const [contPopTitle, setContPopTitle] = useState<string>(''); //검색할 파라미터 상태관리
  const [flexRef, setFlexRef] = useState<any>();

  useEffect(() => {
    setRowData(progressStatusData || []);
    console.log('progressStatusData', progressStatusData);
  }, [progressStatusData]);

  const OnInitialized = (grid) => {
    setFlexRef(grid);

    grid.itemFormatter = (panel, row, col, cell) => {
      const binding = panel.grid.columns[col].binding;
      if (
        'inspectionSeqs' === binding ||
        'nprogressRatio' === binding ||
        'nprogressSummary' === binding ||
        'yprogressRatio' === binding ||
        'yprogressSummary' === binding ||
        'result' === binding ||
        'elapsedDays' === binding
      ) {
        const pin = cell.getElementsByClassName('wj-elem-pin');
        pin && pin.length && pin[0].remove();
      }
    };

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnPlay':
            fnSearchCheckResultBtn(item);
            break;
          case 'actionContactId':
            setPopup(true);
            setInitUserId(item.actionContactId);
            setContPopTitle(String(t('gtng.label.실시담당자', '실시담당자')));

            break;
          case 'checkerId':
            setPopup(true);
            setInitUserId(item.checkerId);
            setContPopTitle(String(t('gtng.label.점검담당자', '점검담당자')));
            break;
          case 'completionCheckerId':
            setPopup(true);
            setInitUserId(item.completionCheckerId);
            setContPopTitle(String(t('gtng.label.실시(T/L)', '실시(T/L)')));
            break;
        }
      }
      grid.refresh();
    });
  };

  console.log('rowData', rowData);

  const layoutDefinition = [
    {
      header: String(t('com.label.NO', 'NO')),
      binding: 'no',
      cellTemplate: (grid) => grid.row._idx + 1,
      width: 40,
    },
    {
      binding: 'equipmentId',
      header: String(t('gtng.grid.Equipment ID', 'Equipment ID')),
      align: 'left',
      width: 200,
    },
    {
      binding: 'equipmentName',
      header: String(t('gtng.grid.Equipment Desc', 'Equipment Desc')),
      align: 'left',
      width: 250,
    },
    {
      binding: 'inspectionSeqs',
      header: String(t('gtng.grid.진행차수', '진행차수')),
      width: 75,
    },
    {
      binding: 'gatingPeriod',
      header: String(t('gtng.grid.진행일정', '진행일정')),
      width: 200,
    },
    {
      header: String(t('gtng.label.중간점검', '중간점검')),
      columns: [
        {
          binding: 'nprogressRatio',
          header: String(t('com.label.상태', '상태')),
          width: 55,
          cssClass: 'WijmoDot',
          cellTemplate: (item) => dotCellRenderer(item.value),
        },
        {
          binding: 'nprogressRatio',
          header: String(t('gtng.grid.진행율', '진행율')),
          width: 90,
        },
        {
          binding: 'nprogressSummary',
          header: String(t('gtng.grid.점검요약', '점검요약')),
          width: 80,
        },
      ],
    },
    {
      header: String(t('gtng.label.최종점검', '최종점검')),
      columns: [
        {
          binding: 'yprogressRatio',
          header: String(t('com.label.상태', '상태')),
          cssClass: 'WijmoDot',
          width: 55,
          cellTemplate: (item) => dotCellRenderer(item.value),
        },
        {
          binding: 'yprogressRatio',
          header: String(t('gtng.grid.진행율', '진행율')),
          width: 90,
        },
        {
          binding: 'yprogressSummary',
          header: String(t('gtng.grid.점검요약', '점검요약')),
          width: 80,
        },
      ],
    },
    {
      binding: 'actionContactId',
      header: String(t('gtng.label.실시담당자', '실시담당자')),
      minWidth: 160,
      width: '*',
      align: 'left',
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (item.item.actionContactName === null) {
          return '<span></span>';
        } else {
          return `<span>${item.item.actionContactName}</span><button id="actionContactId" />`;
        }
      },
    },
    {
      binding: 'actionContactName',
      header: String(t('gtng.label.실시담당자', '실시담당자')),
      visible: false,
    },
    {
      binding: 'checkerId',
      header: String(t('gtng.label.점검담당자', '점검담당자')),
      minWidth: 160,
      width: '*',
      align: 'left',
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (item.item.checkerName === null) {
          return '<span></span>';
        } else {
          return `<span>${item.item.checkerName}</span><button id="checkerId" />`;
        }
      },
    },
    {
      binding: 'checkerName',
      header: String(t('gtng.label.점검담당자', '점검담당자')),
      visible: false,
    },
    {
      binding: 'completionCheckerId',
      header: String(t('gtng.label.실시(T/L)', '실시(T/L)')),
      minWidth: 160,
      width: '*',
      align: 'left',
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (item.item.completionCheckerName === null) {
          return '<span></span>';
        } else {
          return `<span>${item.item.completionCheckerName}</span><button id="completionCheckerId" />`;
        }
      },
    },
    {
      binding: 'completionCheckerName',
      header: String(t('gtng.label.실시(T/L)', '실시(T/L)')),
      visible: false,
    },
    {
      binding: 'elapsedDays',
      header: String(t('gtng.grid.경과일수', '경과일수')),
      width: 70,
    },
    {
      header: String(t('gtng.grid.결과조회/작성', '결과조회/작성')),
      width: 100,
      binding: 'result',
      cssClass: 'WijmoPlay',
      // cellTemplate: (item) => fnSearchCheckResultBtn(item),
      cellTemplate: (item) => {
        if (item.item.progressStatusCode == 'B2' || item.item.progressStatusCode == 'B3') {
          return '<button id="btnPlay"></button>';
        } else {
          return '';
        }
      },
    },
  ];

  const dotCellRenderer = (params: any) => {
    if (params != null) {
      const value = parseInt(params.split('%'));
      if (value > 89) {
        return '<span class="confirm"></span>';
      }
      if (value < 70) {
        return '<span class="error"></span>';
      }
      if (value < 90 && value > 69) {
        return '<span class="warning"></span>';
      }
    }
  };

  //점검상태가 B2(중간점검),B3(최종검검)시 Gating 평과/채점 (결과등록화면)으로 이동
  const fnSearchCheckResultBtn = (item: any) => {
    const searchParam = {
      gatingId: item.gatingId,
      gatingNo: item.gatingNo,
      equipmentId: item.equipmentId,
      equipmentName: item.equipmentName,
    };
    navigate('/gating/result/gating-check-result', { state: { sCheckResultParam: searchParam } });
  };

  const exportFormat = (args) => {
    const p = args.panel;
    const row = args.row;
    const col = args.col;
    const xlsxCell = args.xlsxCell;
    if (p.columns[col].binding === 'actionContactId') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'checkerId') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'completionCheckerId') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
      formatItem: exportFormat,
    });
    book.sheets[0].name = 'gating';
    book.saveAsync(getExcelFileName(t('gtng.label.Gating 진행현황', 'Gating 진행현황')));
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 진행현황', 'Gating 진행현황')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
          {subTitle && <div className="info primary">{subTitle}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <StatusDot>
            <span className="green">{t('gtng.label.달성(90% 이상)', '달성(90% 이상)')}</span>
            <span className="yellow">
              {t('gtng.label.경고(70% ~ 90% 미만)', '경고(70% ~ 90% 미만)')}
            </span>
            <span className="red">{t('gtng.label.미달(70% 미만)', '미달(70% 미만)')}</span>
          </StatusDot>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={300}
        align="center"
        isReadOnly={true}
        isSelector={false}
        excludeFilter={[
          'inspectionSeqs',
          'nprogressRatio',
          'nprogressSummary',
          'yprogressRatio',
          'yprogressSummary',
          'result',
          'elapsedDays',
        ]}
        initialized={OnInitialized}
      />

      {/*담당자 팝업*/}
      {popup && (
        <GatingContListPopUp
          open={popup}
          title={contPopTitle}
          setReadonly={true}
          initParam={initUserId}
          close={() => setPopup(false)}
          onCallback={() => setPopup(false)}
        />
      )}
    </>
  );
};
export default GatingProgressStatusGrid;
