import React from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import MainPage from 'pages/sample/MainPage';
import SampleMainPage from 'pages/sample/SampleMainPage';
import SamplePage from 'pages/sample/SamplePage';
import GuideComponent from 'pages/sample/guide/GuideComponent';
import NotFoundPage from 'pages/common/NotFoundPage';

import LoginPage from 'pages/common/LoginPage';
import MenuManagementPage from 'pages/admin/MenuManagementPage';
import ApiUrlManagementPage from 'pages/admin/ApiUrlManagementPage';
import RoleDepartmentManagementPage from 'pages/admin/RoleDepartmentManagementPage';
import { MessageManagementPage } from 'pages/admin/MessageManagementPage';
import RoleManagementPage from 'pages/admin/RoleManagementPage';
import RoleEmployeeManagementPage from 'pages/admin/RoleEmployeeManagementPage';
import CodeManagementPage from 'pages/admin/CodeManagementPage';
import ProtectRoute from './ProtectRoute';
import ApprovalLineManagementPage from 'pages/admin/ApprovalLineManagementPage';
import ApprovalExcludeManagementPage from 'pages/admin/ApprovalExcludeManagementPage';
import { ApprovalTemplateManagementPage } from 'pages/admin/ApprovalTemplateManagementPage';
import NotificationManagementPage from 'pages/admin/NotificationManagementPage';
import BbsManagementPage from 'pages/admin/BbsManagementPage';
import BbsPostPage from 'pages/admin/BbsPostPage';
import { BbsEditPage } from 'pages/admin/BbsEditPage';
import NoticeManagementPage from 'pages/admin/noticemanagement/NoticeManagementPage';
import ApprovalDelegateManagementPage from 'pages/admin/ApprovalDelegateManagementPage';
import NoticeManagementDetailPage from 'pages/admin/noticemanagement/NoticeManagementDetailPage';
import IfLogManagementPage from 'pages/admin/IfLogManagementPage';
import EmailLogManagementPage from 'pages/admin/EmailLogManagementPage';
import MenuLogManagementPage from 'pages/admin/MenuLogManagementPage';
import LoginLogManagementPage from 'pages/admin/LoginLogManagementPage';
import DropzonSamplePage from 'pages/sample/DropzonSamplePage';
import CallTestPage from 'pages/sample/CallTestPage';
import GatingCheckSheetPage from 'pages/gtng/GatingCheckSheetPage';
import GtngVerMstPage from 'pages/gtng/GtngVerMstPage';

/* IP List */
import { StatusManagementPage } from 'pages/ip/maintenance/StatusManagementPage';
import EquipmentParameterPage from 'pages/ip/base/EquipmentParameterPage';
import EquipmentParameterRequestPage from 'pages/ip/base/EquipmentParameterRequestPage';
import BaseImformationPage from 'pages/ip/base/BaseImformationPage';
import GatingRegistPage from 'pages/gtng/GatingRegistPage';
import CheckResultRecordPage from 'pages/ip/maintenance/CheckResultRecordPage';
import CheckResultPage from 'pages/ip/maintenance/CheckResultPage';
import GatingResultReportPage from 'pages/gtng/GatingResultReportPage';
import GatingProgressManagementPage from 'pages/gtng/GatingProgressManagementPage';
import { GatingJudgementResultPage } from 'pages/gtng/GatingJudgementResultPage';

import RequestSample from 'pages/sample/RequestSample';
import ApproveManagementPage from 'pages/approves/ApproveManagementPage';
import ApproveRequestPage from 'pages/approves/ApproveRequestPage';
import ApproveRequestDetailPage from 'pages/approves/ApproveRequestDetailPage';
import GatingCheckResultPage from 'pages/gtng/GatingCheckResultPage';
import SensorListPage from 'pages/sensor/SensorListPage';
import SensorManagementPage from 'pages/sensor/base/SensorManagementPage';
import MpItemPage from 'pages/mp/MpItemPage';
import MpEquipmentClassificationPage from 'pages/mp/MpEquipmentClassificationPage';
import MpItemManagementPage from 'pages/mp/MpItemManagementPage';
import MpExcelManagementPage from 'pages/mp/MpExcelManagementPage';
import MpTargetManagementPage from 'pages/mp/MpTargetManagementPage';
import MPTrackingManagementPate from 'pages/mp/MPTrackingManagementPate';
import NewSensorSolutionReqStatusPage from 'pages/sensor/NewSensorSolutionReqStatusPage';
// import NewSensorSolutionReqPopupShowMenu from 'pages/sensor/NewSensorSolutionReqPopupShowMenu';
import NewSensorSolutionReqPage from 'pages/sensor/NewSensorSolutionReqPage';
import GuideWijmo from 'pages/sample/wijmo/GuideWijmo';
import DevelopMenualManagementPage from 'pages/admin/developmenualmanagement/DevelopMenualManagementPage';
import UploadTemplatePage from 'pages/admin/UploadTemplatePage';
import ExcpManagementPage from 'pages/partner/ExcpManagementPage';
import ExcpUserRegistPage from 'pages/partner/ExcpUserRegistPage';
import ExcpUserEditPage from 'pages/partner/ExcpUserEditPage';
import ExcpUserManagementPage from 'pages/partner/ExcpUserManagementPage';
import ExcpSecrPldgManagementPage from 'pages/partner/ExcpSecrPldgManagementPage';
import UtMatrixListPage from 'pages/ut/UtMatrixListPage';
import UtMatrixManagerPage from 'pages/ut/UtMatrixManagerPage';
import UtMatrixRegistPage from 'pages/ut/UtMatrixRegistPage';
import UtMatrixRequestPage from 'pages/ut/UtMatrixRequestPage';
import UtMatrixSummaryPage from 'pages/ut/UtMatrixSummaryPage';
import UtMatrixReviewPage from '../pages/ut/UtMatrixReviewPage';

export const MenuUrl = {
  BbsManagementPage: '/system/admin/sample/bbs-sample',
  BbsPostPage: '/system/admin/sample/bbs-sample/:bbmNo',
  BbsEditPage: '/system/admin/sample/bbs-edit',
  BbsMenual: '/system/admin/sample/menual',
};

const RouterList: RouteObject[] = [
  { path: '/', element: <MainPage /> },
  { path: '/sample', element: <SampleMainPage /> },
  { path: '/sample/sample', element: <SamplePage /> },
  { path: '/sample/dropzone', element: <DropzonSamplePage /> },
  { path: '/sample/call', element: <CallTestPage /> },
  { path: '/sample/guide/wijmo', element: <GuideWijmo /> },
  { path: '/sample/upload-templates', element: <CallTestPage /> },
  { path: '/sample/guide/component', element: <GuideComponent /> },
  { path: '/system/login', element: <LoginPage /> },
  { path: '/system/user-regist', element: <ExcpUserRegistPage /> },
  { path: '/system/user-edit', element: <ExcpUserEditPage /> },
  {
    element: <ProtectRoute />,
    children: [
      /* 접근제어가 필요한 화면 */
      /*{ path: '/sample', element: <SamplePage /> },*/
      { path: '/accessible', element: <SamplePage /> },
      { path: '/inaccessible', element: <SamplePage /> },
      /* TODO 테스트 용으로 머지시 삭제 */
    ],
  },
  { path: '/system/admin/role-menu/role-menu', element: <MenuManagementPage /> },
  { path: '/system/admin/message/message', element: <MessageManagementPage /> },
  { path: '/system/admin/api/api', element: <ApiUrlManagementPage /> },
  { path: '/system/admin/role/role', element: <RoleManagementPage /> },
  { path: '/system/admin/role-user/role-user', element: <RoleEmployeeManagementPage /> },
  {
    path: '/system/admin/role-department/role-department',
    element: <RoleDepartmentManagementPage />,
  },
  { path: '/system/admin/code/code', element: <CodeManagementPage /> },
  { path: '/system/admin/approval-line/approval-line', element: <ApprovalLineManagementPage /> },
  { path: '/system/admin/to-inform/to-inform', element: <NotificationManagementPage /> },
  {
    path: '/system/admin/approval-exclude/approval-exclude',
    element: <ApprovalExcludeManagementPage />,
  },
  {
    path: '/system/admin/approval-template/approval-template',
    element: <ApprovalTemplateManagementPage />,
  },
  {
    path: '/system/admin/approval-delegate/approval-delegate',
    element: <ApprovalDelegateManagementPage />,
  },
  {
    path: '/system/admin/upload-template',
    element: <UploadTemplatePage />,
  },
  {
    path: '/system/admin/excp/excp',
    element: <ExcpManagementPage />,
  },
  {
    path: '/system/admin/excp/excp-user',
    element: <ExcpUserManagementPage />,
  },
  {
    path: '/system/admin/excp/excp-secr-pldg',
    element: <ExcpSecrPldgManagementPage />,
  },
  { path: MenuUrl.BbsManagementPage, element: <BbsManagementPage /> },
  { path: '/system/admin/sample/bbs-sample/bbs-post', element: <BbsPostPage /> },
  { path: '/system/admin/sample/bbs-sample/bbs-edit', element: <BbsEditPage /> },
  { path: '/system/admin/sample/notice', element: <NoticeManagementPage /> },
  { path: '/system/admin/sample/notice-edit', element: <NoticeManagementDetailPage /> },
  { path: MenuUrl.BbsMenual, element: <DevelopMenualManagementPage /> },
  { path: '/system/admin/if-log/if-log', element: <IfLogManagementPage /> },
  { path: '/system/admin/email-log/email-log', element: <EmailLogManagementPage /> },
  { path: '/system/admin/menu-log/menu-log', element: <MenuLogManagementPage /> },
  { path: '/system/admin/login-log/login-log', element: <LoginLogManagementPage /> },
  { path: '/ip', element: null },
  { path: '/ip-regist', element: null },
  { path: '/ip/parameter', element: <BaseImformationPage /> },
  { path: '/ip/maintenance/StatusManagementPage', element: <StatusManagementPage /> },
  { path: '/ip/maintenance/check-result-record', element: <CheckResultRecordPage /> },
  { path: '/ip/maintenance/check-result', element: <CheckResultPage /> },
  { path: '/ip/equipment-parameter', element: <EquipmentParameterPage /> },
  { path: '/ip/equipment-parameter/request', element: <EquipmentParameterRequestPage /> },
  { path: '/gating/basic/check-sheet', element: <GatingCheckSheetPage /> },
  { path: '/gating/result/gating-check-result', element: <GatingCheckResultPage /> },

  { path: '/elm/gtng/gating-version-master', element: <GtngVerMstPage /> },
  { path: '/gating/basic/gating-regist', element: <GatingRegistPage /> },
  { path: '/gating/result/gating-result-report', element: <GatingResultReportPage /> },
  { path: '/gating/result/gating-judge-report', element: <GatingJudgementResultPage /> },
  { path: '/gating/result/gating-progress-management', element: <GatingProgressManagementPage /> },

  { path: '/sample/request', element: <RequestSample /> },
  { path: '/approves/approve-management-page', element: <ApproveManagementPage /> },
  { path: '/approves/approve-request-page', element: <ApproveRequestPage /> },
  { path: '/approves/approve-request-detail', element: <ApproveRequestDetailPage /> },

  { path: '/sensor/sensorListPage', element: <SensorListPage /> },
  { path: '/sensor/sensor-management-page', element: <SensorManagementPage /> },
  { path: '/sensor/newSensorSolutionReqStatus', element: <NewSensorSolutionReqStatusPage /> },
  { path: '/sensor/newSensorSolutionReqPage', element: <NewSensorSolutionReqPage /> },
  // {
  //   path: '/sensor/request/newSensorSolutionReqStatusShowMenu', //'/sensor/newSensorSolutionReq', //'/sensor/request/newSensorSolutionReqStatusShowMenu', //'/sensor/request/newSensorSolutionReq',
  //   element: <NewSensorSolutionReqPopupShowMenu />,
  // },

  { path: '/mp/item', element: <MpItemPage /> },
  { path: '/mp/equipment-clsf-page', element: <MpEquipmentClassificationPage /> },
  { path: '/mp/excel-management', element: <MpExcelManagementPage /> },
  { path: '/mp/item-management', element: <MpItemManagementPage /> },
  { path: '/mp/item-management/new', element: <MpItemManagementPage /> },
  { path: '/mp/target', element: <MpTargetManagementPage /> },
  { path: '/mp/tracking-management', element: <MPTrackingManagementPate /> },
  { path: '/mp/item-management/tpl', element: <MPTrackingManagementPate /> },

  { path: '/*', element: <NotFoundPage /> },

  { path: '/ut/ut-matrix-request', element: <UtMatrixRequestPage /> },
  { path: '/ut/regist-management/review', element: <UtMatrixReviewPage /> },
  { path: '/ut/regist-management/list', element: <UtMatrixListPage /> },
  { path: '/ut/ut-matrix-manager', element: <UtMatrixManagerPage /> },
  { path: '/ut/regist-management/regist', element: <UtMatrixRegistPage /> },
  { path: '/ut/ut-matrix-summary', element: <UtMatrixSummaryPage /> },
];

export const getRoute = (path: string) => {
  return RouterList.filter((router) => router.path === path);
};

const Router = () => {
  // const { userId, teamYn, tldYn } = useSessionStore();
  // const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
  return useRoutes(RouterList);
};

export default Router;
