/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  StatusDot,
} from 'components/layouts/ContentLayout';
import { GatingProgressSummary } from 'models/gtng/GatingProgressSummary';
import { findGatingProgressMst, findGatingProgressStatus } from 'apis/gtng/GatingProgressSummary';
import GatingProgressStatusGrid from './GatingProgressStatusGrid';
import { GatingProgressStatus } from 'models/gtng/GatingProgressStatus';
import { GatingContListPopUp } from '../popup/GatingContListPopUp';
import { IconButton } from 'components/buttons/IconSVG';
import { getExcelFileName } from 'utils/ExcelUtil';
import CustomGrid from 'components/grids/CustomGrid';

/**
 * Gating 진척관리 > Gating 진행 요약 그리드
 * @param props
 * @constructor
 */
export const GatingProgressSummaryGrid = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<GatingProgressSummary[]>([]);
  const [progressStatusData, setProgressStatusData] = useState<GatingProgressStatus[]>([]);
  const [progressStatusSubTitle, setProgressStatusSubTitle] = useState<string>('');
  const [initUserId, setInitUserId] = useState<string>(''); //검색할 파라미터 상태관리
  const [popup, setPopup] = useState<any>(false); //팝업창 오픈, 클로즈 상태관리
  const [contPopTitle, setContPopTitle] = useState<string>(''); //검색할 파라미터 상태관리

  const [flexRef, setFlexRef] = useState<any>();

  useEffect(() => {
    fnSearchProgressMst(props.searchParamData);
  }, [props.searchParamData, props.searchState]);

  const fnSearchProgressMst = useCallback((searchParamData) => {
    if (searchParamData) {
      findGatingProgressMst(searchParamData).then((result: GatingProgressSummary[]) => {
        setRowData(result);
        setProgressStatusSubTitle('');
        setProgressStatusData([]);
      });
    }
  }, []);

  const OnInitialized = (grid) => {
    grid.itemFormatter = (panel, row, col, cell) => {
      const binding = panel.grid.columns[col].binding;
      if (
        'periodGyr' === binding ||
        'nprogressRatio' === binding ||
        'nprogressSummary' === binding ||
        'yprogressRatio' === binding ||
        'yprogressSummary' === binding ||
        'result' === binding
      ) {
        const pin = cell.getElementsByClassName('wj-elem-pin');
        pin && pin.length && pin[0].remove();
      }
    };

    setFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
      const item = grid.rows[ht.row].dataItem; // 선택한 row의

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'checkResult': //진행현황
            fnSearchProgressStatusBtn(item);
            break;
          case 'judgeResult': //판정결과
            fnSearchJudgeResultBtn(item);
            break;
          case 'resultReport': //완료문서(결과리포트)
            fnSearchResultReportBtn(item);
            break;
          case 'gatingContIds':
            setPopup(true);
            setInitUserId(item.gatingContIds);
            setContPopTitle(String(t('gtng.label.등록 담당자', '등록담당자')));
            break;
          case 'pmoApproverId':
            setPopup(true);
            setInitUserId(item.pmoApproverId);
            setContPopTitle(String(t('gtng.label.등록 T/L', '등록T/L')));
            break;
        }
      }
      grid.refresh();
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      cellTemplate: (grid) => grid.row._idx + 1,
      width: 40,
      isReadOnly: true,
    },
    {
      binding: 'gatingName',
      header: String(t('gtng.label.Gating Task명', 'Gating Task명')),
      align: 'left',
      minWidth: 200,
      width: '*',
    },
    {
      binding: 'gatingTypeName',
      header: String(t('gtng.label.Gating 유형', 'Gating 유형')),
      align: 'left',
      width: 140,
    },
    {
      binding: 'gatingStepName',
      header: String(t('gtng.label.Gating 단계', 'Gating 단계')),
      width: 140,
      align: 'left',
    },
    {
      binding: 'gatingNo',
      header: String(t('gtng.grid.Gating No', 'Gating No')),
      width: 200,
    },
    {
      binding: 'progressStatusName',
      header: String(t('gtng.label.Gating 상태', 'Gating 상태')),
      width: 120,
    },
    {
      binding: 'gatingContIds',
      header: String(t('gtng.label.등록 담당자', '등록 담당자')),
      width: 200,
      align: 'left',
      cssClass: 'WijmoFind',
      cellTemplate: '<span>${item.gatingContName}</span><button id="gatingContIds" />',
    },
    {
      binding: 'gatingContName',
      header: String(t('gtng.label.등록 담당자', '등록 담당자')),
      visible: false,
    },
    {
      binding: 'pmoApproverId',
      header: String(t('gtng.label.등록 T/L', '등록 T/L')),
      width: 200,
      align: 'left',
      cssClass: 'WijmoFind',
      cellTemplate: '<span>${item.pmoApproverName}</span><button id="pmoApproverId" />',
    },
    {
      binding: 'pmoApproverName',
      header: String(t('gtng.label.등록 T/L', '등록 T/L')),
      visible: false,
    },
    {
      header: String(t('gtng.grid.진행일정', '진행일정')),
      columns: [
        {
          binding: 'gatingPeriod',
          header: String(t('gtng.grid.일정', '일정')),
          width: 200,
        },
        {
          binding: 'periodGyr',
          header: String(t('com.label.상태', '상태')),
          cssClass: 'WijmoDot',
          width: 55,
          cellTemplate: (item) => dotCellRenderer(item.value),
        },
      ],
    },
    {
      header: String(t('gtng.label.중간점검', '중간점검')),
      columns: [
        {
          binding: 'nprogressRatio',
          header: String(t('gtng.grid.진행율', '진행율')),
          width: 100,
        },
        {
          binding: 'nprogressSummary',
          header: String(t('gtng.grid.점검요약', '점검요약')),
          width: 80,
        },
      ],
    },
    {
      header: String(t('gtng.label.최종점검', '최종점검')),
      columns: [
        {
          binding: 'yprogressRatio',
          header: String(t('gtng.grid.진행율', '진행율')),
          width: 100,
        },
        {
          binding: 'yprogressSummary',
          header: String(t('gtng.grid.점검요약', '점검요약')),
          width: 80,
        },
      ],
    },
    {
      header: String(t('gtng.label.판정결과', '판정결과')),
      width: 80,
      binding: 'result',
      cssClass: 'WijmoPlay',
      cellTemplate: (item) => {
        if (item.item.progressStatusCode == 'C') {
          return '<button id="judgeResult"></button>';
        } else {
          return '';
        }
      },
    },
    {
      header: String(t('gtng.grid.진행현황', '진행현황')),
      width: 80,
      binding: 'result',
      cssClass: 'WijmoPlay',
      cellTemplate: (item) => {
        if (item.item.progressStatusCode == 'A') {
          return '';
        } else {
          return '<button id="checkResult"></button>';
        }
      },
    },
    {
      header: String(t('gtng.grid.완료문서', '완료문서')),
      width: 80,
      binding: 'result',
      cssClass: 'WijmoPlay',
      cellTemplate: (item) => {
        if (item.item.progressStatusCode == 'D') {
          return '<button id="resultReport"></button>';
        } else {
          return '';
        }
      },
    },
  ];

  const dotCellRenderer = (params: any) => {
    if (params != null) {
      if (params == 'G') {
        return '<span class="confirm"></span>';
      }
      if (params == 'Y') {
        return '<span class="warning"></span>';
      }
      if (params == 'R') {
        return '<span class="error"></span>';
      }
    }
  };

  //진행현황 출력
  const fnSearchProgressStatusBtn = (params: any) => {
    const searchParam = {
      gatingId: params.gatingId,
    };
    setProgressStatusSubTitle(params.gatingName);
    findGatingProgressStatus(searchParam).then((result: GatingProgressStatus[]) => {
      setProgressStatusData(result);
    });
  };

  //판정결과 페이지이동 (progressStatus == 'C' 일때 -> Gating 판정 결과 Report)
  const fnSearchJudgeResultBtn = (params: any) => {
    const searchParam = {
      gatingId: params.gatingId,
      gatingNo: params.gatingNo,
    };
    navigate('/gating/result/gating-judge-report', { state: { sJudgeResultParam: searchParam } });
  };

  //완료문서 페이지이동(progressStatus == 'D' 일때 -> Gating 결과 report library)
  const fnSearchResultReportBtn = (params: any) => {
    const searchParam = {
      gatingId: params.gatingId,
      gatingNo: params.gatingNo,
      gatingName: params.gatingName,
      gatingTypeCode: params.gatingTypeCode,
      gatingStepCode: params.gatingStepCode,
    };
    navigate('/gating/result/gating-result-report', {
      state: { sResultReprotParam: searchParam },
    });
  };

  const exportFormat = (args) => {
    const p = args.panel;
    const row = args.row;
    const col = args.col;
    const xlsxCell = args.xlsxCell;
    if (p.columns[col].binding === 'gatingContIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'pmoApproverId') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
      formatItem: exportFormat,
    });
    book.sheets[0].name = 'Gating';
    book.saveAsync(getExcelFileName(t('gtng.label.Gating 진행요약', 'Gating 진행요약')));
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 진행요약', 'Gating 진행요약')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <StatusDot>
            <span className="green">{t('com.label.On Track', 'On Track')}</span>
            <span className="yellow">{t('com.label.1주 전', '1주 전')}</span>
            <span className="red">{t('com.label.지연', '지연')}</span>
          </StatusDot>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={300}
        align="center"
        isReadOnly={true}
        isSelector={false}
        excludeFilter={[
          'periodGyr',
          'nprogressRatio',
          'nprogressSummary',
          'yprogressRatio',
          'yprogressSummary',
          'result',
        ]}
        initialized={OnInitialized}
      />

      <GatingProgressStatusGrid
        progressStatusData={progressStatusData}
        subTitle={progressStatusSubTitle}
      />

      {/*담당자 팝업*/}
      {popup && (
        <GatingContListPopUp
          open={popup}
          title={contPopTitle}
          setReadonly={true}
          initParam={initUserId}
          close={() => setPopup(false)}
        />
      )}
    </>
  );
};
