/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { GatingCheckSheetVer } from 'models/gtng/popup/GatingCheckSheetVer';
import { saveGatingCheckSheetVersionUp } from 'apis/gtng/GatingCheckSheet';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { tb } from 'components/layouts/Table';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomTextarea from 'components/inputs/CustomTextarea';
import { IconButton } from 'components/buttons/IconSVG';
import { ContentSection } from 'components/layouts/ContentSection';
import CustomDialog from 'components/modals/common/CustomDialog';

const emptyPostDetail: GatingCheckSheetVer = {
  changeReasonDesc: '',
  dataUpdDtm: '',
  dataUpdUserId: '',
  dataUpdUserNm: '',
  gatingStepCode: '',
  gatingTemplateId: '',
  gatingTypeCode: '',
  newVersionNo: '',
  versionNo: '',
};

type Props = {
  open: boolean;
  close: () => void;
  initParam?: {
    gatingTemplateId: string;
    versionNo: string;
  };
  onCallback: () => void;
};
/**
 * 게이팅 버전 변경 팝업
 * 사용 화면 : 게이팅 체크시트 등록
 * @param open
 * @param close
 * @param initParam
 * @param onCallback
 * @constructor
 */
export const VersionUpPopUp = ({ open, close, initParam, onCallback }: Props) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<GatingCheckSheetVer>(emptyPostDetail);
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();

  const handleChange = (e) => {
    let val = e.target.value;
    if (e.target.name == 'newVersionNo') {
      val = val.replace(/[^\d.]/g, '');
    }
    setRowData({ ...rowData, [e.target.name]: val });
  };

  /**
   * 버전신규 적용  등록버튼
   */
  const btnCkVerInsertInfo = () => {
    if (!(rowData?.newVersionNo || '').length) {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.버전을 입력해 주세요.', '버전을 입력해 주세요.'),
      });
      return;
    }

    if (!(rowData?.changeReasonDesc || '').length) {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.변경사유를 입력해 주세요.', '변경사유를 입력해 주세요.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('gtng.msg.VersionUp 하시겠습니까?', 'VersionUp 하시겠습니까?'),
      yesCallback: async () => {
        if (rowData == null) return;
        rowData.gatingTemplateId = initParam?.gatingTemplateId || '';
        saveGatingCheckSheetVersionUp(rowData).then((response) => {
          if (response != null && response.successOrNot == 'N') {
            openMessageBar({
              type: 'error',
              content: t('gtng.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return;
          }
          openMessageBar({
            type: 'confirm',
            content: t('gtng.msg.저장되었습니다.', '저장되었습니다.'),
          });
          onCallback();
          close();
        });
      },
    });
  };

  const dialogButtons = [
    <Button key={'save'} css={IconButton.button} className="save" onClick={btnCkVerInsertInfo}>
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('gtng.label.Check Sheet Version Up', 'Check Sheet Version Up')}
      size={'sm'}
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <ContentSection>
        <TableContainer css={tb.table}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="firstCol">
                  <span>{t('gtng.label.게이팅템플릿번호', '게이팅템플릿번호')}</span>
                </TableCell>
                <TableCell>{initParam?.gatingTemplateId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="firstCol">
                  <span>{t('gtng.label.최종버전', '최종버전')}</span>
                </TableCell>
                <TableCell>{initParam?.versionNo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="firstCol">
                  <span>{t('gtng.label.신규버전', '신규버전')}</span>
                </TableCell>
                <TableCell>
                  <CustomInputWithSearch
                    name="newVersionNo"
                    placeholder={String(
                      t('gtng.msg.버전을 입력해 주세요.', '버전을 입력해 주세요.')
                    )}
                    value={rowData?.newVersionNo}
                    onChange={handleChange}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="firstCol">
                  <span>{t('gtng.label.변경사유', '변경사유')}</span>
                </TableCell>
                <TableCell>
                  <CustomTextarea
                    name="changeReasonDesc"
                    placeholder={String(
                      t('gtng.msg.변경사유를 입력해 주세요.', '변경사유를 입력해 주세요.')
                    )}
                    value={rowData?.changeReasonDesc}
                    onChange={handleChange}
                    showMaxLength={true}
                    maxLength={500}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ContentSection>
    </CustomDialog>
  );
};
