/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { Button } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import _, { toNumber } from 'lodash';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { FileuploadBtn } from 'components/buttons/CustomButton';
import {
  Divider,
  HalfContetntLayout,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { CustomInputText, CustomInputWrap, Textarea } from 'components/inputs/CustomInput';
import {
  getGatingCheckResultItemList,
  getGatingCheckResultMst,
  retrieveGtngEquipN,
  saveGtngCheckItem,
} from 'apis/gtng/GatingCheckResult';
import { GatingCheckResultItem, GatingCheckResultMst } from 'models/gtng/GatingCheckResult';
import { GatingContListPopUp } from './GatingContListPopUp';
import GatingEquipmentListPopUp from './GatingEquipmentListPopUp';
import { ComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import { CrudCode } from 'models/common/Edit';
import { hasRole } from 'utils/SessionUtil';
import CustomDialog from 'components/modals/common/CustomDialog';
import { convertPercentScore, getInspectionResultCode } from 'utils/GatingUtil';

type Props = {
  open: boolean;
  close: () => void;
  initParam?: {
    gatingId?: string;
    equipmentId?: string;
    gatingCheckItemId?: string;
    rowItemList?: any;
  };
  onCallback: any;
};

/**
 * 게이팅 평가/채점 등록(체크항목별) 팝업
 * @param open
 * @param close
 * @param initParam
 * @param onCallback
 * @constructor
 */
export const GatingCheckResultPopUp = (props: Props) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();

  //dataset
  const [rowData, setRowData] = useState<GatingCheckResultMst>();
  const [rowDataEquip, setRowDataEquip] = useState<GatingCheckResultMst>();
  const [rowDataCheckList, setRowDataCheckList] = useState<GatingCheckResultItem>({});
  const [isOpenContEmpPopUp, setOpenContEmpPopUp] = useState<boolean>(false);
  const [isOpenEquipmentPopUp, setOpenEquipmentPopUp] = useState<boolean>(false);
  const [isOpenFileUploadPopUp, setOpenFileUploadPopUp] = useState<boolean>(false);
  const [initParamFileUpload, setInitParamFileUpload] = useState<any>();
  const [getContUserIds, setContUserIds] = useState<string>('');
  const [okFlagCode, setOkFlagCode] = useState<Code[]>([]);
  const [hasManagerAuth, setHasManagerAuth] = useState(false);
  const isCancel = useRef<boolean>(false);
  const isStatusRequest = useRef<boolean>(false);
  // 점검일자
  const gatingPeriod = useMemo(
    () => [rowDataEquip?.startDate, rowDataEquip?.endDate].filter((o) => !!o).join(' - '),
    [rowDataEquip?.startDate, rowDataEquip?.endDate]
  );
  // 저장 활성화 여부
  const activeSave = useMemo(() => {
    if ('E' === rowData?.progressStatusCode || 'B4' === rowDataEquip?.progressStatusCode) {
      return false;
    } else if (
      'B1' === rowDataEquip?.progressStatusCode &&
      (hasManagerAuth || rowDataCheckList?.aeditableFlag === 'Y')
    ) {
      return true;
    } else if (
      'B2' === rowDataEquip?.progressStatusCode &&
      (hasManagerAuth || rowDataCheckList?.neditableFlag === 'Y')
    ) {
      return true;
    } else if (
      'B3' === rowDataEquip?.progressStatusCode &&
      (hasManagerAuth || rowDataCheckList?.yeditableFlag === 'Y')
    ) {
      return true;
    }
    return false;
  }, [
    hasManagerAuth,
    rowData?.progressStatusCode,
    rowDataEquip?.progressStatusCode,
    rowDataCheckList,
  ]);
  // 점검완료 활성화 여부
  const activeConfirm = useMemo(() => {
    if ('E' === rowData?.progressStatusCode || 'B4' === rowDataEquip?.progressStatusCode) {
      return false;
    } else if (
      'B1' === rowDataEquip?.progressStatusCode &&
      (hasManagerAuth || rowDataCheckList?.aeditableFlag === 'Y')
    ) {
      return true;
    } else if (
      'B2' === rowDataEquip?.progressStatusCode &&
      (hasManagerAuth || rowDataCheckList?.neditableFlag === 'Y')
    ) {
      return true;
    } else if (
      'B3' === rowDataEquip?.progressStatusCode &&
      (hasManagerAuth || rowDataCheckList?.yeditableFlag === 'Y')
    ) {
      return true;
    }
    return false;
  }, [
    hasManagerAuth,
    rowData?.progressStatusCode,
    rowDataEquip?.progressStatusCode,
    rowDataCheckList,
  ]);

  const handleOnClose = () => {
    props.onCallback();
  };

  useEffect(() => {
    getCommonCodes();
    // 관리자 권한 여부
    setHasManagerAuth(hasRole('NIGS_MANAGER') || hasRole('ADM'));
  }, []);

  const getCommonCodes = async () => {
    const okflagCode: Code[] = await getCommonCodeNames('GATING_INSPECTION_RESULT_CODE');
    setOkFlagCode(okflagCode);
  };

  /**
   * 팝업 오픈시 조회
   */
  useEffect(() => {
    handleSearchView(props.initParam);
  }, []);

  /**
   * 팝업 정보 조회
   * @param condition
   */
  const handleSearchView = async (condition) => {
    const response = await getGatingCheckResultMst({
      ...condition,
    });
    const responseEquip = await retrieveGtngEquipN({
      ...condition,
    });
    const responseCheckList = await getGatingCheckResultItemList({
      ...condition,
    });
    // 취소, 취소요청인 경우
    isCancel.current = response?.progressStatusCode === 'E' || response?.cancelRequestYn === 'Y';
    // 단계변경 요청인 경우
    isStatusRequest.current = response?.statusRequestYn === 'Y';

    setRowData(response);
    setRowDataEquip(responseEquip);
    setRowDataCheckList(responseCheckList[0]);
  };

  const handleSaveView = useEvent(() => {
    const saveDataCheckList = [] as GatingCheckResultItem[];
    if (rowDataCheckList) {
      saveDataCheckList.push({
        ...rowDataCheckList,
        crudKey: CrudCode.UPDATE,
      });
    }
    //조건 변경
    const condition = {
      gatingId: props.initParam?.gatingId,
      equipmentId: props.initParam?.equipmentId,
      gatingCheckItemId: rowDataCheckList?.gatingCheckItemId,
    };

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        saveGtngCheckItem(saveDataCheckList).then((response) => {
          if (response != null && response.successOrNot == 'N') {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return;
          }
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          handleSearchView(condition);
        });
      },
    });
  });

  /**
   * confirm
   */
  const handleSaveConfirm = useEvent(() => {
    let propateScoreVal = 0;
    let code;
    propateScoreVal =
      ((toNumber(rowDataCheckList?.nevaluationScore) * 1) /
        toNumber(rowDataCheckList?.prorateScore)) *
      100;

    if (toNumber(rowDataCheckList?.goodBasisScore) <= propateScoreVal) {
      code = 'OK';
    } else if (toNumber(rowDataCheckList?.levelScore) <= propateScoreVal) {
      code = 'C-OK';
    } else {
      code = 'NG';
    }

    //B3 :  최종점검 validation check
    if (rowDataCheckList?.ycpltYn == 'Y' && rowDataEquip?.progressStatusCode == 'B3') {
      if (
        _.isNull(rowDataCheckList?.yevaluationScore) ||
        rowDataCheckList?.yevaluationScore == ''
      ) {
        openMessageBar({
          type: 'error',
          content: t('gtng.msg.최종점검 점수를 입력해주세요.', '최종점검 점수를 입력해주세요.'),
        });
        return;
      }
      if (!rowDataCheckList?.yinspectionResultCode) {
        openMessageBar({
          type: 'error',
          content: t('gtng.msg.최종점검 OK여부를 선택해주세요.', '최종점검 OK여부를 선택해주세요.'),
        });
        return;
      }

      // 등급 C : Critical , R : 필수 , O : 선택
      // C 합격이 아니면 다음 진행 단계 불가능 하게
      // 조건부 합격은 점검결과 내용 입력 하도록
      // 환산점수로 나온 ok 여부를 사용자가 변경했을때도 점검 결과는 필수 입력
      // 원래 값과 다른 결과를 선택하는 경우 사유 적도록 안내

      if (rowDataCheckList?.gatingCheckItemGradeCode == 'C') {
        if (
          rowDataCheckList?.yinspectionResultCode == 'C-OK' &&
          _.isEmpty(rowDataCheckList?.ycheckResultContent)
        ) {
          openMessageBar({
            type: 'error',
            content: t('gtng.msg.점검결과를 입력해주세요.', '점검결과를 입력해주세요.'),
          });
          return;
        }
      }
      if (code == 'NG' || code == 'C-OK' || code == 'OK') {
        if (
          code != rowDataCheckList?.yinspectionResultCode &&
          _.isEmpty(rowDataCheckList?.ycheckResultContent)
        ) {
          openMessageBar({
            type: 'error',
            content: t('gtng.msg.점검결과를 입력해주세요.', '점검결과를 입력해주세요.'),
          });
          return;
        }
      }
    }
    //B2 : 중간점검 validation check
    if (rowDataCheckList?.ncpltYn == 'Y' && rowDataEquip?.progressStatusCode == 'B2') {
      if (
        rowDataCheckList?.gatingCheckItemGradeCode != 'O' &&
        toNumber(rowDataCheckList?.fileCountTl) == 0
      ) {
        openMessageBar({
          type: 'error',
          content: t(
            'gtng.msg.중간점검 첨부파일을 등록해주세요.',
            '중간점검 첨부파일을 등록해주세요.'
          ),
        });
        return;
      }
      if (
        _.isNull(rowDataCheckList?.nevaluationScore) ||
        rowDataCheckList?.nevaluationScore == ''
      ) {
        openMessageBar({
          type: 'error',
          content: t('gtng.msg.중간점검 점수를 입력해주세요.', '중간점검 점수를 입력해주세요.'),
        });
        return;
      }
      if (!rowDataCheckList?.ninspectionResultCode) {
        openMessageBar({
          type: 'error',
          content: t('gtng.msg.중간점검 OK여부를 선택해주세요.', '중간점검 OK여부를 선택해주세요.'),
        });
        return;
      }

      // 등급 C : Critical , R : 필수 , O : 선택
      // C 합격이 아니면 다음 진행 단계 불가능 하게
      // 조건부 합격은 점검결과 내용 입력 하도록
      // 환산점수로 나온 ok 여부를 사용자가 변경했을때도 점검 결과는 필수 입력
      // 원래 값과 다른 결과를 선택하는 경우 사유 적도록 안내

      if (rowDataCheckList?.gatingCheckItemGradeCode == 'C') {
        if (
          rowDataCheckList?.ninspectionResultCode == 'C-OK' &&
          !rowDataCheckList?.ncheckResultContent
        ) {
          openMessageBar({
            type: 'error',
            content: t('gtng.msg.점검결과를 입력해주세요.', '점검결과를 입력해주세요.'),
          });
          return;
        }
      }
      if (code == 'NG' || code == 'C-OK' || code == 'OK') {
        if (
          code != rowDataCheckList?.ninspectionResultCode &&
          !rowDataCheckList?.ncheckResultContent
        ) {
          openMessageBar({
            type: 'error',
            content: t('gtng.msg.점검결과를 입력해주세요.', '점검결과를 입력해주세요.'),
          });
          return;
        }
      }
    }

    //조건 변경
    const condition = {
      gatingId: props.initParam?.gatingId,
      equipmentId: props.initParam?.equipmentId,
      gatingCheckItemId: rowDataCheckList?.gatingCheckItemId,
    };

    openCommonModal({
      modalType: 'confirm',
      content: t('gtng.msg.점검완료하시겠습니까?', '점검완료하시겠습니까?'),
      yesCallback: async () => {
        if (rowDataEquip?.progressStatusCode == 'B2' && rowDataCheckList != null) {
          rowDataCheckList.ncpltYn = 'Y' || '';
        }
        if (rowDataEquip?.progressStatusCode == 'B3' && rowDataCheckList != null) {
          rowDataCheckList.ycpltYn = 'Y' || '';
        }
        const saveDataCheckList = [] as GatingCheckResultItem[];
        if (rowDataCheckList) {
          saveDataCheckList.push({
            ...rowDataCheckList,
            crudKey: CrudCode.UPDATE,
          });
        }
        saveGtngCheckItem(saveDataCheckList).then((response) => {
          if (response != null && response.successOrNot == 'N') {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return;
          }
          openMessageBar({
            type: 'confirm',
            content: t('com.label.점검 완료되었습니다.', '점검 완료되었습니다.'),
          });
          handleSearchView(condition);
        });
      },
    });
  });

  /**
   * 첨부파일
   */
  const handleFileUpload = (atchFileTpCd: 'GTNG_S_RST' | 'GTNG_F_RST') => {
    /*
    [24.03.12] 첨부파일 업로드 후 재조회 로직 삭제 -> 편집하던 데이터가 사라지지 않기땜에 저장 불필요
    if (rowDataCheckList) {
      saveDataCheckList[0] = rowDataCheckList;
    }

    saveDataCheckList[0].crudKey = CrudCode.UPDATE;

    saveGtngCheckItem(saveDataCheckList).then((response) => {
      if (response != null && response.successOrNot == 'N') {
        // console.log('---------저장중오류가발행했습니다.');
      }
    });
    */

    let downloadOnly = true;
    let fileCount = 0;
    // 중간점검 결과 > 중간점검첨부
    if ('GTNG_S_RST' === atchFileTpCd) {
      downloadOnly =
        rowDataEquip?.progressStatusCode !== 'B2' ||
        rowDataCheckList?.neditableFlag !== 'Y' ||
        isCancel.current ||
        isStatusRequest.current;
      fileCount = Number(rowDataCheckList?.fileCountTl || 0);
    }
    // 최종점검 결과 > 최종점검첨부
    else if ('GTNG_F_RST' === atchFileTpCd) {
      downloadOnly =
        rowDataEquip?.progressStatusCode !== 'B3' ||
        rowDataCheckList?.yeditableFlag !== 'Y' ||
        isCancel.current ||
        isStatusRequest.current;
      fileCount = Number(rowDataCheckList?.fileCountPmo || 0);
    }

    // [24.03.12] 다운로드할 첨부파일이 없는 경우 팝업 미노출
    if (downloadOnly && fileCount < 1) {
      return;
    }

    setInitParamFileUpload({
      atchFileGrId: rowDataCheckList?.atchFileGrId,
      atchFileTpCd: atchFileTpCd,
      tableName: 'tb_elm_gtng_equip_n',
      bizName: 'gtng',
      downloadOnly: downloadOnly,
    });
    setOpenFileUploadPopUp(true);
  };

  //다음 아이템 조회
  const handleOnNext = (param) => {
    // 현재 idx
    const itemIdx = props.initParam?.rowItemList.findIndex(
      (o) => o.gatingCheckItemId == rowDataCheckList?.gatingCheckItemId
    );
    // next 인지 prev 인지
    const idx = param == 'next' ? itemIdx + 1 : itemIdx - 1;

    if (param === 'prev' && idx < 0) {
      // prev & idx가 0 인경우 마지막 아이템 번호 대입
      return;
      // idx = 0; //props.initParam?.rowItemList.length - 1;
    } else if (param === 'next' && idx === props.initParam?.rowItemList.length) {
      // next & idx 마지막 인경우 첫 아이템 번호 대입
      return;
      // idx = props.initParam?.rowItemList.length - 1;
    }

    // 다음 게이팅 아이템 id
    const itemId = props.initParam?.rowItemList.find((o, i) => i == idx).gatingCheckItemId;

    //조건 변경
    const condition = {
      gatingId: props.initParam?.gatingId,
      equipmentId: props.initParam?.equipmentId,
      gatingCheckItemId: itemId,
    };
    handleSearchView(condition);
  };

  /**
   * 점수 변경 이벤트
   * @param value     점수
   * @param isFinal   최종점검여부 (false: 중간점검, true: 최종점검)
   */
  const handleChangeEvaluationScore = (value, isFinalStep: boolean) => {
    // 점수 속성명 (nevaluationScore: 중간점검>점수, yevaluationScore: 최종점검>점수)
    const colScore = isFinalStep ? 'yevaluationScore' : 'nevaluationScore';
    // 결과 속성명 (ninspectionResultCode: 중간점검>OK여부, yinspectionResultCode: 최종점검>OK여부)
    const colResult = isFinalStep ? 'yinspectionResultCode' : 'ninspectionResultCode';
    const newValue = parseFloat(value);
    if (isNaN(newValue)) {
      setRowDataCheckList({
        ...rowDataCheckList,
        [colScore]: '',
      });
      return;
    }

    const prorateScore = toNumber(rowDataCheckList?.prorateScore); // 배점
    if (prorateScore < newValue) {
      openMessageBar({
        type: 'error',
        content: `${t('gtng.msg.기준 배점', '기준 배점')} ( ${prorateScore} ) ${t(
          'gtng.msg.이하로 입력하세요.',
          '이하로 입력하세요.'
        )}`,
      });

      setRowDataCheckList({
        ...rowDataCheckList,
        [colScore]: '',
      });
      return;
    }

    const resultCode = getInspectionResultCode(
      newValue,
      prorateScore,
      rowDataCheckList?.goodBasisScore,
      rowDataCheckList?.levelScore
    );

    setRowDataCheckList((prev) => ({
      ...prev,
      [colResult]: resultCode,
      [colScore]: value,
    }));
  };

  const dialogButtons = useMemo(() => {
    if (isCancel.current || isStatusRequest.current || 'B4' === rowDataEquip?.progressStatusCode) {
      return null;
    }
    if (['B1', 'B2', 'B3'].includes(rowDataEquip?.progressStatusCode || '')) {
      return [
        <Button
          key={'save'}
          className="draft"
          onClick={handleSaveView}
          disabled={!activeSave}
          disableRipple
        >
          {t('com.button.임시저장', '임시저장')}
        </Button>,
        <Button
          key={'confirm'}
          css={IconButton.button}
          className="confirm"
          disabled={!activeConfirm}
          onClick={handleSaveConfirm}
          disableRipple
        >
          {t('gtng.button.점검완료', '점검완료')}
        </Button>,
      ];
    }
    return null;
  }, [rowData?.progressStatusCode, rowDataEquip?.progressStatusCode, activeSave, activeConfirm]);

  return (
    <>
      <CustomDialog
        title={t('gtng.label.평가/채점(Advice) 상세', '평가/채점(Advice) 상세')}
        open={true}
        size={'xl'}
        buttons={dialogButtons}
        onClose={handleOnClose}
        onCancel={
          !rowData?.progressStatusCode ||
          isCancel.current ||
          isStatusRequest.current ||
          'B4' === rowDataEquip?.progressStatusCode
            ? undefined
            : handleOnClose
        }
      >
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('gtng.label.Gating 정보', 'Gating 정보')}</h3>
          </SubTitleGroup>
        </SubTitleLayout>
        <TableContainer css={tb.table}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('gtng.label.Gating Task명', 'Gating Task명')}</TableCell>
                <TableCell className="col31">{rowData?.gatingName}</TableCell>
                <TableCell>{t('gtng.label.Gating 단계', 'Gating 단계')}</TableCell>
                <TableCell className="col31">{rowData?.gatingStepName}</TableCell>
                <TableCell>{t('gtng.label.진행단계', '진행단계')}</TableCell>
                <TableCell className="col31"> {rowDataEquip?.progressStatusName}</TableCell>
                <TableCell>{t('gtng.label.점검일자', '점검일자')}</TableCell>
                <TableCell>{gatingPeriod}</TableCell>
                <TableCell>{t('gtng.label.점검담당자', '점검담당자')}</TableCell>
                <TableCell>
                  <CustomInputWrap>
                    <span style={{ marginLeft: '20px' }}>{rowData?.contCheckName}</span>
                    <Button
                      className="buttonFind"
                      disableRipple
                      style={{ left: 0 }}
                      onClick={() => {
                        setContUserIds(rowData?.contCheckIds || '');
                        setOpenContEmpPopUp(true);
                      }}
                    ></Button>
                  </CustomInputWrap>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('gtng.label.실시(T/L)', '실시(T/L)')}</TableCell>
                <TableCell>
                  <CustomInputWrap>
                    <span style={{ marginLeft: '20px' }}>{rowData?.contExecuteTlName}</span>
                    <Button
                      className="buttonFind"
                      disableRipple
                      style={{ left: 0 }}
                      onClick={() => {
                        setContUserIds(rowData?.contExecuteTlIds || '');
                        setOpenContEmpPopUp(true);
                      }}
                    ></Button>
                  </CustomInputWrap>
                </TableCell>
                <TableCell>{t('gtng.label.실시담당자', '실시담당자')}</TableCell>
                <TableCell>
                  <CustomInputWrap>
                    <span style={{ marginLeft: '20px' }}>{rowData?.contExecuteName}</span>
                    <Button
                      className="buttonFind"
                      disableRipple
                      style={{ left: 0 }}
                      onClick={() => {
                        setContUserIds(rowData?.contExecuteIds || '');
                        setOpenContEmpPopUp(true);
                      }}
                    ></Button>
                  </CustomInputWrap>
                </TableCell>
                <TableCell>{t('gtng.label.점검장비', '점검장비')}</TableCell>
                <TableCell>
                  <CustomInputWrap>
                    <span style={{ marginLeft: '20px' }}>{rowData?.gatingEquipName}</span>
                    <Button
                      className="buttonFind"
                      disableRipple
                      style={{ left: 0 }}
                      onClick={() => {
                        setOpenEquipmentPopUp(true);
                      }}
                    ></Button>
                  </CustomInputWrap>
                </TableCell>
                <TableCell>{t('com.label.진행상태', '진행상태')}</TableCell>
                <TableCell colSpan={3}>{rowData && rowData?.progressStatusName2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('com.label.설비', '설비')}</TableCell>
                <TableCell colSpan={3}>{rowData?.equipmentName}</TableCell>
                <TableCell>{t('com.label.설비 ID', '설비 ID')}</TableCell>
                <TableCell>{rowDataEquip?.equipmentId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Divider>
          <Button className="left" disableRipple onClick={() => handleOnNext('prev')} />
          <Button className="right" disableRipple onClick={() => handleOnNext('next')} />
        </Divider>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>Gating Information</h3>
          </SubTitleGroup>
        </SubTitleLayout>
        <TableContainer css={tb.table}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  {t('gtng.label.Gating Sheet No', 'Gating Sheet No')}
                </TableCell>
                <TableCell width="200">{rowDataCheckList?.subSeqNo}</TableCell>
                <TableCell>{t('gtng.grid.등급', '등급')}</TableCell>
                <TableCell colSpan={2}>{rowDataCheckList?.gatingCheckItemGradeName}</TableCell>
                <TableCell>{t('gtng.label.완료여부', '완료여부')}</TableCell>
                <TableCell colSpan={2}>
                  {rowDataEquip?.progressStatusCode === 'B2'
                    ? rowDataCheckList?.ncpltYn
                    : rowDataEquip?.progressStatusCode === 'B3'
                    ? rowDataCheckList?.ycpltYn
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>{t('gtng.grid.점검구분1', '점검구분1')}</TableCell>
                <TableCell className="col3">{rowDataCheckList?.gatingType1Value}</TableCell>
                <TableCell>{t('gtng.grid.점검구분2', '점검구분2')}</TableCell>
                <TableCell colSpan={2} className="col3">
                  {rowDataCheckList?.gatingType2Value}
                </TableCell>
                <TableCell>{t('gtng.grid.점검구분3', '점검구분3')}</TableCell>
                <TableCell className="col3">{rowDataCheckList?.gatingType3Value}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="row" rowSpan={3}>
                  {t('gtng.label.점검항목', '점검항목')}
                </TableCell>
                <TableCell className="odd">{t('gtng.label.Check List', 'Check List')}</TableCell>
                <TableCell className="noBg" colSpan={6}>
                  {rowDataCheckList?.gatingCheckItem1Value}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="odd">
                  {t('gtng.label.Check 방법/계측/검사', 'Check 방법/계측/검사')}
                </TableCell>
                <TableCell className="noBg" colSpan={6}>
                  {rowDataCheckList?.gatingCheckItem3Value}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="odd">
                  {t('gtng.label.판단기준/Spec/목표', '판단기준/Spec/목표')}
                </TableCell>
                <TableCell className="noBg" colSpan={6}>
                  {rowDataCheckList?.gatingCheckItem3Value}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="150" className="row" rowSpan={3} style={{ borderBottom: 'none' }}>
                  {t('com.label.담당자', '담당자')}
                </TableCell>
                <TableCell width="150" className="odd">
                  {t('gtng.label.실시담당자', '실시담당자')}
                </TableCell>
                <TableCell className="noBg">
                  <CustomInputWrap>
                    <span style={{ marginLeft: '20px' }}>
                      {rowDataCheckList?.actionContactName}
                    </span>
                    <Button
                      className="buttonFind"
                      disableRipple
                      style={{ left: 0 }}
                      onClick={() => {
                        // TODO 담당자 변경 로직 추가
                        setContUserIds(rowDataCheckList?.actionContactId || '');
                        setOpenContEmpPopUp(true);
                      }}
                    ></Button>
                  </CustomInputWrap>
                </TableCell>
                <TableCell className="oddnone" rowSpan={3} width="150">
                  {t('gtng.label.결과 기준', '결과 기준')}
                </TableCell>
                <TableCell className="odd" width="150">
                  {t('gtng.label.배점', '배점')}
                </TableCell>
                <TableCell className="noBg col3" style={{ textAlign: 'right' }}>
                  {rowDataCheckList?.prorateScore}
                </TableCell>
                <TableCell
                  className="odd row"
                  width="150"
                  rowSpan={3}
                  style={{ borderBottom: 'none' }}
                >
                  {t('com.label.비고', '비고')}
                </TableCell>
                <TableCell className="noBg col3" rowSpan={3} style={{ borderBottom: 'none' }}>
                  <Textarea
                    readOnly
                    style={{ height: '100%', borderStyle: 'none', backgroundColor: '#ffffff' }}
                  >
                    {rowDataCheckList?.noteContent}
                  </Textarea>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="odd">{t('gtng.label.점검담당자', '점검담당자')}</TableCell>
                <TableCell className="noBg">
                  <CustomInputWrap>
                    <span style={{ marginLeft: '20px' }}>{rowDataCheckList?.checkerName}</span>
                    <Button
                      className="buttonFind"
                      disableRipple
                      style={{ left: 0 }}
                      onClick={() => {
                        // TODO 담당자 변경 로직 추가
                        setContUserIds(rowDataCheckList?.checkerId || '');
                        setOpenContEmpPopUp(true);
                      }}
                    ></Button>
                  </CustomInputWrap>
                </TableCell>
                <TableCell className="odd">{t('gtng.label.OK 기준', 'OK 기준')}</TableCell>
                <TableCell className="noBg" style={{ textAlign: 'right' }}>
                  {rowDataCheckList?.goodBasisScore}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="odd">{t('gtng.label.실시(T/L)', '실시(T/L)')}</TableCell>
                <TableCell className="noBg">
                  <CustomInputWrap>
                    <span style={{ marginLeft: '20px' }}>
                      {rowDataCheckList?.completionCheckerName}
                    </span>
                    <Button
                      className="buttonFind"
                      disableRipple
                      style={{ left: 0 }}
                      onClick={() => {
                        // TODO 담당자 변경 로직 추가
                        setContUserIds(rowDataCheckList?.completionCheckerId || '');
                        setOpenContEmpPopUp(true);
                      }}
                    ></Button>
                  </CustomInputWrap>
                </TableCell>
                <TableCell className="odd">{t('gtng.label.C-OK 기준', 'C-OK 기준')}</TableCell>
                <TableCell className="noBg" style={{ textAlign: 'right' }}>
                  {rowDataCheckList?.levelScore}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <HalfContetntLayout style={{ marginTop: '24px' }}>
          <div>
            <SubTitleLayout>
              <SubTitleGroup>
                <h3>{t('gtng.label.중간점검', '중간점검')}</h3>
              </SubTitleGroup>
            </SubTitleLayout>
            <TableContainer css={tb.table}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('gtng.label.점수', '점수')}</TableCell>
                    <TableCell>
                      <CustomInputWrap>
                        <CustomInputText
                          className="right"
                          readOnly={
                            isCancel.current ||
                            isStatusRequest.current ||
                            rowDataEquip?.progressStatusCode !== 'B2' ||
                            rowDataCheckList?.neditableFlag !== 'Y'
                          }
                          value={rowDataCheckList?.nevaluationScore || ''}
                          onChange={(e) => handleChangeEvaluationScore(e.target.value, false)}
                        />
                      </CustomInputWrap>
                    </TableCell>

                    <TableCell>{t('gtng.label.환산점수', '환산점수')}</TableCell>
                    <TableCell>
                      <CustomInputWrap>
                        <CustomInputText
                          className="right"
                          value={convertPercentScore(
                            toNumber(rowDataCheckList?.nevaluationScore || 0),
                            toNumber(rowDataCheckList?.prorateScore || 0)
                          )}
                          readOnly
                        />
                      </CustomInputWrap>
                    </TableCell>
                    <TableCell>{t('gtng.label.첨부', '첨부')}</TableCell>
                    <TableCell style={{ width: '100px', textAlign: 'center' }}>
                      <FileuploadBtn onClick={() => handleFileUpload('GTNG_S_RST')}>
                        <span>{toNumber(rowDataCheckList?.fileCountTl || 0).toLocaleString()}</span>
                      </FileuploadBtn>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('gtng.label.OK여부', 'OK여부')}</TableCell>
                    <TableCell style={{ width: '150px' }}>
                      <ComboBox
                        options={okFlagCode}
                        placeholder={String(t('com.label.선택', '선택'))}
                        defaultValue={rowDataCheckList?.ninspectionResultCode}
                        disabled={
                          rowDataEquip?.progressStatusCode !== 'B2' ||
                          isCancel.current ||
                          isStatusRequest.current
                        }
                        onChange={(value) => {
                          setRowDataCheckList({
                            ...rowDataCheckList,
                            ninspectionResultCode: value,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>{t('gtng.label.점검일자', '점검일자')}</TableCell>
                    <TableCell colSpan={3}>{rowDataCheckList?.nlastUpdatedByName || ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('gtng.label.점검결과', '점검결과')}</TableCell>
                    <TableCell colSpan={5}>
                      <Textarea
                        className="popup"
                        readOnly={
                          rowDataEquip?.progressStatusCode !== 'B2' ||
                          isCancel.current ||
                          isStatusRequest.current
                        }
                        value={rowDataCheckList?.ncheckResultContent || ''}
                        maxLength={500}
                        onChange={(e) => {
                          setRowDataCheckList({
                            ...rowDataCheckList,
                            ncheckResultContent: e.target.value || '',
                          });
                        }}
                      ></Textarea>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div>
            <SubTitleLayout>
              <SubTitleGroup>
                <h3>{t('gtng.label.최종점검', '최종점검')}</h3>
              </SubTitleGroup>
            </SubTitleLayout>
            <TableContainer css={tb.table}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('gtng.label.점수', '점수')}</TableCell>
                    <TableCell>
                      <CustomInputWrap>
                        <CustomInputText
                          className="right"
                          readOnly={
                            rowDataEquip?.progressStatusCode !== 'B3' ||
                            isCancel.current ||
                            isStatusRequest.current
                          }
                          value={rowDataCheckList?.yevaluationScore || ''}
                          onChange={(e) => handleChangeEvaluationScore(e.target.value, true)}
                        />
                      </CustomInputWrap>
                    </TableCell>
                    <TableCell>{t('gtng.label.환산점수', '환산점수')}</TableCell>
                    <TableCell>
                      <CustomInputWrap>
                        <CustomInputText
                          className="right"
                          value={convertPercentScore(
                            toNumber(rowDataCheckList?.yevaluationScore || 0),
                            toNumber(rowDataCheckList?.prorateScore || 0)
                          )}
                          readOnly
                        />
                      </CustomInputWrap>
                    </TableCell>
                    <TableCell>{t('gtng.label.첨부', '첨부')}</TableCell>
                    <TableCell style={{ width: '100px', textAlign: 'center' }}>
                      <FileuploadBtn onClick={() => handleFileUpload('GTNG_F_RST')}>
                        <span>
                          {toNumber(rowDataCheckList?.fileCountPmo || 0).toLocaleString()}
                        </span>
                      </FileuploadBtn>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('gtng.label.OK여부', 'OK여부')}</TableCell>
                    <TableCell style={{ width: '150px' }}>
                      <ComboBox
                        options={okFlagCode}
                        placeholder={String(t('com.label.선택', '선택'))}
                        defaultValue={rowDataCheckList?.yinspectionResultCode}
                        disabled={
                          rowDataEquip?.progressStatusCode !== 'B3' ||
                          isCancel.current ||
                          isStatusRequest.current
                        }
                        onChange={(params) => {
                          setRowDataCheckList({
                            ...rowDataCheckList,
                            yinspectionResultCode: params || '',
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>{t('gtng.label.점검일자', '점검일자')}</TableCell>
                    <TableCell colSpan={3}>{rowDataCheckList?.ylastUpdatedByName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('gtng.label.점겸결과', '점겸결과')}</TableCell>
                    <TableCell colSpan={2}>
                      <Textarea
                        className="popup"
                        maxLength={500}
                        readOnly={
                          rowDataEquip?.progressStatusCode !== 'B3' ||
                          isCancel.current ||
                          isStatusRequest.current
                        }
                        value={rowDataCheckList?.ycheckResultContent || ''}
                        onChange={(e) => {
                          setRowDataCheckList({
                            ...rowDataCheckList,
                            ycheckResultContent: e.target.value || '',
                          });
                        }}
                      ></Textarea>
                    </TableCell>
                    <TableCell>{t('gtng.label.Advice', 'Advice')}</TableCell>
                    <TableCell colSpan={2}>
                      <Textarea
                        className="popup"
                        readOnly={
                          rowDataEquip?.progressStatusCode !== 'B3' ||
                          isCancel.current ||
                          isStatusRequest.current
                        }
                        maxLength={500}
                        value={rowDataCheckList?.ypmoComment || ''}
                        onChange={(e) => {
                          setRowDataCheckList({
                            ...rowDataCheckList,
                            ypmoComment: e.target.value || '',
                          });
                        }}
                      ></Textarea>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </HalfContetntLayout>
      </CustomDialog>
      {/* 담당자 팝업 */}
      {isOpenContEmpPopUp && (
        <GatingContListPopUp
          open={isOpenContEmpPopUp}
          setReadonly={true}
          initParam={getContUserIds}
          close={() => setOpenContEmpPopUp(false)}
        />
      )}
      {/* 설비팝업 */}
      {isOpenEquipmentPopUp && (
        <GatingEquipmentListPopUp
          open={isOpenEquipmentPopUp}
          condition={{ gatingId: rowData?.gatingId || '' }}
          close={() => setOpenEquipmentPopUp(false)}
          onCallback={() => setOpenEquipmentPopUp(false)}
        />
      )}
      {/* 첨부파일팝업 */}
      {isOpenFileUploadPopUp && (
        <FileUploadPopUp
          open={isOpenFileUploadPopUp}
          close={() => setOpenFileUploadPopUp(false)}
          downloadOnly={initParamFileUpload?.downloadOnly}
          initParam={{
            atchFileGrId: initParamFileUpload?.atchFileGrId,
            atchFileTpCd: initParamFileUpload?.atchFileTpCd,
            optValCtn1: initParamFileUpload?.tableName,
            bizName: initParamFileUpload?.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            // 중간점검 결과 > 중간점검첨부
            if ('GTNG_S_RST' === initParamFileUpload?.atchFileTpCd) {
              setRowDataCheckList((prev) => ({ ...prev, fileCountTl: fileCount?.toString() }));
            }
            // 최종점검 결과 > 최종점검첨부
            else if ('GTNG_F_RST' === initParamFileUpload?.atchFileTpCd) {
              setRowDataCheckList((prev) => ({ ...prev, fileCountPmo: fileCount?.toString() }));
            }
            setOpenFileUploadPopUp(false);
            /*
                // [24.03.12] 변경된 첨부파일 수 재조회를 위해 사용한 것으로 보임
                handleSearchView({
                  gatingId: props.initParam?.gatingId,
                  equipmentId: props.initParam?.equipmentId,
                  gatingCheckItemId: rowDataCheckList?.gatingCheckItemId,
                });
                */
          }}
        />
      )}
    </>
  );
};
