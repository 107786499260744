/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactNode } from 'react';

interface MainLayoutProps {
  children: ReactNode;
}

export const SubLayout = ({ children }: MainLayoutProps) => {
  return (
    <div css={st.root}>
      {/* <LeftNavigationBar models={SystemAccModels} /> */}
      {children}
    </div>
  );
};

const st = {
  root: css`
    margin-top: 52px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    height: calc(100vh - 52px) !important;
    overflow: hidden;
  `,
};
