/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/buttons/IconSVG';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { tb } from 'components/layouts/Table';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import {
  SensorApprovalRequest,
  SensorMasterBase,
  SensorRequestCondition,
} from 'models/sensor/SensorRequest';
import { uploadExcelTemplates } from 'apis/common/Excel';
import { createSensorRequest, findSensorReqList } from 'apis/sensor/popup/SensorReq';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import Dropzone from 'components/dropzone/Dropzone';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { GlobalBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { PaginationRef } from 'components/layouts/Pagination';
import SensorMasterGrid from 'pages/sensor/base/sensormanagement/SensorMasterGrid';
import ExcelValidModal from 'components/modals/common/ExcelValidModal';
import { generateApprovalRequestId } from 'apis/ip/EquipmentParameter';
import { ManagementMode } from 'models/common/Common';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';

interface SensorManagementPageLocationState {
  equipmentGroup?: string;
  eqpParaNo?: string;
  versionNo?: string;
  condition?: any;
  pageNo?: number;
  mode: ManagementMode;
}

const SensorManagementPage = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const locationState: SensorManagementPageLocationState = useLocation().state;
  const [reqData, setReqData] = useState<SensorApprovalRequest>({
    aprTplId: 'SEC-FORM-0001', // 고정
    aprPvnDdlnCd: 'APPRCODE_1M',
  } as SensorApprovalRequest);
  const reqGridRef = useRef<any>(null);

  // Excel Vaild Modal
  const [ExcelValidModalOpen, setExcelValidModalOpen] = useState<boolean>(false);
  const [excelValidCondition, setExcelValidCondition] = useState<string | null>(null);
  const [rowData, setRowData] = useState<SensorMasterBase[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(
    locationState?.condition && locationState?.condition?.pageSize
      ? Number(locationState.condition.pageSize)
      : 30
  );
  const paginationRef = useRef<PaginationRef>(null);
  const menuContext = useContext<MenuContextType>(MenuContext);

  useEffect(() => {
    generateApprovalRequestId().then((value) => {
      setReqData({ ...reqData, aprReqId: value });
    });
  }, []);

  /**
   * 임시저장 처리
   */
  const handleSave = () => {
    createSensorRequest({
      ...reqData,
      mode: locationState.mode,
      sensorReqList: rowData,
    }).then((result) => {
      openMessageBar({
        type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          result?.successOrNot === 'Y'
            ? t('com.label.저장되었습니다.', '저장되었습니다.')
            : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
      });
      if (result?.successOrNot === 'Y') {
        menuContext.closeCurrentTab(menuContext);
      }
    });
  };

  const handleSearch = async (condition: SensorRequestCondition, isFirst = false) => {
    const response = await findSensorReqList({
      ...condition,
      pageSize: !_.isEmpty(condition.pageSize) ? condition.pageSize : String(pageSize),
    });
    if (response) {
      const rowData = response.list || [];
      setRowData(rowData);
      setTotalCount(response.totalCount || 0);
      if (isFirst) {
        paginationRef.current?.setSelectNo(1);
      }
    }
  };

  const uploadTemplate = (file) => {
    openLoading(true);
    uploadExcelTemplates(
      [file],
      'TPL_SNSR_LIST',
      'NORMAL',
      'TB_ELM_SNSR_REQ_N',
      locationState.mode,
      reqData?.aprReqId,
      reqData?.aprTplId,
      reqData?.aprPvnDdlnCd,
      reqData?.aprReqTitNm,
      reqData?.trnmBdyCtn
    )
      .then((result) => {
        openLoading(false);

        if (result?.successOrNot === 'Y') {
          if (result?.data.x_result == 'OK') {
            reqData.aprReqId = result?.data.p_apr_req_id;
            handleSearch(reqData);
          } else {
            setExcelValidCondition(result?.data.p_xls_upload_id);
            setExcelValidModalOpen(true);
            return;
          }
        }

        const data = typeof result?.data === 'string' ? result?.data : null;
        openMessageBar({
          type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
          content:
            result?.successOrNot === 'Y'
              ? t('snsr.msg.검증이 완료되었습니다.', '검증이 완료되었습니다.')
              : data || t('snsr.msg.검증 중 오류가 발생했습니다.', '검증 중 오류가 발생했습니다.'),
        });
        if (result?.successOrNot === 'Y') {
          handleSearch(reqData);
        }
      })
      .finally(() => openLoading(false));
  };

  return (
    <>
      <ContainerLayout>
        <TableContainer css={tb.table}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('snsr.label.파일 선택', '파일 선택')}</TableCell>
                <TableCell>
                  <Dropzone
                    singleSelect={true}
                    onChange={(files) => {
                      if (files && files.length > 0) {
                        const file = files[0].file;
                        if (file) {
                          if ((rowData || []).length) {
                            openCommonModal({
                              modalType: 'confirm',
                              content: t(
                                'com.label.업로드된 데이터가 존재합니다.\n새로 업로드 하시겠습니까?',
                                '업로드된 데이터가 존재합니다.\n새로 업로드 하시겠습니까?'
                              ),
                              yesCallback: () => {
                                uploadTemplate(file);
                              },
                            });
                            return;
                          }
                          uploadTemplate(file);
                        }
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('snsr.label.센서 등록/수정 요청 List', '센서 등록/수정 요청 List')}</h3>
            <span className="total">
              {t('com.label.총', '총')}
              <span>{totalCount.toLocaleString()}</span>
              {t('com.label.건', '건')}
            </span>
            {/*<PaginationSize*/}
            {/*  defaultPageSize={pageSize}*/}
            {/*  onChange={(value) => {*/}
            {/*    setPageSize(value);*/}
            {/*    paginationRef.current?.handlePageSizeChange(value);*/}
            {/*  }}*/}
            {/*/>*/}
            {/* 안내문 class 종류는 default,error,warning,confirm,primary가 있습니다. */}
            {/*<div className="info default">Info Text (Default)</div>*/}
          </SubTitleGroup>
        </SubTitleLayout>

        <SensorMasterGrid ref={reqGridRef} rowData={rowData} mode={locationState?.mode} />

        <GlobalBtnGroup>
          <Button css={IconButton.button} className="save" onClick={handleSave} disableRipple>
            {t('com.button.저장', '저장')}
          </Button>
        </GlobalBtnGroup>
      </ContainerLayout>
      {ExcelValidModalOpen && (
        <ExcelValidModal
          open={ExcelValidModalOpen}
          close={() => {
            setExcelValidCondition(null);
            setExcelValidModalOpen(false);
          }}
          condition={{
            valReqId: excelValidCondition || '',
          }}
        />
      )}
    </>
  );
};

export default SensorManagementPage;
