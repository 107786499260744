/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SearchButtonWrap } from 'components/layouts/SearchBox';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';

// 프로퍼티 타입 지정
SearchButtonGroup.propTypes = {
  name: PropTypes.string,
  reOnClick: PropTypes.any,
  searchOnClick: PropTypes.any,
  boxOnClick: PropTypes.any,
};

export default function SearchButtonGroup(props) {
  const { t } = useTranslation();
  const [isOpenSearchBox, setOpenSearchBox] = useState(false);

  const labelId = 'com.button.' + props.name;
  const labelText = '' + props.name;

  const handleOnChange = (e: any) => {
    null;
  };

  return (
    <SearchButtonWrap>
      <Button
        css={IconButton.button}
        className="reload"
        disableRipple
        onClick={props.reOnClick}
      ></Button>
      <Button css={IconButton.button} className="find" disableRipple onClick={props.searchOnClick}>
        <label>{t(labelId, labelText)}</label>
      </Button>
      {props.boxOnClick && (
        <Button
          css={IconButton.button}
          className="chevron"
          disableRipple
          onClick={props.boxOnClick}
        ></Button>
      )}
    </SearchButtonWrap>
  );
}
