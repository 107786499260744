/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { IconButton } from '../../components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from '../../components/layouts/SearchBox';
import { ComboBox } from '../../components/selects/ComboBox';
import { Code } from '../../models/common/CommonCode';
import { getCommonCodes } from '../../apis/admin/CommonCode';
import { useTranslation } from 'react-i18next';
import {
  SubTitleLayout,
  SubTitleGroup,
  GlobalBtnGroup,
  ControlBtnGroup,
} from '../../components/layouts/ContentLayout';
import { ContentGrid } from '../../components/layouts/ContentGrid';
import {
  CustomInputText,
  CustomInputWrap,
  CustomTextarea,
} from '../../components/inputs/CustomInput';
import { ExcpPartnerUser, ExcpPartnerUserSearchCondition } from '../../models/admin/ExcpPartner';
import {
  deleteSecrPldg,
  getExcpSecrPldgList,
  getExcpUserList,
  updateConfirmApproval,
  updateRejectApproval,
} from 'apis/admin/ExcpPartner';
import { hasRole } from 'utils/SessionUtil';
// Wijmo library
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CellType, CellRange, MergeManager } from '@grapecity/wijmo.grid';
import { getExcpList, compUserCount } from 'apis/admin/ExcpPartner';
import { useMessageBar } from 'hooks/useMessageBar';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { ManagementMode } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import CustomGrid from 'components/grids/CustomGrid';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import Datepicker, { DateRangeType } from 'react-tailwindcss-datepicker';
import ExcpUserRejectPopUp from './excpmanagement/ExcpUserRejectPopUp';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';
import { PartnerSecrPldg } from 'models/common/Partner';
import * as input from '@grapecity/wijmo.input';
import { ExcpSecrPldgPopUp } from './excpmanagement/ExcpSecrPldgPopUp';
import _ from 'lodash';

export interface SecrPldgCondtion {
  dataUpdUserId: string;
  secrPldgCtn: string;
}

const ExcpSecrPldgManagementPage = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [rowData, setRowData] = useState<PartnerSecrPldg[]>([]);
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [hasAuth, setAuth] = useState(false);
  const [isOpenExcpSecrPldgPopUp, setOpenExcpSecrPldgPopUp] = useState<boolean>(false);
  const [sercPldgCondition, setSercPldgCondition] = useState<any>({
    secrPldgSeq: '',
    secrPldgVer: '',
  });
  const [isReadOnly, setReadOnly] = useState<boolean>(false);
  const { openCommonModal } = useCommonModal();
  // const [handleRejectApprove, setHandleRejectApprove] = useState<string>('');
  const [searchParam, setSearchParam] = useState<SecrPldgCondtion>({
    dataUpdUserId: '',
    secrPldgCtn: '',
  });
  const { openMessageBar } = useMessageBar();

  const userAprvYnCondition: Code[] = [
    { cmnCd: 'N', cmnCdNm: '요청' },
    { cmnCd: 'Y', cmnCdNm: '승인완료' },
  ];

  const OnInitialized = (grid) => {
    setFlexRef(grid);
    new Selector(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      if (grid.rows[ht.row].isSelected == true) {
        grid.rows[ht.row].isSelected = false;
      } else {
        grid.rows[ht.row].isSelected = true;
      }

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        setflexItem(item);
        setHitTest(ht);
        switch (e.target.id) {
          case 'btnSecrPldg':
            setSercPldgCondition({
              secrPldgSeq: item.secrPldgSeq,
              secrPldgVer: item.secrPldgVer,
              mode: 'M',
            });
            if (!_.isNull(item.rflcDt)) {
              setReadOnly(true);
            } else {
              setReadOnly(false);
            }
            setOpenExcpSecrPldgPopUp(true);
            break;
        }
      }
    });
  };
  useEffect(() => {
    // 관리자 권한 여부
    setAuth(hasRole('ADM'));
  }, []);

  const handleSearchParam = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  };

  const handleDelete = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected === true);

    if (isSelected < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.보안서약서를 선택해 주세요.', '보안서약서를 선택해 주세요.'),
      });
      return false;
    }
    for (let i = 0; i < isSelected.length; i++) {
      if (!_.isNull(isSelected[i].dataItem.rflcDt)) {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.삭제할 수 없는 보안서약서입니다.',
            '삭제할 수 없는 보안서약서입니다.'
          ),
        });
        return false;
      }
    }

    openCommonModal({
      modalType: 'confirm',
      content: t(`com.msg.삭제하시겠습니까?`, `삭제하시겠습니까?`),
      yesCallback: async () => {
        const deleteData: PartnerSecrPldg[] = [];
        for (let i = 0; i < isSelected.length; i++) {
          deleteData.push(isSelected[i].dataItem);
        }
        console.log('deleteData', deleteData);
        deleteSecrPldg(deleteData).then((result) => {
          if (result !== null) {
            openMessageBar({
              type: 'confirm',
              content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
            });
            handleSearch();
          } else {
            openMessageBar({
              type: 'error',
              content: t('com.label.삭제에 실패하였습니다.', '삭제에 실패하였습니다.'),
            });
          }
        });
      },
    });
  };

  const handleResetCondition = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as SecrPldgCondtion
    );
  };

  //다운로드 버튼
  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'user';
    book.saveAsync(
      getExcelFileName(t('admin.label.협력업체보안서약서이력', '협력업체보안서약서이력'))
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSearch();
  };

  const handleSearch = async () => {
    await getExcpSecrPldgList(searchParam).then((result) => {
      console.log(result);
      if (result === null) {
        openMessageBar({
          type: 'error',
          content: t(
            'admin.label.조회 가능한 데이터가 없습니다.',
            '조회 가능한 데이터가 없습니다.'
          ),
        });
      }
      setRowData(result);
    });
  };

  const userApvrYnDataMap = new DataMap(userAprvYnCondition, 'cmnCd', 'cmnCdNm');

  const LayoutDefinition = () => {
    return [
      {
        binding: 'secrPldgSeq',
        visible: false,
      },
      {
        header: String(t('admin.label.반영일자', '반영일자')),
        binding: 'rflcDt',
        align: 'center',
        width: 150,
        format: 'yyyy.MM.dd',
        editor: new input.InputDate(document.createElement('div'), {
          format: 'yyyy.MM.dd',
        }),
      },
      {
        header: String(t('admin.label.보안서약완료', '보안서약완료')),
        width: 200,
        binding: 'secrPldgUserYn',
        align: 'center',
      },
      {
        header: String(t('admin.label.개정일자', '개정일자')),
        binding: 'revDt',
        width: 150,
        format: 'yyyy.MM.dd',
        editor: new input.InputDate(document.createElement('div'), {
          format: 'yyyy.MM.dd',
        }),
      },
      {
        header: String(t('admin.label.버전', '버전')),
        binding: 'secrPldgVer',
        align: 'center',
        width: 100,
        cssClass: 'WijmoPopup',
        cellTemplate: (item) => {
          return `<span>${item.value}</span><Button id="btnSecrPldg" />`;
        },
      },
      {
        header: String(t('admin.label.최종보안서약서', '최종보안서약서')),
        binding: 'finYn',
      },
      {
        header: String(t('admin.grid.설명', '설명')),
        binding: 'secrPldgDesc',
        align: 'left',
        width: '*',
      },
      {
        header: String(t('admin.grid.수정자', '수정자')),
        binding: 'dataUpdUserNm',
        width: 150,
      },
      {
        binding: 'dataUpdUserId',
        visible: false,
      },
      {
        header: String(t('admin.grid.수정일', '수정일')),
        binding: 'dataUpdDtm',
        width: 150,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const btnNewSecrPldg = () => {
    setSercPldgCondition({
      mode: 'C',
    });
    setReadOnly(false);
    setOpenExcpSecrPldgPopUp(true);
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('com.label.담당자', '담당자')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    name="dataUpdUserId"
                    value={searchParam.dataUpdUserId}
                    onChange={handleSearchParam}
                    onKeyDown={handleKeyDown}
                    placeholder={String(
                      t('com.label.담당자를 입력해 주세요.', '담당자를 입력해 주세요.')
                    )}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.보안서약 내용', '보안서약 내용')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    name="secrPldgCtn"
                    value={searchParam.secrPldgCtn}
                    onChange={handleSearchParam}
                    onKeyDown={handleKeyDown}
                    placeholder={String(
                      t(
                        'com.label.보안서약서 내용을 입력해 주세요.',
                        '보안서약서 내용을 입력해 주세요.'
                      )
                    )}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.보안서약서 목록', '보안서약서 목록')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={state.layoutDefinition}
        rowData={state.itemsSource}
        rowSelection="multiple"
        isReadOnly={true}
        align="center"
        initialized={OnInitialized}
        height={550}
      />
      <GlobalBtnGroup>
        <Button css={IconButton.button} className="delete" onClick={handleDelete} disableRipple>
          {t('com.label.삭제', '삭제')}
        </Button>
        <Button css={IconButton.button} className="create" onClick={btnNewSecrPldg} disableRipple>
          {t('com.button.신규등록', '신규등록')}
        </Button>
      </GlobalBtnGroup>
      {isOpenExcpSecrPldgPopUp && (
        <ExcpSecrPldgPopUp
          open={isOpenExcpSecrPldgPopUp}
          condition={sercPldgCondition}
          isReadOnly={isReadOnly}
          close={() => setOpenExcpSecrPldgPopUp(false)}
          useCallback={handleSearch}
        />
      )}
    </>
  );
};

export default ExcpSecrPldgManagementPage;
