import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ApprovalTemplate } from 'models/admin/ApprovalTemplate';
import { ContentGrid } from 'components/layouts/ContentGrid';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { IconButton } from 'components/buttons/IconSVG';
import { Button } from '@mui/material';
import { SubTitleLayout, SubTitleGroup, ControlBtnGroup } from 'components/layouts/ContentLayout';

interface paramData {
  aprTplNm: string;
  editable: boolean;
  callBackRowData?: any;
  rowData: ApprovalTemplate[];
}

const ApprovalTemplateManagementGrid = (props: paramData) => {
  const { t } = useTranslation();
  const [flexRef, setflexRef] = useState<any>();

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'aprTplId',
      header: String(t('admin.grid.양식ID', '양식ID')),
      width: 130,
      align: 'left',
    },
    {
      binding: 'intgAprTpCd',
      header: String(t('admin.grid.결재Type', '결재Type')),
      width: 110,
    },
    {
      binding: 'aprTplNm',
      header: String(t('admin.grid.양식명', '양식명')),
      align: 'left',
      width: '*',
    },
    {
      binding: 'useYn',
      header: String(t('admin.grid.사용여부', '사용여부')),
      width: 100,
    },
    {
      binding: 'aprTplDesc',
      header: String(t('admin.grid.결재양식설명', '결재양식설명')),
      visible: false,
    },
    {
      binding: 'prsDesc',
      header: String(t('admin.grid.프로세스 설명', '프로세스 설명')),
      visible: false,
    },
    {
      binding: 'wcstUseYn',
      header: String(t('admin.grid.동의서 사용여부', '동의서 사용여부')),
      visible: false,
    },
    {
      binding: 'wcstDesc',
      header: String(t('admin.grid.동의서 설명', '동의서 설명')),
      visible: false,
    },
    {
      binding: 'notfUseYn',
      header: String(t('admin.grid.통보처 사용여부', '통보처 사용여부')),
      visible: false,
    },
    {
      binding: 'ntdkId',
      header: String(t('admin.grid.통보처ID', '통보처ID')),
      visible: false,
    },
    {
      binding: 'mgrUseYn',
      header: String(t('admin.grid.담당자 사용여부', '담당자 사용여부')),
      visible: false,
    },
  ];

  // const columnDefs: ColDef[] = [
  //   {
  //     headerName: String(t('com.label.NO', 'NO')),
  //     field: 'No',
  //     width: 80,
  //     filter: false,
  //     editable: false,
  //     valueFormatter: (params: ValueFormatterParams) => {
  //       return `${parseInt(params.node!.id!) + 1}`;
  //     },
  //   },
  //   {
  //     headerName: String(t('admin.grid.양식ID', '양식ID')),
  //     field: 'aprTplId',
  //     width: 140,
  //   },
  //   {
  //     headerName: String(t('admin.grid.결재Type', '결재Type')),
  //     field: 'intgAprTpCd',
  //     width: 130,
  //   },
  //   {
  //     headerName: String(t('admin.grid.양식명', '양식명')),
  //     field: 'aprTplNm',
  //     flex: 1,
  //   },
  //   {
  //     headerName: String(t('admin.grid.사용여부', '사용여부')),
  //     field: 'useYn',
  //     width: 120,
  //   },
  //   {
  //     headerName: String(t('admin.grid.결재양식설명', '결재양식설명')),
  //     field: 'aprTplDesc',
  //     hide: true,
  //   },
  //   {
  //     headerName: String(t('admin.grid.프로세스 설명', '프로세스 설명')),
  //     field: 'prsDesc',
  //     hide: true,
  //   },
  //   {
  //     headerName: String(t('admin.grid.동의서 사용여부', '동의서 사용여부')),
  //     field: 'wcstUseYn',
  //     hide: true,
  //   },
  //   {
  //     headerName: String(t('admin.grid.동의서 설명', '동의서 설명')),
  //     field: 'wcstDesc',
  //     hide: true,
  //   },
  //   {
  //     headerName: String(t('admin.grid.통보처 사용여부', '통보처 사용여부')),
  //     field: 'notfUseYn',
  //     hide: true,
  //   },
  //   {
  //     headerName: String(t('admin.grid.통보처ID', '통보처ID')),
  //     field: 'ntdkId',
  //     hide: true,
  //   },
  //   {
  //     headerName: String(t('admin.grid.담당자 사용여부', '담당자 사용여부')),
  //     field: 'mgrUseYn',
  //     hide: true,
  //   },
  // ];

  const onInitialized = (grid) => {
    setflexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;
      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;
      props.callBackRowData && props.callBackRowData(item);
    });
  };

  const fnOnCellClicked = useCallback((event: any) => {
    props.callBackRowData && props.callBackRowData(event.data);
  }, []);

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'ApproveTemplateList';
    book.saveAsync(getExcelFileName(t('com.label.결재양식목록', '결재양식목록')));
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.결재양식', '결재양식')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span> {props.rowData ? props.rowData.length : 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>

      <CustomGrid
        layoutDefinition={layoutDefinition}
        initialized={onInitialized}
        isSelector={false}
        rowData={props.rowData}
        height={500}
        deferResizing={false}
        isReadOnly={true}
        align={'center'}
      />

      {/* <ContentGrid className="ag-theme-alpine" style={{ height: '500px' }}>
        <AgGridReact
          overlayNoRowsTemplate="No rows to show"
          rowData={props.rowData}
          columnDefs={columnDefs}
          onCellClicked={fnOnCellClicked}
        ></AgGridReact>
      </ContentGrid> */}
    </>
  );
};

export default memo(ApprovalTemplateManagementGrid);
