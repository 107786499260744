/** @jsxImportSource @emotion/react */
import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { GrayColor, CommonBG, PrimaryColor, CommonText, NavTop, NavLeft } from 'ui/theme/Color';
import { SVGIcon } from 'components/buttons/IconSVG';
import { Menu, MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';
import useSessionStore from 'stores/useSessionStore';

interface SideMenuListProps {
  summary: { menuInfo: Menu };
  content: Menu[];
  isActive: boolean;
  openParent?: () => void;
}

export const SideMenuList = ({ summary, content, isActive, openParent }: SideMenuListProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isActive);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const menuContext = useContext<MenuContextType>(MenuContext);

  useEffect(() => {
    isOpen && openParent && openParent();
  }, [isOpen]);

  const navigateToMenu = (menuUrl: string, linkTo?: boolean, optValCtn1?: string) => {
    if ('LINK' === optValCtn1 && menuUrl) {
      window.open(menuUrl, '_blank');
      return;
    }
    linkTo && menuUrl && navigate(menuUrl, { replace: true });
    setIsOpen(!isOpen);
  };

  return (
    <AccordionContainer>
      {summary.menuInfo.mnuEpsYn === 'Y' && (
        <div
          css={st.accHeader(
            content.length > 0 ? isOpen : menuContext.currentMenu.mnuId === summary.menuInfo.mnuId
          )}
          onClick={() =>
            navigateToMenu(
              summary.menuInfo.mnuUrl ?? '',
              content.length === 0,
              summary.menuInfo.mnuOptValCtn1
            )
          }
        >
          <div css={st.accHeaderText}>
            {summary.menuInfo.mnuUrl && summary.menuInfo.mnuOptValCtn1 !== 'LINK' ? (
              <Link
                to={summary.menuInfo.mnuUrl}
                className={
                  content.length > 0
                    ? ''
                    : summary.menuInfo.mnuId === menuContext.currentMenu?.mnuId
                    ? 'active'
                    : ''
                }
              >
                <Typography
                  className={`acc-title ${
                    summary.menuInfo.mnuOptValCtn1 === 'TO-BE' ? 'disabled' : ''
                  }`}
                  color={GrayColor.Gray900}
                >
                  {summary.menuInfo.mnuNm}
                </Typography>
              </Link>
            ) : (
              <Typography
                className={`acc-title ${
                  summary.menuInfo.mnuOptValCtn1 === 'TO-BE' ? 'disabled' : ''
                }`}
                color={GrayColor.Gray900}
              >
                {summary.menuInfo.mnuNm}
              </Typography>
            )}
          </div>
          {content.length > 0 && (
            <>
              <div className="ic-plus" />
              <div className="ic-minus" />
            </>
          )}
        </div>
      )}
      {content.length > 0 && (
        <SideMenuContent
          contentList={content.filter((item) => item.mnuEpsYn === 'Y')}
          isActive={isOpen}
          openParent={() => setIsOpen(true)}
        />
      )}
    </AccordionContainer>
  );
};

const SideMenuContent = ({ contentList, isActive, openParent }: any) => {
  const [count, setCount] = useState<number>(1);
  const [lowestMenu, setLowestMenu] = useState<Menu>();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { menus } = useSessionStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    setLowestMenu(null as unknown as Menu);
    if (contentList.find((item) => item.mnuId === menuContext.currentMenu.mnuId)) {
      openParent();
    } else if (menuContext.currentMenu) {
      let cur = menuContext.currentMenu;
      while (cur.mnuId != '000000') {
        cur = menus.find((menu) => menu.mnuId === cur.upprMnuId) ?? (null as unknown as Menu);
        if (!cur) break;
        const lowestParent = contentList.find((item) => item.mnuId === cur.mnuId);
        if (lowestParent) {
          setLowestMenu(lowestParent);
          openParent();
          break;
        }
      }
    }
  }, [menuContext]);

  const navigateToMenu = (menuUrl: string) => {
    const menu = menus.filter((o) => o.mnuUrl === menuUrl)[0];
    const path = pathname.substring(1, pathname.length);
    if (menuUrl === path) {
      const stacksMenu = menuContext.stacksMenu.some((o) => o.mnuId === menu?.mnuId)
        ? menuContext.stacksMenu
        : [...menuContext.stacksMenu, menu];

      const idx = menuContext.stacksMenu.findIndex((o) => o.mnuId === menu?.mnuId);
      const tabIndex = idx < 0 ? stacksMenu.length - 1 : idx;
      menuContext.handleMenuChange({
        ...menuContext,
        stacksMenu: stacksMenu,
        currentMenu: stacksMenu[tabIndex],
        tabIndex: tabIndex,
      });
    }
    menuUrl && navigate(menuUrl, { replace: true });
  };

  return (
    <>
      {contentList.map((it) =>
        it.children && it.children.filter((child) => child.mnuEpsYn === 'Y').length > 0 ? (
          <div
            css={st.accContent(isActive, count)}
            key={it.mnuId}
            onClick={() => setCount(isActive ? it.children.length + 1 : 1)}
          >
            <>
              <SideMenuList
                summary={{ menuInfo: it }}
                content={it.children}
                isActive={isActive}
                openParent={openParent}
              />
            </>
          </div>
        ) : (
          <div
            css={st.accContent(isActive, 1)}
            key={it.mnuId}
            onClick={() => navigateToMenu(it.mnuUrl ?? '')}
          >
            <Link
              css={st.li}
              to={it.mnuUrl ?? '/notFound'}
              className={`${
                it.mnuId === menuContext.currentMenu?.mnuId || it.mnuId === lowestMenu?.mnuId
                  ? 'active'
                  : ''
              } ${it.mnuOptValCtn1 === 'TO-BE' ? 'disabled' : ''}`}
            >
              {it.mnuNm}
            </Link>
          </div>
        )
      )}
    </>
  );
};

const AccordionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const st = {
  acc: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
  accHeader: (isAcc1Click: boolean) => css`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 14px;
    cursor: pointer;
    transition: 0.2s;
    overflow: hidden;
    .acc-title {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.5;
      word-break: keep-all;
      white-space: pre;
      color: ${CommonText.Basic};
    }
    .disabled {
      color: ${GrayColor.Gray700};
      opacity: 0.4;
      font-weight: 400;
      transition: 0.3s;
    }
    .ic-plus {
      display: block;
      width: 20px;
      height: 20px;
      transition: 0.2s;
      &:before {
        content: '';
        clear: both;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: ${CommonText.Light};
        -webkit-mask: ${SVGIcon.IcoPlus};
        mask-image: ${SVGIcon.IcoPlus};
        mask-size: 12px 12px;
        mask-position: center center;
        mask-repeat: no-repeat;
      }
    }
    .ic-minus {
      display: none;
      width: 20px;
      height: 20px;
      transition: 0.2s;
      &:before {
        content: '';
        clear: both;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: ${CommonText.Light};
        -webkit-mask: ${SVGIcon.IcoMinus};
        mask-image: ${SVGIcon.IcoMinus};
        mask-size: 12px 12px;
        mask-position: center center;
        mask-repeat: no-repeat;
      }
    }

    ${isAcc1Click === true &&
    ` color:${PrimaryColor.Primary700};
      transition: 0.2s;
      .acc-title {
        font-size:13px;
        font-weight:500;
        line-height:1.5;
        color:${NavLeft.TextSelected};
      }
      .ic-plus {
        display: none;
      }
      .ic-minus {
        display: block;
        transform: rotate(180deg);
        transition: 0.2s;
        fill:${CommonText.Light};
      }
    `},

    &:hover {
      transition: 0.2s;
      background: ${NavLeft.Bg1LevelHover};
    }
    &:focus,
    &:active {
      background: ${NavLeft.BgLastChildSelected};
      .acc-title {
        color: ${GrayColor.White};
        font-weight: 500;
      }
      .ic-minus,
      .ic-plus {
        &:before {
          background: ${GrayColor.White};
        }
      }
    }
  `,
  accThickHeader: (isAcc1Click: boolean) => css`
    width: 100%;
    background-color: ${isAcc1Click ? NavTop.TextSysNameBasic : 'transparent'};
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 13px 30px 13px 30px;
    cursor: pointer;
    transition: 0.2s;
    overflow: hidden;
    .img {
      display: block;
      transition: 0.2s;
    }

    .img-hover {
      display: none;
      transition: 0.2s;
    }

    .acc-title {
      word-break: keep-all;
      white-space: pre;
      line-height: 1.5;
    }

    .ic-plus {
      transition: 0.2s;
    }

    ${isAcc1Click === true &&
    `
      background-color: ${NavLeft.BgLastChildSelected};
      transition: 0.2s;
      .ic-minus {
        display: none;
        transition: 0.2s;
      }
      .ic-plus {
        display: block;
        transition: 0.2s;
      }
      .acc-title {
        color: ${CommonBG.Elevation};
      }
      .ic-plus {
        fill: white;
        transform: rotate(180deg);
        transition: 0.2s;
      }
    `},

    &:hover {
      background-color: ${NavLeft.BgLastChildSelected};
      transition: 0.2s;
      .acc-title {
        color: ${CommonBG.Elevation};
      }
      .ic-plus {
        display: block;
        transition: 0.2s;
      }
      .ic-minus {
        display: none;
        transition: 0.2s;
      }
    }
  `,
  accContent: (isClicked: boolean, count: number) => css`
    width: 100%;
    height: ${isClicked ? `calc(34px * ${count})` : '0px'};
    overflow: hidden;
    transition: 0.35s ease;
  `,
  accHeaderText: css`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  accThickHeaderText: css`
    display: flex;
    align-items: flex-start;
    gap: 8px;
  `,

  // icWrapper: css`
  //   padding-top: 2px;
  // `,

  li: css`
    width: 100%;
    height: 34px;
    padding-left: 36px;
    display: flex;
    align-items: center;
    color: ${CommonText.Light};
    transition: 0.2s;
    cursor: pointer;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 12px;
    font-weight: 400;
    &:hover,
    &.active {
      color: ${CommonText.Primary};
      transition: 0.2s;
    }
    &.disabled {
      opacity: 0.4;
    }
  `,
};
