import React from 'react';
import { Session } from 'models/common/Session';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const devLogin = async (userId: string, userPwd: string, langCd: string) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/dev/login`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { userId: userId, userPwd: userPwd, langCd: langCd },
  };
  const response: CommonResponse<Session> = await callApi(request);

  return response;
};

export const devLogout = async () => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/dev/logout`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<Session> = await callApi(request);

  return response;
};

export const getSession = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/session`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<Session> = await callApi(request);

  return response;
};

export const hasMenuAuth = async (mnuUrl) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/has-menu-auth`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ mnuUrl: mnuUrl }),
  };
  const response: CommonResponse<boolean> = await callApi(request);
  return response.data;
};
