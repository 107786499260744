/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import { uploadExcelTemplates } from 'apis/common/Excel';
import { createMpRequest, findMpReqList } from 'apis/mp/MpItemReq';
import { useLocation } from 'react-router-dom';
import Dropzone from 'components/dropzone/Dropzone';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { GlobalBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import ExcelValidModal from 'components/modals/common/ExcelValidModal';
import { generateApprovalRequestId } from 'apis/ip/EquipmentParameter';
import { ManagementMode } from 'models/common/Common';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';
import useSessionStore from 'stores/useSessionStore';
import MpItemCopModal from 'components/modals/mp/MpItemCopModal';
import {
  MpItem,
  MpItemApprovalRequest,
  MpItemAtchFileTpCd,
  MpItemCondition,
  MpItemRequestCondition,
} from '../../models/mp/MpItem';
import { hasRole } from 'utils/SessionUtil';
// Wijmo library
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { InputDate, ComboBox } from '@grapecity/wijmo.input';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcInput from '@grapecity/wijmo.input';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

interface SensorManagementPageLocationState {
  equipmentGroup?: string;
  eqpParaNo?: string;
  versionNo?: string;
  condition?: any;
  pageNo?: number;
  mode: ManagementMode;
}

const MpExcelManagementPage = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const locationState: SensorManagementPageLocationState = useLocation().state;
  const { gridNoRowsTemplate } = useSessionStore();
  const [isOpenMpItemCopModal, setOpenMpItemCopModal] = useState<boolean>(false);

  const [reqData, setReqData] = useState<MpItemApprovalRequest>({
    aprTplId: 'SEC-FORM-0001', // 고정
    aprPvnDdlnCd: 'APPRCODE_1M',
  } as MpItemApprovalRequest);
  const gridRef = useRef<any>(null);

  // Excel Vaild Modal
  const [ExcelValidModalOpen, setExcelValidModalOpen] = useState<boolean>(false);
  const [excelValidCondition, setExcelValidCondition] = useState<string | null>(null);
  const [rowData, setRowData] = useState<MpItem[]>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [itemId, setItemId] = useState<any>('');
  const menuContext = useContext<MenuContextType>(MenuContext);

  const [hasAuth, setHasAuth] = useState<boolean>(false);

  useEffect(() => {
    // mp 권한체크
    if (hasRole('MP_MANAGER') || hasRole('ADM')) {
      setHasAuth(true);
    }

    generateApprovalRequestId().then((value) => {
      setReqData({ ...reqData, aprReqId: value });
    });
  }, []);

  const onInitialized = (grid) => {
    new FlexGridFilter(grid);
    // new Selector(grid);
    setflexRef(grid);

    //셀렉트 방식
    // grid.selectionMode = SelectionMode.Row;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      console.log(ht.row, ht.col);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      setItemId(item.itemId);

      setflexItem(item);
      setFieldId(col.binding);
      //console.log(col, col.binding, grid.rows[ht.row]);

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnCompany':
            if (item.itemId) {
              console.log(item);
            }
            break;
        }
      }
      grid.refresh();
    });
  };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) return;
    const row = s.rows[e.row].dataItem;
  };

  const LayoutDefinition = () => {
    return [
      // {
      //   width: 37,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      //   headerClass: 'headerCheck',
      //   cssClass: 'cellCheck',
      // },
      {
        header: String(t('mp.grid.Item', 'Item')),
        align: 'center',
        columns: [
          {
            binding: 'itemId',
            header: String(t('mp.grid.번호', '번호')),
            width: 70,
            align: 'center',
          },
          {
            binding: 'itemNm',
            header: String(t('mp.grid.명', '명')),
            align: 'center',
            width: 250,
          },
          {
            binding: 'itemEngNm',
            align: 'center',
            header: String(t('mp.grid.영문명', '영문명')),
            width: 250,
          },
        ],
      },
      {
        binding: 'mpClsfCdNm',
        align: 'center',
        header: String(t('mp.grid.MP 분류', 'MP 분류')),
        width: 150,
      },
      {
        binding: 'procCdNm',
        align: 'center',
        header: String(t('mp.grid.공정', '공정')),
        width: 120,
      },
      {
        header: String(t('mp.grid.설비분류', '설비분류')),
        align: 'center',
        columns: [
          {
            binding: 'eqpGr',
            align: 'center',
            header: String(t('com.label.설비군', '설비군')),
            width: 150,
          },
          {
            binding: 'eqpClsfNm',
            align: 'center',
            header: String(t('mp.grid.설비분류체계', '설비분류체계')),
            width: 170,
          },
          {
            binding: 'eqpLv1Nm',
            align: 'center',
            header: String(t('mp.grid.Main', 'Main')),
            width: 150,
          },
          {
            binding: 'eqpLv2Nm',
            align: 'center',
            header: String(t('mp.grid.Machine', 'Machine')),
            width: 150,
          },
          {
            binding: 'eqpLv3Nm',
            align: 'center',
            header: String(t('mp.grid.Unit', 'Unit')),
            width: 150,
          },
        ],
      },
      {
        header: String(t('mp.grid.개선목적', '개선목적')),
        align: 'center',
        columns: [
          {
            binding: 'iprvClsfLv1CdNm',
            align: 'center',
            header: String(t('mp.grid.Level 1', 'Level 1')),
            width: 130,
          },
          {
            binding: 'iprvClsfLv2CdNm',
            align: 'center',
            header: String(t('mp.grid.Level 2', 'Level 2')),
            width: 130,
          },
        ],
      },
      {
        binding: 'wkDivsCdNm',
        align: 'center',
        header: String(t('mp.grid.작업구분', '작업구분')),
        width: 100,
      },
      {
        binding: 'copCdNm',
        align: 'center',
        header: String(t('mp.grid.대상법인', '대상법인')),
        width: 200,
        cssClass: 'WijmoFind',
        cellTemplate: (params) => {
          return `<span>${params.value}</span>
              <Button
                onClick={() => {
                  if (${params.item.itemId}) {
                    setOpenMpItemCopModal(true);
                  }
                }}
              ></Button>
            `;
        },
      },
      {
        binding: 'isuDesc',
        align: 'center',
        header: String(t('mp.grid.Issue사항/개선목적', 'Issue사항/개선목적')),
        width: 200,
      },
      {
        binding: 'iprvCtn',
        align: 'center',
        header: String(t('mp.grid.개조/개선 항목', '개조/개선 항목')),
        width: 150,
      },
      {
        binding: 'rmk',
        align: 'center',
        header: String(t('mp.grid.비고', '비고')),
        width: 100,
      },
      {
        binding: 'itemStatCdNm',
        align: 'center',
        header: String(t('com.label.상태', '상태')),
        width: 150,
      },
      {
        header: String(t('mp.grid.MP 유형', 'MP 유형')),
        align: 'center',
        columns: [
          {
            binding: 'mpYn',
            align: 'center',
            header: String(t('mp.grid.설비변경점', '설비변경점')),
            width: 80,
          },
          {
            binding: 'heYn',
            align: 'center',
            header: String(t('mp.grid.수평전개', '수평전개')),
            width: 80,
          },
          {
            binding: 'niYn',
            align: 'center',
            header: String(t('mp.grid.신규개선', '신규개선')),
            width: 80,
          },
        ],
      },
      {
        binding: 'reqMgrNm',
        header: String(t('mp.grid.기술 제안자', '기술 제안자')),
        width: 100,
        align: 'center',
      },
      {
        binding: 'm4ChgYn',
        header: String(t('mp.grid.4M 여부', '4M 여부')),
        width: 80,
        align: 'center',
      },
      {
        binding: 'spshRvwYn',
        header: String(t('mp.grid.표준사양서검토여부', '표준사양서검토여부')),
        width: 150,
        align: 'center',
      },
      {
        binding: 'atchFileGrId',
        visible: true,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  /**
   * 임시저장 처리
   */
  const handleSave = () => {
    createMpRequest({
      ...reqData,
      mode: locationState.mode,
      mpReqList: rowData,
    }).then((result) => {
      openMessageBar({
        type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          result?.successOrNot === 'Y'
            ? t('com.label.저장되었습니다.', '저장되었습니다.')
            : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
      });
      if (result?.successOrNot === 'Y') {
        menuContext.closeCurrentTab(menuContext);
      }
    });
  };

  const handleSearch = async (condition: MpItemRequestCondition, isFirst = false) => {
    const response = await findMpReqList(condition);
    if (response) {
      const rowData = response || [];
      setRowData(rowData);
    }
  };

  const uploadTemplate = (file) => {
    openLoading(true);
    uploadExcelTemplates(
      [file],
      'TPL_MP_LIST',
      'NORMAL',
      'TB_ELM_MP_ITEM_REQ_N',
      locationState.mode,
      reqData?.aprReqId,
      reqData?.aprTplId,
      reqData?.aprPvnDdlnCd,
      reqData?.aprReqTitNm,
      reqData?.trnmBdyCtn
    )
      .then((result) => {
        openLoading(false);

        if (result?.successOrNot === 'Y') {
          reqData.xlsUploadId = result?.data.p_xls_upload_id;
          if (result?.data.x_result == 'OK') {
            reqData.aprReqId = result?.data.p_apr_req_id;
            handleSearch(reqData);
          } else {
            setExcelValidCondition(result?.data.p_xls_upload_id);
            setExcelValidModalOpen(true);
            return;
          }
        }

        const data = typeof result?.data === 'string' ? result?.data : null;
        openMessageBar({
          type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
          content:
            result?.successOrNot === 'Y'
              ? t('mp.msg.검증이 완료되었습니다.', '검증이 완료되었습니다.')
              : data || t('mp.msg.검증 중 오류가 발생했습니다.', '검증 중 오류가 발생했습니다.'),
        });
        if (result?.successOrNot === 'Y') {
          handleSearch(reqData);
        }
      })
      .finally(() => openLoading(false));
  };

  return (
    <>
      <ContainerLayout>
        <TableContainer css={tb.table}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('mp.msg.파일 선택', '파일 선택')}</TableCell>
                <TableCell>
                  <Dropzone
                    singleSelect={true}
                    onChange={(files) => {
                      if (files && files.length > 0) {
                        const file = files[0].file;
                        if (file) {
                          if ((rowData || []).length) {
                            openCommonModal({
                              modalType: 'confirm',
                              content: t(
                                'com.label.업로드된 데이터가 존재합니다.\\n새로 업로드 하시겠습니까?',
                                '업로드된 데이터가 존재합니다.<br/>새로 업로드 하시겠습니까?'
                              ),
                              yesCallback: () => {
                                uploadTemplate(file);
                              },
                            });
                            return;
                          }
                          uploadTemplate(file);
                        }
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('mp.msg.MP 등록/수정 요청 List', 'MP 등록/수정 요청 List')}</h3>
            <span className="total">
              {t('com.label.총', '총')} <span>{rowData.length}</span>
              {t('com.label.건', '건')}
            </span>
            {/*<PaginationSize*/}
            {/*  defaultPageSize={pageSize}*/}
            {/*  onChange={(value) => {*/}
            {/*    setPageSize(value);*/}
            {/*    paginationRef.current?.handlePageSizeChange(value);*/}
            {/*  }}*/}
            {/*/>*/}
            {/* 안내문 class 종류는 default,error,warning,confirm,primary가 있습니다. */}
            {/*<div className="info default">Info Text (Default)</div>*/}
          </SubTitleGroup>
        </SubTitleLayout>

        <ContentGrid className={(rowData || []).length < 1 ? 'noData' : ''}>
          {/* <AgGridReact<MpItem>
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            groupHeaderHeight={32}
            rowHeight={32}
            overlayNoRowsTemplate={gridNoRowsTemplate}
            defaultColDef={defaultColumnDefs}
          ></AgGridReact> */}
          <FlexGrid
            itemsSource={state.itemsSource}
            columns={state.layoutDefinition}
            autoGenerateColumns={false}
            //   allowPinning="ColumnRange" // 열 고정 방식
            // frozenColumns={1} // 열고정
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            showMarquee={true} // 선택 윤곽선 (default: false)
            selectionMode={SelectionMode.CellRange}
            alternatingRowStep={0}
            // stickyHeaders={true} // 헤더 고정
            // isReadOnly={true}
            // showSelectedHeaders={HeadersVisibility.Row}
            style={{ height: (rowData || []).length < 1 ? '' : '500px' }}
            align="center"
            allowMerging="Cells"
            initialized={onInitialized}
          />
          <div className="noData" style={{ height: (rowData || []).length < 1 ? '500px' : '' }}>
            <span>
              {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
            </span>
          </div>
        </ContentGrid>

        {hasAuth && (
          <GlobalBtnGroup>
            <Button css={IconButton.button} className="save" onClick={handleSave} disableRipple>
              {t('com.button.저장', '저장')}
            </Button>
          </GlobalBtnGroup>
        )}
        {isOpenMpItemCopModal && (
          <MpItemCopModal
            open={isOpenMpItemCopModal}
            close={() => setOpenMpItemCopModal(false)}
            itemId={itemId}
            isReadOnly={true}
          />
        )}
      </ContainerLayout>
      {ExcelValidModalOpen && (
        <ExcelValidModal
          open={ExcelValidModalOpen}
          close={() => {
            setExcelValidCondition(null);
            setExcelValidModalOpen(false);
          }}
          condition={{
            valReqId: excelValidCondition || '',
          }}
        />
      )}
    </>
  );
};

export default MpExcelManagementPage;
