/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { CloseBtn } from '../../components/buttons/CustomButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { tb } from '../../components/layouts/Table';
import {
  CustomInputText,
  CustomInputWrap,
  AllInputWrap,
} from '../../components/inputs/CustomInput';
import { ExcpUserConditionSave } from 'models/admin/ExcpUser';
import { Button } from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from '../../components/layouts/Dialog';
import { IconButton } from '../../components/buttons/IconSVG';
import _ from 'lodash';
import useSessionStore from '../../stores/useSessionStore';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from '../../hooks/useCommonModal';
import { checkExcpUserIdNm, resetExcpUserPwd } from '../../apis/admin/ExcpUser';
import * as CryptoJS from 'crypto-js';

const excpUser: ExcpUserConditionSave = {
  excpId: '',
  userId: '',
  userNm: '',
  userPwd: '',
  userEml: '',
  rmk: '',
};

type Props = {
  open: boolean;
  close: () => void;
};
/**
 * @param open
 * @param close
 * @param isEditable
 * @param condition
 * @param onCallback
 * @constructor
 */
export const ResetPwdPopUp = ({ open, close }: Props) => {
  const { t } = useTranslation();
  const [isAgreed, setAgreed] = useState<boolean>(false);
  const { openCommonModal } = useCommonModal();
  const [excpUserInfo, setExcpUserInfo] = useState<ExcpUserConditionSave>(excpUser);

  const key = CryptoJS.enc.Utf8.parse('s302lks823klasde0fasdfal322kj439');
  const iv = CryptoJS.enc.Utf8.parse('2983923827611212');

  useEffect(() => {
    initCondition();
  }, []);

  const initCondition = async () => {
    //await getExcpList().then((result: any) => {
    //  if (result != null && result != '') {
    //    console.log('##########');
    //  }
    //});
  };

  const handleOnChangeUserNm = (e) => {
    setExcpUserInfo({
      ...excpUserInfo,
      userNm: e.target.value,
    });
  };

  const handleOnChangeUserId = (e) => {
    setExcpUserInfo({
      ...excpUserInfo,
      userId: e.target.value,
      userEml: e.target.value,
    });
  };

  const handleConfirm = async () => {
    if (excpUserInfo.userId == '') {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.아이디를 입력 해주세요.', '아이디를 입력 해주세요.'),
      });
      return false;
    }
    const validateEmail = (email: any) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    if (validateEmail(excpUserInfo.userId) == null) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.아이디가 이메일 형식이 아닙니다.',
          '아이디가 이메일 형식이 아닙니다.'
        ),
      });
      return false;
    }
    if (excpUserInfo.userNm == '') {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.이름을 입력 해주세요.', '이름을 입력 해주세요.'),
      });
      return false;
    }
    const response = await checkExcpUserIdNm(excpUserInfo);
    if (response.data.updatedRows == 8) {
      openCommonModal({
        content: '가입된 정보가 존재하지 않습니다. 아이디를 확인 하시 길 바랍니다.',
      });
    } else if (response.data.updatedRows == 7) {
      openCommonModal({
        content: '잘못된 정보입니다. 올바른 정보를 입력하세요.',
      });
    } else {
      openCommonModal({
        modalType: 'confirm',
        content: t(
          'com.label.가입시 등록된 메일로 임시 비밀번호를 전송 합니다. 임시 비밀번호를 발급 하시겠습니까?',
          '가입시 등록된 메일로 임시 비밀번호를 전송 합니다. 임시 비밀번호를 발급 하시겠습니까?'
        ),
        yesCallback: async () => {
          const finalResponse = await resetExcpUserPwd(excpUserInfo);
          if (finalResponse.data.updatedRows == 1) {
            openCommonModal({
              content: '가입시 등록된 메일로 임시 비밀번호를 전송 하였습니다.',
            });
            close();
          } else {
            openCommonModal({
              content: '시스템 문제로 다시 한번 시도바랍니다.',
            });
          }
        },
      });
    }
  };

  return (
    <>
      <Dialog open={true} css={di.dialog} fullWidth className="sm" maxWidth={false}>
        <TitleWrap>
          <h2>{t('com.label.비밀번호 초기화', '비밀번호 초기화')}</h2>
          <CloseBtn
            onClick={() => {
              close();
            }}
          ></CloseBtn>
        </TitleWrap>
        <DialogContent>
          <TableContainer css={tb.table}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{t('com.label.아이디(이메일)', '아이디(이메일)')}</TableCell>
                  <TableCell>
                    <AllInputWrap>
                      <CustomInputWrap>
                        <CustomInputText
                          placeholder={String(
                            t('com.label.아이디를 입력하세요.', '아이디를 입력하세요.')
                          )}
                          onChange={handleOnChangeUserId}
                        />
                      </CustomInputWrap>
                    </AllInputWrap>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('com.label.이름', '이름')}</TableCell>
                  <TableCell>
                    <AllInputWrap>
                      <CustomInputWrap>
                        <CustomInputText
                          placeholder={String(
                            t('com.label.이름을 입력 해주세요.', '이름을 입력하세요.')
                          )}
                          onChange={handleOnChangeUserNm}
                        />
                      </CustomInputWrap>
                    </AllInputWrap>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <PopupBtnGroup>
          <Button
            css={IconButton.button}
            className="select"
            onClick={() => {
              handleConfirm();
            }}
          >
            {t('com.label.초기화', '초기화')}
          </Button>
        </PopupBtnGroup>
      </Dialog>
    </>
  );
};
export default ResetPwdPopUp;
