/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useEffect, useState } from 'react';
import { CustomInputWrap } from 'components/inputs/CustomInput';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import { Button } from '@mui/material';
import Datepicker from 'react-tailwindcss-datepicker';
import { RangeDate } from 'models/ip/Generic';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import moment from 'moment';

// 프로퍼티 타입 지정
TableCellDateRangePicker.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  colSpan: PropTypes.number,
  required: PropTypes.bool,

  onChange: PropTypes.any,
};

export default function TableCellDateRangePicker(props) {
  const { t } = useTranslation();
  const [textboxGrCd, setTextboxGrCd] = useState<any>();

  const labelId = 'common.label.' + props.name;
  const labelText = '' + props.name;

  useEffect(() => {
    setTextboxGrCd(props?.defaultValue);
  }, [props.defaultValue]);

  const handleOnChange = (newValue) => {
    const value = {
      startDate:
        typeof newValue?.startDate === 'object' && newValue?.startDate
          ? moment(newValue?.startDate).format('YYYY.MM.DD')
          : String(newValue?.startDate || ''),
      endDate:
        typeof newValue?.endDate === 'object' && newValue?.endDate
          ? moment(newValue?.endDate).format('YYYY.MM.DD')
          : String(newValue?.endDate || ''),
    };
    setTextboxGrCd(value);
    const result = {
      id: props.id,
      value: value,
    };
    props.onChange(result);
  };

  return (
    <>
      <TableCell>
        <span className={props.required ? 'dot' : ''}>{t(labelId, labelText)}</span>
      </TableCell>
      <TableCell colSpan={props.colSpan}>
        <DatePickerWrap className="range">
          <Datepicker
            inputClassName="datepickerInput"
            readOnly={true}
            value={textboxGrCd}
            onChange={handleOnChange}
            i18n={'ko'}
            useRange={true}
            placeholder="YYYY.MM.DD - YYYY.MM.DD"
            displayFormat={'YYYY.MM.DD'}
          />
        </DatePickerWrap>
      </TableCell>
    </>
  );
}
