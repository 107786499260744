/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Button } from '@mui/material';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import CustomGrid from 'components/grids/CustomGrid';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import {
  downloadExcelEquipmentParameter,
  findEquipmentParameter,
  createEquipmentParameterRequestByEqpParaNo,
  generateAtchFileGrId,
} from 'apis/ip/EquipmentParameter';
import {
  EquipmentParameter,
  EquipmentParameterCondition,
  ParameterPointCondition,
} from 'models/ip/EquipmentParameter';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { getExcelFileName } from 'utils/ExcelUtil';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import ParameterPointModal from 'components/modals/ip/ParameterPointModal';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { EquipmentGroupPopUp } from 'pages/common/popup/EquipmentGroupPopUp';
import { FileTypeName, ManagementMode } from 'models/common/Common';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { downloadExcelTemplatesPost } from 'apis/common/Excel';
import ParameterManagementModal from 'components/modals/ip/ParameterManagementModal';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import { hasRole } from 'utils/SessionUtil';
import { useMessageBar } from 'hooks/useMessageBar';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getTplAtchFileByCd, handleUploadTplAtchFileDownload } from 'apis/admin/UploadTemplate';
import { ApproveRequestPageType } from 'pages/approves/ApproveRequestDetailCase';
import ApproveRequestModal from 'pages/approves/ApproveRequestModal';

interface EquipmentParameterPageLocationState {
  equipmentGroup?: string;
  eqpParaNo?: string;
  versionNo?: string;
  condition?: any;
  pageNo?: number;
  request?: string;
}

/**
 * 설비검수 > IP점검 계획/실적 > Ip List 조회 > 설비별 IP LIST 조회
 * @constructor
 */
const EquipmentParameterPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();
  const locationState: EquipmentParameterPageLocationState = useLocation().state;
  const gridRef = useRef<any>();
  const [flexRef, setflexRef] = useState<any>();
  const [rowData, setRowData] = useState<EquipmentParameter[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [equipmentParamCondition, setEquipmentParamCondition] =
    useState<EquipmentParameterCondition>({});
  const [isOpenEquipmentGroupPopUp, setOpenEequipmentGroupPopUp] = useState<boolean>(false);
  const [isOpenParameterManagementModal, setOpenParameterManagementModal] =
    useState<boolean>(false);
  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  const [mode, setMode] = useState<ManagementMode>(ManagementMode.CREATE);
  const [errors, setErrors] = useState<any>({});
  const [isOpenParaPoint, setOpenParaPoint] = useState<boolean>(false);
  const [paraPointCondition, setParaPointCondition] = useState<ParameterPointCondition>({});
  const [paraPointInfo, setParaPointInfo] = useState<EquipmentParameter>({});
  const [checkedItems, setCheckedItems] = useState<EquipmentParameter[]>();
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [hasAuth, setHasAuth] = useState<boolean>(false);
  const [pageId, setPageId] = useState<ApproveRequestPageType>(
    ApproveRequestPageType.IP_APPROVE_REQ
  );

  useEffect(() => {
    // ip 권한체크
    if (hasRole('IP_MANAGER')) {
      setHasAuth(true);
    }

    if (locationState?.equipmentGroup || locationState?.eqpParaNo) {
      const condition = {
        ...equipmentParamCondition,
        equipmentGroup: locationState?.equipmentGroup || equipmentParamCondition.equipmentGroup,
        eqpParaNo: locationState?.eqpParaNo || equipmentParamCondition.eqpParaNo,
        versionNo: locationState?.versionNo || equipmentParamCondition.versionNo,
      };
      setEquipmentParamCondition(condition);
      setErrors({ equipmentGroup: false });
      handleSearch(condition);
    }
  }, [locationState]);

  const handleSearch = async (condition: EquipmentParameterCondition) => {
    if (_.isEmpty(condition.equipmentGroup)) {
      setErrors({ equipmentGroup: true });
      return;
    }
    const response = await findEquipmentParameter(condition);
    if (response) {
      const rowData = response.list || [];
      setRowData(rowData);
      setTotalCount(response.totalCount || 0);
    }
  };

  const handleParamConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setEquipmentParamCondition({
      ...equipmentParamCondition,
      [e.target.name]: e.target.value,
      versionNo: '', // 값 변경 시 버전 조건 초기화 (IP List 에서 넘어온 경우에만 버전 설정됨)
    });
    setErrors({ ...errors, [e.target.name]: false });
  };

  const handleOpenParaPointModal = (item) => {
    const { eqpParaNo, versionNo, paraId } = item;
    setParaPointCondition({
      eqpParaNo,
      versionNo,
      paraId,
    } as ParameterPointCondition);
    setParaPointInfo({
      ...item,
    } as EquipmentParameter);
    setOpenParaPoint(true);
  };

  const handleDownloadExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ip.label.설비별 IPList', '설비별 IPList');
    book.saveAsync(getExcelFileName(t('ip.label.설비별 IPList', '설비별 IPList')) + '.xlsx');
  };

  const handleParaPointCell = (item) => {
    if (item.paraPointType === 'POINT') {
      handleOpenParaPointModal(item);
    }
  };

  const handleFileUpload = async (grid, item, row, col) => {
    let atchFileGrId = item.atchFileGrId;
    if (_.isEmpty(atchFileGrId)) {
      const { eqpParaNo, versionNo, paraId } = item;
      atchFileGrId = await generateAtchFileGrId(eqpParaNo, versionNo, paraId);
      if (atchFileGrId) {
        if (rowData.length > 0) {
          setRowData(
            [...rowData].map((o) =>
              o.eqpParaNo === eqpParaNo && o.versionNo === versionNo && o.paraId === paraId
                ? {
                    ...o,
                    atchFileGrId: atchFileGrId || '',
                  }
                : o
            )
          );
        }
      } else {
        openMessageBar({
          type: 'error',
          content:
            t(
              'com.label.첨부파일그룹ID 생성에 실패했습니다.',
              '첨부파일그룹ID 생성에 실패했습니다.'
            ) +
            <br /> +
            t('com.label.새로고침 후 다시 시도해 주세요.', '새로고침 후 다시 시도해 주세요.'),
        });
        return;
      }
    }
    setFileUploadModalCondition({
      atchFileGrId: atchFileGrId,
      atchFileTpCd: 'NORMAL',
      optValCtn1: 'TB_ELM_IP_EQP_PARA_D',
      bizName: 'ip',
      downloadOnly: item.currentFlag !== 'C',
      target: {
        item: item,
        row: row,
        col: col,
      },
    });
    setOpenFileUploadModal(true);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(equipmentParamCondition);
    }
  };

  const layoutDefinition = [
    {
      binding: 'equipmentGroup',
      header: String(t('com.label.설비군', '설비군')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'eqpParaNo',
      header: String(t('ip.label.설비파라미터번호', '설비파라미터번호')),
      align: 'center',
      width: 150,
      cellTemplate: CellMaker.makeLink({
        click: (e, ctx) => {
          navigate('/ip/maintenance/StatusManagementPage', {
            state: {
              equipmentGroup: ctx.item.equipmentGroup,
              eqpParaNo: ctx.value,
            },
          });
        },
      }),
    },
    {
      binding: 'versionNo',
      header: String(t('ip.grid.버전', '버전')),
      align: 'center',
      width: 80,
    },
    {
      binding: 'eqpParaName',
      header: String(t('ip.grid.설비파라미터명', '설비파라미터명')),
      align: 'left',
      width: 200,
    },
    {
      header: String(t('ip.grid.설비', '설비')),
      align: 'center',
      collapseTo: 'machineName',
      isCollapsed: true,
      columns: [
        {
          binding: 'electrodeTypeNm',
          header: String(t('ip.label.Anode/Cathode', 'Anode/Cathode')),
          align: 'center',
          width: 170,
        },
        {
          binding: 'machineName',
          header: String(t('ip.label.Machine', 'Machine')),
          align: 'left',
          width: 130,
        },
        {
          binding: 'unitName',
          header: String(t('ip.grid.Unit', 'Unit')),
          align: 'left',
          width: 200,
        },
        {
          binding: 'assemblyName',
          header: String(t('ip.label.Assembly', 'Assembly')),
          align: 'left',
          width: 200,
        },
      ],
    },
    {
      binding: 'paraTypeNm',
      header: String(t('ip.grid.파라미터 유형', '파라미터 유형')),
      align: 'center',
      width: 210,
    },
    {
      binding: 'ipCategoryNm',
      header: String(t('ip.label.IP구분', 'IP구분')),
      align: 'center',
      width: 120,
    },
    {
      binding: 'checkStepNm',
      header: String(t('ip.grid.점검단계', '점검단계')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'paraItemNm',
      header: String(t('ip.grid.파라미터 항목', '파라미터 항목')),
      align: 'left',
      width: 170,
    },
    {
      binding: 'paraId',
      header: String(t('ip.grid.파라미터ID', '파라미터ID')),
      align: 'center',
      width: 200,
    },
    {
      binding: 'paraName',
      header: String(t('ip.grid.파라미터명', '파라미터명')),
      align: 'left',
      width: 250,
    },
    {
      binding: 'paraDesc',
      header: String(t('ip.grid.파라미터 설명', '파라미터 설명')),
      align: 'left',
      width: 300,
    },
    {
      header: String(t('ip.grid.사양', '사양')),
      align: 'center',
      collapseTo: 'meterTypeNm',
      isCollapsed: true,
      columns: [
        {
          binding: 'specDataum',
          header: String(t('ip.grid.측정기준', '측정기준')),
          align: 'left',
          width: 180,
        },
        {
          binding: 'meterTypeNm',
          header: String(t('ip.grid.미터유형', '미터유형')),
          align: 'center',
          width: 140,
        },
        {
          binding: 'lowerBound',
          header: String(t('ip.grid.하한', '하한')),
          align: 'center',
          width: 120,
        },
        {
          binding: 'upperBound',
          header: String(t('ip.grid.상한', '상한')),
          align: 'center',
          width: 120,
        },
        {
          binding: 'uomNm',
          header: String(t('ip.grid.UOM', 'UOM')),
          align: 'left',
          width: 130,
        },
        {
          binding: 'rmk',
          header: String(t('ip.grid.주석', '주석')),
          align: 'left',
          width: 150,
        },
      ],
    },
    {
      header: String(t('ip.grid.측정', '측정')),
      align: 'center',
      collapseTo: 'measureToolNm',
      isCollapsed: true,
      columns: [
        {
          binding: 'measureToolNm',
          header: String(t('ip.grid.도구', '도구')),
          align: 'left',
          width: 200,
        },
        {
          binding: 'measureMethod',
          header: String(t('ip.grid.방법', '방법')),
          align: 'left',
          width: 100,
        },
        {
          binding: 'measureGuide',
          header: String(t('ip.grid.가이드', '가이드')),
          align: 'left',
          width: 150,
        },
        {
          binding: 'digitalTypeNm',
          header: String(t('ip.grid.A/D', 'A/D')),
          align: 'left',
          width: 150,
        },
      ],
    },
    {
      header: String(t('ip.grid.측정개소', '측정개소')),
      align: 'center',
      columns: [
        {
          binding: 'paraPointTypeNm',
          header: String(t('com.label.유형', '유형')),
          align: 'left',
          width: 100,
          cssClass: 'WijmoPopup',
          cellTemplate: (params) => `
            ${params.item.paraPointTypeNm || ''}
            ${params.item.paraPointType === 'POINT' ? '<Button />' : ''}
          `,
        },
        {
          binding: 'paraPointCnt',
          header: String(t('ip.grid.개수', '개수')),
          align: 'center',
          width: 100,
          cssClass: 'WijmoPopup',
          cellTemplate: (params) => `
            ${(params.item.paraPointCnt || 0).toLocaleString()}
            ${params.item.paraPointType === 'POINT' ? '<Button />' : ''}
          `,
        },
      ],
    },
    {
      binding: 'atchFileCnt',
      header: String(t('ip.grid.첨부', '첨부')),
      align: 'center',
      width: 100,
      cellTemplate: (params) =>
        `<div class="fileDiv">
            <span>
                <em>${(params.item.atchFileCnt || 0).toLocaleString()}</em>
            </span>
         </div>`,
    },
    {
      header: String(t('ip.grid.CTQ/CTP', 'CTQ/CTP')),
      align: 'center',
      collapseTo: 'ctqctpTypeNm',
      isCollapsed: true,
      columns: [
        {
          binding: 'ctqctpTypeNm',
          header: String(t('com.label.유형', '유형')),
          align: 'center',
          width: 150,
        },
        {
          binding: 'ctqctpDetail',
          header: String(t('com.label.상세', '상세')),
          align: 'left',
          width: 150,
        },
      ],
    },
    {
      binding: 'mainIpFlag',
      header: String(t('ip.grid.핵심IP여부', '핵심IP여부')),
      align: 'center',
      width: 140,
    },
    {
      header: String(t('ip.grid.이상발생', '이상발생')),
      align: 'center',
      collapseTo: 'abnormalTypeNm',
      isCollapsed: true,
      columns: [
        {
          binding: 'abnormalTypeNm',
          header: String(t('com.label.유형', '유형')),
          align: 'left',
          width: 140,
        },
        {
          binding: 'abnormalCause',
          header: String(t('ip.grid.원인', '원인')),
          align: 'left',
          width: 140,
        },
        {
          binding: 'abnormalDetectFlag',
          header: String(t('ip.grid.감지여부', '감지여부')),
          align: 'left',
          width: 140,
        },
        {
          binding: 'abnormalDetectMethod',
          header: String(t('ip.grid.감지방법', '감지방법')),
          align: 'left',
          width: 140,
        },
        {
          binding: 'abnormalDetectMntFlag',
          header: String(t('ip.grid.모니터링여부', '모니터링여부')),
          align: 'center',
          width: 140,
        },
      ],
    },
    {
      header: String(t('ip.grid.디지털화', '디지털화')),
      align: 'center',
      collapseTo: 'digitalNeedFlag',
      isCollapsed: true,
      columns: [
        {
          binding: 'digitalNeedFlag',
          header: String(t('ip.grid.필요여부', '필요여부')),
          align: 'center',
          width: 110,
        },
        {
          binding: 'digitalImprovement',
          header: String(t('ip.grid.개선방안', '개선방안')),
          align: 'left',
          width: 150,
        },
        {
          binding: 'digitalOwner',
          header: String(t('ip.grid.주체', '주체')),
          align: 'center',
          width: 150,
        },
        {
          binding: 'digitalPlan',
          header: String(t('ip.grid.계획', '계획')),
          align: 'left',
          width: 100,
        },
        {
          binding: 'digitalCompleteFlag',
          header: String(t('ip.grid.완료여부', '완료여부')),
          align: 'center',
          width: 110,
        },
      ],
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;
    setflexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;
        // 측정개소 유형이 개소관리인 경우 유형, 개수 클릭 시 팝업 열기
        if (['paraPointTypeNm', 'paraPointCnt'].includes(binding)) {
          handleParaPointCell(item);
        }
        // 첨부파일 클릭
        if ('atchFileCnt' === binding) {
          handleFileUpload(grid, item, ht.row, ht.col);
        }
      }
    });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols style={{ width: '40%' }}>
                <label>
                  <span className="dot">{t('com.label.설비군', '설비군')}</span>
                </label>
                <CustomInputWithSearch
                  name="equipmentGroup"
                  className="find"
                  placeholder={String(
                    t('ip.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.')
                  )}
                  value={equipmentParamCondition.equipmentGroup}
                  onChange={handleParamConditionChange}
                  onSearchClick={() => setOpenEequipmentGroupPopUp(true)}
                  isError={errors?.equipmentGroup}
                  msgError={String(t('ip.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.'))}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols style={{ width: '50%' }}>
                <label>{t('ip.label.설비파라미터번호', '설비파라미터번호')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="eqpParaNo"
                  placeholder={String(
                    t(
                      'ip.msg.설비 파라미터번호를 입력해 주세요.',
                      '설비 파라미터번호를 입력해 주세요.'
                    )
                  )}
                  value={equipmentParamCondition.eqpParaNo}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            setReset={setEquipmentParamCondition}
            onSearch={() => handleSearch(equipmentParamCondition)}
          />
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.설비별 IP List', '설비별 IP List')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => {
              const id = (checkedItems || [])
                .reduce(
                  (acc, cur) => [...acc, `${cur.eqpParaNo}||${cur.versionNo}` as string],
                  [] as string[]
                )
                .join();
              downloadExcelTemplatesPost(FileTypeName.TPL_IP_LIST, id);
            }}
            disableRipple
          >
            {t('com.button.템플릿 다운로드', '템플릿 다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleDownloadExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(equipmentParamCondition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isReadOnly={true}
        height={500}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
        initialized={onInitialized}
      />
      <GlobalBtnGroup>
        {hasAuth && (
          <Button
            css={IconButton.button}
            className="create"
            disableRipple
            onClick={(e) => {
              setMode(ManagementMode.CREATE);
              setOpenParameterManagementModal(true);
              setPageId(ApproveRequestPageType.IP_APPROVE_REQ);
            }}
          >
            {t('com.button.신규', '신규')}
          </Button>
        )}
        {hasAuth && (
          <Button
            css={IconButton.button}
            className="Excelcreate"
            disableRipple
            onClick={(e) => {
              setMode(ManagementMode.CREATE_EXCEL);
              setOpenParameterManagementModal(true);
              setPageId(ApproveRequestPageType.IP_APPROVE_REQ);
            }}
          >
            {t('com.button.엑셀신규', '엑셀신규')}
          </Button>
        )}
        {hasAuth && (
          <Button
            css={IconButton.button}
            className="edit"
            disableRipple
            onClick={(e) => {
              if (checkedItems && checkedItems.length > 0) {
                if (checkedItems.length > 1) {
                  openMessageBar({
                    type: 'error',
                    content: t(
                      'ip.msg.수정할 대상을 1건만 선택해 주세요.',
                      '수정할 대상을 1건만 선택해 주세요.'
                    ),
                  });
                  return;
                }
                if (locationState.request !== null) {
                  openMessageBar({
                    type: 'error',
                    content: t('ip.msg.결재 진행중인 설비입니다.', '결재 진행중인 설비입니다.'),
                  });
                  return;
                }
                setRequestModalCondition({
                  eqpParaNo: checkedItems[0].eqpParaNo,
                  versionNo: checkedItems[0].versionNo,
                });
                setPageId(ApproveRequestPageType.IP_UPDATE_REQ);
                setMode(ManagementMode.MODIFY);
                setOpenParameterManagementModal(true);
              } else {
                openMessageBar({
                  type: 'error',
                  content: t('ip.msg.수정할 대상을 선택해 주세요.', '수정할 대상을 선택해 주세요.'),
                });
              }
            }}
          >
            {t('com.button.수정', '수정')}
          </Button>
        )}
        {hasAuth && (
          <Button
            css={IconButton.button}
            className="Exceledit"
            disableRipple
            onClick={(e) => {
              setMode(ManagementMode.MODIFY_EXCEL);
              setOpenParameterManagementModal(true);
              setPageId(ApproveRequestPageType.IP_UPDATE_REQ);
            }}
          >
            {t('com.button.엑셀수정', '엑셀수정')}
          </Button>
        )}
        {hasAuth && (
          <Button
            css={IconButton.button}
            className="delete"
            onClick={() => {
              if (checkedItems && checkedItems.length > 0) {
                if (checkedItems.length > 1) {
                  openMessageBar({
                    type: 'error',
                    content: t(
                      'com.label.삭제할 항목을 1건만 선택해 주세요.',
                      '삭제할 항목을 1건만 선택해 주세요.'
                    ),
                  });
                  return;
                }
                if (locationState.request !== null) {
                  openMessageBar({
                    type: 'error',
                    content: t('ip.msg.결재 진행중인 설비입니다.', '결재 진행중인 설비입니다.'),
                  });
                  return;
                }
              } else {
                openMessageBar({
                  type: 'error',
                  content: t(
                    'com.label.삭제할 항목을 선택해 주세요.',
                    '삭제할 항목을 선택해 주세요.'
                  ),
                });
                return;
              }
              setRequestModalCondition({
                eqpParaNo: checkedItems[0].eqpParaNo,
                versionNo: checkedItems[0].versionNo,
              });
              setMode(ManagementMode.DELETE);
              setOpenParameterManagementModal(true);
              setPageId(ApproveRequestPageType.IP_DELETE_REQ);
            }}
            disableRipple
          >
            {t('com.label.삭제', '삭제')}
          </Button>
        )}
      </GlobalBtnGroup>
      {isOpenEquipmentGroupPopUp && (
        <EquipmentGroupPopUp
          open={isOpenEquipmentGroupPopUp}
          close={() => setOpenEequipmentGroupPopUp(false)}
          condition={{
            equipmentGroup: equipmentParamCondition.equipmentGroup || '',
          }}
          onCallback={(value) => {
            setEquipmentParamCondition((prev) => ({
              ...prev,
              equipmentGroup: (value as EquipmentGroupCondition).equipmentGroup,
            }));
            setErrors({ equipmentGroup: !value });
          }}
        />
      )}
      {isOpenParameterManagementModal && (
        <ApproveRequestModal
          open={isOpenParameterManagementModal}
          pageId={pageId}
          aprReqId={requestModalCondition?.aprReqId}
          condition={{
            aprReqId: requestModalCondition?.aprReqId || null,
            eqpParaNo: requestModalCondition?.eqpParaNo || null,
            versionNo: requestModalCondition?.versionNo || null,
            mode: mode,
          }}
          close={() => setOpenParameterManagementModal(false)}
        />
      )}

      {isOpenParaPoint && (
        <ParameterPointModal
          open={isOpenParaPoint}
          close={() => setOpenParaPoint(false)}
          condition={paraPointCondition}
          info={paraPointInfo}
        />
      )}
      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          downloadOnly={fileUploadModalCondition.downloadOnly}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            const target = fileUploadModalCondition.target;
            if (target) {
              gridRef.current.setCellData(target.row, target.col, fileCount);
            }
            setOpenFileUploadModal(false);
          }}
        />
      )}
    </>
  );
};
export default EquipmentParameterPage;
