/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { getGatingCheckSheetVerList } from 'apis/gtng/popup/GatingCheckSheetVer';
import { GatingCheckSheetVer } from 'models/gtng/popup/GatingCheckSheetVer';
import { IconButton } from 'components/buttons/IconSVG';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';

/**
 * VersionHistoryPopup
 * 게이팅 시트 버전 이력 조회 팝업
 * 사용화면 : 게이팅 체크시트 등록
 *          게이팅 생성
 * @param props
 * @constructor
 */
export const VersionHistoryPopUp = (props: any) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<GatingCheckSheetVer[]>([]);
  const [checkedItems, setCheckedItems] = useState<GatingCheckSheetVer[]>([]);

  useEffect(() => {
    const code = { gatingStepCode: props.gatingStepCode, gatingTypeCode: props.gatingTypeCode };
    getGatingCheckSheetVerList(props.gatingTemplateId).then((result: GatingCheckSheetVer[]) => {
      setRowData(result);
    });
  }, [props]);

  const onInitialized = (grid) => {
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem;
      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnSelect':
            btnQuickSelect(item.versionNo);
            break;
        }
      }
      grid.refresh();
    });
    grid.hostElement.addEventListener('dblclick', (e) => {
      const ht = grid.hitTest(e);
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
      btnQuickSelect(item.versionNo);
    });
  };

  const layoutDefinition = [
    {
      // header: 'Quick Select',
      cssClass: 'WijmoRelay',
      cellTemplate: '<Button id="btnSelect"/>',
      width: 39,
    },
    {
      binding: 'versionNo',
      header: String(t('gtng.grid.버전', '버전')),
      align: 'center',
    },
    {
      binding: 'changeReasonDesc',
      header: String(t('gtng.label.변경사유', '변경사유')),
      align: 'left',
      width: '*',
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('com.label.최종수정자', '최종수정자')),
      align: 'center',
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('com.label.최종수정일', '최종수정일')),
      align: 'center',
      width: 150,
    },
  ];

  //적용 버튼
  const btnCkVerSelectInfo = () => {
    if ((checkedItems || []).length) {
      props.setVersionData(checkedItems[0]?.versionNo);
      props.setPopup(false);
    } else {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.버전을 선택해 주세요.', '버전을 선택해 주세요.'),
      });
    }
  };

  const btnQuickSelect = (versionNo) => {
    props.setVersionData(versionNo);
    props.setPopup(false);
  };

  const onClose = () => {
    props.setPopup(false);
  };

  const dialogButtons = [
    <Button key={'select'} css={IconButton.button} className="select" onClick={btnCkVerSelectInfo}>
      {t('com.label.선택', '선택')}
    </Button>,
  ];

  return (
    <>
      <CustomDialog
        title={t('gtng.label.Check Sheet Version 조회', 'Check Sheet Version 조회')}
        size={'md'}
        open={true}
        onClose={onClose}
        onCancel={props.isEditable ? onClose : undefined}
        buttons={props.isEditable ? dialogButtons : []}
      >
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={400}
          rowSelection={'single'}
          autoCheck={true}
          isReadOnly={true}
          isFilter={false}
          allowPinning={false}
          initialized={onInitialized}
          onChangeCheckedItem={(items) => setCheckedItems(items)}
        />
      </CustomDialog>
    </>
  );
};
