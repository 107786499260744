import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SVGIcon } from 'components/buttons/IconSVG';
import { ButtonSize, ButtonStyle, ButtonDesign } from 'components/buttons/ButtonStyle';
import { CommonBG, LegendStatusBG, CommonText, CommonBorder } from 'ui/theme/Color';

const AvatarSize = {
  xxlg: css`
    width: 80px;
    height: 80px;
    border-radius: 40px;
    position: relative;
  `,
  xlg: css`
    width: 64px;
    height: 64px;
    border-radius: 32px;
    position: relative;
  `,
  lg: css`
    width: 48px;
    height: 48px;
    border-radius: 24px;
    position: relative;
  `,
  md: css`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: relative;
  `,
  sm: css`
    width: 32px;
    height: 32px;
    border-radius: 16px;
    position: relative;
  `,
  xsm: css`
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: relative;
  `,
  xxsm: css`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: relative;
  `,
};

export const AvatarStyle = {
  icon: css`
    background: ${CommonBG.Secondary};
    -webkit-mask: ${SVGIcon.IcoAvatar};
    mask-image: ${SVGIcon.IcoAvatar};
    mask-repeat: no-repeat;
  `,
  iconInvers: css`
    background: ${CommonBG.Secondary};
    -webkit-mask: ${SVGIcon.IcoAvatarInvers};
    mask-image: ${SVGIcon.IcoAvatarInvers};
    mask-repeat: no-repeat;
  `,
  initial: css`
    background: ${CommonBG.Secondary};
    -webkit-mask: ${SVGIcon.IcoInitial};
    mask-image: ${SVGIcon.IcoInitial};
    mask-repeat: no-repeat;
  `,
  initialInvers: css`
    background: ${SVGIcon.IcoInitialInvers};
  `,
  photo: css`
    background-size: contain;
    border: 1px solid red;
  `,
};

export const AvatarCount = styled.div`
  width: auto;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${CommonBG.Elevation};
  background: ${LegendStatusBG.ErrorNormal};
  border: 1px solid ${CommonBG.Elevation};
  z-index: 999;
  position: absolute;
`;

export const AvatarBadge = styled.div`
  position: absolute;
  border: 1px solid ${CommonBG.Elevation};
  z-index: 999;
  bottom: 0;
  &.confirm {
    background: ${SVGIcon.IcoDotConfirm};
  }
  &.error {
    background: ${SVGIcon.IcoDotError};
  }
  &.warning {
    background: ${SVGIcon.IcoDotWarning};
  }
  &.default {
    background: ${LegendStatusBG.ConfirmedNormal};
  }
`;

export const Avatar = styled.div`
  position: relative;
`;

export const AvatarDiv = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 0px 24px;
  margin-top: 7px;
  &.none {
    cursor: default;
  }
  .name {
    display: block;
    margin-left: 8px;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-weight: 500;
    word-break: keep-all;
    color: ${CommonText.Light};
  }
  &.image {
    & > .avatar {
      ${AvatarStyle.photo};
    }
  }
  &.initial {
    & > .avatar {
      ${AvatarStyle.initial};
    }
  }
  &.initialInvers {
    & > .avatar {
      ${AvatarStyle.initialInvers};
    }
  }
  &.icon {
    & > .avatar {
      ${AvatarStyle.icon};
    }
  }
  &.iconInvers {
    & > .avatar {
      ${AvatarStyle.iconInvers};
    }
  }
  &.xxlg {
    & > .avatar {
      ${AvatarSize.xxlg};
    }
    & > .count {
      min-width: 32px;
      height: 32px;
      border-radius: 16px;
      padding: 4px 10px;
      font-size: 15px;
      top: -6px;
      left: 54px;
    }
    & > .badge {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      left: 56px;
      &:before {
        width: 24px;
        height: 24px;
      }
    }
  }
  &.xlg {
    & > .avatar {
      ${AvatarSize.xlg};
    }
    & > .count {
      min-width: 24px;
      height: 24px;
      border-radius: 12px;
      padding: 4px 7px;
      font-size: 14px;
      top: -3px;
      left: 49px;
    }
    & > .badge {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      left: 44px;
      &:before {
        width: 24px;
        height: 24px;
      }
    }
  }
  &.lg {
    & > .avatar {
      ${AvatarSize.lg};
    }
    & > .name {
      & > span {
        font-size: 16px;
        font-weight: 700;
        color: ${CommonText.Basic};
      }
      font-size: 13px;
      font-weight: 400;
      color: ${CommonText.Light};
    }
    & > .count {
      min-width: 20px;
      height: 20px;
      border-radius: 10px;
      padding: 4px 6px;
      font-size: 12px;
      top: -3px;
      left: 31px;
    }
    & > .badge {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      left: 35px;
      &:before {
        width: 16px;
        height: 16px;
      }
    }
  }
  &.md {
    & > .avatar {
      ${AvatarSize.md};
    }
    & > .count {
      min-width: 20px;
      height: 20px;
      border-radius: 10px;
      padding: 4px 6px;
      font-size: 12px;
      top: -4px;
      left: 24px;
    }
    & > .badge {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      left: 26px;
      &:before {
        width: 16px;
        height: 16px;
      }
    }
  }
  &.sm {
    & > .avatar {
      ${AvatarSize.sm};
    }
    & > .count {
      min-width: 20px;
      height: 20px;
      border-radius: 10px;
      padding: 4px 6px;
      font-size: 12px;
      top: -5px;
      left: 17px;
    }
    & > .badge {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      left: 18px;
      &:before {
        width: 14px;
        height: 14px;
      }
    }
  }
  &.xsm {
    & > .avatar {
      ${AvatarSize.xsm};
    }
    & > .count {
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
      padding: 4px 4px;
      font-size: 11px;
      top: -6px;
      left: 13px;
    }
    & > .badge {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      left: 14px;
      &:before {
        width: 14px;
        height: 14px;
      }
    }
  }
  &.xxsm {
    & > .avatar {
      ${AvatarSize.xxsm};
    }
    & > .count {
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
      padding: 4px 4px;
      font-size: 11px;
      top: -8px;
      left: 15px;
    }
    & > .badge {
      width: 12px;
      height: 12px;
      border-radius: 6px;
      left: 12px;
      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const AvatarMenu = {
  div: (active: boolean) => css`
    display: ${active ? 'block' : 'none'};
    position: absolute;
    top: 48px;
    right: 0;
    z-index: 999;
    width: 400px;
    background: ${CommonBG.Elevation};
    border: 1px solid ${CommonBorder.Strong};
    border-radius: 3px;
    padding: 24px 0px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.22), 0 7px 10px rgba(0, 0, 0, 0.12);
  `,
};

export const SettingMenu = {
  div: (active: boolean) => css`
    display: ${active ? 'block' : 'none'};
    position: absolute;
    top: 56px;
    right: 125px;
    z-index: 999;
    width: 400px;
    background: ${CommonBG.Elevation};
    border: 1px solid ${CommonBorder.Strong};
    border-radius: 3px;
    padding: 24px 0px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.22), 0 7px 10px rgba(0, 0, 0, 0.12);
    .SettingPopup {
      .select {
        margin: 0px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & + div {
          margin-top: 12px;
        }
        & > label {
          font-size: 13px;
        }
      }
    }
  `,
};

export const AvatarCon = styled.div`
  margin: 12px 24px 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const LayerBtnGroup = styled.div`
  padding-top: 12px;
  &.setting {
    padding-top: 0px;
  }
  button {
    ${ButtonSize.sm};
    &.logout {
      ${ButtonStyle.outlineNormal};
      ${ButtonDesign.IconLfSM};
      &:before {
        background: ${CommonText.Light};
        -webkit-mask: ${SVGIcon.IcoLogout};
        mask-image: ${SVGIcon.IcoLogout};
      }
    }
    &.apply {
      ${ButtonStyle.containedPrimary};
      ${ButtonDesign.IconLfSM};
      &:before {
        background: ${CommonBG.Basic};
        -webkit-mask: ${SVGIcon.IcoCheck};
        mask-image: ${SVGIcon.IcoCheck};
      }
    }
    &.cancel {
      ${ButtonStyle.outlineNormal};
      ${ButtonDesign.labelSM};
    }
    & + button {
      margin-left: 4px;
    }
  }
`;

export const AlertList = styled.div`
  max-height: 400px;
  padding: 0 0 0 24px;
  overflow: overlay;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(60, 62, 61, 0.4);
    width: 6px;
    border-radius: 3px;
  }
`;

export const AlertCon = styled.div`
  margin-right: 24px;
  border-top: 1px solid ${CommonBorder.Basic};
  margin-top: 12px;
  padding-top: 12px;
  .TitleArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    .title {
      font-size: 13px;
      font-weight: 700;
      color: ${CommonText.Primary};
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .date {
      font-size: 12px;
      font-weight: 400;
      color: ${CommonText.Light};
    }
  }
  .AlertCon {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 400;
    color: ${CommonText.Basic};
  }
  .AlertButton {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      ${ButtonSize.sm}
      ${ButtonDesign.labelSM}
      ${ButtonStyle.outlineNormal}
    }
  }
`;
