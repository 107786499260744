/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { HalfContetntLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { CommonYN } from 'models/common/Common';
import { useMessageBar } from 'hooks/useMessageBar';
import { CrudCode } from 'models/common/Edit';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ComboBox } from 'components/selects/ComboBox';
import { MpTargetCondition } from 'models/mp/MpTarget';
import { findMpTargetEquipment, findMpTargetFactory, findExistsMpTarget } from 'apis/mp/MpTarget';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { MpItemTpCho } from 'models/mp/MpItem';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';

interface Props {
  open: boolean;
  close: (isDone?: boolean) => void;
  condition: any;
  rowData: any[];
  setRowData: any;
}
export const MpTargetFactoryPopUp = ({ open, close, condition, rowData, setRowData }: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();

  const [code, setCode] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [mpTargetCondition, setMpTargetCondition] = useState<MpTargetCondition>({});

  const [eGridRef, setEGridRef] = useState<any>();
  const [fGridRef, setFGridRef] = useState<any>();
  const [factoryData, setFactoryData] = useState<any[]>([]);
  const [equipmentData, setEquipmentData] = useState<any[]>([]);
  const [colWidthMap, setColWidthMap] = useState<any>({
    factoryNm: '*',
    mpClsfNm: 100,
    copCd: 100,
    factoryCd: 100,
  });

  const [colWidthMapEquip, setColWidthMapEquip] = useState<any>({
    equipmentGroup: 130,
    equipmentGroupName: '*',
  });

  useEffect(() => {
    mpTargetCondition.mpClsfType = condition.mpClsfType;
    getCommonCodesForGrid();
  }, []);

  const getCommonCodesForGrid = async () => {
    const mpClsfType: Code[] = await getCommonCodeNames('MP_CLSF_TYPE'); // MP분류
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    const factoryCode: Code[] = await getCommonCodeNames('FACTORY_CODE'); // 공장
    const ynFlag: Code[] = await getCommonCodeNames('YN_FLAG'); // 대상여부
    setCode({
      mpClsfType: mpClsfType,
      elmCopCd: elmCopCd,
      factoryCode: factoryCode,
      ynFlag: ynFlag,
    });
  };

  const layoutDefinition = [
    {
      binding: 'mpClsfNm',
      header: String(t('mp.grid.MP 분류', 'MP 분류')),
      width: colWidthMap.mpClsfNm,
    },
    {
      binding: 'copCd',
      header: String(t('mp.grid.법인', '법인')),
      width: colWidthMap.copCd,
    },
    {
      binding: 'factoryCd',
      header: String(t('mp.grid.공장(동)', '공장(동)')),
      width: colWidthMap.factoryCd,
    },
    {
      binding: 'factoryNm',
      header: String(t('mp.grid.공장명', '공장명')),
      width: colWidthMap.factoryNm,
      align: 'left',
    },
    {
      binding: 'mpClsfCd',
      visible: false,
    },
  ];

  const equipLayoutDefinition = [
    {
      binding: 'equipmentGroup',
      header: String(t('com.label.설비군', '설비군')),
      width: colWidthMapEquip.equipmentGroup,
    },
    {
      binding: 'equipmentGroupName',
      header: String(t('mp.grid.설비군명', '설비군명')),
      width: colWidthMapEquip.equipmentGroupName,
      align: 'left',
    },
  ];

  const onInitialized = (grid) => {
    setFGridRef(grid);

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });
  };

  const onInitializedEquip = (grid) => {
    setEGridRef(grid);

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      colWidthMapEquip[col.binding] = col.width;
      setColWidthMapEquip(colWidthMapEquip);
    });
  };

  const handleClose = () => {
    close();
  };

  const handleReset = async () => {
    setMpTargetCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as MpTargetCondition
    );

    setFactoryData([]);
    setEquipmentData([]);
  };

  const handleSearchFactory = async () => {
    const err = {};
    if (!mpTargetCondition.mpClsfType) {
      Object.assign(err, { mpClsfType: true });
    }
    if (!mpTargetCondition.elmCopCd) {
      Object.assign(err, { elmCopCd: true });
    }
    setErrors(err);
    if (Object.keys(err).filter((k) => err[k]).length) {
      return;
    }

    const response = await findMpTargetFactory(mpTargetCondition);
    if (response) {
      const rowData = response || [];
      setFactoryData(rowData);
    }
  };

  const handleSearchEquipment = async () => {
    if (!mpTargetCondition.equipmentGroup) {
      setErrors({ equipmentGroup: true });
      return;
    }

    const response = await findMpTargetEquipment(mpTargetCondition);
    if (response) {
      const rowData = response || [];
      setEquipmentData(rowData);
    }
  };

  const handleParamConditionChange = (name: string, value: string) => {
    setMpTargetCondition({
      ...mpTargetCondition,
      [name]: value,
    });
    setErrors({ ...errors, [name]: false });
  };

  const addRow = () => {
    const fData = fGridRef.selectedRows;
    const eData = eGridRef.selectedRows;

    if (fData.length < 1 || !fData[0].dataItem.factoryCd) {
      openMessageBar({
        type: 'error',
        content: t('mp.msg.공장(동)을 선택해 주세요.', '공장(동)을 선택해 주세요.'),
      });
      return false;
    }

    if (eData.length < 1 || !eData[0].dataItem.equipmentGroup) {
      openMessageBar({
        type: 'error',
        content: t('mp.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.'),
      });
      return false;
    }

    const newRows = fData.flatMap((fRow) =>
      eData.map((eRow, index) => ({
        ...fRow.dataItem,
        no: index + 1,
        crudKey: CrudCode.CREATE,
        itemTpChoCd: MpItemTpCho.HE, // [24.05.29] 수평전개 고정
        mpClsf: fRow.dataItem.mpClsfCd,
        factory: fRow.dataItem.factoryCd,
        // mpClsf: fData[0]?.mpClsfCd, // mp분류
        // factory: fData[0]?.factoryCd,
        eqpGr: eRow.dataItem.equipmentGroup,
        eqpGrNm: eRow.dataItem.equipmentGroupName,
        useYn: CommonYN.Y,
        heYn: CommonYN.Y, // [24.05.29] 수평전개 고정
        mpYn: CommonYN.N,
        niYn: CommonYN.N,
      }))
    );

    const existDuplicate = (mpClsf, copCd, factory, eqpGr) => {
      return (
        newRows.filter(
          (o) =>
            o.mpClsf === mpClsf && o.copCd === copCd && o.factory === factory && o.eqpGr === eqpGr
        ).length > 0
      );
    };
    const existsRows = (rowData || []).filter((o) =>
      existDuplicate(o.mpClsf, o.copCd, o.factory, o.eqpGr)
    );
    const valid = (existsRows || [])
      .map((o, index) => `${index + 1} : ${o.mpClsfNm}, ${o.copCd}, ${o.factory}, ${o.eqpGr}`)
      .filter((element) => element !== undefined);
    // [24.05.15] MP분류, 법인, 공장(동), 설비군은 중복 불가
    if (valid.length) {
      openMessageBar({
        type: 'error',
        content: t(
          'mp.msg.이미 등록된 데이터가 있습니다.',
          `이미 등록된 데이터가 있습니다.<br/>${valid.join('<br/>')}`
        ),
      });
      return;
    }
    findExistsMpTarget(newRows)
      .then((res) => {
        if (res.successOrNot === 'Y') {
          const valid = (res.data || [])
            .map((o, index) => `${index + 1} : ${o.mpClsfNm}, ${o.copCd}, ${o.factory}, ${o.eqpGr}`)
            .filter((element) => element !== undefined);

          if (valid.length) {
            openMessageBar({
              type: 'error',
              content: t(
                'mp.msg.이미 등록된 데이터가 있습니다.',
                `이미 등록된 데이터가 있습니다.<br/>${valid.join('<br/>')}`
              ),
            });
            return;
          }
          setRowData([...newRows, ...rowData]);
          handleClose();
        } else {
          openMessageBar({
            type: 'error',
            content:
              res.data && typeof res.data === 'string'
                ? res.data
                : t(
                    'mp.msg.중복데이터 확인 중 오류가 발생했습니다.',
                    `중복데이터 확인 중 오류가 발생했습니다.`
                  ),
          });
        }
      })
      .catch(() => {
        openMessageBar({
          type: 'error',
          content: t(
            'mp.msg.중복데이터 확인 중 오류가 발생했습니다.',
            `중복데이터 확인 중 오류가 발생했습니다.`
          ),
        });
      });
  };

  const dialogButtons = [
    <Button key={'reset'} onClick={handleReset}>
      {t('com.button.초기화', '초기화')}
    </Button>,
    <Button key={'add'} css={IconButton.button} className="plus" onClick={addRow}>
      {t('com.label.추가', '추가')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('mp.label.MP 대상 공장 설정', 'MP 대상 공장 설정')}
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      buttons={dialogButtons}
    >
      <HalfContetntLayout>
        <div>
          <SubTitleGroup>
            <h3>{t('mp.label.공장(동)', '공장(동)')}</h3>
          </SubTitleGroup>
          <SearchBox style={{ marginTop: '8px' }}>
            <SearchBoxRow>
              <InputBox>
                <SearchRows className="twoColhalf">
                  <SearchCols>
                    <label>
                      <span className="dot">{t('mp.label.MP 분류', 'MP 분류')}</span>
                    </label>
                    <ComboBox
                      placeholder={String(
                        t('mp.msg.MP 분류를 선택해 주세요.', 'MP 분류를 선택해 주세요.')
                      )}
                      options={code?.mpClsfType}
                      defaultValue={mpTargetCondition?.mpClsfType}
                      isError={errors?.mpClsfType}
                      msgError={String(
                        t('mp.msg.MP 유형을 선택해 주세요.', 'MP 유형을 선택해 주세요.')
                      )}
                      onChange={(value) => handleParamConditionChange('mpClsfType', value)}
                    />
                  </SearchCols>
                  <SearchCols>
                    <label>
                      <span className="dot">{t('mp.label.법인', '법인')}</span>
                    </label>
                    <ComboBox
                      placeholder={String(
                        t('mp.msg.법인을 선택해 주세요.', '법인을 선택해 주세요.')
                      )}
                      options={code?.elmCopCd}
                      defaultValue={mpTargetCondition?.elmCopCd}
                      isError={errors?.elmCopCd}
                      msgError={String(t('mp.msg.법인을 선택해 주세요.', '법인을 선택해 주세요.'))}
                      onChange={(value) => handleParamConditionChange('elmCopCd', value)}
                    />
                  </SearchCols>
                </SearchRows>
              </InputBox>
              <SearchButtonWrap>
                <Button
                  css={IconButton.button}
                  className="reload"
                  onClick={() => {
                    setMpTargetCondition((prev) => ({
                      ...prev,
                      mpClsfType: '',
                      elmCopCd: '',
                    }));
                  }}
                  disableRipple
                ></Button>
                <Button css={IconButton.button} onClick={handleSearchFactory} className="find">
                  {t('com.button.조회', '조회')}
                </Button>
              </SearchButtonWrap>
            </SearchBoxRow>
          </SearchBox>
          <CustomGrid
            layoutDefinition={layoutDefinition}
            rowData={factoryData}
            height={250}
            align="center"
            allowPinning={false}
            isFilter={false}
            isReadOnly={true}
            autoCheck={true}
            style={{ marginTop: '8px' }}
            initialized={onInitialized}
          />
        </div>
        <div>
          <SubTitleGroup>
            <h3>{t('com.label.설비군', '설비군')}</h3>
          </SubTitleGroup>
          <SearchBox style={{ marginTop: '8px' }}>
            <SearchBoxRow>
              <InputBox>
                <SearchRows className="none">
                  <SearchCols>
                    <label>
                      <span className="dot">{t('mp.label.설비군/명', '설비군/명')}</span>
                    </label>
                    <CustomInputWithSearch
                      name="equipmentGroup"
                      placeholder={String(
                        t('mp.msg.설비군/명을 입력해 주세요.', '설비군/명을 입력해 주세요.')
                      )}
                      value={mpTargetCondition?.equipmentGroup}
                      onChange={(e) => handleParamConditionChange(e.target.name, e.target.value)}
                      isError={errors?.equipmentGroup}
                      msgError={String(
                        t('mp.msg.설비군/명을 입력해 주세요.', '설비군/명을 입력해 주세요.')
                      )}
                      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                          handleSearchEquipment();
                        }
                      }}
                    />
                  </SearchCols>
                </SearchRows>
              </InputBox>
              <SearchButtonWrap>
                <Button
                  css={IconButton.button}
                  className="reload"
                  onClick={() => setMpTargetCondition((prev) => ({ ...prev, equipmentGroup: '' }))}
                ></Button>
                <Button css={IconButton.button} onClick={handleSearchEquipment} className="find">
                  {t('com.button.조회', '조회')}
                </Button>
              </SearchButtonWrap>
            </SearchBoxRow>
          </SearchBox>
          <CustomGrid
            layoutDefinition={equipLayoutDefinition}
            rowData={equipmentData}
            height={250}
            align="center"
            allowPinning={false}
            isFilter={false}
            isReadOnly={true}
            autoCheck={true}
            style={{ marginTop: '8px' }}
            initialized={onInitializedEquip}
          />
        </div>
      </HalfContetntLayout>
    </CustomDialog>
  );
};
