/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomGrid from 'components/grids/CustomGrid';
import { findElectricSummary } from 'apis/ut/UtMatrixRegist';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';
import useEvent from 'react-use-event-hook';
import { CollectionView } from '@grapecity/wijmo';

interface SearchParam {
  totalStandard?: string;
}

interface Props {
  electricRowData?: any;
}

const ElectricSummaryContent = ({ electricRowData }: Props, ref) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<any[]>([]);
  const [flexRef, setFlexRef] = useState<any>();
  const [afBinding, setAfBinding] = useState<any>();
  const [bfBinding, setBfBinding] = useState<any>();

  useImperativeHandle(ref, () => ({
    btnElecExcelExport: () => {
      btnElecExcelExport();
    },
  }));

  const flexLayoutDefinition = useMemo(() => {
    const prevHeaderNames = ['_LINE_QTY', '_EQP_QTY', '_ELEC', '_ELEC_DMD'];

    const parentHeaderNames = [
      String(t('ut.label.라인', '라인')),
      String(t('ut.label.설비', '설비')),
      String(t('ut.label.수용율 적용 전', '수용율 적용 전')),
      String(t('ut.label.수용율 적용 후', '수용율 적용 후')),
      // String(t('ut.label.증감', '증감')),
    ];

    const flexLayout = [] as any[];
    const arr = [] as any[];

    if (electricRowData !== undefined) {
      const childHeader: string[] = [];

      if (electricRowData?.col.length > 0) {
        for (let i = 0; i < prevHeaderNames.length; i++) {
          for (let j = 0; j < electricRowData.col.length; j++) {
            const data = "'" + electricRowData.col[j].KEY + "'" + prevHeaderNames[i];
            childHeader.push(data);
          }
        }
      }

      const result = {};

      for (let i = 0; i < childHeader.length; i += electricRowData.col.length) {
        const key = Math.floor(i / electricRowData.col.length);
        result[key] = childHeader.slice(i, i + electricRowData.col.length);
      }

      let prevHeaderName = '';
      let prevHeaderIdx = 0;
      if (parentHeaderNames.length > 0) {
        parentHeaderNames.forEach((item, index) => {
          // Header 추가
          if (prevHeaderName !== item) {
            prevHeaderIdx = index;
            prevHeaderName = item;
            flexLayout.push({ header: item, align: 'center', columns: [] });
          }
          electricRowData.col.forEach((item, i) => {
            flexLayout[flexLayout.length - 1]['columns'].push({
              header: i + 1,
              align: 'right',
              width: '*',
              minWidth: 80,
              binding: result[index][i],
            });
          });
        });

        arr.push({
          header: String(t('ut.label.증감', '증감')),
          align: 'center',
          columns: [],
          width: 300,
        });
        const bfBinding = [] as any[];
        for (let i = parentHeaderNames.length - 2; i <= parentHeaderNames.length - 2; i++) {
          // for (let j = electricRowData.col.length - 2; j <= electricRowData.col.length - 1; j++) {
          //   arr[arr.length - 1]['columns'].push({
          //     header: j + 1,
          //     align: 'right',
          //     width: 100,
          //     binding: result[i][j],
          //     visible: true,
          //   });
          //   bfBinding.push(result[i][j]);
          // }

          for (let j = 0; j <= 1; j++) {
            bfBinding.push(result[i][j]);
          }

          arr[arr.length - 1]['columns'].push({
            binding: 'beforApply',
            header: String(t('ut.label.적용 전', '적용 전')),
            align: 'right',
            width: 150,
            cellTemplate: (item) => {
              if (
                Boolean(item.item[bfBinding[0]]) === false &&
                Boolean(item.item[bfBinding[1]]) === false
              ) {
                return '';
              } else if (Boolean(item.item[bfBinding[0]]) === false) {
                return String(parseFloat((Number(item.item[bfBinding[1]]) - 0).toFixed(2)));
              } else if (Boolean(item.item[bfBinding[1]]) === false) {
                return String(parseFloat(Number(0 - item.item[bfBinding[0]]).toFixed(2)));
              } else if (
                Boolean(item.item[bfBinding[0]]) === true &&
                Boolean(item.item[bfBinding[1]]) === true
              ) {
                return String(
                  parseFloat(
                    (Number(item.item[bfBinding[1]]) - Number(item.item[bfBinding[0]])).toFixed(2)
                  )
                );
              }
            },
          });
        }
        const afBinding = [] as any[];
        for (let i = parentHeaderNames.length - 1; i <= parentHeaderNames.length - 1; i++) {
          // for (let j = electricRowData.col.length - 2; j <= electricRowData.col.length - 1; j++) {
          //   arr[arr.length - 1]['columns'].push({
          //     header: j + 1,
          //     align: 'right',
          //     width: 100,
          //     binding: result[i][j],
          //     visible: true,
          //   });
          //   afBinding.push(result[i][j]);
          // }

          for (let j = 0; j <= 1; j++) {
            afBinding.push(result[i][j]);
          }

          arr[arr.length - 1]['columns'].push({
            binding: 'afterApply',
            header: String(t('ut.label.적용 후', '적용 후')),
            align: 'right',
            width: 150,
            cellTemplate: (item) => {
              if (
                Boolean(item.item[afBinding[0]]) === false &&
                Boolean(item.item[afBinding[1]]) === false
              ) {
                return '';
              } else if (Boolean(item.item[afBinding[0]]) === false) {
                return String(parseFloat((Number(item.item[afBinding[1]]) - 0).toFixed(2)));
              } else if (Boolean(item.item[afBinding[1]]) === false) {
                return String(parseFloat((0 - Number(item.item[afBinding[0]])).toFixed(2)));
              } else if (
                Boolean(item.item[afBinding[0]]) === true &&
                Boolean(item.item[afBinding[1]]) === true
              ) {
                return String(
                  parseFloat(
                    (Number(item.item[afBinding[1]]) - Number(item.item[afBinding[0]])).toFixed(2)
                  )
                );
              }
            },
          });
        }

        setBfBinding(bfBinding);
        setAfBinding(afBinding);
      }
    }

    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'PRDN_PROC_NM',
        header: String(t('ut.label.공정', '공정')),
        width: 100,
      },
      {
        binding: 'VLTG_NVL',
        header: String(t('ut.label.전압', '전압')),
        width: '*',
        minWidth: 100,
      },
      ...flexLayout,
      ...arr,
    ];
  }, [electricRowData]);

  const onInitialized = (grid) => {
    setFlexRef(grid);
  };

  const btnElecExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = '전기요약';
    book.saveAsync(getExcelFileName(t('ut.label.전기요약', '전기요약')));
  };

  return (
    <>
      {/* <SubTitleLayout> */}
      {/* <SubTitleGroup>
          <h3>{String(t('ut.label.전기요약', '전기요약'))}</h3>
          <span className="total">
            {String(t('com.label.총', '총'))}
            <span>{(electricRowData?.list || []).length}</span>
            {String(t('com.label.건', '건'))}
          </span>
        </SubTitleGroup> */}
      {/* <ControlBtnGroup>
          <label>{String(t('ut.label.집계기준', '집계기준'))}</label>
          <ComboBox
            options={code?.sumBaseCd}
            defaultValue={searchParam.totalStandard}
            onChange={(value) => {
              setSearchParam({
                ...searchParam,
                totalStandard: value,
              });
            }}
          />
          <Button css={IconButton.button} className="request" disableRipple>
            {String(t('com.label.결과보기', '결과보기'))}
          </Button>
          <Button css={IconButton.button} className="Exceldown" disableRipple>
            {String(t('com.label.다운로드', '다운로드'))}
          </Button>
        </ControlBtnGroup> */}
      {/* </SubTitleLayout> */}
      <CustomGrid
        layoutDefinition={flexLayoutDefinition}
        rowData={electricRowData?.list || []}
        initialized={onInitialized}
        height={388}
        isReadOnly={true}
        isSelector={false}
      />
    </>
  );
};

export default React.forwardRef(ElectricSummaryContent);
