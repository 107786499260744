/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { Button } from '@mui/material';
import { CommonBorder } from 'ui/theme/Color';
import useSessionStore from 'stores/useSessionStore';
import { Logo } from './components/Logo';
import {
  Avatar,
  AvatarDiv,
  AvatarMenu,
  SettingMenu,
  AvatarCon,
  LayerBtnGroup,
  AvatarCount,
  AlertList,
  AlertCon,
} from './components/HeadLinkList/Avatar';
import { BookmarkButton } from './components/HeadLinkList/BookmarkButton';
import { InfoButton } from './components/HeadLinkList/InfoButton';
import { SettingButton } from './components/HeadLinkList/SettingButton';
import { SwitchButton } from './components/HeadLinkList/SwitchButton';
import { HeadLinkList } from './HeadLinkList';
import { LanguageButton } from './components/LanguageButton/LanguageButton';
import { getUserInfo } from 'apis/common/Employee';
import { devLogout, getSession } from 'apis/Session';
import { CloseBtn } from 'components/buttons/CustomButton';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ComboBox } from 'components/selects/ComboBox';
import { ComboWithoutBox } from 'components/selects/ComboWithoutBox';
import useLanguageStore from 'stores/useLanguageStore';
import { LangType } from 'models/common/Session';
import { TitleWrap } from 'components/layouts/Dialog';
import { SVGIcon } from 'components/buttons/IconSVG';
import { CommonText } from 'ui/theme/Color';
import { IconButton } from 'components/buttons/IconSVG';

// import { SitemapButton } from './components/HeadLinkList/SitemapButton';
// import { NewWindowButton } from './components/HeadLinkList/NewWindowButton';
const HeadMenuBar = () => {
  const { userId, empNm, langCd, jtiNm, deptNm, tmzFrmtCd, gptTmzCd, resetSession } =
    useSessionStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState('');
  const [isOpenAvatarMenu, setOpenAvatarMenu] = useState<boolean>(false);
  const [isOpenSettingMenu, setOpenSettingMenu] = useState<boolean>(false);
  const [timeZoneCode, setTimeZoneCode] = useState<Code[]>([]);
  const [empInfoCode, setEmpInfoCode] = useState<Code[]>([]);
  const [languageCode, setLanguageCode] = useState<Code[]>([]);
  const [timeFormatCode, setTimeFormatCode] = useState<Code[]>([]);
  const [errors, setErrors] = useState<any>({});
  const { changeLanguage } = useLanguageStore();
  useEffect(() => {
    const init = async () => {
      if (userId) {
        setUserInfo(await getUserInfo());
      }
    };
    init();
    if (userId && languageCode.length == 0) {
      getCommonCodes();
    }
  });

  useEffect(() => {
    if (navigator.userAgent.toLowerCase().indexOf('edge') != -1) {
      document.addEventListener('mousedown', handleDetectsClick);
    } else {
      document.addEventListener('touchstart', handleDetectsClick);
    }
    return () => {
      if (navigator.userAgent.toLowerCase().indexOf('edge') != -1) {
        document.removeEventListener('mousedown', handleDetectsClick);
      } else {
        document.removeEventListener('touchstart', handleDetectsClick);
      }
    };
  }, []);

  const getCommonCodes = async () => {
    setLanguageCode((await getCommonCodeNames('LANG_CD')) ?? []);
    setEmpInfoCode((await getCommonCodeNames('ELM_EMP_INFO')) ?? []);
    setTimeZoneCode((await getCommonCodeNames('GPT_TMZ_CD')) ?? []);
    setTimeFormatCode((await getCommonCodeNames('TMZ_FRMT_CD')) ?? []);
  };

  const isDev = useMemo(() => {
    const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
    return appEnv == 'local' || appEnv == 'dev' || appEnv == 'stg';
  }, []);

  const handleLogout = async () => {
    const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
    if (isDev) {
      const response = await devLogout();
      if (response.successOrNot === 'Y') {
        resetSession();
      }
      navigate('/', { replace: true });
      window.location.reload();
      //window.close();
    }
    setOpenAvatarMenu(false);
  };

  const handleOpenAvatarMenu = () => {
    if (isDev) {
      setOpenSettingMenu(false);
      setOpenAvatarMenu(true);
    }
  };

  const handleMoveUserEditPage = () => {
    if (userId.includes('@') && userId.includes('.')) {
      navigate('/system/user-edit', {
        replace: true,
        state: {
          mnuNm: t('mp.label.협력업체 사용자 정보 수정', '협력업체 사용자 정보 수정'),
        },
      });
    } else {
      return false;
    }
  };

  const handleOpenSettingPopup = () => {
    if (isDev) {
      setOpenAvatarMenu(false);
      setOpenSettingMenu(true);
    }
  };

  const handleChangeLang = (value) => {
    changeLanguage(value as LangType);
    navigate('/', { replace: true });
    window.location.reload();
  };

  const handleChangeCombo = (value) => {
    for (let i = 0; i < empInfoCode.length; i++) {
      const combo = document.getElementsByClassName(empInfoCode[i].cmnCd!)[0] as HTMLElement;
      (combo.parentElement as HTMLElement).style.display = 'none';
    }
    const selCombo = document.getElementsByClassName(value)[0] as HTMLElement;
    (selCombo.parentElement as HTMLElement).style.display = 'block';
  };

  const handleChangeTMZ = (value) => {
    useSessionStore.getState().setGptTmzCd(value);
  };

  const handleChangeFrmt = (value) => {
    useSessionStore.getState().setTmzFrmtCd(value);
  };

  const handleSettingApply = async () => {
    window.location.reload();
  };

  /**
   * 외부 클릭 감지 이벤트
   * @param e
   */
  const handleDetectsClick = (e) => {
    let isSettingBox = false;
    let isAvatarBox = false;
    if (e.path && e.path !== undefined) {
      e.path.forEach((item) => {
        if (typeof item.className === 'string') {
          if (item.className?.includes('SettingBox')) {
            isSettingBox = true;
          }
          if (item.className?.includes('AvatarBox')) {
            isAvatarBox = true;
          }
        }
      });
    }

    if (isSettingBox) {
      setOpenSettingMenu(true);
    } else {
      setOpenSettingMenu(false);
    }

    if (isAvatarBox) {
      setOpenAvatarMenu(true);
    } else {
      setOpenAvatarMenu(false);
    }
  };

  return (
    <div css={st.root(isDev, process.env.REACT_APP_NODE_ENV)}>
      <div css={st.inner}>
        <Logo />
      </div>
      <div>
        <HeadLinkList />
      </div>
      <div css={st.right}>
        {/*
        <BookmarkButton />
        <InfoButton />
        <LanguageButton />
        <SwitchButton />
        */}
        {userId && (
          <ComboBox
            options={languageCode}
            defaultValue={langCd}
            isWrap={false}
            isError={errors?.languageCode}
            msgError={'선택해 주세요.'}
            className="language"
            onChange={(value) => handleChangeLang(value)}
          />
        )}
        {userId && empInfoCode.length > 0 && (
          <Button css={st.btn} onClick={handleOpenSettingPopup}></Button>
        )}
        <div className="SettingBox" css={SettingMenu.div(isOpenSettingMenu)}>
          <div className="SettingPopup">
            {empInfoCode.filter((o) => o.cmnCd === 'GPT_TMZ_CD')[0]?.cmnCdNm && (
              <div className="select">
                <label>{empInfoCode.filter((o) => o.cmnCd === 'GPT_TMZ_CD')[0]?.cmnCdNm}</label>
                <ComboBox
                  options={timeZoneCode}
                  defaultValue={gptTmzCd}
                  className="GPT_TMZ_CD"
                  isWrap={false}
                  onChange={(value) => handleChangeTMZ(value)}
                />
              </div>
            )}
            {empInfoCode.filter((o) => o.cmnCd === 'TMZ_FRMT_CD')[0]?.cmnCdNm && (
              <div className="select">
                <label>{empInfoCode.filter((o) => o.cmnCd === 'TMZ_FRMT_CD')[0]?.cmnCdNm}</label>
                <ComboBox
                  options={timeFormatCode}
                  defaultValue={tmzFrmtCd}
                  className="TMZ_FRMT_CD"
                  isWrap={false}
                  onChange={(value) => handleChangeFrmt(value)}
                />
              </div>
            )}
            {empInfoCode.length > 0 && (
              <AvatarCon>
                <LayerBtnGroup>
                  <Button className="cancel" onClick={() => setOpenSettingMenu(false)}>
                    취소
                  </Button>
                  <Button className="apply" onClick={handleSettingApply}>
                    저장
                  </Button>
                </LayerBtnGroup>
              </AvatarCon>
            )}
          </div>
        </div>
        {userId && (
          <Avatar>
            <AvatarDiv className="iconInvers xsm" onClick={handleOpenAvatarMenu}>
              <div className="avatar"></div>
              <div className="name">{empNm || userId}</div>
            </AvatarDiv>
            <div className="AvatarBox" css={AvatarMenu.div(isOpenAvatarMenu)}>
              <AvatarDiv className="iconInvers lg" onClick={handleMoveUserEditPage}>
                <div className="avatar"></div>
                <div className="name">
                  {/*<span>홍길동 책임</span> OOO센터 OOOO팀{' '}*/}
                  <span>
                    {empNm} {jtiNm}
                  </span>{' '}
                  {deptNm}
                </div>
                {/* <AvatarCount className="count">9</AvatarCount> */}
                {/* <AvatarBadge className="badge confirm" /> */}
              </AvatarDiv>
              <CloseBtn onClick={() => setOpenAvatarMenu(false)} />
              <AvatarCon>
                <LayerBtnGroup>
                  <Button className="logout" onClick={handleLogout}>
                    로그아웃
                  </Button>
                </LayerBtnGroup>
              </AvatarCon>
            </div>
          </Avatar>
        )}
      </div>
    </div>
  );
};

const st = {
  root: (server: boolean, env?: string) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 52px;
    z-index: 999;
    background: ${env === 'stg' ? '#F0DEFB' : server ? '#FFE56A' : '#F7F9F8'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 22px;
    border-bottom: 1px solid ${CommonBorder.Basic};
  `,
  inner: css`
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 52px;
  `,
  right: css`
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  btn: css`
    min-width: 24px;
    padding: 0 !important;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 24px;
      height: 24px;
      background: ${CommonText.Light};
      -webkit-mask: ${SVGIcon.IcoSetting};
      mask-image: ${SVGIcon.IcoSetting};
      mask-size: 24px;
    }
  `,
};

export default React.memo(HeadMenuBar);
