import { BmkAtcTpCd, BookmarkCondition, BookmarkMaster } from 'models/common/Bookmark';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

/**
 * 나의 관심 항목 조회
 */
export const findBookmark = async (bmkAtcTpCd: BmkAtcTpCd) => {
  const condition = {
    bmkAtcTpCd: bmkAtcTpCd,
  } as BookmarkCondition;

  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/bookmark`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<BookmarkMaster[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as BookmarkMaster[];
};
