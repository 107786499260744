import { create } from 'zustand';
import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

interface LoadingStore {
  isLoading: boolean;
  openLoading: (b: boolean) => void;
}

export const useLoading = create<LoadingStore>((set, get) => {
  return {
    isLoading: false,
    openLoading: (e) => {
      set({ isLoading: e });
    },
  };
});

export const Loading = () => {
  const { isLoading } = useLoading();

  return <ProgressCircle hidden={!isLoading} />;
};

function ProgressCircle(props: CircularProgressProps) {
  return (
    <ProgressWrap hidden={props.hidden}>
      <Box>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          }}
          size={60}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => (theme.palette.mode === 'light' ? '#3EC2CF' : '#308fe8'),
            animationDuration: '1.5s',
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={60}
          thickness={4}
          {...props}
        />
      </Box>
    </ProgressWrap>
  );
}

const ProgressWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1500; // [23.10.15] Dialog z-index 보다 높게 수정
  background: rgba(0, 0, 0, 0.4);
  .MuiCircularProgress-determinate {
    color: #ebeeed;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  .MuiCircularProgress-indeterminate {
    color: #3ec2cf;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
  }
  .MuiCircularProgress-circle {
    stroke-dasharray: 30px, 200px;
  }
`;
