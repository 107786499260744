/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentGrid } from '../../../components/layouts/ContentGrid';
import {
  SubTitleLayout,
  SubTitleGroup,
  GlobalBtnGroup,
  Chart2Div,
} from 'components/layouts/ContentLayout';
import { findGatingResultReportAct } from 'apis/gtng/GatingResultReport';
import { GatingResultReportAct } from 'models/gtng/GatingResultReportAct';
import { CustomInputWrap, CustomInputText } from 'components/inputs/CustomInput';
import { FlexRadar, FlexRadarAxis, FlexRadarSeries } from '@grapecity/wijmo.react.chart.radar';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import {
  confirmGatingJudgementAct,
  findGatingJudgementReportDtl,
  findGatingJudgementReportDtlEquipment,
  findGatingjudgementReportReqSummary,
  findGatingjudgementReportSummary,
  getAprReqProgStatCd,
  getProgressStatusCode,
  retrieveGatingAprReqId,
  retrieveGtngEquipRsltChart,
  retrieveGtngEquipRsltChartSub,
  saveGatingJudgementAct,
  updateTmpApprovalLineAfterReject,
} from 'apis/gtng/GatingJudgementResult';
import { GatingJudgementResultSummary } from 'models/gtng/GatingJudgementResult';
import { Button } from '@mui/material';

import {
  Palettes,
  FlexChartCore,
  ChartElement,
  SeriesBase,
  SeriesRenderingEventArgs,
  FlexChartBase,
  RenderEventArgs,
} from '@grapecity/wijmo.chart';
import {
  FlexChart,
  FlexChartLegend,
  FlexChartSeries,
  FlexChartAxis,
  FlexChartDataLabel,
} from '@grapecity/wijmo.react.chart';
import {
  ProcessBarWrap,
  ProcessStepBox,
  StepBox,
  ProcessStep,
  ProcessNum,
  ProcessText,
  ProgressBar,
} from 'components/process/Process';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { IconButton } from 'components/buttons/IconSVG';
import { CrudCode } from 'models/common/Edit';
import { useCommonModal } from 'hooks/useCommonModal';
import { GatingJudgementResultDtl } from 'models/gtng/GatingJudgementResultDtl';
import { useNavigate } from 'react-router-dom';
import { GatingJudgementResultDtlEquipment } from '../../../models/gtng/GatingJudgementResultDtlEquipment';
import _ from 'lodash';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import useSessionStore from 'stores/useSessionStore';
import ApproveRequestModal from 'pages/approves/ApproveRequestModal';
import CustomInputWithSearch from '../../../components/inputs/CustomInputWithSearch';
import { GatingContListPopUp } from '../popup/GatingContListPopUp';
import { DataMap, SelectionMode, CellType } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import * as input from '@grapecity/wijmo.input';
import { ApproveRequestPageType } from '../../approves/ApproveRequestDetailCase';
import CustomGrid from '../../../components/grids/CustomGrid';
import moment from 'moment/moment';
import { formatDate } from 'react-tailwindcss-datepicker/dist/helpers';
import dayjs from 'dayjs';

/**
 * Gating 판정결과 Report 그리드
 * @param props
 * @constructor
 */
export const GatingResultReportActGrid = (props: any) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const { openCommonModal } = useCommonModal();
  const [gatingActgridRef, setGatingActgridRef] = useState<any>();
  const [gatingDtlgridRefEquip, setGatingEquipgridRef] = useState<any>();
  const [rowData, setRowData] = useState<GatingResultReportAct[]>([]);
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);

  const [summaryData2, setSummaryData2] = useState<GatingJudgementResultDtlEquipment[]>([]);
  const [rowDataChart1, setRowDataChart1] = useState<GatingResultReportAct[]>([]);
  const [rowDataChart2, setRowDataChart2] = useState<GatingResultReportAct[]>([]);
  const [summaryData, setSummaryData] = useState<GatingJudgementResultSummary[]>([]);
  const [judgeReqSummary, setJudgeReqSummary] = useState<any>();
  const [dtlData, setDtlData] = useState<GatingJudgementResultDtl[]>([]);
  const [gatingProgressStatusCodeD, setGatingProgressStatusCodeD] = useState<Code[]>([]);
  const [isOpenReqPopUp, setOpenReqPopUp] = useState<boolean>(false);
  const [progressStatusCode, setProgressStatusCode] = useState();
  const [aprReqProgStatCd, setAprReqProgStatCd] = useState(); //결재상태
  const barChartRef = useRef<FlexChartCore>();
  const [newColumns, setNewColumns] = useState<any>([]);
  const [gatingAprReqId, setGatingAprReqId] = useState('');
  const [gatingAprReqProgStatCd, setGatingAprReqProgStatCd] = useState('');
  const editable = useMemo(
    () => (progressStatusCode == 'D' || gatingAprReqProgStatCd == 'REQ' ? false : true),
    [progressStatusCode, gatingAprReqProgStatCd]
  );

  const radarItemsSource = useMemo(() => {
    const items = (rowDataChart1 || []).reduce((acc, cur) => {
      if ('gatingType2Value' in cur) {
        const ravg = Number(cur['ravg']);
        const goodBasisScore = Number(cur['goodBasisScore']);
        acc.push({
          gatingId: cur['gatingId'],
          gatingType2Value: cur['gatingType2Value'],
          ravg: 'ravg' in cur && !_.isNaN(ravg) ? ravg : 0,
          goodBasisScore: 'goodBasisScore' in cur && !_.isNaN(goodBasisScore) ? goodBasisScore : 0,
        });
      }
      return acc;
    }, [] as any[]);
    return items;
  }, [rowDataChart1]);
  const barItemsSource = useMemo(() => {
    const items = (rowDataChart2 || []).reduce((acc, cur) => {
      if ('equipmentId' in cur) {
        const ravg = Number(cur['ravg']);
        const goodBasisScore = Number(cur['goodBasisScore']);
        acc.push({
          equipmentId: cur['equipmentId'],
          ravg: 'ravg' in cur && !_.isNaN(ravg) ? ravg : 0,
          goodBasisScore: 'goodBasisScore' in cur && !_.isNaN(goodBasisScore) ? goodBasisScore : 0,
        });
      }
      return acc;
    }, [] as any[]);
    return items;
  }, [rowDataChart2]);

  useEffect(() => {
    getCommonCodes();
  }, []);

  const getCommonCodes = async () => {
    const processStatusCodeD: Code[] = await getCommonCodeNames('GATING_PROGRESS_STATUS_CODE_B');
    setGatingProgressStatusCodeD(processStatusCodeD);
  };

  const [initUserId, setInitUserId] = useState<string>(''); //검색할 파라미터 상태관리
  const [popup, setPopup] = useState<any>(false); //팝업창 오픈, 클로즈 상태관리
  const [contPopTitle, setContPopTitle] = useState<string>(''); //검색할 파라미터 상태관리

  //사용자 검색 팝업
  const btnElmEmpPopup = ({ value }) => `<span>${value ? value : ''}</span>
  <Button id="empPopBtn"></Button>
  `;

  const openPopup = (params: any) => {
    const fieldName = params.binding;
    const id = fieldName === 'actionContact' ? 'actionContactId' : '';
    if (fieldName != null && fieldName != '') {
      setInitUserId(params.item[id]);
    }

    //실시담당자
    if (fieldName == 'actionContact') {
      setContPopTitle(String(t('gtng.label.실시담당자', '실시담당자')));
    }
    setPopup(true);
  };

  const fnSearchMst = () => {
    if (props.searchParamData != undefined) {
      // 진행 상태 확인
      getProgressStatusCode(props.searchParamData?.gatingId).then((result) => {
        setProgressStatusCode(result);
      });

      //(임시)결재 상태 확인
      getAprReqProgStatCd(props.searchParamData?.gatingId).then((result) => {
        setAprReqProgStatCd(result);
      });

      // 판정결과 보완이력
      findGatingResultReportAct(props.searchParamData?.gatingId).then(
        (result: GatingResultReportAct[]) => {
          const actData = result.filter((element) => element.itemInspectionResultCode !== 'OK');
          setRowData(actData);
        }
      );

      // 판정결과(요약)
      findGatingjudgementReportSummary(props.searchParamData?.gatingId).then(
        (result: GatingJudgementResultSummary[]) => {
          if (result.length == 0) {
            setSummaryData([
              {
                gatingId: '',
                gatingNo: '',
                gatingName: '',
                gatingTypeCode: '',
                gatingTypeName: '',
                gatingStepCode: '',
                gatingStepName: '',
                taskLeaderUserId: '',
                taskLeaderUserName: '',
                pmoApproverId: '',
                pmoApproverName: '',
                pmoApproverNames: '',
                gatingContIds: '',
                gatingContNames: '',
                gatingContName: '',
                progressStatusCode: '',
                progressStatusName: '',
                approvalRequestYn: '',
                evaluationScore: '',
                inspectionResultCode: '',
                inspectionResultName: '',
                decisionResult: '',
                aprReqId: '',
              },
            ]);
          } else {
            setSummaryData(result);
          }
        }
      );

      if (barChartRef.current) {
        barChartRef.current.header = t('gtng.label.설비별 점수', '설비별 점수');
      }
      //차트1 : 방사형
      retrieveGtngEquipRsltChart(props.searchParamData?.gatingId).then(
        (result: GatingResultReportAct[]) => {
          setRowDataChart1(result);
        }
      );
      // 차트2: 막대형
      retrieveGtngEquipRsltChartSub(props.searchParamData?.gatingId).then(
        (result: GatingResultReportAct[]) => {
          setRowDataChart2(result);
        }
      );

      // 가변 그리드 용
      findGatingJudgementReportDtlEquipment(props.searchParamData?.gatingId).then((result: any) => {
        setSummaryData2(result.data);
      });
      // 판정상세
      findGatingJudgementReportDtl(props.searchParamData?.gatingId).then(
        (result: GatingJudgementResultDtl[]) => {
          setDtlData(result);
        }
      );

      //판정요청서 설비별 판정상세요약
      findGatingjudgementReportReqSummary(props.searchParamData?.gatingId).then((result: any) => {
        setJudgeReqSummary(result);
      });
    }
  };

  useEffect(() => {
    //결재ID 조회
    if (props.searchParamData != undefined) {
      retrieveGatingAprReqId(props.searchParamData?.gatingId).then((result: any) => {
        //결재 상태가 TMP가 아니면 새로운 결재ID 생성
        setGatingAprReqId(result?.aprReqProgStatCd != 'TMP' ? null : result?.aprReqId);
        setGatingAprReqProgStatCd(result?.aprReqProgStatCd);
      });
    }
  }, [props.searchParamData, props.searchState, isOpenRequestModal]);

  useEffect(() => {
    fnSearchMst();
  }, [props.searchParamData, props.searchState]);

  //시작일자, 종료일자 YY-MM-DD 형태로 변환
  const dateValueFommater = (params: any) => {
    const date = params.value;
    if (date != null && date != undefined) {
      const subStrDate = date.substr(0, 10); //jhujhjh     dsds
      const formmattedDate = subStrDate.replaceAll('-', '.');
      return formmattedDate;
    }
  };

  // const [reqTmp, setReqTmp] = useState<any>(false);
  // 최종완료 :: 임시승인요청 (임시저장)
  const handleSave = () => {
    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.actionPlanDesc == null || rowNode.actionPlanDesc == '')
          return `${t(
            'gtng.msg.승인요청을 하기 전 보완계획 및 계획일자를 작성 후 저장해주세요.',
            '승인요청을 하기 전 보완계획 및 계획일자를 작성 후 저장해주세요.'
          )}`;
        if (rowNode.planDate == null || rowNode.planDate == '')
          return `${t(
            'gtng.msg.승인요청을 하기 전 보완계획 및 계획일자를 작성 후 저장해주세요.',
            '승인요청을 하기 전 보완계획 및 계획일자를 작성 후 저장해주세요.'
          )}`;
        if (rowNode.crudKey == 'U')
          return `${t(
            'gtng.msg.승인요청을 하기 전 보완계획 및 계획일자를 작성 후 저장해주세요.',
            '승인요청을 하기 전 보완계획 및 계획일자를 작성 후 저장해주세요.'
          )}`;
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openCommonModal({ content: content || '' });
      return false;
    }
    setOpenRequestModal(true);
  };

  // 최종완료 :: 임시승인 (진행상태 = 'D' or 'B')
  const btnConfirm2 = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.승인하시겠습니까?', '승인하시겠습니까?'),
      yesCallback: () => {
        confirmGatingJudgementAct(summaryData[0]).then((result) => {
          if (!result) {
            openCommonModal({
              content: t('gtng.msg.결재승인이 실패하였습니다.', '결재승인이 실패하였습니다.'),
            });
            return false;
          }
          openCommonModal({
            content: t('gtng.msg.결재승인이 완료되었습니다.', '결재승인이 완료되었습니다.'),
          });
          // setReqTmp(false);
          fnSearchMst();
        });
      },
    });
  };

  // 반려 : 임시반려 - > APR_REQ_PROG_STATA_CD 를 REJ상태로 전환
  const btnConfirm3 = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('gtng.msg.반려하시겠습니까?', '반려하시겠습니까?'),
      yesCallback: () => {
        updateTmpApprovalLineAfterReject(summaryData[0]).then((result) => {
          if (!result) {
            openCommonModal({
              content: t('gtng.msg.결재반려가 실패하였습니다.', '결재반려가 실패하였습니다.'),
            });
            return false;
          }
          openCommonModal({
            content: t('gtng.msg.결재반려가 완료되었습니다.', '결재반려가 완료되었습니다.'),
          });
          // setReqTmp(false);
          fnSearchMst();
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const btnSave = () => {
    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.actionPlanDesc == null || rowNode.actionPlanDesc == '')
          return `${t('gtng.msg.보완계획을 입력해주세요.', '보완계획을 입력해주세요.')}`;
        if (rowNode.planDate == null || rowNode.planDate == '')
          return `${t('gtng.msg.계획일자를 입력해주세요.', '계획일자를 입력해주세요.')}`;
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openCommonModal({ content: content || '' });
      return false;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        const saveRows = rowData
          .map((rowNode) => {
            rowNode.planDate = moment(rowNode.planDate).format('YYYY.MM.DD');
            return rowNode.crudKey ? rowNode : null;
          })
          .filter((element) => element !== null) as GatingResultReportAct[];
        saveGatingJudgementAct(saveRows).then((result) => {
          if (!result) {
            openCommonModal({
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return false;
          }
          openCommonModal({ content: t('com.label.저장되었습니다.', '저장되었습니다.') });
          findGatingResultReportAct(props.searchParamData?.gatingId).then(
            (result: GatingResultReportAct[]) => {
              const actData = result.filter((element) => element.itemInspectionResultCode !== 'OK');
              setRowData(actData);
            }
          );
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const LayoutDefinitionEquip2 = () => {
    const columnDefsEquip2 = [
      {
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        align: 'center',
        //  filter: false,
        isReadOnly: true,
        //  suppressSizeToFit: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      { header: 'GatingId', binding: 'GATING_ID', visible: false },
      { header: 'GatingId', binding: 'GATING_NO', visible: false },
      { header: 'sub_seq_no', binding: 'SUB_SEQ_NO', visible: false },
      {
        header: String(t('gtng.grid.등급', '등급')),
        binding: 'GATING_CHECK_ITEM_GRADE_NAME',
        isReadOnly: true,
        align: 'center',
        width: 100,
      },
      {
        header: String(t('gtng.grid.점검구분1', '점검구분1')),
        binding: 'GATING_TYPE1_VALUE',
        isReadOnly: true,
        align: 'left',
        width: 130,
      },
      {
        header: String(t('gtng.grid.점검구분2', '점검구분2')),
        binding: 'GATING_TYPE2_VALUE',
        isReadOnly: true,
        align: 'left',
        width: 130,
      },
      {
        header: String(t('gtng.grid.점검구분3', '점검구분3')),
        binding: 'GATING_TYPE3_VALUE',
        isReadOnly: true,
        align: 'left',
        width: 130,
      },
      {
        header: String(t('gtng.grid.Check List', 'Check List')),
        binding: 'GATING_CHECK_ITEM1_VALUE',
        align: 'left',
        isReadOnly: true,
        width: 130,
      },
      {
        header: String(t('gtng.grid.Check 방법/계측/검사', 'Check 방법/계측/검사')),
        binding: 'GATING_CHECK_ITEM2_VALUE',
        align: 'left',
        isReadOnly: true,
        minWidth: 160,
        width: '*',
      },
      {
        header: String(t('gtng.grid.판단기준/Spec/목표', '판단기준/Spec/목표')),
        binding: 'GATING_CHECK_ITEM3_VALUE',
        align: 'left',
        isReadOnly: true,
        minWidth: 160,
        width: '*',
      },
    ];
    // CellMaker.makeLink({
    //   text: '${value}',
    //   click: (e, ctx) => {
    //     if (ctx && Object.keys(ctx).includes('value')) {
    //       fnSearchCheckResultBtn(ctx);
    //     }
    //   },
    // })
    if (summaryData2.length > 0) {
      const sampleData = summaryData2[0]; // 첫 번째2 2데이터 항목을 기반으로 33컬럼 생성
      const cellLinkMaker = (params) => {
        const newId = params.col.binding.substring(
          params.col.binding.indexOf("'") + 1,
          params.col.binding.lastIndexOf("'")
        );
        return params.value == 'NG' || params.value == 'C-OK'
          ? `<a href="javascript:void(0)" onClick="document.getElementById('${newId}_${
              params.row._idx + 1
            }_handler').click();" >${params.value}</a><Button id='${newId}_${
              params.row._idx + 1
            }_handler' style='visibility: hidden;'></Button>`
          : `<div>${params.value ? params.value : ''}</div>`;
      };

      for (const key in sampleData) {
        if (!checkIfColumnExists(newColumns, key)) {
          // 1. 값 추출
          // 2. 컬럼 그룹 헤더 생성
          // 3. 컬럼 그룹에 컬럼 매핑
          // if (key.includes('RESULT_CODE'))

          let columnNameH;
          if (key.includes('_SCORE')) {
            columnNameH = key.replace('_SCORE', '');
            // if (newColumns.length > 0 && columnNameH === newColumns[newColumns.length - 1].header)
            //   continue;
            newColumns.push({
              header: columnNameH,
              align: 'center',
              columns: [
                {
                  header: String(t('gtng.grid.점수', '점수')),
                  width: 150,
                  isReadOnly: true,
                  align: 'center',
                  binding: columnNameH + '_SCORE',
                },
                {
                  header: String(t('gtng.grid.결과', '결과')),
                  binding: columnNameH + '_RESULT_CODE',
                  align: 'center',
                  isReadOnly: true,
                  width: 150,
                  cellTemplate: cellLinkMaker,
                },
              ],
            });
          }
        }
      }
    }
    return [...columnDefsEquip2, ...newColumns];
  };

  const checkIfColumnExists = (columnDefs, columnName) => {
    for (const columnDef of columnDefs) {
      if (Object.keys(columnDef).includes('columns')) {
        for (const item of columnDef.columns) {
          if (item.binding === columnName) {
            return true; // 컬럼이 존재함
          }
        }
      }
    }
    return false; // 컬럼이 존재하지 않음122
  };

  const cellEditing = (params) => {
    return progressStatusCode != 'C' || gatingAprReqProgStatCd == 'REQ' ? '' : 'cellEditing';
  };

  const LayoutDefinitionAct = () => {
    const inputDate = new input.InputDate(document.createElement('div'), {
      // 기간은 InputDateRange 사용
      min: new Date(),
      format: 'yyyy.MM.dd',
      isRequired: true,
    });

    return [
      {
        header: String(t('com.label.NO', 'NO')),
        width: 70,
        //  filter: false,
        isReadOnly: true,
        align: 'center',
        //  suppressSizeToFit: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 70,
        align: 'center',
        // suppressSizeToFit: true,
        // filter: false,
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        header: String(t('gtng.grid.장비명', '장비명')),
        binding: 'equipmentId',
        visible: false,
        // cellTemplate: valueFormatter,
        //  align: 'left',
      },
      {
        header: String(t('gtng.grid.장비명', '장비명')),
        binding: 'equipmentName',
        isReadOnly: true,
        width: 150,
      },
      {
        header: String(t('gtng.grid.점검차수', '점검차수')),
        binding: 'inspectionSeqs',
        isReadOnly: true,
        align: 'right',
        width: 85,
      },
      {
        header: String(t('gtng.grid.점검점수', '점검점수')),
        binding: 'itemEvaluationScore',
        isReadOnly: true,
        align: 'center',
        width: 85,
      },
      {
        header: String(t('gtng.grid.점검결과', '점검결과')),
        binding: 'itemInspectionResultCode',
        visible: false,
        //  align: 'left',
        // cellTemplate: valueFormatter,
        // filter: true,
      },
      {
        header: String(t('gtng.grid.점검결과', '점검결과')),
        binding: 'itemInspectionResultName',
        align: 'center',
        isReadOnly: true,
        width: 105,
      },
      {
        header: String(t('gtng.grid.미비점(NG항목)', '미비점(NG항목)')),
        align: 'center',
        columns: [
          {
            header: String(t('gtng.grid.점검구분1', '점검구분1')),
            binding: 'gatingType1Value',
            isReadOnly: true,
            width: 145,
            //  align: 'left',
          },
          {
            header: String(t('gtng.grid.점검구분2', '점검구분2')),
            binding: 'gatingType2Value',
            isReadOnly: true,
            width: 125,
            //  align: 'left',
          },
          {
            header: String(t('gtng.grid.점검구분3', '점검구분3')),
            binding: 'gatingType3Value',
            isReadOnly: true,
            width: 125,
            //  align: 'left',
          },
          {
            header: String(t('gtng.grid.Check List', 'Check List')),
            binding: 'gatingCheckItem1Value',
            isReadOnly: true,
            width: 125,
            //  align: 'left',
          },
        ],
      },
      {
        header: String(t('gtng.grid.점검결과', '점검결과')),
        binding: 'checkResultContent',
        align: 'center',
        isReadOnly: true,
        width: 105,
      },
      {
        header: String(t('gtng.label.실시(T/L)', '실시(T/L)')),
        align: 'center',
        columns: [
          // {
          //   header: String(t('gtng.grid.Advice', 'Advice')),
          //   binding: 'pmoCommentCount',
          //   width: 85,
          // },
          {
            header: String(t('gtng.grid.Advice', 'Advice')),
            align: 'center',
            binding: 'pmoComment',
            isReadOnly: true,
            width: 85,
          },
        ],
      },
      {
        header: String(t('gtng.grid.조치/실행', '조치/실행')),
        align: 'center',
        columns: [
          {
            header: String(t('gtng.label.실시담당자', '실시담당자')),
            binding: 'actionContact',
            isReadOnly: true,
            width: 100,
            cssClass: 'WijmoFind',
            cellTemplate: (params) => btnElmEmpPopup(params),
          },
          {
            header: String(t('gtng.label.실시담당자', '실시담당자')),
            binding: 'actionContactId',
            visible: false,
          },
          {
            header: String(t('gtng.grid.보완계획', '보완계획')),
            binding: 'actionPlanDesc',
            isReadOnly: !editable,
            width: 300,
            //  align: 'left',
          },
          {
            binding: 'planDate',
            header: String(t('gtng.grid.계획일자', '계획일자')),
            cssClass: 'WijmoDate',
            format: 'yyyy.MM.dd',
            isReadOnly: !editable,
            width: 140,
            editor: inputDate,
            // cellTemplate: (params) =>
            //   params.value ? dayjs(params.value).format('yyyy.MM.dd') : '',
          },
          {
            header: String(t('com.label.수정일', '수정일')),
            binding: 'dataUpdDtm',
            isReadOnly: true,
            align: 'center',
            width: 110,
            cellTemplate: (params) => dateValueFommater(params),
          },
        ],
      },
    ];
  };

  const navigate = useNavigate();

  const fnSearchCheckResultActBtn = (params) => {
    const searchParam = {
      gatingId: params.item.gatingId,
      gatingNo: params.item.gatingNo,
      equipmentId: params.item.equipmentId,
      equipmentName: params.item.equipmentName,
    };
    navigate('/gating/result/gating-check-result', { state: { sCheckResultParam: searchParam } });
  };

  const fnSearchCheckResultBtn = (params) => {
    const equipId = params.binding;

    const searchParam = {
      gatingId: params.item.GATING_ID,
      gatingNo: params.item.GATING_NO,
      equipmentId: equipId,
      equipmentName: equipId,
    };
    navigate('/gating/result/gating-check-result', { state: { sCheckResultParam: searchParam } });
  };

  const LayoutDefinitionReqEquip = () => {
    return [
      {
        header: String(t('com.label.NO', 'NO')),
        align: 'center',
        width: 40,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('gtng.grid.등급', '등급')),
        binding: 'gatingCheckItemGradeCode',
        width: 80,
      },
      {
        header: String(t('gtng.grid.등급명', '등급명')),
        binding: 'gatingCheckItemGradeName',
        visible: false,
      },
      {
        header: String(t('gtng.grid.장비명', '장비명')),
        binding: 'equipmentName',
        width: 130,
      },
      {
        header: String(t('gtng.grid.점검점수', '점검점수')),
        binding: 'convScore',
        width: 80,
        align: 'center',
      },
      {
        header: String(t('gtng.grid.점검결과', '점검결과')),
        binding: 'inspectionResultCode',
        width: 100,
      },
      {
        header: String(t('gtng.grid.점검구분1', '점검구분1')),
        binding: 'gatingType1Value',
        width: 130,
        align: 'left',
      },
      {
        header: String(t('gtng.grid.점검구분2', '점검구분2')),
        binding: 'gatingType2Value',
        width: 100,
      },
      {
        header: String(t('gtng.grid.점검구분3', '점검구분3')),
        binding: 'gatingType3Value',
        width: 100,
      },
      {
        header: String(t('gtng.grid.Check List', 'Check List')),
        binding: 'gatingCheckItem1Value',
        width: 300,
      },
      {
        header: String(t('gtng.grid.Check 방법/계측/검사', 'Check 방법/계측/검사')),
        binding: 'gatingCheckItem2Value',
        width: 250,
      },
      {
        header: String(t('gtng.grid.판단기준/Spec/목표', '판단기준/Spec/목표')),
        binding: 'gatingCheckItem3Value',
        width: 300,
      },
    ];
  };

  const [showDiv, setShowDiv] = useState(false);
  const [showDiv2, setShowDiv2] = useState(false);

  //방사형
  useEffect(() => {
    // 2초 후에 div를 화면에 보이게 만듭니다.
    const timeoutId = setTimeout(() => {
      setShowDiv(true);
    }, 2000);

    // 컴포넌트가 언마운트될 때 타임아웃을 정리합니다.
    return () => clearTimeout(timeoutId);
  }, [rowDataChart1]);

  //막대
  useEffect(() => {
    // 2초 후에 div를 화면에 보이게 만듭니다.

    const timeoutId = setTimeout(() => {
      setShowDiv2(true);
    }, 1000);

    // 컴포넌트가 언마운트될 때 타임아웃을 정리합니다.
    return () => clearTimeout(timeoutId);
  }, [rowDataChart2]);

  const stateAct = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinitionAct(),
  };
  const stateEquip2 = {
    itemsSource: summaryData2,
    layoutDefinition: LayoutDefinitionEquip2(),
  };

  const onInitializedAct = (grid) => {
    new FlexGridFilter(grid);
    setGatingActgridRef(grid);
    grid.rowHeaders.columns.clear();

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      // setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      // for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      // grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      // setflexItem(item);
      // setFieldId(col.binding);
      switch (e.target.id) {
        case 'empPopBtn':
          openPopup({ binding: col.binding, item });
          break;
      }
      grid.refresh();
      grid.startEditing(true);
    });
  };

  const onInitializedEquip2 = (grid) => {
    new FlexGridFilter(grid);
    setGatingEquipgridRef(grid);
    grid.rowHeaders.columns.clear();

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      const col = grid.columns[ht.col];
      if (e.target instanceof HTMLButtonElement) {
        const originalColumnName = e.target.id.substring(0, e.target.id.indexOf('_'));
        const rowIdx = e.target.id.substring(
          e.target.id.indexOf('_') + 1,
          e.target.id.lastIndexOf('_')
        );
        switch (e.target.id.slice(-8)) {
          case '_handler':
            fnSearchCheckResultBtn({
              binding: originalColumnName,
              item: grid.rows[Number(rowIdx) - 1].dataItem,
            });
            break;
        }
      }

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem;

      // row 선택시 체크박스 체크
      // for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      // grid.rows[ht.row].isSelected = true;

      setflexItem(item);
      setFieldId(col.binding);

      grid.refresh();
    });
  };

  return (
    <>
      {/*gating process step start */}
      {/*{!_.isEmpty(processSteps) && (*/}
      <ProcessBarWrap className="mt24">
        {progressStatusCode == 'C' ? (
          <>
            {(gatingProgressStatusCodeD || []).map((o, i) => (
              <ProcessStepBox
                key={o.cmnCd}
                className={o.cmnCd == 'C' ? 'now' : o.cmnCd == 'D' ? 'disable' : 'done'}
              >
                <StepBox>
                  <ProcessStep>
                    <ProcessNum>{i + 1}</ProcessNum>
                    <ProcessText>{o.cmnCdNm}</ProcessText>
                  </ProcessStep>
                </StepBox>
              </ProcessStepBox>
            ))}
          </>
        ) : null}
        {progressStatusCode == 'D' ? (
          <>
            {(gatingProgressStatusCodeD || []).map((o, i) => (
              <ProcessStepBox key={o.cmnCd} className={o.cmnCd == 'D' ? 'now' : 'done'}>
                <StepBox>
                  <ProcessStep>
                    <ProcessNum>{i + 1}</ProcessNum>
                    <ProcessText>{o.cmnCdNm}</ProcessText>
                  </ProcessStep>
                </StepBox>
              </ProcessStepBox>
            ))}
          </>
        ) : null}
        <ProgressBar></ProgressBar>
      </ProcessBarWrap>
      {/*)}*/}
      {/*gating process step end */}
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating판정결과(요약)', 'Gating판정결과(요약)')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.Gating Task명', 'Gating Task명')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    type="text"
                    name="gatingName"
                    placeholder=""
                    disabled
                    value={summaryData[0]?.gatingName}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 유형', 'Gating 유형')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    type="text"
                    name="gatingTypeName"
                    placeholder=""
                    disabled
                    value={summaryData[0]?.gatingTypeName}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 단계', 'Gating 단계')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    type="text"
                    name="gatingStepName"
                    placeholder=""
                    disabled
                    value={summaryData[0]?.gatingStepName}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.판정결과', '판정결과')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    type="text"
                    name="decisionResult"
                    placeholder=""
                    disabled
                    value={summaryData[0]?.decisionResult}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.등록 담당자', '등록담당자')}</label>
                <CustomInputWithSearch
                  name="gatingContName"
                  className="find"
                  width={200}
                  value={summaryData[0]?.gatingContName}
                  readOnly={true}
                  disabled={true}
                  onSearchClick={(value) => {
                    if (summaryData[0] == null || summaryData[0]?.gatingContIds == '') return false;
                    setInitUserId(summaryData[0]?.gatingContIds || '');
                    setContPopTitle(String(t('gtng.label.등록 담당자', '등록담당자')));
                    setPopup(true);
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.등록 T/L', '등록T/L')}</label>
                <CustomInputWithSearch
                  name="pmoApproverName"
                  className="find"
                  value={summaryData[0]?.pmoApproverName}
                  readOnly={true}
                  disabled={true}
                  onSearchClick={(param) => {
                    if (summaryData[0] == null || summaryData[0]?.pmoApproverId == '') return false;
                    setInitUserId(summaryData[0]?.pmoApproverId || '');
                    setContPopTitle(String(t('gtng.label.등록 T/L', '등록T/L')));
                    setPopup(true);
                  }}
                ></CustomInputWithSearch>
              </SearchCols>
            </SearchRows>
          </InputBox>
        </SearchBoxRow>
      </SearchBox>
      <Chart2Div>
        {/* 차트1 : 방사형 그래프 */}
        {showDiv2 && (
          <div>
            <FlexRadar
              bindingX="gatingType2Value"
              itemsSource={radarItemsSource}
              // palette={['#5E5FB7', '#FCA9BF']}
              palette={['#B4B5F3', '#FDC1CA']}
              /*itemFormatter={(engine, ht, defRender) => {
                const pal = ht.chart.palette ? ht.chart.palette : Palettes.standard;
                engine.fill = pal[ht.pointIndex % pal.length];
                engine.stroke = null;
                defRender();
              }}*/
              rendered={(c: FlexChartBase, e: RenderEventArgs) => {
                // axisY 배경선
                const yNodes = c.axisY.hostElement.childNodes;
                yNodes.forEach((o) => {
                  if ('polygon' === o.tagName.toLowerCase()) {
                    o.setAttribute('stroke', '#b9bcbb');
                    o.setAttribute('stroke-dasharray', '2,5');
                  }
                });
                const eNodes = e.engine.element.childNodes;
                eNodes.forEach((o) => {
                  // 하단 Legend
                  if (o.classList.contains('wj-legend')) {
                    const g = o.childNodes[1];
                    if (g) {
                      const gcNodes = g.childNodes;
                      gcNodes.forEach((gc) => {
                        // LineSymbols 투명하지 않게 설정
                        if ('ellipse' === gc.tagName.toLowerCase()) {
                          gc.setAttribute('fill', gc.getAttribute('stroke') || '');
                        }
                      });
                    }
                  }
                  // series 마크업 순서 변경
                  if (o.classList.contains('wj-series-group')) {
                    const gcNodes = o.children;
                    for (const gc of gcNodes) {
                      // 취득점수 (polygon, ellipse x N)
                      if (gc.children.length > 1) {
                        const pNode = gc.parentNode;
                        // 합격기준점수 -> 취득점수 순으로 마크업
                        if (pNode.firstChild === gc) {
                          pNode.removeChild(gc);
                          pNode.appendChild(gc);
                          break;
                        }
                      }
                    }
                  }
                });
              }}
              selectionMode="Point"
              selectionChanged={(s, e) => {
                if (s.selection) {
                  const point = s.selection.collectionView.currentItem;
                  if (point) {
                    if (barChartRef.current) {
                      barChartRef.current.header = `${point?.gatingType2Value} ${t(
                        'gtng.msg.설비별 점수',
                        '설비별 점수'
                      )}`;
                    }
                    // 차트2: 막대형
                    retrieveGtngEquipRsltChartSub(point?.gatingId, point?.gatingType2Value).then(
                      (result: GatingResultReportAct[]) => {
                        setRowDataChart2(result);
                      }
                    );
                  }
                }
              }}
            >
              <FlexChartLegend position="Bottom"></FlexChartLegend>
              <FlexRadarAxis
                wjProperty="axisX"
                itemFormatter={(engine, label) => {
                  const item = radarItemsSource[label.val];
                  if (item) {
                    /**
                     <tspan x="0" dy={idx === 0 ? 0 : '1.2em'}>
                     {textRow}
                     </tspan>
                     */
                    label.text = `${item.gatingType2Value}(${item.ravg}${t(
                      'gtng.label.점',
                      '점'
                    )})`;
                  }

                  return label;
                }}
              />
              <FlexRadarAxis wjProperty="axisY" min={0} max={100} majorUnit={20} />
              <FlexRadarSeries
                chartType="LineSymbols"
                binding="ravg"
                name={t('gtng.label.취득점수', '취득점수')}
                rendered={(c: SeriesBase, e: SeriesRenderingEventArgs) => {
                  const nodes = c.hostElement.childNodes;
                  if (nodes) {
                    nodes.forEach((o) => {
                      if ('ellipse' === o.tagName.toLowerCase()) {
                        // LineSymbols 투명하지 않게 설정
                        o.setAttribute('fill', o.getAttribute('stroke'));
                      }
                    });
                  }
                }}
              />
              <FlexRadarSeries
                chartType="Area"
                binding="goodBasisScore"
                name={`${t('gtng.label.합격기준점수', '합격기준점수')}${
                  radarItemsSource.length > 0
                    ? `(${radarItemsSource[0].goodBasisScore}${t('gtng.label.점 이상', '점 이상')})`
                    : ''
                }`}
              />
              {/*<FlexChartAnimation easing="Swing" animationMode="Point" duration={500} />*/}
            </FlexRadar>
          </div>
        )}
        {/* 차트2: 막대형 그래프 */}
        {showDiv && (
          <div>
            <FlexChart
              header={t('gtng.label.설비별 점수', '설비별 점수')}
              chartType="Bar"
              bindingX="equipmentId"
              itemsSource={barItemsSource}
              // palette={['#B4B5F3', '#FCA9BF']}
              palette={['#B4B5F3', '#FDC1CA']}
              /*
              itemFormatter={(engine, ht, defRender) => {
                const pal = ht.chart.palette ? ht.chart.palette : Palettes.standard;
                engine.fill = pal[ht.pointIndex % pal.length];
                engine.stroke = null;
                defRender();
              }}
              */
              initialized={(grid) => {
                barChartRef.current = grid;
              }}
            >
              <FlexChartLegend position="Bottom"></FlexChartLegend>
              <FlexChartAxis wjProperty="axisX" min={0} max={100} />
              <FlexChartAxis
                wjProperty="axisY"
                reversed={true}
                itemFormatter={(engine, label) => {
                  const nodes = engine.element.childNodes;
                  if (nodes) {
                    nodes.forEach((node) => {
                      if (node.classList.contains('wj-axis-y')) {
                        const c = node.childNodes;
                        c.forEach((o) => {
                          if ('line' === o.tagName.toLowerCase()) {
                            o.setAttribute('stroke', '#b9bcbb');
                            o.setAttribute('stroke-dasharray', '2,5');
                          }
                        });
                      }
                    });
                  }
                  return label;
                }}
              />
              <FlexChartDataLabel
                content="{y}"
                position="Left"
                rendering={(d, e) => {
                  if (e.hitTestInfo.series.binding === 'goodBasisScore') {
                    e.cancel = true;
                  } else {
                    e.point.x = e.point.x - 10;
                  }
                }}
              />
              <FlexChartSeries
                name={t('gtng.label.취득점수', '취득점수')}
                binding="ravg"
                rendered={(c: SeriesBase, e: SeriesRenderingEventArgs) => {
                  const nodes = c.hostElement.childNodes;
                  if (nodes) {
                    // 투명하지 않게 설정
                    nodes.forEach((o) => o.setAttribute('fill', o.getAttribute('stroke')));
                  }
                }}
              />
              <FlexChartSeries
                binding="goodBasisScore"
                name={`${t('gtng.label.합격기준점수', '합격기준점수')}${
                  barItemsSource.length > 0
                    ? `(
                    ${barItemsSource[0].goodBasisScore}${t('gtng.label.점 이상', '점 이상')})`
                    : ''
                }`}
                chartType="Line"
                style={{
                  d: 'M5 20 l215 0',
                  'stroke-dasharray': '5,5',
                  'stroke-width': 2,
                }}
                rendered={(c, e) => {
                  if (c.hostElement.children && c.hostElement.children.length) {
                    const points = c.hostElement.children[0]?.points;
                    if (points && points.length) {
                      const svg = c.hostElement.viewportElement;
                      if (svg) {
                        const p1 = svg.createSVGPoint();
                        p1.x = points[0].x;
                        p1.y = 0;
                        const p2 = svg.createSVGPoint();
                        p2.x = points[0].x;
                        p2.y = 500;
                        c.hostElement.children[0].points.insertItemBefore(p1, 0);
                        c.hostElement.children[0].points.appendItem(p2);
                      }
                    }
                  }
                }}
              />
            </FlexChart>
          </div>
        )}
      </Chart2Div>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 판정결과 및 보완이력', 'Gating 판정결과 및 보완이력')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span> {rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={stateAct.layoutDefinition}
        rowData={stateAct.itemsSource}
        height={300}
        isSelector={false}
        isFilter={false}
        allowPinning={false}
        initialized={onInitializedAct}
        // formatItem={flexGridTooltip}
        rowEditEnded={(grid, e) => {
          const data = grid.rows[e.row].dataItem;
          data.crudKey = CrudCode.UPDATE;
        }}
        loadedRows={(grid, e) => {
          grid.rows.forEach((r) => {
            if (r.dataItem.lastSeqYn === 'N') {
              r.isReadOnly = true;
            }
          });
        }}
      />
      {/*결재요청버튼*/}
      <>
        {progressStatusCode === 'C' ? (
          <GlobalBtnGroup>
            {gatingAprReqProgStatCd == null ||
            gatingAprReqProgStatCd == 'TMP' ||
            gatingAprReqProgStatCd == 'REJ' ||
            gatingAprReqProgStatCd == 'CCL' ? (
              <Button
                css={IconButton.button}
                className="create"
                disableRipple
                onClick={() => {
                  handleSave();
                }}
              >
                {t('com.button.결재요청', '결재요청')}
              </Button>
            ) : (
              <Button
                css={IconButton.button}
                style={{ marginTop: '50px' }}
                className="request"
                disableRipple
              >
                {t('com.button.결재요청 중', '결재요청 중')}
              </Button>
            )}
            {gatingAprReqProgStatCd == null ||
            gatingAprReqProgStatCd == 'TMP' ||
            gatingAprReqProgStatCd == 'REJ' ||
            gatingAprReqProgStatCd == 'CCL' ? (
              <Button css={IconButton.button} className="save" disableRipple onClick={btnSave}>
                {t('com.button.저장', '저장')}
              </Button>
            ) : null}
          </GlobalBtnGroup>
        ) : (
          ''
        )}
      </>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 판정상세', 'Gating 판정상세')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span> {summaryData2.length}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={stateEquip2.layoutDefinition}
        rowData={stateEquip2.itemsSource}
        height={380}
        isSelector={false}
        isFilter={false}
        allowPinning={false}
        initialized={onInitializedEquip2}
        // selectionMode={'Row'}
        // formatItem={flexGridTooltip}
      />
      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={ApproveRequestPageType.GATING_JUDGE_RESULT_REQ} // 'GatingJudgeResultReqPopUp'
          aprReqId={gatingAprReqId}
          condition={{
            gatingId: props.searchParamData?.gatingId,
          }}
        />
      )}
      {/*담당자 팝업*/}
      {popup && (
        <GatingContListPopUp
          open={popup}
          // rowId={rowId}
          title={contPopTitle}
          setReadonly={true}
          initParam={initUserId}
          close={() => setPopup(false)}
        />
      )}
    </>
  );
};
export default GatingResultReportActGrid;
