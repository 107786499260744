/** @jsxImportSource @emotion/react */
import React, {
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { InputDate } from '@grapecity/wijmo.input';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import dayjs from 'dayjs';
import { tb } from 'components/layouts/Table';
import { ContentSection } from 'components/layouts/ContentSection';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import CustomGrid from 'components/grids/CustomGrid';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { findGatingInfo, findGatingEquipments } from 'apis/gtng/GatingChangeRequest';
import {
  GatingChangeRequestMaster,
  GatingChangeRequestDetail,
} from 'models/gtng/GatingChangeRequest';
import { GatingInfo } from 'models/gtng/GatingInfo';
import { GatingContListPopUp } from '../popup/GatingContListPopUp';
import { GatingEquipment } from 'models/gtng/GatingEquipment';
import { CrudCode } from 'models/common/Edit';
import { Code } from 'models/common/CommonCode';
import { useMessageBar } from 'hooks/useMessageBar';

export enum GatingReqType {
  GATING_CANCEL = 'GATING_CANCEL', // 취소요청
  STATUS_CHANGE = 'STATUS_CHANGE', // 단계변경
  DATE_CHANGE = 'DATE_CHANGE', // 일정변경
}

interface Props {
  aprReqId?: string;
  gatingId: string;
  equipmentId?: string;
  reqTp: GatingReqType;
  isReadOnly?: boolean;
}

const GatingChangeRequest = (props: Props, ref) => {
  const { gatingId, equipmentId, reqTp, isReadOnly = false } = props;
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const gridRef = useRef<any>();
  const dateEditor = useRef(
    new InputDate(document.createElement('div'), {
      format: 'yyyy.MM.dd',
      isRequired: false,
    })
  );
  const [gatingMaster, setGatingMaster] = useState<GatingInfo>();
  const [rowData, setRowData] = useState<GatingEquipment[]>([]);
  const [gatingProgressStatusCodeB, setGatingProgressStatusCodeB] = useState<Code[]>([]);
  const [isOpenContEmpPopUp, setOpenContEmpPopUp] = useState<boolean>(false);
  const [contEmpPopUpCondition, setContEmpPopUpCondition] = useState({
    title: '',
    contIds: '',
  });
  // 날짜 변경 가능 여부
  const isEditableDate = useMemo(
    () => [GatingReqType.STATUS_CHANGE, GatingReqType.DATE_CHANGE].includes(reqTp) && !isReadOnly,
    [reqTp, isReadOnly]
  );
  // 상태 변경 가능 여부
  const isEditableStatus = useMemo(
    () => GatingReqType.STATUS_CHANGE === reqTp && !isReadOnly,
    [reqTp, isReadOnly]
  );

  useImperativeHandle(ref, () => ({
    validate: () => {
      // 변경 가능한 항목이 없는 경우
      if (!isEditableDate && !isEditableStatus) {
        return true;
      }
      const rows = (rowData || []).filter((o) => CrudCode.UPDATE === o.crudKey);
      if (rows.length) {
        return true;
      }
      openMessageBar({
        type: 'warning',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return false;
    },
    getBizReqData: (aprReqId: string, reqRsn: string) => {
      let details = [] as GatingChangeRequestDetail[];
      // 일정변경, 상태변경인 경우 변경된 요청상세목록 정보 가공
      if (isEditableDate || isEditableStatus) {
        details = rowData
          // .filter((o) => CrudCode.UPDATE === o.crudKey)
          .reduce(
            (acc, cur) => [
              ...acc,
              {
                aprReqId: aprReqId,
                gatingId: cur.gatingId,
                equipmentId: cur.equipmentId,
                inspectionSeqs: cur.inspectionSeqs,
                chgStDd: cur.startDate,
                chgEndDd: cur.endDate,
                chgProgStatCd: cur.progressStatusCode,
              } as GatingChangeRequestDetail,
            ],
            [] as GatingChangeRequestDetail[]
          );
      }
      // 저장
      const master = {
        castType: 'GatingChangeRequest', // 서버측 결재요청상세vo > bizReqData cast 타입
        aprReqId: aprReqId,
        gatingId: gatingId,
        reqTp: reqTp,
        reqRsn: reqRsn,
        requestDetailList: details,
      } as GatingChangeRequestMaster;

      return master;
    },
  }));
  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    findGatingInfo(gatingId).then((result) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t('com.label.잘못된 접근입니다.', '잘못된 접근입니다.'),
        });
        return;
      }
      setGatingMaster(result);
    });
    findGatingEquipments(gatingId, equipmentId || '', props.aprReqId).then((res) => {
      setRowData(
        (res || []).reduce(
          (acc, cur) => [
            ...acc,
            {
              ...cur,
              bfProgressStatusCode: cur.progressStatusCode,
            },
          ],
          [] as GatingEquipment[]
        )
      );
    });
  }, [gatingId, equipmentId]);

  const getCommonCodes = async () => {
    const progressStatusCodeB: Code[] = await getCommonCodeNames('GATING_PROGRESS_STATUS_CODE_B');
    setGatingProgressStatusCodeB(progressStatusCodeB);
  };

  const convertProgressStatusCodes = (statusCode: string) => {
    // 점검단계 중 등록, 완료 상태 제외
    const codes = (gatingProgressStatusCodeB || []).filter(
      (o) => !['A', 'D'].includes(o.cmnCd || '')
    );
    // 점검준비 ~ 현재 단계 목록
    const idx = codes.findIndex((o) => o.cmnCd === statusCode);
    return codes.slice(0, idx + 1);
  };

  const layoutDefinition = useMemo(() => {
    const mapProgressStatusCode = new DataMap(gatingProgressStatusCodeB, 'cmnCd', 'cmnCdNm');
    mapProgressStatusCode.getDisplayValues = (item) => {
      return convertProgressStatusCodes(item.bfProgressStatusCode)
        .filter((o) => !!o.cmnCdNm)
        .map((o) => o.cmnCdNm || '');
    };
    return [
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 43,
        align: 'center',
        isReadOnly: true,
        visible: isEditableDate || isEditableStatus,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'equipmentId',
        header: String(t('gtng.grid.Equipment', 'Equipment')),
        width: '*',
        align: 'left',
        isReadOnly: true,
      },
      {
        binding: 'equipmentName',
        header: String(t('gtng.grid.Equipment Desc', 'Equipment Desc')),
        width: '*',
        align: 'left',
        isReadOnly: true,
      },
      {
        binding: 'bfProgressStatusCode',
        width: 150,
        align: 'center',
        visible: false,
      },
      {
        binding: 'progressStatusCode',
        header: String(t('com.label.진행상태', '진행상태')),
        width: 150,
        align: 'center',
        cssClass: isEditableStatus ? 'WijmoSelect' : '',
        isReadOnly: !isEditableStatus,
        dataMap: mapProgressStatusCode,
      },
      {
        binding: 'startDate',
        header: String(t('gtng.grid.시작일', '시작일')),
        width: '*',
        align: 'left',
        cssClass: 'WijmoDate',
        // isReadOnly: !isEditableDate, // 컬럼 속성에서 isReadOnly 설정을 하는 경우 달력 아이콘 미노출되어 별도로 이벤트 제어
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'endDate',
        header: String(t('gtng.grid.종료일', '종료일')),
        width: '*',
        align: 'left',
        cssClass: 'WijmoDate',
        // isReadOnly: !isEditableDate, // 컬럼 속성에서 isReadOnly 설정을 하는 경우 달력 아이콘 미노출되어 별도로 이벤트 제어
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
    ];
  }, [reqTp, gatingProgressStatusCodeB, isEditableDate, isEditableStatus]);

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 시작일, 종료일 editable css 변경
      if (['startDate', 'endDate'].includes(binding)) {
        cell.ariaReadOnly = !isEditableDate;
      }
    }
  });

  const onBeginningEdit = useEvent((grid, e) => {
    const binding = grid.columns[e.col].binding;
    // readonly 설정
    // 시작일, 종료일
    if (['startDate', 'endDate'].includes(binding)) {
      if (!isEditableDate) {
        e.cancel = true;
      }
    }
  });

  const onCellEditEnding = (grid, e) => {
    const newVal = grid.activeEditor?.value;
    const data = grid.rows[e.row].dataItem;
    const binding = grid.columns[e.col].binding;

    if (['startDate', 'endDate'].includes(binding)) {
      const newDate = dayjs(newVal);
      if (!newDate.isValid()) {
        openMessageBar({
          type: 'error',
          content: t('gtng.msg.날짜를 정확히 입력해 주세요.', '날짜를 정확히 입력해 주세요.'),
        });
        e.cancel = true;
      }
      if (
        ('startDate' === binding && dayjs(data?.endDate).isBefore(newDate)) ||
        ('endDate' === binding && dayjs(data?.startDate).isAfter(newDate))
      ) {
        openMessageBar({
          type: 'error',
          content: t(
            'gtng.msg.종료일이 시작일보다 빠를수 없습니다.',
            '종료일이 시작일보다 빠를수 없습니다.'
          ),
        });
        e.cancel = true;
      }
    }
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 기본 정보', 'Gating 기본 정보')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <ContentSection>
        <TableContainer css={tb.table}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="secondCol">
                  {t('gtng.label.Product Type', 'Product Type')}
                </TableCell>
                <TableCell>{gatingMaster?.productTypeName}</TableCell>
                <TableCell className="secondCol">
                  {t('gtng.label.Gating Task명', 'Gating Task명')}
                </TableCell>
                <TableCell>{gatingMaster?.gatingName}</TableCell>
                <TableCell className="secondCol">
                  {t('gtng.label.Gating 유형', 'Gating 유형')}
                </TableCell>
                <TableCell>{gatingMaster?.gatingTypeName}</TableCell>
                <TableCell className="secondCol">
                  {t('gtng.label.Gating No', 'Gating No')}
                </TableCell>
                <TableCell>{gatingMaster?.gatingNo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="secondCol">
                  {t('gtng.label.등록 담당자', '등록담당자')}
                </TableCell>
                <TableCell>
                  <CustomInputWithSearch
                    name="gatingContRegisterNames"
                    className="find"
                    value={gatingMaster?.gatingContRegisterNames}
                    readOnly={true}
                    onSearchClick={() => {
                      setContEmpPopUpCondition({
                        title: t('gtng.label.등록 담당자', '등록담당자'),
                        contIds: gatingMaster?.gatingContRegisterIds || '',
                      });
                      setOpenContEmpPopUp(true);
                    }}
                  />
                </TableCell>
                <TableCell className="secondCol">{t('gtng.label.등록 T/L', '등록T/L')}</TableCell>
                <TableCell>
                  <CustomInputWithSearch
                    name="gatingContRegisterTlNames"
                    className="find"
                    value={gatingMaster?.gatingContRegisterTlNames}
                    readOnly={true}
                    onSearchClick={() => {
                      setContEmpPopUpCondition({
                        title: t('gtng.label.등록 T/L', '등록T/L'),
                        contIds: gatingMaster?.gatingContRegisterTlIds || '',
                      });
                      setOpenContEmpPopUp(true);
                    }}
                  />
                </TableCell>
                <TableCell className="secondCol">{t('gtng.label.점검일자', '점검일자')}</TableCell>
                <TableCell colSpan={3}>
                  {[gatingMaster?.startDate, gatingMaster?.endDate].filter((o) => !!o).join(' - ')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ContentSection>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 대상 장비 목록', 'Gating 대상 장비 목록')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={200}
        isSelector={false}
        isFilter={false}
        allowPinning={false}
        isReadOnly={isReadOnly}
        initialized={onInitialized}
        beginningEdit={onBeginningEdit}
        cellEditEnding={onCellEditEnding}
      />

      {/* 담당자 팝업 (단순 조회용) */}
      {isOpenContEmpPopUp && (
        <GatingContListPopUp
          open={isOpenContEmpPopUp}
          setReadonly={true}
          title={contEmpPopUpCondition?.title}
          initParam={contEmpPopUpCondition?.contIds}
          close={() => {
            setContEmpPopUpCondition({
              title: '',
              contIds: '',
            });
            setOpenContEmpPopUp(false);
          }}
        />
      )}
    </>
  );
};

export default forwardRef(GatingChangeRequest);
