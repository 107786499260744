/** @jsxImportSource @emotion/react */

import { getEmployeeBySearchCondition } from 'apis/admin/Employee';
import DepartmentTree from 'pages/admin/departmentmanagement/DepartmentTree';
import { Employee } from 'models/admin/Employee';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Department } from 'models/admin/Department';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { di, TitleWrap } from 'components/layouts/Dialog';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { BlueButton, GreyButton, GreyLineButton } from 'components/buttons/CustomButton';
import { ContentSection, GridInfo, GridInfoSection } from 'components/layouts/ContentSection';
import {
  SearchBox,
  SearchCols,
  InputBox,
  SearchBoxRow,
  SearchRows,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { CustomInputText, CustomInputWrap, UseToggle } from 'components/inputs/CustomInput';
import { ContentFlex } from 'components/layouts/ContentFlex';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import { CrudCode } from 'models/common/Edit';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { TooltipComponent, TooltipValueGetter } from 'components/grids/Tooltip';
import { CloseBtn } from 'components/buttons/CustomButton';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';

type Props = {
  open: boolean;
  close: () => void;
  save: (item: Employee[]) => void;
  singleSelect?: boolean;
};

const emptyDepartment: Department = {
  deptCd: '',
  copCd: '',
  deptNm: '',
  deptEngNm: '',
  temLdrUserId: '',
  upprDeptCd: '',
  useYn: '',
  deptCngNm: '',
};

export const defaultContext = {
  userDeptCd: '',
  userDepartment: emptyDepartment,
  departmentList: [] as Department[],
  selectedDepartment: [] as Department[],
  multipleSelect: false,
  handleSelectDepartment: (item: any) => {
    return;
  },
  handleGetAllDepartemtn: (item: any) => {
    return;
  },
};

export const DepartmentContext = createContext(defaultContext);

const EmployeeModal = (props: Props) => {
  const { t } = useTranslation();

  const gridRef = useRef<AgGridReact<Employee>>(null);
  const { openMessageBar } = useMessageBar();
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee[]>([]);
  const [searchItem, setSearchItem] = useState<string>('');
  const [useYn, setUseYn] = useState<string>('Y');
  const [contextValue, setContextValue] = useState({
    ...defaultContext,
    handleSelectDepartment: (item) => setContextValue(item),
  });

  const btnQuickSelect = (params) => {
    const handleButtonClick = () => {
      const empData = [
        {
          userId: params.data.userId,
          empNm: params.data.empNm,
          jtiNm: params.data.jtiNm,
          jpsNm: params.data.jpsNm,
          deptNm: params.data.deptNm,
          ofcPhn: params.data.ofcPhn,
          emlSvrDmnIfoNm: params.data.emlSvrDmnIfoNm,
        },
      ];
      props.save(empData);
    };
    return <button className={'cellRelay'} onClick={handleButtonClick} />;
  };

  const btnReload = () => {
    setContextValue({ ...contextValue, selectedDepartment: [emptyDepartment] });
    setSearchItem('');
    setUseYn('Y');
  };

  const btnDoubleClick = (params) => {
    const empData = [
      {
        userId: params.data.userId,
        empNm: params.data.empNm,
        jtiNm: params.data.jtiNm,
        jpsNm: params.data.jpsNm,
        deptNm: params.data.deptNm,
        ofcPhn: params.data.ofcPhn,
        emlSvrDmnIfoNm: params.data.emlSvrDmnIfoNm,
      },
    ];
    props.save(empData);
  };

  const [columnDefs, setColumnDefs] = useState<ColDef[]>([
    {
      width: 37,
      headerCheckboxSelection: props.singleSelect ? false : true,
      checkboxSelection: true,
      cellStyle: { textAlign: 'center' },
      //headerCheckboxSelection: true,
      cellClass: 'cellCheck',
    },
    {
      headerName: String(t('com.label.NO', 'NO')),
      width: 50,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        return params.node.rowIndex + 1;
      },
    },
    {
      headerName: 'Quick Select',
      width: 100,
      cellRenderer: btnQuickSelect,
      cellClass: 'WijmoRelay',
      cellStyle: { textAlign: 'center' },
      hide: true,
    },
    {
      field: 'userId',
      headerName: String(t('com.label.사용자ID', '사용자ID')),
      width: 120,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'empNm',
      headerName: String(t('com.label.사용자명', '사용자명')),
      width: 100,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'deptNm',
      headerName: String(t('com.label.부서명', '부서명')),
      flex: 1,
      width: 165,
      cellStyle: { textAlign: 'left' },
    },
    {
      field: 'jtiNm',
      headerName: String(t('com.label.직책/직급 호칭', '직책/직급 호칭')),
      width: 120,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'useYn',
      headerName: String(t('com.label.사용여부', '사용여부')),
      width: 60,
      cellStyle: { textAlign: 'center' },
    },
  ]);

  const defaultColumnDefs: ColDef = {
    cellStyle: { textAlign: 'center' },
    resizable: true,
    tooltipComponent: TooltipComponent,
    tooltipValueGetter: TooltipValueGetter,
  };

  useEffect(() => {
    props.open && contextValue.selectedDepartment[0] && getEmployeeList();
  }, [contextValue]);

  const getEmployeeList = useCallback(async () => {
    const response = await getEmployeeBySearchCondition(
      searchItem,
      contextValue.selectedDepartment[0] ? contextValue.selectedDepartment[0].deptCd : '',
      contextValue.selectedDepartment[0] ? contextValue.selectedDepartment[0].deptNm : '',
      searchItem,
      useYn
    );
    setEmployeeList(response);
  }, [contextValue, searchItem, useYn]);

  const handlOnClose = () => {
    setSelectedEmployee([]);
    props.close();
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(e.target.value);
  };

  const handleOnSelectionChange = useCallback(() => {
    setSelectedEmployee(gridRef.current?.api.getSelectedRows() ?? []);
  }, [employeeList]);

  const handleSearch = useCallback(() => {
    setContextValue({ ...contextValue, selectedDepartment: [emptyDepartment] });
    getEmployeeList();
  }, [searchItem]);

  const handleOnSave = () => {
    if (selectedEmployee && selectedEmployee.length <= 0) {
      openMessageBar({
        type: 'error',
        content: t('com.label.한명 이상 선택해야 합니다.', '한명 이상 선택해야 합니다.'),
      });
      return;
    }
    props.save(selectedEmployee);
    handlOnClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handlOnClose}
      css={di.dialog}
      fullWidth
      className="lg"
      maxWidth={false}
    >
      <TitleWrap>
        <h2>{t('com.label.사용자 검색', '사용자 검색')}</h2>
        <CloseBtn onClick={handlOnClose}></CloseBtn>
      </TitleWrap>
      <DialogContent className="popupContent">
        <SearchBox>
          <SearchBoxRow>
            <InputBox>
              <SearchRows>
                <SearchCols>
                  <label>{t('com.label.사용자명', '사용자명')}</label>
                  <CustomInputWrap>
                    <CustomInputText
                      value={searchItem}
                      onChange={handleOnChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') handleSearch();
                      }}
                    ></CustomInputText>
                  </CustomInputWrap>
                </SearchCols>
                <SearchCols>
                  <label>{t('com.label.사용여부', '사용여부')}</label>
                  <UseToggle className="switch">
                    <input
                      type="checkbox"
                      name="useYn"
                      value={useYn}
                      checked={useYn === 'Y'}
                      onChange={() => {
                        setUseYn(useYn === 'Y' ? 'N' : 'Y');
                      }}
                    />
                    <span className="slider"></span>
                    <span
                      className="labels"
                      data-on={t('com.label.사용', '사용')}
                      data-off={t('com.label.미사용', '미사용')}
                    ></span>
                  </UseToggle>
                </SearchCols>
              </SearchRows>
            </InputBox>
            <SearchButtonWrap>
              <Button
                css={IconButton.button}
                className="reload"
                onClick={btnReload}
                disableRipple
              ></Button>
              <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>
                {t('com.button.조회', '조회')}
              </Button>
            </SearchButtonWrap>
          </SearchBoxRow>
        </SearchBox>
        <ContentFlex>
          <ContentSection className="section width30p marginT0">
            <DepartmentContext.Provider value={contextValue}>
              <DepartmentTree totalCount={true} isRetrieveEnabled={props.open} />
            </DepartmentContext.Provider>
          </ContentSection>
          <ContentSection className="section width70p">
            <SubTitleLayout>
              <SubTitleGroup>
                <h3>{t('com.label.사용자', '사용자')}</h3>
                <span className="total">
                  {t('com.label.총', '총')} <span>{employeeList.length}</span>
                  {t('com.label.건', '건')}
                </span>
              </SubTitleGroup>
            </SubTitleLayout>
            <ContentGrid className="ag-theme-alpine" style={{ height: '640px' }}>
              <AgGridReact
                ref={gridRef}
                rowData={employeeList}
                defaultColDef={defaultColumnDefs}
                columnDefs={columnDefs}
                rowSelection={props.singleSelect ? 'single' : 'multiple'}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                onSelectionChanged={handleOnSelectionChange}
                overlayNoRowsTemplate="No rows to show"
                onCellDoubleClicked={btnDoubleClick}
              ></AgGridReact>
            </ContentGrid>
          </ContentSection>
        </ContentFlex>
      </DialogContent>
      <DialogActions className="popupBottom">
        <BlueButton onClick={handleOnSave}>{t('com.button.선택', '선택')}</BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeModal;
