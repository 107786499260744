import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { ParaItemCondition } from 'models/ip/popup/ParaItem';

/**파라미터항목 조회 */
export const findParaItem = async (condition) => {
  // condition?: ParaItemCondition
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/paraItemSelect`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<ParaItemCondition[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ParaItemCondition[];
};
