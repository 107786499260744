/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { ColDef, ValueFormatterParams, GridReadyEvent } from 'ag-grid-community';
import { CommonYN } from 'models/common/Common';

import { BlueButton, BlueLineButton, GreyLineButton } from 'components/buttons/CustomButton';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { GridTop, GridInfo, GridInfoSection, GridButtons } from 'components/layouts/ContentSection';
import { ViewTable } from 'components/tables/ViewTable';
import { CustomInputText } from 'components/inputs/CustomInput';
import { CustomSelect } from 'components/selects/CustomSelect';
import { getApprovalRuleMasters, setApprovalRuleMaster } from 'apis/admin/ApprovalRule';
import { ApprovalRuleMaster } from 'models/admin/Approval';
import {
  ApporoveExcludeCode,
  ApproveExcludeDetail,
  ApproveExcludeMaster,
} from 'models/admin/ApproveExclude';
import { getAllApproveExcludeDetails, getAllApproveExcludeList } from 'apis/admin/ApproveExclude';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import { Button, FormControl } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { getNotificationGroupUsers, getNotificationGroups } from 'apis/admin/Notification';
import { NotificationGroup, NotificationGroupUser } from 'models/admin/Notification';
import { set } from 'lodash';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from '../../../components/layouts/ContentLayout';
import { IconButton } from '../../../components/buttons/IconSVG';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';

interface SearchParamData {
  sRuleId: string;
  callBackFunc?: () => void;
}

const ApprovalMasterForm = (props: SearchParamData) => {
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();

  const [rowDataExc, setRowDataExc] = useState<ApproveExcludeDetail[]>([]);
  const [rowDataNtdk, setRowDataNtdk] = useState<NotificationGroupUser[]>([]);

  const [formRuleId, setFormRuleId] = useState<string>('');
  const [formRuleNm, setFormRuleNm] = useState<string>('');
  const [formLnAddPmitYn, setFormLnAddPmitYn] = useState<string>('');
  const [formRferAddPsblYn, setFormRferAddPsblYn] = useState<string>('');
  const [formLnRstbPmitYn, setFormLnRstbPmitYn] = useState<string>('');
  const [formLnDuplPmitYn, setFormLnDuplPmitYn] = useState<string>('');
  const [formExcId, setFormExcId] = useState<string>('');
  const [formNtdkId, setFormNtdkId] = useState<string>('');

  const [excList, setExcList] = useState<ApproveExcludeMaster[]>([]);
  const [ntdkList, setNtdkList] = useState<NotificationGroup[]>([]);

  const [code, setCode] = useState<ApporoveExcludeCode>();

  const data = {
    aprRuleId: formRuleId,
    aprRuleNm: formRuleNm,
    aprLnAddPmitYn: formLnAddPmitYn,
    aprRferAddPsblYn: formRferAddPsblYn,
    aprLnRstbPmitYn: formLnRstbPmitYn,
    aprLnDuplPmitYn: formLnDuplPmitYn,
    aprExcTgtId: formExcId,
    mstNtdkId: formNtdkId,
  } as ApprovalRuleMaster;

  const defaultColDef: ColDef = useMemo(
    () => ({
      editable: false,
      sortable: false,
      filter: false,
    }),
    []
  );

  const excColumnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: String(t('com.label.NO', 'NO')),
        field: 'crudKey',
        width: 100,
        cellStyle: { textAlign: 'center' },
        valueFormatter: (params: ValueFormatterParams) => {
          return `${parseInt(params.node!.id!) + 1}`;
        },
      },
      {
        headerName: String(t('admin.grid.제외구분', '제외구분')),
        field: 'aprExcDivsCd',
        width: 150,
        cellStyle: { textAlign: 'center' },
        valueFormatter: (params: ValueFormatterParams) => {
          return code?.divisionCode
            .map((node) => {
              return node.cmnCd == params.value && node.cmnCdNm;
            })
            .filter((e) => e != false)[0] as string;
        },
      },
      {
        headerName: String(t('admin.grid.제외대상', '제외대상')),
        field: 'aprExcUserId',
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) => {
          return params.data.aprExcUserInfo;
          // 아래 switch는 무효코드
          switch (params.data.aprExcDivsCd) {
            case 'JPS':
              return code?.jpsCode
                .map((node) => {
                  return node.cmnCd == params.value && node.cmnCdNm;
                })
                .filter((e) => e != false)[0] as string;
            case 'JTI':
              return code?.jtiCode
                .map((node) => {
                  return node.cmnCd == params.value && node.cmnCdNm;
                })
                .filter((e) => e != false)[0] as string;
            default:
              return params.data.aprExcUserInfo;
          }
        },
      },
    ],
    [code]
  );

  const ntdkColumnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: String(t('com.label.NO', 'NO')),
        field: 'crudKey',
        width: 100,
        cellStyle: { textAlign: 'center' },
        filter: false,
        valueFormatter: (params: ValueFormatterParams) => {
          return `${parseInt(params.node!.id!) + 1}`;
        },
      },
      {
        headerName: String(t('com.label.아이디', '아이디')),
        width: 120,
        field: 'aprNotfUserId',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: String(t('admin.grid.이름', '이름')),
        width: 120,
        field: 'empNm',
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: String(t('admin.grid.직책/직급호칭', '직책/직급호칭')),
        field: 'jtiNm',
        width: 150,
        cellStyle: { textAlign: 'center' },
      },
      {
        headerName: String(t('admin.grid.부서', '부서')),
        flex: 1,
        field: 'deptNm',
        resizable: true,
      },
    ],
    []
  );

  const fnSearchApprovalMaster = useCallback((param: string) => {
    if (param)
      getApprovalRuleMasters(param, '').then((result: ApprovalRuleMaster[]) => {
        fnSetResultData(result[0]);
      });
    else fnFormInit(false);
  }, []);

  const [editable, setEditable] = useState<boolean>(false);

  const fnFormInit = (edit: boolean) => {
    setFormRuleId('');
    setFormRuleNm('');
    setFormLnAddPmitYn(CommonYN.Y);
    setFormRferAddPsblYn(CommonYN.Y);
    setFormLnRstbPmitYn(CommonYN.Y);
    setFormLnDuplPmitYn(CommonYN.Y);
    setFormExcId('');
    setFormNtdkId('');
    setRowDataExc([]);
    setRowDataNtdk([]);
    setEditable(edit);
    props.callBackFunc && props.callBackFunc();
  };

  const fnSetResultData = (result: ApprovalRuleMaster) => {
    setFormRuleId(result.aprRuleId);
    setFormRuleNm(result.aprRuleNm);
    setFormLnAddPmitYn(result.aprLnAddPmitYn);
    setFormRferAddPsblYn(result.aprRferAddPsblYn);
    setFormLnRstbPmitYn(result.aprLnRstbPmitYn);
    setFormLnDuplPmitYn(result.aprLnDuplPmitYn);
    setFormExcId(result.aprExcTgtId || '');
    fnGetExcList(result.aprExcTgtId || '');
    setFormNtdkId(result.mstNtdkId || '');
    fnGetNtdkList(result.mstNtdkId || '');
    setEditable(true);
  };

  const fnGetExcList = (excId: string) => {
    getAllApproveExcludeDetails(excId).then((result: ApproveExcludeDetail[]) => {
      setRowDataExc(result);
    });
  };
  const fnGetNtdkList = (ntdkId: string) => {
    if (ntdkId === '') {
      setRowDataNtdk([]);
    } else {
      getNotificationGroupUsers({ ntdkId: ntdkId }).then((result: NotificationGroupUser[]) => {
        setRowDataNtdk(result);
      });
    }
  };

  const getCommonCodesForGrid = async () => {
    const divisionCodeRes: Code[] = await getCommonCodeNames('APR_EXC_DIVS_CD');
    const jpsCodeRes: Code[] = await getCommonCodeNames('JPS_CD');
    const jtiCodeREs: Code[] = await getCommonCodeNames('JTI_CD');

    setCode({
      divisionCode: divisionCodeRes,
      jpsCode: jpsCodeRes,
      jtiCode: jtiCodeREs,
    });
  };

  const btnSave = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      noCallback: () => {
        return false;
      },
    });

    //Validation Start
    if (!data.aprRuleNm)
      return openCommonModal({
        content: t('admin.msg.결재규칙명을 입력하세요.', '결재규칙명을 입력하세요.'),
      });
    //아래는 화면상 구현 불가능한 validation. console 등을 통해서만 구현 가능.
    if (!data.aprLnAddPmitYn)
      return openCommonModal({
        content: t(
          'admin.msg.Please enter an item: aprLnAddPmitYn',
          'Please enter an item: aprLnAddPmitYn'
        ),
      });
    if (!data.aprLnDuplPmitYn)
      return openCommonModal({
        content: t(
          'admin.msg.Please enter an item: aprLnDuplPmitYn',
          'Please enter an item: aprLnDuplPmitYn'
        ),
      });
    if (!data.aprLnRstbPmitYn)
      return openCommonModal({
        content: t(
          'admin.msg.Please enter an item: aprLnRstbPmitYn',
          'Please enter an item: aprLnRstbPmitYn'
        ),
      });
    if (!data.aprRferAddPsblYn)
      return openCommonModal({
        content: t(
          'admin.msg.Please enter an item: aprRferAddPsblYn',
          'Please enter an item: aprRferAddPsblYn'
        ),
      });
    //Validation End

    setApprovalRuleMaster(data).then((result) => {
      if (result.successOrNot === 'N') {
        openCommonModal({
          content: ('statusCode: ' + result.statusCode + '\nmessage: ' + result.data) as string,
        });
        return false;
      }
      openCommonModal({
        content: t('com.label.저장되었습니다.', '저장되었습니다.'),
      });
      fnSearchApprovalMaster(props.sRuleId);
      props.callBackFunc && props.callBackFunc();
    });
  };

  useEffect(() => {
    fnSearchApprovalMaster(props.sRuleId);
  }, [props]);

  useEffect(() => {
    getCommonCodesForGrid();
    getAllApproveExcludeList('').then((result: ApproveExcludeMaster[]) => {
      setExcList(result);
    });

    getNotificationGroups({ ntdkNm: '' }).then((result: NotificationGroup[]) => {
      setNtdkList(result);
    });
  }, []);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.결재라인 기본정보', '결재라인 기본정보')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <FormControl fullWidth>
        <ViewTable>
          <colgroup>
            <col width="15%" />
            <col />
            <col width="15%" />
            <col />
            <col width="15%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label>{t('admin.label.결재규칙ID', '결재규칙ID')}</label>
              </th>
              <td>
                <CustomInputWithSearch readOnly={true} value={formRuleId} />
              </td>
              <th scope="row">
                <label>{t('admin.label.결재규칙명', '결재규칙명')}</label>
              </th>
              <td colSpan={3}>
                <CustomInputWithSearch
                  value={formRuleNm}
                  onChange={(e) => {
                    editable && setFormRuleNm(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('admin.label.결재라인 추가허용', '결재라인 추가허용')}</label>
              </th>
              <td>
                <CustomSelect
                  value={formLnAddPmitYn}
                  onChange={(e) => {
                    editable && setFormLnAddPmitYn(e.target.value);
                  }}
                >
                  <option value={CommonYN.Y}>{t('com.label.사용', '사용')}</option>
                  <option value={CommonYN.N}>{t('com.label.사용안함', '사용안함')}</option>
                </CustomSelect>
              </td>
              <th scope="row">
                <label>{t('admin.label.통보자 추가허용', '통보자 추가허용')}</label>
              </th>
              <td>
                <CustomSelect
                  value={formRferAddPsblYn}
                  onChange={(e) => {
                    editable && setFormRferAddPsblYn(e.target.value);
                  }}
                >
                  <option value={CommonYN.Y}>{t('com.label.사용', '사용')}</option>
                  <option value={CommonYN.N}>{t('com.label.사용안함', '사용안함')}</option>
                </CustomSelect>
              </td>
              <th scope="row">
                <label>{t('admin.label.재설정 허용', '재설정 허용')}</label>
              </th>
              <td>
                <CustomSelect
                  value={formLnRstbPmitYn}
                  onChange={(e) => {
                    editable && setFormLnRstbPmitYn(e.target.value);
                  }}
                >
                  <option value={CommonYN.Y}>{t('com.label.사용', '사용')}</option>
                  <option value={CommonYN.N}>{t('com.label.사용안함', '사용안함')}</option>
                </CustomSelect>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('admin.label.결재제외', '결재제외')}</label>
              </th>
              <td>
                <CustomSelect
                  value={formExcId}
                  onChange={(e) => {
                    editable && setFormExcId(e.target.value);
                    editable && fnGetExcList(e.target.value);
                  }}
                >
                  <option value={''}> {t('admin.label.사용안함', '사용안함')} </option>
                  {excList.map((item, key) => (
                    <option value={item.aprExcTgtId} key={key}>
                      {item.aprExcNm}
                    </option>
                  ))}
                </CustomSelect>
              </td>
              <td colSpan={4}>
                <ContentGrid style={{ height: '200px' }} className="ag-theme-alpine">
                  <AgGridReact<ApproveExcludeDetail>
                    overlayNoRowsTemplate="No rows to show"
                    rowData={rowDataExc}
                    columnDefs={excColumnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    suppressRowClickSelection={true}
                    stopEditingWhenCellsLoseFocus={true}
                    singleClickEdit={true}
                    editType={'fullRow'}
                  />
                </ContentGrid>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('admin.label.기본통보처', '기본통보처')}</label>
              </th>
              <td>
                <CustomSelect
                  value={formNtdkId}
                  onChange={(e) => {
                    editable && setFormNtdkId(e.target.value);
                    editable && fnGetNtdkList(e.target.value);
                  }}
                >
                  <option value={''}> {t('com.label.미사용', '미사용')} </option>
                  {ntdkList.map((item, key) => (
                    <option value={item.ntdkId} key={key}>
                      {item.ntdkNm}
                    </option>
                  ))}
                </CustomSelect>
              </td>
              <td colSpan={4}>
                <ContentGrid className="ag-theme-alpine" style={{ height: '200px' }}>
                  <AgGridReact<NotificationGroupUser>
                    overlayNoRowsTemplate="No rows to show"
                    rowData={rowDataNtdk}
                    columnDefs={ntdkColumnDefs}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    suppressRowClickSelection={true}
                    stopEditingWhenCellsLoseFocus={true}
                    singleClickEdit={true}
                    rowSelection="multiple"
                  />
                </ContentGrid>
              </td>
            </tr>
          </tbody>
        </ViewTable>
      </FormControl>
      <GlobalBtnGroup>
        <Button
          css={IconButton.button}
          className="write"
          disableRipple
          onClick={() => {
            fnFormInit(true);
          }}
        >
          {t('com.button.신규', '신규')}
        </Button>
        <Button css={IconButton.button} className="save" disableRipple onClick={btnSave}>
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>
    </>
  );
};

export default memo(ApprovalMasterForm);
