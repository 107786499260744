/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { ColDef, ValueFormatterParams, GridReadyEvent } from 'ag-grid-community';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { GridTop, GridInfo, GridInfoSection } from 'components/layouts/ContentSection';
import { ApprovalRuleMaster } from 'models/admin/Approval';
import { getApprovalRuleMasters } from 'apis/admin/ApprovalRule';
import { useTranslation } from 'react-i18next';
import {
  ControlBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from '../../../components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from '../../../components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
interface SearchParamData {
  sRuleData: ApprovalRuleMaster[];
  callBackFunc?: (e: string) => void;
}

const ApprovalMasterGrid = (props: SearchParamData) => {
  const { t } = useTranslation();

  const gridRef = useRef<AgGridReact<ApprovalRuleMaster>>(null);
  const [rowData, setRowData] = useState<ApprovalRuleMaster[]>([]);
  const [flexRef, setFlexRef] = useState<any>();
  useEffect(() => {
    setRowData(props.sRuleData);
  }, [props.sRuleData]);

  const onInitialized = (grid) => {
    setFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;
      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          // 상세보기
          case 'btnDetail':
            props.callBackFunc && props.callBackFunc(item.aprRuleId);
            break;
        }
      }
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'detail',
      header: String(t('com.label.상세', '상세')),
      width: 45,
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: `<Button id="btnDetail"></Button>`,
    },
    {
      binding: 'aprRuleId',
      header: String(t('admin.grid.결재규칙ID', '결재규칙ID')),
      width: 130,
    },
    {
      binding: 'aprRuleNm',
      header: String(t('admin.grid.결재규칙명', '결재규칙명')),
      align: 'left',
      width: '*',
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.결재규칙', '결재규칙')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        rowData={rowData}
        height={500}
        deferResizing={false}
        align={'center'}
        layoutDefinition={layoutDefinition}
        initialized={onInitialized}
        isSelector={false}
        isReadOnly={true}
        excludeFilter={['detail']}
        excludePin={['detail']}
      />
    </>
  );
};

export default memo(ApprovalMasterGrid);
