import { PaginationRequest } from 'models/common/Pagination';
import { Crud } from '../common/Edit';
import { MpResult } from './MpResult';

export enum TapButtonType {
  ButtonSave = 'SAVE',
  ButtonNiDevComp = 'NI02', // 신규개선 개발완료
  ButtonNiDrop = 'NI03', // 신규개선 드랍
  ButtonMpReview = 'MP02', // MP 검토중
  ButtonMpVal = 'MP03', // MP 검증중
  ButtonMpValComp = 'MP04', // MP 검증완료
  ButtonMpDrop = 'MP05', // 설비변경점 드랍
  ButtonMpHeReview = 'MPHE02', // MP 검토중
  ButtonMpHeVal = 'MPHE03', // MP 검증중
  ButtonMpHeValComp = 'MPHE04', // 수평전개 검증완료
  ButtonMpHeAction = 'MPHE05', // 수평전개 실행
  ButtonMpHeComp = 'MPHE06', // 수평전개 완료
  ButtonMpHeDrop = 'MPHE07', // 수평전개 드랍
  ButtonHeUnitVal = 'HE02', // 대표호기 검증
  ButtonHeAction = 'HE03', //  수평전개 실행
  ButtonHeComp = 'HE04', // 수평전개 완료
  ButtonHeDrop = 'HE05', // 수평전개 Drop
}

export enum MpItemTpCho {
  MP = 'MP', // 설비변경점
  HE = 'HE', // 수평전개
  MPHE = 'MPHE', // 설비변경점/수평전개
  NI = 'NI', // 신규개선
}

export enum MpItemAtchFileTpCd {
  MP_ITEM = 'MP_ITEM', // 첨부파일
  MP_MINUTES = 'MP_MINUTES', // 회의록
  SPEC_SHEET = 'SPEC_SHEET', // 표준사양서
  MP_ITEM_RPT = 'MP_ITEM_RPT', // 개조/개선 Item 보고서
  VAID_EVL_RPT = 'VAID_EVL_RPT', // 유효성 평가 보고서
  SCMM_MINT = 'SCMM_MINT', // 분과 위원회회의록
}

export interface MpItemRequestCondition extends PaginationRequest {
  aprReqId?: string;
}

export interface MpItemApprovalRequest extends PaginationRequest {
  xlsUploadId?: string;
  aprReqId?: string;
  aprTplId?: string;
  aprPvnDdlnCd?: string;
  aprReqTitNm?: string;
  trnmBdyCtn?: string;
  mode?: string;

  reqSeqId?: string;
  reqType?: string;
  mpReqList?: MpItem[];
}

export interface MpItemBookmarkRequest extends PaginationRequest {
  bmkGrId?: string;
  mpItems?: MpItem[];
}

export interface MpItemCopyRequest extends PaginationRequest {
  mpItems?: MpItem[];
}

export interface MpItemCondition extends PaginationRequest {
  itemId?: string; // 품목ID
  mpClsfCd?: string; // MP분류코드 (tb_elm_mp_item_tp_n)
  procCd?: string; // 공정코드
  eqclId?: string; // 설비군
  eqpClsfNo?: string; // 설비분류번호
  itemNm?: string; // 품목명
  itemTpChoCd?: string; // 품목유형선택코드
  itemStatCd?: string; // item 상태
  iprvClsfLv1Cd?: string; // 개선분류1레벨코드
  iprvClsfLv2Cd?: string; // 개선분류2레벨코드
  wkDivsCd?: string; // 작업구분코드
  reqMgrId?: string; // 기술제안자
  startDataInsDtm?: string; // 등록일 검색시작일
  endDataInsDtm?: string; // 등록일 검색종료일
  bmkGrId?: string; // 즐겨찾기그룹ID
  eqpLv1Cd?: string; // 설비1레벨코드
  eqpLv2Cd?: string; // 설비2레벨코드
  eqpLv3Cd?: string; // 설비3레벨코드
  dataInsUserId?: string; // 데이터입력사용자ID
}

export interface MpItem extends Crud {
  itemId?: string; // 품목ID
  itemNm?: string; // 품목명
  itemEngNm?: string; // 품목영어명
  procCd?: string; // 공정코드
  eqclId?: string; // 설비군
  eqpClsfNo?: string; // 설비분류번호
  eqpLv1Cd?: string; // 설비1레벨코드
  eqpLv2Cd?: string; // 설비2레벨코드
  eqpLv3Cd?: string; // 설비3레벨코드
  eqpLv4Cd?: string; // 설비4레벨코드
  eqpLv1Nm?: string; // 설비1레벨명
  eqpLv2Nm?: string; // 설비2레벨명
  eqpLv3Nm?: string; // 설비3레벨명
  eqpLv4Nm?: string; // 설비4레벨명
  wkDivsCd?: string; // 작업구분코드
  iprvClsfLv1Cd?: string; // 개선분류1레벨코드
  iprvClsfLv1CdNm?: string;
  iprvClsfLv2Cd?: string; // 개선분류2레벨코드
  iprvClsfLv2CdNm?: string;
  itemStatCd?: string; // 품목상태코드
  itemStatCdNm?: string;
  reqMgrId?: string; // 요청담당자ID
  reqMgrNm?: string; // 요청담당자명
  itemTpChoCd?: string; // 품목유형선택코드
  heYn?: string; // 수평전개여부
  mpYn?: string; // MP여부
  niYn?: string; // 신규개선여부
  duplYn?: string; // 중복여부
  m4ChgYn?: string; // M4변경여부
  spshRvwYn?: string; // 사양서검토여부
  isuDesc?: string; // 이슈설명
  causCtn?: string; // 원인내용
  causTactCtn?: string; // 원조치내용
  iprvCtn?: string; // 개선내용
  rmk?: string; // 비고
  atchFileGrId?: string; // 첨부파일그룹ID
  useYn?: string; // 사용여부
  optValCtn1?: string; // 옵션값내용1
  optValCtn2?: string; // 옵션값내용2
  optValCtn3?: string; // 옵션값내용3
  optValCtn4?: string; // 옵션값내용4
  optValCtn5?: string; // 옵션값내용5
  dataInsUserId?: string; // 데이터입력사용자ID
  dataInsUserNm?: string;
  dataInsDtm?: string; // 데이터입력일시
  dataUpdUserId?: string; // 데이터수정사용자ID
  dataUpdUserNm?: string;
  dataUpdDtm?: string; // 데이터수정일시
  mpClsfCd?: string; // MP분류코드목록문자열
  mpClsfCds?: string; // MP분류코드목록 (tb_elm_mp_item_tp_n)
  copCdNm?: string; // 법인코드명 (ex. LGESGM, LGESHD, ...)
  atchFileCnt?: number; // 첨부파일수
  specSheetAtchFileCnt?: number; // 표준사양서 첨부파일수
  mpItemRptAtchFileCnt?: number; // 개조/개선 Item 보고서 첨부파일수
  vaidEvlRptFileCnt?: number; // 유효성 평가보고서 첨부파일수
  scmmMintAtchFileCnt?: number; // 분과위원회 회의록 첨부파일수
  resultList?: MpResult[]; // 결과정보 목록
  itemTpCd?: string; // 품목유형코드 (ex. MP, HE, NI)
}

export interface MpResultN extends MpItem {
  execPlanId?: string;
  itemId?: string;
  itemTpCd?: string;
  copCd?: string;
  plntCd?: string;
  lnId?: string;
  eqpId?: string;
  tgtYn?: string;
  planDtm?: string;
  cpltDtm?: string;
  wkProgCtn?: string;
  rmk?: string;
  atchFileGrId?: string;
  useYn?: string;
  optValCtn1?: string;
  optValCtn2?: string;
  optValCtn3?: string;
  optValCtn4?: string;
  optValCtn5?: string;
}
