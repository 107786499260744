/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortDescription } from '@grapecity/wijmo';
import { Button } from '@mui/material';
import _ from 'lodash';
import { ContentSection } from 'components/layouts/ContentSection';
import MyBookmarkGrid from '../admin/myBookmarkmanagement/MyBookmarkGrid';
import { getMyBookmarkDtl } from 'apis/admin/MyBookmark';
import { MyBookmarkDtl } from 'models/admin/MyBookmark';
import { IconButton } from 'components/buttons/IconSVG';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { CustomInputText } from 'components/inputs/CustomInput';
import { MpItem, MpItemCondition } from 'models/mp/MpItem';
import { findMpItems } from 'apis/mp/MpItem';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { saveMyBookmarkDtl } from 'apis/admin/MyBookmark';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import MPCommonSearch from './base/MPCommonSearch';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';

type Props = { open: boolean; close: () => void };

const MpMyBookmarkModal = (props: Props) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const masterRef = useRef<any>();
  const detailRef = useRef<any>();
  const [myBmkDtlIds, setMyBmkDtlIds] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [textboxBmkGrNm, setTextboxBmkGrNm] = useState<string>('');
  const [textboxBmkGrId, setTextboxBmkGrId] = useState<string>('');
  const [textboxBmkGrDesc, setTextboxBmkGrDesc] = useState<string>('');
  const [rowData, setRowData] = useState<MpItem[]>([]);
  const [checkedItems, setCheckedItems] = useState<MpItem[]>([]);
  const [mpItemCondition, setMpItemCondition] = useState<MpItemCondition>({});

  const handlOnClose = () => {
    props.close();
  };

  const searchMasterGrid = async () => {
    masterRef.current?.search(textboxBmkGrDesc);
  };

  const onChangeDetail = (bmkGrId: string, bmkGrDesc: string) => {
    setTextboxBmkGrNm(bmkGrDesc);
    setTextboxBmkGrId(bmkGrId);
    searchDetailGrid(mpItemCondition);
  };

  const handlOnSave = async () => {
    if (textboxBmkGrId == '' || textboxBmkGrId == null) {
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.선택된 관심항목이 없습니다. 상세보기 클릭 후 저장 바랍니다.',
          '선택된 관심항목이 없습니다. 상세보기 클릭 후 저장 바랍니다.'
        ),
      });
      return;
    }
    const oldList = myBmkDtlIds.split(',');
    const newList: string[] = [];
    (checkedItems || []).forEach((item) => {
      newList.push(item?.itemId || '');
    });
    if (JSON.stringify(oldList) === JSON.stringify(newList)) {
      openMessageBar({
        type: 'error',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        saveMyBookmarkDtl(myBmkDtlIds, newList + '', textboxBmkGrId).then((response) => {
          if (response != null && response.successOrNot == 'N') {
            return;
          }
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          searchMasterGrid();
          searchDetailGrid(mpItemCondition);
        });
      },
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchMasterGrid();
    }
  };

  const handleSearch = async (condition: MpItemCondition) => {
    setMpItemCondition(condition);
    searchDetailGrid(condition);
  };

  const searchDetailGrid = async (condition: MpItemCondition) => {
    const response = await findMpItems(condition);
    if (response) {
      const returnData = response || [];
      setRowData(returnData);
      getMyBookmarkDtl(inputRef.current?.value || '').then((result: MyBookmarkDtl[]) => {
        const myBmkDtlList: string[] = [];
        (detailRef.current?.rows || []).forEach((r) => {
          result.forEach((item) => {
            if (r.dataItem?.itemId == item?.bmkAtcCd) {
              myBmkDtlList.push(r.dataItem?.itemId || '');
              r.isSelected = true;
              r.dataItem.sortNo = 1;
            }
          });
        });
        const sd = new SortDescription('sortNo', true);
        detailRef.current.collectionView.sortDescriptions.push(sd);
        detailRef.current?.refresh();
        setMyBmkDtlIds(myBmkDtlList + '');
      });
    }
  };

  const layoutDefinition = [
    {
      header: String(t('mp.grid.Item', 'Item')),
      align: 'center',
      columns: [
        {
          binding: 'itemId',
          header: String(t('mp.grid.번호', '번호')),
          isReadOnly: true,
          align: 'center',
          width: 110,
        },
        {
          binding: 'itemNm',
          header: String(t('mp.grid.명', '명')),
          isReadOnly: true,
          width: 200,
        },
        {
          binding: 'itemEngNm',
          header: String(t('mp.grid.영문명', '영문명')),
          isReadOnly: true,
          width: 200,
        },
      ],
    },
    {
      binding: 'mpClsfCdNm',
      header: String(t('mp.grid.MP 분류', 'MP 분류')),
      width: 120,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'procCdNm',
      header: String(t('mp.grid.공정', '공정')),
      isReadOnly: true,
      align: 'center',
      width: 120,
    },
    {
      header: String(t('mp.grid.설비분류', '설비분류')),
      align: 'center',
      columns: [
        {
          binding: 'eqpGr',
          header: String(t('com.label.설비군', '설비군')),
          width: 180,
          isReadOnly: true,
        },
        {
          binding: 'eqpClsfNm',
          header: String(t('mp.grid.설비분류체계', '설비분류체계')),
          width: 180,
          isReadOnly: true,
        },
        {
          binding: 'eqpLv1Nm',
          header: String(t('mp.grid.Main', 'Main')),
          isReadOnly: true,
          align: 'center',
          width: 120,
        },
        {
          binding: 'eqpLv2Nm',
          header: String(t('mp.grid.Machine', 'Machine')),
          isReadOnly: true,
          align: 'center',
          width: 120,
        },
        {
          binding: 'eqpLv3Nm',
          header: String(t('mp.grid.Unit', 'Unit')),
          isReadOnly: true,
          align: 'center',
          width: 120,
        },
      ],
    },
    {
      binding: 'sortNo',
      header: 'sort',
      width: 5,
      visible: false,
    },
  ];

  const onInitialized = (grid) => {
    detailRef.current = grid;
  };

  const dialogButtons = [
    <Button
      key={'save'}
      css={IconButton.button}
      className="save"
      onClick={handlOnSave}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  return (
    <>
      <CustomDialog
        title={t('mp.label.나의 관심항목 조회', '나의 관심항목 조회')}
        open={props.open}
        size={'xl'}
        onClose={handlOnClose}
        onCancel={handlOnClose}
        buttons={dialogButtons}
      >
        <SearchBox>
          <SearchBoxRow>
            <InputBox>
              <SearchRows>
                <SearchCols>
                  <label>
                    <span>{t('mp.label.관심항목명', '관심항목명')}</span>
                  </label>
                  <CustomInputWithSearch
                    className="width400"
                    value={textboxBmkGrDesc}
                    onKeyDown={handleKeyDown}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setTextboxBmkGrDesc(e.target.value);
                    }}
                  />
                </SearchCols>
              </SearchRows>
            </InputBox>
            <SearchBoxButtons
              onReset={() => setTextboxBmkGrDesc('')}
              onSearch={() => searchMasterGrid()}
            />
          </SearchBoxRow>
        </SearchBox>
        <ContentSection>
          <MyBookmarkGrid
            ref={masterRef}
            sBmkGrDesc={textboxBmkGrDesc}
            onChangeDetail={onChangeDetail}
          />
        </ContentSection>
        <ContentSection>
          <MPCommonSearch type={'mybook'} onHandleSearch={handleSearch} isDefaultSearch={true} />
        </ContentSection>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{String(t('mp.label.설비변경점/수평전개 Item', '설비변경점/수평전개 Item'))}</h3>
            <span className="total">
              {t('com.label.총', '총')}
              <span>{rowData?.length ?? 0}</span>
              {t('com.label.건', '건')}
            </span>
            {/* 안내문 class 종류는 default,error,warning,confirm,primary가 있습니다. */}
            {!_.isEmpty(textboxBmkGrNm) && <div className="info warning">{textboxBmkGrNm}</div>}
            <CustomInputText ref={inputRef} type="hidden" value={textboxBmkGrId} />
          </SubTitleGroup>
        </SubTitleLayout>
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={258}
          isReadOnly={true}
          autoCheck={true}
          initialized={onInitialized}
          onChangeCheckedItem={(items) => setCheckedItems(items)}
        />
      </CustomDialog>
    </>
  );
};

export default MpMyBookmarkModal;
