/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useLocation, useNavigate } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ContentSection, GridButtons, GridInfoSection } from 'components/layouts/ContentSection';
import { BlueButton, GreyLineButton } from 'components/buttons/CustomButton';
import { ViewTable } from 'components/tables/ViewTable';
import { CustomInputText } from 'components/inputs/CustomInput';
import DatePicker, { DatePickerWrap } from 'components/inputs/DatePicker';
import { BbsEmployee, BbsPostDetail } from 'models/admin/Bbs';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CrossEditor from 'components/crosseditor/CrossEditor';
import dayjs, { Dayjs } from 'dayjs';
import { CustomSelect } from 'components/selects/CustomSelect';
import Dropzone from 'components/dropzone/Dropzone';
import { useCommonModal } from 'hooks/useCommonModal';
import { createBbs, deleteBbs, findBbsPost, modifyBbs } from 'apis/admin/Bbs';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { Code } from 'models/common/CommonCode';
import { EditType } from 'models/common/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { ComboBox } from 'components/selects/ComboBox';
import Datepicker from 'react-tailwindcss-datepicker';
import { GlobalBtnGroup } from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { MenuContext } from 'App';
import { stat } from 'fs';
import { MenuContextType } from 'models/admin/Menu';

const mockBbsEmployee: BbsEmployee = {
  userId: '',
  empNm: '',
  deptNm: '',
  jtiNm: '',
  jpsNm: '',
  ofcTanoPhn: '',
  email: '',
};

interface BbsEditProps {
  editType?: EditType;
  editBbmNo?: string;
}

export const BbsEditPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const state = { ...location.state };

  const emptyPostDetail: BbsPostDetail = {
    bbmNo: '',
    dataInsUser: mockBbsEmployee,
    dataInsDtm: '',
    dataUpdUser: mockBbsEmployee,
    dataUpdDtm: '',
    bbsTpCd: state.bbsTpCd,
    bbsTpNm: state.bbsTpNm,
    ptupTgtCopCd: 'Z000',
    bbmTitNm: '',
    bbmCtn: '',
    atchFileGrId: '',
  };
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();
  const menuContext = useContext<MenuContextType>(MenuContext);

  const [bbsDetail, setBbsDetail] = useState<BbsPostDetail>(emptyPostDetail);
  const [bbsDivisionCode, setBbsDivisionCode] = useState<Code[]>([]);
  const [ptupTgtCopCd, setPtupTgtCopCd] = useState<Code[]>([]);
  const [onLoad, setOnload] = useState<any>(false);
  const [customBbsDetail, setCustomBbsDetail] = useState<any>({
    prevMenuCode: '',
    disableMenu: false,
    dateValue: '20991231',
  });

  const editorRef = useRef<CrossEditor>(null);
  const dropzoneRef = useRef<any>(null);
  useEffect(() => {
    (async function () {
      const bbsTpCd = await getCommonCodeNames('BBS_TP_CD');
      if (bbsTpCd != null) setBbsDivisionCode(bbsTpCd);

      const copCD = await getCommonCodeNames('COP_CD');
      if (copCD != null) setPtupTgtCopCd(copCD);

      if (Object.keys(state).includes('prevMenuCode')) {
        if (state.prevMenuCode === 'menual') {
          setCustomBbsDetail({
            prevMenuCode: state.prevMenuCode,
            disableMenu: true,
            dateValue: '20991231',
          });
        } else {
          setCustomBbsDetail({
            prevMenuCode: '',
            disableMenu: false,
            dateValue: dayjs(bbsDetail.ptupEndDt).format('YYYYMMDD'),
          });
        }
      }
    })();
  }, [state.prevMenuCode]);

  // 에디터 처음 실행될때
  const handleEditorOnLoad = async () => {
    if (state.editType === EditType.UPDATE && state.editBbmNo) {
      const result = await findBbsPost(state.editBbmNo);
      if (result) {
        result && setBbsDetail(result);
        editorRef.current?.editorRef.SetBodyValue(result.bbmCtn);
      }
    }

    if (state.editType === EditType.CREATE && state.editBbmNo == '') {
      setBbsDetail(emptyPostDetail);
      editorRef.current?.editorRef.SetBodyValue('');
      dropzoneRef.current?.initDropZone();
    }
    setOnload(true);
  };

  useEffect(() => {
    if (onLoad == true) {
      handleEditorOnLoad();
    }
  }, [state.editBbmNo]);

  const handleOnChangeBbsTpCd = (value) => {
    setBbsDetail({ ...bbsDetail, bbsTpCd: value });
  };

  const handleOnChangePtupTgtCopCd = (value) => {
    setBbsDetail({ ...bbsDetail, ptupTgtCopCd: value });
  };

  const handleOnChangeBbmTitNm = (e) => {
    setBbsDetail({ ...bbsDetail, bbmTitNm: e.target.value });
  };

  // const handleDateChange = (date: Dayjs) => {
  //   date && setBbsDetail({ ...bbsDetail, ptupEndDt: date.format('YYYYMMDD') });
  // };

  const handleOnDelete = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('admin.msg.게시글을 삭제하시겠습니까?', '게시글을 삭제하시겠습니까?'),
      yesCallback: async () => {
        const response = await deleteBbs(bbsDetail.bbmNo);
        if (response != null)
          openCommonModal({
            content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
            yesCallback: () => {
              if (bbsDetail.bbsTpCd == 'MENUAL') {
                navigate('/system/admin/sample/menual', {
                  state: { reSearch: true },
                });
              } else {
                navigate('/system/admin/sample/bbs-sample', {
                  state: { reSearch: true },
                });
              }
            },
          });
      },
    });
  };

  // 현재 창 닫고 이동
  const thisPageClose = () => {
    menuContext.closeCurrentTab(menuContext);
  };

  const handleOnCancel = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t(
        'admin.msg.작성을 취소하시겠습니까? 작성된 내용이 사라집니다.',
        '작성을 취소하시겠습니까? 작성된 내용이 사라집니다.'
      ),
      yesCallback() {
        thisPageClose();
        if (state.editType == EditType.UPDATE) {
          navigate('/system/admin/sample/bbs-sample/bbs-post', {
            state: { postBbmNo: state.editBbmNo },
          });
        } else {
          navigate('/system/admin/sample/bbs-sample', {
            state: { reSearch: true },
          });
        }
      },
    });
  };

  const saveValidation = () => {
    if (bbsDetail.bbmTitNm === '') {
      openCommonModal({
        content: t('admin.msg.게시글 제목을 입력해주세요.', '게시글 제목을 입력해주세요.'),
      });
      return false;
    }
    if (bbsDetail.ptupEndDt === null) {
      openCommonModal({
        content: t('admin.msg.게시기한을 입력해 주세요.', '게시기한을 입력해 주세요.'),
      });
      return false;
    }
    return true;
  };

  const handleOnSave = async () => {
    if (!saveValidation()) return;

    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    if (fileSaveResponse.fileSaveResult == FileSaveResult.FAIL) return;
    const atchFileGrId = fileSaveResponse.atchFileGrId;
    const content = editorRef.current?.editorRef.GetBodyValue();
    let response;
    if (state.editType === EditType.CREATE) {
      response = await createBbs({ ...bbsDetail, bbmCtn: content, atchFileGrId: atchFileGrId });
    } else if (state.editType === EditType.UPDATE) {
      response = await modifyBbs({
        ...bbsDetail,
        bbmCtn: content,
        atchFileGrId: atchFileGrId,
        dataUpdUserId: bbsDetail.dataUpdUser.userId,
      });
    }
    if (response != null)
      openCommonModal({
        content: t('com.label.저장되었습니다.', '저장되었습니다.'),
        yesCallback: () => {
          thisPageClose();
          if (state.editType == EditType.UPDATE) {
            navigate('/system/admin/sample/bbs-sample/bbs-post', {
              state: { postBbmNo: state.editBbmNo },
            });
          }
          if (state.editType == EditType.CREATE && state.bbsTpCd == 'MENUAL') {
            navigate('/system/admin/sample/menual', {
              state: { reSearch: true },
            });
          } else {
            navigate('/system/admin/sample/bbs-sample', {
              state: { reSearch: true },
            });
          }
        },
      });
  };

  return (
    <>
      <ContentSection>
        <ViewTable>
          <colgroup>
            <col width="13%" />
            <col />
            <col width="13%" />
            <col />
            <col width="13%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label>{t('admin.label.게시판분류', '게시판분류')}</label>
              </th>
              <td>
                <ComboBox
                  options={bbsDivisionCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={bbsDetail.bbsTpCd}
                  onChange={handleOnChangeBbsTpCd}
                />
                {/* <CustomSelect
                  id="bbsTpCd"
                  className="fullWidth"
                  value={bbsDetail.bbsTpCd}
                  onChange={handleOnChange}
                >
                  {bbsDivisionCode.map((key) => (
                    <option key={key.cmnCd} value={key.cmnCd}>
                      {key.cmnCdNm}
                    </option>
                  ))}
                </CustomSelect> */}
              </td>
              <th scope="row">
                <label>{t('admin.label.게시 법인', '게시 법인')}</label>
              </th>
              <td>
                <ComboBox
                  options={ptupTgtCopCd}
                  disabled={customBbsDetail.disableMenu}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={bbsDetail.ptupTgtCopCd}
                  onChange={handleOnChangePtupTgtCopCd}
                />
                {/* <CustomSelect
                  id="ptupTgtCopCd"
                  className="fullWidth"
                  value={bbsDetail.ptupTgtCopCd}
                  onChange={handleOnChange}
                >
                  {ptupTgtCopCd.map((key) => (
                    <option key={key.cmnCd} value={key.cmnCd}>
                      {key.cmnCdNm}
                    </option>
                  ))}
                </CustomSelect> */}
              </td>
              <th scope="row">
                <label>{t('admin.label.게시기한', '게시기한')}</label>
              </th>
              <td>
                <DatePickerWrap className="single">
                  {/* <DatePicker
                    date={dayjs(bbsDetail.ptupEndDt as string)}
                    changeDate={handleDateChange}
                    mindate={'today'}
                  /> */}
                  <Datepicker
                    value={{
                      startDate: customBbsDetail.dateValue,
                      endDate: customBbsDetail.dateValue,
                    }}
                    onChange={(newValue) => {
                      setBbsDetail({
                        ...bbsDetail,
                        ptupEndDt: dayjs(newValue?.startDate).format('YYYYMMDD'),
                      });
                    }}
                    disabled={customBbsDetail.disableMenu}
                    displayFormat={'YYYY.MM.DD'}
                    minDate={new Date()}
                    i18n={'ko'}
                    inputClassName="datepickerInput"
                    asSingle={true}
                  />
                </DatePickerWrap>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('admin.label.제목', '제목')}</label>
              </th>
              <td colSpan={5}>
                <CustomInputWithSearch
                  placeholder={String(
                    t('admin.msg.제목을 입력해 주세요.', '제목을 입력해 주세요.')
                  )}
                  value={bbsDetail?.bbmTitNm}
                  onChange={handleOnChangeBbmTitNm}
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('admin.label.본문', '본문')}</label>
              </th>
              <td colSpan={5}>
                <div>
                  <CrossEditor
                    ref={editorRef}
                    name="crosseditor1"
                    params={{ Width: '100%', Height: 500, Chevron: true }}
                    value={bbsDetail.bbmCtn}
                    onLoaded={handleEditorOnLoad}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('com.label.첨부', '첨부')}</label>
              </th>
              <td colSpan={5}>
                <Dropzone ref={dropzoneRef} atchFileGrIdInput={bbsDetail.atchFileGrId} />
              </td>
            </tr>
          </tbody>
        </ViewTable>
        <GlobalBtnGroup>
          <Button css={IconButton.button} className="cancel" disableRipple onClick={handleOnCancel}>
            {t('com.button.취소', '취소')}
          </Button>
          <Button css={IconButton.button} className="delete" disableRipple onClick={handleOnDelete}>
            {t('com.label.삭제', '삭제')}
          </Button>
          <Button css={IconButton.button} className="save" disableRipple onClick={handleOnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        </GlobalBtnGroup>
        {/* <GridInfoSection className="contentEnd">
          <GridButtons>
            <GreyLineButton onClick={handleOnCancel}>
              {t('com.button.취소', '취소')}
            </GreyLineButton>
            <GreyLineButton onClick={handleOnDelete}>
              {t('com.button.삭제', '삭제')}
            </GreyLineButton>
            <BlueButton onClick={handleOnSave}>
              <DoneIcon />
              {t('com.button.저장', '저장')}
            </BlueButton>
          </GridButtons>
        </GridInfoSection> */}
      </ContentSection>
    </>
  );
};
