import { css } from '@emotion/react';
import { ButtonDesign, ButtonSize, ButtonStyle } from 'components/buttons/ButtonStyle';
import { CommonBG, CommonBorder, CommonText, TableBG } from 'ui/theme/Color';

export const tb = {
  table: css`
    overflow: visible;
    border: 1px solid ${CommonBorder.Basic};
    table {
      overflow: visible;
    }
    & + .MuiTableContainer-root {
      margin-top: 8px !important;
    }
    table.board {
      td {
        background: ${CommonBG.Deep} !important;
        padding: 12px !important;
        &:first-child {
          padding-top: 18px !important;
        }
      }
    }
    thead {
      tr {
        th {
          padding: 8px !important;
          height: 37px;
          span {
            font-size: 13px !important;
            font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 2px 8px !important;
          font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
          font-size: 13px !important;
          font-weight: 400;
          white-space: nowrap;
          height: 37px;
          border-bottom: 1px solid ${CommonBorder.Basic};
          &:nth-child(odd) {
            padding: 8px !important;
            background: ${TableBG.Header};
            font-weight: 700;
            width: 130px !important;
            vertical-align: top;
            span {
              &.dot {
                position: relative;
                &:before {
                  content: '';
                  display: inline-block;
                  clear: both;
                  position: absolute;
                  top: 0px;
                  left: -6px;
                  width: 4px;
                  height: 4px;
                  border-radius: 2px;
                  background: ${CommonText.Error};
                }
              }
            }
          }
          &:nth-child(even) {
            background: ${CommonBG.Basic};
          }
          &.row {
            border-right: 1px solid ${CommonBorder.Basic};
          }
          &.odd {
            background: ${TableBG.Header};
            font-weight: 700;
            padding: 8px;
          }
          &.width100 {
            width: 100px !important;
          }
          &.oddnone {
            background: ${TableBG.Header};
            font-weight: 700;
            border-right: 1px solid ${CommonBorder.Basic};
            border-bottom: none;
            vertical-align: top;
            padding: 8px !important;
          }
          &.noBg {
            background: ${CommonBG.Basic};
            padding: 2px 8px !important;
            font-weight: 400;
            vertical-align: middle !important;
          }
          &.col3 {
            width: calc((100% - 650px) / 3) !important;
          }
          &.col31 {
            width: calc((100% - 390px) / 3) !important;
          }
          &.error {
            color: ${CommonText.Error};
            font-weight: 700;
          }
          &.w500 {
            width: 500px !important;
          }
          &.disabled {
            opacity: 0.2;
          }
          &.duplication {
            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;
              & > div {
                width: 88%;
              }
              button {
                ${ButtonDesign.labelMD}
                ${ButtonSize.md}
                ${ButtonStyle.containedNormal}
              }
            }
          }
          .flex {
            display: flex;
            align-items: center !important;
          }
          .single {
            width: 100%;
          }
        }
        &:last-child {
          td {
            border-bottom: none !important;
          }
        }
      }
    }
    &.check {
      td {
        font-weight: 400 !important;
        background: none !important;
        width: calc(100% / 3) !important;
        & + td {
          border-left: 1px solid ${CommonBorder.Basic};
        }
      }
    }
    &.abbr {
      height: 310px;
      margin-top: 8px;
      table {
        height: 100%;
      }
      td {
        background: none !important;
        font-weight: 400 !important;
        ul {
          margin: 12px;
        }
      }
    }
    &.colFix3 {
      table {
        table-layout: fixed;
      }
      td:nth-child(even) {
        width: calc((100% - 390px) / 3) !important;
        max-width: calc((100% - 390px) / 3) !important;
        word-break: keep-all;
        white-space: break-spaces;
      }
    }
    &.colFix4 {
      table {
        table-layout: fixed;
      }
      td {
        vertical-align: top;
      }
      td:nth-child(odd) {
        width: 140px !important;
      }
      td:nth-child(even) {
        width: calc((100% - 560px) / 4) !important;
        max-width: calc((100% - 560px) / 4) !important;
        word-break: keep-all;
        white-space: break-spaces;
      }
    }
    &.colFix9 {
      table {
        table-layout: fixed;
      }
      td:nth-child(even) {
        width: calc((100% - 560px) / 9) !important;
        max-width: calc((100% - 560px) / 9) !important;
        word-break: keep-all;
        white-space: break-spaces;
      }
    }
  `,

  tableRow: css`
  table-layout:fixed;
    border:1px solid ${CommonBorder.Basic};
  overflow:visible;
  thead {
    tr {
      th {
        padding:14px 0 0 8px !important;
        span {
          font-size:13px !important;
          font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
        }        
      }
    }
  }
  tbody {
    tr {
      td {
        padding:2px 0px 2px 12px !important;
        font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
        font-size:13px !important;
        font-weight:400;
        white-space:nowrap;
        height:37px;
          border-bottom:1px solid ${CommonBorder.Basic};
        &.firstCol {
          padding:8px 0px 0px 12px !important;
            background:${TableBG.Header};
          font-weight:700;
          width:130px !important;
          vertical-align:top;
            border-right:1px solid ${CommonBorder.Basic};
        }
        &.secondCol {
          padding:8px 0px 0px 12px !important;
            background:${TableBG.Header};
          font-weight:700;
          width:130px !important;
          vertical-align:top;
        }
      }
      &:last-child {
        td {          
          border-bottom:none !important;
        }
      }
    }
  },
`,
  tableCol: css`
  overflow:visible;
  border:1px solid ${CommonBorder.Basic};  
  table {
    table-layout:fixed;
    overflow:visible;
  }
  &.center {
    td {
      text-align: center;
    }
  }
    thead {
      tr {
        th {
          padding:2px 8px !important;
          font-size:13px !important;
          height:37px;
          background:${TableBG.Header};
          font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
          font-weight:700;
          text-align:center;
        border-right:1px solid ${CommonBorder.Basic};
          &:last-child {
            border-right:none;
          }
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          td {
            border-bottom:none;
          }
        }
        td {
          padding:2px 8px !important;
          font-size:13px !important;
          font-weight:400;
          white-space:nowrap;
          font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
          height:37px;
          text-align:center;
          word-break:break-word;
          white-space: wrap;
          border-bottom:1px solid ${CommonBorder.Basic};
          border-right:1px solid ${CommonBorder.Basic};
          &:last-child {
            border-right:none;
          }
        }
      }
    },
  `,
};
