/** @jsxImportSource @emotion/react */
import React, {
  useCallback,
  MouseEvent,
  useMemo,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMessageManagementStore } from 'stores/useMessageManagementStore';
import { MessageCondition, ShowingMessage } from 'models/admin/Message';
import { CrudCode } from 'models/common/Edit';
import { useCommonModal } from 'hooks/useCommonModal';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { CustomInputText, CustomInputWrap, UseToggle } from 'components/inputs/CustomInput';
import _, { isEmpty } from 'lodash';
import { deployTranslatedMessages } from 'apis/admin/TranslatedMessage';
import useLanguageStore from 'stores/useLanguageStore';
import { reloadMessages } from 'apis/admin/Message';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from '../../components/layouts/ContentLayout';
import { IconButton } from '../../components/buttons/IconSVG';
import { CommonYN } from 'models/common/Common';
import useSessionStore from 'stores/useSessionStore';
import { getCommonCodeNames, setCommonCodeGroups } from 'apis/admin/CommonCode';
import { Code, commonYNList } from 'models/common/CommonCode';
import {
  GridStatusCellRenderer,
  GridStatusCellTemplate,
} from 'components/grids/GridStatusCellRenderer';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';
import CustomGrid from 'components/grids/CustomGrid';
import useEvent from 'react-use-event-hook';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { DataType, toggleClass } from '@grapecity/wijmo';
import { useMessageBar } from 'hooks/useMessageBar';
import { useMessageModalStore } from 'stores/useMessageModalStore';
import { flexGridShortDate } from 'models/ip/Public';
import { ColDef } from 'ag-grid-community';

export const MessageManagementPage = () => {
  const { t } = useTranslation();
  const {
    languageCodes,
    useYnCodes,
    showingMessages,
    findMessages,
    initMessageManagementPage,
    resetMessageManagementPage,
    setMessageManagementGridApi,
    findMessagesWithLastSearchedCondition,
    addMessage,
    removeMessages,
    saveMessages,
    messageManagementGridApi,
  } = useMessageManagementStore();
  const { refreshLanguage } = useLanguageStore();
  const { gridNoRowsTemplate } = useSessionStore();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const [msgCtn, setMsgCtn] = useState<string>('');
  const [msgTxtCtn, setMsgTxtCtn] = useState<string>('');
  // const [selectedUseYn, setSelectedUseYn] = useState<string>('');
  const [textboxUseYn, setTextboxUseYn] = useState<any>('Y');
  const [useYn, setUseYn] = useState<Code[]>([]);
  const gridRef = useRef<any>(null);
  const [flexRef, setflexRef] = useState<any>();
  const { setMessageModalStateWhenModalOpen } = useMessageModalStore();

  useEffect(() => {
    getCommonCodeNames('YN_FLAG').then((result: Code[]) => {
      setUseYn(result);
    });
  }, []);

  const btnReload = () => {
    setMsgCtn('');
    setMsgTxtCtn('');
    setTextboxUseYn('Y');
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const layoutDefinition = () => {
    const frontLayOut = [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 45,
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'msgCtn',
        header: String(t('admin.grid.메시지코드', '메시지코드')),
        align: 'left',
        width: 300,
        cssClass: 'WijmoPopup',
        cellTemplate: (params) =>
          params.item['crudKey'] === CrudCode.CREATE
            ? params.value
            : `<span>${params.value}</span><Button id="btnCode" />`,
      },
    ];

    const languageLayout = languageCodes.map((languageCode, index) => {
      return {
        header: languageCode.cmnCd,
        binding: `msgTxtCtn${index + 1}`,
        align: 'left',
        width: 160,
      };
    });

    const backLayOut = [
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        width: 100,
        dataMap: new DataMap(useYn, 'cmnCd', 'cmnCdNm'),
      },
      {
        binding: 'dataInsUserId',
        header: String(t('admin.grid.작성자', '작성자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.작성일시', '작성일시')),
        align: 'center',
        width: 140,
        isReadOnly: true,
        cellTemplate: (ctx) => flexGridShortDate(ctx),
      },
      {
        binding: 'dataUpdUserId',
        header: String(t('com.label.수정자', '수정자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('com.label.수정일', '수정일')),
        align: 'center',
        width: 140,
        isReadOnly: true,
        cellTemplate: (ctx) => flexGridShortDate(ctx),
      },
    ];

    return [...frontLayOut, ...languageLayout, ...backLayOut];
  };

  const onInitialized = (grid) => {
    setflexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          // 공통코드
          case 'btnCode':
            setMessageModalStateWhenModalOpen(item.msgCtn, item.cmnGrCdNm, (cmnGrCdNm?: string) => {
              openCommonModal({
                modalType: 'confirm',
                content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
                yesCallback: () => {
                  findMessagesWithLastSearchedCondition();
                  openMessageBar({
                    type: 'confirm',
                    content: t('com.label.저장되었습니다.', '저장되었습니다.'),
                  });
                },
              });
            });
        }
      }
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      const isReadOnly = item.crudKey !== CrudCode.CREATE;
      if ('msgCtn' === binding) {
        cell.ariaReadOnly = isReadOnly;
      }
    }
  });

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'message';
    book.saveAsync(getExcelFileName(t('admin.label.메세지목록', '메세지목록')));
  };

  const handleMsgCtnInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMsgCtn(e.target.value);
  }, []);

  const handleMsgTxtCtnInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMsgTxtCtn(e.target.value);
  }, []);

  const handleSubmit = () => {
    findMessages({
      msgCtn: msgCtn,
      msgTxtCtn: msgTxtCtn,
      useYn: textboxUseYn,
    } as MessageCondition);
  };

  const handleAddButtonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    addMessage();
  }, []);

  const btnDelete = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }

    setMessageManagementGridApi(flexRef);

    openCommonModal({
      modalType: 'confirm',
      content: t(
        'com.label.삭제하시겠습니까? 기존 작업 내용이 사라집니다.',
        '삭제하시겠습니까? 기존 작업 내용이 사라집니다.'
      ),
      yesCallback: async () => {
        const result = await removeMessages();
        if (result == 'OK') {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
          });
        } else {
          openMessageBar({
            type: 'error',
            content: t('com.label.삭제 중 오류가 발생했습니다.', '삭제 중 오류가 발생했습니다.'),
          });
        }
      },
    });
  };

  const btnSave = () => {
    if (showingMessages != undefined) {
      const vaildCandU = showingMessages.filter(
        (element) => element.crudKey == CrudCode.CREATE || element.crudKey == CrudCode.UPDATE
      );
      const valid = vaildCandU
        .map((rowNode, index) => {
          if (_.isEmpty(rowNode.msgCtn))
            return t('admin.msg.메세지코드를 입력해 주세요.', `메세지코드를 입력해 주세요.`);
          if (
            !_.isNull(flexRef.current?.columnApi.getColumn('msgTxtCtn1')) &&
            _.isEmpty(rowNode.msgTxtCtn1)
          )
            return t('admin.msg.ko를 입력해 주세요.', `ko를 입력해 주세요.`);
          if (
            !_.isNull(flexRef.current?.columnApi.getColumn('msgTxtCtn2')) &&
            _.isEmpty(rowNode.msgTxtCtn2)
          )
            return t('admin.msg.en을 입력해 주세요.', `en을 입력해 주세요.`);
          if (
            !_.isNull(flexRef.current?.columnApi.getColumn('msgTxtCtn3')) &&
            _.isEmpty(rowNode.msgTxtCtn3)
          )
            return t('admin.msg.zh를 입력해 주세요.', `zh를 입력해 주세요.`);
          if (
            !_.isNull(flexRef.current?.columnApi.getColumn('msgTxtCtn4')) &&
            _.isEmpty(rowNode.msgTxtCtn4)
          )
            return t('admin.msg.id를 입력해 주세요.', `id를 입력해 주세요.`);
          if (
            !_.isNull(gridRef.current?.columnApi.getColumn('msgTxtCtn5')) &&
            _.isEmpty(rowNode.msgTxtCtn5)
          )
            return t('admin.msg.pl을 입력해 주세요.', `pl을 입력해 주세요.`);
        })
        .filter((element) => element !== undefined);
      if (valid.length) {
        openMessageBar({
          type: 'error',
          content: valid.toString(),
        });
        return false;
      }

      const noneFixedRow = showingMessages.filter(
        (element) => element.crudKey !== null && element.crudKey !== 'R'
      );
      if (noneFixedRow.length == 0) {
        openMessageBar({
          type: 'error',
          content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
        });
        return true;
      }
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        const result = await saveMessages();
        if (result == 'OK') {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          await reloadMessages();
          if (await deployTranslatedMessages()) {
            refreshLanguage();
          }
        } else {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
        }
      },
    });
  };

  useEffect(() => {
    initMessageManagementPage();

    return () => {
      resetMessageManagementPage();
    };
  }, []);

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('admin.label.메시지코드', '메시지코드')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    id="msgCtn"
                    name="msgCtn"
                    type="text"
                    value={msgCtn}
                    onKeyDown={onKeyDown}
                    onChange={handleMsgCtnInputChange}
                    placeholder={String(
                      t('admin.msg.메시지코드를 입력해 주세요.', '메시지코드를 입력해 주세요.')
                    )}
                  />
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('admin.label.메시지', '메시지')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    id="msgTxtCtn"
                    name="msgTxtCtn"
                    type="text"
                    value={msgTxtCtn}
                    onKeyDown={onKeyDown}
                    onChange={handleMsgTxtCtnInputChange}
                    placeholder={String(
                      t('admin.msg.메시지를 입력해 주세요.', '메시지를 입력해 주세요.')
                    )}
                  />
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.사용여부', '사용여부')}</label>
                <UseToggle className="switch">
                  <input
                    type="checkbox"
                    name="useYn"
                    value={textboxUseYn}
                    checked={textboxUseYn === 'Y'}
                    onChange={() => {
                      setTextboxUseYn(textboxUseYn === 'Y' ? 'N' : 'Y');
                    }}
                  />
                  <span className="slider"></span>
                  <span
                    className="labels"
                    data-on={t('com.label.사용', '사용')}
                    data-off={t('com.label.미사용', '미사용')}
                  ></span>
                </UseToggle>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button css={IconButton.button} className="reload" disableRipple onClick={btnReload} />
            <Button
              css={IconButton.button}
              className="find"
              id="search"
              disableRipple
              onClick={handleSubmit}
            >
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.메시지목록', '메시지목록')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{showingMessages?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="addRow"
            disableRipple
            onClick={handleAddButtonClick}
          >
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button css={IconButton.button} className="delRow" disableRipple onClick={btnDelete}>
            {t('com.button.행삭제', '행삭제')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition()}
        rowData={showingMessages}
        height={300}
        deferResizing={false}
        initialized={onInitialized}
        itemFormatter={onItemFormatter}
        align={'center'}
        beginningEdit={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const item = grid.rows[e.row].dataItem;
          // readonly 설정
          // 공통코드, 코드명
          if (['msgCtn'].includes(binding)) {
            if (CrudCode.CREATE !== item.crudKey) {
              e.cancel = true;
            }
          }
        }}
      />
      <GlobalBtnGroup>
        <Button css={IconButton.button} className="confirm" disableRipple onClick={btnSave}>
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>
    </>
  );
};
