/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import MenuSidebar from 'pages/admin/menumanagement/MenuSidebar';
import { MenuEnum, MenuRequest, MenuVO } from 'models/admin/Menu';
import { createMenu, getAllMenus, getMenusByRole, saveMenusByRole } from 'apis/admin/MenuApi';
import useMenuManagementStore from 'stores/useMenuManagementStore';
import RoleManagementGrid from 'pages/admin/rolemanagement/RoleManagementGrid';
import DepartmentManageTable from 'pages/admin/departmentmanagement/DepartmentManageTable';
import RoleEmpManagementGrid from 'pages/admin/rolemanagement/RoleEmpManagementGrid';
import { AgGridReact } from 'ag-grid-react';
import styled from '@emotion/styled';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

import { ViewTable } from 'components/tables/ViewTable';
import { BlueButton, GreyButton, GreyLineButton } from 'components/buttons/CustomButton';
import { CustomInputText, CustomInputWrap, st } from 'components/inputs/CustomInput';
import { BgColor, BorderColor, FontColor } from 'ui/theme/Color';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import { CrudCode } from 'models/common/Edit';
import MenumanagementTable from './menumanagement/MenuManagementTable';
import { useMessageModalStore } from 'stores/useMessageModalStore';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { commonYNarr } from 'models/common/Common';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtons,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { ContentFlex } from 'components/layouts/ContentFlex';
import {
  ContentSection,
  GridButtons,
  GridInfo,
  GridInfoSection,
} from 'components/layouts/ContentSection';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { di } from 'components/layouts/Dialog';
import { CustomSelect } from 'components/selects/CustomSelect';
import { IconButton } from '../../components/buttons/IconSVG';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { ContentBox } from 'components/layouts/Board';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { ComboBox } from '../../components/selects/ComboBox';
import CustomGrid from 'components/grids/CustomGrid';
import { getExcelFileName } from 'utils/ExcelUtil';
import { useMessageBar } from 'hooks/useMessageBar';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';

const MenuManagementPage = () => {
  useEffect(() => {
    getMenus();
    getLangCds();
    document.getElementsByClassName('width40p')[0].classList.remove('css-hc2cu');
    document.getElementsByClassName('width35p')[0].classList.remove('css-hc2cu');
    //document.getElementsByClassName('css-1ngnywd')[0].setAttribute('style','width: 200px; !important');
  }, []);

  const {
    setModalOpen,
    modalOpen,
    menuId,
    setMenuId,
    menuName,
    setMenuName,
    targetMenuId,
    setTargetMenuId,
    menuLocation,
    setMenuLocation,
    menuUseYn,
    setMenuUseYn,
    menuExposureYn,
    setMenuExposureYn,
    menuOptionValue1,
    setMenuOptionValue1,
    menuOptionValue2,
    setMenuOptionValue2,
    menuOptionValue3,
    setMenuOptionValue3,
    menuOptionValue4,
    setMenuOptionValue4,
    menuOptionValue5,
    setMenuOptionValue5,
    messageCode,
    setMessageCode,
    menuUrl,
    setMenuUrl,
    menuDesc,
    setMenuDesc,
    menuInfoId,
    menuInfoUrl,
    menuInfoDesc,
    rolesByMenu,
    employeesByMenu,
    departmentByMenu,
    allMenuList,
    setAllMenuList,
    allMenuListExceptClickedMenu,
    menuListWithCheckbox,
    setMenuListWithCheckbox,
    menuListbyRole,
    setMenuListbyRole,
  } = useMenuManagementStore((state) => state);

  const { setMessageModalStateWhenModalOpen } = useMessageModalStore();

  const [menuMgtModalOpen, setMenuMgtModalOpen] = useState<boolean>(false);
  const [colDefs, setColDefs] = useState<any>(MenumanagementTable());
  const [roleSearchParamData, setRoleSearchParamData] = useState<string>('');
  const [departmentSearchParamData, setDepartmentSearchParamData] = useState<string>('');
  const [roleNameSearchParamData, setRoleNameSearchParamData] = useState<string>('');
  const [langCds, setLangCds] = useState<Code[]>([]);
  const [language, setLanguage] = useState<string>('ko');
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();
  const [roleFlexRef, setRoleFlexRef] = useState<any>();
  const [empFlexRef, setEmpFlexRef] = useState<any>();
  const [deptFlexRef, setDeptFlexRef] = useState<any>();

  const getLangCds = async () => {
    const res = await getCommonCodeNames('LANG_CD');
    setLangCds(res ?? []);
  };

  const handleClose = () => {
    setMenuMgtModalOpen(false);
  };

  const saveMenu = async () => {
    if (!checkMandatoryParam()) return;
    const newMenu: MenuRequest = {
      mnuId: menuId,
      mnuNm: menuName,
      targetMenuId: targetMenuId,
      menuLocation: menuLocation,
      useYn: menuUseYn,
      mnuEpsYn: menuExposureYn,
      mnuOptValCtn1: menuOptionValue1,
      mnuOptValCtn2: menuOptionValue2,
      mnuOptValCtn3: menuOptionValue3,
      mnuOptValCtn4: menuOptionValue4,
      mnuOptValCtn5: menuOptionValue5,
      msgCtn: messageCode,
      mnuUrl: menuUrl,
      mnuDesc: menuDesc,
      crudKey: menuId ? CrudCode.UPDATE : CrudCode.CREATE,
    };
    await createMenu(newMenu);

    closeModal();
  };

  const checkMandatoryParam = () => {
    if (menuName === '') {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.메뉴 이름은 필수값입니다.', '메뉴 이름은 필수값입니다.'),
      });
      return false;
    }

    return true;
  };

  const closeModal = () => {
    getMenus();
    setMenuId('');
    setMenuName('');
    setTargetMenuId(MenuEnum.root);
    setMenuLocation('3');
    setMenuUseYn('Y');
    setMenuExposureYn('Y');
    setMenuOptionValue1('');
    setMenuOptionValue2('');
    setMenuOptionValue3('');
    setMenuOptionValue4('');
    setMenuOptionValue5('');
    setMessageCode('');
    setMenuUrl('');
    setMenuDesc('');
    setModalOpen(false);
    setMenuListbyRole([]);
  };

  async function getMenus() {
    const allMenuList = await getAllMenus();
    const allMenuListWithOptionName = allMenuList?.map((element: MenuVO) => {
      return { ...element, optionName: element.mnuNm, optionValue: element.mnuId };
    });
    setAllMenuList(allMenuListWithOptionName);
  }

  async function getRoleMenus(roleCd: string) {
    const menuListbyRole = await getMenusByRole(roleCd);

    setMenuListWithCheckbox(menuListbyRole.map((item) => item.mnuId));
    setMenuListbyRole(menuListbyRole);
    addChecked(menuListbyRole);
  }

  const addChecked = async (menuListbyRole: MenuVO[]) => {
    const allMenuLists = await getAllMenus();

    const alteredRoleMenuList = allMenuLists?.map((element: MenuVO) => {
      const exists = menuListbyRole?.find((el) => el.mnuId === element.mnuId);
      if (exists) {
        return { ...element, isChecked: true };
      } else {
        return { ...element, isChecked: false };
      }
    });
    setMenuListbyRole(alteredRoleMenuList);
  };

  const btnSearch = () => {
    setRoleSearchParamData(roleNameSearchParamData);
    setDepartmentSearchParamData('');
  };

  const handleSearchParamData = (e: any) => {
    setRoleNameSearchParamData(e.target.value);
  };

  const onCallbackRoleClick = useCallback((roleCd: string) => {
    setDepartmentSearchParamData(roleCd);
    getRoleMenus(roleCd);
  }, []);

  const onClickRoleMenuSave = async () => {
    const res = await saveMenusByRole(departmentSearchParamData, menuListWithCheckbox);
    if (res) {
      openMessageBar({
        type: 'confirm',
        content: t('com.label.저장되었습니다.', '저장되었습니다.'),
      });
    }
  };

  const handleMultiLanguageButton = () => {
    if (messageCode) setMessageModalStateWhenModalOpen(messageCode);
  };

  const btnExcelExport = (flexRef) => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'menu';
    book.saveAsync(getExcelFileName(t('admin.label.역할별메뉴관리', '역할별메뉴관리')));
  };

  const roleOnInitialized = (grid) => {
    setRoleFlexRef(grid);
  };

  const empOnInitialized = (grid) => {
    setEmpFlexRef(grid);
  };

  const deptOnInitialized = (grid) => {
    setDeptFlexRef(grid);
  };

  return (
    <>
      <ContentFlex>
        <ContentSection className="section width40p">
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('admin.label.역할조회', '역할조회')}</h3>
            </SubTitleGroup>
          </SubTitleLayout>
          <SearchBox>
            <SearchBoxRow>
              <InputBox>
                <SearchRows>
                  <SearchCols>
                    <label>{t('admin.label.역할명', '역할명')}</label>
                  </SearchCols>
                  <SearchCols>
                    <CustomInputWithSearch
                      onChange={handleSearchParamData}
                      placeholder={String(
                        t('admin.label.역할명을 입력해 주세요.', '역할명을 입력해 주세요.')
                      )}
                    />
                  </SearchCols>
                </SearchRows>
              </InputBox>
              <SearchButtonWrap>
                <Button css={IconButton.button} className="reload" disableRipple />
                <Button css={IconButton.button} className="find" disableRipple onClick={btnSearch}>
                  {t('com.button.조회', '조회')}
                </Button>
              </SearchButtonWrap>
            </SearchBoxRow>
          </SearchBox>
          <RoleManagementGrid
            sRoleNm={roleSearchParamData}
            editable={false}
            callBackRoleCd={onCallbackRoleClick}
            height={250}
          />
          <RoleEmpManagementGrid
            sRoleCd={departmentSearchParamData}
            editable={false}
            height={250}
          />
          <DepartmentManageTable roleCd={departmentSearchParamData} editable={false} height={300} />
        </ContentSection>
        <ContentSection className="section width60p">
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('admin.label.메뉴정보', '메뉴정보')}</h3>
            </SubTitleGroup>
          </SubTitleLayout>
          <SearchBox>
            <SearchBoxRow>
              <InputBox>
                <SearchRows>
                  <SearchCols>
                    <label>{t('admin.label.메뉴ID', '메뉴ID')}</label>
                    <CustomInputText readOnly={true} value={menuInfoId}></CustomInputText>
                  </SearchCols>
                  <SearchCols>
                    <label>{t('admin.label.메뉴설명', '메뉴설명')}</label>
                    <CustomInputText
                      className="fullWidth"
                      readOnly={true}
                      value={menuInfoDesc}
                    ></CustomInputText>
                  </SearchCols>
                  <SearchCols>
                    <label>{t('admin.label.URL', 'URL')}</label>
                    <CustomInputText
                      className="fullWidth"
                      readOnly={true}
                      value={menuInfoUrl}
                    ></CustomInputText>
                  </SearchCols>
                </SearchRows>
              </InputBox>
            </SearchBoxRow>
          </SearchBox>
          <ContentFlex>
            <ContentSection className="section width35p">
              <SubTitleLayout>
                <SubTitleGroup>
                  <h3>{t('admin.label.메뉴', '메뉴')}</h3>
                </SubTitleGroup>
                <ControlBtnGroup>
                  <Button
                    css={IconButton.button}
                    className="save"
                    disableRipple
                    onClick={() => onClickRoleMenuSave()}
                  >
                    {t('com.button.저장', '저장')}
                  </Button>
                  <Button
                    css={IconButton.button}
                    className="copy"
                    disableRipple
                    onClick={() => setMenuMgtModalOpen(true)}
                  >
                    {t('com.button.메뉴관리', '메뉴관리')}
                  </Button>
                </ControlBtnGroup>
              </SubTitleLayout>

              <CheckTreeBox>
                <CheckTree>
                  {menuListbyRole?.length > 0 ? (
                    <MenuSidebar items={menuListbyRole} hasCheckbox={true} />
                  ) : (
                    allMenuList && <MenuSidebar items={allMenuList} hasCheckbox={true} />
                  )}
                </CheckTree>
              </CheckTreeBox>
            </ContentSection>

            <ContentSection className="section width65p">
              <SubTitleLayout>
                <SubTitleGroup>
                  <h3>{t('admin.label.이용가능 역할', '이용가능 역할')}</h3>
                </SubTitleGroup>
                <ControlBtnGroup>
                  <Button
                    css={IconButton.button}
                    className="Exceldown"
                    onClick={() => btnExcelExport(roleFlexRef)}
                    disableRipple
                  >
                    {t('com.button.다운로드', '다운로드')}
                  </Button>
                </ControlBtnGroup>
              </SubTitleLayout>
              <CustomGrid
                layoutDefinition={colDefs.roleByMenuCol}
                rowData={rolesByMenu}
                height={250}
                align={'center'}
                isSelector={false}
                initialized={roleOnInitialized}
              />

              <SubTitleLayout>
                <SubTitleGroup>
                  <h3>{t('admin.label.이용가능 사용자', '이용가능 사용자')}</h3>
                </SubTitleGroup>
                <ControlBtnGroup>
                  <Button
                    css={IconButton.button}
                    className="Exceldown"
                    onClick={() => btnExcelExport(empFlexRef)}
                    disableRipple
                  >
                    {t('com.button.다운로드', '다운로드')}
                  </Button>
                </ControlBtnGroup>
              </SubTitleLayout>
              <CustomGrid
                layoutDefinition={colDefs.employeeByMenuCol}
                rowData={employeesByMenu}
                height={250}
                align={'center'}
                isSelector={false}
                initialized={empOnInitialized}
              />
              <SubTitleLayout>
                <SubTitleGroup>
                  <h3>{t('admin.label.이용가능 부서', '이용가능 부서')}</h3>
                </SubTitleGroup>
                <ControlBtnGroup>
                  <Button
                    css={IconButton.button}
                    className="Exceldown"
                    onClick={() => btnExcelExport(deptFlexRef)}
                    disableRipple
                  >
                    {t('com.button.다운로드', '다운로드')}
                  </Button>
                </ControlBtnGroup>
              </SubTitleLayout>

              <CustomGrid
                layoutDefinition={colDefs.departmentByMenuCol}
                rowData={departmentByMenu}
                height={300}
                align={'center'}
                isSelector={false}
                initialized={deptOnInitialized}
              />
            </ContentSection>
          </ContentFlex>
        </ContentSection>
      </ContentFlex>

      <Dialog open={menuMgtModalOpen} onClose={handleClose} css={di.dialog} maxWidth="sm" fullWidth>
        <DialogTitle className="popupTitle">
          {t('admin.label.메뉴관리', '메뉴관리')}
          <Button className="buttonClose" onClick={handleClose} />
        </DialogTitle>
        <DialogContent className="popupContent" style={{ width: '500px' }}>
          <SearchBox>
            <SearchBoxRow>
              <InputBox>
                <SearchRows>
                  <SearchCols>
                    <GridButtons>
                      <GreyLineButton onClick={() => setModalOpen(true)}>
                        {t('admin.button.메뉴추가', '메뉴추가')}
                      </GreyLineButton>
                    </GridButtons>
                  </SearchCols>
                  <SearchCols>
                    <label>{t('admin.label.언어', '언어')}</label>
                  </SearchCols>
                  <SearchCols>
                    <ComboBox
                      options={langCds}
                      disabled={false}
                      defaultValue={'ko'}
                      onChange={(value) => {
                        setLanguage(value);
                      }}
                    />
                  </SearchCols>
                </SearchRows>
              </InputBox>
            </SearchBoxRow>
          </SearchBox>
          <ContentSection className="marginT0">
            <TreeBox>
              <Tree>
                {<MenuSidebar items={allMenuList} langCd={language} hasCheckbox={false} />}
              </Tree>
            </TreeBox>
          </ContentSection>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GreyLineButton onClick={handleClose}>{t('com.button.취소', '취소')}</GreyLineButton>
          <BlueButton onClick={handleClose}>
            <DoneIcon fontSize="small"></DoneIcon>
            {t('com.button.확인', '확인')}
          </BlueButton>
        </DialogActions>
      </Dialog>

      <Dialog open={modalOpen} onClose={closeModal} css={di.dialog}>
        <DialogTitle className="popupTitle">
          {t('com.label.메뉴 추가/수정', '메뉴 추가/수정')}
          <Button
            css={IconButton.button}
            className="buttonClose"
            disableRipple
            onClick={closeModal}
          />
        </DialogTitle>
        <DialogContent className="popupContent">
          <ContentSection className="marginT0">
            <ViewTable className="width300">
              <colgroup>
                <col width="30%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.메뉴명', '메뉴명')}</label>
                    <GreyLineButton className="small" onClick={handleMultiLanguageButton}>
                      {t('com.button.다국어설정', '다국어설정')}
                    </GreyLineButton>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuName(e.target.value)}
                      value={menuName}
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label> {t('admin.label.변경기준메뉴', '변경기준메뉴')}</label>
                  </th>
                  <td>
                    <CustomSelect
                      onChange={(e) => setTargetMenuId(e.target.value)}
                      defaultValue={menuId === '' ? MenuEnum.root : targetMenuId}
                    >
                      {menuId !== ''
                        ? allMenuListExceptClickedMenu.map((menu) => (
                            <option key={menu.mnuId} value={menu.mnuId}>
                              {menu.mnuNm}
                            </option>
                          ))
                        : allMenuList.map((menu) => (
                            <option key={menu.mnuId} value={menu.mnuId}>
                              {menu.mnuNm}
                            </option>
                          ))}
                    </CustomSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label> {t('admin.label.메뉴위치', '메뉴위치')}</label>
                  </th>
                  <td>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={menuLocation}
                        onChange={(e) => {
                          setMenuLocation(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="1"
                          control={
                            <Radio
                              disabled={targetMenuId === MenuEnum.root ? true : false}
                              css={st.radio}
                              size="small"
                            />
                          }
                          label={t('admin.label.위', '위')}
                          css={st.label}
                        />
                        <FormControlLabel
                          disabled={targetMenuId === MenuEnum.root ? true : false}
                          value="2"
                          control={<Radio css={st.radio} size="small" />}
                          label={t('admin.label.아래', '아래')}
                          css={st.label}
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio css={st.radio} size="small" />}
                          label={t('admin.label.하위', '하위')}
                          css={st.label}
                        />
                        <FormControlLabel
                          disabled={menuId === '' ? true : false}
                          value="4"
                          control={<Radio css={st.radio} size="small" />}
                          label={t('admin.label.변경없음', '변경없음')}
                          css={st.label}
                        />
                      </RadioGroup>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('com.label.사용여부', '사용여부')}</label>
                  </th>
                  <td>
                    <CustomSelect
                      onChange={(e) => setMenuUseYn(e.target.value)}
                      defaultValue={menuUseYn}
                    >
                      {commonYNarr.map((code) => (
                        <option key={code.optionValue} value={code.optionValue}>
                          {code.optionName}
                        </option>
                      ))}
                    </CustomSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.메뉴노출여부', '메뉴노출여부')}</label>
                  </th>
                  <td>
                    <CustomSelect
                      onChange={(e) => setMenuExposureYn(e.target.value)}
                      defaultValue={menuExposureYn}
                    >
                      {commonYNarr.map((code) => (
                        <option key={code.optionValue} value={code.optionValue}>
                          {code.optionName}
                        </option>
                      ))}
                    </CustomSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.URL', 'URL')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuUrl(e.target.value)}
                      value={menuUrl}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.메뉴설명', '메뉴설명')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuDesc(e.target.value)}
                      value={menuDesc}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.메시지코드', '메시지코드')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMessageCode(e.target.value)}
                      value={messageCode}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.메뉴옵션값1', '메뉴옵션값1')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue1(e.target.value)}
                      value={menuOptionValue1}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.메뉴옵션값2', '메뉴옵션값2')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue2(e.target.value)}
                      value={menuOptionValue2}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.메뉴옵션값3', '메뉴옵션값3')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue3(e.target.value)}
                      value={menuOptionValue3}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.메뉴옵션값4', '메뉴옵션값4')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue4(e.target.value)}
                      value={menuOptionValue4}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="col">
                    <label>{t('admin.label.메뉴옵션값5', '메뉴옵션값5')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      onChange={(e) => setMenuOptionValue5(e.target.value)}
                      value={menuOptionValue5}
                      className="fullWidth"
                    ></CustomInputText>
                  </td>
                </tr>
              </tbody>
            </ViewTable>
          </ContentSection>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GreyLineButton onClick={closeModal}>{t('com.button.닫기', '닫기')}</GreyLineButton>
          <BlueButton onClick={saveMenu}>
            <DoneIcon fontSize="small"></DoneIcon>
            {t('com.button.저장', '저장')}
          </BlueButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuManagementPage;

const CheckTreeBox = styled.div`
  min-height: 400px;
  height: 900px;
  padding: 10px 0;
  border: 1px solid ${BorderColor.Primary};
`;

const CheckTree = styled.div`
  margin-top: 5px;

  label {
    display: inline-block;
    position: relative;
    padding: 6px 0;
    cursor: pointer;
    color: ${FontColor.Gray400};
    width: 100%;
    height: 36px;
    font-size: 13px;

    &.root {
      color: ${FontColor.Default};
    }
    &.root:before {
      background-image: url(/assets/icon/ic-tree-close.png);
    }

    &:hover,
    &:focus,
    &.on {
      background-color: ${BgColor.Secondary50};

      :after {
        content: '';
        display: inline-block;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${BgColor.Secondary100};
      }
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: -6px;
      background: url(/assets/icon/ic-tree-close.png) no-repeat center;
    }

    &.lastTree:before {
      background-image: none;
    }
  }

  li {
    position: relative;
    list-style: none;
    font-size: 13px;

    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 10px;
    }

    &.lastList {
      :before {
        display: none;
      }
    }

    &.hasCheckbox > label:before {
      margin-right: 28px;
    }

    .MuiCheckbox-root {
      position: absolute;
      left: 26px;
      top: 8px;
      z-index: 2;
      input {
        display: block;
      }
      .Mui-checked {
        color: #3ec2cf;
      }
    }
  }

  ul {
    display: none;
  }

  *:before {
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  input[type='checkbox'] {
    display: none;
    &:checked ~ ul {
      display: block;
    }
    &:checked ~ ul > li {
      label {
        padding-left: 25px;
      }

      .MuiCheckbox-root {
        left: 50px;
      }
    }

    &:checked ~ ul > li ul > li {
      label {
        padding-left: 50px;
      }

      .MuiCheckbox-root {
        left: 75px;
      }
    }

    &:checked ~ ul > li ul > li ul > li {
      label {
        padding-left: 75px;
      }

      .MuiCheckbox-root {
        left: 100px;
      }
    }

    &:checked + label:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }

    &:checked + label.lastTree:before {
      background-image: none;
    }

    &:checked + label.root:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }
  }

  [datatype='N'] {
    color: ${FontColor.Gray200} !important;
  }
`;
const TreeBox = styled.div`
  min-height: 400px;
  padding: 10px 0;
  border: 1px solid ${BorderColor.Primary};
`;

const Tree = styled.div`
  margin-top: 5px;

  label {
    display: inline-block;
    position: relative;
    padding: 6px 0 8px;
    cursor: pointer;
    color: ${FontColor.Gray400};
    width: 100%;
    font-size: 13px;

    &.root {
      color: ${FontColor.Default};
    }
    &.root:before {
      background-image: url(/assets/icon/ic-tree-close.png);
    }

    &:hover,
    &:focus,
    &.on {
      background-color: ${BgColor.Secondary50};

      :after {
        content: '';
        display: inline-block;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${BgColor.Secondary100};
      }
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: -6px;
      background: url(/assets/icon/ic-tree-close.png) no-repeat center;
    }

    &.lastTree:before {
      background-image: none;
    }
  }

  li {
    position: relative;
    list-style: none;
    font-size: 13px;

    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 10px;
    }

    &.lastList {
      :before {
        display: none;
      }
    }

    .MuiCheckbox-root {
      position: absolute;
      left: 26px;
      top: 8px;
      z-index: 2;
      input {
        display: block;
      }
      .Mui-checked {
        color: #3ec2cf;
      }
    }
  }

  ul {
    display: none;
  }

  *:before {
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  input[type='checkbox'] {
    display: none;
    &:checked ~ ul {
      display: block;
    }
    &:checked ~ ul > li {
      label {
        padding-left: 25px;
      }

      .MuiCheckbox-root {
        left: 50px;
      }
    }

    &:checked ~ ul > li ul > li {
      label {
        padding-left: 50px;
      }

      .MuiCheckbox-root {
        left: 75px;
      }
    }

    &:checked ~ ul > li ul > li ul > li {
      label {
        padding-left: 75px;
      }

      .MuiCheckbox-root {
        left: 100px;
      }
    }

    &:checked + label:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }

    &:checked + label.lastTree:before {
      background-image: none;
    }

    &:checked + label.root:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }
  }

  [datatype='N'] {
    color: ${FontColor.Gray200} !important;
  }
`;
