import { useCallback } from 'react';

import { MessageBarInitialState, MessageBarState } from 'models/common/MessageBar';
import { useMessageBarStore } from 'stores/useMessageBarStore';

export const useMessageBar = () => {
  const {
    type,
    isOpen,
    content,
    setMessageBarStateWhenModalOpen,
    setMessageBarStateWhenModalClose,
  } = useMessageBarStore();

  const closeMessageBar = useCallback(() => {
    setTimeout(() => {
      setMessageBarStateWhenModalClose();
    }, 180);
  }, [setMessageBarStateWhenModalClose]);

  /**
   * @param type message bar 유형 (default | confirm | warning | error)
   * @param content 내용
   */
  const openMessageBar = useCallback(
    ({ type = 'default', ...rest }: MessageBarInitialState) => {
      setMessageBarStateWhenModalOpen({
        ...rest,
        type: type,
      });
    },
    [setMessageBarStateWhenModalOpen]
  );

  return {
    messageBarState: {
      type: type,
      isOpen: isOpen,
      content: content,
    } as MessageBarState,
    closeMessageBar,
    openMessageBar,
  };
};
