/** @jsxImportSource @emotion/react */
import { GreyLineButton } from 'components/buttons/CustomButton';
import { CustomInputText, CustomInputWrap } from 'components/inputs/CustomInput';
import React, { useEffect, useRef, useState } from 'react';
import {
  ApporoveExcludeCode,
  ApproveExcludeDetail,
  ApproveExcludeMaster,
  ApproveExcludeRequest,
} from 'models/admin/ApproveExclude';
import {
  deleteApproveExcludeMaster,
  getAllApproveExcludeDetails,
  getAllApproveExcludeList,
  saveApproveExcludeCud,
} from 'apis/admin/ApproveExclude';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { Employee } from 'models/admin/Employee';
import { isEmpty } from 'lodash';
import { CrudCode } from 'models/common/Edit';
import {
  InputBox,
  SearchBoxRow,
  SearchBox,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { ContentFlex } from 'components/layouts/ContentFlex';
import {
  ContentSection,
  GridButtons,
  GridInfo,
  GridInfoSection,
} from 'components/layouts/ContentSection';
import { ContentGrid } from 'components/layouts/ContentGrid';
import EmployeeModal from 'components/modals/common/EmployeeModal';
import { CustomSelect } from 'components/selects/CustomSelect';
import { getEmployeeBySearchCondition } from 'apis/admin/Employee';
import { getCommonCodeNames, getCommonCodeNamesWithCode } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import { Button } from '@mui/material';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from '../../components/layouts/ContentLayout';
import { IconButton } from '../../components/buttons/IconSVG';
import { getExcelFileName } from 'utils/ExcelUtil';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomGrid from 'components/grids/CustomGrid';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import useEvent from 'react-use-event-hook';
import { useMessageBar } from 'hooks/useMessageBar';
import { setApprovalRuleDetails } from 'apis/admin/ApprovalRule';
//
const ApprovalExcludeManagementPage: React.FC = () => {
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();
  const masterRef = useRef<AgGridReact<ApproveExcludeMaster>>(null);
  const detailRef = useRef<AgGridReact<ApproveExcludeDetail>>(null);

  const [approveExcludeList, setApproveExcludeList] = useState<ApproveExcludeMaster[]>([]);
  const [approveExcludeDetails, setApproveExcludeDetails] = useState<ApproveExcludeDetail[]>([]);
  const [originalExcludeList, setOriginalExcludeList] = useState<ApproveExcludeMaster[]>([]);
  const [originalExcludeDetails, setOriginalExcludeDetails] = useState<ApproveExcludeDetail[]>([]);

  const [selectedId, setSelectedId] = useState<string>('');
  const [msCrudKey, setCrudKey] = useState<string>('');
  const [searchItem, setSearchItem] = useState<string>('');

  const [EmployeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);

  const [rowIndex, setRowIndex] = useState<string>('');
  const [code, setCode] = useState<ApporoveExcludeCode>();
  const [divisionCodeMap, setDivisionCodeMap] = useState<Code[]>([]);
  const [jpsCodeMap, setJpsCodeMap] = useState<Code[]>([]);
  const [jtiCodeMap, setJtiCodeMap] = useState<Code[]>([]);

  const [flexItem, setFlexItem] = useState<any>();

  const [masterFlexRef, setMasterFlexRef] = useState<any>();
  const [detailFlexRef, setDetailFlexRef] = useState<any>();

  useEffect(() => {
    getApproveExcludeList();
    getCommonCodesForGrid();
  }, []);

  const getCommonCodesForGrid = async () => {
    const divisionCodeRes: Code[] = await getCommonCodeNames('APR_EXC_DIVS_CD');
    const jpsCodeRes: Code[] = await getCommonCodeNamesWithCode('JPS_CD');
    const jtiCodeRes: Code[] = await getCommonCodeNamesWithCode('JTI_CD');

    setDivisionCodeMap(divisionCodeRes);
    setJpsCodeMap(jpsCodeRes);
    setJtiCodeMap(jtiCodeRes);

    setCode({
      divisionCode: divisionCodeRes,
      jpsCode: jpsCodeRes,
      jtiCode: jtiCodeRes,
    });
  };

  const masterOnInitialized = (grid) => {
    setMasterFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;
      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          // 상세보기
          case 'btnDetail':
            setSelectedId(item.aprExcTgtId as string);
            setCrudKey(item.crudKey);
            break;
        }
      }
    });
  };

  const masterLayoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('com.label.상태', '상태')),
      width: 50,
      binding: 'crudKey',
      isReadOnly: true,
      align: 'center',
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'detail',
      header: String(t('com.label.상세', '상세')),
      width: 45,
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: `<Button id="btnDetail"></Button>`,
    },
    {
      binding: 'aprExcTgtId',
      header: String(t('admin.grid.제외ID', '제외ID')),
      width: 130,
      isReadOnly: true,
      cellTemplate: (item) => {
        return `<span>${item.value ? 'EXC' + item.value.padStart(3, 0) : ''}</span>`;
      },
    },
    {
      binding: 'aprExcNm',
      header: String(t('admin.grid.제외명', '제외명')),
      align: 'left',
      width: 200,
    },
  ];

  const detailOnInitialized = (grid) => {
    setDetailFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;
      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnUserInfo':
            setFlexItem(item);
            setEmployeeModalOpen(true);
            break;
        }
      }
    });

    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        const item = panel.rows[row].dataItem;
      }
    };
  };

  const divisionCode = new DataMap(divisionCodeMap, 'cmnCd', 'cmnCdNm');
  const jtiCode = new DataMap(jtiCodeMap, 'cmnCd', 'cmnCdNm');
  const jpsCode = new DataMap(jpsCodeMap, 'cmnCd', 'cmnCdNm');

  const detailLayoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('com.label.상태', '상태')),
      width: 50,
      binding: 'crudKey',
      isReadOnly: true,
      align: 'center',
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'aprExcSeq',
      header: String(t('admin.grid.제외번호', '제외번호')),
      width: 100,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'aprExcDivsCd',
      header: String(t('admin.grid.대상구분', '대상구분')),
      width: 200,
      cssClass: 'WijmoSelect',
      dataMap: new DataMap(divisionCodeMap, 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'aprExcUserId',
      visible: false,
    },
    {
      binding: 'aprExcUserInfo',
      header: String(t('admin.grid.사용자', '사용자')),
      align: 'left',
      width: 230,
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (item.item.aprExcDivsCd === 'USER') {
          if (item.item.aprExcUserInfo === undefined) {
            return `<span>${item.text}</span><button id="btnUserInfo" />`;
          } else {
            return `<span>${item.item.aprExcUserInfo}</span><button id="btnUserInfo" />`;
          }
        } else {
          return '';
        }
      },
    },
    {
      binding: 'aprExcJtiCd',
      header: String(t('admin.grid.직위', '직위')),
      width: 200,
      cssClass: 'WijmoSelect',
      dataMap: jtiCode,
      cellTemplate: (item) => {
        if (item.item.aprExcDivsCd === 'JTI') {
          if (jtiCode.getDisplayValue(item.value) == undefined) {
            return `<span>${item.text}</span>`;
          } else {
            return `<span>${jtiCode.getDisplayValue(item.value)}</span>`;
          }
        } else {
          return '';
        }
      },
    },
    {
      binding: 'aprExcJpsCd',
      header: String(t('admin.grid.직책', '직책')),
      width: 200,
      cssClass: 'WijmoSelect',
      dataMap: jpsCode,
      cellTemplate: (item) => {
        if (item.item.aprExcDivsCd === 'JPS') {
          if (jpsCode.getDisplayValue(item.value) == undefined) {
            return `<span>${item.text}</span>`;
          } else {
            return `<span>${jpsCode.getDisplayValue(item.value)}</span>`;
          }
        } else {
          return '';
        }
      },
    },
  ];

  const beginningEdit = useEvent((grid, e) => {
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;

    if (binding === 'aprExcUserInfo') {
      e.cancel = true;
    }
  });

  const handleSelectUser = (emp: Employee[]) => {
    flexItem['aprExcUserId'] = emp[0].userId;
    flexItem['aprExcUserInfo'] =
      emp[0].empNm + '/' + emp[0].jtiNm + (emp[0].deptNm ? '/' + emp[0].deptNm : '');

    setEmployeeModalOpen(false);
  };

  useEffect(() => {
    getApproveExcludeDetails();
  }, [selectedId]);

  const getApproveExcludeList = async (searchItem?: string) => {
    const res: ApproveExcludeMaster[] = await getAllApproveExcludeList(searchItem ?? '');
    if (res !== null) {
      setApproveExcludeList(res);
      const original = res.map((item) => JSON.parse(JSON.stringify(item)));
      setOriginalExcludeList(original);
    }
  };

  const getApproveExcludeDetails = async () => {
    const res: ApproveExcludeDetail[] = await getAllApproveExcludeDetails(selectedId);
    if (res != null) {
      setApproveExcludeDetails(res);
      const original = res.map((item) => JSON.parse(JSON.stringify(item)));
      setOriginalExcludeDetails(original);
    }
  };

  const handleSearch = () => {
    setSelectedId('');
    getApproveExcludeList(searchItem);
  };

  const getUserInfoByUserId = async (params) => {
    const userId = params.data.aprExcUserId;

    const response = await getEmployeeBySearchCondition(userId);
    if (response) {
      const userInfo = response.find((emp) => emp.userId === userId);
      params.data.userInfo = userInfo
        ? userInfo.empNm + '/' + userInfo.jtiNm + (userInfo.deptNm ? '/' + userInfo.deptNm : '')
        : userId;
    }
    params.api.refreshCells();
  };

  const handleAddMasterRow = () => {
    if (approveExcludeList.length === originalExcludeList.length)
      setApproveExcludeList([
        ...approveExcludeList,
        { aprExcTgtId: '', aprExcNm: '', crudKey: CrudCode.CREATE },
      ]);
  };

  const handleDeleteMasterRow = () => {
    const selectedRowNodes = masterFlexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }

    const notNulldata = isSelected.filter((item) => item.dataItem.crudKey !== CrudCode.CREATE);
    const delData = notNulldata.map(({ dataItem }) => dataItem);

    if (delData.length === 0) return;
    if (delData[0].aprExcTgtId === '') {
      setApproveExcludeList(approveExcludeList.filter((item) => item.aprExcTgtId !== ''));
    } else {
      openCommonModal({
        modalType: 'confirm',
        content: t(
          'admin.msg.해당 결재자 제외를 삭제하시겠습니까?',
          '해당 결재자 제외를 삭제하시겠습니까?'
        ),
        yesCallback: async () => {
          deleteApproveExcludeMaster(delData).then((result) => {
            openMessageBar({
              type: result ? 'confirm' : 'error',
              content: result
                ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            getApproveExcludeList();
            setSelectedId('');
          });
        },
      });
    }
  };

  const handleAddDetailRow = () => {
    if (msCrudKey !== CrudCode.CREATE && selectedId == '') {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.제외ID를 선택해 주세요.', '제외ID를 선택해 주세요.'),
      });
      return false;
    }
    setApproveExcludeDetails([
      ...approveExcludeDetails,
      {
        crudKey: CrudCode.CREATE,
        aprExcTgtId: selectedId,
        aprExcDivsCd: 'USER',
        aprExcUserInfo: '',
        aprExcJtiCd: '',
        aprExcJpsCd: '',
      },
    ]);
  };

  const handleDeleteDetailRow = () => {
    const selectedRowNodes = detailFlexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode._idx!);
      })
      .reverse();

    selectedIds.forEach((idx) => {
      if (approveExcludeDetails[idx].crudKey === CrudCode.CREATE) {
        delete approveExcludeDetails[idx];
      } else {
        approveExcludeDetails[idx].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = approveExcludeDetails.filter((element) => element !== undefined);
    setApproveExcludeDetails(filteredData);
  };

  const validateSaveCondition = () => {
    if (approveExcludeList.some((item) => item.aprExcNm === '')) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.제외명을 입력해 주세요.', '제외명을 입력해 주세요.'),
      });
      return false;
    }
    if (
      approveExcludeDetails.some(
        (item) => item.aprExcDivsCd === 'USER' && item.aprExcUserInfo === ''
      )
    ) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.사묭자를 선택해 주세요.', '사묭자를 선택해 주세요.'),
      });
      return false;
    }
    if (
      approveExcludeDetails.some((item) => item.aprExcDivsCd === 'JTI' && item.aprExcJtiCd === '')
    ) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.직위를 선택해 주세요.', '직위를 선택해 주세요.'),
      });
      return false;
    }
    if (
      approveExcludeDetails.some((item) => item.aprExcDivsCd === 'JPS' && item.aprExcJpsCd === '')
    ) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.직책을 선택해 주세요.', '직책을 선택해 주세요.'),
      });
      return false;
    }
  };

  const handleSave = () => {
    if (validateSaveCondition() === false) return false;

    const masterCrud = approveExcludeList.filter((item) => item.crudKey !== undefined);
    const detailCrud = approveExcludeDetails.filter((item) => item.crudKey !== undefined);
    const crudRequest: ApproveExcludeRequest = {
      approveExcludeMaster: masterCrud,
      approveExcludeDetail: detailCrud,
    };

    saveApproveExcludeCrud(crudRequest);
  };

  const saveApproveExcludeCrud = async (crudRequest: ApproveExcludeRequest) => {
    if (isEmpty(crudRequest.approveExcludeDetail) && isEmpty(crudRequest.approveExcludeMaster))
      return;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.msg.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        saveApproveExcludeCud(crudRequest).then((result) => {
          openMessageBar({
            type: result ? 'confirm' : 'error',
            content: result
              ? t('com.msg.저장되었습니다.', '저장되었습니다.')
              : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          getApproveExcludeList();
          getApproveExcludeDetails();
        });
      },
    });
  };

  const btnExcelExportMaster = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(masterFlexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'ApproveExcludeList';
    book.saveAsync(getExcelFileName(t('com.label.결재자제외명목록', '결재자제외명목록')));
  };

  const btnExcelExportDetail = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(detailFlexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'ApproveExcludeList';
    book.saveAsync(getExcelFileName(t('com.label.결재자제외대상목록', '결재자제외대상목록')));
  };

  const btnReload = () => {
    setSearchItem('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('admin.label.제외명', '제외명')}</label>
                <CustomInputWithSearch
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearchItem(e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                  placeholder={String(
                    t('admin.label.제외명을 입력해 주세요.', '제외명을 입력해 주세요.')
                  )}
                  value={searchItem}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button css={IconButton.button} className="reload" disableRipple onClick={btnReload} />
            <Button css={IconButton.button} className="find" disableRipple onClick={handleSearch}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContentFlex>
        <ContentSection className="section width30p marginT0">
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('admin.label.결재자 제외명', '결재자 제외명')}</h3>
              <span className="total">
                {t('com.label.총', '총')}
                <span>{approveExcludeList?.length ?? 0}</span>
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
            <ControlBtnGroup>
              <Button
                css={IconButton.button}
                className="Exceldown"
                onClick={btnExcelExportMaster}
                disableRipple
              >
                {t('com.button.다운로드', '다운로드')}
              </Button>
              <Button
                css={IconButton.button}
                className="plus"
                disableRipple
                onClick={handleAddMasterRow}
              >
                {t('com.button.행추가', '행추가')}
              </Button>
              <Button
                css={IconButton.button}
                className="minus"
                disableRipple
                onClick={handleDeleteMasterRow}
              >
                {t('com.button.행삭제', '행삭제')}
              </Button>
            </ControlBtnGroup>
          </SubTitleLayout>

          <CustomGrid
            rowData={approveExcludeList}
            height={560}
            deferResizing={false}
            align={'center'}
            layoutDefinition={masterLayoutDefinition}
            initialized={masterOnInitialized}
            excludeFilter={['detail']}
            excludePin={['detail']}
          />
        </ContentSection>
        <ContentSection className="section width70p">
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('admin.label.결재자 제외 대상목록', '결재자 제외 대상목록')}</h3>
              <span className="total">
                {t('com.label.총', '총')}
                <span>{approveExcludeDetails?.length ?? 0}</span>
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
            <ControlBtnGroup>
              <Button
                css={IconButton.button}
                className="Exceldown"
                onClick={btnExcelExportDetail}
                disableRipple
              >
                {t('com.button.다운로드', '다운로드')}
              </Button>
              <Button
                css={IconButton.button}
                className="plus"
                disableRipple
                onClick={handleAddDetailRow}
              >
                {t('com.button.행추가', '행추가')}
              </Button>
              <Button
                css={IconButton.button}
                className="minus"
                disableRipple
                onClick={handleDeleteDetailRow}
              >
                {t('com.button.행삭제', '행삭제')}
              </Button>
            </ControlBtnGroup>
          </SubTitleLayout>

          <CustomGrid
            rowData={approveExcludeDetails}
            height={560}
            deferResizing={false}
            align={'center'}
            beginningEdit={beginningEdit}
            layoutDefinition={detailLayoutDefinition}
            initialized={detailOnInitialized}
          />
          <GlobalBtnGroup>
            <Button css={IconButton.button} className="save" disableRipple onClick={handleSave}>
              {t('com.button.저장', '저장')}
            </Button>
          </GlobalBtnGroup>
        </ContentSection>
      </ContentFlex>

      <EmployeeModal
        open={EmployeeModalOpen}
        close={() => {
          setEmployeeModalOpen(false);
        }}
        save={(emp: Employee[]) => handleSelectUser(emp)}
        singleSelect={true}
      />
    </>
  );
};

export default ApprovalExcludeManagementPage;
