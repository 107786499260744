/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import _ from 'lodash';
import { ManagementMode } from 'models/common/Common';
import { tb } from 'components/layouts/Table';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import Dropzone from 'components/dropzone/Dropzone';
import ParameterMasterGrid from 'pages/ip/base/parametermanagement/ParameterMasterGrid';
import ParameterDetailGrid from 'pages/ip/base/parametermanagement/ParameterDetailGrid';
import useSessionStore from 'stores/useSessionStore';
import {
  ParameterApprovalRequest,
  ParameterDetailRequest,
  ParameterMasterRequest,
} from 'models/ip/EquipmentParameter';
import {
  generateApprovalRequestId,
  findEquipmentParameterRequest,
  createEquipmentParameterRequest,
  approveEquipmentParameterRequest,
  findEquipmentParameterRequestMasterList,
} from 'apis/ip/EquipmentParameter';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ComboBox } from 'components/selects/ComboBox';
import { CustomTextarea } from 'components/inputs/CustomInput';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { uploadExcelTemplates } from 'apis/common/Excel';
import ExcelValidModal from '../common/ExcelValidModal';
import { useLoading } from 'components/process/Loading';
import { CrudCode } from 'models/common/Edit';
import CustomDialog from 'components/modals/common/CustomDialog';

interface Props {
  open: boolean;
  mode: ManagementMode;
  condition?: {
    aprReqId?: string; // 결재요청ID
    defaultEqpParaNo?: string; // 설비 파라미터 번호 (수정/삭제 요청인 경우)
  };
  close: (isDone?: boolean) => void;
}

/**
 * IP 기준정보 등록/수정/삭제 요청 팝업 화면
 * 24.03.29 IP 결재 시스템 변경으로 인해 사용 X ParameterManagementReqModal 으로 변경
 * @param open
 * @param mode
 * @param condition
 * @param close
 * @constructor
 */

const ParameterManagementModal = ({ open, mode, condition, close }: Props) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const [detailTarget, setDetailTarget] = useState<ParameterMasterRequest>({});
  const [reqData, setReqData] = useState<ParameterApprovalRequest>({
    aprTplId: 'SEC-FORM-0001', // 고정
    aprPvnDdlnCd: 'APPRCODE_1M',
  } as ParameterApprovalRequest);
  const { userId } = useSessionStore();
  const masterGridRef = useRef<any>(null);
  const detailGridRef = useRef<any>(null);
  const [code, setCode] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  // Excel Vaild Modal
  const [ExcelValidModalOpen, setExcelValidModalOpen] = useState<boolean>(false);
  const [excelValidCondition, setExcelValidCondition] = useState<string | null>(null);
  const [rowData, setRowData] = useState<ParameterMasterRequest[]>([]);
  const isExcelMode = useMemo(
    () => [ManagementMode.CREATE_EXCEL, ManagementMode.MODIFY_EXCEL].includes(mode),
    [mode]
  );
  const title = useMemo(() => {
    /*
    let modeNm = '등록';
    if ([ManagementMode.MODIFY, ManagementMode.MODIFY_EXCEL].includes(mode)) {
      modeNm = '수정';
    } else if (ManagementMode.DELETE === mode) {
      modeNm = '삭제';
    }
    return `IP 기준정보 ${modeNm} 요청`;
    */
    if ([ManagementMode.MODIFY, ManagementMode.MODIFY_EXCEL].includes(mode)) {
      return t('ip.label.IP 기준정보 수정 요청', 'IP 기준정보 수정 요청');
    } else if (ManagementMode.DELETE === mode) {
      return t('ip.label.IP 기준정보 삭제 요청', 'IP 기준정보 삭제 요청');
    } else if (ManagementMode.READ === mode) {
      return t('ip.label.IP 기준정보', 'IP 기준정보') + ' ' + t('ip.label.조회', '조회');
    }
    return t('ip.label.IP 기준정보 등록 요청', 'IP 기준정보 등록 요청');
  }, [mode]);

  useEffect(() => {
    getCommonCodesForGrid();

    if (!condition?.aprReqId) {
      generateApprovalRequestId().then((value) => {
        setReqData({ ...reqData, aprReqId: value });
      });
    } else {
      // 요청 정보 조회
      searchRequest(condition?.aprReqId);
    }
  }, []);

  useEffect(() => {
    searchData();
  }, [reqData.aprReqId]);

  const searchData = () => {
    if (reqData?.aprReqId) {
      findEquipmentParameterRequestMasterList(reqData.aprReqId).then((result) => {
        if (result && result.length > 0) {
          setRowData(result);
          setDetailTarget(result[0]);
        } else {
          // 신규 & UI인 경우 등록을 위한 행 추가
          if (mode === ManagementMode.CREATE) {
            const newRow = {
              crudKey: CrudCode.CREATE,
            } as ParameterMasterRequest;
            setRowData([newRow, ...rowData]);
          }
        }
      });
    }
  };

  const getCommonCodesForGrid = async () => {
    const aprPvnDdlnCd: Code[] = await getCommonCodeNames('APR_PVN_DDLN_CD');

    setCode({
      aprPvnDdlnCd: aprPvnDdlnCd,
    });
  };

  /**
   * 임시저장 처리
   */
  const handleSave = () => {
    if (_.isEmpty(reqData.aprReqTitNm)) {
      setErrors({ aprReqTitNm: true });
      return;
    }
    createEquipmentParameterRequest({
      ...reqData,
      mode: mode,
      eqpParaMReqList: rowData,
    }).then((result) => {
      openMessageBar({
        type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          result?.successOrNot === 'Y'
            ? t('com.label.저장되었습니다.', '저장되었습니다.')
            : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
      });
      refresh();
    });
  };

  /**
   * 결재의뢰 처리
   * [23.10.05] 결재작업 완료 전까지는 결재의뢰와 동시에 바로 승인 처리함
   */
  const handleApprovalRequest = () => {
    if (_.isEmpty(reqData.aprReqTitNm)) {
      setErrors({ aprReqTitNm: true });
      return;
    }

    if (masterGridRef.current?.validate() && detailGridRef.current?.validate()) {
      approveEquipmentParameterRequest({
        ...reqData,
        mode: mode,
        eqpParaMReqList: rowData,
      }).then((result) => {
        const data = typeof result?.data === 'string' ? result?.data : null;
        openMessageBar({
          type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
          content:
            result?.successOrNot === 'Y'
              ? t('com.label.승인되었습니다.', '승인되었습니다.')
              : data || t('com.label.승인 중 오류가 발생했습니다.', '승인 중 오류가 발생했습니다.'),
        });
        if (result?.successOrNot === 'Y') {
          close(true);
        }
      });
    }
  };

  const searchRequest = (aprReqId) => {
    findEquipmentParameterRequest(aprReqId).then((result) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t(
            'ip.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
        close();
        return;
      }
      if (!result.aprPvnDdlnCd && reqData.aprPvnDdlnCd) result.aprPvnDdlnCd = reqData.aprPvnDdlnCd;
      if (!result.aprReqTitNm && reqData.aprReqTitNm) result.aprReqTitNm = reqData.aprReqTitNm;
      if (!result.trnmBdyCtn && reqData.trnmBdyCtn) result.trnmBdyCtn = reqData.trnmBdyCtn;
      setReqData({ ...reqData, ...result });
    });
  };

  const handleReqDataChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReqData({ ...reqData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
  };

  const refresh = () => {
    searchRequest(reqData?.aprReqId);
    searchData();
    // masterGridRef.current.refresh();
    // detailGridRef.current.refresh();
  };

  const handleClose = () => {
    if (ManagementMode.DELETE === mode) {
      close();
      return;
    }
    const mList = (rowData || []).filter((o) => !!o.crudKey);
    rowData.filter((o) => !!(o.eqpParaDReqList || []).length);
    const dList = (detailGridRef.current.getDataList() || []).filter((o) => {
      return !!o.crudKey || !!(o.parameterPointList || []).filter((p) => !!p.crudKey).length;
    });
    if (mList.length || dList.length) {
      openCommonModal({
        modalType: 'confirm',
        content: t(
          'com.label.작성하신 내용이 저장되지 않았습니다.<br/>그래도 화면을 닫으시겠습니까?',
          '작성하신 내용이 저장되지 않았습니다.<br/>그래도 화면을 닫으시겠습니까?'
        ),
        yesCallback() {
          close();
        },
      });
      return;
    }
    close();
  };

  const uploadTemplate = (file) => {
    openLoading(true);
    uploadExcelTemplates(
      [file],
      'TPL_IP_LIST',
      'NORMAL',
      'TB_ELM_IP_EQP_PARA_M_REQ_N',
      mode,
      reqData?.aprReqId,
      reqData?.aprTplId,
      reqData?.aprPvnDdlnCd,
      reqData?.aprReqTitNm,
      reqData?.trnmBdyCtn
    )
      .then((result) => {
        openLoading(false);
        if (result?.successOrNot === 'Y') {
          if (result?.data.x_result == 'NG') {
            setExcelValidCondition(result?.data.p_xls_upload_id);
            setExcelValidModalOpen(true);
            return;
          }
        }
        const data = typeof result?.data === 'string' ? result?.data : null;
        openMessageBar({
          type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
          content:
            result?.successOrNot === 'Y'
              ? t('ip.msg.검증이 완료되었습니다.', '검증이 완료되었습니다.')
              : data || t('ip.msg.검증 중 오류가 발생했습니다.', '검증 중 오류가 발생했습니다.'),
        });
        if (result?.successOrNot === 'Y') {
          refresh();
        }
      })
      .finally(() => openLoading(false));
  };

  const dialogButtons = [
    <Button
      key={'save'}
      css={IconButton.button}
      className="save"
      onClick={handleSave}
      disableRipple
      hidden={mode === ManagementMode.READ}
    >
      {t('com.button.저장', '저장')}
    </Button>,
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleApprovalRequest}
      disableRipple
    >
      {t('com.button.결재의뢰', '결재의뢰')}
    </Button>,
  ];

  const dialogButtonsByAuth = mode === ManagementMode.READ ? null : dialogButtons;

  return (
    <>
      <CustomDialog
        open={open}
        title={title}
        size={'xl'}
        buttons={dialogButtonsByAuth}
        onCancel={handleClose}
        onClose={handleClose}
      >
        <TableContainer css={tb.tableRow}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="firstCol">{t('com.label.요청 ID', '요청 ID')}</TableCell>
                <TableCell>{reqData?.aprReqId}</TableCell>
                <TableCell className="secondCol">
                  {t('com.label.결재요청양식', '요청양식')}
                </TableCell>
                <TableCell>{reqData?.aprTplId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="firstCol">{t('com.label.요청자', '요청자')}</TableCell>
                <TableCell>{userId}</TableCell>
                <TableCell className="secondCol">
                  {t('com.label.결재보존기한', '결재보존기한')}
                </TableCell>
                <TableCell>
                  <ComboBox
                    options={code?.aprPvnDdlnCd}
                    defaultValue={reqData.aprPvnDdlnCd}
                    onChange={(value: string) =>
                      setReqData({
                        ...reqData,
                        aprPvnDdlnCd: value,
                      })
                    }
                    disabled={mode === ManagementMode.READ}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="firstCol">{t('ip.label.요청제목', '요청제목')}</TableCell>
                <TableCell colSpan={3}>
                  <CustomInputWithSearch
                    type="text"
                    name="aprReqTitNm"
                    placeholder={String(
                      t('ip.msg.요청제목을 입력해 주세요.', '요청제목을 입력해 주세요.')
                    )}
                    value={reqData.aprReqTitNm}
                    onChange={handleReqDataChange}
                    isError={errors?.aprReqTitNm}
                    msgError={String(
                      t('ip.msg.요청제목을 입력해 주세요.', '요청제목을 입력해 주세요.')
                    )}
                    disabled={mode === ManagementMode.READ}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="firstCol">{t('ip.label.요청내용', '요청내용')}</TableCell>
                <TableCell colSpan={3}>
                  <CustomTextarea
                    name="trnmBdyCtn"
                    value={reqData.trnmBdyCtn}
                    onChange={handleReqDataChange}
                    disabled={mode === ManagementMode.READ}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {isExcelMode && reqData?.aprReqId && (
          <>
            <SubTitleLayout>
              <SubTitleGroup>
                <h3>{t('ip.label.엑셀 파일 검색', '엑셀 파일 검색')}</h3>
              </SubTitleGroup>
            </SubTitleLayout>
            <TableContainer css={tb.table}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('ip.label.파일 선택', '파일 선택')}</TableCell>
                    <TableCell>
                      <Dropzone
                        singleSelect={true}
                        onChange={(files) => {
                          if (files && files.length > 0) {
                            const file = files[0].file;
                            if (file) {
                              if ((rowData || []).length) {
                                openCommonModal({
                                  modalType: 'confirm',
                                  content: t(
                                    'com.label.업로드된 데이터가 존재합니다.\n새로 업로드 하시겠습니까?',
                                    '업로드된 데이터가 존재합니다.\n새로 업로드 하시겠습니까?'
                                  ),
                                  yesCallback: () => {
                                    uploadTemplate(file);
                                  },
                                });
                                return;
                              }
                              uploadTemplate(file);
                            }
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {/* <ParameterMasterGrid
          ref={masterGridRef}
          rowData={rowData}
          mode={mode}
          onChange={(masterRequest) => {
            setDetailTarget(masterRequest);
          }}
        /> */}
        <ParameterDetailGrid
          ref={detailGridRef}
          mode={mode}
          versionNoUp={''}
          masterRequest={detailTarget}
          onChange={(eqpParaDReqList: ParameterDetailRequest[]) => {
            setRowData(
              rowData.reduce((acc, cur) => {
                if (
                  cur.eqpParaNo === detailTarget.eqpParaNo &&
                  cur.versionNo === detailTarget.versionNo
                ) {
                  cur.eqpParaDReqList = eqpParaDReqList;
                }
                acc.push(cur);
                return acc;
              }, [] as ParameterMasterRequest[])
            );
          }}
        />
      </CustomDialog>
      {ExcelValidModalOpen && (
        <ExcelValidModal
          open={ExcelValidModalOpen}
          close={() => {
            setExcelValidCondition(null);
            setExcelValidModalOpen(false);
          }}
          condition={{
            valReqId: excelValidCondition || '',
          }}
        />
      )}
    </>
  );
};

export default ParameterManagementModal;
