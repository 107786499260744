import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { handlerUrls } from 'mocks/handlers';
import {
  CommonRequest,
  CommonResponse,
  Method,
  ServiceName,
  StatusCode,
  SuccessOrNot,
} from 'models/common/RestApi';
import useSessionStore from 'stores/useSessionStore';

axios.defaults.withCredentials = true;
const getInstance = (request: CommonRequest): AxiosInstance => {
  let baseURL = '';
  if (process.env.REACT_APP_MSW_ENABLE === 'N' || handlerUrls.indexOf(request.url) === -1) {
    switch (request.serviceName) {
      case ServiceName.ELM_BE:
        baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
        break;
      default:
    }
  }

  let config = {};
  switch (request.method) {
    case Method.GET:
      config = {
        baseURL: baseURL,
        //timeout: 6000,
        headers: request?.headers || {
          Accept: 'application/json',
        },
        params: request?.queryParams || {},
        responseType: request?.responseType || 'json',
      };
      break;
    case Method.DELETE:
      config = {
        baseURL: baseURL,
        timeout: 120000,
        headers: request?.headers || {
          Accept: 'application/json',
        },
        params: request?.queryParams || {},
        responseType: request?.responseType || 'json',
      };
      break;
    case Method.POST:
    case Method.PUT:
    case Method.PATCH:
      config = {
        baseURL: baseURL,
        timeout: request?.timeout || 120000,
        headers: request?.headers || {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        responseType: request?.responseType || 'json',
      };
      break;
    default:
  }

  const instance = axios.create(config);

  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      config.headers['x-language'] = useSessionStore.getState().langCd;
      config.headers['x-timeZone'] = useSessionStore.getState().gptTmzCd;
      config.headers['x-timeFormat'] = useSessionStore.getState().tmzFrmtCd;
      config.headers['accept-language'] = useSessionStore.getState().langCd;
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    async (response: any): Promise<any> => {
      if (response.data instanceof Blob) {
        const commonFileResponse: CommonResponse<Blob> = {
          successOrNot: SuccessOrNot.Y,
          statusCode: StatusCode.SUCCESS,
          data: new Blob([response?.data]),
          headers: response?.headers,
        };

        return commonFileResponse;
      }

      const commonResponse: CommonResponse = response.data as CommonResponse;

      if (commonResponse.statusCode && commonResponse.statusCode === StatusCode.SESSION_EXPIRE) {
        sessionStorage.clear();
        window.location.assign('/'); // TODO: 서버확인 후 수정필요
      } else if (
        commonResponse.statusCode &&
        commonResponse.statusCode === StatusCode.NOT_AUTHORIZED_EXCEPTION
      ) {
        window.location.assign('/*');
      }
      return commonResponse;
    },

    async (error: any): Promise<any> => {
      let errorResponse: CommonResponse = {
        successOrNot: SuccessOrNot.N,
        statusCode: StatusCode.UNKNOWN_ERROR,
        data: {},
      };

      if (error.code === 'ECONNABORTED') {
        errorResponse.statusCode = StatusCode.TIMEOUT;
      }

      if (error.response?.status === 403 && errorResponse.successOrNot === SuccessOrNot.N) {
        errorResponse = error.response.data;
        return Promise.reject(errorResponse);
      }

      return errorResponse;
    }
  );
  return instance;
};

export const callApi = async (apiRequest: CommonRequest): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: SuccessOrNot.N,
    statusCode: StatusCode.BAD_REQUEST_ERROR,
    data: {},
    headers: [],
  };

  switch (apiRequest.method) {
    case Method.GET:
      response = await getInstance(apiRequest).get(apiRequest.url);
      break;
    case Method.POST:
      response = await getInstance(apiRequest).post(
        apiRequest.url,
        apiRequest.bodyParams || {},
        apiRequest.headers || {}
      );
      break;
    case Method.PUT:
      response = await getInstance(apiRequest).put(apiRequest.url, apiRequest.bodyParams || {});
      break;
    case Method.DELETE:
      response = await getInstance(apiRequest).delete(apiRequest.url);
      break;
    case Method.PATCH:
      response = await getInstance(apiRequest).patch(apiRequest.url, apiRequest.bodyParams || {});
      break;
    default:
      break;
  }
  return response;
};
