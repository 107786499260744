/** @jsxImportSource @emotion/react */
import React, { ReactNode, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { TitleWrap, di, PopupBtnGroup } from 'components/layouts/Dialog';
import { CloseBtn } from 'components/buttons/CustomButton';

/**
 * Dialog 크기
 *
 * xs : 400px
 * sm : 600px
 * md : 800px
 * lg : 1200px
 * xl : 1600px
 */
interface Props {
  title: string; // 제목
  open: boolean; // dialog 노출여부
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'; // dialog 크기 (default: lg)
  className?: string; // dialog 크기를 제외한 별도 클래스가 있는 경우 (ex. noScroll)
  children: ReactNode; // Dialog 내용 (cf. <DialogContent> 내 노출)
  buttons?: ReactElement[] | null; // Dialog 하단 버튼
  onCancel?: () => void; // 취소 이벤트 (설정 시 취소버튼 노출)
  onClose: () => void; // 닫기 이벤트
}

const CustomDialog = ({
  title,
  open,
  size = 'lg',
  className,
  children,
  buttons,
  onCancel,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose && onClose();
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <Dialog
      open={open}
      css={di.dialog}
      className={`${size} ${className || ''}`}
      maxWidth={false}
      fullWidth
      disableEnforceFocus
    >
      <TitleWrap>
        <h2>{title}</h2>
        <CloseBtn onClick={handleClose} />
      </TitleWrap>
      <DialogContent>{children}</DialogContent>
      <PopupBtnGroup className={(buttons || []).length < 1 && !onCancel ? 'blank' : ''}>
        {onCancel && <Button onClick={handleCancel}>{t('com.button.취소', '취소')}</Button>}
        {/* 버튼들 렌더링 */}
        {(buttons || []).length > 0 &&
          (buttons || []).map((button, index) => (
            <React.Fragment key={index}>{button}</React.Fragment>
          ))}
      </PopupBtnGroup>
    </Dialog>
  );
};

export default CustomDialog;
