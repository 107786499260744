import {
  MpItemCondition,
  MpItem,
  MpItemCopyRequest,
  MpItemBookmarkRequest,
} from 'models/mp/MpItem';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { downloadFile } from 'apis/common/Excel';

/**
 * 설비변경점/수평전개 Item 목록 조회
 * @param condition
 */
export const findMpItems = async (condition?: MpItemCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<MpItem[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MpItem[];
};

/**
 * 설비변경점/수평전개 Item 정보 조회
 * @param condition
 */
export const findMpItem = async (itemId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/${itemId}`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<MpItem> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MpItem;
};

/**
 * 설비변경점/수평전개 Item 등록 여부 확인
 * @param condition
 */
export const countByItemId = async (itemId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/${itemId}/count`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<number> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : -1) as number;
};

/**
 * 설비변경점/수평전개 Item 조회 > 첨부파일그룹ID 생성
 * @param itemId
 */
export const generateAtchFileGrId = async (itemId: string) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/${itemId}/attach`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<string> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * 설비변경점/수평전개 Item 정보 저장
 * @param itemId
 */
export const saveMpItem = async (mpItem: MpItem) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/${mpItem.itemId}`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: mpItem,
  };

  const response: CommonResponse<string> = await callApi(request);
  return response;
};

/**
 * 설비변경점/수평전개 Item 상태 정보 저장
 * @param itemId
 */
export const saveMpItemStat = async (mpItem: MpItem) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/${mpItem.itemId}/stat`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: mpItem,
  };

  const response: CommonResponse<string> = await callApi(request);
  return response;
};

/**
 * 품목ID 생성
 */
export const generateItemId = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/item-id`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<string> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * Mp Items 목록 엑셀 다운로드
 * @param condition
 */
export const downloadExcelMpItems = async (condition: ExcelDownloadRequest<MpItemCondition>) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/items/excel-download`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, `${condition.fileName}`);
};

/**
 * MP Item 저장
 * @param approvalRequest
 */
export const copyMpItem = async (mpItemCopyRequest?: MpItemCopyRequest) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/copy`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: mpItemCopyRequest,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * MP Item 관심항목 추가
 * @param approvalRequest
 */
export const addBookmarkItem = async (mpItemBookmarkRequest?: MpItemBookmarkRequest) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/bookmark`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: mpItemBookmarkRequest,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};
