import MenuSidebarItem from 'pages/admin/menumanagement/MenuSidebarItem';
import { MenuVO } from 'models/admin/Menu';
import { useEffect, useState } from 'react';
import { getTranslatedMessages } from 'apis/admin/TranslatedMessage';

export interface treeParam {
  items: MenuVO[];
  hasCheckbox: boolean;
  langCd?: string;
}

const MenuSidebar = ({ items, hasCheckbox, langCd }: treeParam) => {
  const [tree, setTree] = useState<any>();

  useEffect(() => {
    setTree(nest(items));
  }, [items]);

  useEffect(() => {
    if (langCd) {
      (async function () {
        const response = await getTranslatedMessages(langCd);
        if (response) {
          const translatedItems: MenuVO[] = items.map((menu) => {
            if (menu.msgCtn) {
              if (response[menu.msgCtn]) return { ...menu, mnuNm: response[menu.msgCtn] };
              else return { ...menu, mnuNm: `${menu.mnuNm}` };
            } else return { ...menu };
          });
          setTree(nest(translatedItems));
          return;
        }
      })();
    }
  }, [langCd, items]);

  const nest = (menuData: MenuVO[], mnuId = '000000', link = 'upprMnuId') =>
    menuData
      .filter((item) => item[link] === mnuId)
      .map((item) => ({ ...item, childrens: nest(menuData, item.mnuId) }));

  return (
    <>
      {tree &&
        tree.map((subItem: MenuVO, index: number) => (
          <MenuSidebarItem
            item={subItem}
            key={index}
            hasCheckbox={hasCheckbox}
            langCd={langCd ?? ''}
          />
        ))}
    </>
  );
};

export default MenuSidebar;
