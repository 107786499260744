/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@mui/material';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomSwitch from 'components/selects/CustomSwitch';
import { st } from 'components/inputs/CustomInput';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { CellType, CellRange, MergeManager } from '@grapecity/wijmo.grid';
import { createEquipmentParameterRequestByEqpParaNo } from 'apis/ip/EquipmentParameter';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { findBaseImformation, getBaseImfoDownload } from 'apis/ip/BaseImformation';
import { BaseImformationCondition } from 'models/ip/BaseImformation';
import { EquipmentGroupPopUp } from 'pages/common/popup/EquipmentGroupPopUp';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { IconButton } from 'components/buttons/IconSVG';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { getExcelFileName } from 'utils/ExcelUtil';
import { FileTypeName, ManagementMode } from 'models/common/Common';
import { downloadExcelTemplatesPost } from 'apis/common/Excel';
import ParameterManagementModal from 'components/modals/ip/ParameterManagementModal';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { hasRole } from 'utils/SessionUtil';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import useEvent from 'react-use-event-hook';
import ApproveRequestModal from 'pages/approves/ApproveRequestModal';
import { ApproveRequestPageType } from 'pages/approves/ApproveRequestDetailCase';
import ParameterManagementReqReadModal from 'components/modals/ip/ParameterManagementReqReadModal';

interface BaseImformationPageLocationState {
  equipmentGroup?: string;
  equipmentGroupName?: string;
  useYn?: string;
  condition?: any;
  pageNo?: number;
}

/**
 * 설비검수 > IP점검 계획/실적 > Ip List 조회
 * @constructor
 */
export const BaseImformationPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();
  const [searchParamData, setSearchParamData] = useState<BaseImformationCondition>({
    showHistoryYn: 'N',
    useYn: 'Y',
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rowData, setRowData] = useState<BaseImformationCondition[]>([]);
  const [pageId, setPageId] = useState<ApproveRequestPageType>(
    ApproveRequestPageType.IP_APPROVE_REQ
  );
  const [isOpenParameterManagementModal, setOpenParameterManagementModal] =
    useState<boolean>(false);
  const [isOpenParameterManagementReqReadModal, setOpenParameterManagementReqReadModal] =
    useState<boolean>(false);
  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  const [mode, setMode] = useState<ManagementMode>(ManagementMode.CREATE);
  const [checkedItems, setCheckedItems] = useState<BaseImformationCondition[]>();
  const [openEquipmentGroupPopUp, setOpenEequipmentGroupPopUp] = useState<boolean>(false);
  const [hasAuth, setHasAuth] = useState<boolean>(false);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [colWidthMap, setColWidthMap] = useState<any>({
    equipmentGroup: 110,
    eqpParaNo: 190,
    versionNo: 100,
    eqpParaName: 250,
    paraCnt: 150,
    ipPointCnt: 120,
    request: 200,
    changeReasonDesc: 150,
    useYn: 130,
    dataInsDtm: 160,
    empNm: 150,
    dataUpdDtm: 160,
    updateEmpNm: 150,
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setSearchParamData({
      ...searchParamData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    // ip 권한체크
    if (hasRole('IP_MANAGER') || hasRole('CMN')) {
      setHasAuth(true);
    }
  }, []);

  const handleDownloadExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ip.label.IP기준정보', 'IP기준정보');
    book.saveAsync(getExcelFileName(t('ip.label.IP기준정보', 'IP기준정보')) + '.xlsx');
    // getBaseImfoDownload(excelData);
  };

  const layoutDefinition = [
    {
      visible: false, // 설비군이 0 위치에 있으면 체크박스도 같아 셀병합되어 임의로 추가
    },
    {
      binding: 'equipmentGroup',
      header: String(t('com.label.설비군', '설비군')),
      align: 'center',
      width: colWidthMap.equipmentGroup,
    },
    {
      binding: 'eqpParaNo',
      header: String(t('ip.grid.설비파라미터번호', '설비파라미터번호')),
      align: 'center',
      width: colWidthMap.eqpParaNo,
      cellTemplate: CellMaker.makeLink({
        click: (e, ctx) => {
          navigate('/ip/equipment-parameter', {
            replace: true,
            state: {
              upprMnuUrl: location.pathname,
              mnuId: location.pathname,
              mnuNm: t('ip.grid.설비별 IP List', '설비별 IP List'),
              equipmentGroup: ctx.item.equipmentGroup,
              eqpParaNo: ctx.item.eqpParaNo,
              versionNo: ctx.item.versionNo,
              request: ctx.item.request,
            },
          });
        },
      }),
    },
    {
      binding: 'versionNo',
      header: String(t('ip.grid.버전', '버전')),
      align: 'center',
      width: colWidthMap.versionNo,
    },
    {
      binding: 'eqpParaName',
      header: String(t('ip.grid.설비파라미터명', '설비파라미터명')),
      align: 'left',
      width: colWidthMap.eqpParaName,
    },
    {
      binding: 'paraCnt',
      header: String(t('ip.grid.파라미터 개수', '파라미터 개수')),
      align: 'right',
      width: colWidthMap.paraCnt,
    },
    {
      binding: 'ipPointCnt',
      header: String(t('ip.grid.측정개소수', '측정개소수')),
      align: 'right',
      width: colWidthMap.ipPointCnt,
    },
    {
      binding: 'request',
      header: String(t('com.label.요청', '요청')),
      align: 'center',
      width: colWidthMap.request,
      cssClass: 'WijmoPopup',
      cellTemplate: (params) => {
        if (params.value == null || params.value == '') {
          return '';
        } else {
          return `${params.value} <Button id="request" />`;
        }
      },
    },
    {
      binding: 'changeReasonDesc',
      header: String(t('ip.grid.변경이력사유', '변경이력사유')),
      align: 'left',
      width: colWidthMap.changeReasonDesc,
    },
    {
      binding: 'progStatCd',
      visible: false,
    },
    {
      binding: 'useYn',
      header: String(t('com.label.사용여부', '사용여부')),
      align: 'center',
      width: colWidthMap.useYn,
    },
    {
      binding: 'dataInsDtm',
      header: String(t('com.label.등록일', '등록일')),
      align: 'center',
      width: colWidthMap.dataInsDtm,
    },
    {
      binding: 'empNm',
      header: String(t('com.label.등록자', '등록자')),
      align: 'center',
      width: colWidthMap.empNm,
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('com.label.최종수정일', '최종수정일')),
      align: 'center',
      width: colWidthMap.dataUpdDtm,
    },
    {
      binding: 'updateEmpNm',
      header: String(t('com.label.최종수정자', '최종수정자')),
      align: 'center',
      width: colWidthMap.updateEmpNm,
    },
  ];

  const handleSearch = async (condition: BaseImformationCondition) => {
    const response = await findBaseImformation(condition);
    if (response) {
      setRowData(response.list || []);
      setTotalCount(response.totalCount || 0);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(searchParamData);
    }
  };

  const onInitialized = useEvent((grid) => {
    // new FlexGridFilter(grid);
    setflexRef(grid);
    // grid.rowHeaders.columns.splice(0, 1);
    // CustomGrid내부에 이미 CustomMergeManager가 있어서 그런지 allowMerging 적용이 안되어 새로추가
    grid.mergeManager = new RestrictedMergeManager();

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);

      switch (e.target.id) {
        case 'request':
          setRequestModalCondition({
            aprReqId: item.request,
            eqpParaNo: item.eqpParaNo,
            versionNo: item.versionNo,
          });

          setMode(ManagementMode.READ);
          setOpenParameterManagementReqReadModal(true);
          break;
      }
      grid.startEditing(true);
    });
  });

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>
                  <span>{t('com.label.설비군', '설비군')}</span>
                </label>
                <CustomInputWithSearch
                  name="equipmentGroup"
                  className="find"
                  placeholder={String(
                    t('ip.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.')
                  )}
                  value={searchParamData.equipmentGroup}
                  onChange={handleChange}
                  onSearchClick={() => setOpenEequipmentGroupPopUp(true)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label htmlFor="useYn">{t('com.label.사용여부', '사용여부')}</label>
                <CustomSwitch
                  id="useYn"
                  onValue={'Y'}
                  onLabel={String(t('com.label.사용', '사용'))}
                  offValue={'N'}
                  offLabel={String(t('com.label.미사용', '미사용'))}
                  defaultValue={searchParamData.useYn}
                  onChange={(value) => {
                    setSearchParamData({
                      ...searchParamData,
                      useYn: value,
                    });
                  }}
                />
              </SearchCols>
              <SearchCols>
                <div style={{ display: 'inline-flex', width: '200px', paddingTop: '5px' }}>
                  <label htmlFor="showHistoryYn" style={{ paddingTop: '0px' }}>
                    {t('ip.label.이전버전보기', '이전버전보기')}
                  </label>
                  <Checkbox
                    id="showHistoryYn"
                    name="showHistoryYn"
                    css={st.checkbox}
                    className="custom"
                    checked={searchParamData.showHistoryYn === 'Y'}
                    onClick={(e) => {
                      setSearchParamData({
                        ...searchParamData,
                        showHistoryYn: searchParamData.showHistoryYn === 'Y' ? 'N' : 'Y',
                      });
                    }}
                    disableRipple
                  />
                </div>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            defaultCondition={{ useYn: 'Y', showHistoryYn: 'N' }}
            setReset={setSearchParamData}
            onSearch={() => handleSearch(searchParamData)}
          />
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.IP 기준정보', 'IP 기준정보')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => {
              const id = (rowData || [])
                .reduce(
                  (acc, cur) => [...acc, `${cur.eqpParaNo}||${cur.versionNo}` as string],
                  [] as string[]
                )
                .join();
              downloadExcelTemplatesPost(FileTypeName.TPL_IP_LIST, id);
            }}
            disableRipple
          >
            {t('com.button.템플릿 다운로드', '템플릿 다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleDownloadExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(searchParamData)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isReadOnly={true}
        height={570}
        initialized={onInitialized}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
      />
      {hasAuth && (
        <GlobalBtnGroup>
          <Button
            css={IconButton.button}
            className="create"
            disableRipple
            onClick={(e) => {
              setMode(ManagementMode.CREATE);
              setPageId(ApproveRequestPageType.IP_APPROVE_REQ);
              setOpenParameterManagementModal(true);
              setRequestModalCondition({
                eqpParaNo: null,
                versionNo: null,
                pageDetail: false,
              });
            }}
          >
            {t('com.button.신규', '신규')}
          </Button>
          <Button
            css={IconButton.button}
            className="Excelcreate"
            disableRipple
            onClick={(e) => {
              setMode(ManagementMode.CREATE_EXCEL);
              setPageId(ApproveRequestPageType.IP_APPROVE_REQ);
              setOpenParameterManagementModal(true);
              setRequestModalCondition({
                eqpParaNo: null,
                versionNo: null,
                aprReqId: null,
                pageDetail: false,
              });
            }}
          >
            {t('com.button.엑셀신규', '엑셀신규')}
          </Button>
          <Button
            css={IconButton.button}
            className="edit"
            disableRipple
            onClick={(e) => {
              if (checkedItems && checkedItems.length > 0) {
                if (checkedItems.length > 1) {
                  openMessageBar({
                    type: 'error',
                    content: t(
                      'ip.msg.수정할 대상을 1건만 선택해 주세요.',
                      '수정할 대상을 1건만 선택해 주세요.'
                    ),
                  });
                  return;
                }
                if (checkedItems[0].request !== null) {
                  openMessageBar({
                    type: 'error',
                    content: t('ip.msg.결재 진행중인 설비입니다.', '결재 진행중인 설비입니다.'),
                  });
                  return;
                }
                setRequestModalCondition({
                  eqpParaNo: checkedItems[0].eqpParaNo,
                  versionNo: checkedItems[0].versionNo,
                  pageDetail: false,
                });
                setMode(ManagementMode.MODIFY);
                setPageId(ApproveRequestPageType.IP_UPDATE_REQ);
                setOpenParameterManagementModal(true);
                // createEquipmentParameterRequestByEqpParaNo(
                //   checkedItems[0].eqpParaNo || '',
                //   checkedItems[0].versionNo || '',
                //   ManagementMode.MODIFY
                // ).then((res) => {
                //   if (res.successOrNot === 'Y') {
                //     setRequestModalCondition({
                //       aprReqId: res.data,
                //       defaultEqpParaNo: checkedItems[0].eqpParaNo,
                //     });
                //     setMode(ManagementMode.MODIFY);
                //     setOpenParameterManagementModal(true);
                //   } else {
                //     const data = typeof res?.data === 'string' ? res?.data : null;
                //     openMessageBar({
                //       type: 'error',
                //       content:
                //         data ||
                //         t(
                //           'ip.msg.요청 정보 생성 중 오류가 발생했습니다.',
                //           '요청 정보 생성 중 오류가 발생했습니다.'
                //         ),
                //     });
                //   }
                // });
              } else {
                openMessageBar({
                  type: 'error',
                  content: t('ip.msg.수정할 대상을 선택해 주세요.', '수정할 대상을 선택해 주세요.'),
                });
              }
            }}
          >
            {t('com.button.수정', '수정')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceledit"
            disableRipple
            onClick={(e) => {
              setMode(ManagementMode.MODIFY_EXCEL);
              setPageId(ApproveRequestPageType.IP_UPDATE_REQ);
              setOpenParameterManagementModal(true);
              setRequestModalCondition({
                eqpParaNo: null,
                versionNo: null,
                aprReqId: null,
                pageDetail: false,
              });
            }}
          >
            {t('com.button.엑셀수정', '엑셀수정')}
          </Button>
          <Button
            css={IconButton.button}
            className="delete"
            onClick={() => {
              if (checkedItems && checkedItems.length > 0) {
                if (checkedItems.length > 1) {
                  openMessageBar({
                    type: 'error',
                    content: t(
                      'com.label.삭제할 항목을 1건만 선택해 주세요.',
                      '삭제할 항목을 1건만 선택해 주세요.'
                    ),
                  });
                  return;
                }
                if (checkedItems[0].request !== null) {
                  openMessageBar({
                    type: 'error',
                    content: t('ip.msg.결재 진행중인 설비입니다.', '결재 진행중인 설비입니다.'),
                  });
                  return;
                }
                // createEquipmentParameterRequestByEqpParaNo(
                //   checkedItems[0].eqpParaNo || '',
                //   checkedItems[0].versionNo || '',
                //   ManagementMode.DELETE
                // ).then((res) => {
                //   if (res.successOrNot === 'Y') {
                //     setRequestModalCondition({
                //       aprReqId: res.data,
                //       defaultEqpParaNo: checkedItems[0].eqpParaNo,
                //     });
                //     setMode(ManagementMode.DELETE);
                //     setOpenParameterManagementModal(true);
                //   } else {
                //     const data = typeof res?.data === 'string' ? res?.data : null;
                //     openMessageBar({
                //       type: 'error',
                //       content:
                //         data ||
                //         t(
                //           'com.label.요청 정보 생성 중 오류가 발생했습니다.',
                //           '요청 정보 생성 중 오류가 발생했습니다.'
                //         ),
                //     });
                //   }
                // });
              } else {
                openMessageBar({
                  type: 'error',
                  content: t(
                    'com.label.삭제할 항목을 선택해 주세요.',
                    '삭제할 항목을 선택해 주세요.'
                  ),
                });
                return;
              }
              setRequestModalCondition({
                eqpParaNo: checkedItems[0].eqpParaNo,
                versionNo: checkedItems[0].versionNo,
                pageDetail: false,
              });
              setMode(ManagementMode.DELETE);
              setPageId(ApproveRequestPageType.IP_DELETE_REQ);
              setOpenParameterManagementModal(true);
            }}
            disableRipple
          >
            {t('com.label.삭제', '삭제')}
          </Button>
        </GlobalBtnGroup>
      )}
      {openEquipmentGroupPopUp && (
        <EquipmentGroupPopUp
          open={openEquipmentGroupPopUp}
          condition={{
            equipmentGroup: searchParamData.equipmentGroup || '',
          }}
          close={() => setOpenEequipmentGroupPopUp(false)}
          onCallback={(value) =>
            setSearchParamData({
              ...searchParamData,
              equipmentGroup: (value as EquipmentGroupCondition).equipmentGroup,
            })
          }
        />
      )}
      {/* {isOpenParameterManagementModal && (
        <ParameterManagementModal
          open={isOpenParameterManagementModal}
          mode={mode}
          condition={requestModalCondition}
          close={(isDone = false) => {
            if (isDone) {
              handleSearch(searchParamData);
            }
            setRequestModalCondition(null);
            setOpenParameterManagementModal(false);
          }}
        />
      )} */}
      {isOpenParameterManagementModal && (
        <ApproveRequestModal
          open={isOpenParameterManagementModal}
          pageId={pageId}
          aprReqId={requestModalCondition?.aprReqId}
          condition={{
            aprReqId: requestModalCondition?.aprReqId || null,
            eqpParaNo: requestModalCondition?.eqpParaNo || null,
            versionNo: requestModalCondition?.versionNo || null,
            mode: mode,
            pageDetail: requestModalCondition?.pageDetail || null,
          }}
          //onCallback={(value) => {undefined}}
          close={() => setOpenParameterManagementModal(false)}
        />
      )}
      {isOpenParameterManagementReqReadModal && (
        <ParameterManagementReqReadModal
          open={isOpenParameterManagementReqReadModal}
          aprReqId={requestModalCondition?.aprReqId}
          close={() => setOpenParameterManagementReqReadModal(false)}
        />
      )}
    </>
  );
};

export default BaseImformationPage;

class RestrictedMergeManager extends MergeManager {
  getMergedRange(p, r, c, clip = true) {
    const rng = new CellRange(r, c);

    const pcol = c > 0 ? c - 1 : c;
    const val = p.getCellData(r, c, false);
    const pval = p.getCellData(r, pcol, false);

    while (
      rng.row > 0 &&
      p.getCellData(rng.row - 1, c, false) == val &&
      p.getCellData(rng.row - 1, pcol, false) == pval &&
      c === 1 // 설비군만
    ) {
      rng.row--;
    }

    while (
      rng.row2 < p.rows.length - 1 &&
      p.getCellData(rng.row2 + 1, c, false) == val &&
      p.getCellData(rng.row2 + 1, pcol, false) == pval &&
      c === 1 // 설비군만
    ) {
      rng.row2++;
    }
    if (rng.isSingleCell) {
      return null;
    }
    return rng;
  }
}
