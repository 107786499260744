import { Typography } from '@mui/material';
import { ImageList, ImageListItem } from '@mui/material';
import { FileImgInfo } from 'models/admin/FileImgInfo';
interface FileImageListProps {
  listIndex: string;
  itemTitle: string;
  itemList: FileImgInfo[] | [];
  imageCols?: number;
  imageGaps?: number;
}

export default function CustomFileImageList({
  listIndex,
  itemTitle,
  itemList,
  imageCols,
  imageGaps,
}: FileImageListProps) {
  return (
    <>
      <Typography variant="caption" component="h3" key={`title-${listIndex}`}>
        {itemTitle}
      </Typography>
      <ImageList
        key={`list-${listIndex}`}
        sx={{ width: '100%', margin: '0px 0px 10px 0px' }}
        cols={imageCols}
        gap={imageGaps}
      >
        {itemList.length > 0 &&
          itemList.map((item, index) => (
            <ImageListItem key={`item-${index}`}>
              <img
                key={`img-${index}`}
                srcSet={`${process.env.REACT_APP_API_BASE_URL}/v1/image?id=${encodeURI(
                  item.imgId
                )}`}
                src={`${process.env.REACT_APP_API_BASE_URL}/v1/image?id=${encodeURI(item.imgId)}`}
                loading="lazy"
                style={{ border: '1px solid rgb(62 65 63)' }}
              />
            </ImageListItem>
          ))}
      </ImageList>
    </>
  );
}
