import { create } from 'zustand';
import { MessageBarInitialState, MessageBarType } from 'models/common/MessageBar';

interface MessageBarState {
  type: MessageBarType;
  isOpen: boolean;
  content: string;
  setMessageBarStateWhenModalOpen: (messageBarInitialState: MessageBarInitialState) => void;
  setMessageBarStateWhenModalClose: () => void;
}

export const useMessageBarStore = create<MessageBarState>((set, get) => {
  return {
    type: 'default',
    isOpen: false,
    content: '',
    setMessageBarStateWhenModalOpen: (messageBarInitialState: MessageBarInitialState) => {
      set({
        ...messageBarInitialState,
        isOpen: true,
      });
    },
    setMessageBarStateWhenModalClose: () => {
      set({
        isOpen: false,
        type: 'default',
        content: '',
      });
    },
  };
});
