/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { di, PopupBtnGroup, TitleWrap } from 'components/layouts/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { IconButton } from 'components/buttons/IconSVG';
import Dropzone from 'components/dropzone/Dropzone';
import { downloadAllFiles } from 'apis/admin/FileUpload';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { useLoading } from 'components/process/Loading';
import { CloseBtn } from '../../../components/buttons/CustomButton';
import { BizName, FileUpload as FileConst } from 'models/common/Common';
import { formatBytes } from 'utils/FormatUtil';
import FileConverterView from 'pages/common/components/FileConverterView';
import { FileSaveResponse, FileSaveResult } from '../../../models/admin/FileInfo';

type Props = {
  open: boolean;
  close: () => void;
  initParam: {
    atchFileGrId?: string;
    atchFileTpCd: string;
    optValCtn1: string; // 관련 테이블 명 (확인용)
    bizName?: BizName; // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
  };
  downloadOnly?: boolean; // 다운로드만 가능한 경우 true
  singleSelect?: boolean;
  showAllDownload?: boolean;
  onCallback: (atchFileGrId: string | null, fileCount: number) => void;
  isRequire?: boolean; // 필수여부체크
};
export const FileConverterPopUp = ({
  open,
  close,
  initParam,
  downloadOnly = false,
  singleSelect = false,
  showAllDownload = true,
  onCallback,
  isRequire = false,
}: Props) => {
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const [atchFileTpCdList, setAtchFileTpCdList] = useState<Code[]>([]);
  const [atchFileGrId, setAtchFileGrId] = useState<string>(initParam?.atchFileGrId || '');
  const [atchFileTpCd, setAtchFileTpCd] = useState<string>(initParam?.atchFileTpCd || '');
  const dropzoneRef = useRef<any>();
  const title = useMemo(() => {
    const tp = atchFileTpCdList.find(
      (o) => o.cmnCd !== 'NORMAL' && o.cmnCd === initParam.atchFileTpCd
    );
    const tpNm = tp ? `${tp.cmnCdNm} ` : '';
    if (downloadOnly) {
      return tpNm + t('com.label.다운로드', `다운로드`);
    }
    return tpNm + t('com.label.PPT 변환 샘플', `PPT 변환 샘플`); // `${tpNm}파일 업로드`;
  }, [downloadOnly, atchFileTpCdList]);

  const [dataList, setDataList] = useState([]);
  const [isOpenFileConverterViewModal, setOpenFileConverterViewModal] = useState<boolean>(false);

  useEffect(() => {
    getCommonCodeNames('ATCH_FILE_TP_CD').then((result) => setAtchFileTpCdList(result));
  }, []);

  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const handleConverterFile = async () => {
    if (isBtnDisabled) return;
    dropzoneRef.current.setErrorMsg('');
    openLoading(true);
    setBtnDisabled(true);

    const maxSize = FileConst.maxSize;
    const totalFileSize = dropzoneRef.current.getTotalFileSize();

    if (totalFileSize > maxSize) {
      dropzoneRef.current.setErrorMsg(
        t(
          `com.label.checkfilesize`,
          `첨부파일 크기는 ${formatBytes(maxSize)}를 초과할 수 없습니다.`
        )
      );
      openLoading(false);
      setBtnDisabled(false);
      return;
    }

    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    if (fileSaveResponse.fileSaveResult === FileSaveResult.NONE) {
      isRequire &&
        dropzoneRef.current.setErrorMsg(
          t('com.label.첨부파일을 확인해 주세요.', '첨부파일을 확인해 주세요.')
        );
      openLoading(false);
      setBtnDisabled(false);

      if (isRequire) return;
    } else if (fileSaveResponse.fileSaveResult === FileSaveResult.SUCCESS) {
      setAtchFileGrId(fileSaveResponse.atchFileGrId);

      const dateList = await dropzoneRef.current.convertPPTFiles(fileSaveResponse.atchFileGrId);

      if (dateList && dateList.length > 0) {
        setOpenFileConverterViewModal(true);
        setDataList(dateList);
      }
    }

    setTimeout(() => {
      openLoading(false);
      setBtnDisabled(false);
    }, 500);
  };

  const handleDownload = async () => {
    if (atchFileGrId) {
      await downloadAllFiles(atchFileGrId || '', atchFileTpCd || '');
    }
  };

  return (
    <>
      <Dialog open={open} css={di.dialog} fullWidth className="sm" maxWidth={false}>
        <TitleWrap>
          <h2>{title}</h2>
          <CloseBtn onClick={() => close()} />
        </TitleWrap>
        <DialogContent>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{title}</h3>
            </SubTitleGroup>
            {showAllDownload && (
              <ControlBtnGroup>
                <Button
                  css={IconButton.button}
                  className="download"
                  onClick={handleDownload}
                  disableRipple
                >
                  {t('com.button.전체 다운로드', '전체 다운로드')}
                </Button>
              </ControlBtnGroup>
            )}
          </SubTitleLayout>
          <Dropzone
            ref={dropzoneRef}
            singleSelect={singleSelect}
            downloadOnly={downloadOnly}
            atchFileGrIdInput={initParam?.atchFileGrId}
            atchFileTpCd={initParam?.atchFileTpCd}
            tableName={initParam?.optValCtn1}
            bizName={initParam?.bizName}
          />
        </DialogContent>
        <PopupBtnGroup>
          <Button className="cancel" onClick={() => close()} disableRipple>
            {t('com.button.닫기', '닫기')}
          </Button>
          {!downloadOnly && (
            <Button
              css={IconButton.button}
              className="save"
              onClick={handleConverterFile}
              disableRipple
            >
              {t('com.button.변환', '변환')}
            </Button>
          )}
        </PopupBtnGroup>
      </Dialog>

      {isOpenFileConverterViewModal && (
        <FileConverterView
          open={isOpenFileConverterViewModal}
          close={() => setOpenFileConverterViewModal(false)}
          data={dataList}
        />
      )}
    </>
  );
};
export default FileConverterPopUp;
