/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';

import {
  ProcessBarWrap,
  ProcessStepBox,
  StepBox,
  ProcessStep,
  ProcessNum,
  ProcessText,
  SubStep,
  ProgressBar,
} from 'components/process/Process';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { tabs } from 'components/layouts/Tab';
import { ComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { Code } from 'models/common/CommonCode';
import { UtMatrixRegistSearchCondition, UtMatrixMaster, UtMatrixDetail } from 'models/ut/UtMatrix';
import {
  findMaster,
  findProcess,
  findProcessBar,
  submitCheckRegistMatrix,
  submitUtMatix,
  changeStatUtMatix,
  submitInterlockRegistMatrix,
} from 'apis/ut/UtMatrixRegist';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import UtMatrixContent from './regist/UtMatrixContent';
import MachineSummaryContent from './regist/MachineSummaryContent';
import ElectricSummaryContent from './regist/ElectricSummaryContent';
import StandardUseContent from './regist/StandardUseContent';

interface PageLocationState {
  openMode?: 'UT_MGR' | 'WRITE' | 'READ';
  utmId?: string;
  planProcId?: string;
}

const UtMatrixRegistPage = () => {
  const { t } = useTranslation();
  const matrixRef = useRef<any>();
  const machineRef = useRef<any>();
  const elecRef = useRef<any>();
  const atulCapaRef = useRef<any>();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const [locationState, setLocationState] = useState<PageLocationState>(useLocation().state);
  const conditionRef = useRef<UtMatrixRegistSearchCondition>();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [code, setCode] = useState<any>();
  const [condition, setCondition] = useState<UtMatrixRegistSearchCondition>({
    utmId: searchParams?.get('utmId') || '',
    planProcId: searchParams?.get('planProcId') || '',
    openMode: searchParams?.get('openMode') || '',
    sumBaseCd: 'ALL',
  });
  const [errors, setErrors] = useState<any>({});
  const [isRefresh, setRefresh] = useState<boolean>(false);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [masterData, setMasterData] = useState<UtMatrixMaster[]>([]);
  const [processData, setProcessData] = useState<UtMatrixDetail[]>([]);
  const [utmWkProgStatCd, setUtmWkProgStatCd] = useState<Code[]>([]);
  const [utmWkProgInfo, setUtmWkProgInfo] = useState<UtMatrixDetail>();
  const tabMenus = useMemo(() => {
    return [
      { id: 'UTM', name: t('ut.label.UT Matrix', 'UT Matrix') },
      { id: 'MACH', name: t('ut.label.기계요약', '기계요약') },
      { id: 'ELTR', name: t('ut.label.전기요약', '전기요약') },
      { id: 'CAPA', name: t('ut.label.표준사용량 비교', '표준사용량 비교') },
    ];
  }, []);

  // 검색조건 > UT Matrix 명 (ComboBox 사용을 위해 변환)
  const optionsUtMaster = useMemo(() => {
    return (masterData || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: cur.utmId,
          cmnCdNm: cur.utmNm,
        } as Code,
      ],
      [] as Code[]
    );
  }, [masterData]);

  // 검색조건 > 공정 (ComboBox 사용을 위해 변환)
  const optionsProcess = useMemo(() => {
    return (processData || []).reduce((acc, cur) => {
      if (acc.findIndex(({ cmnCd }) => cmnCd === cur.prdnProcCd) < 0) {
        acc.push({
          cmnCd: cur.prdnProcCd,
          cmnCdNm: cur.prdnProcCdNm,
        });
      }
      return acc;
    }, [] as Code[]);
  }, [processData]);

  // 검색조건 > 세부공정 (ComboBox 사용을 위해 변환)
  const optionsDtlProcess = useMemo(() => {
    if (!condition?.prdnProcCd) return [];
    return (processData || []).reduce((acc, cur) => {
      if (cur.prdnProcCd === condition?.prdnProcCd) {
        if (acc.findIndex(({ cmnCd }) => cmnCd === cur.dtalProcCd) < 0) {
          acc.push({
            cmnCd: cur.dtalProcCd,
            cmnCdNm: cur.dtalProcCdNm,
          });
        }
      }
      return acc;
    }, [] as Code[]);
  }, [processData, condition?.prdnProcCd]);

  const selectedMaster = useMemo(() => {
    if (!condition?.utmId) return null;
    const master = (masterData || []).filter((o) => o.utmId === condition?.utmId);
    return master.length > 0 ? master[0] : null;
  }, [condition?.utmId, masterData]);

  useEffect(() => {
    setCondition((prev) => ({
      utmId: locationState?.utmId || searchParams?.get('utmId') || '',
      planProcId: locationState?.planProcId || searchParams?.get('planProcId') || '',
      openMode: locationState?.openMode || searchParams?.get('openMode') || '',
      sumBaseCd: prev?.sumBaseCd || 'ALL',
    }));
    if (locationState?.utmId && locationState?.planProcId) {
      setRefresh(true);
    }
  }, [locationState?.utmId, locationState?.planProcId]);

  useEffect(() => {
    if (state?.utmId && state?.planProcId) {
      setLocationState(state);
    }
  }, [state]);

  useEffect(() => {
    findMaster().then((result: UtMatrixMaster[]) => {
      if (result !== null) {
        setMasterData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
    getCommonCodes();
  }, []);

  useEffect(() => {
    setCondition((prev) => ({
      ...prev,
      prdnProcCd: '',
      dtalProcCd: '',
    }));
    if (!condition?.utmId) {
      setProcessData([]);
      return;
    }
    searchProcess(condition?.utmId);
  }, [condition?.utmId]);

  const getCommonCodes = async () => {
    const utmWkProgStatCd: Code[] = await getCommonCodeNames('UTM_WK_PROG_STAT_CD');
    const sumBaseCd: Code[] = await getCommonCodeNames('SUM_BASE_CD');

    setUtmWkProgStatCd((utmWkProgStatCd || []).filter((o) => o.cmnCd !== 'UTM06'));
    setCode({
      sumBaseCd: sumBaseCd,
    });
  };

  useEffect(() => {
    if (isRefresh && condition.utmId && condition.prdnProcCd && condition.dtalProcCd) {
      setRefresh(false);
      handleSearch();
    }
  }, [condition.utmId, condition.prdnProcCd, condition.dtalProcCd, isRefresh]);

  const handleSearch = () => {
    setErrors({
      utmId: _.isEmpty(condition.utmId),
      prdnProcCd: _.isEmpty(condition.prdnProcCd),
      dtalProcCd: _.isEmpty(condition.dtalProcCd),
    });

    if (
      _.isEmpty(condition.utmId) ||
      _.isEmpty(condition.prdnProcCd) ||
      _.isEmpty(condition.dtalProcCd)
    ) {
      return;
    }

    let proc = [] as UtMatrixDetail[];
    if (condition?.prdnProcCd && condition?.dtalProcCd) {
      proc = processData.filter(
        (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
      );
    } else if (condition?.planProcId) {
      proc = processData.filter((o) => o.planProcId === condition?.planProcId);
    }
    const selectedProc = proc.length > 0 ? proc[0] : null;
    conditionRef.current = {
      ...condition,
      utmNo: selectedMaster?.utmNo || '',
      bildPlntCd: selectedMaster?.bildPlntCd || '',
      planProcId: selectedProc?.planProcId || '',
      prdnProcCd: selectedProc?.prdnProcCd || '',
      prdnProcCdNm: selectedProc?.prdnProcCdNm || '',
      dtalProcCd: selectedProc?.dtalProcCd || '',
      dtalProcCdNm: selectedProc?.dtalProcCdNm || '',
      utmWrtProcProgStatCd: selectedProc?.utmWrtProcProgStatCd || '',
      utmWrtProcProgStatCdNm: selectedProc?.utmWrtProcProgStatCdNm || '',
    };
    searchProcessBar(condition.utmId);
    searchTabContent();
  };

  useEffect(() => {
    if (conditionRef.current && conditionRef.current?.utmId) {
      searchTabContent();
    }
  }, [currentTabIndex]);

  useEffect(() => {
    if ((processData || []).length) {
      let proc = [] as UtMatrixDetail[];
      if (condition?.prdnProcCd && condition?.dtalProcCd) {
        proc = processData.filter(
          (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
        );
      } else if (condition?.planProcId) {
        proc = processData.filter((o) => o.planProcId === condition?.planProcId);
      }
      if (proc.length) {
        setCondition((prev) => ({
          ...prev,
          utmNo: selectedMaster?.utmNo,
          planProcId: proc[0].planProcId,
          prdnProcCd: proc[0].prdnProcCd,
          prdnProcCdNm: proc[0].prdnProcCdNm,
          dtalProcCd: proc[0].dtalProcCd,
          dtalProcCdNm: proc[0].dtalProcCdNm,
          utmWrtProcProgStatCd: proc[0].utmWrtProcProgStatCd,
          utmWrtProcProgStatCdNm: proc[0].utmWrtProcProgStatCdNm,
        }));
      }
    }
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd, condition?.planProcId]);

  const processSteps = useMemo(() => {
    let currentStepNo = -1;
    return (utmWkProgStatCd || []).reduce((acc, cur, idx) => {
      const isNow = (utmWkProgInfo || {}).utmWkProgStatCd === cur.cmnCd;
      if (isNow) currentStepNo = idx;
      return [
        ...acc,
        {
          ...cur,
          cmnCdDesc: isNow
            ? 'now'
            : Object.keys(utmWkProgInfo || {}).length > 0 && currentStepNo < 0
            ? 'done'
            : '', // or 'disable'
        },
      ];
    }, [] as Code[]);
  }, [utmWkProgStatCd, utmWkProgInfo]);

  const searchProcessBar = (utmId?: string) => {
    if (!utmId) return;
    findProcessBar(utmId).then((result) => {
      setUtmWkProgInfo(result);
    });
  };

  const searchProcess = (utmId?: string) => {
    return new Promise((resolve) => {
      if (!utmId) {
        resolve(true);
        return;
      }
      findProcess(utmId)
        .then((result: UtMatrixDetail[]) => {
          if (result !== null) {
            setProcessData(result);
          } else {
            openMessageBar({
              type: 'error',
              content: t(
                'ut.label.공정 정보 조회 중 오류가 발생했습니다.',
                '공정 정보 조회 중 오류가 발생했습니다.'
              ),
            });
          }
        })
        .finally(() => resolve(true));
    });
  };

  const searchTabContent = () => {
    if ((tabMenus || []).length) {
      if (tabMenus[currentTabIndex].id === 'UTM') {
        matrixRef.current?.searchRegistMatrix(conditionRef.current);
      } else if (tabMenus[currentTabIndex].id === 'MACH') {
        machineRef.current?.searchMachineSummary(conditionRef.current);
      } else if (tabMenus[currentTabIndex].id === 'ELTR') {
        elecRef.current?.searchElectricSummary(conditionRef.current);
      } else if (tabMenus[currentTabIndex].id === 'CAPA') {
        atulCapaRef.current?.searchStandardUse(conditionRef.current);
      }
    }
  };

  const handleSubmit = async () => {
    if (!conditionRef.current?.utmId || !conditionRef.current?.planProcId) return;
    const interlock = await submitInterlockRegistMatrix(
      conditionRef.current?.utmId,
      conditionRef.current?.planProcId
    );
    if (interlock.successOrNot === 'Y' && interlock.data === 'OK') {
      submitCheckRegistMatrix(conditionRef.current?.utmId, conditionRef.current?.planProcId).then(
        (result) => {
          if (result) {
            openCommonModal({
              modalType: 'confirm',
              content: result,
              size: 'md',
              yesButtonText: String(t('ut.label.제출', '제출')),
              yesCallback: () => {
                submitUtMatrix();
              },
            });
          } else {
            submitUtMatrix();
          }
        }
      );
    } else {
      openCommonModal({
        modalType: 'alert',
        size: interlock.data !== 'NG' ? 'md' : 'xs',
        content:
          interlock.data !== 'NG'
            ? String(interlock.data)
            : t(
                'ut.label.Ut Matrix 내용을 정확히 입력해 주세요.',
                'Ut Matrix 내용을 정확히 입력해 주세요.'
              ),
      });
    }
  };

  const submitUtMatrix = () => {
    if (!conditionRef.current?.utmId) return;
    submitUtMatix(conditionRef.current).then((res) => {
      if (res?.successOrNot === 'Y') {
        searchProcess(conditionRef.current?.utmId).then(() => handleSearch());
      }
      openMessageBar({
        type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          res?.successOrNot === 'Y'
            ? t('ut.label.제출되었습니다.', '제출되었습니다.')
            : t('ut.label.제출 중 오류가 발생했습니다.', '제출 중 오류가 발생했습니다.'),
      });
    });
  };

  const handleModify = () => {
    if (!conditionRef.current?.utmId) return;

    changeStatUtMatix({ ...conditionRef.current, utmWrtProcProgStatCd: 'UTP02' }).then((res) => {
      if (res?.successOrNot === 'Y') {
        searchProcess(conditionRef.current?.utmId).then(() => handleSearch());
      }
      openMessageBar({
        type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          res?.successOrNot === 'Y'
            ? t('ut.label.작성중 상태로 변경되었습니다.', '작성중 상태로 변경되었습니다.')
            : t('ut.label.수정 중 오류가 발생했습니다.', '수정 중 오류가 발생했습니다.'),
      });
    });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols className="width25">
                <label>
                  <span className="dot">{t('ut.label.UT Matrix 명', 'UT Matrix 명')}</span>
                </label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={optionsUtMaster}
                  defaultValue={condition?.utmId}
                  onChange={(value) => {
                    setCondition((prev) => ({
                      utmId: value,
                      openMode: prev?.openMode || '',
                      sumBaseCd: prev?.sumBaseCd || 'ALL',
                    }));
                    setErrors((prev) => ({
                      ...prev,
                      utmId: false,
                    }));
                  }}
                  isError={errors?.utmId}
                  msgError={String(
                    t('ut.label.UT Matrix 명을 선택해 주세요.', 'UT Matrix 명을 선택해 주세요.')
                  )}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>{t('ut.label.UT Matrix 번호', 'UT Matrix 번호')}</label>
                <CustomInputWithSearch
                  name="utmNo"
                  placeholder={String(
                    t('ut.label.UT Matrix 명을 선택해 주세요.', 'UT Matrix 명을 선택해 주세요.')
                  )}
                  value={selectedMaster?.utmNo || ''}
                  readOnly={true}
                  isWrap={false}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>{t('ut.label.공장', '공장')}</label>
                <CustomInputWithSearch
                  name="bildPlntCdNm"
                  placeholder={String(
                    t('ut.label.UT Matrix 명을 선택해 주세요.', 'UT Matrix 명을 선택해 주세요.')
                  )}
                  value={selectedMaster?.bildPlntCdNm || ''}
                  readOnly={true}
                  isWrap={false}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>{t('ut.label.목적', '목적')}</label>
                <CustomInputWithSearch
                  name="askPurpCdNm"
                  placeholder={String(
                    t('ut.label.UT Matrix 명을 선택해 주세요.', 'UT Matrix 명을 선택해 주세요.')
                  )}
                  value={selectedMaster?.askPurpCdNm || ''}
                  readOnly={true}
                  isWrap={false}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols className="width25">
                <label>
                  <span className="dot">{t('ut.label.공정', '공정')}</span>
                </label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={optionsProcess}
                  defaultValue={condition?.prdnProcCd}
                  onChange={(value) => {
                    setCondition({
                      ...condition,
                      prdnProcCd: value,
                    });
                    setErrors((prev) => ({
                      ...prev,
                      prdnProcCd: false,
                    }));
                  }}
                  isError={errors?.prdnProcCd}
                  msgError={String(t('ut.label.공정을 선택해 주세요.', '공정을 선택해 주세요.'))}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>
                  <span className="dot">{t('ut.label.세부공정', '세부공정')}</span>
                </label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={optionsDtlProcess}
                  defaultValue={condition?.dtalProcCd}
                  onChange={(value) => {
                    setCondition({
                      ...condition,
                      dtalProcCd: value,
                    });
                    setErrors((prev) => ({
                      ...prev,
                      dtalProcCd: false,
                    }));
                    setRefresh(true);
                  }}
                  isError={errors?.dtalProcCd}
                  msgError={String(
                    t('ut.label.세부공정을 선택해 주세요.', '세부공정을 선택해 주세요.')
                  )}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>{t('ut.label.버전', '버전')}</label>
                <div className="widthHalf width3-1">
                  <CustomInputWithSearch
                    name="verNo"
                    placeholder={String(
                      t('ut.label.UT Matrix 명을 선택해 주세요.', 'UT Matrix 명을 선택해 주세요.')
                    )}
                    value={selectedMaster?.verNo || ''}
                    readOnly={true}
                    isWrap={false}
                  />
                  <label>{t('com.label.상태', '상태')}</label>
                  <CustomInputWithSearch
                    name="utmWrtProcProgStatCdNm"
                    placeholder={String(
                      t('ut.label.세부공정을 선택해 주세요.', '세부공정을 선택해 주세요.')
                    )}
                    value={condition?.utmWrtProcProgStatCdNm || ''}
                    readOnly={true}
                    isWrap={false}
                  />
                </div>
              </SearchCols>
              <SearchCols className="width25">
                {tabMenus[currentTabIndex] && tabMenus[currentTabIndex].id === 'UTM' ? (
                  <>
                    {/*Tab이 UT Matrix일 경우*/}
                    <label>{t('ut.label.제출요청일', '제출요청일')}</label>
                    <CustomInputWithSearch
                      name="dataInsDtm"
                      placeholder={String(
                        t('ut.label.UT Matrix 명을 선택해 주세요.', 'UT Matrix 명을 선택해 주세요.')
                      )}
                      value={selectedMaster?.dataInsDtm || ''}
                      readOnly={true}
                      isWrap={false}
                    />
                  </>
                ) : (
                  <>
                    {/*Tab이 기계요약/ 전기요약일 경우*/}
                    <label>{t('ut.label.집계기준', '집계기준')}</label>
                    <ComboBox
                      options={code?.sumBaseCd || []}
                      defaultValue={condition?.sumBaseCd}
                      isWrap={false}
                      onChange={(value) => {
                        setCondition({
                          ...condition,
                          sumBaseCd: value,
                        });
                      }}
                    />
                  </>
                )}
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons setReset={setCondition} onSearch={handleSearch} />
        </SearchBoxRow>
      </SearchBox>

      <ProcessBarWrap className="mt24">
        {(processSteps || []).map((o, i) => (
          <ProcessStepBox key={o.cmnCd} className={o.cmnCdDesc}>
            <StepBox>
              <ProcessStep>
                <ProcessNum>{i + 1}</ProcessNum>
                <ProcessText>{o.cmnCdNm}</ProcessText>
                {o.cmnCd === 'UTM02' && o.cmnCd === utmWkProgInfo?.utmWkProgStatCd ? (
                  <SubStep className="subPer">
                    <li>
                      <strong>{utmWkProgInfo?.procPer || 0}%</strong>
                      <span>{t('ut.label.진행', '진행')}</span>
                      <div>
                        <span
                          style={{
                            width: `${utmWkProgInfo?.procPer || 0}%`,
                          }}
                        ></span>
                      </div>
                    </li>
                    <li>
                      (<em>{utmWkProgInfo.cmplCnt}</em>/{utmWkProgInfo.totalCnt})
                    </li>
                    <li>
                      <span>{t('ut.label.지연', '지연')}</span> {utmWkProgInfo.delayCnt}
                    </li>
                  </SubStep>
                ) : null}
              </ProcessStep>
            </StepBox>
          </ProcessStepBox>
        ))}
        <ProgressBar></ProgressBar>
      </ProcessBarWrap>

      <div css={tabs.wrap} style={{ marginTop: '24px' }}>
        <Tabs
          value={currentTabIndex}
          onChange={(e, newValue) => {
            // 이전 탭이 Ut Matrix인 경우 자동 저장 후 탭변경
            if (currentTabIndex === 0) {
              matrixRef.current?.saveRegistMatrix().finally(() => {
                setCurrentTabIndex(newValue);
              });
            } else {
              setCurrentTabIndex(newValue);
            }
          }}
          css={tabs.tab}
        >
          {tabMenus.map((o, idx) => (
            <Tab
              key={o.id}
              label={o.name}
              id={`ut-tab-${idx}`}
              aria-controls={`ut-tabpanel-${idx}`}
            />
          ))}
        </Tabs>
      </div>
      <TabPanel value={currentTabIndex} index={currentTabIndex}>
        {currentTabIndex === 0 ? (
          <UtMatrixContent
            ref={matrixRef}
            // isReadOnly={isReadOnly}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={() => {
              matrixRef.current?.saveRegistMatrix(false).then(() => {
                handleSubmit();
              });
            }}
            onModify={handleModify}
          />
        ) : currentTabIndex === 1 ? (
          <MachineSummaryContent
            ref={machineRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmit}
            onModify={handleModify}
          />
        ) : currentTabIndex === 2 ? (
          <ElectricSummaryContent
            ref={elecRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmit}
            onModify={handleModify}
          />
        ) : (
          <StandardUseContent
            ref={atulCapaRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmit}
            onModify={handleModify}
          />
        )}
      </TabPanel>
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`ut-tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default UtMatrixRegistPage;
