import { Crud } from 'models/common/Edit';

export enum EqpLvCd {
  MAIN = 'M',
  MACHINE = 'C',
  UNIT = 'U',
}

export enum ReqChgTpCd {
  CREATE = 'C',
  UPDATE = 'U',
  DELETE = 'D',
}

export interface EquipmentClassificationMasterResult {
  eqpClsfNo?: string;
  eqpClsfNm?: string;
  eqclId?: string;
  eqpMdpNm?: string;
  mpClsfCd?: string;
  atchFileGrId?: string;
  useYn?: string;
  optValCtn1?: string;
  optValCtn2?: string;
  optValCtn3?: string;
  optValCtn4?: string;
  optValCtn5?: string;
}

export interface EquipmentClassificationCondition {
  eqclId?: string;
  eqpLvCd?: string;
  mpClsfCd?: string;
  eqpClsfNo?: string;
  eqpClsfNm?: string;
  upprEqpClsfStrcNo?: string;
  useYn?: string;
}

export interface EquipmentClassificationMaster extends Crud {
  reqChgTpCd?: ReqChgTpCd;
  eqpClsfNo?: string;
  eqpClsfNm?: string;
  eqclId?: string;
  eqpMdpNm?: string;
  mpClsfCd?: string;
  eqpGenCd?: string;
  eqpGenNm?: string;
  atchFileGrId?: string;
  useYn?: string;
  optValCtn1?: string;
  optValCtn2?: string;
  optValCtn3?: string;
  optValCtn4?: string;
  optValCtn5?: string;
  detailList?: EquipmentClassificationDetail[];
}

export interface EquipmentClassificationDetail extends Crud {
  eqpClsfNo?: string;
  eqpClsfStrcNo?: string;
  eqpLv?: string;
  eqpLvNm?: string;
  eqpLvCd?: string;
  eqpClsfStrcNm?: string;
  upprEqpClsfStrcNo?: string;
  sortOrd?: string;
  atchFileGrId?: string;
  useYn?: string;
  eqpMdpNm?: string;
  optValCtn1?: string;
  optValCtn2?: string;
  optValCtn3?: string;
  optValCtn4?: string;
  optValCtn5?: string;
  dataInsUserNm: string;
  dataInsDtm: string;
  dataUpdUserNm: string;
  dataUpdDtm: string;
  machines?: EquipmentClassificationMachineDetail[];
}
export interface EquipmentClassificationMachineDetail extends EquipmentClassificationDetail {
  eqpClsfNo?: string;
  units?: EquipmentClassificationUnitDetail[];
}
export interface EquipmentClassificationUnitDetail extends EquipmentClassificationDetail {
  eqpClsfNo?: string;
}

/**
 * 결재 요청 정보
 */
export interface EquipmentApprovalRequest {
  castType?: string; // 서버측 결재요청상세vo > bizReqData cast 타입
  aprReqId: string; // 결재ID
  //reqTp?: string; // 요청유형
  reqRsn?: string; // 요청사유
  mode: string;
  requestMasterList?: EquipmentClassificationMaster[];
}
