/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import _ from 'lodash';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
  SearchButtonWrap,
  SearchBtn,
} from 'components/layouts/SearchBox';
import { UseToggle } from 'components/inputs/CustomInput';
import { SubTitleLayout, SubTitleGroup, ControlBtnGroup } from 'components/layouts/ContentLayout';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { findEquipmentGroup } from 'apis/common/popup/EquipmentGroup';
import { IconButton } from 'components/buttons/IconSVG';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';

type PageType = 'mp';

type Props = {
  open: boolean;
  close: () => void;
  type?: PageType;
  condition?: {
    equipmentGroup: string;
  };
  onCallback: (item: EquipmentGroupCondition | EquipmentGroupCondition[]) => void;
  singleSelect?: boolean;
};

export const EquipmentGroupPopUp = ({
  open,
  close,
  type,
  condition,
  onCallback,
  singleSelect = true,
}: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [searchEquGroupData, setSearchEquGroupData] = useState<EquipmentGroupCondition>({
    equipmentGroup: condition?.equipmentGroup,
    useYn: 'Y',
    eqpClsfYn: type === 'mp' ? 'Y' : 'N',
  });
  const [rowData, setRowData] = useState<EquipmentGroupCondition[]>([]);
  const [checkedItems, setCheckedItems] = useState<EquipmentGroupCondition[]>([]);
  const [colWidthMap, setColWidthMap] = useState<any>({
    qselect: 39,
    equipmentGroup: 120,
    useYn: 120,
    equipmentGroupName: '*',
  });

  useEffect(() => {
    if (!_.isEmpty(condition?.equipmentGroup)) {
      searchBtn(searchEquGroupData);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setSearchEquGroupData({ ...searchEquGroupData, [e.target.name]: e.target.value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchBtn(searchEquGroupData);
    }
  };

  const handleConfirm = () => {
    if ((checkedItems || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.'),
      });
      return;
    }
    onCallback(singleSelect && checkedItems.length > 0 ? checkedItems[0] : checkedItems);
    close();
  };

  const searchBtn = async (condition: EquipmentGroupCondition) => {
    const response = await findEquipmentGroup(condition);
    if (response) {
      const rowData = response || [];
      setRowData(rowData);
    }
  };

  const handleButtonClick = (params) => {
    onCallback(!_.isNull(params.data) ? params.data : null);
    close();
  };

  const handleResetCondition = () => {
    setSearchEquGroupData(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: cur === 'useYn' ? 'Y' : '' }),
          {}
        ) as EquipmentGroupCondition
    );
  };

  const layoutDefinition = [
    {
      cssClass: 'WijmoRelay',
      cellTemplate: '<Button id="qSelect"/>',
      width: colWidthMap.qselect,
    },
    {
      header: String(t('com.label.설비군', '설비군')),
      binding: 'equipmentGroup',
      width: colWidthMap.equipmentGroup,
    },
    {
      header: String(t('ip.grid.설비군명', '설비군명')),
      binding: 'equipmentGroupName',
      align: 'left',
      width: colWidthMap.equipmentGroupName,
    },
    {
      header: String(t('ip.grid.사용여부', '사용여부')),
      binding: 'useYn',
      width: colWidthMap.useYn,
    },
  ];

  const onInitialized = (grid) => {
    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('dblclick', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return;

      const item = grid.rows[ht.row].dataItem;

      handleButtonClick({ data: item });
    });

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return;

      const item = grid.rows[ht.row].dataItem;

      switch (e.target.id) {
        case 'qSelect':
          handleButtonClick({ data: item });
          break;
      }
      grid.refresh();
    });
  };

  const dialogButtons = [
    <Button key={'select'} css={IconButton.button} className="select" onClick={handleConfirm}>
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <>
      <CustomDialog
        title={t('ip.label.설비군 조회', '설비군 조회')}
        open={open}
        onClose={close}
        onCancel={close}
        buttons={dialogButtons}
      >
        <SearchBox>
          <SearchBoxRow>
            <InputBox>
              <SearchRows className="threeCol">
                <SearchCols>
                  <label>{t('com.label.설비군', '설비군')}</label>
                  <CustomInputWithSearch
                    type="text"
                    name="equipmentGroup"
                    placeholder={String(
                      t('ip.msg.설비군을 입력해 주세요.', '설비군을 입력해 주세요.')
                    )}
                    value={searchEquGroupData.equipmentGroup}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
                <SearchCols>
                  <label>{t('ip.label.설비군명', '설비군명')}</label>
                  <CustomInputWithSearch
                    type="text"
                    name="equipmentGroupName"
                    placeholder={String(
                      t('ip.msg.설비군명을 입력해 주세요.', '설비군명을 입력해 주세요.')
                    )}
                    value={searchEquGroupData.equipmentGroupName}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
                <SearchCols>
                  <label>{t('com.label.사용여부', '사용여부')}</label>
                  <UseToggle className="switch">
                    <input
                      type="checkbox"
                      name="useYn"
                      value={searchEquGroupData.useYn}
                      checked={searchEquGroupData.useYn === 'Y'}
                      onChange={() => {
                        setSearchEquGroupData({
                          ...searchEquGroupData,
                          useYn: searchEquGroupData.useYn === 'Y' ? 'N' : 'Y',
                        });
                      }}
                    />
                    <span className="slider"></span>
                    <span
                      className="labels"
                      data-on={t('com.label.사용', '사용')}
                      data-off={t('com.label.미사용', '미사용')}
                    ></span>
                  </UseToggle>
                </SearchCols>
              </SearchRows>
            </InputBox>
            <SearchButtonWrap>
              <Button
                css={IconButton.button}
                className="reload"
                onClick={handleResetCondition}
                disableRipple
              ></Button>
              <SearchBtn
                css={IconButton.button}
                className="find"
                onClick={() => searchBtn(searchEquGroupData)}
                disableRipple
              >
                {t('com.button.조회', '조회')}
              </SearchBtn>
            </SearchButtonWrap>
          </SearchBoxRow>
        </SearchBox>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('com.label.설비군', '설비군')}</h3>
            <span className="total">
              {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
              {t('com.label.건', '건')}
            </span>
          </SubTitleGroup>
          <ControlBtnGroup>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={() => searchBtn(searchEquGroupData)}
              disableRipple
            >
              {t('com.button.새로고침', '새로고침')}
            </Button>
          </ControlBtnGroup>
        </SubTitleLayout>
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={270}
          align={'center'}
          rowSelection={singleSelect ? 'single' : 'multiple'}
          allowPinning={false}
          isFilter={false}
          isReadOnly={true}
          autoCheck={true}
          initialized={onInitialized}
          onChangeCheckedItem={(items) => setCheckedItems(items)}
        />
      </CustomDialog>
    </>
  );
};
