/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { BlueButton } from 'components/buttons/CustomButton';
// import { CustomInputText } from 'components/inputs/CustomInput';
// import ApproverManagementModal from 'components/modals/approve/ApproverManagementModal';
// import ApproveModal from 'components/modals/common/ApproveModal';
// import CommonCLOBModal from 'components/modals/common/CommonCLOBModal';
// import CommonContentModal from 'components/modals/common/CommonContentModal';
import { useCommonModal } from 'hooks/useCommonModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RequestSample = () => {
  const navigate = useNavigate();
  const [approverManagementModalOpen, setApproverManagementModalOpen] = useState(false);
  const [selectedApprover, setSelectedApprover] = useState<any[]>([]);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [commonContentModalOpen, setCommonContentModalOpen] = useState(false);
  const [commonCLOBModalOpen, setCommonCLOBModalOpen] = useState(false);
  const { openCommonModal } = useCommonModal();
  const [ctrcFatnCnsnId, setCtrcFatnCnsnId] = useState<string>('CR23052401');
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContainer style={{ minHeight: '200px', display: 'grid', height: 'fit-content' }}>
        <BlueButton
          onClick={() =>
            navigate('/approves/approve-management-page', {
              state: { ctrcFatnCnsnId: ctrcFatnCnsnId },
            })
          }
        >
          결재 리스트(예정)
        </BlueButton>

        <BlueButton
          onClick={() =>
            navigate('/approves/approve-request-page', {
              state: { ctrcFatnCnsnId: ctrcFatnCnsnId },
            })
          }
        >
          신규 결재 생성
        </BlueButton>
      </CardContainer>

      {/* <CardContainer style={{ minHeight: '200px', display: 'grid', height: 'fit-content' }}>
        <CustomInputText
          value={ctrcFatnCnsnId}
          onChange={(e) => setCtrcFatnCnsnId(e.target.value)}
        />
        <BlueButton
          onClick={() =>
            navigate('/approves/ContractSignApproveDetailPage', {
              state: { ctrcFatnCnsnId: ctrcFatnCnsnId },
            })
          }
        >
          계약체결품의 상세조회
        </BlueButton>
      </CardContainer>

      <CardContainer>
        <BlueButton
          onClick={() => {
            setApproverManagementModalOpen(true);
          }}
        >
          결재/통보자 지정
        </BlueButton>
        <ApproverManagementModal
          open={approverManagementModalOpen}
          approverData={selectedApprover}
          close={() => setApproverManagementModalOpen(false)}
          callback={(approver) => {
            setSelectedApprover(approver);
            setApproverManagementModalOpen(false);
          }}
          aprBtnParam={{
            P0: true,
            P1: true,
            P6: true,
            P7: true,
            P8: true,
            PC: true,
          }}
          title="결재자 지정"
        />
        선택한 사용자 <br />
        {selectedApprover.map((item, index) => (
          <p key={index}>
            {item.empNm}, {item.status}
          </p>
        ))}
        <br />
        <br />
        <BlueButton
          onClick={() => {
            setCommonContentModalOpen(true);
          }}
        >
          내용입력 팝업
        </BlueButton>
        <BlueButton
          onClick={() => {
            setApproveModalOpen(true);
          }}
        >
          결재승인 팝업
        </BlueButton>
        <BlueButton
          onClick={() => {
            setCommonCLOBModalOpen(true);
          }}
        >
          내용입력 팝업(CLOB)
        </BlueButton>
        <CommonContentModal
          open={commonContentModalOpen}
          title={'test popup'}
          close={() => setCommonContentModalOpen(false)}
          callback={(msg) => {
            setCommonContentModalOpen(false);
            openCommonModal({ content: 'message: ' + msg });
          }}
        />
        <ApproveModal
          open={approveModalOpen}
          title={'test popup'}
          approvalType={'0'}
          close={() => setApproveModalOpen(false)}
          approveCallback={(msg) => {
            setApproveModalOpen(false);
            openCommonModal({ content: 'APPROVE: ' + msg });
          }}
          rejectCallback={(msg) => {
            setApproveModalOpen(false);
            openCommonModal({ content: 'REJECT: ' + msg });
          }}
        />
        <CommonCLOBModal
          open={commonCLOBModalOpen}
          title={'test popup'}
          close={() => setCommonCLOBModalOpen(false)}
          callback={(msg) => {
            setCommonCLOBModalOpen(false);
            openCommonModal({ content: 'message: ' + msg });
          }}
        />
      </CardContainer> */}
    </div>
  );
};
export default RequestSample;

const CardContainer = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
  padding: 20px;
  align-content: space-around;
  hr {
    width: 100%;
  }
  justify-items: start;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
