/** @jsxImportSource @emotion/react */
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useState } from 'react';
import { CrudCode } from 'models/common/Edit';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import 'ui/css/multiSelect.css';

import { GuideBlock } from 'pages/sample/guide/GuideComponent';

const SampleWijmo = () => {
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [isOpenElmEmp, setOpenElmEmp] = useState<boolean>(false);
  const [rowData, setRowData] = useState([
    { crudKey: CrudCode.READ, id: 1, name: '박문수', address: '대한민국', point: 'Y' },
    { crudKey: CrudCode.READ, id: 2, name: '홍길동', address: '미국', point: 'Y' },
    { crudKey: CrudCode.READ, id: 3, name: '일지매', address: '일본', point: 'N' },
    { crudKey: CrudCode.READ, id: 4, name: '이몽룡', address: '중국', point: 'N' },
    { crudKey: CrudCode.READ, id: 5, name: '성춘향', address: '에스토니아', point: 'Y' },
  ]);

  const LayoutDefinition = () => {
    return [
      {
        binding: 'id',
        align: 'left',
        width: 50,
      },
      {
        header: '개인정보',
        align: 'center',
        columns: [
          {
            binding: 'name',
            header: '이름',
            width: 150,
          },
          {
            binding: 'address',
            header: '주소',
            width: 150,
          },
        ],
      },
      {
        binding: 'point',
        header: '체크박스',
        align: 'center',
        width: 150,
        cssClass: 'WijmoCheck',
        cellTemplate: (context) => {
          const checked = context.value === 'Y' ? 'checked' : '';
          return (
            '<input\
            type="checkbox"' +
            checked +
            '/>'
          );
        },
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) return;
    const row = s.rows[e.row].dataItem;
    setSelectedRow(row);
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setFlexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //볼일 보기 위해 노크
    grid.hostElement.addEventListener('click', (e) => {
      const ht = grid.hitTest(e);
      if (!ht.panel?.cellType || ht.panel?.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      setHitTest(ht);
      setFlexItem(item);

      switch (col.binding) {
        case 'point':
          grid.setCellData(ht.row, ht.col, e.target.checked ? 'Y' : 'N');
          break;
      }
    });
  };

  return (
    <div>
      <GuideBlock title={'Import'} isCode={true}>
        {`
import { CrudCode } from 'models/common/Edit';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import 'ui/css/multiSelect.css';
`}
      </GuideBlock>
      <GuideBlock title={'Layout'} isCode={true}>
        {`
const [rowData, setRowData] = useState([    //샘플 데이터
  { crudKey: CrudCode.READ, id: 1, name: '박문수', address: '대한민국', point: 'Y' },
  { crudKey: CrudCode.READ, id: 2, name: '홍길동', address: '미국', point: 'Y' },
  { crudKey: CrudCode.READ, id: 3, name: '일지매', address: '일본', point: 'N' },
  { crudKey: CrudCode.READ, id: 4, name: '이몽룡', address: '중국', point: 'N' },
  { crudKey: CrudCode.READ, id: 5, name: '성춘향', address: '에스토니아', point: 'Y' },
]);

const LayoutDefinition = () => {
  return [
    {
      binding: 'id',
      align: 'left',
      width: 50,
    },
    {
      header: '개인정보',
      align: 'center',
      columns: [
        {
          binding: 'name',
          header: '이름',
          width: 150,
         },
        {
          binding: 'address',
          header: '주소',
          width: 150,
        },
      ],
    },
    {
      binding: 'point',
      header: '체크박스',
      align: 'center',
      width: 150,
      cellTemplate: (context) => {
        const checked = context.value === 'Y' ? 'checked' : '';
        return (
          '<input\
          type="checkbox"\
          className="customStringCheckbox"' +
          checked +
          '/>'
        );
      },
    },
  ];
};

const state = {
  itemsSource: rowData,
  layoutDefinition: LayoutDefinition(),
};
`}
      </GuideBlock>
      <GuideBlock title={'Method'} isCode={true}>
        {`
const onFlexGridSelectionChanged = (s, e) => {
  if (s.rows == 0) return;
  const row = s.rows[e.row].dataItem;
};

const onInitialized = (grid) => {
  const ht = grid.hitTest(e);
      if (!ht.panel?.cellType || ht.panel?.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      setHitTest(ht);
      setFlexItem(item);

      switch (col.binding) {
        case 'point':
          grid.setCellData(ht.row, ht.col, e.target.checked ? 'Y' : 'N');
          break;
      }
  });
};

`}
      </GuideBlock>
      <GuideBlock title={'Render'} isCode={true}>
        {`
<ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
  <FlexGrid
    columns={state.layoutDefinition}
    itemsSource={state.itemsSource}
    showMarquee={true}
    autoGenerateColumns={false}
    stickyHeaders={true}
    isReadOnly={true}
    formatItem={flexGridTooltip}
    selectionChanged={onFlexGridSelectionChanged}
    initialized={onInitialized}
    style={{ height: '300px' }}
  />
</ContentGrid>
`}
      </GuideBlock>

      <GuideBlock title={'Example'} isCode={true}>
        <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
          <FlexGrid
            columns={state.layoutDefinition}
            itemsSource={state.itemsSource}
            showMarquee={true}
            autoGenerateColumns={false}
            stickyHeaders={true}
            isReadOnly={true}
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: '300px' }}
          />
        </ContentGrid>
      </GuideBlock>
      <GuideBlock title={'Selected Row Data'} isCode={true}>
        <pre>{JSON.stringify(selectedRow, null, 2)}</pre>
      </GuideBlock>
    </div>
  );
};
export default SampleWijmo;
