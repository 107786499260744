import {
  MpItemCondition,
  MpItem,
  MpItemRequestCondition,
  MpItemApprovalRequest,
} from 'models/mp/MpItem';
import { PaginationResponse } from 'models/common/Pagination';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

/**
 * MP 요청 목록 조회
 * @param condition
 */
export const findMpReqList = async (condition?: MpItemRequestCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/mp-request`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<MpItem[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MpItem[];
};

/**
 * MP 결재요청 저장
 * @param approvalRequest
 */
export const createMpRequest = async (approvalRequest?: MpItemApprovalRequest) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/mp-request`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: approvalRequest,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * MP 결재요청 삭제
 * @param approvalRequest
 */
export const deleteMpRequest = async (approvalRequest?: MpItemApprovalRequest) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/mp-request/delete`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: approvalRequest,
  };
  const response: CommonResponse = await callApi(request);
  return response;
};
