import { BaseImformationCondition } from 'models/ip/BaseImformation';
import { PaginationResponse } from 'models/common/Pagination';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { ExcelDownloadRequest } from 'models/common/Excel';

/**IP기준정보 목록 조회 */
export const findBaseImformation = async (condition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/BaseImfo`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<PaginationResponse<BaseImformationCondition>> = await callApi(
    request
  );

  return (
    response.successOrNot === 'Y' ? response.data : null
  ) as PaginationResponse<BaseImformationCondition>;
};

/**IP기준정보 엑셀 다운로드 */
export const getBaseImfoDownload = async (
  condition: ExcelDownloadRequest<BaseImformationCondition>
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `v1/ip/baseImfoDownload`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) {
    downloadFile(response.data, `${condition.fileName}`); // 'data.xlsx' or `${condition.fileName}`
  }
};

export const downloadFile = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
