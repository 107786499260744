import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { SearchParam } from 'pages/gtng/GatingRegistPage';
import { GatingProgressSummary } from 'models/gtng/GatingProgressSummary';
import { GatingProgressStatus } from 'models/gtng/GatingProgressStatus';

export const findGatingProgressMst = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingProgressMst`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<GatingProgressSummary[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingProgressSummary[];
};

export const findGatingProgressStatus = async (searchParam: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingProgressStatus`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<GatingProgressStatus[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingProgressStatus[];
};
