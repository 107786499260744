/** @jsxImportSource @emotion/react */
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Button, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  close: () => void;
  data: any;
};
const FileConverterView = ({ open, close, data }: Props) => {
  const { t } = useTranslation();

  const handleClose = () => {
    close();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: '1214px!important',
            height: '2000px!important',
          },
        }}
      >
        <DialogTitle className="popupTitle">
          {t('admin.label.파일변환결과', '파일변환결과')}
          <Button className="buttonClose" onClick={handleClose} />
        </DialogTitle>
        <DialogContent className="popupContent">
          <ImageList sx={{ width: '100%', height: '100%' }} cols={4} gap={3}>
            {data.map((item, index) => (
              <ImageListItem key={index}>
                <img
                  srcSet={`${process.env.REACT_APP_API_BASE_URL}/v1/image?path=${encodeURI(item)}`}
                  src={`
                    ${process.env.REACT_APP_API_BASE_URL}/v1/image?path=${encodeURI(item)}
                  `}
                  loading="lazy"
                  style={{ border: '1px solid rgb(62 65 63)', width: 465, maxHeight: 200 }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default FileConverterView;
