/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import { GuideBlock } from '../GuideComponent';
import { Code } from 'models/common/CommonCode';
import Alert from '@mui/material/Alert';

const SelectGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <>
      <GuideBlock title={'<ComboBox> Import'} isCode={true}>
        {`import { ComboBox } from 'components/selects/ComboBox';`}
      </GuideBlock>
      <GuideBlock title={'<ComboBox> 속성'} isCode={true}>
        {`
interface ComboBoxProps {
  options: Code[]; // 옵션 목록 (cf. {cmnCd: 코드, cmnCdNm: 코드명})
  placeholder?: string;  // 값이 없는 경우 노출되는 문구 (ex. 선택)
  defaultValue?: string; // 기본값
  className?: string; // <SelectBox>에 추가되는 클래스명
  readOnly?: boolean;
  disabled?: boolean;
  isError?: boolean; // 에러여부
  msgError?: string; // 에러문구
  // isWrap가 true인 경우에는 <AllSelectWrap>에 추가, false인 경우 <SelectBox>에 추가
  style?: {
    width?: string;
    display?: string;
  };
  isWrap?: boolean; // <AllSelectWrap> 추가 여부 (default: true)
  onChange: (value: string) => void; // 변경 이벤트 (변경된 값을 반환)
}
              `}
      </GuideBlock>
      <Alert severity="warning">
        공통코드를 조회해 노출하는경우가 많아 Code 타입을 그대로 사용, 다른 객체인 경우 타입에 맞춰
        변환 필요
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>ComboBox - Basic</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <ComboBox
                options={
                  [
                    { cmnCd: 'A', cmnCdNm: '에이' },
                    { cmnCd: 'B', cmnCdNm: '비' },
                  ] as Code[]
                }
                placeholder={'선택'}
                defaultValue={''}
                onChange={(value) => console.log(value)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<ComboBox
  options={
    [
      { cmnCd: 'A', cmnCdNm: '에이' },
      { cmnCd: 'B', cmnCdNm: '비' },
    ] as Code[]
  }
  placeholder={'선택'}
  defaultValue={''} // 변수로 변경해서 사용
  onChange={(value) => console.log(value)}
/>
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>ComboBox - Error</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <ComboBox
                options={
                  [
                    { cmnCd: 'A', cmnCdNm: '에이' },
                    { cmnCd: 'B', cmnCdNm: '비' },
                  ] as Code[]
                }
                placeholder={'선택'}
                defaultValue={''}
                isError={true}
                msgError={'오류 메세지가 표시됩니다.'}
                onChange={(value) => console.log(value)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<ComboBox
  options={
    [
      { cmnCd: 'A', cmnCdNm: '에이' },
      { cmnCd: 'B', cmnCdNm: '비' },
    ] as Code[]
  }
  placeholder={'선택'}
  defaultValue={''} // 변수로 변경해서 사용
  isError={true} 
  msgError={'오류 메세지가 표시됩니다.'}
  onChange={(value) => console.log(value)}
/>
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary>ComboBox - AllSelectWrap 없는 경우</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <ComboBox
                options={
                  [
                    { cmnCd: 'A', cmnCdNm: '에이' },
                    { cmnCd: 'B', cmnCdNm: '비' },
                  ] as Code[]
                }
                placeholder={'선택'}
                defaultValue={''}
                isWrap={false}
                onChange={(value) => console.log(value)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<ComboBox
  options={
    [
      { cmnCd: 'A', cmnCdNm: '에이' },
      { cmnCd: 'B', cmnCdNm: '비' },
    ] as Code[]
  }
  placeholder={'선택'}
  defaultValue={''} // 변수로 변경해서 사용
  isWrap={false}
  onChange={(value) => console.log(value)}
/>
`}
            </GuideBlock>
            <Alert severity="warning">
              {'<AllSelectWrap>'}가 없는 경우 isWrap 속성값을 false로 설정
              <br />
              <br />
              속성값 중 style은 isWrap 속성값이 true인 경우에는 {'<AllSelectWrap>'}에 추가되고,
              <br />
              false인 경우 {'<SelectBox>'}에 추가됨
            </Alert>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>

      <br />
      <GuideBlock title={'<MultiComboBox> Import'} isCode={true}>
        {`import { MultiComboBox } from 'components/selects/ComboBox';`}
      </GuideBlock>
      <GuideBlock title={'<MultiComboBox> 속성'} isCode={true}>
        {`
interface MultiComboBoxProps {
  id: string;
  options: Code[]; // 옵션 목록 (cf. {cmnCd: 코드, cmnCdNm: 코드명})
  placeholder?: string; // 값이 없는 경우 노출될 문구
  defaultValue?: string[]; // 기본값
  uniqueValue?: string; // 유니크값 (특정 값은 단독으로 선택되는 경우 (ex. MP 분류 중 UTILITY는 단독으로 선택가능))
  readOnly?: boolean;
  disabled?: boolean;
  isError?: boolean; // 에러여부
  msgError?: string; // 에러문구
  onChange: (values: string[]) => void; // 변경 이벤트 (선택된 값 목록 반환)
}
              `}
      </GuideBlock>
      <Alert severity="warning">
        공통코드를 조회해 노출하는경우가 많아 Code 타입을 그대로 사용, 다른 객체인 경우 타입에 맞춰
        변환 필요
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary>MultiComboBox - Basic</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <MultiComboBox
                id="test"
                options={
                  [
                    { cmnCd: 'A', cmnCdNm: '에이' },
                    { cmnCd: 'B', cmnCdNm: '비' },
                  ] as Code[]
                }
                defaultValue={['A', 'B']}
                placeholder={'선택해 주세요.'}
                onChange={(values: string[]) => console.log(values)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>{`
<MultiComboBox
  id="test"
  options={
    [
      { cmnCd: 'A', cmnCdNm: '에이' },
      { cmnCd: 'B', cmnCdNm: '비' },
    ] as Code[]
  }
  defaultValue={['A', 'B']}
  placeholder={'선택해 주세요.'}
  onChange={(values: string[]) => console.log(values)}
/>
            `}</GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary>MultiComboBox - UniqueValue</AccordionSummary>
          <AccordionDetails>
            <Alert severity="info">
              옵션 값 중 특정 값은 멀티가 아닌 단독으로 선택해야하는 경우 uniqueValue 사용
              <br />
              아래 예시는 옵션 값 중 `비 (B)` 값을 선택하는 경우 다른 선택됐던 값들은 체크해제됨
            </Alert>
            <GuideBlock>
              <MultiComboBox
                id="test"
                options={
                  [
                    { cmnCd: 'A', cmnCdNm: '에이' },
                    { cmnCd: 'B', cmnCdNm: '비' },
                    { cmnCd: 'C', cmnCdNm: '씨' },
                    { cmnCd: 'D', cmnCdNm: '디' },
                  ] as Code[]
                }
                defaultValue={['A', 'D']}
                uniqueValue={'B'}
                placeholder={'선택해 주세요.'}
                onChange={(values: string[]) => console.log(values)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>{`
<MultiComboBox
  id="test"
  options={
    [
      { cmnCd: 'A', cmnCdNm: '에이' },
      { cmnCd: 'B', cmnCdNm: '비' },
      { cmnCd: 'C', cmnCdNm: '씨' },
      { cmnCd: 'D', cmnCdNm: '디' },
    ] as Code[]
  }
  defaultValue={['A', 'D']}
  uniqueValue={'B'} // 유니크값 
  placeholder={'선택해 주세요.'} 
  onChange={(values: string[]) => console.log(values)}
/>
            `}</GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary>MultiComboBox - Error</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <MultiComboBox
                id="test"
                options={
                  [
                    { cmnCd: 'A', cmnCdNm: '에이' },
                    { cmnCd: 'B', cmnCdNm: '비' },
                    { cmnCd: 'C', cmnCdNm: '씨' },
                    { cmnCd: 'D', cmnCdNm: '디' },
                  ] as Code[]
                }
                defaultValue={['A', 'D']}
                uniqueValue={'B'}
                placeholder={'선택해 주세요.'}
                isError={true}
                msgError={'오류 메세지가 표시됩니다.'}
                onChange={(values: string[]) => console.log(values)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>{`
<MultiComboBox
  id="test"
  options={
    [
      { cmnCd: 'A', cmnCdNm: '에이' },
      { cmnCd: 'B', cmnCdNm: '비' },
      { cmnCd: 'C', cmnCdNm: '씨' },
      { cmnCd: 'D', cmnCdNm: '디' },
    ] as Code[]
  }
  defaultValue={['A', 'D']}
  uniqueValue={'B'}
  placeholder={'선택해 주세요.'}
  isError={true}
  msgError={'오류 메세지가 표시됩니다.'}
  onChange={(values: string[]) => console.log(values)}
/>
            `}</GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default SelectGuide;
