/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import _, { toNumber } from 'lodash';
import dayjs from 'dayjs';
import { CrudCode } from 'models/common/Edit';
import { CommonYN } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { MyBookmarkMst } from 'models/admin/MyBookmark';
import { getMyBookmarkMst, saveMyBookmarkMst } from 'apis/admin/MyBookmark';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import CustomGrid from 'components/grids/CustomGrid';
import { SuccessOrNot } from 'models/common/RestApi';

interface Props {
  sBmkGrDesc?: string;
  onChangeDetail?: (bmkGrId: string, bmkGrDesc: string) => void;
}
const MyBookmarkGrid = (props: Props, ref) => {
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<any[]>([]);
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();

  useImperativeHandle(ref, () => ({
    search: (bmkGrDesc: string) => {
      searchMyBookmarkMst(bmkGrDesc);
    },
  }));

  useEffect(() => {
    searchMyBookmarkMst();
  }, []);

  const btnAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
      bmkGrDesc: '',
      bmkAtcTpCd: 'MP_LIST',
    } as MyBookmarkMst;
    setRowData([newRow, ...rowData]);
  };

  const btnDelRow = () => {
    let idx = 0;
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if ((selectedRows || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.삭제할 항목을 1건 이상 선택해 주세요.',
          '삭제할 항목을 1건 이상 선택해 주세요.'
        ),
      });
      return;
    }
    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item] && toNumber(rowData[item].optValCtn1) > 0) {
        idx++;
      } else if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });
    if (idx > 0) {
      openMessageBar({
        type: 'error',
        content: t(
          'admin.msg.등록된 상세항목이 있는 관심항목은 삭제 할 수 없습니다.',
          '등록된 상세항목이 있는 관심항목은 삭제 할 수 없습니다.'
        ),
      });
      return;
    }

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  };

  const searchMyBookmarkMst = (searchBmkGrDesc?: string) => {
    getMyBookmarkMst(searchBmkGrDesc || props.sBmkGrDesc || '').then((result) => {
      setRowData(result);
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      align: 'center',
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('com.label.상태', '상태')),
      binding: 'crudKey',
      width: 43,
      align: 'center',
      isReadOnly: true,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'bmkGrId',
      visible: false,
    },
    {
      header: String(t('admin.grid.관심항목', '관심항목')),
      binding: 'bmkGrDesc',
      minWidth: 300,
      width: '*',
    },
    {
      header: String(t('admin.grid.상세보기', '상세보기')),
      binding: 'dataOccurSerialNo',
      width: 100,
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: (params) =>
        params.item['crudKey'] === CrudCode.CREATE || params.item['crudKey'] === CrudCode.DELETE
          ? ''
          : `<Button id="btnDetail"></Button>`,
    },
    {
      header: String(t('admin.grid.등록갯수', '등록갯수')),
      align: 'right',
      binding: 'optValCtn1',
      width: 120,
      isReadOnly: true,
    },
    {
      header: String(t('com.label.수정일', '수정일')),
      align: 'center',
      binding: 'dataUpdDtm',
      width: 180,
      isReadOnly: true,
      cellTemplate: (params) => dayjs(params.value).format('YYYY.MM.DD') || '',
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnDetail':
            if (item.crudKey != CrudCode.CREATE && item.crudKey != CrudCode.DELETE) {
              props.onChangeDetail && props.onChangeDetail(item.bmkGrId, item.bmkGrDesc);
            }
            break;
        }
      }
    });
  };

  const handleSave = () => {
    const saveData = rowData.filter((o) => o.crudKey && o.crudKey !== CrudCode.READ);
    if (!saveData || saveData.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }
    const saveDataChk = rowData.filter(
      (o) => o.crudKey !== CrudCode.READ && o.crudKey !== CrudCode.DELETE && o.crudKey !== null
    );

    const valid = saveDataChk
      .map((o, index) => {
        if (_.isEmpty(o.bmkGrDesc))
          return `${index + 1} : ${t(
            'admin.msg.관심항목을 입력해 주세요.',
            '관심항목을 입력해 주세요.'
          )}\n`;
      })
      .filter((element) => element !== undefined);

    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        saveMyBookmarkMst(saveData)
          .then((res) => {
            if (res.successOrNot == SuccessOrNot.Y) {
              openMessageBar({
                type: 'confirm',
                content: t('com.label.저장되었습니다.', '저장되었습니다.'),
              });
              searchMyBookmarkMst();
            } else {
              openMessageBar({
                type: 'error',
                content:
                  res.data && typeof res.data === 'string'
                    ? res.data
                    : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
              });
            }
          })
          .catch((err) =>
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            })
          );
      },
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.나의 관심항목 List', '나의 관심항목 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="addRow" disableRipple onClick={btnAddRow}>
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button css={IconButton.button} className="delRow" disableRipple onClick={btnDelRow}>
            {t('com.button.행삭제', '행삭제')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={200}
        allowPinning={false}
        excludeFilter={['dataOccurSerialNo']}
        initialized={onInitialized}
      />
      <GlobalBtnGroup>
        <Button css={IconButton.button} className="save" disableRipple onClick={handleSave}>
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>
    </>
  );
};
export default React.forwardRef(MyBookmarkGrid);
