import { callApi } from 'utils/ApiUtil';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { GatingInfo } from 'models/gtng/GatingInfo';
import { GatingEquipment } from 'models/gtng/GatingEquipment';
import { GatingReqType } from 'pages/gtng/gatingcheckresult/GatingChangeRequest';

/**
 * 게이팅 마스터 정보 조회
 * @param gatingId
 */
export const findGatingInfo = async (gatingId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/gtng/${gatingId}`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<GatingInfo> = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

/**
 * 게이팅 장비 목록 조회
 * @param gatingId
 * @param equipmentId
 * @param aprReqId
 */
export const findGatingEquipments = async (gatingId: string, equipmentId = '', aprReqId = '') => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/gtng/${gatingId}/equipments`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      gatingId: gatingId,
      equipmentId: equipmentId,
      aprReqId: aprReqId,
    }),
  };
  const response: CommonResponse<GatingEquipment[]> = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

/**
 * 게이팅 변경 요청 가능여부 확인 ex. Gating 생성/조회
 * @param gatingId
 * @param reqTp
 */
export const checkAvailableRequest = async (gatingId: string, reqTp: GatingReqType) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/gtng/${gatingId}/check-request`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId, reqTp: reqTp }),
  };
  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 게이팅 변경 요청 가능여부 확인 ex. Gating 평가/채점
 * @param gatingId
 * @param equipmentId
 * @param reqTp
 */
export const checkAvailableRequestEquipment = async (
  gatingId: string,
  equipmentId: string,
  reqTp: GatingReqType
) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/gtng/${gatingId}/check-request-equipment`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      gatingId: gatingId,
      equipmentId: equipmentId,
      reqTp: reqTp,
    }),
  };
  const response: CommonResponse = await callApi(request);
  return response;
};
