/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState } from 'react';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import CustomDatepicker from 'components/inputs/CustomDatepicker';

import { GuideBlock } from '../GuideComponent';

const DatepickerGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <>
      <GuideBlock title={'<CustomDatepicker> Import'} isCode={true}>
        {`import CustomDatepicker from 'components/inputs/CustomDatepicker';`}
      </GuideBlock>
      <GuideBlock title={'<CustomDatepicker> 속성'} isCode={true}>
        {`
// 참고용) DateType (Datepicker에서 제공하는 타입)
type DateType = string | null | Date;

// 참고용) DateRangeType (Datepicker에서 제공하는 타입)
type DateRangeType = {
    startDate: DateType;
    endDate: DateType;
};

// 참고용) DateValueType (Datepicker에서 제공하는 타입)
type DateValueType = DateRangeType | null;

interface Props {
  isRange?: boolean; // 기간선택여부 (true: 기간선택(default), false: 날짜선택)
  isError?: boolean; // 에러여부
  msgError?: string; // 에러메세지
  value?: DateType | DateRangeType; // 기본값
  // Datepicker 기본 속성
  minDate?: Date; // 최소날짜
  maxDate?: Date; // 최대날짜
  disabledDates?: DateRangeType[]; // 사용할 수 없는 날짜
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (newValue: DateType | DateRangeType) => void; // 기간인 경우 DateRangeType, 날짜인 경우 DateType으로 반환
}
        `}
      </GuideBlock>
      <Alert severity="warning">
        {'<DatePickerWrap>'} 영역부터 {'<CustomDatepicker>'}로 대체함
        <br />
        기간선택인 경우 달력 2개가 표시되고, 날짜선택인 경우 달력 1개만 표시
        <br />
        onChange {'>'} 기간 선택인 경우 DateRangeType, 날짜 선택인 경우 DateType으로 반환하고, 반환
        날짜포맷은 사용자 언어 설정에 따름
        <br />
        ex.
        <br />
        기간 선택 - {`{ startDate: '2024.01.16', endDate: '2024.01.18' }`} or null
        <br />
        날짜 선택 - {`'2024.01.16'`} or null
        <br />
        <br />
        날짜포맷은 사용자 설정 정보에 따라 설정됨
        <br />
        cf. UX 가이드
        <br />
        {`{YYYY.MM.DD}`} - 대한민국, 중국, 일본, 대만
        <br />
        {`{MM.DD.YYYY}`} - 미국
        <br />
        {`{DD.MM.YYYY}`} - 폴란드, 독일, 베트남, 인도네시아 등
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>CustomDatepicker - 기간 선택</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomDatepicker
                isRange={true} // 기간선택인 경우 생략가능
                value={{ startDate: dayjs().add(-2, 'day').toDate(), endDate: dayjs().toDate() }} // 기본값 (date)
                // value={{ startDate: '2024.01.16', endDate: '2024.01.19' }} // 기본값 (string)
                minDate={dayjs().add(-7, 'day').toDate()} // 최소날짜
                maxDate={dayjs().add(14, 'day').toDate()} // 최대날짜
                onChange={(newValue) => console.log(newValue)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomDatepicker
  isRange={true} // 기간선택인 경우 생략가능
  value={{ startDate: dayjs().add(-2, 'day').toDate(), endDate: dayjs().toDate() }} // 기본값 (date)
  // value={{ startDate: '2024.01.16', endDate: '2024.01.19' }} // 기본값 (string)
  minDate={dayjs().add(-7, 'day').toDate()} // 최소날짜
  maxDate={dayjs().add(14, 'day').toDate()} // 최대날짜
  onChange={(newValue) => console.log(newValue)}
/>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>CustomDatepicker - 날짜 선택</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomDatepicker
                isRange={false} // 기간선택여부
                value={dayjs().add(3, 'day').toDate()} // 기본값 (date)
                // value={'2024.01.20'} // 기본값 (string)
                minDate={dayjs().add(-7, 'day').toDate()} // 최소날짜
                maxDate={dayjs().add(14, 'day').toDate()} // 최대날짜
                onChange={(newValue) => console.log(newValue)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomDatepicker
  isRange={false} // 기간선택여부
  value={dayjs().add(3, 'day').toDate()} // 기본값 (date)
  // value={'2024.01.20'} // 기본값 (string)
  minDate={dayjs().add(-7, 'day').toDate()} // 최소날짜
  maxDate={dayjs().add(14, 'day').toDate()} // 최대날짜
  onChange={(newValue) => console.log(newValue)}
/>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary>CustomDatepicker - Error</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomDatepicker
                isError={true} // 에러여부
                msgError={'오류 메세지가 표시됩니다.'} // 에러메세지
                value={null} // 기본값
                onChange={(newValue) => console.log(newValue)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomDatepicker
  isError={true} // 에러여부
  msgError={'오류 메세지가 표시됩니다.'} // 에러메세지
  value={null} // 기본값
  onChange={(newValue) => console.log(newValue)}
/>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default DatepickerGuide;
