import { BbsReplyRequest } from 'models/admin/BbsReply';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const createReply = async (bbsReplyRequest: BbsReplyRequest) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/bbs/reply`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: bbsReplyRequest,
  };
  const response: CommonResponse = await callApi(request);

  return response.successOrNot === 'Y' ? true : false;
};

export const removeReply = async (bbmNo: string, bbmReNo: string) => {
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `/v1/bbs/reply/${bbmNo}/${bbmReNo}`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse = await callApi(request);

  return response.successOrNot === 'Y' ? true : false;
};
