/** @jsxImportSource @emotion/react */
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { CellType } from '@grapecity/wijmo.grid';
import _ from 'lodash';
import dayjs from 'dayjs';
import { findMpTarget, setMpTarget } from 'apis/mp/MpTarget';
import { MpTargetCondition, MpTargetLineCondition } from 'models/mp/MpTarget';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { CrudCode } from 'models/common/Edit';
import { MpTargetFactoryPopUp } from 'pages/mp/popup/MpTargetFactoryPopUp';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { getExcelFileName } from 'utils/ExcelUtil';
import { downloadExcelResult } from 'apis/mp/MpTarget';
import CustomGrid from 'components/grids/CustomGrid';
import { Employee } from 'models/admin/Employee';
import UserModal from 'components/modals/common/UserModal';

interface MpTargetMasterGridProps {
  setErrors: any;
  condition: MpTargetCondition;
  callback?: (item: MpTargetLineCondition) => void;
  error?: any;
}

const MpTargetMasterGrid = ({ setErrors, callback, condition }: MpTargetMasterGridProps, ref) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<any[]>([]);
  const [isOpenMpTargetFactoryPopUp, setOpenMpTargetFactoryPopUp] = useState<boolean>(false);
  const [userModalCondition, setUserModalCondition] = useState<any>({});
  const [isOpenUserModal, setOpenUserModal] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    search: async (condition: MpTargetCondition) => {
      await handleSearch(condition);
    },
    save: () => {
      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
        yesCallback: () => {
          // 변경된 데이터
          const params = (rowData || []).filter((o) => o.crudKey !== null);
          setMpTarget(params).then((response) => {
            handleSearch(condition);
          });
        },
      });
    },
  }));

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);

    if ((selectedRows || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element);
    setRowData(filteredData);
  };

  const handleSearch = async (condition: MpTargetCondition) => {
    if (_.isEmpty(condition.mpClsfType)) {
      setRowData([]);
      setErrors({ mpClsfType: true });
      return;
    } else {
      setErrors({});
    }

    const response = await findMpTarget(condition);
    setRowData(response || []);
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('com.label.상태', '상태')),
      binding: 'crudKey',
      width: 40,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'idnNo',
      header: 'idnNo',
      visible: false,
    },
    {
      binding: 'mpClsfNm',
      width: 100,
      header: String(t('mp.grid.MP 분류', 'MP 분류')),
    },
    {
      binding: 'mpClsf',
      width: 100,
      header: String(t('mp.grid.MP 분류', 'MP 분류')),
      visible: false,
    },
    {
      binding: 'copCd',
      width: 110,
      header: String(t('mp.grid.법인', '법인')),
    },
    {
      binding: 'factoryNm',
      width: 210,
      align: 'left',
      header: String(t('mp.grid.공장(동)', '공장(동)')),
      cellTemplate: (params) => {
        if (params.item.crudKey === CrudCode.CREATE) {
          return `${params.item.factory} : ${params.value}`;
        }
        return params.value;
      },
    },
    {
      binding: 'factory',
      visible: false,
    },
    {
      binding: 'eqpGrNm',
      header: String(t('com.label.설비군', '설비군')),
      width: 250,
      align: 'left',
      cellTemplate: (params) => {
        if (params.item.crudKey === CrudCode.CREATE) {
          return `${params.item.eqpGr} : ${params.value}`;
        }
        return params.value;
      },
    },
    {
      binding: 'eqpGr',
      visible: false,
    },
    {
      header: String(t('mp.grid.수평전개', '수평전개')),
      columns: [
        {
          binding: 'heYn',
          header: String(t('mp.grid.대상', '대상')),
          width: 80,
          cssClass: 'WijmoCheck',
          cellTemplate: (params) => {
            const checked = params.value === 'Y' ? 'checked' : '';
            return `<div class="checkbox">
                  <input type="checkbox" id="heYnChk_${params.item.execPlanId}" ${checked} />
                  <label />
                </div>
          `;
          },
        },
        {
          binding: 'heMgr',
          visible: false,
        },
        {
          binding: 'heMgrNm',
          header: String(t('mp.grid.담당자명', '담당자명')),
          cssClass: 'WijmoFind',
          align: 'left',
          minWidth: 200,
          width: '*',
          cellTemplate: (params) => `
            <span>${params.value || ''}</span>
            <Button /> 
          `,
        },
      ],
    },
    {
      binding: 'crdUser',
      width: 110,
      header: String(t('com.label.등록자', '등록자')),
    },
    {
      binding: 'dataInsDtm',
      width: 130,
      header: String(t('com.label.등록일', '등록일')),
      cellTemplate: (params) => {
        if (params.value) {
          return dayjs(params.value).format('YYYY.MM.DD');
        }
        return params.value;
      },
    },
    {
      binding: 'updUser',
      width: 110,
      header: String(t('com.label.수정자', '수정자')),
    },
    {
      binding: 'dataUpdDtm',
      width: 130,
      header: String(t('com.label.수정일', '수정일')),
      cellTemplate: (params) => {
        if (params.value) {
          return dayjs(params.value).format('YYYY.MM.DD');
        }
        return params.value;
      },
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        // 담당자 editable css 적용
        if ('heMgrNm' === binding) {
          cell.ariaReadOnly = false;
        }
      }
    };

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return;

      const item = grid.rows[ht.row].dataItem;
      const binding = grid.columns[ht.col].binding;

      callback &&
        callback({
          idnNo: item.idnNo,
          copCd: item.copCd,
          plntCd: item.factory,
          factoryNm: item.factoryNm,
        } as MpTargetLineCondition);

      // 대상 클릭
      if ('heYn' === binding && e.target.id?.startsWith('heYn')) {
        item.heYn = e.target.checked ? 'Y' : 'N';
        if (item.crudKey !== CrudCode.CREATE) {
          item.crudKey = CrudCode.UPDATE;
        }
        gridRef.current?.refresh();
      }
      // 담당자 클릭
      else if ('heMgrNm' === binding) {
        setUserModalCondition({
          defaultUserId: item.heMgr,
          target: {
            row: ht.row,
            col: ht.col,
          },
        });
        setOpenUserModal(true);
      }
    });
  };

  const handleDownloadExcel = () => {
    const excelData: ExcelDownloadRequest<MpTargetCondition> = {
      fileName: getExcelFileName(
        t('mp.label.수평전개 대상/담당자설정', '수평전개 대상/담당자설정')
      ),
      sheetName: t('mp.label.담당자설정', '담당자설정'),
      header: [
        String(t('mp.grid.MP 분류', 'MP 분류')),
        String(t('mp.grid.법인', '법인')),
        String(t('mp.grid.공장(동)', '공장(동)')),
        String(t('com.label.설비군', '설비군')),
        String(t('mp.grid.수평전개 대상', '수평전개 대상')),
        String(t('mp.grid.수평전개 담당자명', '수평전개 담당자명')),
        String(t('mp.grid.설비변경점 대상', '설비변경점 대상')),
        String(t('mp.grid.설비변경점 담당자명', '설비변경점 담당자명')),
        String(t('mp.grid.신규개선 대상', '신규개선 대상')),
        String(t('com.label.등록자', '등록자')),
        String(t('com.label.수정자', '수정자')),
        String(t('com.label.수정일', '수정일')),
      ],
      searchCondition: {
        ...condition,
      },
    };
    downloadExcelResult(excelData);
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.MP 대상 공장/설비군', 'MP 대상 공장/설비군')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="create"
            disableRipple
            onClick={() => {
              setOpenMpTargetFactoryPopUp(true);
            }}
          >
            {t('com.button.신규', '신규')}
          </Button>
          <Button css={IconButton.button} className="minus" disableRipple onClick={handleDelRow}>
            {t('com.button.행삭제', '행삭제')}
          </Button>
          <Button css={IconButton.button} className="Exceldown" onClick={handleDownloadExcel}>
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(condition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>

      <CustomGrid
        rowData={rowData}
        layoutDefinition={layoutDefinition}
        height={250}
        align={'center'}
        allowPinning={false}
        isReadOnly={true}
        initialized={onInitialized}
      />

      {isOpenMpTargetFactoryPopUp && (
        <MpTargetFactoryPopUp
          open={isOpenMpTargetFactoryPopUp}
          close={() => setOpenMpTargetFactoryPopUp(false)}
          condition={condition}
          rowData={rowData}
          setRowData={setRowData}
        />
      )}
      {isOpenUserModal && (
        <UserModal
          open={isOpenUserModal}
          close={() => setOpenUserModal(false)}
          title={t('mp.label.담당자 조회', '담당자 조회')}
          defaultUserId={userModalCondition?.defaultUserId}
          singleSelect={false}
          onCallback={(result) => {
            const target = userModalCondition?.target;
            if (target && target?.row > -1) {
              const users = (result || []) as Employee[];
              const item = gridRef.current?.rows[target.row].dataItem;
              item.heMgrNm = users.map((o) => o.empNm).join(',');
              item.heMgr = users.map((o) => o.userId).join(',');
              if (item.crudKey !== CrudCode.CREATE) {
                item.crudKey = CrudCode.UPDATE;
              }
              gridRef.current?.collectionView.refresh();
            }
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(MpTargetMasterGrid);
