import { css } from '@emotion/react';
import { ButtonDesign, ButtonSize, ButtonStyle } from 'components/buttons/ButtonStyle';
import { CommonBG, CommonBorder, CommonText, ControlLinedBorder } from 'ui/theme/Color';

export const tabs = {
  tab: css`
    min-height: 0px !important;
    height: 36px !important;
    overflow: visible !important;
    margin-bottom: 16px;
    .MuiTabs-scroller {
      overflow: visible !important;
    }
    .MuiTabs-scroller {
      min-height: 0px !important;
      height: 36px !important;
      border-bottom: 1px solid ${CommonBorder.Strong};
    }
    .MuiButtonBase-root {
      font-size: 13px;
      color: ${CommonText.Light};
      font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', sans-serif;
      min-width: 0px !important;
      min-height: 0px !important;
      height: 36px !important;
      padding: 8px 12px;
      border: 1px solid ${CommonBorder.Strong};
      background: ${CommonBG.Deeper};
      & + .MuiButtonBase-root {
        border-left: none;
      }
      &:hover {
        background: ${CommonBG.Basic};
        color: ${CommonText.Primary} !important;
      }
      &:disabled {
        color: ${CommonText.Lighter};
        pointer-events: none;
      }
    }
    .Mui-selected {
      background: ${CommonBG.Basic};
      color: ${CommonText.Primary} !important;
    }
    .MuiTabs-indicator {
      z-index: 999;
      bottom: -2px !important;
      height: 2px !important;
      background: ${CommonBorder.inverse} !important;
    }
  `,
  subtab: css`
    min-height: 0px !important;
    height: 32px !important;
    margin-bottom: 16px;
    .MuiButtonBase-root {
      font-size: 13px;
      color: ${CommonText.Light};
      font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', sans-serif;
      min-width: 0px !important;
      min-height: 0px !important;
      height: 32px !important;
      padding: 6px 10px;
      & + .MuiButtonBase-root {
        margin-left: 8px;
      }
      &:hover {
        color: ${CommonText.Primary};
      }
      &:disabled {
        color: ${CommonText.Lighter};
        pointer-events: none;
      }
    }
    .Mui-selected {
      color: ${CommonText.Primary} !important;
    }
    .MuiTabs-indicator {
      background: ${ControlLinedBorder.PrimaryActive};
    }
  `,
  wrap: css`
    position: relative;
    margin-bottom: 16px;
  `,
  btn: css`
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    button {
      ${ButtonSize.sm}
      ${ButtonDesign.IconLfSM};
      ${ButtonStyle.outlineNormal}
    }
    button + button {
      margin-left: 4px;
    }
  `,
};
