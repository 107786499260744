import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const getUtMatrixUsageData = async (searchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getUtMatrixUsageData`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<any> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as any;
};
