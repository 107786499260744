import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import 'index.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import 'i18n';
import { worker } from 'mocks/worker';
import { setLicenseKey } from '@grapecity/wijmo';

setLicenseKey(`${process.env.REACT_APP_WIJMO_KEY}`);
if (process.env.REACT_APP_MSW_ENABLE === 'Y') {
  worker.start({ onUnhandledRequest: 'bypass' });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <CookiesProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </CookiesProvider>
);

reportWebVitals();
