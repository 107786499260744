/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import CustomSwitch from 'components/selects/CustomSwitch';
import { ControlBtnGroup } from 'components/layouts/ContentLayout';
import { GuideBlock } from '../GuideComponent';

const SwitchGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <>
      <GuideBlock title={'<CustomSwitch> Import'} isCode={true}>
        {`import CustomSwitch from 'components/selects/CustomSwitch';`}
      </GuideBlock>
      <GuideBlock title={'<CustomSwitch> 속성'} isCode={true}>
        {`
interface Props {
  id: string;
  name?: string; // default: id
  onValue: string; // 스위치 on 값
  onLabel?: string; // 스위치 on 문구
  offValue: string; // 스위치 off 값
  offLabel?: string; // 스위치 off 문구
  defaultValue?: string; // 기본값 (default: onValue)
  isSmall?: boolean; // small switch 여부 (default: false)
  onChange: (value: string) => void; // 변경이벤트 (선택된 값 반환)
}
        `}
      </GuideBlock>
      <Alert severity="warning">
        2가지 선택사항이 있을 경우 사용 (ex. 조회조건 {'>'} 사용/미사용)
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>CustomSwitch - Basic</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomSwitch
                id={'test'} // <label>과 함께 쓰는 경우 <label>의 htmlFor 속성값과 동일한 값으로 설정
                onValue={'Y'}
                onLabel={'사용'}
                offValue={'N'}
                offLabel={'미사용'}
                defaultValue={'Y'} // 기본값 생략가능 (default: onValue)
                onChange={(value) => console.log(value)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomSwitch
  id={'test'} // <label>과 함께 쓰는 경우 <label>의 htmlFor 속성값과 동일한 값으로 설정
  onValue={'Y'} // 스위치 on 값
  onLabel={'사용'} // 스위치 on 문구
  offValue={'N'} // 스위치 off 값
  offLabel={'미사용'} // 스위치 off 문구
  defaultValue={'Y'} // 기본값 생략가능 (default: onValue)
  onChange={(value) => console.log(value)}
/>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>CustomSwitch - Small</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <ControlBtnGroup className="board">
                <CustomSwitch
                  id={'test'}
                  onValue={'Y'}
                  onLabel={'label'}
                  offValue={'N'}
                  defaultValue={'Y'} // 기본값 생략가능 (default: onValue)
                  isSmall={true} // small switch 사용여부
                  onChange={(value) => console.log(value)}
                />
              </ControlBtnGroup>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<ControlBtnGroup className="board"> // 퍼블 적용 예시를 위해 추가한 wrap 영역 (화면별로 다를 수 있음)
  <CustomSwitch
    id={'test'}
    onValue={'Y'}
    onLabel={'label'}
    offValue={'N'}
    defaultValue={'Y'} // 기본값 생략가능 (default: onValue)
    isSmall={true} // small switch 사용여부
    onChange={(value) => console.log(value)}
  />
</ControlBtnGroup>
              `}
            </GuideBlock>
            <Alert severity="warning">
              샘플코드에 있는 {'<ControlBtnGroup className="board">'}은 퍼블 적용 예시를 위해
              추가해둔 것으로,
              <br />
              화면 적용 시 스위치의 wrap 영역은 퍼블과 동일하게 따라가고
              <br />
              {'<FormGroup>'} 내 영역만 {'<CustomSwitch>'}로 대체함
              <br />
            </Alert>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default SwitchGuide;
