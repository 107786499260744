/** @jsxImportSource @emotion/react */
import { CopyBlock, dracula } from 'react-code-blocks';

interface Props {
  children: string;
}
const CodeBlock = ({ children }: Props) => {
  return <CopyBlock text={children} language={'tsx'} theme={dracula} showLineNumbers={true} />;
};

export default CodeBlock;
