export const CommonBG = {
  Basic: '#FFFFFF',
  Elevation: '#FFFFFF',
  Deep: '#F7F9F8',
  Deeper: '#F1F4F3',
  Strong: '#EBEEED',
  Stonger: '#DDE0DF',
  Inverse: '#3C3E3D',
  Primary: '#2D9BB2',
  Secondary: '#554596',
  Dim: 'rgba(0,0,0,0.4)',
};

export const CommonText = {
  Basic: '#1F1F1F',
  Light: '#5B5C5B',
  Lighter: '#979998',
  Bright: '#DDE0DF',
  Inverse: '#FFFFFF',
  Primary: '#2D9BB2',
  PrimaryLight: '#8BF0E9',
  PrimaryExtreme: '#0B3E63',
  Secondary: '#554596',
  SecondaryLight: '#A897DF',
  SecondaryExtreme: '#2E226C',
  Confirmed: '#00806A',
  WarningMinor: '#FFC907',
  WarningMajor: '#FF9322',
  Error: '#F94B50',
  Done: '#979998',
  ColorA: '#303188',
  ColorB: '#77A5FF',
  ColorC: '#6D941C',
  ColorD: '#E12991',
};

export const CommonBorder = {
  Basic: '#DDE0DF',
  Light: '#EBEEED',
  Bright: '#F1F4F3',
  Strong: '#B9BCBB',
  Stronger: '#6E706F',
  Extreme: '#464747',
  inverse: '#FFFFFF',
  Primary: '#8BF0E9',
  Secondary: '#A897DF',
  Confirmed: '#56D8AA',
  WarningMinor: '#FFE56A',
  WarningMajor: '#FFCB7A',
  Error: '#FDA293',
};

export const ControlLinedBG = {
  Basic: '#FFFFFF',
  BasicHover: '#F1F4F3',
  BasicActive: '#DDE0DF',
  Primary: '#FFFFFF',
  PrimaryHover: '#ECFCF8',
  PrimaryActive: '#D9FCF4',
};

export const ControlLinedBorder = {
  BasicHover: '#979998',
  BasicActive: '#6E706F',
  PrimaryHover: '#3EC2CF',
  PrimaryActive: '#2D9BB2',
};

export const ControlFilldBG = {
  Inverse: '#3C3E3D',
  InverseHover: '#5B5C5B',
  InversActive: '#1F1F1F',
  Primary: '#1F7795',
  PrimaryHover: '#3EC2CF',
  PrimaryActive: '#135678',
  Basic: '#EBEEED',
  BasicActive: '#5B5C5B',
  InversePrimaryHover: '#1F7795',
  InversePrimaryActive: '#135678',
};

export const InputBG = {
  Disabled: '#F1F4F3',
};

export const InputBorder = {
  Focus: '#5B5C5B',
};

export const TableBG = {
  Header: '#F1F4F3',
  CellBasic: '#FFFFFF',
  CellOdd: '#F7F9F8',
  CellSelected: '#EEEAF8',
  CellHover: '#F1F4F3',
  CellTotal: '#D9FCF4',
  CellSubTotal: '#ECFCF8',
  CellEditing: '#D9FCF4',
  CellUpperDepth: '#F7F9F8',
};

export const TableBorder = {
  Header: '#DDE0DF',
  Cell: '#F1F4F3',
  CellFocus: '#A897DF',
  CellEditing: '#5B5C5B',
};

export const NavLeft = {
  Bg1LevelHover: '#DDE0DF',
  BgLastChildSelected: '#6E706F',
  Bg3Level: '#EBEEED',
  TextLastChildSelected: '#FFFFFF',
  TextSelected: '#1F7795',
};

export const NavTop = {
  BgBasic: '#F7F9F8',
  BgSwitch: '#EBEEED',
  BgSwitchHolder: '#FFFFFF',
  BorderSelected: '#2D9BB2',
  TextSysNameBasic: '#6E706F',
  TextSysNamePrimary: '#135678',
};

export const InfoBG = {
  Primary: '#D9FCF4',
  Secondary: '#E6DEF9',
  Confirmed: '#DCFBEA',
  WarningMinor: '#FFF8CD',
  WarningMajor: '#FEEFD6',
  Error: '#FEE6DB',
  Done: '#F1F4F3',
  ColorA: '#D8D9F9',
  ColorB: '#E3F0FF',
  ColorC: '#F6FCD8',
  ColorD: '#FDD4DA',
};

export const InfoBorder = {
  Primary: '#8BF0E9',
  Secondary: '#A897DF',
  Confirmed: '#56D8AA',
  WarningMinor: '#FFE56A',
  WarningMajor: '#FFCB7A',
  Error: '#FDA293',
  Done: '#979998',
  ColorA: '#8687DB',
  ColorB: '#ADCEFF',
  ColorC: '#C4E167',
  ColorD: '#F67DA9',
};

export const InfoText = {
  Primary: '#1F7795',
  Secondary: '#554596',
  Confirmed: '#01463A',
  WarningMinor: '#B78803',
  WarningMajor: '#B75511',
  Error: '#B32542',
  Done: '#5B5C5B',
  ColorA: '#232374',
  ColorB: '#567FDB',
  ColorC: '#537712',
  ColorD: '#C11D8A',
};

export const LegendBasicBG = {
  ColorALight: '#D8D9F9',
  ColorANormal: '#B4B5F3',
  ColorAStrong: '#5E5FB7',
  ColorBLight: '#E3F0FF',
  ColorBNormal: '#ADCEFF',
  COlorBStrong: '#77A5FF',
  ColorCLight: '#DAF088',
  ColorCNormal: '#C4E167',
  ColorCStrong: '#89B129',
  ColorDLight: '#FDD4DA',
  ColorDNormal: '#FCA9BF',
  ColorDStrong: '#E12991',
};

export const LegendBasicBorder = {
  ColorAStrong: '#5E5FB7',
  ColorAExtreme: '#303188',
  ColorBStrong: '#77A5FF',
  ColorBExtreme: '#567FDB',
  ColorCStrong: '#89B129',
  ColorCExtreme: '#41620A',
  ColorDStrong: '#E12991',
  ColorDExtreme: '#820D72',
};

export const LegendStatusBG = {
  ConfirmedBright: '#DCFBEA',
  ConfirmedLight: '#56D8AA',
  ConfirmedNormal: '#00806A',
  WarningMinorBright: '#FFF8CD',
  WarningMinorLight: '#FFE56A',
  WarningMinorNormal: '#FFC907',
  WarningMajorBright: '#FEEFS6',
  WarningMajorLight: '#FFCB7A',
  WarningMajorNormal: '#FF9322',
  ErrorBright: '#FEE6DB',
  ErrorLight: '#FDA293',
  ErrorNormal: '#F94B50',
  DoneBright: '#F1F4F3',
  DoneLight: '#8687DB',
  DoneNormal: '#979998',
};

export const LegendStatusBorder = {
  ConfirmedNormal: '#00806A',
  ConfirmedStrong: '#01463A',
  ConfirmedExtreme: '#052923',
  WarningMinorNormal: '#FFC907',
  WarningMinorStrong: '#B78803',
  WarningMinorExtreme: '#7A5501',
  WarningMajorNormal: '#FF9322',
  WarningMajorStrong: '#B75511',
  WarningMajorExtreme: '#7A2B06',
  ErrorNormal: '#F94B50',
  ErrorStrong: '#B32542',
  ErrorExtreme: '#770E36',
  DoneNormel: '#979998',
  DoneStrong: '#5B5C5B',
  DoneExtreme: '#1F1F1F',
};

export enum BasicColor {
  Primary = '#2D9BB2',
  Secondary = '#554596',
  White = '#FFFFFF',
  Black = '#000000',
}

export enum PrimaryColor {
  Primary50 = '#ECFCF8',
  Primary100 = '#D9FCF4',
  Primary200 = '#B4FAEF',
  Primary300 = '#8BF0E9',
  Primary400 = '#6BE1E2',
  Primary500 = '#3EC2CF',
  Primary600 = '#2D9BB2', // KeyColor
  Primary700 = '#1F7795',
  Primary800 = '#135678',
  Primary900 = '#0B3E63',
}

export enum SecondColor {
  Second50 = '#EEEAF8',
  Second100 = '#E6DEF9',
  Second200 = '#CDBFF4',
  Second300 = '#A897DF',
  Second400 = '#8372C0',
  Second500 = '#554596', // KeyColor
  Second600 = '#403281',
  Second700 = '#2E226C',
  Second800 = '#1E1657',
  Second900 = '#140D48',
}

export enum GrayColor {
  White = '#FFFFFF',
  Black = '#000000',
  Gray50 = '#F7F9F8',
  Gray100 = '#F1F4F3',
  Gray200 = '#EBEEED',
  Gray300 = '#DDE0DF',
  Gray400 = '#B9BCBB',
  Gray500 = '#979998',
  Gray600 = '#6E706F',
  Gray700 = '#5B5C5B',
  Gray800 = '#3C3E3D',
  Gray900 = '#1F1F1F',
}

export enum StatusConfirmColor {
  StatusConfirm100 = '#DCFBEA',
  StatusConfirm300 = '#56D8AA',
  StatusConfirm500 = '#00806A',
  StatusConfirm700 = '#01463A',
  StatusConfirm900 = '#052923',
}

export enum StatusWarningMinorColor {
  StatusWarningMinor100 = '#FFF8CD',
  StatusWarningMinor300 = '#FFE56A',
  StatusWarningMinor500 = '#FFC907',
  StatusWarningMinor700 = '#B78803',
  StatusWarningMinor900 = '#7A5501',
}

export enum StatusWarningMajorColor {
  StatusWarningMinor100 = '#FEEFS6',
  StatusWarningMinor300 = '#FFCB7A',
  StatusWarningMinor500 = '#FF9322',
  StatusWarningMinor700 = '#B75511',
  StatusWarningMinor900 = '#7A2B06',
}

export enum StatusErrorColor {
  StatusError100 = '#FEE6DB',
  StatusError300 = '#FDA293',
  StatusError500 = '#F94B50',
  StatusError700 = '#B32542',
  StatusError900 = '#770E36',
}

export enum StatusDoneColor {
  StatusDone100 = '#F1F4F3',
  StatusDone300 = '#DDE0DF',
  StatusDone500 = '#979998',
  StatusDone700 = '#5B5C5B',
  StatusDone900 = '#1F1F1F',
}

export enum DeepBlueColor {
  DeepBlue100 = '#D8D9F9',
  DeepBlue200 = '#B4B5F3',
  DeepBlue300 = '#8687DB',
  DeepBlue400 = '#5E5FB7',
  DeepBlue500 = '#303188',
  DeepBlue600 = '#232374',
  DeepBlue700 = '#181861',
  DeepBlue800 = '#0F0F4E',
  DeepBlue900 = '#090941',
}

export enum LimeColor {
  Lime100 = '#F6FCD8',
  Lime200 = '#ECFAB1',
  Lime300 = '#DAF088',
  Lime400 = '#C4E167',
  Lime500 = '#A6CE39',
  Lime600 = '#89B129',
  Lime700 = '#6D941C',
  Lime800 = '#537712',
  Lime900 = '#41620A',
}

export enum PinkColor {
  Pink100 = '#FDD4DA',
  Pink200 = '#FCA9BF',
  Pink300 = '#F67DA9',
  Pink400 = '#EC5B9F',
  Pink500 = '#E12991',
  Pink600 = '#C11D8A',
  Pink700 = '#A21480',
  Pink800 = '#820D72',
  Pink900 = '#6C0768',
}

export enum LightBlueColor {
  LightBlue100 = '#E3F0FF',
  LightBlue200 = '#C8E0FF',
  LightBlue300 = '#ADCEFF',
  LightBlue400 = '#99BFFF',
  LightBlue500 = '#77A5FF',
  LightBlue600 = '#567FDB',
  LightBlue700 = '#3B5DB7',
  LightBlue800 = '#253F93',
  LightBlue900 = '#162A7A',
}

/**
 * 법무 공통 소스에서 사용중이여서 삭제안함
 * TODO 퍼블 확인필요
 */
export enum FontColor {
  Primary700 = '#0b3e63',
  Primary = '#2D9BB2',
  Default = '#1f1f1f',
  Gray600 = '#b1b1b1',
  Gray500 = '#CCCCCC',
  Gray400 = '#5b5c5b',
  Gray300 = '#6e706f',
  Gray200 = '#979998',
  Gray100 = '#A7A6A2',
  White = '#FFFFFF',
  HighLight = '#00B4CB',
}

/**
 * 법무 공통 소스에서 사용중이여서 삭제안함
 * TODO 퍼블 확인필요
 */
export enum ButtonColor {
  Primary = '#3ec2cf',
  Secondary = '#666666',
  Red = '#a40033',
}

/**
 * 법무 공통 소스에서 사용중이여서 삭제안함
 * TODO 퍼블 확인필요
 */
export enum BorderColor {
  Primary = '#dde0df',
  Secondary = '#333333',
  Form = '#b9bcbb',
  Third = '#135678',
}

/**
 * 법무 공통 소스에서 사용중이여서 삭제안함
 * TODO 퍼블 확인필요
 */
export enum BgColor {
  White300 = '#F1EFEB',
  White200 = '#F5F5F5',
  White100 = '#F2F2F2',
  White50 = '#F8F8F8',
  White = '#FFFFFF',
  Gray700 = '#3c3e3d',
  Gray600 = '#D1D1D1',
  Gray300 = '#c1bfbc',
  Gray200 = '#999999',
  Gray100 = '#EEEEEE',
  Gray50 = '#F7F9F8',
  Secondary50 = '#EEEAf8',
  Secondary100 = '#a897df',
  InputBlue50 = '#E8F0FB',
}
