/** @jsxImportSource @emotion/react */
import React, { useEffect, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { CellType } from '@grapecity/wijmo.grid';
import { findMpTargetLine, setMpTargetLine } from 'apis/mp/MpTarget';
import { MpTargetLineCondition } from 'models/mp/MpTarget';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { CrudCode } from 'models/common/Edit';
import { CommonYN } from 'models/common/Common';
import MpTargetLineConfigModal from 'components/modals/mp/MpTargetLineConfigModal';
import { MpLineCondition } from 'models/mp/MpLine';
import { useCommonModal } from 'hooks/useCommonModal';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { Employee } from 'models/admin/Employee';
import UserModal from 'components/modals/common/UserModal';
import CustomGrid from 'components/grids/CustomGrid';

interface MpTargetDetailGridProps {
  conditionRef: any;
  condition: {
    copCd?: string; // 법인코드
    plntCd?: string; // 공장(동) 코드
  };
}

const MpTargetDetailGrid = ({ conditionRef, condition }: MpTargetDetailGridProps, ref) => {
  // const mpTargetLineConditionRef = useRef<MpTargetLineCondition>(conditionRef.current);
  const gridRef = useRef<any>();

  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();

  const [isOpenMpTargetLineConfigModal, setOpenMpTargetLineConfigModal] = useState<boolean>(false);
  const [mpTargetLineConfigModalCondition, setMpTargetLineConfigModalCondition] =
    useState<MpLineCondition>({});

  const [rowData, setRowData] = useState<any[]>([]);
  const [userModalCondition, setUserModalCondition] = useState<any>({});
  const [isOpenUserModal, setOpenUserModal] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    init: async () => {
      setRowData([]);
    },
    search: async () => {
      await handleSearch({ ...conditionRef.current }, true);
    },
    save: () => {
      openCommonModal({
        modalType: 'confirm',
        content: t('com.button.저장하시겠습니까?', '저장하시겠습니까?'),
        yesCallback: () => {
          const params = (rowData || []).filter((o) => o.crudKey);
          setMpTargetLine(params, conditionRef.current).then((response) => {
            handleSearch({ ...conditionRef.current }, true);
          });
        },
      });
    },
  }));

  const addRow = useCallback(() => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
    };
    setRowData([newRow, ...rowData]);
  }, [rowData]);

  const handleSearch = async (condition: MpTargetLineCondition, isFirst = false) => {
    const response = await findMpTargetLine(condition);

    if (response) {
      const rowData = response || [];
      // No 데이터 추가
      rowData.forEach((value, index) => {
        value['no'] = index + 1;
        value.crudKey = CrudCode.READ;
      });
      setRowData(rowData);
    }
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('com.label.상태', '상태')),
      binding: 'crudKey',
      width: 40,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'plntCd',
      header: String(t('mp.grid.공장코드', '공장코드')),
    },
    {
      width: 140,
      binding: 'lnId',
      header: String(t('mp.grid.Line ID', 'Line ID')),
    },
    {
      width: 150,
      binding: 'lnNm',
      header: String(t('mp.grid.Line 명', 'Line 명')),
      align: 'left',
    },
    {
      width: 180,
      binding: 'lnAnmNm',
      header: String(t('mp.grid.Line 약어', 'Line 약어')),
      align: 'left',
    },
    {
      header: String(t('mp.grid.수평전개', '수평전개')),
      columns: [
        {
          binding: 'heYn',
          header: String(t('mp.grid.대상', '대상')),
          width: 80,
          cssClass: 'WijmoCheck',
          cellTemplate: (params) => {
            const checked = params.value === 'Y' ? 'checked' : '';
            return `<div class="checkbox">
                  <input type="checkbox" id="heYnChk_${params.item.execPlanId}" ${checked} />
                  <label />
                </div>
          `;
          },
        },
        {
          binding: 'heMgr',
          visible: false,
        },
        {
          binding: 'heMgrNm',
          header: String(t('mp.grid.담당자명', '담당자명')),
          cssClass: 'WijmoFind',
          align: 'left',
          minWidth: 200,
          width: '*',
          cellTemplate: (params) => `
            <span>${params.value || ''}</span>
            <Button /> 
          `,
        },
      ],
    },
    {
      binding: 'crdUser',
      header: String(t('com.label.등록자', '등록자')),
      width: 110,
    },
    {
      binding: 'dataInsDtm',
      width: 130,
      header: String(t('com.label.등록일', '등록일')),
    },
    {
      binding: 'updUser',
      header: String(t('com.label.수정자', '수정자')),
      width: 110,
    },
    {
      binding: 'dataUpdDtm',
      width: 130,
      header: String(t('com.label.수정일', '수정일')),
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        // 담당자 editable css 적용
        if ('heMgrNm' === binding) {
          cell.ariaReadOnly = false;
        }
      }
    };

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return;

      const item = grid.rows[ht.row].dataItem;
      const binding = grid.columns[ht.col].binding;

      // 대상 클릭
      if ('heYn' === binding && e.target.id?.startsWith('heYn')) {
        item.heYn = e.target.checked ? 'Y' : 'N';
        item.crudKey = CrudCode.UPDATE;
        gridRef.current?.refresh();
      }
      // 담당자 클릭
      else if ('heMgrNm' === binding) {
        setUserModalCondition({
          defaultUserId: item.heMgr,
          target: {
            row: ht.row,
            col: ht.col,
          },
        });
        setOpenUserModal(true);
      }
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.MP 대상 Line', 'MP 대상 Line')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
          {conditionRef.current && conditionRef.current?.copCd && (
            <div className="info primary">{`${conditionRef.current.copCd} (${conditionRef.current.factoryNm}) `}</div>
          )}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="docu"
            onClick={() => {
              setMpTargetLineConfigModalCondition({
                copCd: condition.copCd || conditionRef.current.copCd,
                plntCd: condition.plntCd || conditionRef.current.plntCd,
              });

              setOpenMpTargetLineConfigModal(true);
            }}
          >
            {t('com.button.Line 약어 설정', 'Line 약어 설정')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>

      <CustomGrid
        rowData={rowData}
        layoutDefinition={layoutDefinition}
        height={250}
        align={'center'}
        allowPinning={false}
        isReadOnly={true}
        isSelector={false}
        initialized={onInitialized}
      />

      {isOpenUserModal && (
        <UserModal
          open={isOpenUserModal}
          close={() => setOpenUserModal(false)}
          title={t('mp.label.담당자 조회', '담당자 조회')}
          singleSelect={false}
          onCallback={(result) => {
            const target = userModalCondition?.target;
            if (target && target?.row > -1) {
              const users = (result || []) as Employee[];
              const item = gridRef.current?.rows[target.row].dataItem;
              item.heMgrNm = users.map((o) => o.empNm).join(',');
              item.heMgr = users.map((o) => o.userId).join(',');
              item.crudKey = CrudCode.UPDATE;
              gridRef.current?.collectionView.refresh();
            }
          }}
        />
      )}
      {isOpenMpTargetLineConfigModal && (
        <MpTargetLineConfigModal
          open={isOpenMpTargetLineConfigModal}
          close={() => setOpenMpTargetLineConfigModal(false)}
          condition={mpTargetLineConfigModalCondition}
          onCallback={(value) => console.log('callback')}
        />
      )}
    </>
  );
};

export default React.forwardRef(MpTargetDetailGrid);
