import { create } from 'zustand';
import { v4 as uuid } from 'uuid';
import { GridApi, IRowNode } from 'ag-grid-community';
import _ from 'lodash';

import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { findMessages, saveMessages } from 'apis/admin/Message';
import { Message, MessageCondition, ShowingMessage } from 'models/admin/Message';
import { CommonYN } from 'models/common/Common';
import { Nullable } from 'models/common/FalsyGeneric';
import { translate as t } from 'utils/i18nUtil';
import { Code, commonYNList } from 'models/common/CommonCode';
import { CrudCode } from 'models/common/Edit';

interface MessageManagementState {
  messages: Message[];
  showingMessages: ShowingMessage[];
  lastSearchedCondition: MessageCondition;
  messageManagementGridApi: any;
  languageCodes: Code[];
  useYnCodes: Code[];
  initMessageManagementPage: () => void;
  resetMessageManagementPage: () => void;
  setMessageManagementGridApi: (gridApi: GridApi) => void;
  findMessages: (messageCondition: MessageCondition) => void;
  findMessagesWithLastSearchedCondition: () => void;
  addMessage: () => void;
  removeMessages: () => Promise<string | undefined>;
  saveMessages: () => Promise<string | undefined>;
}

const convertMessagesToShowingMessages = (
  messages: Message[],
  languageCodes: Code[]
): ShowingMessage[] => {
  if (messages.length === 0 || languageCodes.length === 0) {
    return [];
  }

  const uniqueMsgCtn = [
    ...messages
      .filter((msgCtn) => msgCtn !== null)
      .map((message) => message.msgCtn as string)
      .filter((msgCtn, index, array) => array.indexOf(msgCtn) === index),
  ];

  const showingMessages: ShowingMessage[] = [];

  for (const msgCtn of uniqueMsgCtn) {
    let showingMessage: ShowingMessage = {
      msgCtn: null,
      msgTxtCtn1: null,
      msgTxtCtn2: null,
      msgTxtCtn3: null,
      msgTxtCtn4: null,
      msgTxtCtn5: null,
      rmk: null,
      optValCtn1: null,
      optValCtn2: null,
      optValCtn3: null,
      useYn: null,
      dataInsUserId: null,
      dataInsUserIp: null,
      dataInsDtm: null,
      dataUpdUserId: null,
      dataUpdUserIp: null,
      dataUpdDtm: null,
    };

    const messagesSelectedByMsgCtn = messages.filter((message) => message.msgCtn === msgCtn);

    for (let i = 0; i < languageCodes.length; i++) {
      const languageCode = languageCodes[i].cmnCd ?? '';

      const findMessages = messagesSelectedByMsgCtn.filter(
        (message) => message.langCd === languageCode
      );

      if (findMessages.length !== 0) {
        const { msgTxtCtn, ...rest } = findMessages[0];

        if (showingMessage.msgCtn === null) {
          showingMessage = { ...showingMessage, ...rest };
        }

        showingMessage[`msgTxtCtn${i + 1}`] = msgTxtCtn;
      }
    }

    showingMessages.push({ ...showingMessage, crudKey: CrudCode.READ, uuid: uuid() });
  }

  return showingMessages;
};

const convertShowingMessagesToMessages = (
  showingMessages: ShowingMessage[],
  languageCodes: Code[]
): Message[] => {
  const messages: Message[] = [];

  for (const showingMessage of showingMessages) {
    const { uuid, msgTxtCtn1, msgTxtCtn2, msgTxtCtn3, msgTxtCtn4, msgTxtCtn5, ...rest } =
      showingMessage;

    for (let i = 0; i < languageCodes.length; i++) {
      const languageCode = languageCodes[i].cmnCd ?? '';

      messages.push({
        ...rest,
        langCd: languageCode,
        msgTxtCtn: showingMessage[`msgTxtCtn${i + 1}`],
      });
    }
  }
  return messages;
};

export const useMessageManagementStore = create<MessageManagementState>((set, get) => {
  return {
    messages: [],
    showingMessages: [],
    lastSearchedCondition: {},
    messageManagementGridApi: null,
    languageCodes: [],
    useYnCodes: [],
    initMessageManagementPage: async () => {
      set({
        messages: [],
        showingMessages: [],
        lastSearchedCondition: {},
        languageCodes: (await getCommonCodeNames('LANG_CD')) ?? [],
        useYnCodes: commonYNList ?? [],
      });
    },
    resetMessageManagementPage: () => {
      set({
        messages: [],
        showingMessages: [],
        lastSearchedCondition: {},
        languageCodes: [],
        useYnCodes: [],
      });
    },
    setMessageManagementGridApi: (gridApi: GridApi) => {
      set({
        messageManagementGridApi: gridApi,
      });
    },
    findMessages: async (messageCondition: MessageCondition) => {
      const { languageCodes } = get();

      const apiResult = (await findMessages(messageCondition)) ?? [];
      set({
        messages: [...apiResult],
        showingMessages: [...convertMessagesToShowingMessages([...apiResult], [...languageCodes])],
        lastSearchedCondition: { ...messageCondition },
      });
    },
    findMessagesWithLastSearchedCondition: async () => {
      const { languageCodes, lastSearchedCondition } = get();

      const apiResult = (await findMessages(lastSearchedCondition)) ?? [];

      set({
        messages: [...apiResult],
        showingMessages: [...convertMessagesToShowingMessages([...apiResult], [...languageCodes])],
      });
    },
    addMessage: () => {
      const { showingMessages } = get();
      set({
        showingMessages: [
          { crudKey: CrudCode.CREATE, uuid: uuid(), useYn: CommonYN.Y } as ShowingMessage,
          ...showingMessages,
        ],
      });
    },
    removeMessages: async () => {
      const { messageManagementGridApi, showingMessages, languageCodes, lastSearchedCondition } =
        get();

      if (messageManagementGridApi === null) {
        return t('grid api');
      }
      const messageNodesToDelete = messageManagementGridApi.selectedRows;
      const createdMessagesToDelete = messageNodesToDelete.filter(
        (messageNode) => messageNode.dataItem.crudKey === CrudCode.CREATE
      );

      const dbMessagesToDelete = messageNodesToDelete.filter(
        (messageNode) => messageNode.dataItem.crudKey !== CrudCode.CREATE
      );

      if (!_.isEmpty(createdMessagesToDelete) && _.isEmpty(dbMessagesToDelete)) {
        const filter = showingMessages.filter((item) => item.crudKey !== CrudCode.CREATE);
        set({
          showingMessages: filter,
        });
        return 'OK';
      } else if (!_.isEmpty(dbMessagesToDelete)) {
        const messagesToDelete: Message[] = [];

        for (const item of dbMessagesToDelete) {
          for (const languageCode of languageCodes) {
            messagesToDelete.push({
              crudKey: CrudCode.DELETE,
              msgCtn: item.dataItem.msgCtn,
              langCd: languageCode.cmnCd,
              msgTxtCtn: CrudCode.DELETE,
            } as Message);
          }
        }
        const saveApiResult = await saveMessages(messagesToDelete);

        if (saveApiResult) {
          const findApiResult = (await findMessages({ ...lastSearchedCondition })) ?? [];
          set({
            messages: [...findApiResult],
            showingMessages: [
              ...convertMessagesToShowingMessages([...findApiResult], [...languageCodes]),
            ],
          });
          return 'OK';
        } else {
          return 'FAIL';
        }
      } else {
        return 'OK';
      }
    },
    saveMessages: async () => {
      const { messages, showingMessages, languageCodes, lastSearchedCondition } = get();
      const showingMessagesToSave = showingMessages.filter(
        (message) => message.crudKey === CrudCode.CREATE || message.crudKey === CrudCode.UPDATE
      );

      const messagesToSave = convertShowingMessagesToMessages(showingMessagesToSave, languageCodes);
      if (
        !_.isEmpty(
          messagesToSave.find(
            (message) =>
              _.isEmpty(message.msgCtn) || _.isEmpty(message.msgTxtCtn) || _.isEmpty(message.langCd)
          )
        )
      ) {
        return 'FAIL';
        // return t('com.label.저장 중 오류가 발생했습니다.');
      }

      const saveApiResult = await saveMessages(
        messagesToSave.map((message) => {
          if (
            _.isEmpty(
              messages.find(
                (apiMessage) =>
                  apiMessage.langCd === message.langCd && apiMessage.msgCtn === message.msgCtn
              )
            )
          ) {
            return { ...message, crudKey: CrudCode.CREATE };
          } else {
            return { ...message };
          }
        })
      );

      if (saveApiResult) {
        const findApiResult = (await findMessages({ ...lastSearchedCondition })) ?? [];
        set({
          messages: [...findApiResult],
          showingMessages: [
            ...convertMessagesToShowingMessages([...findApiResult], [...languageCodes]),
          ],
        });
        return 'OK';
        // return t('com.label.저장되었습니다.');
      } else {
        return 'FAIL';
        // return t('com.label.저장 중 오류가 발생했습니다.');
      }
    },
  };
});
