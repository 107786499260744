/** @jsxImportSource @emotion/react */
import { CellClickedEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { findBbsPosts } from 'apis/admin/Bbs';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { CustomInputText, CustomInputWrap } from 'components/inputs/CustomInput';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { Pagination, PaginationRef } from 'components/layouts/Pagination';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { CustomSelect } from 'components/selects/CustomSelect';
import { BbsCondition, BbsPost } from 'models/admin/Bbs';
import { Code } from 'models/common/CommonCode';
import { EditType } from 'models/common/Edit';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuUrl } from 'routers/Router';
import { Button } from '@mui/material';
import {
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from '../../components/layouts/ContentLayout';
import { IconButton } from '../../components/buttons/IconSVG';
import { ComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import useSessionStore from 'stores/useSessionStore';
import dayjs from 'dayjs';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import { ContentSection } from 'components/layouts/ContentSection';

export interface BbsManagementPageLocationState {
  condition?: BbsCondition;
  pageNo?: number;
}

const initParam = {
  atchFileGrId: '' || undefined,
  atchFileTpCd: '',
  optValCtn1: '',
  bizName: '' || undefined,
};

const BbsManagementPage = () => {
  const locationState: BbsManagementPageLocationState = useLocation().state;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { gridNoRowsTemplate } = useSessionStore();
  const [postList, setpostList] = useState<BbsPost[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [bbsTypeConditionList, setBbsTypeConditionList] = useState<Code[]>([]);
  const [bbsTpCd, setBbsTpCd] = useState<string>('');
  const [bbmTitNm, setBbmTitNm] = useState<string>('');
  const [bbmCtn, setBbmCtn] = useState<string>('');
  const [searchType, setSearchType] = useState<string>('title');
  const [pageSize, setPageSize] = useState<number>(
    locationState?.condition && locationState?.condition?.pageSize
      ? Number(locationState.condition.pageSize)
      : 10
  );
  const [searchItem, setSearchItem] = useState<string>('');
  const [pageNo, setPageNo] = useState<number>(locationState?.pageNo ? locationState.pageNo : 1);
  const [lastSearchCondition, setLastSearchCondition] = useState<BbsCondition>();

  const userSession = useSessionStore();

  const paginationRef = useRef<PaginationRef>(null);

  const location = useLocation();
  const state = { ...location.state };

  const [isOpenFileUploadPopUp, setOpenFileUploadPopUp] = useState<boolean>(false);
  const [getDownloadOnly, setDownloadOnly] = useState<boolean>(true); //검색할 파라미터 상태관리
  const [initParams, setInitParams] = useState<any>(initParam); //검색할 파라미터 상태관리

  const onCellClicked = (e: CellClickedEvent) => {
    if (e.colDef.field === 'bbmTitNm') {
      handleTitleClick(e);
    }
  };

  const AtchFileExistCellRenderer = (props: ICellRendererParams) => {
    return (
      <>
        <Button
          className="cellFile"
          onClick={() => {
            setOpenFileUploadPopUp(true);
            setInitParams({
              atchFileGrId: props.data.atchFileGrId,
              atchFileTpCd: 'NORMAL',
              optValCtn1: '',
              bizName: '' || undefined,
            });
          }}
        >
          {props.value}
        </Button>
      </>
    );
  };

  const handlePageSizeChange = async (pageSize: number) => {
    const bbsCondition: BbsCondition = {
      bbsTpCd: bbsTpCd,
      bbmTitNm: bbmTitNm,
      bbmCtn: bbmCtn,
      pageSize: String(pageSize),
    };
    setLastSearchCondition(bbsCondition);
    const response = await findBbsPosts(bbsCondition);
    if (response) {
      const bbsPosts = response.list || [];
      setpostList(bbsPosts);
      setTotalCount(response.totalCount || 0);
      setPageSize(pageSize);
    }
  };

  const handleSearchClick = async () => {
    const bbsCondition: BbsCondition = {
      bbsTpCd: bbsTpCd,
      bbmTitNm: bbmTitNm,
      bbmCtn: bbmCtn,
      pageSize: String(pageSize),
    };
    setLastSearchCondition(bbsCondition);
    const response = await findBbsPosts(bbsCondition);
    if (response) {
      const bbsPosts = response.list || [];
      setpostList(bbsPosts);
      setTotalCount(response.totalCount || 0);
      paginationRef.current?.setSelectNo(1);
    }
  };

  const handleCreateClick = () => {
    navigate('/system/admin/sample/bbs-sample/bbs-edit', {
      state: {
        condition: lastSearchCondition,
        editType: EditType.CREATE,
        editBbmNo: '',
        prevMenuCode: 'bbs-sample',
        bbsTpCd: 'CMN',
        bbsTpNm: t('admin.label.일반', '일반'),
        mnuNm: `${t('admin.label.게시글 생성', '게시글 생성')}`,
      },
    });
  };

  const handleTitleClick = (e) => {
    navigate('/system/admin/sample/bbs-sample/bbs-post', {
      state: {
        condition: lastSearchCondition,
        postBbmNo: e.data.bbmNo,
        prevMenuCode: 'bbs-sample',
        mnuNm: `${t('admin.label.게시글 상세', '게시글 상세')}`,
      },
    });
  };

  const handlePageNoClick = async (pageNo: number) => {
    setPageNo(pageNo);
    const bbsCondition: BbsCondition = {
      bbsTpCd: bbsTpCd,
      bbmTitNm: searchType === 'title' ? searchItem : '',
      bbmCtn: searchType === 'content' ? searchItem : '',
      pageSize: String(pageSize),
      start: String(pageSize * (pageNo - 1)),
    };
    setLastSearchCondition(bbsCondition);
    const response = await findBbsPosts(bbsCondition);
    if (response) {
      const bbsPosts = response.list || [];
      setpostList(bbsPosts);
      setTotalCount(response.totalCount || 0);
    }
  };

  const initCondition = async () => {
    const categoryConditions = await getCommonCodeNames('BBS_TP_CD');
    setBbsTypeConditionList(categoryConditions);
  };

  const init = async () => {
    const bbsCondition: BbsCondition = {
      pageSize: String(pageSize),
    };
    setLastSearchCondition(bbsCondition);
    const response = await findBbsPosts(bbsCondition);
    if (response) {
      const bbsPosts = response.list || [];
      setpostList(bbsPosts);
      setTotalCount(response.totalCount || 0);
    }
  };

  useEffect(() => {
    init();
    initCondition();
  }, []);

  useEffect(() => {
    handleSearchClick();
  }, [state.reSearch]);

  // useEffect(() => {
  //   const initCondition = async () => {
  //     const categoryConditions = await getCommonCodeNames('BBS_TP_CD');
  //     setBbsTypeConditionList(categoryConditions);
  //   };

  //   const init = async () => {
  //     const bbsCondition: BbsCondition = {
  //       pageSize: String(pageSize),
  //     };
  //     setLastSearchCondition(bbsCondition);
  //     const response = await findBbsPosts(bbsCondition);
  //     if (response) {
  //       const bbsPosts = response.list || [];
  //       setpostList(bbsPosts);
  //       setTotalCount(response.totalCount || 0);
  //     }
  //   };

  //   const reSearch = async () => {
  //     if (!locationState.condition) return;

  //     const condition: BbsCondition = locationState.condition;
  //     setBbsType(condition.bbsTpCd ?? '');
  //     setSearchItem(condition.searchItem ?? '');
  //     setLastSearchCondition(condition);
  //     const response = await findBbsPosts(condition);
  //     if (response) {
  //       const bbsPosts = response.list || [];
  //       setpostList(bbsPosts);
  //       setTotalCount(response.totalCount || 0);
  //     }
  //   };
  //   initCondition();
  //   if (locationState) {
  //     reSearch();
  //   } else {
  //     init();
  //   }
  // }, []);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };

  const [columnDefs] = useState([
    {
      headerName: 'No',
      field: 'bbmNo',
      width: 40,
      filter: false,
      editable: false,
      suppressSizeToFit: true,
      cellClass: 'cellStatus',
    },
    {
      field: 'bbsTpNm',
      headerName: t('admin.grid.분류', '분류'),
      width: 100,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'bbmTitNm',
      headerName: t('admin.grid.제목', '제목'),
      flex: 1,
      // cellRenderer: BbsTitleCellRenderer,
    },
    {
      field: 'atchFileExist',
      headerName: t('admin.grid.첨부', '첨부'),
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellRenderer: AtchFileExistCellRenderer,
    },
    {
      field: 'dataInsUserInfo',
      headerName: t('admin.grid.작성자', '작성자'),
      width: 250,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'dataInsDtm',
      headerName: t('com.label.작성일시', '작성일시'),
      width: 150,
      cellStyle: { textAlign: 'center' },
      valueFormatter: (params) => {
        return dayjs(params.value).format('YYYY.MM.DD');
      },
    },
    {
      field: 'bbmVwct',
      headerName: t('admin.grid.조회수', '조회수'),
      width: 100,
      cellStyle: { textAlign: 'center' },
    },
  ]);

  const btnReload = () => {
    setBbsTpCd('');
    setBbmTitNm('');
    setBbmCtn('');
  };

  return (
    <>
      <ContentSection>
        <SearchBox>
          <SearchBoxRow>
            <InputBox>
              <SearchRows>
                <SearchCols>
                  <label>{t('admin.label.게시판분류', '게시판분류')}</label>
                  <ComboBox
                    options={bbsTypeConditionList}
                    placeholder={String(t('com.label.전체', '전체'))}
                    defaultValue={bbsTpCd}
                    onChange={setBbsTpCd}
                  />
                </SearchCols>
                <SearchCols>
                  <label>{t('admin.label.제목', '제목')}</label>
                  <CustomInputWithSearch
                    type="text"
                    name="gatingName"
                    placeholder={String(
                      t('admin.msg.제목을 입력해 주세요.', '제목을 입력해 주세요.')
                    )}
                    value={bbmTitNm}
                    onChange={(e) => setBbmTitNm(e.target.value)}
                    onKeyDown={onKeyDown}
                  />
                </SearchCols>
                <SearchCols>
                  <label>{t('admin.label.내용', '내용')}</label>
                  <CustomInputWithSearch
                    type="text"
                    name="gatingName"
                    placeholder={String(
                      t('admin.msg.내용을 입력해 주세요.', '내용을 입력해 주세요.')
                    )}
                    value={bbmCtn}
                    onChange={(e) => setBbmCtn(e.target.value)}
                    onKeyDown={onKeyDown}
                  />
                </SearchCols>
              </SearchRows>
            </InputBox>
            <SearchButtonWrap>
              <Button
                css={IconButton.button}
                className="reload"
                onClick={btnReload}
                disableRipple
              ></Button>
              <Button
                css={IconButton.button}
                className="find"
                disableRipple
                onClick={handleSearchClick}
              >
                {t('com.button.조회', '조회')}
              </Button>
            </SearchButtonWrap>
          </SearchBoxRow>
        </SearchBox>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('admin.label.게시글 목록', '게시글 목록')}</h3>
            <span className="total">
              {t('com.label.총', '총')}
              <span>{totalCount}</span>
              {t('com.label.건', '건')}
            </span>
          </SubTitleGroup>
        </SubTitleLayout>
        <ContentGrid className="ag-theme-alpine" style={{ height: '500px' }}>
          <AgGridReact
            overlayNoRowsTemplate={gridNoRowsTemplate}
            rowData={postList}
            columnDefs={columnDefs}
            suppressPaginationPanel={true}
            onCellClicked={onCellClicked}
            groupHeaderHeight={32}
            rowHeight={32}
          ></AgGridReact>
        </ContentGrid>
        <Pagination
          ref={paginationRef}
          totalCount={totalCount}
          defaultPageNo={pageNo}
          defaultPageSize={pageSize}
          onChangePageSize={handlePageSizeChange}
          onClickPageNo={handlePageNoClick}
        />
        <GlobalBtnGroup>
          <Button
            css={IconButton.button}
            className="write"
            disableRipple
            onClick={handleCreateClick}
          >
            {t('com.button.신규', '신규')}
          </Button>
        </GlobalBtnGroup>
        {/*첨부파일팝업*/}
        {isOpenFileUploadPopUp && (
          <FileUploadPopUp
            open={isOpenFileUploadPopUp}
            close={() => setOpenFileUploadPopUp(false)}
            onCallback={(value) => {
              setOpenFileUploadPopUp(false);
              // handleSearch(gatingCheckResultParamCondition);
            }}
            initParam={initParams}
            downloadOnly={getDownloadOnly}
            singleSelect={false}
            showAllDownload={true}
          />
        )}
      </ContentSection>
    </>
  );
};

export default BbsManagementPage;
