/** @jsxImportSource @emotion/react */
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { PopupBtnGroup, TitleWrap, di } from 'components/layouts/Dialog';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { CustomInputText, CustomInputWrap } from 'components/inputs/CustomInput';
import { IconButton } from 'components/buttons/IconSVG';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { simpleRequest } from 'apis/ip/IpCheckPlan';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessageBar } from 'hooks/useMessageBar';
import CellRendererCommonSelect from '../grid/CellRendererCommonSelect';
import { commonColDef } from 'models/ip/Public';

type Props = {
  open: boolean;
  close: () => void;
  params: any;
};

export const IpCheckResultPointModal = (props: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>(0);

  const defaultColDef = useMemo(() => {
    return commonColDef;
  }, []);

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'crudKey',
      headerName: String(t('com.label.상태', '상태')),
      width: 20,
      hide: true,
    },
    {
      headerName: String(t('ip.grid.파라미터ID', '파라미터ID')),
      field: 'paraId',
      width: 130,
      editable: false,
    },
    {
      headerName: String(t('ip.grid.파라미터명', '파라미터명')),
      field: 'paraName',
      width: 150,
      editable: false,
    },
    {
      headerName: String(t('ip.grid.포인터 번호', '포인터 번호')),
      field: 'paraPointNo',
      width: 100,
      editable: false,
    },
    {
      headerName: String(t('ip.grid.측정개소명', '측정개소명')),
      field: 'paraPointName',
      width: 250,
      editable: false,
    },
    {
      headerName: String(t('ip.grid.측정개소 설명', '측정개소 설명')),
      field: 'paraPointDesc',
      flex: 1,
      editable: false,
    },
    {
      headerName: String(t('ip.grid.미터유형', '미터유형')),
      field: 'meterType',
      width: 100,
      cellStyle: { textAlign: 'right' },
      cellClass: 'cellSelect',
      editable: false,
      cellRenderer: CellRendererCommonSelect,
      cellRendererParams: { keyword: 'METER_TYPE' },
    },
    {
      headerName: String(t('ip.grid.하한', '하한')),
      field: 'lowerBound',
      width: 60,
      cellStyle: { textAlign: 'right' },
      cellClass: (params) => ['cellEditing'],
      editable: true,
    },
    {
      headerName: String(t('ip.grid.상한', '상한')),
      field: 'upperBound',
      width: 60,
      cellStyle: { textAlign: 'right' },
      cellClass: (params) => ['cellEditing'],
      editable: true,
    },
    {
      headerName: String(t('ip.grid.UOM', 'UOM')),
      field: 'uom',
      width: 100,
      cellStyle: { textAlign: 'right' },
      cellClass: 'cellSelect',
      editable: false,
      cellRenderer: CellRendererCommonSelect,
      cellRendererParams: { keyword: 'IP_UOM' },
    },
    {
      field: 'rmk',
      width: 100,
      headerName: String(t('ip.grid.주석', '주석')),
      cellStyle: { textAlign: 'left' },
      cellClass: (params) => ['cellEditing'],
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      editable: true,
      cellEditorParams: {
        useFormatter: true,
        maxLength: 1000,
      },
    },
  ];

  const handlOnClose = () => {
    props.close();
  };

  useEffect(() => {
    drawList(props.params);
  }, []);

  const drawList = async (params: any) => {
    await simpleRequest(params, `/v1/selectFromIpCheckParaPoint`).then((result: any[]) => {
      setTotalCount(result.length);
      setRowData(result);
    });
  };

  const saveBtnOnClick = async () => {
    const params = {
      listIpCheckParaPoint: rowData,
    };

    await simpleRequest(params, `/v1/updateToIpCheckParaPoint`).then((result: any[]) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content:
            '그리드 내용 ' +
            t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
        });
        return;
      }
      openMessageBar({
        type: 'confirm',
        content: t('com.label.저장되었습니다.', '저장되었습니다.'),
      });
      return result;
    });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handlOnClose}
        css={di.dialog}
        fullWidth
        className="lg"
        maxWidth={false}
      >
        <TitleWrap>
          <h2>{t('ip.label.측정개소 대상 조회', '측정개소 대상 조회')}</h2>
          <Button disableRipple onClick={handlOnClose}></Button>
        </TitleWrap>
        <DialogContent>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('ip.label.측정개소 대상 계획', '측정개소 대상 계획')}</h3>
              <span className="total">
                {t('com.label.총', '총')} <span>{totalCount}</span>
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
          </SubTitleLayout>
          <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              groupHeaderHeight={32}
              rowHeight={32}
              rowSelection="single"
              stopEditingWhenCellsLoseFocus={true}
              singleClickEdit={true}
            ></AgGridReact>
          </ContentGrid>
        </DialogContent>
        <PopupBtnGroup>
          <Button css={IconButton.button} className="cancel" onClick={handlOnClose} disableRipple>
            {t('com.button.취소', '취소')}
          </Button>
          <Button
            css={IconButton.button}
            className="confirm"
            disableRipple
            onClick={saveBtnOnClick}
          >
            {t('com.button.저장', '저장')}
          </Button>
        </PopupBtnGroup>
      </Dialog>
    </div>
  );
};
