/** @jsxImportSource @emotion/react */
import { ContentGrid } from 'components/layouts/ContentGrid';
// import CustomGrid from 'components/grids/CustomGrid';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { IpCheckPlan } from 'models/ip/IpCheckPlan';
import { getListFromIpCheckPlan } from 'apis/ip/IpCheckPlan';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import ChangePlanModal from '../modals/ChangePlanModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { CrudCode } from 'models/common/Edit';
import { IconButton } from 'components/buttons/IconSVG';
import MakePlanModal from '../modals/MakePlanModal';
import { flexGridShortDate, flexGridTooltip } from 'models/ip/Public';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { useNavigate } from 'react-router-dom';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import useEvent from 'react-use-event-hook';
import { hasRole } from 'utils/SessionUtil';

interface paramData {
  condition: any;
  editable: boolean;
  callBackRowData?: any;
}

const StatusManagementGrid = forwardRef((props: paramData, ref) => {
  const { t } = useTranslation();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [hasAuth, setHasAuth] = useState<boolean>(false);
  const [colWidthMap, setColWidthMap] = useState<any>({
    copCd: 100,
    ipCheckPlanNo: 180,
    ipCheckPlanName: 250,
    ipCheckStatusName: 150,
    ipCnt: 100,
    elementCnt: 100,
    isptTpCdNm: 150,
    emlSndoYN: 100,
    fatPlanStartDate: 120,
    fatPlanEndDate: 120,
    fatMkRst: 100,
    fatEnRst: 100,
    satMkRst: 100,
    satEnRst: 100,
    satPlanStartDate: 120,
    satPlanEndDate: 120,
    rmk: 130,
    dataInsDtm: 120,
    dataInsUserNm: 100,
    dataUpdDtm: 120,
    dataUpdUserNm: 100,
  });
  const selectedRow = useRef(0);

  const [rowData, setRowData] = useState<IpCheckPlan[]>([]);
  const [params, setParams] = useState<any>();
  const [current, setCurrent] = useState<any>();
  const { openMessageBar } = useMessageBar();

  const [changePlanModalOpen, setChangePlanModalOpen] = useState<boolean>(false);
  const [makePlanModalOpen, setMakePlanModalOpen] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const movePage = useNavigate();
  useImperativeHandle(ref, () => ({
    drawListFromIpCheckPlan,
  }));

  useEffect(() => {
    drawList(props.condition);
    // 협력사 권한체크
    if (!hasRole('PTN') || hasRole('ADM')) {
      setHasAuth(true);
    }
  }, []);

  const reFresh = useCallback(() => {
    drawList(current);
  }, []);

  const drawListFromIpCheckPlan = useCallback((condition) => {
    drawList(condition);
  }, []);

  const drawList = async (condition) => {
    await getListFromIpCheckPlan(condition).then((result) => {
      setCurrent(condition);
      setRowData(result);
    });
  };

  const beforeHandle = () => {
    if (!selectedRow.current) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg. IP List 중 하나를 선택해 주세요.', ' IP List 중 하나를 선택해 주세요.'),
      });
      return false;
    }
    return true;
  };

  const btnClickHandler = (e, mode) => {
    setParams((params) => ({
      ...params,
      rowSingle: selectedRow.current,
      mode: mode,
      callBack: reFresh,
    }));

    switch (mode) {
      case CrudCode.CREATE:
        setParams((params) => ({ ...params, rowSingle: '', mode: mode, callBack: reFresh }));
        setMakePlanModalOpen(true);
        break;
      case CrudCode.UPDATE:
      case CrudCode.DELETE:
        if (!beforeHandle()) return;
        setMakePlanModalOpen(true);
        break;
      default:
        if (!beforeHandle()) return;
        setChangePlanModalOpen(true);
    }
  };

  const onBtExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ip.label.IP List 점검 현황', 'IP List 점검 현황');
    book.saveAsync('ipList-Export.xlsx');
  };

  const onInitialized = useEvent((grid) => {
    //new Selector(grid);
    new FlexGridFilter(grid);
    setFlexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    // grid.hostElement.addEventListener('click', (e) => {
    //   const ht = grid.hitTest(e);
    //   if (!ht.panel?.cellType || ht.panel?.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

    //   const col = grid.columns[ht.col];
    //   const item = grid.rows[ht.row].dataItem;
    //   const len = grid.rows.length;

    //   // for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
    //   // grid.rows[ht.row].isSelected = true;
    //   // //grid.refresh();
    //   // grid.invalidate();
    // });

    grid.hostElement.addEventListener('dblclick', (e) => {
      const ht = grid.hitTest(e);
      if (!ht.panel?.cellType || ht.panel?.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      const len = grid.rows.length;

      setHitTest(ht); //클릭 위치정보
      setFlexItem(item); //클릭 Row Data
      setFieldId(col.binding); //클릭 Cell Binding Field 명

      switch (item.ipCheckStatus) {
        case 'PLAN':
          if (hasAuth) btnClickHandler(e, CrudCode.UPDATE);
          break;
        case 'FAT_MAKER':
          movePage('/ip/maintenance/check-result-record', {
            state: {
              ipCheckPlanNo: grid.rows[ht.row]._data.ipCheckPlanNo,
              isptTpCd: item.isptTpCd,
            },
          }); //매개변수 확인 필요
          break;
        case 'FAT_ENSOL':
          movePage('/ip/maintenance/check-result-record', {
            state: {
              ipCheckPlanNo: grid.rows[ht.row]._data.ipCheckPlanNo,
              isptTpCd: item.isptTpCd,
            },
          }); //매개변수 확인 필요
          break;
        case 'SAT_MAKER':
          movePage('/ip/maintenance/check-result-record', {
            state: {
              ipCheckPlanNo: grid.rows[ht.row]._data.ipCheckPlanNo,
              isptTpCd: item.isptTpCd,
            },
          }); //매개변수 확인 필요
          break;
        case 'SAT_ENSOL':
          movePage('/ip/maintenance/check-result-record', {
            state: {
              ipCheckPlanNo: grid.rows[ht.row]._data.ipCheckPlanNo,
              isptTpCd: item.isptTpCd,
            },
          }); //매개변수 확인 필요
          break;
        case 'COMPLETE':
          // props.setRowSingle(rowSingle);
          movePage('/ip/maintenance/check-result', {
            state: { ipCheckPlanNo: grid.rows[ht.row]._data.ipCheckPlanNo },
          }); //매개변수 확인 필요
          break;
        default:
          break;
      }
    });
  });

  const onFlexGridSelectionChanged = useEvent((s, e) => {
    if (s.rows == 0) return;
    const item = s.rows[e.row].dataItem;

    if (item?.ipCheckPlanNo) {
      item.fatDate = {
        startDate: item.fatPlanStartDate?.substr(0, 10),
        endDate: item.fatPlanEndDate?.substr(0, 10),
      };
      item.satDate = {
        startDate: item.satPlanStartDate?.substr(0, 10),
        endDate: item.satPlanEndDate?.substr(0, 10),
      };
      item.rmk = '';
    }
    selectedRow.current = item;
    setDisabled(item && item.ipCheckStatus === 'PLAN' ? false : true);
  });

  const LayoutDefinition = () => {
    return [
      {
        binding: 'copCd',
        header: String(t('ip.grid.법인코드', '법인코드')),
        align: 'center',
        width: colWidthMap.copCd,
      },
      {
        binding: 'ipCheckPlanNo',
        header: String(t('ip.grid.점검계획번호', '점검계획번호')),
        align: 'center',
        width: colWidthMap.ipCheckPlanNo,
      },
      {
        binding: 'ipCheckPlanName',
        header: String(t('ip.grid.IP점검계획명', 'IP점검계획명')),
        align: 'left',
        width: colWidthMap.ipCheckPlanName,
      },
      {
        binding: 'ipCheckStatusName',
        header: String(t('com.label.진행상태', '진행상태')),
        align: 'center',
        width: colWidthMap.ipCheckStatusName,
      },
      {
        binding: 'emlSndoYN', // 메일발송유형
        visible: false,
      },
      {
        binding: 'ipCnt',
        header: String(t('ip.grid.대상IP수', '대상IP수')),
        align: 'center',
        width: colWidthMap.ipCnt,
      },
      {
        binding: 'elementCnt',
        header: String(t('ip.grid.측정개소수', '측정개소수')),
        align: 'center',
        width: colWidthMap.elementCnt,
      },
      {
        binding: 'isptTpCd',
        visible: false,
      },
      {
        binding: 'isptTpCdNm',
        header: String(t('ip.label.점검유형', '점검유형')),
        align: 'center',
        width: colWidthMap.isptTpCdNm,
      },
      {
        binding: 'emlSndoYN',
        header: String(t('ip.grid.메일발송여부', '메일발송여부')),
        align: 'center',
        width: colWidthMap.emlSndoYN,
        cssClass: 'WijmoCheck',
        cellTemplate: (params) => {
          const checked = params.value === 'Y' ? 'checked' : '';
          return (
            '<input id="heYnChk" type="checkbox" className="customStringCheckbox"' +
            checked +
            ' disabled/>'
          );
        },
      },
      {
        header: String(t('ip.label.FAT', 'FAT')),
        align: 'center',
        collapseTo: 'fatPlanStartDate',
        columns: [
          {
            binding: 'fatPlanStartDate',
            header: String(t('ip.grid.계획일자', '계획일자')),
            width: colWidthMap.fatPlanStartDate,
            cellTemplate: (ctx) => flexGridShortDate(ctx),
            // ctx.item.isptTpCd === 'NO_FAT' ? '미대상' : flexGridShortDate(ctx),
          },
          {
            binding: 'fatPlanEndDate',
            header: String(t('ip.grid.완료일자', '완료일자')),
            width: colWidthMap.fatPlanEndDate,
            cellTemplate: (ctx) => flexGridShortDate(ctx),
          },
          {
            binding: 'fatMkRst',
            header: String(t('ip.grid.Maker', 'Maker')),
            width: colWidthMap.fatMkRst,
          },
          {
            binding: 'fatEnRst',
            header: String(t('com.label.엔솔', '엔솔')),
            width: colWidthMap.fatEnRst,
          },
        ],
      },
      {
        header: String(t('ip.label.SAT', 'SAT')),
        align: 'center',
        collapseTo: 'satPlanStartDate',
        columns: [
          {
            binding: 'satPlanStartDate',
            header: String(t('ip.grid.계획일자', '계획일자')),
            width: colWidthMap.satPlanStartDate,
            cellTemplate: (ctx) => flexGridShortDate(ctx),
          },
          {
            binding: 'satPlanEndDate',
            header: String(t('ip.grid.완료일자', '완료일자')),
            width: colWidthMap.satPlanEndDate,
            cellTemplate: (ctx) => flexGridShortDate(ctx),
          },
          {
            binding: 'satMkRst',
            header: String(t('ip.grid.Maker', 'Maker')),
            width: colWidthMap.satMkRst,
          },
          {
            binding: 'satEnRst',
            header: String(t('com.label.엔솔', '엔솔')),
            width: colWidthMap.satEnRst,
          },
        ],
      },
      {
        binding: 'rmk',
        header: String(t('ip.grid.변경내역', '변경내역')),
        width: colWidthMap.rmk,
        align: 'center',
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.등록일', '등록일')),
        width: colWidthMap.dataInsDtm,
        align: 'center',
        cellTemplate: (ctx) => flexGridShortDate(ctx),
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('com.label.등록자', '등록자')),
        align: 'center',
        width: colWidthMap.dataInsUserNm,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('com.label.수정일', '수정일')),
        align: 'center',
        width: colWidthMap.dataUpdDtm,
        cellTemplate: (ctx) => flexGridShortDate(ctx),
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('com.label.수정자', '수정자')),
        align: 'center',
        width: colWidthMap.dataUpdUserNm,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.IP List 점검 현황', 'IP List 점검 현황')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData ? rowData.length : 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="Exceldown" disableRipple onClick={onBtExport}>
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" disableRipple onClick={reFresh}>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <ContentGrid style={{ height: '500px' }}>
        {
          <FlexGrid
            columnGroups={state.layoutDefinition}
            itemsSource={state.itemsSource}
            headersVisibility="Column"
            autoGenerateColumns={false}
            stickyHeaders={true}
            isReadOnly={true}
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: '500px' }}
          />
        }
        {/* <CustomGrid
          layoutDefinition={state.layoutDefinition}
          rowData={state.itemsSource}
          isReadOnly={true}
          height={500}
          initialized={onInitialized}
          // onChangeCheckedItem={(items) => setCheckedItems(items)}
        /> */}
        {hasAuth && (
          <GlobalBtnGroup>
            <Button
              css={IconButton.button}
              className="date"
              disableRipple
              onClick={(e) => {
                btnClickHandler(e, '');
              }}
            >
              {t('com.button.계획변경', '계획변경')}
            </Button>
            <Button
              css={IconButton.button}
              className="write"
              disableRipple
              onClick={(e) => {
                btnClickHandler(e, CrudCode.CREATE);
              }}
            >
              {t('com.button.신규', '신규')}
            </Button>
            <Button
              css={IconButton.button}
              className="write"
              disableRipple
              disabled={isDisabled}
              onClick={(e) => {
                btnClickHandler(e, CrudCode.UPDATE);
              }}
            >
              {t('com.button.수정', '수정')}
            </Button>
            <Button
              css={IconButton.button}
              className="delete"
              disableRipple
              disabled={isDisabled}
              onClick={(e) => {
                btnClickHandler(e, CrudCode.DELETE);
              }}
            >
              {t('com.label.삭제', '삭제')}
            </Button>
          </GlobalBtnGroup>
        )}
      </ContentGrid>
      {changePlanModalOpen && (
        <ChangePlanModal
          params={params}
          open={changePlanModalOpen}
          close={() => setChangePlanModalOpen(false)}
        />
      )}
      {makePlanModalOpen && (
        <MakePlanModal
          params={params}
          open={makePlanModalOpen}
          close={() => setMakePlanModalOpen(false)}
        />
      )}
    </>
  );
});

StatusManagementGrid.displayName = 'StatusManagementGrid';
export default memo(StatusManagementGrid);
