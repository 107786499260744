import { create } from 'zustand';
import { getTranslatedMessages } from 'apis/admin/TranslatedMessage';
import i18n from 'i18n';
import useSessionStore from 'stores/useSessionStore';
import { LangType } from 'models/common/Session';
import message_ko from 'locales/message_ko.json';
import message_en from 'locales/message_en.json';
import message_pl from 'locales/message_pl.json';
import message_zhC from 'locales/message_zhC.json';
import message_zhT from 'locales/message_zhT.json';
import '@grapecity/wijmo.cultures/wijmo.culture.ko';
import '@grapecity/wijmo.cultures/wijmo.culture.en';
import '@grapecity/wijmo.cultures/wijmo.culture.zh';
import '@grapecity/wijmo.cultures/wijmo.culture.id';
import '@grapecity/wijmo.cultures/wijmo.culture.pl';
import { changeCultureSync } from '@grapecity/wijmo';

interface LanguageState {
  currentLanguage: LangType;
  initLanguage: () => void;
  changeLanguage: (newLocale: LangType, callBack?: () => void) => void;
  refreshLanguage: () => void;
}

/**
 * @description translation 메시지 정보를 서버에서 가져온다.
 * @param newLanguage 새로 바뀔 언어코드
 * @returns api 성공 여부.
 */
const changeTranslatedMessages = async (newLangCd: LangType) => {
  const translations = await getTranslatedMessages(newLangCd);
  // i18n.addResourceBundle(newLangCd, 'translation', translations);
  i18n.changeLanguage(newLangCd);
  i18n.reloadResources();
};

const changeTranslatedGrid = (newLangCd: LangType) => {
  let langCd = 'ko';
  if (['zh', 'zhC'].includes(newLangCd)) {
    langCd = 'zh-CN';
  } else if ('zhT' === newLangCd) {
    langCd = 'zh-TW';
  } else if (['ko', 'en', 'pl', 'id'].includes(newLangCd)) {
    langCd = newLangCd;
  }
  changeCultureSync(langCd);
};

const useLanguageStore = create<LanguageState>((set, get) => {
  return {
    currentLanguage: useSessionStore.getState().langCd || 'ko',
    initLanguage: async () => {
      i18n.addResourceBundle('ko', 'translation', message_ko);
      i18n.addResourceBundle('en', 'translation', message_en);
      i18n.addResourceBundle('pl', 'translation', message_pl);
      i18n.addResourceBundle('zhC', 'translation', message_zhC);
      i18n.addResourceBundle('zhT', 'translation', message_zhT);
      const langCd = useSessionStore.getState().langCd || 'ko';
      changeTranslatedGrid(langCd);
      changeTranslatedMessages(langCd);
      set({ currentLanguage: langCd });
      useSessionStore.getState().setLangCd(langCd);
    },
    changeLanguage: async (newLanguage: LangType, callBack?: () => void) => {
      changeTranslatedGrid(newLanguage);
      changeTranslatedMessages(newLanguage);
      set({ currentLanguage: newLanguage });
      useSessionStore.getState().setLangCd(newLanguage);
      callBack && callBack();
    },
    refreshLanguage: async () => {
      const { currentLanguage } = get();
      const langCd = useSessionStore.getState().langCd || currentLanguage;
      changeTranslatedGrid(langCd);
      changeTranslatedMessages(langCd);
    },
  };
});

export default useLanguageStore;
