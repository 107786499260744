/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { findGatingVerCkList } from 'apis/gtng/popup/GatingCheckSheetVer';
import { GatingCheckSheetVer } from 'models/gtng/popup/GatingCheckSheetVer';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';

const GatingCheckSheetVerRetrievePopUp = (props: any) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<GatingCheckSheetVer[]>([]);

  useEffect(() => {
    const code = { gatingStepCode: props.gatingStepCode, gatingTypeCode: props.gatingTypeCode };
    findGatingVerCkList(code).then((result: GatingCheckSheetVer[]) => {
      setRowData(result);
    });
  }, [props.initParam]);

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'versionNo',
      header: String(t('gtng.grid.버전', '버전')),
      align: 'center',
    },
    {
      binding: 'changeReasonDesc',
      header: String(t('gtng.label.변경사유', '변경사유')),
      align: 'left',
      width: '*',
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('com.label.최종수정자', '최종수정자')),
      align: 'center',
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('com.label.최종수정일', '최종수정일')),
      align: 'center',
      width: 150,
    },
  ];

  const onClose = () => {
    props.setPopup(false);
  };

  return (
    <>
      <CustomDialog
        title={t('gtng.label.Check Sheet Version 조회', 'Check Sheet Version 조회')}
        size={'md'}
        open={true}
        onClose={onClose}
      >
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={300}
          align="center"
          isReadOnly={true}
          isSelector={false}
          isFilter={false}
          allowPinning={false}
        />
      </CustomDialog>
    </>
  );
};

export default React.memo(GatingCheckSheetVerRetrievePopUp);
