import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { NewSensorSolutionReq } from 'models/sensor/NewSensorSolutionReq';
import { callApi } from 'utils/ApiUtil';
import { SensorSolution } from '../../models/sensor/SensorSolution';

/**
 * 센서 솔루션 정보 조회
 * @param slunId
 */
export const findSensorSolution = async (slunId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/sensor/solution/${slunId}`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<SensorSolution> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as SensorSolution;
};

/**
 * 신규센서요청 상세 조회
 * @param condition
 */
export const findClickStatusContent = async (slunId: string) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/findClickStatusContent`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { slunId },
    //bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as any;
};

/**
 * 신규센서요청 첨부파일
 * @param condition
 */
export const generateSensorRequestAtchFileGrId = async (slunId: string) => {
  const condition = {
    slunId,
  };

  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/newSensorRequest/attach`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<string> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * 신규센서요청 메일발송(USER)
 * @param condition
 */
export const sendMail = async (condition: NewSensorSolutionReq) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/sendMail`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<NewSensorSolutionReq | string> = await callApi(request);
  return response;
};

/**
 * 신규센서요청 진행 이력 조회
 * @param condition
 */
export const findSensorProgressStatusList = async (condition: NewSensorSolutionReq) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/findSensorProgressStatusList`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<NewSensorSolutionReq[]> = await callApi(request);
  return response;
};

/**
 * 신규센서요청 저장 및 진행상태(SYSTEM)
 * @param condition
 */
export const saveAndProcessStatus = async (condition: NewSensorSolutionReq) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/saveAndProgresStatus`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<NewSensorSolutionReq | number | string> = await callApi(request);
  return response;
};

/**
 * 신규센서요청 시퀀스 생성
 */
export const createRequestInfo = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/sensor/createRequestInformation`,
    serviceName: ServiceName.ELM_BE,
    //bodyParams: condition,
  };

  const response: CommonResponse<NewSensorSolutionReq> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as NewSensorSolutionReq;
};
// string
