import styled from '@emotion/styled';
import { Button } from '@mui/material';
import {
  BgColor,
  CommonBG,
  FontColor,
  ControlLinedBG,
  CommonText,
  CommonBorder,
  ControlLinedBorder,
} from 'ui/theme/Color';
import { SVGIcon } from 'components/buttons/IconSVG';
import { ButtonSize, ButtonStyle, ButtonDesign } from 'components/buttons/ButtonStyle';

export const CloseBtn = styled.button`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 24px;
  right: 24px;
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${CommonText.Light};
    -webkit-mask: ${SVGIcon.IcoClose};
    mask-image: ${SVGIcon.IcoClose};
  }
  &:focus,
  &:active {
    border: none !important;
    outline: none !important;
    background: ${ControlLinedBG.BasicActive};
    &:before {
      background: ${CommonText.Basic};
    }
  }
  &:hover {
    background: ${ControlLinedBG.BasicHover};
  }
`;

export const ExUpload = styled.div`
  margin-left: 4px;
  height: 28px;
  input[type='file'] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
  label {
    ${ButtonSize.sm}
    ${ButtonStyle.outlineNormal}
    ${ButtonDesign.IconLfSM}   
    &:before {
      -webkit-mask: ${SVGIcon.IcoEXUp};
      mask-image: ${SVGIcon.IcoEXUp};
    }
  }
`;

export const ExUploadBtn = () => {
  return (
    <ExUpload>
      <input type="file" id="file" />
      <label htmlFor="file">업로드</label>
    </ExUpload>
  );
};

export const FileuploadBtn = styled.button`
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: ${CommonText.Secondary};
  span {
    color: ${CommonText.Primary};
  }
`;

export const FileUpload = styled.div`
  min-height: 80px;
  background: ${CommonBG.Elevation};
  border-radius: 2px;
  padding: 8px;
  border: 1px dashed ${CommonBorder.Strong};
  position: relative;
  &.hover {
    background: ${CommonBG.Deeper};
  }
  &.focus,
  &.active {
    background: ${CommonBG.Deeper};
    border: 1px solid ${ControlLinedBorder.BasicActive};
  }
  input[type='file'] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
  label {
    display: inline-block;
    width: 100%;
    height: 48px;
    padding: 2px 0 0px 36px;
    font-size: 13px;
    font-weight: 400;
    color: ${CommonText.Lighter};
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0px;
      left: 7px;
      background: ${CommonText.Light};
      -webkit-mask: ${SVGIcon.IcoFile};
      mask-image: ${SVGIcon.IcoFile};
    }
  }
  .volumn {
    position: absolute;
    text-align: right;
    top: 10px;
    right: 8px;
    font-size: 13px;
    font-weight: 400;
    color: ${CommonText.Light};
    span {
      color: ${CommonText.Basic};
    }
  }
`;

export const UploadFile = styled.div`
  &.error {
    & > span {
      display: inline-block;
      margin-top: 4px;
      font-size: 13px;
      font-weight: 400;
      color: ${CommonText.Error};
    }
    .uploadFile {
      border: 1px solid ${CommonBorder.Error};
    }
  }
  &.comfirm {
    & > span {
      display: inline-block;
      margin-top: 4px;
      font-size: 13px;
      font-weight: 400;
      color: ${CommonText.Confirmed};
    }
    .uploadFile {
      border: 1px solid ${CommonBorder.Confirmed};
    }
  }
  .uploadFile {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 28px 0 8px;
    border-radius: 2px;
    position: relative;
    background: ${CommonBG.Elevation};
    border: 1px solid ${CommonBorder.Basic};
    & + .uploadFile {
      margin-top: 2px;
    }
    div {
      display: inline-block;
      font-size: 13px;
      font-weight: 400;
      white-space: wrap;
      word-break: break-all;
      position: relative;
      padding-left: 24px;
      margin: 8px 0;
      &:before {
        content: '';
        clear: both;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
      }
      &.excel {
        &:before {
          background: ${SVGIcon.IcoExcel};
        }
      }
      &.ppt {
        &:before {
          background: ${SVGIcon.icoPpt};
        }
      }
      &.pdf {
        &:before {
          background: ${SVGIcon.IcoPdf};
        }
      }
      &.docu {
        &:before {
          background: ${SVGIcon.IcoEtc};
        }
      }
      &.word {
        &:before {
          background: ${SVGIcon.IcoWord};
        }
      }
      &.zip {
        &:before {
          background: ${SVGIcon.IcoZip};
        }
      }
      &.img {
        &:before {
          background: ${SVGIcon.IcoImg};
        }
      }
    }
    .fileVolumn {
      padding-left: 24px;
      font-size: 12px;
      font-weight: 400;
      text-align: right;
      color: ${CommonText.Lighter};
    }
    button {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      min-width: 16px !important;
      width: 16px;
      height: 16px;
      &:before {
        content: '';
        clear: both;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        background: ${CommonText.Lighter};
        -webkit-mask: ${SVGIcon.IcoInputDel};
        mask-image: ${SVGIcon.IcoInputDel};
      }
    }
  }
`;

export const tp = {
  tooltip: `
  `,
};

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const BlueButton = styled(Button)`
  padding: 10px 14px;
  height: 36px;
  margin: 0 3px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  background-color: #1f7795;
  line-height: 15px;

  &:hover {
    background-color: #135678;
  }
  &:active,
  &:focus {
    background-color: #3ec2cf;
  }
  &:disabled {
    background-color: #a5c8d4;
    color: #fff;
  }
  svg {
    margin-right: 5px;
    font-size: 1.125rem;
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const GreyButton = styled(Button)`
  padding: 10px 14px;
  height: 36px;
  margin: 0 3px;
  border-radius: 3px;
  color: #fff;
  font-size: 13px;
  background-color: ${BgColor.Gray700};
  &:hover {
    background-color: ${FontColor.Gray400};
  }
  &:disabled {
    background-color: ${FontColor.Gray600};
    color: #fff;
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const BlueLineButton = styled(Button)`
  padding: 10px 14px;
  height: 36px;
  margin: 0 3px;
  border-radius: 3px;
  color: #3ec2cf;
  font-size: 13px;
  border: 1px solid #3ec2cf;
  background-color: #fff;
  &:hover {
    border-color: #3ab3bf;
  }

  &.smallIcon {
    width: 25px;
    height: 25px;
    min-width: auto;
    padding: 0;
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const GreyLineButton = styled(Button)`
  padding: 10px 14px;
  height: 36px;
  margin: 0 3px;
  color: #666;
  font-size: 13px;
  line-height: 15px;
  border-radius: 3px;
  border: solid 1px #b9bcbb;
  background-color: #fff;

  &:hover {
    border-color: #d5d5d5;
  }

  &.small {
    min-width: auto;
    padding: 6px 8px;
    height: 28px;
    font-size: 12px;
  }

  &.medium {
    padding: 6px 8px;
    height: 32px;
    font-size: 12px;
  }

  &.alignTop {
    vertical-align: top;
  }
  svg {
    margin-right: 5px;
    font-size: 1.125rem;
  }

  &.marginLR0 {
    margin-left: 0;
    margin-riht: 0;
  }

  &.marginTB3 {
    margin-top: 3px;
    margin-bottom: 3px;
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const IconButton = styled(Button)`
  min-width: auto;
  padding: 0;
  margin: 0 5px;
  color: #666;

  &:hover {
    background-color: transparent;
  }

  &.buttonHelp {
    vertical-align: sub;
    svg {
      fill: ${BgColor.Gray700};
    }
  }

  & + & {
    margin-left: 0;
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const IconLineButton = styled(Button)`
  width: 25px;
  height: 25px;
  min-width: auto;
  padding: 0;
  margin: 0 3px;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  color: #666;

  &:hover {
    background-color: transparent;
  }

  &.blueLine {
    border-color: #3ec2cf;
    color: #3ec2cf;
  }

  &.large {
    width: 32px;
    height: 32px;
  }

  .xs {
    font-size: 15px;
  }
`;
