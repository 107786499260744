import { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { SVGIcon } from 'components/buttons/IconSVG';
import { CommonBorder, CommonText, ControlFilldBG, ControlLinedBG } from 'ui/theme/Color';

interface PaginationProps {
  totalCount: number;
  defaultPageNo?: number;
  defaultPageSize?: number;
  onChangePageSize: (param: any) => void;
  onClickPageNo: (param: number) => void;
}
export interface PaginationRef {
  setSelectNo: (no: number) => void;
  setPageSize: (pageSize: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
}

// eslint-disable-next-line react/display-name
export const Pagination = forwardRef((props: PaginationProps, ref?: React.Ref<PaginationRef>) => {
  const defalutPageLength = 10;
  const [pageLength, setPageLength] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(props.defaultPageSize ?? 10);
  const [pageNoList, setPageNoList] = useState<number[]>([]);
  const [selectedNo, setSelectedNo] = useState<number>(props.defaultPageNo ?? 1);
  const [lastPageNo, setLastPageNo] = useState<number>(10);

  useImperativeHandle(ref, () => ({
    setSelectNo: (no: number) => {
      setSelectedNo(no);
      const index = Math.floor((no - 1) / pageSize);
      const firstNoOfRange = index * pageSize + 1;

      if (lastPageNo < defalutPageLength) {
        setPageLength(lastPageNo);
        setPageNoList(getRange(firstNoOfRange, lastPageNo));
      } else if (firstNoOfRange > defalutPageLength && lastPageNo % defalutPageLength != 0) {
        setPageLength(lastPageNo % defalutPageLength);
        setPageNoList(getRange(firstNoOfRange, lastPageNo % defalutPageLength));
      } else {
        setPageLength(defalutPageLength);
        setPageNoList(getRange(firstNoOfRange, defalutPageLength));
      }
    },
    setPageSize: (pageSize: number) => {
      setPageSize(pageSize);
    },
    handlePageSizeChange: (value) => {
      setPageSize(value);
      const calLastPageNo = Math.floor(props.totalCount / value);
      const lastPageNo =
        props.totalCount / value === calLastPageNo ? calLastPageNo : calLastPageNo + 1;
      setLastPageNo(lastPageNo);
      setSelectedNo(1);
      props.onChangePageSize(value);
    },
  }));

  const getRange = (start: number, length: number) => {
    return Array.from({ length: length }, (_, i) => start + i);
  };

  const handlePageNoClick = (no: number) => {
    setSelectedNo(no);
    props.onClickPageNo(Number(no));
  };

  const handleFirstPageClick = () => {
    setSelectedNo(1);
    props.onClickPageNo(1);
    if (lastPageNo < defalutPageLength) {
      setPageNoList(getRange(1, pageLength));
    } else {
      setPageLength(defalutPageLength);
      setPageNoList(getRange(1, defalutPageLength));
    }
  };

  const handleDoubleArrowLeftClick = () => {
    if (selectedNo - pageLength < 1) {
      handleFirstPageClick();
    } else {
      if (lastPageNo < defalutPageLength) {
        setPageNoList(getRange(pageNoList[0] - pageLength, pageLength));
      } else {
        setPageLength(defalutPageLength);
        setPageNoList(getRange(pageNoList[0] - defalutPageLength, defalutPageLength));
      }
      props.onClickPageNo(selectedNo - pageLength);
      setSelectedNo((prevNo) => prevNo - pageLength);
    }
  };

  const handleDoubleArrowRightClick = () => {
    if (selectedNo + pageLength > lastPageNo) {
      handleLastPageClick();
    } else if (
      Math.floor((selectedNo + pageLength) / pageLength) == Math.floor(lastPageNo / pageLength)
    ) {
      setPageNoList(
        getRange(Math.floor(lastPageNo / pageLength) * pageLength + 1, lastPageNo % pageLength)
      );
      props.onClickPageNo(selectedNo + pageLength);
      setSelectedNo((prevNo) => prevNo + pageLength);
    } else {
      setPageNoList(getRange(pageNoList[0] + pageLength, pageLength));
      props.onClickPageNo(selectedNo + pageLength);
      setSelectedNo((prevNo) => prevNo + pageLength);
    }
  };

  const handleLastPageClick = () => {
    props.onClickPageNo(lastPageNo);
    setSelectedNo(lastPageNo);
    if (lastPageNo > defalutPageLength) {
      let start = Math.floor(lastPageNo / pageLength) * pageLength + 1;
      let length = lastPageNo % pageLength;
      length = length < 1 ? pageLength : length;
      if (start > lastPageNo) {
        start = lastPageNo - length + 1;
      }
      setPageNoList(getRange(start, length));
    }
  };

  useEffect(() => {
    const index = Math.floor((selectedNo - 1) / pageSize);
    const firstNoOfRange = index * pageSize + 1;

    if (lastPageNo < defalutPageLength) {
      setPageLength(lastPageNo);
      setPageNoList(getRange(firstNoOfRange, lastPageNo));
    } else if (firstNoOfRange > defalutPageLength && lastPageNo % defalutPageLength != 0) {
      setPageLength(lastPageNo % defalutPageLength);
      setPageNoList(getRange(firstNoOfRange, lastPageNo % defalutPageLength));
    } else {
      setPageLength(defalutPageLength);
      setPageNoList(getRange(firstNoOfRange, defalutPageLength));
    }
  }, [lastPageNo]);

  useEffect(() => {
    const lastPageNo =
      props.totalCount % pageSize == 0
        ? props.totalCount / pageSize
        : Math.floor(props.totalCount / pageSize) + 1;
    setLastPageNo(lastPageNo);
  }, [props.totalCount]);

  return (
    <Paging>
      <FirstPage onClick={handleFirstPageClick} />
      <PrePage onClick={handleDoubleArrowLeftClick} />
      {pageNoList &&
        pageNoList.map((no) => (
          <Link
            key={no}
            to="#"
            className={no === selectedNo ? 'current' : ''}
            onClick={() => handlePageNoClick(no)}
          >
            {no}
          </Link>
        ))}
      <NextPage onClick={handleDoubleArrowRightClick} />
      <EndPage onClick={handleLastPageClick} />
    </Paging>
  );
});

const FirstPage = styled.button`
  width: 28px;
  height: 28px;
  margin-right: 4px;
  position: relative;
  outline: none !important;
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 16px;
    height: 17px;
    position: absolute;
    top: 50%;
    left: calc(50% - 8px);
    transform: translateY(-50%) rotate(180deg);
    background: ${SVGIcon.IcoLineChevronDouble};
  }
`;

const PrePage = styled.button`
  width: 28px;
  height: 28px;
  margin-right: 4px;
  position: relative;
  outline: none !important;
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    transform: rotate(90deg);
    background: ${CommonText.Light};
    -webkit-mask: ${SVGIcon.IcoLineChevron};
    mask-image: ${SVGIcon.IcoLineChevron};
  }
`;

const NextPage = styled.button`
  width: 28px;
  height: 28px;
  margin-right: 4px;
  position: relative;
  outline: none !important;
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    transform: rotate(270deg);
    background: ${CommonText.Light};
    -webkit-mask: ${SVGIcon.IcoLineChevron};
    mask-image: ${SVGIcon.IcoLineChevron};
  }
`;

const EndPage = styled.button`
  width: 28px;
  height: 28px;
  margin-right: 4px;
  position: relative;
  outline: none !important;
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 16px;
    height: 17px;
    position: absolute;
    top: 50%;
    left: calc(50% - 8px);
    transform: translateY(-50%);
    background: ${SVGIcon.IcoLineChevronDouble};
  }
`;

const Paging = styled.div`
  height: 32px;
  border: 1px solid ${CommonBorder.Basic};
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    & + a {
      margin-left: 2px;
    }
  }
  a:link,
  a:visited,
  a:active {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 700;
    text-decoration: none;
    color: ${CommonText.Light};
  }
  a:hover {
    text-decoration: none;
    background: ${ControlLinedBG.BasicHover};
    color: ${CommonText.Light};
  }
  .current {
    background: ${ControlFilldBG.Inverse};
    color: ${CommonText.Inverse} !important;
  }
`;
