import { PaginationResponse } from 'models/common/Pagination';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { BaseImformationCondition } from 'models/ip/BaseImformation';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';

/**설비군 목록 조회 */
export const findEquipmentGroup = async (condition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/common/equGroupSelect`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<EquipmentGroupCondition[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as EquipmentGroupCondition[];
};

/**설비군 엑셀 다운로드 */
export const getEquGroupDownload = async () => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/common/equGroupDownload`,
    serviceName: ServiceName.ELM_BE,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) {
    downloadFile(response.data, 'data.xlsx'); //'data.xlsx' `${condition.fileName}`
  }
};

export const downloadFile = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
