/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { AgGridReact } from 'ag-grid-react';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { Employee } from 'models/admin/Employee';
import {
  deleteNotificationGroup,
  getNotificationGroupDivisions,
  getNotificationGroupUsers,
  getNotificationGroups,
  saveNotificationGroupUsers,
  saveNotificationGroups,
} from 'apis/admin/Notification';
import { CommonYN } from 'models/common/Common';
import {
  NotificationCondition,
  NotificationGroup,
  NotificationGroupDivision,
  NotificationGroupUser,
} from 'models/admin/Notification';
import { CrudCode } from 'models/common/Edit';
import { Button } from '@mui/material';
import { ContentFlex } from '../../components/layouts/ContentFlex';
import { ContentSection } from '../../components/layouts/ContentSection';
import EmployeeModal from '../../components/modals/common/EmployeeModal';
import { IconButton } from '../../components/buttons/IconSVG';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from '../../components/layouts/ContentLayout';
import useSessionStore from 'stores/useSessionStore';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import { DataMap, Row } from '@grapecity/wijmo.grid';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as input from '@grapecity/wijmo.input';

export interface SearchParam {
  ntdkNm?: string;
}

export const NotificationManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { gridNoRowsTemplate } = useSessionStore();
  const [userFlexRef, setUserFlexRef] = useState<any>();
  const [groupFlexRef, setGroupFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const validateSaveCondition = (): boolean => {
    const filteredDelData = notificationUserList.filter((item) => item.crudKey !== CrudCode.DELETE);
    if (
      notificationGroupList.some(
        (item) => !item.ntdkNm || item.ntdkNm === '' || item.ntdkNm === null
      )
    ) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.통보처명을 입력해주세요.', '통보처명을 입력해주세요.'),
      });
      return false;
    }
    if (
      filteredDelData.some(
        (item) => !item.aprNotfUserId || item.aprNotfUserId === '' || item.aprNotfUserId === null
      )
    ) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.사용자를 입력해주세요.', '사용자를 입력해주세요.'),
      });
      return false;
    }
    if (
      filteredDelData.some(
        (item) => !item.ntdkDivsCd || item.ntdkDivsCd === '' || item.ntdkDivsCd === null
      )
    ) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.통보처 구분을 입력해주세요.', '통보처 구분을 입력해주세요.'),
      });
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (!validateSaveCondition()) return;
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      noCallback: () => {
        return false;
      },
      yesCallback: saveNotification,
    });
  };

  const saveNotification = async () => {
    const saveNotificationGroup = notificationGroupList.filter(
      (group) => group.crudKey === CrudCode.CREATE || group.crudKey === CrudCode.UPDATE
    );

    const saveNotificationUserGroup = notificationUserList.filter(
      (group) =>
        (group.crudKey === CrudCode.CREATE ||
          group.crudKey === CrudCode.UPDATE ||
          CrudCode.DELETE) &&
        group.aprNotfUserId !== undefined
    );

    console.log('saveNotificationUserGroup', saveNotificationUserGroup);

    const groupSaveResult =
      saveNotificationGroup &&
      (await saveNotificationGroups(saveNotificationGroup).then((result) => {
        if (!result) {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return false;
        } else return result;
      }));

    const userSaveResult =
      saveNotificationUserGroup &&
      (await saveNotificationGroupUsers(saveNotificationUserGroup).then((result) => {
        if (!result) {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return false;
        } else return result;
      }));

    openMessageBar({
      type: 'confirm',
      content: t('com.label.저장되었습니다.', '저장되었습니다.'),
    });
    getNotificationGroupList();
    getNotificationGroupUsersList();
    setSelectedGroupId('');
  };

  const groupRef = useRef<AgGridReact<NotificationGroup>>(null);
  const userRef = useRef<AgGridReact<NotificationGroupUser>>(null);

  // 통보처 이름 검색
  const [searchParam, setSearchParam] = useState<SearchParam>({});
  const [ntdkNm, setNtdkNm] = useState<string>('');
  const [selectedGroupID, setSelectedGroupId] = useState<string>('');
  const [groupDivisions, setGroupDivisions] = useState<NotificationGroupDivision[]>([]);

  // list
  const [notificationGroupList, setNotificationGroupList] = useState<NotificationGroup[]>([]);
  const [notificationUserList, setNotificationUserList] = useState<NotificationGroupUser[]>([]);

  const [EmployeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);

  const [rowIndex, setRowIndex] = useState<string>('');

  const init = async () => {
    setGroupDivisions(await getNotificationGroupDivisions());
    getNotificationGroupList();
  };

  const getNotificationGroupList = async (ntdkNm?: string) => {
    setNotificationGroupList(
      (await getNotificationGroups({
        ntdkNm: ntdkNm ?? '',
        useYn: CommonYN.Y,
      } as NotificationCondition)) ?? []
    );
  };

  const getNotificationGroupUsersList = async () => {
    await getNotificationGroupUsers({ ntdkId: selectedGroupID }).then((result) => {
      if (result) {
        setNotificationUserList(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (selectedGroupID !== '') {
      getNotificationGroupUsersList();
    }
  }, [selectedGroupID]);

  const handleNtdkNmInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  }, []);

  const handleSearch = () => {
    setSelectedGroupId('');
    getNotificationGroupList(searchParam?.ntdkNm);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const btnReload = () => {
    setSearchParam({ ntdkNm: '' });
  };

  const handleSelectUser = (emp: Employee[]) => {
    flexItem['aprNotfUserId'] = emp[0].userId;
    flexItem['empNm'] = emp[0].empNm;
    flexItem['deptNm'] = emp[0].deptNm;
    flexItem['ofcPhn'] = emp[0].ofcPhn;
    flexItem['emlSvrDmnIfoNm'] = emp[0].emlSvrDmnIfoNm;

    setEmployeeModalOpen(false);
  };

  const handleAddMasterRow = () => {
    setNotificationGroupList([
      { crudKey: CrudCode.CREATE, useYn: CommonYN.Y, ntdkId: '' } as NotificationGroup,
      ...notificationGroupList,
    ]);
  };

  const handleDeleteMasterRow = () => {
    const selectedRowNodes = groupFlexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }

    const notNulldata = isSelected.filter((item) => item.dataItem.crudKey !== CrudCode.CREATE);
    const delData = notNulldata.map(({ dataItem }) => dataItem);
    openCommonModal({
      modalType: 'confirm',
      content: t('admin.msg.해당 통보처를 삭제하시겠습니까?', '해당 통보처를 삭제하시겠습니까?'),
      yesCallback: () => {
        deleteMasterRow(delData);
      },
    });
  };

  const deleteMasterRow = async (selectedIds: NotificationGroup) => {
    const result = await deleteNotificationGroup(selectedIds);
    if (result) {
      openMessageBar({
        type: 'confirm',
        content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
      });
    } else {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제 중 오류가 발생했습니다.', '삭제 중 오류가 발생했습니다.'),
      });
    }
    getNotificationGroupList();
  };

  const handleAddDetailRow = () => {
    if (selectedGroupID === '') {
      openMessageBar({
        type: 'error',
        content: t(
          'admin.msg.사용자를 추가할 통보처 그룹을 선택해주세요. 새로 등록한 통보처에 대해서는 추가할 수 없습니다.',
          '사용자를 추가할 통보처 그룹을 선택해주세요. 새로 등록한 통보처에 대해서는 추가할 수 없습니다.'
        ),
      });
      return;
    }

    setNotificationUserList([
      {
        crudKey: CrudCode.CREATE,
        useYn: CommonYN.Y,
        ntdkId: selectedGroupID,
      } as NotificationGroupUser,
      ...notificationUserList,
    ]);
  };

  const handleDeleteDetailRow = () => {
    const selectedRowNodes = userFlexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    console.log('isSelected', isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }
    const notNulldata = isSelected.filter((item) => item.dataItem.crudKey !== CrudCode.CREATE);
    const delData = isSelected.map(({ dataItem }) => dataItem);
    delData.forEach((item) => {
      item.crudKey = CrudCode.DELETE;
    });
    const filteredData = notificationUserList.filter((element) => element !== undefined);
    setNotificationUserList(filteredData);
  };

  const groupLayoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('com.label.상태', '상태')),
      width: 50,
      binding: 'crudKey',
      isReadOnly: true,
      align: 'center',
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'detail',
      header: String(t('com.label.상세', '상세')),
      width: 45,
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: (params) =>
        params.item['crudKey'] === CrudCode.CREATE || params.item['crudKey'] === CrudCode.DELETE
          ? ''
          : `<Button id="btnDetail"></Button>`,
    },
    {
      binding: 'ntdkId',
      header: String(t('admin.grid.통보처ID', '통보처ID')),
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'ntdkNm',
      header: String(t('admin.grid.통보처명', '통보처명')),
      align: 'left',
      width: '*',
      isReadOnly: false,
    },
  ];

  const userLayoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('com.label.상태', '상태')),
      width: 50,
      binding: 'crudKey',
      isReadOnly: true,
      align: 'center',
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'ntdkDivsCd',
      header: String(t('admin.grid.통보처 구분', '통보처 구분')),
      width: 140,
      align: 'center',
      isReadOnly: false,
      dataMap: new DataMap(groupDivisions, 'ntdkDivsCd', 'ntdkDivsNm'),
    },
    {
      binding: 'aprNotfUserId',
      header: String(t('com.label.아이디', '아이디')),
      align: 'left',
      width: 140,
      isReadOnly: true,
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (item.item.aprNotfUserId === undefined) {
          return `<span>${item.text}</span><button id="btnUserId" />`;
        } else {
          return `<span>${item.item.aprNotfUserId}</span><button id="btnUserId" />`;
        }
      },
    },
    {
      binding: 'empNm',
      header: String(t('admin.grid.이름', '이름')),
      align: 'center',
      width: 150,
      isReadOnly: true,
    },
    {
      binding: 'deptNm',
      header: String(t('admin.grid.부서명', '부서명')),
      align: 'center',
      width: 150,
      isReadOnly: true,
    },
    {
      binding: 'ofcPhn',
      header: String(t('admin.grid.회사전화번호', '회사전화번호')),
      align: 'center',
      width: 150,
      isReadOnly: true,
    },
    {
      binding: 'emlSvrDmnIfoNm',
      header: String(t('admin.grid.이메일', '이메일')),
      align: 'center',
      width: '*',
      isReadOnly: true,
    },
  ];

  const btnNotiGroupExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(groupFlexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'GroupList';
    book.saveAsync(getExcelFileName(t('com.label.통보처그룹목록', '통보처그룹목록')));
  };

  const btnUserExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(userFlexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'User';
    book.saveAsync(getExcelFileName(t('com.label.사용자목록', '사용자목록')));
  };

  const groupOnInitialized = (grid) => {
    setGroupFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          // 상세보기
          case 'btnDetail':
            setSelectedGroupId(item.ntdkId);
            break;
        }
      }
    });
  };

  const userOnInitialized = (grid) => {
    setUserFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;
      setFlexItem(item);

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnUserId':
            setEmployeeModalOpen(true);
            break;
        }
      }
    });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('admin.label.통보처명', '통보처명')}</label>
                <CustomInputWithSearch
                  onChange={handleNtdkNmInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder={String(
                    t('admin.label.통보처명을 입력해 주세요.', '통보처명을 입력해 주세요.')
                  )}
                  name="ntdkNm"
                  value={searchParam.ntdkNm}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button css={IconButton.button} className="reload" disableRipple onClick={btnReload} />
            <Button css={IconButton.button} className="find" disableRipple onClick={handleSearch}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContentFlex>
        <ContentSection className="section width30p marginT0">
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('admin.label.통보처 그룹 목록', '통보처 그룹 목록')}</h3>
              <span className="total">
                {t('com.label.총', '총')}
                <span>{notificationGroupList?.length ?? 0}</span>
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
            <ControlBtnGroup>
              <Button
                css={IconButton.button}
                className="Exceldown"
                onClick={btnNotiGroupExcelExport}
                disableRipple
              >
                {t('com.button.다운로드', '다운로드')}
              </Button>
              <Button
                css={IconButton.button}
                className="addRow"
                disableRipple
                onClick={handleAddMasterRow}
              >
                {t('com.button.행추가', '행추가')}
              </Button>
              <Button
                css={IconButton.button}
                className="delRow"
                disableRipple
                onClick={handleDeleteMasterRow}
              >
                {t('com.button.행삭제', '행삭제')}
              </Button>
            </ControlBtnGroup>
          </SubTitleLayout>
          <CustomGrid
            layoutDefinition={groupLayoutDefinition}
            initialized={groupOnInitialized}
            rowData={notificationGroupList}
            height={560}
            deferResizing={false}
            align={'center'}
          />
        </ContentSection>
        <ContentSection className="section width70p">
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('admin.label.사용자', '사용자')}</h3>
              <span className="total">
                {t('com.label.총', '총')}
                <span>{notificationUserList?.length ?? 0}</span>
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
            <ControlBtnGroup>
              <Button
                css={IconButton.button}
                className="Exceldown"
                onClick={btnUserExcelExport}
                disableRipple
              >
                {t('com.button.다운로드', '다운로드')}
              </Button>
              <Button
                css={IconButton.button}
                className="plus"
                disableRipple
                onClick={handleAddDetailRow}
              >
                {t('com.button.행추가', '행추가')}
              </Button>
              <Button
                css={IconButton.button}
                className="minus"
                disableRipple
                onClick={handleDeleteDetailRow}
              >
                {t('com.button.행삭제', '행삭제')}
              </Button>
            </ControlBtnGroup>
          </SubTitleLayout>
          <CustomGrid
            layoutDefinition={userLayoutDefinition}
            initialized={userOnInitialized}
            rowData={notificationUserList}
            height={560}
            deferResizing={false}
            align={'center'}
          />
          <GlobalBtnGroup>
            <Button css={IconButton.button} className="save" disableRipple onClick={handleSave}>
              {t('com.button.저장', '저장')}
            </Button>
          </GlobalBtnGroup>
        </ContentSection>
      </ContentFlex>
      {EmployeeModalOpen && (
        <EmployeeModal
          open={EmployeeModalOpen}
          close={() => {
            setEmployeeModalOpen(false);
          }}
          save={(emp: Employee[]) => handleSelectUser(emp)}
          singleSelect={true}
        />
      )}
    </>
  );
};

export default NotificationManagementPage;
