/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import CustomInputWithSearch, { MsgType } from 'components/inputs/CustomInputWithSearch';
import { GuideBlock } from '../GuideComponent';

const InputGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <>
      <GuideBlock title={'<CustomInputWithSearch> Import'} isCode={true}>
        {`
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';

// MsgType 사용하는 경우
import CustomInputWithSearch, { MsgType } from 'components/inputs/CustomInputWithSearch';
`}
      </GuideBlock>
      <GuideBlock title={'<CustomInputWithSearch> 속성'} isCode={true}>
        {`
export enum MsgType {
  ERROR = 'error',
  WARNING = 'warning',
  CONFIRM = 'comfirm',
}

interface PropsType extends InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean; // 오류여부 (추후 삭제 예정 - msgType으로 변경)
  msgError?: string; // 오류문구
  msgWarning?: string; // 경고문구
  msgConfirm?: string; // 확인문구
  msgHelp?: string; // 도움말문구
  msgType?: MsgType; // 노출메세지유형
  onSearchClick?: (e) => void; // 검색버튼 클릭 이벤트
  // className에 find가 포함되는 경우 검색버튼 노출
  // 클릭 이벤트가 정의되지 않은 경우 검색버튼이 disabled 처리됨
  
  // 그외 속성은 InputHTMLAttributes 그대로 사용
}
              `}
      </GuideBlock>
      <Alert severity="info">
        기본으로 msgHelp(도움말)이 노출되고, 메세지 유형에 따라 해당 하는 메세지가 노출됩니다.
        <br />- MsgType.ERROR {'>'} msgError
        <br />- MsgType.WARNING {'>'} msgWarning
        <br />- MsgType.CONFIRM {'>'} msgConfirm
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>CustomInputWithSearch - Basic</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomInputWithSearch
                name="test"
                placeholder={'값을 입력해 주세요.'}
                onChange={(e) => console.log(e)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomInputWithSearch
  name="test"
  placeholder={'값을 입력해 주세요.'}
  value={'test'} // 입력값은 변수로 변경 필요
  onChange={(e) => console.log(e)} // 변경 이벤트
/> 
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>CustomInputWithSearch - Find</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomInputWithSearch
                name="test"
                className="find" // 클래스명에 find가 포함되는 경우 돋보기버튼 노출
                placeholder={'값을 입력해 주세요.'}
                onChange={(e) => console.log(e)}
                onSearchClick={() => console.log('돋보기버튼 클릭이벤트')} // 해당 속성이 정의되지 않은 경우 돋보기버튼이 disabled 처리됨
              />
            </GuideBlock>

            <GuideBlock isCode={true}>
              {`
<CustomInputWithSearch
  name="test"
  className="find" // 클래스명에 find가 포함되는 경우 돋보기버튼 노출
  placeholder={'값을 입력해 주세요.'}
  value={'test value'} // 입력값은 변수로 변경 필요
  onChange={(e) => console.log(e)}
  onSearchClick={() => console.log('돋보기버튼 클릭이벤트')} // 해당 속성이 정의되지 않은 경우 돋보기버튼이 disabled 처리됨
/>
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary>CustomInputWithSearch - Help</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomInputWithSearch
                name="test"
                placeholder={'값을 입력해 주세요.'}
                msgHelp={'도움말 문구가 표시됩니다.'}
                onChange={(e) => console.log(e)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomInputWithSearch
  name="test"
  placeholder={'값을 입력해 주세요.'}
  value={'test'} // 입력값은 변수로 변경 필요
  msgHelp={'도움말 문구가 표시됩니다.'} // 도움말 문구
  onChange={(e) => console.log(e)} // 변경 이벤트
/>
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary>CustomInputWithSearch - Error</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomInputWithSearch
                name="test"
                placeholder={'값을 입력해 주세요.'}
                msgHelp={'도움말이 표시됩니다.'}
                msgError={'오류 메세지가 표시됩니다.'}
                msgType={MsgType.ERROR}
                onChange={(e) => console.log(e)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomInputWithSearch
  name="test"
  placeholder={'값을 입력해 주세요.'}
  value={'test'} // 입력값은 변수로 변경 필요
  msgHelp={'도움말이 표시됩니다.'} // 도움말
  msgError={'오류 메세지가 표시됩니다.'} // 오류 메세지 
  msgType={MsgType.ERROR} // 노출될 메세지 유형 (cf. CustomInputWithSearch.tsx > MsgType)
  onChange={(e) => console.log(e)} // 변경 이벤트
/>
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary>CustomInputWithSearch - Warning</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomInputWithSearch
                name="test"
                placeholder={'값을 입력해 주세요.'}
                msgHelp={'도움말이 표시됩니다.'}
                msgError={'오류 메세지가 표시됩니다.'}
                msgWarning={'경고 메세지가 표시됩니다.'}
                msgType={MsgType.WARNING}
                onChange={(e) => console.log(e)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomInputWithSearch
  name="test"
  placeholder={'값을 입력해 주세요.'}
  value={'test'} // 입력값은 변수로 변경 필요
  msgHelp={'도움말이 표시됩니다.'} // 도움말
  msgError={'오류 메세지가 표시됩니다.'} // 오류 메세지 
  msgWarning={'경고 메세지가 표시됩니다.'} // 경고 메세지
  msgType={MsgType.WARNING} // 노출될 메세지 유형 (cf. CustomInputWithSearch.tsx > MsgType)
  onChange={(e) => console.log(e)} // 변경 이벤트
/>  
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default InputGuide;
