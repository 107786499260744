/** @jsxImportSource @emotion/react */
import { useState, useEffect, ChangeEvent } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import styled from '@emotion/styled';
import axios from 'axios';

interface ApiResponse {
  result: any;
}

const CallTestPage = () => {
  const [host, setHost] = useState<string>('http://localhost:8080');
  const [method, setMethod] = useState<string>('GET');
  const [param, setParam] = useState<string>('');
  const [apiUrl, setApiUrl] = useState<string>('/v1/ip/check-result'); // 기본 API URL 설정
  const [jsonResponse, setJsonResponse] = useState<string>(''); // JSON 응답 데이터를 저장할 상태

  const handleMethodChange = (event: SelectChangeEvent) => {
    setMethod(event.target.value as string);
  };
  const handleSearch = async () => {
    try {
      let response;
      if (method === 'GET') {
        response = await axios.get<ApiResponse[]>(`${host}${apiUrl}${param}`);
      } else if (method === 'POST') {
        response = await axios.post<ApiResponse[]>(`${host}${apiUrl}`);
      }
      // setJsonResponse(JSON.stringify(response.data, null, 2));
    } catch (error) {
      console.error(`API 호출 중 오류 발생 ${error}`);
    }
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <FormControl>
            <InputLabel>HTTP 메소드</InputLabel>
            <Select value={method} onChange={handleMethodChange} style={{ width: '150px' }}>
              <MenuItem value="GET">GET</MenuItem>
              <MenuItem value="POST">POST</MenuItem>
            </Select>
          </FormControl>
        </SearchBoxRow>
        <SearchBoxRow>
          <TextField
            label="HOST"
            InputProps={{
              style: { width: '500px' },
            }}
            value={host}
            onChange={(e) => setHost(e.target.value)}
          />
        </SearchBoxRow>
        <SearchBoxRow>
          <TextField
            label="파라미터"
            placeholder="PARAM (?param=1&param=2)"
            InputProps={{
              style: { width: '500px' },
            }}
            value={param}
            onChange={(e) => setParam(e.target.value)}
          />
        </SearchBoxRow>
        <SearchBoxRow>
          <TextField
            label="API URL"
            InputProps={{
              style: { width: '500px' },
            }}
            value={apiUrl}
            onChange={(e) => setApiUrl(e.target.value)}
          />
          <Button variant="contained" onClick={handleSearch} style={{ width: '80px' }}>
            조회
          </Button>
        </SearchBoxRow>
      </SearchBox>
      <div style={{ overflow: 'auto', maxHeight: '2000px' }}>
        <br />
        <br />
        <SearchBox>
          <TextField
            label="응답 데이터"
            multiline
            fullWidth={true}
            variant="outlined"
            value={jsonResponse}
            InputProps={{
              readOnly: true,
              style: { whiteSpace: 'pre-wrap' },
            }}
          />
        </SearchBox>
      </div>
    </>
  );
};
export default CallTestPage;
