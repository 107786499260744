/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import { GuideBlock } from '../GuideComponent';
import { useLoading } from 'components/process/Loading';

const LoadingGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const { openLoading } = useLoading();

  return (
    <>
      <GuideBlock title={'Loading Import'} isCode={true}>
        {`import { useLoading } from 'components/process/Loading';`}
      </GuideBlock>
      <GuideBlock title={'Loading Function'} isCode={true}>
        {`const { openLoading } = useLoading();`}
      </GuideBlock>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>Loading</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <IconButton
                aria-label="play"
                color="success"
                onClick={() => {
                  openLoading(true);
                  setTimeout(() => openLoading(false), 1000);
                }}
              >
                <PlayCircleOutlinedIcon />
              </IconButton>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<IconButton
  aria-label="play"
  color="success"
  onClick={() => {
    openLoading(true); // loading 노출
    setTimeout(() => {
      openLoading(false); // loading 숨김
    }, 1000);
  }}
>
  <PlayCircleOutlinedIcon />
</IconButton>
              `}
            </GuideBlock>
            <Alert severity="warning">
              실제 코드에 적용 시에는 비동기 통신할때 주로 사용될텐데,
              <br />
              통신 시작 전에 loading 노출하고 (cf. openLoading(true))
              <br />
              통신이 끝난 시점에 loading 숨김 처리함. (ex. finally(() {`=>`} openLoading(false)))
              <br />
              <br />
              모든 비동기 통신에 공통으로 loading을 적용하게 되면, 화면에 계속 로딩바가 노출될 수
              있어 필요한 부분에 따로 적용 필요
            </Alert>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default LoadingGuide;
