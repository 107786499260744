/** @jsxImportSource @emotion/react */
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { CellType } from '@grapecity/wijmo.grid';
import { InputDate } from '@grapecity/wijmo.input';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useMessageBar } from 'hooks/useMessageBar';
import { SubTitleGroup, SubTitleLayout, ControlBtnGroup } from 'components/layouts/ContentLayout';
import { tabs } from 'components/layouts/Tab';
import { tb } from 'components/layouts/Table';
import { IconButton } from 'components/buttons/IconSVG';
import { findMpItemCop } from 'apis/mp/MpItemCop';
import {
  findMpResults,
  findFactory,
  findLine,
  generateAtchFileGrId,
  downloadExcelResult,
} from 'apis/mp/MpResult';
import { MpItemCop } from 'models/mp/MpItemCop';
import { MpResult, MpResultCondition } from 'models/mp/MpResult';
import { Line } from 'models/common/Line';
import { Code } from 'models/common/CommonCode';
import { CrudCode } from 'models/common/Edit';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { getExcelFileName } from 'utils/ExcelUtil';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import CustomGrid from 'components/grids/CustomGrid';
import { ComboBox } from 'components/selects/ComboBox';
import WJCellTextarea from 'components/inputs/WJCellTextarea';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { Employee } from 'models/admin/Employee';
import UserModal from 'components/modals/common/UserModal';

interface Props {
  itemTpCd: string;
  itemId: string;
  mpClsfCds?: string;
  isRefresh?: boolean;
  condition?: {
    itemId?: string;
    itemTpCd?: string;
    copCd?: string;
    plntCd?: string;
    lnId?: string;
  };
  onChangeRefresh: () => void;
}

const MpItemOtherContent = (props: Props, ref) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const firstRef = useRef(true);
  const refreshRef = useRef(false);
  const dateEditor = useRef(
    new InputDate(document.createElement('div'), {
      format: 'yyyy.MM.dd',
      min: dayjs().toDate(),
      isRequired: false,
    })
  );
  const { openMessageBar } = useMessageBar();
  const [code, setCode] = useState<any>({});
  const [resultCondition, setResultCondition] = useState<MpResultCondition>({});
  const [checkedItems, setCheckedItems] = useState<MpResult[]>();
  const [rowData, setRowData] = useState<MpResult[]>([]);
  const [hitTest, setHitTest] = useState<any>();

  const [copTabIndex, setCopTabIndex] = useState<number>(0);
  const [mpItemCops, setMpItemCops] = useState<MpItemCop[]>([]);
  const isUt = useMemo(() => (props.mpClsfCds || '').includes('UT'), [props.mpClsfCds]);

  const [userModalCondition, setUserModalCondition] = useState<any>({});
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [defaultCondition, setDefaultCondition] = useState<MpResultCondition>({});

  const [allMgrNm, setAllMgrNm] = useState<string>();
  const [allMgrId, setAllMgrId] = useState<string>();

  const [isOpenUserModal, setOpenUserModal] = useState<any>(false);
  const [isOpenAllUserModal, setOpenAllUserModal] = useState<any>(false);
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    validate: () => {
      return true;
    },
    getRowData: () => {
      return (rowData || [])
        .filter((o) => o.crudKey)
        .map((o) => {
          o.planDtm = o.planDtm ? dayjs(o.planDtm).format('YYYY.MM.DD') : o.planDtm;
          o.cpltDtm = o.cpltDtm ? dayjs(o.cpltDtm).format('YYYY.MM.DD') : o.cpltDtm;
          return o;
        });
    },
    refresh: () => {
      handleSearch();
    },
    reload: (itemId: string, itemTpCd: string, copCd: string, plntCd: string, lnId?: string) => {
      refreshRef.current = true;
      let isReset = true;
      // 동일한 ItemId에 다른 조건으로 진입한 경우
      if (props.itemId === itemId) {
        const tabIndex = Math.max(
          0,
          (mpItemCops || []).findIndex((o) => o.copCd === copCd)
        );
        if (copTabIndex === tabIndex) {
          isReset = false;
        }
        setCopTabIndex(tabIndex);
      }

      setResultCondition({
        plntCd: isReset ? '' : plntCd,
        lnId: isReset ? '' : lnId,
      });
    },
  }));

  useEffect(() => {
    if (props.isRefresh) {
      props.onChangeRefresh();
      refreshRef.current = true;
      if (
        defaultCondition?.itemId !== props.condition?.itemId ||
        defaultCondition?.itemTpCd !== props.condition?.itemTpCd ||
        defaultCondition?.copCd !== props.condition?.copCd ||
        defaultCondition?.plntCd !== props.condition?.plntCd ||
        defaultCondition?.lnId !== props.condition?.lnId
      ) {
        setDefaultCondition({
          itemId: props.condition?.itemId,
          itemTpCd: props.condition?.itemTpCd,
          copCd: props.condition?.copCd,
          plntCd: props.condition?.plntCd,
          lnId: props.condition?.lnId,
        });
      }
    }
  }, [props.condition, props.isRefresh]);

  useEffect(() => {
    // 법인코드 기본값이 있는 경우 제외
    if (!refreshRef.current) {
      // 수평전개/설비변경/신규개선 탭 변경 시 값 초기화
      setCopTabIndex(0);
      initCondition();
    }
    if (props.itemId) {
      // 법인 조회
      findMpItemCop(props.itemId)
        .then((result) => {
          const cops = (result || []).filter((o) => o.tgtYn === 'Y');
          setMpItemCops(cops);
        })
        .finally(() => {
          if (firstRef.current) {
            firstRef.current = false;
          }
        });
    }
  }, [props.itemId, props.itemTpCd]);

  useEffect(() => {
    if (defaultCondition.copCd) {
      setCopTabIndex(
        Math.max(
          0,
          (mpItemCops || []).findIndex((o) => o.copCd === defaultCondition.copCd)
        )
      );
    }
  }, [mpItemCops, defaultCondition.copCd]);

  useEffect(() => {
    if (!refreshRef.current) {
      initCondition();
    }
    setRowData([]);
    // 법인 탭 변경 시 공장(동) 목록 조회
    searchFactory(props.itemId || '', mpItemCops[copTabIndex]?.copCd || '', props.itemTpCd);
  }, [copTabIndex, mpItemCops]);

  useEffect(() => {
    if (!refreshRef.current) {
      setResultCondition((prev) => ({
        ...prev,
        lnId: '', // 라인
      }));
      setRowData([]);
    }
    if (resultCondition?.plntCd) {
      // 공장(동) 변경 시 목록 조회
      handleSearch();
      // 공장(동) 변경 시 라인 조회
      searchLine();
    }
  }, [resultCondition?.plntCd]);

  useEffect(() => {
    // 라인 변경 시 목록 조회
    handleSearch();
  }, [resultCondition?.lnId]);

  const initCondition = () => {
    setResultCondition((prev) =>
      Object.keys(prev).reduce(
        (acc, cur) => Object.assign(acc, { [cur]: defaultCondition[cur] || '' }),
        {} as MpResultCondition
      )
    );
  };

  const searchLine = async () => {
    // 공장(동) 변경 시 라인 조회
    if (!isUt) {
      const result = await findLine(
        mpItemCops[copTabIndex]?.copCd || '',
        resultCondition?.plntCd || ''
      );
      // 검색 조건 > 라인 (ComboBox 사용을 위해 변환)
      const line = (result || []).reduce(
        (acc, cur) => [
          ...acc,
          {
            cmnCd: cur.lnId,
            cmnCdNm: cur.lnNm,
          } as Code,
        ],
        [] as Code[]
      );

      setCode((prev) => ({
        ...prev,
        line: line,
      }));

      setDefaultCondition((prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {} as MpResultCondition
        )
      );
    }
    refreshRef.current = false;
  };

  /**
   * 공장(동) 목록 조회
   */
  const searchFactory = (itemId: string, copCd: string, itemTpCd: string) => {
    return new Promise((resolve) => {
      if (itemId && copCd && itemTpCd) {
        // 공장(동)
        // findFactory(defaultMpItem?.itemId || '', mpItemCops[copTabIndex]?.copCd || '', tabId).then(
        findFactory(itemId, copCd, itemTpCd).then((result) => {
          setCode((prev) => ({
            ...prev,
            factory: result,
            line: [],
          }));

          setResultCondition({
            plntCd: defaultCondition.plntCd,
            lnId: defaultCondition.lnId,
          });

          resolve(result);
        });
      } else {
        resolve([]);
      }
    });
  };

  const handleSearch = () => {
    const copCd = mpItemCops[copTabIndex]?.copCd;
    if (props.itemId && resultCondition?.plntCd && copCd) {
      findMpResults(
        props.itemId,
        props.itemTpCd,
        copCd,
        resultCondition?.plntCd,
        resultCondition?.lnId
      ).then((result) => setRowData(result));
    } else {
      setRowData([]);
      setCode((prev) => ({
        ...prev,
        line: [],
      }));
    }
  };

  const handleConditionChange = (name: string, value: string | string[]) => {
    // 담당자 일괄적용 관련 데이터 초기화
    setCheckedItems([]);
    setAllMgrId('');
    setAllMgrNm('');

    setResultCondition((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDownloadExcel = () => {
    const msg = [] as string[];
    if (!props.itemId) {
      msg.push(t('mp.msg.ItemID', 'ItemID'));
    }
    if (!props.itemTpCd) {
      msg.push(t('mp.msg.품목유형코드', '품목유형코드'));
    }
    if (!mpItemCops[copTabIndex]?.copCd) {
      msg.push(t('mp.msg.법인코드', '법인코드'));
    }
    if (!resultCondition.plntCd) {
      msg.push(t('mp.msg.공장코드', '공장코드'));
    }
    if (msg.length) {
      openMessageBar({
        type: 'error',
        content: msg.join(', ') + t('mp.msg.가 없습니다.', `가 없습니다.`),
      });
      return;
    }

    const lineHeader = isUt
      ? []
      : [String(t('mp.label.Line', 'Line')), String(t('mp.grid.Line 약어', 'Line 약어'))];
    const excelData: ExcelDownloadRequest<MpResultCondition> = {
      fileName: getExcelFileName(t('mp.label.설비변경/수평전개 Item', '설비변경/수평전개 Item')),
      sheetName: t('mp.label.검토 및 실행', '검토 및 실행'),
      header: [
        ...lineHeader,
        String(t('mp.grid.설비 ID', '설비 ID')),
        String(t('com.label.설비명', '설비명')),
        String(t('com.label.담당자', '담당자')),
        String(t('mp.grid.대상', '대상')),
        String(t('mp.grid.계획일', '계획일')),
        String(t('mp.grid.완료일', '완료일')),
        String(t('mp.grid.진행', '진행')),
        String(t('mp.grid.비고', '비고')),
        String(t('com.label.첨부', '첨부')),
      ],
      searchCondition: {
        ...resultCondition,
        itemId: props.itemId,
        itemTpCd: props.itemTpCd,
        copCd: mpItemCops[copTabIndex]?.copCd || '',
        utYn: isUt ? 'Y' : 'N',
      },
    };
    downloadExcelResult(excelData);
  };

  const handleFileUpload = async (item, row, col) => {
    let atchFileGrId = item.atchFileGrId;
    if (_.isEmpty(atchFileGrId)) {
      atchFileGrId = await generateAtchFileGrId(item.execPlanId);
      if (atchFileGrId) {
        item.atchFileGrId = atchFileGrId;
      } else {
        openMessageBar({
          type: 'error',
          content:
            t(
              'com.label.첨부파일그룹ID 생성에 실패했습니다.',
              '첨부파일그룹ID 생성에 실패했습니다.'
            ) +
            <br /> +
            t('com.label.새로고침 후 다시 시도해 주세요.', '새로고침 후 다시 시도해 주세요.'),
        });
        return;
      }
    }
    setFileUploadModalCondition({
      atchFileGrId: atchFileGrId,
      tableName: 'tb_elm_mp_result_n',
      bizName: 'mp',
      target: {
        item: item,
        row: row,
        col: col,
      },
    });
    setOpenFileUploadModal(true);
  };

  const handleSetAllUser = () => {
    if (rowData.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('mp.msg.데이터를 조회후 적용해주세요.', '데이터를 조회후 적용해주세요.'),
      });
      return;
    } else if ((checkedItems || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('mp.msg.일괄적용할 항목을 선택해주세요.', '일괄적용할 항목을 선택해주세요.'),
      });
      return;
    } else if (allMgrId === null || allMgrId === '') {
      openMessageBar({
        type: 'error',
        content: t('com.label.담당자를 선택해 주세요.', '담당자를 선택해 주세요.'),
      });
      return;
    }

    (checkedItems || []).forEach((o) => {
      o.mgrId = allMgrId;
      o.mgrNm = allMgrNm;
      o.crudKey = CrudCode.UPDATE;
    });

    gridRef.current.collectionView.refresh();
  };

  const layoutDefinition = useMemo(
    () => [
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 50,
        align: 'center',
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'lnNm',
        header: String(t('mp.grid.Line', 'Line')),
        width: 150,
        isReadOnly: true,
        align: 'left',
        visible: !isUt,
      },
      {
        binding: 'lnAnmNm',
        header: String(t('mp.grid.Line 약어', 'Line 약어')),
        width: 100,
        isReadOnly: true,
        align: 'center',
        visible: !isUt,
      },
      {
        header: String(t('mp.grid.설비', '설비')),
        columns: [
          {
            binding: 'eqpId',
            header: String(t('mp.grid.설비 ID', '설비 ID')),
            minWidth: 150,
            width: '*',
            align: 'center',
            isReadOnly: true,
          },
          {
            binding: 'equipmentName',
            minWidth: 200,
            width: '*',
            header: String(t('com.label.설비명', '설비명')),
            isReadOnly: true,
            align: 'center',
          },
        ],
      },
      {
        binding: 'mgrNm',
        width: 200,
        header: String(t('com.label.담당자', '담당자')),
        cssClass: 'WijmoFind',
        align: 'left',
        isReadOnly: true,
        isRequired: false,
        cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        <Button /> 
      `,
      },
      {
        binding: 'tgtYn',
        width: 80,
        header: String(t('mp.grid.대상', '대상')),
        isReadOnly: true,
        align: 'center',
        cssClass: 'WijmoCheck',
        cellTemplate: (params) => {
          const checked = params.value === 'Y' ? 'checked' : '';
          return `<div class="checkbox">
                  <input type="checkbox" id="tgtYn_${params.item.execPlanId}" ${checked} />
                  <label />
                </div>
          `;
        },
      },
      {
        binding: 'planDtm',
        width: 150,
        header: String(t('mp.grid.계획일', '계획일')),
        align: 'left',
        cssClass: 'WijmoDate',
        isRequired: false,
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'cpltDtm',
        width: 150,
        header: String(t('mp.grid.완료일', '완료일')),
        align: 'left',
        cssClass: 'WijmoDate',
        isRequired: false,
        editor: dateEditor.current,
        cellTemplate: (params) => (params.value ? dayjs(params.value).format('YYYY.MM.DD') : ''),
      },
      {
        binding: 'statusCd',
        width: 80,
        header: String(t('mp.grid.진행', '진행')),
        align: 'center',
        isReadOnly: true,
        cssClass: 'WijmoTag',
        cellTemplate: (params) => {
          switch (params.value) {
            case 'COMPLETE':
              return `<span class="green">${t('mp.msg.완료', '완료')}</span>`;
            case 'INPROGRESS':
              return `<span class="yellow">${t('mp.msg.진행중', '진행중')}</span>`;
            case 'DELAY':
              return `<span class="red">${t('mp.msg.지연', '지연')}</span>`;
            case 'REVIEW':
              return `<span class="purple">${t('mp.msg.검토중', '검토중')}</span>`;
            case 'NO_TGT':
              return `<span class="grey">${t('mp.msg.비대상', '비대상')}</span>`;
            case 'CHECK':
              return `<span class="orange">${t('mp.msg.확인중', '확인중')}</span>`;
            default:
              return '';
          }
        },
      },
      {
        binding: 'rmk',
        header: String(t('mp.grid.비고', '비고')),
        align: 'left',
        minWidth: 200,
        width: '*',
      },
      {
        binding: 'atchFileCnt',
        width: 80,
        align: 'center',
        header: String(t('com.label.첨부', '첨부')),
        isReadOnly: true,
        cellTemplate: (params) =>
          `<div class="fileDiv"><span><em>${(
            params.value || 0
          ).toLocaleString()}</em></span></div>`,
      },
      {
        binding: 'execPlanId',
        visible: false,
      },
      {
        binding: 'atchFileGrId',
        visible: false,
      },
      {
        binding: 'mgrId',
        isRequired: false,
        visible: false,
      },
    ],
    [isUt]
  );

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        const item = panel.rows[row].dataItem;
        // 담당자 editable css 적용
        if ('mgrNm' === binding) {
          cell.ariaReadOnly = false;
        }
      }
    };

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;

        // 담당자 클릭
        if ('mgrNm' === binding) {
          setUserModalCondition({
            mgrId: item.mgrId,
            target: {
              row: ht.row,
              col: ht.col,
            },
          });
          setOpenUserModal(true);
        }
        // 첨부파일 클릭
        else if ('atchFileCnt' === binding) {
          handleFileUpload(item, ht.row, ht.col);
        }
        // 대상 클릭
        else if ('tgtYn' === binding && e.target.id?.startsWith('tgtYn')) {
          item.tgtYn = e.target.checked ? 'Y' : 'N';
          item.crudKey = CrudCode.UPDATE;
          gridRef.current?.refresh();
        }
        // 비고 클릭
        else if ('rmk' === binding) {
          setHitTest(ht);
          setOpenCellTextarea(true);
        }
      }
    });
  };

  return (
    <>
      {firstRef.current ? (
        <></>
      ) : (mpItemCops || []).length ? (
        <>
          <Tabs
            value={copTabIndex}
            onChange={(e, newValue) => setCopTabIndex(newValue)}
            css={tabs.subtab}
          >
            {mpItemCops.map((o, idx) => (
              <Tab
                key={o.copCd}
                label={o.copCd}
                id={`mp-cop-tab-${idx}`}
                aria-controls={`mp-cop-tabpanel-${idx}`}
              />
            ))}
          </Tabs>
          <TabPanel value={copTabIndex} index={copTabIndex}>
            <TableContainer css={tb.table} style={{ marginBottom: '24px' }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('mp.label.공장(동)', '공장(동)')}</TableCell>
                    <TableCell>
                      <ComboBox
                        placeholder={String(t('com.label.선택', '선택'))}
                        options={code?.factory}
                        defaultValue={resultCondition?.plntCd}
                        style={{ width: '200px' }}
                        onChange={(value) => handleConditionChange('plntCd', value)}
                      />
                    </TableCell>
                    {!isUt && (
                      <>
                        <TableCell>{t('mp.label.Line', 'Line')}</TableCell>
                        <TableCell>
                          <ComboBox
                            placeholder={String(t('com.label.선택', '선택'))}
                            options={code?.line}
                            defaultValue={resultCondition?.lnId}
                            style={{ width: '200px' }}
                            onChange={(value) => handleConditionChange('lnId', value)}
                          />
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <SubTitleLayout>
              <SubTitleGroup>
                <h3>{t('mp.label.검토 및 실행', '검토 및 실행')}</h3>
                <span className="total">
                  {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
                  {t('com.label.건', '건')}
                </span>
              </SubTitleGroup>
              <ControlBtnGroup>
                <CustomInputWithSearch
                  name="allContDataNm"
                  className="find"
                  placeholder={String(
                    t('com.label.담당자를 선택해 주세요.', '담당자를 선택해 주세요.')
                  )}
                  value={allMgrNm}
                  readOnly={true}
                  onSearchClick={() => {
                    setOpenAllUserModal(true);
                  }}
                />
                <Button
                  css={IconButton.button}
                  className="setting"
                  onClick={handleSetAllUser}
                  disableRipple
                >
                  {t('com.button.일괄적용', '일괄적용')}
                </Button>

                <Button css={IconButton.button} className="Exceldown" onClick={handleDownloadExcel}>
                  {t('com.button.다운로드', '다운로드')}
                </Button>
                <Button css={IconButton.button} className="reload" onClick={handleSearch}>
                  {t('com.button.새로고침', '새로고침')}
                </Button>
              </ControlBtnGroup>
            </SubTitleLayout>
            <CustomGrid
              layoutDefinition={layoutDefinition}
              rowData={rowData}
              height={250}
              excludeFilter={['tgtYn']}
              initialized={onInitialized}
              onChangeCheckedItem={(items) => setCheckedItems(items)}
            />
            {isOpenCellTextarea && (
              <WJCellTextarea
                grid={gridRef.current}
                hitTest={hitTest}
                close={() => setOpenCellTextarea(false)}
              />
            )}
          </TabPanel>
        </>
      ) : (
        <>
          <h2>
            {t(
              'mp.label.대상법인이 없어 조회할 법인이 없습니다.',
              '대상법인이 없어 조회할 법인이 없습니다.'
            )}
          </h2>
        </>
      )}
      {/*담당자 일괄적용*/}
      {isOpenAllUserModal && (
        <UserModal
          open={isOpenAllUserModal}
          close={() => setOpenAllUserModal(false)}
          title={t('mp.label.담당자 조회', '담당자 조회')}
          defaultUserId={allMgrId}
          singleSelect={false}
          onCallback={(result) => {
            const users = (result || []) as Employee[];
            setAllMgrNm(users.map((o) => o.empNm).join(', '));
            setAllMgrId(users.map((o) => o.userId).join(', '));
          }}
        />
      )}
      {/*담당자 적용*/}
      {isOpenUserModal && (
        <UserModal
          open={isOpenUserModal}
          close={() => setOpenUserModal(false)}
          title={t('mp.label.담당자 조회', '담당자 조회')}
          defaultUserId={userModalCondition?.mgrId}
          singleSelect={false}
          onCallback={(result) => {
            const target = userModalCondition.target;
            if (target && target?.row > -1) {
              const users = (result || []) as Employee[];
              const item = gridRef.current?.rows[target.row].dataItem;
              item.mgrNm = users.map((o) => o.empNm).join(', ');
              item.mgrId = users.map((o) => o.userId).join(', ');
              item.crudKey = CrudCode.UPDATE;
              gridRef.current?.collectionView.refresh();
            }
          }}
        />
      )}
      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            const target = fileUploadModalCondition.target;
            if (target) {
              gridRef.current?.setCellData(target.row, target.col, fileCount);
              gridRef.current?.startEditing(false, target.row, target.col);
              gridRef.current?.finishEditing();
            }
            setOpenFileUploadModal(false);
          }}
        />
      )}
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`mp-cop-tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default React.forwardRef(MpItemOtherContent);
