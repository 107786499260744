import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICellRendererParams } from 'ag-grid-community';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Code } from 'models/common/CommonCode';
import { getIpCheckValOkNg } from 'apis/ip/IpCheckResult';
import { ICellTemplateContext } from '@grapecity/wijmo.grid';

/**
 * [AgGrid] IP 점검 결과 입력 > 결과값 셑 템플릿 Params
 * TODO 위즈모 변환 완료 후 삭제 예정
 */
export interface ICheckResultCellRendererParams extends ICellRendererParams {
  curValue: string; // 입력값 (ex. makerValue, ensolValue)
  resultOkngCodes: Code[];
  stepStatus: string;
  isReadOnly?: boolean;
}

/**
 * [AgGrid] IP 점검 결과 입력 > 결과값 셑 템플릿
 * TODO 위즈모 변환 완료 후 삭제 예정
 * @param curValue
 * @param resultOkngCodes
 * @param stepStatus
 * @param isReadOnly
 * @param params
 * @constructor
 */
export const CheckResultCellRenderer = ({
  curValue,
  resultOkngCodes,
  stepStatus,
  isReadOnly = false,
  ...params
}: ICheckResultCellRendererParams) => {
  const { t } = useTranslation();
  const { insertYn, paraPointType, meterType, lowerBound, upperBound } = params.data;
  const instanceId = params.column?.getInstanceId();
  const ipCheckValOkNg = getIpCheckValOkNg(meterType, lowerBound, upperBound, curValue) || '';

  // 측정개소유형이 POINT인 경우 value/result 모두 readOnly 처리 (cf. value 클릭 시 측정개소결과입력창(팝업) 열기)
  if (insertYn === 'N' || paraPointType === 'POINT' || stepStatus === 'done' || isReadOnly) {
    return <div>{params.value || ipCheckValOkNg || 'N/A'}</div>;
  }

  // meterType이 OKNG이거나, METER인데 상한/하한값이 없는 경우 ComboBox 노출 (fn_get_ip_check_val_okng 함수값이 null인 경우)
  return (
    <FormControl fullWidth>
      <InputLabel id={`checkResultOptionLabel-${instanceId}`}>
        {t('com.label.선택', '선택')}
      </InputLabel>
      <Select
        labelId={`checkResultOptionLabel-${instanceId}`}
        value={params.value || ''}
        onChange={(e: SelectChangeEvent) => {
          const id = params.column?.getColId() || '';
          params.node.setDataValue(id, e.target.value);
        }}
      >
        <MenuItem value="">{t('com.label.선택', '선택')}</MenuItem>
        {resultOkngCodes.map((o) => (
          <MenuItem key={o.cmnCd} value={o.cmnCd}>
            {o.cmnCdNm}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface Props extends ICellTemplateContext {
  curValue: string; // 입력값 (ex. makerValue, ensolValue)
  resultOkngCodes: Code[];
  stepStatus: string;
  isReadOnly?: boolean;
}

/**
 * [Wijmo] IP 점검 결과 입력 > 결과값 셑 템플릿
 * @param curValue
 * @param resultOkngCodes
 * @param stepStatus
 * @param isReadOnly
 * @param params
 * @constructor
 */
export const CheckResultCellTemplate = ({
  curValue,
  resultOkngCodes,
  stepStatus,
  isReadOnly = false,
  ...params
}: Props) => {
  const { insertYn, paraPointType, meterType, lowerBound, upperBound } = params.item;
  const ipCheckValOkNg = getIpCheckValOkNg(meterType, lowerBound, upperBound, curValue) || '';

  // 측정개소유형이 POINT인 경우 value/result 모두 readOnly 처리 (cf. value 클릭 시 측정개소결과입력창(팝업) 열기)
  if (insertYn === 'N' || paraPointType === 'POINT' || stepStatus === 'done' || isReadOnly) {
    return `<div>${params.value || ipCheckValOkNg || 'N/A'}</div>`;
  }

  // 값이 없는 경우
  if (!params.value) return '';

  // meterType이 OKNG이거나, METER인데 상한/하한값이 없는 경우 ComboBox 노출 (fn_get_ip_check_val_okng 함수값이 null인 경우)
  const dataMapItem = params.col?.dataMap?.getDataItem(params.value);
  if (dataMapItem && dataMapItem?.cmnCdNm) return `${dataMapItem?.cmnCdNm}`;

  const codes = (resultOkngCodes || []).filter((o) => o.cmnCd === params.value);
  if (codes) return `${codes[0]?.cmnCdNm}`;

  return `${params.value}`;
};
