import {
  CommonRequest,
  Method,
  ServiceName,
  CommonResponse,
  DmlResponse,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import {
  SearchParamRequest,
  UtMatrixReqTargetList,
  UtMatrixRequest,
} from '../../models/ut/UtMatrixRequest';
import { UtMatrixManagerList } from '../../models/ut/UtMatrixManager';
import { UtMatrixMaster } from '../../models/ut/UtMatrix';
import { GatingCheckResultItem } from '../../models/gtng/GatingCheckResult';
import { SearchParam, UtMatrixDetailList } from '../../models/ut/UtMatrixList';

/**
 * Ut Matrix 대상 목록 조회 (신규인경우)
 * @param searchParam :
 */
export const getUtMatrixReqDefaultList = async (SearchParamRequest: SearchParamRequest) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getUtMatrixReqDefaultList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...SearchParamRequest,
    }),
  };
  const response: CommonResponse<UtMatrixReqTargetList[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixReqTargetList[];
};

export const getUtMatrixReqTargetList = async (SearchParamRequest: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getUtMatrixReqTargetList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...SearchParamRequest,
    }),
  };
  const response: CommonResponse<UtMatrixDetailList[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixDetailList[];
};

/**
 * Ut Matrix no search
 * @param searchParam :
 */
export const getReqUtmNoNew = async (SearchParamRequest: SearchParamRequest) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getReqUtmNoNew`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...SearchParamRequest,
    }),
  };
  const response: CommonResponse<string> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * 작성요청 저장
 */
export const saveUtMatrixReqMaster = async (saveDataItem: UtMatrixRequest | undefined) => {
  console.log('saveDataItem ;=======================>> saveUtMatrixReqMaster ', saveDataItem);

  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/saveUtMatrixReqMaster`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveDataItem,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return response;
};

/**
 * 작성요청 저장
 */
export const saveUtMatrixReqMasterReq = async (saveDataItem: UtMatrixRequest | undefined) => {
  console.log('saveDataItem ;=======================>> saveUtMatrixReqMasterReq ', saveDataItem);

  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/saveUtMatrixReqMasterReq`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveDataItem,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return response;
};

/**
 * 작성요청 대상 저장
 */
export const saveUtMatrixReqTgtList = async (saveDataItem: UtMatrixManagerList[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/saveUtMatrixReqTgtList`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveDataItem,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};
