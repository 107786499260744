/** @jsxImportSource @emotion/react */
import { useState, useEffect, useMemo } from 'react';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { ContentGrid } from 'components/layouts/ContentGrid';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { useTranslation } from 'react-i18next';
import {
  RequestDetail,
  SearchCondition,
  clearSearchCondition,
} from 'models/approves/ReferenceOpinion';
import { findApproveRequestList } from 'apis/approves/approves';
import { useLoading } from 'components/process/Loading';
import { useNavigate } from 'react-router-dom';
import SearchButtonGroup from 'pages/common/components/SearchButtonGroup';
import ApproveRequestModal from './ApproveRequestModal';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { ComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { Code } from 'models/common/CommonCode';
import { aprTplNm, ApprovalTemplate } from 'models/admin/ApprovalTemplate';
import { getApprovalTemplate } from 'apis/admin/Approval';

export interface values {
  pageId: string;
  pageIdx: string;
}

type Props = {
  changeAprTpl?: (aprTplId: string) => void;
};

const ApproveManagementPage = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openLoading } = useLoading();
  const [rowData, setRowData] = useState<RequestDetail[]>([]);
  const [searchCondition, setSearchCondition] = useState<SearchCondition>();
  const [isRefresh, setRefrash] = useState(false);

  const [ApprovalTemplate, setApprovalTemplate] = useState<ApprovalTemplate>({
    aprTplId: '',
    intgAprTpCd: '',
    aprTplNm: '',
    sortOrd: 0,
    aprTplDesc: '',
    useYn: 'Y',
    prsDesc: '',
    wcstUseYn: 'Y',
    wcstDesc: '',
    notfUseYn: 'Y',
    ntdkId: 999,
    mgrUseYn: 'Y',
  });

  const [aprReqProgStatCd, setAprReqProgStatCd] = useState<any>();

  const [flexRef, setFlexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();

  const [code, setCode] = useState<any>();
  //const [mpItemCondition, setMpItemCondition] = useState<MpItemCondition>({
  // startDataInsDtm: dayjs().add(-2, 'year').format('YYYY.MM.DD'),
  // endDataInsDtm: dayjs().format('YYYY.MM.DD'),
  //});

  const OnInitialized = (grid) => {
    // new Selector(grid);
    new FlexGridFilter(grid);
    setFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      setHitTest(ht);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 false

      const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
      setflexItem(item);
      grid.refresh();
    });
    grid.rowHeaders.columns.splice(0, 1);
  };

  const LayoutDefinition = () => {
    return [
      {
        binding: 'aprReqId',
        header: String(t('com.label.요청번호', '요청번호')),
        align: 'center',
        width: 200,
      },
      {
        binding: 'aprTplNm',
        header: String(t('com.label.결재양식명', '결재양식명')),
        align: 'center',
        width: 180,
      },
      {
        binding: 'dataInsUserId',
        visible: false,
      },
      {
        binding: 'aprReqUserNm',
        header: String(t('com.grid.작성자', '작성자')),
        align: 'center',
        width: '*',
      },
      {
        binding: 'aprReqTitNm',
        header: String(t('com.grid.제목', '제목')),
        align: 'left',
        width: 500,
        cellTemplate: CellMaker.makeLink({
          click: (e, ctx) => {
            // TODO 2개 페이지 합친 후 상태/권한 별 로직 처리 필요
            ctx.item.aprReqProgStatCd == 'TMP'
              ? navigate('/approves/approve-request-page?aprReqId=' + ctx.item.aprReqId, {
                  state: { upprMnuUrl: location.pathname },
                })
              : navigate('/approves/approve-request-detail?aprReqId=' + ctx.item.aprReqId, {
                  state: { upprMnuUrl: location.pathname },
                });
          },
        }),
      },
      {
        binding: 'aprReqProgStatCd',
        visible: false,
      },
      {
        binding: 'aprReqProgStatNm',
        header: String(t('com.label.진행상태', '진행상태')),
        width: 100,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.작성일시', '작성일시')),
        width: 150,
      },
    ];
  };

  const state = useMemo(() => {
    return {
      itemsSource: rowData,
      layoutDefinition: LayoutDefinition(),
    };
  }, [rowData]);

  const getList = async (condition?: SearchCondition) => {
    openLoading(true);
    const response = await findApproveRequestList(condition ?? searchCondition);
    if (response) {
      setRowData(response.list);
      //setTotalCount(response.totalCount);
    }
    openLoading(false);
  };

  const init = async () => {
    getList(searchCondition);
  };

  useEffect(() => {
    init();
    initCondition();
  }, []);

  const initCondition = async () => {
    // Gating 유형
    const aprReqProgStatCd = await getCommonCodeNames('APR_REQ_PROG_STAT_CD');
    setAprReqProgStatCd(aprReqProgStatCd);

    // 결재양식명
    const apprvTpl: ApprovalTemplate[] = await getApprovalTemplate(aprTplNm.NAME, 'Y');
    const apprvTplCodes: Code[] = (apprvTpl || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: cur.aprTplId,
          cmnCdNm: cur.aprTplNm,
        } as Code,
      ],
      [] as Code[]
    );

    setCode({
      apprvTplCodes: apprvTplCodes,
    });
  };

  const listner = (received) => {
    setSearchCondition({ ...searchCondition, [received.target.name]: received.target.value });
    console.log('searchCondition', searchCondition);
  };

  const searchButtonOnClick = function () {
    getList(searchCondition);
    console.log('searchCondition', searchCondition);
  };

  const reOnClick = () => {
    setSearchCondition(clearSearchCondition);
    setRefrash(!isRefresh);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchButtonOnClick();
    }
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('com.label.요청번호', '요청번호')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="aprReqId"
                  placeholder={String(
                    t('com.label.요청번호를 입력해 주세요.', '요청번호를 입력해 주세요.')
                  )}
                  value={searchCondition?.aprReqId}
                  onChange={listner}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.작성자', '작성자')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="dataInsUserId"
                  placeholder={String(
                    t('com.labe.작성자를 입력해주세요.', '작성자를 입력해주세요.')
                  )}
                  value={searchCondition?.dataInsUserId}
                  onChange={listner}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.진행상태', '진행상태')}</label>
                <ComboBox
                  options={aprReqProgStatCd}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchCondition?.aprReqProgStatCd}
                  onChange={(value) => {
                    setSearchCondition({ ...searchCondition, aprReqProgStatCd: value });
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{String(t('mp.label.결재양식명', '결재양식명'))}</label>
                <ComboBox
                  placeholder={String(t('com.button.전체', '전체'))}
                  options={code?.apprvTplCodes}
                  defaultValue={ApprovalTemplate?.aprTplId}
                  onChange={(value) => {
                    // setApprovalTemplate({
                    //   ...ApprovalTemplate,
                    //   aprTplId: value as string,
                    // });
                    setSearchCondition({
                      ...searchCondition,
                      aprTplId: value as string,
                    });
                    props?.changeAprTpl && props.changeAprTpl(value);
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonGroup
            name="조회"
            reOnClick={reOnClick}
            searchOnClick={searchButtonOnClick}
          ></SearchButtonGroup>
        </SearchBoxRow>
      </SearchBox>

      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.결재현황', '결재현황')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData ? rowData.length : 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <ContentGrid className={rowData.length < 1 ? 'noData' : ''} style={{ height: '680px' }}>
        <FlexGrid
          itemsSource={state.itemsSource}
          columnGroups={state.layoutDefinition}
          allowPinning="ColumnRange" // 열 고정 방식
          alternatingRowStep={0}
          isReadOnly={true}
          align="center"
          allowMerging="Cells"
          initialized={OnInitialized}
          autoGenerateColumns={false}
          style={{ height: (rowData || []).length < 1 ? '' : '680px' }}
        />
        <div className="noData" style={{ height: (rowData || []).length < 1 ? '680px' : '' }}>
          <span>
            {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
          </span>
        </div>
      </ContentGrid>
    </ContainerLayout>
  );
};

export default ApproveManagementPage;
