/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { Button } from '@mui/material';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { checkExistsRequest, findMpEqiupmentClassificationNewNo } from 'apis/mp/MpEquipment';
import { EquipmentClassificationMaster, ReqChgTpCd } from 'models/mp/MpEquipment';
import { CrudCode } from 'models/common/Edit';
import CustomGrid from 'components/grids/CustomGrid';
import { IconButton } from 'components/buttons/IconSVG';
import { ManagementMode } from 'models/common/Common';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { EquipmentGroupPopUp } from 'pages/common/popup/EquipmentGroupPopUp';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { useMessageBar } from 'hooks/useMessageBar';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import _ from 'lodash';
import { SuccessOrNot } from '../../../models/common/RestApi';

interface Props {
  mode: ManagementMode;
  rowData?: EquipmentClassificationMaster[];
  isReadOnly?: boolean;
  onRefresh: () => void;
  onChange: (masterList: EquipmentClassificationMaster[]) => void;
  onClickDetail: (target: EquipmentClassificationMaster) => void;
}

interface TargetCondition {
  row: number;
  col: number;
}

const EditMpEquipmentClassificationMasterGrid = (props: Props) => {
  const { mode, onRefresh, onChange, onClickDetail, isReadOnly = false } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();

  const [rowData, setRowData] = useState<EquipmentClassificationMaster[]>(props?.rowData || []);
  const [selectedRow, setSelectedRow] = useState<EquipmentClassificationMaster[]>();

  const [isOpenEquipmentGroupPopup, setOpenEquipmentGroupPopup] = useState<boolean>(false);
  const [targetCondition, setTargetCondition] = useState<TargetCondition>();

  const [code, setCode] = useState<any>();
  const isCreate = useMemo(() => ManagementMode.CREATE === mode && !isReadOnly, [mode, isReadOnly]);
  const isEditable = useMemo(
    () => [ManagementMode.CREATE, ManagementMode.MODIFY].includes(mode) && !isReadOnly,
    [mode, isReadOnly]
  );

  const getCommonCodesForGrid = async () => {
    const mpClsfType: Code[] = await getCommonCodeNames('MP_CLSF_TYPE'); // MP분류
    const eqpGenCd: Code[] = await getCommonCodeNames('EQP_GEN_CD'); // 세대구분

    setCode({
      mpClsfType: mpClsfType,
      eqpGenCd: eqpGenCd,
    });
  };

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  useEffect(() => {
    setRowData(props?.rowData || []);
  }, [props?.rowData]);

  const handleAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      reqChgTpCd: ReqChgTpCd.CREATE,
    } as EquipmentClassificationMaster;
    const rows = [newRow, ...rowData];
    setRowData(rows);
    onChange(rows);
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if (!selectedRows) return;

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
        rowData[item].reqChgTpCd = ReqChgTpCd.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
    onChange(filteredData);
  };

  const layoutDefinition = useMemo(() => {
    const isModify = ManagementMode.MODIFY === mode && !isReadOnly;
    const isCreate = ManagementMode.CREATE === mode && !isReadOnly;
    const isEditable = isModify || isCreate;
    return [
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        align: 'center',
        width: 45,
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'reqChgTpCd',
        header: String(t('mp.grid.변경유형', '변경유형')),
        align: 'center',
        width: 45,
        isReadOnly: true,
        visible: false,
      },
      {
        // binding: 'eqpClsf',
        binding: 'mpClsfCd',
        header: String(t('mp.grid.MP분류', 'MP분류')),
        align: 'center',
        width: 130,
        isReadOnly: !isCreate,
        dataMap: new DataMap(code?.mpClsfType || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        binding: 'eqclId',
        header: String(t('com.label.설비군', '설비군')),
        align: isCreate ? 'left' : 'center',
        width: 80,
        isReadOnly: true, // itemFormatter에서 제어
        cssClass: isCreate ? 'WijmoFind' : '',
        cellTemplate: (params) => `
          <span>${params.value || ''}</span> 
          ${isCreate ? '<Button />' : ''} 
        `,
      },
      {
        binding: 'eqpClsfNo',
        header: String(t('mp.grid.설비분류체계번호', '설비분류체계번호')),
        align: 'center',
        width: 150,
      },
      {
        binding: 'eqpClsfNm',
        header: String(t('mp.grid.설비분류체계명', '설비분류체계명')),
        align: 'left',
        width: 175,
        isReadOnly: !isEditable,
      },
      {
        header: String(t('mp.grid.상세', '상세')),
        binding: 'detailNo',
        width: 70,
        isReadOnly: true,
        cssClass: 'WijmoPlay',
        cellTemplate: (params) => `<Button></Button>`,
      },
      {
        binding: 'eqpMdpNm',
        header: String(t('mp.grid.제조사', '제조사')),
        align: 'left',
        width: 120,
        isReadOnly: !isEditable,
      },
      {
        binding: 'eqpGenCd',
        header: String(t('mp.grid.세대구분', '세대구분')),
        width: 120,
        dataMap: new DataMap(code?.eqpGenCd || [], 'cmnCd', 'cmnCdNm'),
        isReadOnly: !isEditable,
      },
    ];
  }, [mode, code?.mpClsfType, code?.eqpGenCd]);

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 설비군
      if ('eqclId' === binding) {
        cell.ariaReadOnly = !isCreate;
      }
      // 설비분류체계번호
      else if ('eqpClsfNo' === binding) {
        // UT인 경우 key-in, 아닌 경우 자동채번
        cell.ariaReadOnly = item.mpClsfCd !== 'UT';
      }
    }
  });

  const checkRequireRequest = async (item) => {
    let valid = true;
    let message = '';
    if (_.isEmpty(item.mpClsfCd)) {
      message = t('mp.label.MP분류는 필수 항목입니다.', 'MP분류는 필수 항목입니다.');
      valid = false;
    } else if (_.isEmpty(item.eqclId)) {
      message = t('mp.label.설비군은 필수 항목입니다.', '설비군은 필수 항목입니다.');
      valid = false;
    } else if (_.isEmpty(item.eqpClsfNo)) {
      message = t(
        'mp.label.설비분류체계번호는 필수 항목입니다.',
        '설비분류체계번호는 필수 항목입니다.'
      );
      valid = false;
    } else if (_.isEmpty(item.eqpClsfNm)) {
      message = t(
        'mp.label.설비분류체계명은 필수 항목입니다.',
        '설비분류체계명은 필수 항목입니다.'
      );
      valid = false;
    }

    await checkExistsRequest(item.eqpClsfNo).then((res) => {
      if (res.successOrNot !== SuccessOrNot.Y) {
        valid = false;
        message = res.data;
      }
    });

    if (!valid) {
      openMessageBar({
        type: 'error',
        content: message,
      });
    }

    return valid;
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.hostElement.addEventListener('click', async (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const binding = grid.columns[ht.col].binding;
        const item = grid.rows[ht.row].dataItem;
        // 설비군 클릭 이벤트
        if ('eqclId' === binding) {
          if (!item.mpClsfCd) {
            openMessageBar({
              type: 'error',
              content: t('mp.msg.MP분류를 선택해주세요.', 'MP분류를 선택해주세요.'),
            });
            return;
          }
          // 신규인 경우에만 설비군 변경 가능
          if (ManagementMode.CREATE === mode) {
            setOpenEquipmentGroupPopup(true);
            setTargetCondition({
              row: ht.row,
              col: ht.col,
            });
          }
        }
        // 상세보기 버튼
        else if ('detailNo' === binding) {
          const valid = await checkRequireRequest(item);

          if (!valid) {
            return false;
          }

          onClickDetail(item);
        }
      }
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.설비분류체계 List', '설비분류체계 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        {isCreate && (
          <ControlBtnGroup>
            <Button css={IconButton.button} className="plus" onClick={handleAddRow} disableRipple>
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button css={IconButton.button} className="minus" onClick={handleDelRow} disableRipple>
              {t('com.button.행삭제', '행삭제')}
            </Button>
            <Button css={IconButton.button} className="refresh" onClick={onRefresh} disableRipple>
              {t('com.button.새로고침', '새로고침')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>

      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isSelector={ManagementMode.CREATE === mode}
        height={480}
        isFilter={false}
        isReadOnly={!isEditable}
        initialized={onInitialized}
        beginningEdit={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const item = grid.rows[e.row].dataItem;
          // readonly 설정
          // 설비분류체계번호
          if ('eqpClsfNo' === binding) {
            // UT인 경우 key-in, 아닌 경우 자동채번
            if (item.mpClsfCd !== 'UT') {
              e.cancel = true;
            }
          }
        }}
        cellEditEnding={(grid, e) => {
          const item = grid.rows[e.row].dataItem;
          const oldVal = grid.getCellData(e.row, e.col);
          const newVal = grid.activeEditor?.value;
          const binding = grid.columns[e.col].binding;
          // MP분류 변경시 분류체계명 초기화
          if ('mpClsfCd' === binding) {
            item.eqpClsfNo = '';
          }
        }}
        onChangeCheckedItem={(items) => setSelectedRow(items)}
        allowPinning={false}
        allowSorting={false}
      />

      {isOpenEquipmentGroupPopup && (
        <EquipmentGroupPopUp
          open={isOpenEquipmentGroupPopup}
          close={() => setOpenEquipmentGroupPopup(false)}
          condition={{
            equipmentGroup: '',
          }}
          onCallback={(value) => {
            const equipmentGroup = (value as EquipmentGroupCondition).equipmentGroup;
            gridRef.current.setCellData(targetCondition?.row, targetCondition?.col, equipmentGroup);

            // 설비군 변경한 경우
            if (typeof targetCondition?.row === 'number') {
              const selectedRow = gridRef.current.rows[targetCondition?.row].dataItem;
              const filteredData = rowData.filter((item) =>
                item.eqpClsfNo?.startsWith(equipmentGroup || '')
              );

              if (filteredData.length > 0) {
                let maxNumber = 0;
                filteredData.forEach((item) => {
                  const num = parseInt(
                    (item.eqpClsfNo || '').substring((equipmentGroup || '').length + 1)
                  );
                  if (!isNaN(num) && num > maxNumber) {
                    maxNumber = num;
                  }
                });

                const nextNo =
                  maxNumber === 0
                    ? `${equipmentGroup}-01`
                    : `${equipmentGroup}-${(maxNumber + 1).toString().padStart(2, '0')}`;

                // UT일때 는 자동채번 하지않고 key-in
                if (selectedRow.mpClsfCd !== 'UT') {
                  selectedRow.eqpClsfNo = nextNo;
                }
                gridRef.current?.refresh();
              } else {
                findMpEqiupmentClassificationNewNo(equipmentGroup).then((data) => {
                  if (selectedRow.mpClsfCd !== 'UT') {
                    selectedRow.eqpClsfNo = data;
                  }
                  gridRef.current?.refresh();
                });
              }
            }
          }}
        />
      )}
    </>
  );
};

export default EditMpEquipmentClassificationMasterGrid;
