import { RoleEmployee } from 'models/admin/Role';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const getRoleEmps = async (typeCode: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/role/employees`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ roleCd: typeCode }),
  };
  const response: CommonResponse<RoleEmployee[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as RoleEmployee[];
};

export const setRoleEmps = async (roleCd: string, userIdList: string[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/role/employees`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { roleCd: roleCd, userIdList: userIdList },
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};

export const deleteRoleEmps = async (roleCd: string, userIdList: string) => {
  const request: CommonRequest = {
    method: Method.DELETE,
    url: `/v1/role/employees`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ roleCd: roleCd, userIdList: userIdList }),
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};
