import { MpItemCondition, MpItem, MpResultN } from 'models/mp/MpItem';
import { PaginationResponse } from 'models/common/Pagination';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

/**
 * MP 진행 현황 조회
 * @param condition
 */
export const selectMpTracking = async (condition?: MpItemCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/selectMpTracking`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<PaginationResponse<any>> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as PaginationResponse<any>;
};
