/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import CustomTextarea from 'components/inputs/CustomTextarea';
import { GuideBlock } from '../GuideComponent';

const TextAreaGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <>
      <GuideBlock title={'<CustomTextarea> Import'} isCode={true}>
        {`import CustomTextarea from 'components/inputs/CustomTextarea';`}
      </GuideBlock>
      <GuideBlock title={'<CustomTextarea> 속성'} isCode={true}>
        {`
interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  showMaxLength?: boolean; // 글자수제한 표기여부 (true인 경우 maxLength 값 필요)
  isError?: boolean; // 에러여부
  msgError?: string; // 에러문구
  wrapStyle?: any; // <TextareaWrap>에 추가되는 style
  // 그 외 속성은 InputHTMLAttributes 그대로 사용
}
        `}
      </GuideBlock>
      <Alert severity="warning">글자수제한 표기여부 true인 경우 maxLength 값 필수</Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>CustomTextarea - Basic</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomTextarea
                name="test"
                placeholder={'값을 입력해 주세요.'}
                wrapStyle={{ height: '120px' }} // <TextareaWrap>에 추가되는 style이 있는 경우 추가
                onChange={(e) => console.log(e)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomTextarea
  name="test"
  placeholder={'값을 입력해 주세요.'}
  wrapStyle={{ height: '120px' }} // <TextareaWrap>에 추가되는 style이 있는 경우 추가
  onChange={(e) => console.log(e)}
/>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>CustomTextarea - Error</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomTextarea
                name="test"
                placeholder={'값을 입력해 주세요.'}
                isError={true} // 에러 여부
                msgError={'에러 메세지가 표시됩니다.'} // 에러 메세지
                onChange={(e) => console.log(e)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomTextarea
  name="test"
  placeholder={'값을 입력해 주세요.'}
  isError={true} // 에러 여부
  msgError={'에러 메세지가 표시됩니다.'} // 에러 메세지
  onChange={(e) => console.log(e)}
/>
            `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary>CustomTextarea - 글자수제한 표기</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomTextarea
                name="test"
                placeholder={'값을 입력해 주세요.'}
                wrapStyle={{ height: '120px' }} // <TextareaWrap>에 추가되는 style이 있는 경우 추가
                showMaxLength={true} // 글자수제한 표기여부 (default: false)
                maxLength={1500}
                onChange={(e) => console.log(e)}
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomTextarea
  name="test"
  placeholder={'값을 입력해 주세요.'}
  wrapStyle={{ height: '120px' }} // <TextareaWrap>에 추가되는 style이 있는 경우 추가
  showMaxLength={true} // 글자수제한 표기여부 (default: false)
  maxLength={1500}
  onChange={(e) => console.log(e)}
/>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default TextAreaGuide;
