import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { contSearchParam } from 'pages/gtng/popup/GatingContPopUp';

/** Gating Cont(점검담당자) 목록 조회 */
export const getContInfoList = async (value) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getContInfoList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ contactId: value }),
  };
  const response: CommonResponse<contSearchParam[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as contSearchParam[];
};
