/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import CustomChip from 'components/selects/CustomChip';
import { GuideBlock } from '../GuideComponent';
import { Code } from 'models/common/CommonCode';

const ChipGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <>
      <GuideBlock title={'<CustomChip> Import'} isCode={true}>
        {`import CustomChip from 'components/selects/CustomChip';`}
      </GuideBlock>
      <GuideBlock title={'<CustomChip> 속성'} isCode={true}>
        {`
interface Props {
  options: Code[]; // 옵션목록
  defaultValue?: string | string[]; // 기본값
  singleSelect?: boolean; // 단건선택여부 (true: 1건 (default), false: N건)
  onChange: (value: string | string[]) => void; // 변경이벤트 (선택된 값 반환)
}
              `}
      </GuideBlock>
      <Alert severity="warning">
        공통코드를 조회해 노출하는경우가 많아 Code 타입을 그대로 사용, 다른 객체인 경우 타입에 맞춰
        변환 필요
        <br />
        <br />
        선택 옵션이 3개 이하인 경우 사용
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>CustomChip - SingleSelect</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomChip
                options={
                  [
                    { cmnCd: 'A', cmnCdNm: '에이' },
                    { cmnCd: 'B', cmnCdNm: '비' },
                  ] as Code[]
                }
                defaultValue={'A'}
                singleSelect={true} // true인 경우 생략 가능
                onChange={(value) => console.log(value)} // return string
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomChip
  options={
    [
      { cmnCd: 'A', cmnCdNm: '에이' },
      { cmnCd: 'B', cmnCdNm: '비' },
    ] as Code[]
  }
  defaultValue={'A'}
  singleSelect={true} // true인 경우 생략 가능
  onChange={(value) => console.log(value)} // return string
/>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>CustomChip - MultiSelect</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <CustomChip
                options={
                  [
                    { cmnCd: 'A', cmnCdNm: '에이' },
                    { cmnCd: 'B', cmnCdNm: '비' },
                  ] as Code[]
                }
                defaultValue={['A', 'B']} // or 'A'
                onChange={(value) => console.log(value)} // return string[]
                singleSelect={false} // 단건선택여부
              />
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomChip
  options={
    [
      { cmnCd: 'A', cmnCdNm: '에이' },
      { cmnCd: 'B', cmnCdNm: '비' },
    ] as Code[]
  }
  defaultValue={['A', 'B']} // or 'A'
  onChange={(value) => console.log(value)} // return string[]
  singleSelect={false} // 단건선택여부
/>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default ChipGuide;
