/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ManagementMode } from 'models/common/Common';
import { SensorMasterBase } from 'models/sensor/SensorRequest';
import CustomGrid from 'components/grids/CustomGrid';

interface Props {
  rowData: SensorMasterBase[];
  mode?: ManagementMode;
}

const SensorMasterGrid = ({ rowData, mode = ManagementMode.CREATE }: Props, ref) => {
  const { t } = useTranslation();

  const layoutDefinition = [
    {
      header: String(t('snsr.label.센서 분류', '센서 분류')),
      align: 'center',
      columns: [
        {
          binding: 'snsrLv1',
          header: String(t('snsr.grid.Lv1', 'Lv1')),
          align: 'left',
          width: 150,
        },
        {
          binding: 'snsrLv2',
          header: String(t('snsr.grid.Lv2', 'Lv2')),
          align: 'left',
          width: 150,
        },
        {
          binding: 'snsrLv3',
          header: String(t('snsr.grid.Lv3', 'Lv3')),
          align: 'left',
          width: 150,
        },
      ],
    },
    {
      binding: 'snsrMgtNo',
      header: String(t('snsr.label.센서관리번호', '센서관리번호')),
      align: 'left',
      width: 170,
    },
    {
      binding: 'snsrNm',
      header: String(t('snsr.label.센서명', '센서명')),
      align: 'left',
      width: 200,
    },
    {
      binding: 'snsrMaker',
      header: String(t('snsr.grid.센서 Maker', '센서Maker')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'ioLink',
      header: String(t('snsr.grid.IO 링크', 'IO링크')),
      align: 'left',
      width: 100,
    },
    {
      binding: 'expvYn',
      header: String(t('snsr.grid.방폭', '방폭')),
      align: 'left',
      width: 100,
    },
    {
      binding: 'ipGrdCtn',
      header: String(t('snsr.grid.IP 등급', 'IP등급')),
      align: 'left',
      width: 100,
    },
    {
      binding: 'intnCertCtn',
      header: String(t('snsr.grid.국제인증', '국제인증')),
      align: 'left',
      width: 100,
    },
    {
      binding: 'safeCertCtn',
      header: String(t('snsr.grid.안전인증', '안전인증')),
      align: 'left',
      width: 100,
    },
    {
      binding: 'wvlCtn',
      header: String(t('snsr.grid.Scan/Sample주파수', 'Scan/Sample주파수')),
      align: 'left',
      width: 170,
    },
    {
      binding: 'cmcKndCtn',
      header: String(t('snsr.grid.통신 종류', '통신종류')),
      align: 'left',
      width: 110,
    },
    {
      binding: 'cmcPrdCtn',
      header: String(t('snsr.grid.통신 주기(주파수)', '통신주기(주파수)')),
      align: 'left',
      width: 110,
    },
    {
      binding: 'motnPwrCtn',
      header: String(t('snsr.grid.구동전원', '구동전원')),
      align: 'left',
      width: 110,
    },
    {
      binding: 'rslnCtn',
      align: 'left',
      header: String(t('snsr.grid.분해능', '분해능')),
      width: 110,
    },
    {
      binding: 'cmcPrdCtn',
      header: String(t('snsr.grid.응답시간', '응답 시간')),
      align: 'left',
      width: 110,
    },
    {
      binding: 'wireSpecDesc',
      header: String(t('snsr.grid.배선사양', '배선사양')),
      align: 'left',
      width: 110,
    },
    {
      binding: 'cnntSpecDesc',
      header: String(t('snsr.grid.커넥터사양', '커넥터사양')),
      align: 'left',
      width: 110,
    },
    {
      binding: 'motnTmpr',
      header: String(t('snsr.grid.구동온도', '구동온도')),
      align: 'left',
      width: 110,
    },
    {
      binding: 'motnHmd',
      header: String(t('snsr.grid.구동습도', '구동 습도')),
      align: 'left',
      width: 110,
    },
    {
      binding: 'snstChgYn',
      header: String(t('snsr.grid.감도조절', '감도 조절')),
      align: 'left',
      width: 110,
    },
    {
      binding: 'lampYn',
      header: String(t('snsr.grid.표시등', '표시등')),
      align: 'left',
      width: 100,
    },
    {
      binding: 'mgtPram1Ctn',
      header: String(t('snsr.grid.관리파라미터1', '관리파라미터1')),
      align: 'left',
      width: 140,
    },
    {
      binding: 'mgtPram2Ctn',
      header: String(t('snsr.grid.관리파라미터2', '관리파라미터2')),
      align: 'left',
      width: 140,
    },
    {
      binding: 'mgtPram3Ctn',
      header: String(t('snsr.grid.관리파라미터3', '관리파라미터3')),
      align: 'left',
      width: 140,
    },
    {
      binding: 'mgtPram4Ctn',
      header: String(t('snsr.grid.관리파라미터4', '관리파라미터4')),
      align: 'left',
      width: 140,
    },
    {
      binding: 'mgtPram5Ctn',
      header: String(t('snsr.grid.관리파라미터5', '관리파라미터5')),
      align: 'left',
      width: 140,
    },
  ];

  return (
    <>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={500}
        isSelector={false}
        isReadOnly={true}
      />
    </>
  );
};
export default React.forwardRef(SensorMasterGrid);
