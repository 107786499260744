/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef, useTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { findNoticePosts } from 'apis/admin/Notice';
import { NoticeCondition, NoticePost } from 'models/admin/Notice';
import useSessionStore from 'stores/useSessionStore';
import { ICellRendererParams, CellClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { Pagination, PaginationRef } from 'components/layouts/Pagination';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { Button } from '@mui/material';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { flexGridShortDate } from 'models/ip/Public';
import CustomGrid from 'components/grids/CustomGrid';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';

interface NoticeEditProps {
  editType?: 'UPDATE' | 'CREATE';
  bbmNo?: string;
  userId?: string;
  bbmCtn?: string;
  atchFileGrId?: string;
}

const NoticeManagementPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [postList, setpostList] = useState<NoticePost[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [title, setTitle] = useState<string>('');
  const [contents, setContents] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(10);
  const { userId, roleCodes, gridNoRowsTemplate } = useSessionStore();
  const paginationRef = useRef<PaginationRef>(null);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);

  const AtchFileExistCellRenderer = (props: ICellRendererParams) => (
    <>{props.value == true && <Button className="cellFile"></Button>}</>
  );

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'bbsTpNm',
      header: String(t('admin.grid.분류', '분류')),
      width: 100,
    },
    {
      binding: 'bbmTitNm',
      header: String(t('admin.grid.제목', '제목')),
      align: 'left',
      width: '*',
    },
    {
      binding: 'atchFileGrId',
      visible: false,
    },
    {
      binding: 'atchFileExist',
      header: String(t('admin.grid.파일', '파일')),
      width: 80,
      align: 'center',
      cellTemplate: (params) => {
        return params.value > 0
          ? `<div class=${'fileDiv'}>
              <span>
                  <em>${(params.value || 0).toLocaleString()}</em>
              </span>
             </div>`
          : '';
      },
    },
    {
      binding: 'dataInsUserInfo',
      header: String(t('admin.grid.작성자', '작성자')),
      width: 200,
      align: 'center',
    },
    {
      binding: 'dataInsDtm',
      header: String(t('com.label.작성일시', '작성일시')),
      width: 140,
      align: 'center',
      cellTemplate: (ctx) => flexGridShortDate(ctx),
    },
    {
      binding: 'bbmVwct',
      header: String(t('admin.grid.조회수', '조회수')),
      align: 'center',
      width: 100,
    },
  ];

  const onInitialized = (grid) => {
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;
      const binding = grid.columns[ht.col].binding;

      switch (binding) {
        // 공통코드
        case 'atchFileExist': {
          setFileUploadModalCondition({
            atchFileGrId: item.atchFileGrId,
            atchFileTpCd: 'NORMAL',
            bizName: '',
            downloadOnly: true,
          });
          setOpenFileUploadModal(true);
          break;
        }
        case 'bbmTitNm': {
          navigate('/system/admin/sample/notice-edit', {
            state: {
              upprMnuUrl: location.pathname,
              mnuId: `/system/admin/sample/notice-edit/${item.bbmNo}`,
              mnuNm: `${t('admin.label.공지사항 상세', '공지사항 상세')}`,
              editType: userId != item.dataInsUserId ? 'SELECT' : 'UPDATE',
              bbmNo: item.bbmNo,
              bbmCtn: item.bbmCtn,
              atchFileGrId: item.atchFileGrId,
            },
          });
        }
      }
    });
  };

  const handleSearchClick = async () => {
    const noticeCondition = {
      bbmTitNm: title,
      bbmCtn: contents,
      pageSize: String(pageSize),
    } as NoticeCondition;
    const response = await findNoticePosts(noticeCondition);
    if (response) {
      const noticePosts = response.list || [];
      setpostList(noticePosts);
      setTotalCount(response.totalCount || 0);
      paginationRef.current?.setSelectNo(1);
    }
  };

  const handlePageNoClick = async (pageNo: number) => {
    const noticeCondition = {
      pageSize: String(pageSize),
      start: String(pageSize * (pageNo - 1)),
    };
    const response = await findNoticePosts(noticeCondition);
    if (response) {
      const noticePosts = response.list || [];
      setpostList(noticePosts);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await findNoticePosts({
        pageSize: String(pageSize),
      });
      if (response) {
        const noticePosts = response.list || [];
        setpostList(noticePosts);
        setTotalCount(response.totalCount || 0);
      }
    })();
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('admin.label.제목', '제목')}</label>
                <CustomInputWithSearch
                  className="width400"
                  value={title}
                  onKeyDown={handleKeyDown}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(e.target.value);
                  }}
                  placeholder={String(
                    t('admin.label.제목을 입력해 주세요.', '제목을 입력해 주세요.')
                  )}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('admin.label.내용', '내용')}</label>
                <CustomInputWithSearch
                  className="width400"
                  value={contents}
                  onKeyDown={handleKeyDown}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setContents(e.target.value);
                  }}
                  placeholder={String(
                    t('admin.label.내용을 입력해 주세요.', '내용을 입력해 주세요.')
                  )}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={() => {
                setTitle('');
                setContents('');
              }}
              disableRipple
            />
            <Button
              css={IconButton.button}
              className="find"
              disableRipple
              onClick={handleSearchClick}
            >
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>

      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.공지사항 목록', '공지사항 목록')}</h3>
          <span className="total">
            총 <span>{totalCount}</span>건
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="Exceldown" disableRipple>
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        initialized={onInitialized}
        rowData={postList}
        height={500}
        deferResizing={false}
        align={'center'}
        isSelector={false}
        isReadOnly={true}
      />
      {totalCount > 0 && (
        <Pagination
          ref={paginationRef}
          onChangePageSize={(pageSize: number) => {
            setPageSize(pageSize);
          }}
          onClickPageNo={handlePageNoClick}
          totalCount={totalCount}
        />
      )}
      {roleCodes.filter((o) => o === 'ADM').length > 0 && (
        <GlobalBtnGroup>
          <Button
            css={IconButton.button}
            className="write"
            disableRipple
            onClick={() => {
              navigate('/system/admin/sample/notice-edit', { state: { editType: 'CREATE' } });
            }}
          >
            {t('com.button.신규', '신규')}
          </Button>
        </GlobalBtnGroup>
      )}
      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          singleSelect={false}
          downloadOnly={fileUploadModalCondition.downloadOnly}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={() => setOpenFileUploadModal(false)}
        />
      )}
    </>
  );
};

export default NoticeManagementPage;
