/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useEvent from 'react-use-event-hook';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { DataType } from '@grapecity/wijmo';
import { DataMap, Row } from '@grapecity/wijmo.grid';
import _ from 'lodash';
import { IconButton } from 'components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { ComboBox } from 'components/selects/ComboBox';
import {
  ProcessBarWrap,
  ProcessNum,
  ProcessStep,
  ProcessStepBox,
  ProcessText,
  ProgressBar,
  StepBox,
} from 'components/process/Process';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import {
  completeCheckResultRecord,
  findCheckPlan,
  findCheckResultRecord,
  findRecordTargetEquipment,
  findRecordTargetPlan,
  getIpCheckValOkNg,
  saveCheckResultRecord,
  generateAtchFileGrId,
  downloadExcelCheckResultRecord,
} from 'apis/ip/IpCheckResult';
import ParameterPointResultRecordModal from 'components/modals/ip/ParameterPointResultRecordModal';
import { CheckResultCellTemplate } from './grid/CheckResultCellRenderer';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { Code, CommonCodeCondition } from 'models/common/CommonCode';
import { IpCheckResult, IpCheckResultCondition, IpCheckTarget } from 'models/ip/IpCheckResult';
import { IpCheckPlan } from 'models/ip/IpCheckPlan';
import { CrudCode } from 'models/common/Edit';
import { SuccessOrNot } from 'models/common/RestApi';
import { CommonYN, FileTypeName } from 'models/common/Common';
import ExcelUploadButton from 'components/buttons/ExcelUploadButton';
import { uploadExcelTemplates, downloadExcelTemplates } from 'apis/common/Excel';
import ExcelValidModal from 'components/modals/common/ExcelValidModal';
import FileUploadPopUp from '../../common/components/FileUploadPopUp';
import { useLoading } from 'components/process/Loading';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { hasRole } from 'utils/SessionUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';
import CustomGrid from 'components/grids/CustomGrid';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import WJCellTextarea from '../../../components/inputs/WJCellTextarea';
import { isReadable } from 'stream';

interface PageLocationState {
  ipCheckPlanNo?: string;
  isptTpCd?: string;
}

const CheckResultRecordPage = () => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const [flexRef, setflexRef] = useState<any>();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const locationState: PageLocationState = useLocation().state;
  const [searchParams] = useSearchParams();
  const [hasAuth, setHasAuth] = useState<boolean>(false);
  const [rowData, setRowData] = useState<IpCheckResult[]>([]);
  const ipStatusCodeRef = useRef<Code[]>([]);
  const [checkResultCondition, setCheckResultCondition] = useState<IpCheckResultCondition>({
    ipCheckPlanNo: locationState?.ipCheckPlanNo || searchParams?.get('ipCheckPlanNo') || '',
    isptTpCd: locationState?.isptTpCd || searchParams?.get('isptTpCd') || '',
  });
  const [targetPlan, setTargetPlan] = useState<IpCheckPlan[]>([]);
  const [targetEquipment, setTargetEquipment] = useState<IpCheckTarget[]>([]);
  const [isOpenParaPointResult, setOpenParaPointResult] = useState<boolean>(false);
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [isExcelValidModalOpen, setExcelValidModalOpen] = useState<boolean>(false);
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
  const [excelValidCondition, setExcelValidCondition] = useState<string | null>(null);
  // 현재 선택된 IP점검 계획
  const currentPlanRef = useRef<IpCheckResult>({});
  const [paraPointResultCondition, setParaPointResultCondition] = useState<any>(null);
  const [hitTest, setHitTest] = useState<any>();
  // select box용 데이터
  const [resultOkNg, setResultOkNg] = useState<Code[]>([]);

  const isEnsolStep = useMemo(
    () => (currentPlanRef.current?.ipCheckStatus || '').endsWith('ENSOL'),
    [currentPlanRef.current]
  );

  console.log('isEnsolStep useMemo', isEnsolStep);

  useEffect(() => {
    getCommonCodes();
    findRecordTargetPlan().then((result: IpCheckPlan[]) => {
      setTargetPlan(result);
    });
  }, []);
  console.log('searchParams', searchParams);

  useEffect(() => {
    // 협력사 권한체크
    if (!hasRole('PTN') || hasRole('ADM')) {
      setHasAuth(true);
    }
    console.log('checkResultCondition', checkResultCondition);

    if (locationState?.ipCheckPlanNo) {
      const condition = {
        ipCheckPlanNo:
          locationState?.ipCheckPlanNo ||
          checkResultCondition.ipCheckPlanNo ||
          searchParams?.get('ipCheckPlanNo') ||
          '',
        isptTpCd:
          locationState?.isptTpCd ||
          checkResultCondition.isptTpCd ||
          searchParams?.get('isptTpCd') ||
          '',
      };
      setCheckResultCondition(condition);
      handleSearch(condition);
    }
  }, [locationState]);

  const getCommonCodes = async () => {
    const ipStatusCode = await getCommonCodeNamesCondition({
      cmnGrCd: 'IP_STATUS_CODE',
      optValCtn2: CommonYN.Y, // ProcessStep 대상 여부
    } as CommonCodeCondition);
    ipStatusCodeRef.current = ipStatusCode;

    const resultOkNg: Code[] = await getCommonCodeNames('RESULT_OKNG');
    setResultOkNg(resultOkNg);

    const condition = {
      ipCheckPlanNo:
        locationState?.ipCheckPlanNo ||
        checkResultCondition.ipCheckPlanNo ||
        searchParams.get('ipCheckPlanNo'),
      isptTpCd:
        locationState?.isptTpCd || checkResultCondition.isptTpCd || searchParams.get('isptTpCd'),
    };

    handleSearch(condition);
  };

  useEffect(() => {
    let active = true;
    load();
    return () => {
      active = false;
    };

    async function load() {
      setTargetEquipment([]);
      setCheckResultCondition({
        ...checkResultCondition,
        equipmentId: '',
      });
      if (checkResultCondition.ipCheckPlanNo) {
        const response = await findRecordTargetEquipment(checkResultCondition);
        if (!active) {
          return;
        }
        setTargetEquipment(response);
      }
    }
  }, [checkResultCondition.ipCheckPlanNo]);

  const checkResultCellRenderer = (params) => {
    if (
      params.item.insertYn === 'N' ||
      params.item.paraPointType === 'POINT' ||
      params.item.stepStatus === 'done' ||
      params.item.isReadOnly
    ) {
      const ipCheckValOkNg =
        getIpCheckValOkNg(
          params.item.meterType,
          params.item.lowerBound,
          params.item.upperBound,
          params.value
        ) || '';
      if (params.value) {
        return `<div>${params.value || ''}</div>`;
      } else if (ipCheckValOkNg) {
        return `<div>${ipCheckValOkNg || ''}</div>`;
      } else {
        return `<div>N/A</div>`;
      }
    } else {
      return `<div>${params.value || ''}</div>`;
    }
  };

  const ValueCellRenderer = useEvent((params) => {
    if (
      params.item.paraPointType === 'POINT' &&
      getStepStatus(params.col.parentGroup.name) === 'now' &&
      params.item.insertYn === CommonYN.Y
    ) {
      return `
        ${params.value || ''}
        <Button/>
      `;
    }
    return `${params.value || ''}`;
  });

  const layoutDefinition = useMemo(() => {
    const layouts = [
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 45,
        align: 'center',
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'atchFileGrId',
        width: 70,
      },
      {
        binding: 'atchFileTpCd',
        width: 70,
      },
      {
        binding: 'equipmentGroup',
        header: String(t('com.label.설비군', '설비군')),
        align: 'center',
        width: 80,
        isReadOnly: true,
      },
      {
        header: String(t('ip.grid.설비', '설비')),
        align: 'center',
        columns: [
          {
            binding: 'electrodeTypeNm',
            width: 150,
            header: String(t('ip.grid.Anode/Cathode', 'Anode/Cathode')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'eqpMain',
            width: 150,
            header: String(t('ip.grid.대상설비(Main)', '대상설비(Main)')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'machineName',
            width: 150,
            header: String(t('ip.grid.Machine', 'Machine')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'unitName',
            width: 90,
            header: String(t('ip.grid.Unit', 'Unit')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'assemblyName',
            width: 100,
            header: String(t('ip.grid.Assembly', 'Assembly')),
            isReadOnly: true,
            align: 'center',
          },
        ],
      },
      {
        binding: 'paraName',
        header: String(t('ip.grid.파라미터명', '파라미터명')),
        isReadOnly: true,
        width: 230,
        align: 'left',
      },
      {
        binding: 'paraDesc',
        header: String(t('ip.grid.파라미터 설명', '파라미터 설명')),
        isReadOnly: true,
        width: 250,
        align: 'left',
      },
      {
        binding: 'isptTpCd',
        visible: false,
      },
      {
        header: String(t('ip.grid.사양', '사양')),
        align: 'center',
        columns: [
          {
            binding: 'specDataum',
            width: 130,
            header: String(t('ip.grid.측정기준', '측정기준')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'meterTypeNm',
            width: 120,
            header: String(t('ip.grid.미터유형', '미터유형')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'lowerBound',
            width: 100,
            header: String(t('ip.grid.하한', '하한')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'upperBound',
            width: 100,
            header: String(t('ip.grid.상한', '상한')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'uomNm',
            width: 110,
            header: String(t('ip.grid.UOM', 'UOM')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'rmk',
            width: 110,
            header: String(t('ip.grid.주석', '주석')),
            isReadOnly: true,
            align: 'center',
          },
        ],
      },
      {
        header: String(t('ip.grid.측정개소', '측정개소')),
        align: 'center',
        columns: [
          {
            binding: 'paraPointTypeNm',
            width: 100,
            header: String(t('com.label.유형', '유형')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'paraPointCnt',
            width: 80,
            header: String(t('ip.grid.개수', '개수')),
            isReadOnly: true,
            cellTemplate: (params) => {
              return params.item.paraPointCnt ? params.item.paraPointCnt.toLocaleString() : null;
            },
            align: 'center',
          },
        ],
      },
      {
        binding: 'guideAtchFileCnt',
        width: 80,
        header: String(t('com.label.첨부', '첨부')),
        isReadOnly: true,
        align: 'center',
        cellTemplate: (params) =>
          `<div className=${params.value > 0 && params.item.guideAtchFileGrId ? 'fileDiv' : ''}>
            <span>
              <em>${(params.value || 0).toLocaleString()}</em> 
            </span>
          </div>`,
      },
      {
        name: 'FAT_MAKER',
        header: String(t('ip.grid.FAT Maker', 'FAT Maker')),
        align: 'center',
        columns: [
          {
            binding: 'fatMakerTargetFlag',
            width: 100,
            header: String(t('ip.grid.대상여부', '대상여부')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'fatMakerValue',
            width: 100,
            header: String(t('ip.grid.Value', 'Value')),
            dataType: DataType.Number,
            align: 'right',
            cssClass: 'WijmoPopup',
            cellTemplate: ValueCellRenderer,
          },
          {
            binding: 'fatMakerResult',
            width: 100,
            header: String(t('ip.grid.Result', 'Result')),
            align: 'center',
            dataMap: new DataMap(resultOkNg, 'cmnCd', 'cmnCdNm'),
            cssClass: 'WijmoSelect',
            cellTemplate: (params) =>
              CheckResultCellTemplate({
                ...params,
                curValue: params.item.fatMakerValue,
                resultOkngCodes: resultOkNg,
                stepStatus: getStepStatus(params.col.parentGroup.name || ''),
              }),
          },
          {
            binding: 'fatMakerPlanInspectorNm',
            width: 100,
            header: String(t('ip.grid.점검자', '점검자')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'fatMakerComment',
            width: 100,
            header: String(t('ip.grid.Comment', 'Comment')),
            align: 'left',
          },
          {
            binding: 'fatMakerAtchFileCnt',
            header: String(t('com.label.첨부', '첨부')),
            isReadOnly: true,
            width: 80,
            align: 'center',
            cellTemplate: (params) => {
              return params.item.paraPointType === 'ATTACH'
                ? `<div class=${
                    params.value > 0 || isEditable(params.col?.parentGroup?.name || '', params.item)
                      ? 'fileDiv'
                      : ''
                  }>
                    <span>
                        <em>${(params.value || 0).toLocaleString()}</em>
                    </span>
                   </div>`
                : '';
            },
          },
        ],
      },
      {
        name: 'FAT_ENSOL',
        header: String(t('ip.grid.FAT 엔솔', 'FAT 엔솔')),
        align: 'center',
        columns: [
          {
            binding: 'fatEnsolTargetFlag',
            width: 100,
            header: String(t('ip.grid.대상여부', '대상여부')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'copyFatMaker',
            width: 100,
            header: String(t('ip.grid.결과복사', '결과복사')),
            align: 'center',
            isReadOnly: true,
            cssClass: 'WijmoCopy',
            cellTemplate: (params) => {
              if (hasAuth) {
                const { insertYn, paraPointType } = params.item;
                return insertYn === CommonYN.Y &&
                  getStepStatus(params.col.parentGroup.name) === 'now' &&
                  paraPointType !== 'POINT'
                  ? '<Button />'
                  : '';
              }
            },
          },
          {
            binding: 'fatEnsolValue',
            width: 100,
            header: String(t('ip.grid.Value', 'Value')),
            dataType: DataType.Number,
            isReadOnly: !hasAuth,
            align: 'right',
            cssClass: 'WijmoPopup',
            cellTemplate: ValueCellRenderer,
          },
          {
            binding: 'fatEnsolResult',
            width: 100,
            header: String(t('ip.grid.Result', 'Result')),
            align: 'center',
            isReadOnly: !hasAuth,
            dataMap: new DataMap(resultOkNg, 'cmnCd', 'cmnCdNm'),
            cssClass: 'WijmoSelect',
            cellTemplate: (params) =>
              CheckResultCellTemplate({
                ...params,
                curValue: params.item.fatEnsolValue,
                resultOkngCodes: resultOkNg,
                stepStatus: getStepStatus(params.col.parentGroup.name || ''),
              }),
          },
          {
            binding: 'fatEnsolPlanInspectorNm',
            width: 100,
            header: String(t('ip.grid.점검자', '점검자')),
            align: 'center',
            isReadOnly: true,
          },
          {
            binding: 'fatEnsolComment',
            width: 100,
            isReadOnly: !hasAuth,
            header: String(t('ip.grid.Comment', 'Comment')),
            align: 'left',
          },
          {
            binding: 'fatEnsolAtchFileCnt',
            header: String(t('com.label.첨부', '첨부')),
            width: 80,
            isReadOnly: true,
            align: 'center',
            cellTemplate: (params) => {
              return params.item.paraPointType === 'ATTACH'
                ? `<div class=${
                    params.value > 0 || isEditable(params.col?.parentGroup?.name || '', params.item)
                      ? 'fileDiv'
                      : ''
                  }>
                    <span>
                        <em>${(params.value || 0).toLocaleString()}</em>
                    </span>
                   </div>`
                : '';
            },
          },
        ],
      },
      {
        name: 'SAT_MAKER',
        header: String(t('ip.grid.SAT Maker', 'SAT Maker')),
        align: 'center',
        columns: [
          {
            binding: 'satMakerTargetFlag',
            width: 100,
            header: String(t('ip.grid.대상여부', '대상여부')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'satMakerValue',
            width: 100,
            header: String(t('ip.grid.Value', 'Value')),
            dataType: DataType.Number,
            align: 'right',
            cssClass: 'WijmoPopup',
            cellTemplate: ValueCellRenderer,
          },
          {
            binding: 'satMakerResult',
            width: 100,
            header: String(t('ip.grid.Result', 'Result')),
            align: 'center',
            dataMap: new DataMap(resultOkNg, 'cmnCd', 'cmnCdNm'),
            cssClass: 'WijmoSelect',
            cellTemplate: (params) =>
              CheckResultCellTemplate({
                ...params,
                curValue: params.item.satMakerValue,
                resultOkngCodes: resultOkNg,
                stepStatus: getStepStatus(params.col.parentGroup.name || ''),
              }),
          },
          {
            binding: 'satMakerPlanInspectorNm',
            width: 100,
            header: String(t('ip.grid.점검자', '점검자')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'satMakerComment',
            width: 100,
            header: String(t('ip.grid.Comment', 'Comment')),
            align: 'left',
          },
          {
            binding: 'satMakerAtchFileCnt',
            header: String(t('com.label.첨부', '첨부')),
            isReadOnly: true,
            width: 80,
            align: 'center',
            cellTemplate: (params) => {
              return params.item.paraPointType === 'ATTACH'
                ? `<div class=${
                    params.value > 0 ||
                    isEditable(
                      params.column?.getOriginalParent()?.getColGroupDef()?.groupId || '',
                      params.item
                    )
                      ? 'fileDiv'
                      : ''
                  }>
                    <span>
                        <em>${(params.value || 0).toLocaleString()}</em>
                    </span>
                   </div>`
                : '';
            },
          },
        ],
      },
      {
        name: 'SAT_ENSOL',
        header: String(t('ip.grid.SAT 엔솔', 'SAT 엔솔')),
        align: 'center',
        columns: [
          {
            binding: 'satEnsolTargetFlag',
            width: 100,
            header: String(t('ip.grid.대상여부', '대상여부')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'copySatMaker',
            width: 100,
            header: String(t('ip.grid.결과복사', '결과복사')),
            align: 'center',
            isReadOnly: true,
            cssClass: 'WijmoCopy',
            /* 클릭시 Maker의 결과 (Value, Result)를 그대로 복사 */
            cellTemplate: (params) => {
              if (hasAuth) {
                const { insertYn, paraPointType } = params.item;
                return insertYn === CommonYN.Y &&
                  getStepStatus(params.col.parentGroup.name) === 'now' &&
                  paraPointType !== 'POINT'
                  ? '<Button />'
                  : '';
              }
            },
          },
          {
            binding: 'satEnsolValue',
            width: 100,
            header: String(t('ip.grid.Value', 'Value')),
            isReadOnly: !hasAuth,
            dataType: DataType.Number,
            align: 'right',
            cssClass: 'WijmoPopup',
            cellTemplate: ValueCellRenderer,
          },
          {
            binding: 'satEnsolAtchFileCnt',
            header: String(t('com.label.첨부', '첨부')),
            width: 80,
            isReadOnly: true,
            align: 'center',
            cellTemplate: (params) => {
              return params.item.paraPointType === 'ATTACH'
                ? `<div class=${
                    params.value > 0 || isEditable(params.col?.parentGroup?.name || '', params.item)
                      ? 'fileDiv'
                      : ''
                  }>
                    <span>
                        <em>${(params.value || 0).toLocaleString()}</em>
                    </span>
                   </div>`
                : '';
            },
          },
          {
            binding: 'satEnsolResult',
            width: 100,
            header: String(t('ip.grid.Result', 'Result')),
            isReadOnly: !hasAuth,
            align: 'center',
            dataMap: new DataMap(resultOkNg, 'cmnCd', 'cmnCdNm'),
            cssClass: 'WijmoSelect',
            cellTemplate: (params) =>
              CheckResultCellTemplate({
                ...params,
                curValue: params.item.satEnsolValue,
                resultOkngCodes: resultOkNg,
                stepStatus: getStepStatus(params.col.parentGroup.name || ''),
              }),
          },
          {
            binding: 'satEnsolPlanInspectorNm',
            width: 100,
            header: String(t('ip.grid.점검자', '점검자')),
            isReadOnly: true,
            align: 'center',
          },
          {
            binding: 'satEnsolComment',
            width: 100,
            isReadOnly: !hasAuth,
            header: String(t('ip.grid.Comment', 'Comment')),
            align: 'left',
          },
        ],
      },
    ];

    let currentStepNo = -1;
    return layouts.reduce((acc: any, cur: any, idx) => {
      const isNow = (currentPlanRef.current || {}).ipCheckStatus === cur['name'];
      if (isNow) currentStepNo = idx;

      let isVisible = !isNow && currentStepNo > 0 ? false : true;

      // 숨길 대상 체크
      if (cur.visible === false && currentStepNo < 0) {
        isVisible = false;
      } else {
        if ('columns' in cur) {
          cur['columns'].map((o) => {
            o['visible'] = isVisible;
          });
        }
      }

      // 원래 숨겨야할 대상 체크
      if (['atchFileGrId', 'atchFileTpCd'].includes(cur.binding)) {
        isVisible = false;
      }

      return [
        ...acc,
        {
          ...cur,
          visible: isVisible,
        },
      ];
    }, []);
  }, [currentPlanRef.current]);

  // 검색 조건 > IP점검 계획 번호 (ComboBox 사용을 위해 변환)
  const optionsTargetPlan = useMemo(() => {
    return (targetPlan || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: cur.ipCheckPlanNo,
          cmnCdNm: cur.ipCheckPlanName,
        } as Code,
      ],
      [] as Code[]
    );
  }, [targetPlan]);
  // 검색 조건 > 대상설비 (ComboBox 사용을 위해 변환)
  const optionsTargetEquipment = useMemo(() => {
    return (targetEquipment || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: cur.equipmentId,
          cmnCdNm: cur.equipmentName,
        } as Code,
      ],
      [] as Code[]
    );
  }, [targetEquipment]);

  const saveAuthYn = useMemo<any>(
    () => ((rowData || []).filter((o) => o.insertYn === 'Y').length > 0 ? 'Y' : 'N'),
    [rowData]
  );

  const handleSearch = async (condition) => {
    if (_.isEmpty(condition.ipCheckPlanNo)) {
      // IP점검 계획 번호를 선택해 주세요.
      return;
    }

    findCheckPlan(condition).then((result) => {
      //setCurrentPlan(result);
      currentPlanRef.current = result;
    });

    const response = await findCheckResultRecord(condition);
    setRowData(response);
  };

  useEffect(() => {
    Object.keys(currentPlanRef.current || {}).length > 0 && changeColumnDefs();
  }, [currentPlanRef.current]);

  /**
   * 점검 단계 ProcessStep
   */
  const processSteps = useMemo(() => {
    let currentStepNo = -1;
    return (ipStatusCodeRef.current || []).reduce((acc, cur, idx) => {
      const isNow = (currentPlanRef.current || {}).ipCheckStatus === cur.cmnCd;
      if (isNow) currentStepNo = idx;
      return [
        ...acc,
        {
          ...cur,
          cmnCdDesc: isNow
            ? 'now'
            : Object.keys(currentPlanRef.current || {}).length > 0 && currentStepNo < 0
            ? 'done'
            : '', // or 'disable'
        },
      ];
    }, [] as Code[]);
  }, [ipStatusCodeRef.current, currentPlanRef.current]);

  /**
   * 현재 계획점검상태 기준 상태 확인
   * @param groupId   그리드 groupId (=점검상태코드)
   * @return now | done | wait
   */
  const getStepStatus = (name: string) => {
    const curStep = ipStatusCodeRef.current.findIndex(
      (o) => o.cmnCd === currentPlanRef.current.ipCheckStatus
    );
    const groupStep = ipStatusCodeRef.current.findIndex((o) => o.cmnCd === name);
    return groupStep === curStep ? 'now' : groupStep < curStep ? 'done' : 'wait';
  };

  /**
   * 그리드 입력값 수정 가능 여부
   */
  const isEditableForPoint = (groupName, data) => {
    return isEditable(groupName, data) && data.paraPointType !== 'POINT';
  };

  const isEditable = (groupName, data) => {
    const stepStatus = getStepStatus(groupName);
    return data?.insertYn === CommonYN.Y && stepStatus === 'now';
  };

  const handleFileUpload = async (
    data,
    parentGroupName,
    row,
    col,
    item,
    atchFileTpCd,
    isEnsolStep
  ) => {
    if (item.paraPointType === 'ATTACH' && (data > 0 || isEditable(parentGroupName, item))) {
      let atchFileGrId = item.atchFileGrId;
      if (_.isEmpty(atchFileGrId)) {
        atchFileGrId = await generateAtchFileGrId(item.ipCheckPlanNo, item.ipCheckTargetSeqId);
        if (atchFileGrId) {
          item.atchFileGrId = atchFileGrId;
        } else {
          openMessageBar({
            type: 'error',
            content:
              t(
                'com.label.첨부파일그룹ID 생성에 실패했습니다.',
                '첨부파일그룹ID 생성에 실패했습니다.'
              ) +
              <br /> +
              t('com.label.새로고침 후 다시 시도해 주세요.', '새로고침 후 다시 시도해 주세요.'),
          });
          return;
        }
      }
      setFileUploadModalCondition({
        atchFileGrId: atchFileGrId,
        atchFileTpCd: atchFileTpCd,
        optValCtn1: 'TB_ELM_IP_CHECK_RESULT_D',
        bizName: 'ip',
        // downloadOnly: !isEditable(parentGroupName, item) || (hasAuth === false && isEnsolStep),
        downloadOnly: !hasAuth && isEnsolStep,
        target: {
          row: row,
          col: col,
        },
      });
      setOpenFileUploadModal(true);
    }
  };

  /* 그리드 Column 속성 변경
   *
   * - groupId과 점검상태코드를 동일하게 맞춤
   * - 현재 점검 상태의 다음 단계에 해당하는 컬럼 모두 숨김
   */
  const changeColumnDefs = () => {
    let currentStepNo = -1;
    layoutDefinition.reduce((acc: any, cur: any, idx) => {
      const isNow = (currentPlanRef.current || {}).ipCheckStatus === cur['name'];
      if (isNow) currentStepNo = idx;

      let isVisible = !isNow && currentStepNo > 0 ? false : true;
      // 숨길 대상 체크
      if (cur.visible === false && currentStepNo < 0) {
        isVisible = false;
      } else {
        if ('columns' in cur) {
          cur['columns'].map((o) => {
            o['visible'] = isVisible;
          });
        }
      }
      return [
        ...acc,
        {
          ...cur,
          visible: isVisible,
        },
      ];
    }, []);
    //setLayout(init);
    gridRef.current?.refresh();
  };

  /**
   * 검색조건 초기화
   */
  const handleResetCondition = () => {
    setCheckResultCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as IpCheckResultCondition
    );
  };

  /**
   * 결과복사 (다건)
   */
  const handleCopy = () => {
    const rows = gridRef.current?.rows;
    if (!rows) return;
    copyValues(rows);
  };

  /**
   * 결과값 복사
   * @param rows
   */
  const copyValues = (rows: Row[]) => {
    const ensolYn = currentPlanRef.current.ensolYn || CommonYN.N;
    // FAT엔솔검증(FAT_ENSOL), SAT엔솔검증(SAT_ENSOL) 단계에서만 복사 가능 (엔솔대상여부가 N인 경우 복사 불가)
    // TODO 엔솔대상여부가 Y인데, 업체대상여부가 N인 케이스는 없음 (추후 실데이터 확인 필요)
    if (ensolYn === CommonYN.Y && hasAuth) {
      const ipCheckStep = currentPlanRef.current.ipCheckStep;
      rows.map((row) => {
        const item = row.dataItem || {};
        if (item.insertYn === CommonYN.Y && item.paraPointType !== 'POINT') {
          if (ipCheckStep === 'FAT' && item.fatEnsolTargetFlag === CommonYN.Y) {
            item.crudKey = CrudCode.UPDATE;
            item.fatEnsolValue = item.fatMakerValue;
            item.fatEnsolResult = item.fatMakerResult;
          } else if (ipCheckStep === 'SAT' && item.satEnsolTargetFlag === CommonYN.Y) {
            item.crudKey = CrudCode.UPDATE;
            item.satEnsolValue = item.satMakerValue;
            item.satEnsolResult = item.satMakerResult;
          }
        }
      });
      gridRef.current?.refresh();
    }
  };

  /**
   * 저장
   */
  const handleSave = () => {
    const saveData = rowData.filter((o) => o.crudKey === CrudCode.UPDATE);
    if (!saveData || saveData.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        saveCheckResultRecord(saveData)
          .then((response) => {
            if (response.successOrNot === SuccessOrNot.Y) {
              openMessageBar({
                type: 'confirm',
                content: t('com.label.저장되었습니다.', '저장되었습니다.'),
              });
              handleSearch(checkResultCondition);
            } else {
              openMessageBar({
                type: 'error',
                content:
                  response.data ||
                  t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
              });
            }
          })
          .catch((err) =>
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            })
          );
      },
    });
  };

  /**
   * 점검 완료
   */
  const handleCompleteCheck = () => {
    if (_.isEmpty(currentPlanRef.current.ipCheckPlanNo)) {
      openMessageBar({
        type: 'error',
        content: t('com.label.잘못된 접근입니다.', '잘못된 접근입니다.'),
      });
      return;
    }
    // 정성: OK/NG 사용자판단 result 필수
    // 정량: value, result 필수
    // 검수제외(NO_INSP): 결과값 필요 but OK, NG 상관없음 value필수 result OK/NG 상관없음
    // !NO_INSP: value 필수 result OK 필수
    let isPass = true;
    if (currentPlanRef.current.ipCheckStatus === 'FAT_MAKER') {
      for (const item of rowData) {
        if (item.isptTpCd === 'NO_INSP') {
          if (item.fatMakerTargetFlag === 'Y') {
            if (item.meterType === 'OKNG') {
              if (_.isNull(item.fatMakerResult)) {
                isPass = false;
              }
            } else {
              if (_.isNull(item.fatMakerResult) || _.isNull(item.fatMakerValue)) {
                isPass = false;
              }
            }
          }
        } else if (item.isptTpCd !== 'NO_INSP') {
          if (item.fatMakerTargetFlag === 'Y') {
            if (item.meterType === 'OKNG') {
              if (_.isNull(item.fatMakerResult)) {
                isPass = false;
              }
            } else {
              if (_.isNull(item.fatMakerResult) || _.isNull(item.fatMakerValue)) {
                isPass = false;
              }
            }
            if (item.fatMakerResult !== 'OK') {
              isPass = false;
            }
          }
        }
      }
    }
    if (currentPlanRef.current.ipCheckStatus === 'FAT_ENSOL') {
      for (const item of rowData) {
        if (item.isptTpCd === 'NO_INSP') {
          if (item.fatEnsolTargetFlag === 'Y') {
            if (item.meterType === 'OKNG') {
              if (_.isNull(item.fatEnsolResult)) {
                isPass = false;
              }
            } else {
              if (_.isNull(item.fatEnsolResult) || _.isNull(item.fatEnsolValue)) {
                isPass = false;
              }
            }
          }
        } else if (item.isptTpCd !== 'NO_INSP') {
          if (item.fatEnsolTargetFlag === 'Y') {
            if (item.meterType === 'OKNG') {
              if (_.isNull(item.fatEnsolResult)) {
                isPass = false;
              }
            } else {
              if (_.isNull(item.fatEnsolResult) || _.isNull(item.fatEnsolValue)) {
                isPass = false;
              }
            }
            if (item.fatEnsolResult !== 'OK') {
              isPass = false;
            }
          }
        }
      }
    }
    if (currentPlanRef.current.ipCheckStatus === 'SAT_MAKER') {
      for (const item of rowData) {
        if (item.isptTpCd === 'NO_INSP') {
          if (item.satMakerTargetFlag === 'Y') {
            if (item.meterType === 'OKNG') {
              if (_.isNull(item.satMakerResult)) {
                isPass = false;
              }
            } else {
              if (_.isNull(item.satMakerResult) || _.isNull(item.satMakerValue)) {
                isPass = false;
              }
            }
          }
        } else if (item.isptTpCd !== 'NO_INSP') {
          if (item.satMakerTargetFlag === 'Y') {
            if (item.meterType === 'OKNG') {
              if (_.isNull(item.satMakerResult)) {
                isPass = false;
              }
            } else {
              if (_.isNull(item.satMakerResult) || _.isNull(item.satMakerValue)) {
                isPass = false;
              }
            }
            if (item.satMakerResult !== 'OK') {
              isPass = false;
            }
          }
        }
      }
    }
    if (currentPlanRef.current.ipCheckStatus === 'SAT_ENSOL') {
      for (const item of rowData) {
        if (item.isptTpCd === 'NO_INSP') {
          if (item.satEnsolTargetFlag === 'Y') {
            if (item.meterType === 'OKNG') {
              if (_.isNull(item.satEnsolResult)) {
                isPass = false;
              }
            } else {
              if (_.isNull(item.satEnsolResult) || _.isNull(item.satEnsolValue)) {
                isPass = false;
              }
            }
          }
        } else if (item.isptTpCd !== 'NO_INSP') {
          if (item.satEnsolTargetFlag === 'Y') {
            if (item.meterType === 'OKNG') {
              if (_.isNull(item.satEnsolResult)) {
                isPass = false;
              }
            } else {
              if (_.isNull(item.satEnsolResult) || _.isNull(item.satEnsolValue)) {
                isPass = false;
              }
            }
            if (item.satEnsolResult !== 'OK') {
              isPass = false;
            }
          }
        }
      }
    }
    // if (currentPlanRef.current.ipCheckStatus === 'SAT_MAKER') {
    //   for (const item of rowData) {
    //     if (item.isptTpCd === 'NO_INSP') {
    //       if (item.satMakerTargetFlag === 'Y') {
    //         if (_.isNull(item.satMakerResult) || _.isNull(item.satMakerValue)) {
    //           isPass = false;
    //         }
    //       }
    //     } else if (item.isptTpCd !== 'NO_INSP') {
    //       if (item.satMakerTargetFlag === 'Y') {
    //         if (_.isNull(item.satMakerResult) || _.isNull(item.satMakerValue)) {
    //           isPass = false;
    //         } else {
    //           if (item.satMakerResult !== 'OK') {
    //             isPass = false;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // if (currentPlanRef.current.ipCheckStatus === 'SAT_ENSOL') {
    //   for (const item of rowData) {
    //     if (item.isptTpCd === 'NO_INSP') {
    //       if (item.satEnsolTargetFlag === 'Y') {
    //         if (_.isNull(item.satEnsolResult) || _.isNull(item.satEnsolValue)) {
    //           isPass = false;
    //         }
    //       }
    //     } else if (item.isptTpCd !== 'NO_INSP') {
    //       if (item.satEnsolTargetFlag === 'Y') {
    //         if (_.isNull(item.satEnsolResult) || _.isNull(item.satEnsolValue)) {
    //           isPass = false;
    //         } else {
    //           if (item.satEnsolResult !== 'OK') {
    //             isPass = false;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // if (currentPlanRef.current.ipCheckStatus === 'FAT_MAKER') {
    //   for (const item of rowData) {
    //     if (item.fatMakerTargetFlag === 'Y') {
    //       if (item.isptTpCd === 'NO_INSP') {
    //         if (item.fatMakerResult === 'OK' || item.fatMakerResult === 'NG') {
    //           isPass = true;
    //         }
    //       } else {
    //         if (item.fatMakerResult === 'OK') {
    //           isPass = true;
    //         } else {
    //           isPass = false;
    //           break;
    //         }
    //       }
    //     }
    //   }
    // }
    // if (currentPlanRef.current.ipCheckStatus === 'FAT_ENSOL') {
    //   for (const item of rowData) {
    //     if (item.fatEnsolTargetFlag === 'Y') {
    //       if (item.isptTpCd === 'NO_INSP') {
    //         if (item.fatEnsolResult === 'OK' || item.fatEnsolResult === 'NG') {
    //           isPass = true;
    //         }
    //       } else {
    //         if (item.fatEnsolResult === 'OK') {
    //           isPass = true;
    //         } else {
    //           isPass = false;
    //           break;
    //         }
    //       }
    //     }
    //   }
    // }
    // if (currentPlanRef.current.ipCheckStatus === 'SAT_MAKER') {
    //   for (const item of rowData) {
    //     if (item.satMakerTargetFlag === 'Y') {
    //       if (item.satMakerResult === 'OK' || item.satMakerResult === 'NG') {
    //         isPass = true;
    //       } else {
    //         isPass = false;
    //         break;
    //       }
    //     }
    //   }
    // }
    // if (currentPlanRef.current.ipCheckStatus === 'SAT_ENSOL') {
    //   for (const item of rowData) {
    //     if (item.satEnsolTargetFlag === 'Y') {
    //       if (item.satEnsolResult === 'OK' || item.satEnsolResult === 'NG') {
    //         isPass = true;
    //       } else {
    //         isPass = false;
    //         break;
    //       }
    //     }
    //   }
    // }

    if (!isPass) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.결과값을 입력해 주세요.', '결과값을 입력해 주세요.'),
      });
      return;
    }

    const saveData = rowData.filter((o) => o.crudKey === CrudCode.UPDATE);
    openCommonModal({
      modalType: 'confirm',
      content: t('ip.msg.점검을 완료하시겠습니까?', '점검을 완료하시겠습니까?'),
      yesCallback: async () => {
        completeCheckResultRecord(
          currentPlanRef.current.ipCheckPlanNo || '',
          saveData,
          currentPlanRef.current.isptTpCd || ''
        )
          .then((response) => {
            if (response.successOrNot === SuccessOrNot.Y) {
              openMessageBar({
                type: 'confirm',
                content: t('com.label.점검 완료되었습니다.', '점검 완료되었습니다.'),
              });
              handleSearch(checkResultCondition);
            } else {
              openMessageBar({
                type: 'error',
                content:
                  response.data ||
                  t('ip.msg.점검 중 오류가 발생했습니다.', '점검 중 오류가 발생했습니다.'),
              });
            }
          })
          .catch((err) =>
            openMessageBar({
              type: 'error',
              content: t('ip.msg.점검 중 오류가 발생했습니다.', '점검 중 오류가 발생했습니다.'),
            })
          );
      },
    });
  };

  const downloadExcel = async (templateType) => {
    if (checkResultCondition.ipCheckPlanNo) {
      downloadExcelTemplates(templateType, checkResultCondition.ipCheckPlanNo);
    } else {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.IP점검 계획 번호를 선택해 주세요.', 'IP점검 계획 번호를 선택해 주세요.'),
      });
      return;
    }
  };

  const handleDownloadExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ip.label.IP 점검결과 입력', 'IP 점검결과 입력');
    book.saveAsync(getExcelFileName(t('ip.label.IP 점검결과 입력', 'IP 점검결과 입력')) + '.xlsx');

    // downloadExcelCheckResultRecord(excelData);
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    const column = panel.columns[col];
    const binding = column.binding || '';

    if (binding.endsWith('Value') || binding.endsWith('Result') || binding.endsWith('Comment')) {
      const data = panel.rows[row].dataItem;
      if (!data) return;

      const isEditForPoint = isEditableForPoint(column.parentGroup.name, data);
      const stepStatus = getStepStatus(column.parentGroup.name || '');
      if (binding.endsWith('Comment')) {
        data[`${binding}Editing`] = isEditForPoint;
      }
      cell.ariaReadOnly = !isEditForPoint;
      if (!hasAuth) {
        if (
          [
            'fatEnsolValue',
            'fatEnsolResult',
            'fatEnsolComment',
            'satEnsolValue',
            'satEnsolResult',
            'satEnsolComment',
          ].includes(binding)
        ) {
          cell.ariaReadOnly = true;
        }
      }
      if ('fatMakerValue' === binding) {
        const { meterType, lowerBound, upperBound, fatMakerValue } = data;
        const okng = getIpCheckValOkNg(meterType, lowerBound, upperBound, fatMakerValue);
        if (okng) {
          data.fatMakerResult = okng || '';
        }
        data.fatMakerIpCheckStep = 'FAT';
        data.fatMakerStepStatus = stepStatus;
      } else if ('fatMakerResult' === binding) {
        if (!isEditForPoint && cell.firstElementChild instanceof HTMLButtonElement) {
          cell.firstChild.remove();
        }
      } else if ('fatEnsolValue' === binding) {
        const { meterType, lowerBound, upperBound, fatEnsolValue } = data;
        const okng = getIpCheckValOkNg(meterType, lowerBound, upperBound, fatEnsolValue);
        if (okng) {
          data.fatEnsolResult = okng || '';
        }

        data.fatEnsolIpCheckStep = 'FAT';
        data.fatEnsolStepStatus = stepStatus;
      } else if ('fatEnsolResult' === binding) {
        if (!isEditForPoint && cell.firstElementChild instanceof HTMLButtonElement) {
          cell.firstChild.remove();
        }
      } else if ('satMakerValue' === binding) {
        const { meterType, lowerBound, upperBound, satMakerValue } = data;
        const okng = getIpCheckValOkNg(meterType, lowerBound, upperBound, satMakerValue);
        if (okng) {
          data.satMakerResult = okng || '';
        }
        data.satMakerIpCheckStep = 'SAT';
        data.satMakerStepStatus = stepStatus;
      } else if ('satMakerResult' === binding) {
        if (!isEditForPoint && cell.firstElementChild instanceof HTMLButtonElement) {
          cell.firstChild.remove();
        }
      } else if ('satEnsolValue' === binding) {
        const { meterType, lowerBound, upperBound, satEnsolValue } = data;
        const okng = getIpCheckValOkNg(meterType, lowerBound, upperBound, satEnsolValue);
        if (okng) {
          data.satEnsolResult = okng || '';
        }

        data.satEnsolIpCheckStep = 'SAT';
        data.satEnsolStepStatus = stepStatus;
      } else if ('satEnsolResult' === binding) {
        if (!isEditForPoint && cell.firstElementChild instanceof HTMLButtonElement) {
          cell.firstChild.remove();
        }
      }
    }
  });

  const onInitialized = useEvent((grid) => {
    gridRef.current = grid;
    setflexRef(grid);

    grid.addEventListener(grid.hostElement, 'click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return;

      if (ht.panel === grid.cells) {
        const col = grid.columns[ht.col];
        const data = grid.getCellData(ht.row, ht.col);

        const item = grid.rows[ht.row].dataItem;
        const binding = grid.columns[ht.col].binding;
        const parentGroupName = col.parentGroup?.name;
        console.log('item', item);
        if ('copySatMaker' === binding) {
          // 클릭시 Maker의 결과 (Value, Result)를 그대로 복사
          copyValues([grid.rows[ht.row]]);
        }

        if (
          ['fatMakerValue', 'fatEnsolValue', 'satMakerValue', 'satEnsolValue'].includes(binding)
        ) {
          let stepStatus = '',
            ipCheckStep = '';
          switch (binding) {
            case 'fatMakerValue': {
              stepStatus = item.fatMakerStepStatus;
              ipCheckStep = item.fatMakerIpCheckStep;
              break;
            }
            case 'fatEnsolValue': {
              stepStatus = item.fatEnsolStepStatus;
              ipCheckStep = item.fatEnsolIpCheckStep;
              break;
            }
            case 'satMakerValue': {
              stepStatus = item.satMakerStepStatus;
              ipCheckStep = item.satMakerIpCheckStep;
              break;
            }
            case 'satEnsolValue': {
              stepStatus = item.satEnsolStepStatus;
              ipCheckStep = item.satEnsolIpCheckStep;
              break;
            }
          }

          if (
            item.paraPointType === 'POINT' &&
            stepStatus === 'now' &&
            item.insertYn === CommonYN.Y
          ) {
            const { ipCheckPlanNo, ipCheckTargetSeqId, ipCheckStatus } = item;
            setParaPointResultCondition({
              ipCheckPlanNo: ipCheckPlanNo,
              ipCheckTargetSeqId: ipCheckTargetSeqId,
              ipCheckStep: ipCheckStep,
              ipCheckStatus: ipCheckStatus,
              isReadOnly: stepStatus !== 'now',
              targetNode: item,
              targetColId: binding,
              hasAuth: hasAuth,
            });
            setOpenParaPointResult(true);
          }
        } else if (
          ['fatMakerComment', 'fatEnsolComment', 'satMakerComment', 'satEnsolComment'].includes(
            binding
          )
        ) {
          if (!grid.columns[ht.col].isReadOnly) {
            setHitTest(ht); // 클릭 위치정보
            setOpenCellTextarea(!!item[`${binding}Editing`]);
          }
        } else if ('copyFatMaker' === binding) {
          const { insertYn, paraPointType } = item;
          if (
            insertYn === CommonYN.Y &&
            getStepStatus(grid.columns[ht.col].parentGroup.name) === 'now' &&
            paraPointType !== 'POINT'
          ) {
            // 클릭시 Maker의 결과 (Value, Result)를 그대로 복사
            copyValues([grid.rows[ht.row]]);
          }
        } else if ('fatMakerAtchFileCnt' === binding) {
          handleFileUpload(data, parentGroupName, ht.row, ht.col, item, 'FAT_M_RST', false);
        } else if ('fatEnsolAtchFileCnt' === binding) {
          handleFileUpload(data, parentGroupName, ht.row, ht.col, item, 'FAT_E_RST', true);
        } else if ('satMakerAtchFileCnt' === binding) {
          handleFileUpload(data, parentGroupName, ht.row, ht.col, item, 'SAT_M_RST', false);
        } else if ('satEnsolAtchFileCnt' === binding) {
          handleFileUpload(data, parentGroupName, ht.row, ht.col, item, 'SAT_E_RST', true);
        } else if ('guideAtchFileCnt' === binding) {
          if (data > 0 && item.guideAtchFileGrId) {
            setFileUploadModalCondition({
              atchFileGrId: item.guideAtchFileGrId,
              atchFileTpCd: 'NORMAL',
              bizName: 'ip',
              downloadOnly: true,
            });
            setOpenFileUploadModal(true);
          }
        }
      }
    });
  });

  const onBeginningEdit = useEvent((s, e) => {
    const binding = s.columns[e.col].binding || '';
    const row = s.rows[e.row] || '';
    if (binding.endsWith('Value') || binding.endsWith('Result') || binding.endsWith('Comment')) {
      const item = s.rows[e.row].dataItem;
      // Value 입력가능한 조건이 아니면 입력 이벤트 취소 처리
      if (!isEditableForPoint(s.columns[e.col].parentGroup.name, item)) {
        e.cancel = true;
        if (binding.endsWith('Comment')) {
          setOpenCellTextarea(false);
        }
      }
    }
    if (
      binding === 'guideAtchFileCnt' ||
      binding === 'fatMakerAtchFileCnt' ||
      binding === 'fatEnsolAtchFileCnt' ||
      binding === 'satMakerAtchFileCnt' ||
      binding === 'satEnsolAtchFileCnt'
    ) {
      if (row.dataItem.paraPointType === 'POINT') e.cancel = true;
    }
  });

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="twoCol">
              <SearchCols>
                <label>
                  <span className="dot">{t('ip.label.IP점검계획번호', 'IP점검계획번호')}</span>
                </label>
                <ComboBox
                  placeholder={String(
                    t(
                      'ip.msg.IP점검 계획 번호를 선택해 주세요.',
                      'IP점검 계획 번호를 선택해 주세요.'
                    )
                  )}
                  options={optionsTargetPlan}
                  defaultValue={checkResultCondition.ipCheckPlanNo}
                  onChange={(value: string) =>
                    setCheckResultCondition({
                      ...checkResultCondition,
                      ipCheckPlanNo: value,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ip.label.대상설비(Main)', '대상설비(Main)')}</label>
                <ComboBox
                  placeholder={String(
                    t('ip.msg.대상 설비를 선택해 주세요.', '대상 설비를 선택해 주세요.')
                  )}
                  options={optionsTargetEquipment}
                  defaultValue={checkResultCondition.equipmentId}
                  onChange={(value: string) =>
                    setCheckResultCondition({
                      ...checkResultCondition,
                      equipmentId: value,
                    })
                  }
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            ></Button>
            <Button
              css={IconButton.button}
              className="find"
              onClick={() => handleSearch(checkResultCondition)}
              disableRipple
            >
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      {!_.isEmpty(processSteps) && (
        <ProcessBarWrap className="mt24">
          {(processSteps || []).map((o, i) => (
            <ProcessStepBox key={o.cmnCd} className={o.cmnCdDesc}>
              {/* done, now, disable */}
              <StepBox>
                <ProcessStep>
                  <ProcessNum>{i + 1}</ProcessNum>
                  <ProcessText>{o.cmnCdNm}</ProcessText>
                </ProcessStep>
              </StepBox>
            </ProcessStepBox>
          ))}
          <ProgressBar></ProgressBar>
        </ProcessBarWrap>
      )}
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.점검 결과 입력', '점검 결과 입력')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          {/* 엔솔 점검 단계에서만 결과복사 사용 (FAT_ENSOL, SAT_ENSOL) */}
          {(currentPlanRef.current?.ipCheckStatus || '').endsWith('ENSOL') &&
            saveAuthYn === CommonYN.Y && (
              <Button css={IconButton.button} className="copy" onClick={handleCopy} disableRipple>
                {t('ip.button.결과복사', '결과복사')}
              </Button>
            )}
          {saveAuthYn === CommonYN.Y && (
            <Button
              css={IconButton.button}
              className="Exceldown"
              onClick={() => downloadExcel(FileTypeName.TPL_IP_RST)}
              disableRipple
            >
              {t('com.button.템플릿 다운로드', '템플릿 다운로드')}
            </Button>
          )}
          {saveAuthYn === CommonYN.Y && (
            <ExcelUploadButton
              onCallback={(file) => {
                openLoading(true);
                uploadExcelTemplates(
                  file,
                  'TPL_IP_RST',
                  'NORMAL',
                  'TB_ELM_IP_CHECK_RESULT_D',
                  checkResultCondition?.ipCheckPlanNo || ''
                )
                  .then((response) => {
                    openLoading(false);
                    if (response?.data.x_result == 'OK') {
                      openMessageBar({
                        type: 'confirm',
                        content: t('ip.msg.검증이 완료되었습니다.', '검증이 완료되었습니다.'),
                      });
                      handleSearch(checkResultCondition);
                    } else {
                      setExcelValidCondition(response?.data.p_xls_upload_id);
                      setExcelValidModalOpen(true);
                    }
                  })
                  .finally(() => openLoading(false));
              }}
            />
          )}
          {currentPlanRef.current.ipCheckPlanNo && (
            <Button
              css={IconButton.button}
              className="Exceldown"
              onClick={handleDownloadExcel}
              disableRipple
            >
              {t('com.button.다운로드', '다운로드')}
            </Button>
          )}
        </ControlBtnGroup>
      </SubTitleLayout>

      <CustomGrid
        rowData={rowData}
        layoutDefinition={layoutDefinition}
        itemFormatter={onItemFormatter}
        beginningEdit={onBeginningEdit}
        initialized={onInitialized}
        height={500}
      />
      {hasAuth === false && isEnsolStep ? (
        ''
      ) : (
        <GlobalBtnGroup>
          <Button css={IconButton.button} className="draft" onClick={handleSave} disableRipple>
            {t('com.button.저장', '저장')}
          </Button>
          <Button
            css={IconButton.button}
            className="confirm"
            onClick={handleCompleteCheck}
            disableRipple
          >
            {t('ip.button.점검완료', '점검완료')}
          </Button>
        </GlobalBtnGroup>
      )}
      {isOpenParaPointResult && (
        <ParameterPointResultRecordModal
          open={isOpenParaPointResult}
          condition={paraPointResultCondition}
          close={(repResult) => {
            if (repResult) {
              // 등록된 측정개소 대표값으로 그리드 값 변경
              const { targetNode, targetColId } = paraPointResultCondition;
              const keyNm = repResult.ensolYn === CommonYN.Y ? 'ensol' : 'maker';
              const resultKeyNm = targetColId.replace('Value', 'Result');
              const commentKeyNm = targetColId.replace('Value', 'Comment');

              targetNode[targetColId] = repResult[`${keyNm}Value`];
              targetNode[resultKeyNm] = repResult[`${keyNm}Result`];
              targetNode[commentKeyNm] = repResult[`${keyNm}Comment`];
            }
            gridRef.current.refresh();

            setParaPointResultCondition(null);
            setOpenParaPointResult(false);
          }}
        />
      )}
      {isExcelValidModalOpen && (
        <ExcelValidModal
          open={isExcelValidModalOpen}
          close={() => {
            setExcelValidCondition(null);
            setExcelValidModalOpen(false);
          }}
          condition={{
            valReqId: excelValidCondition || '',
          }}
        />
      )}
      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          singleSelect={false}
          downloadOnly={fileUploadModalCondition.downloadOnly}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            const target = fileUploadModalCondition.target;
            if (target) {
              gridRef.current?.setCellData(target.row, target.col, fileCount);
            }
            setOpenFileUploadModal(false);
          }}
        />
      )}
      {isOpenCellTextarea && (
        <WJCellTextarea
          grid={gridRef.current}
          hitTest={hitTest}
          close={() => setOpenCellTextarea(false)}
        />
      )}
    </>
  );
};

export default CheckResultRecordPage;
