import { Employee } from 'models/admin/Employee';
import { ElmEmp } from 'models/common/ElmEmp';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const getElmEmpList = async (searchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getElmEmpList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ElmEmp[];
};
