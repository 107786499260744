export enum BmkAtcTpCd {
  MP_LIST = 'MP_LIST',
}

export interface BookmarkCondition {
  bmkAtcTpCd?: string; // 즐겨찾기 항목 유형 코드
}

export interface BookmarkMaster {
  bmkGrId?: string;
  bmkGrDesc?: string;
  bmkAtcTpCd?: string;
  atchFileGrId?: string;
  useYn?: string;
  optValCtn1?: string;
  optValCtn2?: string;
  optValCtn3?: string;
  optValCtn4?: string;
  optValCtn5?: string;
}
