import { IpCheckResult, IpCheckResultCondition, IpCheckTarget } from 'models/ip/IpCheckResult';
import { IpCheckResultPointCondition, IpCheckResultPoint } from 'models/ip/IpCheckResultPoint';
import { IpCheckPlan } from 'models/ip/IpCheckPlan';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { downloadFile } from 'apis/common/Excel';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { PaginationResponse } from 'models/common/Pagination';

/**
 * IP 점검계획 정보 조회
 * @param condition
 */
export const findCheckPlan = async (condition?: IpCheckResultCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/check-plan`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<IpCheckResult> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IpCheckResult;
};

/**
 * IP 점검결과 입력 대상 계획 목록 조회
 * @param condition
 */
export const findRecordTargetPlan = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/check-result-record/target/plan`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<IpCheckPlan[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IpCheckPlan[];
};

/**
 * IP 점검결과 입력 대상 설비 목록 조회
 * @param condition
 */
export const findRecordTargetEquipment = async (condition?: IpCheckResultCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/check-result-record/target/equipment`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<IpCheckTarget[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IpCheckTarget[];
};

/**
 * IP 점검결과 입력 목록 조회
 * @param condition
 */
export const findCheckResultRecord = async (condition?: IpCheckResultCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/check-result-record`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<IpCheckResult[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IpCheckResult[];
};

/**
 * IP 점검결과 입력 목록 엑셀 다운로드
 * @param condition
 */
export const downloadExcelCheckResultRecord = async (
  condition: ExcelDownloadRequest<IpCheckResultCondition>
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/check-result-record/excel-download`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, `${condition.fileName}`);
};

/**
 * IP 점검결과 목록 조회
 * @param condition
 */
export const findCheckResult = async (condition?: IpCheckResultCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/check-result`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<IpCheckResult[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as IpCheckResult[];
  // return (response.successOrNot === 'Y' ? response.data : null) as IpCheckResult[];
};

/**
 * IP 점검결과 측정개소 결과 조회
 * @param condition
 */
export const findCheckResultParaPoint = async (condition?: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/check-result-para-point`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<IpCheckResult[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as IpCheckResult[];
  // return (response.successOrNot === 'Y' ? response.data : null) as IpCheckResult[];
};

/**
 * IP 점검결과 목록 엑셀 다운로드
 * @param condition
 */
export const downloadExcelCheckResult = async (
  condition: ExcelDownloadRequest<IpCheckResultCondition>
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/check-result/excel-download`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, `${condition.fileName}`);
};

/**
 * 측정개소 점검결과 입력 정보 조회
 * @param condition
 */
export const findCheckResultPointRecord = async (condition?: IpCheckResultPointCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/check-result-record/point`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<IpCheckResultPoint[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IpCheckResultPoint[];
};

/**
 * IP 점검결과 입력 정보 저장
 * @param records
 */
export const saveCheckResultRecord = async (records: IpCheckResult[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/check-result-record`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: records,
  };
  const response: CommonResponse<void> = await callApi(request);
  return response;
};

/**
 * 측정개소 점검결과 입력 정보 저장
 * @param records
 */
export const saveCheckResultPointRecord = async (records: IpCheckResultPoint[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/check-result-record/point`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: records,
  };
  const response: CommonResponse<IpCheckResult | string> = await callApi(request);
  return response;
};

/**
 * IP 점검결과 > 점검 완료 처리
 * @param ipCheckPlanNo IP점검계획번호
 * @param saveRows  IP 점검 결과 목록 (수정대상)
 */
export const completeCheckResultRecord = async (
  ipCheckPlanNo: string,
  saveRows?: IpCheckResult[],
  isptTpCd?: string
) => {
  const condition = {
    ipCheckPlanNo: ipCheckPlanNo,
    ipCheckResults: saveRows,
    isptTpCd: isptTpCd,
  };
  console.log('condition', condition);
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/check-result-record/complete`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  console.log('request', request);

  const response: CommonResponse<void> = await callApi(request);
  return response;
};

/**
 * 첨부파일 그룹ID 생성
 * @param ipCheckPlanNo
 * @param ipCheckTargetSeqId
 */
export const generateAtchFileGrId = async (ipCheckPlanNo: string, ipCheckTargetSeqId: string) => {
  const condition = {
    ipCheckPlanNo: ipCheckPlanNo,
    ipCheckTargetSeqId: ipCheckTargetSeqId,
  };

  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/check-result-record/attach`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<string> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * Result 값 자동 판단 설정
 * @param meterType   Mater 유형
 * @param lowerBound  하한
 * @param upperBound  상한
 * @param value       입력값
 */
export const getIpCheckValOkNg = (meterType, lowerBound, upperBound, value) => {
  const ok = 'OK',
    ng = 'NG';
  // TODO 경계값에 대해 이하/미만, 이상/초과에 대한 로직 결정 필요 (현재는 이상/이하로 적용)
  // 0인 경우 !! 조건에서 막힘
  if (typeof value !== 'number') return null;
  const val = String(value);
  // Meter(정량)이면서 입력값이 있는 경우
  if (meterType === 'METER' && !!val) {
    // 상한, 하한 모두 없는 경우
    if (!lowerBound && !upperBound) {
      return null;
    }
    // [23.11.23] string -> number로 변경 후 값 비교 (개소관리인 경우 OK값이 NG로 표현되는 오류발생)
    // 하한만 있는 경우
    if (!!lowerBound && !upperBound) {
      return Number(val) <= Number(lowerBound) ? ng : ok;
    }
    // 상한만 있는 경우
    else if (!lowerBound && !!upperBound) {
      return Number(val) >= Number(upperBound) ? ng : ok;
    }
    // 고정값인 경우 (상한=하한)
    else if (lowerBound === upperBound) {
      return Number(val) == Number(lowerBound) ? ok : ng;
    }
    // 하한 <= 입력값 <= 상한 ? 'OK' : 'NG'
    else if (!!lowerBound && !!upperBound) {
      return Number(val) >= Number(lowerBound) && Number(val) <= Number(upperBound) ? ok : ng;
    }
  }
  return null;
};
