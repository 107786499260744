import { MpItemCondition, MpItem } from 'models/mp/MpItem';
import { PaginationResponse } from 'models/common/Pagination';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { MpItemCop } from '../../models/mp/MpItemCop';

/**
 * 법인 대상 목록 조회
 * @param condition
 */
export const findMpItemCop = async (itemId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/${itemId}/cop`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<MpItemCop[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : []) as MpItemCop[];
};

/**
 * 법인 대상 설정 저장
 * @param itemId
 */
export const saveMpItemCop = async (itemId: string, copList: MpItemCop[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/${itemId}/cop`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: copList,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};
