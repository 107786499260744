/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GatingCheckSheetVer } from 'models/gtng/popup/GatingCheckSheetVer';
import { saveGatingCheckSheetVersionUp } from 'apis/gtng/GatingCheckSheet';
import { useCommonModal } from 'hooks/useCommonModal';
import DialogContent from '@mui/material/DialogContent';
import { tx } from 'components/inputs/CustomInput';
import { CloseBtn } from 'components/buttons/CustomButton';
import TextareaAutosize from 'react-textarea-autosize';
import { di, PopupBtnGroup, TitleWrap } from 'components/layouts/Dialog';

type Props = {
  open: boolean;
  close: () => void;
  initParam?: {
    gatingId?: string;
    equipmentId?: string;
    guideText?: string;
  };
  // onCallback: () => void;
};
/**
 * 평가/채점 > 가중치,가이드 팝업
 * @param open
 * @param close
 * @param initParam
 * @constructor
 */
export const GatingCheckGuidePopUp = ({ open, close, initParam }: Props) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<GatingCheckSheetVer>();
  const { openCommonModal } = useCommonModal();

  /**
   * props.initParam 변경 시 실행1
   */
  useEffect(() => {
    // setRowData(props);
  }, [initParam]);

  /**
   * 버전신규 적용  등록버튼
   */
  const btnCkVerInsertInfo = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('gtng.msg.VersionUp 하시겠습니까?', 'VersionUp 하시겠습니까?'),
      yesCallback: async () => {
        if (rowData == null) return;
        // rowData.gatingTemplateId = initParam?.gatingTemplateId || '';
        saveGatingCheckSheetVersionUp(rowData).then((response) => {
          if (response != null && response.successOrNot == 'N') {
            openCommonModal({
              content: t('gtng.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return;
          }
          openCommonModal({
            content: t('gtng.msg.저장되었습니다.', '저장되었습니다.'),
            yesCallback: () => {
              close();
            },
          });
        });
      },
    });
  };

  return (
    <Dialog open={true} css={di.dialog} fullWidth className="sm" maxWidth={false}>
      <TitleWrap>
        <h2>{t('gtng.button.배점/가중치 가이드', '배점/가중치 가이드')}</h2>
        <CloseBtn onClick={() => close()} />
      </TitleWrap>
      <DialogContent>
        <div css={tx.textareaWrap}>
          <TextareaAutosize readOnly cacheMeasurements>
            {initParam?.guideText || ''}
          </TextareaAutosize>
        </div>
      </DialogContent>

      <PopupBtnGroup>
        <Button className="cancel" disableRipple onClick={() => close()}>
          {t('com.button.취소', '취소')}
        </Button>
      </PopupBtnGroup>
    </Dialog>
  );
};
