/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useCommonModal } from 'hooks/useCommonModal';
import { getRoles, setRoles } from 'apis/admin/Role';
import {
  ColDef,
  ValueFormatterParams,
  IsRowSelectable,
  IRowNode,
  CellValueChangedEvent,
  EditableCallbackParams,
  GridReadyEvent,
  ICellRendererParams,
} from 'ag-grid-community';
import { Role } from 'models/admin/Role';
import { CommonYN } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import { ContentGrid } from 'components/layouts/ContentGrid';
import {
  DropdownCellRenderer,
  DropdownItem,
  IDropdownCellRendererParams,
} from 'components/grids/DropdownCellRenderer';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import useSessionStore from 'stores/useSessionStore';
import {
  GridStatusCellRenderer,
  GridStatusCellTemplate,
} from 'components/grids/GridStatusCellRenderer';
import CustomGrid from 'components/grids/CustomGrid';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { Code } from 'models/common/CommonCode';
import useEvent from 'react-use-event-hook';
import { useMessageBar } from 'hooks/useMessageBar';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';

interface SearchParamData {
  sRoleNm: string;
  editable: boolean;
  callBackRoleCd?: any;
  width?: number;
  height?: number;
}

const RoleManagementGrid = (props: SearchParamData) => {
  const [flexRef, setFlexRef] = useState<any>();
  const [rowData, setRowData] = useState<Role[]>([]);
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();
  const [colWidthMap, setColWidthMap] = useState<any>({
    roleCd: 150,
    roleNm: 200,
    roleDesc: '*',
    useYn: 100,
  });
  const [getUseYn, setUseYn] = useState<Code[]>([]);

  const initComboBoxCondition = async () => {
    //사용여부
    const getUseYn: Code[] = await getCommonCodeNames('YN_FLAG');
    setUseYn(getUseYn);
  };

  useEffect(() => {
    initComboBoxCondition();
  }, []);

  const useYn = new DataMap(getUseYn, 'cmnCd', 'cmnCdNm');

  const layoutDefinition = () => {
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 50,
        align: 'center',
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
        visible: props.editable,
      },
      {
        binding: 'roleCd',
        header: String(t('admin.grid.역할코드', '역할코드')),
        width: colWidthMap.roleCd,
        // isReadOnly: true,
        // editable: (params: EditableCallbackParams) => {
        //   return params.node!.data.crudKey === CrudCode.CREATE;
        // },
        isRequired: false,
      },
      {
        binding: 'roleNm',
        header: String(t('admin.grid.역할명', '역할명')),
        width: colWidthMap.roleNm,
        // isReadOnly: true,
        // editable: (params: EditableCallbackParams) => {
        //   return params.node!.data.crudKey === CrudCode.CREATE;
        // },
        isRequired: false,
      },
      {
        binding: 'roleDesc',
        header: String(t('admin.grid.역할설명', '역할설명')),
        width: colWidthMap.roleDesc,
        isRequired: false,
      },
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        width: 100,
        align: 'center',
        dataMap: useYn,
        isReadOnly: !props.editable,
        // cellRenderer: DropdownCellRenderer,
        // cellRendererParams: (params: ICellRendererParams) => {
        //   const useYnCds = [CommonYN.Y, CommonYN.N];
        //   return {
        //     ...params,
        //     dropdownItems: useYnCds.map((useYnCd) => {
        //       return { label: useYnCd, value: useYnCd };
        //     }) as DropdownItem[],
        //     editable: props.editable,
        //   } as IDropdownCellRendererParams;
        // },
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: layoutDefinition(),
  };

  const onInitialized = (grid) => {
    setFlexRef(grid);
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        const item = panel.rows[row].dataItem;

        if (item.crudKey !== CrudCode.CREATE) {
          if ('roleCd' === binding || 'roleNm' === binding || 'roleDesc' === binding) {
            cell.ariaReadOnly = true;
          }
        }
      }
    };

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      // grid.startEditing(true);

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      props.callBackRoleCd && props.callBackRoleCd(item.roleCd);
    });
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'role';
    book.saveAsync(getExcelFileName(t('admin.label.역할목록', '역할목록')));
  };

  const beginningEdit = useEvent((s, e) => {
    const item = s.rows[e.row].dataItem;
    const col = s.columns[e.col];
    if (item.crudKey !== CrudCode.CREATE) {
      if ('roleCd' === col.binding || 'roleNm' === col.binding || 'roleDesc' === col.binding) {
        e.cancel = true;
      }
    }
  });

  const fnSearchRole = useCallback((params: string) => {
    getRoles(params).then((result: Role[]) => {
      setRowData(result);
    });
  }, []);

  const btnAddRow = useCallback(() => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
    } as Role;
    setRowData([newRow, ...rowData]);
  }, [rowData]);

  const btnDelRow = useCallback(() => {
    const selectedRowNodes = flexRef.selectedRows;
    // const selectedRowNodes = gridRef.current!.api.getSelectedNodes();

    if (selectedRowNodes.length < 1) {
      openMessageBar({
        type: 'warning',
        content: t(
          'com.label.삭제할 항목을 1건 이상 선택해 주세요.',
          '삭제할 항목을 1건 이상 선택해 주세요.'
        ),
      });
      return;
    }

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode._idx!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item] && rowData[item].crudKey !== CrudCode.CREATE) {
        openMessageBar({
          type: 'error',
          content: t(
            'admin.msg.새로 추가된 항목만 삭제가능합니다.',
            '새로 추가된 항목만 삭제가능합니다.'
          ),
        });
        return;
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData]);

  const btnSave = () => {
    const validRowData = rowData.filter((item) => item.crudKey !== CrudCode.DELETE);
    const valid = validRowData
      .map((rowNode, index) => {
        if (rowNode.roleCd == null || rowNode.roleCd === '')
          return (
            `\nNo.${index + 1}:` +
            t('admin.msg.역할코드를 입력해 주세요.', '역할코드를 입력해 주세요.')
          );
        if (rowNode.roleNm == null || rowNode.roleNm === '')
          return (
            `\nNo.${index + 1}:` + t('admin.msg.역할명을 입력해 주세요.', '역할명을 입력해 주세요.')
          );
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      openMessageBar({
        type: 'error',
        content: valid.toString(),
      });
      return false;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        const saveRows = rowData
          .map((rowNode) => {
            return rowNode.crudKey && rowNode.roleCd && rowNode.roleNm ? rowNode : null;
          })
          .filter((element) => element !== null) as Role[];

        setRoles(saveRows).then((result) => {
          if (!result) {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return false;
          }

          if ((result.insertedRows ?? 0) + (result.updatedRows ?? 0) === 0) {
            openMessageBar({
              type: 'error',
              content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
            });
            fnSearchRole(props.sRoleNm);
          }
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          fnSearchRole(props.sRoleNm);
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const onCellClicked = useCallback((event: any) => {
    props.callBackRoleCd && props.callBackRoleCd(event.data.roleCd);
    event.node.setSelected(true);
  }, []);

  useEffect(() => {
    fnSearchRole(props.sRoleNm);
  }, [props]);

  const onModelUpdated = useCallback((e: GridReadyEvent) => {
    // e.api.sizeColumnsToFit();
  }, []);

  const isRowSelectable = useMemo<IsRowSelectable>(() => {
    return (node: IRowNode) => {
      return !!node.data && node.data.crudKey === CrudCode.CREATE;
    };
  }, []);

  const onCellValueChanged = useCallback((e: CellValueChangedEvent) => {
    !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  }, []);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.역할목록', '역할목록')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>

        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          {props.editable && (
            <>
              <Button css={IconButton.button} className="plus" disableRipple onClick={btnAddRow}>
                {t('com.button.행추가', '행추가')}
              </Button>
              <Button css={IconButton.button} className="minus" disableRipple onClick={btnDelRow}>
                {t('com.button.행삭제', '행삭제')}
              </Button>
            </>
          )}
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={state.layoutDefinition}
        rowData={state.itemsSource}
        height={props.height ?? 500}
        isSelector={props.editable}
        excludeFilter={['no']}
        initialized={onInitialized}
        beginningEdit={beginningEdit}
      />
      {props.editable && (
        <GlobalBtnGroup>
          <Button css={IconButton.button} className="save" disableRipple onClick={btnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        </GlobalBtnGroup>
      )}
    </>
  );
};

export default memo(RoleManagementGrid);
