/** @jsxImportSource @emotion/react */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import MPCommonSearch from './base/MPCommonSearch';
import { MpItemCondition } from 'models/mp/MpItem';
import { selectMpTracking } from 'apis/mp/MpTracking';
import MpMyBookmarkModal from './MpMyBookmarkModal';
import { BizName, ManagementMode } from 'models/common/Common';
import AutoSendEmailModal from 'components/modals/common/AutoSendEmailModal';
import { BmkAtcTpCd } from 'models/common/Bookmark';
import CustomGrid from 'components/grids/CustomGrid';

const MPTrackingManagementPate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<any[]>([]);
  const [condition, setCondition] = useState<MpItemCondition>({});
  const [dynamicCol, setDynamicCol] = useState<any[]>([]);
  const [excelData, setExcelData] = useState<any>();
  const [mpMyBookmarkModalOpen, setMpMyBookmarkModalOpen] = useState<boolean>(false);
  const [isOpenEmailModal, setOpenEmailModal] = useState<boolean>(false);

  // data layout definition
  const layoutDefinition = useMemo(() => {
    const layout = [] as any[];

    let prevHeaderName = '';
    let prevHeaderIdx = 0;
    if (dynamicCol.length > 0) {
      dynamicCol.forEach((item, index) => {
        const words = item.PLLN.split(':');
        // Header 추가
        if (prevHeaderName !== words[2]) {
          prevHeaderIdx = index;
          prevHeaderName = words[2];
          layout.push({ header: words[2], align: 'center', columns: [] });
        }

        const filedNm = "'" + dynamicCol[prevHeaderIdx].PLLN + "'_SATUS";
        // Children Column 추가
        layout[layout.length - 1]['columns'].push({
          header: words[3],
          align: 'center',
          binding: filedNm,
          cssClass: 'WijmoTag',
          cellTemplate: (ctx) => setColor(ctx),
        });
      });
    }

    return [
      {
        header: 'ITEM',
        align: 'center',
        columns: [
          { binding: 'ITEM_ID', header: t('mp.label.번호', '번호') },
          {
            binding: 'ITEM_NM',
            header: t('mp.label.명', '명'),
            width: 250,
            align: 'left',
          },
          {
            binding: 'ITEM_ENG_NM',
            header: t('mp.label.영문명', '영문명'),
            width: 250,
            align: 'left',
          },
        ],
      },
      { binding: 'EQCL_ID', header: t('com.label.설비군', '설비군'), align: 'center' },
      {
        binding: 'ITEM_PROG',
        header: t('mp.label.수평전개 진척률(%)', '수평전개 진척률(%)'),
        align: 'center',
        minWidth: 150,
        width: '*',
        // wordWrap: true,
      },
      ...layout,
    ];
  }, [dynamicCol]);

  const baseRemarks = [
    { index: 0, name: 'NO_TGT', color: 'grey', desc: t('mp.label.비대상', '비대상') },
    { index: 1, name: 'COMPLETE', color: 'green', desc: t('mp.label.완료', '완료') },
    { index: 2, name: 'INPROGRESS', color: 'yellow', desc: t('mp.label.진행중', '진행중') },
    { index: 3, name: 'REVIEW', color: 'purple', desc: t('mp.label.검토중', '검토중') },
    { index: 4, name: 'CHECK', color: 'blue', desc: t('mp.label.확인중', '확인중') },
    { index: 5, name: 'DELAY', color: 'red', desc: t('mp.label.지연', '지연') },
  ];

  const setColor = (ctx) => {
    if (ctx.text.length == 0) return '';

    const type = ctx.text.split('.');
    const remark = baseRemarks[type[0]];
    return `<span class="${remark.color}">${remark.desc}</span>`;
  };

  const initialized = (grid) => {
    setExcelData(grid);
    grid.rowHeaders.columns.clear();

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 5) return;
      if (grid.cells.getCellData(ht.row, ht.col, true).length <= 0) return;

      const hdr = grid.cells.columns[ht.col]._binding._key.replace("'", '').split(':');

      navigate('/mp/item-management', {
        state: {
          upprMnuUrl: '/mp/item', // 고정
          mode: ManagementMode.MODIFY, // 고정
          mnuId: `/mp/item/${ManagementMode.MODIFY}`, // 고정
          mnuNm: t('mp.label.Item 수정', 'Item 수정'), // 고정
          itemId: grid.rows[ht.row]._data.ITEM_ID, // 값 변경
          itemTpCd: grid.rows[ht.row]._data.ITEM_TP_CD, // 값 변경
          copCd: grid.rows[ht.row]._data.COP_CD, // 값 변경
          plntCd: hdr[0], // 값 변경
          lnId: hdr[1], // 값 변경
        },
      }); //매개변수 확인 필요
    });
  };

  const onExportXlsxClick = () => {
    wjGridXlsx.FlexGridXlsxConverter.saveAsync(excelData, undefined, '수평전개Item.xlsx');
  };

  const handleSearch = async (condition: MpItemCondition) => {
    setCondition(condition);
    const response = await selectMpTracking(condition);
    if (response) {
      const rowData = response.list[0]?.list || [];
      const dynamicCol = response.list[0]?.col || [];
      setRowData(rowData);
      setDynamicCol(dynamicCol);
    }
  };

  return (
    <>
      <MPCommonSearch type={'tracking'} onHandleSearch={handleSearch} />
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.수평전개 Item', '수평전개 Item')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData ? rowData.length : 0}</span>
            {t('com.label.건', '건')}
            {/* {t('com.label.진척률', '진척률')}<span>00</span>% */}
          </span>
        </SubTitleGroup>

        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="bookmark"
            disableRipple
            onClick={(e) => {
              setMpMyBookmarkModalOpen(true);
            }}
          >
            {t('mp.button.나의관심항목등록', '나의관심항목등록')}
          </Button>
          <Button
            css={IconButton.button}
            className="user"
            onClick={() => {
              navigate('/mp/target');
            }}
            disableRipple
          >
            {t('mp.button.MP 대상/담당자', 'MP 대상/담당자')}
          </Button>
          <Button
            css={IconButton.button}
            className="mail"
            onClick={() => setOpenEmailModal(true)}
            disableRipple
          >
            {t('com.button.자동메일설정', '자동메일설정')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            disableRipple
            onClick={onExportXlsxClick}
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            disableRipple
            onClick={() => handleSearch(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={500}
        initialized={initialized}
        isReadOnly={true}
        isSelector={false}
        align="center"
      />
      {mpMyBookmarkModalOpen && (
        <MpMyBookmarkModal
          open={mpMyBookmarkModalOpen}
          close={() => setMpMyBookmarkModalOpen(false)}
        />
      )}
      {isOpenEmailModal && (
        <AutoSendEmailModal
          open={isOpenEmailModal}
          close={() => setOpenEmailModal(false)}
          condition={{
            bizName: BizName.MP, // 이메일업무구분코드
            bmkAtcTpCd: BmkAtcTpCd.MP_LIST, //즐겨찾기항목유형코드
          }}
        />
      )}
    </>
  );
};

export default MPTrackingManagementPate;
