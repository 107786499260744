/** @jsxImportSource @emotion/react */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogContent, formControlClasses } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import _, { isEmpty } from 'lodash';
import { ManagementMode } from 'models/common/Common';
import { tb } from 'components/layouts/Table';
import { GlobalBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import Dropzone from 'components/dropzone/Dropzone';
import ParameterMasterGrid from 'pages/ip/base/parametermanagement/ParameterMasterGrid';
import ParameterDetailGrid from 'pages/ip/base/parametermanagement/ParameterDetailGrid';
import useSessionStore from 'stores/useSessionStore';
import {
  ParameterApprovalRequest,
  ParameterDetailRequest,
  ParameterMasterRequest,
} from 'models/ip/EquipmentParameter';
import {
  generateApprovalRequestId,
  findEquipmentParameterRequest,
  createEquipmentParameterRequest,
  approveEquipmentParameterRequest,
  findEquipmentParameterRequestMasterList,
  getEquipmentParameterRecentAprReqId,
  getEquipmentParameterRecentVersion,
} from 'apis/ip/EquipmentParameter';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ComboBox } from 'components/selects/ComboBox';
import { CustomTextarea } from 'components/inputs/CustomInput';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { uploadExcelTemplates } from 'apis/common/Excel';
import ExcelValidModal from '../common/ExcelValidModal';
import { useLoading } from 'components/process/Loading';
import { CrudCode } from 'models/common/Edit';
import CustomDialog from 'components/modals/common/CustomDialog';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import ApproveBasicInfo from 'pages/approves/ApproveBasicInfo';
import ApprovalLineInfo from 'pages/approves/ApproveLineInfo';
import ApproveRequestDetailCase from 'pages/approves/ApproveRequestDetailCase';
import { RequestDetail, SaveApprover } from 'models/approves/ReferenceOpinion';
import { findApproveRequestDetail } from 'apis/approves/approves';

interface Props {
  aprReqId: string;
  condition: {
    aprReqId: string; // 결재요청ID
    eqpParaNo: string; // 설비 파라미터 번호 (수정/삭제 요청인 경우)
    mode: ManagementMode;
    versionNo: string;
    pageDetail: boolean;
  };
}

/**
 * IP 기준정보 등록/수정/삭제 요청 팝업 화면
 * @param open
 * @param mode
 * @param condition
 * @param close
 * @constructor
 */

const ParameterManagementReqModal = forwardRef(({ condition, aprReqId }: Props, ref) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const [detailTarget, setDetailTarget] = useState<ParameterMasterRequest>({});
  const [reqData, setReqData] = useState<ParameterApprovalRequest>({
    aprTplId: 'SEC-FORM-0001', // 고정
    aprPvnDdlnCd: 'APPRCODE_1M',
  } as ParameterApprovalRequest);
  const { userId } = useSessionStore();
  const masterGridRef = useRef<any>(null);
  const detailGridRef = useRef<any>(null);
  const [code, setCode] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  // Excel Vaild Modal
  const [ExcelValidModalOpen, setExcelValidModalOpen] = useState<boolean>(false);
  const [excelValidCondition, setExcelValidCondition] = useState<string | null>(null);
  const [rowData, setRowData] = useState<ParameterMasterRequest[]>([]);
  const isExcelMode = useMemo(
    () => [ManagementMode.CREATE_EXCEL, ManagementMode.MODIFY_EXCEL].includes(condition.mode),
    [condition.mode]
  );
  const [excelAprReqId, setExcelAprReqId] = useState<any>();
  const [versionNoUp, setVersionNoUp] = useState<any>();
  const [aprReqProgStatCd, setAprReqProgStatCd] = useState<string>('TMP');
  const title = useMemo(() => {
    /*
    let modeNm = '등록';
    if ([ManagementMode.MODIFY, ManagementMode.MODIFY_EXCEL].includes(mode)) {
      modeNm = '수정';
    } else if (ManagementMode.DELETE === mode) {
      modeNm = '삭제';
    }
    return `IP 기준정보 ${modeNm} 요청`;
    */
    if ([ManagementMode.MODIFY, ManagementMode.MODIFY_EXCEL].includes(condition.mode)) {
      return t('ip.label.IP 기준정보 수정 요청', 'IP 기준정보 수정 요청');
    } else if (ManagementMode.DELETE === condition.mode) {
      return t('ip.label.IP 기준정보 삭제 요청', 'IP 기준정보 삭제 요청');
    }
    return t('ip.label.IP 기준정보 등록 요청', 'IP 기준정보 등록 요청');
  }, [condition.mode]);

  useEffect(() => {
    const mode = condition.mode;
    console.log('condition', condition);
    console.log('aprReqId', aprReqId);
    getCommonCodesForGrid();
    if (mode === ManagementMode.CREATE) {
      const newRow = {
        crudKey: CrudCode.CREATE,
      } as ParameterMasterRequest;
      setRowData([newRow, ...rowData]);
    } else if (mode === ManagementMode.CREATE_EXCEL || mode === ManagementMode.MODIFY_EXCEL) {
      return;
    } else if (
      (mode === ManagementMode.DELETE || mode === ManagementMode.MODIFY) &&
      _.isEmpty(aprReqId)
    ) {
      searchParaData(condition);
    } else if (aprReqId) {
      searchData(aprReqId);
    }
  }, []);

  useEffect(() => {
    const mode = condition.mode;
    if (mode === ManagementMode.CREATE_EXCEL || mode === ManagementMode.MODIFY_EXCEL) {
      searchData(excelAprReqId);
    }
  }, [excelAprReqId]);

  const searchParaData = (condition) => {
    getEquipmentParameterRecentAprReqId(condition).then((result) => {
      if (result !== null) {
        findEquipmentParameterRequestMasterList(result).then((result) => {
          if (result && result.length > 0) {
            setRowData(result);
            setDetailTarget(result[0]);
          }
        });
        getEquipmentParameterRecentVersion(condition.eqpParaNo).then((result) => {
          setVersionNoUp(result);
        });
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'ip.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
        close();
        return;
      }
    });
  };

  const searchData = (aprReqId) => {
    findEquipmentParameterRequestMasterList(aprReqId).then((result) => {
      if (result && result.length > 0) {
        setRowData(result);
        setDetailTarget(result[0]);
      }
    });
  };

  const getCommonCodesForGrid = async () => {
    const aprPvnDdlnCd: Code[] = await getCommonCodeNames('APR_PVN_DDLN_CD');

    setCode({
      aprPvnDdlnCd: aprPvnDdlnCd,
    });
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (!masterGridRef.current?.validate) {
        return true;
      }
      if (!detailGridRef.current?.validate) {
        return true;
      }
      return masterGridRef.current?.validate?.() ? detailGridRef.current?.validate?.() : false;
    },

    getBizReqData: (aprReqId: string, reqRsn: string) => {
      // 저장
      const master = {
        castType: 'IpApproveRequest', // 서버측 결재요청상세vo > bizReqData cast 타입
        aprReqId: aprReqId,
        eqpParaMReqList: rowData,
      };
      return master;
    },
  }));

  useEffect(() => {
    const mode = condition.mode;
    if (_.isEmpty(mode)) {
      findApproveRequestDetail(aprReqId).then((result) => {
        console.log('result', result);
        if (!result) {
          openMessageBar({
            type: 'error',
            content: t(
              'ip.msg.요청 정보 조회 중 오류가 발생했습니다.',
              '요청 정보 조회 중 오류가 발생했습니다.'
            ),
          });
          close();
          return;
        }
        setAprReqProgStatCd(result.aprReqProgStatCd);
      });
    }
  }, []);

  const searchRequest = (aprReqId) => {
    findApproveRequestDetail(aprReqId).then((result) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t(
            'ip.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
        close();
        return;
      }
      setAprReqProgStatCd(result.aprReqProgStatCd);
    });
    findEquipmentParameterRequest(aprReqId).then((result) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t(
            'ip.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
        close();
        return;
      }
      if (!result.aprPvnDdlnCd && reqData.aprPvnDdlnCd) result.aprPvnDdlnCd = reqData.aprPvnDdlnCd;
      if (!result.aprReqTitNm && reqData.aprReqTitNm) result.aprReqTitNm = reqData.aprReqTitNm;
      if (!result.trnmBdyCtn && reqData.trnmBdyCtn) result.trnmBdyCtn = reqData.trnmBdyCtn;
      setReqData({ ...reqData, ...result });
    });
  };

  // const refresh = () => {
  //   searchRequest(reqData?.aprReqId);
  //   searchData(excelAprReqId);
  //   masterGridRef.current.refresh();
  //   detailGridRef.current.refresh();
  // };

  const handleClose = () => {
    if (ManagementMode.DELETE === condition.mode) {
      close();
      return;
    }
    const mList = (rowData || []).filter((o) => !!o.crudKey);
    rowData.filter((o) => !!(o.eqpParaDReqList || []).length);
    const dList = (detailGridRef.current.getDataList() || []).filter((o) => {
      return !!o.crudKey || !!(o.parameterPointList || []).filter((p) => !!p.crudKey).length;
    });
    if (mList.length || dList.length) {
      openCommonModal({
        modalType: 'confirm',
        content: t(
          'com.label.작성하신 내용이 저장되지 않았습니다.<br/>그래도 화면을 닫으시겠습니까?',
          '작성하신 내용이 저장되지 않았습니다.<br/>그래도 화면을 닫으시겠습니까?'
        ),
        yesCallback() {
          close();
        },
      });
      return;
    }
    close();
  };

  const uploadTemplate = (file) => {
    openLoading(true);
    uploadExcelTemplates(
      [file],
      'TPL_IP_LIST',
      'NORMAL',
      'TB_ELM_IP_EQP_PARA_M_REQ_N',
      condition.mode,
      aprReqId,
      reqData?.aprTplId,
      reqData?.aprPvnDdlnCd,
      reqData?.aprReqTitNm,
      reqData?.trnmBdyCtn
    )
      .then((result) => {
        openLoading(false);
        if (result?.successOrNot === 'Y') {
          if (result?.data.x_result == 'NG') {
            setExcelValidCondition(result?.data.p_xls_upload_id);
            setExcelValidModalOpen(true);
            return;
          }
        }
        const data = typeof result?.data === 'string' ? result?.data : null;
        openMessageBar({
          type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
          content:
            result?.successOrNot === 'Y'
              ? t('ip.msg.검증이 완료되었습니다.', '검증이 완료되었습니다.')
              : data || t('ip.msg.검증 중 오류가 발생했습니다.', '검증 중 오류가 발생했습니다.'),
        });
        if (result?.successOrNot === 'Y') {
          setExcelAprReqId(result.data.p_apr_req_id);
          // refresh();
        }
      })
      .finally(() => openLoading(false));
  };

  return (
    <>
      {isExcelMode && (
        <>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('ip.label.엑셀 파일 검색', '엑셀 파일 검색')}</h3>
            </SubTitleGroup>
          </SubTitleLayout>
          <TableContainer css={tb.table}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{t('ip.label.파일 선택', '파일 선택')}</TableCell>
                  <TableCell>
                    <Dropzone
                      singleSelect={true}
                      onChange={(files) => {
                        if (files && files.length > 0) {
                          const file = files[0].file;
                          if (file) {
                            if ((rowData || []).length) {
                              openCommonModal({
                                modalType: 'confirm',
                                content: t(
                                  'com.label.업로드된 데이터가 존재합니다.\n새로 업로드 하시겠습니까?',
                                  '업로드된 데이터가 존재합니다.\n새로 업로드 하시겠습니까?'
                                ),
                                yesCallback: () => {
                                  uploadTemplate(file);
                                },
                              });
                              return;
                            }
                            uploadTemplate(file);
                          }
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <ParameterMasterGrid
        ref={masterGridRef}
        rowData={rowData}
        mode={condition.mode}
        aprReqProgStatCd={aprReqProgStatCd}
        onChange={(masterRequest) => {
          setDetailTarget(masterRequest);
        }}
        versionNoUp={versionNoUp}
      />
      <ParameterDetailGrid
        ref={detailGridRef}
        mode={condition.mode}
        masterRequest={detailTarget}
        aprReqProgStatCd={aprReqProgStatCd}
        versionNoUp={versionNoUp}
        onChange={(eqpParaDReqList: ParameterDetailRequest[]) => {
          setRowData(
            rowData.reduce((acc, cur) => {
              if (
                cur.eqpParaNo === detailTarget.eqpParaNo &&
                cur.versionNo === detailTarget.versionNo
              ) {
                cur.eqpParaDReqList = eqpParaDReqList;
              }
              acc.push(cur);
              return acc;
            }, [] as ParameterMasterRequest[])
          );
        }}
      />
      {ExcelValidModalOpen && (
        <ExcelValidModal
          open={ExcelValidModalOpen}
          close={() => {
            setExcelValidCondition(null);
            setExcelValidModalOpen(false);
          }}
          condition={{
            valReqId: excelValidCondition || '',
          }}
        />
      )}
    </>
  );
});

ParameterManagementReqModal.displayName = 'ParameterManagementReqModal';
export default React.memo(ParameterManagementReqModal);
