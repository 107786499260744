import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { IpCheckPlan } from 'models/ip/IpCheckPlan';
import { Generic } from 'models/ip/Generic';
import { FacilityGroupCondition } from 'pages/ip/maintenance/grid/FacilityGroupModalGrid';

export const selectFromIpEqpParaMaster = async (condition: FacilityGroupCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ipEqpParaMaster`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<Generic[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as Generic[];
};
