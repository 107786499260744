/** @jsxImportSource @emotion/react */
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import { DataType } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { tb } from 'components/layouts/Table';
import { SubTitleGroup, SubTitleLayout, ControlBtnGroup } from 'components/layouts/ContentLayout';
import { ParameterDetailRequest, ParameterPoint } from 'models/ip/EquipmentParameter';
import { IconButton } from 'components/buttons/IconSVG';
import { ManagementMode } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import { Code } from 'models/common/CommonCode';
import CustomDialog from 'components/modals/common/CustomDialog';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import CustomGrid from 'components/grids/CustomGrid';
import useEvent from 'react-use-event-hook';

type ParameterPointModalProps = {
  open: boolean;
  close: () => void;
  mode: ManagementMode;
  detailRequest: ParameterDetailRequest;
  code: {
    paraItem?: Code[];
    electrodeType?: Code[];
    meterType?: Code[];
    ipUom?: Code[];
  };
  aprReqProgStatCd?: string;
  versionNoUp: string;
  onCallback: (result: ParameterPoint[]) => void;
};

/**
 * 설비별 측정개소 등록/수정 요청 Modal
 * @param props
 * @constructor
 */
const ParameterPointManagementModal = ({
  open,
  close,
  mode,
  code,
  detailRequest,
  onCallback,
  aprReqProgStatCd,
  versionNoUp,
}: ParameterPointModalProps) => {
  const [rowData, setRowData] = useState<ParameterPoint[]>(detailRequest.parameterPointList || []);
  const gridRef = useRef<any>(null);
  const { t } = useTranslation();
  const editable = useMemo(() => mode !== ManagementMode.DELETE, [mode]);
  const readable = useMemo(() => mode !== ManagementMode.READ, [mode]);
  const title = useMemo(() => {
    let modeNm = '등록';
    if ([ManagementMode.MODIFY, ManagementMode.MODIFY_EXCEL].includes(mode)) {
      modeNm = '수정';
    } else if (ManagementMode.DELETE === mode) {
      modeNm = '삭제';
    }
    return `설비별 측정개소 ${modeNm} 요청`;
  }, [mode]);
  const electrodeTypeNm = useMemo(() => {
    const item = (code?.electrodeType || []).find((o) => o.cmnCd === detailRequest.electrodeType);
    return item ? item.cmnCdNm : '';
  }, [code?.electrodeType, detailRequest.electrodeType]);
  const meterTypeNm = useMemo(() => {
    const item = (code?.meterType || []).find((o) => o.cmnCd === detailRequest.meterType);
    return item ? item.cmnCdNm : '';
  }, [code?.meterType, detailRequest.meterType]);
  const paraItemNm = useMemo(() => {
    const item = (code?.paraItem || []).find((o) => o.cmnCd === detailRequest.paraItem);
    return item ? item.cmnCdNm : '';
  }, [code?.paraItem, detailRequest.paraItem]);
  const uomNm = useMemo(() => {
    const item = (code?.ipUom || []).find((o) => o.cmnCd === detailRequest.uom);
    return item ? item.cmnCdNm : '';
  }, [code?.ipUom, detailRequest.uom]);

  console.log('apc', aprReqProgStatCd);
  console.log('editable', editable);

  const layoutDefinition = [
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      width: 45,
      align: 'center',
      isReadOnly: true,
      visible: editable,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'paraId',
      header: String(t('ip.grid.파라미터ID', '파라미터ID')),
      width: 150,
      isReadOnly: true,
      cellTemplate: (params) => `${detailRequest?.paraId}`,
    },
    {
      binding: 'paraName',
      header: String(t('ip.grid.파라미터명', '파라미터명')),
      width: 200,
      align: 'left',
      isReadOnly: true,
      cellTemplate: (params) => `${detailRequest?.paraName}`,
    },
    {
      binding: 'paraPointNo',
      header: String(t('ip.grid.측정개소번호', '측정개소번호')),
      width: 120,
      isReadOnly: true,
    },
    {
      binding: 'paraPointName',
      header: String(t('ip.grid.측정개소명', '측정개소명')),
      width: 250,
      align: 'left',
      isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
    },
    {
      header: String(t('ip.grid.사양', '사양')),
      align: 'center',
      columns: [
        {
          binding: 'meterType',
          header: String(t('ip.grid.미터유형', '미터유형')),
          width: 90,
          isReadOnly: true,
          cellTemplate: (params) => {
            // [23.10.10 - 이광수총괄님 요청] 설비 파라미터 상세에 있는 meterType을 그대로 설정 (단위, 상한, 하한은 수정 가능)
            const meterType = code?.meterType?.filter((o) => o.cmnCd === detailRequest?.meterType);
            return meterType && meterType.length > 0
              ? meterType[0].cmnCdNm
              : detailRequest?.meterType;
          },
        },
        {
          binding: 'lowerBound',
          header: String(t('ip.grid.하한', '하한')),
          width: 70,
          dataType: DataType.Number,
          isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
        },
        {
          binding: 'upperBound',
          header: String(t('ip.grid.상한', '상한')),
          width: 70,
          dataType: DataType.Number,
          isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
        },
        {
          binding: 'uom',
          header: String(t('ip.grid.UOM', 'UOM')),
          width: 80,
          isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          dataMap: new DataMap(code?.ipUom || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'rmk',
          header: String(t('ip.grid.주석', '주석')),
          width: 100,
          isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
        },
      ],
    },
    {
      binding: 'paraPointDesc',
      header: String(t('ip.grid.측정개소 설명', '측정개소 설명')),
      width: 250,
      isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
      align: 'left',
    },
  ];

  const handleAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      paraPointNo: Math.max(...rowData.map((o) => o.paraPointNo), 0) + 10, // 측정개소번호는 10단위로 증가 (ex. 10, 20, 30 ...)
      meterType: detailRequest?.meterType,
    } as ParameterPoint;
    setRowData([newRow, ...rowData]);
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if (!selectedRows) return;

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  };

  const handleSave = () => {
    onCallback(rowData);
    close();
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  const onBeginningEdit = (grid, e) => {
    const binding = grid.columns[e.col].binding;
    const item = grid.rows[e.row].dataItem;
    // 상한, 하한은 정량인 경우에만 입력 가능
    if ('lowerBound' === binding || 'upperBound' === binding) {
      if (!editable || detailRequest?.meterType !== 'METER') {
        e.cancel = true;
      }
    }
  };

  const onCellEditEnded = (grid, e) => {
    const binding = grid.columns[e.col].binding;
    // 미터유형이 변경된 경우
    if ('meterType' === binding) {
      const data = grid.rows[e.row].dataItem;
      // 정성인 경우 상한, 하한 값 변경
      if ('OKNG' === detailRequest?.meterType) {
        data.lowerBound = null;
        data.upperBound = null;
        grid.refreshCells();
      }
    }
  };

  return (
    <CustomDialog
      open={open}
      title={t('ip.label.파라미터 정보', '파라미터 정보')}
      buttons={editable ? dialogButtons : null}
      onCancel={close}
      onClose={close}
    >
      <TableContainer css={tb.tableRow}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} className="secondCol">
                <span>{t('com.label.설비군', '설비군')}</span>
              </TableCell>
              <TableCell>{detailRequest?.equipmentGroup}</TableCell>
              <TableCell colSpan={2} className="secondCol">
                <span>{t('snsr.label.파라미터명', '파라미터명')}</span>
              </TableCell>
              <TableCell>{detailRequest?.paraName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={4} className="firstCol">
                <span>{t('com.label.설비', '설비')}</span>
              </TableCell>
              <TableCell className="secondCol">
                {t('snsr.label.Anode/Cathode', 'Anode/Cathode')}
              </TableCell>
              <TableCell>{electrodeTypeNm}</TableCell>
              <TableCell rowSpan={6} className="firstCol" style={{ borderBottom: 'none' }}>
                <span>{t('ip.label.사양', '사양')}</span>
              </TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.측정기준', '측정기준')}</span>
              </TableCell>
              <TableCell style={{ whiteSpace: 'normal' }}>{detailRequest?.specDataum}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="secondCol">
                <span>{t('ip.label.Machine', 'Machine')}</span>
              </TableCell>
              <TableCell>{detailRequest?.machineName}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.미터유형', '미터유형')}</span>
              </TableCell>
              <TableCell>{meterTypeNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="secondCol">
                <span>{t('ip.label.Unit', 'Unit')}</span>
              </TableCell>
              <TableCell>{detailRequest?.unitName}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.하한', '하한')}</span>
              </TableCell>
              <TableCell>{detailRequest?.lowerBound}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="secondCol">
                <span>{t('ip.label.Assembly', 'Assembly')}</span>
              </TableCell>
              <TableCell>{detailRequest?.assemblyName}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.상한', '상한')}</span>
              </TableCell>
              <TableCell>{detailRequest?.upperBound}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} className="secondCol">
                <span>{t('ip.label.파라미터 항목', '파라미터 항목')}</span>
              </TableCell>
              <TableCell>{paraItemNm}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.UOM', 'UOM')}</span>
              </TableCell>
              <TableCell>{uomNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} className="secondCol">
                <span>{t('ip.label.파라미터 ID', '파라미터 ID')}</span>
              </TableCell>
              <TableCell>{detailRequest?.paraId}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.주석', '주석')}</span>
              </TableCell>
              <TableCell>{detailRequest?.rmk}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.측정개소', '측정개소')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>
              {(rowData || []).filter((o) => o.crudKey !== CrudCode.DELETE).length.toLocaleString()}
            </span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        {mode === ManagementMode.CREATE ||
        mode === ManagementMode.MODIFY ||
        aprReqProgStatCd === 'TMP' ? (
          <ControlBtnGroup>
            <Button css={IconButton.button} className="plus" onClick={handleAddRow} disableRipple>
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button css={IconButton.button} className="minus" onClick={handleDelRow} disableRipple>
              {t('com.button.행삭제', '행삭제')}
            </Button>
          </ControlBtnGroup>
        ) : (
          ''
        )}
      </SubTitleLayout>
      <CustomGrid
        rowData={rowData}
        layoutDefinition={layoutDefinition}
        height={200}
        initialized={(grid) => {
          gridRef.current = grid;
        }}
        beginningEdit={onBeginningEdit}
        cellEditEnded={onCellEditEnded}
        itemFormatter={(panel, row, col, cell) => {
          if (CellType.Cell === panel.cellType) {
            const binding = panel.columns[col].binding;
            const item = panel.rows[row].dataItem;
            // 상한, 하한은 정량인 경우에만 입력 가능 (readonly css 적용)
            if ('lowerBound' === binding || 'upperBound' === binding) {
              if (!editable || 'METER' !== detailRequest?.meterType) {
                cell.ariaReadOnly = true;
              }
            }
          }
        }}
      />
    </CustomDialog>
  );
};

export default ParameterPointManagementModal;
