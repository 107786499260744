/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';

// 프로퍼티 타입 지정
TableCellReadOnly.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  colSpan: PropTypes.number,
};

export default function TableCellReadOnly(props) {
  const { t } = useTranslation();

  const labelId = 'common.label.' + props.name;
  const labelText = '' + props.name;

  return (
    <>
      <TableCell>{t(labelId, labelText)}</TableCell>
      <TableCell colSpan={props.colSpan}>{props.defaultValue}</TableCell>
    </>
  );
}
