/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentGrid } from '../../../components/layouts/ContentGrid';
import { SubTitleLayout, SubTitleGroup, ControlBtnGroup } from 'components/layouts/ContentLayout';
import { findGatingResultReportAct } from 'apis/gtng/GatingResultReport';
import { GatingResultReportAct } from 'models/gtng/GatingResultReportAct';
import { TooltipComponent, TooltipValueGetter } from 'components/grids/Tooltip';
import useSessionStore from 'stores/useSessionStore';
import Button from '@mui/material/Button';
import { GatingContListPopUp } from '../popup/GatingContListPopUp';
// Wijmo library
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { ContentSection } from 'components/layouts/ContentSection';
import { IconButton } from 'components/buttons/IconSVG';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';

/**
 * Gating 결과 Report Library -> 판정결과 및 보완이력 그리드
 * @param props
 * @constructor
 */
const GatingResultReportActGrid = (props: any) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const [rowData, setRowData] = useState<GatingResultReportAct[]>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();

  const valueFormatter = (params: any) => {
    if (params.colDef.field == 'equipmentId') {
      return params.node.data.equipmentName;
    }
    if (params.colDef.field == 'itemInspectionResultCode') {
      return params.node.data.itemInspectionResultName;
    }
    //실시담당자
    if (params.colDef.field == 'actionContactId') {
      return params.node.data.actionContact;
    }
  };

  useEffect(() => {
    if (props.gatingId != undefined) {
      findGatingResultReportAct(props.gatingId).then((result: GatingResultReportAct[]) => {
        setRowData(result);
      });
    }
  }, [props.gatingId]);

  //시작일자, 종료일자 YY-MM-DD 형태로 변환
  const dateValueFommater = (params: any) => {
    const date = params.value;
    if (date != null && date != undefined) {
      const subStrDate = date.substr(0, 10);
      const formmattedDate = subStrDate.replaceAll('-', '.');
      return formmattedDate;
    }
  };

  const [initUserId, setInitUserId] = useState<string>(''); //검색할 파라미터 상태관리
  const [popup, setPopup] = useState<any>(false); //팝업창 오픈, 클로즈 상태관리
  const [contPopTitle, setContPopTitle] = useState<string>(''); //검색할 파라미터 상태관리
  const empPopupOpen = (params: any) => {
    const fieldName = params.binding;
    const id = fieldName === 'actionContact' ? 'actionContactId' : '';
    if (fieldName != null && fieldName != '') {
      setInitUserId(params.item[id]);
    }

    //실시담당자
    if (fieldName == 'actionContact') {
      setContPopTitle(String(t('gtng.label.실시담당자', '실시담당자')));
    }

    setPopup(true);
  };
  //사용자 검색 팝업
  const btnElmEmpPopup = ({ value }) => `<span>${value ? value : ''}</span>
  <Button class="WijmoFind" id="empPopBtn"></Button>
  `;

  /**
   * grid
   * */
  // const defaultColDef: ColDef = useMemo(
  //   () => ({
  //     sortable: true,
  //     resizable: true,
  //     isReadOnly: true,
  //     cellStyle: { textAlign: 'center' },
  //     tooltipComponent: TooltipComponent,
  //     tooltipValueGetter: TooltipValueGetter,
  //   }),
  //   []
  // );
  const LayoutDefinition = () => {
    return [
      {
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        // filter: false,
        isReadOnly: true,
        align: 'center',
        // suppressSizeToFit: true,
        // cellClass: 'cellStatus',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('gtng.grid.장비명', '장비명')),
        binding: 'equipmentId',
        width: 150,
        visible: false,
        // cellTemplate: valueFormatter,
        //  cellStyle: { textAlign: 'left' },
      },
      {
        header: String(t('gtng.grid.장비명', '장비명')),
        binding: 'equipmentName',
        isReadOnly: true,
      },
      {
        header: String(t('gtng.grid.점검차수', '점검차수')),
        binding: 'inspectionSeqs',
        align: 'center',
        isReadOnly: true,
        width: 75,
      },
      {
        header: String(t('gtng.grid.점검점수', '점검점수')),
        binding: 'itemEvaluationScore',
        align: 'center',
        isReadOnly: true,
        width: 75,
      },
      {
        header: String(t('gtng.grid.점검결과', '점검결과')),
        binding: 'itemInspectionResultCode',
        align: 'center',
        width: 100,
        visible: false,
        //  cellStyle: { textAlign: 'left' },
        // cellTemplate: valueFormatter,
      },
      {
        header: String(t('gtng.grid.점검결과명', '점검결과명')),
        binding: 'itemInspectionResultName',
        isReadOnly: true,
        width: 100,
      },
      {
        header: String(t('gtng.grid.미비점(NG항목)', '미비점(NG항목)')),
        align: 'center',
        columns: [
          {
            header: String(t('gtng.grid.점검구분1', '점검구분1')),
            binding: 'gatingType1Value',
            isReadOnly: true,
            width: 140,
            //  cellStyle: { textAlign: 'left' },
          },
          {
            header: String(t('gtng.grid.점검구분2', '점검구분2')),
            binding: 'gatingType2Value',
            isReadOnly: true,
            width: 120,
            //  cellStyle: { textAlign: 'left' },
          },
          {
            header: String(t('gtng.grid.점검구분3', '점검구분3')),
            binding: 'gatingType3Value',
            isReadOnly: true,
            width: 120,
            //  cellStyle: { textAlign: 'left' },
          },
          {
            header: String(t('gtng.grid.Check List', 'Check List')),
            binding: 'gatingCheckItem1Value',
            isReadOnly: true,
            width: 120,
            //  cellStyle: { textAlign: 'left' },
          },
        ],
      },
      {
        header: String(t('gtng.grid.점검결과', '점검결과')),
        binding: 'checkResultContent',
        isReadOnly: true,
        width: 100,
      },
      {
        header: String(t('gtng.label.실시(T/L)', '실시(T/L)')),
        align: 'center',
        columns: [
          // {
          //   header: String(t('gtng.grid.Advice', 'Advice')),
          //   binding: 'pmoCommentCount',
          //   width: 85,
          // },
          {
            header: String(t('gtng.grid.Advice', 'Advice')),
            binding: 'pmoComment',
            isReadOnly: true,
            width: 85,
          },
        ],
      },
      {
        header: String(t('gtng.grid.조치/실행', '조치/실행')),
        align: 'center',
        columns: [
          {
            header: String(t('gtng.label.실시담당자', '실시담당자')),
            binding: 'actionContact',
            cssClass: 'WijmoFind',
            isReadOnly: true,
            width: 100,
            cellTemplate: (params) => btnElmEmpPopup(params),
          },
          {
            header: String(t('gtng.label.실시담당자', '실시담당자')),
            binding: 'actionContactId',
            width: 100,
            // cellTemplate: valueFormatter,
            //  cellStyle: { textAlign: 'left' },
            visible: false,
          },
          {
            header: String(t('gtng.grid.보완계획', '보완계획')),
            binding: 'actionPlanDesc',
            isReadOnly: true,
            width: 500,
            //  cellStyle: { textAlign: 'left' },
          },
          {
            header: String(t('gtng.grid.계획일자', '계획일자')),
            binding: 'planDate',
            isReadOnly: true,
            align: 'center',
            width: 120,
            cellTemplate: dateValueFommater,
          },
          {
            header: String(t('com.label.수정일', '수정일')),
            binding: 'dataUpdDtm',
            isReadOnly: true,
            align: 'center',
            width: 110,
            //cellTemplate: dateValueFommater,
          },
        ],
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onInitialized = (grid) => {
    const filter = new FlexGridFilter(grid);
    grid.rowHeaders.columns.splice(0, 1);
    setflexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      grid.startEditing(true);
      if (len == 0) return;

      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      switch (e.target.id) {
        case 'empPopBtn':
          setHitTest(ht);
          empPopupOpen({ binding: col.binding, item });
          break;
      }

      grid.refresh();
    });
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'gating';
    book.saveAsync(
      getExcelFileName(t('gtng.label.Gating 판정결과 및 보완이력', 'Gating 판정결과 및 보완이력'))
    );
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 판정결과 및 보완이력', 'Gating 판정결과 및 보완이력')}</h3>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <ContentGrid
        className={(rowData || []).length < 1 ? 'noData' : ''}
        style={{
          height: '310px',
        }}
      >
        <FlexGrid
          columnGroups={state.layoutDefinition}
          itemsSource={state.itemsSource}
          // deferResizing={false}
          // formatItem={flexGridTooltip}
          initialized={onInitialized}
          style={{ height: (rowData || []).length < 1 ? '' : '300px' }}
        />
        <div className="noData" style={{ height: (rowData || []).length < 1 ? '300px' : '' }}>
          <span>
            {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
          </span>
        </div>
      </ContentGrid>

      {/*담당자 팝업*/}
      {popup && (
        <GatingContListPopUp
          open={popup}
          // rowId={rowId}
          title={contPopTitle}
          setReadonly={true}
          initParam={initUserId}
          close={() => setPopup(false)}
        />
      )}
    </>
  );
};
export default GatingResultReportActGrid;
