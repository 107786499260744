import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { IpCheckPlan } from 'models/ip/IpCheckPlan';

export const getListFromIpCheckPlan = async (condition: IpCheckPlan) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ipCheckPlan`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<IpCheckPlan[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IpCheckPlan[];
};

export const insertIpCheckPlan = async (condition: IpCheckPlan) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/insertIpCheckPlan`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<IpCheckPlan[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as IpCheckPlan[];
};

export const simpleRequest = async (condition: any, url: string) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: url,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<any[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as any[];
};
