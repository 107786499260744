/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { IconButton } from 'components/buttons/IconSVG';
import { st } from 'components/inputs/CustomInput';
import { ComboBox } from 'components/selects/ComboBox';
import { findMpLines, saveMpLines } from 'apis/mp/MpLine';
import { MpLine, MpLineCondition } from 'models/mp/MpLine';
import { Code } from 'models/common/CommonCode';
import { CrudCode } from 'models/common/Edit';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { GridStatusCellTemplate } from '../../grids/GridStatusCellRenderer';
import _ from 'lodash';
import CustomGrid from 'components/grids/CustomGrid';
import CustomDialog from 'components/modals/common/CustomDialog';

type Props = {
  open: boolean;
  close: () => void;
  condition: {
    copCd?: string; // 법인코드
    plntCd?: string; // 공장(동) 코드
    lnAnmNmYn?: string; // 약어 미등록만 보기 여부
  };
  onCallback: (item: MpLine[]) => void;
};

const MpTargetLineConfigModal = ({ open, close, condition, onCallback }: Props) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [mpLineCondition, setMpLineCondition] = useState<MpLineCondition>(condition);
  const [rowData, setRowData] = useState<MpLine[]>([]);
  const [colWidthMap, setColWidthMap] = useState<any>({
    no: 40,
    crudKey: 45,
    copCd: 90,
    dataUpdUserNm: 100,
    lnId: 110,
    lnNm: 150,
    dataUpdDtm: 170,
    plntNm: 210,
    plntCd: '*',
    lnAnmNm: '*',
  });

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  useEffect(() => {
    changeSearchConditionByCopCd();
  }, [mpLineCondition?.copCd]);

  const handleClose = () => {
    close();
  };

  const getCommonCodesForGrid = async () => {
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    const factoryCode: Code[] = await getCommonCodeNamesCondition({
      cmnGrCd: 'FACTORY_CODE',
      optValCtn1: mpLineCondition?.copCd || '',
    });

    setCode({
      elmCopCd: elmCopCd,
      factoryCode: factoryCode,
    });
  };

  /**
   * 법인코드 변경에 따른 검색조건 값 설정 변경
   *
   * 재성정 항목
   * 1. 공장(동)
   */
  const changeSearchConditionByCopCd = async () => {
    setMpLineCondition({
      ...mpLineCondition,
      plntCd: '', // 공장(동)코드
    });

    setCode({
      ...code,
      factoryCode: [],
    });

    if (mpLineCondition?.copCd) {
      const factoryCode: Code[] = await getCommonCodeNamesCondition({
        cmnGrCd: 'FACTORY_CODE',
        optValCtn1: mpLineCondition?.copCd,
      });

      setCode({
        ...code,
        factoryCode: factoryCode,
      });

      setMpLineCondition({ ...mpLineCondition });
    }
  };

  const handleSearch = () => {
    const err = {};
    if (_.isEmpty(mpLineCondition.copCd)) {
      Object.assign(err, { copCd: true });
    }
    if (_.isEmpty(mpLineCondition.plntCd)) {
      Object.assign(err, { plntCd: true });
      setErrors({ ...errors, plntCd: true });
    }
    setErrors(err);

    if (Object.keys(err).filter((k) => err[k]).length) {
      return;
    }

    findMpLines(mpLineCondition).then((result) => {
      setRowData(result);
    });
  };
  const handleConditionChange = (name: string, value: string) => {
    setMpLineCondition({
      ...mpLineCondition,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: false,
    });
  };

  const handleResetCondition = () => {
    setMpLineCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as MpLineCondition
    );
  };

  const handleSave = () => {
    // 수정된 항목 저장
    const params = rowData.filter((o) => o.crudKey === CrudCode.UPDATE);
    if (params.length) {
      saveMpLines(params).then((result) => {
        console.log(result);
        handleSearch();
      });
    }
  };

  const layoutDefinition = [
    {
      header: String(t('com.label.NO', 'NO')),
      binding: 'no',
      width: colWidthMap.no,
      isReadOnly: true,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('com.label.상태', '상태')),
      width: colWidthMap.crudKey,
      binding: 'crudKey',
      isReadOnly: true,
      align: 'center',
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'copCd',
      header: String(t('mp.grid.법인', '법인')),
      isReadOnly: true,
      align: 'center',
      width: colWidthMap.copCd,
    },
    {
      binding: 'plntCd',
      header: String(t('mp.grid.공장(동)', '공장(동)')),
      isReadOnly: true,
      width: colWidthMap.plntCd,
      // cellStyle: { textAlign: 'left' },
      visible: false,
    },
    {
      binding: 'plntNm',
      header: String(t('mp.grid.공장(동)', '공장(동)')),
      isReadOnly: true,
      width: colWidthMap.plntNm,
      // cellStyle: { textAlign: 'left' },
    },
    {
      binding: 'lnId',
      header: String(t('mp.grid.Line ID', 'Line ID')),
      isReadOnly: true,
      align: 'center',
      width: colWidthMap.lnId,
    },
    {
      binding: 'lnNm',
      header: String(t('mp.grid.Line 명', 'Line 명')),
      isReadOnly: true,
      align: 'center',
      width: colWidthMap.lnNm,
      // cellStyle: { textAlign: 'left' },
    },
    {
      binding: 'lnAnmNm',
      header: String(t('mp.grid.Line 약어', 'Line 약어')),
      width: colWidthMap.lnAnmNm,
      minWidth: 230,
    },
    {
      binding: 'dataUpdUserNm',
      isReadOnly: true,
      header: String(t('com.label.수정자', '수정자')),
      align: 'center',
      width: colWidthMap.dataUpdUserNm,
    },
    {
      binding: 'dataUpdDtm',
      isReadOnly: true,
      header: String(t('com.label.수정일', '수정일')),
      align: 'center',
      width: colWidthMap.dataUpdDtm,
    },
  ];

  const onInitialized = (grid) => {
    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });
  };

  const dialogButtons = [
    <Button
      key={'save'}
      css={IconButton.button}
      className="save"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  return (
    <CustomDialog
      open={true}
      title={t('mp.label.대상 라인 설정', '대상 라인 설정')}
      onClose={handleClose}
      onCancel={handleClose}
      buttons={dialogButtons}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>
                  <span className="dot">{t('mp.label.법인', '법인')}</span>
                </label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={code?.elmCopCd}
                  defaultValue={mpLineCondition?.copCd}
                  onChange={(value) => handleConditionChange('copCd', value)}
                  isError={errors?.copCd}
                  msgError={String(t('mp.msg.법인을 선택해 주세요.', '법인을 선택해 주세요.'))}
                />
              </SearchCols>
              <SearchCols>
                <label>
                  <span className="dot">{t('mp.label.공장(동)', '공장(동)')}</span>
                </label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={code?.factoryCode}
                  defaultValue={mpLineCondition?.plntCd}
                  onChange={(value) => handleConditionChange('plntCd', value)}
                  isError={errors?.plntCd}
                  msgError={String(
                    t('mp.msg.공장(동)을 선택해 주세요.', '공장(동)을 선택해 주세요.')
                  )}
                />
              </SearchCols>
              <SearchCols className="flex">
                <label htmlFor="lnAnmNmYn" style={{ paddingTop: '0px' }}>
                  {t('mp.label.약어 미등록만 보기', '약어 미등록만 보기')}
                </label>
                <Checkbox
                  id="lnAnmNmYn"
                  css={st.checkbox}
                  className="custom"
                  checked={mpLineCondition?.lnAnmNmYn === 'Y'}
                  onChange={(e, checked) => handleConditionChange('lnAnmNmYn', checked ? 'Y' : 'N')}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button css={IconButton.button} className="reload" onClick={handleResetCondition} />
            <Button css={IconButton.button} className="find" onClick={handleSearch}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        rowData={rowData}
        layoutDefinition={layoutDefinition}
        isSelector={false}
        isFilter={false}
        allowPinning={false}
        initialized={onInitialized}
        height={450}
      />
    </CustomDialog>
  );
};

export default MpTargetLineConfigModal;
