/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { getYearList, retrieveGatingInfo } from 'apis/gtng/GatingRegist';
import { GatingRegist } from 'models/gtng/GatingRegist';
import { Code } from 'models/common/CommonCode';
import { IconButton } from 'components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { ComboBox } from 'components/selects/ComboBox';
import { SearchParam } from 'pages/gtng/GatingRegistPage';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';

type Props = {
  open: boolean;
  close: () => void;
  condition?: {
    gatingName?: string;
    progressStatusCode?: string; // gating 상태
  };
  onCallback: (item: GatingRegist | null) => void;
};
/**
 * 게이팅 찾기 팝업
 * 사용화면 : 게이팅 평가/채점
 *          게이팅 판정
 *
 * @param open
 * @param close
 * @param condition
 * @param onCallback
 * @constructor
 */
const GatingNoPopUp = ({ open, close, condition, onCallback }: Props) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<GatingRegist[]>([]);
  const [searchParam, setSearchParam] = useState<SearchParam>({
    startDate: dayjs().format('YYYY').toString(),
    progressStatusCode: condition?.progressStatusCode || '',
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [gatingTypeConditionList, setGatingTypeConditionList] = useState<Code[]>([]);
  const [gatingProgressStatusCodeConditionList, setGatingProgressStatusCodeConditionList] =
    useState<Code[]>([]);
  const [gatingProductConditionList, setGatingProductConditionList] = useState<Code[]>([]);
  const [gatingStartEndYearList, setGatingStartEndYearList] = useState<any>();
  const optionsGatingStartEndYearList = useMemo(() => {
    return (gatingStartEndYearList || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: String(cur.STARTYEAR),
          cmnCdNm: String(cur.STARTYEAR),
        },
      ],
      [] as Code[]
    );
  }, [gatingStartEndYearList]);

  useEffect(() => {
    getCommonCodes();
    handleSearch(searchParam);
  }, []);

  const [flexItem, setflexItem] = useState<GatingRegist | null>(null);

  const onInitialized = (grid) => {
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
      setflexItem(item);
      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnSelect':
            btnQuickSelect(item);
            break;
        }
      }
      grid.refresh();
    });
    grid.hostElement.addEventListener('dblclick', (e) => {
      const ht = grid.hitTest(e);
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
      btnQuickSelect(item);
    });
  };

  const layoutDefinition = [
    {
      // header: 'Quick Select',
      cssClass: 'WijmoRelay',
      cellTemplate: '<Button id="btnSelect"/>',
      width: 39,
    },
    {
      binding: 'gatingId',
      header: 'GatingId',
      align: 'center',
      visible: false,
    },
    {
      binding: 'productTypeName',
      header: String(t('gtng.grid.Product Type', 'Product Type')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'gatingName',
      header: String(t('gtng.label.Gating Task명', 'Gating Task명')),
      align: 'left',
      width: 200,
    },
    {
      binding: 'gatingNo',
      header: String(t('gtng.grid.Gating No', 'Gating No')),
      align: 'center',
      width: 200,
    },
    {
      binding: 'gatingContNames',
      header: String(t('gtng.label.등록 담당자', '등록 담당자')),
      align: 'left',
      width: 100,
    },
    {
      binding: 'pmoApproverName',
      header: String(t('gtng.label.등록 T/L', '등록 T/L')),
      align: 'left',
      width: 100,
    },
    {
      binding: 'gatingTypeName',
      header: String(t('gtng.label.Gating 유형', 'Gating 유형')),
      width: 150,
    },
    {
      binding: 'gatingStepName',
      header: String(t('gtng.label.Gating 단계', 'Gating 단계')),
      width: 130,
    },
    {
      binding: 'versionNo',
      header: String(t('gtng.grid.버전', '버전')),
      align: 'center',
      width: 80,
    },
    {
      binding: 'startDate',
      header: String(t('gtng.grid.시작일자', '시작일자')),
      align: 'center',
      width: 120,
    },
    {
      binding: 'endDate',
      header: String(t('gtng.grid.종료일자', '종료일자')),
      align: 'center',
      width: 120,
    },
    {
      binding: 'progressStatusName',
      header: String(t('com.label.상태', '상태')),
      align: 'center',
      width: 100,
    },
  ];

  //콤보박스 공통코드 호출
  const getCommonCodes = async () => {
    //Gating 유형
    const gatingTypeCodeConditions = await getCommonCodeNames('GATING_TYPE_CODE');
    setGatingTypeConditionList(gatingTypeCodeConditions);
    //Gating 상태
    const gatingProgressStatusCodeConditions = await getCommonCodeNames(
      'GATING_PROGRESS_STATUS_CODE'
    );
    setGatingProgressStatusCodeConditionList(gatingProgressStatusCodeConditions);
    //Product Type
    const gatingProductTypeConditions = await getCommonCodeNames('GATING_PRODUCT_TYPE_CODE');
    setGatingProductConditionList(gatingProductTypeConditions);

    const gatingStartEndYearConditions = await getYearList();
    setGatingStartEndYearList(gatingStartEndYearConditions);
  };

  const handleSearchParam = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setSearchParam({ ...searchParam, [e.target.name]: e.target.value });
  };

  const handleConfirm = (e) => {
    onCallback(flexItem);
    close();
  };

  const handleSearch = async (condition: SearchParam) => {
    const response = await retrieveGatingInfo(condition);
    if (response) {
      const rowData = response || [];
      setRowData(rowData);
      setTotalCount(rowData.length || 0);
    }
  };

  const btnQuickSelect = (item) => {
    onCallback(!_.isNull(item) ? item : null);
    close();
  };

  // 엔터키 검색 추가
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(searchParam);
    }
  };

  const dialogButtons = [
    <Button
      key={'select'}
      css={IconButton.button}
      className="select"
      onClick={handleConfirm}
      disableRipple
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('gtng.label.Gating No 조회', 'Gating No 조회')}
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="popup">
              <SearchCols>
                <label>
                  <span className="dot">{t('gtng.label.실시년도', '실시년도')}</span>
                </label>
                <ComboBox
                  options={optionsGatingStartEndYearList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.startDate}
                  onChange={(value) =>
                    setSearchParam({
                      ...searchParam,
                      startDate: value,
                      endDate: value,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Product', 'Product')}</label>
                <ComboBox
                  options={gatingProductConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.productTypeCode}
                  onChange={(value) =>
                    setSearchParam({
                      ...searchParam,
                      productTypeCode: value,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating Task명', 'Gating Task명')}</label>
                <CustomInputWithSearch
                  name="gatingName"
                  placeholder={String(
                    t('gtng.msg.Gating Task명을 입력해 주세요.', 'Gating Task명을 입력해 주세요.')
                  )}
                  value={searchParam.gatingName}
                  onChange={handleSearchParam}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.Gating 유형', 'Gating 유형')}</label>
                <ComboBox
                  options={gatingTypeConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.gatingTypeCode}
                  onChange={(value) =>
                    setSearchParam({
                      ...searchParam,
                      gatingTypeCode: value,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 상태', 'Gating 상태')}</label>
                <ComboBox
                  options={gatingProgressStatusCodeConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.progressStatusCode}
                  onChange={(value) =>
                    setSearchParam({
                      ...searchParam,
                      progressStatusCode: value,
                    })
                  }
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            defaultCondition={{
              startDate: dayjs().format('YYYY').toString(),
              progressStatusCode: condition?.progressStatusCode || '',
            }}
            setReset={setSearchParam}
            onSearch={() => handleSearch(searchParam)}
          />
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating No 조회', 'Gating No 조회')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={400}
        rowSelection={'single'}
        frozenColumns={2}
        isFilter={false}
        isReadOnly={true}
        allowPinning={false}
        align="center"
        initialized={onInitialized}
        onChangeCheckedItem={(items) => setflexItem((items || []).length > 0 ? items[0] : null)}
      />
    </CustomDialog>
  );
};

export default GatingNoPopUp;
