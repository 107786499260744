import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SVGIcon } from 'components/buttons/IconSVG';
import {
  ControlFilldBG,
  GrayColor,
  CommonBorder,
  CommonText,
  ControlLinedBG,
  ControlLinedBorder,
} from 'ui/theme/Color';

export const ButtonSize = {
  lg: css`
    border-radius: 3px;
    height: 36px;
    cursor: pointer;
    font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.5;
    white-space: nowrap;
  `,
  md: css`
    border-radius: 2px;
    height: 32px;
    cursor: pointer;
    font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.5;
    white-space: nowrap;
  `,
  sm: css`
    border-radius: 2px;
    min-width: 0px;
    height: 28px;
    cursor: pointer;
    font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    white-space: nowrap;
  `,
};

export const ButtonStyle = {
  containedPrimary: css`
    background: ${ControlFilldBG.Primary};
    color: ${GrayColor.White};
    &:before {
      background: ${GrayColor.White};
    }
    &:hover {
      background: ${ControlFilldBG.PrimaryHover};
      &:before {
        background: ${GrayColor.White};
      }
    }
    &:active {
      background: ${ControlFilldBG.PrimaryActive};
      &:before {
        background: ${GrayColor.White};
      }
    }
    &:disabled {
      opacity: 0.4;
      pointer-events: none !important ;
    }
  `,
  containedNormal: css`
    color: ${GrayColor.White};
    background: ${ControlFilldBG.Inverse};
    &:before {
      background: ${GrayColor.White};
    }
    &:hover {
      background: ${ControlFilldBG.InverseHover};
      &:before {
        background: ${GrayColor.White};
      }
    }
    &:active {
      background: ${ControlFilldBG.InversActive};
      &:before {
        background: ${GrayColor.White};
      }
    }
    &:disabled {
      opacity: 0.4;
      &:hover {
        pointer-events: none !important ;
      }
    }
  `,
  outlinePrimary: css`
    background: ${GrayColor.White};
    border: 1px solid ${CommonBorder.Primary};
    color: ${CommonText.Primary};
    &:before {
      background: ${CommonText.Primary};
    }
    &:hover {
      background: ${ControlLinedBG.PrimaryHover};
      border: 1px solid ${ControlLinedBorder.PrimaryHover};
      &:before {
        background: ${CommonText.Primary};
      }
    }
    &:active {
      background: ${ControlLinedBG.PrimaryActive};
      border: 1px solid ${ControlLinedBorder.PrimaryActive};
      color: ${CommonText.PrimaryExtreme};
      &:before {
        background: ${CommonText.PrimaryExtreme};
      }
    }
    &:disabled {
      opacity: 0.4;
      &:hover {
        pointer-events: none !important ;
      }
    }
  `,
  outlineNormal: css`
    background: ${ControlLinedBG.Basic};
    border: 1px solid ${CommonBorder.Basic};
    color: ${CommonText.Light};
    &:before {
      background: ${CommonText.Light};
    }
    &:hover {
      background: ${ControlLinedBG.BasicHover};
      border: 1px solid ${ControlLinedBorder.BasicHover};
    }
    &:active {
      background: ${ControlLinedBG.BasicActive};
      border: 1px solid ${ControlLinedBorder.BasicActive};
      color: ${CommonText.Basic};
      &:before {
        background: ${CommonText.Basic};
      }
    }
    &:disabled {
      opacity: 0.4;
      &:hover {
        pointer-events: none !important ;
      }
    }
  `,
  unfiledPrimary: css`
    color: ${CommonText.Primary};
    &:before {
      background: ${CommonText.Primary};
    }
    &:hover {
      background: ${ControlLinedBG.PrimaryHover};
    }
    &:active {
      background: ${ControlLinedBG.PrimaryActive};
      color: ${CommonText.PrimaryExtreme};
      &:before {
        background: ${CommonText.PrimaryExtreme};
      }
    }
    &:disabled {
      opacity: 0.4;
      &:hover {
        pointer-events: none !important ;
      }
    }
  `,
  unfiledNormal: css`
    color: ${CommonText.Light};
    &:before {
      background: ${CommonText.Light};
    }
    &:hover {
      background: ${ControlLinedBG.BasicHover};
    }
    &:active {
      background: ${ControlLinedBG.BasicActive};
      color: ${CommonText.Basic};
      &:before {
        background: ${CommonText.Basic};
      }
    }
    &:disabled {
      opacity: 0.4;
      &:hover {
        pointer-events: none !important ;
      }
    }
  `,
};

const BtnIcon = css`
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    position: absolute;
  }
`;

export const ButtonDesign = {
  labelLG: css`
    padding: 8px 16px;
    &:before {
      display: none;
    }
  `,
  labelMD: css`
    padding: 6px;
    min-width: 0px !important;
    &:before {
      display: none;
    }
  `,
  labelSM: css`
    padding: 5px 8px;
    &:before {
      display: none;
    }
  `,
  IconLfLG: css`
    ${BtnIcon}
    padding: 8px 16px 8px 36px;
    position: relative;
    &:before {
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
    }
    &.icoNone {
      padding-left: 16px;
    }
  `,
  IconLfMD: css`
    ${BtnIcon}
    padding: 6px 10px 6px 30px;
    position: relative;
    &:before {
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;
    }
  `,
  IconLfSM: css`
    ${BtnIcon}
    padding: 5px 8px 5px 24px;
    position: relative;
    &:before {
      width: 16px;
      height: 16px;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
    }
  `,
  IconRgLG: css`
    ${BtnIcon}
    padding: 8px 36px 8px 16px;
    position: relative;
    &:before {
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
    }
  `,
  IconRgMD: css`
    ${BtnIcon}
    padding: 6px 30px 6px 10px;
    position: relative;
    &:before {
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
    }
  `,
  IconRgSM: css`
    ${BtnIcon}
    padding: 5px 24px 5px 8px;
    position: relative;
    &:before {
      width: 16px;
      height: 16px;
      top: 50%;
      transform: translateY(-50%);
      right: 6px;
    }
  `,
  IconLG: css`
    ${BtnIcon}
    padding:0px !important;
    width: 36px !important;
    min-width: 36px !important;
    position: relative;
    &:before {
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  IconMD: css`
    ${BtnIcon}
    padding: 0px;
    width: 32px !important;
    min-width: 32px !important;
    position: relative;
    &:before {
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  IconSM: css`
    ${BtnIcon}
    padding: 0px;
    width: 28px !important;
    min-width: 28px !important;
    position: relative;
    &:before {
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
};

export const SegButtonBox = styled.div`
  border: 1px solid ${CommonBorder.Basic};
  display: inline-flex;
  align-items: center;
  padding: 1px;
  &.lg {
    height: 36px;
    border-radius: 3px;
    button {
      height: 32px;
      font-size: 13px;
      font-weight: 700;
      border-radius: 2px;
      padding: 6px 14px;
    }
  }
  &.md {
    height: 32px;
    border-radius: 2px;
    button {
      height: 28px;
      font-size: 13px;
      font-weight: 700;
      border-radius: 1px;
      padding: 4px 8px;
    }
  }
  &.sm {
    height: 28px;
    border-radius: 2px;
    button {
      height: 24px;
      font-size: 12px;
      font-weight: 700;
      border-radius: 1px;
      padding: 3px 6px;
    }
  }
`;

export const SegButton = styled.button`
  background: ${GrayColor.White};
  color: ${CommonText.Light};
  cursor: pointer;
  font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
  line-height: 1.5;
  white-space: nowrap;
  position: relative;
  border-radius: 1px;
  border: none !important;
  & + button {
    margin-left: 0px !important;
  }
  &:hover {
    background: ${ControlLinedBG.BasicHover} !important;
    border: none !important;
  }
  &:active,
  &:focus,
  &.active {
    background: ${ControlFilldBG.Inverse} !important;
    color: ${CommonText.Inverse} !important;
    border: none !important;
    outline: none !important;
    &:before {
      background: ${CommonText.Inverse} !important;
    }
  }
  &:disabled {
    opacity: 0.4;
  }
  &:before {
    content: '';
    clear: both;
    width: 24px !important;
    height: 24px !important;
    display: inline-block;
    position: absolute;
    left: 0 !important;
    top: 0 !important;
    background: ${CommonText.Light};
    mask-size: 24px 24px;
    transform: none !important;
  }
  &.list {
    width: 24px;
    &:before {
      -webkit-mask: ${SVGIcon.IcoBoardLIst};
      mask-image: ${SVGIcon.IcoBoardLIst};
    }
  }
  &.list3line {
    width: 24px;
    &:before {
      -webkit-mask: ${SVGIcon.IcoBoard3Line};
      mask-image: ${SVGIcon.IcoBoard3Line};
    }
  }
  &.list2line {
    width: 24px;
    &:before {
      -webkit-mask: ${SVGIcon.IcoBoard2Line};
      mask-image: ${SVGIcon.IcoBoard2Line};
    }
  }
  &.thum {
    width: 24px;
    &:before {
      -webkit-mask: ${SVGIcon.IcoBoardThumList};
      mask-image: ${SVGIcon.IcoBoardThumList};
    }
  }
  &.thumCard {
    width: 24px;
    &:before {
      -webkit-mask: ${SVGIcon.IcoBoardThumCard};
      mask-image: ${SVGIcon.IcoBoardThumCard};
    }
  }
  &.boardPageFull {
    width: 24px;
    &:before {
      -webkit-mask: ${SVGIcon.IcoBoardPageFull};
      mask-image: ${SVGIcon.IcoBoardPageFull};
    }
  }
  &.boardRow {
    width: 24px;
    &:before {
      -webkit-mask: ${SVGIcon.IcoBoardRow};
      mask-image: ${SVGIcon.IcoBoardRow};
    }
  }
  &.boardCol {
    width: 24px;
    &:before {
      -webkit-mask: ${SVGIcon.IcoBoardCol};
      mask-image: ${SVGIcon.IcoBoardCol};
    }
  }
`;

export const NormalButton = styled.button`
  ${ButtonSize.lg}
  ${ButtonStyle.outlineNormal}
  ${ButtonDesign.IconLfLG}
 
  &.lightBtn {
    ${ButtonStyle.outlineNormal}
    &:hover {
      &:before {
        background: ${GrayColor.Gray700};
      }
    }
  }
  &.primaryBtn {
    ${ButtonStyle.containedPrimary}
    &:hover {
      &:before {
        background: ${GrayColor.White};
      }
    }
  }

  & + button {
    margin-left: 4px;
  }
`;
