import * as React from 'react';
import { CrossEditorParams } from 'models/common/CrossEditor';

declare const NamoSE: any;

export interface CrossEditorProps {
  name: string;
  baseUrl?: string;
  value?: string;
  params?: CrossEditorParams;
  onLoaded?: (e: Event, editor: any) => void;
}

export class CrossEditor extends React.Component<CrossEditorProps> {
  private _instance: any;

  get editorRef(): any {
    if (typeof this._instance === 'undefined') {
      throw new Error('에디터 객체가 생성되지 않았습니다');
    }
    return this._instance;
  }

  get containerId(): string {
    return `${this.props.name}-container`;
  }

  componentDidMount(): void {
    this._startEditor();
  }

  render(): React.ReactElement {
    return <div id={this.containerId}></div>;
  }

  private _startEditor() {
    const { name, baseUrl, value, params = {}, onLoaded } = this.props;
    const $container = document.getElementById(this.containerId);

    if (!$container) {
      throw new Error('Load');
    }

    this._instance = new NamoSE(name);

    if (!params.event) {
      params.event = {};
    }

    // params에 설정된 OnInitComplete를 백업
    const { OnInitCompleted } = params.event;

    params.event.OnInitCompleted = (e: Event): void => {
      this._instance.SetBodyValue(value || '');

      if (OnInitCompleted) {
        OnInitCompleted(e);
      }

      if (onLoaded) {
        onLoaded(e, this.editorRef);
      }
    };

    this._instance.params = params;
    // CrossEditor의 script를 불러올 때 사용하는 base 경로를 지정해야 한다.
    if (baseUrl) {
      this._instance.pBaseURL = baseUrl;
    }

    this._instance.params.TagBlockList = ['form', 'object', 'details'];
    this._instance.params.AttributeBlockList = [
      'onclick',
      'onload',
      'onerror',
      'onblur',
      'ontoggle',
      'onmouseover',
      'onmouseout',
      'onfocus',
      'onchange',
      'onsubmit',
    ];

    this._instance.params.ParentEditor = $container;

    // TODO: 업로드 백앤드 서버 구축 후 연결
    const uploadFileExecutePath = process.env.REACT_APP_API_BASE_URL;

    this._instance.params.UploadFileExecutePath = uploadFileExecutePath + '/v1/namoFileUpload'; //이미지 업로드 URL
    this._instance.params.UploadEtcFileExecutePath = uploadFileExecutePath + '/v1/namoFileUpload'; //파일 업로드 URL

    this._instance.params.Menu = false;
    // Editor 시작
    this._instance.EditorStart();
  }
}

export default CrossEditor;
