/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentGrid } from 'components/layouts/ContentGrid';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import GatingNoPopUp from './popup/GatingNoPopUp';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GatingJudgementReportActGrid from './gatingjudgementresult/GatingJudgementReportActGrid';
import { useCommonModal } from 'hooks/useCommonModal';

/**
 * Gating 판정결과 Report 페이지
 * @param
 * @constructor
 */
export const GatingJudgementResultPage = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const [searchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState<any>({
    gatingId: searchParams.get('gatingId') || '',
    gatingNo: searchParams.get('gatingNo') || '',
  });
  const [searchParamData, setSearchParamData] = useState<any>();
  const [searchState, setSearchState] = useState<any>(false);
  const [isOpenGatingNoPopUp, setOpenGatingNoPopUp] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const location = useLocation();
  const navigate = useNavigate();
  const state = { ...location.state };

  useEffect(() => {
    if (searchParams.get('gatingId')) {
      btnSearch();
    }
  }, [searchParams]);

  useEffect(() => {
    if (state.sJudgeResultParam != undefined) {
      setSearchParamData(state.sJudgeResultParam);
      setSearchParam(state.sJudgeResultParam);
    }
  }, [state.sJudgeResultParam]);

  const handleParamConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setSearchParam({
      ...searchParam,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: false });
  };

  const btnSearch = () => {
    if (searchParam == undefined || searchParam.gatingNo == '') {
      setOpenGatingNoPopUp(true);
      return false;
    }
    if (searchState == true) {
      setSearchState(false);
    }
    if (searchState == false) {
      setSearchState(true);
    }
    setSearchParamData(searchParam);
  };

  const btnReload = () => {
    setSearchParam({ gatingNo: '' });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>
                  <span className="dot">{t('gtng.label.Gating No', 'Gating No')}</span>
                </label>
                <CustomInputWithSearch
                  name="gatingNo"
                  className="find"
                  placeholder={String(
                    t('gtng.msg.Gating No를 선택해 주세요.', 'Gating No를 선택해 주세요.')
                  )}
                  readOnly={true}
                  value={searchParam?.gatingNo}
                  onChange={handleParamConditionChange}
                  onSearchClick={() => setOpenGatingNoPopUp(true)}
                  isError={errors?.gatingNo}
                  msgError={String(
                    t('gtng.msg.Gating No를 선택해 주세요.', 'Gating No를 선택해 주세요.')
                  )}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button disableRipple></Button>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContentGrid className="ag-theme-alpine">
        <GatingJudgementReportActGrid searchParamData={searchParamData} searchState={searchState} />
      </ContentGrid>
      {isOpenGatingNoPopUp && (
        <GatingNoPopUp
          open={isOpenGatingNoPopUp}
          close={() => setOpenGatingNoPopUp(false)}
          condition={{
            progressStatusCode: 'C', // 판정 상태 기본값 설정
          }}
          onCallback={(value) => {
            navigate(location.pathname, undefined);
            setSearchParam({
              gatingNo: value?.gatingNo || '',
              gatingId: value?.gatingId || '',
            });
          }}
        />
      )}
    </>
  );
};
