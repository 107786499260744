import { ColDef } from 'ag-grid-community';
import { TooltipComponent, TooltipValueGetter } from 'components/grids/Tooltip';
import { Tooltip, PopupPosition } from '@grapecity/wijmo';
import { CrudCode } from 'models/common/Edit';

export const commonColDef: ColDef = {
  editable: false,
  sortable: true,
  resizable: true,
  filter: true,
  minWidth: 50,
  width: 150,
  tooltipComponent: TooltipComponent,
  tooltipValueGetter: TooltipValueGetter,
  cellStyle: { textAlign: 'center' },
};

export const shortDate = (params) => {
  if (!params.value) {
    return params.value;
  }
  return params.value.substr(0, 10);
};

export const transYnToTf = (params) => {
  params.node?.setDataValue(String(params.colDef?.field), 1);
  return 'true';
};

export const isEmpty = (params) => {
  if (typeof params == 'undefined' || params == null || params == '') return true;
  else return false;
};

export const flexGridTooltip = (s, e) => {
  const tooltip = new Tooltip({
    position: PopupPosition.RightTop,
    showAtMouse: true,
    showDelay: 600,
    cssClass: 'hdr-tip',
  });
  let msg = '';
  if (e.panel == s.columnHeaders) msg = e.getColumn().header;
  if (e.panel == s.cells) {
    msg = s.rows[e.row].dataItem[s.columns[e.col].binding];
  }

  if (isEmpty(msg)) return;

  tooltip.setTooltip(e.cell, '<span>' + msg + '</span>');
};

export const putCrudkey = (items) => {
  return (items || []).map((item) => ({
    ...item,
    crudKey: CrudCode.READ,
  }));
};

export const flexGridShortDate = (params) => {
  if (!params.value) return params.value;
  return params.value.substr(0, 10);
};

export const flexGridCheckBoxYN = (params) => {
  const checked = params.value === 'Y' ? 'checked' : '';
  return (
    '<input\
    type="checkbox"\
    className="customStringCheckbox"' +
    checked +
    '/>'
  );
};

export const flexGridButtonEmploy = (params) => {
  const value = params.value ? params.value : '';
  return '<span>' + value + '</span><button id="btnEmploy" />';
};

export const flexGridButtonPartner = (params) => {
  const value = params.value ? params.value : '';
  return '<span>' + value + '</span><button id="btnPartner" />';
};

export const flexGridButtonPointType = (params) => {
  const value = params.value ? params.value : '';
  const btn = value == '개소관리' ? '<button id="btnPointType" />' : '';
  return '<span>' + value + '</span>' + btn;
};
