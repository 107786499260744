/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import DepartmentManageTable from 'pages/admin/departmentmanagement/DepartmentManageTable';
import RoleManagementGrid from 'pages/admin/rolemanagement/RoleManagementGrid';
import { CustomInputText, CustomInputWrap } from 'components/inputs/CustomInput';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { ContentFlex } from 'components/layouts/ContentFlex';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ContentSection } from '../../components/layouts/ContentSection';
import { IconButton } from '../../components/buttons/IconSVG';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
const RoleDepartmentManagementPage = () => {
  const [roleSearchParamData, setRoleSearchParamData] = useState<string>('');
  const [departmentSearchParamData, setDepartmentSearchParamData] = useState<string>('');
  const [roleNameSearchParamData, setRoleNameSearchParamData] = useState<string>('');
  const { t } = useTranslation();

  const listener = useCallback((param: string) => {
    setDepartmentSearchParamData(param);
  }, []);

  const handleSearchParamData = (e: any) => {
    setRoleNameSearchParamData(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const handleResetCondition = () => {
    setRoleNameSearchParamData('');
  };

  const btnSearch = () => {
    setRoleSearchParamData(roleNameSearchParamData);
    setDepartmentSearchParamData('');
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('admin.label.역할명', '역할명')}</label>
                <CustomInputWithSearch
                  value={roleNameSearchParamData}
                  onChange={handleSearchParamData}
                  onKeyDown={handleKeyDown}
                  placeholder={String(
                    t('admin.label.역할명을 입력해 주세요.', '역할명을 입력해 주세요.')
                  )}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            />
            <Button css={IconButton.button} className="find" disableRipple onClick={btnSearch}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContentFlex>
        <ContentSection className="section width50p marginT0">
          <RoleManagementGrid
            sRoleNm={roleSearchParamData}
            editable={false}
            callBackRoleCd={listener}
          />
        </ContentSection>
        <ContentSection className="section width50p marginT0">
          <DepartmentManageTable roleCd={departmentSearchParamData} editable={true} />
        </ContentSection>
      </ContentFlex>
    </>
  );
};

export default RoleDepartmentManagementPage;
