/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ValueFormatterParams,
  CellValueChangedEvent,
  EditableCallbackParams,
  ICellRendererParams,
} from 'ag-grid-community';
import { RoleEmployee } from 'models/admin/Role';
import { CrudCode } from 'models/common/Edit';
import { getRoleEmps, setRoleEmps, deleteRoleEmps } from 'apis/admin/RoleEmployee';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { CustomInputText } from 'components/inputs/CustomInput';
import { Employee } from 'models/admin/Employee';
import EmployeeModal from 'components/modals/common/EmployeeModal';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import {
  GridStatusCellRenderer,
  GridStatusCellTemplate,
} from 'components/grids/GridStatusCellRenderer';
import useSessionStore from 'stores/useSessionStore';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
interface SearchParamData {
  sRoleCd: string;
  editable: boolean;
  callBack?: any;
  width?: number;
  height?: number;
}

const RoleEmpManagementGrid = (props: SearchParamData) => {
  const { gridNoRowsTemplate } = useSessionStore();
  const gridRef = useRef<AgGridReact<RoleEmployee>>(null);
  const [rowData, setRowData] = useState<RoleEmployee[]>([]);
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();
  const [colWidthMap, setColWidthMap] = useState<any>({
    userId: 120,
    empNm: 100,
    deptNm: 200,
    officeNumber: 150,
    emlSvrDmnIfoNm: 250,
  });
  const [rowIndex, setRowIndex] = useState<string>('');
  const [employeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);
  const [updDelIds, setUpdDelIds] = useState<string>('');

  const layoutDefinition = () => {
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 50,
        align: 'center',
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
        visible: props.editable,
      },
      {
        binding: 'userId',
        header: String(t('admin.grid.사용자ID', '사용자ID')),
        width: colWidthMap.userId,
        isReadOnly: true,
        isRequired: false,
      },
      {
        binding: 'empNm',
        header: String(t('admin.grid.사용자명', '사용자명')),
        width: colWidthMap.empNm,
        isReadOnly: true,
        isRequired: false,
      },
      {
        binding: 'deptNm',
        header: String(t('admin.grid.부서명', '부서명')),
        width: colWidthMap.deptNm,
        isReadOnly: true,
        isRequired: false,
      },
      {
        binding: 'officeNumber',
        header: String(t('admin.grid.회사전화번호', '회사전화번호')),
        width: colWidthMap.officeNumber,
        isReadOnly: true,
        isRequired: false,
      },
      {
        binding: 'emlSvrDmnIfoNm',
        header: String(t('admin.grid.이메일', '이메일')),
        width: colWidthMap.emlSvrDmnIfoNm,
        isReadOnly: true,
        isRequired: false,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: layoutDefinition(),
  };

  const onInitialized = (grid) => {
    setFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      // grid.startEditing(true);

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;
    });

    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'user';
    book.saveAsync(getExcelFileName(t('admin.label.역할별사용자목록', '역할별사용자목록')));
  };

  const fnSearchRoleEmp = useCallback((params: string) => {
    getRoleEmps(params).then((result: RoleEmployee[]) => {
      setRowData(result);
    });
  }, []);

  const fnSelectUser = (emp: Employee[]) => {
    let isDup = false;
    gridRef.current?.api.forEachNode((node) => {
      if (node.data && node.data.userId === emp[0].userId) {
        isDup = true;
        return;
      }
    });
    if (isDup) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.이미 존재하는 사용자입니다.', '이미 존재하는 사용자입니다.'),
      });
      return false;
    }
    const selRow = gridRef.current?.api
      .getRenderedNodes()
      .filter((element) => element.rowIndex == parseInt(rowIndex!))[0];

    if (
      selRow?.data?.userId !== undefined &&
      selRow?.data?.userId !== '' &&
      selRow?.data?.userId !== null
    ) {
      setUpdDelIds(selRow?.data?.userId + ',' + updDelIds);
    }

    flexRef.rows[0].dataItem['empNm'] = emp[0].empNm;
    flexRef.rows[0].dataItem['userId'] = emp[0].userId;
    flexRef.rows[0].dataItem['deptNm'] = emp[0].deptNm;
    flexRef.rows[0].dataItem['officeNumber'] = emp[0].ofcPhn;
    flexRef.rows[0].dataItem['emlSvrDmnIfoNm'] = emp[0].emlSvrDmnIfoNm;

    setEmployeeModalOpen(false);
  };

  const btnAddRow = useCallback(() => {
    if (props.sRoleCd == '') {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.역할을 선택해주세요.', '역할을 선택해주세요.'),
      });
      return;
    }
    const newRow = {
      crudKey: CrudCode.CREATE,
    } as RoleEmployee;

    setRowIndex('0');
    setEmployeeModalOpen(true);
    setRowData([newRow, ...rowData]);
  }, [rowData]);

  const btnDelRow = useCallback(() => {
    if (props.sRoleCd == '') {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.역할을 선택해주세요.', '역할을 선택해주세요.'),
      });
      return;
    }
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'warning',
        content: t(
          'com.label.삭제할 항목을 1건 이상 선택해 주세요.',
          '삭제할 항목을 1건 이상 선택해 주세요.'
        ),
      });
      return;
    }

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode._idx!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      rowData[item].crudKey = CrudCode.DELETE;
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData]);

  const btnSave = async () => {
    const saveIds = rowData
      .map((rowNode) => {
        if (rowNode.crudKey === CrudCode.CREATE || rowNode.crudKey === CrudCode.UPDATE) {
          return rowNode.userId;
        }
      })
      .filter((element) => element) as string[];

    const deleteIds = rowData
      .map((rowNode) => {
        if (rowNode.crudKey === CrudCode.DELETE) {
          return rowNode.userId;
        }
      })
      .filter((element) => element) as string[];

    if (saveIds.length === 0 && deleteIds.length === 0) {
      return openMessageBar({
        type: 'error',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
    }
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        const roleCd = props.sRoleCd;

        let allDeleteIds = '';
        if (updDelIds.length !== 0 && deleteIds.length !== 0) {
          allDeleteIds = updDelIds + deleteIds.toString();
        } else if (updDelIds.length !== 0 && deleteIds.length === 0) {
          allDeleteIds = updDelIds.slice(0, -1);
        } else if (updDelIds.length === 0 && deleteIds.length !== 0) {
          allDeleteIds = deleteIds.toString();
        }

        const inserted =
          saveIds.length &&
          (await setRoleEmps(roleCd, saveIds).then((result) => {
            if (!result) {
              openMessageBar({
                type: 'error',
                content: t(
                  'com.label.저장 중 오류가 발생했습니다.',
                  '저장 중 오류가 발생했습니다.'
                ),
              });
              return false;
            } else return result.insertedRows;
          }));
        if (inserted === false) return false;

        const deleted =
          (deleteIds.length || updDelIds.length) &&
          (await deleteRoleEmps(roleCd, allDeleteIds).then((result) => {
            if (!result) {
              openMessageBar({
                type: 'error',
                content: t(
                  'com.label.저장 중 오류가 발생했습니다.',
                  '저장 중 오류가 발생했습니다.'
                ),
              });
              return false;
            } else return result.deletedRows;
          }));
        if (deleted === false) return false;
        /**
        TODO: insert성공 후 delete 실패시 insert까지 롤백해야하나?
        그렇다면 insert/delete api를 하나로 통합해야 함
        */
        setUpdDelIds('');
        openMessageBar({
          type: 'confirm',
          content: t('com.label.저장되었습니다.', '저장되었습니다.'),
        });
        fnSearchRoleEmp(props.sRoleCd);
      },
    });
  };

  useEffect(() => {
    fnSearchRoleEmp(props.sRoleCd);
  }, [props]);

  const onModelUpdated = (e) => {
    // e.api.sizeColumnsToFit();
  };

  const onCellValueChanged = useCallback((e: CellValueChangedEvent) => {
    !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  }, []);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.사용자', '사용자')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>

        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          {props.editable && (
            <>
              <Button css={IconButton.button} className="plus" disableRipple onClick={btnAddRow}>
                {t('com.button.행추가', '행추가')}
              </Button>
              <Button css={IconButton.button} className="minus" disableRipple onClick={btnDelRow}>
                {t('com.button.행삭제', '행삭제')}
              </Button>
            </>
          )}
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={state.layoutDefinition}
        rowData={state.itemsSource}
        height={props.height ?? 500}
        isSelector={props.editable}
        excludeFilter={['no']}
        initialized={onInitialized}
      />
      <GlobalBtnGroup>
        {props.editable && (
          <Button css={IconButton.button} className="save" disableRipple onClick={btnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        )}
      </GlobalBtnGroup>
      <>
        <EmployeeModal
          open={employeeModalOpen}
          close={() => {
            setEmployeeModalOpen(false);
          }}
          save={(emp: Employee[]) => fnSelectUser(emp)}
          singleSelect={true}
        />
      </>
    </>
  );
};

export default memo(RoleEmpManagementGrid);
