/** @jsxImportSource @emotion/react */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import FacilityGroupModalGrid from '../grid/FacilityGroupModalGrid';
import FacilityTargetGrid from '../grid/FacilityTargetGrid';
import { PopupBtnGroup, TitleWrap, di } from 'components/layouts/Dialog';
import { Button } from '@mui/material';
import { HalfContetntLayout } from 'components/layouts/ContentLayout';
import { useState } from 'react';
import { simpleRequest } from 'apis/ip/IpCheckPlan';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { IconButton } from 'components/buttons/IconSVG';

type Props = {
  open: boolean;
  close: () => void;
  params: any;
  callBack: any;
  //save: (item: Employee[]) => void;
  singleSelect?: boolean;
};

const SelectTargetModal = (props: Props) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [faciltyTargetRows, setFaciltyTargetRows] = useState<any>([]);

  const handlOnClose = () => {
    setSelectedRows(null);
    props.close();
  };

  const onSelectionChanged = (event: any) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const getCountIpCheckList = function (param) {
    return simpleRequest(param, `/v1/getCountIpCheckList`).then((result: any[]) => {
      return Number(result);
    });
  };

  const createIpCheckList = function (param) {
    return simpleRequest(param, `/v1/createIpCheckList`).then((result: any[]) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t('ip.msg.생성중 오류가 발생했습니다.', '생성중 오류가 발생했습니다.'),
        });
        return result;
      }

      openMessageBar({
        type: 'confirm',
        content: t('ip.msg.생성 하였습니다.', '생성 하였습니다.'),
      });
      props.callBack(param.ipCheckPlan);
      props.close();
      return result;
    });
  };

  const deleteIpCheckList = function (param) {
    return simpleRequest(param, `/v1/deleteIpCheckList`).then((result: any[]) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t('ip.msg.삭제에 실패했습니다.', '삭제에 실패했습니다.'),
        });
      }
      return result;
    });
  };

  const handlOnCreate = async function () {
    if (!selectedRows || selectedRows.length <= 0) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.설비 Parameter를 선택해 주세요.', '설비 Parameter를 선택해 주세요.'),
      });
      return false;
    }

    if (!faciltyTargetRows || faciltyTargetRows.length <= 0) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.대상 설비를 추가해주세요.', '대상 설비를 추가해주세요.'),
      });
      return false;
    }

    let validationPass = true;
    let message;
    faciltyTargetRows.forEach((row) => {
      if (!row['equipmentId']) {
        validationPass = false;
        message = {
          type: 'error',
          content: t('ip.msg.Main설비ID를 입력해주세요.', 'Main설비ID를 입력해주세요.'),
        };
        return false;
      }
      if (!row['equipmentName']) {
        validationPass = false;
        message = {
          type: 'error',
          content: t('ip.msg.설비명을 입력해 주세요.', '설비명을 입력해 주세요.'),
        };
        return false;
      }
    });

    if (!validationPass) {
      openMessageBar(message);
      return false;
    }

    const param = {
      ipCheckPlan: props.params,
      ipEqpParaM: selectedRows[0],
      ipCheckList: faciltyTargetRows,
    };

    const countCheckList = await getCountIpCheckList(props.params);

    //아무것도 없다면 그냥 생성
    if (!countCheckList) {
      const result = await createIpCheckList(param);
      return false;
    }

    //기존 있을경우 확인절차
    openCommonModal({
      modalType: 'confirm',
      content: t(
        'ip.msg.이미 작성된 계획이 있습니다. 삭제하고 새로 생성하시겠습니까?',
        '이미 작성된 계획이 있습니다. 삭제하고 새로 생성하시겠습니까?'
      ),
      yesCallback: async () => {
        const result = await deleteIpCheckList(props.params);
        if (result) {
          const result2 = await createIpCheckList(param);
        }
      },
      noCallback: () => {
        return false;
      },
    });
  };

  return (
    <div>
      <Dialog open={props.open} css={di.dialog} onClose={handlOnClose} fullWidth maxWidth="xl">
        <TitleWrap>
          <h2>{t('ip.label.IP점검 대상 선택', 'IP점검 대상 선택')}</h2>
          <Button disableRipple onClick={handlOnClose}></Button>
        </TitleWrap>

        <DialogContent>
          <HalfContetntLayout>
            <FacilityGroupModalGrid
              editable={false}
              condition={undefined}
              onSelectionChanged={onSelectionChanged}
            ></FacilityGroupModalGrid>
            <FacilityTargetGrid
              editable={true}
              condition={selectedRows}
              callBackRowData={setFaciltyTargetRows}
            ></FacilityTargetGrid>
          </HalfContetntLayout>

          <PopupBtnGroup>
            <Button css={IconButton.button} className="cancel" onClick={handlOnClose} disableRipple>
              {t('com.button.취소', '취소')}
            </Button>
            <Button
              css={IconButton.button}
              className="confirm"
              onClick={handlOnCreate}
              disableRipple
            >
              {t('ip.button.점검대상생성', '점검대상생성')}
            </Button>
          </PopupBtnGroup>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SelectTargetModal;
