/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Datepicker from 'react-tailwindcss-datepicker';
import dayjs from 'dayjs';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { ComboBox } from 'components/selects/ComboBox';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { MpItemCondition, MpItemTpCho } from 'models/mp/MpItem';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';
import { EquipmentGroupPopUp } from 'pages/common/popup/EquipmentGroupPopUp';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { BmkAtcTpCd, BookmarkMaster } from 'models/common/Bookmark';
import { findBookmark } from 'apis/common/Bookmark';
import { findMpClass, findMpStandard } from 'apis/mp/MpEquipment';
import { EqpLvCd } from 'models/mp/MpEquipment';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';

interface DisplayCondition {
  reqMgrNm?: string; // 기술제안자명
  dataInsUserNm?: string; // 등록자명
}

type PageType = 'item' | 'tracking' | 'mybook';

interface Props {
  type?: PageType;
  isDefaultSearch?: boolean;
  onHandleSearch: (condition) => void;
  changeBookmark?: (bmkGrId: string) => void;
}

const MPCommonSearch = ({ isDefaultSearch = false, ...props }: Props) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [userData, setUserData] = useState<any>('');
  const [isOpenEquipmentGroupPopUp, setOpenEequipmentGroupPopUp] = useState<boolean>(false);
  const [isOpenElmEmpPopup, setOpenElmEmpPopup] = useState<boolean>(false);
  const [displayCondition, setDisplayCondition] = useState<DisplayCondition>({});
  const [isOpenSearchBox, setOpenSearchBox] = useState<boolean>(false);
  const [empPopupCondition, setEmpPopupCondition] = useState({
    fieldId: '',
    initParam: '',
  });
  const [mpItemCondition, setMpItemCondition] = useState<MpItemCondition>({
    startDataInsDtm: dayjs().add(-2, 'year').format('YYYY.MM.DD'),
    endDataInsDtm: dayjs().format('YYYY.MM.DD'),
  });
  // MP분류 필수값 여부
  const isRequiredMpClsfCd = useMemo(() => 'tracking' === props.type, [props.type]);
  const isTrackingPage = useMemo(() => 'tracking' === props.type, [props.type]);
  const isMyBookPage = useMemo(() => 'mybook' === props.type, [props.type]);

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  useEffect(() => {
    if (isDefaultSearch) {
      handleSearch(mpItemCondition);
    }
  }, [isDefaultSearch]);

  useEffect(() => {
    changeSearchConditionByMpClsfCd();
  }, [mpItemCondition?.mpClsfCd]);

  useEffect(() => {
    changeSearchConditionByIprvClsfLv1Cd();
  }, [mpItemCondition?.iprvClsfLv1Cd]);

  useEffect(() => {
    changeSearchConditionByItemTpChoCd();
  }, [mpItemCondition?.itemTpChoCd]);

  useEffect(() => {
    setMpItemCondition({
      ...mpItemCondition,
      eqpLv1Cd: '', // 설비 분류 Level1 (Main)
    });

    setCode({
      ...code,
      eqpLv1Codes: [],
    });
    changeSearchConditionByEqpClsfNo(EqpLvCd.MAIN, mpItemCondition?.eqpClsfNo);
  }, [mpItemCondition?.eqpClsfNo]);

  useEffect(() => {
    setMpItemCondition({
      ...mpItemCondition,
      eqpLv2Cd: '', // 설비 분류 Level2 (Machine)
    });

    setCode({
      ...code,
      eqpLv2Codes: [],
    });
    changeSearchConditionByEqpClsfNo(EqpLvCd.MACHINE, mpItemCondition?.eqpLv1Cd);
  }, [mpItemCondition?.eqpLv1Cd]);

  useEffect(() => {
    setMpItemCondition({
      ...mpItemCondition,
      eqpLv3Cd: '', // 설비 분류 Level3 (Unit)
    });

    setCode({
      ...code,
      eqpLv3Codes: [],
    });
    changeSearchConditionByEqpClsfNo(EqpLvCd.UNIT, mpItemCondition?.eqpLv2Cd);
  }, [mpItemCondition?.eqpLv2Cd]);

  useEffect(() => {
    if (userData != null && userData != '') {
      const fieldId = userData.fieldId;
      const userInfo = userData.userInfo[0] || {};
      if (fieldId) {
        setMpItemCondition({
          ...mpItemCondition,
          [fieldId.replace('Nm', 'Id')]: userInfo.userId,
        });
        setDisplayCondition({
          ...displayCondition,
          [fieldId]: userInfo.empNm,
        });
      }
    }
  }, [userData]);

  const getCommonCodesForGrid = async () => {
    const mpClsfType: Code[] = await getCommonCodeNames('MP_CLSF_TYPE'); // MP분류
    const mpItemTpCho: Code[] = await getCommonCodeNames('MP_ITEM_TP_CHO'); // MP유형
    const bookmark: BookmarkMaster[] = await findBookmark(BmkAtcTpCd.MP_LIST);
    const bookmarkCodes: Code[] = (bookmark || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: cur.bmkGrId,
          cmnCdNm: cur.bmkGrDesc,
        } as Code,
      ],
      [] as Code[]
    );
    setCode({
      mpClsfType: mpClsfType,
      mpItemTpCho: mpItemTpCho,
      bookmarkCodes: bookmarkCodes,
    });
  };

  /**
   * MP분류 변경에 따른 검색조건 값 설정 변경
   *
   * 재설정 항목
   * 1. 공정
   * 2. 개선목적 Level1
   * 3. 작업구분
   * 4. 설비분류체계
   */
  const changeSearchConditionByMpClsfCd = async () => {
    setMpItemCondition({
      ...mpItemCondition,
      procCd: '', // 공정
      iprvClsfLv1Cd: '', // 개선목적 Level1
      iprvClsfLv2Cd: '', // 개선목적 Level2
      wkDivsCd: '', // 작업구분
      eqpClsfNo: '', // 설비분류번호
    });

    setCode({
      ...code,
      processCode: [],
      mpImprTypeLvl1: [],
      mpImprTypeLvl2: [],
      mpWorkType: [],
      eqpClsfNoCodes: [],
    });

    // MP 분류에 따른 검색조건 코드 조회
    if (mpItemCondition?.mpClsfCd) {
      const c = (code?.mpClsfType || []).filter((o) => o.cmnCd === mpItemCondition?.mpClsfCd);
      if (c.length) {
        const optValCtn1 = c[0].optValCtn1;
        // 1. 공정
        const processCode: Code[] = await getCommonCodeNamesCondition({
          cmnGrCd: 'PROCESS_CODE',
          optValCtn1: optValCtn1,
        });

        // 2. 개선목적 Level1 (iprvClsfLv1Cd)
        const mpImprTypeLvl1: Code[] = await getCommonCodeNamesCondition({
          cmnGrCd: 'MP_IMPR_TYPE_LVL1',
          optValCtn1: optValCtn1,
        });

        // 3. 작업구분
        const mpWorkType: Code[] = await getCommonCodeNamesCondition({
          cmnGrCd: 'MP_WK_DIVS_CD', //MP_WORK_TYPE
          optValCtn1: optValCtn1,
        });

        // 4. 설비분류체계
        const eqpClsfNos = await findMpStandard({ mpClsfCd: mpItemCondition?.mpClsfCd });
        let eqpClsfNoCodes = [] as Code[];
        if ((eqpClsfNos || []).length) {
          eqpClsfNoCodes = (eqpClsfNos || []).reduce(
            (acc, cur) => [
              ...acc,
              {
                cmnCd: cur.eqpClsfNo,
                cmnCdNm: cur.eqpClsfNm,
              } as Code,
            ],
            [] as Code[]
          );
        }

        setCode({
          ...code,
          mpImprTypeLvl1: mpImprTypeLvl1,
          processCode: processCode,
          mpWorkType: mpWorkType,
          eqpClsfNoCodes: eqpClsfNoCodes,
        });
      }
    }
  };

  /**
   * 개선목적 Level1 변경에 따른 검색조건 값 설정 변경
   *
   * 재설정 항목
   * 1. 개선목적 Level2
   */
  const changeSearchConditionByIprvClsfLv1Cd = async () => {
    setMpItemCondition({
      ...mpItemCondition,
      iprvClsfLv2Cd: '', // 개선목적 Level2
    });

    setCode({
      ...code,
      mpImprTypeLvl2: [],
    });

    // 개선목적 Level1에 따른 검색조건 코드 조회
    if (mpItemCondition?.iprvClsfLv1Cd) {
      // 1. 개선목적 Level2 (iprvClsfLv2Cd)
      const mpImprTypeLvl2: Code[] = await getCommonCodeNamesCondition({
        cmnGrCd: 'MP_IMPR_TYPE_LVL2',
        optValCtn1: mpItemCondition?.iprvClsfLv1Cd,
      });
      setCode({
        ...code,
        mpImprTypeLvl2: mpImprTypeLvl2,
      });
    }
  };

  /**
   * MP유형 변경에 따른 검색조건 값 설정 변경
   *
   * 재설정 항목
   * 1. 수평전개 Item 상태
   */
  const changeSearchConditionByItemTpChoCd = async () => {
    setMpItemCondition({
      ...mpItemCondition,
      itemStatCd: '', // Item 상태
    });

    setCode({
      ...code,
      mpItemStatus: [],
    });

    // MP 유형에 따른 검색조건 코드 조회
    if (mpItemCondition?.itemTpChoCd) {
      const optValCtn1 = mpItemCondition?.itemTpChoCd;
      // 1. 수평전개 Item 상태
      const mpItemStatus: Code[] = await getCommonCodeNamesCondition({
        cmnGrCd: 'MP_ITEM_STATUS',
        optValCtn1: optValCtn1,
      });

      setCode({
        ...code,
        mpItemStatus: mpItemStatus,
      });
    }
  };

  const handleDisplayConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const name = e.target.name;
    setDisplayCondition({
      ...displayCondition,
      [name]: e.target.value,
    });

    setMpItemCondition({
      ...mpItemCondition,
      [name.replace('Nm', 'Id')]: '',
    });
  };

  const handleConditionChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setMpItemCondition({
      ...mpItemCondition,
      [e.target.name]: e.target.value,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(mpItemCondition);
    }
  };

  const handleResetCondition = () => {
    setMpItemCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as MpItemCondition
    );

    setDisplayCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as DisplayCondition
    );
  };

  /**
   * 설비분류체계 변경에 따른 검색조건 값 설정 변경
   *
   * 재설정 항목
   * 1. 설비 분류 Level1 (Main)
   * 2. 설비 분류 Level2 (Machine)
   * 3. 설비 분류 Level3 (Unit)
   */
  const changeSearchConditionByEqpClsfNo = (eqpLvCd: EqpLvCd, eqpClsfNo?: string) => {
    if (eqpClsfNo) {
      const conditionKey = EqpLvCd.MAIN === eqpLvCd ? 'eqpClsfNo' : 'upprEqpClsfStrcNo';
      const condition = {
        [conditionKey]: eqpClsfNo,
        eqpLvCd: eqpLvCd,
      };
      findMpClass(condition).then((result) => {
        if ((result || []).length) {
          const eqpClsfNoCodes: Code[] = (result || []).reduce(
            (acc, cur) => [
              ...acc,
              {
                cmnCd: cur.eqpClsfStrcNo,
                cmnCdNm: cur.eqpClsfStrcNm,
              } as Code,
            ],
            [] as Code[]
          );

          const codeKey =
            EqpLvCd.MAIN === eqpLvCd
              ? 'eqpLv1Codes'
              : EqpLvCd.MACHINE === eqpLvCd
              ? 'eqpLv2Codes'
              : 'eqpLv3Codes';
          setCode({
            ...code,
            [codeKey]: eqpClsfNoCodes,
          });
        }
      });
    }
  };

  const handleSearch = (condition: MpItemCondition) => {
    if (isRequiredMpClsfCd && !condition.mpClsfCd) {
      setErrors((prev) => ({
        ...prev,
        mpClsfCd: true,
      }));
      return;
    }
    props?.onHandleSearch &&
      props.onHandleSearch({
        ...condition,
        // 진척현황인 경우 수평전개 고정
        itemTpChoCd: isTrackingPage ? MpItemTpCho.HE : condition?.itemTpChoCd,
      });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols className="width33">
                <label>
                  <span className={isRequiredMpClsfCd ? 'dot' : ''}>
                    {String(t('mp.label.MP 분류', 'MP 분류'))}
                  </span>
                </label>
                <ComboBox
                  placeholder={String(t('mp.msg.분류', '분류'))}
                  options={code?.mpClsfType}
                  defaultValue={mpItemCondition?.mpClsfCd}
                  isError={errors?.mpClsfCd}
                  msgError={String(t('mp.msg.MP분류를 선택해 주세요.', 'MP분류를 선택해 주세요.'))}
                  onChange={(value) => {
                    if (isRequiredMpClsfCd) {
                      setErrors((prev) => ({
                        ...prev,
                        mpClsfCd: false,
                      }));
                    }
                    setMpItemCondition({ ...mpItemCondition, mpClsfCd: value as string });
                  }}
                />
              </SearchCols>
              <SearchCols className="width33">
                <label>{String(t('mp.label.설비분류체계', '설비분류체계'))}</label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={code?.eqpClsfNoCodes}
                  defaultValue={mpItemCondition?.eqpClsfNo}
                  onChange={(value) =>
                    setMpItemCondition({
                      ...mpItemCondition,
                      eqpClsfNo: value as string,
                    })
                  }
                />
              </SearchCols>
              <SearchCols className="colspan31">
                <label>
                  {String(t('mp.label.설비분류', '설비분류'))} {/*Main &gt; Machine &gt; Unit*/}
                </label>
                <ComboBox
                  placeholder={String(t('mp.msg.Main', 'Main'))}
                  options={code?.eqpLv1Codes}
                  defaultValue={mpItemCondition?.eqpLv1Cd}
                  onChange={(value) =>
                    setMpItemCondition({
                      ...mpItemCondition,
                      eqpLv1Cd: value as string,
                    })
                  }
                />
                <ComboBox
                  placeholder={String(t('mp.msg.Machine', 'Machine'))}
                  options={code?.eqpLv2Codes}
                  defaultValue={mpItemCondition?.eqpLv2Cd}
                  onChange={(value) =>
                    setMpItemCondition({
                      ...mpItemCondition,
                      eqpLv2Cd: value as string,
                    })
                  }
                />
                <ComboBox
                  placeholder={String(t('mp.msg.Unit', 'Unit'))}
                  options={code?.eqpLv3Codes}
                  defaultValue={mpItemCondition?.eqpLv3Cd}
                  onChange={(value) =>
                    setMpItemCondition({
                      ...mpItemCondition,
                      eqpLv3Cd: value as string,
                    })
                  }
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols>
                {isTrackingPage ? (
                  <>
                    <label>{String(t('mp.label.작업구분', '작업구분'))}</label>
                    <ComboBox
                      placeholder={String(
                        t('mp.msg.작업구분을 선택해 주세요.', '작업구분을 선택해 주세요.')
                      )}
                      options={code?.mpWorkType}
                      defaultValue={mpItemCondition?.wkDivsCd}
                      onChange={(value) =>
                        setMpItemCondition({ ...mpItemCondition, wkDivsCd: value as string })
                      }
                    />
                  </>
                ) : (
                  <>
                    <label>
                      <span>{String(t('mp.label.MP 유형', 'MP 유형'))}</span>
                    </label>
                    <ComboBox
                      placeholder={String(t('com.button.선택', '선택'))}
                      options={code?.mpItemTpCho}
                      defaultValue={mpItemCondition?.itemTpChoCd}
                      onChange={(value) =>
                        setMpItemCondition({ ...mpItemCondition, itemTpChoCd: value as string })
                      }
                    />
                  </>
                )}
              </SearchCols>
              <SearchCols>
                <label>{String(t('mp.label.Item 명', 'Item 명'))}</label>
                <CustomInputWithSearch
                  name="itemNm"
                  placeholder={String(
                    t('mp.msg.Item명을 입력해 주세요.', 'Item명을 입력해 주세요.')
                  )}
                  value={mpItemCondition?.itemNm}
                  onChange={handleConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('com.label.설비군', '설비군'))}</label>
                <CustomInputWithSearch
                  name="eqclId"
                  className="find"
                  placeholder={String(
                    t('mp.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.')
                  )}
                  value={mpItemCondition.eqclId}
                  onChange={handleConditionChange}
                  onSearchClick={() => setOpenEequipmentGroupPopUp(true)}
                  isError={errors?.eqclId}
                  msgError={String(t('mp.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.'))}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              {isMyBookPage ? (
                <SearchCols>
                  <label>{t('mp.label.Item 번호', 'Item 번호')}</label>
                  <CustomInputWithSearch
                    name="itemId"
                    placeholder={String(
                      t('mp.msg.Item 번호를 입력해 주세요.', 'Item 번호를 입력해 주세요.')
                    )}
                    value={mpItemCondition?.itemId}
                    onChange={handleConditionChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
              ) : (
                <SearchCols>
                  <label>{String(t('mp.label.나의 관심항목', '나의 관심항목'))}</label>
                  <ComboBox
                    placeholder={String(t('com.button.선택', '선택'))}
                    options={code?.bookmarkCodes}
                    defaultValue={mpItemCondition?.bmkGrId}
                    onChange={(value) => {
                      setMpItemCondition({
                        ...mpItemCondition,
                        bmkGrId: value as string,
                      });

                      props?.changeBookmark && props.changeBookmark(value);
                    }}
                  />
                </SearchCols>
              )}
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            defaultCondition={{
              startDataInsDtm: dayjs().add(-2, 'year').format('YYYY.MM.DD'),
              endDataInsDtm: dayjs().format('YYYY.MM.DD'),
            }}
            onReset={handleResetCondition}
            onSearch={() => handleSearch(mpItemCondition)}
            onFolding={(isOpen) => setOpenSearchBox(isOpen)}
          />
        </SearchBoxRow>
        <SearchBoxRow style={{ display: isOpenSearchBox ? 'block' : 'none' }}>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('mp.label.개선목적', '개선목적'))} Level 1</label>
                <ComboBox
                  placeholder={String(t('mp.msg.Level1', 'Level1'))}
                  options={code?.mpImprTypeLvl1}
                  defaultValue={mpItemCondition?.iprvClsfLv1Cd}
                  onChange={(value) =>
                    setMpItemCondition({
                      ...mpItemCondition,
                      iprvClsfLv1Cd: value as string,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('mp.label.개선목적', '개선목적'))} Level 2</label>
                <ComboBox
                  placeholder={String(t('mp.msg.Level2', 'Level2'))}
                  options={code?.mpImprTypeLvl2}
                  defaultValue={mpItemCondition?.iprvClsfLv2Cd}
                  onChange={(value) =>
                    setMpItemCondition({
                      ...mpItemCondition,
                      iprvClsfLv2Cd: value as string,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('mp.label.공정', '공정'))}</label>
                <ComboBox
                  placeholder={String(t('mp.msg.공정', '공정'))}
                  options={code?.processCode}
                  defaultValue={mpItemCondition?.procCd}
                  onChange={(value) =>
                    setMpItemCondition({ ...mpItemCondition, procCd: value as string })
                  }
                />
              </SearchCols>
              <SearchCols>
                {isTrackingPage ? (
                  <>
                    <label>{String(t('mp.label.기술 제안자', '기술 제안자'))}</label>
                    <CustomInputWithSearch
                      name="reqMgrNm"
                      className="find"
                      placeholder={String(
                        t('mp.msg.기술 제안자를 입력해 주세요.', '기술 제안자를 입력해 주세요.')
                      )}
                      value={displayCondition.reqMgrNm}
                      onChange={handleDisplayConditionChange}
                      onSearchClick={() => {
                        setEmpPopupCondition({
                          fieldId: 'reqMgrNm',
                          initParam: displayCondition.reqMgrNm || '',
                        });
                        setOpenElmEmpPopup(true);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  </>
                ) : (
                  <>
                    <label>{String(t('mp.label.작업구분', '작업구분'))}</label>
                    <ComboBox
                      placeholder={String(
                        t('mp.msg.작업구분을 선택해 주세요.', '작업구분을 선택해 주세요.')
                      )}
                      options={code?.mpWorkType}
                      defaultValue={mpItemCondition?.wkDivsCd}
                      onChange={(value) =>
                        setMpItemCondition({ ...mpItemCondition, wkDivsCd: value as string })
                      }
                    />
                  </>
                )}
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{String(t('mp.label.Item 상태', 'Item 상태'))}</label>
                <ComboBox
                  placeholder={String(t('com.button.선택', '선택'))}
                  options={code?.mpItemStatus}
                  defaultValue={mpItemCondition?.itemStatCd}
                  onChange={(value) =>
                    setMpItemCondition({
                      ...mpItemCondition,
                      itemStatCd: value as string,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                {isTrackingPage ? (
                  <>
                    <label>{t('mp.label.Item 번호', 'Item 번호')}</label>
                    <CustomInputWithSearch
                      name="itemId"
                      placeholder={String(
                        t('mp.msg.Item 번호를 입력해 주세요.', 'Item 번호를 입력해 주세요.')
                      )}
                      value={mpItemCondition?.itemId}
                      onChange={handleConditionChange}
                      onKeyDown={handleKeyDown}
                    />
                  </>
                ) : (
                  <>
                    <label>{String(t('mp.label.기술 제안자', '기술 제안자'))}</label>
                    <CustomInputWithSearch
                      name="reqMgrNm"
                      className="find"
                      placeholder={String(
                        t('mp.msg.기술 제안자를 입력해 주세요.', '기술 제안자를 입력해 주세요.')
                      )}
                      value={displayCondition.reqMgrNm}
                      onChange={handleDisplayConditionChange}
                      onSearchClick={() => {
                        setEmpPopupCondition({
                          fieldId: 'reqMgrNm',
                          initParam: displayCondition.reqMgrNm || '',
                        });
                        setOpenElmEmpPopup(true);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  </>
                )}
              </SearchCols>
              <SearchCols>
                <label>{String(t('com.label.등록자', '등록자'))}</label>
                <CustomInputWithSearch
                  name="dataInsUserNm"
                  className="find"
                  placeholder={String(
                    t('com.label.등록자를 입력해 주세요.', '등록자를 입력해 주세요.')
                  )}
                  value={displayCondition.dataInsUserNm}
                  onChange={handleDisplayConditionChange}
                  onSearchClick={() => {
                    setEmpPopupCondition({
                      fieldId: 'dataInsUserNm',
                      initParam: displayCondition.dataInsUserNm || '',
                    });
                    setOpenElmEmpPopup(true);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('com.label.등록일', '등록일'))}</label>
                <DatePickerWrap className="range">
                  <Datepicker
                    inputClassName="datepickerInput"
                    useRange={true}
                    placeholder="YYYY.MM.DD - YYYY.MM.DD"
                    separator="-"
                    displayFormat={'YYYY.MM.DD'}
                    i18n={'ko'}
                    value={{
                      startDate: mpItemCondition?.startDataInsDtm || '',
                      endDate: mpItemCondition?.endDataInsDtm || '',
                    }}
                    onChange={(value) => {
                      setMpItemCondition({
                        ...mpItemCondition,
                        startDataInsDtm: String(value?.startDate || ''),
                        endDataInsDtm: String(value?.endDate || ''),
                      });
                    }}
                  ></Datepicker>
                </DatePickerWrap>
              </SearchCols>
            </SearchRows>
            {!isTrackingPage && !isMyBookPage && (
              <SearchRows className="FourCol">
                <SearchCols>
                  <label>{t('mp.label.Item 번호', 'Item 번호')}</label>
                  <CustomInputWithSearch
                    name="itemId"
                    placeholder={String(
                      t('mp.msg.Item 번호를 입력해 주세요.', 'Item 번호를 입력해 주세요.')
                    )}
                    value={mpItemCondition?.itemId}
                    onChange={handleConditionChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
              </SearchRows>
            )}
          </InputBox>
        </SearchBoxRow>
      </SearchBox>
      {isOpenElmEmpPopup && (
        <ElmEmpPopup
          setUserData={setUserData}
          setPopup={setOpenElmEmpPopup}
          initParam={empPopupCondition.initParam}
          fieldId={empPopupCondition.fieldId}
        />
      )}

      {isOpenEquipmentGroupPopUp && (
        <EquipmentGroupPopUp
          open={isOpenEquipmentGroupPopUp}
          close={() => setOpenEequipmentGroupPopUp(false)}
          type={'mp'}
          condition={{
            equipmentGroup: mpItemCondition.eqclId || '',
          }}
          onCallback={(value) =>
            setMpItemCondition({
              ...mpItemCondition,
              eqclId: (value as EquipmentGroupCondition).equipmentGroup,
            })
          }
        />
      )}
    </>
  );
};

export default MPCommonSearch;
