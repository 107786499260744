/** @jsxImportSource @emotion/react */

/**
 * 부서 선택 팝업 TYPE 1
 */
import { getDepartments } from 'apis/admin/Department';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useCallback, useRef, useState } from 'react';
import { Department } from 'models/admin/Department';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchBtn,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { di, PopupBtnGroup, TitleWrap } from 'components/layouts/Dialog';
import { useMessageBar } from 'hooks/useMessageBar';
import { Button } from '@mui/material';
import { CustomInputText, CustomInputWrap } from '../../inputs/CustomInput';
import { SubTitleGroup, SubTitleLayout } from '../../layouts/ContentLayout';
import { ContentGrid } from '../../layouts/ContentGrid';
import { IconButton } from 'components/buttons/IconSVG';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { BlueButton } from 'components/buttons/CustomButton';
import DialogActions from '@mui/material/DialogActions';
import { CloseBtn } from 'components/buttons/CustomButton';

type DepartmentModalProps = {
  open: boolean;
  close: () => void;
  save: (item: Department) => void;
};

const emptyDepartment: Department = {
  deptCd: '',
  copCd: '',
  deptNm: '',
  deptEngNm: '',
  temLdrUserId: '',
  upprDeptCd: '',
  useYn: '',
  deptCngNm: '',
};

const DepartmentType1Modal = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [clickedDepartment, setClickedDepartment] = useState<Department>(emptyDepartment);
  const [selectedDepartment, setSelectedDepartment] = useState<Department>(emptyDepartment);
  const [searchDeptNm, setSearchDeptNm] = useState<string>('');
  const [searchItem, setSearchItem] = useState<string>('');
  const [gridHeight, setGridHeight] = useState<string>('100%');
  const { openMessageBar } = useMessageBar();

  const columns: ColDef[] = [
    {
      headerName: 'No',
      width: 50,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        return params.node.rowIndex + 1;
      },
    },
    {
      headerName: String(t('com.label.선택', '선택')),
      // headerCheckboxSelection: false,
      width: 50,
      checkboxSelection: true,
      cellStyle: { textAlign: 'center' },
      cellClass: 'cellCheck',
    },
    {
      field: 'deptCd',
      headerName: String(t('com.label.부서코드', '부서코드')),
      width: 100,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'copCd',
      headerName: String(t('com.label.법인 코드', '법인 코드')),
      width: 100,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'deptNm',
      headerName: String(t('com.label.부서명', '부서명')),
      flex: 1,
      cellStyle: { textAlign: 'center' },
    },
    // {
    //   field: 'useYn',
    //   headerName: String(t('com.label.사용여부', '사용여부')),
    //   width: 100,
    //   cellStyle: { textAlign: 'center' },
    // },
  ];

  const getDepartmentList = useCallback(async () => {
    const response = await getDepartments(searchItem, searchDeptNm);
    setDepartmentList(response.departmentList);
  }, [searchItem, searchDeptNm]);

  const handleClose = () => {
    setDepartmentList([]);
    props.close();
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDeptNm(e.target.value);
  };
  const handleOnChangeDeptCd = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(e.target.value);
  };

  const handleOnSelectionChange = useCallback(() => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) {
      setSelectedDepartment(selectedRows[0]);
    } else {
      setSelectedDepartment(emptyDepartment);
    }
  }, []);

  const handleResetCondition = () => {
    setSearchItem('');
    setSearchDeptNm('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = useCallback(() => {
    getDepartmentList();
    setGridHeight('400px');
  }, [searchItem, searchDeptNm]);

  const btnDoubleClick = (params) => {
    props.save(params.data);
    props.close();
    setDepartmentList([]);
  };

  const handleOnSave = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    if (selectedRows.length === 0) {
      openMessageBar({
        type: 'error',
        content: t('com.label.한 부서 이상 선택해야 합니다.', '한 부서 이상 선택해야 합니다.'),
      });
      return;
    }
    props.save(selectedDepartment);
    props.close();
    setDepartmentList([]);
  };
  return (
    <Dialog open={props.open} onClose={handleClose} css={di.dialog} fullWidth maxWidth="lg">
      <TitleWrap>
        <h2>{t('com.label.부서조회', '부서조회')}</h2>
        <CloseBtn onClick={handleClose}></CloseBtn>
      </TitleWrap>
      <DialogContent className="popupContent">
        <SearchBox>
          <SearchBoxRow>
            <InputBox>
              <SearchRows className={'twoCol'}>
                <SearchCols>
                  <label>{t('com.label.부서명', '부서명')}</label>
                  <CustomInputWithSearch
                    value={searchDeptNm}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
                <SearchCols>
                  <label>{t('com.label.부서코드', '부서코드')}</label>
                  <CustomInputWithSearch
                    value={searchItem}
                    onChange={handleOnChangeDeptCd}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
              </SearchRows>
            </InputBox>
            <SearchButtonWrap>
              <Button
                css={IconButton.button}
                className="reload"
                onClick={handleResetCondition}
                disableRipple
              />
              <Button css={IconButton.button} className="find" disableRipple onClick={handleSearch}>
                {t('com.button.조회', '조회')}
              </Button>
            </SearchButtonWrap>
          </SearchBoxRow>
        </SearchBox>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('com.label.부서', '부서')}</h3>
            <span className="total">
              <span>
                {departmentList ? departmentList.length : 0} {t('com.label.건', '건')}
              </span>
            </span>
          </SubTitleGroup>
        </SubTitleLayout>
        <ContentGrid className="ag-theme-alpine" style={{ height: '500px' }}>
          <AgGridReact
            rowData={departmentList}
            columnDefs={columns}
            headerHeight={32}
            rowHeight={32}
            suppressRowTransform={true}
            ref={gridRef}
            onSelectionChanged={handleOnSelectionChange}
            onCellDoubleClicked={btnDoubleClick}
            rowSelection="single"
          ></AgGridReact>
        </ContentGrid>
      </DialogContent>
      <DialogActions className="popupBottom">
        <BlueButton onClick={handleOnSave}>{t('com.button.선택', '선택')}</BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default DepartmentType1Modal;
