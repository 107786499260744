/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { SubTitleGroup, SubTitleLayout, ControlBtnGroup } from 'components/layouts/ContentLayout';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { IpCheckResultCondition, IpCheckResult } from 'models/ip/IpCheckResult';
import { findCheckResult, downloadExcelCheckResult } from 'apis/ip/IpCheckResult';
import { Pagination, PaginationRef } from 'components/layouts/Pagination';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { Partner, PartnerUser } from 'models/common/Partner';
import CheckResultParaPointModal from './modals/CheckResultParaPointPopUp';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { EquipmentGroupPopUp } from 'pages/common/popup/EquipmentGroupPopUp';
import { ComboBox } from 'components/selects/ComboBox';
import { ParaItemPopUp } from 'pages/ip/popup/ParaItemPopUp';
import PartnerUserModal from 'components/modals/common/PartnerUserModal';
import PartnerModal from 'components/modals/common/PartnerModal';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { ParaItemCondition } from 'models/ip/popup/ParaItem';
import { flexGridTooltip } from 'models/ip/Public';
import _ from 'lodash';
import { HeadersVisibility, Row, SelectionMode, CellType, GroupRow } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import {
  FlexGrid,
  FlexGridColumnGroup,
  FlexGridColumn,
  FlexGridCellTemplate,
} from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { useLoading } from 'components/process/Loading';
import FileUploadPopUp from '../../common/components/FileUploadPopUp';
import { getExcelFileName } from '../../../utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { downloadExcelEquipmentParameter } from '../../../apis/ip/EquipmentParameter';
import { ExcpPartner, ExcpPartnerCondition } from 'models/admin/ExcpPartner';

interface CheckResultPageLocationState {
  ipCheckPlanNo?: string;
  condition?: any;
  pageNo?: number;
}

interface DisplayCondition {
  paraItemNm?: string; // 파라미터 항목명
  ptnrNm?: string; // 협력사코드명
  makerInspectorNm?: string; // maker점검자명
  ensolInspectorNm?: string; // 엔솔점검자명
}

const CheckResultPage = () => {
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const locationState: CheckResultPageLocationState = useLocation().state;
  // const paginationRef = useRef<PaginationRef>(null);
  const [isOpenSearchBox, setOpenSearchBox] = useState<boolean>(false);
  const [rowData, setRowData] = useState<IpCheckResult[]>([]);
  // const [totalCount, setTotalCount] = useState<number>(0);
  // const [pageSize, setPageSize] = useState<number>(
  //   locationState?.condition && locationState?.condition?.pageSize
  //     ? Number(locationState.condition?.pageSize)
  //     : 30
  // );

  const gridRef = useRef<any>(null);
  const [flexRef, setflexRef] = useState<any>();

  // 측정개소 Modal
  const [isOpenCheckResultParaPointModal, setIsOpenCheckResultParaPointModal] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IpCheckResultCondition>({});
  const [ipCheckResultParamCondition, setIpCheckResultParamCondition] =
    useState<IpCheckResultCondition>({
      ipCheckPlanNo: locationState?.ipCheckPlanNo || '',
    });
  const [isOpenEquipmentGroupPopUp, setOpenEequipmentGroupPopUp] = useState<boolean>(false);
  const [isOpenParaItemPopUp, setOpenParaItemPopUp] = useState<boolean>(false);
  const [ipStatusCodes, setIpStatusCodes] = useState<Code[]>([]);
  const [isIsptTpCd, setIsptTpCd] = useState<Code[]>([]);
  const [copCodes, setCopCodes] = useState<Code[]>([]);

  const [isOpenPartnerUserModal, setOpenPartnerUserModal] = useState<boolean>(false);
  const [isOpenPartnerModal, setIsOpenPartnerModal] = useState<boolean>(false);

  const [isOpenElmEmpPopup, setOpenElmEmpPopup] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>('');
  const [displayCondition, setDisplayCondition] = useState<DisplayCondition>({});
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});

  useEffect(() => {
    getCommonCode();
  }, []);

  useEffect(() => {
    if (locationState?.ipCheckPlanNo) {
      const condition = {
        ipCheckPlanNo: locationState?.ipCheckPlanNo || ipCheckResultParamCondition.ipCheckPlanNo,
      };
      setIpCheckResultParamCondition(condition);
      handleSearch(condition);
    }
  }, [locationState]);

  useEffect(() => {
    if (userData != null && userData != '') {
      const userInfo = userData.userInfo[0] || {};
      setIpCheckResultParamCondition({
        ...ipCheckResultParamCondition,
        ensolInspector: userInfo.userId,
      });
      setDisplayCondition({
        ...displayCondition,
        ensolInspectorNm: userInfo.empNm,
      });
    }
  }, [userData]);

  const getCommonCode = async () => {
    const result = await getCommonCodeNames('IP_STATUS_CODE');
    const istpList = await getCommonCodeNames('IP_ISPT_TP_CD');
    setIpStatusCodes(result.filter((code) => code.cmnCd !== 'CANCEL'));
    setCopCodes(await getCommonCodeNames('ELM_COP_CD'));
    setIsptTpCd(istpList);
  };

  // const handlePageNoClick = async (pageNo: number) => {
  //   const condition = {
  //     ...ipCheckResultParamCondition,
  //     pageSize: String(pageSize),
  //     start: String(pageSize * (pageNo - 1)),
  //   };
  //   await handleSearch(condition);
  // };

  // const handlePageSizeChange = async (pageSize: number) => {
  //   await handleSearch(ipCheckResultParamCondition);
  // };

  const handleSearch = async (condition: IpCheckResultCondition) => {
    await findCheckResult(
      condition
      // pageSize: !_.isEmpty(condition.pageSize) ? condition.pageSize : String(pageSize),
    ).then((response) => {
      if (response) {
        const rowData = response || [];
        setRowData(rowData);
        // setTotalCount(response.length || 0);
      }
    });
  };

  /**
   * 검색조건 초기화
   */
  const handleResetCondition = () => {
    setIpCheckResultParamCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as IpCheckResultCondition
    );

    setDisplayCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as DisplayCondition
    );
  };

  const handleParamConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setIpCheckResultParamCondition({
      ...ipCheckResultParamCondition,
      [e.target.name]: e.target.value,
    });
  };
  //
  const handleDisplayConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const name = e.target.name;
    setDisplayCondition({
      ...displayCondition,
      [name]: e.target.value,
    });

    setIpCheckResultParamCondition({
      ...ipCheckResultParamCondition,
      [name.replace('Nm', '')]: '',
    });
  };

  const handleParaPointTypeNmClick = (item) => {
    setSelectedRow({
      ipCheckPlanNo: item.ipCheckPlanNo,
      ipCheckTargetSeqId: item.ipCheckTargetSeqId,
    });
    setIsOpenCheckResultParaPointModal(true);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(ipCheckResultParamCondition);
    }
  };

  const handleOpenFileDownload = (atchFileGrId: string, atchFileTpCd: string) => {
    setFileUploadModalCondition({
      atchFileGrId: atchFileGrId,
      atchFileTpCd: atchFileTpCd,
      optValCtn1: 'TB_ELM_IP_CHECK_RESULT_D',
      bizName: 'ip',
      downloadOnly: true,
    });
    setOpenFileUploadModal(true);
  };

  const onInitialized = (grid) => {
    grid.rowHeaders.columns.splice(0, 1);
    setflexRef(grid);
  };

  const handleDownloadExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ip.label.IP 점검결과', 'IP 점검결과');
    book.saveAsync(getExcelFileName(t('ip.label.IP점검결과조회', 'IP점검결과조회')) + '.xlsx');

    // downloadExcelCheckResult(excelData);
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{String(t('ip.label.IP점검계획번호', 'IP점검계획번호'))}</label>
                <CustomInputWithSearch
                  type="text"
                  name="ipCheckPlanNo"
                  placeholder={String(
                    t('ip.msg.IP 점검계획번호를 입력해 주세요.', 'IP 점검계획번호를 입력해 주세요.')
                  )}
                  value={ipCheckResultParamCondition.ipCheckPlanNo}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ip.label.IP점검계획명', 'IP점검계획명'))}</label>
                <CustomInputWithSearch
                  type="text"
                  name="ipCheckPlanName"
                  placeholder={String(
                    t('ip.msg.IP 점검 계획명을 입력해 주세요.', 'IP 점검 계획명을 입력해 주세요.')
                  )}
                  value={ipCheckResultParamCondition.ipCheckPlanName}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ip.label.진행상태', '진행상태')}</label>
                <ComboBox
                  placeholder={String(
                    t('ip.msg.진행상태를 선택해 주세요.', '진행상태를 선택해 주세요.')
                  )}
                  options={ipStatusCodes}
                  defaultValue={ipCheckResultParamCondition.ipCheckStatus}
                  onChange={(value: string) =>
                    setIpCheckResultParamCondition({
                      ...ipCheckResultParamCondition,
                      ipCheckStatus: value,
                    })
                  }
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('ip.label.법인코드', '법인코드')}</label>
                <ComboBox
                  placeholder={String(
                    t('ip.msg.법인코드를 선택해 주세요.', '법인코드를 선택해 주세요.')
                  )}
                  options={copCodes}
                  defaultValue={ipCheckResultParamCondition.copCd}
                  onChange={(value: string) =>
                    setIpCheckResultParamCondition({
                      ...ipCheckResultParamCondition,
                      copCd: value,
                    })
                  }
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.설비군', '설비군')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="equipmentGroup"
                  className="find"
                  placeholder={String(
                    t('ip.msg.설비군을 입력해 주세요.', '설비군을 입력해 주세요.')
                  )}
                  value={ipCheckResultParamCondition.equipmentGroup}
                  onChange={handleParamConditionChange}
                  onSearchClick={() => setOpenEequipmentGroupPopUp(true)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.설비명', '설비명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="equipmentName"
                  placeholder={String(
                    t('ip.msg.설비명을 입력해 주세요.', '설비명을 입력해 주세요.')
                  )}
                  value={ipCheckResultParamCondition.equipmentName}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('ip.label.Machine', 'Machine')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="machineName"
                  placeholder={String(
                    t('ip.msg.Machine을 입력해 주세요.', 'Machine을 입력해 주세요.')
                  )}
                  value={ipCheckResultParamCondition.machineName}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ip.label.Assembly', 'Assembly')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="assemblyName"
                  placeholder={String(
                    t('ip.msg.Assembly를 입력해 주세요.', 'Assembly를 입력해 주세요.')
                  )}
                  value={ipCheckResultParamCondition.assemblyName}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ip.label.점검유형', '점검유형')}</label>
                <ComboBox
                  placeholder={String(
                    t('ip.label.점검유형', '점검유형') +
                      t('com.label.을 선택해 주세요.', '을 선택해 주세요.')
                  )}
                  options={isIsptTpCd}
                  defaultValue={ipCheckResultParamCondition.isptTpCd}
                  onChange={(value: string) =>
                    setIpCheckResultParamCondition({
                      ...ipCheckResultParamCondition,
                      isptTpCd: value,
                    })
                  }
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            ></Button>
            <Button
              css={IconButton.button}
              className="find"
              onClick={() => {
                handleSearch(ipCheckResultParamCondition);
              }}
              disableRipple
            >
              {t('com.button.조회', '조회')}
            </Button>
            <Button
              css={IconButton.button}
              className={`chevron ${isOpenSearchBox ? 'active' : ''}`}
              onClick={() => setOpenSearchBox(!isOpenSearchBox)}
              disableRipple
            ></Button>
          </SearchButtonWrap>
        </SearchBoxRow>
        <SearchBoxRow style={{ display: isOpenSearchBox ? 'block' : 'none' }}>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('ip.label.설비파라미터번호', '설비 파라미터번호')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="eqpParaNo"
                  placeholder={String(
                    t(
                      'ip.msg.설비파라미터번호를 입력해 주세요.',
                      '설비파라미터번호를 입력해 주세요.'
                    )
                  )}
                  value={ipCheckResultParamCondition.eqpParaNo}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ip.label.설비파라미터명', '설비파라미터명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="eqpParaName"
                  placeholder={String(
                    t('ip.msg.설비 파라미터명을 입력해 주세요.', '설비 파라미터명을 입력해 주세요.')
                  )}
                  value={ipCheckResultParamCondition.eqpParaName}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ip.label.파라미터 항목', '파라미터 항목')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="paraItemNm"
                  className="find"
                  placeholder={String(
                    t('ip.msg.파라미터 항목을 입력해 주세요.', '파라미터 항목을 입력해 주세요.')
                  )}
                  value={displayCondition.paraItemNm}
                  onChange={handleDisplayConditionChange}
                  onSearchClick={() => setOpenParaItemPopUp(true)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('ip.label.파라미터명', '파라미터명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="paraName"
                  placeholder={String(
                    t('ip.msg.파라미터명을 입력해 주세요.', '파라미터명을 입력해 주세요.')
                  )}
                  value={ipCheckResultParamCondition.paraName}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ip.label.Unit', 'Unit')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="unitName"
                  placeholder={String(
                    t('ip.msg.Unit 명을 입력해 주세요.', 'Unit 명을 입력해 주세요.')
                  )}
                  value={ipCheckResultParamCondition.unitName}
                  onChange={handleParamConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ip.label.Maker', 'Maker')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="ptnrNm"
                  className="find"
                  placeholder={String(
                    t('ip.msg.협력사 정보를 입력해 주세요.', '협력사 정보를 입력해 주세요.')
                  )}
                  value={displayCondition.ptnrNm}
                  onChange={handleDisplayConditionChange}
                  onSearchClick={() => setIsOpenPartnerModal(true)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('ip.label.Maker 점검자', 'Maker 점검자')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="makerInspectorNm"
                  className="find"
                  placeholder={String(
                    t('ip.msg.Maker 점검자를 입력해 주세요.', 'Maker 점검자를 입력해 주세요.')
                  )}
                  value={displayCondition.makerInspectorNm}
                  onChange={handleDisplayConditionChange}
                  onSearchClick={() => setOpenPartnerUserModal(true)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.엔솔 점검자', '엔솔 점검자')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="ensolInspectorNm"
                  className="find"
                  placeholder={String(
                    t('ip.msg.엔솔 점검자를 입력해 주세요.', '엔솔 점검자를 입력해 주세요.')
                  )}
                  value={displayCondition.ensolInspectorNm}
                  onChange={handleDisplayConditionChange}
                  onSearchClick={() => setOpenElmEmpPopup(true)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.IP 점검결과', 'IP 점검결과')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{rowData.length}</span> {t('com.label.건', '건')}
          </span>
          {/* <PaginationSize
            defaultPageSize={pageSize}
            onChange={(value) => {
              setPageSize(value);
              paginationRef.current?.handlePageSizeChange(value);
            }}
          /> */}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleDownloadExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(ipCheckResultParamCondition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <ContentGrid className={rowData.length < 1 ? 'noData' : ''} style={{ height: '500px' }}>
        <FlexGrid
          itemsSource={rowData}
          showMarquee={true}
          allowPinning="ColumnRange" // 열 고정 방식
          selectionMode={SelectionMode.CellRange}
          ref={gridRef}
          initialized={onInitialized}
          // showSelectedHeaders="All"
          alternatingRowStep={0}
          // autoGenerateColumns={false}
          formatItem={flexGridTooltip}
          showRowHeader={false}
          style={{ height: (rowData || []).length < 1 ? '' : '500px' }}
          allowMerging="ColumnHeaders"
          align="center"
          isReadOnly={true}
        >
          <FlexGridFilter />
          <FlexGridColumnGroup
            binding="ipCheckPlanNo"
            header={String(t('ip.grid.점검계획번호', '점검계획번호'))}
            width={160}
          />
          <FlexGridColumnGroup
            binding="ipCheckPlanName"
            header={String(t('ip.label.IP점검계획명', 'IP점검계획명'))}
            width={230}
          />
          <FlexGridColumnGroup
            binding="ipCheckStatusNm"
            header={String(t('com.label.진행상태', '진행상태'))}
            width={120}
          />
          <FlexGridColumnGroup
            binding="equipmentGroup"
            header={String(t('com.label.설비군', '설비군'))}
            width={100}
          />
          <FlexGridColumnGroup
            header={String(t('ip.grid.설비', '설비'))}
            collapseTo="machineName"
            isCollapsed={true}
          >
            <FlexGridColumnGroup
              binding="electrodeTypeNm"
              header={String(t('ip.label.Anode/Cathode', 'Anode/Cathode'))}
              width={120}
              allowMerging={true}
            />
            <FlexGridColumnGroup
              binding="eqpMain"
              header={String(t('ip.grid.Main', 'Main'))}
              width={120}
              allowMerging={true}
            />
            <FlexGridColumnGroup
              binding="machineName"
              header={String(t('ip.label.Machine', 'Machine'))}
              width={150}
              allowMerging={true}
            />
            <FlexGridColumnGroup
              binding="unitName"
              header={String(t('ip.grid.Unit', 'Unit'))}
              width={140}
              allowMerging={true}
            />
            <FlexGridColumnGroup
              binding="assemblyName"
              header={String(t('ip.label.Assembly', 'Assembly'))}
              width={140}
              allowMerging={true}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup
            binding="ipCategoryNm"
            header={String(t('ip.grid.IP 구분', 'IP 구분'))}
            width={100}
          />
          <FlexGridColumnGroup
            binding="ipCheckStepNm"
            header={String(t('ip.grid.점검단계', '점검단계'))}
            width={80}
          />
          <FlexGridColumnGroup
            binding="paraItemNm"
            header={String(t('ip.grid.파라미터 항목', '파라미터 항목'))}
            width={120}
          />
          <FlexGridColumnGroup
            binding="paraId"
            header={String(t('ip.grid.파라미터ID', '파라미터ID'))}
            width={130}
          />
          <FlexGridColumnGroup
            binding="paraName"
            header={String(t('ip.grid.파라미터명', '파라미터명'))}
            width={180}
            align="left"
          />
          <FlexGridColumnGroup
            binding="paraDesc"
            header={String(t('ip.grid.파라미터 설명', '파라미터 설명'))}
            width={250}
            align="left"
          />
          <FlexGridColumnGroup
            header={String(t('ip.grid.사양', '사양'))}
            collapseTo="spec"
            isCollapsed={true}
          >
            <FlexGridColumnGroup
              binding="specDataum"
              header={String(t('ip.grid.측정기준', '측정기준'))}
              width={150}
              align="left"
            />
            <FlexGridColumnGroup
              binding="meterTypeNm"
              header={String(t('ip.grid.미터유형', '미터유형'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="lowerBound"
              header={String(t('ip.grid.하한', '하한'))}
              width={80}
            />
            <FlexGridColumnGroup
              binding="upperBound"
              header={String(t('ip.grid.상한', '상한'))}
              width={80}
            />
            <FlexGridColumnGroup
              binding="uomNm"
              header={String(t('ip.grid.UOM', 'UOM'))}
              width={80}
            />
            <FlexGridColumnGroup
              binding="rmk"
              header={String(t('ip.grid.주석', '주석'))}
              width={80}
              align="left"
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup
            header={String(t('ip.grid.측정', '측정'))}
            collapseTo="measure"
            isCollapsed={true}
          >
            <FlexGridColumnGroup
              binding="measureToolNm"
              header={String(t('ip.grid.도구', '도구'))}
              width={120}
              align="left"
            />
            <FlexGridColumnGroup
              binding="measureMethod"
              header={String(t('ip.grid.방법', '방법'))}
              width={140}
              align="left"
            />
            <FlexGridColumnGroup
              binding="measureGuide"
              header={String(t('ip.grid.가이드', '가이드'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="digitalTypeNm"
              header={String(t('ip.grid.A/D', 'A/D'))}
              width={90}
              align="left"
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup
            header={String(t('ip.grid.측정개소', '측정개소'))}
            collapseTo="paraPoint"
            isCollapsed={true}
          >
            <FlexGridColumnGroup
              binding="paraPointType"
              header={String(t('com.label.유형', '유형'))}
              width={130}
            >
              <FlexGridCellTemplate
                cellType="Cell"
                template={(cell) => (
                  <>
                    {cell.item.paraPointTypeNm}
                    {cell.item.paraPointType === 'POINT' && (
                      <Button
                        className="cellPopup"
                        onClick={() => handleParaPointTypeNmClick(cell.item)}
                      ></Button>
                    )}
                  </>
                )}
              />
            </FlexGridColumnGroup>
            <FlexGridColumnGroup
              binding="paraPointCnt"
              header={String(t('ip.grid.개수', '개수'))}
              width={80}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup
            binding="guideAtchFileCnt"
            header={String(t('ip.grid.첨부', '첨부'))}
            align="center"
            width={100}
          >
            <FlexGridCellTemplate
              cellType="Cell"
              template={(cell) => (
                <span
                  style={
                    cell.item.guideAtchFileGrId && cell.item.guideAtchFileCnt > 0
                      ? { textDecoration: 'underline', color: '#554596', cursor: 'pointer' }
                      : {}
                  }
                  onClick={() => {
                    if (cell.item.guideAtchFileGrId && cell.item.guideAtchFileCnt > 0) {
                      setFileUploadModalCondition({
                        atchFileGrId: cell.item.guideAtchFileGrId,
                        atchFileTpCd: 'NORMAL',
                        optValCtn1: 'TB_ELM_IP_EQP_PARA_D',
                        bizName: 'ip',
                        downloadOnly: true,
                      });
                      setOpenFileUploadModal(true);
                    }
                  }}
                >
                  {(cell.item.guideAtchFileCnt || 0).toLocaleString()}
                </span>
              )}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup
            header={String(t('ip.grid.CTQ/CTP', 'CTQ/CTP'))}
            collapseTo="ctqctpTypeNm"
            isCollapsed={true}
          >
            <FlexGridColumnGroup
              binding="ctqctpTypeNm"
              header={String(t('com.label.유형', '유형'))}
              width={80}
            />
            <FlexGridColumnGroup
              binding="ctqctpDetail"
              header={String(t('com.label.상세', '상세'))}
              width={80}
            />
          </FlexGridColumnGroup>
          <FlexGridColumnGroup
            binding="mainIpFlag"
            header={String(t('ip.grid.핵심IP여부', '핵심IP여부'))}
            width={90}
          />
          <FlexGridColumnGroup
            header={String(t('ip.grid.FAT Maker', 'FAT Maker'))}
            collapseTo="fatMakerTargetFlag"
            isCollapsed={true}
          >
            <FlexGridColumnGroup
              binding="fatMakerTargetFlag"
              header={String(t('ip.grid.대상여부', '대상여부'))}
              width={110}
            />
            <FlexGridColumnGroup
              binding="fatMakerValue"
              header={String(t('ip.grid.Value', 'Value'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="fatMakerResult"
              header={String(t('ip.grid.Result', 'Result'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="fatMakerInspectorNm"
              header={String(t('ip.grid.점검자', '점검자'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="fatMakerComment"
              header={String(t('ip.grid.Comment', 'Comment'))}
              width={120}
              align="left"
            />
            <FlexGridColumnGroup
              binding="fatMakerAtchFileCnt"
              header={String(t('com.label.첨부', '첨부'))}
              align="center"
              width={100}
            >
              <FlexGridCellTemplate
                cellType="Cell"
                template={(cell) =>
                  cell.item.paraPointType === 'ATTACH' && (
                    <span
                      style={
                        cell.item.fatMakerAtchFileCnt > 0
                          ? { textDecoration: 'underline', color: '#554596', cursor: 'pointer' }
                          : {}
                      }
                      onClick={() => {
                        if (cell.item.fatMakerAtchFileCnt > 0) {
                          handleOpenFileDownload(cell.item.atchFileGrId, 'FAT_M_RST');
                        }
                      }}
                    >
                      {(cell.item.fatMakerAtchFileCnt || 0).toLocaleString()}
                    </span>
                  )
                }
              />
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup
            header={String(t('ip.grid.FAT 엔솔', 'FAT 엔솔'))}
            collapseTo="fatEnsolTargetFlag"
            isCollapsed={true}
          >
            <FlexGridColumnGroup
              binding="fatEnsolTargetFlag"
              header={String(t('ip.grid.대상여부', '대상여부'))}
              width={110}
            />
            <FlexGridColumnGroup
              binding="fatEnsolValue"
              header={String(t('ip.grid.Value', 'Value'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="fatEnsolResult"
              header={String(t('ip.grid.Result', 'Result'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="fatEnsolInspectorNm"
              header={String(t('ip.grid.점검자', '점검자'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="fatEnsolComment"
              header={String(t('ip.grid.Comment', 'Comment'))}
              width={120}
              align="left"
            />
            <FlexGridColumnGroup
              binding="fatEnsolAtchFileCnt"
              header={String(t('com.label.첨부', '첨부'))}
              align="center"
              width={100}
            >
              <FlexGridCellTemplate
                cellType="Cell"
                template={(cell) =>
                  cell.item.paraPointType === 'ATTACH' && (
                    <span
                      style={
                        cell.item.fatEnsolAtchFileCnt > 0
                          ? { textDecoration: 'underline', color: '#554596', cursor: 'pointer' }
                          : {}
                      }
                      onClick={() => {
                        if (cell.item.fatEnsolAtchFileCnt > 0) {
                          handleOpenFileDownload(cell.item.atchFileGrId, 'FAT_E_RST');
                        }
                      }}
                    >
                      {(cell.item.fatEnsolAtchFileCnt || 0).toLocaleString()}
                    </span>
                  )
                }
              />
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup
            header={String(t('ip.grid.SAT Maker', 'SAT Maker'))}
            collapseTo="satMakerTargetFlag"
            isCollapsed={true}
          >
            <FlexGridColumnGroup
              binding="satMakerTargetFlag"
              header={String(t('ip.grid.대상여부', '대상여부'))}
              width={110}
            />
            <FlexGridColumnGroup
              binding="satMakerValue"
              header={String(t('ip.grid.Value', 'Value'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="satMakerResult"
              header={String(t('ip.grid.Result', 'Result'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="satMakerInspectorNm"
              header={String(t('ip.grid.점검자', '점검자'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="satMakerComment"
              header={String(t('ip.grid.Comment', 'Comment'))}
              width={120}
              align="left"
            />
            <FlexGridColumnGroup
              binding="satMakerAtchFileCnt"
              header={String(t('com.label.첨부', '첨부'))}
              align="center"
              width={100}
            >
              <FlexGridCellTemplate
                cellType="Cell"
                template={(cell) =>
                  cell.item.paraPointType === 'ATTACH' && (
                    <span
                      style={
                        cell.item.satMakerAtchFileCnt > 0
                          ? { textDecoration: 'underline', color: '#554596', cursor: 'pointer' }
                          : {}
                      }
                      onClick={() => {
                        if (cell.item.satMakerAtchFileCnt > 0) {
                          handleOpenFileDownload(cell.item.atchFileGrId, 'SAT_M_RST');
                        }
                      }}
                    >
                      {(cell.item.satMakerAtchFileCnt || 0).toLocaleString()}
                    </span>
                  )
                }
              />
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
          <FlexGridColumnGroup
            header={String(t('ip.grid.SAT 엔솔', 'SAT 엔솔'))}
            collapseTo="satEnsolTargetFlag"
            isCollapsed={true}
          >
            <FlexGridColumnGroup
              binding="satEnsolTargetFlag"
              header={String(t('ip.grid.대상여부', '대상여부'))}
              width={110}
            />
            <FlexGridColumnGroup
              binding="satEnsolValue"
              header={String(t('ip.grid.Value', 'Value'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="satEnsolResult"
              header={String(t('ip.grid.Result', 'Result'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="satEnsolInspectorNm"
              header={String(t('ip.grid.점검자', '점검자'))}
              width={100}
            />
            <FlexGridColumnGroup
              binding="satEnsolComment"
              header={String(t('ip.grid.Comment', 'Comment'))}
              width={120}
              align="left"
            />
            <FlexGridColumnGroup
              binding="satEnsolAtchFileCnt"
              header={String(t('com.label.첨부', '첨부'))}
              align="center"
              width={100}
            >
              <FlexGridCellTemplate
                cellType="Cell"
                template={(cell) =>
                  cell.item.paraPointType === 'ATTACH' && (
                    <span
                      style={
                        cell.item.satEnsolAtchFileCnt > 0
                          ? { textDecoration: 'underline', color: '#554596', cursor: 'pointer' }
                          : {}
                      }
                      onClick={() => {
                        if (cell.item.satEnsolAtchFileCnt > 0) {
                          handleOpenFileDownload(cell.item.atchFileGrId, 'SAT_E_RST');
                        }
                      }}
                    >
                      {(cell.item.satEnsolAtchFileCnt || 0).toLocaleString()}
                    </span>
                  )
                }
              />
            </FlexGridColumnGroup>
          </FlexGridColumnGroup>
        </FlexGrid>
        <div className="noData" style={{ height: (rowData || []).length < 1 ? '500px' : '' }}>
          <span>
            {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
          </span>
        </div>
        {/* {totalCount > 0 && (
          <Pagination
            ref={paginationRef}
            totalCount={totalCount}
            defaultPageSize={pageSize}
            onChangePageSize={handlePageSizeChange}
            onClickPageNo={handlePageNoClick}
          />
        )} */}
      </ContentGrid>
      {isOpenCheckResultParaPointModal && (
        <CheckResultParaPointModal
          condition={selectedRow}
          close={() => setIsOpenCheckResultParaPointModal(false)}
        />
      )}
      {isOpenEquipmentGroupPopUp && (
        <EquipmentGroupPopUp
          open={isOpenEquipmentGroupPopUp}
          close={() => setOpenEequipmentGroupPopUp(false)}
          condition={{
            equipmentGroup: ipCheckResultParamCondition.equipmentGroup || '',
          }}
          onCallback={(value) =>
            setIpCheckResultParamCondition({
              ...ipCheckResultParamCondition,
              equipmentGroup: (value as EquipmentGroupCondition).equipmentGroup,
            })
          }
        />
      )}
      {isOpenParaItemPopUp && (
        <ParaItemPopUp
          open={isOpenParaItemPopUp}
          close={() => setOpenParaItemPopUp(false)}
          condition={{
            paraItemNm: displayCondition.paraItemNm || '',
          }}
          onCallback={(value) => {
            setIpCheckResultParamCondition({
              ...ipCheckResultParamCondition,
              paraItem: (value as ParaItemCondition).paraItem,
            });
            setDisplayCondition({
              ...displayCondition,
              paraItemNm: (value as ParaItemCondition).paraItemNm,
            });
          }}
        />
      )}
      {isOpenPartnerUserModal && (
        <PartnerUserModal
          open={isOpenPartnerUserModal}
          condition={{
            userNm: displayCondition.makerInspectorNm || '',
          }}
          close={() => setOpenPartnerUserModal(false)}
          onCallback={(value) => {
            setIpCheckResultParamCondition({
              ...ipCheckResultParamCondition,
              makerInspector: (value as ExcpPartner).userId,
            });
            setDisplayCondition({
              ...displayCondition,
              makerInspectorNm: (value as ExcpPartner).userNm,
            });
          }}
        />
      )}
      {isOpenPartnerModal && (
        <PartnerModal
          open={isOpenPartnerUserModal}
          condition={{
            ptnrNm: displayCondition.ptnrNm || '',
          }}
          onCallback={(value) => {
            setIpCheckResultParamCondition({
              ...ipCheckResultParamCondition,
              makerCd: (value as ExcpPartner).excpId,
            });
            setDisplayCondition({
              ...displayCondition,
              ptnrNm: (value as ExcpPartner).excpNm,
            });
          }}
          close={() => setIsOpenPartnerModal(false)}
        />
      )}
      {isOpenElmEmpPopup && (
        <ElmEmpPopup
          setUserData={setUserData}
          setPopup={setOpenElmEmpPopup}
          initParam={displayCondition.ensolInspectorNm}
        />
      )}
      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          downloadOnly={fileUploadModalCondition.downloadOnly}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={() => {
            setOpenFileUploadModal(false);
          }}
        />
      )}
    </>
  );
};

export default CheckResultPage;
