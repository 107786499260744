/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormatShapesTwoToneIcon from '@mui/icons-material/FormatShapesTwoTone';
import ChecklistRtlTwoToneIcon from '@mui/icons-material/ChecklistRtlTwoTone';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import AnnouncementTwoToneIcon from '@mui/icons-material/AnnouncementTwoTone';
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone';
import MarkChatUnreadTwoToneIcon from '@mui/icons-material/MarkChatUnreadTwoTone';
import TextsmsTwoToneIcon from '@mui/icons-material/TextsmsTwoTone';
import SmartButtonTwoToneIcon from '@mui/icons-material/SmartButtonTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import TextFieldsTwoToneIcon from '@mui/icons-material/TextFieldsTwoTone';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import ToggleOffTwoToneIcon from '@mui/icons-material/ToggleOffTwoTone';

import InputGuide from './features/InputGuide';
import SelectGuide from './features/SelectGuide';
import ChipGuide from './features/ChipGuide';
import SwitchGuide from './features/SwitchGuide';
import DatepickerGuide from './features/DatepickerGuide';
import ButtonsGuide from './features/ButtonsGuide';
import LoadingGuide from './features/LoadingGuide';
import DialogGuide from './features/DialogGuide';
import ConfirmAlertGuide from './features/ConfirmAlertGuide';
import MessageBarGuide from './features/MessageBarGuide';
import FileUploadGuide from './features/FileUploadGuide';
import FileConverterGuide from './features/FileConverterGuide';
import TextAreaGuide from './features/TextAreaGuide';
import CodeBlock from './CodeBlock';

const drawerWidth = 240;

interface Props {
  window?: () => Window;
}

const GuideComponent = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [selectedMenu, setSelectedMenu] = useState<string | null>('Input');

  const handleMenuClick = (menu: string) => {
    setSelectedMenu(menu);
  };

  const drawer = (
    <div>
      <List>
        {['Input', 'Select', 'Chip', 'Switch', 'TextArea', 'Datepicker', 'Buttons'].map(
          (menu, index) => (
            <ListItem key={menu} onClick={() => handleMenuClick(menu)}>
              <ListItemButton selected={selectedMenu === menu}>
                <ListItemIcon>
                  {(() => {
                    switch (menu) {
                      case 'Input':
                        return <FormatShapesTwoToneIcon />;
                      case 'Select':
                        return <ChecklistRtlTwoToneIcon />;
                      case 'Chip':
                        return <CircleTwoToneIcon />;
                      case 'Switch':
                        return <ToggleOffTwoToneIcon />;
                      case 'TextArea':
                        return <TextFieldsTwoToneIcon />;
                      case 'Datepicker':
                        return <CalendarMonthTwoToneIcon />;
                      case 'Buttons':
                        return <SmartButtonTwoToneIcon />;
                    }
                  })()}
                </ListItemIcon>
                <ListItemText primary={menu} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      <Divider />
      <List>
        {['Dialog', 'Confirm/Alert', 'MessageBar', 'Loading', 'FileUpload', 'FileConverter'].map(
          (menu, index) => (
            <ListItem key={menu} onClick={() => handleMenuClick(menu)}>
              <ListItemButton selected={selectedMenu === menu}>
                <ListItemIcon>
                  {(() => {
                    switch (menu) {
                      case 'Dialog':
                        return <MarkChatUnreadTwoToneIcon />;
                      case 'Confirm/Alert':
                        return <AnnouncementTwoToneIcon />;
                      case 'MessageBar':
                        return <TextsmsTwoToneIcon />;
                      case 'Loading':
                        return <RefreshTwoToneIcon />;
                      case 'FileUpload':
                        return <FileUploadTwoToneIcon />;
                      case 'FileConverter':
                        return <FileUploadTwoToneIcon />;
                    }
                  })()}
                </ListItemIcon>
                <ListItemText primary={menu} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiPaper-root': { display: 'contents' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <div style={{ flexGrow: 1, backgroundColor: '#f8f9fa' }}>
            {selectedMenu === 'Input' && <InputGuide />}
            {selectedMenu === 'Select' && <SelectGuide />}
            {selectedMenu === 'Chip' && <ChipGuide />}
            {selectedMenu === 'Switch' && <SwitchGuide />}
            {selectedMenu === 'Datepicker' && <DatepickerGuide />}
            {selectedMenu === 'TextArea' && <TextAreaGuide />}
            {selectedMenu === 'Buttons' && <ButtonsGuide />}
            {selectedMenu === 'Dialog' && <DialogGuide />}
            {selectedMenu === 'Confirm/Alert' && <ConfirmAlertGuide />}
            {selectedMenu === 'MessageBar' && <MessageBarGuide />}
            {selectedMenu === 'Loading' && <LoadingGuide />}
            {selectedMenu === 'FileUpload' && <FileUploadGuide />}
            {selectedMenu === 'FileConverter' && <FileConverterGuide />}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default GuideComponent;

interface GuideBlockProps {
  title?: string;
  isDisplay?: boolean;
  isCode?: boolean;
  children: ReactNode | string;
}

export const GuideBlock = ({
  title,
  isDisplay = true,
  isCode = false,
  children,
}: GuideBlockProps) => {
  return (
    <>
      <Typography variant="caption" gutterBottom fontWeight={700} fontSize={'20px'}>
        {title || (isCode ? 'Source Code' : isDisplay ? 'Display' : '')}
      </Typography>
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '15px' }}>
        {isCode && typeof children === 'string' ? <CodeBlock>{children}</CodeBlock> : children}
      </Paper>
    </>
  );
};
