import { Department } from 'models/admin/Department';
import { memo, useContext, useEffect, useState } from 'react';
import { getAllDepartments } from 'apis/admin/Department';
import DepartmentTreeItem from './DepartmentTreeItem';
import { DepartmentContext } from 'components/modals/common/EmployeeModal';
import { Tree, TreeBox } from 'components/layouts/Tree';
import { GridInfo, GridTop } from 'components/layouts/ContentSection';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
interface DepartmentTreeProps {
  totalCount?: boolean;
  isRetrieveEnabled?: boolean;
}

const DepartmentTree = (props: DepartmentTreeProps) => {
  const { t } = useTranslation();

  const departmentContext = useContext(DepartmentContext);
  const [userDeptCd, setUserDeptCd] = useState<string>('');
  const [tree, setTree] = useState<Department[]>([]);
  const { totalCount, isRetrieveEnabled = true } = props;

  useEffect(() => {
    isRetrieveEnabled && departmentContext.departmentList.length === 0 && getDepartmentList();

    if (departmentContext.departmentList && tree.length === 0) {
      setTree(nest(departmentContext.departmentList));
    }
  }, [departmentContext]);

  const getDepartmentList = async () => {
    const data = await getAllDepartments();
    if (data) {
      setUserDeptCd(data.userDeptCd);
      departmentContext.handleSelectDepartment({
        ...departmentContext,
        departmentList: data.departmentList,
      });
    }
  };

  const nest = (departmentData: Department[], deptCd = '58174290', link = 'upprDeptCd') =>
    departmentData
      .filter((item) => item[link] === deptCd)
      .map((item) => {
        if (item.deptCd === userDeptCd) {
          departmentContext.handleSelectDepartment({
            ...departmentContext,
            userDeptCd: userDeptCd,
            userDepartment: item,
            selectedDepartment: !departmentContext.multipleSelect ? [item] : [],
          });
        }
        return { ...item, children: nest(departmentData, item.deptCd) };
      });

  return (
    <>
      {totalCount && (
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('admin.label.부서', '부서')}</h3>
            <span className="total">
              총 <span>{departmentContext.departmentList?.length.toLocaleString()}</span>건
            </span>
          </SubTitleGroup>
        </SubTitleLayout>
      )}
      <TreeBox>
        <Tree>
          {tree.length > 0 ? (
            tree.map((subItem: Department, index: number) => (
              <DepartmentTreeItem
                item={subItem}
                key={subItem.deptCd}
                userDept={departmentContext.userDeptCd}
                depth={0}
              />
            ))
          ) : (
            <CircularProgress
              style={{
                position: 'absolute',
                top: '38%',
                left: '48%',
                transform: 'translate(-50%,-50%)',
              }}
            />
          )}
        </Tree>
      </TreeBox>
    </>
  );
};

export default memo(DepartmentTree);
