/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import { useApiUrlManagementStore } from 'stores/useApiUrlManagementStore';
import { ApiManagementGrid } from 'pages/admin/apiurlmanagement/ApiManagementGrid';
import { CustomInputText, CustomInputWrap, UseToggle } from 'components/inputs/CustomInput';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { ContentSection } from 'components/layouts/ContentSection';
import React, { ChangeEvent, useState, useCallback, useEffect } from 'react';
import { ApiUrlCondition } from 'models/admin/ApiUrl';
import { CustomSelect } from 'components/selects/CustomSelect';
import { Code } from 'models/common/CommonCode';
import { Button } from '@mui/material';
import { CommonCode } from '../../models/admin/CommonCode';
import { ComboBox } from '../../components/selects/ComboBox';
import { IconButton } from '../../components/buttons/IconSVG';
// API
export const ApiUrlManagementPage = () => {
  const { t } = useTranslation();
  const { findApiUrls } = useApiUrlManagementStore();
  const [apiNm, setApiNm] = useState<string>('');
  const [apiUrl, setApiUrl] = useState<string>('');
  const [selectedUseYn, setSelectedUseYn] = useState<string>('Y');

  const handleApiNameInputChange = (e: any) => {
    setApiNm(e.target.value);
  };

  const handleApiUrlInputChange = (e: any) => {
    setApiUrl(e.target.value);
  };

  const handleUseYnSelected = (e: any) => {
    setSelectedUseYn(e.target.value === 'Y' ? 'N' : 'Y');
  };

  const handleSubmit = () => {
    findApiUrls({
      apiNm: apiNm,
      apiUrl: apiUrl,
      useYn: selectedUseYn,
    } as ApiUrlCondition);
  };
  const { initApiUrlManagementPage, resetApiUrlManagementPage } = useApiUrlManagementStore();

  useEffect(() => {
    initApiUrlManagementPage();

    return () => {
      resetApiUrlManagementPage();
    };
  }, []);

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const btnReload = () => {
    setApiNm('');
    setApiUrl('');
    setSelectedUseYn('Y');
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('admin.label.API 명', 'API 명')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    id="apiNm"
                    name="apiNm"
                    type="text"
                    value={apiNm}
                    onKeyDown={onKeyDown}
                    onChange={handleApiNameInputChange}
                    placeholder={String(
                      t('admin.label.API명을 입력해 주세요.', 'API명을 입력해 주세요.')
                    )}
                  />
                </CustomInputWrap>
              </SearchCols>
              {/* <SearchCols>
                <label>{t('com.label.사용여부', '사용여부')}</label>
                <CustomSelect id="useYn" value={selectedUseYn} onChange={handleUseYnSelected}>
                  {[
                    { cmnCdNm: t('com.label.전체', '전체'), cmnCd: '' } as CommonCode,
                    ...useYnCodes,
                  ].map((useYnCode) => (
                    <option key={useYnCode.cmnCd} value={useYnCode.cmnCd}>
                      {useYnCode.cmnCdNm}
                    </option>
                  ))}
                </CustomSelect>
              </SearchCols> */}
              <SearchCols>
                <label>{t('admin.label.URL', 'URL')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    id="apiUrl"
                    name="apiUrl"
                    type="text"
                    value={apiUrl}
                    onKeyDown={onKeyDown}
                    onChange={handleApiUrlInputChange}
                    placeholder={String(t('admin.msg.URL을 입력해주세요.', 'URL을 입력해주세요.'))}
                  />
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.사용여부', '사용여부')}</label>
                <UseToggle className="switch">
                  <input
                    type="checkbox"
                    name="useYn"
                    value={selectedUseYn}
                    checked={selectedUseYn === 'Y'}
                    onChange={handleUseYnSelected}
                  />
                  <span className="slider"></span>
                  <span
                    className="labels"
                    data-on={t('com.label.사용', '사용')}
                    data-off={t('com.label.미사용', '미사용')}
                  ></span>
                </UseToggle>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button css={IconButton.button} className="reload" disableRipple onClick={btnReload} />
            <Button css={IconButton.button} className="find" onClick={handleSubmit} id="search">
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ApiManagementGrid />
    </>
  );
};

export default ApiUrlManagementPage;
