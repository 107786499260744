import { Code, CommonCodeCondition } from 'models/common/CommonCode';
import {
  CommonRequest,
  Method,
  ServiceName,
  CommonResponse,
  DmlResponse,
} from 'models/common/RestApi';
import { SearchParam, UtMatrixManagerList } from 'models/ut/UtMatrixManager';
import { callApi } from 'utils/ApiUtil';

export const getUtMatrixManagerMasterList = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getUtMatrixManagerMasterList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<UtMatrixManagerList[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixManagerList[];
};

export const saveUtMatrixManagerList = async (saveData: UtMatrixManagerList[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/saveUtMatrixManagerList`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};

export const getDtalProcCodes = async (condition: CommonCodeCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getDtalProcCodes`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<Code[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as Code[];
};
