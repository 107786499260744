/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { tb } from 'components/layouts/Table';

import {
  AllInputWrap,
  CustomInputText,
  CustomInputWrap,
  Textarea,
} from 'components/inputs/CustomInput';
import { FileuploadBtn } from 'components/buttons/CustomButton';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { CrudCode } from 'models/common/Edit';
import CustomGrid from 'components/grids/CustomGrid';
import { useTranslation } from 'react-i18next';
import { addClass } from '@grapecity/wijmo';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ComboBox } from 'components/selects/ComboBox';
import { hasRole } from 'utils/SessionUtil';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import dayjs from 'dayjs';
import useEvent from 'react-use-event-hook';
import UserModal from '../../components/modals/common/UserModal';
import { Employee } from '../../models/admin/Employee';
import { getUtMatrixMasterList } from '../../apis/ut/UtMatrixList';
import { useCommonModal } from '../../hooks/useCommonModal';
import { useMessageBar } from '../../hooks/useMessageBar';
import {
  getReqUtmNoNew,
  getUtMatrixReqDefaultList,
  getUtMatrixReqTargetList,
  saveUtMatrixReqMaster,
  saveUtMatrixReqMasterReq,
  saveUtMatrixReqTgtList,
} from '../../apis/ut/UtMatrixRequest';
import { UtMatrixReqTargetList, UtMatrixRequest } from '../../models/ut/UtMatrixRequest';
import _, { toNumber } from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SearchParam, UtMatrixDetailList, UtMatrixMasterList } from '../../models/ut/UtMatrixList';
import { getExcelFileName } from '../../utils/ExcelUtil';
import useSessionStore from '../../stores/useSessionStore';
import FileUploadPopUp from '../common/components/FileUploadPopUp';
import { GridStatusCellTemplate } from '../../components/grids/GridStatusCellRenderer';
import { CommonYN, ManagementMode } from 'models/common/Common';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';

/**
 * UT Matrix 작성요청
 * @constructor
 */
const UtMatrixRequestPage = (props) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const menuContext = useContext<MenuContextType>(MenuContext);

  // 관리자 여부
  const [hasManagerAuth, setHasManagerAuth] = useState(false);

  const userSession = useSessionStore();

  const [flexRef, setFlexRef] = useState<any>();

  // 요청 유형(신규,재작성,보류)
  const [reqType, setReqType] = useState<string>('');

  // 화면 key 조회용 : link 되어 넘어오는 경우를 위함
  const [pUtmId, setPUtmId] = useState<string>('');

  // dataset 요청정보 master , 작성대상목록 target
  const [rowDataRequestView, setRowDataRequestView] = useState<UtMatrixRequest>({
    crudKey: CrudCode.CREATE,
    reqMode: '',
    // aprRequest: '',
    askPurpCd: '',
    bfUtmWkProgStatCd: '',
    bildPlntCd: '',
    curYn: 'Y',
    dataInsDtm: '',
    dataInsUserId: '',
    dataInsUserIp: '',
    dataInsUserNm: '',
    // delayCtn: '',
    // 계획완료일
    planCpltDtm: dayjs().add(21, 'day').format('YYYY.MM.DD'),
    // 제출마감일시
    smitCloDtm: dayjs().add(14, 'day').format('YYYY.MM.DD'),
    useYn: 'Y',
    utmId: '',
    utmNm: '',
    utmNo: '',
    utmWkProgStatCd: '',
    verNo: '0.0',
    veupYn: 'N',
    wrtReqCtn: '', // 작성요청내용
  });

  const [rowDataReqTargetList, setRowDataReqTargetList] = useState<UtMatrixReqTargetList[]>([]);

  // 사용자 팝업 검색 조건
  const [userModalCondition, setUserModalCondition] = useState({
    defaultUserId: '',
    target: {
      binding: '',
      row: -1,
      col: -1,
    },
  });

  const [searchParam, setSearchParam] = useState<SearchParam>({
    mode: '',
    utmId: '',
  });

  // 사용자 팝업 오픈 여부
  const [isOpenContPopUp, setOpenContPopUp] = useState<any>(false);

  // 수정 여부
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [isReadOnly, setReadOnly] = useState<boolean>(false);

  // UTM No 채번 룰 : UTM + 건설플랜트 코드 +투자/설계구분자(1자리) + 숫자(2자리)  ex) UTM-GM1-A01, UTM-GM1-D01
  const [verNoNew, setVerNoNew] = useState<string>('');
  const [isOpenMode, setOpenMode] = useState<string>('');

  const [askPurpCdNew, setAskPurpCdNew] = useState<string>('');
  const [bildPlntCdNew, setBildPlntCdNew] = useState<string>('');
  const [utmWkProgStat, setUtmWkProgStat] = useState<string>('');

  // const [smitCloDtmNew, setSmitCloDtmNew] = useState<any>('');
  // const [planCpltDtmNew, setPlanCpltDtmNew] = useState<any>('');

  // 공통코드 (combo box list)  bildPlntCd : 공장, askPurpCd : 목적, utLineQty : 라인수량, utmWkProgStatCd : 진행상태
  const [bildPlntCd, setBildPlntCd] = useState<Code[]>([]);
  const [askPurpCd, setAskPurpCd] = useState<Code[]>([]);
  const [utLineQty, setUtLineQty] = useState<Code[]>([]);
  const [utmWkProgStatCd, setUtmWkProgStatCd] = useState<Code[]>([]);

  // 공통코드 api call
  const getCommonCodes = async () => {
    const bildPlntCd: Code[] = await getCommonCodeNames('BILD_PLNT_CD');
    const askPurpCd: Code[] = await getCommonCodeNames('ASK_PURP_CD');
    const utLineQty: Code[] = await getCommonCodeNames('UT_LINE_QTY');
    const utmWkProgStatCd: Code[] = await getCommonCodeNames('UTM_WK_PROG_STAT_CD');
    setBildPlntCd(bildPlntCd); // 공장
    setAskPurpCd(askPurpCd); // 목적(투자,설계)
    setUtLineQty(utLineQty); // 라인수량
    setUtmWkProgStatCd(utmWkProgStatCd); //진행상태
  };
  const [initParamFileUpload, setInitParamFileUpload] = useState<any>();
  const [isOpenFileUploadPopUp, setOpenFileUploadPopUp] = useState<boolean>(false);

  const utLineQtyMap = new DataMap(utLineQty, 'cmnCd', 'cmnCdNm');

  useEffect(() => {
    // UTM NO 채번
    handleSearchReqUtmNoNew({
      askPurpCd: askPurpCdNew,
      bildPlntCd: bildPlntCdNew,
    });
  }, [askPurpCdNew, bildPlntCdNew]);

  const location = useLocation();
  const state = { ...location.state };
  const navigate = useNavigate();

  useEffect(() => {
    console.log(searchParams.get('utmId'));
    if (state.searchParam != undefined && !_.isEmpty(state.searchParam.utmId)) {
      setPUtmId(state.searchParam.utmId);
      setOpenMode(state.searchParam.mode);
      setSearchParam({ utmId: state.searchParam.utmId, mode: state.searchParam.mode });
      handleSearchRequestView({ utmId: state.searchParam.utmId, mode: state.searchParam.mode }); // 조회
    } else if (searchParams != null && !_.isEmpty(searchParams.get('utmId'))) {
      setPUtmId(searchParams.get('utmId') || '');
      setSearchParam({
        utmId: searchParams.get('utmId') || '',
        mode: searchParams.get('mode') || '',
      });
      handleSearchRequestView({
        utmId: searchParams.get('utmId') || '',
        mode: searchParams.get('mode'),
      }); // 조회
    } else {
      // 신규 메뉴에서 클릭 하는 경우
      setReqType('신규');
      setReadOnly(false);
      setOpenMode('NEW');
      setUtmWkProgStat('UTM01');
    }
  }, [state.searchParam, searchParams]);

  useEffect(() => {
    //공통코드 호출
    getCommonCodes();
    // 관리자 권한 여부
    setHasManagerAuth(hasRole('UT_MANAGER') || hasRole('ADM'));

    //initOpenMode();
  }, []);

  const initOpenMode = () => {
    //open mode
    switch (isOpenMode) {
      case 'COPY_NEW': // 신규 copy
        setReqType('신규');

        // handleSearchRequestView({ utmId: searchParams.get('utmId') }); // 조회
        // setRowDataRequestView({ ...rowDataRequestView, askPurpCd: 'INVEST', verNo: '0.0' });

        // DESIGN 설계 ,  INVEST 투자

        break;
      case 'HOLD': // 보류
        setReqType('보류');
        break;
      case 'DESIGN_NEW': //신규 - 설계
        // UT Matrix List에서 목적이 투자용을 선택하고 신규 버튼 클릭시
        // 목적을 설계용으로 신규 선택,UTM No는 기존 투자용 그대로 적용, Version 1.0 설정,  복사한 값 표시, 목적: 설계용, 분류 신규

        setReqType('신규');
        break;
      case 'DRAFT': // 임시저장
        setReqType('임시저장');
        break;
      case 'REWRITE': // 재작성
        setReqType('재작성');
        break;
      case 'VERSION_UP': // 버전 업 작성
        setReqType('버전업');
        break;
      default: // 신규  NEW
        if (_.isNull(searchParams) || _.isEmpty(searchParams.get('utmId'))) {
          setReqType('신규');
        } else {
          setReqType('');
        }
        break;
    }
  };

  /**
   * 작성요청 기본정보 조회 - 초기 설정 조회
   * @param condition
   */
  const handleSearchRequestView = async (condition) => {
    console.log('---------------', condition.mode);

    getUtMatrixMasterList(condition).then((result: UtMatrixMasterList[]) => {
      if (!_.isNull(result) && result.length > 0) {
        switch (condition.mode) {
          case 'DESIGN_NEW':
            // UT Matrix List에서 목적이 투자용을 선택하고 신규 버튼 클릭시
            // 목적을 설계용으로 신규 선택,UTM No는 기존 투자용 그대로 적용, Version 1.0 설정,  복사한 값 표시, 목적: 설계용, 분류 신규
            result[0].utmWkProgStatCd = 'UTM01';
            result[0].verNo = '1.0';
            result[0].askPurpCd = 'DESIGN';
            setReqType('신규');
            setVerNoNew('1.0');
            setOpenMode('DESIGN_NEW');
            setUtmWkProgStat('UTM01');
            setReadOnly(true);
            result[0].utmWkProgStatCd = 'UTM01';

            result[0].utmId = '';
            setRowDataRequestView(result[0]);
            handleSearchReqDetailList({
              utmId: condition.utmId,
              insTgtYn: 'Y',
              mode: condition.mode,
            });
            break;
          case 'COPY_NEW':
            // 1. UT Matrix List에서 목적이 설계용을 선택하고 신규 버튼 클릭시
            // 목적을 투자용으로 신규 선택, Version 0.0 설정  복사한 값 표시, 분류 : 신규,
            // UTM_NO 는 신규 채번
            result[0].utmWkProgStatCd = 'UTM01';
            result[0].verNo = '0.0';
            result[0].askPurpCd = 'INVEST';
            setReadOnly(true);

            // eslint-disable-next-line no-case-declarations
            const conditionUtmNo2 = {
              bildPlntCd: result[0].bildPlntCd,
              askPurpCd: result[0].askPurpCd,
            };

            getReqUtmNoNew(conditionUtmNo2).then((resultUtmNo: string) => {
              result[0].utmNo = resultUtmNo;
              console.log('resultUtmNo : ', resultUtmNo);
            });

            result[0].utmId = '';

            // setAskPurpCdNew('INVEST');
            setReqType('신규');
            setVerNoNew('0.0');
            setOpenMode('COPY_NEW');
            setUtmWkProgStat(result[0].utmWkProgStatCd || '');

            setRowDataRequestView(result[0]);
            handleSearchReqDetailList({
              utmId: condition.utmId,
              insTgtYn: 'Y',
              mode: condition.mode,
            });
            break;

          case 'VERSION_UP':
            // VERSION_UP
            // UT Matrix List에서 상태가  확정완료 인 UT Matirx 를 선택하고 재작성 요청 클릭
            // 기존 데이터를 복사하여 유틸리티매트릭스식별번호 새로 채번
            // 기존 UT Matrix의 경우 현재버전 N로 변경 표시, 분류: 재작성
            // 버전 : 기존 버전 + 0.1
            // result[0].utmWkProgStatCd = 'UTM01';
            // eslint-disable-next-line no-case-declarations
            // const vernoUp = (0.1 + toNumber(result[0].verNo)).toFixed(1);
            // result[0].verNo = vernoUp.toString();
            setReqType('재작성요청');
            // setVerNoNew(vernoUp.toString());
            setOpenMode('VERSION_UP');
            setReadOnly(true);
            setUtmWkProgStat(result[0].utmWkProgStatCd || '');
            setRowDataRequestView(result[0]);
            handleSearchReqDetailList({ utmId: result[0].utmId, insTgtYn: 'Y' });
            break;

          case 'REWRITE':
            // REWRITE
            // UT Matrix List에서 상태가 작성완료,검토중 버인 UT Matirx 를 선택하고 재작성 요청 클릭
            // 기존 데이터 표시 분류 : 재작성
            setReqType('재작성요청');
            setReadOnly(true);
            setOpenMode('REWRITE');
            setUtmWkProgStat(result[0].utmWkProgStatCd || '');
            setRowDataRequestView(result[0]);
            handleSearchReqDetailList({ utmId: result[0].utmId, insTgtYn: 'Y' });

            break;

          case 'HOLD':
            setReqType('보류');
            setOpenMode('HOLD');
            setReadOnly(true);
            setUtmWkProgStat(result[0].utmWkProgStatCd || '');
            setRowDataRequestView(result[0]);
            handleSearchReqDetailList({ utmId: result[0].utmId, insTgtYn: 'Y' });

            break;

          case 'DRAFT':
            setReqType('임시저장');
            setOpenMode('DRAFT');
            setReadOnly(false);
            setUtmWkProgStat(result[0].utmWkProgStatCd || '');
            setRowDataRequestView(result[0]);
            handleSearchReqDetailList({ utmId: result[0].utmId });

            break;

          default:
            setReqType('');
            setOpenMode('READ');
            setReadOnly(true);
            setUtmWkProgStat(result[0].utmWkProgStatCd || '');
            setRowDataRequestView(result[0]);
            handleSearchReqDetailList({ utmId: result[0].utmId, insTgtYn: 'Y' });
            break;
        }
      }
    });
  };

  /**
   * 작성요청 기본정보 조회 ( 저장 후 조회  )
   * @param condition
   */
  const handleSearchRequestViewRead = async (condition) => {
    getUtMatrixMasterList(condition).then((result: UtMatrixMasterList[]) => {
      if (!_.isNull(result) && result.length > 0) {
        // setOpenMode('READ');
        setUtmWkProgStat(result[0].utmWkProgStatCd || '');
        setRowDataRequestView(result[0]);
        // setOpenMode('READ');

        handleSearchReqDetailList(condition);
      }
    });
  };

  /**
   * 작성요청 기본목록 조회 ( 공장 코드 기준  - 기본정보 )
   * @param condition
   */
  const handleSearchReqTargetList = async (condition) => {
    getUtMatrixReqDefaultList(condition).then((result: UtMatrixReqTargetList[]) => {
      if (result !== null) {
        setRowDataReqTargetList(result);
      }
    });
  };

  /**
   * 작성요청 대상목록 조회 ( utm id 있는 경우 )
   * @param condition
   */
  const handleSearchReqDetailList = async (condition) => {
    getUtMatrixReqTargetList(condition).then((result: UtMatrixDetailList[]) => {
      if (result !== null) {
        if (condition.mode === 'DESIGN_NEW' || condition.mode === 'COPY_NEW') {
          result.map((o, index) => {
            o.rmk = ''; // 복사 해서 넘어 오는 경우 비고는 복사 하지 않음.
          });
        }
        setRowDataReqTargetList(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });

    // setRowDataRequestView({ crudKey: CrudCode.CREATE });
    // if (searchParams.get('utmId')) {
    //   handleSearchReqDetailList(result[0].utmId || '');
    // }
  };

  // init
  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;
      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;
      const col = grid.columns[ht.col];
      console.log('-------------1');
      if (e.target instanceof HTMLInputElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        // setFlexItem(item);
        // setHitTest(ht);

        console.log(item);
        switch (e.target.id) {
          case 'insTgtYn':
            console.log('-------------2');
            item.insTgtYn = e.target.checked ? 'Y' : 'N';
            if (item.crudKey !== CrudCode.CREATE) {
              item.crudKey = CrudCode.UPDATE;
            }
            gridRef.current?.refresh();
            break;
        }
      }

      switch (e.target.id) {
        // 담당자 수정 팝업
        case 'btnElmEmpPopup':
          console.log(binding);
          elmEmpPopupOpen({ binding: binding, header: col.header, row: ht.row, col: ht.col, item });
          break;
      }
    });
  };

  const elmEmpPopupOpen = (params: any) => {
    const binding = params.binding;
    switch (binding) {
      case 'wrtUserNms':
        setUserModalCondition({
          defaultUserId: params.item.wrtUserIds,
          target: {
            binding: binding,
            row: params.row,
            col: params.col,
          },
        });
        break;
    }
    setOpenContPopUp(true);
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      if (isOpenMode === 'REWRITE' || isOpenMode === 'VERSION_UP') {
        // 적용할 컬럼 binding 명
        if (binding === 'wrtUserNms' || binding === 'eqpPrdnLnQty' || binding === 'rmk')
          addClass(cell, 'dot');
      } else {
        if (binding === 'wrtUserNms' || binding === 'eqpPrdnLnQty') addClass(cell, 'dot');
      }
    }
  });

  // UTM No 채번 룰 : UTM + 건설플랜트 코드 +투자/설계구분자(1자리) + 숫자(2자리)
  // ex) UTM-GM1-A01, UTM-GM1-D01
  const handleSearchReqUtmNoNew = async (condition) => {
    const responseGetReqUtmNoNew = await getReqUtmNoNew({
      ...condition,
    });

    // Version
    let version = '1.0';
    if (condition.askPurpCd === 'INVEST') {
      version = '0.0';
    }

    setRowDataRequestView({
      ...rowDataRequestView,
      utmNo: responseGetReqUtmNoNew || '',
      verNo: version || '',
    });
  };

  const handleRejectRe = () => {
    console.log('-------handleRejectRe 재진행');

    const saveRowsData = rowDataRequestView;

    // 작성 요청
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.재진행 하시겠습니까?', '재진행 하시겠습니까?'),
      yesCallback: async () => {
        const saveDataItem = rowDataReqTargetList.filter((element) => element);

        // 상태를 작성중으로 변경한다. 작성대상 공정 상태도 작성중으로 변경한다.
        //  Mail 발송 처리
        // 검증 :  필수 값 입력 확인
        // saveRowsData.utmWkProgStatCd = rowDataRequestView.bfUtmWkProgStatCd; // 검토완료, 검토중 > 작성중
        // saveRowsData.bfUtmWkProgStatCd = rowDataRequestView.utmWkProgStatCd; // 이전 상태 - 보류
        saveRowsData.crudKey = CrudCode.UPDATE;
        saveRowsData.reqMode = 'RESTART';

        const responseMaster = await saveUtMatrixReqMasterReq({
          ...saveRowsData,
          utMatrixReqTargetList: saveDataItem,
        });

        if (responseMaster == null || responseMaster?.successOrNot === 'N') {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }
        setOpenMode('READ');

        openMessageBar({
          type: 'confirm',
          content: t('com.label.요청 되었습니다.', '요청 되었습니다.'),
        });
        // handleSearchRequestViewRead({ utmId: responseMaster.data, insTgtYn: 'Y' });
        // 임시저장 제외한 동작 후 탭닫고 목록 열기
        moveToList();
      },
    });
  };

  const handleDelete = () => {
    const saveRowsData = rowDataRequestView;

    // 작성 요청
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.삭제 하시겠습니까?', '삭제 하시겠습니까?'),
      yesCallback: async () => {
        // 상태를 작성중으로 변경한다. 작성대상 공정 상태도 작성중으로 변경한다.
        //  Mail 발송 처리
        // 검증 :  필수 값 입력 확인
        saveRowsData.bfUtmWkProgStatCd = saveRowsData.utmWkProgStatCd; // 이전 상태
        // saveRowsData.utmWkProgStatCd = 'UTM02'; // 검토완료, 검토중 > 작성중
        saveRowsData.useYn = 'N';
        saveRowsData.crudKey = CrudCode.UPDATE;
        saveRowsData.reqMode = 'DELETE';

        const saveDataItem = rowDataReqTargetList.filter((element) => element);
        const responseMaster = await saveUtMatrixReqMaster({
          ...saveRowsData,
          utMatrixReqTargetList: saveDataItem,
        });

        if (responseMaster == null || responseMaster?.successOrNot === 'N') {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }
        openMessageBar({
          type: 'confirm',
          content: t('com.label.삭제 되었습니다.', '삭제 되었습니다.'),
        });

        // handleSearchRequestViewRead({ utmId: responseMaster.data });
        // 임시저장 제외한 동작 후 탭닫고 목록 열기
        moveToList();
      },
    });
  };

  const handleRequestSaveRe = () => {
    console.log('-------재작성요청번튼');

    const saveRowsData = rowDataRequestView;

    // target list valid
    const saveDataItem = rowDataReqTargetList.filter((element) => element);

    const valid = saveDataItem
      .map((rowNode, index) => {
        if (rowNode.crudKey != CrudCode.DELETE) {
          if (rowNode.insTgtYn === 'Y') {
            if (rowNode.rmk == null || rowNode.rmk == '')
              return `${t(
                'ut.label.재작성요청 사유를 입력해 주세요.',
                '재작성요청 사유를 입력해 주세요.'
              )}`;
          }
        }
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return false;
    }

    // 작성 요청
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.재작성 요청하시겠습니까?', '재작성 요청하시겠습니까?'),
      yesCallback: async () => {
        rowDataRequestView.reqMode = isOpenMode;

        // 대상목록
        // const saveDataItem = rowDataReqTargetList.filter((element) => element);

        //확정완료 이후 재작성 요청
        if (isOpenMode === 'VERSION_UP') {
          console.log(' --------------- VERSION_UP : ');
          saveRowsData.crudKey = CrudCode.CREATE;
          saveRowsData.reqMode = 'VERSION_UP';
          saveRowsData.smitCloDtm = dayjs(saveRowsData.smitCloDtm).format('YYYY.MM.DD');
          saveRowsData.planCpltDtm = dayjs(saveRowsData.planCpltDtm).format('YYYY.MM.DD');

          saveDataItem.map((rowNode) => {
            if (rowNode.insTgtYn === 'Y') {
              rowNode.crudKey = CrudCode.CREATE;
            }
          });
        } else {
          // 상태를 작성중으로 변경한다. 작성대상 공정 상태도 작성중으로 변경한다.
          //  Mail 발송 처리
          // 검증 :  필수 값 입력 확인
          console.log('------------else ', saveRowsData.utmNo);
          saveRowsData.crudKey = CrudCode.UPDATE;
          saveRowsData.reqMode = 'REWRITE';
          saveRowsData.smitCloDtm = dayjs(saveRowsData.smitCloDtm).format('YYYY.MM.DD');
          saveRowsData.planCpltDtm = dayjs(saveRowsData.planCpltDtm).format('YYYY.MM.DD');
          saveDataItem.map((rowNode) => {
            console.log('rowNode.insTgtYn', rowNode.insTgtYn);
            // if (rowNode.insTgtYn === 'Y') {
            rowNode.crudKey = CrudCode.UPDATE;
            // }
          });
        }

        const responseMaster = await saveUtMatrixReqMasterReq({
          ...saveRowsData,
          utMatrixReqTargetList: saveDataItem,
        });

        if (responseMaster == null || responseMaster?.successOrNot === 'N') {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }
        openMessageBar({
          type: 'confirm',
          content: t('com.label.재작성 요청 되었습니다.', '재작성 요청 되었습니다.'),
        });
        // handleSearchRequestViewRead({ utmId: responseMaster.data, insTgtYn: 'Y' });
        // 임시저장 제외한 동작 후 탭닫고 목록 열기
        moveToList();
      },
    });
  };

  const moveToList = () => {
    menuContext.closeCurrentTab(menuContext);
    setTimeout(() => {
      navigate(`/ut/regist-management/list`, {
        replace: true,
        state: {
          utmNo: rowDataRequestView?.utmNo,
        },
      });
    }, 100);
  };

  const handleReject = () => {
    console.log('-------보류');

    const saveRowsData = rowDataRequestView;

    // 보류사유 필수 체크
    if (saveRowsData.rerqRsnCtn == null || saveRowsData.rerqRsnCtn == '')
      return openMessageBar({
        type: 'error',
        content: `${t('ut.label.보류사유를 입력해주세요.', '보류사유를 입력해주세요.')}`,
      });

    // 작성 요청
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.보류 하시겠습니까?', '보류 하시겠습니까?'),
      yesCallback: async () => {
        const saveDataItem = rowDataReqTargetList.filter((element) => element);

        // 상태를 작성중으로 변경한다. 작성대상 공정 상태도 작성중으로 변경한다.
        //  Mail 발송 처리
        // 검증 :  필수 값 입력 확인
        saveRowsData.bfUtmWkProgStatCd = rowDataRequestView.utmWkProgStatCd; // 현재 상태를 이전상태에 저장
        saveRowsData.utmWkProgStatCd = 'UTM06'; // 검토완료, 검토중 > 작성중
        saveRowsData.crudKey = CrudCode.UPDATE;
        saveRowsData.reqMode = 'HOLD';

        saveDataItem.map((rowNode) => {
          if (rowNode.insTgtYn === 'Y') {
            rowNode.bfUtmWrtProcProgStatCd = rowNode.utmWrtProcProgStatCd;
            rowNode.utmWrtProcProgStatCd = 'UTP07';
            rowNode.crudKey = CrudCode.UPDATE;
          }
        });

        const responseMaster = await saveUtMatrixReqMasterReq({
          ...saveRowsData,
          utMatrixReqTargetList: saveDataItem,
        });

        if (responseMaster == null || responseMaster?.successOrNot === 'N') {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }
        openMessageBar({
          type: 'confirm',
          content: t('com.label.보류 되었습니다.', '보류 되었습니다.'),
        });
        // handleSearchRequestViewRead({ utmId: responseMaster.data, insTgtYn: 'Y' });
        // 임시저장 제외한 동작 후 탭닫고 목록 열기
        moveToList();
      },
    });
  };

  /**
   * 작성 요청
   */
  const handleRequestSave = () => {
    console.log('-----------작성요청버튼');

    const saveRowsData = rowDataRequestView;

    if (saveRowsData.bildPlntCd == null || saveRowsData.bildPlntCd == '')
      return openMessageBar({
        type: 'error',
        content: `${t('ut.label.공장을 선택해주세요.', '공장을 선택해주세요.')}`,
      });
    if (saveRowsData.askPurpCd == null || saveRowsData.askPurpCd == '')
      return openMessageBar({
        type: 'error',
        content: `${t('ut.label.목적을 선택해주세요.', '목적을 선택해주세요.')}`,
      });
    if (saveRowsData.wrtReqCtn == null || saveRowsData.wrtReqCtn == '')
      return openMessageBar({
        type: 'error',
        content: `${t('ut.label.요청내용을 입력해주세요.', '요청내용을 입력해주세요.')}`,
      });
    if (saveRowsData.utmNm == null || saveRowsData.utmNm == '')
      return openMessageBar({
        type: 'error',
        content: `${t('ut.label.UT Matrix명을 입력해주세요.', 'UT Matrix명을 입력해주세요.')}`,
      });
    saveRowsData.smitCloDtm = dayjs(saveRowsData.smitCloDtm).format('YYYY.MM.DD');
    saveRowsData.planCpltDtm = dayjs(saveRowsData.planCpltDtm).format('YYYY.MM.DD');

    if (saveRowsData.smitCloDtm == null || saveRowsData.smitCloDtm == '')
      return openMessageBar({
        type: 'error',
        content: `${t(
          'ut.label.UT Matrix 제출일을 선택해주세요.',
          'UT Matrix 제출일을 선택해주세요.'
        )}`,
      });

    if (saveRowsData.planCpltDtm == null || saveRowsData.planCpltDtm == '')
      return openMessageBar({
        type: 'error',
        content: `${t(
          'ut.label.UT Matrix 계획 완료일을 선택해주세요.',
          'UT Matrix 계획 완료일을 선택해주세요.'
        )}`,
      });
    if (saveRowsData.planCpltDtm < saveRowsData.smitCloDtm)
      return openMessageBar({
        type: 'error',
        content: `${t(
          'ut.label.UT Matrix 계획완료일은 제출일보다 이후 날짜를 선택해주세요.',
          'UT Matrix 계획완료일은 제출일보다 이후 날짜를 선택해주세요.'
        )}`,
      });

    // target list valid
    const saveDataItem = rowDataReqTargetList.filter((element) => element);

    let chk = 0;
    const valid = saveDataItem
      .map((rowNode, index) => {
        if (rowNode.crudKey != CrudCode.DELETE) {
          if (rowNode.insTgtYn === 'Y') {
            if (rowNode.eqpPrdnLnQty == null || rowNode.eqpPrdnLnQty == '')
              return `${t('ut.label.라인수량을 선택해주세요.', '라인수량을 선택해주세요.')}`;
            if (rowNode.wrtUserIds == null || rowNode.wrtUserIds == '')
              return `${t('ut.label.담당자를 선택해 주세요.', '담당자를 선택해 주세요.')}`;
            chk++;
          }
        }
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return false;
    }

    ////전극(E) x 조립(A) x 활성화(F)
    //       if( rowNode.bildPlntCd === 'E' ) {
    //
    //       }
    const chkItemE = rowDataReqTargetList.filter((element) => element.prdnProcCd === 'E');
    const chkItemA = rowDataReqTargetList.filter((element) => element.prdnProcCd === 'A');
    const chkItemF = rowDataReqTargetList.filter((element) => element.prdnProcCd === 'F');

    let idxChkE = 0;
    let idxChkA = 0;
    let idxChkF = 0;

    if (chkItemE.length > 0) {
      const chkValE = chkItemE[0].eqpPrdnLnQty;

      chkItemE.map((o) => {
        if (o.eqpPrdnLnQty != chkValE) {
          idxChkE = 1;
        }
      });
    }

    if (chkItemA.length > 0) {
      const chkValA = chkItemA[0].eqpPrdnLnQty;

      chkItemA.map((o) => {
        if (o.eqpPrdnLnQty != chkValA) {
          idxChkA = 1;
        }
      });
    }

    if (chkItemF.length > 0) {
      const chkValF = chkItemF[0].eqpPrdnLnQty;

      chkItemF.map((o) => {
        if (o.eqpPrdnLnQty != chkValF) {
          idxChkF = 1;
        }
      });
    }

    if (idxChkE > 0 || idxChkA > 0 || idxChkF > 0) {
      openMessageBar({
        type: 'warning',
        content: t('com.label.공정별 라인수는 동일해야합니다.', '공정별 라인수는 동일해야합니다.'),
      });
      return;
    }

    if (chk === 0)
      return openMessageBar({
        type: 'error',
        content: `${t(
          'ut.label.작성대상은 1건이상 선택 해야합니다.',
          '작성대상은 1건이상 선택 해야합니다.'
        )}`,
      });
    // 작성 요청
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.요청하시겠습니까?', '요청하시겠습니까?'),
      yesCallback: async () => {
        // 상태를 작성중으로 변경한다. 작성대상 공정 상태도 작성중으로 변경한다.
        //   Mail 발송 처리
        // 검증 :  필수 값 입력 확인
        saveRowsData.bfUtmWkProgStatCd = rowDataRequestView.utmWkProgStatCd; // 이전 상태
        saveRowsData.utmWkProgStatCd = 'UTM02'; // 요청전 > 작성중
        saveRowsData.reqMode = 'REQUEST';

        if (rowDataRequestView.utmId === '') {
          saveRowsData.crudKey = CrudCode.CREATE;
        } else {
          saveRowsData.crudKey = CrudCode.UPDATE;
        }

        if (rowDataRequestView.utmId === '') {
          saveDataItem.map((rowNode) => {
            rowNode.crudKey = CrudCode.CREATE;
            rowNode.utmWrtProcProgStatCd = 'UTP02';
          });
        } else {
          saveDataItem.map((rowNode) => {
            rowNode.crudKey = CrudCode.UPDATE;
            rowNode.utmWrtProcProgStatCd = 'UTP02';
          });
        }

        const responseMaster = await saveUtMatrixReqMaster({
          ...saveRowsData,
          utMatrixReqTargetList: saveDataItem,
        });

        if (responseMaster == null || responseMaster?.successOrNot === 'N') {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }
        openMessageBar({
          type: 'confirm',
          content: t('com.label.요청 되었습니다.', '요청 되었습니다.'),
        });
        // handleSearchRequestViewRead({ utmId: responseMaster.data, insTgtYn: 'Y' });
        // 임시저장 제외한 동작 후 탭닫고 목록 열기
        moveToList();
      },
    });
  };

  /**
   * 임시 저장
   */
  const handleSave = () => {
    // const saveRowsData = { ...rowDataRequestView };

    const saveRowsData = rowDataRequestView;

    if (saveRowsData.bildPlntCd == null || saveRowsData.bildPlntCd == '')
      return openMessageBar({
        type: 'error',
        content: `${t('ut.label.공장을 선택해주세요.', '공장을 선택해주세요.')}`,
      });
    if (saveRowsData.askPurpCd == null || saveRowsData.askPurpCd == '')
      return openMessageBar({
        type: 'error',
        content: `${t('ut.label.목적을 선택해주세요.', '목적을 선택해주세요.')}`,
      });
    if (saveRowsData.utmNm == null || saveRowsData.utmNm == '')
      return openMessageBar({
        type: 'error',
        content: `${t('ut.label.UT Matrix명을 입력해주세요.', 'UT Matrix명을 입력해주세요.')}`,
      });
    if (saveRowsData.wrtReqCtn == null || saveRowsData.wrtReqCtn == '')
      return openMessageBar({
        type: 'error',
        content: `${t('ut.label.요청내용을 입력해주세요.', '요청내용을 입력해주세요.')}`,
      });

    saveRowsData.smitCloDtm = dayjs(saveRowsData.smitCloDtm).format('YYYY.MM.DD');
    saveRowsData.planCpltDtm = dayjs(saveRowsData.planCpltDtm).format('YYYY.MM.DD');

    if (saveRowsData.smitCloDtm == null || saveRowsData.smitCloDtm == '')
      return openMessageBar({
        type: 'error',
        content: `${t(
          'ut.label.UT Matrix 제출일을 선택해주세요.',
          'UT Matrix 제출일을 선택해주세요.'
        )}`,
      });

    if (saveRowsData.planCpltDtm == null || saveRowsData.planCpltDtm == '')
      return openMessageBar({
        type: 'error',
        content: `${t(
          'ut.label.UT Matrix 계획 완료일을 선택해주세요.',
          'UT Matrix 계획 완료일을 선택해주세요.'
        )}`,
      });

    if (saveRowsData.planCpltDtm < saveRowsData.smitCloDtm)
      return openMessageBar({
        type: 'error',
        content: `${t(
          'ut.label.UT Matrix 계획완료일은 제출일보다 이후 날짜를 선택해주세요.',
          'UT Matrix 계획완료일은 제출일보다 이후 날짜를 선택해주세요.'
        )}`,
      });

    if (rowDataRequestView.utmId === '') {
      saveRowsData.crudKey = CrudCode.CREATE;
      saveRowsData.utmWkProgStatCd = 'UTM01';
    } else {
      saveRowsData.crudKey = CrudCode.UPDATE;
    }

    const isMasterEdit = true;

    const saveDataItem = rowDataReqTargetList.filter((element) => element);

    ////전극(E) x 조립(A) x 활성화(F)
    //       if( rowNode.bildPlntCd === 'E' ) {
    //
    //       }
    const chkItemE = rowDataReqTargetList.filter((element) => element.prdnProcCd === 'E');
    const chkItemA = rowDataReqTargetList.filter((element) => element.prdnProcCd === 'A');
    const chkItemF = rowDataReqTargetList.filter((element) => element.prdnProcCd === 'F');
    let idxChkE = 0;
    let idxChkA = 0;
    let idxChkF = 0;

    if (chkItemE.length > 0) {
      const chkValE = chkItemE[0].eqpPrdnLnQty;

      chkItemE.map((o) => {
        if (o.eqpPrdnLnQty != chkValE) {
          idxChkE = 1;
        }
      });
    }

    if (chkItemA.length > 0) {
      const chkValA = chkItemA[0].eqpPrdnLnQty;

      chkItemA.map((o) => {
        if (o.eqpPrdnLnQty != chkValA) {
          idxChkA = 1;
        }
      });
    }

    if (chkItemF.length > 0) {
      const chkValF = chkItemF[0].eqpPrdnLnQty;

      chkItemF.map((o) => {
        if (o.eqpPrdnLnQty != chkValF) {
          idxChkF = 1;
        }
      });
    }

    if (idxChkE > 0 || idxChkA > 0 || idxChkF > 0) {
      openMessageBar({
        type: 'warning',
        content: t('com.label.공정별 라인수는 동일해야합니다.', '공정별 라인수는 동일해야합니다.'),
      });
      return;
    }

    saveDataItem.map((rowNode) => {
      rowNode.utmWrtProcProgStatCd = 'UTP01';

      if (saveRowsData.utmId === '') {
        rowNode.crudKey = CrudCode.CREATE;
      } else {
        rowNode.crudKey = CrudCode.UPDATE;
      }
    });

    if ((!saveDataItem || saveDataItem.length < 1) && !isMasterEdit) {
      openMessageBar({
        type: 'warning',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        const responseMaster = await saveUtMatrixReqMaster({
          ...saveRowsData,
          utMatrixReqTargetList: saveDataItem,
        });

        if (responseMaster == null || responseMaster?.successOrNot === 'N') {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }
        openMessageBar({
          type: 'confirm',
          content: t('com.label.저장되었습니다.', '저장되었습니다.'),
        });
        setOpenMode('DRAFT');
        console.log('after save utmid ', responseMaster.data);

        handleSearchRequestViewRead({ utmId: responseMaster.data });
      },
    });
  };

  const btnEmpPop = (params: any) => {
    // 담당자 변경 가능한 상태인 경우
    if (isOpenMode === 'VERSION_UP' || isOpenMode === 'REWRITE') {
      return `<span>${params.value || ''}</span><button id="btnContPop" />`;
    } else {
      return `<span>${params.value || ''}</span><button id="btnElmEmpPopup" />`;
    }
  };

  // grid layout
  // 작성대상, 공정, 세부공정, 상태, 라인수량, 담당자, 비고
  const layoutDefinition = [
    {
      binding: 'crudKey',
      header: String(t('ut.label.상태', '상태')),
      isReadOnly: true,
      width: 50,
      align: 'center',
      cellTemplate: GridStatusCellTemplate,
    },
    // {
    //   binding: 'planProcId',
    //   header: String(t('ut.label.계획공정아이디', '계획공정아이디')),
    //   isReadOnly: true,
    //   align: 'center',
    // },
    {
      binding: 'insTgtYn',
      header: String(t('ut.label.작성대상', '작성대상')),
      isReadOnly: true,
      visible: !(isOpenMode === 'READ' || isOpenMode === 'HOLD'),
      width: 120,
      align: 'left',
      cssClass: 'WijmoCheck',
      cellTemplate: (params) => {
        const checked = params.value === 'Y' ? 'checked' : '';
        return `<div class="checkbox">
                  <input type="checkbox" id="insTgtYn" ${checked} />  
                  <label />
                </div>
          `;
      },
    },
    {
      binding: 'bildPlntNm',
      header: String(t('ut.label.건설플랜트명', '건설플랜트명')),
      isReadOnly: true,
      align: 'left',
      visible: false,
      width: 160,
    },
    {
      header: String(t('ut.label.공정', '공정')),
      binding: 'prdnProcNm',
      isReadOnly: true,
      align: 'left',
      // visible: false,
      width: 200,
    },
    {
      header: String(t('ut.label.세부공정', '세부공정')),
      binding: 'dtalProcNm',
      isReadOnly: true,
      align: 'left',
      width: 250,
    },
    {
      header: String(t('ut.label.라인수량', '라인수량')),
      binding: 'eqpPrdnLnQty',
      cssClass: 'WijmoSelect',
      dataMap: utLineQtyMap,
      isReadOnly: isOpenMode === 'VERSION_UP' || isOpenMode === 'REWRITE',
      width: 120,
      // cellTemplate: (item) => {
      //   if (utLineQtyMap.getDisplayValue(item.value) == undefined) {
      //     return `<span>${item.text}</span>`;
      //   } else {
      //     return `<span>${utLineQtyMap.getDisplayValue(item.value)}</span>`;
      //   }
      // },
    },
    {
      header: String(t('ut.label.담당자', '담당자')),
      binding: 'wrtUserNms',
      align: 'left',
      cssClass: 'WijmoFind',
      isReadOnly: true,
      width: 250,
      // cellTemplate: (item) => {
      //   console.log(item);
      //   if (isNull(item.item.wrtUserNms)) return `<span></span><button id="" />`;
      //   return `<span>${item.item.wrtUserNms}</span><button id="wrtUserIds" />`;
      // },
      cellTemplate: (params) => btnEmpPop({ ...params }),
    },
    {
      header: String(t('ut.label.비고', '비고')),
      binding: 'rmk',
      align: 'left',
      // isReadOnly: false,
      width: '*',
    },
  ];

  const handleConditionChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setRowDataRequestView({ ...rowDataRequestView, [e.target.name]: e.target.value || '' });
  };

  const btnExcelExportGrid = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ut.label.Ut Matrix 작성 대상', 'Ut Matrix 작성 대상');
    book.saveAsync(getExcelFileName(t('ut.label.Ut Matrix 작성 대상', 'Ut Matrix 작성 대상')));
  };

  /**
   * 첨부파일
   * @param atchFileTpCd
   */
  const handleFileUpload = (atchFileTpCd) => {
    let downloadOnly = true;
    let fileCount = 0;
    // 중간점검 결과 > 중간점검첨부
    if ('UTM_REQUEST' === atchFileTpCd) {
      downloadOnly = false;
      fileCount = Number(rowDataRequestView?.fileCount || 0);
    }

    // [24.03.12] 다운로드할 첨부파일이 없는 경우 팝업 미노출
    // if (downloadOnly && fileCount < 1) {
    //   return;
    // }

    setInitParamFileUpload({
      atchFileGrId: rowDataRequestView?.atchFileGrId,
      atchFileTpCd: atchFileTpCd,
      tableName: 'tb_eelmb_utm_m',
      bizName: 'ut',
      downloadOnly: downloadOnly,
    });
    setOpenFileUploadPopUp(true);
  };

  return (
    <>
      <TableContainer css={tb.table} className="colFix9">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{t('ut.label.공장', '공장')}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  options={bildPlntCd}
                  placeholder={String(t('com.label.선택', '선택'))}
                  defaultValue={rowDataRequestView?.bildPlntCd}
                  disabled={isReadOnly}
                  onChange={(value) => {
                    handleSearchReqTargetList({
                      bildPlntCd: value,
                      askPurpCd: askPurpCdNew,
                      insTgtYn: 'Y',
                    });
                    setRowDataRequestView({
                      ...rowDataRequestView,
                      bildPlntCd: value,
                    });
                    setBildPlntCdNew(value);
                  }}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('ut.label.목적', '목적')}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  options={askPurpCd}
                  placeholder={String(t('com.label.선택', '선택'))}
                  defaultValue={rowDataRequestView?.askPurpCd}
                  disabled={isReadOnly}
                  onChange={(value) => {
                    setRowDataRequestView({
                      ...rowDataRequestView,
                      askPurpCd: value,
                    });
                    setAskPurpCdNew(value);
                  }}
                />
              </TableCell>
              <TableCell>{t('ut.label.분류', '분류')}</TableCell>
              <TableCell>{reqType}</TableCell>
              <TableCell>{t('ut.label.상태', '상태')}</TableCell>
              <TableCell>
                <ComboBox
                  options={utmWkProgStatCd}
                  defaultValue={utmWkProgStat == '' ? 'UTM01' : utmWkProgStat}
                  // disabled={!isEditMode}
                  readOnly={true}
                  onChange={(value) => {
                    console.log(value);
                  }}
                />
              </TableCell>
              <TableCell>{t('ut.label.버전', '버전')}</TableCell>
              <TableCell>
                {rowDataRequestView?.verNo === '' ? verNoNew : rowDataRequestView?.verNo}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('ut.label.UT Matrix 번호', 'UT Matrix 번호')}</TableCell>
              <TableCell>{rowDataRequestView?.utmNo}</TableCell>
              <TableCell>
                <span className="dot">{t('ut.label.UT Matrix 명', 'UT Matrix 명')}</span>
              </TableCell>
              <TableCell colSpan={3}>
                <CustomInputWrap>
                  <CustomInputText
                    type="text"
                    name="utmNm"
                    readOnly={
                      isOpenMode === 'VERSION_UP' ||
                      isOpenMode === 'READ' ||
                      isOpenMode === 'HOLD' ||
                      isOpenMode === 'REWRITE'
                    }
                    placeholder={String(
                      t('ut.label.UT Matrix명을 입력해 주세요.', 'UT Matrix명을 입력해 주세요.')
                    )}
                    // disabled
                    value={rowDataRequestView?.utmNm}
                    onChange={(e) => handleConditionChange(e)}
                  ></CustomInputText>
                </CustomInputWrap>
              </TableCell>
              <TableCell>
                <span className="dot">{t('ut.label.제출요청일', '제출요청일')}</span>
              </TableCell>
              <TableCell>
                <CustomDatepicker
                  isRange={false} // 기간선택여부
                  // value={dayjs().add(3, 'day').toDate()} // 기본값 (date)
                  value={
                    rowDataRequestView?.smitCloDtm
                    // rowDataRequestView?.smitCloDtm === undefined ||
                    // rowDataRequestView.smitCloDtm == ''
                    //   ? dayjs().add(14, 'day').toDate()
                    //   : rowDataRequestView?.smitCloDtm
                  } // 기본값 (string)
                  minDate={dayjs().add(0, 'day').toDate()} // 최소날짜
                  disabled={isOpenMode === 'READ' || isOpenMode === 'HOLD'}
                  // maxDate={dayjs().add(+14, 'day').toDate()} // 최대날짜
                  onChange={(newValue) => {
                    // setSmitCloDtmNew(newValue);
                    setRowDataRequestView((prev) => ({
                      ...prev,
                      smitCloDtm: newValue as string,
                    }));
                  }}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{t('ut.label.계획완료일', '계획완료일')}</span>
              </TableCell>
              <TableCell>
                <CustomDatepicker
                  isRange={false} // 기간선택여부
                  // value={dayjs().add(3, 'day').toDate()} // 기본값 (date)
                  value={rowDataRequestView?.planCpltDtm} // 기본값 (string)
                  minDate={dayjs().add(0, 'day').toDate()} // 최소날짜
                  disabled={isOpenMode === 'READ' || isOpenMode === 'HOLD'}
                  // maxDate={dayjs().add(+14, 'day').toDate()} // 최대날짜
                  onChange={(newValue) => {
                    // setPlanCpltDtmNew(newValue);
                    setRowDataRequestView((prev) => ({
                      ...prev,
                      planCpltDtm: newValue as string,
                    }));
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('ut.label.요청내용', '요청내용')}</span>
              </TableCell>
              <TableCell colSpan={9}>
                <Textarea
                  style={{ height: '100px' }}
                  readOnly={isOpenMode === 'READ' || isOpenMode === 'HOLD'}
                  value={rowDataRequestView?.wrtReqCtn}
                  onChange={(e) => {
                    setRowDataRequestView({
                      ...rowDataRequestView,
                      wrtReqCtn: e.target.value || '',
                    });
                  }}
                ></Textarea>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('com.label.첨부파일', '첨부파일')}</TableCell>
              <TableCell>
                <FileuploadBtn onClick={() => handleFileUpload('UTM_REQUEST')}>
                  <span>{toNumber(rowDataRequestView?.fileCount || 0).toLocaleString()}</span>
                </FileuploadBtn>
              </TableCell>
              <TableCell>{t('com.label.요청자', '요청자')}</TableCell>
              <TableCell>
                {rowDataRequestView?.dataInsUserNm === undefined
                  ? userSession.empNm
                  : rowDataRequestView?.dataInsUserNm}
              </TableCell>
              <TableCell>{t('com.label.요청일', '요청일')}</TableCell>
              <TableCell>
                {rowDataRequestView?.dataInsDtm === undefined
                  ? dayjs().format('yyyy.mm.dd')
                  : rowDataRequestView?.dataInsDtm}
              </TableCell>
              <TableCell>{t('com.label.수정자', '수정자')}</TableCell>
              <TableCell>{rowDataRequestView?.dataUpdUserNm}</TableCell>
              <TableCell>{t('com.label.수정일', '수정일')}</TableCell>
              <TableCell>{rowDataRequestView?.dataUpdDtm}</TableCell>
            </TableRow>
            {(isOpenMode === 'HOLD' || rowDataRequestView.utmWkProgStatCd === 'UTM06') && (
              <TableRow>
                <TableCell>
                  <span className="dot">{t('ut.label.보류사유', '보류사유')}</span>
                </TableCell>
                <TableCell colSpan={9}>
                  <AllInputWrap>
                    <CustomInputWrap>
                      <CustomInputText
                        value={rowDataRequestView?.rerqRsnCtn}
                        name="rerqRsnCtn"
                        onChange={(e) => handleConditionChange(e)}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </AllInputWrap>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <SubTitleLayout style={{ marginTop: '24px' }}>
        <SubTitleGroup>
          <h3>{t('ut.label.UT Matrix 담당자별 요청', 'UT Matrix 담당자별 요청')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowDataReqTargetList || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExportGrid}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>

      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowDataReqTargetList}
        align="center"
        isReadOnly={isOpenMode === 'READ' || isOpenMode === 'HOLD'}
        initialized={onInitialized}
        isSelector={true}
        allowPinning={false}
        height={350}
        excludeFilter={['countB1', 'countB2', 'countB3', 'countB4', 'result']}
      />
      <GlobalBtnGroup>
        {isOpenMode === 'REWRITE' && (
          <>
            <Button css={IconButton.button} className="request" onClick={handleRequestSaveRe}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
          </>
        )}
        {isOpenMode === 'VERSION_UP' && rowDataRequestView.utmWkProgStatCd === 'UTM05' && (
          <>
            <Button css={IconButton.button} className="request" onClick={handleRequestSaveRe}>
              {t('com.button.재작성요청(버전업)', '재작성요청(버전업)')}
            </Button>
          </>
        )}
        {isOpenMode === 'HOLD' && rowDataRequestView.utmWkProgStatCd !== 'UTM06' && (
          <Button css={IconButton.button} className="info" onClick={handleReject}>
            {t('com.button.보류', '보류')}
          </Button>
        )}
        {rowDataRequestView.utmWkProgStatCd === 'UTM06' && (
          <Button css={IconButton.button} className="refresh" onClick={handleRejectRe}>
            {t('com.button.재진행', '재진행')}
          </Button>
        )}
        {((rowDataRequestView.utmWkProgStatCd === 'UTM01' && isOpenMode === 'DRAFT') ||
          (isOpenMode === 'NEW' && rowDataRequestView.utmId != '')) && (
          <Button css={IconButton.button} className="delete" onClick={handleDelete}>
            {t('com.button.삭제', '삭제')}
          </Button>
        )}
        {(rowDataRequestView.utmWkProgStatCd === 'UTM01' ||
          rowDataRequestView.utmWkProgStatCd == undefined ||
          isOpenMode === 'NEW') &&
          isOpenMode !== 'READ' && (
            <>
              <Button css={IconButton.button} className="save lightBtn" onClick={handleSave}>
                {t('com.button.임시저장', '임시저장')}
              </Button>
              <Button
                css={IconButton.button}
                className="request primaryBtn"
                onClick={handleRequestSave}
              >
                {t('com.button.작성요청', '작성요청')}
              </Button>
            </>
          )}
      </GlobalBtnGroup>

      {isOpenContPopUp && (
        <UserModal
          open={isOpenContPopUp}
          close={() => setOpenContPopUp(false)}
          title={t('ut.label.Ut 작성 담당 조회', 'Ut 작성 담당 조회')}
          defaultUserId={userModalCondition?.defaultUserId}
          singleSelect={false}
          onCallback={(result) => {
            const users = (result || []) as Employee[];
            const target = userModalCondition?.target;
            console.log('----------' || users);
            if (target && target?.binding && target?.row > -1) {
              const contactId = users.map((o) => o.userId).join(',');
              const empNm = users.map((o) => o.empNm).join(',');
              const item = gridRef.current?.rows[target.row]?.dataItem;
              item[target.binding] = empNm;
              item[target.binding.replace('Nms', 'Ids')] = contactId;
              item['crudKey'] = CrudCode.UPDATE;
              gridRef.current?.invalidate();
            }
          }}
        />
      )}
      {/* 첨부파일팝업112 */}
      {isOpenFileUploadPopUp && (
        <FileUploadPopUp
          open={isOpenFileUploadPopUp}
          close={() => setOpenFileUploadPopUp(false)}
          downloadOnly={initParamFileUpload?.downloadOnly}
          initParam={{
            atchFileGrId: initParamFileUpload?.atchFileGrId,
            atchFileTpCd: initParamFileUpload?.atchFileTpCd,
            optValCtn1: initParamFileUpload?.tableName,
            bizName: initParamFileUpload?.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            setRowDataRequestView((prev) => ({
              ...prev,
              atchFileGrId: atchFileGrId || undefined,
              fileCount: fileCount.toString(),
            }));
            setOpenFileUploadPopUp(false);
          }}
        />
      )}
    </>
  );
};

export default UtMatrixRequestPage;
