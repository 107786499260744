import { ExcpUser, ExcpUserConditionSave } from '../../models/admin/ExcpUser';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from '../../models/common/RestApi';
import { callApi } from '../../utils/ApiUtil';

/**
 * 협력업체 사용자 가입 요청
 * @param condition
 */
export const registExcpUser = async (condition: ExcpUserConditionSave) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excpUser/regist',
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response;
};

/**
 * 협력업체 사용자 보안 서약서 동의 Update
 * @param condition
 */
export const setExcpUserSecrPldgY = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/excpUser/setSecrPldgY`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ userId: userId }),
  };
  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

/**
 * 협력업체 사용자 아이디 중복 여부 조회
 * @param condition
 */
export const checkExcpUserIdDup = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/excpUser/userIdCheck`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ userId: userId }),
  };
  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const getExcpMgrList = async (cmnGrCd: string, condition: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excpUser/excpMgrlist',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ cmnGrCd, ...condition }),
  };
  const response: CommonResponse<[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const getExcpUserInfo = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/excpUser/excpUserInfo`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ userId }),
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ExcpUser;
};

/**
 * 협력업체 사용자 수정
 * @param condition
 */
export const editExcpUser = async (condition: ExcpUserConditionSave) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excpUser/edit',
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response;
};

/**
 * 협력업체 사용자 비밀번호 초기화
 * @param condition
 */
export const resetExcpUserPwd = async (condition: ExcpUserConditionSave) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excpUser/resetPwd',
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response;
};

/**
 * 협력업체 사용자 아이디와 이름 매칭여부 체크
 * @param condition
 */
export const checkExcpUserIdNm = async (condition: ExcpUserConditionSave) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/excpUser/userIdNmCheck`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response;
};

/**
 * 협력업체 사용자 개인정보활용 동의 Update
 * @param condition
 */
export const setExcpUserIdvlIfoCncY = async (userId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/excpUser/setIdvlIfoCncY`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ userId: userId }),
  };
  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const getLatestSecrPldgDesc = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/getLatestSecrPldgDesc',
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};
