/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { Button } from '@mui/material';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { SubTitleLayout, SubTitleGroup, GlobalBtnGroup } from 'components/layouts/ContentLayout';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { tb } from 'components/layouts/Table';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { IconButton } from 'components/buttons/IconSVG';
import { NewSensorSolutionReq } from 'models/sensor/NewSensorSolutionReq';
import {
  findSensorSolution,
  createRequestInfo,
  saveAndProcessStatus,
  findSensorProgressStatusList,
  sendMail,
  generateSensorRequestAtchFileGrId,
} from 'apis/sensor/NewSensorSolutionReq';
import { SuccessOrNot } from 'models/common/RestApi';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { hasRole } from 'utils/SessionUtil';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import { FileuploadBtn } from 'components/buttons/CustomButton';
import useSessionStore from 'stores/useSessionStore';
import { SensorSolution } from 'models/sensor/SensorSolution';
import { useLoading } from 'components/process/Loading';
import { useLocation } from 'react-router';
import { ManagementMode } from 'models/common/Common';
import CustomTextarea from 'components/inputs/CustomTextarea';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';

interface LocationState {
  slunId?: string; // 솔루션ID
  isCopy: boolean; // 복사여부
}

interface PageLocationState {
  itemId?: string;
  mode?: ManagementMode;
  itemTpCd?: string;
  copCd?: string;
  plntCd?: string;
  lnId?: string;
}

const NewSensorSolutionReqPage = (props) => {
  const { t } = useTranslation();
  const { userId, empNm } = useSessionStore();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();

  const URLParams = new URLSearchParams(location.search);
  const slunId = URLParams.get('slunId');

  const condition: LocationState = useLocation().state;

  const [locationState, setLocationState] = useState<PageLocationState>(useLocation().state);
  const [snsrTab, setMpItem] = useState<SensorSolution>({});
  const [rqrId, setRqrId] = useState<string>();

  const [newSensorSolutionReqData, setNewSensorSolutionReqData] = useState<SensorSolution>({
    slunStatCd: 'DRAFT',
  });
  const [rowData, setRowData] = useState<NewSensorSolutionReq[]>([]); // 진행이력 조회 결과 담기
  const [errors, setErrors] = useState<any>({});

  const [hasAuthSnsrManager, setHasAuthSnsrManager] = useState<boolean>(false);
  const [hasAuthAdmin, setHasAuthAdmin] = useState<boolean>(false);

  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const inputRef = useRef<HTMLInputElement>(null);

  const menuContext = useContext<MenuContextType>(MenuContext);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const isReadonly = useMemo(
    () =>
      ['COMPLETE', 'REJECT', 'DROP'].includes(newSensorSolutionReqData?.slunStatCd || '') ||
      ('DRAFT' !== newSensorSolutionReqData?.slunStatCd && !(hasAuthAdmin || hasAuthSnsrManager)),
    [newSensorSolutionReqData?.slunStatCd, hasAuthAdmin, hasAuthSnsrManager, userId]
  );

  useEffect(() => {
    // Sensor MANAGER 권한체크
    if (hasRole('SNSR_MANAGER')) {
      setHasAuthSnsrManager(true);
    }
    // ADMIN 권한체크
    else if (hasRole('ADM')) {
      setHasAuthAdmin(true);
    }
    if (!condition) {
      setNewSensorSolutionReqData({});
      setRowData([]);
      createRequestInfo().then((res) => {
        setNewSensorSolutionReqData({
          slunId: res.slunId,
          slunStatCd: 'DRAFT',
          rqrUserId: userId,
          rqrUserNm: empNm,
        });
      });
    }
  }, []);

  const init = async (parameter) => {
    openLoading(true);
    const response = await findSensorSolution(slunId ?? '');
    openLoading(false);
    setNewSensorSolutionReqData({});
    setNewSensorSolutionReqData({
      // ...newSensorSolutionReqData,
      slunId: response.slunId,
      slunStatCd: response.slunStatCd,
      slunStatCdNm: response.slunStatCdNm,
      psgCtn: '', //response.psgCtn,
      misnNm: response.misnNm,
      procLocCtn: response.procLocCtn,
      prbPnmnCtn: response.prbPnmnCtn,
      reqPurpCtn: response.reqPurpCtn,
      dueDtm: response.dueDtm,
      rqrUserId: response.rqrUserId,
      rqrUserNm: response.rqrUserNm,
      snsrSpecCtn: response.snsrSpecCtn,
      curCrtnCtn: response.curCrtnCtn,
      golCrtnCtn: response.golCrtnCtn,
      epcEftCtn: response.epcEftCtn,
      iprvMesrCtn: response.iprvMesrCtn,
      rmk: response.iprvMesrCtn,
      atchFileGrId: response.atchFileGrId, // 첨부파일그룹ID
      atchFileCnt: response.atchFileCnt, // 첨부파일 수
    });
    retrieveProgressStatus({ ...newSensorSolutionReqData, slunId: slunId?.toString() }); // 진행이력
  };

  useEffect(() => {
    slunId && init(slunId);
  }, [slunId]);

  // 센서솔루션 클릭 또는 메뉴에서
  useEffect(() => {
    // location경로에 slun Id가 있으면 솔루션번호이동, slunId 제외한 나머지 값일땐, 복사, 아무 값도 없으면 신규생성
    if (condition?.slunId) {
      // 복사인 경우
      let newSlunId;
      if (condition?.isCopy) {
        // sequence &&
        setRowData([]);
        createRequestInfo().then((res) => {
          newSlunId = res?.slunId; // 시퀀스로 가져옴
          if (!newSlunId) {
            openMessageBar({
              type: 'error',
              content: t(
                'com.label.솔루션ID 생성에 실패했습니다.<br/>새로고침 후 다시 시도해 주세요.',
                '솔루션ID 생성에 실패했습니다.<br/>새로고침 후 다시 시도해 주세요.'
              ),
            });
            close();
            return;
          }
        });
      }

      // 솔루션 정보 조회
      findSensorSolution(condition?.slunId).then((result) => {
        const data = {
          ...result,
          // rqrUserId: userId,
          // rqrUserNm: empNm,
          psgCtn: condition?.isCopy
            ? String(t('snsr.msg.최초생성', '최초생성')) // 복사인 경우 최초생성
            : isReadonly
            ? result?.psgCtn // readOnly 상태인 경우 기존 처리내용 노출 (COMPLETE, REJECT, DROP)
            : '', // 처리내용 초기값
          slunId: condition?.isCopy ? newSlunId : result?.slunId, // 복사인 경우 생성된 솔루션ID 사용
          slunStatCd: condition?.isCopy ? 'DRAFT' : result?.slunStatCd, // 솔루션 상태
          rqrUserId: condition?.isCopy ? userId : result?.rqrUserId, // 요청자ID
          rqrUserNm: condition?.isCopy ? empNm : result.rqrUserNm, // 요청자명 (복사일때->세션에서, 솔루션 요청번호일때 : 솔루션 정보조회로 가져옴)
          slunStatCdNm: condition?.isCopy ? '' : result?.slunStatCdNm,
          atchFileGrId: condition?.isCopy ? '' : result?.atchFileGrId, // 첨부파일그룹ID
          atchFileCnt: condition?.isCopy ? 0 : result?.atchFileCnt, // 첨부파일 수
        }; // (위) 복사면 ? 복사값: 솔루션정보조회 해온 값
        setNewSensorSolutionReqData(data);
      });

      if (!condition?.isCopy) {
        // 진행이력 조회(복사 아니면)
        retrieveProgressStatus({
          // 진행이력 조회(id로) -> 조회시 솔루션요청현황 페이지 값 태우기
          ...newSensorSolutionReqData,
          slunId: condition.slunId,
        } as NewSensorSolutionReq); // slunId로 진행이력 조회 함수 실행
      }
    }
    // 신규 요청인 경우
    else {
      if (condition?.isCopy == false) {
        setRowData([]);
        createRequestInfo().then((res) => {
          setNewSensorSolutionReqData({
            slunId: res.slunId,
            slunStatCd: 'DRAFT',
            slunStatCdNm: '',
            misnNm: '',
            procLocCtn: '',
            prbPnmnCtn: '',
            reqPurpCtn: '',
            dueDtm: res.dueDtm,
            psgCtn: String(t('snsr.msg.최초생성', '최초생성')),
            snsrSpecCtn: '',
            curCrtnCtn: '',
            golCrtnCtn: '',
            epcEftCtn: '',
            iprvMesrCtn: '',
            rmk: '',
            atchFileGrId: '',
            useYn: '',
            dataInsUserId: '',
            dataInsUserNm: '',
            dataInsDtm: '',
            dataUpdUserId: '',
            dataUpdUserNm: '',
            dataUpdDtm: '',
            atchFileCnt: 0,
            rqrUserId: userId,
            rqrUserNm: empNm,
          });
        });
      }
    }
  }, [condition]);

  const btnReload = () => {
    setNewSensorSolutionReqData(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as SensorSolution
    );
  };

  const delBtnOnClick = (e: any) => {
    const t_name = e.currentTarget.parentElement.children[0].getAttribute('name');
    switch (t_name) {
      case 'misnNm':
        setNewSensorSolutionReqData({
          ...newSensorSolutionReqData,
          misnNm: e.target.value,
        });
        break;
      case 'procLocCtn':
        setNewSensorSolutionReqData({
          ...newSensorSolutionReqData,
          procLocCtn: e.target.value,
        });
        break;
      case 'psgCtn':
        setNewSensorSolutionReqData({
          ...newSensorSolutionReqData,
          psgCtn: e.target.value,
        });
        break;
    }

    const s_input = e.currentTarget.parentElement.children[0];
    const setValue = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(s_input), 'value')?.set;
    const event = new Event('input', { bubbles: true });
    setValue?.call(s_input, '');
    s_input.current?.dispatchEvent(event);
  };

  const validate = () => {
    const err = {};
    const msg = [] as string[];
    // 처리내용
    if (!newSensorSolutionReqData?.psgCtn) {
      Object.assign(err, { psgCtn: true });
    }
    // 과제명
    if (!newSensorSolutionReqData?.misnNm) {
      Object.assign(err, { misnNm: true });
    }
    // 공정위치
    if (!newSensorSolutionReqData?.procLocCtn) {
      Object.assign(err, { procLocCtn: true });
    }
    // 문제 및 현상 (textarea 에러문구 표시 영역 확인필요)
    if (!newSensorSolutionReqData?.prbPnmnCtn) {
      Object.assign(err, { prbPnmnCtn: true });
      // msg.push('문제 및 현상을 입력해 주세요.');
    }
    // 납기형식 (textarea 에러문구 표시 영역 확인필요)
    if (newSensorSolutionReqData?.dueDtm?.length != 10) {
      Object.assign(err, { reqPurpCtn: true });
      msg.push(t('snsr.msg.납기일자를 확인해 주세요.', '납기일자를 확인해 주세요.'));
    }

    if (msg.length) {
      openMessageBar({
        type: 'error',
        content: msg.join('<br/>'),
      });
    }

    setErrors(err);
    if (!Object.keys(err).filter((k) => err[k]).length) {
      return true;
    }

    return false;
  };

  const handleSave = (slunStatCd: string) => {
    if (validate()) {
      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
        yesCallback: () => {
          handleSaveAndProgressStatus({
            ...newSensorSolutionReqData,
            slunStatCd: slunStatCd,
          });
        },
      });
    }
  };

  const handleSaveAndProgressStatus = async (condition: NewSensorSolutionReq) => {
    openLoading(true);
    const response = await saveAndProcessStatus({ ...condition });
    console.log('response', response);
    console.log('response.data', response.data);
    if (response.successOrNot === SuccessOrNot.Y) {
      if (response.data != null || response.data != undefined) {
        openLoading(false);
        if (response.data == 'sendmailFail') {
          openMessageBar({
            type: 'error',
            content: t('com.label.메일 발송에 실패하였습니다.', '메일 발송에 실패하였습니다.'),
          });
        }
        if (response.data == 'saveFail') {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
        }
        if (response.data == 'saveSuccess') {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
        }
        if (response.data == 'developRequestSuccess') {
          openMessageBar({
            type: 'confirm',
            content: t('snsr.msg.개발 요청되었습니다.', '개발 요청되었습니다.'),
          });
        }
        if (response.data == 'developRequestFail') {
          openMessageBar({
            type: 'error',
            content: t('snsr.msg.개발 요청에 실패하였습니다.', '개발 요청에 실패하였습니다.'),
          });
        }
        if (response.data == 'receiptRequestFail') {
          openMessageBar({
            type: 'error',
            content: t('snsr.msg.접수 처리에 실패하였습니다.', '접수 처리에 실패하였습니다.'),
          });
        }
        if (response.data == 'receiptRequestSuccess') {
          openMessageBar({
            type: 'confirm',
            content: t('snsr.msg.접수 처리되었습니다.', '접수 처리되었습니다.'),
          });
        }
        if (response.data == 'rejectRequestFail') {
          openMessageBar({
            type: 'error',
            content: t('snsr.msg.반려 처리에 실패하였습니다.', '반려 처리에 실패하였습니다.'),
          });
        }
        if (response.data == 'rejectRequestSuccess') {
          openMessageBar({
            type: 'confirm',
            content: t('snsr.msg.반려 처리되었습니다.', '반려 처리되었습니다.'),
          });
        }
        if (response.data == 'registerRequestFail') {
          openMessageBar({
            type: 'error',
            content: t('snsr.msg.등록 처리에 실패하였습니다.', '등록 처리에 실패하였습니다.'),
          });
        }
        if (response.data == 'registerRequestSuccess') {
          openMessageBar({
            type: 'confirm',
            content: t('snsr.msg.등록 처리되었습니다.', '등록 처리되었습니다.'),
          });
        }
        if (response.data == 'developingRequestFail') {
          openMessageBar({
            type: 'error',
            content: t('snsr.msg.개발중 처리에 실패하였습니다.', '개발중 처리에 실패하였습니다.'),
          });
        }
        if (response.data == 'developingRequestSuccess') {
          openMessageBar({
            type: 'confirm',
            content: t('snsr.msg.개발중 처리되었습니다.', '개발중 처리되었습니다.'),
          });
        }
        if (response.data == 'completeRequestFail') {
          openMessageBar({
            type: 'error',
            content: t('snsr.msg.완료 처리에 실패하였습니다.', '완료 처리에 실패하였습니다.'),
          });
        }
        if (response.data == 'completeRequestSuccess') {
          openMessageBar({
            type: 'confirm',
            content: t('snsr.msg.완료 처리되었습니다.', '완료 처리되었습니다.'),
          });
        }
        if (response.data == 'dropRequestFail') {
          openMessageBar({
            type: 'error',
            content: t(
              'snsr.msg.개발중단 처리에 실패하였습니다.',
              '개발중단 처리에 실패하였습니다.'
            ),
          });
        }
        if (response.data == 'dropRequestSuccess') {
          openMessageBar({
            type: 'confirm',
            content: t('snsr.msg.개발중단 처리되었습니다.', '개발중단 처리되었습니다.'),
          });
        }

        // btnReload();
        // 요청후 재 조회
        const refindCondition = condition.slunId;
        const refindResult = await findSensorSolution(refindCondition ?? '');
        setNewSensorSolutionReqData({});
        setNewSensorSolutionReqData({
          ...newSensorSolutionReqData,
          slunId: refindResult.slunId,
          slunStatCd: refindResult.slunStatCd,
          slunStatCdNm: refindResult.slunStatCdNm,
          psgCtn: refindResult.psgCtn,
          misnNm: refindResult.misnNm,
          procLocCtn: refindResult.procLocCtn,
          prbPnmnCtn: refindResult.prbPnmnCtn,
          reqPurpCtn: refindResult.reqPurpCtn,
          dueDtm: refindResult.dueDtm,
          snsrSpecCtn: refindResult.snsrSpecCtn,
          curCrtnCtn: refindResult.curCrtnCtn,
          golCrtnCtn: refindResult.golCrtnCtn,
          epcEftCtn: refindResult.epcEftCtn,
          iprvMesrCtn: refindResult.iprvMesrCtn,
          rmk: refindResult.iprvMesrCtn,
        });
      }
      // 진행이력 재조회
      const res = await findSensorProgressStatusList({ ...condition });
      const rowData = res.data || []; //list
      setRowData(rowData);
    } else if (response.successOrNot === SuccessOrNot.N) {
      openLoading(false);
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.처리 실패했습니다.<br/>새로고침 후 다시 시도해 주세요.',
          '처리 실패했습니다.<br/>새로고침 후 다시 시도해 주세요.'
        ),
      });
      return;
    }
  };

  const handleSendMail = async (condition: NewSensorSolutionReq) => {
    const response = await sendMail({ ...condition });
    if (response.successOrNot === SuccessOrNot.Y) {
      if (response.data != null || response.data != undefined) {
        if (response.data == 'sendmailSuccess') {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.메일 발송에 성공하였습니다.', '메일 발송에 성공하였습니다.'),
            // yesCallback: () => close(),
          });
        } else {
          //(response.data == 'sendmailFail') {
          openMessageBar({
            type: 'error',
            content: t('com.label.메일 발송에 실패하였습니다.', '메일 발송에 실패하였습니다.'),
            // yesCallback: () => close(),
          });
        }
      }
    }
  };

  const retrieveProgressStatus = async (condition: NewSensorSolutionReq) => {
    const response = await findSensorProgressStatusList({ ...condition });
    if (response) {
      const rowData = response.data || []; //list
      setRowData(rowData);
    }
  };

  const handleFileUpload = async (e) => {
    let atchFileGrId = newSensorSolutionReqData.atchFileGrId; // cell.item.atchFileGrId;
    if (_.isEmpty(atchFileGrId)) {
      atchFileGrId = await generateSensorRequestAtchFileGrId(
        newSensorSolutionReqData?.slunId || ''
      );
      if (atchFileGrId) {
        setNewSensorSolutionReqData({
          ...newSensorSolutionReqData,
          atchFileGrId: atchFileGrId,
        });
      } else {
        openMessageBar({
          type: 'error',
          content:
            t(
              'com.label.첨부파일그룹ID 생성에 실패했습니다.',
              '첨부파일그룹ID 생성에 실패했습니다.'
            ) +
            <br /> +
            t('com.label.새로고침 후 다시 시도해 주세요.', '새로고침 후 다시 시도해 주세요.'),
        });
        return;
      }
    }
    setFileUploadModalCondition({
      atchFileGrId: atchFileGrId,
      atchFileTpCd: 'NORMAL',
      optValCtn1: 'TB_ELM_SNSR_REQ_N',
      bizName: 'snsr',
    });
    setOpenFileUploadModal(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewSensorSolutionReqData({ ...newSensorSolutionReqData, [e.target.name]: e.target.value });
    setErrors((prev) => ({
      ...prev,
      [e.target.name]: false,
    }));
  };

  return (
    <>
      <TableContainer css={tb.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('snsr.label.솔루션 요청번호', '솔루션 요청번호')}</TableCell>
              <TableCell>{newSensorSolutionReqData?.slunId}</TableCell>
              <TableCell>{t('com.label.진행상태', '진행상태')}</TableCell>
              <TableCell>
                {newSensorSolutionReqData?.slunStatCdNm || t('snsr.label.초안작성', '초안작성')}
              </TableCell>
              <TableCell>
                <span className="dot">{t('snsr.label.처리내용', '처리내용')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="psgCtn"
                  placeholder={String(
                    t('snsr.msg.처리내용을 입력해 주세요.', '처리내용을 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqData?.psgCtn}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 1000) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 1000자를 넘을 수 없습니다.',
                          '양식 설명은 1000자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                  isError={errors?.psgCtn}
                  msgError={String(
                    t('snsr.msg.처리내용을 입력해 주세요.', '처리내용을 입력해 주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer css={tb.table} style={{ marginTop: '8px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{t('snsr.label.과제명', '과제명')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="misnNm"
                  placeholder={String(
                    t('snsr.msg.과제명을 입력해 주세요.', '과제명을 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqData?.misnNm}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 100) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 100자를 넘을 수 없습니다.',
                          '양식 설명은 100자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                  isError={errors?.misnNm}
                  msgError={String(
                    t('snsr.msg.과제명을 입력해 주세요.', '과제명을 입력해 주세요.')
                  )}
                />
              </TableCell>
              <TableCell>
                <span className="dot">
                  {t('snsr.label.공정위치(Assy 단위까지)', '공정위치(Assy 단위까지)')}
                </span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="procLocCtn"
                  placeholder={String(
                    t('snsr.msg.공정위치를 입력해 주세요.', '공정위치를 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqData?.procLocCtn}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 1000) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 1000자를 넘을 수 없습니다.',
                          '양식 설명은 1000자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                  isError={errors?.procLocCtn}
                  msgError={String(
                    t('snsr.msg.공정위치를 입력해 주세요.', '공정위치를 입력해 주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('snsr.label.문제 및 현상', '문제 및 현상')}</span>
              </TableCell>
              <TableCell>
                <CustomTextarea
                  name="prbPnmnCtn"
                  placeholder={String(
                    t('snsr.msg.문제 및 현상을 입력해 주세요.', '문제 및 현상을 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqData.prbPnmnCtn}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 1000) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 1000자를 넘을 수 없습니다.',
                          '양식 설명은 1000자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                  isError={errors?.prbPnmnCtn}
                  msgError={String(
                    t('snsr.msg.문제 및 현상을 입력해 주세요.', '문제 및 현상을 입력해 주세요.')
                  )}
                ></CustomTextarea>
              </TableCell>
              <TableCell>
                <span>{t('snsr.label.요청목적', '요청목적')}</span>
              </TableCell>
              <TableCell>
                <CustomTextarea
                  name="reqPurpCtn"
                  placeholder={String(
                    t('snsr.msg.요청목적을 입력해 주세요.', '요청목적을 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqData.reqPurpCtn}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 1000) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 1000자를 넘을 수 없습니다.',
                          '양식 설명은 1000자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                  isError={errors?.reqPurpCtn}
                  msgError={String(
                    t('snsr.msg.요청목적을 입력해 주세요.', '요청목적을 입력해 주세요.')
                  )}
                ></CustomTextarea>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('snsr.label.납기', '납기')}</span>
              </TableCell>
              <TableCell>
                <DatePickerWrap className="single">
                  {isReadonly ? (
                    newSensorSolutionReqData?.dueDtm
                  ) : (
                    <Datepicker
                      value={{
                        startDate: newSensorSolutionReqData?.dueDtm ?? null,
                        endDate: newSensorSolutionReqData?.dueDtm ?? null,
                      }}
                      onChange={(newValue) => {
                        setNewSensorSolutionReqData({
                          ...newSensorSolutionReqData,
                          dueDtm: String(newValue?.startDate || null),
                        });
                        // startDate: dayjs().format('YYYY').toString(),
                      }}
                      displayFormat={'YYYY.MM.DD'}
                      minDate={new Date()}
                      i18n={'ko'}
                      inputClassName="datepickerInput"
                      asSingle={true}
                      disabled={isReadonly}
                    />
                  )}
                </DatePickerWrap>
              </TableCell>
              <TableCell>{t('com.label.요청자', '요청자')}</TableCell>
              <TableCell>{newSensorSolutionReqData?.rqrUserNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}>
                {t('snsr.label.측정대상 및 필요 Spec', '측정대상 및 필요 Spec')}
              </TableCell>
              <TableCell rowSpan={2}>
                <CustomTextarea
                  style={{ height: '120px' }}
                  name="snsrSpecCtn"
                  placeholder={String(
                    t(
                      'snsr.msg.측정대상 및 필요 Spec을 입력해 주세요.',
                      '측정대상 및 필요 Spec을 입력해 주세요.'
                    )
                  )}
                  value={newSensorSolutionReqData.snsrSpecCtn}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 1000) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 1000자를 넘을 수 없습니다.',
                          '양식 설명은 1000자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                />
              </TableCell>
              <TableCell>{t('snsr.label.현재기준', '현재기준')}</TableCell>
              <TableCell>
                <CustomTextarea
                  name="curCrtnCtn"
                  placeholder={String(
                    t('snsr.msg.현재기준을 입력해 주세요.', '현재기준을 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqData.curCrtnCtn}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 100) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 100자를 넘을 수 없습니다.',
                          '양식 설명은 100자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('snsr.label.목표기준', '목표기준')}</TableCell>
              <TableCell>
                <CustomTextarea
                  name="golCrtnCtn"
                  placeholder={String(
                    t('snsr.msg.목표기준을 입력해 주세요.', '목표기준을 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqData.golCrtnCtn}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 100) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 100자를 넘을 수 없습니다.',
                          '양식 설명은 100자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('snsr.label.기대효과', '기대효과')}</TableCell>
              <TableCell colSpan={3}>
                <CustomTextarea
                  name="epcEftCtn"
                  placeholder={String(
                    t('snsr.msg.기대효과를 입력해 주세요.', '기대효과를 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqData.epcEftCtn}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 1000) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 1000자를 넘을 수 없습니다.',
                          '양식 설명은 1000자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('com.label.첨부', '첨부')}</TableCell>
              <TableCell colSpan={3}>
                <FileuploadBtn onClick={(e) => handleFileUpload(e)}>
                  <span>{(newSensorSolutionReqData?.atchFileCnt || 0).toLocaleString()}</span>
                </FileuploadBtn>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('snsr.label.개선방향', '개선방향')}</TableCell>
              <TableCell colSpan={3}>
                <CustomTextarea
                  name="iprvMesrCtn"
                  placeholder={String(
                    t('snsr.msg.개선방향을 입력해 주세요.', '개선방향을 입력해 주세요.')
                  )}
                  value={newSensorSolutionReqData.iprvMesrCtn}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 1000) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 1000자를 넘을 수 없습니다.',
                          '양식 설명은 1000자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('com.label.비고', '비고')}</TableCell>
              <TableCell colSpan={3}>
                <CustomTextarea
                  name="rmk"
                  placeholder={String(t('snsr.msg.비고를 입력해 주세요.', '비고를 입력해 주세요.'))}
                  value={newSensorSolutionReqData.rmk}
                  onChange={(event: any) => {
                    const value = event?.target.value;
                    if (value.length <= 1000) {
                      handleChange(event);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content: t(
                          'snsr.msg.양식 설명은 1000자를 넘을 수 없습니다.',
                          '양식 설명은 1000자를 넘을 수 없습니다.'
                        ),
                      });
                    }
                  }}
                  readOnly={isReadonly}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <GlobalBtnGroup>
        {/* 권한 상관없이 DRAFT 상태인 경우 저장, 개발요청, 닫기 */}
        {newSensorSolutionReqData?.slunStatCd === 'DRAFT' ? (
          <>
            {
              // (!hasAuthAdmin || !hasAuthSnsrManager) &&
              userId != newSensorSolutionReqData.rqrUserId ? (
                ''
              ) : (
                <Button
                  // disabled={}
                  // 수정사항
                  css={IconButton.button}
                  className="save"
                  onClick={() => {
                    if (validate()) {
                      openCommonModal({
                        modalType: 'confirm',
                        content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
                        yesCallback: () => {
                          handleSaveAndProgressStatus({
                            ...newSensorSolutionReqData,
                            slunStatCd: 'DRAFT',
                          });
                        },
                      });
                    }
                  }}
                >
                  {t('com.button.저장', '저장')}
                </Button>
              )
            }
            {userId != newSensorSolutionReqData.rqrUserId ? (
              ''
            ) : (
              <Button
                css={IconButton.button}
                className="request"
                onClick={() => {
                  if (validate()) {
                    openCommonModal({
                      modalType: 'confirm',
                      content: t(
                        'snsr.msg.개발요청 상태로 변경하시겠습니까?',
                        '개발요청 상태로 변경하시겠습니까?'
                      ),
                      yesCallback: () => {
                        handleSaveAndProgressStatus({
                          ...newSensorSolutionReqData,
                          slunStatCd: 'REQUEST',
                          dataUpdUserId: userId, // 개발요청시 요청하는 사람이 진행이력에 남기
                          dataUpdUserNm: empNm,
                        });
                      },
                    });
                  }
                }}
              >
                {t('snsr.button.개발요청', '개발요청')}
              </Button>
            )}
          </>
        ) : (
          <>
            {(hasAuthSnsrManager || hasAuthAdmin) && (
              <>
                {/* REJECT, COMPLETE, DROP 제외 메일발송, 저장 버튼 노출 */}
                {!isReadonly && (
                  <>
                    <Button
                      css={IconButton.button}
                      className="mail"
                      onClick={() => {
                        openCommonModal({
                          modalType: 'confirm',
                          content: t('com.label.메일발송 하시겠습니까?', '메일발송 하시겠습니까?'),
                          yesCallback: () => {
                            handleSendMail({
                              ...newSensorSolutionReqData,
                              slunId: newSensorSolutionReqData.slunId,
                            });
                          },
                        });
                      }}
                    >
                      {t('com.button.메일발송', '메일발송')}
                    </Button>
                    <Button
                      css={IconButton.button}
                      className="save"
                      onClick={() => {
                        if (validate()) {
                          openCommonModal({
                            modalType: 'confirm',
                            content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
                            yesCallback: () => {
                              handleSaveAndProgressStatus({
                                ...newSensorSolutionReqData,
                                slunStatCd: 'DRAFT',
                              });
                            },
                          });
                        }
                      }}
                    >
                      {t('com.button.저장', '저장')}
                    </Button>
                  </>
                )}
                {/* REQUEST - 접수, 반려 */}
                {newSensorSolutionReqData?.slunStatCd === 'REQUEST' && (
                  <>
                    <Button
                      css={IconButton.button}
                      className="reception"
                      onClick={() => {
                        if (validate()) {
                          openCommonModal({
                            modalType: 'confirm',
                            content: t(
                              'snsr.msg.접수 상태로 변경하시겠습니까?',
                              '접수 상태로 변경하시겠습니까?'
                            ),
                            yesCallback: () => {
                              handleSaveAndProgressStatus({
                                ...newSensorSolutionReqData,
                                slunStatCd: 'RECEIPT',
                                dataUpdUserId: userId,
                                dataUpdUserNm: empNm,
                              });
                            },
                          });
                        }
                      }}
                    >
                      {t('snsr.button.접수', '접수')}
                    </Button>
                    <Button
                      css={IconButton.button}
                      className="cancel"
                      onClick={() => {
                        if (validate()) {
                          openCommonModal({
                            modalType: 'confirm',
                            content: t(
                              'snsr.msg.반려 상태로 변경하시겠습니까?',
                              '반려 상태로 변경하시겠습니까?'
                            ),
                            yesCallback: () => {
                              handleSaveAndProgressStatus({
                                ...newSensorSolutionReqData,
                                slunStatCd: 'REJECT',
                                dataUpdUserId: userId,
                                dataUpdUserNm: empNm,
                              });
                            },
                          });
                        }
                      }}
                    >
                      {t('com.button.반려', '반려')}
                    </Button>
                  </>
                )}
                {/* RECEIPT - 등록 */}
                {newSensorSolutionReqData?.slunStatCd === 'RECEIPT' && (
                  <Button
                    css={IconButton.button}
                    className="regist"
                    onClick={() => {
                      if (validate()) {
                        openCommonModal({
                          modalType: 'confirm',
                          content: t(
                            'snsr.msg.등록 상태로 변경하시겠습니까?',
                            '등록 상태로 변경하시겠습니까?'
                          ),
                          yesCallback: () => {
                            handleSaveAndProgressStatus({
                              ...newSensorSolutionReqData,
                              slunStatCd: 'REGISTER',
                              dataUpdUserId: userId,
                              dataUpdUserNm: empNm,
                            });
                          },
                        });
                      }
                    }}
                  >
                    {t('snsr.button.등록', '등록')}
                  </Button>
                )}
                {/* REGISTER - 개발중 */}
                {newSensorSolutionReqData?.slunStatCd === 'REGISTER' && (
                  <Button
                    css={IconButton.button}
                    className="develop"
                    onClick={() => {
                      if (validate()) {
                        openCommonModal({
                          modalType: 'confirm',
                          content: t(
                            'snsr.msg.개발중 상태로 변경하시겠습니까?',
                            '개발중 상태로 변경하시겠습니까?'
                          ),
                          yesCallback: () => {
                            handleSaveAndProgressStatus({
                              ...newSensorSolutionReqData,
                              slunStatCd: 'DEVELOPING',
                              dataUpdUserId: userId,
                              dataUpdUserNm: empNm,
                            });
                          },
                        });
                      }
                    }}
                  >
                    {t('snsr.button.개발중', '개발중')}
                  </Button>
                )}
                {/* RECEIPT, REGISTER, DEVELOPING - 개발중단 */}
                {['RECEIPT', 'REGISTER', 'DEVELOPING'].includes(
                  newSensorSolutionReqData?.slunStatCd || ''
                ) && (
                  <Button
                    css={IconButton.button}
                    className="developstop"
                    onClick={() => {
                      if (validate()) {
                        openCommonModal({
                          modalType: 'confirm',
                          content: t(
                            'snsr.msg.개발중단 상태로 변경하시겠습니까?',
                            '개발중단 상태로 변경하시겠습니까?'
                          ),
                          yesCallback: () => {
                            handleSaveAndProgressStatus({
                              ...newSensorSolutionReqData,
                              slunStatCd: 'DROP',
                              dataUpdUserId: userId,
                              dataUpdUserNm: empNm,
                            });
                          },
                        });
                      }
                    }}
                  >
                    {t('snsr.button.개발중단', '개발중단')}
                  </Button>
                )}
                {newSensorSolutionReqData?.slunStatCd === 'DEVELOPING' && (
                  <Button
                    css={IconButton.button}
                    className="confirm"
                    onClick={() => {
                      if (validate()) {
                        openCommonModal({
                          modalType: 'confirm',
                          content: t(
                            'snsr.msg.완료 상태로 변경하시겠습니까?',
                            '완료 상태로 변경하시겠습니까?'
                          ),
                          yesCallback: () => {
                            handleSaveAndProgressStatus({
                              ...newSensorSolutionReqData,
                              slunStatCd: 'COMPLETE',
                              dataUpdUserId: userId,
                              dataUpdUserNm: empNm,
                            });
                          },
                        });
                      }
                    }}
                  >
                    {t('com.button.완료', '완료')}
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </GlobalBtnGroup>
      {(rowData || []).length > 0 && (
        <>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('snsr.label.진행이력', '진행이력')}</h3>
            </SubTitleGroup>
          </SubTitleLayout>
          <TableContainer css={tb.tableCol}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '15%' }}>
                    {t('com.label.진행상태', '진행상태')}
                  </TableCell>
                  <TableCell style={{ width: '55%' }}>
                    {t('snsr.label.진행이력', '진행이력')}
                  </TableCell>
                  <TableCell style={{ width: '15%' }}>{t('snsr.label.처리자', '처리자')}</TableCell>
                  <TableCell style={{ width: '15%' }}>
                    {t('snsr.label.처리일시', '처리일시')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((key, i) => (
                  <TableRow key={i}>
                    <TableCell>{key.slunStatus}</TableCell>
                    <TableCell style={{ textAlign: 'left' }}>{key.psgCtn}</TableCell>
                    <TableCell>{key.dataUpdUserNm}</TableCell>
                    <TableCell>{key.dataInsDtm}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          downloadOnly={fileUploadModalCondition.downloadOnly}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.optValCtn1,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            setNewSensorSolutionReqData({
              ...newSensorSolutionReqData,
              atchFileCnt: fileCount,
            });
            setOpenFileUploadModal(false);
          }}
        />
      )}
    </>
  );
};

export default React.memo(NewSensorSolutionReqPage);
