/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { BlueButton, GreyButton, GreyLineButton } from 'components/buttons/CustomButton';
import { CustomInputText, CustomInputWrap, CustomTextarea } from 'components/inputs/CustomInput';
import { CustomSelect } from 'components/selects/CustomSelect';
import { Button, FormControl } from '@mui/material';
import { ViewTable } from 'components/tables/ViewTable';
import {
  ContentSection,
  GridButtons,
  GridInfo,
  GridInfoSection,
} from 'components/layouts/ContentSection';
//
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { ContentFlex } from 'components/layouts/ContentFlex';
import ApprovalTemplateManagementGrid from 'pages/admin/approvaltemplatemanagement/ApprovalTemplateManagementGrid';
import { ApprovalTemplate } from 'models/admin/ApprovalTemplate';
import { CommonYN } from 'models/common/Common';
import {
  createApprovalTemplate,
  getApprovalTemplate,
  modifyApprovalTemplate,
} from 'apis/admin/Approval';
import CrossEditor from 'components/crosseditor/CrossEditor';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { useCommonModal } from 'hooks/useCommonModal';
import { Code } from 'models/common/CommonCode';
import { CrudCode } from 'models/common/Edit';
import { useTranslation } from 'react-i18next';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from '../../components/layouts/ContentLayout';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { IconButton } from 'components/buttons/IconSVG';

export const ApprovalTemplateManagementPage = () => {
  const { t } = useTranslation();
  const prsDescEditorRef = useRef<CrossEditor>(null);
  const wcstDescEditorRef = useRef<CrossEditor>(null);
  const [aprTplSearchParamData, setAprTplSearchParamData] = useState<string>('');
  const [aprTplNmSearchParamData, setAprTplNmSearchParamData] = useState<string>('');
  let editorLoadCount = 0;
  const [aprTplInfoData, setAprTplInfoData] = useState<ApprovalTemplate>({
    aprTplId: '',
    intgAprTpCd: '',
    aprTplNm: '',
    sortOrd: 0,
    aprTplDesc: '',
    useYn: 'Y',
    prsDesc: '',
    wcstUseYn: 'Y',
    wcstDesc: '',
    notfUseYn: 'Y',
    ntdkId: 999,
    mgrUseYn: 'Y',
    crudKey: CrudCode.CREATE,
  });
  const [rowData, setRowData] = useState<ApprovalTemplate[]>([]);
  const [selectIntgAprTpCd, setSelectIntgAprTpCd] = useState<Code[]>([]);
  const { openCommonModal } = useCommonModal();

  const newRow: ApprovalTemplate = {
    aprTplId: '',
    intgAprTpCd: selectIntgAprTpCd[0]?.cmnCdNm ?? 'TYPE2',
    aprTplNm: '',
    sortOrd: 0,
    aprTplDesc: '',
    useYn: 'Y',
    prsDesc: '',
    wcstUseYn: 'Y',
    wcstDesc: '',
    notfUseYn: 'Y',
    ntdkId: 999, //TODO : ntdkId는 화면에서 입력받지 않는데 어떻게 정해지나?
    mgrUseYn: 'Y',
    crudKey: CrudCode.CREATE,
  };

  const handleEditorOnLoad = async () => {
    if (editorLoadCount === 0) editorLoadCount = 1;
    else {
      editorLoadCount = 2;
      fnSearchApprovalTemplate(aprTplSearchParamData, true);
    }
  };

  const fnSearchApprovalTemplate = async (params: string, flag: boolean) => {
    await getApprovalTemplate(params).then((result: ApprovalTemplate[]) => {
      setRowData(result);
      if (result && flag) {
        setAprTplInfoData(result[0]);
        prsDescEditorRef.current?.editorRef.SetBodyValue(result[0].prsDesc ?? '');
        wcstDescEditorRef.current?.editorRef.SetBodyValue(result[0].wcstDesc ?? '');
      }
    });
  };

  const listener = (param: ApprovalTemplate) => {
    prsDescEditorRef.current?.editorRef.SetBodyValue(param.prsDesc ?? '');
    wcstDescEditorRef.current?.editorRef.SetBodyValue(param.wcstDesc ?? '');
    setAprTplInfoData({ ...param, crudKey: CrudCode.READ });
  };

  const handleSearchParamData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAprTplNmSearchParamData(e.target.value);
  };

  const btnSearch = () => {
    setAprTplSearchParamData(aprTplNmSearchParamData);
    fnSearchApprovalTemplate(aprTplNmSearchParamData, true);
  };

  const btnNew = () => {
    const newData = rowData.slice();
    newData.splice(0, 0, newRow);
    setRowData(newData);
    setAprTplInfoData(newRow);
    prsDescEditorRef.current?.editorRef.SetBodyValue(newRow.prsDesc ?? '');
    wcstDescEditorRef.current?.editorRef.SetBodyValue(newRow.wcstDesc ?? '');
  };

  const saveValidation = (saveAprTplInfoData) => {
    if (saveAprTplInfoData?.crudKey === CrudCode.READ) {
      openCommonModal({
        content: t('admin.msg.수정 내역이 없습니다.', '수정 내역이 없습니다.'),
      });
      return false;
    }

    if (!saveAprTplInfoData?.aprTplId) {
      openCommonModal({
        content: t('admin.msg.양식 ID를 입력해주세요.', '양식 ID를 입력해주세요.'),
      });
      return false;
    }

    if (!saveAprTplInfoData?.aprTplNm) {
      openCommonModal({
        content: t('admin.msg.양식명을 입력해주세요.', '양식명을 입력해주세요.'),
      });
      return false;
    }

    if (!saveAprTplInfoData?.aprTplDesc) {
      openCommonModal({
        content: t('admin.msg.양식설명을 입력해주세요.', '양식설명을 입력해주세요.'),
      });
      return false;
    }

    if (
      saveAprTplInfoData?.crudKey === '' ||
      saveAprTplInfoData?.crudKey === null ||
      saveAprTplInfoData?.crudKey === undefined
    ) {
      openCommonModal({
        content: t(
          'admin.msg.저장에 실패하였습니다. 입력값을 확인하세요.',
          '저장에 실패하였습니다. 입력값을 확인하세요.'
        ),
      });
      return false;
    }
    return true;
  };

  const checkNamoModified = () => {
    if (
      prsDescEditorRef.current?.editorRef.IsDirty() ||
      wcstDescEditorRef.current?.editorRef.IsDirty()
    ) {
      aprTplInfoData.crudKey =
        aprTplInfoData.crudKey === CrudCode.READ ? CrudCode.UPDATE : aprTplInfoData.crudKey;
    }
  };

  const btnSave = async () => {
    checkNamoModified();
    const saveAprTplInfoData = aprTplInfoData;
    saveAprTplInfoData.prsDesc = prsDescEditorRef.current?.editorRef.GetBodyValue();
    saveAprTplInfoData.wcstDesc = wcstDescEditorRef.current?.editorRef.GetBodyValue();

    if (!saveValidation(saveAprTplInfoData)) return;

    let result: any = null;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        if (saveAprTplInfoData?.crudKey === CrudCode.CREATE) {
          result = await createApprovalTemplate(saveAprTplInfoData);
        }
        if (saveAprTplInfoData?.crudKey === CrudCode.UPDATE) {
          result = await modifyApprovalTemplate(saveAprTplInfoData);
        }
        if (result) {
          openCommonModal({
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          fnSearchApprovalTemplate('', false);
          prsDescEditorRef.current?.editorRef.SetDirty();
          wcstDescEditorRef.current?.editorRef.SetDirty();
          setAprTplInfoData({ ...aprTplInfoData, crudKey: CrudCode.READ });
        } else {
          openCommonModal({
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
        }
      },
    });
  };

  const handleAprTplId = (event: any) => {
    const value = event?.target.value;
    const regex = /^[A-Za-z0-9-]*$/;
    if (value.length <= 13 && regex.test(value)) {
      handleChange(event);
    } else {
      openCommonModal({
        content: t(
          'admin.msg.양식 ID 규칙에 위배됩니다.(영문 및 - 만 입력가능, 13글자 이하)',
          '양식 ID 규칙에 위배됩니다.(영문 및 - 만 입력가능, 13글자 이하)'
        ),
      });
    }
  };

  const handleChange = (event: any) => {
    setAprTplInfoData((prevData) => {
      return {
        ...prevData,
        [event?.target.name]: event?.target.value,
        crudKey: prevData.crudKey === CrudCode.CREATE ? CrudCode.CREATE : CrudCode.UPDATE,
      };
    });
  };

  useEffect(() => {
    const getTplCdData = async () => {
      try {
        const intgAprTpCdData = await getCommonCodeNames('INTG_APR_TP_CD');
        setSelectIntgAprTpCd(intgAprTpCdData);
      } catch (error) {
        openCommonModal({
          content: t(
            'admin.msg.공통코드를 불러오는데 실패했습니다.',
            '공통코드를 불러오는데 실패했습니다.'
          ),
        });
      }
    };
    getTplCdData();
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      //fnSearchApprovalTemplate(aprTplNmSearchParamData, true);
      btnSearch();
    }
  };

  const btnReload = () => {
    setAprTplNmSearchParamData('');
  };

  // <Button className="buttonFind" disableRipple></Button>
  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('admin.label.양식명', '양식명')}</label>
                <CustomInputWithSearch
                  onChange={handleSearchParamData}
                  onKeyDown={handleKeyDown}
                  placeholder={String(
                    t('admin.label.양식명을 입력해 주세요.', '양식명을 입력해 주세요.')
                  )}
                  name="ntdkNm"
                  value={aprTplNmSearchParamData}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button css={IconButton.button} className="reload" disableRipple onClick={btnReload} />
            <Button css={IconButton.button} className="find" disableRipple onClick={btnSearch}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>

      <ContentFlex>
        <ContentSection className="section width50p marginT0">
          <ApprovalTemplateManagementGrid
            aprTplNm={aprTplSearchParamData}
            rowData={rowData}
            callBackRowData={listener}
            editable={false}
          />
          <GlobalBtnGroup>
            <Button css={IconButton.button} className="write" disableRipple onClick={btnNew}>
              {t('com.button.신규', '신규')}
            </Button>
          </GlobalBtnGroup>
        </ContentSection>
        <ContentSection className="section width50p">
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('admin.label.결재양식 기본정보', '결재양식 기본정보')}</h3>
            </SubTitleGroup>
          </SubTitleLayout>
          <FormControl fullWidth>
            <ViewTable>
              <colgroup>
                <col width="20%" />
                <col />
                <col width="20%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.양식ID', '양식ID')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      name="aprTplId"
                      value={aprTplInfoData?.aprTplId}
                      onChange={handleAprTplId}
                      readOnly={aprTplInfoData?.crudKey === CrudCode.CREATE ? false : true}
                      maxLength={13}
                    />
                  </td>
                  <th scope="row">
                    <label>{t('admin.label.전자결재유형', '전자결재유형')}</label>
                  </th>
                  <td>
                    <CustomSelect
                      className="fullWidth"
                      name="intgAprTpCd"
                      value={aprTplInfoData?.intgAprTpCd}
                      onChange={handleChange}
                    >
                      {[
                        ...selectIntgAprTpCd.map((item) => (
                          <option key={item.cmnCd} value={item.cmnCd}>
                            {`${item.cmnCd?.charAt(0)}${item.cmnCd
                              ?.slice(1, item.cmnCd?.length)
                              .toLowerCase()}`}
                          </option>
                        )),
                      ]}
                    </CustomSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.양식명', '양식명')}</label>
                  </th>
                  <td colSpan={3}>
                    <CustomInputText
                      name="aprTplNm"
                      value={aprTplInfoData?.aprTplNm}
                      className="fullWidth"
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.정렬 순서', '정렬 순서')}</label>
                  </th>
                  <td>
                    <CustomInputText
                      name="sortOrd"
                      value={aprTplInfoData?.sortOrd}
                      type="number"
                      onChange={(event: any) => {
                        const value = event?.target.value;
                        const regex = /^[0-9]*$/;
                        if (value < 0 && !regex.test(value)) {
                          openCommonModal({
                            content: t(
                              'admin.msg.정렬 순서는 음수일 수 없습니다.',
                              '정렬 순서는 음수일 수 없습니다.'
                            ),
                          });
                          return;
                        }
                        handleChange(event);
                      }}
                    />
                  </td>
                  <th scope="row">
                    <label>{t('com.label.사용여부', '사용여부')}</label>
                  </th>
                  <td>
                    <CustomSelect
                      className="fullWidth"
                      name="useYn"
                      value={aprTplInfoData?.useYn}
                      onChange={handleChange}
                    >
                      <option value={CommonYN.Y}>{t('com.label.사용', '사용')}</option>
                      <option value={CommonYN.N}>{t('com.label.사용안함', '사용안함')}</option>
                    </CustomSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.양식설명', '양식설명')}</label>
                  </th>
                  <td colSpan={3}>
                    <CustomTextarea
                      className="fullWidth"
                      name="aprTplDesc"
                      value={aprTplInfoData?.aprTplDesc ? aprTplInfoData.aprTplDesc : ''}
                      onChange={(event: any) => {
                        const value = event?.target.value;
                        if (value.length <= 65) {
                          handleChange(event);
                        } else {
                          openCommonModal({
                            content: t(
                              'admin.msg.양식설명은 65자를 넘을 수 없습니다.',
                              '양식설명은 65자를 넘을 수 없습니다.'
                            ),
                          });
                        }
                      }}
                      maxLength={65}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.프로세스 설명', '프로세스 설명')}</label>
                  </th>
                  <td colSpan={3}>
                    <CrossEditor
                      ref={prsDescEditorRef}
                      name="prsDesc"
                      params={{ Width: '100%', Height: 300, Chevron: true }}
                      value={aprTplInfoData?.prsDesc}
                      onLoaded={handleEditorOnLoad}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.동의서 사용여부', '동의서 사용여부')}</label>
                  </th>
                  <td colSpan={3}>
                    <CustomSelect
                      name="wcstUseYn"
                      value={aprTplInfoData?.wcstUseYn}
                      onChange={handleChange}
                    >
                      <option value={CommonYN.Y}>{t('com.label.사용', '사용')}</option>
                      <option value={CommonYN.N}>{t('com.label.사용안함', '사용안함')}</option>
                    </CustomSelect>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.동의서 내용', '동의서 내용')}</label>
                  </th>
                  <td colSpan={3}>
                    <CrossEditor
                      ref={wcstDescEditorRef}
                      name="wcstDesc"
                      params={{ Width: '100%', Height: 300, Chevron: true }}
                      value={aprTplInfoData?.wcstDesc}
                      onLoaded={handleEditorOnLoad}
                    />
                  </td>
                </tr>
              </tbody>
            </ViewTable>
          </FormControl>
          <GlobalBtnGroup>
            <Button css={IconButton.button} className="save" disableRipple onClick={btnSave}>
              {t('com.button.저장', '저장')}
            </Button>
          </GlobalBtnGroup>
        </ContentSection>
      </ContentFlex>
    </>
  );
};
