/** @jsxImportSource @emotion/react */
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  DialogActions,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { GreyButton, GreyLineButton } from 'components/buttons/CustomButton';
import { CustomInputText, CustomTextarea } from 'components/inputs/CustomInput';
import { ContentGrid } from 'components/layouts/ContentGrid';
import {
  FlexBetween,
  ContentSection,
  GridInfoSection,
  GridInfo,
} from 'components/layouts/ContentSection';
import { di } from 'components/layouts/Dialog';
import { SearchBox, SearchRows, SearchButtons } from 'components/layouts/SearchBox';
import { ViewTable } from 'components/tables/ViewTable';
import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import BasicDatePicker from 'components/inputs/DatePicker';
import { Pagination, PaginationRef } from 'components/layouts/Pagination';
import dayjs, { Dayjs } from 'dayjs';
import { PaginationResponse } from 'models/common/Pagination';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { EmailLog, EmailLogRequest } from 'models/admin/EmailLog';
import { findEmailLogs } from 'apis/admin/EmailLog';
import DOMPurify from 'dompurify';

type DialogProps = {
  open: boolean;
  emailDtm: string;
  reciever: string;
  content: string;
};

const EmailLogManagementPage = () => {
  const { t } = useTranslation();
  const pageRef = useRef<PaginationRef>(null);
  const [emailLogList, setEmailLogList] = useState<PaginationResponse<EmailLog>>({
    totalCount: 0,
    list: [],
  });

  const [ifDtmFr, setIfDtmFr] = useState<Dayjs | null>(dayjs().add(-1, 'month'));
  const [ifDtmTo, setIfDtmTo] = useState<Dayjs | null>(dayjs());

  const [searchCondition, setSearchConditon] = useState<EmailLogRequest>({
    sedDtmFr: dayjs().add(-1, 'month').format('YYYYMMDD').toString(),
    sedDtmTo: dayjs().format('YYYYMMDD').toString(),
    searchItem: '',
  });

  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [dialogProp, setDialogProp] = useState<DialogProps>({
    open: false,
    emailDtm: '',
    reciever: '',
    content: '',
  });

  useEffect(() => {
    getEmailLogs();
  }, []);

  const getEmailLogs = async (search?: boolean, condition?: EmailLogRequest) => {
    const response = await findEmailLogs(condition ?? searchCondition);
    if (response) {
      setEmailLogList(response);
      if (search) {
        setPageNo(1);
        pageRef.current?.setSelectNo(1);
      }
    }
  };

  const handleSearch = () => {
    const condition: EmailLogRequest = { ...searchCondition, start: '0' };

    setSearchConditon(condition);
    getEmailLogs(true, condition);
  };

  const handleClickOpen = (data: EmailLog | undefined) => {
    if (typeof data === 'undefined') return;
    setDialogProp({
      open: true,
      emailDtm: data.sedDtm,
      reciever: data.restEmal,
      content: data.emlBdyCtn,
    });
  };
  const handleClose = () => {
    setDialogProp({
      open: false,
      emailDtm: '',
      reciever: '',
      content: '',
    });
  };

  const defaultColum: ColDef = {
    cellStyle: { textAlign: 'left' },
    resizable: true,
  };

  const handleChangeDate = (date: Dayjs, id: string) => {
    if (!date) return;
    const stringDate = date.format('YYYYMMDD').toString();
    if (id === 'ifDtmFr') {
      if (stringDate === 'Invalid Date') {
        setIfDtmFr(null);
        setSearchConditon({ ...searchCondition, sedDtmFr: '' });
      } else {
        setIfDtmFr(date);
        setSearchConditon({ ...searchCondition, sedDtmFr: stringDate });
      }
    } else {
      if (stringDate === 'Invalid Date') {
        setIfDtmTo(null);
        setSearchConditon({ ...searchCondition, sedDtmTo: '' });
      } else {
        setIfDtmTo(date);
        setSearchConditon({ ...searchCondition, sedDtmTo: stringDate });
      }
    }
  };
  const handleChangePageSize = (pageSize: string) => {
    const condition: EmailLogRequest = {
      ...searchCondition,
      pageSize: pageSize,
      start: '0',
    };
    setSearchConditon(condition);
    getEmailLogs(false, condition);
    setPageSize(parseInt(pageSize));
    pageRef.current?.setPageSize(parseInt(pageSize));
  };
  const handleClickPageNo = (pageNo: number) => {
    const condition: EmailLogRequest = {
      ...searchCondition,
      start: String(parseInt(searchCondition.pageSize ?? '10') * (pageNo - 1)),
    };
    setSearchConditon(condition);
    getEmailLogs(false, condition);
    pageRef.current?.setSelectNo(pageNo);
  };
  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: String(t('admin.grid.발송순번', '발송순번')),
      field: 'emlSndoSeq',
      cellStyle: { textAlign: 'center' },
      width: 100,
    },

    {
      headerName: String(t('admin.grid.발송일', '발송일')),
      field: 'sedDtm',
      cellStyle: { textAlign: 'center' },
      width: 250,
    },
    {
      headerName: String(t('admin.grid.수신자', '수신자')),
      field: 'emlRcvrLstCtn',
      cellStyle: { textAlign: 'left' },
      width: 250,
    },
    {
      headerName: String(t('com.label.유형', '유형')),
      field: 'emlTpCd',
      width: 200,
    },
    {
      headerName: String(t('admin.grid.이메일 내용', '이메일 내용')),
      field: 'emlBdyCtn',
      minWidth: 250,
      flex: 1,
      cellRenderer: (params: ICellRendererParams<EmailLog>) => {
        return (
          <FlexBetween style={{ width: '100%' }}>
            <span style={{ width: '90%', overflowX: 'hidden' }}>{params.value}</span>...
            <IconButton onClick={() => handleClickOpen(params.data)}>
              <img src="/assets/icon/ic-popup.svg" alt="팝업 열기" />
            </IconButton>
          </FlexBetween>
        );
      },
    },
    {
      headerName: String(t('admin.grid.전송결과', '전송결과')),
      field: 'emlTrnmStatCd',
      width: 200,
      cellStyle: { textAlign: 'center' },
    },
  ]);

  return (
    <>
      <SearchBox>
        <SearchRows className="contentStart">
          <div className="searchCol">
            <label>{t('admin.label.발송일', '발송일')}</label>
            <BasicDatePicker
              date={ifDtmFr}
              changeDate={(date) => handleChangeDate(date, 'ifDtmFr')}
            />
            -
            <BasicDatePicker
              date={ifDtmTo}
              changeDate={(date) => handleChangeDate(date, 'ifDtmTo')}
            />
          </div>
          <div className="searchCol">
            <label>{t('admin.label.이메일 내용', '이메일 내용')}</label>
            <CustomInputText
              onChange={(e) =>
                setSearchConditon({ ...searchCondition, searchItem: e.target.value })
              }
            ></CustomInputText>
          </div>
        </SearchRows>
        <SearchButtons className="searchButtons">
          <GreyButton className="buttonSrch" onClick={handleSearch}>
            <SearchIcon /> {t('com.button.조회', '조회')}
          </GreyButton>
        </SearchButtons>
      </SearchBox>
      <ContentSection>
        <GridInfoSection>
          <GridInfo>
            <span>{t('admin.label.이메일 목록', '이메일 목록')}</span>
            <span className="primary">
              {t('com.label.총', '총')} {emailLogList.totalCount}
              {t('com.label.건', '건')}
            </span>
          </GridInfo>
        </GridInfoSection>
        <ContentGrid className="ag-theme-alpine" style={{ height: '450px' }}>
          <AgGridReact
            overlayNoRowsTemplate="No rows to show"
            rowData={emailLogList.list}
            columnDefs={columnDefs}
            suppressPaginationPanel={true}
            defaultColDef={defaultColum}
          ></AgGridReact>
        </ContentGrid>
        <Pagination
          ref={pageRef}
          totalCount={emailLogList.totalCount}
          onChangePageSize={handleChangePageSize}
          onClickPageNo={handleClickPageNo}
          defaultPageNo={pageNo}
          defaultPageSize={pageSize}
        />
      </ContentSection>
      <Dialog open={dialogProp.open} onClose={handleClose} css={di.dialog} maxWidth="md">
        <DialogTitle className="popupTitle">
          {t('admin.label.이메일 내용', '이메일 내용')}
          <IconButton className="buttonClose" onClick={handleClose}>
            <CloseIcon fontSize="large"></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent className="popupContent">
          <FormControl>
            <ViewTable>
              <colgroup>
                <col width="30%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.발생일', '발생일')}</label>
                  </th>
                  <td>
                    <CustomInputText value={dialogProp.emailDtm} disabled></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.수신자', '수신자')}</label>
                  </th>
                  <td>
                    <CustomInputText value={dialogProp.reciever} disabled></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.이메일 내용', '이메일 내용')}</label>
                  </th>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dialogProp.content) }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </ViewTable>
          </FormControl>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GreyLineButton onClick={handleClose}>{t('com.button.닫기', '닫기')}</GreyLineButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmailLogManagementPage;
