export interface Generic {
  developer?: string;
}

export const emptyGeneric: Generic = {
  developer: '',
};

export interface RangeDate {
  startDate: any;
  endDate: any;
}

export const cleanRangeDate: RangeDate = {
  startDate: null,
  endDate: null,
};
