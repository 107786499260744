import styled from '@emotion/styled';
import { css } from '@mui/material';
import { SVGIcon } from 'components/buttons/IconSVG';
import {
  InputBG,
  GrayColor,
  CommonBG,
  CommonBorder,
  CommonText,
  ControlLinedBorder,
  LegendStatusBorder,
  LegendStatusBG,
  ControlLinedBG,
  InputBorder,
  NavTop,
  ControlFilldBG,
  BasicColor,
} from 'ui/theme/Color';

export const CustomInputText = styled.input`
  height: 32px;
  border-radius: 2px;
  border: solid 1px ${CommonBorder.Strong};
  background-color: ${CommonBG.Basic};
  font-size: 13px;
  color: ${CommonText.Basic};
  vertical-align: middle;
  &:focus,
  &:active {
    border: 1px solid ${InputBorder.Focus};
  }
  &:focus,
  &:hover,
  &:active {
    outline: 0;
  }
  &:disabled {
    background: ${InputBG.Disabled};
    color: ${CommonText.Lighter};
    pointer-events: none !important;
  }
  &::placeholder {
    color: ${CommonText.Lighter};
  }
  &:read-only {
    background: ${InputBG.Disabled};
    font-weight: 400 !important;
    pointer-events: none !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${CommonText.Basic};
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active {
    -webkit-text-fill-color: ${CommonText.Basic};
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }
  &.right {
    text-align: right;
    padding: 0 10px;
  }
`;

export const AllInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  span {
    display: none;
    padding-top: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    max-width: 100%;
  }
  &.help {
    span {
      display: inline-block;
      color: ${CommonText.Lighter};
    }
  }
  &.error {
    input {
      border: 1px solid ${CommonText.Error};
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 6px;
      right: 8px;
      z-index: 99;
      background: ${CommonText.Error};
      -webkit-mask: ${SVGIcon.IcoError};
      mask-image: ${SVGIcon.IcoError};
    }
    span {
      display: inline-block;
      color: ${CommonText.Error};
    }
  }
  &.warning {
    input {
      border: 1px solid ${LegendStatusBorder.WarningMinorNormal};
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 6px;
      right: 8px;
      z-index: 99;
      background: ${LegendStatusBG.WarningMajorNormal};
      -webkit-mask: ${SVGIcon.IcoWarning};
      mask-image: ${SVGIcon.IcoWarning};
    }
    span {
      display: inline-block;
      color: ${LegendStatusBG.WarningMajorNormal};
    }
  }
  &.comfirm {
    input {
      border: 1px solid ${CommonBorder.Confirmed};
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 6px;
      right: 8px;
      z-index: 99;
      background: ${CommonText.Confirmed};
      -webkit-mask: ${SVGIcon.IcoCofirm};
      mask-image: ${SVGIcon.IcoCofirm};
    }
    span {
      display: inline-block;
      color: ${CommonText.Confirmed};
    }
  }
`;

export const CustomInputWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  input {
    width: 100% !important;
    padding: 0 8px;
    margin-left: 0 !important;
    &.find {
      padding: 0px 8px 4px 26px;
    }
    &::-webkit-input-placeholder,
    &::-ms-input-placeholder {
      color: ${CommonText.Lighter};
      font-size: 13px;
      font-weight: 400;
    }
    &:read-only,
    &:disabled {
      & + .buttonFind {
        background: ${CommonText.Lighter};
      }
    }
  }
  & > button {
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    min-width: 16px !important;
    width: 16px !important;
    height: 16px !important;
    padding: 0px !important;
    &.buttonFind {
      left: 8px;
      background: ${CommonText.Basic};
      -webkit-mask: ${SVGIcon.IcoFind};
      mask-image: ${SVGIcon.IcoFind};
    }
  }
`;

export const form = {
  radioGroup: css`
    height: 20px !important;
    &.center {
      justify-content: center;
    }
    & > .MuiFormControlLabel-root {
      margin: 0px !important;
      position: relative;
      padding-left: 20px;
      height: 20px !important;
      display: inline-flex;
      align-items: center;
      padding-top: 0px !important;
      & + .MuiFormControlLabel-root {
        margin-left: 12px !important;
      }
    }
    .MuiButtonBase-root {
      padding: 0px !important;
      position: absolute;
      top: 2px;
      left: 0;
    }
    .MuiTypography-root {
      font-size: 13px;
      font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', sans-serif;
      color: ${CommonText.Basic};
      padding-left: 4px;
    }
    input[type='radio'] {
      display: none;
      & + span {
        svg {
          display: none;
        }
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background: ${CommonBG.Basic};
        border: 1px solid ${CommonBorder.Strong};
      }
      &:checked {
        & + span {
          border: 5px solid ${CommonBG.Primary};
        }
      }
      &:disabled {
        & + span {
          opacity: 0.4;
        }
      }
      &:checked:disabled {
        & + span {
          opacity: 0.4;
        }
      }
    }
    &.search {
      width: 100%;
    }
  `,
};

export const st = {
  checkbox: css`
    padding: 0px !important;
    margin: 0px !important;
    border: 1px solid ${CommonBorder.Strong};
    border-radius: 3px;
    width: 16px;
    height: 16px;
    background-color: ${CommonBG.Basic};
    &:hover {
      border: 1px solid ${ControlLinedBorder.PrimaryHover};
      background: ${CommonBG.Basic};
    }
    svg {
      display: none;
    }
    &.Mui-disabled {
      opacity: 0.4;
    }
    &.Mui-checked {
      border: 1px solid ${CommonBG.Primary};
      background-color: ${CommonBG.Primary};
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.6343 5.23431C11.9467 4.9219 12.4533 4.9219 12.7657 5.23431C13.0781 5.54673 13.0781 6.05327 12.7657 6.36568L7.4217 11.7097C6.81646 12.3149 5.8352 12.315 5.22986 11.7099L2.23441 8.71548C1.92194 8.40311 1.92185 7.89658 2.23422 7.58411C2.54658 7.27163 3.05311 7.27154 3.36559 7.58391L6.32567 10.543L11.6343 5.23431Z' fill='white'/%3E%3C/svg%3E%0A");
      background-size: 14px 14px;
      background-position: center;
      background-repeat: no-repeat;
      &.Mui-disabled {
        opacity: 0.4;
      }
      &:hover {
        border: 1px solid ${ControlFilldBG.PrimaryHover};
        background-color: ${ControlFilldBG.PrimaryHover};
      }
    }
    & + label {
      margin-left: 4px;
      font-size: 13px;
      color: ${CommonText.Basic};
      & + span {
        margin-left: 4px !important;
      }
    }
  `,
  /** TODO st.radio 기존 소스에서 사용 중 - 수정 후 삭제 필요 */
  radio: css`
    padding-right: 3px;
    &.Mui-checked {
      color: #3ec2cf;
    }

    &.purple.Mui-checked {
      color: ${BasicColor.Secondary};
    }
  `,
  label: css`
    height: 32px;
    .MuiFormControlLabel-label {
      font-size: 13px;
      font-weight: 400;
    }

    &.displayFlex {
      display: flex;
      flex: 1 0 100%;
    }

    &.marginR0 {
      margin-right: 0;
    }

    &.marginL0 {
      margin-left: 0;
    }

    & + .buttonHelp {
      margin-right: 16px;
    }
  `,

  chip: css`
    margin-top: 2px;
    .MuiChip-root {
      height: 28px;
      & + .MuiChip-root {
        margin-left: 4px;
      }
    }
    .MuiChip-outlined {
      border: 1px solid ${CommonBorder.Strong};
      background: ${ControlLinedBG.Basic};
      color: ${CommonText.Light};
      padding: 5px 14px;
      .MuiChip-label {
        font-size: 12px;
        padding: 0px !important;
      }
      &:hover {
        border: 1px solid ${ControlLinedBorder.BasicHover};
        background: ${ControlLinedBG.BasicHover};
        transition: initial !important;
      }
      &:disabled {
        opacity: 0.4;
      }
      &:active,
      &:focus {
        box-shadow: none !important;
        border: 1px solid ${ControlLinedBorder.BasicHover} !important;
        background: ${ControlLinedBG.BasicHover} !important;
        transition: initial !important;
      }
    }
    .MuiChip-filled {
      border: 1px solid ${ControlLinedBorder.PrimaryActive};
      background: ${GrayColor.White};
      color: ${CommonText.Primary};
      position: relative;
      padding: 5px 30px 5px 14px;
      &:before {
        content: '';
        clear: both;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: absolute;
        top: calc(50% - 8px);
        right: 6px;
        background: ${CommonText.Primary};
        -webkit-mask: ${SVGIcon.IcoCheck};
        mask-image: ${SVGIcon.IcoCheck};
      }
      .MuiChip-label {
        font-size: 12px;
        padding: 0px !important;
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  `,
  category: css`
    .MuiChip-root {
      height: 28px;
      border-radius: 14px;
      border: 1px solid ${CommonBorder.Strong};
      background: ${GrayColor.White};
      font-size: 12px;
      color: ${CommonText.Light};
      & + .MuiChip-root {
        margin-left: 4px;
      }
    }
    .MuiChip-filled {
      border: 1px solid ${ControlLinedBorder.PrimaryActive};
      background: ${CommonBG.Primary};
      color: ${GrayColor.White};
    }
  `,
};

export const UseToggle = styled.label`
  &.switch {
    width: 70px !important;
    height: 26px;
    padding: 0px;
    position: relative;
    display: inline-block;
    border-radius: 13px;
    margin-top: 2px;
    cursor: pointer;
    input {
      display: none;
    }
    .slider {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 13px;
      border: 1px solid ${CommonBorder.Basic};
      background: ${NavTop.BgSwitch};
      box-shadow: rgba(151, 150, 150, 0.4) 2px 2px 4px inset;
      transition: all 0.4s ease-in-out;
    }

    .slider::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      border-radius: 12px;
      background: ${CommonBG.Elevation};
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
      transition: all 0.4s ease-in-out;
    }

    input:checked + .slider {
      background: ${ControlFilldBG.Primary};
      border: 1px solid ${ControlLinedBorder.PrimaryActive};
    }

    input:checked + .slider::before {
      transform: translateX(42px);
    }

    .labels {
      position: absolute;
      top: 4px;
      left: 6px;
      width: 100%;
      height: 100%;
      font-size: 12px;
      transition: all 0.4s ease-in-out;
    }

    .labels::after {
      content: attr(data-off);
      position: absolute;
      right: 12px;
      color: ${CommonText.Light};
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }

    .labels::before {
      content: attr(data-on);
      position: absolute;
      left: 5px;
      color: ${CommonBG.Elevation};
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }

    input:checked ~ .labels::after {
      opacity: 0;
    }

    input:checked ~ .labels::before {
      opacity: 1;
    }
  }
`;

export const TextareaWrap = styled.div`
  border: 1px solid ${CommonBorder.Strong};
  border-radius: 2px;
  position: relative;
  padding-bottom: 12px;
  &.focus {
    border: 1px solid ${InputBorder.Focus};
  }
  &.disabled {
    background: ${InputBG.Disabled};
  }
  &.error {
    border: 1px solid ${CommonBorder.Error};
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 6px;
      right: 8px;
      z-index: 99;
      background: ${CommonText.Error};
      -webkit-mask: ${SVGIcon.IcoError};
      mask-image: ${SVGIcon.IcoError};
    }
    & + .message {
      display: inline-block;
      font-size: 12px;
      line-height: 1.5;
      margin-top: 4px;
      color: ${CommonText.Error};
    }
  }
  textarea {
    border: none !important;
    outline: none !important;
    width: 100%;
    resize: none;
    height: 100%;
    display: block;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(60, 62, 61, 0.4);
      width: 6px;
      border-radius: 3px;
    }
  }
  &.num {
    padding: 6px 0px 6px 12px;
    textarea {
      padding: 0px 12px 0px 0px !important;
      height: calc(100% - 18px);
    }
  }
  .num {
    height: 12px;
    font-size: 12px;
    font-weight: 400;
    color: ${CommonText.Light};
    margin-top: 2px;
    height: 18px;
  }
  & + .message {
    display: none;
  }
`;

export const tx = {
  textareaWrap: css`
    position: relative;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 12px;
      width: 18px;
      height: 18px;
      z-index: 99;
      border: 1px solid red;
      background: ${CommonText.Primary};
      -webkit-mask: ${SVGIcon.IcoInfo};
      mask-image: ${SVGIcon.IcoInfo};
    }
    textarea {
      width: 100%;
      background: ${CommonBG.Basic};
      border: 1px solid ${CommonBorder.Strong};
      border-radius: 2px;
      resize: none !important;
      font-size: 12px;
      line-height: 1.5;
      color: ${CommonText.Basic};
      padding: 7px 12px 7px 42px;
      &::placeholder {
        font-size: 13px;
        line-height: 1.5;
        color: ${CommonText.Lighter};
      }
      &:focus,
      &:active {
        outline: none !important;
        border: 1px solid ${InputBorder.Focus};
      }
      &:disabled {
        background: ${InputBG.Disabled};
        pointer-events: none;
        color: ${CommonText.Lighter};
      }
      &:read-only {
        background: ${InputBG.Disabled};
        pointer-events: none;
        color: ${CommonText.Basic};
      }
    }
  `,
};

export const Textarea = styled.textarea`
  width: 100%;
  background: ${CommonBG.Basic};
  border: 1px solid ${CommonBorder.Strong};
  border-radius: 2px;
  resize: none !important;
  font-size: 13px;
  line-height: 1.5;
  display: block;
  color: ${CommonText.Basic};
  padding: 7px 12px;
  &.popup {
    height: 100%;
    margin-top: 4px;
    height: 50px;
  }
  &::placeholder {
    font-size: 13px;
    line-height: 1.5;
    color: ${CommonText.Lighter};
  }
  &:focus,
  &:active {
    outline: none !important;
    border: 1px solid ${InputBorder.Focus};
  }
  &:disabled {
    background: ${InputBG.Disabled};
    pointer-events: none;
    color: ${CommonText.Lighter};
  }
  &:read-only {
    background: ${InputBG.Disabled};
    pointer-events: auto;
    color: ${CommonText.Basic};
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(60, 62, 61, 0.4);
    width: 6px;
    border-radius: 3px;
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const CustomTextarea = styled.textarea`
  min-height: 80px;
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: solid 1px ${GrayColor.Gray400};
  background-color: ${BasicColor.White};
  font-size: 13px;
  font-family: 'Spoqa Han Sans Neo';
  color: ${GrayColor.Gray900};
  resize: none;
  line-height: 1.4;

  &:focus,
  &:hover,
  &:active {
    outline: 0;
  }
  &:disabled {
    background-color: ${GrayColor.Gray50};
    color: ${GrayColor.Gray700};
  }
  &::placeholder {
    color: ${GrayColor.Gray500};
  }
  &.height200 {
    height: 200px;
  }
`;
