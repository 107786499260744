import { GtngVerMst } from 'models/gtng/GtngVerMst';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { Code } from 'models/common/CommonCode';

export const getGatingVerCkList = async (code) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getGatingVerCkList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ code }),
  };
  const response: CommonResponse<GtngVerMst[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GtngVerMst[];
};

export const saveGatingVerCk = async (saveData: GtngVerMst[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveGatingVerCk`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};
