import styled from '@emotion/styled';
import { FontColor } from 'ui/theme/Color';

export const ContentSection = styled.div`
  margin-top: 25px;
  line-height: 1.2;

  &.selfCenter {
    align-self: center;

    .buttons {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      button + button {
        margin-top: 10px;
      }
    }
  }

  &.marginT0 {
    margin-top: 0 !important;
  }

  &.marginT35 {
    margin-top: 35px !important;
  }

  &.marginT38 {
    margin-top: 38px !important;
  }

  .noGridTop {
    margin-top: 53px;
  }

  &.noSearchBox {
    margin-top: 84px;
  }

  .required {
    margin-left: 3px;
  }

  .marginT10 {
    margin-top: 10px;
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const GridTop = styled.div`
  margin-bottom: 15px;
  height: 38px;
  &.contentBetween {
    justify-content: space-between;
  }

  &.contentEnd {
    justify-content: end;
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const GridInfo = styled.div`
  font-weight: 500;
  font-size: 16px;
  span {
    margin-right: 5px;
    &.primary {
      color: ${FontColor.Primary};
    }
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const GridInfoSection = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  justify-content: space-between;

  div + & {
    margin-top: 10px;
  }

  &.contentEnd {
    justify-content: flex-end;
  }

  &.alignFlexStart {
    align-items: flex-start;
  }

  &.marginT20 {
    margin-top: 20px;
  }

  &.minHeight28 {
    min-height: 28px;
  }
  &.minHeight15 {
    min-height: 15px;
  }
  &.minHeight36 {
    min-height: 36px;
  }

  &.IconButton {
    align-items: center;
  }
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const GridButtons = styled.div`
  display: flex;
  align-items: end;
`;

/**
 * TODO 기존 소스에서 사용중으로 삭제안함 (확인필요)
 */
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    flex: 0 0 auto;
  }

  & + & {
    margin-top: 5px;
  }
`;
