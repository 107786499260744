/** @jsxImportSource @emotion/react */
import { GuideBlock } from '../GuideComponent';
import Accordion from '@mui/material/Accordion';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import React, { useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { SearchBox, SearchBoxRow, SearchButtonWrap } from 'components/layouts/SearchBox';
import { Button } from '@mui/material';
import { IconButton } from '../../../../components/buttons/IconSVG';
import FileConverterPopUp from 'pages/common/components/FileConverterPopUp';
import { BizName } from 'models/common/Common';

const FileConverterGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const [atchFileGrId, setAtchFileGrId] = useState<string>('');
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);

  return (
    <>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>FileConverter - Basic</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <SearchBox>
                <SearchBoxRow>
                  <SearchButtonWrap>
                    <Button
                      css={IconButton.button}
                      className="find"
                      onClick={() => {
                        setOpenFileUploadModal(true);
                      }}
                      disableRipple
                    >
                      팝업열기
                    </Button>
                  </SearchButtonWrap>
                </SearchBoxRow>
              </SearchBox>
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>

      {isOpenFileUploadModal && (
        <FileConverterPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          initParam={{
            atchFileGrId: atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: BizName.ETC,
          }}
          singleSelect={fileUploadModalCondition.singleSelect}
          downloadOnly={fileUploadModalCondition.downloadOnly}
          showAllDownload={true}
          onCallback={(value) => {
            if (value) {
              setAtchFileGrId(value);
            }
          }}
          isRequire={false}
        />
      )}
    </>
  );
};

export default FileConverterGuide;
