import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import {
  GatingCheckResultItem,
  GatingCheckResultItemCondition,
  GatingCheckResultMst,
  GatingCheckResultMstCondition,
} from 'models/gtng/GatingCheckResult';

//게이팅 실적 마스터 정보 조회
export const getGatingCheckResultMst = async (condition: GatingCheckResultMstCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/getGatingCheckResultMst`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<GatingCheckResultMst> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckResultMst;
};

//게이팅 실적 마스터 정보 조회
export const retrieveGtngEquipN = async (condition: GatingCheckResultMstCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/retrieveGtngEquipN`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<GatingCheckResultMst> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckResultMst;
};

//게이팅 실적 체크 항목 리스트 조회
export const getGatingCheckResultItemList = async (condition: GatingCheckResultItemCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/getGatingCheckResultItemList`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<GatingCheckResultItem[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckResultItem[];
};

//요청서에서 게이팅 조회  info
export const retrieveGtngEquipJudge = async (condition: GatingCheckResultMst) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/retrieveGtngEquipJudge`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<GatingCheckResultMst> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckResultMst;
};

//요청서에서 게이팅 조회 master
export const retrieveGtngEquipMaster = async (condition: GatingCheckResultItemCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/retrieveGtngEquipMaster`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<GatingCheckResultMst> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckResultMst;
};

//요청서에서 게이팅 조회 list
export const retrieveGtngEquipList = async (condition: GatingCheckResultItemCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/retrieveGtngEquipList`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<GatingCheckResultItem[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckResultItem[];
};

/**
 * 게이팅 실적 아이템 저장 1
 * @param saveDataItem
 */
export const saveGatingReqRequest = async (saveDataItem: GatingCheckResultMst | undefined) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveGatingReqRequest`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveDataItem,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  // return (response.successOrNot === 'Y' ? response.data : response.statusCode) as DmlResponse;
  return response;
};

export const saveEquipment = async (params: GatingCheckResultMst) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/saveEquipment`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: params,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 게이팅 실적 아이템 저장 1
 * @param saveDataItem
 */
export const saveGtngCheckItem = async (saveDataItem: GatingCheckResultItem[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveGtngCheckItem`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveDataItem,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  // return (response.successOrNot === 'Y' ? response.data : response.statusCode) as DmlResponse;
  return response;
};

/**
 * 점검 완료
 * @param confirmDataItem
 */
export const confirmGtngCheck = async (saveData: GatingCheckResultMst) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/confirmGtngCheckItem`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<GatingCheckResultMst> = await callApi(request);
  return response;
};

/**
 * 저장버튼 실행여부 조회
 * @param confirmDataItem
 */
export const fnSaveCheck = async (sGatingId: string, sEquipmentId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/retrieveCheckSaveBtn`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      sGatingId: sGatingId,
      sEquipmentId: sEquipmentId,
    }),
  };
  const response: CommonResponse = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};
