/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import ApprovalDelegateGrid from './approvalmanagement/ApprovalDelegateGrid';

import { CustomInputText, CustomInputWrap } from 'components/inputs/CustomInput';
import { CustomSelect } from 'components/selects/CustomSelect';
import EmployeeModal from 'components/modals/common/EmployeeModal';
import { Employee } from 'models/admin/Employee';
import useSessionStore from 'stores/useSessionStore';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { Button } from '@mui/material';
import { IconButton } from '../../components/buttons/IconSVG';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useTranslation } from 'react-i18next';
import { ComboBox } from 'components/selects/ComboBox';

interface SearchParam {
  DlgtUserId?: string;
  status?: string;
}

const ApprovalDelegateManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchParam, setSearchParam] = useState<SearchParam>({});
  const [searchData, setSearchData] = useState<SearchParam>({});
  const [dlgtUserIdData, setDlgtUserIdData] = useState<string>('');
  const [statusData, setStatusData] = useState<string>('');
  const [EmployeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);
  const userSession = useSessionStore();
  const fnSelectUser = (emp: Employee[]) => {
    setSearchParam({ DlgtUserId: emp[0].userId });
    setEmployeeModalOpen(false);
  };

  const optionList = [
    { cmnCd: 'A', cmnCdNm: t('admin.label.등록', '등록') },
    { cmnCd: 'B', cmnCdNm: t('admin.label.위임중', '위임중') },
    { cmnCd: 'C', cmnCdNm: t('admin.label.종료', '종료') },
  ];

  const btnReload = () => {
    setSearchParam({ DlgtUserId: '', status: '' });
  };

  const handleItemChange = (name: string, value: string | string[]) => {
    setSearchParam((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const btnSearch = () => {
    setSearchData(searchParam);
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('admin.label.위임자', '위임자')}</label>
                {userSession.roleCodes.some((e) => e == 'ADM') && (
                  <CustomInputWithSearch
                    name="DlgtUserId"
                    className="find"
                    value={searchParam.DlgtUserId}
                    onChange={(e) => {
                      handleItemChange('DlgtUserId', e.target.value);
                    }}
                    onSearchClick={() => setEmployeeModalOpen(true)}
                    placeholder={String(
                      t('admin.label.위임자를 선택해 주세요.', '위임자를 선택해 주세요.')
                    )}
                  ></CustomInputWithSearch>
                )}
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.상태', '상태')}</label>
                <ComboBox
                  options={optionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.status}
                  onChange={(value) => setSearchParam({ ...searchParam, status: value })}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>

      <EmployeeModal
        open={EmployeeModalOpen}
        close={() => {
          setEmployeeModalOpen(false);
        }}
        save={(emp: Employee[]) => fnSelectUser(emp)}
        singleSelect={true}
      />

      <ApprovalDelegateGrid searchParam={searchData} />

      {/* {userSession.roleCodes.some((e) => e == 'ADM') && (
        <EmployeeModal
          open={EmployeeModalOpen}
          close={() => {
            setEmployeeModalOpen(false);
          }}
          save={(emp: Employee[]) => fnSelectUser(emp)}
          singleSelect={true}
        />
      )} */}
    </>
  );
};

export default ApprovalDelegateManagementPage;
