/** @jsxImportSource @emotion/react */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Accept, useDropzone } from 'react-dropzone';
import './Dropzone.css';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';
import {
  convertFiles,
  findFiles,
  modifyFiles,
  modifyFilesWithNewGroupId,
  uploadFiles,
  uploadFileWithNewGroupId,
} from 'apis/admin/FileUpload';
import { FileInfo, FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import axios from 'axios';
import { FileUpload, UploadFile } from 'components/buttons/CustomButton';
import { BizName, CommonYN, FileUpload as FileConst } from 'models/common/Common';
import { CrudCode } from 'models/common/Edit';
import { fileExtension, formatBytes } from 'utils/FormatUtil';

interface DropzoneProps {
  atchFileGrIdInput?: string; // 첨부파일 그룹ID
  acceptExtensions?: Accept; // 파일 확장자
  newGrId?: boolean; // 미사용
  atchFileTpCd?: string; // 첨부파일유형
  downloadOnly?: boolean; // 다운로드만 사용여부
  maxSize?: number; // 최대 첨부용량 (default: FileConst.maxSize)
  singleSelect?: boolean; // 단건 선택여부 (default: false)
  atchFilesHide?: boolean; // 첨부파일 조회 여부 (default: false)
  tableName?: string; // 관련 테이블 명 (확인용)
  bizName?: BizName; // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
  onChange?: (files: FileInfo[]) => void;
}

// eslint-disable-next-line react/display-name
const Dropzone = forwardRef(
  (
    {
      atchFileGrIdInput,
      acceptExtensions,
      newGrId,
      atchFileTpCd = '',
      downloadOnly = false,
      singleSelect = false,
      atchFilesHide = false,
      maxSize = FileConst.maxSize,
      tableName = '',
      bizName,
      onChange,
    }: DropzoneProps,
    ref: React.Ref<any>
  ) => {
    const { t } = useTranslation();
    const firstRef = useRef(true);
    const [isHover, setHover] = useState<boolean>(false);
    const [files, setFiles] = useState<FileInfo[]>([]);
    const [atchFileGrId, setAtchFileGrId] = useState('');
    // const [atchFileGrId, setAtchFileGrId] = useState(uuidv4());
    const totalSize = useMemo(
      () =>
        files
          .filter((file) => file.useYn === CommonYN.Y)
          .reduce((acc, cur) => acc + cur.atchFileSize, 0),
      [files]
    );
    const [errorMsg, setErrorMsg] = useState<string>('');

    useEffect(() => {
      setErrorMsg('');

      if (firstRef.current) {
        firstRef.current = false;
        return;
      }

      if (typeof onChange === 'function') {
        // 삭제되는 파일은 전달하지 않음
        onChange(files.filter((file) => file.useYn === CommonYN.Y));
      }
    }, [files]);

    const convertPPTFiles = async (atchFileGrId, bizName) => {
      const insertFormData = new FormData();

      let strFileSortOrds = '';
      files.forEach((fileInfo) => {
        insertFormData.append('files', fileInfo.file as Blob);
        strFileSortOrds += `${fileInfo.sortOrd},`;
      });

      let result: any = null;
      if (insertFormData.has('files')) {
        insertFormData.append('atchFileGrId', atchFileGrId);
        insertFormData.set('fileSortOrds', strFileSortOrds.slice(0, -1));
        insertFormData.append('atchFileTpCd', atchFileTpCd);
        insertFormData.append('tableName', tableName);
        insertFormData.append('bizName', bizName);

        result = await convertFiles(insertFormData);
        if (result && result.convertFileList) {
          return result.convertFileList;
        }
      }
    };

    const saveFiles = async (newGrId2?: boolean) => {
      const newGroupId = uuidv4(); // 다른 파일그룹 아이디에 저장할 때만 사용

      const response = {
        atchFileGrId: atchFileGrId,
        fileSaveResult: FileSaveResult.NONE,
        fileCount: (files || []).filter((file) => file.useYn === CommonYN.Y).length,
      } as FileSaveResponse;
      if (files.length == 0) return response;

      const insertFormData = new FormData();
      const updateFiles: FileInfo[] = [];

      let strFileSortOrds = '';

      files.forEach((fileInfo) => {
        if (fileInfo.crudKey === CrudCode.CREATE) {
          insertFormData.append('files', fileInfo.file as Blob);
          strFileSortOrds += `${fileInfo.sortOrd},`;
        } else if (fileInfo.crudKey === CrudCode.UPDATE) {
          updateFiles.push(fileInfo);
        }
      });

      let result: any = null;
      if (insertFormData.has('files')) {
        insertFormData.append('atchFileGrId', atchFileGrId);
        insertFormData.set('fileSortOrds', strFileSortOrds.slice(0, -1));
        insertFormData.append('atchFileTpCd', atchFileTpCd);
        insertFormData.append('tableName', tableName);
        insertFormData.append('bizName', bizName || '');
        if (!(newGrId || newGrId2)) result = await uploadFiles(insertFormData);
        else {
          insertFormData.append('oldAtchFileGrId', atchFileGrId);
          insertFormData.append('newAtchFileGrId', newGroupId);
          result = await uploadFileWithNewGroupId(insertFormData);
        }

        if (!result) {
          response.fileSaveResult = FileSaveResult.FAIL;
          return response;
        }
        setAtchFileGrId(result.atchFileGrId);
        response.atchFileGrId = result.atchFileGrId;
      }
      if (updateFiles.length > 0) {
        if (!(newGrId || newGrId2)) result = await modifyFiles(updateFiles);
        else result = await modifyFilesWithNewGroupId(newGroupId, updateFiles);
      }
      if (result || files.length > 0) {
        if (newGrId || newGrId2) {
          response.atchFileGrId = newGroupId;
        }
        response.fileSaveResult = FileSaveResult.SUCCESS;
      } else {
        response.fileSaveResult = FileSaveResult.FAIL;
      }
      retrieveFiles(response.atchFileGrId);
      return response;
    };

    const retrieveFiles = (grId) => {
      findFiles(grId, atchFileTpCd).then((result: FileInfo[]) => {
        result.map((files) => ({ ...files, newYn: false }));
        setFiles(result);
      });
    };

    const onDropHandler = (acceptedFiles: File[]) => {
      const newFiles: FileInfo[] = acceptedFiles.map((acceptedFile) => {
        return {
          atchFileGrId: atchFileGrId,
          atchFileId: uuidv4(),
          atchFileNm: acceptedFile.name,
          sortOrd: '',
          atchFileSaveLocDivsCd: '',
          atchFileSaveNm: acceptedFile.name,
          atchFileSize: acceptedFile.size,
          atchFileEfnmNm: '',
          atchFileTpCd: '',
          optValCtn1: '',
          optValCtn2: '',
          optValCtn3: '',
          optValCtn4: '',
          optValCtn5: '',
          useYn: CommonYN.Y,
          newYn: true,
          crudKey: CrudCode.CREATE,
          file: acceptedFile,
        };
      });

      // setFiles((prev) => [...prev, ...newFiles]);
      setFiles((prev) => (singleSelect ? [...newFiles] : [...prev, ...newFiles]));
    };

    const deleteFileHandler = (atchFileId) => {
      const updateFiles = files
        .map((file) => {
          if (file.atchFileId === atchFileId) {
            if (file.crudKey === CrudCode.CREATE) {
              return null;
            } else {
              return { ...file, useYn: CommonYN.N, sortOrd: '', crudKey: CrudCode.UPDATE };
            }
          }
          return file;
        })
        .filter(Boolean) as FileInfo[];
      setFiles(updateFiles);
    };

    const deleteCancelHandler = (atchFileId) => {
      setFiles((prev) =>
        prev.map((file) => {
          if (file.newYn) {
            return file.atchFileId === atchFileId
              ? { ...file, useYn: CommonYN.Y, crudKey: CrudCode.CREATE }
              : file;
          } else {
            return file.atchFileId === atchFileId
              ? { ...file, useYn: CommonYN.Y, crudKey: CrudCode.READ }
              : file;
          }
        })
      );
    };

    const handleSortOrdChange = (key, newValue) => {
      setFiles((prev) =>
        prev.map((file) =>
          file.atchFileId === key
            ? {
                ...file,
                crudKey: file.crudKey == CrudCode.CREATE ? CrudCode.CREATE : CrudCode.UPDATE,
                sortOrd: newValue,
              }
            : file
        )
      );
    };

    const handleDownloadFile = async (file: FileInfo) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/v1/file/download?atchFileGrId=${file.atchFileGrId}&atchFileId=${file.atchFileId}`,
          {
            responseType: 'blob',
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.atchFileNm);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error);
      }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      noClick: false,
      onDrop: onDropHandler,
      accept: acceptExtensions,
      maxSize: maxSize,
      multiple: !singleSelect,
    });

    useImperativeHandle(ref, () => ({
      saveFiles: async (newGrId2?: boolean) => {
        const fileSaveResponse: FileSaveResponse = await saveFiles(newGrId2);
        return fileSaveResponse;
      },
      checkPPTFiles: async (atchFileGrId, bizName) => {
        return await convertPPTFiles(atchFileGrId, bizName);
      },
      getFileCount: () => {
        return files.length;
      },
      setErrorMsg: (msg) => {
        setErrorMsg(msg);
      },
      getTotalFileSize: () => {
        if (files.length == 0) return 0;
        let size = 0;
        for (let i = 0; i < files.length; i++) {
          if (files[i].useYn == CommonYN.Y) {
            size += files[i].atchFileSize;
          }
        }
        return size;
      },
      initDropZone: () => {
        init();
      },
    }));

    const init = () => setFiles([]);

    useEffect(() => {
      if (atchFileGrIdInput && atchFileGrIdInput.length > 0) {
        setAtchFileGrId(atchFileGrIdInput);
        if (atchFilesHide === false) {
          findFiles(atchFileGrIdInput, atchFileTpCd).then((result: FileInfo[]) => {
            result.map((files) => ({ ...files, newYn: false }));
            setFiles(result);
          });
        }
      } else {
        init();
      }
    }, [atchFileGrIdInput]);

    return (
      <>
        <FileUpload className={isDragActive ? 'active' : isHover ? 'hover' : ''}>
          {!downloadOnly && (
            <>
              <span {...getRootProps()}>
                <input {...getInputProps()} />
                <label
                  htmlFor="file"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  {t(
                    'com.label.첨부할 파일을 선택하거나 여기에 drag & drop 하세요.',
                    '첨부할 파일을 선택하거나 여기에 drag & drop 하세요.'
                  )}
                </label>
              </span>
              <div className="volumn">
                <span>{formatBytes(totalSize)}</span> / {formatBytes(maxSize)}
              </div>
            </>
          )}
          {files &&
            files
              .filter((file) => file.useYn === CommonYN.Y)
              .map((file) => (
                <>
                  <UploadFile key={file.atchFileId}>
                    {/*comfirm | error*/}
                    <div className="uploadFile">
                      <div
                        className={fileExtension(file.atchFileNm)}
                        onClick={() => handleDownloadFile(file)}
                        style={{ cursor: 'pointer' }}
                      >
                        {file.atchFileNm}
                      </div>
                      <span className="fileVolumn">{formatBytes(file.atchFileSize)}</span>
                      {!downloadOnly && (
                        <Button onClick={() => deleteFileHandler(file.atchFileId)}></Button>
                      )}
                    </div>
                    {/*<span>에러메세지</span>*/}
                  </UploadFile>
                </>
              ))}
        </FileUpload>
        {errorMsg && (
          <span
            className="error"
            style={{ marginTop: '4px', fontSize: '13px', fontWeight: '400', color: '#F94B50' }}
          >
            {errorMsg}
          </span>
        )}
      </>
    );
  }
);

export default Dropzone;
