/** @jsxImportSource @emotion/react */
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  DialogActions,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { GreyButton, GreyLineButton } from 'components/buttons/CustomButton';
import { CustomInputText, CustomTextarea } from 'components/inputs/CustomInput';
import { ContentGrid } from 'components/layouts/ContentGrid';
import {
  FlexBetween,
  ContentSection,
  GridInfoSection,
  GridInfo,
} from 'components/layouts/ContentSection';
import { di } from 'components/layouts/Dialog';
import { SearchBox, SearchRows, SearchButtons } from 'components/layouts/SearchBox';
import { ViewTable } from 'components/tables/ViewTable';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import BasicDatePicker from 'components/inputs/DatePicker';
import { Pagination, PaginationRef } from 'components/layouts/Pagination';
import dayjs, { Dayjs } from 'dayjs';
import { IfLog, IfLogRequest } from 'models/admin/IfLog';
import { PaginationResponse } from 'models/common/Pagination';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { findIfLogs } from 'apis/admin/ifLog';

type DialogProps = {
  open: boolean;
  ifDtm: string;
  divsNm: string;
  content: string;
  result: string;
};

const IfLogManagementPage = () => {
  const { t } = useTranslation();
  const pageRef = useRef<PaginationRef>(null);
  const [ifLogList, setIfLogList] = useState<PaginationResponse<IfLog>>({
    totalCount: 0,
    list: [],
  });

  const [ifDtmFr, setIfDtmFr] = useState<Dayjs | null>(dayjs().add(-1, 'month'));
  const [ifDtmTo, setIfDtmTo] = useState<Dayjs | null>(dayjs());

  const [searchCondition, setSearchConditon] = useState<IfLogRequest>({
    ifLogDtmFr: dayjs().add(-1, 'month').format('YYYYMMDD').toString(),
    ifLogDtmTo: dayjs().format('YYYYMMDD').toString(),
    searchItem: '',
  });

  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [dialogProp, setDialogProp] = useState<DialogProps>({
    open: false,
    ifDtm: '',
    divsNm: '',
    content: '',
    result: '',
  });

  useEffect(() => {
    getIfLogs();
  }, []);

  const getIfLogs = async (search?: boolean, condition?: IfLogRequest) => {
    const response = await findIfLogs(condition ?? searchCondition);
    if (response) {
      setIfLogList(response);
      if (search) {
        setPageNo(1);
        pageRef.current?.setSelectNo(1);
      }
    }
  };

  const handleSearch = () => {
    const condition: IfLogRequest = { ...searchCondition, start: '0' };

    setSearchConditon(condition);
    getIfLogs(true, condition);
  };

  const handleClickOpen = (data: IfLog | undefined) => {
    if (typeof data === 'undefined') return;
    setDialogProp({
      open: true,
      ifDtm: data.ifLogDtm,
      divsNm: data.cmnCdNm,
      content: data.ifTrmtValCtn,
      result: data.ifRestValCtn,
    });
  };
  const handleClose = () => {
    setDialogProp({
      open: false,
      divsNm: '',
      ifDtm: '',
      content: '',
      result: '',
    });
  };

  const defaultColum: ColDef = {
    cellStyle: { textAlign: 'left' },
    resizable: true,
  };

  const handleChangeDate = (date: Dayjs, id: string) => {
    if (!date) return;
    const stringDate = date.format('YYYYMMDD').toString();
    if (id === 'ifDtmFr') {
      if (stringDate === 'Invalid Date') {
        setIfDtmFr(null);
        setSearchConditon({ ...searchCondition, ifLogDtmFr: '' });
      } else {
        setIfDtmFr(date);
        setSearchConditon({ ...searchCondition, ifLogDtmFr: stringDate });
      }
    } else {
      if (stringDate === 'Invalid Date') {
        setIfDtmTo(null);
        setSearchConditon({ ...searchCondition, ifLogDtmTo: '' });
      } else {
        setIfDtmTo(date);
        setSearchConditon({ ...searchCondition, ifLogDtmTo: stringDate });
      }
    }
  };
  const handleChangePageSize = (pageSize: string) => {
    const condition: IfLogRequest = {
      ...searchCondition,
      pageSize: pageSize,
      start: '0',
    };
    setSearchConditon(condition);
    getIfLogs(false, condition);
    setPageSize(parseInt(pageSize));
    pageRef.current?.setPageSize(parseInt(pageSize));
  };
  const handleClickPageNo = (pageNo: number) => {
    const condition: IfLogRequest = {
      ...searchCondition,
      start: String(parseInt(searchCondition.pageSize ?? '10') * (pageNo - 1)),
    };
    setSearchConditon(condition);
    getIfLogs(false, condition);
    pageRef.current?.setSelectNo(pageNo);
  };
  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: String(t('admin.grid.발생일', '발생일')),
      field: 'ifLogDtm',
      cellStyle: { textAlign: 'center' },
      width: 200,
    },

    {
      headerName: String(t('admin.grid.송/수신 구분', '송/수신 구분')),
      field: 'cmnCdNm',
      cellStyle: { textAlign: 'center' },
      width: 150,
    },
    {
      headerName: String(t('admin.grid.I/F 명', 'I/F 명')),
      field: 'ifNm',
      cellStyle: { textAlign: 'center' },
      width: 250,
    },
    {
      headerName: String(t('admin.grid.I/F 내용', 'I/F 내용')),
      field: 'ifTrmtValCtn',
      width: 250,
    },
    {
      headerName: String(t('admin.grid.I/F 결과', 'I/F 결과')),
      field: 'ifRestValCtn',
      width: 250,
      cellRenderer: (params: ICellRendererParams<IfLog>) => {
        return (
          <FlexBetween style={{ width: '100%' }}>
            <span style={{ width: '90%', overflowX: 'hidden' }}>{params.value}</span>...
            <IconButton onClick={() => handleClickOpen(params.data)}>
              <img src="/assets/icon/ic-popup.svg" alt="팝업 열기" />
            </IconButton>
          </FlexBetween>
        );
      },
    },
    {
      headerName: String(t('admin.grid.부가정보1', '부가정보1')),
      field: 'optValNm1',
      width: 150,
    },
    {
      headerName: String(t('admin.grid.부가정보2', '부가정보2')),
      field: 'optValNm2',
      width: 150,
    },
    {
      headerName: String(t('admin.grid.부가정보3', '부가정보3')),
      field: 'optValNm3',
      width: 150,
    },
    {
      headerName: String(t('admin.grid.부가정보4', '부가정보4')),
      field: 'optValNm4',
      width: 150,
    },
    {
      headerName: String(t('admin.grid.부가정보5', '부가정보5')),
      field: 'optValNm5',
      width: 150,
    },
  ]);

  return (
    <>
      <SearchBox>
        <SearchRows className="contentStart">
          <div className="searchCol">
            <label>{t('admin.label.발생일', '발생일')}</label>
            <BasicDatePicker
              date={ifDtmFr}
              changeDate={(date) => handleChangeDate(date, 'ifDtmFr')}
            />
            -
            <BasicDatePicker
              date={ifDtmTo}
              changeDate={(date) => handleChangeDate(date, 'ifDtmTo')}
            />
          </div>
          <div className="searchCol">
            <label>{t('admin.label.I/F 내용', 'I/F 내용')}</label>
            <CustomInputText
              onChange={(e) =>
                setSearchConditon({ ...searchCondition, searchItem: e.target.value })
              }
            ></CustomInputText>
          </div>
        </SearchRows>
        <SearchButtons className="searchButtons">
          <GreyButton className="buttonSrch" onClick={handleSearch}>
            <SearchIcon /> {t('com.button.조회', '조회')}
          </GreyButton>
        </SearchButtons>
      </SearchBox>
      <ContentSection>
        <GridInfoSection>
          <GridInfo>
            <span>{t('admin.label.I/F 로그목록', 'I/F 로그목록')}</span>
            <span className="primary">
              {t('com.label.총', '총')} {ifLogList.totalCount}
              {t('com.label.건', '건')}
            </span>
          </GridInfo>
        </GridInfoSection>
        <ContentGrid className="ag-theme-alpine" style={{ height: '450px' }}>
          <AgGridReact
            overlayNoRowsTemplate="No rows to show"
            rowData={ifLogList.list}
            columnDefs={columnDefs}
            suppressPaginationPanel={true}
            defaultColDef={defaultColum}
          ></AgGridReact>
        </ContentGrid>
        <Pagination
          ref={pageRef}
          totalCount={ifLogList.totalCount}
          onChangePageSize={handleChangePageSize}
          onClickPageNo={handleClickPageNo}
          defaultPageNo={pageNo}
          defaultPageSize={pageSize}
        />
      </ContentSection>
      <Dialog open={dialogProp.open} onClose={handleClose} css={di.dialog}>
        <DialogTitle className="popupTitle">
          {t('admin.label.I/F 내용/결과', 'I/F 내용/결과')}
          <IconButton className="buttonClose" onClick={handleClose}>
            <CloseIcon fontSize="large"></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent className="popupContent">
          <FormControl>
            <ViewTable>
              <colgroup>
                <col width="30%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.발생일', '발생일')}</label>
                  </th>
                  <td>
                    <CustomInputText value={dialogProp.ifDtm} disabled></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.송/수신 구분', '송/수신 구분')}</label>
                  </th>
                  <td>
                    <CustomInputText value={dialogProp.divsNm} disabled></CustomInputText>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.I/F 내용', 'I/F 내용')}</label>
                  </th>
                  <td>
                    <CustomTextarea value={dialogProp.content} disabled></CustomTextarea>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label>{t('admin.label.I/F 결과', 'I/F 결과')}</label>
                  </th>
                  <td>
                    <CustomTextarea value={dialogProp.result} disabled></CustomTextarea>
                  </td>
                </tr>
              </tbody>
            </ViewTable>
          </FormControl>
        </DialogContent>
        <DialogActions className="popupBottom">
          <GreyLineButton onClick={handleClose}>{t('com.button.닫기', '닫기')}</GreyLineButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default IfLogManagementPage;
