/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getHealthCheck } from 'apis/common/Sample';
import useSessionStore from 'stores/useSessionStore';
import { NoticeModal } from 'pages/admin/noticemanagement/NoticeModal';
import { NoticePostDetail } from 'models/admin/Notice';
import { findNoticePopupPost } from 'apis/admin/Notice';
import styled from '@emotion/styled';

import { BlueButton, GreyButton } from 'components/buttons/CustomButton';

import { useLoading } from 'components/process/Loading';

const SampleMainPage = () => {
  const { userId } = useSessionStore();
  const [noticePopups, setNoticePopups] = useState<NoticePostDetail[]>([]);
  const { openLoading } = useLoading();

  useEffect(() => {
    if (userId.length > 0) {
      (async () => {
        const noticePopupPosts = await findNoticePopupPost();
        noticePopupPosts && setNoticePopups(noticePopupPosts);
      })();
    }
  }, [userId]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContainer>
        <CardTitle>Main</CardTitle>
        <hr />
        <Link to="/dev/login">
          <GreyButton>Dev Login</GreyButton>
        </Link>
      </CardContainer>

      <CardContainer>
        <CardTitle>로딩 테스트</CardTitle>
        <GreyButton
          onClick={() => {
            openLoading(true);
            setTimeout(() => openLoading(false), 1000);
          }}
        >
          1초간 로딩!
        </GreyButton>
      </CardContainer>

      <CardContainer>
        <CardTitle>Sample</CardTitle>
        <hr />
        <Link to="/main/legal-affairs-manager">
          <GreyButton>Main Page</GreyButton>
        </Link>
        <br />
        <Link to="/sample/sample">
          <GreyButton>Common Sample Page</GreyButton>
        </Link>
        <br />
        <Link to="/sample/dropzone">
          <GreyButton>Dropzone Sample Page</GreyButton>
        </Link>
        <br />
        <Link to="/sample/biz">
          <GreyButton>Biz Sample Page</GreyButton>
        </Link>
        <br />
        <Link to="/sample/contract-template">
          <GreyButton>비검토계약서 작성</GreyButton>
        </Link>
      </CardContainer>

      <CardContainer>
        <CardTitle>HealthCheck</CardTitle>
        <hr />
        <BlueButton
          style={{ width: '150px' }}
          onClick={async () => {
            const response = await getHealthCheck();
          }}
        >
          HealthCheck
        </BlueButton>
      </CardContainer>
      <>
        {noticePopups.map((notice) => (
          <NoticeModal
            key={notice.bbmNo}
            bbmNo={notice.bbmNo}
            bbmTitNm={notice.bbmTitNm}
            bbmCtn={notice.bbmCtn}
            poupEpsNuseDdn={Number(notice.poupEpsNuseDdn)}
          />
        ))}
      </>
    </div>
  );
};

const CardContainer = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
  padding: 20px;
  align-content: space-around;
  hr {
    width: 100%;
  }
  justify-items: start;
  display: grid;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
export default SampleMainPage;
