/** @jsxImportSource @emotion/react */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import GatingJudgeResultReqPopUp from 'pages/gtng/popup/GatingJudgeResultReqPopUp';
import GatingChangeRequest, {
  GatingReqType,
} from 'pages/gtng/gatingcheckresult/GatingChangeRequest';
import { convertJson } from 'utils/FormatUtil';
import ParameterManagementReqModal from 'components/modals/ip/ParameterManagementReqModal';
import MpEquipmentClassificationRequest from 'pages/mp/MpEquipmentClassificationRequest';
import UtMatrixReviewPage from '../ut/UtMatrixReviewPage';
import UtMatrixReviewRequest from '../ut/UtMatrixReviewRequest';

export enum ApproveRequestPageType {
  GATING_JUDGE_RESULT_REQ = 'GATING_JUDGE_RESULT_REQ',
  GATING_CANCEL = 'GATING_CANCEL',
  GATING_STATUS_CHANGE = 'GATING_STATUS_CHANGE',
  GATING_DATE_CHANGE = 'GATING_DATE_CHANGE',
  IP_APPROVE_REQ = 'IP_APPROVE_REQ',
  IP_UPDATE_REQ = 'IP_UPDATE_REQ',
  IP_DELETE_REQ = 'IP_DELETE_REQ',
  MP_CLSF_REQ = 'MP_CLSF_REQ',
  MP_CLSF_UPDATE_REQ = 'MP_CLSF_UPDATE_REQ',
  MP_CLSF_DELETE_REQ = 'MP_CLSF_DELETE_REQ',
  UT_REVIEW_REQ = 'UT_REVIEW_REQ',
}

type Props = {
  pageId: string;
  condition?: any;
  isReadOnly?: boolean;
  aprReqId: string;
};

const ApproveRequestDetailCase = (props: Props, ref) => {
  const { pageId, isReadOnly = false, aprReqId } = props;
  const { t } = useTranslation();
  const childRef = useRef<any>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [condition, setCondition] = useState(convertJson(props.condition));

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (!childRef.current?.validate) {
        return true;
      }
      return childRef.current?.validate?.();
    },
    getBizReqData: (aprReqId?: string, reqRsn?: string) => {
      return childRef.current?.getBizReqData?.(aprReqId, reqRsn);
    },
  }));

  useEffect(() => setCondition(convertJson(props.condition)), [props.condition]);
  return (
    condition && (
      <AccodianWrap className="marginTop">
        <Accordion expanded={isExpanded} onChange={() => setIsExpanded((prev) => !prev)}>
          <AccordionSummary>{t('com.label.결재 내용', '결재 내용')}</AccordionSummary>
          <AccordionDetails>
            {
              {
                GATING_JUDGE_RESULT_REQ: (
                  <GatingJudgeResultReqPopUp
                    ref={childRef}
                    condition={{
                      gatingId: condition?.gatingId,
                    }}
                  />
                ),
                GATING_CANCEL: (
                  <GatingChangeRequest
                    ref={childRef}
                    reqTp={GatingReqType.GATING_CANCEL}
                    gatingId={condition?.gatingId}
                    aprReqId={aprReqId}
                    isReadOnly={isReadOnly}
                  />
                ),
                GATING_STATUS_CHANGE: (
                  <GatingChangeRequest
                    ref={childRef}
                    reqTp={GatingReqType.STATUS_CHANGE}
                    gatingId={condition?.gatingId}
                    equipmentId={condition?.equipmentId}
                    aprReqId={aprReqId}
                    isReadOnly={isReadOnly}
                  />
                ),
                GATING_DATE_CHANGE: (
                  <GatingChangeRequest
                    ref={childRef}
                    reqTp={GatingReqType.DATE_CHANGE}
                    gatingId={condition?.gatingId}
                    equipmentId={condition?.equipmentId}
                    aprReqId={aprReqId}
                    isReadOnly={isReadOnly}
                  />
                ),
                IP_APPROVE_REQ: (
                  <ParameterManagementReqModal
                    ref={childRef}
                    aprReqId={aprReqId}
                    condition={condition}
                  />
                ),
                IP_UPDATE_REQ: (
                  <ParameterManagementReqModal
                    ref={childRef}
                    aprReqId={aprReqId}
                    condition={condition}
                  />
                ),
                IP_DELETE_REQ: (
                  <ParameterManagementReqModal
                    ref={childRef}
                    aprReqId={aprReqId}
                    condition={condition}
                  />
                ),
                MP_CLSF_REQ: (
                  <MpEquipmentClassificationRequest
                    ref={childRef}
                    aprReqId={aprReqId}
                    mode={condition?.mode}
                    requestMasterList={condition?.requestMasterList}
                    isReadOnly={isReadOnly}
                  />
                ),
                MP_CLSF_UPDATE_REQ: (
                  <MpEquipmentClassificationRequest
                    ref={childRef}
                    aprReqId={aprReqId}
                    mode={condition?.mode}
                    requestMasterList={condition?.requestMasterList}
                    isReadOnly={isReadOnly}
                  />
                ),
                MP_CLSF_DELETE_REQ: (
                  <MpEquipmentClassificationRequest
                    ref={childRef}
                    aprReqId={aprReqId}
                    mode={condition?.mode}
                    requestMasterList={condition?.requestMasterList}
                    isReadOnly={isReadOnly}
                  />
                ),
                UT_REVIEW_REQ: (
                  <UtMatrixReviewRequest
                    ref={childRef}
                    aprReqId={aprReqId}
                    mode={condition?.mode}
                    utmId={condition?.utmId}
                    isReadOnly={isReadOnly}
                  />
                ),
              }[pageId]
            }
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    )
  );
};

export default forwardRef(ApproveRequestDetailCase);
