/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ComboBox } from 'components/selects/ComboBox';
import { SearchCols } from 'components/layouts/SearchBox';
import { CustomSelect } from 'components/selects/CustomSelect';

// 프로퍼티 타입 지정
SearchSelectBox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  isRefreah: PropTypes.bool,

  options: PropTypes.any,
  onChange: PropTypes.any,
  keyword: PropTypes.any,
};

export default function SearchSelectBox(props) {
  const { t } = useTranslation();
  const [current, setCurrent] = useState<any>(props.defaultValue);
  const [options, setOptions] = useState<Code[]>([]);

  useEffect(() => {
    setCurrent('');
    const result = {
      id: props.id,
      value: '',
    };
    props.onChange(result);
  }, [props.isRefreah]);

  useEffect(() => {
    setCurrent(props.defaultValue);
  }, [props.defaultValue]);

  const handleOnChange = (value: any) => {
    setCurrent(value);
    const result = {
      id: props.id,
      value: value,
    };
    props.onChange(result);
  };

  useEffect(() => {
    setOptions(props.options);

    props.keyword &&
      (async function () {
        const result = await getCommonCodeNames(props.keyword);
        if (result != null) setOptions(result);
      })();
  }, []);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const labelId = 'com.label.' + props.name;
  const labelText = '' + props.name; //'__' + props.name;

  return (
    <>
      <label>{t(labelId, labelText)}</label>
      <ComboBox
        placeholder={props.placeholder}
        options={options}
        defaultValue={current}
        onChange={handleOnChange}
      />
    </>
  );
}
