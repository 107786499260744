/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { useMessageBar } from 'hooks/useMessageBar';
import { IconButton } from 'components/buttons/IconSVG';
import { tb } from 'components/layouts/Table';
import { form } from 'components/inputs/CustomInput';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomDialog from 'components/modals/common/CustomDialog';
import { MpItemCop } from 'models/mp/MpItemCop';
import { findMpItemCop, saveMpItemCop } from 'apis/mp/MpItemCop';
import { CrudCode } from 'models/common/Edit';

type Props = {
  open: boolean;
  close: () => void;
  itemId: string;
  isOptional?: boolean;
  isReadOnly?: boolean;
  onCallback?: (item: MpItemCop[]) => void;
};

const MpItemCopModal = ({
  open,
  close,
  itemId,
  isOptional = true,
  isReadOnly = false,
  onCallback,
}: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [errors, setErrors] = useState<any>({});
  const [mpItemCops, setMpItemCops] = useState<MpItemCop[]>([]);

  useEffect(() => {
    if (itemId) {
      handleSearch();
    }
  }, [itemId]);
  const handleClose = () => {
    if (onCallback && typeof onCallback === 'function') {
      onCallback(mpItemCops);
    }
    close();
  };

  const handleSearch = () => {
    findMpItemCop(itemId).then((result) => setMpItemCops(result));
  };

  const handleItemChange = (index: number, name: string, value: string, errorKey?: string) => {
    const newMpItemCops = [...mpItemCops];
    newMpItemCops[index]['crudKey'] = CrudCode.UPDATE;
    newMpItemCops[index][name] = value;
    setMpItemCops(newMpItemCops);

    if (errorKey) {
      setErrors({
        ...errors,
        [errorKey]: false,
      });
    }
  };

  const validate = () => {
    const targetCnt = (mpItemCops || []).filter((o) => o.tgtYn === 'Y').length;
    if (isOptional && !targetCnt) {
      return true;
    }

    const err = {};
    (mpItemCops || []).map((o) => {
      if (o.tgtYn !== 'Y' && !o.unreRsnCtn) {
        Object.assign(err, { [`unreRsnCtn_${o.copCd}`]: true });
      }
    });
    setErrors(err);

    // 수평전개인 경우 대상법인 필수값 (1개 이상 대상 설정 필요)
    if (!targetCnt) {
      openMessageBar({
        type: 'error',
        content: t(
          'mp.msg.설정된 대상이 없습니다.<br/>대상법인을 설정해 주세요.',
          '설정된 대상이 없습니다.<br/>대상법인을 설정해 주세요.'
        ),
      });
      return false;
    }

    if (!Object.keys(err).filter((k) => err[k]).length) {
      return true;
    }
    return false;
  };

  const handleSave = async () => {
    if (validate()) {
      const rows = (mpItemCops || []).filter((o) => o.crudKey === CrudCode.UPDATE);
      if (rows.length) {
        const res = await saveMpItemCop(itemId, mpItemCops);
        openMessageBar({
          type: res.successOrNot === 'Y' ? 'confirm' : 'error',
          content:
            res.successOrNot === 'Y'
              ? t('com.label.저장되었습니다.', '저장되었습니다.')
              : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
        });
      }
      handleClose();
    }
  };

  const dialogButtons = [
    <Button key={'confirm'} css={IconButton.button} className="confirm" onClick={handleSave}>
      {t('com.button.적용', '적용')}
    </Button>,
  ];

  return (
    <CustomDialog
      open={true}
      title={`설비변경점/수평전개 Item 대상 법인 ${isReadOnly ? '' : '선택'}`}
      size={'md'}
      buttons={isReadOnly ? [] : dialogButtons}
      onCancel={close}
      onClose={close}
    >
      <TableContainer css={tb.tableCol}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>법인</TableCell>
              <TableCell>대상</TableCell>
              <TableCell>비대상</TableCell>
              <TableCell>비대상 사유</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(mpItemCops || []).map((o, index) => (
              <TableRow key={o.copCd}>
                <TableCell>{o.copCd}</TableCell>
                <TableCell>
                  <RadioGroup
                    css={form.radioGroup}
                    className="center"
                    row
                    aria-labelledby={`tgt_y_label_${o.copCd}`}
                    name={`tgt_yn_radio_${o.copCd}`}
                  >
                    <FormControlLabel
                      id={`tgt_y_label_${o.copCd}`}
                      label=""
                      value="Y"
                      checked={o.tgtYn === 'Y'}
                      control={<Radio />}
                      onChange={(e, checked) =>
                        !isReadOnly &&
                        handleItemChange(
                          index,
                          'tgtYn',
                          checked ? 'Y' : 'N',
                          `unreRsnCtn_${o.copCd}`
                        )
                      }
                    />
                  </RadioGroup>
                </TableCell>
                <TableCell>
                  <RadioGroup
                    css={form.radioGroup}
                    className="center"
                    row
                    aria-labelledby={`tgt_n_label_${o.copCd}`}
                    name={`tgt_yn_radio_${o.copCd}`}
                  >
                    <FormControlLabel
                      id={`tgt_n_label_${o.copCd}`}
                      label=""
                      value="N"
                      checked={o.tgtYn !== 'Y'}
                      onChange={(e, checked) =>
                        !isReadOnly && handleItemChange(index, 'tgtYn', checked ? 'N' : 'Y')
                      }
                      control={<Radio />}
                    />
                  </RadioGroup>
                </TableCell>
                <TableCell>
                  {isReadOnly ? (
                    o?.unreRsnCtn
                  ) : (
                    <CustomInputWithSearch
                      name="unreRsnCtn"
                      placeholder={String(
                        t('mp.msg.비대상 사유를 입력해 주세요.', '비대상 사유를 입력해 주세요.')
                      )}
                      value={o?.unreRsnCtn}
                      onChange={(e) =>
                        handleItemChange(
                          index,
                          e.target.name,
                          e.target.value,
                          `unreRsnCtn_${o.copCd}`
                        )
                      }
                      isError={errors[`unreRsnCtn_${o.copCd}` || '']}
                      msgError={String(
                        t('mp.msg.비대상 사유를 입력해 주세요.', '비대상 사유를 입력해 주세요.')
                      )}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CustomDialog>
  );
};

export default MpItemCopModal;
