/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CrudCode } from 'models/common/Edit';
import { Button } from '@mui/material';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { GatingRegist, GridInitCondition } from 'models/gtng/GatingRegist';
import {
  findGatingRegistMst,
  insertGatingChkEquip,
  saveGatingRegistMst,
} from 'apis/gtng/GatingRegist';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { GatingCheckSheetVerPopUp } from 'pages/common/popup/GatingCheckSheetVerPopUp';
import { GatingTaskNamingRulePopUp } from 'pages/gtng/popup/GatingTaskNamingRulePopUp';
import { GatingEquipPopUp } from 'pages/common/popup/GatingEquipPopUp';
import { IconButton } from 'components/buttons/IconSVG';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { Code } from 'models/common/CommonCode';
import { GatingContListPopUp } from '../popup/GatingContListPopUp';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import useSessionStore from 'stores/useSessionStore';
import { GatingEquipContRetrievePopUp } from '../popup/GatingEquipContRetrievePopUp';
import GatingCheckSheetVerRetrievePopUp from '../popup/GatingCheckSheetVerRetrievePopUp';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import WJCellTextarea from 'components/inputs/WJCellTextarea';
import useEvent from 'react-use-event-hook';
import * as input from '@grapecity/wijmo.input';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import moment from 'moment';
import CustomGrid from 'components/grids/CustomGrid';
import ApproveRequestModal from 'pages/approves/ApproveRequestModal';
import { ApproveRequestPageType } from 'pages/approves/ApproveRequestDetailCase';
import { checkAvailableRequest } from 'apis/gtng/GatingChangeRequest';
import { GatingReqType } from '../gatingcheckresult/GatingChangeRequest';
import { getExcelFileName } from 'utils/ExcelUtil';
import { hasRole } from 'utils/SessionUtil';
import UserModal from 'components/modals/common/UserModal';
import { Employee } from 'models/admin/Employee';

/**
 * Gating 생성/조회 그리드
 * @param props
 * @constructor
 */
const GatingRegistGrid = (props: any) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();
  const { userId } = useSessionStore();

  const [rowData, setRowData] = useState<GatingRegist[]>([]); //grid 게이팅리스트
  const [delData, setDelData] = useState<GatingRegist[]>([]); //삭제상태의 grid row data

  const [code, setCode] = useState<GridInitCondition>(); //공통코드

  const [disable, setDisable] = useState<any>(true); //점검중장비변경 상태에 따른 활성/비활성
  const [value, setValue] = useState<string>(''); //점검중장비변경 점검장비 column의 value값
  const [isOpenTaskNamingRulePopUp, setOpenTaskNamingRulePopUp] = useState<any>(false); //점검중장비변경 팝업 open/close
  const [getGatingStepCode, setGatingStepCode] = useState<Code[]>([]);
  const [getGatingTypeCode, setGatingTypeCode] = useState<Code[]>([]);
  const [getProductTypeCode, setProductTypeCode] = useState<Code[]>([]);
  const [userModalCondition, setUserModalCondition] = useState({
    defaultUserId: '',
    target: {
      binding: '',
      row: -1,
      col: -1,
    },
  });

  /**
   * 조회조건 영역 공통코드 가져오기
   */
  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  const [getUseYn, setUseYn] = useState<Code[]>([]);
  const useYn = new DataMap(getUseYn, 'cmnCd', 'cmnCdNm');

  //gatingTypeCode optValCtn1에 따라 gatingStepCode 값이 달라진다.
  const getCommonCodesForGrid = async () => {
    const gatingTypeCode = await getCommonCodeNames('GATING_TYPE_CODE');
    const gatingStepCode = await getCommonCodeNames('GATING_STEP_CODE');
    const productTypeCode = await getCommonCodeNames('GATING_PRODUCT_TYPE_CODE');
    const useYnCode = await getCommonCodeNames('YN_FLAG');
    setGatingTypeCode(gatingTypeCode);
    setGatingStepCode(gatingStepCode);
    setProductTypeCode(productTypeCode);
    setUseYn(useYnCode);
    setCode({
      gatingTypeCode: gatingTypeCode,
      gatingStepCode: gatingStepCode,
      productTypeCode: productTypeCode,
    });
  };

  const [flexRef, setFlexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [initParam, setInitParam] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
  const [colWidthMap, setColWidthMap] = useState<any>({
    productTypeCode: 140,
    gatingName: 170,
    gatingTypeCode: 150,
    gatingStepCode: 150,
    versionNo: 100,
    gatingContIds: 150,
    gatingContTlIds: 150,
    aprReqId: 150,
    gatingEquipIds: 250,
    startDate: 100,
    endDate: 100,
    goodBasisScore: 100,
    levelScore: 100,
    gatingNo: 200,
    progressStatusCode: 100,
    countB1: 100,
    countB2: 100,
    countB3: 100,
    countB4: 100,
  });
  const [isOpenCheckSheetPopup, setOpenCheckSheetPopup] = useState(false);
  const [isOpenCheckSheetVerRetrievePopUp, setCheckSheetVerRetrievePopUp] = useState(false);
  const [isOpenGatingContListPopUp, setGatingContListPopUp] = useState(false);
  const [isOpenGatingContPopUp, setGatingContPopUp] = useState(false);
  const [isOpenGatingEquipPopUp, setGatingEquipPopUp] = useState(false);
  const [isOpenGatingEquipRetreivePopUp, setGatingEquipRetreivePopUp] = useState(false);
  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [requestModalCondition, setRequestModalCondition] = useState<any>({});
  const [contPopTitle, setContPopTitle] = useState<string>(''); //검색할 파라미터 상태관리
  const [popupGatingStepCode, setPopupGatingStepCode] = useState<string>('');
  const [popupGatingTypeCode, setPopupGatingTypeCode] = useState<string>('');
  const [gatingItem, setGatingItem] = useState();
  const [gatingProgressStatusCode, setGatingProgressStatusCode] = useState<string>('');
  const [checkedItems, setCheckedItems] = useState<GatingRegist[]>();

  const flexGridRef = React.useRef<any>(null);

  const OnInitialized = (grid) => {
    // new Selector(grid, {
    //   showCheckAll: false,
    //   itemChecked: (s, e) => {
    //     // grid.hostElement.addEventListener('click', (e) => {
    //     //   const ht = grid.hitTest(e);
    //     //   grid.rows.forEach((r) => {
    //     //     if (r.dataIndex !== ht.row) {
    //     //       r.isSelected = false;
    //     //     }
    //     //   });
    //     // });
    //     const row = grid.rows.filter((r) => r.isSelected);
    //     if (row.length > 0) {
    //       if (row[0].dataItem.progressStatusCode == 'B') {
    //         setDisable(false);
    //       }
    //     } else {
    //       setDisable(true);
    //     }
    //   },
    // });

    setFlexRef(grid);

    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        const item = panel.rows[row].dataItem;
        if (
          'countB1' === binding ||
          'countB2' === binding ||
          'countB3' === binding ||
          'countB4' === binding ||
          'result' === binding
        ) {
          const pin = cell.getElementsByClassName('wj-elem-pin');
          pin && pin.length && pin[0].remove();
        }
      }
    };

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      // grid.startEditing(true);

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;
      if (e.target instanceof HTMLButtonElement) {
        setflexItem(item);
        setHitTest(ht);
        setFieldId(binding);
        const gatingStepCode = item.gatingStepCode;
        const gatingTypeCode = item.gatingTypeCode;
        switch (e.target.id) {
          case 'btnPlay':
            // fnSearchDetailBtn(item);
            break;
          case 'btnCheckSheetPopup':
            setOpenCheckSheetPopup(true);
            setInitParam(item.gatingTemplateId);
            setPopupGatingStepCode(gatingStepCode);
            setPopupGatingTypeCode(gatingTypeCode);
            break;
          case 'btnCheckSheetVerRetrievePopUp':
            setCheckSheetVerRetrievePopUp(true);
            setInitParam(item.gatingTemplateId);
            setPopupGatingStepCode(gatingStepCode);
            setPopupGatingTypeCode(gatingTypeCode);
            break;
          case 'checkResult': //진행현황
            fnSearchCheckResultBtn(item);
            break;
          case 'judgeResult': //판정결과
            fnSearchJudgeResultBtn(item);
            break;
          case 'resultReport': //완료문서(결과리포트)
            fnSearchResultReportBtn(item);
            break;
          case 'btnGatingContIds':
            setGatingContPopUp(true);
            setUserModalCondition({
              defaultUserId: item.gatingContIds,
              target: {
                binding: binding,
                row: ht.row,
                col: ht.col,
              },
            });
            // setInitParam(item.gatingContIds);
            break;
          case 'btnGatingContIdsList':
            setContPopTitle(String(t('gtng.label.등록 담당자', '등록담당자')));
            setGatingContListPopUp(true);
            setInitParam(item.gatingContIds);
            break;
          case 'btnGatingContTlIds':
            setGatingContPopUp(true);
            setUserModalCondition({
              defaultUserId: item.gatingContTlIds,
              target: {
                binding: binding,
                row: ht.row,
                col: ht.col,
              },
            });
            // setInitParam(item.gatingContTlIds);
            break;
          case 'btnGatingContTlIdsList':
            setGatingContListPopUp(true);
            setInitParam(item.gatingContTlIds);
            setContPopTitle(String(t('gtng.label.등록 T/L', '등록T/L')));
            break;
          case 'btnGatingEquipIds':
            setGatingEquipPopUp(true);
            setGatingItem(item);
            setGatingProgressStatusCode('');
            break;
          case 'btnGatingEquipContIdsList':
            setGatingEquipRetreivePopUp(true);
            setInitParam(item.gatingId);
            setContPopTitle(String(t('gtng.label.점검장비', '점검장비')));
            setGatingProgressStatusCode('');
            break;
          case 'aprReqId':
            setRequestModalCondition({
              pageId: item?.aprReqType,
              gatingId: item?.gatingId,
              aprReqId: item?.aprReqId,
            });
            setOpenRequestModal(true);
            break;
        }
      }
    });

    grid.resizingColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.cellEditEnding.addHandler((s, e) => {
      const binding = grid.columns[e.col].binding;
      const newValue = s.activeEditor?.value;
      const data = grid.rows[e.row].dataItem;

      if (binding === 'levelScore') {
        if (data.goodBasisScore != '' && newValue >= data.goodBasisScore) {
          alert(t('gtng.msg.OK 기준 이하로 입력하세요.', 'OK 기준 이하로 입력하세요.'));
          e.cancel = true;
          e.stayInEditMode = true;
          return;
        }
      }
      if (binding === 'goodBasisScore') {
        if (data.levelScore != '' && newValue <= data.levelScore) {
          alert(t('gtng.msg.C-OK 기준 이상으로 입력하세요.', 'C-OK 기준 이상으로 입력하세요.'));
          e.cancel = true;
          e.stayInEditMode = true;
          return;
        }
      }
    });
  };

  const exportFormat = (args) => {
    const p = args.panel;
    const row = args.row;
    const col = args.col;
    const xlsxCell = args.xlsxCell;
    if (p.columns[col].binding === 'gatingContIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingContTlIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingEqpContData') {
      const cell = args.getFormattedCell();
      xlsxCell.value = '';
    }
  };

  const beginningEdit = useEvent((grid, e) => {
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;

    // readonly 설정
    if (['gatingContIds', 'gatingContTlIds', 'gatingEquipIds', 'versionNo'].includes(binding)) {
      e.cancel = true;
    }

    if (
      binding === 'gatingTypeCode' &&
      (item.crudKey === CrudCode.CREATE || item.crudKey === CrudCode.UPDATE)
    ) {
      if (
        item.gatingStepCode !== null &&
        item.gatingStepCode !== undefined &&
        item.gatingStepCode !== ''
      ) {
        item.gatingStepCode = '';
        item.versionNo = '';
      }
    }
    if (
      binding === 'gatingStepCode' &&
      (item.crudKey === CrudCode.CREATE || item.crudKey === CrudCode.UPDATE)
    ) {
      const code = getGatingStepCode.filter((code) => {
        return code.cmnCd?.substring(0, 1) == item.gatingTypeCode;
      });
      col.dataMap = new DataMap(code, 'cmnCd', 'cmnCdNm');
      if (item.versionNo !== null && item.versionNo !== undefined && item.versionNo !== '') {
        item.versionNo = '';
      }
    }
  });

  const resultCheckSheetVerPopup = (fieldId, result) => {
    flexItem[fieldId] = result;
  };

  const resultEquipPopUp = (fieldId, result) => {
    const equipChangeList = result.equipChangeList;
    let equipmentIds = '';
    let equipmentNames = '';
    const startDate: any = [];
    const endDate: any = [];

    for (let i = 0; i < equipChangeList.length; i++) {
      equipmentIds += equipChangeList[i].equipmentId;
      equipmentNames += equipChangeList[i].equipmentName;
      startDate.push(equipChangeList[i].startDate);
      endDate.push(equipChangeList[i].endDate);

      if (i < equipChangeList.length - 1) {
        equipmentIds += ',';
        equipmentNames += ',';
      }
    }

    startDate.sort((a, b) => {
      return moment(a).diff(moment(b));
    });

    endDate.sort((a, b) => {
      return moment(a).diff(moment(b));
    });
    endDate.reverse();

    if (fieldId === 'gatingEquipIds') {
      flexItem[fieldId] = equipmentIds;
      if (flexItem['crudKey'] !== CrudCode.CREATE) {
        flexItem['crudKey'] = CrudCode.UPDATE;
      }
      flexItem['gatingEquipNames'] = equipmentNames;
      flexItem['gatingEqpContData'] = equipChangeList;
      flexItem['startDate'] = startDate[0];
      flexItem['endDate'] = endDate[0];
    }
  };

  useEffect(() => {
    fnSearchGatingRegistMst(props.searchParamData);
  }, [gatingProgressStatusCode]);

  //다운로드 버튼
  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
      formatItem: exportFormat,
    });
    book.sheets[0].name = 'gating';
    book.saveAsync(getExcelFileName(t('gtng.label.Gating 생성/조회', 'Gating 생성/조회')));
  };

  const gatingTypeCodeMap = new DataMap(getGatingTypeCode, 'cmnCd', 'cmnCdNm');
  const gatingStepCodeMap = new DataMap(getGatingStepCode, 'cmnCd', 'cmnCdNm');
  const productTypeCodeMap = new DataMap(getProductTypeCode, 'cmnCd', 'cmnCdNm');
  const layoutDefinition = [
    {
      header: 'gatingId',
      binding: 'gatingId',
      visible: false,
    },
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      header: String(t('com.label.상태', '상태')),
      binding: 'crudKey',
      width: 40,
      isReadOnly: true,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'productTypeCode',
      header: String(t('gtng.grid.Product Type', 'Product Type')),
      width: colWidthMap.productTypeCode,
      cssClass: 'WijmoSelect',
      dataMap: productTypeCodeMap,
    },
    {
      binding: 'gatingName',
      header: String(t('gtng.label.Gating Task명', 'Gating Task명')),
      width: colWidthMap.gatingName,
      align: 'left',
    },
    {
      binding: 'gatingTypeCode',
      header: String(t('gtng.label.Gating 유형', 'Gating 유형')),
      width: colWidthMap.gatingTypeCode,
      cssClass: 'WijmoSelect',
      dataMap: gatingTypeCodeMap,
      cellTemplate: (item) => {
        if (item.item.crudKey !== CrudCode.CREATE) {
          return `<span>${gatingTypeCodeMap.getDisplayValue(item.value)}</span>`;
        } else {
          return `<span>${item.text}</span>`;
        }
      },
    },
    {
      binding: 'gatingStepCode',
      header: String(t('gtng.label.Gating 단계', 'Gating 단계')),
      width: colWidthMap.gatingStepCode,
      cssClass: 'WijmoSelect',
      dataMap: gatingStepCodeMap,
      cellTemplate: (item) => {
        if (gatingStepCodeMap.getDisplayValue(item.value) == undefined) {
          return `<span>${item.text}</span>`;
        } else {
          return `<span>${gatingStepCodeMap.getDisplayValue(item.value)}</span>`;
        }
      },
    },
    {
      binding: 'versionNo',
      header: String(t('gtng.grid.Version', 'Version')),
      width: colWidthMap.versionNo,
      cssClass: 'WijmoPopup',
      cellTemplate: (item) => {
        if (item.item.crudKey == CrudCode.CREATE || item.item.progressStatusCode == 'A') {
          return `<span>${item.text}</span><button id="btnCheckSheetPopup" />`;
        } else {
          return `<span>${item.text}</span><button id="btnCheckSheetVerRetrievePopUp" />`;
        }
      },
    },
    {
      binding: 'emlSndoYn',
      header: String(t('ip.grid.메일발송여부', '메일발송여부')),
      cssClass: 'WijmoSelect',
      dataMap: useYn,
    },
    {
      binding: 'gatingContIds',
      header: String(t('gtng.grid.등록담당자명', '등록담당자명')),
      width: colWidthMap.gatingContIds,
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (_.isNull(item.item.gatingContIds) || item.item.gatingContIds === undefined) {
          return `<span>${item.text}</span><button id="btnGatingContIds" />`;
        } else {
          if (item.item.crudKey == CrudCode.CREATE || item.item.progressStatusCode == 'A') {
            return `<span>${item.item.gatingContNames}</span><button id="btnGatingContIds" />`;
          } else {
            return `<span>${item.item.gatingContNames}</span><button id="btnGatingContIdsList" />`;
          }
        }
      },
    },
    {
      binding: 'gatingContNames',
      header: String(t('gtng.grid.등록담당자명', '등록담당자명')),
      visible: false,
    },
    {
      binding: 'gatingContTlIds',
      header: String(t('gtng.label.등록 T/L', '등록 T/L')),
      width: colWidthMap.gatingContTlIds,
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (_.isNull(item.item.gatingContTlIds) || item.item.gatingContTlIds === undefined) {
          return `<span>${item.text}</span><button id="btnGatingContTlIds" />`;
        } else {
          if (item.item.crudKey == CrudCode.CREATE || item.item.progressStatusCode == 'A') {
            return `<span>${item.item.gatingContTlNames}</span><button id="btnGatingContTlIds" />`;
          } else {
            return `<span>${item.item.gatingContTlNames}</span><button id="btnGatingContTlIdsList" />`;
          }
        }
      },
    },
    {
      binding: 'gatingContTlNames',
      header: String(t('gtng.grid.등록T/L NAME', '등록T/L NAME')),
      visible: false,
    },
    {
      binding: 'gatingEquipIds',
      header: String(t('gtng.label.점검장비', '점검장비')),
      width: colWidthMap.gatingEquipIds,
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (_.isNull(item.item.gatingEquipIds) || item.item.gatingEquipIds === undefined) {
          return `<span>${item.text}</span><button id="btnGatingEquipIds" />`;
        } else {
          if (item.item.crudKey == CrudCode.CREATE || item.item.progressStatusCode == 'A') {
            return `<span>${item.item.gatingEquipIds}</span><button id="btnGatingEquipIds" />`;
          } else {
            return `<span>${item.item.gatingEquipIds}</span><button id="btnGatingEquipContIdsList" />`;
          }
        }
      },
    },
    {
      binding: 'gatingEquipNames',
      header: String(t('gtng.grid.점검장비명', '점검장비명')),
      visible: false,
    },
    {
      binding: 'gatingEqpContData',
      visible: false,
    },
    {
      binding: 'startDate',
      header: String(t('gtng.grid.시작일', '시작일')),
      width: colWidthMap.startDate,
      isReadOnly: true,
      format: 'yyyy.MM.dd',
      editor: new input.InputDate(document.createElement('div'), {
        format: 'yyyy.MM.dd',
      }),
    },
    {
      binding: 'endDate',
      header: String(t('gtng.grid.종료일', '종료일')),
      width: colWidthMap.endDate,
      isReadOnly: true,
      format: 'yyyy.MM.dd',
      editor: new input.InputDate(document.createElement('div'), {
        format: 'yyyy.MM.dd',
      }),
    },
    {
      binding: 'goodBasisScore',
      header: String(t('gtng.grid.OK 기준', 'OK 기준')),
      width: colWidthMap.goodBasisScore,
      editor: new input.InputNumber(document.createElement('div'), {
        isRequired: true,
      }),
    },
    {
      binding: 'levelScore',
      header: String(t('gtng.grid.C-OK 기준', 'C-OK 기준')),
      width: colWidthMap.levelScore,
      editor: new input.InputNumber(document.createElement('div'), {
        isRequired: true,
      }),
    },
    {
      binding: 'gatingNo',
      header: String(t('gtng.grid.Gating No', 'Gating No')),
      width: colWidthMap.gatingNo,
      isReadOnly: true,
    },
    {
      binding: 'progressStatusName',
      header: String(t('gtng.label.Gating 상태', 'Gating 상태')),
      width: colWidthMap.progressStatusCode,
      isReadOnly: true,
    },
    {
      binding: 'aprReqId',
      header: String(t('com.label.결재 내용', '결재 내용')),
      width: colWidthMap.aprReqId,
      cssClass: 'WijmoPopup',
      isReadOnly: true,
      cellTemplate: (params) => {
        if (params.value == null || params.value == '') {
          return '';
        } else {
          return `${params.value} <Button id="aprReqId" />`;
        }
      },
    },
    {
      binding: 'progressStatusCode',
      header: String(t('gtng.grid.Gating 상태코드', 'Gating 상태코드')),
      visible: false,
    },
    {
      header: String(t('gtng.grid.장비별점검상태', '장비별점검상태')),
      columns: [
        {
          binding: 'countB1',
          header: String(t('gtng.grid.점검준비', '점검준비')),
          width: colWidthMap.countB1,
          isReadOnly: true,
        },
        {
          binding: 'countB2',
          header: String(t('gtng.label.중간점검', '중간점검')),
          width: colWidthMap.countB1,
          isReadOnly: true,
        },
        {
          binding: 'countB3',
          header: String(t('gtng.label.최종점검', '최종점검')),
          width: colWidthMap.countB1,
          isReadOnly: true,
        },
        {
          binding: 'countB4',
          header: String(t('gtng.grid.점검완료', '점검완료')),
          width: colWidthMap.countB1,
          isReadOnly: true,
        },
      ],
    },
    {
      header: String(t('gtng.grid.점검결과등록', '점검결과등록')),
      binding: 'result',
      width: 100,
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: (item) => {
        if (item.item.progressStatusCode == 'B') {
          return '<button id="checkResult"></button>';
        } else {
          return '';
        }
      },
    },
    {
      header: String(t('gtng.grid.Gating 판정', 'Gating 판정')),
      binding: 'result',
      width: 100,
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: (item) => {
        if (item.item.progressStatusCode == 'C') {
          return '<button id="judgeResult"></button>';
        } else {
          return '';
        }
      },
    },
    {
      header: String(t('gtng.grid.완료문서', '완료문서')),
      binding: 'result',
      width: 100,
      isReadOnly: true,
      cssClass: 'WijmoPlay',

      cellTemplate: (item) => {
        if (item.item.progressStatusCode == 'D') {
          return '<button id="resultReport"></button>';
        } else {
          return '';
        }
      },
    },
  ];

  /**
   * 조회조건 - 게이팅유형에 따른 게이팅단계변경
   */

  /**
   * grid - 게이팅유형에 따른 단계변경
   * row마다 개별적으로 변경하고 불러와야하기 때문에 cellRenderer 사용
   * 상태가 신규등록(null) or 등록(A) 상태일때는 수정가능
   */

  /**
   * 행 추가 crudKey 컬럼을 C로 변경
   */
  const btnAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
    } as GatingRegist;
    setRowData([newRow, ...rowData]);
  };

  /**
   * 행 복사 crudKey 컬럼을 C로 변경하고 복사열의 데이터를 가져옴
   * version, 시작일자, 종료일자 제외
   * 선택 X -> 경고창
   * 단일복사
   */
  const btnCopyRow = useCallback(() => {
    if (flexRef == null) {
      openMessageBar({
        type: 'error',
        content: `${t(
          'gtng.msg.Gating을 선택 후 복사하시기 바랍니다.',
          'Gating을 선택 후 복사하시기 바랍니다.'
        )}`,
      });
      return false;
    }

    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: `${t(
          'gtng.msg.Gating을 선택 후 복사하시기 바랍니다.',
          'Gating을 선택 후 복사하시기 바랍니다.'
        )}`,
      });
      return false;
    } else {
      const selectNo = 0; //단일 선택 복사 시 0번째
      const copiedData = isSelected[selectNo].dataItem;
      const newRow = {
        crudKey: CrudCode.CREATE,
        gatingName: copiedData?.gatingName,
        gatingTypeCode: copiedData?.gatingTypeCode,
        gatingStepCode: copiedData?.gatingStepCode,
        taskLeaderUserId: copiedData?.taskLeaderUserId,
        taskLeaderUserName: copiedData?.taskLeaderUserName,
        goodBasisScore: copiedData?.goodBasisScore,
        levelScore: copiedData?.levelScore,
        productTypeCode: copiedData?.productTypeCode,
        gatingContIds: copiedData?.gatingContIds,
        gatingContNames: copiedData?.gatingContNames,
        gatingContTlIds: copiedData?.gatingContTlIds,
        gatingContTlNames: copiedData?.gatingContTlNames,
        gatingEquipIds: copiedData?.gatingEquipIds,
        gatingEquipNames: copiedData?.gatingEquipNames,
        gatingEqpContData: copiedData?.gatingEqpContData,
        startDate: copiedData?.startDate,
        endDate: copiedData?.endDate,
      } as GatingRegist;
      setRowData([newRow, ...rowData]);
    }
  }, [rowData, flexGridRef]);

  /**
   * 행 삭제 상태코드(A, null) 인 경우에만 삭제 가능
   * 등록/점검/판정/완료 삭제불가
   * crudKey == 'D' 적용
   */
  const btnDelRow = useCallback(() => {
    if (flexRef == null) {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.Gating을 선택해 주세요.', 'Gating을 선택해 주세요.'),
      });
      return false;
    }

    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.Gating을 선택해 주세요.', 'Gating을 선택해 주세요.'),
      });
      return false;
    }
    const progressStatusCode = selectedRowNodes[0].dataItem?.progressStatusCode;
    if (progressStatusCode == 'A' || progressStatusCode == null) {
      const selectedIds = selectedRowNodes
        .map((rowNode) => {
          return parseInt(rowNode._idx!);
        })
        .reverse();

      selectedIds.forEach((idx) => {
        if (rowData[idx].crudKey === CrudCode.CREATE) {
          delete rowData[idx];
        } else {
          rowData[idx].crudKey = CrudCode.DELETE;
        }
      });

      const filteredDelData = [
        ...delData,
        ...rowData.filter((data) => data.crudKey == CrudCode.DELETE),
      ];
      setDelData(filteredDelData);

      const filteredData = rowData.filter((element) => element !== undefined);
      setRowData(filteredData);
    } else {
      openMessageBar({
        type: 'error',
        content: t(
          'gtng.msg.Gating상태가 등록인 경우만 삭제가 가능합니다.',
          'Gating상태가 등록인 경우만 삭제가 가능합니다.'
        ),
      });
      return false;
    }
  }, [rowData, flexGridRef]);

  // 1. 중복체크.
  // 2. rowData 바뀔때마다 DB 데이터 가져오기
  const [duplicationData, setDuplicationData] = useState<GatingRegist[]>([]); //DB 게이팅 Data

  useEffect(() => {
    const searchParam = {
      gatingTypeCode: '',
      gatingStepCode: '',
      progressStatusCode: '',
      productTypeCode: '',
      gatingName: '',
      startDate: '',
      endDate: '',
    };

    findGatingRegistMst(searchParam).then((result: GatingRegist[]) => {
      setDuplicationData(result);
    });
  }, [rowData]);

  /**
   * 저장
   * 필수 입력 사항에 대한 validation check
   */
  const btnSave = () => {
    const validStatusCorU = rowData.filter(
      (element) => element.crudKey == CrudCode.CREATE || element.crudKey == CrudCode.UPDATE
    );
    const valid = validStatusCorU
      .map((rowNode, index) => {
        if (rowNode.crudKey != 'D') {
          if (rowNode.productTypeCode == null || rowNode.productTypeCode == '')
            return `${t('gtng.msg.ProductType을 입력해 주세요.', 'ProductType을 입력해 주세요.')}`;
          if (rowNode.gatingName == null || rowNode.gatingName == '')
            return `${t(
              'gtng.msg.Gating Task명을 입력해 주세요.',
              'Gating Task명을 입력해 주세요.'
            )}`;
          if (rowNode.gatingName.length < 3)
            return `${t(
              'gtng.msg.GatingTask명은 3글자 이상 작성해 주세요.',
              'GatingTask명은 3글자 이상 작성해 주세요.'
            )}`;
          if (rowNode.gatingTypeCode == null || rowNode.gatingTypeCode == '')
            return `${t('gtng.msg.Gating 유형을 선택해 주세요.', 'Gating 유형을 선택해 주세요.')}`;
          if (rowNode.gatingStepCode == null || rowNode.gatingStepCode == '')
            return `${t('gtng.msg.Gating 단계를 선택해 주세요.', 'Gating 단계를 선택해 주세요.')}`;
          if (rowNode.versionNo == null || rowNode.versionNo == '')
            return `${t('gtng.msg.Version을 선택해 주세요.', 'Version을 선택해 주세요.')}`;
          if (rowNode.gatingContIds == null || rowNode.gatingContIds == '')
            return `${t('gtng.msg.등록담당자를 입력해 주세요.', '등록담당자를 입력해 주세요.')}`;
          if (rowNode.gatingContTlIds == null || rowNode.gatingContTlIds == '')
            return `${t('gtng.msg.등록T/L을 입력해 주세요.', '등록T/L을 입력해 주세요.')}`;
          if (rowNode.gatingEquipIds == null || rowNode.gatingEquipIds == '')
            return `${t('gtng.msg.점검장비를 입력해 주세요.', '점검장비를 입력해 주세요.')}`;
          if (rowNode.gatingEqpContData == null || rowNode.gatingEqpContData == undefined)
            return `${t(
              'gtng.msg.점검장비 입력 화면에서 설비별 실시담당자를 입력해 주세요.',
              '점검장비 입력 화면에서 설비별 실시담당자를 입력해 주세요.'
            )}`;
          if (rowNode.goodBasisScore == null || rowNode.goodBasisScore == undefined)
            return `${t('gtng.msg.OK기준 점수를 입력해 주세요.', 'OK기준 점수를 입력해 주세요.')}`;
          if (rowNode.goodBasisScore > 100)
            return `${t(
              'gtng.msg.OK기준은 100점을 초과할 수 없습니다.',
              'OK기준은 100점을 초과할 수 없습니다.'
            )}`;
          if (rowNode.levelScore == null || rowNode.levelScore == undefined)
            return `${t(
              'gtng.msg.C-OK기준 점수를 입력해 주세요.',
              'C-OK기준 점수를 입력해 주세요.'
            )}`;
          if (rowNode.levelScore > rowNode.goodBasisScore)
            return `${t(
              'gtng.msg.C-OK기준 점수는 OK기준 점수보다 낮아야 합니다.',
              'C-OK기준 점수는 OK기준 점수보다 낮아야 합니다.'
            )}`;
          if (rowNode.goodBasisScore < 0 || rowNode.levelScore < 0)
            return `${t(
              'gtng.msg.기준점수는 음수가 될 수 없습니다.',
              '기준점수는 음수가 될 수 없습니다.'
            )}`;
        }
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return false;
    }

    // Gating 생성/조회 중복 체크
    // DB = duplicationData
    // 신규데이터 = rowDataC + duplicationData
    // 수정데이터 = rowDataC + duplicationData - duplicationData(U되기전 데이터(gatingId로 체크)) + rowDataU
    // 삭제데이터 = rowDataC + duplicationData - rowDataD

    // 1. U상태의 gatingId를 가져옴
    // 2. DB에서 불러온 duplicationData에서 추출한 gatingId의 row들 제외
    // 3. duplicationData에 U상태의 row들 추가

    const updateRowData = rowData.filter((element) => element.crudKey == CrudCode.UPDATE);
    const fixData: any = [];
    if (updateRowData.length > 0) {
      const updGatingId = updateRowData.map((element) => element.gatingId);
      const fixedData = duplicationData.filter(
        (element) => !updGatingId.includes(element.gatingId)
      );
      fixedData.push(...updateRowData);
      fixData.push(...fixedData);
    }

    const newDuplData: any = [];
    const createRowData = rowData.filter((element) => element.crudKey == CrudCode.CREATE);
    if (updateRowData.length > 0) {
      newDuplData.push(...fixData, ...createRowData);
    } else {
      newDuplData.push(...duplicationData, ...createRowData);
    }

    const duplicationResult: any = [];
    for (let i = 0; i < newDuplData.length; i++) {
      const equipmentId = newDuplData[i].gatingEquipIds;
      const productTypeCode = newDuplData[i].productTypeCode;
      const gatingName = newDuplData[i].gatingName;
      const gatingTypeCode = newDuplData[i].gatingTypeCode;
      const gatingStepCode = newDuplData[i].gatingStepCode;
      const versionNo = newDuplData[i].versionNo;

      const gatingData = {
        productTypeCode: productTypeCode,
        gatingName: gatingName,
        gatingTypeCode: gatingTypeCode,
        gatingStepCode: gatingStepCode,
        versionNo: versionNo,
      };

      const splitEquipId = equipmentId?.split(',');

      const newDataArr: any = [];
      for (let i = 0; i < splitEquipId?.length; i++) {
        const newEquipment = { equipmentId: splitEquipId[i] };
        const newData: any = _.merge({}, gatingData, newEquipment);
        newDataArr.push(newData);
      }
      duplicationResult.push(...newDataArr);
    }

    const stringDuplicationResult = duplicationResult.map((element) => {
      return Object.entries(element).toString();
    });

    const setDuplicationResult = new Set(stringDuplicationResult);

    if (duplicationResult.length !== setDuplicationResult.size) {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.중복된 Gating이 있습니다.', '중복된 Gating이 있습니다.'),
      });
      return false;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        const saveRows = rowData
          .map((rowNode) => {
            return rowNode.crudKey ? rowNode : null;
          })
          .filter((element) => element !== null) as GatingRegist[];

        const saveRowsData = [...saveRows, ...delData];
        saveGatingRegistMst(saveRowsData).then((result) => {
          if (!result) {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return false;
          }
          openCommonModal({
            content:
              t('com.label.입력', '입력') +
              ' : ' +
              (result.insertedRows || 0) +
              t('com.label.건', '건') +
              '\n' +
              t('com.label.수정', '수정') +
              ' : ' +
              (result.updatedRows || 0) +
              t('com.label.건', '건') +
              '\n' +
              t('com.label.삭제', '삭제') +
              ' : ' +
              (result.deletedRows || 0) +
              t('com.label.건', '건'),
          });
          setDelData([]);
          fnSearchGatingRegistMst(props.searchParamData);
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  /**
   * 조회조건 검색
   * props.searchState : 같은 조건으로 다시 검색을 위한 상태변화
   */
  useEffect(() => {
    if (props.searchParamData != undefined) {
      fnSearchGatingRegistMst(props.searchParamData);
    }
  }, [props.searchParamData, props.searchState]);

  const fnSearchGatingRegistMst = (searchParamData) => {
    findGatingRegistMst(searchParamData).then((result: GatingRegist[]) => {
      setRowData(result);
    });
  };

  /**
   * 점검중 장비변경
   * 상태코드 B(점검) : 장비변경 팝업 / 그 외 : 경고
   */
  const btnChangeEquip = () => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected === true);

    if (isSelected.length === 0) {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.Gating을 선택해 주세요.', 'Gating을 선택해 주세요.'),
      });
      return false;
    } else {
      const progressStautsCode = isSelected[0].dataItem.progressStatusCode;

      if (progressStautsCode !== 'B') {
        openMessageBar({
          type: 'error',
          content: t(
            'gtng.msg.점검상태의 Gating만 변경 할 수 있습니다.',
            '점검상태의 Gating만 변경 할 수 있습니다.'
          ),
        });
        return false;
      } else {
        checkAvailableRequest(isSelected[0].dataItem.gatingId, GatingReqType.DATE_CHANGE)
          .then((res) => {
            checkAvailableRequest(isSelected[0].dataItem.gatingId, GatingReqType.STATUS_CHANGE)
              .then((res) => {
                setGatingEquipPopUp(true);
                setGatingItem(isSelected[0].dataItem);
              })
              .catch((res) => {
                openMessageBar({
                  type: 'error',
                  content:
                    res.data && typeof res.data === 'string'
                      ? res.data
                      : t(
                          'gtng.msg.진행중인 요청이 있어 장비변경할수 없습니다.',
                          '진행중인 요청이 있어 장비변경할수 없습니다.'
                        ),
                });
                return false;
              });
          })
          .catch((res) => {
            openMessageBar({
              type: 'error',
              content:
                res.data && typeof res.data === 'string'
                  ? res.data
                  : t(
                      'gtng.msg.진행중인 요청이 있어 장비변경할수 없습니다.',
                      '진행중인 요청이 있어 장비변경할수 없습니다.'
                    ),
            });
            return false;
          });
      }
    }
  };

  /**
   * 등록완료 권한 확인
   * @param item
   */
  const isValidRegistRole = (item) => {
    if (hasRole('NIGS_MANAGER') || hasRole('ADM')) {
      return true;
    }
    // 등록담당자, 등록T/L
    const gatingContIds = item?.gatingContIds?.split(',');
    const gatingContTlIds = item?.gatingContTlIds?.split(',');
    if (Array.isArray(gatingContIds) && gatingContIds.includes(userId)) {
      return true;
    }
    if (Array.isArray(gatingContTlIds) && gatingContTlIds.includes(userId)) {
      return true;
    }
    return false;
  };

  /**
   * 등록완료
   * 상태코드에 따른 validation check
   */
  const btnfinish = () => {
    const selectedRows = checkedItems || [];
    if (selectedRows.length > 0) {
      if (selectedRows.length > 1) {
        openMessageBar({
          type: 'error',
          content: t(
            'gtng.msg.등록 완료는 1건만 선택 가능합니다',
            '등록 완료는 1건만 선택 가능합니다'
          ),
        });
        return false;
      }
      const valid = selectedRows
        .map((item, index) => {
          if (item.progressStatusCode !== 'A') {
            return `${t(
              'gtng.msg.Gating 상태가 등록인 경우만 저장후 등록완료처리를 할수 있습니다.',
              'Gating 상태가 등록인 경우만 저장후 등록완료처리를 할수 있습니다.'
            )}`;
          }
          if (item.crudKey) {
            return `${t(
              'gtng.msg.수정중인 Gating이 있습니다. 저장 후 등록완료를 진행해 주세요.',
              '수정중인 Gating이 있습니다. 저장 후 등록완료를 진행해 주세요.'
            )}`;
          }
          // 등록완료 권한 확인 (등록담당자, 등록T/L)
          if (!isValidRegistRole(item)) {
            return `${t(
              'gtng.msg.등록완료는 등록담당자, 등록T/L만 가능합니다.',
              '등록완료는 등록담당자, 등록T/L만 가능합니다.'
            )}`;
          }
        })
        .filter((element) => element !== undefined);
      if (valid.length) {
        const content = valid[0]?.toString();
        openMessageBar({ type: 'error', content: content || '' });
        return false;
      }
      openCommonModal({
        modalType: 'confirm',
        content: t(
          'gtng.label.등록완료 처리후에는 데이터를 수정할 수 없습니다.',
          '등록완료 처리후에는 데이터를 수정할 수 없습니다.'
        ),
        yesCallback: () => {
          insertGatingChkEquip(selectedRows[0]).then((res) => {
            if (res.successOrNot === 'Y' && res.data === 'OK') {
              openMessageBar({
                type: 'confirm',
                content: t('gtng.msg.등록되었습니다.', '등록되었습니다.'),
              });
            } else {
              openMessageBar({
                type: 'error',
                content:
                  typeof res.data === 'string' && res.data
                    ? res.data
                    : t('gtng.msg.등록실패하였습니다.', '등록실패하였습니다.'),
              });
            }
            fnSearchGatingRegistMst(props.searchParamData);
          });
        },
        noCallback: () => {
          return false;
        },
      });
    } else {
      openMessageBar({
        type: 'error',
        content: t(
          'gtng.label.생성한 Gating을 먼저 선택해 주세요.',
          '생성한 Gating을 먼저 선택해 주세요.'
        ),
      });
      return false;
    }
  };

  /**
   * 조회조건 검색
   * progressStatus == 'B' -> Gating 평과/채점
   * GatingCheckResultPage
   */
  const fnSearchCheckResultBtn = (params: any) => {
    const searchParam = {
      gatingId: params.gatingId,
      gatingNo: params.gatingNo,
      equipmentId: params.equipmentId,
      equipmentName: params.equipmentName,
    };
    navigate('/gating/result/gating-check-result', { state: { sCheckResultParam: searchParam } });
  };

  /**
   * 조회조건 검색
   * progressStatus == 'C' -> Gating 판정 결과 Report
   * GatingJudgementResultPage
   */
  const fnSearchJudgeResultBtn = (params: any) => {
    const searchParam = {
      gatingId: params.gatingId,
      gatingNo: params.gatingNo,
    };
    navigate('/gating/result/gating-judge-report', { state: { sJudgeResultParam: searchParam } });
  };

  /**
   * 조회조건 검색
   * progressStatus == 'D' -> Gating 결과 report library
   * GatingResultReportPage
   */
  const fnSearchResultReportBtn = (params: any) => {
    const searchParam = {
      gatingId: params.gatingId,
      gatingNo: params.gatingNo,
      gatingName: params.gatingName,
      gatingTypeCode: params.gatingTypeCode,
      gatingStepCode: params.gatingStepCode,
    };
    navigate('/gating/result/gating-result-report', { state: { sResultReprotParam: searchParam } });
  };

  /**
   * 게이팅 취소 요청 버튼 클릭 이벤트
   */
  const handleGatingCancel = () => {
    if (checkedItems && checkedItems.length > 0) {
      if (checkedItems.length > 1) {
        openMessageBar({
          type: 'error',
          content: t(
            'gtng.msg.취소 요청할 대상을 1건만 선택해 주세요.',
            '취소 요청할 대상을 1건만 선택해 주세요.'
          ),
        });
        return;
      }
      checkAvailableRequest(checkedItems[0]?.gatingId, GatingReqType.GATING_CANCEL)
        .then((res) => {
          setRequestModalCondition({
            pageId: ApproveRequestPageType.GATING_CANCEL,
            gatingId: checkedItems[0]?.gatingId,
          });
          setOpenRequestModal(true);
        })
        .catch((res) => {
          openMessageBar({
            type: 'error',
            content: res.data || t('gtng.msg.취소 요청할 수 없습니다.', '취소 요청할 수 없습니다.'),
          });
        });
    } else {
      openMessageBar({
        type: 'error',
        content: t(
          'gtng.msg.취소 요청할 대상을 선택해 주세요.',
          '취소 요청할 대상을 선택해 주세요.'
        ),
      });
    }
  };

  /**
   * 점검일자 변경 요청 버튼 클릭 이벤트
   */
  const handleChangeDate = () => {
    console.log('----------------------');
    if (checkedItems && checkedItems.length > 0) {
      if (checkedItems.length > 1) {
        openMessageBar({
          type: 'error',
          content: t(
            'gtng.msg.일정변경 요청할 대상을 1건만 선택해 주세요.',
            '일정변경 요청할 대상을 1건만 선택해 주세요.'
          ),
        });
        return;
      }
      checkAvailableRequest(checkedItems[0]?.gatingId, GatingReqType.DATE_CHANGE)
        .then((res) => {
          setRequestModalCondition({
            pageId: ApproveRequestPageType.GATING_DATE_CHANGE,
            gatingId: checkedItems[0]?.gatingId,
          });
          setOpenRequestModal(true);
        })
        .catch((res) => {
          openMessageBar({
            type: 'error',
            content:
              res.data ||
              t('gtng.msg.일정변경 요청할 수 없습니다.', '일정변경 요청할 수 없습니다.'),
          });
        });
    } else {
      openMessageBar({
        type: 'error',
        content: t(
          'gtng.msg.일정변경 요청할 대상을 선택해 주세요.',
          '일정변경 요청할 대상을 선택해 주세요.'
        ),
      });
    }
  };

  /**
   * 점검단계 변경 요청 버튼 클릭 이벤트
   */
  const handleChangeStep = () => {
    if (checkedItems && checkedItems.length > 0) {
      if (checkedItems.length > 1) {
        openMessageBar({
          type: 'error',
          content: t(
            'gtng.msg.점검단계 요청할 대상을 1건만 선택해 주세요.',
            '점검단계 요청할 대상을 1건만 선택해 주세요.'
          ),
        });
        return;
      }
      checkAvailableRequest(checkedItems[0]?.gatingId, GatingReqType.STATUS_CHANGE)
        .then((res) => {
          setRequestModalCondition({
            pageId: ApproveRequestPageType.GATING_STATUS_CHANGE,
            gatingId: checkedItems[0]?.gatingId,
          });
          setOpenRequestModal(true);
        })
        .catch((res) => {
          openMessageBar({
            type: 'error',
            content:
              res.data ||
              t('gtng.msg.점검단계 요청할 수 없습니다.', '점검단계 요청할 수 없습니다.'),
          });
        });
    } else {
      openMessageBar({
        type: 'error',
        content: t(
          'gtng.msg.점검단계 요청할 대상을 선택해 주세요.',
          '점검단계 요청할 대상을 선택해 주세요.'
        ),
      });
    }
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 등록 List', 'Gating 등록 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
          {/* 안내문 class 종류는 default,error,warning,confirm,primary가 있습니다. */}
          <div className="info primary">
            {t(
              'gtng.label.Gating Task명은 (공장위치)-(센터)-(라인) 형식 [GM1-P1-01~10] 으로 작성해 주세요.',
              'Gating Task명은 (공장위치)-(센터)-(라인) 형식 [GM1-P1-01~10] 으로 작성해 주세요.'
            )}
          </div>
        </SubTitleGroup>
        {props.editable && (
          <ControlBtnGroup>
            <Button
              css={IconButton.button}
              className="rule"
              onClick={() => setOpenTaskNamingRulePopUp(true)}
              disableRipple
            >
              {t('gtng.button.Task명 Naming Rule', 'Task명 Naming Rule')}
            </Button>
            <Button
              css={IconButton.button}
              className="tool"
              onClick={btnChangeEquip}
              // disabled={disable}
              disableRipple
            >
              {t('gtng.button.점검중 장비변경', '점검중 장비변경')}
            </Button>
            <Button css={IconButton.button} className="addRow" onClick={btnAddRow} disableRipple>
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button css={IconButton.button} className="copyRow" onClick={btnCopyRow} disableRipple>
              {t('com.button.행복사', '행복사')}
            </Button>
            <Button css={IconButton.button} className="delRow" onClick={btnDelRow} disableRipple>
              {t('com.button.행삭제', '행삭제')}
            </Button>
            <Button
              css={IconButton.button}
              className="Exceldown"
              onClick={btnExcelExport}
              disableRipple
            >
              {t('com.button.다운로드', '다운로드')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        align="center"
        initialized={OnInitialized}
        isSelector={true}
        beginningEdit={beginningEdit}
        height={500}
        excludeFilter={['countB1', 'countB2', 'countB3', 'countB4', 'result']}
        loadedRows={(grid, e) => {
          grid.rows.forEach((r) => {
            if (r.dataItem.crudKey === CrudCode.CREATE || r.dataItem.progressStatusCode === 'A') {
              r.isReadOnly = false;
            } else {
              r.isReadOnly = true;
            }
          });
        }}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
      />
      {isOpenCellTextarea && (
        <WJCellTextarea grid={flexRef} hitTest={hitTest} close={() => setOpenCellTextarea(false)} />
      )}
      <GlobalBtnGroup>
        {props.editable && (
          <>
            <Button
              css={IconButton.button}
              className="request"
              onClick={handleGatingCancel}
              disableRipple
            >
              {t('gtng.button.Gating 취소', 'Gating 취소')}
            </Button>
            <Button
              css={IconButton.button}
              className="date"
              onClick={handleChangeDate}
              disableRipple
            >
              {t('gtng.button.점검일자 변경', '점검일자 변경')}
            </Button>
            <Button
              css={IconButton.button}
              className="request"
              onClick={handleChangeStep}
              disableRipple
            >
              {t('gtng.button.점검단계 변경', '점검단계 변경')}
            </Button>
            <Button css={IconButton.button} className="draft" disableRipple onClick={btnSave}>
              {t('com.button.저장', '저장')}
            </Button>
            <Button css={IconButton.button} className="confirm" disableRipple onClick={btnfinish}>
              {t('com.button.등록완료', '등록완료')}
            </Button>
          </>
        )}
      </GlobalBtnGroup>

      {isOpenCheckSheetPopup && (
        <GatingCheckSheetVerPopUp
          setPopup={setOpenCheckSheetPopup}
          setVersionData={(result) => resultCheckSheetVerPopup(fieldId, result)}
          initParam={initParam}
          gatingStepCode={popupGatingStepCode}
          gatingTypeCode={popupGatingTypeCode}
          close={() => setOpenCheckSheetPopup(false)}
        />
      )}
      {isOpenCheckSheetVerRetrievePopUp && (
        <GatingCheckSheetVerRetrievePopUp
          setPopup={setCheckSheetVerRetrievePopUp}
          initParam={initParam}
          gatingStepCode={popupGatingStepCode}
          gatingTypeCode={popupGatingTypeCode}
          close={() => setCheckSheetVerRetrievePopUp(false)}
        />
      )}
      {isOpenGatingContPopUp && (
        <UserModal
          open={isOpenGatingContPopUp}
          close={() => setGatingContPopUp(false)}
          title={t('gtng.label.Gating 담당자 조회', 'Gating 담당자 조회')}
          defaultUserId={userModalCondition?.defaultUserId}
          singleSelect={false}
          onCallback={(result) => {
            const users = (result || []) as Employee[];
            const target = userModalCondition?.target;
            if (target && target?.binding && target?.row > -1) {
              const contactId = users.map((o) => o.userId).join(',');
              const empNm = users.map((o) => o.empNm).join(',');
              const item = flexRef?.rows[target.row].dataItem;
              if ('gatingContIds' === target?.binding) {
                item['gatingContIds'] = contactId;
                item['gatingContNames'] = empNm;
                if (item['crudKey'] !== CrudCode.CREATE) {
                  item['crudKey'] = CrudCode.UPDATE;
                }
              } else if ('gatingContTlIds' === target?.binding) {
                item['gatingContTlIds'] = contactId;
                item['gatingContTlNames'] = empNm;
                if (item['crudKey'] !== CrudCode.CREATE) {
                  item['crudKey'] = CrudCode.UPDATE;
                }
              }
              flexRef?.collectionView.refresh();
            }
          }}
        />
      )}
      {isOpenGatingContListPopUp && (
        <GatingContListPopUp
          open={setGatingContListPopUp}
          setPopup={setGatingContListPopUp}
          title={contPopTitle}
          setReadonly={true}
          initParam={initParam}
          close={() => setGatingContListPopUp(false)}
        />
      )}
      {isOpenGatingEquipPopUp && (
        <GatingEquipPopUp
          setPopup={setGatingEquipPopUp}
          setEquipData={(result) => resultEquipPopUp(fieldId, result)}
          params={gatingItem}
          setGatingProgressStatusCode={setGatingProgressStatusCode}
          close={() => setGatingEquipPopUp(false)}
        />
      )}
      {isOpenGatingEquipRetreivePopUp && (
        <GatingEquipContRetrievePopUp
          setPopup={setGatingEquipRetreivePopUp}
          initParam={initParam}
          value={value}
          close={() => setGatingEquipRetreivePopUp(false)}
        />
      )}

      {isOpenTaskNamingRulePopUp && (
        <GatingTaskNamingRulePopUp
          open={isOpenTaskNamingRulePopUp}
          close={() => setOpenTaskNamingRulePopUp(false)}
        />
      )}

      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={requestModalCondition?.pageId}
          aprReqId={requestModalCondition?.aprReqId}
          condition={{
            aprReqId: requestModalCondition?.aprReqId,
            gatingId: requestModalCondition?.gatingId,
            equipmentId: requestModalCondition?.equipmentId,
          }}
        />
      )}
    </>
  );
};

export default GatingRegistGrid;
