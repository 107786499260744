/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { st } from 'components/inputs/CustomInput';
import { Code } from 'models/common/CommonCode';

interface Props {
  options: Code[]; // 옵션목록 (cf. {cmnCd: 코드, cmnCdNm: 코드명})
  defaultValue?: string | string[]; // 기본값
  singleSelect?: boolean; // 단건선택여부 (true: 1건 (default), false: N건)
  onChange: (value: string | string[]) => void; // 변경이벤트 (선택된 값 반환)
}

/**
 * CustomChip 컴포넌트
 *
 * 선택 옵션이 3개 이하인 경우 사용
 *
 * @param options
 * @param defaultValue
 * @param singleSelect
 * @param onChange
 * @constructor
 */
const CustomChip = ({ options, defaultValue, singleSelect = true, onChange }: Props) => {
  const [currentValue, setCurrentValue] = useState(
    typeof defaultValue === 'string' ? [defaultValue] : defaultValue || []
  );

  useEffect(() => {
    // 초기화 시 값 변경을 위해 추가
    setCurrentValue(typeof defaultValue === 'string' ? [defaultValue] : defaultValue || []);
  }, [defaultValue]);

  /**
   * 값 변경 이벤트
   * @param e
   */
  const handleChangeOption = (code: Code) => {
    const value = code.cmnCd || '';
    let curValue = [] as string[];
    if (currentValue.includes(value)) {
      curValue = currentValue.filter((o) => o !== value);
    } else {
      curValue = singleSelect ? [value] : [...currentValue, value];
    }

    setCurrentValue(curValue);
    onChange(singleSelect ? (curValue.length > 0 ? curValue[0] : '') : curValue);
  };

  return (
    <Stack direction="row" css={st.chip}>
      {options &&
        options.map((o) => (
          <Chip
            key={o.cmnCd}
            label={o.cmnCdNm}
            variant={currentValue.includes(o.cmnCd || '') ? 'filled' : 'outlined'}
            onClick={() => handleChangeOption(o)}
          />
        ))}
    </Stack>
  );
};

export default CustomChip;
