/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import SearchDateRangePicker from 'pages/common/components/SearchDateRangePicker';
import SearchInputText from 'pages/common/components/SearchInputText';
import SearchSelectBox from 'pages/common/components/SearchSelectBox';
import { ContentSection } from 'components/layouts/ContentSection';
import StatusManagementGrid from 'pages/ip/maintenance/grid/StatusManagementGrid';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import SearchButtonGroup from 'pages/common/components/SearchButtonGroup';
import { Generic, emptyGeneric } from 'models/ip/Generic';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';
import { EquipmentGroupPopUp } from 'pages/common/popup/EquipmentGroupPopUp';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useTranslation } from 'react-i18next';

interface DisplayCondition {
  dataInsUserIdNm?: string; // 등록자명
}

const StatusManagementPage: React.FC = () => {
  const childRef = useRef<any>();
  const { t } = useTranslation();
  const [generic, setGeneric] = useState<any>(emptyGeneric);
  const [isOpenSearchBox, setOpenSearchBox] = useState(false);
  const [isRefresh, setRefrash] = useState(false);
  const [isOpenEquipmentGroupPopUp, setOpenEquipmentGroupPopUp] = useState<boolean>(false);
  const [isOpenElmEmpPopup, setOpenElmEmpPopup] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>('');
  const listner = (received) => {
    setGeneric({ ...generic, [received.id]: received.value });
  };

  useEffect(() => {
    if (userData != '' && userData != null) {
      const userInfo = userData.userInfo[0] || {};
      setGeneric({
        ...generic,
        dataUpdUserId: userInfo.userId,
        dataUpdUserNm: userInfo.empNm,
      });
    }
  }, [userData]);

  const searchButtonOnClick = function () {
    childRef.current.drawListFromIpCheckPlan(generic); //displayCondition
  };

  const reOnClick = () => {
    setGeneric('');
    setUserData('');
    setRefrash(!isRefresh);
  };

  const resultPopup = (type, result) => {
    setGeneric({ ...generic, [type]: result.equipmentGroup });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      console.log('엔터', generic);
      searchButtonOnClick();
    }
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                {/* <label>{String(t('ip.label.IP점검계획번호', 'IP점검계획번호'))}</label>
                <CustomInputWithSearch
                  type="text"
                  name="ipCheckPlanNo"
                  id="ipCheckPlanNo"
                  placeholder={String(
                    t('ip.msg.IP 점검계획번호를 입력해 주세요.', 'IP 점검계획번호를 입력해 주세요.')
                  )}
                  value={generic.ipCheckPlanNo}
                  onChange={listner}
                  onKeyDown={handleKeyDown}
                /> */}
                <SearchInputText
                  name={t('ip.label.IP점검계획번호', 'IP점검계획번호')}
                  id="ipCheckPlanNo"
                  isRefreah={isRefresh}
                  onChange={listner}
                  placeholder={String(
                    t(
                      'ip.msg.IP 점검 계획번호를 입력해 주세요.',
                      'IP 점검 계획번호를 입력해 주세요.'
                    )
                  )}
                ></SearchInputText>
              </SearchCols>
              <SearchCols>
                <SearchInputText
                  name={t('ip.label.IP점검계획명', 'IP점검계획명')}
                  id="ipCheckPlanName"
                  placeholder={String(
                    t('ip.msg.IP 점검 계획명을 입력해 주세요.', 'IP 점검 계획명을 입력해 주세요.')
                  )}
                  isRefreah={isRefresh}
                  onChange={listner}
                ></SearchInputText>
              </SearchCols>
              <SearchCols>
                <SearchSelectBox
                  name={t('com.label.진행상태', '진행상태')}
                  id="ipCheckStatus"
                  isRefreah={isRefresh}
                  onChange={listner}
                  keyword="IP_STATUS_CODE"
                  placeholder={String(
                    t('ip.msg.진행상태를 선택해 주세요.', '진행상태를 선택해 주세요.')
                  )}
                ></SearchSelectBox>
              </SearchCols>
              <SearchCols>
                <SearchSelectBox
                  name={t('ip.label.법인코드', '법인코드')}
                  id="copCd"
                  isRefreah={isRefresh}
                  onChange={listner}
                  keyword="ELM_COP_CD"
                  placeholder={String(
                    t('ip.msg.법인코드를 선택해 주세요.', '법인코드를 선택해 주세요.')
                  )}
                ></SearchSelectBox>
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols>
                <SearchDateRangePicker
                  name={t('ip.label.계획시작일자', '계획시작일자')}
                  id="viewPeriod"
                  isRefreah={isRefresh}
                  onChange={listner}
                ></SearchDateRangePicker>
              </SearchCols>
              <SearchCols>
                <SearchInputText
                  className="find"
                  name={t('com.label.설비군', '설비군')}
                  id="equipmentGroup"
                  defaultValue={generic?.equipmentGroup}
                  isRefreah={isRefresh}
                  onChange={listner}
                  placeholder={String(
                    t('ip.msg.설비군을 입력해 주세요.', '설비군을 입력해 주세요.')
                  )}
                  findOnClick={() => setOpenEquipmentGroupPopUp(true)}
                ></SearchInputText>
              </SearchCols>
              <SearchCols>
                <SearchSelectBox
                  name={t('ip.label.점검유형', '점검유형')}
                  id="isptTpCd"
                  onChange={listner}
                  placeholder={String(
                    t('ip.label.점검유형', '점검유형') +
                      t('com.label.을 선택해 주세요.', '을 선택해 주세요.')
                  )}
                  isRefreah={isRefresh}
                  keyword="IP_ISPT_TP_CD"
                ></SearchSelectBox>
              </SearchCols>
              <SearchCols>
                <SearchInputText
                  name={t('ip.label.설비파라미터번호', '설비파라미터번호')}
                  id="eqpParaNo"
                  isRefreah={isRefresh}
                  onChange={listner}
                  placeholder={String(
                    t(
                      'ip.msg.설비파라미터번호를 입력해 주세요.',
                      '설비파라미터번호를 입력해 주세요.'
                    )
                  )}
                ></SearchInputText>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonGroup
            name={t('ip.label.조회', '조회')}
            reOnClick={reOnClick}
            searchOnClick={searchButtonOnClick}
          ></SearchButtonGroup>
        </SearchBoxRow>
      </SearchBox>
      <ContentSection>
        <StatusManagementGrid
          condition={generic}
          editable={false}
          ref={childRef}
        ></StatusManagementGrid>
      </ContentSection>
      {isOpenEquipmentGroupPopUp && (
        <EquipmentGroupPopUp
          open={isOpenEquipmentGroupPopUp}
          close={() => setOpenEquipmentGroupPopUp(false)}
          singleSelect={true}
          condition={{ equipmentGroup: generic?.equipmentGroup || '' }}
          onCallback={(result) => resultPopup('equipmentGroup', result)}
        />
      )}
      {isOpenElmEmpPopup && (
        <ElmEmpPopup
          userData={userData}
          setUserData={setUserData}
          setPopup={setOpenElmEmpPopup}
          //value={displayCondition.dataUpdUserNm}
        />
      )}
    </ContainerLayout>
  );
};
export { StatusManagementPage };
