/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';
import {
  LoginWrap,
  LoginInputBox,
  LoginInput,
  Message,
  FormStyle,
  EyeCheckbox,
  LoginMenu,
} from '../../components/layouts/LoginBox';
import { Logo } from '../../components/layouts/menu/components/Logo';

/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { devLogin } from '../../apis/Session';
import useLanguageStore from '../../stores/useLanguageStore';
import useSessionStore from '../../stores/useSessionStore';
import { useMessageBar } from '../../hooks/useMessageBar';

import { Cookies } from 'react-cookie';
import { Button } from '@mui/material';
import { SecrPldgPopUp } from '../../pages/partner/SecrPldgPopUp';
import { IdvlIfoPopUp } from '../../pages/partner/IdvlIfoPopUp';
import { ResetPwdPopUp } from '../../pages/partner/ResetPwdPopUp';
import * as CryptoJS from 'crypto-js';
import styled from '@emotion/styled';
import axios from 'axios';

const LoginPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();
  const { changeLanguage } = useLanguageStore();
  const { setSession } = useSessionStore();

  const [userId, setUserId] = useState<string>('');
  const [userPwd, setUserPwd] = useState<string>('');
  const [langCd, setLangCd] = useState<string>('ko');
  const [pwdType, setPwdType] = useState<string>('password');
  const [empNm, setEmpNm] = useState<string>('password');
  const [deptNm, setDeptNm] = useState<string>('password');

  const [eyeActive, setEyeActive] = useState<boolean>(true);
  const cookies = new Cookies();

  const [isOpenSecrPldgPopUp, setOpenSecrPldgPopUp] = useState<boolean>(false);
  const [isOpenIdvlIfoPopUp, setOpenIdvlIfoPopUp] = useState<boolean>(false);
  const [isOpenResetPwdPopUp, setOpenResetPwdPopUp] = useState<boolean>(false);

  const key = CryptoJS.enc.Utf8.parse('s302lks823klasde0fasdfal322kj439');
  const iv = CryptoJS.enc.Utf8.parse('2983923827611212');

  const handleChangePwd = (e) => {
    setUserPwd(e.target.value);
  };

  const handleEyeBtn = (e) => {
    if (e.target.checked) {
      setPwdType('password');
      setEyeActive(true);
    } else {
      setPwdType('text');
      setEyeActive(false);
    }
  };

  const handleLogin = async () => {
    if (userId == '') {
      openMessageBar({
        type: 'error',
        content: t('com.msg.User ID를 입력해 주세요.', 'User ID를 입력해 주세요.'),
      });
      return false;
    }
    if (userPwd == '') {
      openMessageBar({
        type: 'error',
        content: t('com.msg.Password를 입력해 주세요.', 'Password를 입력해 주세요.'),
      });
      return false;
    }
    const response = await devLogin(userId, userPwd, langCd);
    const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
    if (
      response.successOrNot === 'Y' &&
      response?.data &&
      response.statusCode != 'USER_NOT_FOUND' &&
      response.statusCode != 'SECURITY_PLEDGE_EXPIRE' &&
      response.statusCode != 'IDVL_IFO_CNC_EXPIRE'
    ) {
      const session = response.data;
      changeLanguage(session.langCd || 'ko');
      setSession(session);
      navigate('/', { replace: true });
    } else if (response.successOrNot === 'N' && response.statusCode == 'USER_NOT_FOUND') {
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.가입되지는 않은 사용자거나 혹은 비밀번호가 맞지 않은 사용자입니다.',
          '가입되지는 않은 사용자거나 혹은 비밀번호가 맞지 않은 사용자입니다.'
        ),
      });
    } else if (response.successOrNot === 'Y' && response.statusCode == 'SECURITY_PLEDGE_EXPIRE') {
      setEmpNm(response.data?.empNm || '');
      setDeptNm(response.data?.deptNm || '');
      setOpenSecrPldgPopUp(true);
    } else if (response.successOrNot === 'Y' && response.statusCode == 'IDVL_IFO_CNC_EXPIRE') {
      setOpenIdvlIfoPopUp(true);
    } else if (response.successOrNot === 'Y' && response.statusCode == 'USER_NOT_FOUND') {
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.가입승인이 수락되지 않은 사용자입니다.',
          '가입승인이 수락되지 않은 사용자입니다.'
        ),
      });
    } else {
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.로그인에 실패하였습니다. 시스템관리자에게 문의하십시오.',
          '로그인에 실패하였습니다. 시스템관리자에게 문의하십시오.'
        ),
      });
    }
  };

  const handleSSOLogin = async () => {
    window.location.href =
      'http://gssodev.lgensol.com:8001/nls3/cookieLogin.jsp?UURL=' +
      `${process.env.REACT_APP_API_BASE_URL}` +
      '&RTOA=1';
  };

  const handleUserIdChange = (e: any) => {
    setUserId(e.target.value);
  };

  const handleLanguageChange = (e: any) => {
    setLangCd(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const directLogin = async (id) => {
    const response = await devLogin(id, 'SKIP_PWD', langCd);
    const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
    if (response.successOrNot === 'Y' && response?.data) {
      const session = response.data;
      changeLanguage(session.langCd || 'ko');
      setSession(session);

      navigate('/', { replace: true });
    } else {
      openMessageBar({ type: 'error', content: 'Login Fail' });
    }
  };

  const setCookieForDevSso = (cname, cvalue, exdays) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + exdays * 24 * 60 * 60 * 1000);

    cookies.set(cname, cvalue, {
      path: '/',
      //expires,
    });
  };

  const abcClick = () => {
    setCookieForDevSso('engpuid', 'sMuvI+VuuQShK1Y0SfKIUcdL5L8Aay6UfB95iyP4ubk=', 1);
    setCookieForDevSso('UserTimeZone', 'Korea%20Standard%20Time', 1);
    setCookieForDevSso('language', 'ko', 1);
  };

  return (
    <CenterBox>
      <hr />
      <LoginWrap>
        <Logo />
        <div>
          <LoginInputBox>
            <LoginInput
              type="text"
              name="userid"
              autoComplete="off"
              placeholder="ID"
              value={userId}
              onChange={handleUserIdChange}
              onKeyDown={handleKeyDown}
            />
          </LoginInputBox>
          {/*입력에러일때
            <Message id='valid-id' style={{display: 'none'}}>ID를 다시 확인해주세요</Message>
          */}
          <LoginInputBox className="error">
            <LoginInput
              type={pwdType}
              id="password"
              name="password"
              autoComplete="off"
              placeholder="Password"
              onChange={handleChangePwd}
              onKeyDown={handleKeyDown}
            />
            <FormStyle>
              <EyeCheckbox id="eyeBtn" checked={eyeActive} onClick={handleEyeBtn} />
            </FormStyle>
          </LoginInputBox>
          {/*<Message id="valid-password">Password를 다시 확인해주세요</Message>*/}
        </div>
        {/* <CheckWrap>
          <Checkbox id='dd' css={st.checkbox}/>
          <label>자동로그인</label>
        </CheckWrap> */}
        <Button className="login" onClick={handleLogin} disableRipple>
          로그인
        </Button>
        <LoginMenu>
          <Link to="" onClick={() => setOpenResetPwdPopUp(true)}>
            비밀번호 초기화
          </Link>
          <Link to="/system/user-regist">{t('com.label.가입요청', '가입요청')}</Link>
        </LoginMenu>
      </LoginWrap>
      {/*<button onClick={abcClick}>.</button>*/}
      {isOpenSecrPldgPopUp && (
        <SecrPldgPopUp
          open={isOpenSecrPldgPopUp}
          close={() => setOpenSecrPldgPopUp(false)}
          userId={userId}
          empNm={empNm}
          deptNm={deptNm}
          onCallback={(value) => {
            if (value) {
              handleLogin();
            }
          }}
        />
      )}
      {isOpenIdvlIfoPopUp && (
        <IdvlIfoPopUp
          open={isOpenIdvlIfoPopUp}
          close={() => setOpenIdvlIfoPopUp(false)}
          userId={userId}
          onCallback={(value) => {
            if (value) {
              handleLogin();
            }
          }}
        />
      )}
      {isOpenResetPwdPopUp && (
        <ResetPwdPopUp open={isOpenResetPwdPopUp} close={() => setOpenResetPwdPopUp(false)} />
      )}
    </CenterBox>
  );
};

export default LoginPage;

const styles = {
  li: {
    margin: 20,
  },
};
const CenterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
