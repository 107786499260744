/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useMemo } from 'react';
import useEvent from 'react-use-event-hook';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { DataType } from '@grapecity/wijmo';
import { CellType, Row } from '@grapecity/wijmo.grid';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { findMachineSummary, saveUtMatrixDemand } from 'apis/ut/UtMatrixRegist';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { hasRole } from '../../../utils/SessionUtil';
import { getExcelFileName } from '../../../utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { calcuateActualLoad } from 'utils/UtMatrixUtil';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { useMessageBar } from 'hooks/useMessageBar';
const MachineSummaryContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const [flexRef, setFlexRef] = useState<any>();
  const isAprText = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP05');
    return condition.utmWkProgStatCd === 'UTM04' && p.length > 0
      ? String(t('com.button.결재정보', '결재정보'))
      : String(t('com.button.결재요청', '결재요청'));
  }, [processData, condition.utmWkProgStatCd]);
  //검토중
  const isUtReview = useMemo(() => {
    return condition.utmWkProgStatCd === 'UTM04' && condition.openMode !== 'appr';
  }, [condition.utmWkProgStatCd]);

  // 작성완료 : <검토진행> <재작성요청> <보류> <목록> <다운로드>
  const isUtComplete = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP03');

    return condition.utmWkProgStatCd === 'UTM03';
  }, [processData, condition.utmWkProgStatCd]);

  // 작성중 : <검증> <임시저장> <새로고침>
  const isUtWriter = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP02');
    return condition.utmWkProgStatCd === 'UTM02'; //작성중
  }, [processData, condition.utmWkProgStatCd]);

  const isUtWriter2 = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return (p.length > 0 && p[0].utmWriteYn === 'Y') || hasRole('ADM');
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);

  const isWrite = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP02';
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);
  const isWriteComplete = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP03';
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);

  useImperativeHandle(ref, () => ({
    searchMachineSummary: (condition) => {
      searchMachineSummary(condition);
    },
  }));

  const searchMachineSummary = (condition) => {
    findMachineSummary(condition).then((result) => {
      setRowData(result || []);
    });
  };

  const layoutDefinition = useMemo(() => {
    const calcuateActualLoad = (
      targetColNm = '',
      totalSumCd = 'Total Sum',
      demandCd = 'demand'
    ) => {
      const demandRows = (rowData || []).filter((o) => o.prdnProcCd === 'demand');
      const totalSumRows = (rowData || []).filter((o) => o.dtalProcCd === 'Total Sum');
      const demandRow = demandRows.length > 0 ? demandRows[0] : null;
      const totalSumRow = totalSumRows.length > 0 ? totalSumRows[0] : null;
      if (demandRow && totalSumRow && targetColNm) {
        const demand = Number(demandRow[targetColNm] || 0);
        const totalSum = Number(totalSumRow[targetColNm] || 0);
        //return parseFloat((totalSum * demand).toFixed(1)).toLocaleString();
        return parseFloat(((totalSum * demand) / 100).toFixed(1)).toLocaleString();
      }
      return '';
    };
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        width: 150,
        isReadOnly: true,
      },
      {
        binding: 'dtalProcCdNm',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 150,
        isReadOnly: true,
      },
      {
        binding: 'qty',
        header: String(t('ut.label.라인/설비 수량', '라인/설비 수량')),
        width: 150,
        align: 'center',
        isReadOnly: true,
        cellTemplate: (params) =>
          ['demand', 'actualLoad'].includes(params.item.prdnProcCd)
            ? ''
            : `${(params.item.eqpPrdnLnQty || 0).toLocaleString()} / ${(
                params.item.machEqpQty || 0
              ).toLocaleString()}`,
      },
      {
        binding: 'cdaDmdRate',
        header: String(t('ut.label.CDA[l/min]', 'CDA[l/min]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('cdaDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'nitrDmdRate',
        header: String(t('ut.label.N₂[l/min]', 'N₂[l/min]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('nitrDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'iwDmdRate',
        header: String(t('ut.label.IW[l/min]', 'IW[l/min]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('iwDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'frwtDmdRate',
        header: String(t('ut.label.IW2[l/min]', 'IW2[l/min]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('frwtDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'coolDmdRate',
        header: String(t('ut.label.PCW[l/min]', 'PCW[l/min]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('coolDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'wwDmdRate',
        header: String(t('ut.label.WW[l/min]', 'WW[l/min]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('wwDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'steamDmdRate',
        header: String(t('ut.label.Steam[Ton/hr]', 'Steam[Ton/hr]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('steamDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'lngDmdRate',
        header: String(t('ut.label.NG[N㎥/hr]', 'NG[N㎥/hr]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('lngDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'insdExhaDmdRate',
        header: String(t('ut.label.Ex.Return[CMH]', 'Ex.Return[CMH]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('insdExhaDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'otsdExhaDmdRate',
        header: String(t('ut.label.Ex.Vent[CMH]', 'Ex.Vent[CMH]')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('otsdExhaDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
      {
        binding: 'suarDmdRate',
        header: String(t('ut.label.Supply Air', 'Supply Air')),
        minWidth: 150,
        width: '*',
        dataType: DataType.Number,
        cellTemplate: (params) =>
          'actualLoad' === params.item.prdnProcCd
            ? calcuateActualLoad('suarDmdRate')
            : Number(params.value || 0).toLocaleString(),
      },
    ];
  }, [rowData]);

  const btnExcelExportGrid = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Ut Matrix 검토 기계 요약';
    book.saveAsync(
      getExcelFileName(t('ut.label.Ut Matrix 검토 전기 요약', 'Ut Matrix 검토 전기 요약'))
    );
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;

      // readonly 설정
      if (
        [
          'cdaDmdRate',
          'nitrDmdRate',
          'iwDmdRate',
          'frwtDmdRate',
          'coolDmdRate',
          'wwDmdRate',
          'steamDmdRate',
          'lngDmdRate',
          'insdExhaDmdRate',
          'otsdExhaDmdRate',
          'suarDmdRate',
        ].includes(binding)
      ) {
        const readonly = item.prdnProcCd !== 'demand';
        cell.ariaReadOnly = readonly;
      }
    }
  });
  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
  };

  const handleSave = () => {
    return new Promise((resolve) => {
      if (!condition?.utmId) {
        openMessageBar({
          type: 'error',
          content: t('com.label.잘못된 접근입니다.', '잘못된 접근입니다.'),
        });
        return;
      }

      const rows = (rowData || []).filter((o) => o.crudKey);
      if (!rows.length) {
        resolve(true);
        return;
      }

      saveUtMatrixDemand({ ...rows[0], utmId: condition?.utmId })
        .then((res) => {
          if (res) {
            openMessageBar({
              type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res?.successOrNot === 'Y'
                  ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                  : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
          }
          if (res?.successOrNot === 'Y') {
            searchMachineSummary(condition);
          }
        })
        .finally(() => resolve(true));
    });
  };

  //검토진행
  const handleSubmitReview = () => {
    props?.onSubmit?.();
  };

  //검증
  const handleModify = () => {
    props?.onModify?.();
  };
  //보류 링크
  const handleRequestLinkHold = () => {
    props?.onLinkHold?.();
  };
  //재작성 링크
  const handleRequestLinkRewrite = () => {
    props?.onLinkRewrite?.();
  };

  const handleApproval = () => {
    props?.onAppr?.();
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.기계요약', '기계요약')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && <div className="info warning">{condition?.utmNo}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExportGrid}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchMachineSummary(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        frozenColumns={4}
        isSelector={false}
        initialized={onInitialized}
        beginningEdit={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const item = grid.rows[e.row].dataItem;
          // readonly 설정
          if (
            [
              'cdaDmdRate',
              'nitrDmdRate',
              'iwDmdRate',
              'frwtDmdRate',
              'coolDmdRate',
              'wwDmdRate',
              'steamDmdRate',
              'lngDmdRate',
              'insdExhaDmdRate',
              'otsdExhaDmdRate',
              'suarDmdRate',
            ].includes(binding) &&
            item.prdnProcCd !== 'demand'
          ) {
            // 수용률만 key-in 처리
            e.cancel = true;
          }
        }}
      />

      <GlobalBtnGroup>
        {/* 작성중 : <검증> <임시저장> <새로고침> UTM02  */}
        {/* 작성완료 : <검토진행> <재작성요청> <보류> <목록> <다운로드>  UTM05 */}
        {/* 검토중 : <결재요청> <목록> <다운로드>  UTM04 */}
        {/* 그외 (결재중) : <목록> <다운로드>  */}

        {isUtWriter && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={() => handleSave()}>
              {t('com.button.임시저장', '임시저장')}
            </Button>
          </>
        )}
        {isUtComplete && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleSubmitReview}>
              {t('com.button.검토진행', '검토진행')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            {/*<Button css={IconButton.button} className="draft" onClick={() => handleSave()}>*/}
            {/*  임시저장*/}
            {/*</Button>*/}
          </>
        )}
        {isUtReview && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            {/*<Button css={IconButton.button} className="draft" onClick={() => handleSave()}>*/}
            {/*  임시저장*/}
            {/*</Button>*/}
            <Button css={IconButton.button} className="save" onClick={handleApproval}>
              {isAprText}
            </Button>
          </>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default React.forwardRef(MachineSummaryContent);
