import { SecrPldgCondtion } from 'pages/partner/ExcpSecrPldgManagementPage';
import {
  ExcpPartnerChangeStat,
  ExcpPartnerCondition,
  ExcpPartnerConditionSave,
  ExcpPartnerUser,
  ExcpPartnerUserSearchCondition,
  ExcpPartnerUserSimpleSearchCondition,
} from '../../models/admin/ExcpPartner';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from '../../models/common/RestApi';
import { callApi } from '../../utils/ApiUtil';
import { PartnerSecrPldg } from 'models/common/Partner';

export const getExcpList = async (condition: ExcpPartnerCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/list',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const saveExcpPartner = async (condition: ExcpPartnerConditionSave) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excp/save',
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const updateExcpStat = async (condition: ExcpPartnerChangeStat[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excp/stat/update',
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const rejectExcpStat = async (condition: ExcpPartnerChangeStat) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excp/stat/reject',
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const compNmCheck = async (cmpNm: string, excpId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/compNmCheck',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ cmpNm, excpId }),
  };
  const response: CommonResponse<string> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const compUserCount = async (excpId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/countUserByCmpId',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ excpId }),
  };
  const response: CommonResponse<string> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const getExcpListWithoutSession = async (condition: ExcpPartnerCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/listWithoutSession',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

// 협력사사용자승인 리스트 조회
export const getExcpUserList = async (condition: ExcpPartnerUserSearchCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/getExcpUserList',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<ExcpPartnerUser[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ExcpPartnerUser[];
};

// 협력사사용자 단순 리스트 조회용
export const getSimpleExcpUserList = async (condition: ExcpPartnerUserSimpleSearchCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/getSimpleExcpUserList',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<ExcpPartnerUser[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ExcpPartnerUser[];
};

export const updateConfirmApproval = async (approvalData: ExcpPartnerUser[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excp/updateConfirmApproval',
    serviceName: ServiceName.ELM_BE,
    bodyParams: approvalData,
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const updateRejectApproval = async (rejectData: ExcpPartnerUser[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excp/updateRejectApproval',
    serviceName: ServiceName.ELM_BE,
    bodyParams: rejectData,
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const saveExcpPartnerNoSession = async (condition: ExcpPartnerConditionSave) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excp/save-nosession',
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as [];
};

export const getExcpSecrPldgList = async (condition: SecrPldgCondtion) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/getExcpSecrPldgList',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<PartnerSecrPldg[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as PartnerSecrPldg[];
};

export const getExcpSecrPldgDetail = async (condition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/getExcpSecrPldgDetail',
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<PartnerSecrPldg> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as PartnerSecrPldg;
};

export const saveSecrPldgInfo = async (saveData: PartnerSecrPldg) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excp/insertSecrPldgInfo',
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response;
};

export const getSecrPldgVer = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: '/v1/excp/getSecrPldgVer',
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const deleteSecrPldg = async (condition: PartnerSecrPldg[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: '/v1/excp/deleteSecrPldg',
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};
