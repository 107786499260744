/** @jsxImportSource @emotion/react */
import {
  deleteMenus,
  getAllMenus,
  getDepartmentsByMenu,
  getEmployeesByMenu,
  getMenu,
  getRolesByMenu,
} from 'apis/admin/MenuApi';
import { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import { BgColor, BorderColor, ButtonColor, FontColor } from 'ui/theme/Color';
import { IconLineButton } from 'components/buttons/CustomButton';
import { css } from '@emotion/react';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import { Menu, MenuEnum, MenuVO } from 'models/admin/Menu';
import styled from '@emotion/styled';
import useMenuManagementStore from 'stores/useMenuManagementStore';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import { CustomInputText, st } from 'components/inputs/CustomInput';

interface treeItem {
  item: MenuVO;
  hasCheckbox: boolean;
  depth?: number;
  langCd?: string;
}

const MenuSidebarItem = ({ item, depth = 0, hasCheckbox, langCd }: treeItem) => {
  const {
    setModalOpen,
    setMenuId,
    setMenuName,
    setTargetMenuId,
    setMenuUseYn,
    setMenuExposureYn,
    setMenuOptionValue1,
    setMenuOptionValue2,
    setMenuOptionValue3,
    setMenuOptionValue4,
    setMenuOptionValue5,
    setMessageCode,
    setMenuUrl,
    setMenuDesc,
    setMenuInfoId,
    setMenuInfoUrl,
    setMenuInfoDesc,
    setRolesByMenu,
    setEmployeesByMenu,
    setDepartmentsByMenu,
    setMenuLocation,
    allMenuList,
    setAllMenuList,
    setAllMenuListExceptClickedMenu,
    menuListWithCheckbox,
    setMenuListWithCheckbox,
    setMenuListbyRole,
  } = useMenuManagementStore((state) => state);

  const [delClickedMenu, setDelClickedMenu] = useState<Menu>();
  const [checked, setChecked] = useState<boolean>(item.isChecked || false);
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();

  useEffect(() => {
    setChecked(item.isChecked || false);
  }, [item.isChecked]);

  const callbackConfirmModal = (menu: Menu) => {
    deleteMenu(menu);
  };

  const onDeleteMenu = (menu: Menu) => {
    setDelClickedMenu(menu);
    if (delClickedMenu) {
      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.삭제하시겠습니까?', '삭제하시겠습니까?'),
        yesCallback: () => {
          delClickedMenu && callbackConfirmModal(delClickedMenu);
        },
      });
    }
  };

  async function getMenus() {
    const allMenuList = await getAllMenus();
    const allMenuListWithOptionName = allMenuList?.map((element: MenuVO) => {
      return { ...element, optionName: element.mnuNm, optionValue: element.mnuId };
    });
    setAllMenuList(allMenuListWithOptionName);
  }

  const deleteMenu = async (menu: Menu) => {
    const del = await deleteMenus(menu.mnuId);
    getMenus();
    setDelClickedMenu(undefined);
  };

  const onUpdateMenu = (menu: Menu) => {
    setModalOpen(true);
    setMenuId(menu.mnuId);
    setMenuName(menu.mnuNm);
    setTargetMenuId(menu.upprMnuId);
    setMenuUseYn(menu.useYn);
    setMenuExposureYn(menu.mnuEpsYn);
    setMenuOptionValue1(menu.mnuOptValCtn1);
    setMenuOptionValue2(menu.mnuOptValCtn2);
    setMenuOptionValue3(menu.mnuOptValCtn3);
    setMenuOptionValue4(menu.mnuOptValCtn4);
    setMenuOptionValue5(menu.mnuOptValCtn5);
    setMessageCode(menu.msgCtn);
    setMenuUrl(menu.mnuUrl);
    setMenuDesc(menu.mnuDesc);
    setMenuLocation('4');

    const removeClickedMenuList = allMenuList.filter((item) => item.mnuId !== menu.mnuId);

    setAllMenuListExceptClickedMenu(removeClickedMenuList);
  };

  const showMenuDetail = async (mnuId: string) => {
    const menu = await getMenu(mnuId);
    setMenuInfoId(menu.mnuId);
    setMenuInfoUrl(menu.mnuUrl == null ? '' : menu.mnuUrl);
    setMenuInfoDesc(menu.mnuDesc == null ? '' : menu.mnuDesc);
  };

  const getDataByMenuId = async (mnuId: string) => {
    const roles = await getRolesByMenu(mnuId);
    const employees = await getEmployeesByMenu(mnuId);
    const department = await getDepartmentsByMenu(mnuId);

    setRolesByMenu(roles);
    setEmployeesByMenu(employees);
    setDepartmentsByMenu(department);
  };

  const onClickMenu = (e, mnuId: string) => {
    document.querySelectorAll('label').forEach((label) => (label.style.backgroundColor = ''));
    e.target.style.backgroundColor = 'rgba(168,151,223,0.2)';
    showMenuDetail(mnuId);
    getDataByMenuId(mnuId);
  };

  const recursiveChange = (childrens: Menu[], changeIds: string[]) => {
    childrens.forEach((item: Menu) => {
      changeIds.push(item.mnuId);
      if (item.childrens) {
        recursiveChange(item.childrens, changeIds);
      }
    });
    return changeIds;
  };

  const onCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>, item: MenuVO) => {
    let changeIds: string[] = [];
    changeIds.push(item.mnuId);
    if (item?.childrens?.length && item.childrens.length > 0) {
      changeIds = recursiveChange(item.childrens, changeIds);
    }

    if (e.target.checked) {
      const adddedList = [...menuListWithCheckbox, ...changeIds];
      setMenuListWithCheckbox(adddedList);
      const allMenuLists = await getAllMenus();

      const alteredRoleMenuList = allMenuLists?.map((element: MenuVO) => {
        const exists = adddedList?.find((el) => el === element.mnuId);
        if (exists) {
          return { ...element, isChecked: true };
        } else {
          return { ...element, isChecked: false };
        }
      });

      setMenuListbyRole([...alteredRoleMenuList]);
    } else {
      const checkboxList = menuListWithCheckbox.filter(
        (eachItem: string) => !changeIds.includes(eachItem)
      );
      setMenuListWithCheckbox(checkboxList);

      const allMenuLists = await getAllMenus();
      const alteredRoleMenuList = allMenuLists?.map((element: MenuVO) => {
        const exists = checkboxList?.find((el) => el === element.mnuId);
        if (exists) {
          return { ...element, isChecked: true };
        } else {
          return { ...element, isChecked: false };
        }
      });
      setMenuListbyRole([...alteredRoleMenuList]);
    }
    setChecked(!checked);
  };

  return (
    <>
      {item?.childrens?.length && item.childrens.length > 0 ? (
        <li className="hasCheckbox">
          {hasCheckbox && (
            <Checkbox
              css={st.checkbox}
              onChange={(e) => onCheckboxChange(e, item)}
              checked={checked}
              value={checked}
            />
          )}
          <input type="checkbox" id={hasCheckbox ? item.mnuId : `${item.mnuId}-mnuMgt`} />
          <label
            htmlFor={hasCheckbox ? item.mnuId : `${item.mnuId}-mnuMgt`}
            className={item.upprMnuId === MenuEnum.root ? 'root' : ''}
            datatype={item.useYn === 'N' ? 'N' : ''}
            onClick={(e) => onClickMenu(e, item.mnuId)}
          >
            [{depth}]{item.mnuNm}
            {hasCheckbox ? (
              ''
            ) : (
              <>
                <IconLineButton onClick={() => onUpdateMenu(item)}>
                  <EditIcon className="xs"></EditIcon>
                </IconLineButton>
                <IconLineButton onClick={() => onDeleteMenu(item)}>
                  <RemoveIcon fontSize="small"></RemoveIcon>
                </IconLineButton>
              </>
            )}
          </label>
          <ul>
            {item?.childrens.map((child, index) => (
              <MenuSidebarItem
                item={child}
                depth={depth + 1}
                key={index}
                hasCheckbox={hasCheckbox}
                langCd={langCd ?? ''}
              />
            ))}
          </ul>
        </li>
      ) : (
        <li className="lastList hasCheckbox">
          {hasCheckbox && (
            <Checkbox
              onChange={(e) => onCheckboxChange(e, item)}
              css={st.checkbox}
              checked={checked}
              value={checked}
            />
          )}

          <input type="checkbox" id={hasCheckbox ? item.mnuId : `${item.mnuId}-mnuMgt`} />
          <label
            htmlFor={hasCheckbox ? item.mnuId : `${item.mnuId}-mnuMgt`}
            className="lastTree"
            datatype={item.useYn === 'N' ? 'N' : ''}
            onClick={(e) => onClickMenu(e, item.mnuId)}
          >
            [{depth}]{item.mnuNm}
            {hasCheckbox ? (
              ''
            ) : (
              <>
                <IconLineButton onClick={() => onUpdateMenu(item)}>
                  <EditIcon className="xs"></EditIcon>
                </IconLineButton>
                <IconLineButton onClick={() => onDeleteMenu(item)}>
                  <RemoveIcon fontSize="small"></RemoveIcon>
                </IconLineButton>
              </>
            )}
          </label>
        </li>
      )}
    </>
  );
};

interface sProps {
  useYn?: string;
}

const StyledLabel = styled.label`
  color: ${(props: sProps) =>
    props.useYn === 'N' ? `${FontColor.Gray200} !important` : `${FontColor.Gray400}`};
`;
const style = {
  content: css`
    flex: 1;
    height: 100%;
    background-color: ${BgColor.White};
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 30px;
    background-color: ${BgColor.White};
    border-bottom: 1px solid ${BorderColor.Primary};

    h1 {
      color: ${FontColor.Primary};
      font-size: 36px;
      font-weight: bold;
      span {
        display: block;
        color: ${FontColor.Gray200};
        font-size: 14px;
        font-weight: 400;
        margin: 8px 0 0;
      }
    }

    .breadcrumb {
      font-weight: 500;
      color: ${FontColor.Gray200};
      svg {
        vertical-align: middle;
      }
      span {
        color: ${FontColor.Primary};
      }
    }
  `,
  container: css`
    padding: 50px 30px;
  `,
  search: css`
    display: flex;
    flex-direction: column;
    padding: 12px 20px;
    border-top: 1px solid ${BorderColor.Primary};
    border-bottom: 1px solid ${BorderColor.Primary};
    background-color: ${BgColor.White50};
    margin-bottom: 60px;

    .searchRow {
      justify-content: space-between;
      display: flex;
      align-items: center;

      + .searchRow {
        margin-top: 12px;
      }
      .searchCol {
        label + input,
        label + select {
          margin-left: 12px;
        }
        label + input,
        label + select {
          margin-left: 12px;
        }
        min-width: 200px;

        &:last-child {
          text-align: right;
        }
      }
    }
  `,

  flex: css`
    display: flex;
    justify-content: space-between;

    .section {
      &.width20p {
        width: 20%;
      }
      &.width30p {
        width: 30%;
      }
      &.width40p {
        width: 40%;
      }
      &.width50p {
        width: 50%;
      }
      &.width60p {
        width: 60%;
      }
    }
    .section + .section {
      margin-top: 0;
      margin-left: 20px;
    }
  `,

  section: css`
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }

    .sectionTop {
      display: flex;
      align-items: flex-end;
      padding-bottom: 15px;
      border-bottom: 1px solid ${BorderColor.Secondary};
      margin-bottom: 15px;
      height: 38px;
      font-size: 24px;
      font-weight: 700;

      &.contentBetween {
        justify-content: space-between;
      }

      &.contentEnd {
        justify-content: end;
      }
    }

    .gridTop {
      display: flex;
      align-items: flex-end;
      margin-bottom: 15px;
      height: 38px;

      &.contentBetween {
        justify-content: space-between;
      }

      &.contentEnd {
        justify-content: end;
      }
    }

    .gridInfo {
      font-weight: 500;

      span {
        margin-right: 10px;
        &.primary {
          color: ${FontColor.Primary};
        }
      }
    }

    .noGridTop {
      margin-top: 53px;
    }

    .ag-theme-alpine {
      width: 100%;
      height: 200px;

      --ag-header-background-color: ${BgColor.Gray100};
      --ag-font-size: 16px;
      --ag-font-family: LGSmHaT;

      --ag-row-hover-color: ${BgColor.White50};
      --ag-selected-row-background-color: ${BgColor.White50};

      --ag-borders: none;
      --ag-row-border-color: ${BorderColor.Primary};
      --ag-input-focus-border-color: ${BorderColor.Primary};
      --ag-checkbox-background-color: transparent;
      --ag-checkbox-checked-color: ${ButtonColor.Primary};
      --ag-input-focus-box-shadow: 0;

      --ag-row-height: 50px !important;

      & + .gridTop {
        margin-top: 40px;
      }
    }

    .ag-root {
      border-top: 1px solid ${BorderColor.Primary};
      border-bottom: 1px solid ${BorderColor.Primary};

      .ag-header-cell-label {
        justify-content: center;
      }
      .ag-header-cell-text {
        font-weight: 500;
      }

      .ag-row {
        height: 52px !important;
      }

      .ag-cell {
        text-align: center;
      }
    }

    .treeBox {
      padding: 10px;
      border: 1px solid ${BorderColor.Primary};
      .tree {
        margin-top: 5px;
        padding-left: 5px;

        label {
          display: inline-block;
          position: relative;
          padding-bottom: 15px;
          cursor: pointer;
          color: ${FontColor.Gray400};

          &.root {
            font-weight: 600;
            color: ${FontColor.Default};
          }
          &.root:before {
            color: ${FontColor.Primary};
            background-image: url(/assets/icon/ic_tree_root_close.svg);
          }

          &:hover {
            color: ${FontColor.Primary};
          }

          &:before {
            content: '';
            width: 24px;
            height: 24px;
            display: inline-block;
            vertical-align: -6px;
            color: ${FontColor.Primary};
            background: #fff url(/assets/icon/ic_tree_folder_close.svg) no-repeat center;
          }

          &.lastTree:before {
            background-image: url(/assets/icon/ic_tree_file.svg);
          }
          button:first-child {
            margin-left: 10px;
          }
        }

        li {
          position: relative;

          &:before {
            content: '';
            display: inline-block;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 10px;
            background: url(/assets/icon/dot_tree_line.svg) repeat-y;
          }

          &.lastList {
            :before {
              display: none;
            }
          }

          &.hasCheckbox > label:before {
            margin-right: 28px;
          }

          .MuiCheckbox-root {
            position: absolute;
            left: 26px;
            z-index: 2;

            input {
              display: block;
            }
          }
        }

        ul {
          padding-left: 25px;
        }

        *:before {
          width: 15px;
          height: 15px;
          display: inline-block;
        }

        input[type='checkbox'] {
          display: none;
          &:checked ~ ul {
            display: block;
          }

          &:checked + label:before {
            background-image: url(/assets/icon/ic_tree_folder_open.svg);
          }

          &:checked + label.lastTree:before {
            background-image: url(/assets/icon/ic_tree_file.svg);
          }

          &:checked + label.root:before {
            background-image: url(/assets/icon/ic_tree_root_open.svg);
          }
        }

        [datatype='N'] {
          color: ${FontColor.Gray200} !important;
        }
      }
    }
  `,

  dialog: css`
    .MuiDialog-paper {
      padding: 20px;
    }

    .popupTitle {
      padding: 40px 0 20px;
      border-bottom: 1px solid ${BorderColor.Secondary};
      font-size: 24px;
      font-weight: bold;
    }
    .popupContent {
      padding: 20px 0;

      .searchBox {
        margin-bottom: 40px;
      }
    }

    .MuiDialogTitle-root + .MuiDialogContent-root {
      padding-top: 20px;
    }

    .buttonClose {
      position: absolute;
      top: 30px;
      right: 20px;
    }

    .buttonsTop {
      text-align: right;
      & + .section {
        margin-top: 20px;
      }
    }

    .popupBottom {
      padding: 0 0 40px;
    }
  `,
  checkbox: css`
    padding: 0;
    color: ${BorderColor.Primary};
    &.Mui-checked {
      color: #3ec2cf;
    }
  `,

  radio: css`
    padding-right: 3px;
    color: ${BorderColor.Primary};
    &.Mui-checked {
      color: #3ec2cf;
    }
  `,
};
export default MenuSidebarItem;
