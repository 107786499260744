/** @jsxImportSource @emotion/react */
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { tabs } from 'components/layouts/Tab';
import { Code } from 'models/common/CommonCode';
import { UtMatrixRegistSearchCondition, UtMatrixMaster, UtMatrixDetail } from 'models/ut/UtMatrix';
import {
  findMaster,
  findProcess,
  findProcessBar,
  submitCheckRegistMatrix,
  submitUtMatix,
  changeStatUtMatix,
} from 'apis/ut/UtMatrixRegist';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import MachineSummaryContent from './review/MachineSummaryContent';
import ElectricSummaryContent from './review/ElectricSummaryContent';
import StandardUseContent from './review/StandardUseContent';
import DetailSummaryContent from './review/DetailSummaryContent';

import CustomGrid from '../../components/grids/CustomGrid';
import { UtMatrixSummaryList } from '../../models/ut/UtMatrixSummary';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { getUtMatrixSummaryList } from '../../apis/ut/UtMatrixSummary';
import { ManagementMode } from '../../models/common/Common';
import { EquipmentClassificationMaster } from '../../models/mp/MpEquipment';
import UtMatrixGraphPopup from './popup/UtMatrixGraphPopup';

interface PageLocationState {
  openMode?: 'UT_MGR' | 'WRITE' | 'READ';
  utmId?: string;
  planProcId?: string;
}

interface Props {
  aprReqId: string; // 결재요청ID
  mode: ManagementMode;
  // requestMasterList: EquipmentClassificationMaster[];
  isReadOnly?: boolean;
  utmId?: string;
}

const UtMatrixReviewRequest = (props: Props, ref) => {
  const { t } = useTranslation();
  const detailRef = useRef<any>();
  const machineRef = useRef<any>();
  const elecRef = useRef<any>();
  const atulCapaRef = useRef<any>();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const [locationState, setLocationState] = useState<PageLocationState>(useLocation().state);
  const [isOpenGraphModal, setOpenGraphModal] = useState<boolean>(false);
  const [utmData, setUtmData] = useState<UtMatrixSummaryList[]>([]);
  const conditionRef = useRef<UtMatrixRegistSearchCondition>();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [code, setCode] = useState<any>();
  const [condition, setCondition] = useState<UtMatrixRegistSearchCondition>({
    utmId: props?.utmId || searchParams?.get('utmId') || '',
    // planProcId: searchParams?.get('planProcId') || '',
    openMode: searchParams?.get('openMode') || 'appr',
    sumBaseCd: 'ALL',
  });
  const [errors, setErrors] = useState<any>({});
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [masterData, setMasterData] = useState<UtMatrixMaster[]>([]);
  const [processData, setProcessData] = useState<UtMatrixDetail[]>([]);
  const [utmWkProgStatCd, setUtmWkProgStatCd] = useState<Code[]>([]);
  const [utmWkProgInfo, setUtmWkProgInfo] = useState<UtMatrixDetail>();
  const tabMenus = useMemo(() => {
    return [
      { id: 'UTM', name: t('ut.label.UT Matrix', 'UT Matrix') },
      { id: 'MACH', name: t('ut.label.기계요약', '기계요약') },
      { id: 'ELTR', name: t('ut.label.전기요약', '전기요약') },
      { id: 'CAPA', name: t('ut.label.표준사용량 비교', '표준사용량 비교') },
    ];
  }, []);

  // 검색조건 > UT Matrix 명 (ComboBox 사용을 위해 변환)
  const optionsUtMaster = useMemo(() => {
    return (masterData || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: cur.utmId,
          cmnCdNm: cur.utmNm,
        } as Code,
      ],
      [] as Code[]
    );
  }, [masterData]);

  const selectedMaster = useMemo(() => {
    if (!condition?.utmId) return null;
    const master = (masterData || []).filter((o) => o.utmId === condition?.utmId);
    return master.length > 0 ? master[0] : null;
  }, [condition?.utmId, masterData]);

  useEffect(() => {
    console.log('==========>searchParams ', searchParams?.get('utmId'));
    console.log('==========> locationState', locationState);
    console.log('==========>props?.utmId ', props?.utmId);

    setCondition((prev) => ({
      utmId: props?.utmId || locationState?.utmId || searchParams?.get('utmId') || '',
      // planProcId: locationState?.planProcId || searchParams?.get('planProcId') || '',
      openMode: locationState?.openMode || searchParams?.get('openMode') || '',
      sumBaseCd: prev?.sumBaseCd || 'ALL',
      aprReqId: props.aprReqId,
    }));
  }, [locationState?.utmId, props]);

  useEffect(() => {
    if (state?.utmId) {
      setLocationState(state);
    }
  }, [state]);

  useEffect(() => {
    findMaster().then((result: UtMatrixMaster[]) => {
      if (result !== null) {
        setMasterData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
    getCommonCodes();
  }, []);

  useEffect(() => {
    console.log('--------------', condition);
    setCondition((prev) => ({
      ...prev,
      prdnProcCd: '',
      dtalProcCd: '',
    }));
    if (!condition?.utmId) {
      setProcessData([]);
      return;
    }
    searchProcess(condition?.utmId);
    handleSearch();
  }, [condition?.utmId]);

  const getCommonCodes = async () => {
    const utmWkProgStatCd: Code[] = await getCommonCodeNames('UTM_WK_PROG_STAT_CD');
    const sumBaseCd: Code[] = await getCommonCodeNames('SUM_BASE_CD');

    setUtmWkProgStatCd((utmWkProgStatCd || []).filter((o) => o.cmnCd !== 'UTM06'));
    setCode({
      sumBaseCd: sumBaseCd,
    });
  };

  const handleSearch = () => {
    console.log(' handleSearch ... ', condition);
    setErrors({
      utmId: _.isEmpty(condition.utmId),
      sumBaseCd: _.isEmpty(condition.sumBaseCd),
    });

    if (_.isEmpty(condition.utmId) || _.isEmpty(condition.sumBaseCd)) {
      return;
    }
    conditionRef.current = condition;
    searchProcessBar(condition.utmId);
    searchSummary(condition);
    searchTabContent();
  };

  useEffect(() => {
    if (conditionRef.current && conditionRef.current?.utmId) {
      searchTabContent();
    }
  }, [currentTabIndex]);

  useEffect(() => {
    if ((processData || []).length) {
      let proc = [] as UtMatrixDetail[];
      if (condition?.prdnProcCd && condition?.dtalProcCd) {
        proc = processData.filter(
          (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
        );
      } else if (condition?.planProcId) {
        proc = processData.filter((o) => o.planProcId === condition?.planProcId);
      }
      if (proc.length) {
        setCondition((prev) => ({
          ...prev,
          planProcId: proc[0].planProcId,
          prdnProcCd: proc[0].prdnProcCd,
          prdnProcCdNm: proc[0].prdnProcCdNm,
          dtalProcCd: proc[0].dtalProcCd,
          dtalProcCdNm: proc[0].dtalProcCdNm,
          utmWrtProcProgStatCd: proc[0].utmWrtProcProgStatCd,
          utmWrtProcProgStatCdNm: proc[0].utmWrtProcProgStatCdNm,
        }));
      }
    }
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd, condition?.planProcId]);

  const processSteps = useMemo(() => {
    let currentStepNo = -1;
    return (utmWkProgStatCd || []).reduce((acc, cur, idx) => {
      const isNow = (utmWkProgInfo || {}).utmWkProgStatCd === cur.cmnCd;
      if (isNow) currentStepNo = idx;
      return [
        ...acc,
        {
          ...cur,
          cmnCdDesc: isNow
            ? 'now'
            : Object.keys(utmWkProgInfo || {}).length > 0 && currentStepNo < 0
            ? 'done'
            : '', // or 'disable'
        },
      ];
    }, [] as Code[]);
  }, [utmWkProgStatCd, utmWkProgInfo]);

  const searchProcessBar = (utmId?: string) => {
    if (!utmId) return;
    findProcessBar(utmId).then((result) => {
      setUtmWkProgInfo(result);
      condition.utmWkProgStatCd = result.utmWkProgStatCd;
    });
  };

  const searchProcess = (utmId?: string) => {
    return new Promise((resolve) => {
      if (!utmId) {
        resolve(true);
        return;
      }
      findProcess(utmId)
        .then((result: UtMatrixDetail[]) => {
          if (result !== null) {
            setProcessData(result);
          } else {
            openMessageBar({
              type: 'error',
              content: t(
                'ut.label.공정 정보 조회 중 오류가 발생했습니다.',
                '공정 정보 조회 중 오류가 발생했습니다.'
              ),
            });
          }
        })
        .finally(() => resolve(true));
    });
  };

  const searchTabContent = () => {
    if ((tabMenus || []).length) {
      if (tabMenus[currentTabIndex].id === 'UTM') {
        detailRef.current?.searchRegistMatrix(conditionRef.current);
      } else if (tabMenus[currentTabIndex].id === 'MACH') {
        machineRef.current?.searchMachineSummary(conditionRef.current);
      } else if (tabMenus[currentTabIndex].id === 'ELTR') {
        elecRef.current?.searchElectricSummary(conditionRef.current);
      } else if (tabMenus[currentTabIndex].id === 'CAPA') {
        atulCapaRef.current?.searchStandardUse(conditionRef.current);
      }
    }
  };

  const navigate = useNavigate();

  //보류 링크
  const handleRequestLinkHold = async () => {
    if (!conditionRef.current?.utmId) return;
    navigate('/ut/ut-matrix-request', {
      state: { searchParam: { utmId: summaryRowData[0].utmId, mode: 'HOLD' } },
    });
  };
  //재작성 요청 링크
  const handleRequestLinkRewrite = async () => {
    if (!conditionRef.current?.utmId) return;
    navigate('/ut/ut-matrix-request', {
      state: { searchParam: { utmId: summaryRowData[0].utmId, mode: 'REWRITE' } },
    });
    //결재요청1
  };

  // 검증
  const handleReview = async () => {
    if (!conditionRef.current?.utmId) return;
    submitCheckRegistMatrix(conditionRef.current?.utmId, undefined).then((result) => {
      if (result) {
        openCommonModal({
          modalType: 'alert',
          content: result,
          // yesButtonText: String(t('ut.label.검증', '검증')),
          // yesCallback: () => {
          //   submitUtMatrix();
          // },
        });
      } else {
        // submitUtMatrix();
        openMessageBar({
          type: 'confirm',
          content: t('com.msg.검증되었습니다.', '검증되었습니다.'),
        });
      }
    });
  };

  const submitUtMatrix = () => {
    if (!conditionRef.current?.utmId) return;
    submitUtMatix(conditionRef.current).then((res) => {
      if (res?.successOrNot === 'Y') {
        searchProcess(conditionRef.current?.utmId).then(() => handleSearch());
      }
      openMessageBar({
        type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
        content:
          res?.successOrNot === 'Y'
            ? t('com.msg.검증되었습니다.', '검증되었습니다.')
            : t('com.msg.검증 중 오류가 발생했습니다.', '검증 중 오류가 발생했습니다.'),
      });
    });
  };

  // 검토진행
  const handleSubmitReview = async () => {
    if (!conditionRef.current?.utmId) return;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.검토진행 하시겠습니까?', '검토진행 하시겠습니까?'),
      yesCallback: () => {
        changeStatUtMatix({ ...conditionRef.current, utmWrtProcProgStatCd: 'UTP04' }).then(
          (res) => {
            if (res?.successOrNot === 'Y') {
              searchProcess(conditionRef.current?.utmId).then(() => handleSearch());
            }
            openMessageBar({
              type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res?.successOrNot === 'Y'
                  ? t('ut.label.검토중 상태로 변경되었습니다.', '검토중 상태로 변경되었습니다.')
                  : t('ut.label.수정 중 오류가 발생했습니다.', '수정 중 오류가 발생했습니다.'),
            });
          }
        );
      },
    });
  };

  //결재 요청
  const handleApproval_temp = () => {
    if (!conditionRef.current?.utmId) return;
    //결재요청

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.확정완료 하시겠습니까?', '확정완료 하시겠습니까?'),
      yesCallback: () => {
        changeStatUtMatix({ ...conditionRef.current, utmWrtProcProgStatCd: 'UTP06' }).then(
          (res) => {
            if (res?.successOrNot === 'Y') {
              searchProcess(conditionRef.current?.utmId).then(() => handleSearch());
            }
            openMessageBar({
              type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res?.successOrNot === 'Y'
                  ? t('ut.label.확정완료 상태로 변경되었습니다.', '확정완료 상태로 변경되었습니다.')
                  : t('ut.label.오류가 발생했습니다.', '오류가 발생했습니다.'),
            });
          }
        );
      },
    });
  };

  const [summaryRowData, setSummaryRowData] = useState<UtMatrixSummaryList[]>([]);
  const [flexRef, setFlexRef] = useState<any>();

  const onInitializedSummary = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        switch (e.target.id) {
          case 'btnMoveGraph':
            setOpenGraphModal(true);
            setUtmData(item);
            break;
        }
      }
    });
  };

  useImperativeHandle(ref, () => ({
    getBizReqData: (aprReqId: string, reqRsn: string) => {
      console.log('===================== ', summaryRowData[0].utmId);
      // 저장
      const master = {
        castType: 'UtMatrixReviewAppr', // 서버측 결재요청상세vo > bizReqData cast 타입
        aprReqId: aprReqId,
        reqRsn: reqRsn,
        utmId: summaryRowData[0].utmId,
      };
      return master;
    },
  }));

  const searchSummary = async (condition) => {
    if (!condition) return;

    getUtMatrixSummaryList(condition.utmId).then((result) => {
      setSummaryRowData(result);
    });
  };

  const layoutDefinitionSummary = [
    // {
    //   binding: 'no',
    //   header: String(t('com.label.NO', 'NO')),
    //   width: 40,
    //   isReadOnly: true,
    //   align: 'center',
    //   cellTemplate: (grid) => grid.row._idx + 1,
    // },
    {
      binding: 'utmId',
      header: String(t('ut.label.UTM ID', 'UTM ID')),
      visible: true,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'bildPlntCdNm',
      header: String(t('ut.label.공장', '공장')),
      width: 100,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'bildPlntCd',
      visible: false,
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('ut.label.목적', '목적')),
      width: 100,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'askPurpCd',
      visible: false,
    },
    {
      binding: 'utmNo',
      header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
      width: 150,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'utmNm',
      header: String(t('ut.label.UT Matrix 명', 'UT Matrix 명')),
      width: 180,
      isReadOnly: true,
      cssClass: 'WijmoFind',
      // cellTemplate: '<span>${item.utmNm}</span><button id="btnOpenModal" />',
    },
    {
      binding: 'verNo',
      header: String(t('ut.label.버전', '버전')),
      width: 80,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'lineQty',
      header: String(t('ut.label.Line 수', 'Line 수')),
      width: 110,
      align: 'center',
      isReadOnly: true,
    },
    {
      header: String(t('ut.label.Graph', 'Graph')),
      width: 80,
      align: 'center',
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: '<button id="btnMoveGraph"></button>',
    },
    {
      binding: 'elec',
      header: String(t('ut.label.전기[kw]', '전기[kw]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'cda',
      header: String(t('ut.label.CDA[l/min]', 'CDA[l/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'n2',
      header: String(t('ut.label.N₂[l/min]', 'N₂[l/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'iw',
      header: String(t('ut.label.IW[l/min]', 'IW[l/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'pcw',
      header: String(t('ut.label.PCW[l/min]', 'PCW[l/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'steam',
      header: String(t('ut.label.Steam[Ton/hr]', 'Steam[Ton/hr]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'ng',
      header: String(t('ut.label.NG[N㎥/hr]', 'NG[N㎥/hr]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'exRetrun',
      header: String(t('ut.label.Ex.Return[CMH]', 'Ex.Return[CMH]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'exVent',
      header: String(t('ut.label.Ex.Vent[CMH]', 'Ex.Vent[CMH]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'sa',
      header: String(t('ut.label.Supply Air', 'Supply Air')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
  ];

  return (
    <>
      {/* summary grid start */}
      <div>
        <CustomGrid
          layoutDefinition={layoutDefinitionSummary}
          rowData={summaryRowData}
          initialized={onInitializedSummary}
          showMarquee={true}
          isFilter={false}
          // selectionMode={SelectionMode.CellRange}
          alternatingRowStep={0}
          height={100}
        />
      </div>
      {/* summary grid end */}

      <div css={tabs.wrap} style={{ marginTop: '24px' }}>
        <Tabs
          value={currentTabIndex}
          onChange={(e, newValue) => {
            // 이전 탭이 Ut Matrix인 경우 자동 저장 후 탭변경
            console.log('----------------------------------- tabs ; ', newValue);
            if (currentTabIndex === 0) {
              detailRef.current?.saveRegistMatrix().finally(() => {
                setCurrentTabIndex(newValue);
              });
            } else {
              setCurrentTabIndex(newValue);
            }
          }}
          css={tabs.tab}
        >
          {tabMenus.map((o, idx) => (
            <Tab
              key={o.id}
              label={o.name}
              id={`ut-tab-${idx}`}
              aria-controls={`ut-tabpanel-${idx}`}
            />
          ))}
        </Tabs>
      </div>

      <TabPanel value={currentTabIndex} index={currentTabIndex}>
        {currentTabIndex === 0 ? (
          <DetailSummaryContent
            ref={detailRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmitReview}
            onModify={handleReview}
            onLinkHold={handleRequestLinkHold}
            onLinkRewrite={handleRequestLinkRewrite}
          />
        ) : currentTabIndex === 1 ? (
          <MachineSummaryContent
            ref={machineRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmitReview}
            onModify={handleReview}
            onLinkHold={handleRequestLinkHold}
            onLinkRewrite={handleRequestLinkRewrite}
          />
        ) : currentTabIndex === 2 ? (
          <ElectricSummaryContent
            ref={elecRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmitReview}
            onModify={handleReview}
            onLinkHold={handleRequestLinkHold}
            onLinkRewrite={handleRequestLinkRewrite}
          />
        ) : (
          <StandardUseContent
            ref={atulCapaRef}
            condition={conditionRef.current}
            processData={processData}
            onSubmit={handleSubmitReview}
            onModify={handleReview}
            onLinkHold={handleRequestLinkHold}
            onLinkRewrite={handleRequestLinkRewrite}
          />
        )}
      </TabPanel>

      {isOpenGraphModal && (
        <UtMatrixGraphPopup
          open={isOpenGraphModal}
          close={() => setOpenGraphModal(false)}
          condition={utmData}
        />
      )}
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`ut-tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default forwardRef(UtMatrixReviewRequest);
