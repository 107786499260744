/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMessageBar } from 'hooks/useMessageBar';
import { InputBox, SearchBox, SearchBoxRow, SearchRows } from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { GridInfoSection } from 'components/layouts/ContentSection';
import { ViewTable } from 'components/tables/ViewTable';
import { GreyButton } from 'components/buttons/CustomButton';
import { useCommonModal } from 'hooks/useCommonModal';
import { ExcpListPopUp } from 'pages/partner/ExcpListPopUp';
import { ExcpMgrListPopUp } from 'pages/partner/ExcpMgrListPopUp';
import CustomSwitch from 'components/selects/CustomSwitch';
import { ExcpUser } from 'models/admin/ExcpUser';
import { registExcpUser, checkExcpUserIdDup } from 'apis/admin/ExcpUser';
import { ExcpManagementPopUp } from 'pages/partner/excpmanagement/ExcpManagementPopUp';
import { ManagementMode } from 'models/common/Common';
import { JoinLayout } from 'components/layouts/MainLayout';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { tb } from '../../components/layouts/Table';
import { IconButton } from '../../components/buttons/IconSVG';
import {
  CustomInputText,
  CustomInputWrap,
  AllInputWrap,
  UseToggle,
} from '../../components/inputs/CustomInput';
import {
  SubTitleGroup,
  SubTitleLayout,
  GlobalBtnGroup,
  cs,
} from 'components/layouts/ContentLayout';
import styled from '@emotion/styled';
import * as CryptoJS from 'crypto-js';

const excpUser: ExcpUser = {
  excpId: '',
  rpsnNm: '',
  userId: '',
  userNm: '',
  userPwd: '',
  userEml: '',
  userTel: '',
  mgrUserId: '',
  idvlIfoCncYn: '',
};

const ExcpUserRegistPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();

  const [excpUserInfo, setExcpUserInfo] = useState<ExcpUser>(excpUser);
  const [excpNm, setExcpNm] = useState<string>('');
  const [excpRpsnNm, setExcpRpsnNm] = useState<string>('');
  const [excpMgrNm, setExcpMgrNm] = useState<string>('');
  const [upd, setUpd] = useState<string>('');
  const [updr, setUpdr] = useState<string>('');
  const { openCommonModal } = useCommonModal();
  const [isOpenExcpListPopUp, setOpenExcpListPopUp] = useState<boolean>(false);
  const [isOpenExcpMgrListPopUp, setOpenExcpMgrListPopUp] = useState<boolean>(false);
  const [isOpenExcpManagementPopUp, setOpenExcpManagementPopUp] = useState<boolean>(false);
  const [flexItem, setflexItem] = useState<any>();
  const [hasCompleteCheck, setCompleteCheck] = useState(false);

  const key = CryptoJS.enc.Utf8.parse('s302lks823klasde0fasdfal322kj439');
  const iv = CryptoJS.enc.Utf8.parse('2983923827611212');

  const confirmUserId = async (e) => {
    const validateEmail = (email: any) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    if (validateEmail(excpUserInfo.userId) == null) {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.아이디가 이메일 형식이 아닙니다.',
          '아이디가 이메일 형식이 아닙니다.'
        ),
      });
      return false;
    }

    const response = await checkExcpUserIdDup(excpUserInfo.userId);
    if (response == '0') {
      openCommonModal({
        modalType: 'confirm',
        content: t(
          'com.label.사용가능한 아이디입니다. 사용하시겠습니까?',
          '사용가능한 아이디입니다. 사용하시겠습니까?'
        ),
        yesCallback() {
          e.target.disabled = true;
          setCompleteCheck(true);
        },
      });
    } else {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.아이디가 이미 존재합니다. 다른 아이디를 사용 하셔야 합니다.',
          '아이디가 이미 존재합니다. 다른 아이디를 사용 하셔야 합니다.'
        ),
      });
      return false;
    }
  };

  const handleOnChangeUserNm = (e) => {
    setExcpUserInfo({
      ...excpUserInfo,
      userNm: e.target.value,
    });
  };

  const handleOnChangeUserId = (e) => {
    setCompleteCheck(false);
    setExcpUserInfo({
      ...excpUserInfo,
      userId: e.target.value,
      userEml: e.target.value,
    });
  };

  const handleChangePwd = (e) => {
    setUpd(e.target.value);
  };

  const handleChangeRePwd = (e) => {
    setUpdr(e.target.value);
    const encryptedCP = CryptoJS.AES.encrypt(e.target.value, key, { iv: iv });
    setExcpUserInfo({
      ...excpUserInfo,
      userPwd: encryptedCP.toString(),
    });
  };

  const handleOnChangeUserTel = (e) => {
    setExcpUserInfo({
      ...excpUserInfo,
      userTel: e.target.value,
    });
  };

  const handleOnChangeIdvlIfoCncYn = (e) => {
    setExcpUserInfo({
      ...excpUserInfo,
      idvlIfoCncYn: e,
    });
  };

  const handleOnRegistNewExcp = () => {
    setflexItem({
      excpNm: '',
      rpsnNm: '',
      rpsnEml: '',
      rpsnPhn: '',
      snsrYn: 'N',
      ipYn: 'N',
      mode: ManagementMode.CREATE,
    });
    setOpenExcpManagementPopUp(true);
  };

  const handleOnSave = async () => {
    let noProblem = true;
    const validatePwrd = (pwrd: any) => {
      return String(pwrd).match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,16}$/
      );
    };

    const validateTel = (tel: any) => {
      return String(tel).match(/^[-0-9]{8,16}$/);
    };

    if (excpUserInfo.userNm == '') {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.이름은 필수 입력사항입니다.', '이름은 필수 입력사항입니다.'),
      });
      return false;
    }

    if (excpUserInfo.userId == '') {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.아이디는 필수 입력사항입니다.', '아이디는 필수 입력사항입니다.'),
      });
      return false;
    }

    if (!hasCompleteCheck) {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.아이디 중복 체크를 해주세요.', '아이디 중복 체크를 해주세요.'),
      });
      return false;
    }

    if (upd == '') {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.비밀번호는 필수 입력사항입니다.', '비밀번호는 필수 입력사항입니다.'),
      });
      return false;
    }

    if (validatePwrd(upd) == null) {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.비밀번호는 8자이상 16자이하이어야 하고, 최소 하나의 숫자, 대문자, 소문자, 특수문자를 포함해야 됩니다.',
          '비밀번호는 8자이상 16자이하이어야 하고, 최소 하나의 숫자, 대문자, 소문자, 특수문자를 포함해야 됩니다.'
        ),
      });
      return false;
    }
    if (upd !== updr) {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.입력하신 비밀번호가 일치하지 않습니다.',
          '입력하신 비밀번호가 일치하지 않습니다.'
        ),
      });
      setExcpUserInfo({
        ...excpUserInfo,
        userPwd: '',
      });
      setUpdr('');
      return false;
    }

    if (excpUserInfo.excpId == '') {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.업체정보는 필수 선택사항입니다.', '업체정보는 필수 선택사항입니다.'),
      });
      return false;
    }

    if (excpUserInfo.userTel !== '' && !validateTel(excpUserInfo.userTel)) {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.형식에 맞지 않은 연락처입니다.', '형식에 맞지 않은 연락처입니다.'),
      });
      return false;
    }

    if (excpUserInfo.mgrUserId == '') {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.엔솔담당자는 필수 선택사항입니다.',
          '엔솔담당자는 필수 선택사항입니다.'
        ),
      });
      return false;
    }

    if (excpUserInfo.idvlIfoCncYn !== 'Y') {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.개인정보 활용동의 내용에 대한 동의 체크하셔야 가입가능합니다.',
          '개인정보 활용동의 내용에 대한 동의 체크하셔야 가입가능합니다.'
        ),
      });
      return false;
    }

    if (noProblem) {
      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.가입요청 진행하시겠습니까?', '가입요청 진행하시겠습니까?'),
        yesCallback: async () => {
          const response = await registExcpUser({
            ...excpUserInfo,
          });
          if (response.successOrNot === 'Y' && response.data.updatedRows == '1') {
            openMessageBar({
              type: 'confirm',
              content: t(
                'com.label.가입요청이 정상적으로 신청되었습니다.',
                '가입요청이 정상적으로 신청되었습니다.'
              ),
            });
            navigate('/system/login', { replace: true });
            //window.location.reload();
          } else if (response.successOrNot === 'Y' && response.data.updatedRows == '0') {
            openMessageBar({
              type: 'error',
              content: t(
                'com.label.사용자 아이디 중복으로 가입요청에 실패하였습니다.',
                '사용자 아이디 중복으로 가입요청에 실패하였습니다.'
              ),
            });
          } else {
            openMessageBar({
              type: 'error',
              content: t(
                'admin.msg.가입요청에 실패하였습니다. 입력값을 확인하세요.',
                '가입요청에 실패하였습니다. 입력값을 확인하세요.'
              ),
            });
          }
        },
      });
    }
  };

  return (
    <JoinLayout>
      <TableContainer css={tb.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.이름', '이름')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  onChange={handleOnChangeUserNm}
                  maxLength={33}
                  placeholder={String(t('com.label.이름을 입력하세요.', '이름을 입력하세요.'))}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.아이디(이메일)', '아이디(이메일)')}</span>
              </TableCell>
              <TableCell className="duplication">
                <div>
                  <CustomInputWithSearch
                    id="userId"
                    onChange={handleOnChangeUserId}
                    maxLength={50}
                    placeholder={String(
                      t('com.label.아이디(이메일)를 입력하세요.', '아이디(이메일)를 입력하세요.')
                    )}
                  />
                  <Button
                    onClick={confirmUserId}
                    disabled={hasCompleteCheck}
                    onChange={() => setCompleteCheck(false)}
                  >
                    {t('com.button.중복체크', '중복체크')}
                  </Button>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.비밀번호', '비밀번호')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  type="password"
                  onChange={handleChangePwd}
                  maxLength={20}
                  placeholder={String(
                    t('com.label.비밀번호를 입력해주세요.', '비밀번호를 입력하세요.')
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.비밀번호 확인', '비밀번호 확인')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  type="password"
                  onChange={handleChangeRePwd}
                  maxLength={20}
                  placeholder={String(
                    t('com.label.비밀번호를 다시 입력하세요.', '비밀번호를 다시 입력하세요.')
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.업체정보', '업체정보')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  value={excpNm}
                  readOnly={true}
                  className="find"
                  placeholder={String(
                    t('com.label.업체정보를 선택해주세요.', '업체정보를 선택해주세요.')
                  )}
                  onSearchClick={() => {
                    setOpenExcpListPopUp(true);
                  }}
                  msgError={String(
                    t('gtng.msg.점검대상장비를 선택해주세요.', '점검대상장비를 선택해주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.대표자명', '대표자명')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  value={excpRpsnNm}
                  readOnly={true}
                  placeholder={String(
                    t('com.label.대표자명을 입력해주세요.', '대표자명을 선택해주세요.')
                  )}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span>{t('com.label.연락처', '연락처')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  onChange={handleOnChangeUserTel}
                  maxLength={16}
                  placeholder={String(t('com.label.연락처를 입력하세요.', '연락처를 입력하세요.'))}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.업무(엔솔) 담당자', '업무(엔솔) 담당자')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  value={excpMgrNm}
                  className="find"
                  readOnly={true}
                  placeholder={String(
                    t(
                      'com.label.업무(엔솔)담당자를 선택해주세요.',
                      '업무(엔솔)담당자를 선택해주세요.'
                    )
                  )}
                  onSearchClick={() => {
                    setOpenExcpMgrListPopUp(true);
                  }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>
            {t('com.label.개인정보의 수집·이용 동의(필수)', '개인정보의 수집·이용 동의(필수)')}
          </h3>
        </SubTitleGroup>
        <CustomSwitch
          id={'idvlIfoCncYn'}
          onValue={'Y'} // 스위치 on 값
          onLabel={'동의'} // 스위치 on 문구
          offValue={'N'} // 스위치 off 값
          offLabel={'미동의'} // 스위치 off 문구
          defaultValue={'N'} // 기본값 생략가능 (default: onValue)
          onChange={handleOnChangeIdvlIfoCncYn}
        />
      </SubTitleLayout>
      <div css={cs.persnal}>
        <span>
          {t(
            'LG에너지솔루션(이하 "회사")은 [설비라이프사이클](이하 "서비스")를 이용하는 경우 아래와 같이 개인정보를 수집·이용 및 제공합니다. 회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 및 훼손되지 않도록 다양한 기술적 보호조치를 강구하고 있습니다. 기타 상세한 사항은 홈페이지에 게시된 "개인정보 처리방침"을 참조하시기 바랍니다.'
          )}
        </span>
        <h4>
          {t('com.label.개인정보 수집 및 이용에 대한 동의', '개인정보 수집 및 이용에 대한 동의')}
        </h4>
        <TableContainer css={tb.table}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>수집·이용 항목</TableCell>
                <TableCell>설비라이프사이클 등의 회사 업무시스템 이용</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>수집·이용 항목</TableCell>
                <TableCell>이름, 휴대폰 번호, 이메일, 회사명</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>보유 및 이용 기간</TableCell>
                <TableCell>회원 탈퇴 후 2년동안 보관</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>동의 거부 권리 안내</TableCell>
                <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                  {t(
                    '이용자는 개인정보 수집 및 이용과 관련하여 동의를 거부할 권리가 있으며, 동의 거부 시 회사의 업무시스템 접속이 불가 합니다.'
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <GlobalBtnGroup className="between">
        <Button css={IconButton.button} onClick={handleOnRegistNewExcp} className="create">
          {t('com.label.신규업체등록', '신규업체등록')}
        </Button>
        <Button css={IconButton.button} onClick={handleOnSave} className="confirm">
          {t('com.label.가입요청', '가입요청')}
        </Button>
      </GlobalBtnGroup>
      {isOpenExcpMgrListPopUp && (
        <ExcpMgrListPopUp
          open={isOpenExcpMgrListPopUp}
          close={() => setOpenExcpMgrListPopUp(false)}
          onCallback={(value) => {
            setExcpMgrNm(value.empNm);
            setExcpUserInfo({
              ...excpUserInfo,
              mgrUserId: value?.userId || '',
            });
          }}
        />
      )}
      {isOpenExcpListPopUp && (
        <ExcpListPopUp
          open={isOpenExcpListPopUp}
          close={() => setOpenExcpListPopUp(false)}
          onCallback={(value) => {
            setExcpNm(value.excpNm);
            setExcpRpsnNm(value.rpsnNm);
            setExcpUserInfo({
              ...excpUserInfo,
              excpId: value?.excpId || '',
            });
          }}
        />
      )}
      {isOpenExcpManagementPopUp && (
        <ExcpManagementPopUp
          open={isOpenExcpManagementPopUp}
          setPopup={setOpenExcpManagementPopUp}
          condition={flexItem}
          isFromRegistPage={true}
          close={() => setOpenExcpManagementPopUp(false)}
        />
      )}
    </JoinLayout>
  );
};
export default ExcpUserRegistPage;
