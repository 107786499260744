/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import { tb } from 'components/layouts/Table';
import { SubTitleGroup, SubTitleLayout, ControlBtnGroup } from 'components/layouts/ContentLayout';
import { ParameterPoint, ParameterPointCondition } from 'models/ip/EquipmentParameter';
import { downloadExcelParameterPoint, findParameterPoint } from 'apis/ip/EquipmentParameter';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { IconButton } from 'components/buttons/IconSVG';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';

type ParameterPointModalProps = {
  open: boolean;
  close: () => void;
  condition: ParameterPointCondition;
  info?: any;
};

const ParameterPointModal = ({ info, condition, open, close }: ParameterPointModalProps) => {
  const [rowData, setRowData] = useState<ParameterPoint[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { t } = useTranslation();
  useEffect(() => {
    handleSearchParaPoint(condition);
  }, []);

  const handleSearchParaPoint = async (condition: ParameterPointCondition) => {
    const response = await findParameterPoint(condition);
    if (response) {
      setRowData(response || []);
      setTotalCount(response.length);
    }
  };

  /*
  // modal 내 그리드 엑셀 다운로드 제외
  const handleDownloadExcel = () => {
    const excelData: ExcelDownloadRequest<ParameterPointCondition> = {
      fileName:
        t('ip.label.설비파라미터 측정개소 목록', '설비파라미터 측정개소 목록') + '.xlsx',
      sheetName: t('ip.label.설비파라미터 측정개소', '설비파라미터 측정개소'),
      header: [
        String(t('ip.grid.측정개소번호', '측정개소번호')),
        String(t('ip.grid.측정개소명', '측정개소명')),
        String(t('ip.grid.측정개소 설명', '측정개소 설명')),
      ],
      searchCondition: { ...condition },
    };

    downloadExcelParameterPoint(excelData);
  };
  */

  const layoutDefinition = [
    {
      binding: 'paraId',
      header: String(t('ip.grid.파라미터ID', '파라미터ID')),
      width: 150,
      align: 'center',
    },
    {
      binding: 'paraName',
      header: String(t('ip.grid.파라미터명', '파라미터명')),
      width: 200,
      align: 'left',
    },
    {
      binding: 'paraPointNo',
      header: String(t('ip.grid.측정개소번호', '측정개소번호')),
      width: 120,
      align: 'center',
    },
    {
      binding: 'paraPointName',
      header: String(t('ip.grid.측정개소명', '측정개소명')),
      width: 250,
      align: 'left',
    },
    {
      header: String(t('ip.grid.사양', '사양')),
      align: 'center',
      columns: [
        {
          binding: 'meterTypeNm',
          header: String(t('ip.grid.미터유형', '미터유형')),
          width: 100,
        },
        {
          binding: 'lowerBound',
          header: String(t('ip.grid.하한', '하한')),
          width: 70,
          align: 'right',
        },
        {
          binding: 'upperBound',
          header: String(t('ip.grid.상한', '상한')),
          width: 70,
          align: 'right',
        },
        {
          binding: 'uomNm',
          header: String(t('ip.grid.UOM', 'UOM')),
          width: 80,
        },
        { binding: 'rmk', header: String(t('ip.grid.주석', '주석')), width: 100 },
      ],
    },
    {
      binding: 'paraPointDesc',
      header: String(t('ip.grid.측정개소 설명', '측정개소 설명')),
      width: 250,
      align: 'left',
    },
  ];

  return (
    <CustomDialog
      open={open}
      title={t('ip.label.설비파라미터 측정개소 조회', '설비파라미터 측정개소 조회')}
      onClose={close}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.파라미터 정보', '파라미터 정보')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.tableRow}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} className="secondCol">
                <span>{t('com.label.설비군', '설비군')}</span>
              </TableCell>
              <TableCell>{info.equipmentGroup}</TableCell>
              <TableCell colSpan={2} className="secondCol">
                <span>{t('ip.label.파라미터명', '파라미터명')}</span>
              </TableCell>
              <TableCell>{info.paraName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={4} className="firstCol">
                <span>{t('com.label.설비', '설비')}</span>
              </TableCell>
              <TableCell className="secondCol">
                {t('ip.label.Anode/Cathode', 'Anode/Cathode')}
              </TableCell>
              <TableCell>{info.electrodeTypeNm}</TableCell>
              <TableCell rowSpan={6} className="firstCol" style={{ borderBottom: 'none' }}>
                <span>{t('ip.label.사양', '사양')}</span>
              </TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.측정기준', '측정기준')}</span>
              </TableCell>
              <TableCell>{info.specDataum}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="secondCol">
                <span>{t('ip.label.Machine', 'Machine')}</span>
              </TableCell>
              <TableCell>{info.machineName}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.미터유형', '미터유형')}</span>
              </TableCell>
              <TableCell>{info.meterTypeNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="secondCol">
                <span>{t('ip.label.Unit', 'Unit')}</span>
              </TableCell>
              <TableCell>{info.unitName}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.하한', '하한')}</span>
              </TableCell>
              <TableCell>{info.lowerBound}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="secondCol">
                <span>{t('ip.label.Assembly', 'Assembly')}</span>
              </TableCell>
              <TableCell>{info.assemblyName}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.grid.상한', '상한')}</span>
              </TableCell>
              <TableCell>{info.upperBound}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} className="secondCol">
                <span>{t('ip.label.파라미터 항목', '파라미터 항목')}</span>
              </TableCell>
              <TableCell>{info.paraItemNm}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.UOM', 'UOM')}</span>
              </TableCell>
              <TableCell>{info.uomNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} className="secondCol">
                <span>{t('ip.label.파라미터 ID', '파라미터 ID')}</span>
              </TableCell>
              <TableCell>{info.paraId}</TableCell>
              <TableCell className="secondCol">
                <span>{t('ip.label.주석', '주석')}</span>
              </TableCell>
              <TableCell>{info.rmk}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.측정개소', '측정개소')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          {/*
            <Button
              css={IconButton.button}
              className="Exceldown"
              onClick={handleDownloadExcel}
              disableRipple
            >
              다운로드
            </Button>
            */}
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearchParaPoint(condition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        rowData={rowData}
        layoutDefinition={layoutDefinition}
        height={200}
        isSelector={false}
        isReadOnly={true}
        excludeFilter={['paraId']}
        allowPinning={false}
      />
    </CustomDialog>
  );
};

export default ParameterPointModal;
