import { callApi } from 'utils/ApiUtil';
import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import { UtMatrixMaster, UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';

export const findMaster = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/regist-master`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<UtMatrixMaster[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixMaster[];
};

/**
 * 공정 정보 목록 조회
 * @param utmId
 */
export const findWithConditionMaster = async (condition: UtMatrixRegistSearchCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/regist-master`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<UtMatrixMaster[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixMaster[];
};

/**
 * 공정 정보 목록 조회
 * @param utmId
 */
export const findProcess = async (utmId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/process`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmId: utmId,
    }),
  };
  const response: CommonResponse<UtMatrixDetail[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixDetail[];
};

/**
 * UtMatrix 진척률 정보 조회
 * @param utmId
 */
export const findProcessBar = async (utmId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/regist-matrix/process`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmId: utmId,
    }),
  };
  const response: CommonResponse<UtMatrixDetail> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixDetail;
};

/**
 * UtMatrix 상세 목록 조회
 * @param condition
 */
export const findUtMatrix = async (type: 'write' | 'review', condition: UtMatrixDetail) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/regist-matrix/${type}`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...condition,
    }),
  };
  const response: CommonResponse<UtMatrixDetail[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixDetail[];
};

/**
 * 기계요약 목록 조회
 * @param condition
 */
export const findMachineSummary = async (condition: UtMatrixDetail) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/regist-matrix/machine`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...condition,
    }),
  };
  const response: CommonResponse<UtMatrixDetail[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixDetail[];
};

/**
 * 전기요약 목록 조회
 * @param condition
 */
export const findElectricSummary = async (condition: UtMatrixDetail) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/regist-matrix/electric`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...condition,
    }),
  };
  const response: CommonResponse<UtMatrixDetail[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixDetail[];
};

/**
 * 표준사용량 비교 목록 조회
 * @param condition
 */
export const findStandardUse = async (condition: UtMatrixDetail) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/regist-matrix/use`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...condition,
    }),
  };
  const response: CommonResponse<UtMatrixDetail[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixDetail[];
};

/**
 * Ut Matrix 상세 저장
 * @param detailList
 */
export const saveUtMatrix = async (type: 'write' | 'review', detailList: UtMatrixDetail[]) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/ut/regist-matrix/${type}`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: detailList,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

export const submitInterlockRegistMatrix = async (utmId: string, planProcId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/regist-matrix/submit/interlock`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmId: utmId,
      planProcId: planProcId,
    }),
  };
  const response: CommonResponse<string> = await callApi(request);
  return response;
};

export const submitCheckRegistMatrix = async (utmId: string, planProcId?: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/regist-matrix/submit/check`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmId: utmId,
      planProcId: planProcId || '',
    }),
  };
  const response: CommonResponse<string> = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

//검증 ?? 왜 패키지가 같지?
export const submitCheckReviewMatrix = async (utmId: string) => {
  console.log('-------------------------utmId : ', utmId);
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/regist-matrix/submit/check`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmId: utmId,
    }),
  };
  const response: CommonResponse<string> = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

/**
 * Ut Matrix 제출
 * @param utmId
 */
export const submitUtMatix = async (condition: UtMatrixRegistSearchCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/regist-matrix`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: {
      ...condition,
    },
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

export const changeStatUtMatix = async (condition: UtMatrixRegistSearchCondition) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/ut/regist-matrix/stat`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: {
      ...condition,
    },
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

export const saveUtMatrixDemand = async (detail: UtMatrixDetail) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/ut/regist-matrix/demand`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: detail,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};
