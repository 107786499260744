/** @jsxImportSource @emotion/react */
import React, { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { findMpEqiupmentClassificationMaster } from 'apis/mp/MpEquipment';
import { EquipmentClassificationMaster } from 'models/mp/MpEquipment';
import CustomGrid from 'components/grids/CustomGrid';

interface MpEquipmentClsfMasterGridProps {
  onClickDetail: (item: EquipmentClassificationMaster) => void;
  onClickApprove: (aprReqId: string) => void;
}

const MpEquipmentClassificationMasterGrid = (
  { onClickDetail, onClickApprove }: MpEquipmentClsfMasterGridProps,
  ref
) => {
  const { t } = useTranslation();

  const [rowData, setRowData] = useState<EquipmentClassificationMaster[]>([]);
  const [selectedRow, setSelectedRow] = useState<EquipmentClassificationMaster[]>();

  useImperativeHandle(ref, () => ({
    search: async (condition) => {
      await handleSearch(condition);
    },
    getSelectedRow: () => {
      return selectedRow || [];
    },
  }));

  const handleSearch = async (condition) => {
    const response = await findMpEqiupmentClassificationMaster(condition);
    if (response) {
      const rowData = response || [];
      setRowData(rowData);
    }
  };

  const layoutDefinition = [
    {
      binding: 'eqclId',
      header: String(t('com.label.설비군', '설비군')),
      align: 'center',
      width: 80,
    },
    {
      binding: 'eqpClsfNo',
      header: String(t('mp.grid.설비분류체계번호', '설비분류체계번호')),
      align: 'center',
      width: 150,
    },
    {
      binding: 'eqpClsfNm',
      header: String(t('mp.grid.설비분류체계명', '설비분류체계명')),
      align: 'left',
      minWidth: 175,
      width: '*',
    },
    {
      header: String(t('mp.grid.상세', '상세')),
      binding: 'detailNo',
      width: 70,
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: (params) => `<Button />`,
    },
    {
      binding: 'eqpMdpNm',
      header: String(t('mp.grid.제조사', '제조사')),
      align: 'left',
      width: 120,
    },
    {
      binding: 'useYn',
      header: String(t('com.label.사용여부', '사용여부')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'eqpGenNm',
      header: String(t('mp.grid.세대구분', '세대구분')),
      width: 100,
      align: 'center',
    },
    {
      binding: 'aprReqId',
      header: String(t('mp.grid.요청번호', '요청번호')),
      width: 170,
      align: 'left',
      cssClass: 'WijmoPopup',
      cellTemplate: (params) => {
        if (params.value == null || params.value == '') {
          return '';
        } else {
          return `${params.value} <Button />`;
        }
      },
    },
  ];

  const onInitialized = (grid) => {
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (ht.panel === grid.cells) {
        // 상세보기
        if ('detailNo' === binding) {
          onClickDetail(item);
        }
        // 결재 요청 상세보기
        else if ('aprReqId' === binding) {
          if (item.aprReqId) {
            onClickApprove(item.aprReqId);
          }
        }
      }
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.설비분류체계 List', '설비분류체계 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isReadOnly={true}
        height={570}
        autoCheck={true}
        allowPinning={false}
        excludeFilter={['detailNo']}
        rowSelection={'single'}
        initialized={onInitialized}
        onChangeCheckedItem={(items) => setSelectedRow(items)}
      />
    </>
  );
};

export default React.forwardRef(MpEquipmentClassificationMasterGrid);
