/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useState } from 'react';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import { getEquipments, getEquipments2 } from 'apis/gtng/popup/GatingEquipment';
import { EquipRowData } from 'pages/common/popup/GatingEquipPopUp';
import { useTranslation } from 'react-i18next';

/**
 * Gating 생성/조회 -> 장비 및 장비별 담당자 조회 (읽기전용)
 * @param props
 * @constructor
 */

export const GatingEquipContRetrievePopUp = (props: any) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<EquipRowData[]>([]);

  const onInitialized = (grid) => {
    // new FlexGridFilter(grid);
    grid.rowHeaders.columns.splice(0, 1);
  };

  const LayoutDefinition = () => {
    return [
      {
        header: String(t('com.label.NO', 'NO')),
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
        width: 40,
      },
      {
        binding: 'equipmentId',
        header: String(t('gtng.grid.Equipment', 'Equipment')),
        align: 'left',
        width: '*',
      },
      {
        binding: 'equipmentName',
        header: String(t('gtng.grid.Equipment Desc', 'Equipment Desc')),
        align: 'left',
        width: 200,
      },
      {
        binding: 'startDate',
        header: String(t('gtng.grid.시작일', '시작일')),
        align: 'center',
        isReadOnly: 'false',
        width: 130,
      },
      {
        binding: 'endDate',
        header: String(t('gtng.grid.종료일', '종료일')),
        align: 'center',
        isReadOnly: 'false',
        width: 130,
      },
      {
        binding: 'gatingEqpExecuteContNames',
        header: String(t('gtng.label.실시담당자', '실시담당자')),
        align: 'left',
        width: '*',
      },
      {
        binding: 'gatingEqpCheckContNames',
        header: String(t('gtng.label.점검담당자', '점검담당자')),
        align: 'left',
        width: '*',
      },
      {
        binding: 'gatingEqpExecuteTlContNames',
        header: String(t('gtng.label.실시(T/L)', '실시(T/L)')),
        align: 'left',
        width: '*',
      },
      {
        binding: 'progressStatusName',
        header: String(t('gtng.grid.단계', '단계')),
        width: '*',
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  useEffect(() => {
    if (props.initParam != null && props.initParam != '') {
      const gatingId = props.initParam;
      getEquipmentInfo(gatingId);
    }
  }, [props.initParam]);

  const getEquipmentInfo = async (gatingId) => {
    await getEquipments2(gatingId).then((result: any) => {
      if (result != null && result != '') {
        setRowData(result);
      }
    });
  };

  return (
    <CustomDialog
      open={true}
      title={t('gtng.label.점검장비', '점검장비현황')}
      onClose={() => props.setPopup(false)}
    >
      <CustomGrid
        rowData={state.itemsSource}
        layoutDefinition={state.layoutDefinition}
        initialized={onInitialized}
        isReadOnly={true}
        isSelector={false}
        isFilter={false}
        allowPinning={false}
        align="center"
      />
    </CustomDialog>
  );
};
