import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { SearchParam } from 'pages/gtng/GatingRegistPage';
import { GatingResultReport } from 'models/gtng/GatingResultReport';
import { GatingResultReportAct } from 'models/gtng/GatingResultReportAct';

export const findGatingResultReportMst = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingResultReportMst`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<GatingResultReport[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingResultReport[];
};

export const findGatingResultReportAct = async (gatingId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingResultReportAct`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse<GatingResultReportAct[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingResultReportAct[];
};
