import React, { InputHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { Textarea, TextareaWrap } from './CustomInput';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  showMaxLength?: boolean; // 글자수제한 표기여부 (true인 경우 maxLength 값 필요)
  isError?: boolean; // 에러여부
  msgError?: string; // 에러문구
  wrapStyle?: any; // <TextareaWrap>에 추가되는 style
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  // 그 외 속성은 InputHTMLAttributes 그대로 사용
}

const CustomTextarea = (props: Props) => {
  const {
    showMaxLength = false,
    isError = false,
    msgError,
    wrapStyle = {},
    onChange,
    ...inputProps
  } = props;
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isFocus, setFocus] = useState(false);
  const [textLength, setTextLength] = useState(0);
  const showError = useMemo(() => {
    if (isError) {
      return !isFocus && msgError;
    }
    return false;
  }, [msgError, isError, isFocus]);

  const wrapClassName = useMemo(() => {
    const arr = [] as string[];
    if (isError && !isFocus && msgError) {
      arr.push('error');
    }
    if (isFocus) {
      arr.push('focus');
    }
    // [24.02.01] readonly 나 disabled인 경우 클래스 추가 (퍼블요청)
    if (inputProps.disabled || inputProps.readOnly) {
      arr.push('readOnly');
      // arr.push('disabled');
    }
    if (showMaxLength && inputProps?.maxLength) {
      arr.push('num');
    }
    return arr.join(' ');
  }, [msgError, isError, isFocus, showMaxLength, inputProps?.maxLength]);

  useEffect(() => {
    document.addEventListener('click', handleFocusCheck);
    return () => {
      document.removeEventListener('click', handleFocusCheck);
    };
  }, []);

  const handleFocusCheck = (e) => {
    if (document.activeElement !== searchRef.current && !searchRef.current?.contains(e.target)) {
      setFocus(false);
    }
  };

  const handleChange = (e) => {
    setTextLength(e.target.textLength);
    onChange && onChange(e);
  };

  return (
    <>
      <TextareaWrap ref={searchRef} style={wrapStyle} className={wrapClassName}>
        <Textarea
          ref={inputRef}
          {...inputProps}
          defaultValue={inputProps?.defaultValue || ''}
          onFocus={() => setFocus(true)}
          onChange={handleChange}
        />
        {showMaxLength && inputProps.maxLength && (
          <div className="num">
            {(textLength || 0).toLocaleString()}/{(inputProps.maxLength || 0).toLocaleString()}
          </div>
        )}
      </TextareaWrap>
      {showError && <span className="message">{msgError}</span>}
    </>
  );
};
export default CustomTextarea;
