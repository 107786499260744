/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { ComboBox } from 'components/selects/ComboBox';
import { AllInputWrap, CustomInputText, CustomInputWrap } from 'components/inputs/CustomInput';
import { CustomSelect } from 'components/selects/CustomSelect';
import { prototype } from 'events';

// 프로퍼티 타입 지정
TableCellSelectBox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.oneOfType([() => null, PropTypes.any]).isRequired,
  required: PropTypes.bool,

  options: PropTypes.any,
  onChange: PropTypes.any,
  keyword: PropTypes.any,
};

TableCellSelectBox.defaultProps = {
  disabled: false,
};

export default function TableCellSelectBox(props) {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Code[]>([]);
  const [value, setValue] = useState(props.defaultValue);
  const [showError, setShowError] = useState<boolean>(false);

  const handleOnChange = (value: any) => {
    setShowError(false);
    if (!value) {
      setShowError(true);
      return;
    }
    const result = {
      id: props.id,
      value: value,
    };
    props.onChange(result);
  };

  useEffect(() => {
    setValue(props?.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    props.keyword &&
      (async function () {
        const result = await getCommonCodeNames(props.keyword);
        if (result != null) setOptions(result);
      })();
  }, []);

  const labelId = 'common.label.' + props.name;
  const labelText = '' + props.name;

  return (
    <>
      <TableCell>
        <span className={props.required ? 'dot' : ''}>{t(labelId, labelText)}</span>
      </TableCell>
      <TableCell>
        <AllInputWrap className={showError ? 'error' : ''}>
          <ComboBox
            placeholder={props.placeholder}
            options={options}
            defaultValue={value}
            disabled={props.disabled}
            onChange={handleOnChange}
          />
          {showError && (
            <span>{t('com.label.옵션을 선택해 주세요.', '옵션을 선택해 주세요.')}</span>
          )}
        </AllInputWrap>
      </TableCell>
    </>
  );
}
