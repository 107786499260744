/** @jsxImportSource @emotion/react */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TitleWrap, di } from 'components/layouts/Dialog';
import { Button, TableCell } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { tb } from 'components/layouts/Table';
import CustomSwitch from 'components/selects/CustomSwitch';
import TableCellInputText from 'pages/common/components/TableCellInputText';
import TableCellSelectBox from 'pages/common/components/TableCellSelectBox';
import TableCellReadOnly from 'pages/common/components/TableCellReadOnly';
import TableCellDateRangePicker from 'pages/common/components/TableCellDateRangePicker';
import { ContentSection } from 'components/layouts/ContentSection';
import moment from 'moment';
import { simpleRequest } from 'apis/ip/IpCheckPlan';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import useSessionStore from 'stores/useSessionStore';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { CrudCode } from 'models/common/Edit';
import SelectTargetModal from './SelectTargetModal';
import { emptyRangeDate } from 'models/ip/IpCheckPlan';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';
import PartnerUserModal from 'components/modals/common/PartnerUserModal';
import { IconButton } from 'components/buttons/IconSVG';
import { flexGridTooltip } from 'models/ip/Public';
import _ from 'lodash';
// Wijmo library
import 'ui/css/multiSelect.css';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { IpCheckResultPointModal } from './IpCheckResultPointModal';
import useEvent from 'react-use-event-hook';
import { EquipmentParameter, ParameterPointCondition } from 'models/ip/EquipmentParameter';
import CustomGrid from 'components/grids/CustomGrid';
import CustomDialog from 'components/modals/common/CustomDialog';

type Props = {
  open: boolean;
  close: () => void;
  params: any;
  //save: (item: Employee[]) => void;
  singleSelect?: boolean;
};

const cleanSet = {
  ipCheckPlanType: '',
  copCd: '',
  wbsCode: '',
  dataInsUserId: '',
  ipCheckPlanNo: '',
  ipCheckPlanName: '',
  fdmProjectNo: '',
  fdmProjectName: '',
  fatDate: emptyRangeDate,
  satDate: emptyRangeDate,
  rmk: '',
  emlSndoYN: 'N',
};

const MakePlanModal = (props: Props) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const [isUpdate, setUpdate] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [disabledCompleteBtn, setDisabledCompleteBtn] = useState(true);
  const { userId, empNm } = useSessionStore();
  const [selectTargetModalOpen, setSelectTargetModalOpen] = useState<boolean>(false);
  const [isOpenElmEmpPopup, setOpenElmEmpPopup] = useState<boolean>(false);
  const [isOpenPartnerPopup, setOpenPartnerPopup] = useState<boolean>(false);
  const [isOpenParaPoint, setOpenParaPoint] = useState<boolean>(false);
  const [paraPointCondition, setParaPointCondition] = useState<ParameterPointCondition>({});
  const [paraPointInfo, setParaPointInfo] = useState<EquipmentParameter>({});

  const [rowData, setRowData] = useState<any>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [selectData, setSelectData] = useState<any>([]);
  const [mode, setMode] = useState<any>();
  const [generic, setGeneric] = useState<any>(cleanSet);
  const [isRequired, setRequired] = useState<any>({
    ipCheckPlanType: true,
    ipCheckPlanName: true,
    isptTpCd: true,
    copCd: true,
    rmk: true,
  });
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [userData, setUserData] = useState<any>('');
  const [empPopupCondition, setEmpPopupCondition] = useState({
    fieldId: '',
    initParam: '',
    // initUserId: '',
  });

  // const defaultColDef = useMemo(() => {
  //   return commonColDef;
  // }, []);

  // const valueFormatter = (params: any) => {
  //   let temp = '';
  //   switch (params.colDef.field) {
  //     case 'fatMakerPlanInspector':
  //       temp = params.node.data.fatMakerPlanInspectorNm;
  //       break;
  //     case 'fatEnsolPlanInspector':
  //       temp = params.node.data.fatEnsolPlanInspectorNm;
  //       break;
  //     case 'satMakerPlanInspector':
  //       temp = params.node.data.satMakerPlanInspectorNm;
  //       break;
  //     case 'satEnsolPlanInspector':
  //       temp = params.node.data.satEnsolPlanInspectorNm;
  //       break;
  //   }
  //   return temp;
  //   // if (params.colDef.field === 'fatMakerPlanInspector') {
  //   //   return params.node.data.fatMakerPlanInspectorNm;
  //   // }
  //   // if (params.colDef.field == 'fatEnsolPlanInspector') {
  //   //   return params.node.data.fatEnsolPlanInspectorNm;
  //   // }
  //   // if (params.colDef.field == 'satMakerPlanInspector') {
  //   //   return params.node.data.satMakerPlanInspectorNm;
  //   // }
  //   // if (params.colDef.field == 'satEnsolPlanInspector') {
  //   //   return params.node.data.satEnsolPlanInspectorNm;
  //   // }
  // };

  const handleOpenParaPointModal = (item) => {
    const { eqpParaNo, versionNo, paraId } = item;
    setParaPointCondition({
      eqpParaNo,
      versionNo,
      paraId,
    } as ParameterPointCondition);
    setParaPointInfo({
      ...item,
    } as EquipmentParameter);
    setOpenParaPoint(true);
  };

  const handleParaPointCell = (item) => {
    if (item.paraPointType === 'POINT') {
      handleOpenParaPointModal(item);
    }
  };

  const LayoutDefinition = () => {
    return [
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 20,
        visible: false,
      },
      {
        header: 'No',
        binding: 'No',
        width: 60,
        isReadOnly: true,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.설비군', '설비군')),
        binding: 'equipmentGroup',
        align: 'center',
        isReadOnly: true,
        width: 80,
      },
      {
        header: String(t('ip.grid.설비', '설비')),
        align: 'center',
        columns: [
          {
            header: String(t('ip.grid.Anode/Cathode', 'Anode/Cathode')),
            binding: 'fatMakerPlanDate',
            width: 100,
            isReadOnly: true,
          },
          {
            header: String(t('ip.grid.Main', 'Main')),
            binding: 'eqpMain',
            align: 'center',
            width: 120,
            isReadOnly: true,
          },
          {
            header: String(t('ip.grid.Machine', 'Machine')),
            binding: 'machineName',
            width: 80,
            isReadOnly: true,
          },
          {
            header: String(t('ip.grid.Unit', 'Unit')),
            binding: 'unitName',
            width: 80,
            isReadOnly: true,
          },
          {
            header: String(t('ip.grid.Assembly', 'Assembly')),
            binding: 'assemblyName',
            width: 80,
            isReadOnly: true,
          },
        ],
      },
      {
        header: String(t('ip.grid.IP구분', 'IP구분')),
        binding: 'ipCategory',
        align: 'center',
        isReadOnly: true,
        width: 90,
      },
      {
        header: String(t('ip.grid.점검단계', '점검단계')),
        binding: 'checkStep',
        isReadOnly: true,
        width: 100,
      },
      {
        header: String(t('ip.grid.파라미터 항목', '파라미터 항목')),
        binding: 'paraItem',
        align: 'center',
        isReadOnly: true,
        width: 120,
      },
      {
        header: String(t('ip.grid.파라미터ID', '파라미터ID')),
        binding: 'paraId',
        align: 'center',
        isReadOnly: true,
        width: 120,
      },
      {
        header: String(t('ip.grid.파라미터명', '파라미터명')),
        binding: 'paraName',
        align: 'center',
        isReadOnly: true,
        width: 150,
      },
      {
        header: String(t('ip.grid.파라미터 설명', '파라미터 설명')),
        binding: 'paraDesc',
        align: 'center',
        isReadOnly: true,
        width: 200,
      },
      {
        header: String(t('ip.grid.사양', '사양')),
        align: 'center',
        columns: [
          {
            header: String(t('ip.grid.측정기준', '측정기준')),
            binding: 'specDataum',
            align: 'center',
            isReadOnly: true,
            width: 100,
          },
          {
            header: String(t('ip.grid.미터유형', '미터유형')),
            binding: 'meterType',
            isReadOnly: true,
            align: 'center',
            width: 100,
          },
          {
            header: String(t('ip.grid.하한', '하한')),
            binding: 'lowerBound',
            width: 80,
            // cssClass: 'cellEditing',
            // onCellValueChanged: (params) => {
            //   params.node?.setDataValue('crudKey', CrudCode.UPDATE);
            // },
          },
          {
            header: String(t('ip.grid.상한', '상한')),
            binding: 'upperBound',
            width: 80,
            cssClass: 'cellEditing',
            // onCellValueChanged: (params) => {
            //   params.node?.setDataValue('crudKey', CrudCode.UPDATE);
            // },
          },
          {
            header: String(t('ip.grid.UOM', 'UOM')),
            binding: 'uom',
            isReadOnly: true,
            align: 'center',
            width: 80,
          },
          {
            header: String(t('ip.grid.주석', '주석')),
            binding: 'rmk',
            isReadOnly: true,
            width: 100,
          },
        ],
      },
      {
        header: String(t('ip.grid.측정', '측정')),
        align: 'center',
        columns: [
          {
            header: String(t('ip.grid.도구', '도구')),
            binding: 'measureTool',
            isReadOnly: true,
            width: 100,
          },
          {
            header: String(t('ip.grid.방법', '방법')),
            binding: 'measureMethod',
            isReadOnly: true,
            width: 100,
          },
          {
            header: String(t('ip.grid.가이드', '가이드')),
            binding: 'measureGuide',
            isReadOnly: true,
            width: 100,
          },
          {
            header: String(t('ip.grid.A/D', 'A/D')),
            binding: '',
            isReadOnly: true,
            width: 100,
          },
        ],
      },
      {
        header: String(t('ip.grid.측정개소', '측정개소')),
        align: 'center',
        columns: [
          {
            header: String(t('com.label.유형', '유형')),
            binding: 'paraPointType',
            isReadOnly: true,
            width: 120,
            cssClass: 'WijmoPopup',
            cellTemplate: (params) => `
            <span>${params.value}</span>
            ${
              params.value == '개소관리' && params.item.paraPointCnt > 0
                ? `<Button id="paraPointBtn"></Button>`
                : ''
            }`,
          },
          {
            header: String(t('ip.grid.개수', '개수')),
            binding: 'paraPointCnt',
            isReadOnly: true,
            align: 'center',
            width: 65,
          },
          {
            header: String(t('ip.grid.첨부', '첨부')),
            binding: 'attach',
            isReadOnly: true,
            align: 'center',
            width: 65,
            cellTemplate: (params) =>
              `<span>
                      <em>${(params.item.atchFileCnt || 0).toLocaleString()}</em>
              </span>`,
          },
        ],
      },
      {
        header: String(t('ip.grid.CTQ/CTP', 'CTQ/CTP')),
        align: 'center',
        columns: [
          {
            header: String(t('com.label.유형', '유형')),
            binding: 'fatMakerPlanDate',
            isReadOnly: true,
            align: 'center',
            width: 100,
          },
          {
            header: String(t('com.label.상세', '상세')),
            binding: 'fatMakerCompleteDate',
            isReadOnly: true,
            align: 'center',
            width: 100,
          },
        ],
      },
      {
        header: String(t('ip.grid.핵심IP여부', '핵심IP여부')),
        binding: 'mainIpFlag',
        isReadOnly: true,
        align: 'center',
        width: 90,
      },
      {
        header: 'FAT(' + String(t('ip.grid.Maker', 'Maker')) + ')',
        align: 'center',
        columns: [
          {
            binding: 'fatMakerPlanInspector',
            visible: false,
          },
          {
            binding: 'fatMakerPlanInspectorNm',
            width: 120,
            header: String(t('com.label.담당자', '담당자')),
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: (params) =>
              `<span>${params.value || ''}</span><button id="btnPartner" />`,
            //cellTemplate: valueFormatter,
            // cellTemplate: btnFindPartnerEmployee,
            // cellTemplate: (params) => btnElmEmpPopup(params),
            // cellRendererParams: (params) => {
            //   return {
            //     ...params,
            //     fieldNameUserId: 'fatMakerPlanInspector',
            //   };
            // },
          },
          {
            header: String(t('ip.grid.대상여부', '대상여부')),
            binding: 'fatMakerTargetFlag',
            isReadOnly: true,
            align: 'center',
            width: 80,
            // cellTemplate: CellRendererCheckbox,
            cellTemplate: (params) => {
              const checked = params.value === 'Y' ? 'checked' : '';
              return (
                '<input id="fatMakerChk" type="checkbox" className="customStringCheckbox"' +
                checked +
                '/>'
              );
            },
          },
        ],
      },
      {
        header: 'FAT(' + String(t('com.label.엔솔', '엔솔')) + ')',
        align: 'center',
        columns: [
          {
            binding: 'fatEnsolPlanInspector',
            visible: false,
          },
          {
            header: String(t('com.label.담당자', '담당자')),
            binding: 'fatEnsolPlanInspectorNm',
            isReadOnly: true,
            width: 120,
            // cssClass: 'cellFind',
            cssClass: 'WijmoFind',
            cellTemplate: (params) => `<span>${params.value || ''}</span><button id="btnEmp" />`,
            // cellTemplate: btnFindEmployee,
            // cellTemplate: (params) => {
            //   return {
            //     ...params,
            //     fieldNameUserId: 'fatEnsolPlanInspector',
            //   };
            // },
          },
          {
            header: String(t('ip.grid.대상여부', '대상여부')),
            binding: 'fatEnsolTargetFlag',
            isReadOnly: true,
            align: 'center',
            width: 80,
            cellTemplate: (params) => {
              const checked = params.value === 'Y' ? 'checked' : '';
              return (
                '<input id="fatEnsolChk" type="checkbox" className="customStringCheckbox"' +
                checked +
                '/>'
              );
            },
            // cellTemplate: CellRendererCheckbox,
          },
        ],
      },
      {
        header: 'SAT(' + String(t('ip.grid.Maker', 'Maker')) + ')',
        align: 'center',
        columns: [
          {
            binding: 'satMakerPlanInspector',
            visible: false,
          },
          {
            header: String(t('com.label.담당자', '담당자')),
            binding: 'satMakerPlanInspectorNm',
            isReadOnly: true,
            width: 120,
            cssClass: 'WijmoFind',
            cellTemplate: (params) =>
              `<span>${params.value || ''}</span><button id="btnPartner" />`,
            // cssClass: 'cellFind',
            // cellTemplate: btnFindPartnerEmployee,
            // cellTemplate: (params) => {
            //   return {
            //     ...params,
            //     fieldNameUserId: 'satMakerPlanInspector',
            //   };
            // },
          },
          {
            header: String(t('ip.grid.대상여부', '대상여부')),
            binding: 'satMakerTargetFlag',
            isReadOnly: true,
            align: 'center',
            width: 80,
            cellTemplate: (params) => {
              const checked = params.value === 'Y' ? 'checked' : '';
              return (
                '<input id="satMakerChk" type="checkbox" className="customStringCheckbox"' +
                checked +
                '/>'
              );
            },
            // cellTemplate: CellRendererCheckbox,
          },
        ],
      },
      {
        header: 'SAT(' + String(t('com.label.엔솔', '엔솔')) + ')',
        align: 'center',
        columns: [
          {
            binding: 'satEnsolPlanInspector',
            visible: false,
          },
          {
            header: String(t('com.label.담당자', '담당자')),
            binding: 'satEnsolPlanInspectorNm',
            isReadOnly: true,
            width: 120,
            cssClass: 'WijmoFind',
            cellTemplate: (params) => `<span>${params.value || ''}</span><button id="btnEmp" />`,
            // cssClass: 'cellFind',
            // cellTemplate: btnFindEmployee,
            // cellTemplate: (params) => {
            //   return {
            //     ...params,
            //     fieldNameUserId: 'satEnsolPlanInspector',
            //   };
            // },
          },
          {
            header: String(t('ip.grid.대상여부', '대상여부')),
            binding: 'satEnsolTargetFlag',
            isReadOnly: true,
            align: 'center',
            width: 80,
            cellTemplate: (params) => {
              const checked = params.value === 'Y' ? 'checked' : '';
              return (
                '<input id="satEnsolChk" type="checkbox" className="customStringCheckbox"' +
                checked +
                '/>'
              );
            },
            // cellTemplate: CellRendererCheckbox,
          },
        ],
      },
      {
        header: String(t('ip.grid.첨부', '첨부')),
        binding: 'attach',
        isReadOnly: true,
        align: 'center',
        width: 65,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onInitialized = useEvent((grid) => {
    // new FlexGridFilter(grid);
    setflexRef(grid);
    grid.rowHeaders.columns.splice(0, 1);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      //수정여부
      let edited = false;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);

      switch (e.target.id) {
        case 'paraPointBtn':
          edited = true;
          setOpen(true);
          break;
        case 'fatMakerChk':
          edited = true;
          grid.setCellData(ht.row, ht.col, e.target.checked ? 'Y' : 'N');
          if (item.fatMakerTargetFlag === 'Y') {
            item.fatEnsolTargetFlag = 'Y';
            item.satMakerTargetFlag = 'Y';
            item.satEnsolTargetFlag = 'Y';
          } else if (item.fatMakerTargetFlag === 'N') {
            item.fatEnsolTargetFlag = 'N';
            item.satMakerTargetFlag = 'N';
            item.satEnsolTargetFlag = 'N';
          }
          break;
          break;
        case 'fatEnsolChk':
          edited = true;
          grid.setCellData(ht.row, ht.col, e.target.checked ? 'Y' : 'N');
          break;
        case 'satMakerChk':
          edited = true;
          grid.setCellData(ht.row, ht.col, e.target.checked ? 'Y' : 'N');
          break;
        case 'satEnsolChk':
          edited = true;
          grid.setCellData(ht.row, ht.col, e.target.checked ? 'Y' : 'N');
          break;
        case 'btnEmp':
          edited = true;
          setEmpPopupCondition({
            fieldId: 'reqMgrNm',
            initParam: item[col.binding],
            // initUserId: item[col.binding.slice(0, -2)],
          });
          setOpenElmEmpPopup(true);
          break;
        case 'btnPartner':
          edited = true;
          setEmpPopupCondition({
            fieldId: 'reqMgrNm',
            initParam: item[col.binding],
            // initUserId: item[col.binding.slice(0, -2)],
          });
          setOpenPartnerPopup(true);
          break;
        case 'fileAttach':
          edited = true;
          // popUpOpen(item);
          break;
      }
      edited ? (item.crudKey = CrudCode.UPDATE) : '';
      grid.startEditing(true);
    });
  });

  const handlOnClose = () => {
    props.close();
  };

  useEffect(() => {
    //신규라는 가정하에 초기화
    //setGeneric(cleanSet);
    setDisabled(false);
    setDisabledBtn(true);

    //setParams(props.params);
    setMode(props.params?.mode);
    if (props.params?.mode == CrudCode.CREATE) {
      setGeneric((generic) => ({ ...generic, ['dataInsUserId']: userId }));
      setGeneric((generic) => ({ ...generic, ['ipCheckPlanType']: 'IP' }));
      setRequired({ ...isRequired, rmk: false });
      return;
    }

    //수정, 삭제일경우
    if (props.params?.rowSingle?.ipCheckPlanNo) {
      setGeneric(props.params?.rowSingle);
      setUpdate(true);
      setDisabledBtn(false);
      drawList(props.params?.rowSingle);
    }
  }, [props.params?.rowSingle]);

  useEffect(() => {
    setDisabledCompleteBtn(true);
    if (rowData && rowData.length > 0) setDisabledCompleteBtn(false);
  }, [rowData]);

  const drawList = async (params: any) => {
    await simpleRequest(params, `/v1/ipCheckListProduce`).then((result: any[]) => {
      setRowData(result);
    });
  };

  const selectList = async (params: any) => {
    await simpleRequest(params, `/v1/ipCheckListProduce`).then((result: any[]) => {
      setSelectData(result);
    });
  };

  // const reConfirm = () => {
  //   selectList(props.params?.rowSingle);
  //   // if ([CrudCode.UPDATE, CrudCode.DELETE].includes(mode)) {
  //   const sortedSelectList = selectData.map((sorted) =>
  //     Object.keys(sorted)
  //       .sort()
  //       .reduce((obj, key) => ((obj[key] = sorted[key]), obj), [{}])
  //   );
  //   const sortedRowData = rowData.map((sorted) =>
  //     Object.keys(sorted)
  //       .sort()
  //       .reduce((obj, key) => ((obj[key] = sorted[key]), obj), [{}])
  //   );
  //   if (selectList != rowData) {
  //     openMessageBar({
  //       type: 'error',
  //       content: t(
  //         'com.label.수정중인 점검 계획이 있습니다. 저장 후 계획 수립 완료를 진행해 주세요.',
  //         '수정중인 점검 계획이 있습니다. 저장 후 계획 수립 완료를 진행해 주세요.'
  //       ),
  //     });
  //     return;
  //   }
  // };

  const onCellValueChanged = useCallback((e) => {
    !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  }, []);

  const getNewSeq = async (cond) => {
    await simpleRequest(cond, `/v1/ipCheckListNewSeq`).then((result: any[]) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t('ip.msg.번호 생성에 실패하였습니다.', '번호 생성에 실패하였습니다.'),
        });
        return;
      }
      setGeneric((generic) => ({ ...generic, ['ipCheckPlanNo']: String(cond + '-' + result) }));
    });
  };

  const listner = (received) => {
    setGeneric({ ...generic, [received.id]: received.value });
    // await setRequired((isRequired) => ({ ...isRequired, [received.id]: received.validation }));

    switch (received.id) {
      case 'copCd':
        getNewSeq(received.value.concat(moment().format('YYYYMM')));
        break;
      case 'ipCheckPlanType':
        setDisabled(false);
        if (received.value === 'IP') {
          setDisabled(true);
        }
        break;
    }
  };

  const insertIpCheckPlan = async (cond) => {
    await simpleRequest(cond, `/v1/insertIpCheckPlan`).then((result: any[]) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
        });
        return;
      }
      setDisabledBtn(false);
      openMessageBar({
        type: 'confirm',
        content: t('com.label.저장되었습니다.', '저장되었습니다.'),
      });
      setMode(CrudCode.UPDATE);
      setRequired((isRequired) => ({ ...isRequired, rmk: false }));
      setGeneric({ ...generic, ipCheckStatus: 'PLAN' });
      props.params.callBack();
    });
  };

  const updateIpCheckPlan = async (cond) => {
    await simpleRequest(cond, `/v1/updateIpCheckPlan`).then((result: any[]) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
        });
        return;
      }
      openMessageBar({
        type: 'confirm',
        content: t('com.label.저장되었습니다.', '저장되었습니다.'),
      });
      props.params.callBack();
      drawList(cond);
    });
  };

  const deleteIpCheckPlan = async (cond) => {
    const execute = function (cond) {
      return simpleRequest(cond, `/v1/deleteIpCheckPlan`).then((result: any[]) => {
        if (!result) {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }
        openMessageBar({
          type: 'confirm',
          content: t('com.label.저장되었습니다.', '저장되었습니다.'),
        });
        props.params.callBack();
        return result;
      });
    };

    openCommonModal({
      modalType: 'confirm',
      content: t('ip.msg.IP 점검계획을 취소하시겠습니까?', 'IP 점검계획을 취소하시겠습니까?'),
      yesCallback: async () => {
        const result = await execute(cond);
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const createProcess = () => {
    //차후 유효성 체크 수정... 우선 하나만 대충
    if (!generic?.copCd) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.법인코드를 선택해 주세요.', '법인코드를 선택해 주세요.'),
      });
      return;
    }
    if (
      isRequired.ipCheckPlanName &&
      (!generic?.ipCheckPlanName.length || generic?.ipCheckPlanName.trim().length === 0)
    ) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.IP 점검계획명을 입력해 주세요.', 'IP 점검계획명을 입력해 주세요.'),
      });
      return;
    }
    insertIpCheckPlan(generic);
  };

  const updateProcess = () => {
    console.log('generic', generic);
    // if (isRequired.rmk && (!generic?.rmk.length || generic?.rmk.trim().length === 0)) {
    //   openMessageBar({
    //     type: 'error',
    //     content: t('ip.msg.변경 사유를 입력해 주세요.', '변경 사유를 입력해 주세요.'),
    //   });
    //   return false;
    // }
    if (
      isRequired.ipCheckPlanName &&
      (!generic?.ipCheckPlanName.length || generic?.ipCheckPlanName.trim().length === 0)
    ) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.IP 점검계획명을 입력해 주세요.', 'IP 점검계획명을 입력해 주세요.'),
      });
      return false;
    }

    switch (mode) {
      case CrudCode.UPDATE:
        updateIpCheckPlan(generic);
        updateGrid();
        break;
      case CrudCode.DELETE:
        deleteIpCheckPlan(generic);
        break;
    }
    return true;
  };

  const updateGrid = () => {
    const saveData = rowData.filter((o) => o.crudKey === CrudCode.UPDATE);
    if (saveData && saveData.length > 0) {
      const list = {
        listIpCheckListProduce: saveData,
      };

      return simpleRequest(list, `/v1/updateToIpCheckListProduce`).then((result: any[]) => {
        if (!result) {
          openMessageBar({
            type: 'error',
            content:
              '그리드 내용 ' +
              t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }
        openMessageBar({
          type: 'confirm',
          content: t('com.label.저장되었습니다.', '저장되었습니다.'),
        });
        return result;
      });
    }

    drawList(saveData);
  };

  const saveBtnOnClick = () => {
    //Update 일경우
    console.log('mode', mode);
    if ([CrudCode.UPDATE, CrudCode.DELETE].includes(mode)) {
      updateProcess();
      return false;
    }

    //신규 저장일경우
    createProcess();
  };

  const createBtnOnClick = () => {
    setSelectTargetModalOpen(true);
  };
  //if (rowData.filter((o) => o.crudKey != CrudCode.UPDATE)) {
  useEffect(() => {
    // 사용자 조회 callback 처리
    if (userData != null && userData != '') {
      const fieldId = userData.fieldId;
      const userInfo = userData.userInfo[0] || {};
      // if (fieldId) {
      //   setMpItem((prev) => ({
      //     ...prev,
      //     [fieldId.replace('Nm', 'Id')]: userInfo.userId,
      //   }));
      //   setDisplayCondition({
      //     ...displayCondition,
      //     [fieldId]: userInfo.empNm,
      //   });
      // }
    }
  }, [userData]);

  const execute = function (cond) {
    //reConfirm();
    //if (rowData.crudKey == null) {
    // const valid = rowData.map((rowNode, index) => {
    //if (rowNode.crudKey != 'U' || rowNode.crudKey != 'D') {
    //);

    return simpleRequest(cond, `/v1/completeIpCheckPlan`).then((result: any[]) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t('ip.msg.완료 처리에 실패하였습니다.', '완료 처리에 실패하였습니다.'),
        });
        return;
      }
      openMessageBar({
        type: 'confirm',
        content: t('ip.msg.완료 처리 성공하였습니다.', '완료 처리 성공하였습니다.'),
      });
      props.params.callBack();
      handlOnClose();
      return result;
    });
  };

  const completeBtnOnClick = () => {
    //onCellValueChanged();
    if (
      isRequired.ipCheckPlanName &&
      (!generic?.ipCheckPlanName || generic?.ipCheckPlanName.trim().length === 0)
    ) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.IP 점검계획명을 입력해 주세요.', 'IP 점검계획명을 입력해 주세요.'),
      });
      return;
    }
    if (isRequired.isptTpCd && (!generic?.isptTpCd || generic?.isptTpCd.trim().length === 0)) {
      openMessageBar({
        type: 'error',
        content:
          t('ip.label.점검유형', '점검유형') +
          t('com.label.을 선택해 주세요.', '을 선택해 주세요.'),
      });
      return;
    }

    if (
      !generic?.satDate.startDate ||
      generic?.satDate.startDate.length === 0 ||
      !generic?.satDate.endDate ||
      generic?.satDate.endDate.length === 0
    ) {
      openMessageBar({
        type: 'error',
        content:
          t('ip.label.SAT', 'SAT') +
          t('ip.msg.계획일자를 입력해주세요.', '계획일자를 입력해주세요.'),
      });
      return;
    }

    if (generic?.isptTpCd !== 'NO_FAT') {
      if (
        !generic?.fatDate.startDate ||
        generic?.fatDate.startDate.length === 0 ||
        !generic?.fatDate.endDate ||
        generic?.fatDate.endDate.length === 0
      ) {
        openMessageBar({
          type: 'error',
          content:
            t('ip.label.FAT', 'FAT') +
            t('ip.msg.계획일자를 입력해주세요.', '계획일자를 입력해주세요.'),
        });
        return;
      }
      const fatEndDate = new Date(generic?.fatDate.endDate);
      const satStartDate = new Date(generic?.satDate.startDate);
      if (!generic?.fatDate.endDate || !generic?.satDate.startDate || fatEndDate > satStartDate) {
        openMessageBar({
          type: 'error',
          content: t(
            'ip.label.날짜가 부적합합니다. FAT 작업 이후 SAT 작업이 가능합니다.',
            '날짜가 부적합합니다. FAT 작업 이후 SAT 작업이 가능합니다.'
          ),
        });
        return;
      }
    }

    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.crudKey != null) {
          return t(
            'ip.msg.수정중인 점검 계획이 있습니다. 저장 후 계획 수립 완료를 진행해 주세요.',
            '수정중인 점검 계획이 있습니다. 저장 후 계획 수립 완료를 진행해 주세요.'
          ); //'수정중인 점검 계획이 있습니다. 저장 후 계획 수립 완료를 진행해 주세요.';
          // openMessageBar({
          //   type: 'error',
          //   content: t(
          //     'com.label.cancel.cancel',
          //     '수정중인 점검 계획이 있습니다. 저장 후 계획 수립 완료를 진행해 주세요.'
          //   ),
          // });
        }
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return false;
    }
    // }
    //});

    openCommonModal({
      modalType: 'confirm',
      content: t('ip.msg.IP 점검계획을 완료하시겠습니까?', 'IP 점검계획을 완료하시겠습니까?'),
      yesCallback: async () => {
        const result = await execute(generic);
      },
      noCallback: () => {
        return false;
      },
    });
  };

  const resultPopup = (fieldId, result) => {
    setGeneric({ ...generic, [fieldId]: result.userInfo[0].userId });
  };

  const userPopup = (params) => {
    return `<span>${params.value ? params.value : ''}</span>
        <Button id="btnEmp"></Button>
        `;
  };

  const partnerPopup = (params) => {
    return `<span>${params.value ? params.value : ''}</span>
        <Button id="btnPartner"></Button>
        `;
  };

  return (
    <CustomDialog
      title={t('ip.label.IP점검계획', 'IP점검계획')}
      open={props.open}
      onClose={handlOnClose}
      size="xl"
    >
      <TableContainer css={tb.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCellSelectBox
                name={String(t('ip.label.IP점검계획유형', 'IP점검계획유형'))}
                id="ipCheckPlanType"
                placeholder={String(t('com.label.선택해 주세요.', '선택해 주세요.'))}
                keyword="IP_PLAN_TYPE"
                defaultValue={generic?.ipCheckPlanType}
                disabled={true}
                onChange={listner}
                required={true}
              ></TableCellSelectBox>

              <TableCellSelectBox
                name={String(t('ip.label.법인코드', '법인코드'))}
                id="copCd"
                defaultValue={generic?.copCd}
                placeholder={String(t('com.label.선택해 주세요.', '선택해 주세요.'))}
                keyword="ELM_COP_CD"
                disabled={isUpdate}
                onChange={listner}
                required={true}
              ></TableCellSelectBox>

              <TableCellInputText
                name={String(t('ip.label.WBS코드', 'WBS코드'))}
                id="wbsCode"
                defaultValue={generic?.wbsCode}
                onChange={listner}
              ></TableCellInputText>

              <TableCellInputText
                className="find"
                name={String(t('com.label.등록자', '등록자'))}
                id="dataInsUserId"
                defaultValue={empNm}
                onChange={listner}
                findOnClick={() => {
                  setOpenElmEmpPopup(true);
                  setFieldId('dataInsUserId');
                }}
              ></TableCellInputText>
            </TableRow>
            <TableRow>
              <TableCellReadOnly
                name={String(t('ip.label.IP점검계획번호', 'IP점검계획번호'))}
                id="ipCheckPlanNo"
                defaultValue={generic?.ipCheckPlanNo}
              ></TableCellReadOnly>
              <TableCellInputText
                //type="text"
                name={String(t('ip.label.IP점검계획명', 'IP점검계획명'))}
                id="ipCheckPlanName"
                placeholder={String(
                  t(
                    'ip.msg.IP 점검계획명 : OOO법인 OOO설비 OOO세대 IP List 형식으로 작성해 주세요.',
                    'IP 점검계획명 : OOO법인 OOO설비 OOO세대 IP List 형식으로 작성해 주세요.'
                  )
                )}
                colSpan={5}
                defaultValue={generic?.ipCheckPlanName}
                onChange={listner}
                required={true}
              ></TableCellInputText>
            </TableRow>
            {/* <TableRow>
                  <TableCellInputText
                    name="FDM프로젝트번호"
                    id="fdmProjectNo"
                    disabled={disabled}
                    defaultValue={generic?.fdmProjectNo}
                    onChange={listner}
                  ></TableCellInputText>
                  <TableCellReadOnly
                    name="FDM프로젝트명"
                    id="fdmProjectName"
                    defaultValue={generic?.fdmProjectName}
                    colSpan={5}
                  ></TableCellReadOnly>
                </TableRow> */}
            <TableRow>
              <TableCellSelectBox
                name={String(t('ip.label.점검유형', '점검유형'))}
                id="isptTpCd"
                placeholder={String(t('com.label.선택해 주세요.', '선택해 주세요.'))}
                keyword="IP_ISPT_TP_CD"
                defaultValue={generic?.isptTpCd}
                onChange={listner}
                required={!disabledCompleteBtn}
              ></TableCellSelectBox>
              <TableCellDateRangePicker
                name={String(t('ip.label.FAT', 'FAT'))}
                id="fatDate"
                defaultValue={generic?.fatDate}
                onChange={listner}
                required={!disabledCompleteBtn}
              ></TableCellDateRangePicker>
              <TableCellDateRangePicker
                name={String(t('ip.label.SAT', 'SAT'))}
                id="satDate"
                defaultValue={generic?.satDate}
                onChange={listner}
                required={!disabledCompleteBtn}
              ></TableCellDateRangePicker>
              <TableCell>
                <label htmlFor="useYn">{String(t('ip.grid.메일발송여부', '메일발송여부'))}</label>
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                <CustomSwitch
                  id="useYn"
                  onValue={'Y'}
                  onLabel={String(t('com.label.사용', '사용'))}
                  offValue={'N'}
                  offLabel={String(t('com.label.미사용', '미사용'))}
                  defaultValue={generic?.emlSndoYN === 'Y' ? 'Y' : 'N'}
                  onChange={(value) => {
                    setGeneric({ ...generic, emlSndoYN: value });
                  }}
                />
              </TableCell>
            </TableRow>
            {/* 불필요 삭제 */}
            {/* <TableRow>
              <TableCellInputText
                name={String(t('ip.label.변경사유', '변경사유'))}
                id="rmk"
                required={isRequired.rmk}
                colSpan={8}
                defaultValue={generic?.rmk}
                onChange={listner}
              ></TableCellInputText>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>

      <ContentSection>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('ip.grid.IP점검대상 목록', 'IP점검대상 목록')}</h3>
            <span className="total">
              {t('com.label.총', '총')}
              <span>{rowData ? rowData.length : 0}</span>
              {t('com.label.건', '건')}
            </span>
          </SubTitleGroup>
          <ControlBtnGroup>
            {mode == CrudCode.DELETE && (
              <Button className="edit" disableRipple onClick={saveBtnOnClick}>
                {t('com.label.삭제', '삭제')}
              </Button>
            )}

            {mode != CrudCode.DELETE && (
              <>
                <Button
                  css={IconButton.button}
                  className="save"
                  disableRipple
                  onClick={createBtnOnClick}
                  disabled={disabledBtn}
                >
                  {t('ip.button.점검대상선택', '점검대상선택')}
                </Button>
                <Button
                  css={IconButton.button}
                  className="save"
                  disableRipple
                  onClick={saveBtnOnClick}
                >
                  {t('com.button.저장', '저장')}
                </Button>
                <Button
                  css={IconButton.button}
                  className="save"
                  disableRipple
                  disabled={disabledCompleteBtn}
                  onClick={completeBtnOnClick}
                >
                  {t('ip.button.계획수립완료', '계획수립완료')}
                </Button>
              </>
            )}
          </ControlBtnGroup>
        </SubTitleLayout>
        <CustomGrid
          layoutDefinition={state.layoutDefinition}
          rowData={state.itemsSource}
          frozenColumns={2}
          initialized={onInitialized}
          cellEditEnded={(grid, e) => {
            const binding = grid.columns[e.col].binding;
            const value = grid.getCellData(e.row, e.col);
            if (binding.endsWith('Bound')) {
              const data = grid.rows[e.row].dataItem;
              data.crudKey = CrudCode.UPDATE;
            }
          }}
        />
        {selectTargetModalOpen && (
          <SelectTargetModal
            params={generic}
            open={selectTargetModalOpen}
            callBack={drawList}
            close={() => setSelectTargetModalOpen(false)}
          />
        )}
        {isOpenElmEmpPopup && (
          <ElmEmpPopup
            setUserData={(result) => {
              // resultPopup(fieldId, result)
              const colId = fieldId;
              const empNms = result.userInfo[0].empNm;
              const userIds = result.userInfo[0].userId;
              const rowId = flexRef.selectedRanges[0].row;
              const tempRowData = flexRef.selectedItems[0];
              if ('fatEnsolPlanInspectorNm' === colId) {
                tempRowData['fatEnsolPlanInspectorNm'] = empNms.length > 0 ? empNms : '';
                tempRowData['fatEnsolPlanInspector'] = userIds.length > 0 ? userIds : '';
              } else if ('satEnsolPlanInspectorNm' === colId) {
                tempRowData['satEnsolPlanInspectorNm'] = empNms.length > 0 ? empNms : '';
                tempRowData['satEnsolPlanInspector'] = userIds.length > 0 ? userIds : '';
              }
              rowData[rowId] = tempRowData;
              flexRef.refresh();
            }}
            fieldId={empPopupCondition.fieldId}
            initParam={empPopupCondition.initParam}
            setPopup={setOpenElmEmpPopup}
          />
        )}
        {isOpenPartnerPopup && (
          <PartnerUserModal
            open={isOpenPartnerPopup}
            condition={{
              userNm: empPopupCondition.initParam || '',
            }}
            close={() => setOpenPartnerPopup(false)}
            onCallback={(result) => {
              const colId = fieldId;
              const empNms = result.userNm;
              const userIds = result.userId;
              const rowId = flexRef.selectedRanges[0].row;
              const tempRowList = [...rowData];
              const tempRowData = flexRef.selectedItems[0];
              if ('fatMakerPlanInspectorNm' === colId) {
                tempRowData['fatMakerPlanInspectorNm'] = empNms.length > 0 ? empNms : '';
                tempRowData['fatMakerPlanInspector'] = userIds.length > 0 ? userIds : '';
              } else if ('satMakerPlanInspectorNm' === colId) {
                tempRowData['satMakerPlanInspectorNm'] = empNms.length > 0 ? empNms : '';
                tempRowData['satMakerPlanInspector'] = userIds.length > 0 ? userIds : '';
              }
              tempRowList[rowId] = tempRowData;
              tempRowList[rowId].crudKey = CrudCode.UPDATE;
              setRowData(tempRowList);
            }}
          />
        )}
        {isOpen && (
          <IpCheckResultPointModal params={flexItem} open={isOpen} close={() => setOpen(false)} />
        )}
      </ContentSection>
    </CustomDialog>
  );
};

export default MakePlanModal;
