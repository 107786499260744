/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { IconButton } from '../../components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from '../../components/layouts/SearchBox';
import { ComboBox } from '../../components/selects/ComboBox';
import { Code } from '../../models/common/CommonCode';
import { ExcpManagementPopUp } from 'pages/partner/excpmanagement/ExcpManagementPopUp';
import { getCommonCodes } from '../../apis/admin/CommonCode';
import { useTranslation } from 'react-i18next';
import {
  SubTitleLayout,
  SubTitleGroup,
  GlobalBtnGroup,
  ControlBtnGroup,
} from '../../components/layouts/ContentLayout';
import { ContentGrid } from '../../components/layouts/ContentGrid';
import { CustomInputText, CustomInputWrap } from '../../components/inputs/CustomInput';
import { ExcpPartnerChangeStat, ExcpPartnerCondition } from '../../models/admin/ExcpPartner';
import CustomSwitch from 'components/selects/CustomSwitch';
import { updateExcpStat } from 'apis/admin/ExcpPartner';
import { hasRole } from 'utils/SessionUtil';
import CustomChip from 'components/selects/CustomChip';
// Wijmo library
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { CellType, CellRange, MergeManager } from '@grapecity/wijmo.grid';
import { getExcpList, compUserCount } from 'apis/admin/ExcpPartner';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { useMessageBar } from 'hooks/useMessageBar';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { ManagementMode } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

/**
 * 협력업체 관리
 * @constructor
 */
const ExcpManagementPage = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState<any>();
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState<any>({});
  const [rowData, setRowData] = useState<any[]>([]);
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [hasAuth, setAuth] = useState(false);
  const [isOpenExcpManagementPopUp, setOpenExcpManagementPopUp] = useState<boolean>(false);
  const { openCommonModal } = useCommonModal();
  const [partnerCondition, setPartnerCondition] = useState<ExcpPartnerCondition>({
    excpId: searchParams.get('excpId') || '',
    excpNm: '',
    rpsnEml: '',
    excpStatCd: 'REQ',
    rpsnPhn: '',
    snsrYn: 'A',
    ipYn: 'A',
  });
  const [colWidthMap, setColWidthMap] = useState<any>({
    rpsnPhn: 130,
    snsrYn: 100,
    ipYn: 100,
    useYn: 100,
    excpNm: 160,
    rpsnNm: 160,
    excpStatNm: 120,
    rpsnEml: 300,
    rmk: 100,
    dataUpdDtm: 180,
    updUser: 120,
  });
  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    if (searchParams.get('excpId')) {
      handleSearch();
    }
  }, [searchParams]);

  useEffect(() => {
    getCommonCodesForGrid();
    // 관리자 권한 여부
    setAuth(hasRole('ADM'));
    handleSearch();
  }, []);

  const getCommonCodesForGrid = async () => {
    const stsType: Code[] = await getCommonCodes('EXCP_PROGRESS_STATUS_CODE'); // 상태
    setCode(stsType);
  };

  const handleSearchParam = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPartnerCondition({
      ...partnerCondition,
      [name]: value,
    });
  };

  const onBtExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('com.label.협력업체', '협력업체');
    book.saveAsync('excpPartnerList.xlsx');
  };

  const handleRefresh = () => {
    setOpenExcpManagementPopUp(false);
    handleSearch();
  };

  const handleDelete = async () => {
    const selectedRow = await flexRef.selectedRows;
    if (selectedRow && selectedRow.length > 0) {
      const valid = await selectedRow
        .map((rowNode, index) => {
          if (rowNode.dataItem.useYn === 'N') {
            return t('com.label.삭제된 데이터입니다.', '삭제된 데이터입니다.');
          }
          if (rowNode.dataItem.excpStatCd === 'REQ') {
            return t(
              'com.label.협력업체에서 요청한 데이터는 삭제 할 수 없습니다. 반려기능을 이용하시길 바랍니다.',
              '협력업체에서 요청한 데이터는 삭제 할 수 없습니다. 반려기능을 이용하시길 바랍니다.'
            );
          }
        })
        .filter((element) => element !== undefined);

      if (valid.length) {
        const content = valid[0]?.toString();
        openMessageBar({ type: 'error', content: content || '' });
        return false;
      }

      const deleteList = selectedRow.map(({ dataItem }) => dataItem);
      // const userCount = await compUserCount(selectedRow[0].dataItem.excpId);
      openCommonModal({
        modalType: 'confirm',
        content: t(
          'com.msg.삭제 하시겠습니까? 삭제된 데이터는 되돌릴 수 없습니다.',
          '삭제 하시겠습니까? 삭제된 데이터는 되돌릴 수 없습니다.'
        ),
        yesCallback: () => {
          updateExcpStat(
            deleteList.map((element) => {
              element.useYn = 'N';
              return element;
            })
          )
            .then(() => {
              openMessageBar({
                type: 'confirm',
                content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
              });
              handleSearch();
            })
            .catch(() => {
              openMessageBar({
                type: 'error',
                content: t('ip.msg.삭제에 실패했습니다.', '삭제에 실패했습니다.'),
              });
            });
        },
      });
    } else {
      openMessageBar({
        type: 'default',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
    }
  };

  const handleResetCondition = () => {
    setPartnerCondition({
      ...(prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as ExcpPartnerCondition,
      excpStatCd: 'REQ',
      snsrYn: 'A',
      ipYn: 'A',
    });
  };

  const handleReject = async () => {
    const selectedRow = await flexRef.selectedRows;
    if (selectedRow && selectedRow.length > 0) {
      if (selectedRow.length > 1) {
        openMessageBar({
          type: 'error',
          content: t('com.msg.하나의 업체만 선택해 주세요.', '하나의 업체만 선택해 주세요.'),
        });
        return;
      }
      if (selectedRow[0].dataItem.useYn === 'Y') {
        if (selectedRow[0].dataItem.excpStatCd === 'REQ') {
          setflexItem({ ...selectedRow[0].dataItem, mode: ManagementMode.DELETE });
          setOpenExcpManagementPopUp(true);
        } else {
          openMessageBar({
            type: 'error',
            content: t(
              'com.label.현재 요청중인 상태가 아닙니다.',
              '현재 요청중인 상태가 아닙니다.'
            ),
          });
        }
      } else {
        openMessageBar({
          type: 'error',
          content: t('com.label.삭제된 데이터입니다.', '삭제된 데이터입니다.'),
        });
      }
    } else {
      openMessageBar({
        type: 'error',
        content: t('com.label.수정할 대상을 선택해 주세요.', '수정할 대상을 선택해 주세요.'),
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSearch();
  };

  const handleApprove = async () => {
    const selectedRow = await flexRef.selectedRows;
    if (selectedRow && selectedRow.length > 0) {
      const dataList: ExcpPartnerChangeStat[] = [];
      await selectedRow.map((o) => {
        if (o.dataItem.useYn !== 'N' && o.dataItem.excpStatCd !== 'CPLT') {
          dataList.push({ ...o.dataItem, excpStatCd: 'CPLT' });
        }
      });
      if (dataList.length !== selectedRow.length) {
        openMessageBar({
          type: 'error',
          content: t(
            'com.label.이미 등록완료되었거나 삭제된 업체가 포함 되어있습니다. 삭제되지않은 요청상태의 업체만 선택 해주세요.',
            '이미 등록완료되었거나 삭제된 업체가 포함 되어있습니다. 삭제되지않은 요청상태의 업체만 선택 해주세요.'
          ),
        });
        return;
      }

      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.승인하시겠습니까?', '승인하시겠습니까?'),
        yesCallback: async () => {
          await updateExcpStat(dataList)
            .then(() => {
              openMessageBar({
                type: 'confirm',
                content: t('com.label.승인되었습니다.', '승인되었습니다.'),
              });
              handleSearch();
            })
            .catch(() => {
              openMessageBar({
                type: 'error',
                content: t(
                  'com.label.승인 중 오류가 발생했습니다.',
                  '승인 중 오류가 발생했습니다.'
                ),
              });
            });
        },
      });
    } else {
      openMessageBar({
        type: 'error',
        content: t('com.label.수정할 대상을 선택해 주세요.', '수정할 대상을 선택해 주세요.'),
      });
    }
  };

  const handleSearch = async () => {
    await getExcpList(partnerCondition).then((result) => {
      setRowData(result);
    });
  };

  const LayoutDefinition = () => {
    return [
      {
        binding: 'excpId',
        visible: false,
      },
      {
        header: String(t('com.label.회사명', '회사명')),
        binding: 'excpNm',
        align: 'left',
        isReadOnly: true,
        width: colWidthMap.excpNm,
      },
      {
        header: String(t('com.label.대표자명', '대표자명')),
        binding: 'rpsnNm',
        align: 'center',
        isReadOnly: true,
        width: colWidthMap.rpsnNm,
      },
      {
        header: String(t('com.label.이메일주소', '이메일주소')),
        binding: 'rpsnEml',
        align: 'left',
        isReadOnly: true,
        width: colWidthMap.rpsnEml,
      },
      {
        header: String(t('com.label.대표전화번호', '대표전화번호')),
        binding: 'rpsnPhn',
        align: 'center',
        isReadOnly: true,
        width: colWidthMap.rpsnPhn,
      },
      {
        binding: 'excpStatCd',
        visible: false,
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'excpStatNm',
        width: colWidthMap.excpStatNm,
        align: 'center',
        isReadOnly: true,
      },
      {
        header: String(t('admin.grid.비고', '비고')),
        binding: 'rmk',
        isReadOnly: true,
        align: 'left',
        width: colWidthMap.rmk,
      },
      {
        header: String(t('com.label.업체구분', '업체구분')),
        align: 'center',
        columns: [
          {
            header: String(t('com.label.센서여부', '센서여부')),
            binding: 'snsrYn',
            isReadOnly: true,
            align: 'center',
            width: colWidthMap.snsrYn,
          },
          {
            header: String(t('com.label.설비업체여부', '설비업체여부')),
            binding: 'ipYn',
            isReadOnly: true,
            align: 'center',
            width: colWidthMap.ipYn,
          },
        ],
      },
      {
        header: String(t('com.label.사용여부', '사용여부')),
        binding: 'useYn',
        isReadOnly: true,
        align: 'center',
        width: colWidthMap.useYn,
      },
      {
        header: String(t('com.label.수정자', '수정자')),
        binding: 'updUser',
        isReadOnly: true,
        align: 'center',
        // cellStyle: { textAlign: 'center' },
        width: colWidthMap.updUser,
      },
      {
        header: String(t('com.label.수정일시', '수정일시')),
        binding: 'dataUpdDtm',
        isReadOnly: true,
        align: 'center',
        width: colWidthMap.dataUpdDtm,
        cellTemplate: (params) => {
          if (params.value == null || params.value == '') {
            return '';
          } else {
            return dayjs(params.value).format('YYYY.MM.DD HH:mm:ss').toString();
          }
        },
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onInitialized = (grid) => {
    setFlexRef(grid);
    new FlexGridFilter(grid);
    new Selector(grid);
    grid.mergeManager = new CustomMergeManager();

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      setflexItem(item);
    });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('com.label.회사명', '회사명')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    name="excpNm"
                    value={partnerCondition.excpNm}
                    placeholder={String(
                      t('com.label.회사명을 입력해 주세요.', '회사명을 입력해 주세요.')
                    )}
                    onChange={handleSearchParam}
                    onKeyDown={handleKeyDown}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.이메일주소', '이메일주소')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    name="rpsnEml"
                    value={partnerCondition.rpsnEml}
                    placeholder={String(
                      t('com.label.이메일을 입력해 주세요.', '이메일을 입력해 주세요.')
                    )}
                    onChange={handleSearchParam}
                    onKeyDown={handleKeyDown}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>
                  <span>{t('com.label.상태', '상태')}</span>
                </label>
                <ComboBox
                  placeholder={String(t('mp.msg.상태를 선택해 주세요.', '상태를 선택해 주세요.'))}
                  options={code}
                  defaultValue={partnerCondition?.excpStatCd}
                  isError={errors?.tplType}
                  msgError={String(t('mp.msg.상태를 선택해 주세요.', '상태를 선택해 주세요.'))}
                  onChange={(value) => {
                    setPartnerCondition({ ...partnerCondition, excpStatCd: value });
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('com.label.대표전화번호', '대표전화번호')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    name="rpsnPhn"
                    value={partnerCondition.rpsnPhn}
                    placeholder={String(
                      t('com.label.전화번호를 입력해 주세요.', '전화번호를 입력해 주세요.')
                    )}
                    onChange={handleSearchParam}
                    onKeyDown={handleKeyDown}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label htmlFor="snsrYn">{t('com.label.센서여부', '센서여부')}</label>
                <CustomChip
                  // id="snsrYn"
                  key={'snsrYn'}
                  options={
                    [
                      { cmnCd: 'A', cmnCdNm: String(t('com.label.전체', '전체')) },
                      { cmnCd: 'Y', cmnCdNm: String(t('com.label.사용', '사용')) },
                      { cmnCd: 'N', cmnCdNm: String(t('com.label.미사용', '미사용')) },
                    ] as Code[]
                  }
                  defaultValue={partnerCondition.snsrYn}
                  onChange={(value) => {
                    if (value === '') {
                      value = 'A';
                    }
                    setPartnerCondition({ ...partnerCondition, snsrYn: value.toString() });
                  }}
                  singleSelect={true} // true인 경우 생략 가능
                  // onChange={(value) => console.log(value)} // return string
                />
              </SearchCols>
              <SearchCols>
                <label htmlFor="ipYn">{t('com.label.설비업체여부', '설비업체여부')}</label>
                <CustomChip
                  // id="snsrYn"
                  key={'ipYn'}
                  options={
                    [
                      { cmnCd: 'A', cmnCdNm: String(t('com.label.전체', '전체')) },
                      { cmnCd: 'Y', cmnCdNm: String(t('com.label.사용', '사용')) },
                      { cmnCd: 'N', cmnCdNm: String(t('com.label.미사용', '미사용')) },
                    ] as Code[]
                  }
                  defaultValue={partnerCondition.ipYn}
                  onChange={(value) => {
                    if (value === '') {
                      value = 'A';
                    }
                    setPartnerCondition({ ...partnerCondition, ipYn: value.toString() });
                  }}
                  singleSelect={true} // true인 경우 생략 가능
                  // onChange={(value) => console.log(value)} // return string
                />
                {/* <CustomSwitch
                  id="ipYn"
                  onValue={'Y'}
                  onLabel={String(t('com.label.사용', '사용'))}
                  offValue={'N'}
                  offLabel={String(t('com.label.미사용', '미사용'))}
                  defaultValue={partnerCondition.ipYn}
                  onChange={(value) => {
                    setPartnerCondition({
                      ...partnerCondition,
                      ipYn: value,
                    });
                  }}
                /> */}
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.협력업체관리', '협력업체관리')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="Exceldown" onClick={onBtExport} disableRipple>
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <ContentGrid className={(rowData || []).length < 1 ? 'noData' : ''}>
        <FlexGrid
          columnGroups={state.layoutDefinition}
          itemsSource={state.itemsSource}
          deferResizing={false}
          initialized={onInitialized}
          style={{ height: (rowData || []).length < 1 ? '' : '500px' }}
        />
        <div className="noData" style={{ height: (rowData || []).length < 1 ? '500px' : '' }}>
          <span>
            {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
          </span>
        </div>
      </ContentGrid>
      <GlobalBtnGroup>
        {hasAuth && (
          <Button
            css={IconButton.button}
            onClick={() => {
              setflexItem({
                excpNm: '',
                rpsnNm: '',
                rpsnEml: '',
                rpsnPhn: '',
                snsrYn: 'N',
                ipYn: 'Y',
                mode: ManagementMode.CREATE,
              });
              setOpenExcpManagementPopUp(true);
            }}
            className="save"
            disableRipple
          >
            {t('com.button.등록', '등록')}
          </Button>
        )}
        <Button
          css={IconButton.button}
          className="edit"
          onClick={() => {
            const selectedRow = flexRef.selectedRows;
            if (selectedRow && selectedRow.length > 0) {
              if (selectedRow[0].dataItem.useYn === 'N') {
                openMessageBar({
                  type: 'error',
                  content: t('com.label.삭제된 데이터입니다.', '삭제된 데이터입니다.'),
                });
                return;
              } else {
                setflexItem({ ...selectedRow[0].dataItem, mode: ManagementMode.MODIFY });
                setOpenExcpManagementPopUp(true);
              }
            } else {
              openMessageBar({
                type: 'error',
                content: t(
                  'com.label.수정할 대상을 선택해 주세요.',
                  '수정할 대상을 선택해 주세요.'
                ),
              });
              return;
            }
          }}
          disableRipple
        >
          {t('com.button.수정', '수정')}
        </Button>
        {hasAuth && (
          <>
            <Button css={IconButton.button} className="delete" onClick={handleDelete} disableRipple>
              {t('com.label.삭제', '삭제')}
            </Button>
            <Button
              css={IconButton.button}
              className="confirm"
              onClick={handleApprove}
              disableRipple
            >
              {t('com.button.승인', '승인')}
            </Button>
            <Button
              css={IconButton.button}
              className="confirm"
              onClick={handleReject}
              disableRipple
            >
              {t('com.button.반려', '반려')}
            </Button>
          </>
        )}
      </GlobalBtnGroup>
      {isOpenExcpManagementPopUp && (
        <ExcpManagementPopUp
          open={isOpenExcpManagementPopUp}
          setPopup={setOpenExcpManagementPopUp}
          condition={flexItem}
          close={handleRefresh}
        />
      )}
    </>
  );
};

export default ExcpManagementPage;

class CustomMergeManager extends MergeManager {
  getMergedRange(panel, r, c, clip = true) {
    const rng = new CellRange(r, c);
    if (panel.cellType === CellType.ColumnHeader || panel.cellType === CellType.TopLeft) {
      for (let i = rng.row; i < panel.rows.length - 1; i++) {
        if (panel.getCellData(i, rng.col, true) != panel.getCellData(i + 1, rng.col, true)) break;
        rng.row2 = i + 1;
      }
      for (let i = rng.row; i > 0; i--) {
        if (panel.getCellData(i, rng.col, true) != panel.getCellData(i - 1, rng.col, true)) break;
        rng.row = i - 1;
      }
      for (let i = rng.col; i < panel.columns.length - 1; i++) {
        if (panel.getCellData(rng.row, i, true) != panel.getCellData(rng.row, i + 1, true)) break;
        rng.col2 = i + 1;
      }
      for (let i = rng.col; i > 0; i--) {
        if (panel.getCellData(rng.row, i, true) != panel.getCellData(rng.row, i - 1, true)) break;
        rng.col = i - 1;
      }
    }
    return rng;
  }
}
