import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { GatingCheckSheetVer } from 'models/gtng/popup/GatingCheckSheetVer';
import { GatingCheckSheetDtl } from '../../../models/gtng/GatingCheckSheet';

export const findGatingVerCkList = async (code) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingVerCkList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...code }),
  };
  const response: CommonResponse<GatingCheckSheetVer[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckSheetVer[];
};

/**
 * Check sheet version history list search (tamplateId 기준)
 * @param sGatingTemplateId
 */
export const getGatingCheckSheetVerList = async (sGatingTemplateId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getGatingCheckSheetVerList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      gatingTemplateId: sGatingTemplateId,
    }),
  };
  const response: CommonResponse<GatingCheckSheetVer[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckSheetVer[];
};
