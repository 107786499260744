/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { Button } from '@mui/material';
import { hasClass } from '@grapecity/wijmo';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import {
  EqpLvCd,
  EquipmentClassificationDetail,
  EquipmentClassificationMaster,
} from 'models/mp/MpEquipment';
import {
  findMpEqiupmentClassificationDetail,
  findMpEqiupmentClassificationDetailNewNo,
} from 'apis/mp/MpEquipment';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { CrudCode } from 'models/common/Edit';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ManagementMode } from 'models/common/Common';
import { useMessageBar } from 'hooks/useMessageBar';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';

interface Props {
  aprReqId: string;
  mode: ManagementMode;
  masterData?: EquipmentClassificationMaster;
  isReadOnly?: boolean;
  onChange: (detailList: EquipmentClassificationDetail[]) => void;
}

export const EditMpEquipmentClassificationDetailGrid = ({
  aprReqId,
  mode,
  masterData,
  onChange,
  isReadOnly = false,
}: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const gridRef = useRef<any>();
  const [subTitle, setSubTitle] = useState<string>('');
  const [rowData, setRowData] = useState<EquipmentClassificationDetail[]>([]);
  const [eqpLvCdDataMap, setEqpLvCdDataMap] = useState<Code[]>([]);
  const isEditable = useMemo(
    () => [ManagementMode.CREATE, ManagementMode.MODIFY].includes(mode),
    [mode]
  );

  const getCommonCodesForGrid = async () => {
    const eqpLvCd: Code[] = await getCommonCodeNames('EQP_LV_CD'); // 설비레벨

    setEqpLvCdDataMap(eqpLvCd);
  };

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  useEffect(() => {
    setSubTitle(masterData?.eqpClsfNo || '');
    if (!masterData?.eqpClsfNo) {
      setRowData([]);
      return;
    }
    // 상세 목록이 없는 경우 조회
    if (!(masterData?.detailList || []).length) {
      handleSearch({
        aprReqId: aprReqId,
        eqpClsfNo: masterData?.eqpClsfNo,
        reqChgTpCd: masterData?.reqChgTpCd,
        eqpClsfNm: masterData?.eqpClsfNm,
      });
    } else {
      const rows = masterData?.detailList || [];
      setRowData(rows);
    }
  }, [aprReqId, masterData?.eqpClsfNo]);

  const findItemParent = (items: any[], upprEqpClsfStrcNo, parent?: any) => {
    if (parent && parent.eqpClsfStrcNo === upprEqpClsfStrcNo) {
      return parent;
    }

    for (const item of items) {
      if (item.eqpClsfStrcNo === upprEqpClsfStrcNo) {
        return findItemParent(items, upprEqpClsfStrcNo, item);
      }
      if (Object.prototype.hasOwnProperty.call(item, 'machines')) {
        return findItemParent(item?.machines, upprEqpClsfStrcNo, item);
      }
    }

    return parent;
  };

  const handleSearch = async (condition) => {
    if (!condition?.aprReqId || !condition?.eqpClsfNo) {
      return;
    }

    setSubTitle(condition.eqpClsfNo);
    findMpEqiupmentClassificationDetail(condition).then((res) => {
      if (res) {
        // 조회 목록이 없는 경우
        if (!(res || []).length) {
          res.push({
            crudKey: CrudCode.CREATE,
            eqpClsfNo: condition.eqpClsfNo,
            eqpClsfStrcNo: `${condition.eqpClsfNo}-${EqpLvCd.MAIN}1`, // TODO 키 패턴 고정으로 해도 되는지 확인필요
            eqpClsfStrcNm: condition.eqpClsfNm, // TODO 기본값
            eqpLv: '1',
            eqpLvCd: EqpLvCd.MAIN,
          } as EquipmentClassificationDetail);
        }
        const rowData = (res || []).reduce((acc, cur) => {
          // 상위분류코드가 없는 경우 최상위 객체 추가
          if (!cur.upprEqpClsfStrcNo) {
            return [...acc, cur];
          }
          const parent = findItemParent(acc, cur.upprEqpClsfStrcNo);
          if (parent) {
            const key = cur.eqpLvCd === EqpLvCd.MACHINE ? 'machines' : 'units';
            if (!parent[key]) {
              parent[key] = [];
            }
            parent[key].push(cur);
          }
          return acc;
        }, [] as EquipmentClassificationDetail[]);

        setRowData(rowData);
        onChange(rowData);
      }
    });
  };

  const convertFlat = (acc, cur) => {
    if ('machines' in cur && Array.isArray(cur?.machines)) {
      return cur.machines.reduce(convertFlat, [...acc, cur]);
    }
    if ('units' in cur && Array.isArray(cur?.units)) {
      return cur.units.reduce(convertFlat, [...acc, cur]);
    }
    return [...acc, cur];
  };

  const handleAddRow = () => {
    if (!masterData?.eqpClsfNo) {
      openMessageBar({
        type: 'error',
        content: t('mp.msg.상세버튼을 클릭해주세요.', '상세버튼을 클릭해주세요.'),
      });
      return;
    }

    let upprEqpClsfStrcNo = '',
      eqpLvCd = '';
    const activeRow = gridRef.current.activeCell
      ? gridRef.current.activeCell['wj-cell-index']?.row
      : -1;
    if (activeRow > -1) {
      const data = gridRef.current?.rows[activeRow]?.dataItem;
      // Main, Machine을 선택 후 행추가 시 선택한 행의 설비분류구조코드를 상위분류구조코드로 입력
      if ([EqpLvCd.MAIN, EqpLvCd.MACHINE].includes(data?.eqpLvCd || '')) {
        upprEqpClsfStrcNo = data?.eqpClsfStrcNo;
        eqpLvCd = EqpLvCd.MAIN === data.eqpLvCd ? EqpLvCd.MACHINE : EqpLvCd.UNIT;
      } else {
        // Unit을 선택 후 행추가 시 선택한 행의 상위분류구조코드를 동일하게 사용
        upprEqpClsfStrcNo = data?.upprEqpClsfStrcNo;
        eqpLvCd = EqpLvCd.UNIT;
      }
    }

    if (!upprEqpClsfStrcNo || !eqpLvCd) {
      openMessageBar({
        type: 'error',
        content: t('mp.msg.상위코드가 선택되지 않았습니다.', '상위코드가 선택되지 않았습니다.'),
      });
      return;
    }

    // 설비분류구조번호 다음 시퀀스 조회
    findMpEqiupmentClassificationDetailNewNo(masterData?.eqpClsfNo, eqpLvCd).then((result) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t('com.label.잘못된 접근입니다.', '잘못된 접근입니다.'),
        });
        return;
      }
      const parent = findItemParent(rowData, upprEqpClsfStrcNo);
      const key = eqpLvCd === EqpLvCd.MACHINE ? 'machines' : 'units';
      if (!parent[key]) {
        parent[key] = [];
      }

      // rowData 트리구조 제거
      const platRowData = (rowData || []).reduce(convertFlat, []);
      let nextEqpClsfStrcNo = result;
      // 서버에서 조회된 다음 시퀀스 값이 이미 목록에 추가되어 있는지 확인
      const filteredData = platRowData.filter((o) => o.eqpClsfStrcNo === nextEqpClsfStrcNo);
      if (filteredData.length > 0) {
        const parseNumber = (str) => {
          return parseInt(str.match(/\d+$/)[0]);
        };

        // 동일한 설비레벨의 목록에 있는 설비분류구조번호 max값
        const maxEqpClsfStrcNo = platRowData
          .filter((o) => o.eqpLvCd === eqpLvCd)
          .map((o) => o.eqpClsfStrcNo)
          .reduce((acc, cur) => {
            const currentNumber = parseNumber(cur);
            const maxNumber = parseNumber(acc);
            return currentNumber > maxNumber ? cur : acc;
          });

        // max(설비분류구조번호) + 1
        const match = maxEqpClsfStrcNo.match(/(\d+)$/);
        if (match) {
          nextEqpClsfStrcNo = maxEqpClsfStrcNo.replace(/\d+$/, parseInt(match[0], 10) + 1);
        }
      }
      parent[key].push({
        crudKey: CrudCode.CREATE,
        eqpClsfStrcNo: nextEqpClsfStrcNo,
        upprEqpClsfStrcNo: upprEqpClsfStrcNo,
        eqpLvCd: eqpLvCd,
      });

      setRowData((prev) => [...rowData]);
      gridRef.current?.collectionView.refresh();
    });
  };

  const handleDelRow = () => {
    if (!masterData?.eqpClsfNo) {
      openMessageBar({
        type: 'error',
        content: t('mp.msg.상세버튼을 클릭해주세요.', '상세버튼을 클릭해주세요.'),
      });
      return;
    }

    const selectedRow = gridRef.current.rows.filter((r) => r.isSelected).map((o) => o.dataItem);
    /**
     * TODO Unit이 존재하는 Machine 에 포커스가 있는 상태에서 [행삭제] => 불가
     * 설계서 상 해당 조건이 있지만
     * treeGrid는 부모노드 선택 시 자식노드가 전체 자동 선택됨 (위 조건 적용x)
     * wijmo에 부모노드 선택 시 자식노드가 전체 자동 선택 기능 제거 가능한지 문의함
     */
    // Unit이 존재하는 Machine 에 포커스가 있는 상태에서 [행삭제] => 불가
    if (!(selectedRow || []).length) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 대상을 선택해 주세요.', '삭제할 대상을 선택해 주세요.'),
      });
      return;
    }

    if ((selectedRow || []).filter((o) => o.eqpLvCd === EqpLvCd.MAIN).length) {
      openMessageBar({
        type: 'error',
        content: t('mp.msg.Main 설비는 삭제할 수 없습니다.', 'Main 설비는 삭제할 수 없습니다.'),
      });
      return;
    }

    const nRowData = [...rowData];
    selectedRow?.forEach((o, idx) => {
      if (o.crudKey === CrudCode.CREATE) {
        // 각 선택된 아이템에 대해 삭제 로직 수행
        deleteNode(o, nRowData);
      } else {
        o.crudKey = CrudCode.DELETE;
      }
    });
    setRowData(nRowData);
    onChange(nRowData);
    gridRef.current?.collectionView.refresh();
  };

  const handleRefresh = () => {
    if (!masterData?.eqpClsfNo) {
      openMessageBar({
        type: 'error',
        content: t('mp.msg.상세버튼을 클릭해주세요.', '상세버튼을 클릭해주세요.'),
      });
      return;
    }

    handleSearch({
      aprReqId: aprReqId,
      eqpClsfNo: masterData?.eqpClsfNo,
      reqChgTpCd: masterData?.reqChgTpCd,
      eqpClsfNm: masterData?.eqpClsfNm,
    });
  };

  const deleteNode = (item, items) => {
    const index = items.findIndex((o) => o.eqpClsfStrcNo === item.eqpClsfStrcNo);
    if (index !== -1) {
      items.splice(index, 1);
    } else {
      items.forEach((o) => {
        const key =
          o.eqpLvCd === EqpLvCd.MAIN ? 'machines' : o.eqpLvCd === EqpLvCd.MACHINE ? 'units' : '';
        if (key && o[key]) {
          deleteNode(item, o[key]);
        }
      });
    }
  };

  const layoutDefinition = [
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      align: 'center',
      width: 45,
      isReadOnly: true,
      // cellTemplate: GridStatusCellTemplate, // itemFormat 에서 제어
    },
    {
      binding: 'eqpClsfStrcNo',
      header: String(t('mp.grid.설비분류체계 구조 번호', '설비분류체계 구조 번호')),
      align: 'left',
      width: 160,
      isReadOnly: true,
    },
    {
      binding: 'eqpClsfStrcNm',
      header: String(t('mp.grid.설비분류체계 구조명', '설비분류체계 구조명')),
      align: 'left',
      width: '*',
    },
    {
      binding: 'eqpLvCd',
      header: String(t('mp.grid.설비레벨', '설비레벨')),
      align: 'center',
      width: 90,
      isReadOnly: true,
      dataMap: new DataMap(eqpLvCdDataMap, 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'upprEqpClsfStrcNo',
      header: String(t('mp.grid.상위표준설비 구조도 번호', '상위표준설비 구조도 번호')),
      align: 'center',
      width: 180,
      isReadOnly: true,
    },
  ];

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 상위표준설비 구조도 번호
      if ('upprEqpClsfStrcNo' === binding) {
        // 설비레벨이 Main인 경우 readonly
        if (item.eqpLvCd === EqpLvCd.MAIN) {
          cell.ariaReadOnly = true;
        }
      }
      /*
      // 설비레벨
      else if ('eqpLvCd' === binding) {
        // TODO 설비레벨의 경우 crudKey가 등록인 경우 편집모드 활성화하는건지(p.29), 추가 시 포커스행 기준 자동 설정(p.30)인지 확인필요
        // 자동 설정 값으로 readonly 처리
        cell.ariaReadOnly = true;
      }
      */
    }
  });

  const setAllChildrenChecked = (grid, row) => {
    for (let i = row.index + 1; i <= grid.rows.length; i++) {
      const currRow = grid.rows[i];
      if (row.level >= currRow?.level) {
        return;
      }
      if (currRow?.hasChildren) {
        currRow.dataItem.isCheckedByUser = true;
      }
    }
  };

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht?.panel?.cellType === CellType.RowHeader && hasClass(e.target, 'wj-column-selector')) {
        const row = grid.rows[ht.row];
        const col = grid.columns[ht.col];
        const item = grid.rows[ht.row].dataItem;
        if (row.hasChildren) {
          setAllChildrenChecked(grid, row);

          item.isCheckedByUser = true;
        }
      }
    });

    grid.formatItem.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      const row = e.panel.rows[e.row];
      const item = row.dataItem;
      if (e.panel.cellType == CellType.RowHeader && row.hasChildren) {
        const chk = e.cell.querySelector('.wj-column-selector');
        if (chk && chk.checked) {
          chk.checked = item.isCheckedByUser ? true : false;
          chk.indeterminate = item.isCheckedByUser ? false : true;
        } else if (chk && !chk.checked) {
          setTimeout(() => {
            row.isSelected = false;
            item.isCheckedByUser = false;
          });
        }
      }

      if (e.panel.cellType !== CellType.Cell) {
        return;
      }
      // 첫번째 열 트리그리드 폴딩 버튼 삭제
      if (col.index === 0) {
        e.cell.style.paddingLeft = null;
        e.cell.innerHTML = e.cell.textContent.trim();
        e.cell.style.textAlign = 'center';
      }

      // 상태 열 스타일 적용
      if ('crudKey' === col.binding) {
        const item = s.rows[e.row].dataItem;
        if (mode === ManagementMode.CREATE) {
          item.crudKey = CrudCode.CREATE;
        }
        if (mode === ManagementMode.DELETE) {
          item.crudKey = CrudCode.DELETE;
        }
        e.cell.innerHTML = GridStatusCellTemplate({ item: item });
        e.cell.style.textAlign = 'center';
      }

      // 설비분류체계 구조 번호 트리그리드 폴딩 버튼 생성
      if ('eqpClsfStrcNo' === col.binding) {
        let padding = row.level * 20;
        if (!row.hasChildren) {
          padding += 10;
        } else {
          e.cell.innerHTML = '';
          // add buttons
          const html =
            '<button class="wj-btn wj-btn-glyph wj-elem-collapse" tabindex="-1" aria-label="Toggle Group">' +
            '<span class="' +
            (row.isCollapsed ? 'wj-glyph-right' : 'wj-glyph-down-right') +
            '">' +
            '</span>' +
            '</button>' +
            row.dataItem.eqpClsfStrcNo +
            '';
          e.cell.innerHTML = html;
        }
        e.cell.style.paddingLeft = `${padding > 0 ? padding : 5}px`;
      }
    });
  };

  const totalCount = useMemo(() => (rowData || []).reduce(convertFlat, []).length, [rowData]);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.설비분류체계구조 List', '설비분류체계구조 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {subTitle && <div className="info warning">{subTitle}</div>}
        </SubTitleGroup>
        {isEditable && !isReadOnly && (
          <ControlBtnGroup>
            <Button css={IconButton.button} className="plus" onClick={handleAddRow} disableRipple>
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button css={IconButton.button} className="minus" onClick={handleDelRow} disableRipple>
              {t('com.button.행삭제', '행삭제')}
            </Button>
            <Button
              css={IconButton.button}
              className="refresh"
              onClick={handleRefresh}
              disableRipple
            >
              {t('com.button.새로고침', '새로고침')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isSelector={[ManagementMode.CREATE, ManagementMode.MODIFY].includes(mode)}
        showCheckAll={false}
        isFilter={false}
        isReadOnly={isReadOnly}
        height={480}
        allowSorting={false}
        allowPinning={false}
        allowDragging={false}
        childItemsPath={['machines', 'units']}
        initialized={onInitialized}
        loadedRows={(grid, e) => {
          if (isEditable) {
            // 전체 행 편집모드 활성화 후 특정 컬럼 readonly 처리는 itemFormatter에서 처리
            grid.rows.forEach((row) => {
              row.isReadOnly = false;
            });
          }
        }}
        beginningEdit={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const item = grid.rows[e.row].dataItem;
          // readonly 설정
          // 상위표준설비 구조도 번호
          if ('upprEqpClsfStrcNo' === binding) {
            // 설비레벨이 Main인 경우 readonly
            if (item.eqpLvCd === EqpLvCd.MAIN) {
              e.cancel = true;
            }
          }
        }}
      />
    </>
  );
};

export default EditMpEquipmentClassificationDetailGrid;
