/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { findGatingVerCkList } from 'apis/gtng/popup/GatingCheckSheetVer';
import { GatingCheckSheetVer } from 'models/gtng/popup/GatingCheckSheetVer';
import { IconButton } from 'components/buttons/IconSVG';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';

export const GatingCheckSheetVerPopUp = (props: any) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<GatingCheckSheetVer[]>([]);
  const [checkedItems, setCheckedItems] = useState<GatingCheckSheetVer[]>([]);

  const onInitialized = (grid) => {
    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return;

      const item = grid.rows[ht.row].dataItem;

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnSelect':
            btnQuickSelect(item);
            break;
        }
      }
      grid.refresh();
    });

    grid.hostElement.addEventListener('dblclick', (e) => {
      const ht = grid.hitTest(e);
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
      btnQuickSelect(item);
    });
  };

  const layoutDefinition = [
    {
      cssClass: 'WijmoRelay',
      cellTemplate: '<Button id="btnSelect"/>',
      width: 39,
    },
    {
      binding: 'versionNo',
      header: String(t('gtng.grid.버전', '버전')),
      align: 'center',
    },
    {
      binding: 'noteContent',
      header: String(t('gtng.grid.변경내용', '변경내용')),
      visible: false,
    },
    {
      binding: 'changeReasonDesc',
      header: String(t('gtng.label.변경사유', '변경사유')),
      align: 'left',
      width: '*',
    },
    {
      binding: 'dataUpdUserId',
      header: String(t('com.label.최종수정자', '최종수정자')),
      align: 'center',
      visible: false,
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('com.label.최종수정자', '최종수정자')),
      align: 'center',
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('com.label.최종수정일', '최종수정일')),
      align: 'center',
      width: 150,
    },
  ];

  useEffect(() => {
    const code = { gatingStepCode: props.gatingStepCode, gatingTypeCode: props.gatingTypeCode };
    findGatingVerCkList(code).then((result: GatingCheckSheetVer[]) => {
      setRowData(result);
    });
  }, [props.initParam]);

  //적용 버튼
  const btnCkVerSelectInfo = () => {
    if ((checkedItems || []).length) {
      props.setVersionData(checkedItems[0]?.versionNo);
      props.setPopup(false);
    } else {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.버전을 선택해 주세요.', '버전을 선택해 주세요.'),
      });
    }
  };

  //Quick Select 버튼
  const btnQuickSelect = (item) => {
    props.setVersionData(item.versionNo);
    props.setPopup(false);
  };

  const onClose = () => {
    props.setPopup(false);
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={btnCkVerSelectInfo}
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <>
      <CustomDialog
        title={t('gtng.label.Check Sheet Version 조회', 'Check Sheet Version 조회')}
        size={'md'}
        open={true}
        onClose={onClose}
        onCancel={onClose}
        buttons={dialogButtons}
      >
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={300}
          align="center"
          rowSelection={'single'}
          autoCheck={true}
          isReadOnly={true}
          isFilter={false}
          allowPinning={false}
          initialized={onInitialized}
          onChangeCheckedItem={(items) => setCheckedItems(items)}
        />
      </CustomDialog>
    </>
  );
};
