/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  ColDef,
  ValueFormatterParams,
  CellValueChangedEvent,
  ColGroupDef,
} from 'ag-grid-community';
import { CrudCode } from 'models/common/Edit';
import { getGatingVerCkList, saveGatingVerCk } from 'apis/gtng/GtngVerMst';
import { GtngVerMst } from 'models/gtng/GtngVerMst';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';
import { Code } from 'models/common/CommonCode';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { CustomSelect } from 'components/selects/CustomSelect';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { GatingCheckSheetCode } from 'models/gtng/GatingCheckSheet';
import { Button } from '@mui/material';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';

interface SearchParamData {
  gatingTypeCode: string;
  gatingTypeConditionList: Code[];
  editable: boolean;
  callBackRoleCd?: any;
}

/**
 * 사용안함
 * @param props
 * @constructor
 */
const GtngVerMstGrid = (props: SearchParamData) => {
  const gridRef = useRef<AgGridReact<GtngVerMst>>(null);
  const [rowData, setRowData] = useState<GtngVerMst[]>([]);
  const [delData, setDelData] = useState<GtngVerMst[]>([]);
  const [userData, setUserData] = useState<any>(null);
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();
  const [code, setCode] = useState<GatingCheckSheetCode>();
  const valueFormatter = (params: any) => {
    if (params.colDef.field == 'organizationCode') {
      return params.node.data.organizationName;
    }
    if (params.colDef.field == 'registrantId') {
      return params.node.data.registrantName;
    }
  };

  //사용자 검색 팝업
  const btnElmEmpPopup = (params: any) => {
    const fieldId = params.column.colId;
    const crudKey = params.data.crudKey;
    const [popup, setPopup] = useState<any>(false); //팝업창 오픈, 클로즈 상태관리
    const [rowId, setRowId] = useState<string>(''); //클릭한 row node의 Id 상태관리
    const [initParam, setInitParam] = useState<string>(''); //검색할 파라미터 상태관리

    const empPopupOpen = (params: any) => {
      const fieldName = params.value;
      const id = params.node.id;
      if (fieldName != null && fieldName != '') {
        setInitParam(fieldName);
      }

      setRowId(id);
      setPopup(true);
    };
    return (
      <>
        {crudKey == null ? <span>{params.valueFormatted}</span> : <span>{params.value}</span>}
        <button onClick={() => empPopupOpen(params)}>검색</button>
        {popup && (
          <ElmEmpPopup
            setPopup={setPopup}
            setUserData={setUserData}
            rowId={rowId}
            initParam={initParam}
            fieldId={fieldId}
          />
        )}
      </>
    );
  };
  useEffect(() => {
    if (userData != null && userData != '') {
      const rowId = userData.rowId;
      const fieldId = userData.fieldId;
      const userInfo = userData.userInfo;
      const rowNode = gridRef.current!.api.getRowNode(rowId);
      rowNode?.setDataValue(fieldId, userInfo[0].userId);
    }
  }, [userData]);

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  const getCommonCodesForGrid = async () => {
    const gatingTypeCode: Code[] = await getCommonCodeNames('GATING_TYPE_CODE');

    setCode({
      checkItemGradeCode: [],
      gatingTypeCode: gatingTypeCode,
      gatingStepCode: [],
    });
  };

  // const getGatingTypeCodes = () => {}

  const defaultColDef: ColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true,
      cellStyle: { textAlign: 'center' },
    }),
    []
  );

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'id',
      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!)}`;
      },
      hide: true,
    },
    {
      headerName: String(t('com.label.NO', 'NO')),
      field: 'crudKey',
      width: 50,
      editable: false,
      filter: false,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!) + 1}`;
      },
    },
    {
      width: 50,
      editable: false,
      checkboxSelection: props.editable,
      headerCheckboxSelection: props.editable,
      hide: !props.editable,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: String(t('gtng.grid.gatingTemplateId', 'gatingTemplateId')),
      field: 'gatingTemplateId',
      hide: true,
    },
    {
      headerName: String(t('gtng.label.Gating 유형', 'Gating 유형')),
      field: 'gatingTypeCode',
      width: 140,
      cellRenderer: (params) => (
        <div>
          <CustomSelect
            onChange={(it) => {
              params.setValue(it.target.value);
            }}
            value={params.value}
          >
            {props.editable && (
              <option value="" key="">
                {t('com.label.선택', '선택')}
              </option>
            )}
            {code?.gatingTypeCode.map((code) => (
              <option value={code.cmnCd} key={code.cmnCd}>
                {code.cmnCdNm}
              </option>
            ))}
          </CustomSelect>
        </div>
      ),
    },
    {
      headerName: String(t('gtng.grid.버전명', '버전명')),
      field: 'versionNo',
      editable: true,
      width: 100,
    },
    {
      headerName: String(t('gtng.grid.버전업내용', '버전업내용')),
      field: 'noteContent',
      editable: true,
      width: 320,
    },
    {
      headerName: String(t('gtng.grid.버전업사유', '버전업사유')),
      field: 'changeReasonDesc',
      editable: true,
      width: 330,
    },
    {
      headerName: String(t('gtng.grid.부서', '부서')),
      field: 'organizationCode',
      editable: true,
      width: 300,
      valueFormatter: valueFormatter,
    },
    {
      headerName: String(t('com.label.부서명', '부서명')),
      field: 'oranizationName',
      hide: true,
    },
    {
      headerName: String(t('com.label.담당자', '담당자')),
      field: 'registrantId',
      width: 150,
      editable: false,
      cellRenderer: btnElmEmpPopup,
      valueFormatter: valueFormatter,
    },
    {
      headerName: String(t('gtng.grid.담당자명', '담당자명')),
      field: 'registrantName',
      hide: true,
    },
    {
      headerName: String(t('gtng.grid.날짜', '날짜')),
      field: 'registerDate',
      width: 150,
      editable: true,
    },
    {
      headerName: String(t('gtng.grid.유첨', '유첨')),
      width: 70,
      editable: false,
    },
    {
      headerName: String(t('gtng.grid.비고', '비고')),
      field: 'requestorComment',
      editable: true,
      width: 340,
    },
  ];

  useEffect(() => {
    fnSearchGtngVerMst(props.gatingTypeCode);
  }, [props]);

  const fnSearchGtngVerMst = useCallback((gatingTypeCode) => {
    const code = { gatingTypeCode: props.gatingTypeCode };
    if (code != null && code != undefined) {
      getGatingVerCkList(code).then((result: GtngVerMst[]) => {
        setRowData(result);
      });
    }
  }, []);

  const btnAddRow = useCallback(() => {
    const newRow = {
      crudKey: CrudCode.CREATE,
    } as GtngVerMst;
    setRowData([newRow, ...rowData]);
  }, [rowData]);
  const btnDelRow = useCallback(() => {
    const selectedRowNodes = gridRef.current!.api.getSelectedNodes();
    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode.id!);
      })
      .reverse();

    selectedIds.forEach((idx) => {
      rowData[idx].crudKey = CrudCode.DELETE;
    });

    const filteredDelData = [...delData, ...rowData.filter((data) => data.crudKey == 'D')];
    setDelData(filteredDelData);

    const filteredData = rowData.filter((element) => element.crudKey !== 'D');
    setRowData(filteredData);
  }, [rowData, gridRef]);

  const btnSave = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      noCallback: () => {
        return false;
      },
    });
    const saveRows = rowData
      .map((rowNode) => {
        return rowNode.crudKey ? rowNode : null;
      })
      .filter((element) => element !== null) as GtngVerMst[];

    const saveRowsData = [...saveRows, ...delData];

    saveGatingVerCk(saveRowsData).then((result) => {
      if (!result) {
        openCommonModal({ content: 'insert 실패..' });
        return false;
      }
      openCommonModal({
        content:
          t('com.label.입력', '입력') +
          ' : ' +
          (result.insertedRows || 0) +
          t('com.label.건', '건') +
          '\n' +
          t('com.label.수정', '수정') +
          ' : ' +
          (result.updatedRows || 0) +
          t('com.label.건', '건') +
          '\n' +
          t('com.label.삭제', '삭제') +
          ' : ' +
          (result.deletedRows || 0) +
          t('com.label.건', '건'),
      });
      setDelData([]);
      fnSearchGtngVerMst(props.gatingTypeCode);
    });
  };

  const onCellClicked = useCallback((event: any) => {
    props.callBackRoleCd && props.callBackRoleCd(event.data.roleCd);
  }, []);

  // const isRowSelectable = useMemo<IsRowSelectable>(() => {
  //   return (node: IRowNode) => {
  //     return !!node.data && node.data.crudKey === CrudCode.CREATE;
  //   };
  // }, []);

  const onCellValueChanged = useCallback((e: CellValueChangedEvent) => {
    !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  }, []);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating Check Sheet 버전관리', 'Gating Check Sheet 버전관리')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        {props.editable && (
          <ControlBtnGroup>
            <Button css={IconButton.button} className="plus" disableRipple onClick={btnAddRow}>
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button css={IconButton.button} className="minus" disableRipple onClick={btnDelRow}>
              {t('com.button.행삭제', '행삭제')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>
      <ContentGrid className="ag-theme-alpine">
        <AgGridReact<GtngVerMst>
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          suppressRowClickSelection={true}
          // isRowSelectable={isRowSelectable}
          stopEditingWhenCellsLoseFocus={true}
          singleClickEdit={true}
          rowSelection="multiple"
          onCellClicked={onCellClicked}
          onCellValueChanged={onCellValueChanged}
          domLayout={'autoHeight'}
        />
      </ContentGrid>
      <GlobalBtnGroup>
        {props.editable && (
          <Button css={IconButton.button} className="save" onClick={btnSave} disableRipple>
            {t('com.button.저장', '저장')}
          </Button>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default memo(GtngVerMstGrid);
