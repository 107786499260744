/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import { GuideBlock } from '../GuideComponent';
import CustomInputWithSearch, { MsgType } from 'components/inputs/CustomInputWithSearch';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
} from 'components/layouts/SearchBox';
import { ComboBox } from 'components/selects/ComboBox';

interface Sample {
  ipCheckPlanNo?: string;
  ipCheckPlanName?: string;
  ipCheckStatus?: string;
  copCd?: string;
  equipmentGroup?: string;
  equipmentName?: string;
  machineName?: string;
  assemblyName?: string;
  eqpParaNo?: string;
}

const ButtonsGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const [condition, setCondition] = useState<Sample>({});
  const [isOpenSearchBox, setOpenSearchBox] = useState<boolean>(false);

  const handleParamConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setCondition({
      ...condition,
      [e.target.name]: e.target.value,
    });
  };

  const handleResetCondition = () => {
    setCondition(
      (prev) =>
        Object.keys(prev).reduce((acc, cur) => Object.assign(acc, { [cur]: '' }), {}) as Sample
    );
  };

  const drawInputBox = () => {
    return (
      <InputBox>
        <SearchRows>
          <SearchCols>
            <label>IP점검계획번호</label>
            <CustomInputWithSearch
              type="text"
              name="ipCheckPlanNo"
              placeholder={'IP점검계획번호'}
              value={condition?.ipCheckPlanNo}
              onChange={handleParamConditionChange}
            />
          </SearchCols>
          <SearchCols>
            <label>IP점검계획명</label>
            <CustomInputWithSearch
              type="text"
              name="ipCheckPlanName"
              placeholder={'IP점검계획명'}
              value={condition?.ipCheckPlanName}
              onChange={handleParamConditionChange}
            />
          </SearchCols>
          <SearchCols>
            <label>진행상태</label>
            <ComboBox
              placeholder={'진행상태'}
              options={[
                { cmnCd: 'PLAN', cmnCdNm: 'IP점검계획 수립' },
                { cmnCd: 'FAT_MAKER', cmnCdNm: 'FAT 업체 점검' },
                { cmnCd: 'FAT_ENSOL', cmnCdNm: 'FAT 엔솔 검증' },
              ]}
              defaultValue={condition?.ipCheckStatus}
              onChange={(value: string) =>
                setCondition({
                  ...condition,
                  ipCheckStatus: value,
                })
              }
            />
          </SearchCols>
        </SearchRows>
        <SearchRows>
          <SearchCols>
            <label>법인코드</label>
            <ComboBox
              placeholder={'법인코드'}
              options={[
                { cmnCd: 'LGESOC', cmnCdNm: 'LGESOC' },
                { cmnCd: 'LGESWA', cmnCdNm: 'LGESWA' },
                { cmnCd: 'LGESHD', cmnCdNm: 'LGESHD' },
              ]}
              defaultValue={condition?.copCd}
              onChange={(value: string) =>
                setCondition({
                  ...condition,
                  copCd: value,
                })
              }
            />
          </SearchCols>
          <SearchCols>
            <label>설비군</label>
            <CustomInputWithSearch
              type="text"
              name="equipmentGroup"
              className="find"
              placeholder={'설비군'}
              value={condition?.equipmentGroup}
              onChange={handleParamConditionChange}
              onSearchClick={() => null}
            />
          </SearchCols>
          <SearchCols>
            <label>설비명</label>
            <CustomInputWithSearch
              type="text"
              name="equipmentName"
              placeholder={'설비명'}
              value={condition?.equipmentName}
              onChange={handleParamConditionChange}
            />
          </SearchCols>
        </SearchRows>
      </InputBox>
    );
  };

  return (
    <>
      <GuideBlock title={'<SearchBoxButtons> Import'} isCode={true}>
        {`import SearchBoxButtons from 'components/buttons/SearchBoxButtons';`}
      </GuideBlock>
      <GuideBlock title={'<SearchBoxButtons> 속성'} isCode={true}>
        {`
interface Props {
  defaultCondition?: object; // 리셋 초기값 (입력하지 않은 경우 빈 값으로 초기화) (cf. { useYn: 'Y' }
  // 리셋 초기값을 설정할 setter (setter 전달 시에만 초기화, 없는 경우 onReset을 통해 따로 초기화 진행)
  setReset?: React.Dispatch<React.SetStateAction<object>>;
  onReset?: () => void; // 리셋 버튼 클릭 이벤트
  onSearch: () => void; // 검색 버튼 클릭 이벤트
  onFolding?: (isOpen: boolean) => void; // 접기 버튼 클릭 이벤트
}
              `}
      </GuideBlock>
      <Alert severity="info">
        초기화 버튼은 setReset | onReset 둘 중 하나가 선언되어야 노출됨
        <br />
        - setReset: 초기화할 object의 setter를 전달 (useState의 setter)
        <br />- defaultCondition: 초기화 기본값으로, setReset을 통해 초기화 작업 시 동일한 키값이
        있는 경우 기본값으로 사용 (입력하지 않은 경우 빈 값으로 초기화)
        <br />- onReset: 초기화 버튼 클릭 이벤트로 setReset과 동시에 사용가능
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>SearchBoxButtons - setReset만 설정한 경우</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <SearchBox>
                <SearchBoxRow>
                  {drawInputBox()}
                  <SearchBoxButtons
                    setReset={setCondition} // setter
                    onSearch={() => console.log('onSearch click -> ', condition)}
                  />
                </SearchBoxRow>
              </SearchBox>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<SearchBoxButtons
  setReset={setCondition}  // setter
  onSearch={() => console.log('onSearch click -> ', condition)}
/>
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>
            SearchBoxButtons - setReset / defaultCondition 설정한 경우
          </AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <SearchBox>
                <SearchBoxRow>
                  {drawInputBox()}
                  <SearchBoxButtons
                    defaultCondition={{
                      ipCheckPlanNo: 'default value',
                      ipCheckStatus: 'FAT_ENSOL',
                    }} // 초기값
                    setReset={setCondition} // setter
                    onSearch={() => console.log('onSearch click -> ', condition)}
                  />
                </SearchBoxRow>
              </SearchBox>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
// setter 예시
const [condition, setCondition] = useState<Sample>({});

<SearchBoxButtons
  defaultCondition={{
    ipCheckPlanNo: 'default value',
    ipCheckStatus: 'FAT_ENSOL',
  }} // 초기값
  setReset={setCondition} // setter
  onSearch={() => console.log('onSearch click -> ', condition)}
/>
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary>SearchBoxButtons - onReset만 설정한 경우</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <SearchBox>
                <SearchBoxRow>
                  {drawInputBox()}
                  <SearchBoxButtons
                    // setReset 없는 경우 초기화 기능 로직 별도 구현 필요
                    onReset={handleResetCondition}
                    onSearch={() => console.log('onSearch click -> ', condition)}
                  />
                </SearchBoxRow>
              </SearchBox>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
// 초기화 함수 예시
const handleResetCondition = () => {
  setCondition(
    (prev) =>
      Object.keys(prev).reduce((acc, cur) => Object.assign(acc, { [cur]: '' }), {}) as Sample
  );
};

<SearchBoxButtons
  // setReset 없는 경우 초기화 기능 로직 별도 구현 필요
  onReset={handleResetCondition}
  onSearch={() => console.log('onSearch click -> ', condition)}
/>
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary>SearchBoxButtons - onFolding 설정한 경우</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <SearchBox>
                <SearchBoxRow>
                  {drawInputBox()}
                  <SearchBoxButtons
                    setReset={setCondition} // setter
                    onSearch={() => console.log('onSearch click -> ', condition)}
                    onFolding={(isOpen) => setOpenSearchBox(isOpen)}
                  />
                </SearchBoxRow>
                <SearchBoxRow style={{ display: isOpenSearchBox ? 'block' : 'none' }}>
                  <InputBox>
                    <SearchRows>
                      <SearchCols>
                        <label>Machine</label>
                        <CustomInputWithSearch
                          type="text"
                          name="machineName"
                          placeholder={'Machine'}
                          value={condition?.machineName}
                          onChange={handleParamConditionChange}
                        />
                      </SearchCols>
                      <SearchCols>
                        <label>Assembly</label>
                        <CustomInputWithSearch
                          type="text"
                          name="assemblyName"
                          placeholder={'Assembly'}
                          value={condition?.assemblyName}
                          onChange={handleParamConditionChange}
                        />
                      </SearchCols>
                      <SearchCols>
                        <label>설비파라미터번호</label>
                        <CustomInputWithSearch
                          type="text"
                          name="eqpParaNo"
                          placeholder={'설비파라미터번호'}
                          value={condition?.eqpParaNo}
                          onChange={handleParamConditionChange}
                        />
                      </SearchCols>
                    </SearchRows>
                  </InputBox>
                </SearchBoxRow>
              </SearchBox>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
// 예시
const [isOpenSearchBox, setOpenSearchBox] = useState<boolean>(false);

<SearchBoxButtons 
  setReset={setCondition} // setter
  onSearch={() => console.log('onSearch click -> ', condition)}
  onFolding={(isOpen) => setOpenSearchBox(isOpen)}
/>
`}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default ButtonsGuide;
