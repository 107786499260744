/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from '../../components/layouts/Dialog';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { CustomInputText, CustomInputWrap, UseToggle } from '../../components/inputs/CustomInput';
import { SubTitleLayout, SubTitleGroup } from '../../components/layouts/ContentLayout';
import { ContentGrid } from '../../components/layouts/ContentGrid';
import { getElmEmpList } from 'apis/common/ElmEmp';
import { ElmEmp } from 'models/common/ElmEmp';
import { IconButton } from 'components/buttons/IconSVG';
import { useCommonModal } from 'hooks/useCommonModal';
import _ from 'lodash';
import { TooltipComponent, TooltipValueGetter } from 'components/grids/Tooltip';
import useSessionStore from 'stores/useSessionStore';
import { CloseBtn } from 'components/buttons/CustomButton';
import { useTranslation } from 'react-i18next';
// Wijmo library
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';

interface SearchParam {
  userId?: string;
  empNm?: string;
  deptNm?: string;
  useYn?: string;
  isEditableFlag?: boolean;
}

type Props = {
  open: boolean;
  close: () => void;
  condition?: {
    empNm: string;
  };
  onCallback: (item: ElmEmp | ElmEmp[]) => void;
  //singleSelect?: boolean;
};

export const ElmEmpPopup = (props: any) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const [rowData, setRowData] = useState<any[]>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [itemId, setItemId] = useState<any>();
  const [userInfos, setUserInfos] = useState<ElmEmp[]>();
  const [searchParam, setSearchParam] = useState<SearchParam>({
    useYn: 'Y',
  });
  const [isEditableFlag, setEditableFlag] = useState<boolean>(true);
  const [colWidthMap, setColWidthMap] = useState<any>({
    no: 70,
    qselect: 120,
    useYn: 140,
    userId: 150,
    empNm: 150,
    deptCd: 160,
    deptNm: '*',
  });

  const btnUserSelectInfo = () => {
    const fieldId = props.fieldId;
    const data = flexRef.selectedRows.length > 0 ? [flexRef.selectedRows[0].dataItem] : [];
    const userData = { userInfo: data, fieldId: fieldId };
    props.setUserData(userData);
    props.setPopup(false);
    // props.close();
  };

  const onselectionchange = (grid, e) => {
    const ht = grid.hitTest(e);
    setHitTest(ht);
    const selectedRows = grid.rows[ht.row].dataItem || [];
    handleButtonClick({ data: selectedRows });
    selectedRows.length > 0 ? setUserInfos(selectedRows) : '';
  };

  const handleButtonClick = (params) => {
    const userData = { userInfo: [params.data], fieldId: props.fieldId };
    // const userData = {
    //   userInfo: [{ empNm: params.data.empNm, userId: params.data.userId }],
    //   rowId: props.rowId,
    //   fieldId: props.fieldId,
    // };

    props.setUserData(userData);
    props.setPopup(false);
    // props.close();
  };

  useEffect(() => {
    if (!_.isNull(props.initUserId) && !_.isEmpty(props.initUserId)) {
      setSearchParam({
        ...searchParam,
        userId: props?.initUserId || '',
      });
    }
    if (!_.isNull(props.initParam) && !_.isEmpty(props.initParam)) {
      setSearchParam({
        ...searchParam,
        empNm: props?.initParam || '',
      });
    }
    if (props.isEditableFlag != null && props.isEditableFlag != '') {
      setEditableFlag(isEditableFlag);
    }
    //getElmEmpInfo(searchParam);
  }, [props]);

  const getElmEmpInfo = async (searchParam: SearchParam) => {
    await getElmEmpList(searchParam).then((result: ElmEmp[]) => {
      if (result != null && result != undefined) {
        setRowData(result);
      }
    });
  };

  const handleSearchParam = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      getElmEmpInfo(searchParam);
    }
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: cur === 'useYn' ? 'Y' : '' }),
          {}
        ) as SearchParam
    );
  };

  // const defaultColumnDefs: ColDef = {
  //   // cellStyle: { textAlign: 'center' },
  //   resizable: true,
  //   tooltipComponent: TooltipComponent,
  //   tooltipValueGetter: TooltipValueGetter,
  // };

  const LayoutDefinition = () => {
    return [
      // {
      //   width: 37,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      //   headerClass: 'headerCheck disable',
      //   cssClass: 'cellCheck',
      // },
      {
        header: String(t('com.label.NO', 'NO')),
        width: colWidthMap.no,
        align: 'center',
        isReadOnly: true,
        // suppressSizeToFit: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: 'Quick Select',
        align: 'center',
        isReadOnly: true,
        width: colWidthMap.qselect,
        cssClass: 'WijmoRelay',
        cellTemplate: (params) => `<button id='qSelect'/>`,
      },
      {
        header: String(t('com.label.사용자ID', '사용자ID')),
        binding: 'userId',
        isReadOnly: true,
        align: 'center',
        // cellStyle: { textAlign: 'left' },
        width: colWidthMap.userId,
      },
      {
        header: String(t('com.label.사용자명', '사용자명')),
        binding: 'empNm',
        isReadOnly: true,
        // cellStyle: { textAlign: 'left' },
        width: colWidthMap.empNm,
      },
      {
        header: String(t('com.label.부서코드', '부서코드')),
        binding: 'deptCd',
        align: 'center',
        isReadOnly: true,
        // cellStyle: { textAlign: 'center' },
        width: colWidthMap.deptCd,
      },
      {
        header: String(t('com.label.부서명', '부서명')),
        binding: 'deptNm',
        width: colWidthMap.deptNm,
        isReadOnly: true,
        // cellStyle: { textAlign: 'left' },
        // flex: 1,
      },
      {
        header: String(t('com.label.사용여부', '사용여부')),
        binding: 'useYn',
        isReadOnly: true,
        align: 'center',
        // cellStyle: { textAlign: 'center' },
        width: colWidthMap.useYn,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setflexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('dblclick', (e) => {
      onselectionchange(grid, e);
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);

      switch (e.target.id) {
        case 'qSelect':
          handleButtonClick({ data: item });
          break;
      }

      grid.refresh();
    });
  };

  return (
    <>
      <Dialog open={true} css={di.dialog} fullWidth className="lg" maxWidth={false}>
        <TitleWrap>
          <h2>{t('com.label.사용자 조회', '사용자 조회')}</h2>
          <CloseBtn
            onClick={() => {
              props.setPopup(false);
            }}
          ></CloseBtn>
        </TitleWrap>
        <DialogContent>
          <SearchBox>
            <SearchBoxRow>
              <InputBox>
                <SearchRows>
                  <SearchCols>
                    <label>{t('com.label.부서명', '부서명')}</label>
                    <CustomInputWrap>
                      <CustomInputText
                        name="deptNm"
                        placeholder={String(
                          t('com.label.부서명을 입력해 주세요.', '부서명을 입력해 주세요.')
                        )}
                        value={searchParam.deptNm}
                        onChange={handleSearchParam}
                        onKeyDown={onKeyDown}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </SearchCols>
                  <SearchCols>
                    <label>{t('com.label.사용자ID', '사용자ID')}</label>
                    <CustomInputWrap>
                      <CustomInputText
                        name="userId"
                        value={searchParam.userId}
                        placeholder={String(
                          t('com.label.사용자ID를 입력해 주세요.', '사용자ID를 입력해 주세요.')
                        )}
                        onChange={handleSearchParam}
                        onKeyDown={onKeyDown}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </SearchCols>
                  <SearchCols>
                    <label>{t('com.label.사용자명', '사용자명')}</label>
                    <CustomInputWrap>
                      <CustomInputText
                        name="empNm"
                        value={searchParam.empNm}
                        placeholder={String(
                          t('com.label.사용자명을 입력해 주세요.', '사용자명을 입력해 주세요.')
                        )}
                        onChange={handleSearchParam}
                        onKeyDown={onKeyDown}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </SearchCols>
                </SearchRows>
                <SearchRows>
                  <SearchCols>
                    <label>{t('com.label.사용여부', '사용여부')}</label>
                    <UseToggle className="switch">
                      <input
                        type="checkbox"
                        name="useYn"
                        value={searchParam.useYn}
                        checked={searchParam.useYn === 'Y'}
                        onChange={() => {
                          setSearchParam({
                            ...searchParam,
                            useYn: searchParam.useYn === 'Y' ? 'N' : 'Y',
                          });
                        }}
                      />
                      <span className="slider"></span>
                      <span
                        className="labels"
                        data-on={t('com.label.사용', '사용')}
                        data-off={t('com.label.미사용', '미사용')}
                      ></span>
                    </UseToggle>
                  </SearchCols>
                </SearchRows>
              </InputBox>
              <SearchButtonWrap>
                <Button
                  css={IconButton.button}
                  className="reload"
                  onClick={btnReload}
                  disableRipple
                ></Button>
                <Button
                  css={IconButton.button}
                  className="find"
                  onClick={() => {
                    getElmEmpInfo(searchParam);
                  }}
                  disableRipple
                >
                  {t('com.button.조회', '조회')}
                </Button>
              </SearchButtonWrap>
            </SearchBoxRow>
          </SearchBox>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('com.label.사용자', '사용자')}</h3>
              <span className="total">
                {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
          </SubTitleLayout>
          <ContentGrid className={rowData.length < 1 ? 'noData' : ''} style={{ height: '500px' }}>
            <FlexGrid
              columnGroups={state.layoutDefinition}
              itemsSource={state.itemsSource}
              deferResizing={false}
              //selectionMode={'ListBox'}
              //headersVisibility="Column"
              //showSelectedHeaders="All"
              //showMarquee={false}
              //showAlternatingRows={false}
              //alternatingRowStep={0}
              // allowPinning="ColumnRange" // 열 고정 방식
              //allowMerging="ColumnHeaders"
              // autoGenerateColumns={false}
              // selectionMode={'Row'}
              // stickyHeaders={true}
              //isReadOnly={true}
              // itemsSourceChanging={onCellValueChanged}
              // formatItem={flexGridTooltip}
              // selectionChanged={onFlexGridSelectionChanged}
              initialized={onInitialized}
              style={{ height: rowData.length < 1 ? '' : '500px' }}
            />
            <div className="noData" style={{ height: rowData.length < 1 ? '500px' : '' }}>
              <span>
                {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
              </span>
            </div>
          </ContentGrid>
        </DialogContent>
        {isEditableFlag && (
          <PopupBtnGroup>
            <SearchButtonWrap>
              {/* <Button className="cancel" onClick={() => close()} disableRipple> */}
              <Button
                onClick={() => {
                  props.setPopup(false);
                }}
              >
                {t('com.button.취소', '취소')}
              </Button>
              <Button css={IconButton.button} className="select" onClick={btnUserSelectInfo}>
                {t('com.button.선택', '선택')}
              </Button>
            </SearchButtonWrap>
          </PopupBtnGroup>
        )}
      </Dialog>
    </>
  );
};
