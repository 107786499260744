/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SidebarButtonProps } from 'models/common/button.type';
import { ControlFilldBG, GrayColor } from 'ui/theme/Color';
import { SVGIcon } from 'components/buttons/IconSVG';

export const SidebarToggleButton = ({ onClick, isClicked }: SidebarButtonProps) => {
  return <button css={st.button} onClick={onClick}></button>;
};

const st = {
  button: css`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 52px;
    height: 52px;
    border: 0;
    outline: 0;
    z-index: 999;
    background: ${ControlFilldBG.Primary};
    cursor: pointer;
    &:hover {
      background: ${ControlFilldBG.PrimaryHover};
    }
    &:active {
      background: ${ControlFilldBG.PrimaryActive};
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: ${GrayColor.White};
      -webkit-mask: ${SVGIcon.IcoHamberg};
      mask-image: ${SVGIcon.IcoHamberg};
    }
  `,
};
