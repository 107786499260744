import React from 'react';
import { ITooltipParams } from 'ag-grid-community';

export const TooltipComponent = (params: ITooltipParams & { color: string }) => {
  return <div className="cellTooltip">{params.value}</div>;
};

export const TooltipValueGetter = (params: ITooltipParams) => {
  return params.value;
};
