/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from 'components/layouts/Dialog';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { UseToggle } from 'components/inputs/CustomInput';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { SubTitleLayout, SubTitleGroup, ControlBtnGroup } from 'components/layouts/ContentLayout';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { IconButton } from 'components/buttons/IconSVG';
import { ParaItemCondition } from 'models/ip/popup/ParaItem';
import { useTranslation } from 'react-i18next';
import { findParaItem } from 'apis/ip/popup/ParaItem';
import { useMessageBar } from 'hooks/useMessageBar';
import _ from 'lodash';
import useSessionStore from 'stores/useSessionStore';
// Wijmo library
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';

type Props = {
  open: boolean;
  close: () => void;
  condition?: {
    paraItem?: string;
    paraItemNm?: string;
  };
  onCallback: (item: ParaItemCondition | ParaItemCondition[]) => void;
  singleSelect?: boolean;
};

interface ParaItemPageLocationState {
  paraItem?: string;
  paraItemNm: string;
  useYn?: string;
  condition?: any;
}

export const ParaItemPopUp = ({
  open,
  close,
  condition,
  onCallback,
  singleSelect = true,
}: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { gridNoRowsTemplate } = useSessionStore();

  //const [searchParaItemData, setSearchParaItemData] = useState<ParaItemCondition>({});
  const [searchParaItemData, setSearchParaItemData] = useState<ParaItemCondition>({
    paraItem: condition?.paraItem || '',
    paraItemNm: condition?.paraItemNm || '',
    useYn: 'Y',
  });
  const [rowData, setRowData] = useState<ParaItemCondition[]>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [colWidthMap, setColWidthMap] = useState<any>({
    qselect: 120,
    paraItem: 286,
    useYn: 276,
    paraItemNm: '*',
  });

  const locationState: ParaItemPageLocationState = useLocation().state;

  useEffect(() => {
    if (!_.isEmpty(condition?.paraItemNm) || !_.isEmpty(condition?.paraItem)) {
      searchBtn(searchParaItemData);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setSearchParaItemData({ ...searchParaItemData, [e.target.name]: e.target.value });
  };

  const searchBtn = async (condition: ParaItemCondition) => {
    // TODO condition 을 넘겨줘야함
    await findParaItem(condition).then((response) => {
      // props.searchParamData
      if (response) {
        const rowData = response || [];
        setRowData(rowData);

        if (Object.keys(rowData).length === 0) {
          openMessageBar({
            type: 'error',
            content: t(
              'com.label.조회 가능한 데이터가 없습니다.',
              '조회 가능한 데이터가 없습니다.'
            ),
          });
          return;
        }
      }
    });
  };

  const handleResetCondition = () => {
    setSearchParaItemData(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as ParaItemCondition
    );
  };

  const handleConfirm = () => {
    // const rows = gridRef.current?.api.getSelectedRows() || [];
    const rows = flexRef.selectedRows || []; //gridRef.current!.api.getSelectedNodes();
    onCallback(rows.length > 0 ? rows[0].dataItem : rows);
    close();
  };

  const handleButtonClick = (params) => {
    // data에 현재 행의 데이터가 포함되어 있음123345
    onCallback(!_.isNull(params.data) ? params.data : null);
    close();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchBtn(searchParaItemData);
    }
  };

  const LayoutDefinition = () => {
    return [
      // {
      //   width: 37,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      //   suppressSizeToFit: true,
      // },
      {
        header: 'Quick Select',
        width: colWidthMap.qselect,
        isReadOnly: true,
        cellTemplate: (params) => `<button id='qSelect'/>`,
        cssClass: 'WijmoRelay',
        align: 'center',
      },
      {
        binding: 'paraItem',
        header: String(t('ip.grid.파라미터 항목코드', '파라미터 항목코드')),
        isReadOnly: true,
        align: 'center',
        width: colWidthMap.paraItem,
      },
      {
        binding: 'paraItemNm',
        header: String(t('ip.grid.파라미터 항목명', '파라미터 항목명')),
        isReadOnly: true,
        align: 'center',
        width: colWidthMap.paraItemNm,
        // flex: 1,
      },
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        isReadOnly: true,
        align: 'center',
        width: colWidthMap.useYn,
      },
    ];
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    setflexRef(grid);
    // grid.rowHeaders.columns.clear();

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('dblclick', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      handleButtonClick({ data: item });
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);

      switch (e.target.id) {
        case 'qSelect':
          handleButtonClick({ data: item });
          break;
      }
    });
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  // const defaultColumnDefs: ColDef = {
  //   cellStyle: { textAlign: 'center' },
  //   resizable: true,
  //   tooltipComponent: TooltipComponent,
  //   tooltipValueGetter: TooltipValueGetter,
  // };

  return (
    <>
      <Dialog open={open} css={di.dialog} fullWidth className="lg" maxWidth={false}>
        <TitleWrap>
          <h2>{t('ip.label.파라미터 항목', '파라미터 항목')}</h2>
          <Button onClick={() => close()} disableRipple></Button>
        </TitleWrap>
        <DialogContent>
          <SearchBox>
            <SearchBoxRow>
              <InputBox>
                <SearchRows>
                  <SearchCols>
                    <label>{t('ip.label.파라미터 항목코드', '파라미터 항목코드')}</label>
                    <CustomInputWithSearch
                      name="paraItem"
                      placeholder={String(
                        t(
                          'ip.msg.파라미터 항목코드를 입력해 주세요.',
                          '파라미터 항목코드를 입력해 주세요.'
                        )
                      )}
                      value={searchParaItemData.paraItem}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </SearchCols>
                  <SearchCols>
                    <label>{t('ip.label.파라미터 항목명', '파라미터 항목명')}</label>
                    <CustomInputWithSearch
                      name="paraItemNm"
                      placeholder={String(
                        t(
                          'ip.msg.파라미터 항목명을 입력해 주세요.',
                          '파라미터 항목명을 입력해 주세요.'
                        )
                      )}
                      value={searchParaItemData.paraItemNm}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </SearchCols>
                  <SearchCols>
                    <label>{t('com.label.사용여부', '사용여부')}</label>
                    <UseToggle className="switch">
                      <input
                        type="checkbox"
                        name="useYn"
                        value={searchParaItemData.useYn}
                        checked={searchParaItemData.useYn === 'Y'}
                        onChange={() => {
                          setSearchParaItemData({
                            ...searchParaItemData,
                            useYn: searchParaItemData.useYn === 'Y' ? 'N' : 'Y',
                          });
                        }}
                      />
                      <span className="slider"></span>
                      <span
                        className="labels"
                        data-on={t('com.label.사용', '사용')}
                        data-off={t('com.label.미사용', '미사용')}
                      ></span>
                    </UseToggle>
                  </SearchCols>
                </SearchRows>
              </InputBox>
              <SearchButtonWrap>
                <Button
                  css={IconButton.button}
                  className="reload"
                  onClick={handleResetCondition}
                  disableRipple
                ></Button>
                <Button
                  css={IconButton.button}
                  className="find"
                  onClick={() => searchBtn(searchParaItemData)}
                  disableRipple
                >
                  {t('com.button.조회', '조회')}
                </Button>
              </SearchButtonWrap>
            </SearchBoxRow>
          </SearchBox>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('ip.label.파라미터 항목', '파라미터 항목')}</h3>
              <span className="total">
                {t('com.label.총', '총')} <span>{rowData.length}</span>
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
            <ControlBtnGroup>
              <Button
                css={IconButton.button}
                className="refresh"
                onClick={() => searchBtn(searchParaItemData)}
                disableRipple
              >
                {t('com.button.새로고침', '새로고침')}
              </Button>
            </ControlBtnGroup>
          </SubTitleLayout>
          <ContentGrid
            className={rowData.length < 1 ? 'noData' : ''}
            style={{ height: '267px', marginBottom: rowData.length < 1 ? 0 : '32px' }}
          >
            <FlexGrid
              itemsSource={state.itemsSource}
              columnGroups={state.layoutDefinition}
              deferResizing={false}
              //selectionMode={'ListBox'}
              //headersVisibility="Column"
              //showSelectedHeaders="All"
              //showMarquee={false}
              //showAlternatingRows={false}
              //alternatingRowStep={0}
              // allowPinning="ColumnRange" // 열 고정 방식
              //allowMerging="ColumnHeaders"
              // autoGenerateColumns={false}
              selectionMode={'Row'}
              stickyHeaders={true}
              //isReadOnly={true}
              // itemsSourceChanging={onCellValueChanged}
              formatItem={flexGridTooltip}
              // selectionChanged={onFlexGridSelectionChanged}
              initialized={onInitialized}
              style={{ height: (rowData || []).length < 1 ? '' : '267px' }}
            />
            <div className="noData" style={{ height: (rowData || []).length < 1 ? '267px' : '' }}>
              <span>
                {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
              </span>
            </div>
            {/* <AgGridReact
              rowHeight={32}
              defaultColDef={defaultColumnDefs}
              columnDefs={columnDefs}
              rowData={rowData}
              ref={gridRef}
              rowSelection={singleSelect ? 'single' : 'multiple'}
              suppressRowTransform={true}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              onCellDoubleClicked={handleConfirm}
              overlayNoRowsTemplate={gridNoRowsTemplate}
            ></AgGridReact> */}
          </ContentGrid>
        </DialogContent>
        <PopupBtnGroup>
          <Button className="cancel" onClick={() => close()} disableRipple>
            {t('com.button.취소', '취소')}
          </Button>
          <Button css={IconButton.button} className="confirm" onClick={handleConfirm} disableRipple>
            {t('com.button.선택', '선택')}
          </Button>
        </PopupBtnGroup>
      </Dialog>
    </>
  );
};
