import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

const MenumanagementTable = () => {
  const { t } = useTranslation();

  const roleByMenuCol = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'roleCd',
      header: String(t('admin.grid.역할코드', '역할코드')),
      width: 135,
      align: 'left',
      isReadOnly: true,
    },
    {
      binding: 'roleNm',
      header: String(t('admin.grid.역할명', '역할명')),
      align: 'left',
      width: 150,
      isReadOnly: true,
    },
    {
      binding: 'roleDesc',
      header: String(t('admin.grid.역할설명', '역할설명')),
      width: '*',
      align: 'left',
      isReadOnly: true,
    },
  ];

  const employeeByMenuCol = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'userId',
      header: String(t('com.label.아이디', '아이디')),
      width: 115,
      align: 'left',
      isReadOnly: true,
    },
    {
      binding: 'empNm',
      header: String(t('admin.grid.이름', '이름')),
      align: 'center',
      width: 100,
      isReadOnly: true,
    },
    {
      binding: 'deptNm',
      header: String(t('admin.grid.부서명', '부서명')),
      width: 150,
      align: 'left',
      isReadOnly: true,
    },
    {
      binding: 'emlSvrDmnIfoNm',
      header: String(t('admin.grid.이메일', '이메일')),
      width: '*',
      align: 'left',
      isReadOnly: true,
    },
  ];

  const departmentByMenuCol = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'userId',
      header: String(t('com.label.아이디', '아이디')),
      width: 115,
      align: 'left',
      isReadOnly: true,
    },
    {
      binding: 'deptCd',
      header: String(t('admin.grid.부서코드', '부서코드')),
      align: 'center',
      width: 115,
      isReadOnly: true,
    },
    {
      binding: 'copCd',
      header: String(t('admin.grid.법인코드', '법인코드')),
      width: '*',
      align: 'left',
      isReadOnly: true,
    },
  ];

  return {
    roleByMenuCol,
    employeeByMenuCol,
    departmentByMenuCol,
  };
};

export default MenumanagementTable;
