/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from 'components/layouts/Dialog';
import { IconButton } from 'components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import {
  ControlBtnGroup,
  HalfContetntLayout,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { ContentGrid } from 'components/layouts/ContentGrid';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
// Wijmo library
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { ExcpPartnerUser } from 'models/admin/ExcpPartner';
import { ComboBox } from 'components/selects/ComboBox';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import Datepicker, { DateRangeType } from 'react-tailwindcss-datepicker';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { findBookmark } from 'apis/common/Bookmark';
import UserMultiModal from './UserMultiModal';
import useEvent from 'react-use-event-hook';
import * as input from '@grapecity/wijmo.input';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';
import { CrudCode } from 'models/common/Edit';
import { AutoSendEmail, AutoSendEmailSearchCondition } from 'models/common/AutoSendEmail';
import { GridStatusCellRenderer } from 'components/grids/GridStatusCellRenderer';
import UserModal from './UserModal';
import { getAutoSendEmailList, saveAutoSendEmailInfo } from 'apis/common/AutoSendEmail';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import { useCommonModal } from 'hooks/useCommonModal';
import useSessionStore from 'stores/useSessionStore';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import { hasRole } from 'utils/SessionUtil';
import SearchDateRangePicker from 'pages/common/components/SearchDateRangePicker';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import CustomDialog from './CustomDialog';
import { tb } from 'components/layouts/Table';

type Props = {
  open: boolean;
  close: () => void;
  condition?: any;
};

interface DisplayCondition {
  recvUserNm?: string;
  dataUpdUserNm?: string;
}

const AutoSendEmailModal = ({ open, close, condition }: Props) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<AutoSendEmail[]>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [mode, setMode] = useState<string>('');
  const [searchCondition, setSearchCondition] = useState<AutoSendEmailSearchCondition>({
    emlTpNm: '',
    prdTpCd: '',
    prdNvl: '',
    recvUserId: '',
    recvUserIds: '',
    recvUserNm: '',
    emlTgtCtn: '',
    dataUpdUserId: '',
    dataUpdUserIds: '',
    dataUpdUserNm: '',
    startDate: '',
    endDate: '',
  });

  const [displayCondition, setDisplayCondition] = useState<DisplayCondition>({
    recvUserNm: '',
    dataUpdUserNm: '',
  });
  const [colWidthMap, setColWidthMap] = useState<any>({
    emlTpNm: 250,
    emlTgtCtn: 200,
    prdTpCd: 110,
    prdNvl: 110,
    recvUserId: 200,
    vldStDt: 130,
    vldEndDt: 130,
    useYn: 100,
    dataUpdUserNm: 120,
    dataUpdDtm: 120,
  });

  const [isOpenUserMultiModal, setOpenUserMultiModal] = useState<boolean>(false);
  const [isOpenUserMultiReadModal, setOpenUserMultiReadModal] = useState<boolean>(false);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);
  const [prdTpCdCode, setPrdTpCdCode] = useState<Code[]>([]);
  const [prdNvlCode, setPrdNvlCode] = useState<Code[]>([]);
  const [prdNvlCodeDataMap, setPrdNvlCodeDataMa] = useState<Code[]>([]);
  const [myBookMark, setMyBookmark] = useState<Code[]>([]);
  const [getUseYn, setUseYn] = useState<Code[]>([]);
  const [recvUserIds, setRecvUserIds] = useState<string>('');
  const [hasAuth, setHasAuth] = useState<boolean>(false);
  const [isReadOnly, setReadOnly] = useState<boolean>(false);

  const { userId } = useSessionStore();

  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();

  const handleClose = () => {
    close();
  };

  useEffect(() => {
    initComboBoxCondition();
    if (hasRole('ADM')) {
      setHasAuth(true);
    }
  }, []);

  const initComboBoxCondition = async () => {
    //주기유형
    const prdTpCdCode = await getCommonCodeNames('PRD_TP_CD');
    setPrdTpCdCode(prdTpCdCode);
    //발송주기
    const prdNvlCodeDataMap = await getCommonCodeNames('PRD_TP_VALUE');
    setPrdNvlCodeDataMa(prdNvlCodeDataMap);
    //사용여부
    const getUseYn: Code[] = await getCommonCodeNames('YN_FLAG');
    setUseYn(getUseYn);
    //관심항목
    const myBookmarkList = await findBookmark(condition.bmkAtcTpCd);
    const myBookmarkCode: Code[] = [];
    for (let i = 0; i < myBookmarkList.length; i++) {
      myBookmarkCode[i] = {
        cmnCd: myBookmarkList[i].bmkGrId,
        cmnCdNm: myBookmarkList[i].bmkGrDesc,
      };
    }
    setMyBookmark(myBookmarkCode);
  };

  // const handlePrdTpCdCode = (value) => {
  //   setSearchCondition({
  //     ...searchCondition,
  //     prdTpCd: value,
  //   });
  //   getPrdNvlCode(value);
  // };

  const handleConditionChange = (name, value) => {
    setSearchCondition({ ...searchCondition, [name]: value });
    if (name === 'prdTpCd') {
      getPrdNvlCode(value);
    }
  };

  const handleConditionChange2 = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const name = e.target.name;
    setSearchCondition({ ...searchCondition, [name.replace('Nm', 'Id')]: '' });
    setDisplayCondition({
      ...displayCondition,
      [name]: e.target.value,
    });
  };

  const getPrdNvlCode = async (value) => {
    if (value == '') {
      setPrdNvlCode([]);
    } else {
      const prdNvlCode = await getCommonCodeNamesCondition({
        optValCtn1: value,
        cmnGrCd: 'PRD_TP_VALUE',
      });
      setPrdNvlCode(prdNvlCode);
    }
  };

  const beginningEdit = useEvent((s, e) => {
    const col = s.columns[e.col];
    const row = s.rows[e.row];
    const prdTpCd = s.rows[e.row].dataItem.prdTpCd;

    const code = prdNvlCodeDataMap.filter((code) => {
      return code.optValCtn1 === prdTpCd;
    });

    const prdNvlCode = new DataMap(code, 'cmnCd', 'cmnCdNm');
    if (col.binding == 'recvUserId') {
      e.cancel = true;
    }
    if (
      col.binding === 'prdTpCd' &&
      (row.dataItem.crudKey === CrudCode.CREATE || row.dataItem.crudKey === CrudCode.UPDATE)
    ) {
      if (
        row.dataItem.prdNvl !== null &&
        row.dataItem.prdNvl !== undefined &&
        row.dataItem.prdNvl !== ''
      ) {
        row.dataItem.prdNvl = '';
      }
    }
    if (col.binding === 'prdTpCd') {
      row.dataItem.prdNvl = '';
    }
    if (
      col.binding === 'prdNvl' &&
      (row.dataItem.crudKey === CrudCode.CREATE || row.dataItem.crudKey === CrudCode.UPDATE)
    ) {
      col.dataMap = prdNvlCode;
    }
  });

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    const column = panel.columns[col];
    const binding = column.binding || '';

    if (rowData.length > 0) {
      panel.rows.forEach((r) => {
        if (r.dataItem !== null) {
          if (r.dataItem.dataUpdUserId === userId || r.dataItem.crudKey === CrudCode.CREATE) {
            r.isReadOnly = false;
          } else {
            r.isReadOnly = true;
          }
        }
      });
    }
  });

  const onInitialized = (grid) => {
    new Selector(grid);
    setflexRef(grid);

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      // // row 단일 선택 + 체크박스 체크
      grid.rows.forEach((r) => {
        if (r.isSelected === true) {
          r.isSelected = false;
        }
      });

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        setflexItem(item);
        setHitTest(ht);
        setFieldId(col.binding);
        switch (e.target.id) {
          case 'btnRecvUserId':
            if (item.dataUpdUserId === userId || item.crudKey === CrudCode.CREATE) {
              setMode('btnRecvUserId');
              setRecvUserIds(item.recvUserId);
              setOpenUserMultiModal(true);
            } else {
              setRecvUserIds(item.recvUserId);
              setOpenUserMultiReadModal(true);
            }

            break;
        }
      }
    });
  };

  const handleSearch = () => {
    getAutoSendEmailList(searchCondition).then((result) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const handleReload = () => {
    setSearchCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as AutoSendEmailSearchCondition
    );
    setDisplayCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as DisplayCondition
    );
  };

  const handleSave = () => {
    const validStatusCorU = rowData.filter(
      (element) => element.crudKey == CrudCode.CREATE || element.crudKey == CrudCode.UPDATE
    );
    const valid = validStatusCorU
      .map((rowNode) => {
        if (rowNode.crudKey !== CrudCode.DELETE) {
          if (_.isEmpty(rowNode.emlTpNm))
            return `${t('com.msg.항목을 입력해 주세요.', '항목을 입력해 주세요.')}`;
          if (_.isEmpty(rowNode.prdTpCd))
            return `${t('com.msg.주기유형을 입력해 주세요.', '주기유형을 입력해 주세요.')}`;
          if (_.isEmpty(rowNode.prdNvl))
            return `${t('com.msg.발송주기를 입력해 주세요.', '발송주기를 입력해 주세요.')}`;
          if (_.isEmpty(rowNode.emlTgtCtn))
            return `${t('com.msg.관심항목을 입력해 주세요.', '관심항목을 입력해 주세요.')}`;
          if (_.isEmpty(rowNode.recvUserId))
            return `${t('com.msg.수신자를 입력해 주세요.', '수신자를 입력해 주세요.')}`;
          if (_.isUndefined(rowNode.vldStDt))
            return `${t('com.msg.유효시작일자를 입력해 주세요.', '유효시작일자를 입력해 주세요.')}`;
          if (_.isUndefined(rowNode.vldEndDt))
            return `${t('com.msg.유효종료일자를 입력해 주세요.', '유효종료일자를 입력해 주세요.')}`;
        }
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({
        type: 'error',
        content: content || '',
      });
      return false;
    }

    const saveData = rowData.filter((element) => element.crudKey !== null);

    if (_.isEmpty(rowData) || saveData.length === 0) {
      openMessageBar({
        type: 'warning',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t(`com.msg.저장하시겠습니까?`, `저장하시겠습니까?`),
      yesCallback: async () => {
        const saveRows = rowData
          .map((rowNode) => {
            return rowNode.crudKey ? rowNode : null;
          })
          .filter((element) => element !== null) as AutoSendEmail[];
        saveAutoSendEmailInfo(saveRows).then((result) => {
          openMessageBar({
            type: result?.successOrNot === 'Y' ? 'confirm' : 'error',
            content:
              result?.successOrNot === 'Y'
                ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          handleSearch();
        });
      },
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const callbackUserData = (callbackData) => {
    if (mode === 'dataUpdUserId') {
      setSearchCondition({
        ...searchCondition,
        dataUpdUserId: callbackData.userId,
      });
      setDisplayCondition({
        ...displayCondition,
        dataUpdUserNm: callbackData.empNm,
      });
    }
    if (mode === 'recvUserId') {
      setSearchCondition({
        ...searchCondition,
        recvUserId: callbackData.userId,
      });
      setDisplayCondition({
        ...displayCondition,
        recvUserNm: callbackData.empNm,
      });
    }

    if (mode === 'btnRecvUserId') {
      flexItem['recvUserId'] = callbackData.userId;
      flexItem['recvUserNm'] = callbackData.empNm;
      if (flexItem['crudKey'] !== CrudCode.CREATE && flexItem['crudKey'] !== CrudCode.DELETE) {
        flexItem['crudKey'] = CrudCode.UPDATE;
      }
    }
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'mail';
    book.saveAsync(getExcelFileName(t('gtng.label.자동메일발송현황', '자동메일발송현황')));
  };

  const btnAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      emlWktDivsCd: condition.bizName,
      useYn: 'Y',
    } as AutoSendEmail;
    setRowData([newRow, ...rowData]);
  };

  const btnDelRow = () => {
    if (flexRef === null) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.항목을 선택해 주세요.', '항목을 선택해 주세요.'),
      });
      return false;
    }

    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.항목을 선택해 주세요.', '항목을 선택해 주세요.'),
      });
      return false;
    } else {
      const selectedIds = selectedRowNodes
        .map((rowNode) => {
          return parseInt(rowNode._idx!);
        })
        .reverse();

      selectedIds.forEach((idx) => {
        if (rowData[idx].dataUpdUserId !== userId) {
          openMessageBar({
            type: 'error',
            content: t('com.msg.삭제할 수 없습니다.', '삭제할 수 없습니다.'),
          });
          return false;
        }
        if (rowData[idx].crudKey === CrudCode.CREATE) {
          delete rowData[idx];
        } else {
          rowData[idx].crudKey = CrudCode.DELETE;
        }
      });

      const filteredData = rowData.filter((element) => element !== undefined);
      setRowData(filteredData);
    }
  };

  const prdTpCdCodeMap = new DataMap(prdTpCdCode, 'cmnCd', 'cmnCdNm');
  const prdNvlCodeMap = new DataMap(prdNvlCodeDataMap, 'cmnCd', 'cmnCdNm');
  const myBookMarkMap = new DataMap(myBookMark, 'cmnCd', 'cmnCdNm');
  const useYn = new DataMap(getUseYn, 'cmnCd', 'cmnCdNm');

  const LayoutDefinition = () => {
    return [
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 50,
        cellTemplate: ({ value }) =>
          value
            ? `<span class="${
                GridStatusCellRenderer({ data: { crudKey: value } })?.props.className
              }"></span>`
            : '',
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'emlTpId',
        visible: false,
      },
      {
        binding: 'emlWktDivsCd',
        visible: false,
      },
      {
        binding: 'emlTpNm',
        header: String(t('com.label.항목', '항목')),
        width: colWidthMap.emlTpNm,
        align: 'left',
      },
      {
        binding: 'prdTpCd',
        header: String(t('com.label.주기유형', '주기유형')),
        width: colWidthMap.prdTpCd,
        align: 'center',
        cssClass: 'WijmoSelect',
        dataMap: prdTpCdCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey !== CrudCode.CREATE) {
            return `<span>${prdTpCdCodeMap.getDisplayValue(item.value)}</span>`;
          } else {
            return `<span>${item.text}</span>`;
          }
        },
      },
      {
        binding: 'prdNvl',
        header: String(t('com.label.발송주기', '발송주기')),
        width: colWidthMap.prdNvl,
        align: 'center',
        cssClass: 'WijmoSelect',
        dataMap: prdNvlCodeMap,
        cellTemplate: (item) => {
          if (prdNvlCodeMap.getDisplayValue(item.value) == undefined) {
            return `<span>${item.text}</span>`;
          } else {
            return `<span>${prdNvlCodeMap.getDisplayValue(item.value)}</span>`;
          }
        },
      },
      {
        binding: 'emlTgtCtn',
        header: String(t('com.label.관심항목', '관심항목')),
        width: colWidthMap.emlTgtCtn,
        cssClass: 'WijmoSelect',
        dataMap: myBookMarkMap,
        align: 'left',
        cellTemplate: (item) => {
          if (item.item.dataUpdUserId === userId || item.item.crudKey === CrudCode.CREATE) {
            return `<span>${item.text}</span>`;
          } else {
            return `<span>${item.item.emlTgtCtnNm}</span>`;
          }
        },
      },
      {
        binding: 'emlTgtCtnNm',
        visible: false,
      },
      {
        binding: 'recvUserId',
        header: String(t('com.label.수신자', '수신자')),
        width: colWidthMap.recvUserId,
        align: 'left',
        cssClass: 'WijmoFind',
        cellTemplate: (item) => {
          if (item.item.crudKey == CrudCode.CREATE) {
            if (item.item.recvUserId === undefined) {
              return `<span>${item.text}</span><button id="btnRecvUserId" />`;
            } else {
              return `<span>${item.item.recvUserNm}</span><button id="btnRecvUserId" />`;
            }
          } else {
            return `<span>${item.item.recvUserNm}</span><button id="btnRecvUserId" />`;
          }
        },
      },
      {
        binding: 'recvUserNm',
        visible: false,
      },
      {
        binding: 'vldStDt',
        header: String(t('com.label.유효시작일자', '유효시작일자')),
        width: colWidthMap.vldStDt,
        align: 'center',
        cssClass: 'WijmoDate',
        format: 'yyyy.MM.dd',
        editor: new input.InputDate(document.createElement('div'), {
          format: 'yyyy.MM.dd',
          min: new Date(),
        }),
      },
      {
        binding: 'vldEndDt',
        header: String(t('com.label.유효종료일자', '유효종료일자')),
        width: colWidthMap.vldEndDt,
        align: 'center',
        cssClass: 'WijmoDate',
        format: 'yyyy.MM.dd',
        editor: new input.InputDate(document.createElement('div'), {
          format: 'yyyy.MM.dd',
          min: new Date(),
        }),
      },
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        width: colWidthMap.useYn,
        align: 'center',
        dataMap: useYn,
      },
      {
        binding: 'dataUpdUserId',
        visible: false,
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('com.label.수정자', '수정자')),
        isReadOnly: true,
        width: colWidthMap.dataUpdUserNm,
        align: 'center',
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('com.label.수정일자', '수정일자')),
        isReadOnly: true,
        width: colWidthMap.dataUpdDtm,
        align: 'center',
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('com.label.자동메일발송', '자동메일발송')}
      size="xl"
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{t('com.label.항목', '항목')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="emlTpNm"
                  placeholder={String(
                    t('com.label.항목을 입력해 주세요.', '항목을 입력해 주세요.')
                  )}
                  value={searchCondition?.emlTpNm}
                  onChange={(e) => handleConditionChange('emlTpNm', e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.주기유형', '주기유형')}</label>
                <ComboBox
                  options={prdTpCdCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchCondition.prdTpCd}
                  onChange={(value) => handleConditionChange('prdTpCd', value)}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.발송주기', '발송주기')}</label>
                <ComboBox
                  options={prdNvlCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchCondition.prdNvl}
                  onChange={(value) => handleConditionChange('prdNvl', value)}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.수정자', '수정자')}</label>
                <CustomInputWithSearch
                  name="dataUpdUserNm"
                  className="find"
                  placeholder={String(
                    t('mp.msg.수정자를 선택해 주세요.', '수정자를 선택해 주세요.')
                  )}
                  value={displayCondition?.dataUpdUserNm}
                  onChange={handleConditionChange2}
                  onSearchClick={() => {
                    setMode('dataUpdUserId');
                    setOpenUserMultiModal(true);
                    setRecvUserIds('');
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{t('com.label.관심항목', '관심항목')}</label>
                <ComboBox
                  options={myBookMark}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchCondition?.emlTgtCtn}
                  onChange={(value) => handleConditionChange('emlTgtCtn', value)}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.수신자', '수신자')}</label>
                <CustomInputWithSearch
                  name="recvUserNm"
                  className="find"
                  placeholder={String(
                    t('mp.msg.수신자를 선택해 주세요.', '수신자를 선택해 주세요.')
                  )}
                  value={displayCondition?.recvUserNm}
                  onChange={handleConditionChange2}
                  onSearchClick={() => {
                    setMode('recvUserId');
                    setOpenUserMultiModal(true);
                    setRecvUserIds('');
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.수정일자', '수정일자')}</label>
                {/* <CustomDatepicker
                  isRange={true} // 기간선택인 경우 생략가능
                  value={{
                    startDate: searchCondition?.startDate || null,
                    endDate: searchCondition?.endDate || null,
                  }}
                  onChange={(newValue) => {
                    setSearchCondition((prev) => ({
                      ...prev,
                      startDate:
                        ((newValue as DateRangeType)?.startDate as string) || prev?.startDate,
                      endDate: ((newValue as DateRangeType)?.endDate as string) || prev?.endDate,
                    }));
                  }}
                /> */}
                <DatePickerWrap>
                  <Datepicker
                    inputClassName="datepickerInput"
                    useRange={true}
                    placeholder="YYYY.MM.DD - YYYY.MM.DD"
                    separator="-"
                    displayFormat={'YYYY.MM.DD'}
                    i18n={'ko'}
                    value={{
                      startDate: searchCondition?.startDate || null,
                      endDate: searchCondition?.endDate || null,
                    }}
                    onChange={(value) => {
                      setSearchCondition({
                        ...searchCondition,
                        startDate: String(value?.startDate || ''),
                        endDate: String(value?.endDate || ''),
                      });
                    }}
                  />
                </DatePickerWrap>
              </SearchCols>
              <SearchCols></SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.자동메일발송', '자동메일발송')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="addRow" onClick={btnAddRow} disableRipple>
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button css={IconButton.button} className="delRow" onClick={btnDelRow} disableRipple>
            {t('com.button.행삭제', '행삭제')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={state.layoutDefinition}
        rowData={state.itemsSource}
        alternatingRowStep={0}
        selectionMode={SelectionMode.Row}
        align="center"
        initialized={onInitialized}
        beginningEdit={beginningEdit}
        height={400}
        itemFormatter={onItemFormatter}
      />
      {/* <ContentGrid
          className={(rowData || []).length < 1 ? 'noData' : ''}
          style={{ height: '450px', marginBottom: rowData.length < 1 ? 0 : '32px' }}
        >
          <FlexGrid
            columnGroups={state.layoutDefinition}
            itemsSource={state.itemsSource}
            deferResizing={false}
            selectionMode={SelectionMode.Row}
            stickyHeaders={true}
            formatItem={flexGridTooltip}
            initialized={onInitialized}
            beginningEdit={beginningEdit}
            style={{ height: (rowData || []).length < 1 ? '' : '450px' }}
          />
          <div className="noData" style={{ height: (rowData || []).length < 1 ? '450px' : '' }}>
            <span>조회 가능한 데이터가 없습니다.</span>
          </div>
        </ContentGrid> */}
      {/* <PopupBtnGroup>
        <Button css={IconButton.button} className="cancel" onClick={handleClose} disableRipple>
          {t('com.button.취소', '취소')}
        </Button>
        <Button css={IconButton.button} className="save" disableRipple onClick={handleSave}>
          {t('com.button.저장', '저장')}
        </Button>
      </PopupBtnGroup> */}
      {isOpenUserMultiModal && (
        <UserMultiModal
          open={isOpenUserMultiModal}
          close={() => setOpenUserMultiModal(false)}
          title={t('com.label.사용자 조회', '사용자 조회')}
          mode={mode}
          defaultUserId={recvUserIds}
          singleSelect={false}
          onCallback={(callbackData) => callbackUserData(callbackData)}
        />
        // <UserModal
        //   open={isOpenUserMultiModal}
        //   close={() => setOpenUserMultiModal(false)}
        //   title={t('com.label.사용자 조회', '사용자 조회')}
        //   defaultUserId={recvUserIds}
        //   singleSelect={false}
        //   onCallback={(callbackData) => callbackUserData(callbackData)}
        // />
      )}
      {isOpenUserMultiReadModal && (
        <GatingContListPopUp
          open={isOpenUserMultiReadModal}
          close={() => setOpenUserMultiReadModal(false)}
          title={t('com.label.수신자', '수신자')}
          initParam={recvUserIds}
        />
        // <UserModal
        //   open={isOpenUserMultiModal}
        //   close={() => setOpenUserMultiModal(false)}
        //   title={t('com.label.사용자 조회', '사용자 조회')}
        //   defaultUserId={recvUserIds}
        //   singleSelect={false}
        //   onCallback={(callbackData) => callbackUserData(callbackData)}
        // />
      )}
    </CustomDialog>
  );
};

export default AutoSendEmailModal;
