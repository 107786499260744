import React from 'react';
import { CrudCode } from 'models/common/Edit';

/**
 * [AgGrid] 그리드 상태 셀 템플릿 (crudKey)
 * TODO 위즈모 변환 완료 후 삭제 예정
 * @param props
 * @constructor
 */
export const GridStatusCellRenderer = (props) => {
  const customTag = () => {
    if (props.data.crudKey === CrudCode.DELETE && !props.data.isUpdated) {
      return <span className="cellDelete"></span>;
    } else if (props.data.crudKey === CrudCode.UPDATE) {
      return <span className="cellModify"></span>;
    } else if (props.data.crudKey === CrudCode.CREATE) {
      return <span className="cellAdd"></span>;
    } else {
      return;
    }
  };
  return customTag();
};

/**
 * [Wijmo] 그리드 상태 셀 템플릿 (crudKey)
 * @param props
 * @constructor
 */
export const GridStatusCellTemplate = (props) => {
  const customTag = () => {
    if (props.item.crudKey === CrudCode.DELETE) {
      return '<span class="cellDelete"></span>';
    } else if (props.item.crudKey === CrudCode.UPDATE) {
      return '<span class="cellModify"></span>';
    } else if (props.item.crudKey === CrudCode.CREATE) {
      return '<span class="cellAdd"></span>';
    } else {
      return '';
    }
  };
  return customTag();
};
