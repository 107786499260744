/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

import { BlueButton, GreyLineButton } from 'components/buttons/CustomButton';
import { CustomTextarea } from 'components/inputs/CustomInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { ContentSection } from 'components/layouts/ContentSection';
import { TitleWrap, di } from 'components/layouts/Dialog';
import { Button, Typography } from '@mui/material';
import { useCommonModal } from 'hooks/useCommonModal';
import { FontColor } from 'ui/theme/Color';
import { useTranslation } from 'react-i18next';
import { GlobalBtnGroup } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomDialog from 'components/modals/common/CustomDialog';

type Props = {
  open: boolean;
  title: string;
  close: () => void;
  rejectCallback: (item: string) => void;
};

const ExcpUserRejectPopUp = ({ open, close, title, rejectCallback }: Props) => {
  const [textAreaData, setTextAreaData] = useState<string>('');
  const [textAreaByte, setTextAreaByte] = useState<number>(0);

  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();

  const handleClose = () => {
    setTextAreaData('');
    setTextAreaByte(0);
    close;
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    b?: number,
    i?: number,
    c?: number
  ) => {
    const s = e.target.value;
    for (b = 0, i = 0; (c = s.charCodeAt(i++)) && b < 2000; b += c >> 11 ? 3 : c >> 7 ? 2 : 1);
    setTextAreaByte(b);
    setTextAreaData(s.slice(0, i - 1));
  };

  const handleOnReject = (e: React.MouseEvent) => {
    if (textAreaByte > 2000)
      return openMessageBar({
        type: 'error',
        content: t(
          'com.button.최대 입력 Bytes 를 초과하였습니다. (2000Bytes)',
          '최대 입력 Bytes 를 초과하였습니다. (2000Bytes)'
        ),
      });
    if (textAreaData === '')
      return openMessageBar({
        type: 'error',
        content: t('com.button.반려 사유를 입력해 주세요.', '반려 사유를 입력해 주세요.'),
      });

    openCommonModal({
      modalType: 'confirm',
      content: t(
        'admin.msg.반려 처리시 사용자 데이터는 삭제 처리 됩니다.\n 반려 처리 하시겠습니까?',
        '반려 처리시 사용자 데이터는 삭제 처리 됩니다.\n 반려 처리 하시겠습니까?'
      ),
      yesCallback: () => {
        rejectCallback(textAreaData);
        close();
        setTextAreaData('');
        setTextAreaByte(0);
      },
    });
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleOnReject}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={t('com.label.반려사유', '반려사유')}
      size="sm"
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      buttons={dialogButtons}
    >
      <ContentSection className="marginT0">
        <CustomTextarea
          className="height200"
          value={textAreaData}
          onChange={handleOnChange}
        ></CustomTextarea>
        <Typography fontSize="13px" color={FontColor.Default} fontWeight={400} align="right">
          {textAreaByte}/2000 Bytes
        </Typography>
      </ContentSection>
    </CustomDialog>
  );
};

export default ExcpUserRejectPopUp;
