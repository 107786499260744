export const convertNull = (obj: object) => {
  for (const prop in obj) {
    if (obj[prop] === null) {
      obj[prop] = '';
    }
  }
  return obj;
};

export const formatBytes = (bytes = 0, decimals = 2) => {
  if (bytes === 0) return '0KB'; // 0 Bytes

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};

export const fileExtension = (fileName) => {
  const ext = fileName.match(/\.([^.]+)$/)?.[1];

  let r = 'docu';
  if (ext === 'xls' || ext === 'xlsx') {
    r = 'excel';
  } else if (ext === 'doc' || ext === 'docx') {
    r = 'word';
  } else if (ext === 'png' || ext === 'jpg' || ext === 'bmp' || ext === 'gif') {
    r = 'img';
  } else if (ext === 'ppt' || ext === 'pptx') {
    r = 'ppt';
  } else if (ext === 'html' || ext === 'js') {
    r = 'mark';
  } else if (ext === 'zip') {
    r = 'zip';
  }
  return r;
};

/**
 * string -> json 변환
 * @param str
 */
export const convertJson = (str?: string) => {
  if (str) {
    try {
      // TODO CommonUtil unescapeHtml 사용 예정
      const o = JSON.parse(str.replaceAll('&quot;', '"'));
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      return str;
    }
  }
  return str;
};
