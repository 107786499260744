/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import { GuideBlock } from '../GuideComponent';
import { useMessageBar } from 'hooks/useMessageBar';

const MessageBarGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const { openMessageBar } = useMessageBar();

  return (
    <>
      <GuideBlock title={'useMessageBar Import'} isCode={true}>
        {`import { useMessageBar } from 'hooks/useMessageBar';`}
      </GuideBlock>
      <GuideBlock title={'useMessageBar Function'} isCode={true}>
        {`const { openMessageBar } = useMessageBar();`}
      </GuideBlock>
      <GuideBlock title={'MessageBar 속성'} isCode={true}>
        {`
// message bar 유형
export type MessageBarType = 'default' | 'confirm' | 'warning' | 'error';

export interface MessageBarInitialState {
  type?: MessageBarType; // message bar 유형 (default: default)
  content: string; // 내용
}
        `}
      </GuideBlock>
      <Alert severity="info">
        Message bar는 사용자에게 일시적으로 알려야 하는 Message가 있을 때 사용되며, 주로 사용자의
        Action에 피드백을 주기 위해 사용됩니다.
        <br />
        Message bar는 사용자를 방해하지 않는 선에서 제공되어야 하기 때문에 4초간 노출되었다가
        자동으로 사라지도록 합니다.
        <br />
        cf. UX 가이드 Pattern {'>'} Common UI Pattern {'>'} Feedback
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>MessageBar - Default</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <IconButton
                aria-label="play"
                color="success"
                onClick={() => {
                  openMessageBar({
                    content: 'default message bar',
                  });
                }}
              >
                <PlayCircleOutlinedIcon />
              </IconButton>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
openMessageBar({
  content: 'default message bar',
});
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>MessageBar - Error</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <IconButton
                aria-label="play"
                color="success"
                onClick={() => {
                  openMessageBar({
                    type: 'error',
                    content: 'error message bar',
                  });
                }}
              >
                <PlayCircleOutlinedIcon />
              </IconButton>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
openMessageBar({
  type: 'error',
  content: 'error message bar',
});
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary>MessageBar - Warning</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <IconButton
                aria-label="play"
                color="success"
                onClick={() => {
                  openMessageBar({
                    type: 'warning',
                    content: 'warning message bar',
                  });
                }}
              >
                <PlayCircleOutlinedIcon />
              </IconButton>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
openMessageBar({
  type: 'warning',
  content: 'warning message bar',
});
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary>MessageBar - Confirm</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <IconButton
                aria-label="play"
                color="success"
                onClick={() => {
                  openMessageBar({
                    type: 'confirm',
                    content: 'confirm message bar',
                  });
                }}
              >
                <PlayCircleOutlinedIcon />
              </IconButton>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
openMessageBar({
  type: 'confirm',
  content: 'confirm message bar',
});
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default MessageBarGuide;
