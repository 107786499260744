/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import { GuideBlock } from '../GuideComponent';
import { useCommonModal } from 'hooks/useCommonModal';

const ConfirmAlertGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const { openCommonModal } = useCommonModal();

  return (
    <>
      <GuideBlock title={'useCommonModal Import'} isCode={true}>
        {`import { useCommonModal } from 'hooks/useCommonModal';`}
      </GuideBlock>
      <GuideBlock title={'useCommonModal Function'} isCode={true}>
        {`const { openCommonModal } = useCommonModal();`}
      </GuideBlock>
      <GuideBlock title={'CommonModal 속성'} isCode={true}>
        {`
// modal 유형
export type CommonModalType = 'confirm' | 'alert';

export interface CommonModalInitialState {
  modalType?: CommonModalType; // modal 유형  (default: alert)
  title?: string | DefaultTFuncReturn; // 미사용
  content: string; // 내용
  yesCallback?: () => any; // 확인 callback 이벤트
  noCallback?: () => any; // 취소 callback 이벤트
  showCallbackResult?: boolean; // 결과 모달 노출여부 (default: false)
}
        `}
      </GuideBlock>
      <Alert severity="info">
        CommonModal 속성 중 title은 UX가이드 내 confirm/alert의 title이 정해져 있어서 현재 미사용 중
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>Alert</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <IconButton
                aria-label="play"
                color="success"
                onClick={() => {
                  openCommonModal({
                    // modalType: 'alert' // modal 유형 생략가능 (default: alert)
                    content: 'alert content',
                  });
                }}
              >
                <PlayCircleOutlinedIcon />
              </IconButton>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
openCommonModal({
  // modalType: 'alert' // modal 유형 생략가능 (default: alert)
  content: 'alert content', // 내용
});
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>Confirm</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <IconButton
                aria-label="play"
                color="success"
                onClick={() => {
                  openCommonModal({
                    modalType: 'confirm', // modal 유형 (default: alert)
                    content: 'confirm content', // 내용
                    yesCallback: () => {
                      openCommonModal({ content: 'confirm yes callback' });
                    },
                    noCallback: () => {
                      openCommonModal({ content: 'confirm no callback' });
                    },
                  });
                }}
              >
                <PlayCircleOutlinedIcon />
              </IconButton>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
openCommonModal({
  modalType: 'confirm', // modal 유형 (default: alert)
  content: 'confirm content', // 내용
  yesCallback: () => { // 확인 callback 이벤트
    openCommonModal({ content: 'confirm yes callback' });
  }, 
  noCallback: () => { // 취소 callback 이벤트
    openCommonModal({ content: 'confirm no callback' });
  },
});
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary>Confirm - 결과모달 노출</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <IconButton
                aria-label="play"
                color="success"
                onClick={() => {
                  openCommonModal({
                    modalType: 'confirm', // modal 유형 (default: alert)
                    content: '확인을 누르면, 결과 모달이 발생합니다.', // 내용
                    showCallbackResult: true, // 결과모달 노출여부 (default: false)
                    yesCallback: () => {
                      return 'confirm yes callback';
                    },
                    noCallback: () => {
                      // return 값이 없는 경우 Alert Modal 미노출
                      console.log('confirm no callback');
                    },
                  });
                }}
              >
                <PlayCircleOutlinedIcon />
              </IconButton>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
openCommonModal({
  modalType: 'confirm', // modal 유형 (default: alert)
  content: '확인을 누르면, 결과 모달이 발생합니다.', // 내용
  showCallbackResult: true, // 결과모달 노출여부 (default: false)
  yesCallback: () => {
    return 'confirm yes callback';
  },
  noCallback: () => {
    // return 값이 없는 경우 Alert Modal 미노출
    console.log('confirm no callback');
  },
});
              `}
            </GuideBlock>
            <Alert severity="info">
              showCallbackResult: true 설정을 하게 되면 callback의 결과값이 Alert Modal로
              노출됩니다.
              <br />
              return 값이 없는 경우 Alert Modal 미노출
              <br />
            </Alert>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default ConfirmAlertGuide;
