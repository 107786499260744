import {
  MpTargetCondition,
  MpTarget,
  MpTargetUser,
  MpTargetLineCondition,
} from 'models/mp/MpTarget';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { downloadFile } from 'apis/common/Excel';

/**
 * MP 대상/담당자 조회
 * @param condition
 */
export const findMpTarget = async (condition?: MpTargetCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/target`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<MpTarget[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MpTarget[];
};

/**
 * 중복된 MP 대상 조회
 * @param rowData
 */
export const findExistsMpTarget = async (rowData: any[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/exists-target`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { mpTargetRequestList: rowData },
  };
  const response: CommonResponse<any[]> = await callApi(request);

  return response as CommonResponse<any[]>;
};

export const setMpTarget = async (rowData: any[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/target`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { mpTargetRequestList: rowData },
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return response as CommonResponse<DmlResponse>;
};

export const setMpTargetLine = async (rowData: any[], condition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/target/line`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { ...condition, mpManager: rowData },
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return response as CommonResponse<DmlResponse>;
};

export const setMpTargetLineMgr = async (condition: MpTargetLineCondition) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/target/line/mgr`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return response as CommonResponse<DmlResponse>;
};

/**
 * MP 대상 Line 조회
 * @param condition
 */
export const findMpTargetLine = async (condition?: MpTargetCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/target/line`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<MpTarget[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MpTarget[];
};

/**
 * 점검 담당자 목록 조회
 * @param condition
 */
export const getMpTargetUserList = async (value) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/target/user`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ id: value }),
  };
  const response: CommonResponse<MpTargetUser[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MpTargetUser[];
};

/**
 * MP 대상 공장 조회
 * @param condition
 */
export const findMpTargetFactory = async (condition?: MpTargetCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/target/factory`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<MpTarget[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MpTarget[];
};

/**
 * MP 대상 설비군 조회
 * @param condition
 */
export const findMpTargetEquipment = async (condition?: MpTargetCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/target/equipment`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<MpTarget[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : []) as MpTarget[];
};

/**
 * 검토 대상 목록 엑셀 다운로드
 * @param condition
 */
export const downloadExcelResult = async (condition: ExcelDownloadRequest<MpTargetCondition>) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/target/excel-download`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, `${condition.fileName}`);
};
