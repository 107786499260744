/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CommonBorder, NavTop } from 'ui/theme/Color';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';
import React, { useContext, useEffect, useState } from 'react';
import useSessionStore from 'stores/useSessionStore';
import { NoticePostDetail } from '../../../../models/admin/Notice';
import { findNoticePopupPost } from '../../../../apis/admin/Notice';
import { NoticeModal } from '../../../../pages/admin/noticemanagement/NoticeModal';
import { useTranslation } from 'react-i18next';

export const Logo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { userId } = useSessionStore();
  const [noticePopups, setNoticePopups] = useState<NoticePostDetail[]>([]);

  useEffect(() => {
    if (userId.length > 0) {
      (async () => {
        const noticePopupPosts = await findNoticePopupPost();
        noticePopupPosts && setNoticePopups(noticePopupPosts);
      })();
    }
  }, []);

  const handleMenuBar = (e) => {
    e.preventDefault();
    if ((userId && pathname !== '/dev/login') || pathname == '/system/user-regist') {
      (async () => {
        const noticePopupPosts = await findNoticePopupPost();
        noticePopupPosts && setNoticePopups(noticePopupPosts);
      })();
      menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '', tabIndex: 0 });
      navigate('');
    }
  };
  return (
    <div css={st.root}>
      <Link onClick={handleMenuBar} to={''} css={st.logo}>
        <h1 className="abb">ELM</h1>
        <span className="full">
          <span className="ko">
            {t('com.label.설비 라이프사이클 관리시스템', '설비 라이프사이클 관리시스템')}
          </span>
          <span className="en">
            {t('com.label.Equipment Lifecycle Management', 'Equipment Lifecycle Management')}
          </span>
        </span>
      </Link>
      {noticePopups.map((notice) => (
        <NoticeModal
          key={notice.bbmNo}
          bbmNo={notice.bbmNo}
          bbmTitNm={notice.bbmTitNm}
          bbmCtn={notice.bbmCtn}
          poupEpsNuseDdn={Number(notice.poupEpsNuseDdn)}
        />
      ))}
    </div>
  );
};

const st = {
  root: css`
    height: 52px;
    display: flex;
  `,
  logo: css`
    display: flex;
    color: #135678;
    margin: 0 12px;
    height: 100%;
    flex: 0 0 20rem;
    align-items: center;
    justify-content: start;
    font-family: 'Spoqa Han Sans Neo';

    & .abb {
      padding-right: 12px;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.5;
      color: ${NavTop.TextSysNamePrimary};
      border-right: 1px solid ${CommonBorder.Strong};
    }

    & .full {
      display: inline-flex;
      align-items: flex-start;
      flex-direction: column;
      margin-left: 12px;
      & span {
        display: inline-block;
        &.ko {
          font-size: 14px;
          color: ${NavTop.TextSysNamePrimary};
          font-weight: 400;
          line-height: 1.25;
        }
        &.en {
          color: ${NavTop.TextSysNameBasic};
          font-size: 12px;
          font-weight: 300;
          line-height: 1.5;
        }
      }
    }
  `,
};
