/** @jsxImportSource @emotion/react */
import { AgGridReact } from 'ag-grid-react';
import { GreyButton } from 'components/buttons/CustomButton';
import { CustomInputText } from 'components/inputs/CustomInput';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { ContentSection, GridInfoSection, GridInfo } from 'components/layouts/ContentSection';
import { SearchBox, SearchRows, SearchButtons } from 'components/layouts/SearchBox';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import BasicDatePicker from 'components/inputs/DatePicker';
import { Pagination, PaginationRef } from 'components/layouts/Pagination';
import dayjs, { Dayjs } from 'dayjs';
import { PaginationResponse } from 'models/common/Pagination';
import { ColDef } from 'ag-grid-community';
import { MenuLog, MenuLogRequest } from 'models/admin/MenuLog';
import { findMenuLogs } from 'apis/admin/MenuLog';

const MenuLogManagementPage = () => {
  const { t } = useTranslation();
  const pageRef = useRef<PaginationRef>(null);
  const [MenuLogList, setMenuLogList] = useState<PaginationResponse<MenuLog>>({
    totalCount: 0,
    list: [],
  });

  const [ifDtmFr, setIfDtmFr] = useState<Dayjs | null>(dayjs().add(-1, 'month'));
  const [ifDtmTo, setIfDtmTo] = useState<Dayjs | null>(dayjs());

  const [searchCondition, setSearchConditon] = useState<MenuLogRequest>({
    contDtmFr: dayjs().add(-1, 'month').format('YYYYMMDD').toString(),
    contDtmTo: dayjs().format('YYYYMMDD').toString(),
    searchItem: '',
  });

  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    getMenuLogs();
  }, []);

  const getMenuLogs = async (search?: boolean, condition?: MenuLogRequest) => {
    const response = await findMenuLogs(condition ?? searchCondition);
    if (response) {
      setMenuLogList(response);
      if (search) {
        setPageNo(1);
        pageRef.current?.setSelectNo(1);
      }
    }
  };

  const handleSearch = () => {
    const condition: MenuLogRequest = { ...searchCondition, start: '0' };

    setSearchConditon(condition);
    getMenuLogs(true, condition);
  };

  const defaultColum: ColDef = {
    cellStyle: { textAlign: 'center' },
    resizable: true,
  };

  const handleChangeDate = (date: Dayjs, id: string) => {
    if (!date) return;
    const stringDate = date.format('YYYYMMDD').toString();
    if (id === 'ifDtmFr') {
      if (stringDate === 'Invalid Date') {
        setIfDtmFr(null);
        setSearchConditon({ ...searchCondition, contDtmFr: '' });
      } else {
        setIfDtmFr(date);
        setSearchConditon({ ...searchCondition, contDtmFr: stringDate });
      }
    } else {
      if (stringDate === 'Invalid Date') {
        setIfDtmTo(null);
        setSearchConditon({ ...searchCondition, contDtmTo: '' });
      } else {
        setIfDtmTo(date);
        setSearchConditon({ ...searchCondition, contDtmTo: stringDate });
      }
    }
  };
  const handleChangePageSize = (pageSize: string) => {
    const condition: MenuLogRequest = {
      ...searchCondition,
      pageSize: pageSize,
      start: '0',
    };
    setSearchConditon(condition);
    getMenuLogs(false, condition);
    setPageSize(parseInt(pageSize));
    pageRef.current?.setPageSize(parseInt(pageSize));
  };
  const handleClickPageNo = (pageNo: number) => {
    const condition: MenuLogRequest = {
      ...searchCondition,
      start: String(parseInt(searchCondition.pageSize ?? '10') * (pageNo - 1)),
    };
    setSearchConditon(condition);
    getMenuLogs(false, condition);
    pageRef.current?.setSelectNo(pageNo);
  };
  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: String(t('admin.grid.접근 일시', '접근 일시')),
      field: 'contDtm',
      width: 250,
    },
    {
      headerName: String(t('admin.grid.메뉴명', '메뉴명')),
      field: 'mnuNm',
      minWidth: 250,
      cellStyle: { textAlign: 'left' },
      flex: 1,
    },
    {
      headerName: String(t('com.label.사용자ID', '사용자ID')),
      field: 'contUserId',
      width: 200,
    },
    {
      headerName: String(t('com.label.사용자명', '사용자명')),
      field: 'empNm',
      width: 200,
    },
    {
      headerName: String(t('admin.grid.접속IP', '접속IP')),
      field: 'contIp',
      width: 200,
    },
  ]);

  return (
    <>
      <SearchBox>
        <SearchRows className="contentStart">
          <div className="searchCol">
            <label>{t('admin.label.접속일자', '접속일자')}</label>
            <BasicDatePicker
              date={ifDtmFr}
              changeDate={(date) => handleChangeDate(date, 'ifDtmFr')}
            />
            -
            <BasicDatePicker
              date={ifDtmTo}
              changeDate={(date) => handleChangeDate(date, 'ifDtmTo')}
            />
          </div>
          <div className="searchCol">
            <label>{t('com.label.사용자ID/명', '사용자ID/명')}</label>
            <CustomInputText
              onChange={(e) =>
                setSearchConditon({ ...searchCondition, searchItem: e.target.value })
              }
            ></CustomInputText>
          </div>
        </SearchRows>
        <SearchButtons className="searchButtons">
          <GreyButton className="buttonSrch" onClick={handleSearch}>
            <SearchIcon /> {t('com.button.조회', '조회')}
          </GreyButton>
        </SearchButtons>
      </SearchBox>
      <ContentSection>
        <GridInfoSection>
          <GridInfo>
            <span>{t('admin.label.메뉴접근 로그 목록', '메뉴접근 로그 목록')}</span>
            <span className="primary">
              {t('com.label.총', '총')} {MenuLogList.totalCount}
              {t('com.label.건', '건')}
            </span>
          </GridInfo>
        </GridInfoSection>
        <ContentGrid className="ag-theme-alpine" style={{ height: '450px' }}>
          <AgGridReact
            overlayNoRowsTemplate="No rows to show"
            rowData={MenuLogList.list}
            columnDefs={columnDefs}
            suppressPaginationPanel={true}
            defaultColDef={defaultColum}
          ></AgGridReact>
        </ContentGrid>
        <Pagination
          ref={pageRef}
          totalCount={MenuLogList.totalCount}
          onChangePageSize={handleChangePageSize}
          onClickPageNo={handleClickPageNo}
          defaultPageNo={pageNo}
          defaultPageSize={pageSize}
        />
      </ContentSection>
    </>
  );
};

export default MenuLogManagementPage;
