import {
  CommonRequest,
  Method,
  ServiceName,
  CommonResponse,
  DmlResponse,
} from 'models/common/RestApi';
import { UtMatrixMasterList } from 'models/ut/UtMatrixList';
import { UtMatrixSummaryList } from 'models/ut/UtMatrixSummary';
import { callApi } from 'utils/ApiUtil';

export const getUtMatrixSummaryList = async (utmIds: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getUtMatrixSummaryList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmIds: utmIds,
    }),
  };
  const response: CommonResponse<UtMatrixSummaryList[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixSummaryList[];
};

export const getMachineSummaryData = async (utmIds: string, searchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getMachineSummaryData`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmIds: utmIds,
      searchParam: searchParam?.totalStandard,
    }),
  };
  const response: CommonResponse<any> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as any;
};

export const getElectricSummaryData = async (utmIds: string, searchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getElectricSummaryData`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmIds: utmIds,
      searchParam: searchParam?.totalStandard,
    }),
  };
  const response: CommonResponse<any> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as any;
};

export const getDetailSummaryData = async (utmIds: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/getDetailSummaryData`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmIds: utmIds,
    }),
  };
  const response: CommonResponse<any> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as any;
};
