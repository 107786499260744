/** @jsxImportSource @emotion/react */
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useState } from 'react';
import { CrudCode } from 'models/common/Edit';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import 'ui/css/multiSelect.css';

import { GuideBlock } from 'pages/sample/guide/GuideComponent';

const SampleWijmo = () => {
  const [flexRef, setFlexRef] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();

  const [dataSelect, setDataSelect] = useState([
    { id: 1, name: '대한민국', desc: 'KOREA' },
    { id: 2, name: '미국', desc: 'USA' },
    { id: 3, name: '일본', desc: 'JAPAN' },
    { id: 4, name: '중국', desc: 'CHINA' },
    { id: 5, name: '에스토니아', desc: 'ESTONIA' },
  ]);
  const [rowData, setRowData] = useState([
    { crudKey: CrudCode.READ, id: 1, desc: '사용자 1', name: '박문수', address: '대한민국' },
    { crudKey: CrudCode.READ, id: 2, desc: '사용자 2', name: '홍길동', address: '미국' },
    { crudKey: CrudCode.READ, id: 3, desc: '사용자 3', name: '일지매', address: '일본' },
    { crudKey: CrudCode.READ, id: 4, desc: '사용자 4', name: '이몽룡', address: '중국' },
    { crudKey: CrudCode.READ, id: 5, desc: '사용자 5', name: '성춘향', address: '에스토니아' },
  ]);

  const LayoutDefinition = () => {
    const selectMap = new DataMap(dataSelect, 'name', 'name');
    return [
      {
        binding: 'id',
        align: 'left',
        width: 50,
        isReadOnly: true,
      },
      {
        header: '개인정보',
        align: 'center',
        columns: [
          {
            binding: 'name',
            header: '이름',
            width: 150,
            isReadOnly: true,
          },
          {
            binding: 'address',
            header: '주소',
            width: 150,
            dataMap: selectMap, //select box 데이터 바인딩
          },
        ],
      },
      {
        binding: 'desc',
        align: 'center',
        width: 150,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) return;
    const row = s.rows[e.row].dataItem;
    setSelectedRow(row);
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setFlexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //셀 수정 했을때...
    grid.cellEditEnding.addHandler((s, e) => {
      const col = s.columns[e.col];
      const item = s.rows[e.row].dataItem;
      console.log('bbbbbb', s.activeEditor.value);
    });
  };

  return (
    <div>
      <GuideBlock title={'Import'} isCode={true}>
        {`
import { CrudCode } from 'models/common/Edit';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
`}
      </GuideBlock>
      <GuideBlock title={'Layout'} isCode={true}>
        {`
const [flexRef, setFlexRef] = useState<any>();
const [flexItem, setFlexItem] = useState<any>();
const [hitTest, setHitTest] = useState<any>();

//select box용 데이터
const [dataSelect, setDataSelect] = useState([
  { id: 1, name: '대한민국', desc: 'KOREA' },
  { id: 2, name: '미국', desc: 'USA' },
  { id: 3, name: '일본', desc: 'JAPAN' },
  { id: 4, name: '중국', desc: 'CHINA' },
  { id: 5, name: '에스토니아', desc: 'ESTONIA' },
]);

//Row Data
const [rowData, setRowData] = useState([
  { crudKey: CrudCode.READ, id: 1, desc: '사용자 1', name: '박문수', address: '대한민국' },
  { crudKey: CrudCode.READ, id: 2, desc: '사용자 2', name: '홍길동', address: '미국' },
  { crudKey: CrudCode.READ, id: 3, desc: '사용자 3', name: '일지매', address: '일본' },
  { crudKey: CrudCode.READ, id: 4, desc: '사용자 4', name: '이몽룡', address: '중국' },
  { crudKey: CrudCode.READ, id: 5, desc: '사용자 5', name: '성춘향', address: '에스토니아' },
]);

const LayoutDefinition = () => {
  const selectMap = new DataMap(dataSelect, 'name', 'name');  //select  box용 DataMap생성   new DataMap(배열, value, display)
  return [
    {
      binding: 'id',
      align: 'left',
      width: 50,
      isReadOnly: true,
    },
    {
      header: '개인정보',
      align: 'center',
      columns: [
        {
          binding: 'name',
          header: '이름',
          width: 150,
          isReadOnly: true,
        },
        {
          binding: 'address',
          header: '주소',
          width: 150,
          dataMap: selectMap,   //select box 데이터 바인딩
        },
      ],
    },
    {
      binding: 'desc',
      align: 'center',
      width: 150,
    },
  ];
};

const state = {
  itemsSource: rowData,
  layoutDefinition: LayoutDefinition(),
};
`}
      </GuideBlock>
      <GuideBlock title={'Method'} isCode={true}>
        {`
const onFlexGridSelectionChanged = (s, e) => {
  if (s.rows == 0) return;
  const row = s.rows[e.row].dataItem;
};

const onInitialized = (grid) => {
  new Selector(grid);         //Column헤드에 체크셀렉트 추가,
  new FlexGridFilter(grid);   //그리드 기본 필터 추가,
  setFlexRef(grid); //grid 잠조를 위해

  grid.selectionMode = SelectionMode.Row; //셀렉트 방식 지정
};
`}
      </GuideBlock>
      <GuideBlock title={'Render'} isCode={true}>
        {`
<ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
  <FlexGrid
    columns={state.layoutDefinition}
    itemsSource={state.itemsSource}
    showMarquee={true}
    autoGenerateColumns={false}
    stickyHeaders={true}
    //isReadOnly={true}    //True >> 모든 필드가 읽기전용으로 변함. 주의.
    formatItem={flexGridTooltip}
    selectionChanged={onFlexGridSelectionChanged}
    initialized={onInitialized}
    style={{ height: '300px' }}
/>
</ContentGrid>
`}
      </GuideBlock>

      <GuideBlock title={'Example'} isCode={true}>
        <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
          <FlexGrid
            columns={state.layoutDefinition}
            itemsSource={state.itemsSource}
            showMarquee={true}
            autoGenerateColumns={false}
            stickyHeaders={true}
            //isReadOnly={true}
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: '300px' }}
          />
        </ContentGrid>
      </GuideBlock>
      <GuideBlock title={'Selected Row Data'} isCode={true}>
        <pre>{JSON.stringify(selectedRow, null, 2)}</pre>
      </GuideBlock>
    </div>
  );
};
export default SampleWijmo;
