/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { SelectGroup } from 'components/selects/CustomSelect';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { getYearList } from 'apis/gtng/GatingRegist';
import { GatingProgressSummaryGrid } from './gatingprogressmanagement/GatingProgressSummaryGrid';
import {
  GrayColor,
  StatusErrorColor,
  StatusConfirmColor,
  StatusWarningMinorColor,
  StatusDoneColor,
  BasicColor,
} from 'ui/theme/Color';
import { ContentGrid } from 'components/layouts/ContentGrid';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { ComboBox } from 'components/selects/ComboBox';
import { useTranslation } from 'react-i18next';

export interface SearchParam {
  gatingTypeCode?: string;
  gatingStepCode?: string;
  progressStatusCode?: string;
  productTypeCode?: string;
  gatingName?: string;
  startDate?: string;
  endDate?: string;
}

/**
 * Gating 진척관리 Page
 * @param
 * @constructor
 */
const GatingProgressManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchParam, setSearchParam] = useState<SearchParam>({});
  const [searchParamData, setSearchParamData] = useState<SearchParam>();
  const [gatingTypeConditionList, setGatingTypeConditionList] = useState<Code[]>([]);
  const [gatingStepCodeConditionList, setGatingStepCodeConditionList] = useState<Code[]>([]);
  const [gatingProgressStatusCodeConditionList, setGatingProgressStatusCodeConditionList] =
    useState<Code[]>([]);
  const [searchState, setSearchState] = useState<any>(false);
  const [gatingStartEndYearList, setGatingStartEndYearList] = useState<any>();
  const optionsGatingStartEndYearList = useMemo(() => {
    return (gatingStartEndYearList || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: String(cur.STARTYEAR),
          cmnCdNm: String(cur.STARTYEAR),
        },
      ],
      [] as Code[]
    );
  }, [gatingStartEndYearList]);

  useEffect(() => {
    initCondition();
  }, []);

  //콤보박스 공통코드 호출
  const initCondition = async () => {
    //Gating 유형
    const gatingTypeCodeConditions = await getCommonCodeNames('GATING_TYPE_CODE');
    setGatingTypeConditionList(gatingTypeCodeConditions);
    //Gating 단계
    const gatingStepCodeConditions = await getCommonCodeNames('GATING_STEP_CODE');
    setGatingStepCodeConditionList(gatingStepCodeConditions);
    //Gating 상태
    const gatingProgressStatusCodeConditions = await getCommonCodeNames(
      'GATING_PROGRESS_STATUS_CODE'
    );
    setGatingProgressStatusCodeConditionList(gatingProgressStatusCodeConditions);

    const gatingStartEndYearConditions = await getYearList();
    setGatingStartEndYearList(gatingStartEndYearConditions);
  };

  const handleSearchParam = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  };

  const btnSearch = () => {
    setSearchParamData(searchParam);
    if (searchState == true) {
      setSearchState(false);
    }
    if (searchState == false) {
      setSearchState(true);
    }
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce((acc, cur) => Object.assign(acc, { [cur]: '' }), {}) as SearchParam
    );
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.실시년도', '실시년도')}</label>
                <SelectGroup>
                  <ComboBox
                    options={optionsGatingStartEndYearList}
                    placeholder={String(t('com.label.전체', '전체'))}
                    defaultValue={searchParam.startDate}
                    onChange={(value) =>
                      setSearchParam({
                        ...searchParam,
                        startDate: value,
                      })
                    }
                  />
                  <span className="tilde">~</span>
                  <ComboBox
                    options={optionsGatingStartEndYearList}
                    placeholder={String(t('com.label.전체', '전체'))}
                    defaultValue={searchParam.endDate}
                    onChange={(value) =>
                      setSearchParam({
                        ...searchParam,
                        endDate: value,
                      })
                    }
                  />
                </SelectGroup>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 유형', 'Gating 유형')}</label>
                <ComboBox
                  options={gatingTypeConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.gatingTypeCode}
                  onChange={(value) =>
                    setSearchParam({
                      ...searchParam,
                      gatingTypeCode: value,
                    })
                  }
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.Gating Task명', 'Gating Task명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="gatingName"
                  placeholder={String(
                    t('gtng.msg.Gating Task명을 입력해 주세요.', 'GatingTask명을 입력해 주세요.')
                  )}
                  value={searchParam.gatingName}
                  onChange={handleSearchParam}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 상태', 'Gating상태')}</label>
                <ComboBox
                  options={gatingProgressStatusCodeConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.progressStatusCode}
                  onChange={(value) =>
                    setSearchParam({
                      ...searchParam,
                      progressStatusCode: value,
                    })
                  }
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <GatingProgressSummaryGrid searchParamData={searchParamData} searchState={searchState} />
    </>
  );
};

export default GatingProgressManagementPage;
