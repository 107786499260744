/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { CellRangeEventArgs } from '@grapecity/wijmo.grid';
import { IconButton } from 'components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import CustomSwitch from 'components/selects/CustomSwitch';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import { Employee, EmployeeCondition } from 'models/admin/Employee';
import { getEmployeeByCondition } from 'apis/admin/Employee';
import {
  SubTitleGroup,
  SubTitleLayout,
  HalfContetntLayout,
  ControlBtnGroup,
} from 'components/layouts/ContentLayout';
import _ from 'lodash';

interface Props {
  open: boolean;
  close: () => void;
  title: string;
  condition?: {
    userId?: string;
    empNm?: string;
  };
  mode?: string;
  defaultUserId?: string; // 기존 선택된 사용자ID (ex. user1 || user1,user2) (signleSelect=false인 경우 사용)
  sortable?: boolean; // 순서변경 여부
  singleSelect?: boolean; // 단일선택 여부 (default: true)
  onCallback: (result: Employee | Employee[]) => void;
}

/**
 * 사용자 선택 팝업
 * 단일선택(singleSelect)인 경우 그리드 1개, 사용여부 조건 노출
 * 다건선택인 경우 그리드2개, 사용여부 조건 미노출
 * @param open
 * @param close
 * @param title
 * @param defaultUserId
 * @param sortable
 * @param singleSelect
 * @param onCallback
 * @param props
 * @param mode
 * @constructor
 */
const UserMultiModal = ({
  open,
  close,
  title,
  mode,
  defaultUserId,
  sortable = false,
  singleSelect = true,
  onCallback,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const [condition, setCondition] = useState<EmployeeCondition>(
    props?.condition || {
      userId: '',
      empNm: '',
      deptNm: '',
      useYn: 'Y',
    }
  );

  const handleConditionChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setCondition({ ...condition, [e.target.name]: e.target.value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchRef.current?.handleSearch(condition);
    }
  };

  /**
   * 조회 이벤트 처리
   * @param condition
   */
  const handleSearch = async (condition: EmployeeCondition) => {
    searchRef.current?.handleSearch(condition);
  };

  /**
   * 선택 이벤트 처리
   */
  const handleConfirm = () => {
    if (singleSelect) {
      const items = searchRef.current?.getRowData();
      onCallback && onCallback((items || []).length ? items[0] : null);
    } else {
      const items = selectRef.current?.getRowData();

      const empNm = items.map((o) => o.empNm).join(',');
      const userId = items.map((o) => o.userId).join(',');

      const callBackData = {
        empNm: empNm,
        userId: userId,
        mode: mode,
      };
      onCallback(callBackData);
      close();
      onCallback && onCallback(callBackData);
    }
    close();
  };

  /**
   * Quick Select 이벤트 처리
   * @param item
   */
  const handleQuickSelect = (item: Employee) => {
    onCallback && onCallback(item);
    close();
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleConfirm}
      disableRipple
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <CustomDialog
      title={title}
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="popup">
              <SearchCols>
                <label>{t('com.label.사용자ID', '사용자ID')}</label>
                <CustomInputWithSearch
                  name="userId"
                  placeholder={String(
                    t('com.label.사용자 ID를 입력해 주세요.', '사용자 ID를 입력해 주세요.')
                  )}
                  value={condition?.userId}
                  onChange={handleConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.사용자명', '사용자명')}</label>
                <CustomInputWithSearch
                  name="empNm"
                  placeholder={String(
                    t('com.label.사용자명을 입력해 주세요.', '사용자명을 입력해 주세요.')
                  )}
                  value={condition?.empNm}
                  onChange={handleConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.부서명', '부서명')}</label>
                <CustomInputWithSearch
                  name="deptNm"
                  placeholder={String(
                    t('com.label.부서명을 입력해 주세요.', '부서명을 입력해 주세요.')
                  )}
                  value={condition?.deptNm}
                  onChange={handleConditionChange}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
            </SearchRows>
            {singleSelect && (
              <SearchRows>
                <SearchCols>
                  <label htmlFor="useYn">{t('com.label.사용여부', '사용여부')}</label>
                  <CustomSwitch
                    id="useYn"
                    onValue={'Y'}
                    onLabel={String(t('com.label.사용', '사용'))}
                    offValue={'N'}
                    offLabel={String(t('com.label.미사용', '미사용'))}
                    defaultValue={condition?.useYn}
                    onChange={(value) => {
                      setCondition((prev) => ({
                        ...prev,
                        useYn: value,
                      }));
                    }}
                  />
                </SearchCols>
              </SearchRows>
            )}
          </InputBox>
          <SearchBoxButtons
            defaultCondition={{ useYn: 'Y' }}
            setReset={setCondition}
            onSearch={() => handleSearch(condition)}
          />
        </SearchBoxRow>
      </SearchBox>
      {singleSelect ? (
        <SearchGrid
          ref={searchRef}
          singleSelect={singleSelect}
          defaultCondition={props.condition}
          onQuickSelect={handleQuickSelect}
        />
      ) : (
        <HalfContetntLayout style={{ marginTop: '24px' }}>
          <div>
            <SearchGrid
              ref={searchRef}
              singleSelect={singleSelect}
              defaultCondition={props.condition}
              addRow={(items) => selectRef.current?.addRowData(items)}
            />
          </div>
          <div>
            <SelectedGrid
              ref={selectRef}
              singleSelect={singleSelect}
              sortable={sortable}
              defaultUserId={defaultUserId}
            />
          </div>
        </HalfContetntLayout>
      )}
    </CustomDialog>
  );
};

interface ContentProps {
  singleSelect: boolean;
  sortable?: boolean;
  defaultCondition?: EmployeeCondition;
  defaultUserId?: string;
  onQuickSelect?: (item: Employee) => void;
  addRow?: (items: Employee[]) => void;
}

/**
 * 사용자 검색 그리드
 */
const SearchGrid = forwardRef(
  ({ singleSelect, addRow, onQuickSelect, defaultCondition }: ContentProps, ref) => {
    const gridRef = useRef<any>();
    const { t } = useTranslation();
    const [rowData, setRowData] = useState<Employee[]>([]);
    const [checkedItems, setCheckedItems] = useState<Employee[]>([]);

    useImperativeHandle(ref, () => ({
      handleSearch: (condition) => {
        handleSearch(condition);
      },
      getRowData: () => {
        return checkedItems;
      },
    }));

    useEffect(() => {
      if (defaultCondition) {
        handleSearch(defaultCondition);
      }
    }, [defaultCondition]);

    const handleSearch = (condition) => {
      getEmployeeByCondition(condition).then((result) => {
        setRowData(result || []);
      });
    };

    const onInitialized = (grid) => {
      gridRef.current = grid;

      grid.hostElement.addEventListener('click', (e) => {
        if (grid.rows.length == 0) return;

        const ht = grid.hitTest(e);
        if (ht.row < 0 || ht.col < 0) return;

        const binding = grid.columns[ht.col].binding;
        const item = grid.rows[ht.row].dataItem;

        if (ht.panel === grid.cells) {
          // Quick Select
          if ('btnSelect' === binding) {
            onQuickSelect && onQuickSelect(item);
          }
        }
      });

      grid.hostElement.addEventListener('dblclick', (e) => {
        if (grid.rows.length == 0) return;

        const ht = grid.hitTest(e);
        if (ht.row < 0 || ht.col < 0) return;

        const item = grid.rows[ht.row].dataItem;

        if (item) {
          onQuickSelect && onQuickSelect(item);
        }
      });
    };

    const handleAddRow = () => {
      addRow && addRow(checkedItems);
    };

    const layoutDefinition = [
      {
        binding: 'btnSelect',
        header: ' ',
        cssClass: 'WijmoRelay',
        cellTemplate: '<Button />',
        width: 39,
        visible: singleSelect,
      },
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.사용자ID', '사용자ID')),
        binding: 'userId',
        align: 'left',
        width: 150,
      },
      {
        binding: 'empNm',
        header: String(t('com.label.사용자명', '사용자명')),
        width: 120,
      },
      // {
      //   binding: 'deptCd',
      //   header: String(t('com.label.부서코드', '부서코드')),
      //   width: 150,
      //   visible: singleSelect,
      // },
      {
        binding: 'deptNm',
        header: String(t('com.label.부서명', '부서명')),
        align: 'left',
        width: '*',
      },
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        width: 140,
        visible: singleSelect,
      },
    ];

    return (
      <>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('com.label.사용자', '사용자')}</h3>
            <span className="total">
              {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
              {t('com.label.건', '건')}
            </span>
          </SubTitleGroup>
          {!singleSelect && (
            <ControlBtnGroup>
              <Button
                css={IconButton.button}
                className="addRow"
                onClick={handleAddRow}
                disableRipple
              >
                {t('com.label.추가', '추가')}
              </Button>
            </ControlBtnGroup>
          )}
        </SubTitleLayout>
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={500}
          isReadOnly={true}
          isFilter={false}
          autoCheck={true}
          rowSelection={singleSelect ? 'single' : 'multiple'}
          allowPinning={false}
          align="center"
          initialized={onInitialized}
          onChangeCheckedItem={(items) => setCheckedItems(items)}
        />
      </>
    );
  }
);

/**
 * 사용자 선택 그리드
 */
const SelectedGrid = forwardRef(
  ({ singleSelect, sortable = false, defaultUserId }: ContentProps, ref) => {
    const gridRef = useRef<any>();
    const dragIndex = useRef<any>();
    const { t } = useTranslation();
    const [rowData, setRowData] = useState<Employee[]>([]);
    const [checkedItems, setCheckedItems] = useState<Employee[]>([]);

    useImperativeHandle(ref, () => ({
      addRowData: (items: Employee[]) => {
        addRowData(items);
      },
      getRowData: () => {
        return rowData;
      },
    }));

    useEffect(() => {
      if (defaultUserId) {
        getEmployeeByCondition({ userIds: defaultUserId }).then((result) => addRowData(result));
      }
    }, [defaultUserId]);

    const addRowData = (items: Employee[]) => {
      setRowData((prev) => {
        const array = [...prev, ...(items || [])];
        const ids = array.map((o) => o.userId).filter((o) => !!o);
        const result = array.filter((o, idx) => ids.indexOf(o.userId || '') === idx);
        const sortUserNm = _.sortBy(result, ['empNm']);
        return sortUserNm;
      });
    };

    const onInitialized = (grid) => {
      gridRef.current = grid;
      grid.formatItem.addHandler((s, e) => {
        const binding = s.columns[e.col].binding;
        if (s.cells === e.panel) {
          if ('sortable' === binding) {
            e.cell.draggable = true;
          }
        }
      });
      grid.hostElement.addEventListener('dragstart', (e) => {
        const hti = grid.hitTest(e);
        grid.onDraggingRow(new CellRangeEventArgs(hti.panel, hti.range));
      });
      grid.hostElement.addEventListener('dragover', (e) => {
        const hti = grid.hitTest(e);
        if (hti.panel === grid.cells) {
          e.preventDefault();
        }
      });
      grid.hostElement.addEventListener('drop', (e) => {
        const hti = grid.hitTest(e);
        grid.onDraggedRow(new CellRangeEventArgs(hti.panel, hti.range));
      });
    };

    const handleRemoveRow = () => {
      setRowData((prev) => (prev || []).filter((o) => !(checkedItems || []).includes(o)));
      setCheckedItems([]);
    };

    const layoutDefinition = [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.사용자ID', '사용자ID')),
        binding: 'userId',
        align: 'left',
        width: 150,
      },
      {
        binding: 'empNm',
        header: String(t('com.label.사용자명', '사용자명')),
        width: 120,
      },
      {
        binding: 'deptNm',
        header: String(t('com.label.부서명', '부서명')),
        align: 'left',
        width: '*',
      },
      {
        binding: 'sortable',
        header: String(t('com.label.순서변경', '순서변경')),
        width: 80,
        visible: sortable,
        cssClass: 'WijmoTag',
        cellTemplate: '<span class="blue">=</span>',
      },
    ];

    return (
      <>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3></h3>
          </SubTitleGroup>
          <ControlBtnGroup>
            <Button
              css={IconButton.button}
              className="delRow"
              onClick={handleRemoveRow}
              disableRipple
            >
              {t('com.label.삭제', '삭제')}
            </Button>
          </ControlBtnGroup>
        </SubTitleLayout>
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={500}
          isReadOnly={true}
          isFilter={false}
          autoCheck={true}
          rowSelection={'multiple'}
          allowPinning={false}
          allowSorting={false}
          align="center"
          initialized={onInitialized}
          onChangeCheckedItem={(items) => setCheckedItems(items)}
          draggingRow={(grid, e) => {
            dragIndex.current = e.row;
            grid.collectionView.moveCurrentToPosition(e.row);
          }}
          draggedRow={(grid, e) => {
            const dropIndex = e.row;
            const arr = grid.collectionView.sourceCollection;
            grid.collectionView.deferUpdate(() => {
              const item = arr[dragIndex.current];
              arr.splice(dragIndex.current, 1);
              arr.splice(dropIndex, 0, item);
              grid.collectionView.moveCurrentToPosition(dropIndex);
            });
          }}
        />
      </>
    );
  }
);

SearchGrid.displayName = 'SearchGrid';
SelectedGrid.displayName = 'SelectedGrid';
export default UserMultiModal;
