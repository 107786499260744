/** @jsxImportSource @emotion/react */
import React, { useState, useCallback, useEffect } from 'react';

import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import { useTranslation } from 'react-i18next';
import { ApprovalRuleMaster } from 'models/admin/Approval';
import { getApprovalRuleMasters, setApprovalRuleMaster } from 'apis/admin/ApprovalRule';
import { ContentFlex } from '../../components/layouts/ContentFlex';
import { ContentSection } from '../../components/layouts/ContentSection';
import ApprovalMasterGrid from './approvalmanagement/ApprovalMasterGrid';
import ApprovalMasterForm from './approvalmanagement/ApprovalMasterForm';
import ApprovalLineGrid from './approvalmanagement/ApprovalLineGrid';
import { Button } from '@mui/material';
import { IconButton } from '../../components/buttons/IconSVG';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
//결재라인
const ApprovalLineManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const [textboxAprRuleNm, setTextboxAprRuleNm] = useState<string>('');

  const [searchAprRuleNm, setSearchAprRuleNm] = useState<string | null>('');

  const [searchAprRuleIdDetail, setSearchAprRuleIdDetail] = useState<string>('');
  const [approvalRuleList, setApprovalRuleList] = useState<ApprovalRuleMaster[]>([]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnSearch = () => {
    findApprovalRuleList(textboxAprRuleNm);
    setSearchAprRuleIdDetail('');
    fnMasterCellSelect('');
  };

  const fnMasterCellSelect = useCallback((param: string) => {
    setSearchAprRuleIdDetail(param);
  }, []);

  const fnRefreshPage = () => {
    setSearchAprRuleNm(null);
    setSearchAprRuleIdDetail('');
  };

  const findApprovalRuleList = async (aprRuleNm?: string) => {
    setApprovalRuleList(await getApprovalRuleMasters('', aprRuleNm ?? ''));
  };
  useEffect(() => {
    findApprovalRuleList();
  }, []);

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>
                  <span>{t('admin.label.결재규칙명', '결재규칙명')}</span>
                </label>
                <CustomInputWithSearch
                  id="ipCheckPlanNo"
                  value={textboxAprRuleNm}
                  className="width400"
                  onKeyDown={handleKeyDown}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTextboxAprRuleNm(e.target.value);
                  }}
                  placeholder={String(
                    t('admin.label.결재규칙명 입력해 주세요.', '결재규칙명 입력해 주세요.')
                  )}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={() => {
                setTextboxAprRuleNm('');
              }}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>

      <ContentFlex>
        <ContentSection className="section width30p marginT0">
          <ApprovalMasterGrid sRuleData={approvalRuleList} callBackFunc={fnMasterCellSelect} />
        </ContentSection>
        <ContentSection className="section width70p">
          <ApprovalMasterForm sRuleId={searchAprRuleIdDetail} callBackFunc={fnRefreshPage} />
        </ContentSection>
      </ContentFlex>
      <ContentSection>
        <ApprovalLineGrid sRuleId={searchAprRuleIdDetail} callBackFunc={fnRefreshPage} />
      </ContentSection>
    </>
  );
};
export default ApprovalLineManagementPage;
