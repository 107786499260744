/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useContext, useEffect, useState } from 'react';
import { CommonBG, CommonBorder, CommonText } from 'ui/theme/Color';
import { SideMenuList } from 'components/layouts/menu/SideMenuList';
import { Menu, MenuContextType } from 'models/admin/Menu';
import useSessionStore from 'stores/useSessionStore';
import { MenuContext } from 'App';
import { SidebarToggleButton } from './components/HeadLinkList/SidebarToggleButton';
import { useTranslation } from 'react-i18next';

export interface menuType {
  menuInfo: Menu;
  children: Menu[];
}

export const SideMenuBar = () => {
  const [isOpened, setIsOpened] = useState(true);
  const [sideMenus, setSideMenus] = useState<menuType[]>([]);

  const { menus } = useSessionStore();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { headerMenus } = useSessionStore();
  const { t } = useTranslation();

  const nest = (menuData: Menu[], mnuId = '000000', link = 'upprMnuId') =>
    menuData
      .filter((item) => item[link] === mnuId)
      .map((item) => ({ ...item, children: nest(menuData, item.mnuId) }));

  const getHeaderMenuName = (mnuId: string) => {
    const headerMenu = headerMenus.find((item) => item.mnuId === mnuId);
    return String(headerMenu?.mnuNm);
  };

  useEffect(() => {
    if (menus && menuContext.selectedHeaderMenu) {
      const depth1Menus: Menu[] = menus.filter(
        (item) => item.upprMnuId === menuContext.selectedHeaderMenu
      );

      const newSideMenuList: menuType[] = [];

      depth1Menus.forEach((item) => {
        const tree = nest(menus, item.mnuId);
        newSideMenuList.push({
          menuInfo: item,
          children: tree,
        });
      });
      setSideMenus(newSideMenuList);
    }
  }, [menuContext.selectedHeaderMenu]);

  return (
    <>
      {menuContext.selectedHeaderMenu && sideMenus.length > 0 && (
        <div css={st.root(isOpened)}>
          {isOpened ? (
            <div css={st.accList}>
              <p className="pate-title">{getHeaderMenuName(menuContext.selectedHeaderMenu)}</p>
              {sideMenus.map((it) => (
                <SideMenuList
                  key={it.menuInfo.mnuId}
                  summary={{ menuInfo: it.menuInfo }}
                  content={it.children}
                  isActive={false}
                />
              ))}
            </div>
          ) : (
            <div css={st.iconList}></div>
          )}

          <SidebarToggleButton onClick={() => setIsOpened(!isOpened)} isClicked={isOpened} />
        </div>
      )}
    </>
  );
};

const st = {
  root: (isClicked: boolean) => css`
    width: ${isClicked ? '230px' : '0'} !important;
    height: calc(100vh - 52px);
    background-color: ${CommonBG.Deep};
    transition: 0.3s;
    z-index: 999;
    border-right: 1px solid ${CommonBorder.Basic};
  `,

  iconList: css`
    width: 100%;
    height: 100%;
  `,

  accList: css`
    width: 230px !important;
    height: 100%;
    .pate-title {
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      padding: 24px 8px 16px 12px;
      border-bottom: 1px solid ${CommonBorder.Basic};
      color: ${CommonText.Light};
    }
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      background-color: transparent !important;
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent !important;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: transparent !important;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(60, 62, 61, 0.4);
      border-radius: 3px;
      background-clip: content-box;
    }
  `,
};
