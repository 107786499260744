/** @jsxImportSource @emotion/react */
import { ThemeProvider } from '@mui/material';
import Router from 'routers/Router';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultTheme } from 'ui/theme/CustomTheme';
import HeadMenuBar from 'components/layouts/menu/HeadMenuBar';
import { CommonModal } from 'components/modals/common/CommonModal';
import { MessageModal } from 'components/modals/common/MessageModal';
import { getSession } from 'apis/Session';
import useSessionStore from 'stores/useSessionStore';
import useLanguageStore from 'stores/useLanguageStore';
import { createContext, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { MainLayout } from 'components/layouts/MainLayout';
import { HomeMenu, Menu, MenuContextType, defaultMenuContext } from 'models/admin/Menu';
import { createMenuAccessLog } from 'apis/admin/Log';
import { Loading } from 'components/process/Loading';
import 'i18n';
import 'ui/css/wijmo.css';
import 'ui/css/wijmo-core.css';
import 'ui/css/multiSelect.css';
import 'ui/css/wj-customChart.css';
import { ApproveLayout } from 'components/layouts/ApproveLayout';

export const MenuContext = createContext<MenuContextType>(defaultMenuContext);

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { userId, setSession, menus } = useSessionStore();
  const { changeLanguage } = useLanguageStore();
  const [contextValue, setContextValue] = useState<MenuContextType>({
    ...defaultMenuContext,
    handleMenuChange: (item) => setContextValue(item),
    closeCurrentTab: (item) => {
      const menu = item.currentMenu;
      const stacksMenu = item.stacksMenu.filter((o) => o.mnuId !== menu.mnuId);

      let relateMnuIdx = -1;
      if (menu.relateMnuId) {
        relateMnuIdx = item.stacksMenu.findIndex((o) => o.mnuId === menu.relateMnuId);
      }
      const tabIndex = relateMnuIdx > 0 ? relateMnuIdx : Math.max(0, stacksMenu.length - 1);
      setContextValue({
        ...item,
        currentMenu: stacksMenu[tabIndex],
        stacksMenu: stacksMenu,
        tabIndex: tabIndex,
      });

      setTimeout(() => navigate(stacksMenu[tabIndex]?.mnuUrl || '/'), 100);
    },
  });
  const [showMenu, setShowMenu] = useState<boolean>(true);

  const LogMenuAccess = () => {
    const splitedPath = pathname.split('/');
    const menuId = splitedPath.length > 1 ? splitedPath[splitedPath.length - 1] : '';
    createMenuAccessLog(menuId);
  };

  const existPath = (path) => {
    const paths: any[] = ['/system/login'];
    const result = paths.includes(path);
    return result;
  };

  const devPath = (path) => {
    const paths: any[] = ['/dev/login'];
    const result = paths.includes(path);
    return result;
  };

  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.get('showMenu') === 'N' || params.get('appint_url')) {
      setShowMenu(false);
    }

    if (devPath(pathname)) {
      const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
      if (appEnv == 'local' || appEnv == 'dev' || appEnv == 'stg') {
        navigate('/dev/login', { replace: true });
        return;
      }
    }

    if (!userId && !existPath(pathname)) {
      (async () => {
        const response = await getSession();
        //로그인 된 경우
        if (response.successOrNot === 'Y' && response?.data) {
          const session = response.data;
          changeLanguage(session.langCd || 'ko');
          setSession(session);
          //협렵업체 사용자 정보수정 후 메인 페이지로...
          if (pathname == '/system/user-edit') {
            navigate('/', { replace: true });
            return;
          }
          //그 외 해당 메뉴로...
          if (pathname !== '/') {
            LogMenuAccess();
          }
          return;
        }

        //그 외 이유 불문하고 로그인 페이지로....
        const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
        if (appEnv == 'local' || appEnv == 'dev' || appEnv == 'stg') {
          navigate('/system/login', { replace: true });
          return;
        }
        window.location.href =
          'http://gsso.lgensol.com:8001/nls3/cookieLogin.jsp?UURL=' +
          `${process.env.REACT_APP_API_BASE_URL}` +
          '&RTOA=1';
      })();
    }
  }, []);

  useEffect(() => {
    if (menus?.length > 0) {
      const currentUrl = location.pathname.substring(1);
      if (currentUrl === '') {
        setContextValue({
          ...contextValue,
          currentMenu: HomeMenu,
          clickedByHeaderMenu: false,
          selectedHeaderMenu: '',
        });
        return;
      }
      let menu = menus.find((menu) => menu.mnuUrl === currentUrl) as Menu;

      if (menu?.mnuOptValCtn2 !== null && menu?.mnuOptValCtn2 !== undefined) {
        menu = menus.find((menu) => menu.mnuId === menu.mnuOptValCtn2) as Menu;
      }

      if (!menu) {
        menu = menus.find(
          (menu) => menu.mnuUrl && currentUrl.startsWith(menu.mnuUrl) && menu.mnuUrl.endsWith('/')
        ) as Menu;
        if (!menu) return;
      }

      setContextValue({ ...contextValue, currentMenu: menu, clickedByHeaderMenu: false });
    }
  }, [pathname, menus]);

  useEffect(() => {
    if (pathname !== '/' && userId) {
      LogMenuAccess();
    }
  }, [pathname]);

  return (
    <>
      {(userId ||
        pathname === '/dev/login' ||
        pathname === '/system/login' ||
        pathname === '/system/user-regist') && (
        <ThemeProvider theme={defaultTheme}>
          <MenuContext.Provider value={contextValue}>
            <div css={st.root}>
              {showMenu ? (
                <>
                  <HeadMenuBar />
                  <MainLayout showMenu={showMenu}>
                    <Router />
                  </MainLayout>
                </>
              ) : (
                <>
                  <ApproveLayout showMenu={showMenu}>
                    <Router />
                  </ApproveLayout>
                </>
              )}
            </div>
            <>
              <CommonModal />
              <MessageModal />
              <Loading />
            </>
          </MenuContext.Provider>
        </ThemeProvider>
      )}
    </>
  );
}

const st = {
  root: css`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    min-width: 1366px;
    display: flex;
    flex-direction: column;
  `,
};

export default App;
