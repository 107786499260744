/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo, SetStateAction } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Tab, Tabs } from '@mui/material';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { useLocation, useNavigate } from 'react-router-dom';
import useSessionStore from 'stores/useSessionStore';
import { tabs } from 'components/layouts/Tab';
import PropTypes from 'prop-types';
import UtMatrixListPopUp from './popup/UtMatrixListPopUp';
import { UtMatrixSummaryList } from 'models/ut/UtMatrixSummary';
import {
  getDetailSummaryData,
  getElectricSummaryData,
  getMachineSummaryData,
  getUtMatrixSummaryList,
} from 'apis/ut/UtMatrixSummary';
import DetailSummaryContent from './summary/DetailSummaryContent';
import MachineSummaryContent from './summary/MachineSummaryContent';
import ElectricSummaryContent from './summary/ElectricSummaryContent';
import { ComboBox } from 'components/selects/ComboBox';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import _ from 'lodash';
import UtMatrixGraphPopup from './popup/UtMatrixGraphPopup';

interface SearchParam {
  totalStandard?: string;
}

const UtMatrixSummaryPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const navigate = useNavigate();
  const machineRef = useRef<any>();
  const elecRef = useRef<any>();
  const detailRef = useRef<any>();
  const userSession = useSessionStore();
  const location = useLocation();
  const state = { ...location.state };

  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const [summaryRowData, setSummaryRowData] = useState<UtMatrixSummaryList[]>([]);
  const [machineRowData, setMachineRowData] = useState<any>();
  const [electricRowData, setElectricRowData] = useState<any>();
  const [detailRowData, setDetailRowData] = useState<any>();

  const [utmData, setUtmData] = useState<UtMatrixSummaryList[]>([]);

  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [isOpenGraphModal, setOpenGraphModal] = useState<boolean>(false);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const tabMenus = useMemo(() => {
    return [
      { id: 'MACH', name: t('ut.label.기계요약', '기계요약') },
      { id: 'ELTR', name: t('ut.label.전기요약', '전기요약') },
      { id: 'UTM', name: t('ut.label.상세보기', '상세보기') },
    ];
  }, []);

  const [searchParam, setSearchParam] = useState<SearchParam>({
    totalStandard: 'ALL',
  });
  const [code, setCode] = useState<any>();

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  const getCommonCodesForGrid = async () => {
    const sumBaseCd: Code[] = await getCommonCodeNames('SUM_BASE_CD');

    setCode({
      sumBaseCd: sumBaseCd,
    });
  };

  useEffect(() => {
    if (state.utmData !== undefined && state.utmData.length > 0) {
      callbackUtmData(state?.utmData);
    }
  }, [state.utmData]);

  const fnSearchJudgeResultBtn = (params: any) => {
    const searchParam = {
      gatingId: params.gatingId,
      gatingNo: params.gatingNo,
    };
    navigate('/gating/result/gating-judge-report', { state: { sJudgeResultParam: searchParam } });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'utmId',
      header: String(t('ut.label.UTM ID', 'UTM ID')),
      visible: true,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'bildPlntCdNm',
      header: String(t('ut.label.공장', '공장')),
      width: 100,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'bildPlntCd',
      visible: false,
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('ut.label.목적', '목적')),
      width: 100,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'askPurpCd',
      visible: false,
    },
    {
      binding: 'utmNo',
      header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
      width: 150,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'utmNm',
      header: String(t('ut.label.UT Matrix 명', 'UT Matrix 명')),
      width: 260,
      isReadOnly: true,
      // cssClass: 'WijmoFind',
      // cellTemplate: '<span>${item.utmNm}</span><button id="btnOpenModal" />',
    },
    {
      binding: 'verNo',
      header: String(t('ut.label.버전', '버전')),
      width: 80,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'lineQty',
      header: String(t('ut.label.Line 수', 'Line 수')),
      width: 110,
      align: 'center',
      isReadOnly: true,
    },
    {
      header: String(t('ut.label.Graph', 'Graph')),
      width: 80,
      align: 'center',
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: '<button id="btnMoveGraph"></button>',
    },
    {
      binding: 'elec',
      header: String(t('ut.label.전기[kw]', '전기[kw]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'cda',
      header: String(t('ut.label.CDA[l/min]', 'CDA[l/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'n2',
      header: String(t('ut.label.N₂[l/min]', 'N₂[l/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'iw',
      header: String(t('ut.label.IW[l/min]', 'IW[l/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'pcw',
      header: String(t('ut.label.PCW[l/min]', 'PCW[l/min]')),
      width: 140,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'steam',
      header: String(t('ut.label.Steam[Ton/hr]', 'Steam[Ton/hr]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'ng',
      header: String(t('ut.label.NG[N㎥/hr]', 'NG[N㎥/hr]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'exRetrun',
      header: String(t('ut.label.Ex.Return[CMH]', 'Ex.Return[CMH]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'exVent',
      header: String(t('ut.label.Ex.Vent[CMH]', 'Ex.Vent[CMH]')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
    {
      binding: 'sa',
      header: String(t('ut.label.Supply Air', 'Supply Air')),
      width: 150,
      align: 'right',
      isReadOnly: true,
    },
  ];

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      // // row 단일 선택 + 체크박스 체크
      grid.rows.forEach((r) => {
        if (r.isSelected === true) {
          r.isSelected = false;
        }
      });

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        setFlexItem(item);
        setHitTest(ht);
        switch (e.target.id) {
          case 'btnOpenModal':
            setOpenModal(true);
            break;
          case 'btnMoveGraph':
            setOpenGraphModal(true);
            setUtmData(item);
            break;
        }
      }
    });
  };

  const callbackUtmData = (utmData) => {
    const utmIds = utmData.map((item) => item.utmId).join(',');

    getUtMatrixSummaryList(utmIds).then((result: UtMatrixSummaryList[]) => {
      if (result !== null) {
        const utmData: any = [];

        if (result.length > 10) {
          // utmId 10개 이상 선택 시 10개만 등록하고 메세지로 10개 이상 등록할 수 없습니다.
          const sortUtmId = _.sortBy(result, ['utmId']);
          for (let i = 0; i < 10; i++) {
            utmData.push(sortUtmId[i]);
          }
          setSummaryRowData(utmData);
          openMessageBar({
            type: 'warning',
            content: t(
              'ut.label.Ut Matrix를 추가 할 수 없습니다. (최대 10개)',
              'Ut Matrix를 추가 할 수 없습니다. (최대 10개)'
            ),
          });
        } else if (result.length + summaryRowData.length > 10) {
          // 기존 utmId + new 10개 이상시 utmId 순서대로 10개까지만 등록하고 10개 이상 등록할 수 없습니다.
          const sortUtmId = _.sortBy(result, ['utmId']);
          for (let i = 0; i < 10 - summaryRowData.length; i++) {
            utmData.push(sortUtmId[i]);
          }
          // const newRow = summaryRowData.concat(result);
          const newRow = _.uniqBy(summaryRowData?.concat(utmData), 'utmId');
          setSummaryRowData(newRow);
        } else {
          const newRow = _.uniqBy(summaryRowData?.concat(result), 'utmId');
          const sortUtmId = _.sortBy(newRow, ['utmId']);
          setSummaryRowData(sortUtmId);
        }
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnDelRow = () => {
    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Ut Matrix를 선택해 주세요.', 'Ut Matrix를 선택해 주세요.'),
      });
      return false;
    }
    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode._idx!);
      })
      .reverse();

    selectedIds.forEach((idx) => {
      delete summaryRowData[idx];
    });
    const filteredData = summaryRowData.filter((element) => element !== undefined);
    const sortUtmId = _.sortBy(filteredData, ['utmId']);
    setSummaryRowData(sortUtmId);
  };

  const btnShowResult = () => {
    if (summaryRowData.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.UT Matrix를 추가해 주세요.', 'UT Matrix를 추가해 주세요.'),
      });
      return false;
    }
    const utmIds = summaryRowData.map((item) => item.utmId).join(',');
    getMachineSummaryData(utmIds, searchParam).then((result) => {
      if (result !== null) {
        setMachineRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
    getElectricSummaryData(utmIds, searchParam).then((result) => {
      if (result !== null) {
        setElectricRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
    getDetailSummaryData(utmIds).then((result) => {
      if (result !== null) {
        setDetailRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnExcelDownload = () => {
    if (currentTabIndex === 0) {
      machineRef.current?.btnMachineExcelExport();
    }
    if (currentTabIndex === 1) {
      elecRef.current?.btnElecExcelExport();
    }
    if (currentTabIndex === 2) {
      detailRef.current?.btnDetailExcelExport();
    }
  };

  return (
    <ContainerLayout>
      <SubTitleLayout style={{ marginTop: '0' }}>
        <SubTitleGroup>
          <h3>{t('ut.label.UT Matrix Summary', 'UT Matrix Summary')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{summaryRowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="addRow"
            onClick={() => setOpenModal(true)}
            disableRipple
          >
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button css={IconButton.button} className="delRow" onClick={btnDelRow} disableRipple>
            {t('com.button.행삭제', '행삭제')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={summaryRowData}
        initialized={onInitialized}
        showMarquee={true}
        selectionMode={SelectionMode.CellRange}
        alternatingRowStep={0}
        height={250}
      />
      <SubTitleLayout style={{ marginTop: '50px' }}>
        <SubTitleGroup>
          <div css={tabs.wrap} style={{ marginTop: '24px' }}>
            <Tabs
              value={currentTabIndex}
              onChange={(e, newValue) => setCurrentTabIndex(newValue)}
              css={tabs.tab}
            >
              {tabMenus.map((o, idx) => (
                <Tab
                  key={o.id}
                  label={o.name}
                  id={`ut-tab-${idx}`}
                  aria-controls={`ut-tabpanel-${idx}`}
                />
              ))}
            </Tabs>
          </div>
          <span className="total">
            {String(t('com.label.총', '총'))}
            <span>
              {(currentTabIndex === 0
                ? machineRowData?.list.length
                : currentTabIndex === 1
                ? electricRowData?.list.length
                : detailRowData?.length) || [].length}
            </span>
            {String(t('com.label.건', '건'))}
          </span>
        </SubTitleGroup>

        <ControlBtnGroup>
          <label>{String(t('ut.label.집계기준', '집계기준'))}</label>
          <ComboBox
            options={code?.sumBaseCd}
            defaultValue={searchParam.totalStandard}
            onChange={(value) => {
              setSearchParam({
                ...searchParam,
                totalStandard: value,
              });
            }}
          />
          <Button css={IconButton.button} className="request" onClick={btnShowResult} disableRipple>
            {String(t('com.button.결과보기', '결과보기'))}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelDownload}
            disableRipple
          >
            {String(t('com.button.다운로드', '다운로드'))}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <TabPanel value={currentTabIndex} index={currentTabIndex}>
        {currentTabIndex === 0 ? (
          <MachineSummaryContent ref={machineRef} machineRowData={machineRowData} />
        ) : currentTabIndex === 1 ? (
          <ElectricSummaryContent ref={elecRef} electricRowData={electricRowData} />
        ) : currentTabIndex === 2 ? (
          <DetailSummaryContent ref={detailRef} detailRowData={detailRowData} />
        ) : (
          ''
        )}
      </TabPanel>
      {isOpenModal && (
        <UtMatrixListPopUp
          open={isOpenModal}
          close={() => setOpenModal(false)}
          onCallback={(callbackData) => callbackUtmData(callbackData)}
        />
      )}
      {isOpenGraphModal && (
        <UtMatrixGraphPopup
          open={isOpenGraphModal}
          close={() => setOpenGraphModal(false)}
          condition={utmData}
        />
      )}
    </ContainerLayout>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`ut-tabpanel-${index}`}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default UtMatrixSummaryPage;
