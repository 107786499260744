import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { FileImgInfo } from 'models/admin/FileImgInfo';
import axios, { AxiosResponseHeaders } from 'axios';
import _ from 'lodash';

export const downloadFileByType = async (atchFileTpCd: string) => {
  try {
    const fileDownloadUrl = `${process.env.REACT_APP_API_BASE_URL}/v1/file/type/download?atchFileTpCd=${atchFileTpCd}`;
    const response = await axios.get(fileDownloadUrl, {
      responseType: 'blob',
    });

    if (response?.data) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const headers = response?.headers as AxiosResponseHeaders;
      const disposition = (headers['content-disposition'] as string) || '';

      if (!_.isEmpty(disposition)) {
        link.href = url;
        link.setAttribute(
          'download',
          String(decodeURIComponent(disposition?.split('filename=')[1]))
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        alert('Failed to download.');
      }
    }
  } catch (error) {
    console.log(error);
  }
};

/** 파일 이미지 첨부 조회 */
export const findFileImgList = async (atchFileGrId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/file/images`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ atchFileGrId: atchFileGrId }),
  };

  const response: CommonResponse<FileImgInfo[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as FileImgInfo[];
};
