/** @jsxImportSource @emotion/react */
import { findBbsPost } from 'apis/admin/Bbs';
import { createReply, removeReply } from 'apis/admin/BbsReply';
import { CustomTextarea } from 'components/inputs/CustomInput';
import { ContentSection } from 'components/layouts/ContentSection';
import { ViewTable } from 'components/tables/ViewTable';
import { BbsEmployee, BbsPostDetail } from 'models/admin/Bbs';
import { BbsReply, BbsReplyRequest } from 'models/admin/BbsReply';
import React, { useState, useEffect, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { MenuUrl } from 'routers/Router';
import useSessionStore from 'stores/useSessionStore';
import { convertNull } from 'utils/FormatUtil';
import { BbsManagementPageLocationState } from './BbsManagementPage';
import FileGrid from 'components/dropzone/FileGrid';
import DOMPurify from 'dompurify';
import { Button } from '@mui/material';
import { IconButton } from '../../components/buttons/IconSVG';
import { GlobalBtnGroup } from 'components/layouts/ContentLayout';
import { EditType } from 'models/common/Edit';
import { MenuContext } from 'App';
import { MenuContextType } from 'models/admin/Menu';

interface BbsEditProps {
  editType?: EditType;
  postBbmNo?: string;
}

export const BbsPostPage = () => {
  const bbsManagementPageLocationState: BbsManagementPageLocationState = useLocation().state;
  const { t } = useTranslation();

  const { userId } = useSessionStore();

  const [post, setpost] = useState<BbsPostDetail>();
  const [prevMenuCode, setPrevMenuCode] = useState<string>('ETC');
  const [bbmReCtn, setBbmReCtn] = useState<string>('');

  const location = useLocation();
  const navigate = useNavigate();
  const menuContext = useContext<MenuContextType>(MenuContext);
  // 현재 창 닫고 이동
  const thisPageClose = () => {
    menuContext.closeCurrentTab(menuContext);
  };
  const state = { ...location.state };

  const checkMasterUser = () => {
    let i = 0;
    useSessionStore.getState().roleCodes.forEach((item) => {
      if (item === 'ADM') {
        i++;
      }
    });
    if (i > 0) return true;
    else return false;
  };

  const handleReplyContentsChange = async (e) => {
    setBbmReCtn(e.target.value);
  };

  const handleReplyAddClick = async (e) => {
    if (post) {
      const bbsReplyRequest = {
        bbmNo: post.bbmNo,
        bbmReCtn: bbmReCtn,
      } as BbsReplyRequest;
      const response = await createReply(bbsReplyRequest);
      if (response) {
        setBbmReCtn('');
        const updatedPost = await findBbsPost(post.bbmNo);
        updatedPost && setpost(updatedPost);
      }
    }
  };

  const handleReplyDeleteClick = async (bbmReNo: string) => {
    if (post) {
      const response = await removeReply(post.bbmNo, bbmReNo);
      if (response) {
        const updatedPost = await findBbsPost(post.bbmNo);
        updatedPost && setpost(updatedPost);
      }
    }
  };

  const getUserFormatForBbsPostDetail = (bbsEmployee: BbsEmployee) => {
    const user = convertNull(bbsEmployee) as BbsEmployee;
    const { email, ofcTanoPhn, jtiNm } = user;
    let addInfo = '';
    if (
      email &&
      ofcTanoPhn &&
      jtiNm &&
      email.indexOf('@') != -1 &&
      ofcTanoPhn.length > 4 &&
      jtiNm !== ''
    ) {
      addInfo = `.${jtiNm}(${email}, ${ofcTanoPhn})`;
    }
    if (user) {
      return `${user.deptNm}.${user.empNm}` + addInfo;
    }
    return null;
  };

  const getUserFormatForBbsReply = (bbsReply: BbsReply) => {
    const user = convertNull(bbsReply) as BbsReply;
    if (user) {
      return `${user.deptNm}.${user.empNm}.${user.jtiNm !== '' ? user.jtiNm : user.jpsNm}`;
    }
    return null;
  };

  const handleBackListClick = () => {
    if (prevMenuCode === 'bbs-sample') {
      thisPageClose();
      navigate(MenuUrl.BbsManagementPage, {
        state: { condition: state.condition, prevMenuCode: prevMenuCode },
      });
    } else if (prevMenuCode === 'menual') {
      thisPageClose();
      navigate(MenuUrl.BbsMenual, {
        state: { condition: state.condition, prevMenuCode: prevMenuCode },
      });
    } else {
      navigate(MenuUrl.BbsManagementPage, { state: { condition: state.condition } });
    }
  };

  const handleModifyClick = () => {
    let menuName = '';
    if (prevMenuCode === 'bbs-sample') {
      menuName = '게시글 수정';
    } else if (prevMenuCode === 'menual') {
      menuName = '개발메뉴얼 수정';
    }

    navigate('/system/admin/sample/bbs-sample/bbs-edit', {
      state: {
        editType: EditType.UPDATE,
        editBbmNo: post?.bbmNo,
        prevMenuCode: prevMenuCode,
        bbsTpCd: state.bbsTpCd,
        mnuNm: menuName,
      },
    });
  };

  useEffect(() => {
    findBbsPost(state.postBbmNo || '').then((result) => {
      result && setpost(result);
    });
    setPrevMenuCode(state.prevMenuCode);
  }, [state.postBbmNo, prevMenuCode]);

  return (
    <ContentSection className="marginT0">
      <ViewTable>
        <colgroup>
          <col width="15%" />
          <col />
          <col width="15%" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">
              <label>{t('admin.label.번호', '번호')}</label>
            </th>
            <td>{post?.bbmNo}</td>
            <th scope="row">
              <label>{t('admin.label.조회수', '조회수')}</label>
            </th>
            <td>{post?.bbmVwct}</td>
          </tr>
          <tr>
            <th scope="row">
              <label>{t('com.label.작성일시', '작성일시')}</label>
            </th>
            <td>{post?.dataInsDtm}</td>
            <th scope="row">
              <label>{t('admin.label.작성자', '작성자')}</label>
            </th>
            <td>{post?.dataInsUser && getUserFormatForBbsPostDetail(post.dataInsUser)}</td>
          </tr>
          <tr>
            <th scope="row">
              <label>{t('com.label.수정일', '수정일')}</label>
            </th>
            <td>{post?.dataUpdDtm}</td>
            <th scope="row">
              <label>{t('com.label.수정자', '수정자')}</label>
            </th>
            <td>{post?.dataUpdUser && getUserFormatForBbsPostDetail(post.dataUpdUser)}</td>
          </tr>
          <tr>
            <th scope="row">
              <label>{t('admin.label.게시판분류', '게시판분류')}</label>
            </th>
            <td>{post?.bbsTpNm}</td>
            <th scope="row">
              <label>{t('admin.label.게시대상법인', '게시대상법인')}</label>
            </th>
            <td>{post?.ptupTgtCopNm}</td>
          </tr>
          <tr>
            <th scope="row">
              <label>{t('admin.label.제목', '제목')}</label>
            </th>
            <td colSpan={3}>{post?.bbmTitNm}</td>
          </tr>
          <tr>
            <th scope="row">
              <label>{t('admin.label.본문', '본문')}</label>
            </th>
            <td colSpan={3}>
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post?.bbmCtn + '') }}
              ></div>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label>{t('com.label.첨부', '첨부')}</label>
            </th>
            <td colSpan={3}>
              <FileGrid atchFileGrId={post?.atchFileGrId ?? ''} />
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label>{t('admin.label.댓글', '댓글')}</label>
            </th>
            <td colSpan={3}>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <CustomTextarea
                  className="fullWidth"
                  value={bbmReCtn}
                  onChange={handleReplyContentsChange}
                  style={{ width: '95%' }}
                />
                <Button
                  css={IconButton.button}
                  className="save"
                  disableRipple
                  onClick={handleReplyAddClick}
                >
                  {t('com.button.등록', '등록')}
                </Button>
              </div>
            </td>
          </tr>
          {post?.replies &&
            post.replies.map((reply) => (
              <tr key={reply.bbmReNo}>
                <th scope="row">
                  <label>{getUserFormatForBbsReply(reply)}</label>
                  <br></br>
                  <label>{reply.dataInsDtm}</label>
                </th>
                <td colSpan={3}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {reply.bbmReCtn}
                    {reply.dataInsUserId == userId || checkMasterUser() == true ? (
                      // <IconButton
                      //   onClick={() => {
                      //     handleReplyDeleteClick(reply.bbmReNo);
                      //   }}
                      // >
                      //   <DeleteOutlinedIcon></DeleteOutlinedIcon>
                      // </IconButton>
                      <Button
                        css={IconButton.button}
                        className="delete"
                        disableRipple
                        onClick={() => handleReplyDeleteClick(reply.bbmReNo)}
                      >
                        {t('com.label.삭제', '삭제')}
                      </Button>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </ViewTable>
      <GlobalBtnGroup>
        <Button
          css={IconButton.button}
          className="list"
          disableRipple
          onClick={handleBackListClick}
        >
          {t('com.button.목록', '목록')}
        </Button>
        {post &&
          (post.dataInsUser.userId == userId || checkMasterUser() ? (
            <Button
              css={IconButton.button}
              className="edit"
              disableRipple
              onClick={handleModifyClick}
            >
              {t('com.button.수정', '수정')}
            </Button>
          ) : null)}
      </GlobalBtnGroup>
    </ContentSection>
  );
};

export default BbsPostPage;
