import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { EquipRowData } from 'pages/common/popup/GatingEquipPopUp';
import { EquipmentMasterCondition } from 'models/common/popup/EquipmentMaster';

/* Gating 설비Master 목록 조회 */
export const getGatingEquipmentMst = async (condition?: EquipmentMasterCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getGatingEquipmentMst`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<EquipRowData[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as EquipRowData[];
};

/* 점검중 장비변경 시 저장/수정 */
//상태코드가 B면 점검중 장비변경에서 적용을 누를시 CRUD실행
export const saveEquipments = async (saveData: EquipRowData[]) => {
  console.log('saveData', saveData);
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveEquipments`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};

/* 상태코드 B(점검), D(완료)의 점검장비 목록 조회 */
export const getEquipments = async (gatingId, value) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getEquipments`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId, equipmentId: value }),
  };
  const response: CommonResponse<EquipRowData[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as EquipRowData[];
};

/* 상태코드 B(점검), D(완료)의 점검장비 목록 조회 */
export const getEquipments2 = async (gatingId) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getEquipments2`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ gatingId: gatingId }),
  };
  const response: CommonResponse<EquipRowData[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as EquipRowData[];
};

/* 상태코드 B(점검중 장비변경)의 장비추가시 Gating의 날짜 업데이트 */
export const updateGatingDate = async (gatingDate) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/updateGatingDate`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: gatingDate,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};
