/** @jsxImportSource @emotion/react */
import React, { useCallback, useState } from 'react';
import RoleManagementGrid from 'pages/admin/rolemanagement/RoleManagementGrid';
import RoleEmpManagementGrid from 'pages/admin/rolemanagement/RoleEmpManagementGrid';
import { BasicColor, BorderColor, FontColor, BgColor, ButtonColor } from 'ui/theme/Color';
import { css } from '@emotion/react';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { useTranslation } from 'react-i18next';
import { ContentFlex } from 'components/layouts/ContentFlex';
import { ContentSection } from 'components/layouts/ContentSection';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';

const RoleEmployeeManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const [textboxData, setTextboxData] = useState<string>('');
  const [searchParam, setSearchParam] = useState<string>('');
  const [searchEmpRoleCd, setSearchEmpRoleCd] = useState<string>('');

  const handleSearchParamData = (e: any) => {
    setTextboxData(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const handleResetCondition = () => {
    setTextboxData('');
  };

  const btnSearch = () => {
    setSearchParam(textboxData);
  };

  const listner = useCallback((param: string) => {
    setSearchEmpRoleCd(param);
  }, []);

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('admin.label.역할명', '역할명')}</label>
                <CustomInputWithSearch
                  value={textboxData}
                  onChange={handleSearchParamData}
                  onKeyDown={handleKeyDown}
                  placeholder={String(
                    t('admin.label.역할명을 입력해 주세요.', '역할명을 입력해 주세요.')
                  )}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            />
            <Button css={IconButton.button} className="find" disableRipple onClick={btnSearch}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContentFlex>
        <ContentSection className="section width50p marginT0">
          <RoleManagementGrid sRoleNm={searchParam} editable={false} callBackRoleCd={listner} />
        </ContentSection>
        <ContentSection className="section width50p marginT0">
          <RoleEmpManagementGrid sRoleCd={searchEmpRoleCd} editable={true} />
        </ContentSection>
      </ContentFlex>
    </>
  );
};

export default RoleEmployeeManagementPage;
