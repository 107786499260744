/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { DataType, toggleClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { Button } from '@mui/material';
import {
  getCommonCodeHeader,
  getCommonCodes,
  setCommonCodeGroups,
  setCommonCodes,
} from 'apis/admin/CommonCode';
import { CommonCode, CommonCodeHeader } from 'models/admin/CommonCode';
import { CommonYN } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { CrudCode } from 'models/common/Edit';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { commonYNList } from 'models/common/CommonCode';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageModalStore } from 'stores/useMessageModalStore';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';

interface SearchParamData {
  sCmnGrCd: string;
  editable: boolean;
  callBackRoleCd?: any;
}

const CodeDetailGrid = (props: SearchParamData) => {
  const { t } = useTranslation();
  const gridRef = useRef<any>();
  const [flexRef, setflexRef] = useState<any>();
  const [rowData, setRowData] = useState<CommonCode[]>([]);
  const [header, setHeader] = useState<CommonCodeHeader>();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { setMessageModalStateWhenModalOpen } = useMessageModalStore();

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      width: 45,
      isReadOnly: true,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'cmnCd',
      header: String(t('admin.grid.공통코드', '공통코드')),
      align: 'left',
      width: 150,
      cssClass: 'WijmoPopup',
      cellTemplate: (params) =>
        params.item['crudKey'] === CrudCode.CREATE
          ? params.value
          : `<span>${params.value}</span><Button id="btnCode" />`,
    },
    {
      binding: 'cmnCdNm',
      header: String(t('admin.grid.코드명', '코드명')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'cmnCdDesc',
      header: String(t('admin.grid.설명', '설명')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'msgCtn',
      header: String(t('admin.grid.메시지코드', '메시지코드')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'sortOrd',
      header: String(t('admin.grid.정렬순서', '정렬순서')),
      width: 80,
      dataType: DataType.Number,
      align: 'center',
    },
    {
      binding: 'useYn',
      header: String(t('com.label.사용여부', '사용여부')),
      width: 80,
      align: 'left',
      dataMap: new DataMap(commonYNList, 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'optValCtn1',
      header: header?.optValNm1 || String(t('admin.grid.옵션1', '옵션1')),
    },
    {
      binding: 'optValCtn2',
      header: header?.optValNm2 || String(t('admin.grid.옵션2', '옵션2')),
    },
    {
      header: header?.optValNm3 || String(t('admin.grid.옵션3', '옵션3')),
      binding: 'optValCtn3',
    },
    {
      header: header?.optValNm4 || String(t('admin.grid.옵션4', '옵션4')),
      binding: 'optValCtn4',
    },
    {
      header: header?.optValNm5 || String(t('admin.grid.옵션5', '옵션5')),
      binding: 'optValCtn5',
    },
    {
      header: header?.optValNm6 || String(t('admin.grid.옵션6', '옵션6')),
      binding: 'optValCtn6',
    },
    {
      header: header?.optValNm7 || String(t('admin.grid.옵션7', '옵션7')),
      binding: 'optValCtn7',
    },
    {
      header: header?.optValNm8 || String(t('admin.grid.옵션8', '옵션8')),
      binding: 'optValCtn8',
    },
    {
      header: header?.optValNm9 || String(t('admin.grid.옵션9', '옵션9')),
      binding: 'optValCtn9',
    },
    {
      header: header?.optValNm10 || String(t('admin.grid.옵션10', '옵션10')),
      binding: 'optValCtn10',
    },
    {
      header: header?.optValNm11 || String(t('admin.grid.옵션11', '옵션11')),
      binding: 'optValCtn11',
    },
    {
      header: header?.optValNm12 || String(t('admin.grid.옵션12', '옵션12')),
      binding: 'optValCtn12',
    },
    {
      header: header?.optValNm13 || String(t('admin.grid.옵션13', '옵션13')),
      binding: 'optValCtn13',
    },
    {
      header: header?.optValNm14 || String(t('admin.grid.옵션14', '옵션14')),
      binding: 'optValCtn14',
    },
    {
      header: header?.optValNm15 || String(t('admin.grid.옵션15', '옵션15')),
      binding: 'optValCtn15',
    },
    {
      header: String(t('admin.grid.비고', '비고')),
      binding: 'rmk',
    },
  ];

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'code';
    book.saveAsync(getExcelFileName(t('admin.label.코드목록', '코드목록')));
  };

  const fnSearchHeader = useCallback((params: string) => {
    getCommonCodeHeader(params).then((result: CommonCodeHeader) => {
      setHeader(result);
    });
  }, []);

  const fnSearchCode = useCallback((params: string) => {
    getCommonCodes(params).then((result: CommonCode[]) => {
      setRowData(result);
    });
  }, []);

  const btnAddRow = useCallback(() => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
      cmnGrCd: props.sCmnGrCd,
    } as CommonCode;

    setRowData([newRow, ...rowData]);
  }, [rowData]);

  const btnDelRow = useCallback(() => {
    // 행추가된 건만 삭제 가능
    const selectedRows = gridRef.current.rows.filter(
      (r) => r.isSelected && r.dataItem.crudKey === CrudCode.CREATE
    );

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      delete rowData[item];
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData, gridRef]);

  const btnSave = async () => {
    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.cmnCd == null || rowNode.cmnCd == '')
          return `${index + 1} : ${t(
            'admin.msg.공통코드명을 입력해 주세요.',
            '공통코드명을 입력해 주세요.'
          )}`;
        if (rowNode.cmnCdNm == null || rowNode.cmnCdNm == '')
          return `${index + 1} : ${t(
            'admin.msg.코드명을 입력해 주세요.',
            '코드명을 입력해 주세요.'
          )}`;
        if (rowNode.sortOrd == null || rowNode.sortOrd == '') rowNode.sortOrd = '0';
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      openMessageBar({ type: 'error', content: valid.toString() });
      return false;
    }

    const saveRows = rowData
      .map((rowNode) => {
        return rowNode.crudKey ? rowNode : null;
      })
      .filter((element) => element !== null) as CommonCode[];

    if (saveRows.length == 0) {
      openMessageBar({
        type: 'error',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return true;
    }
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        setCommonCodes(saveRows).then((result) => {
          if (!result) {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return false;
          }
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          fnSearchCode(props.sCmnGrCd);
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  useEffect(() => {
    fnSearchHeader(props.sCmnGrCd);
    fnSearchCode(props.sCmnGrCd);
  }, [props]);

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      const isReadOnly = item.crudKey !== CrudCode.CREATE;
      // 공통코드
      if ('cmnCd' === binding) {
        toggleClass(cell, 'WijmoPopup', isReadOnly);
        cell.ariaReadOnly = isReadOnly;
      }
      // 코드명
      if ('cmnCdNm' === binding) {
        cell.ariaReadOnly = isReadOnly;
      }
    }
  });

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
    setflexRef(grid);
    grid.formatItem.addHandler((s, e) => {
      // 행추가된 건만 체크박스 노출
      if (CellType.RowHeader === e.panel.cellType) {
        const item = e.panel.rows[e.row].dataItem;
        if (item.crudKey !== CrudCode.CREATE) {
          if (e.cell.hasChildNodes()) {
            e.cell.removeChild(e.cell.firstChild);
          }
        }
      }
    });

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          // 공통코드
          case 'btnCode':
            setMessageModalStateWhenModalOpen(item.msgCtn, item.cmnGrCdNm, (cmnGrCdNm?: string) => {
              if (cmnGrCdNm && item) {
                setCommonCodeGroups([
                  { ...item, cmnGrCdNm: cmnGrCdNm, crudKey: CrudCode.UPDATE },
                ]).then((result) => result && result && fnSearchCode(props.sCmnGrCd));
              }
              openMessageBar({
                type: 'confirm',
                content: t('com.label.저장되었습니다.', '저장되었습니다.'),
              });
            });
        }
      }
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.코드목록', '코드목록')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        {props.editable && (
          <ControlBtnGroup>
            <Button
              css={IconButton.button}
              className="Exceldown"
              onClick={btnExcelExport}
              disableRipple
            >
              {t('com.button.다운로드', '다운로드')}
            </Button>
            <Button css={IconButton.button} className="addRow" disableRipple onClick={btnAddRow}>
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button css={IconButton.button} className="delRow" disableRipple onClick={btnDelRow}>
              {t('com.button.행삭제', '행삭제')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={300}
        deferResizing={false}
        initialized={onInitialized}
        align={'center'}
        beginningEdit={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const item = grid.rows[e.row].dataItem;
          // readonly 설정
          // 공통코드, 코드명
          if (['cmnCd', 'cmnCdNm'].includes(binding)) {
            if (CrudCode.CREATE !== item.crudKey) {
              e.cancel = true;
            }
          }
        }}
      />
      <GlobalBtnGroup>
        {props.editable && (
          <Button css={IconButton.button} className="save" disableRipple onClick={btnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default memo(CodeDetailGrid);
