/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import GtngVerMstGrid from './gtngvermst/GtngVerMstGrid';
import { CustomSelect } from 'components/selects/CustomSelect';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { ContentSection } from 'components/layouts/ContentSection';
import { Code } from 'models/common/CommonCode';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { IconButton } from '../../components/buttons/IconSVG';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * 게이팅 버전관리 : 현재 메뉴에 없음 사용안함
 * @constructor
 */
const GtngVerMstPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchParam, setSearchParam] = useState<string>('');
  const [textboxGatingTypeCode, setTextboxGatingTypeCode] = useState<string>('');
  const [gatingTypeConditionList, setGatingTypeConditionList] = useState<Code[]>([]);
  const [openTypeCodeList, setOpenTypeCodeList] = useState<any>(false);

  useEffect(() => {
    initCondition();
  }, []);

  const initCondition = async () => {
    const categoryConditions = await getCommonCodeNames('GATING_TYPE_CODE');
    setGatingTypeConditionList(categoryConditions);
  };

  const handleTextboxGatingTypeCode = (e: any) => {
    setTextboxGatingTypeCode(e.target.value);
  };

  const btnSearch = () => {
    setSearchParam(textboxGatingTypeCode);
    setOpenTypeCodeList(false);
  };

  const inputOpenTypeCodeList = () => {
    if (openTypeCodeList == true) {
      setOpenTypeCodeList(false);
    } else {
      setOpenTypeCodeList(true);
    }
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.Gating 유형', 'Gating 유형')}</label>
                {/* <SelectBox>
                  <Selected
                    defaultValue={'전체'}
                    value={'전체'}
                    onClick={() => {
                      inputOpenTypeCodeList();
                    }}
                    readOnly
                  ></Selected>
                  {openTypeCodeList && (
                    <SelectOptions
                      className="multi"
                      style={{ display: 'block', padding: '8px 0px' }}
                    >
                      <label>
                        <Option key={'ALL'} value={''} style={{ margin: '0px' }}>
                          <Checkbox
                            css={st.checkbox}
                            onChange={(e) => {
                              handleTextboxGatingTypeCode(e, '');
                            }}
                          />
                          {'전체'}
                        </Option>
                      </label>
                      {gatingTypeConditionList &&
                        gatingTypeConditionList.map((item) => (
                          <label key={item?.cmnCd}>
                            <Option key={item?.cmnCd} value={item?.cmnCd} style={{ margin: '0px' }}>
                              <Checkbox
                                css={st.checkbox}
                                onChange={(e) => {
                                  handleTextboxGatingTypeCode(e, item?.cmnCd);
                                }}
                              />
                              {item?.cmnCdNm}
                            </Option>
                          </label>
                        ))}
                    </SelectOptions>
                  )}
                </SelectBox> */}
                <CustomSelect
                  className="width120"
                  value={textboxGatingTypeCode}
                  onChange={handleTextboxGatingTypeCode}
                >
                  <option key={'ALL'} value={''}>
                    {String(t('com.label.전체', '전체'))}
                  </option>
                  {gatingTypeConditionList &&
                    gatingTypeConditionList.map((item) => (
                      <option key={item?.cmnCd} value={item?.cmnCd}>
                        {item?.cmnCdNm}
                      </option>
                    ))}
                </CustomSelect>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              // onClick={handleResetCondition}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContentSection className="ag-theme-alpine" style={{ height: '500px' }}>
        <GtngVerMstGrid
          gatingTypeCode={searchParam}
          editable={true}
          gatingTypeConditionList={gatingTypeConditionList}
        />
      </ContentSection>
    </>
  );
};

export default GtngVerMstPage;
