import { Author } from 'models/common/Author';

/** 테이블 명 : IP_CHECK_PLAN_N...... */
export interface IpCheckPlan extends Author {
  ipCheckPlanNo: string;
  copCd: string;
  ipCheckPlanType: string;
  ipCheckPlanName: string;
  ipCheckStatus: string;
  fatPlanStartDate: string;
  fatPlanEndDate: string;
  satPlanStartDate: string;
  satPlanEndDate: string;
  ipCheckDesc: string;
  rmk: string;
  fdmProjectNo: string;
  fdmProjectName: string;
  wbsCode: string;
  useYn: string;
}

export const emptyIpCheckPlan: IpCheckPlan = {
  ipCheckPlanNo: '',
  copCd: '',
  ipCheckPlanType: '',
  ipCheckPlanName: '',
  ipCheckStatus: '',
  fatPlanStartDate: '',
  fatPlanEndDate: '',
  satPlanStartDate: '',
  satPlanEndDate: '',
  ipCheckDesc: '',
  rmk: '',
  fdmProjectNo: '',
  fdmProjectName: '',
  wbsCode: '',
  useYn: '',

  dataInsUserId: '',
  dataInsUserIp: '',
  dataInsDtm: '',
  dataUpdUserId: '',
  dataUpdUserIp: '',
  dataUpdDtm: '',

  dataInsUserNm: '',
  dataUpdUserNm: '',
};

export const emptyRangeDate = {
  startDate: '',
  endDate: '',
};
