/** @jsxImportSource @emotion/react */
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useRef, useState } from 'react';
import { CrudCode } from 'models/common/Edit';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import 'ui/css/multiSelect.css';
import WJCellTextarea from '../../../../components/inputs/WJCellTextarea';

import { GuideBlock } from 'pages/sample/guide/GuideComponent';
import useEvent from 'react-use-event-hook';

const SampleWijmo = () => {
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();

  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
  const [rowData, setRowData] = useState([
    {
      crudKey: CrudCode.READ,
      id: 1,
      desc: '사용자 1',
      name: '박문수',
      address: ['대한민국', '미국'],
      phone: '2024-10-13',
    },
    {
      crudKey: CrudCode.READ,
      id: 2,
      desc: '사용자 2',
      name: '홍길동',
      address: ['미국'],
      phone: new Date(),
    },
    {
      crudKey: CrudCode.READ,
      id: 3,
      desc: '사용자 3',
      name: '일지매',
      address: ['일본'],
      phone: new Date(),
    },
    {
      crudKey: CrudCode.READ,
      id: 4,
      desc: '사용자 4',
      name: '이몽룡',
      address: ['중국'],
      phone: new Date(),
    },
    {
      crudKey: CrudCode.READ,
      id: 5,
      desc: '사용자 5',
      name: '성춘향',
      address: ['에스토니아'],
      phone: new Date(),
    },
  ]);

  const LayoutDefinition = () => {
    return [
      {
        binding: 'id',
        align: 'left',
        width: 50,
        isReadOnly: true,
      },
      {
        header: '개인정보',
        align: 'center',
        columns: [
          {
            binding: 'name',
            header: '이름',
            width: 150,
            isReadOnly: true,
          },
          {
            binding: 'address', //주의: rowData의 필드가 배열형태 이어야 함.
            header: '주소',
            width: 150,
            isReadOnly: true,
          },
        ],
      },
      {
        binding: 'desc',
        header: 'TextArea 샘플',
        align: 'left',
        width: 350,
      },
      {
        binding: 'phone',
        align: 'center',
        width: 150,
        isReadOnly: true,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onFlexGridSelectionChanged = useEvent((s, e) => {
    if (s.rows == 0) return;
    const row = s.rows[e.row].dataItem;
    setSelectedRow(row);
  });

  const onInitialized = useEvent((grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setFlexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //볼일 보기 위해 노크
    grid.hostElement.addEventListener('click', (e) => {
      const ht = grid.hitTest(e);
      if (!ht.panel?.cellType || ht.panel?.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      setHitTest(ht);
      setFlexItem(item);
      switch (ht.panel.columns[ht.col].binding) {
        case 'desc': {
          setOpenCellTextarea(true);
          break;
        }
      }
    });
  });

  return (
    <div>
      <GuideBlock title={'Import'} isCode={true}>
        {`
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import 'ui/css/multiSelect.css';
import WJCellTextarea from '../WJCellTextarea';
`}
      </GuideBlock>
      <GuideBlock title={'Layout'} isCode={true}>
        {`
const [flexRef, setFlexRef] = useState<any>();
const [flexItem, setFlexItem] = useState<any>();
const [hitTest, setHitTest] = useState<any>();
const [selectedRow, setSelectedRow] = useState<any>();

const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
const [rowData, setRowData] = useState([
  {
    crudKey: CrudCode.READ,
    id: 1,
    desc: '사용자 1',
    name: '박문수',
    address: ['대한민국', '미국'],
    phone: '2024-10-13',
  },
  {
    crudKey: CrudCode.READ,
    id: 2,
    desc: '사용자 2',
    name: '홍길동',
    address: ['미국'],
    phone: new Date(),
  },
  {
    crudKey: CrudCode.READ,
    id: 3,
    desc: '사용자 3',
    name: '일지매',
    address: ['일본'],
    phone: new Date(),
  },
  {
    crudKey: CrudCode.READ,
    id: 4,
    desc: '사용자 4',
    name: '이몽룡',
    address: ['중국'],
    phone: new Date(),
  },
  {
    crudKey: CrudCode.READ,
    id: 5,
    desc: '사용자 5',
    name: '성춘향',
    address: ['에스토니아'],
    phone: new Date(),
  },
]);

const LayoutDefinition = () => {
  return [
    {
      binding: 'id',
      align: 'left',
      width: 50,
      isReadOnly: true,
    },
    {
      header: '개인정보',
      align: 'center',
      columns: [
        {
          binding: 'name',
          header: '이름',
          width: 150,
          isReadOnly: true,
        },
        {
          binding: 'address', //주의: rowData의 필드가 배열형태 이어야 함.
          header: '주소',
          width: 150,
          isReadOnly: true,
        },
      ],
    },
    {
      binding: 'desc',
      header: 'TextArea 샘플',
      align: 'left',
      width: 350,
    },
    {
      binding: 'phone',
      align: 'center',
      width: 150,
      isReadOnly: true,
    },
  ];
};

const state = {
  itemsSource: rowData,
  layoutDefinition: LayoutDefinition(),
};
`}
      </GuideBlock>
      <GuideBlock title={'Method'} isCode={true}>
        {`
const onFlexGridSelectionChanged = useEvent((s, e) => {
  if (s.rows == 0) return;
  const row = s.rows[e.row].dataItem;
  setSelectedRow(row);
});

const onInitialized = useEvent((grid) => {
  new Selector(grid);
  new FlexGridFilter(grid);
  setFlexRef(grid);

  //셀렉트 방식
  grid.selectionMode = SelectionMode.Row;

  //볼일 보기 위해 노크
  grid.hostElement.addEventListener('click', (e) => {
    const ht = grid.hitTest(e);
    if (!ht.panel?.cellType || ht.panel?.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

    const col = grid.columns[ht.col];
    const item = grid.rows[ht.row].dataItem;
    setHitTest(ht);
    setFlexItem(item);
    switch (ht.panel.columns[ht.col].binding) {
      case 'desc': {
        setOpenCellTextarea(true);
        break;
      }
    }
  });
});
`}
      </GuideBlock>
      <GuideBlock title={'Render'} isCode={true}>
        {`
<ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
  <FlexGrid
    columns={state.layoutDefinition}
    itemsSource={state.itemsSource}
    showMarquee={true}
    autoGenerateColumns={false}
    stickyHeaders={true}
    //isReadOnly={true}
    formatItem={flexGridTooltip}
    selectionChanged={onFlexGridSelectionChanged}
    initialized={onInitialized}
    style={{ height: '300px' }}
/>
</ContentGrid>
`}
      </GuideBlock>

      <GuideBlock title={'Example'} isCode={true}>
        <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
          <FlexGrid
            columns={state.layoutDefinition}
            itemsSource={state.itemsSource}
            showMarquee={true}
            autoGenerateColumns={false}
            stickyHeaders={true}
            //isReadOnly={true}
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: '300px' }}
          />
        </ContentGrid>
        {isOpenCellTextarea && (
          <WJCellTextarea
            grid={flexRef}
            hitTest={hitTest}
            close={() => setOpenCellTextarea(false)}
          />
        )}
      </GuideBlock>
      <GuideBlock title={'selected Row Data'} isCode={true}>
        <pre>{JSON.stringify(selectedRow, null, 2)}</pre>
      </GuideBlock>
    </div>
  );
};
export default SampleWijmo;
