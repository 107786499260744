/** @jsxImportSource @emotion/react */
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useState } from 'react';
import { Generic } from 'models/ip/Generic';
import { CrudCode } from 'models/common/Edit';
import { CommonYN } from 'models/common/Common';
import { ControlBtnGroup } from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';

import { EquipmentParameterCondition } from 'models/ip/EquipmentParameter';
import { findEquipmentParameterRequestList } from 'apis/ip/EquipmentParameter';
import { GuideBlock } from 'pages/sample/guide/GuideComponent';

const SampleWijmo = () => {
  const [rowData, setRowData] = useState<Generic[]>([]);
  const [flexRef, setflexRef] = useState<any>();

  const btnAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
      editYn: CommonYN.Y,
      A: '',
      B: '',
      C: '',
      D: '',
      E: '',
    } as Generic;
    setRowData([newRow, ...rowData]);
  };

  const btnDelRow = () => {
    flexRef.selectedRows.forEach(function (item) {
      flexRef.collectionView.remove(item.dataItem);
    });
  };

  const LayoutDefinition = () => {
    return [
      {
        binding: 'A',
        header: '컬럼 A',
        align: 'left',
        width: 240,
      },
      {
        binding: 'B',
        header: '컬럼 B',
        align: 'left',
        width: 150,
      },
      {
        binding: 'C',
        header: '컬럼 C',
        align: 'left',
        width: 150,
        isReadOnly: true,
      },
      {
        header: '헤더 D-E',
        align: 'center',
        columns: [
          {
            binding: 'D',
            header: '컬럼 D',
            width: 150,
            isReadOnly: true,
          },
          {
            binding: 'E',
            header: '컬럼 E',
            width: 150,
          },
        ],
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) return;
    const row = s.rows[e.row].dataItem;
  };

  const onInitialized = (grid) => {
    // let selector = new Selector(grid, {
    //   itemChecked: () => 원하는 함수()
    // });
    new Selector(grid);
    new FlexGridFilter(grid);
    setflexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;
  };

  return (
    <div>
      <GuideBlock title={'Import'} isCode={true}>
        {`
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
`}
      </GuideBlock>
      <GuideBlock title={'Layout'} isCode={true}>
        {`
  const LayoutDefinition = () => {
    return [
      {
        binding: 'A',
        header: '컬럼 A',
        align: 'left',
        width: 240,
      },
      {
        binding: 'B',
        header: '컬럼 B',
        align: 'left',
        width: 150,
      },
      {
        binding: 'C',
        header: '컬럼 C',
        align: 'left',
        width: 150,
        isReadOnly: true,
      },
      {
        header: '헤더 D-E',
        align: 'center',
        columns: [
          {
            binding: 'D',
            header: '컬럼 D',
            width: 150,
            isReadOnly: true,
          },
          {
            binding: 'E',
            header: '컬럼 E',
            width: 150,
          },
        ],
      },
    ];
  };
`}
      </GuideBlock>
      <GuideBlock title={'Button Event'} isCode={true}>
        {`
const btnAddRow = () => {
  const newRow = {
    crudKey: CrudCode.CREATE,
    useYn: CommonYN.Y,
    editYn: CommonYN.Y,
    A: '',
    B: '',
    C: '',
    D: '',
    E: '',
  } as Generic;
  setRowData([newRow, ...rowData]);
};

const btnDelRow = () => {
  flexRef.selectedRows.forEach(function (item) {
    flexRef.collectionView.remove(item.dataItem);
  });
};
`}
      </GuideBlock>
      <GuideBlock title={'Render'} isCode={true}>
        {`
<ControlBtnGroup>
  <Button css={IconButton.button} className="plus" disableRipple onClick={btnAddRow}>
    행추가
  </Button>
  <Button css={IconButton.button} className="minus" disableRipple onClick={btnDelRow}>
    행삭제
  </Button>
</ControlBtnGroup>

<ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
  <FlexGrid
    columns={state.layoutDefinition}
    itemsSource={state.itemsSource}
    showMarquee={true}
    autoGenerateColumns={false}
    stickyHeaders={true}
    formatItem={flexGridTooltip}
    selectionChanged={onFlexGridSelectionChanged}
    initialized={onInitialized}
    style={{ height: '300px' }}
  />
</ContentGrid>
`}
      </GuideBlock>

      <GuideBlock title={'Example'} isCode={true}>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="plus" disableRipple onClick={btnAddRow}>
            행추가
          </Button>
          <Button css={IconButton.button} className="minus" disableRipple onClick={btnDelRow}>
            행삭제
          </Button>
        </ControlBtnGroup>

        <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
          <FlexGrid
            columns={state.layoutDefinition}
            itemsSource={state.itemsSource}
            showMarquee={true}
            autoGenerateColumns={false}
            stickyHeaders={true}
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: '300px' }}
          />
        </ContentGrid>
      </GuideBlock>
    </div>
  );
};
export default SampleWijmo;
