/** @jsxImportSource @emotion/react */
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CodeDetailGrid from 'pages/admin/codemanagement/CodeDetailGrid';
import CodeGroupGrid from 'pages/admin/codemanagement/CodeGroupGrid';
import { CustomInputText, CustomInputWrap, UseToggle } from 'components/inputs/CustomInput';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { ComboBox } from '../../components/selects/ComboBox';
import { Code } from '../../models/common/CommonCode';
import { getCommonCodeNames } from '../../apis/admin/CommonCode';
import { IconButton } from '../../components/buttons/IconSVG';
import { Button } from '@mui/material';
import { ContentSection } from '../../components/layouts/ContentSection';
const CodeManagementPage: React.FC = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState<any>();

  const [textboxGrCd, setTextboxGrCd] = useState<string>('');
  const [textboxUseYn, setTextboxUseYn] = useState<any>('Y');
  const [textboxCode, setTextboxCode] = useState<string>('');

  const [searchGrCd, setSearchGrCd] = useState<string>('');
  const [searchUseYn, setSearchUseYn] = useState<string>('Y');
  const [searchCode, setSearchCode] = useState<string>('');

  const [searchDetailCd, setSearchDetailCd] = useState<string>('');
  const [searchParamData, setSearchParamData] = useState<any>(false);
  const [searchState, setSearchState] = useState<any>(false);

  // 사용여부 switch 형태로 변경
  // useEffect(() => {
  //   getCode();
  // }, []);

  // const getCode = async () => {
  //   const ynFlag: Code[] = await getCommonCodeNames('YN_FLAG');
  //   setCode({
  //     ynFlag: ynFlag,
  //   });
  // };

  const handleTextboxGrCd = (e: any) => {
    setTextboxGrCd(e.target.value);
  };

  const handleTextboxCode = (e: any) => {
    setTextboxCode(e.target.value);
  };

  const handleTextboxUseYn = (e: any) => {
    setTextboxUseYn(e.target.value === 'Y' ? 'N' : 'Y');
  };

  const btnSearch = () => {
    setSearchGrCd(textboxGrCd);
    setSearchUseYn(textboxUseYn);
    setSearchCode(textboxCode);
    setSearchDetailCd('');
    setSearchParamData(true);
    if (searchState == true) {
      setSearchState(false);
    }
    if (searchState == false) {
      setSearchState(true);
    }
  };

  const listner = useCallback((param: string) => {
    setSearchDetailCd(param);
  }, []);

  const btnReload = () => {
    setTextboxCode('');
    setTextboxGrCd('');
    setTextboxUseYn('Y');
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className=".none">
              <SearchCols>
                <label>{t('admin.grid.그룹코드명', '그룹코드명')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    value={textboxGrCd}
                    onChange={handleTextboxGrCd}
                    onKeyDown={onKeyDown}
                    placeholder={String(
                      t('admin.msg.그룹코드명을 입력해주세요.', '그룹코드명을 입력해 주세요.')
                    )}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              {/* <SearchCols>
                <label>{t('com.label.사용여부', '사용여부')}</label>
                <ComboBox
                  placeholder={'전체'}
                  options={code?.ynFlag}
                  defaultValue={''}
                  onChange={handleTextboxUseYn}
                />
              </SearchCols> */}
              <SearchCols>
                <label>{t('admin.label.공통코드/명', '공통코드/명')}</label>
                <CustomInputWrap>
                  <CustomInputText
                    value={textboxCode}
                    onChange={handleTextboxCode}
                    onKeyDown={onKeyDown}
                    placeholder={String(
                      t('admin.msg.공통코드/명을 입력해 주세요.', '공통코드/명을 입력해 주세요.')
                    )}
                  ></CustomInputText>
                </CustomInputWrap>
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.사용여부', '사용여부')}</label>
                <UseToggle className="switch">
                  <input
                    type="checkbox"
                    name="useYn"
                    value={textboxUseYn}
                    checked={textboxUseYn === 'Y'}
                    onChange={handleTextboxUseYn}
                  />
                  <span className="slider"></span>
                  <span
                    className="labels"
                    data-on={t('com.label.사용', '사용')}
                    data-off={t('com.label.미사용', '미사용')}
                  ></span>
                </UseToggle>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button css={IconButton.button} className="reload" disableRipple onClick={btnReload} />
            <Button css={IconButton.button} className="find" disableRipple onClick={btnSearch}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContentSection>
        <CodeGroupGrid
          sGrCd={searchGrCd}
          sUseYn={searchUseYn}
          sCode={searchCode}
          editable={true}
          callBack={listner}
          searchParamData={searchParamData}
          searchState={searchState}
        />
      </ContentSection>
      <ContentSection>
        <CodeDetailGrid sCmnGrCd={searchDetailCd} editable={true} />
      </ContentSection>
    </>
  );
};

export default CodeManagementPage;
