import { PaginationResponse } from 'models/common/Pagination';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { BaseImformationCondition } from 'models/ip/BaseImformation';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { Sensor, SensorCondition } from 'models/sensor/Sensor';
import { List } from 'lodash';
import { Code } from 'models/common/CommonCode';

/**센서 목록 조회 */
export const findSensorList = async (condition: SensorCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/sensor/sensorList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<Sensor[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as Sensor[];
};

export const setSensorFile = async (atchFileGrId?: string, snsrMgtNo?: string) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/file`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { atchFileGrId: atchFileGrId, snsrMgtNo: snsrMgtNo },
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return response as CommonResponse<DmlResponse>;
};

/**센서 엑셀 다운로드 */
export const getSensorListDownload = async (condition: ExcelDownloadRequest<SensorCondition>) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/sensorListDownload`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) {
    downloadFile(response.data, `${condition.fileName}`); //'data.xlsx' `${condition.fileName}`
  }
};

export const downloadFile = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**센서 목록 조회 */
export const findSensorLv3List = async (condition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/sensor/sensorList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<PaginationResponse<SensorCondition>> = await callApi(request);
  return (
    response.successOrNot === 'Y' ? response.data : null
  ) as PaginationResponse<SensorCondition>;
};
