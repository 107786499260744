import _ from 'lodash';
import { Row } from '@grapecity/wijmo.grid';
import { Code } from 'models/common/CommonCode';
import { UtMatrixDetail } from '../models/ut/UtMatrix';

/**
 * Electricity > 역률값수치 (PWFT_NVL) 기본값
 */
export const PWFT_NVL_DEFAULT = '0.9';

/**
 * 전력집계용량 계산
 * = Qty(Total) Elec * Capacity[KW]
 * @param elpwEqpQty
 * @param elpwCapa  전력용량
 */
export const calculateElpwSumCapa = (elpwEqpQty = 0, elpwCapa = 0) => {
  return parseFloat((elpwEqpQty * elpwCapa).toFixed(1));
};

/**
 * 소비집계용량 계산
 * = Qty(Total) Mech * Consumption[ℓ/min]
 * @param machEqpQty
 * @param cspCapa 소비용량
 */
export const calculateCspSumCapa = (machEqpQty = 0, cspCapa = 0) => {
  return parseFloat((machEqpQty * cspCapa).toFixed(1));
};

/**
 * 수요전력용량 계산 (검토화면에서 자동계산)
 * = Capacity Sum * Demand Factor[%]
 * @param elpwEqpQty
 * @param elpwCapa
 * @param dmdElpwTarfNvl
 */
export const calculateDmdElpwCapa = (elpwEqpQty = 0, elpwCapa = 0, dmdElpwTarfNvl = 0) => {
  return parseFloat(
    Math.round((calculateElpwSumCapa(elpwEqpQty, elpwCapa) * dmdElpwTarfNvl) / 100).toFixed(1)
  );
};

/**
 * [Current(계산값)] = "Phase ="3P“  이면   ＂Capacity[KW]＂*1000/SQRT(3)/＂Voltage[V]＂/＂Power factor＂
 *                                  아니면  , "Capacity[KW]"*1000/"Voltage[V]"/"Power factor"
 * @param elpwEqpQty
 * @param elpwPhasCd
 * @param elpwCapa
 * @param vltgNvl
 * @param pwftNvl
 */
export const calculateOd1EcNvl = (
  elpwEqpQty = 0,
  elpwPhasCd,
  elpwCapa = 0,
  vltgNvl = 0,
  pwftNvl = PWFT_NVL_DEFAULT
) => {
  if (!elpwPhasCd || Number(elpwEqpQty) < 1) return '';

  let calc = 0;
  if (elpwPhasCd === '3P') {
    calc = (elpwCapa * 1000) / Math.sqrt(3) / vltgNvl / Number(pwftNvl || PWFT_NVL_DEFAULT);
  } else {
    calc = (elpwCapa * 1000) / vltgNvl / Number(pwftNvl || PWFT_NVL_DEFAULT);
  }
  return parseFloat(calc.toFixed(1));
};

/**
 * 조건부 계산
 * 1. 사용자 입력일때 입력 값 사용
 * 2. 입력이 아닐때 자동 계산
 *
 * Current (계산값)[A] 과 Current(입력값)[A]  두 값 중 더 큰값을 기준으로 1.25배한 값보다 큰 가장 근접한 값 ( 대상 List : UT_BRKE_CAPA_LIST)을 자동 입력
 * 참고 : pkg_elm_xls_upload.upload_ut_matrix_list의 tb_eelmb_utm_d.brke_capa  계산로직 참고
 *
 * @param utBrkeCapaList
 * @param od1EcNvl
 * @param od2EcNvl
 */
export const calculateBrkeCapa = (utBrkeCapaList: Code[], od1EcNvl, od2EcNvl) => {
  if (_.isNil(od1EcNvl) && _.isNil(od2EcNvl)) {
    return null;
  }
  const greatest = Math.max(od1EcNvl, od2EcNvl) * 1.25;
  const result = (utBrkeCapaList || []).map((o) => Number(o.cmnCd)).filter((n) => n > greatest);

  if (result.length > 0) {
    return parseFloat(result[0].toFixed(1));
  }
  return null;
};

/**
 * 공장별 총합계(Total Sum) * 수용율(입력값)
 *
 * @param totalSum
 * @param demand
 */
export const calcuateActualLoad = (
  rows = [] as Row[],
  targetColNm = '',
  totalSumCd = 'Total Sum',
  demandCd = 'demand'
) => {
  const demandRows = (rows || []).map((o) => o.dataItem).filter((o) => o.prdnProcCd === 'demand');
  const totalSumRows = (rows || [])
    .map((o) => o.dataItem)
    .filter((o) => o.dtalProcCd === 'Total Sum');
  const demandRow = demandRows.length > 0 ? demandRows[0] : null;
  const totalSumRow = totalSumRows.length > 0 ? totalSumRows[0] : null;
  if (demandRow && totalSumRow && targetColNm) {
    const demand = Number(demandRow[targetColNm] || 0);
    const totalSum = Number(totalSumRow[targetColNm] || 0);
    return parseFloat((totalSum * demand).toFixed(1)).toLocaleString();
  }
  return '';
};
