/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Code } from 'models/common/CommonCode';
import { UploadTemplatePopUp } from 'pages/common/popup/UploadTemplatePopUp';
import { getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { useTranslation } from 'react-i18next';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { UploadTplCondition } from 'models/admin/UploadTemplate';
import {
  getTemplateList,
  getTemplateHistoryList,
  handleUploadTplAtchFileDownload,
  getTemplateListMp,
} from 'apis/admin/UploadTemplate';
import dayjs from 'dayjs';
// Wijmo library
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { ContentSection } from 'components/layouts/ContentSection';
import CustomDialog from '../../../components/modals/common/CustomDialog';
import { hasRole } from '../../../utils/SessionUtil';

type Props = { open: boolean; close: () => void };

const MpItemTemplatePopUp = (props: Props) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [tplData, setTplData] = useState<any[]>([]);
  const [tplHisData, setTplHisData] = useState<any[]>([]);
  const [tplRef, setTplRef] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [tplHisRef, setTplHisRef] = useState<any>();
  const [isOpenUploadTemplatePopUp, setOpenUploadTemplatePopUp] = useState<boolean>(false);
  const [uploadTplCondition, setUploadTplCondition] = useState<UploadTplCondition>({
    tplType: '',
    tpCd: '',
    fileNm: '',
    chCont: '',
    tplFileId: '',
  });
  const [colWidthMap, setColWidthMap] = useState<any>({
    no: 70,
    tplFileNm: 300,
    versionNo: 90,
    WijmoPlay: 100,
    dataUpdDtm: 180,
    tplType: 200,
    description: 200,
    changeDesc: '*',
  });
  const [colWidthMapHis, setColWidthMapHis] = useState<any>({
    no: 70,
    tplFileNm: 300,
    versionNo: 90,
    WijmoPlay: 100,
    dataUpdDtm: 180,
    tplType: 200,
    description: 200,
    changeDesc: '*',
    updUser: 140,
  });
  const [isMpRoleYn, setMpRoleYn] = useState<boolean>(true);

  useEffect(() => {
    setMpRoleYn(hasRole('MP_MANAGER') || hasRole('ADM'));
    getCommonCodesForGrid();
    handleSearch();
  }, []);

  const getCommonCodesForGrid = async () => {
    const mpClsfType: Code[] = await getCommonCodeNamesCondition({
      cmnGrCd: 'ATCH_FILE_TP_CD',
      optValCtn2: 'TPL_MP',
    }); // MP 유형만 조회
    setCode(mpClsfType);
  };

  const handleSearchParam = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUploadTplCondition({
      ...uploadTplCondition,
      [name]: value,
    });
  };

  const handleRefresh = () => {
    handleSearch().then(() => handleSearchHis(flexItem));
  };

  const handleResetCondition = () => {
    setUploadTplCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as UploadTplCondition
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSearch();
  };

  const handleSearch = async () => {
    await getTemplateListMp(uploadTplCondition).then((result) => {
      if (!result) {
        // openMessageBar({
        //   type: 'error',
        //   content: t('mp.msg.Item정보가없습니다.', 'Item정보가없습니다.'),
        // });
        // menuContext.closeCurrentTab(menuContext);
        // return;
      }

      setTplData(result);
      setTplHisData([]);
    });
    // masterRef.current.save();
  };

  const handleSearchHis = (params) => {
    getTemplateHistoryList({ tplFileId: params.tplFileId, tpCd: params.tpCd }).then((result) => {
      setTplHisData(result || []);
    });
  };

  const LayoutDefinition = () => {
    return [
      // {
      //   width: 37,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      //   headerClass: 'headerCheck disable',
      //   cssClass: 'cellCheck',
      // },
      {
        header: String(t('com.label.NO', 'NO')),
        width: colWidthMap.no,
        align: 'center',
        isReadOnly: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'tplFileId',
        visible: false,
      },
      {
        binding: 'tpCd',
        visible: false,
      },
      {
        binding: 'atchFileGrId',
        visible: false,
      },
      {
        binding: 'atchFileId',
        visible: false,
      },
      {
        binding: 'tplCg',
        cellTemplate: (params) => {
          if (params.item.tpCd) {
            if (params.item.tpCd.toUpperCase().indexOf('MP') !== -1) return 'mp';
            else if (params.item.tpCd.toUpperCase().indexOf('IP') !== -1) return 'ip';
            else if (params.item.tpCd.toUpperCase().indexOf('SNSR') !== -1) return 'snsr';
            else if (params.item.tpCd.toUpperCase().indexOf('GTNG') !== -1) return 'GTNG';
            else return 'etc';
          }
        },
        visible: false,
      },
      {
        header: String(t('admin.label.템플릿유형', '템플릿유형')),
        binding: 'tplType',
        align: 'center',
        isReadOnly: true,
        width: colWidthMap.tplType,
      },
      {
        header: String(t('admin.grid.설명', '설명')),
        binding: 'description',
        isReadOnly: true,
        align: 'center',
        width: colWidthMap.description,
      },
      {
        header: String(t('com.label.파일명', '파일명')),
        binding: 'tplFileNm',
        align: 'left',
        isReadOnly: true,
        cellTemplate: CellMaker.makeLink({
          text: '${value}',
          click: (e, ctx) => {
            handleUploadTplAtchFileDownload(ctx.item);
          },
        }),
        width: colWidthMap.tplFileNm,
      },
      {
        header: String(t('admin.grid.버전', '버전')),
        binding: 'versionNo',
        align: 'center',
        isReadOnly: true,
        width: colWidthMap.versionNo,
        cssClass: 'WijmoPopup',
        cellTemplate: (item) => {
          if (item.value == null || item.value == '') {
            item.value = '';
          }
          if (isMpRoleYn) {
            return `<span>${item.value}</span><button id="btnUploadTemplatePopup" />`;
          } else {
            return `<span>${item.value}</span>`;
          }
        },
      },
      {
        header: String(t('admin.grid.변경이력보기', '변경이력보기')),
        width: 100,
        align: 'center',
        cssClass: 'WijmoPlay',
        cellTemplate: (item) => {
          if (item.item.versionNo == null || item.item.versionNo == '') {
            return '';
          } else {
            return '<span></span><button id="btnPlay"></button>';
          }
        },
        isReadOnly: true,
      },
      {
        header: String(t('admin.grid.변경내용', '변경내용')),
        binding: 'changeDesc',
        isReadOnly: true,
        align: 'left',
        width: colWidthMap.changeDesc,
        cellTemplate: (params) => {
          if (params.value == null || params.value == '') {
            return '';
          }
          return params.value;
        },
      },
      {
        header: String(t('com.label.수정일시', '수정일시')),
        binding: 'dataUpdDtm',
        isReadOnly: true,
        align: 'center',
        // cellStyle: { textAlign: 'center' },
        width: colWidthMap.dataUpdDtm,
        cellTemplate: (params) => {
          if (params.value == null || params.value == '') {
            return '';
          } else {
            return dayjs(params.value).format('YYYY.MM.DD HH:mm:ss').toString();
          }
        },
      },
      {
        header: String(t('com.label.수정자', '수정자')),
        binding: 'updUser',
        isReadOnly: true,
        align: 'center',
        // cellStyle: { textAlign: 'center' },
        minWidth: 140,
      },
    ];
  };

  const LayoutDefinitionHis = () => {
    return [
      {
        header: String(t('com.label.NO', 'NO')),
        width: colWidthMapHis.no,
        align: 'center',
        isReadOnly: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'tplFileId',
        visible: false,
      },
      {
        binding: 'tpCd',
        visible: false,
      },
      {
        binding: 'atchFileGrId',
        visible: false,
      },
      {
        binding: 'atchFileId',
        visible: false,
      },
      {
        header: String(t('com.label.파일명', '파일명')),
        binding: 'tplFileNm',
        isReadOnly: true,
        cellTemplate: CellMaker.makeLink({
          text: '${value}',
          click: (e, ctx) => {
            handleUploadTplAtchFileDownload(ctx.item);
          },
        }),
        width: colWidthMapHis.tplFileNm,
      },
      {
        header: String(t('admin.grid.버전', '버전')),
        binding: 'versionNo',
        align: 'center',
        isReadOnly: true,
        width: colWidthMapHis.versionNo,
      },
      {
        header: String(t('admin.grid.변경내용', '변경내용')),
        binding: 'changeDesc',
        isReadOnly: true,
        align: 'left',
        width: colWidthMapHis.changeDesc,
        cellTemplate: (params) => {
          if (params.value == null || params.value == '') {
            return '';
          }
          return params.value;
        },
      },
      {
        header: String(t('com.label.수정일시', '수정일시')),
        binding: 'dataUpdDtm',
        isReadOnly: true,
        align: 'center',
        width: colWidthMapHis.dataUpdDtm,
        cellTemplate: (params) => {
          if (params.value == null || params.value == '') {
            return '';
          } else {
            return dayjs(params.value).format('YYYY.MM.DD HH:mm:ss').toString();
          }
        },
      },
      {
        header: String(t('com.label.수정자', '수정자')),
        binding: 'updUser',
        isReadOnly: true,
        align: 'center',
        // cellStyle: { textAlign: 'center' },
        width: colWidthMapHis.updUser,
      },
    ];
  };

  const state = {
    itemsSource: tplData,
    layoutDefinition: LayoutDefinition(),
  };

  const stateHis = {
    itemsSource: tplHisData,
    layoutDefinition: LayoutDefinitionHis(),
  };

  const onInitialized = (grid) => {
    new FlexGridFilter(grid);
    setTplRef(grid);
    grid.rowHeaders.columns.splice(0, 1);

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      setflexItem(item);
      // setFieldId(col.binding);

      switch (e.target.id) {
        case 'btnPlay':
          handleSearchHis(item);
          // handleButtonClick({ data: item });
          break;
        case 'btnUploadTemplatePopup':
          setOpenUploadTemplatePopUp(true);
          break;
        case 'fileAttach':
          handleUploadTplAtchFileDownload(item);
          break;
      }

      grid.refresh();
    });
  };

  const onInitializedHis = (grid) => {
    setTplHisRef(grid);
    grid.rowHeaders.columns.splice(0, 1);
    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      colWidthMapHis[col.binding] = col.width;
      setColWidthMapHis(colWidthMapHis);
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      // setflexItem(item);
      // setFieldId(col.binding);

      grid.refresh();
    });
  };

  const handlOnClose = () => {
    props.close();
  };

  return (
    <>
      <CustomDialog
        title={t('mp.label.보고서/회의록 템플릿', '보고서/회의록 템플릿')}
        open={props.open}
        size={'xl'}
        onClose={handlOnClose}
        onCancel={handlOnClose}
      >
        {/*<SearchBox>*/}
        {/*  <SearchBoxRow>*/}
        {/*    <InputBox>*/}
        {/*      <SearchRows>*/}
        {/*        <SearchCols>*/}
        {/*          <label>*/}
        {/*            <span>{t('admin.label.템플릿유형', '템플릿유형')}</span>*/}
        {/*          </label>*/}
        {/*          <ComboBox*/}
        {/*            placeholder={String(*/}
        {/*              t('admin.label.템플릿유형', '템플릿유형') +*/}
        {/*                t('admin.msg.을 선택해 주세요.', '을 선택해 주세요.')*/}
        {/*            )}*/}
        {/*            options={code}*/}
        {/*            defaultValue={uploadTplCondition?.tplType}*/}
        {/*            isError={errors?.tplType}*/}
        {/*            msgError={String(*/}
        {/*              t('admin.label.템플릿유형', '템플릿유형') +*/}
        {/*                t('admin.msg.을 선택해 주세요.', '을 선택해 주세요.')*/}
        {/*            )}*/}
        {/*            onChange={(value) => {*/}
        {/*              setUploadTplCondition({ ...uploadTplCondition, tplType: value });*/}
        {/*            }}*/}
        {/*          />*/}
        {/*        </SearchCols>*/}
        {/*        <SearchCols>*/}
        {/*          <label>{t('com.label.파일명', '파일명')}</label>*/}
        {/*          <CustomInputWrap>*/}
        {/*            <CustomInputText*/}
        {/*              name="fileNm"*/}
        {/*              value={uploadTplCondition.fileNm}*/}
        {/*              placeholder={String(*/}
        {/*                t('com.label.파일명을 입력해 주세요.', '파일명을 입력해 주세요.')*/}
        {/*              )}*/}
        {/*              onChange={handleSearchParam}*/}
        {/*              onKeyDown={handleKeyDown}*/}
        {/*            ></CustomInputText>*/}
        {/*          </CustomInputWrap>*/}
        {/*        </SearchCols>*/}
        {/*        <SearchCols>*/}
        {/*          <label>{t('admin.grid.변경내용', '변경내용')}</label>*/}
        {/*          <CustomInputWrap>*/}
        {/*            <CustomInputText*/}
        {/*              name="chCont"*/}
        {/*              value={uploadTplCondition.chCont}*/}
        {/*              placeholder={String(*/}
        {/*                t('com.label.변경내용을 입력해 주세요.', '변경내용을 입력해 주세요.')*/}
        {/*              )}*/}
        {/*              onChange={handleSearchParam}*/}
        {/*              onKeyDown={handleKeyDown}*/}
        {/*            ></CustomInputText>*/}
        {/*          </CustomInputWrap>*/}
        {/*        </SearchCols>*/}
        {/*      </SearchRows>*/}
        {/*    </InputBox>*/}
        {/*    <SearchButtonWrap>*/}
        {/*      <Button*/}
        {/*        css={IconButton.button}*/}
        {/*        className="reload"*/}
        {/*        onClick={handleResetCondition}*/}
        {/*        disableRipple*/}
        {/*      ></Button>*/}
        {/*      <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>*/}
        {/*        {t('com.button.조회', '조회')}*/}
        {/*      </Button>*/}
        {/*    </SearchButtonWrap>*/}
        {/*  </SearchBoxRow>*/}
        {/*</SearchBox>*/}
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>
              {t('com.label.보고서/회의록 템플릿', '보고서/회의록 템플릿') +
                t('com.label.목록', '목록')}
            </h3>
            <span className="total">
              {t('com.label.총', '총')} <span>{(tplData || []).length.toLocaleString()}</span>
              {t('com.label.건', '건')}
            </span>
          </SubTitleGroup>
        </SubTitleLayout>
        <ContentGrid className={(tplData || []).length < 1 ? 'noData' : ''}>
          <FlexGrid
            columnGroups={state.layoutDefinition}
            itemsSource={state.itemsSource}
            deferResizing={false}
            initialized={onInitialized}
            style={{ height: (tplData || []).length < 1 ? '' : '200px' }}
          />
          <div className="noData" style={{ height: (tplData || []).length < 1 ? '200px' : '' }}>
            <span>
              {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
            </span>
          </div>
        </ContentGrid>
        {isOpenUploadTemplatePopUp && (
          <UploadTemplatePopUp
            open={isOpenUploadTemplatePopUp}
            setPopup={setOpenUploadTemplatePopUp}
            condition={flexItem}
            close={handleRefresh}
          />
        )}
        <ContentSection>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>
                {t('com.label.보고서/회의록 템플릿', '보고서/회의록 템플릿') +
                  ' ' +
                  t('com.label.변경이력', '변경이력')}
              </h3>
              <span className="total">
                {t('com.label.총', '총')} <span>{(tplHisData || []).length.toLocaleString()}</span>
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
          </SubTitleLayout>
          <ContentGrid className={tplHisData.length < 1 ? 'noData' : ''}>
            <FlexGrid
              columnGroups={stateHis.layoutDefinition}
              itemsSource={stateHis.itemsSource}
              deferResizing={false}
              initialized={onInitializedHis}
              style={{ height: tplHisData.length < 1 ? '' : '300px' }}
            />
            <div className="noData" style={{ height: tplHisData.length < 1 ? '300px' : '' }}>
              <span>
                {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
              </span>
            </div>
          </ContentGrid>
        </ContentSection>
      </CustomDialog>
    </>
  );
};

export default MpItemTemplatePopUp;
