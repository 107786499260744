import axios from 'axios';
import { useMessageBar } from 'hooks/useMessageBar';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ExcelUploadSample = () => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { openMessageBar } = useMessageBar();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setSelectedFile(fileList[0]);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios
          .post(`http://localhost:8080/v1/excel/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
            },
          })
          .then((response) => {
            if (response.data.statusCode == 'FAIL') {
              openMessageBar({
                type: 'error',
                content: t('com.label.첨부파일을 확인해 주세요.', '첨부파일을 확인해 주세요.'),
              });
            }
          });
      } catch (error) {
        console.error(t('com.label.파일 업로드 실패', '파일 업로드 실패'), ':', error);
        openMessageBar({
          type: 'error',
          content: t('com.label.파일 업로드 실패', '파일 업로드 실패'),
        });
      }
    } else {
      openMessageBar({
        type: 'error',
        content: t('com.label.첨부파일이 없습니다.', '첨부파일이 없습니다.'),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="file" onChange={handleFileChange} />
      <button type="submit">{t('com.label.사용', '사용')}Excel Upload</button>
    </form>
  );
};

export default ExcelUploadSample;
