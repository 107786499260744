/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import _ from 'lodash';
import { EquipRowData } from 'pages/common/popup/GatingEquipPopUp';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import { getEquipments } from 'apis/gtng/popup/GatingEquipment';

type Props = {
  open: boolean;
  close: () => void;
  isSelector?: boolean;
  condition?: {
    gatingId: string;
  };
  onCallback: (item: EquipRowData | null) => void;
};
/**
 * 게이팅 대상설비 목록 조회 팝업 (읽기전용)
 * 사용 화면 : 평가/채점
 *            게이팅 생성
 * @param open
 * @param close
 * @param isSelector
 * @param condition
 * @param onCallback
 * @constructor
 */
export const GatingEquipmentListPopUp = ({
  open,
  close,
  isSelector = false,
  condition,
  onCallback,
}: Props) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<EquipRowData[]>([]);
  const [flexItem, setflexItem] = useState<EquipRowData | null>(null);

  /**
   * init
   */
  useEffect(() => {
    initCondition();
  }, []);

  /**
   * 장비 조회
   */
  const initCondition = async () => {
    await getEquipments(condition?.gatingId, '').then((result: any) => {
      if (result != null && result != '') {
        setRowData(result);
      }
    });
  };

  const onInitialized = (grid) => {
    // 선택 팝업인 경우에만 클릭이벤트 적용
    if (isSelector) {
      grid.hostElement.addEventListener('click', (e) => {
        const len = grid.rows.length;
        if (len == 0) return;
        const ht = grid.hitTest(e);
        if (ht.row < 0 || ht.col < 0) return;
        if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

        // row 선택시 체크박스 체크
        for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
        grid.rows[ht.row].isSelected = true;

        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
        setflexItem(item);
        if (e.target instanceof HTMLButtonElement) {
          switch (e.target.id) {
            case 'btnSelect':
              btnQuickSelect(item);
              break;
          }
        }
      });

      grid.hostElement.addEventListener('dblclick', (e) => {
        const ht = grid.hitTest(e);
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
        btnQuickSelect(item);
      });
    }
  };

  const layoutDefinition = [
    {
      cssClass: 'WijmoRelay',
      cellTemplate: '<Button id="btnSelect"/>',
      width: 39,
      visible: isSelector,
    },
    {
      binding: 'equipmentId',
      header: String(t('gtng.grid.Equipment ID', 'Equipment ID')),
      align: 'center',
      width: '*',
    },
    {
      binding: 'equipmentName',
      header: String(t('gtng.grid.Equipment Desc', 'Equipment Desc')),
      align: 'center',
      width: '*',
    },
    {
      binding: 'progressStatusName',
      header: String(t('com.label.진행상태', '진행상태')),
      align: 'center',
      width: '*',
    },
  ];

  /**
   * 적용(선택) 버튼
   */
  const handleConfirm = () => {
    onCallback(flexItem);
    close();
  };

  const btnQuickSelect = (item) => {
    onCallback(!_.isNull(item) ? item : null);
    close();
  };

  const dialogButtons = [
    <Button
      key={'select'}
      css={IconButton.button}
      className="select"
      onClick={handleConfirm}
      disableRipple
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  return (
    <>
      <CustomDialog
        title={t('gtng.label.점검장비', '점검장비현황')}
        open={open}
        size={'md'}
        buttons={isSelector ? dialogButtons : null}
        onClose={close}
        onCancel={isSelector ? close : undefined}
      >
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('gtng.label.점검장비', '점검장비현황')}</h3>
            <span className="total">
              {t('com.label.총', '총')}
              <span>{(rowData || []).length.toLocaleString()}</span>
              {t('com.label.건', '건')}
            </span>
          </SubTitleGroup>
        </SubTitleLayout>
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={300}
          rowSelection={'single'}
          frozenColumns={2}
          isFilter={false}
          isReadOnly={true}
          allowPinning={false}
          isSelector={isSelector}
          align="center"
          initialized={onInitialized}
          onChangeCheckedItem={(items) => setflexItem((items || []).length > 0 ? items[0] : null)}
        />
      </CustomDialog>
    </>
  );
};
export default GatingEquipmentListPopUp;
