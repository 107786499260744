/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useEvent from 'react-use-event-hook';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DateRangeType } from 'react-tailwindcss-datepicker';
import { Button } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { DataType, toggleClass } from '@grapecity/wijmo';
import { DataMap, SelectionMode, CellType, ICellTemplateContext } from '@grapecity/wijmo.grid';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import _, { toNumber } from 'lodash';
import { Code } from 'models/common/CommonCode';
import { IconButton } from 'components/buttons/IconSVG';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import {
  ProcessBarWrap,
  ProcessNum,
  ProcessStep,
  ProcessStepBox,
  StepBox,
  ProcessText,
  ProgressBar,
} from 'components/process/Process';
import { tb } from 'components/layouts/Table';
import { ContentSection } from 'components/layouts/ContentSection';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import CustomGrid from 'components/grids/CustomGrid';
import { ComboBox } from 'components/selects/ComboBox';
import CustomDatepicker from 'components/inputs/CustomDatepicker';
import WJCellTextarea from 'components/inputs/WJCellTextarea';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import {
  GatingCheckResultItem,
  GatingCheckResultMst,
  GatingCheckResultMstCondition,
} from 'models/gtng/GatingCheckResult';
import {
  confirmGtngCheck,
  getGatingCheckResultItemList,
  getGatingCheckResultMst,
  retrieveGtngEquipN,
  saveGtngCheckItem,
  saveEquipment,
} from 'apis/gtng/GatingCheckResult';
import { CrudCode } from 'models/common/Edit';
import { commonYNcodes } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { hasRole } from 'utils/SessionUtil';
import { getExcelFileName } from 'utils/ExcelUtil';
import {
  convertPercentScore,
  GatingCheckItemGradeCodes,
  getInspectionResultCode,
  InspectionResultCodes,
} from 'utils/GatingUtil';
import useSessionStore from 'stores/useSessionStore';
import { GatingCheckGuidePopUp } from 'pages/gtng/popup/GatingCheckGuidePopUp';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import { GatingCheckResultPopUp } from 'pages/gtng/popup/GatingCheckResultPopUp';
import GatingNoPopUp from './popup/GatingNoPopUp';
import { GatingEquipmentListPopUp } from './popup/GatingEquipmentListPopUp';
import ApproveRequestModal from 'pages/approves/ApproveRequestModal';
import { ApproveRequestPageType } from '../approves/ApproveRequestDetailCase';
import { useLoading } from 'components/process/Loading';
import { checkAvailableRequestEquipment } from 'apis/gtng/GatingChangeRequest';
import { GatingReqType } from './gatingcheckresult/GatingChangeRequest';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import UserModal from 'components/modals/common/UserModal';
import { Employee } from 'models/admin/Employee';

/**
 * 게이팅 평가/채점
 * @constructor
 */
const GatingCheckResultPage = () => {
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { userId } = useSessionStore();
  const gridRef = useRef<any>();
  const progressStatus = useRef<string>();
  const isCancel = useRef<boolean>(false);
  const isStatusRequest = useRef<boolean>(false);
  const originRowDataEquip = useRef<GatingCheckResultMst>();
  const [searchParams] = useSearchParams();
  const [gatingCheckResultParamCondition, setGatingCheckResultParamCondition] =
    useState<GatingCheckResultMstCondition>({
      gatingId: searchParams.get('gatingId') || '',
      gatingNo: searchParams.get('gatingNo') || '',
      equipmentId: searchParams.get('equipmentId') || '',
      equipmentName: searchParams.get('equipmentName') || '',
    });

  const [getGatingCheckItemId, setGatingCheckItemId] = useState<string>('');

  const [rowData, setRowData] = useState<GatingCheckResultMst>();
  const [rowDataEquip, setRowDataEquip] = useState<GatingCheckResultMst>();
  const [rowDataCheckList, setRowDataCheckList] = useState<GatingCheckResultItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [getAllContData, setAllContData] = useState<any>(null);
  const [getAllContDataId, setAllContDataId] = useState<string>('');
  const [getAllContDataNm, setAllContDataNm] = useState<string>('');

  const [gatingProgressStatusCodeB, setGatingProgressStatusCodeB] = useState<Code[]>([]);
  const [gatingGradeCode, setGatingGradeCode] = useState<Code[]>([]);
  const [okFlagCode, setOkFlagCode] = useState<Code[]>([]);
  const [getGatingMgrTp, setGatingMgrTp] = useState<Code[]>([]);

  const [getGatingMgrTpSelected, setGatingMgrTpSelected] = useState<string>('');

  const [equipEditYn, setEquipEditYn] = useState<boolean>(false);
  const [isOpenGatingNoPopUp, setOpenGatingNoPopUp] = useState<boolean>(false);
  const [isOpenContEmpPopUp, setOpenContEmpPopUp] = useState<boolean>(false);
  const [isOpenAllContEmpPopUp, setOpenAllContEmpPopUp] = useState<boolean>(false);
  const [getContUserIds, setContUserIds] = useState<string>('');
  const [contPopTitle, setContPopTitle] = useState<string>(''); //검색할 파라미터 상태관리
  const [userModalCondition, setUserModalCondition] = useState({
    defaultUserId: '',
    target: {
      binding: '',
      row: -1,
      col: -1,
    },
  });

  const [isOpenEquipmentPopUp, setOpenEquipmentPopUp] = useState<boolean>(false);
  const [isOpenChkRstPopUp, setOpenChkRstPopUp] = useState<boolean>(false);
  const [isOpenGuidePopUp, setOpenGuidePopUp] = useState<boolean>(false);
  const [isOpenFileUploadPopUp, setOpenFileUploadPopUp] = useState<boolean>(false);
  const [isOpenGatingContPopUp, setOpenGatingContPopUp] = useState<any>(false);
  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [requestModalCondition, setRequestModalCondition] = useState<any>({});

  const [errors, setErrors] = useState<any>({});
  const [initParamFileUpload, setInitParamFileUpload] = useState<any>();

  const [isBtnSave, setBtnSave] = useState<boolean>(false);
  const [isStatusChange, setStatusChange] = useState<boolean>(false);
  const [hitTest, setHitTest] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();

  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<GatingCheckResultItem[]>();
  const [hasManagerAuth, setHasManagerAuth] = useState(false);

  // 담당자 변경 활성화 여부 (수정 가능 단계: 점검준비, 중간점검, 최종점검)
  const activeChangeContact = useMemo(
    () =>
      'E' !== rowData?.progressStatusCode &&
      ['B1', 'B2', 'B3'].includes(rowDataEquip?.progressStatusCode || ''),
    [rowData, rowDataEquip]
  );
  // 점검일자 변경 가능 여부 (수정 가능 단계: 중간점검, 최종점검, 점검완료 / 저장 권한과 동일)
  const activeChangeDate = useMemo(() => {
    if (
      'E' === rowData?.progressStatusCode ||
      !['B2', 'B3', 'B4'].includes(rowDataEquip?.progressStatusCode || '')
    ) {
      return false;
    }
    // 중간점검인 경우
    if ('B2' === rowDataEquip?.progressStatusCode) {
      const nEditableFlag = (rowDataCheckList || []).filter((o) => o.neditableFlag == 'Y');
      return hasManagerAuth || nEditableFlag.length > 0;
    }
    // 최종점검, 점검완료인 경우
    const yEditableFlag = (rowDataCheckList || []).filter((o) => o.yeditableFlag == 'Y');
    return hasManagerAuth || yEditableFlag.length > 0;
  }, [rowData, rowDataEquip, rowDataCheckList, hasManagerAuth]);
  // 점검단계 변경 가능 여부 (수정 가능 단계: 중간점검, 최종점검, 점검완료 / 저장 권한과 동일)
  const activeChangeStep = useMemo(() => {
    if (
      'E' === rowData?.progressStatusCode ||
      !['B2', 'B3', 'B4'].includes(rowDataEquip?.progressStatusCode || '')
    ) {
      return false;
    }
    // 중간점검인 경우
    if ('B2' === rowDataEquip?.progressStatusCode) {
      const nEditableFlag = (rowDataCheckList || []).filter((o) => o.neditableFlag == 'Y');
      return hasManagerAuth || nEditableFlag.length > 0;
    }
    // 최종점검, 점검완료인 경우
    const yEditableFlag = (rowDataCheckList || []).filter((o) => o.yeditableFlag == 'Y');
    return hasManagerAuth || yEditableFlag.length > 0;
  }, [rowData, rowDataEquip, rowDataCheckList, hasManagerAuth]);

  useEffect(() => {
    if (searchParams.get('gatingId') && searchParams.get('equipmentId')) {
      handleSearch(gatingCheckResultParamCondition);
    }
  }, [searchParams]);

  useEffect(() => {
    setInitComponent();
  }, [rowDataCheckList]);

  /**************************************************************
   * 초기 데이터 설정
   * ************************************************************/
  useEffect(() => {
    getCommonCodes();

    // 관리자 권한 여부
    setHasManagerAuth(hasRole('NIGS_MANAGER') || hasRole('ADM'));
  }, []);

  /**
   * 공통 코드 조회
   */
  const getCommonCodes = async () => {
    const gatingGradeCode: Code[] = await getCommonCodeNames('GATING_CHECK_ITEM_GRADE_CODE');
    const processStatusCodeB: Code[] = await getCommonCodeNames('GATING_PROGRESS_STATUS_CODE_B');
    const okflagCode: Code[] = await getCommonCodeNames('GATING_INSPECTION_RESULT_CODE');

    const gatingMgrTp: Code[] = await getCommonCodeNamesCondition({
      optValCtn1: 'EXECUTE',
      cmnGrCd: 'GATING_MGR_TP',
    });

    setOkFlagCode(okflagCode);
    setGatingProgressStatusCodeB(processStatusCodeB);
    setGatingGradeCode(gatingGradeCode);
    setGatingMgrTp(gatingMgrTp);
  };

  /**************************************************************
   * 조회관련
   * ************************************************************/

  /**
   * 조회 검색 조건 값 변경
   * @param e
   */
  const handleParamConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setGatingCheckResultParamCondition({
      ...gatingCheckResultParamCondition,
      [e.target.name]: e.target.value,
    });
    setErrors((prev) => ({ ...prev, [e.target.name]: false }));
  };

  /**
   * Gating진척관리 -> Gating진행현황 -> 결과조회/작성 click event
   * @param sCheckResultParam(gatingId, gatingNo, equipmentId, equipmentName)
   */
  const location = useLocation();
  const state = { ...location.state };
  const navigate = useNavigate();
  useEffect(() => {
    if (state.sCheckResultParam != undefined) {
      handleSearch(state.sCheckResultParam);
      setGatingCheckResultParamCondition(state.sCheckResultParam);
    }
  }, [state.sCheckResultParam]);

  /**
   * 조회 버튼 이벤트
   * @param condition
   */
  const handleSearch = async (condition: GatingCheckResultMstCondition) => {
    // 관리자 권한인 경우 전체 조회
    condition.gatingContId = hasManagerAuth ? '' : userId;

    setErrors({
      gatingNo: _.isEmpty(condition.gatingNo),
      equipmentName: _.isEmpty(condition.equipmentName),
    });

    if (_.isEmpty(condition.gatingNo) || _.isEmpty(condition.equipmentName)) {
      return;
    }

    const response = await getGatingCheckResultMst({
      ...condition,
    });
    const responseEquip = await retrieveGtngEquipN({
      ...condition,
    });
    const responseCheckList = await getGatingCheckResultItemList({
      ...condition,
    });

    if (response) {
      response.equipmentId = condition.equipmentId;

      setRowData(response);
      // 취소, 취소요청인 경우
      isCancel.current = response?.progressStatusCode === 'E' || response?.cancelRequestYn === 'Y';
      // 단계변경 요청인 경우
      isStatusRequest.current = response?.statusRequestYn === 'Y';

      if (responseEquip) {
        response.aprReqId = responseEquip.aprReqId;
        response.aprReqProgStatYn = responseEquip.aprReqProgStatYn;
        setRowDataEquip(responseEquip);
        progressStatus.current = responseEquip.progressStatusCode;
        originRowDataEquip.current = responseEquip;

        if (responseCheckList) {
          setTotalCount(responseCheckList.length);

          responseCheckList.forEach((value, index) => {
            value['no'] = index + 1;
            value.crudKey = CrudCode.READ;
          });

          setRowDataCheckList(responseCheckList);
        }
      }
    }
  };

  /**
   * form init
   */
  const setInitComponent = () => {
    const sStatusCode = rowDataEquip?.progressStatusCode || '';

    //조치실행(준비)execute
    const aEditableFlag = rowDataCheckList.filter((o) => o.aeditableFlag == 'Y');
    //중간점검check
    const nEditableFlag = rowDataCheckList.filter((o) => o.neditableFlag == 'Y');
    //최종점검execute_tl
    const yEditableFlag = rowDataCheckList.filter((o) => o.yeditableFlag == 'Y');

    // 완료이거나 취소/취소요청, 단계변경요청인 경우 저장, 완료 버튼 숨기기
    if (sStatusCode == 'B4' || isCancel.current || isStatusRequest.current) {
      setStatusChange(false); //완료버튼
      setBtnSave(false);
    } else {
      // 관리자 권한이거나 조치실행담당자인 경우
      if (sStatusCode === 'B1' && (hasManagerAuth || aEditableFlag.length > 0)) {
        //2022.12.12.HY.[Evidence 등록 완료] 버튼은 "조치/실행담당자(Engineer)", "Gating Admin" 만 조회/사용
        setStatusChange(true); //완료버튼
        setBtnSave(true);
      } else if (sStatusCode === 'B2' && (hasManagerAuth || nEditableFlag.length > 0)) {
        setStatusChange(true); //완료버튼
        setBtnSave(true);
      } else if (sStatusCode === 'B3' && (hasManagerAuth || yEditableFlag.length > 0)) {
        setStatusChange(true); //완료버튼
        setBtnSave(true);
      } else {
        setStatusChange(false); //완료버튼
        setBtnSave(false);
      }
    }
  };

  /**
   * 저장
   */
  const handleSave = () => {
    // 점검준비 단계인 경우 장비 점검일자 변경 여부 확인
    const isChangeEquip =
      rowDataEquip?.progressStatusCode === 'B1' &&
      (originRowDataEquip.current?.startDate !== rowDataEquip?.startDate ||
        originRowDataEquip.current?.endDate !== rowDataEquip?.endDate);

    const saveDataItem = rowDataCheckList.filter(
      (o) => o.crudKey !== CrudCode.READ && o.crudKey !== null
    );

    if ((!saveDataItem || saveDataItem.length < 1) && !isChangeEquip) {
      openMessageBar({
        type: 'warning',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        // 대상 장비 정보 저장 (점검일자 변경)
        const resEquip = isChangeEquip ? await saveEquipment(rowDataEquip) : null;
        // 점검 항목 정보 저장
        const resCheck = (saveDataItem || []).length ? await saveGtngCheckItem(saveDataItem) : null;
        if (
          (resEquip != null && resEquip?.successOrNot === 'N') ||
          (resCheck != null && resCheck?.successOrNot === 'N')
        ) {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }

        openMessageBar({
          type: 'confirm',
          content: t('com.label.저장되었습니다.', '저장되었습니다.'),
        });
        handleSearch(gatingCheckResultParamCondition);
      },
    });
  };

  /**
   * 등록완료 / Evidence 점검완료 / 중간점검 완료 / 최종점검 완료 버튼
   */
  const handleConfirm = async () => {
    // B1 점검준비
    if ('B1' === progressStatus.current) {
      let oChk = 0;
      let oTotal = 0;
      // B1 : 점검준비
      const valid = rowDataCheckList
        .map((o, index) => {
          if (_.isEmpty(o.useYn))
            return `${o.subSeqNo} : ${t(
              'gtng.msg.진행 여부를 선택해 주세요.',
              '진행 여부를 선택해 주세요.'
            )}\n`;
          if (o.useYn === 'Y') {
            if (o.gatingCheckItemGradeCode == 'O') {
              oChk++;
            }
            oTotal++;
            if (_.isEmpty(o.gatingCheckItemGradeCode)) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.등급을 선택해 주세요.',
                '등급을 선택해 주세요.'
              )}\n`;
            }
            if (_.isNull(o.prorateScore) || o.prorateScore == '') {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.배점 기준을 입력해 주세요.',
                '배점 기준을 입력해 주세요.'
              )}\n`;
            }
            if (!(Number(o.prorateScore) > 0)) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.배점 기준은 0점 초과로 입력해 주세요.',
                '배점 기준은 0점 초과로 입력해 주세요.'
              )}\n`;
            }
            if (_.isNull(o.goodBasisScore) || o.goodBasisScore == '') {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.OK 기준 점수를 입력해 주세요.',
                'OK 기준 점수를 입력해 주세요.'
              )}\n`;
            }
            if (!(Number(o.goodBasisScore) > 0)) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.OK 기준은 0점 초과로 입력해 주세요.',
                'OK 기준은 0점 초과로 입력해 주세요.'
              )}\n`;
            }
            if (_.isNull(o.levelScore) || o.levelScore == '') {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.C-OK 기준 점수를 입력해 주세요.',
                'C-OK 기준 점수를 입력해 주세요.'
              )}\n`;
            }
            if (!(Number(o.levelScore) > 0)) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.C-OK 기준은 0점 초과로 입력해 주세요.',
                'C-OK 기준은 0점 초과로 입력해 주세요.'
              )}\n`;
            }
            if (_.isEmpty(o.actionContactId)) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.실시담당자를 입력해 주세요.',
                '실시담당자를 입력해 주세요.'
              )}\n`;
            }
            if (_.isEmpty(o.checkerId)) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.점검 담당자를 입력해 주세요.',
                '점검 담당자를 입력해 주세요.'
              )}\n`;
            }
            if (_.isEmpty(o.completionCheckerId)) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.실시(T/L) 담당자를 입력해 주세요.',
                '실시(T/L) 담당자를 입력해 주세요.'
              )}\n`;
            }
          }
        })
        .filter((element) => element !== undefined);

      if (oChk == oTotal) {
        openMessageBar({
          type: 'error',
          content: t(
            'gtng.msg.필수또는 Critical 등급 항목이 1건 이상 존재해야합니다.',
            '필수또는 Critical 등급 항목이 1건 이상 존재해야합니다.'
          ),
        });
        return;
      }
      if (valid.length) {
        const content = valid[0]?.toString();
        openMessageBar({ type: 'error', content: content || '' });
        return;
      }
    }
    // B2 중간점검
    else if ('B2' === progressStatus.current) {
      const valid = rowDataCheckList
        .map((o, index) => {
          if (o.useYn === 'Y') {
            if (o.gatingCheckItemGradeCode !== 'O' && toNumber(o.fileCountTl) < 1)
              return `${o.subSeqNo} : ${t(
                'gtng.msg.중간점검 첨부파일을 등록해주세요.',
                '중간점검 첨부파일을 등록해주세요.'
              )}\n`;
            if (_.isNull(o.nevaluationScore) || o.nevaluationScore === '')
              return `${o.subSeqNo} : ${t(
                'gtng.msg.중간점검 점수를 입력해주세요.',
                '중간점검 점수를 입력해주세요.'
              )}\n`;
            if (_.isEmpty(o.ninspectionResultCode))
              return `${o.subSeqNo} : ${t(
                'gtng.msg.중간점검 OK여부를 선택해주세요.',
                '중간점검 OK여부를 선택해주세요.'
              )}\n`;
            if (o.gatingCheckItemGradeCode !== 'O' && o.ncpltYn !== 'Y')
              return `${o.subSeqNo} : ${t(
                'gtng.msg.중간점검 완료 여부를 체크해주세요.',
                '중간점검 완료 여부를 체크해주세요.'
              )}\n`;
          }

          // Critical 등급 항목인 경우
          if (GatingCheckItemGradeCodes.CRITICAL === o.gatingCheckItemGradeCode) {
            // 불합격인 경우 다음 진행 단계 불가
            if (InspectionResultCodes.NG === o.ninspectionResultCode) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.점검결과 NG가 있는 경우 다음단계를 진행할수 없습니다.',
                '점검결과 NG가 있는 경우 다음단계를 진행할수 없습니다.'
              )}\n`;
            }
            // 조건부합격인 경우 점검결과내용 필수 입력
            if (
              InspectionResultCodes.C_OK === o.ninspectionResultCode &&
              !(o.ncheckResultContent || '').length
            ) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.점검결과를 입력해주세요.',
                '점검결과를 입력해주세요.'
              )}\n`;
            }
          }

          const code = getInspectionResultCode(
            o.nevaluationScore,
            toNumber(o.prorateScore),
            o.goodBasisScore,
            o.levelScore
          );
          // 환산점수로 나온 점검결과(OK여부)를 사용자가 변경한 경우 점검결과내용 필수 입력
          if (code && code != o.ninspectionResultCode && !(o.ncheckResultContent || '').length) {
            return `${o.subSeqNo} : ${t(
              'gtng.msg.점검결과를 입력해주세요.',
              '점검결과를 입력해주세요.'
            )}\n`;
          }
        })
        .filter((element) => element !== undefined);

      if (valid.length) {
        const content = valid[0]?.toString();
        openMessageBar({ type: 'error', content: content || '' });
        return;
      }
    }
    // B3 최종점검
    else if ('B3' === progressStatus.current) {
      // validation check
      const valid = rowDataCheckList
        .map((o, index) => {
          if (o.useYn === 'Y') {
            if (_.isNull(o.yevaluationScore) || o.yevaluationScore === '')
              return `${o.subSeqNo} : ${t(
                'gtng.msg.최종점검 점수를 입력해주세요.',
                '최종점검 점수를 입력해주세요.'
              )}\n`;
            if (_.isEmpty(o.yinspectionResultCode))
              return `${o.subSeqNo} : ${t(
                'gtng.msg.최종점검 OK여부를 선택해주세요.',
                '최종점검 OK여부를 선택해주세요.'
              )}\n`;
            if (o.gatingCheckItemGradeCode != 'O' && o.ycpltYn != 'Y')
              return `${o.subSeqNo} : ${t(
                'gtng.msg.최종점검 완료 여부를 체크해주세요.',
                '최종점검 완료 여부를 체크해주세요.'
              )}\n`;
          }

          // Critical 등급 항목인 경우
          if (GatingCheckItemGradeCodes.CRITICAL === o.gatingCheckItemGradeCode) {
            // 불합격인 경우 다음 진행 단계 불가
            if (InspectionResultCodes.NG === o.yinspectionResultCode) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.점검결과 NG가 있는 경우 다음단계를 진행할수 없습니다.',
                '점검결과 NG가 있는 경우 다음단계를 진행할수 없습니다.'
              )}\n`;
            }
            // 조건부합격인 경우 점검결과내용 필수 입력
            if (
              InspectionResultCodes.C_OK === o.yinspectionResultCode &&
              !(o.ycheckResultContent || '').length
            ) {
              return `${o.subSeqNo} : ${t(
                'gtng.msg.점검결과를 입력해주세요.',
                '점검결과를 입력해주세요.'
              )}\n`;
            }
          }

          const code = getInspectionResultCode(
            o.yevaluationScore,
            toNumber(o.prorateScore),
            o.goodBasisScore,
            o.levelScore
          );
          // 환산점수로 나온 점검결과(OK여부)를 사용자가 변경한 경우 점검결과내용 필수 입력
          // 원래 값과 다른 결과를 선택하는 경우 사유 적도록 안내
          if (code && code !== o.yinspectionResultCode && !(o.ycheckResultContent || '').length) {
            return `${o.subSeqNo} : ${t(
              'gtng.msg.점검결과를 입력해주세요.',
              '점검결과를 입력해주세요.'
            )}\n`;
          }
        })
        .filter((element) => element !== undefined);

      if (valid.length) {
        const content = valid[0]?.toString();
        openMessageBar({ type: 'error', content: content || '' });
        return;
      }
    }

    // 준비완료, 중간점검, 최종점검
    if (['B1', 'B2', 'B3'].includes(progressStatus.current || '')) {
      fnConfirm();
    }
  };

  // 등록완료 / 중간점검 완료
  const fnConfirm = () => {
    if (rowData == null) return;

    let content = '';
    if ('B1' === rowDataEquip?.progressStatusCode) {
      content = t('gtng.label.점검준비를 완료하시겠습니까?', '점검준비를 완료하시겠습니까?');
    } else if ('B2' === rowDataEquip?.progressStatusCode) {
      content = t('gtng.label.중간점검을 완료하시겠습니까?', '중간점검을 완료하시겠습니까?');
    } else if ('B3' === rowDataEquip?.progressStatusCode) {
      content = t('gtng.label.최종점검을 완료하시겠습니까?', '최종점검을 완료하시겠습니까?');
    }

    if (!content) {
      openMessageBar({
        type: 'error',
        content: t('com.label.잘못된 접근입니다.', '잘못된 접근입니다.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: content,
      yesCallback: async () => {
        openLoading(true);
        // 점검준비 단계인 경우 장비 점검일자 변경 여부 확인
        const isChangeEquip =
          rowDataEquip?.progressStatusCode === 'B1' &&
          (originRowDataEquip.current?.startDate !== rowDataEquip?.startDate ||
            originRowDataEquip.current?.endDate !== rowDataEquip?.endDate);

        const saveDataItem = rowDataCheckList.filter(
          (o) => o.crudKey !== CrudCode.READ && o.crudKey !== null
        );

        // 변경된 데이터가 있는 경우 저장
        if ((saveDataItem && saveDataItem.length) || isChangeEquip) {
          // 대상 장비 정보 저장 (점검일자 변경)
          const resEquip = isChangeEquip ? await saveEquipment(rowDataEquip) : null;
          // 점검 항목 정보 저장
          const resCheck = (saveDataItem || []).length
            ? await saveGtngCheckItem(saveDataItem)
            : null;
          if (
            (resEquip != null && resEquip?.successOrNot === 'N') ||
            (resCheck != null && resCheck?.successOrNot === 'N')
          ) {
            openLoading(false);
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return;
          }
        }
        // 점검완료 처리
        confirmGtngCheck(rowData).then((response) => {
          if (response != null && response.successOrNot == 'N') {
            openMessageBar({
              type: 'error',
              content: t(
                'com.label.점검완료 처리 중 오류가 발생했습니다.',
                '점검완료 처리 중 오류가 발생했습니다.'
              ),
            });
            openLoading(false);
            return;
          }
          openLoading(false);
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          handleSearch(gatingCheckResultParamCondition);
        });
      },
    });
  };

  /**
   * 점검 장비 단계 ProcessStep
   */
  const processStepsB = useMemo(() => {
    let currentStepNo = -1;
    return (gatingProgressStatusCodeB || []).reduce((acc, cur, idx) => {
      const isNow = rowDataEquip?.progressStatusCode === cur.cmnCd;
      if (isNow) currentStepNo = idx;
      return [
        ...acc,
        {
          ...cur,
          cmnCdDesc: isNow
            ? 'now'
            : Object.keys(rowDataEquip || {}).length > 0 && currentStepNo < 0
            ? 'done'
            : '',
        },
      ];
    }, [] as Code[]);
  }, [gatingProgressStatusCodeB, rowDataEquip]);

  //사용자 검색 팝업
  const empPopupOpen = (params: any) => {
    switch (params.binding) {
      case 'actionContactName':
        if (params.item.actionContactId == null || params.item.actionContactId == '') {
          return false;
        } else {
          setContUserIds(params.item.actionContactId);
        }
        break;
      case 'checkerName':
        if (params.item.checkerId == null || params.item.checkerId == '') {
          return false;
        } else {
          setContUserIds(params.item.checkerId);
        }
        break;
      case 'completionCheckerName':
        if (params.item.completionCheckerId == null || params.item.completionCheckerId == '') {
          return false;
        } else {
          setContUserIds(params.item.completionCheckerId);
        }
        break;
      case 'contExecuteName':
        if (params.item.contExecuteIds == null || params.item.contExecuteIds == '') {
          return false;
        } else {
          setContUserIds(params.item.contExecuteIds);
        }
        break;
      case 'contCheckName':
        if (params.item.contCheckIds == null || params.item.contCheckIds == '') {
          return false;
        } else {
          setContUserIds(params.item.contCheckIds);
        }
        break;
      case 'contExecuteTlName':
        if (params.item.contExecuteTlIds == null || params.item.contExecuteTlIds == '') {
          return false;
        } else {
          setContUserIds(params.item.contExecuteTlIds);
        }
        break;
    }

    setContPopTitle(params.header);
    setOpenContEmpPopUp(true);
  };

  const elmEmpPopupOpen = (params: any) => {
    const binding = params.binding;
    switch (binding) {
      case 'actionContactName':
        setUserModalCondition({
          defaultUserId: params.item.actionContactId,
          target: {
            binding: binding,
            row: params.row,
            col: params.col,
          },
        });
        break;
      case 'checkerName':
        setUserModalCondition({
          defaultUserId: params.item.checkerId,
          target: {
            binding: binding,
            row: params.row,
            col: params.col,
          },
        });
        break;
      case 'completionCheckerName':
        setUserModalCondition({
          defaultUserId: params.item.completionCheckerId,
          target: {
            binding: binding,
            row: params.row,
            col: params.col,
          },
        });
        break;
    }
    setOpenGatingContPopUp(true);
  };

  const btnEmpPop = (params: any) => {
    // 담당자 변경 가능한 상태인 경우
    if (params?.activeChangeContact && !isCancel.current && !isStatusRequest.current) {
      return `<span>${params.value || ''}</span><button id="btnElmEmpPopup" />`;
    }
    // 단순 조회용 팝업
    return `<span>${params.value || ''}</span><button id="btnContPop" />`;
  };

  const onBtExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = String(t('gtng.label.Gating Information', 'Gating Information'));
    book.saveAsync(
      getExcelFileName(String(t('gtng.label.Gating 평가/채점(Advice)', 'Gating 평가/채점(Advice)')))
    );
  };

  /**
   * 첨부파일 팝업 열기
   * @param item
   * @param row
   * @param col
   * @param atchFileTpCd
   */
  const handleFileUpload = async (item, row, col, atchFileTpCd: 'GTNG_S_RST' | 'GTNG_F_RST') => {
    /*
    [24.03.12] 첨부파일 업로드 후 재조회 로직 삭제 -> 편집하던 데이터가 사라지지 않기땜에 저장 불필요
    const saveDataItem = rowDataCheckList.filter(
      (o) => o.crudKey !== CrudCode.READ && o.crudKey !== null
    );

    if (!saveDataItem || saveDataItem.length > 0) {
      saveGtngCheckItem(saveDataItem).then((response) => {
        if (response != null && response.successOrNot == 'N') {
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return;
        }
      });
    }
    */

    let downloadOnly = true;
    let fileCount = 0;
    // 중간점검 결과 > 중간점검첨부
    if ('GTNG_S_RST' === atchFileTpCd) {
      downloadOnly =
        progressStatus.current !== 'B2' ||
        item.neditableFlag !== 'Y' ||
        isCancel.current ||
        isStatusRequest.current;
      fileCount = item.fileCountTl;
    }
    // 최종점검 결과 > 최종점검첨부
    else if ('GTNG_F_RST' === atchFileTpCd) {
      downloadOnly =
        progressStatus.current !== 'B3' ||
        item.yeditableFlag !== 'Y' ||
        isCancel.current ||
        isStatusRequest.current;
      fileCount = item.fileCountPmo;
    }

    // [24.03.12] 다운로드할 첨부파일이 없는 경우 팝업 미노출
    if (downloadOnly && fileCount < 1) {
      return;
    }

    setInitParamFileUpload({
      atchFileGrId: item.atchFileGrId,
      atchFileTpCd: atchFileTpCd,
      tableName: 'tb_elm_gtng_equip_n',
      bizName: 'gtng',
      downloadOnly: downloadOnly,
      target: {
        item: item,
        row: row,
        col: col,
      },
    });
    setOpenFileUploadPopUp(true);
  };

  interface InspectionResultCellTemplateProps extends ICellTemplateContext {
    resultOkngCodes: Code[];
  }

  const InspectionResultCellTemplate = ({
    resultOkngCodes,
    ...params
  }: InspectionResultCellTemplateProps) => {
    // 값이 없는 경우
    if (!params.value) return '';

    const dataMapItem = params.col?.dataMap?.getDataItem(params.value);
    if (dataMapItem && dataMapItem?.cmnCdNm) return `${dataMapItem?.cmnCdNm}`;

    const codes = (resultOkngCodes || []).filter((o) => o.cmnCd === params.value);
    if (codes) return `${codes[0]?.cmnCdNm}`;

    return `${params.value}`;
  };

  const layoutDefinition = useMemo(() => {
    // 점검준비 단계 여부
    const isReadyStep = 'B1' === rowDataEquip?.progressStatusCode;
    // 판정, 완료 or 중간점검, 최종점검, 점검완료인 경우
    const tlColumn =
      ['C', 'D'].includes(rowData?.progressStatusCode || '') ||
      ['B2', 'B3', 'B4'].includes(rowDataEquip?.progressStatusCode || '');
    // 판정, 완료 or 최종점검, 점검완료인 경우
    const pmoColumn =
      ['C', 'D'].includes(rowData?.progressStatusCode || '') ||
      ['B3', 'B4'].includes(rowDataEquip?.progressStatusCode || '');
    // 취소, 취소요청인 경우
    const isCancel = 'E' === rowData?.progressStatusCode;

    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        align: 'center',
        isReadOnly: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 40,
        align: 'center',
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        header: String(t('gtng.grid.항목순번', '항목순번')),
        binding: 'subSeqNo',
        width: 120,
        isReadOnly: true,
        cellTemplate: (p) => {
          if (
            rowDataEquip?.progressStatusCode === 'B1' ||
            (rowDataEquip?.progressStatusCode === 'B2' && p.item.neditableFlag !== 'Y') ||
            (rowDataEquip?.progressStatusCode === 'B3' && p.item.yeditableFlag !== 'Y')
          ) {
            return p.value || '';
          } else {
            return `<a href="javascript:void(0)" id="openLinkChkRstPopUp">${p.value}</a>`;
          }
        },
      },
      {
        header: String(t('gtng.grid.진행여부', '진행여부')),
        binding: 'useYn',
        width: 120,
        visible: isReadyStep,
        cssClass: 'WijmoSelect',
        dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
      },
      {
        header: String(t('gtng.grid.등급', '등급')),
        binding: 'gatingCheckItemGradeCode',
        width: 120,
        cssClass: isReadyStep ? 'WijmoSelect' : '',
        isReadOnly: !isReadyStep,
        dataMap: new DataMap(gatingGradeCode, 'cmnCd', 'cmnCdNm'),
      },
      {
        header: String(t('gtng.grid.점검구분', '점검구분')),
        align: 'center',
        columns: [
          {
            header: '1',
            binding: 'gatingType1Value',
            align: 'left',
            width: 200,
            isReadOnly: true,
          },
          {
            header: '2',
            binding: 'gatingType2Value',
            align: 'left',
            width: 200,
            isReadOnly: true,
          },
          {
            header: '3',
            binding: 'gatingType3Value',
            align: 'left',
            width: 200,
            isReadOnly: true,
          },
        ],
      },
      {
        header: String(t('gtng.label.점검항목', '점검항목')),
        align: 'center',
        columns: [
          {
            binding: 'gatingCheckItem1Value',
            header: String(t('gtng.grid.Check List', 'Check List')),
            width: 200,
            isReadOnly: true,
            align: 'left',
          },
          {
            binding: 'gatingCheckItem2Value',
            header: String(t('gtng.grid.Check 방법/계측/검사', 'Check 방법/계측/검사')),
            width: 200,
            isReadOnly: true,
            align: 'left',
          },
          {
            binding: 'gatingCheckItem3Value',
            header: String(t('gtng.grid.판단기준/Spec/목표', '판단기준/Spec/목표')),
            width: 200,
            isReadOnly: true,
            align: 'left',
          },
        ],
      },
      {
        header: String(t('com.label.담당자', '담당자')),
        align: 'center',
        columns: [
          {
            header: String(t('gtng.label.실시담당자', '실시담당자')),
            binding: 'actionContactName',
            width: 150,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            align: 'left',
            cellTemplate: (params) =>
              btnEmpPop({ ...params, activeChangeContact: activeChangeContact }),
          },
          {
            header: 'actionContactId',
            binding: 'actionContactId',
            width: 150,
            visible: false,
          },
          {
            header: String(t('gtng.label.점검담당자', '점검담당자')),
            binding: 'checkerName',
            width: 150,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            align: 'left',
            cellTemplate: (params) =>
              btnEmpPop({ ...params, activeChangeContact: activeChangeContact }),
          },
          {
            header: 'checkerId',
            binding: 'checkerId',
            width: 150,
            visible: false,
          },
          {
            header: String(t('gtng.label.실시(T/L)', '실시(T/L)')),
            binding: 'completionCheckerName',
            width: 150,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            align: 'left',
            cellTemplate: (params) =>
              btnEmpPop({ ...params, activeChangeContact: activeChangeContact }),
          },
          {
            header: 'completionCheckerId',
            binding: 'completionCheckerId',
            width: 150,
            visible: false,
          },
        ],
      },
      {
        header: String(t('gtng.label.결과 기준', '결과 기준')),
        columns: [
          {
            binding: 'prorateScore',
            header: String(t('gtng.grid.배점', '배점')),
            width: 80,
            align: 'center',
            isReadOnly: !isReadyStep || isCancel,
            dataType: DataType.Number,
          },
          {
            binding: 'goodBasisScore',
            header: String(t('gtng.grid.OK 기준', 'OK 기준')),
            align: 'center',
            width: 100,
            isReadOnly: !isReadyStep || isCancel,
            dataType: DataType.Number,
          },
          {
            binding: 'levelScore',
            header: String(t('gtng.grid.C-OK 기준', 'C-OK 기준')),
            align: 'center',
            width: 120,
            isReadOnly: !isReadyStep || isCancel,
            dataType: DataType.Number,
          },
          {
            header: String(t('gtng.grid.비고', '비고')),
            width: 200,
            binding: 'noteContent',
            align: 'left',
            isReadOnly: true, // progressStatus.current !== 'B1', // textarea 팝업인 경우 클릭이벤트에서 제어
            isRequired: false,
          },
        ],
      },
      {
        header: String(t('gtng.grid.최종수정', '최종수정')),
        width: 200,
        isReadOnly: true,
        align: 'center',
        binding: 'dataUpdUserNm',
      },
      // 중간점검 결과
      {
        header: String(t('gtng.grid.중간점검 결과', '중간점검 결과')),
        visible: tlColumn,
        align: 'center',
        columns: [
          {
            header: String(t('gtng.grid.점수', '점수')),
            binding: 'nevaluationScore',
            visible: tlColumn,
            align: 'center',
            dataType: DataType.Number,
            width: 80,
          },
          {
            // binding: 'nevaluationScore',
            header: String(t('gtng.grid.환산점수', '환산점수')),
            visible: tlColumn,
            align: 'center',
            dataType: DataType.Number,
            width: 100,
            isReadOnly: true,
            cellTemplate: (params) =>
              convertPercentScore(params.item.nevaluationScore, params.item.prorateScore),
          },
          {
            binding: 'ninspectionResultCode',
            header: String(t('gtng.grid.OK여부', 'OK여부')),
            visible: tlColumn,
            align: 'center',
            width: 120,
            dataMap: new DataMap(okFlagCode, 'cmnCd', 'cmnCdNm'),
            cellTemplate: (params) =>
              InspectionResultCellTemplate({
                ...params,
                resultOkngCodes: okFlagCode,
              }),
          },
          {
            binding: 'ncheckResultContent',
            header: String(t('gtng.grid.점검결과', '점검결과')),
            visible: tlColumn,
            width: 200,
            isRequired: false,
            isReadOnly: true, // progressStatus.current !== 'B2', // textarea 팝업인 경우 클릭이벤트에서 제어1234
            align: 'left',
          },
          {
            binding: 'fileCountTl',
            header: String(t('gtng.grid.중간점검첨부', '중간점검첨부')),
            align: 'center',
            width: 130,
            isReadOnly: true,
            visible: tlColumn,
            cellTemplate: (params) =>
              `<div class="${
                params.value < 1 &&
                (progressStatus.current !== 'B2' || params.item.neditableFlag !== 'Y' || isCancel)
                  ? ''
                  : 'fileDiv'
              }">
                  <span>
                      <em>${(params.value || 0).toLocaleString()}</em>
                  </span>
               </div>`,
          },
          {
            binding: 'ncpltYn',
            header: String(t('gtng.grid.점검완료체크', '점검완료체크')),
            width: 130,
            visible: tlColumn,
            align: 'center',
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'nlastUpdatedByName',
            header: String(t('gtng.grid.최종수정', '최종수정')),
            visible: tlColumn,
            isReadOnly: true,
            align: 'center',
            width: 210,
          },
        ],
      },
      // 최종점검 결과
      {
        header: String(t('gtng.grid.최종점검결과', '최종점검결과')),
        visible: pmoColumn,
        align: 'center',
        width: 100,
        columns: [
          {
            binding: 'yevaluationScore',
            header: String(t('gtng.grid.점수', '점수')),
            visible: pmoColumn,
            align: 'center',
            dataType: DataType.Number,
            width: 80,
          },
          {
            // binding: 'yevaluationScore',
            header: String(t('gtng.grid.환산점수', '환산점수')),
            visible: pmoColumn,
            align: 'center',
            dataType: DataType.Number,
            width: 100,
            isReadOnly: true,
            cellTemplate: (params) =>
              convertPercentScore(params.item.yevaluationScore, params.item.prorateScore),
          },
          {
            binding: 'yinspectionResultCode',
            header: String(t('gtng.grid.OK여부', 'OK여부')),
            visible: pmoColumn,
            align: 'center',
            width: 120,
            dataMap: new DataMap(okFlagCode, 'cmnCd', 'cmnCdNm'),
            cellTemplate: (params) =>
              InspectionResultCellTemplate({
                ...params,
                resultOkngCodes: okFlagCode,
              }),
          },
          {
            binding: 'ycheckResultContent',
            header: String(t('gtng.grid.점검결과', '점검결과')),
            visible: pmoColumn,
            width: 200,
            isRequired: false,
            isReadOnly: true, // progressStatus.current !== 'B3', // textarea 팝업인 경우 클릭이벤트에서 제어111
            align: 'left',
          },
          {
            header: String(t('gtng.grid.최종점검첨부', '최종점검첨부')),
            binding: 'fileCountPmo',
            align: 'center',
            width: 130,
            isReadOnly: true,
            visible: pmoColumn,
            cellTemplate: (params) =>
              `<div class="${
                params.value < 1 &&
                (progressStatus.current !== 'B3' || params.item.yeditableFlag !== 'Y')
                  ? ''
                  : 'fileDiv'
              }">
                  <span>
                      <em>${(params.value || 0).toLocaleString()}</em>
                  </span>
               </div>`,
          },
          {
            binding: 'ycpltYn',
            header: String(t('gtng.grid.점검완료체크', '점검완료체크')),
            width: 130,
            visible: pmoColumn,
            align: 'center',
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'ypmoComment',
            header: String(t('gtng.grid.Advice', 'Advice')),
            visible: pmoColumn,
            width: 200,
            isReadOnly: true, // progressStatus.current !== 'B3', // textarea 팝업인 경우 클릭이벤트에서 제어
            align: 'left',
          },
          {
            binding: 'ylastUpdatedByName',
            header: String(t('gtng.grid.최종수정', '최종수정')),
            visible: pmoColumn,
            align: 'center',
            isReadOnly: true,
            width: 210,
          },
        ],
      },
    ];
  }, [gatingGradeCode, okFlagCode, rowData?.progressStatusCode, rowDataEquip?.progressStatusCode]);

  useEffect(() => {
    if (getAllContData != null && getAllContData != '') {
      const contInfos = getAllContData?.contInfo || [];
      setAllContDataNm(contInfos.map((o) => o.empNm).join(','));
      setAllContDataId(contInfos.map((o) => o.userId).join(','));
    }
  }, [getAllContData]);

  /**
   * 담당자 일괄 변경
   */
  const onBtContAll = () => {
    if (!getGatingMgrTpSelected) {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.담당자구분을 선택해 주세요.', '담당자구분을 선택해 주세요.'),
      });
      return;
    }
    if (getAllContDataId === '' || getAllContDataNm === '') {
      openMessageBar({
        type: 'error',
        content: t('com.label.담당자를 선택해 주세요.', '담당자를 선택해 주세요.'),
      });
      return;
    }
    if ((checkedItems || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t('gtng.msg.일괄적용할 항목을 선택해주세요.', '일괄적용할 항목을 선택해주세요.'),
      });
      return;
    }
    if ('CHECK' === getGatingMgrTpSelected) {
      // 점검담당자
      (checkedItems || []).forEach((o) => {
        o.checkerName = getAllContDataNm;
        o.checkerId = getAllContDataId;
        o.crudKey = CrudCode.UPDATE;
      });
    } else if ('EXECUTE' === getGatingMgrTpSelected) {
      // 실시담당자
      (checkedItems || []).forEach((o) => {
        o.actionContactName = getAllContDataNm;
        o.actionContactId = getAllContDataId;
        o.crudKey = CrudCode.UPDATE;
      });
    } else if ('EXECUTE_TL' === getGatingMgrTpSelected) {
      // 실시TL
      (checkedItems || []).forEach((o) => {
        o.completionCheckerName = getAllContDataNm;
        o.completionCheckerId = getAllContDataId;
        o.crudKey = CrudCode.UPDATE;
      });
    }
    gridRef.current?.collectionView?.refresh();
  };

  const onCellEditEnding = (grid, e) => {
    const newVal = grid.activeEditor?.value;
    const data = grid.rows[e.row].dataItem;
    const binding = grid.columns[e.col].binding;
    // 결과 기준 > 배점
    if ('prorateScore' === binding) {
      if (!_.isNull(newVal) && newVal != '') {
        if (Number(newVal) > 10) {
          openMessageBar({
            type: 'error',
            content: t('gtng.msg.10점 이하로 입력하세요.', '10점 이하로 입력하세요.'),
          });
          e.cancel = true;
          return;
        }
        if (!(Number(newVal) > 0)) {
          openMessageBar({
            type: 'error',
            content: t('gtng.msg.0점 초과로 입력하세요.', '0점 초과로 입력하세요.'),
          });
          e.cancel = true;
          return;
        }
      }
    }
    // 결과 기준 > OK 기준
    else if ('goodBasisScore' === binding) {
      if (!_.isNull(newVal) && newVal != '') {
        if (Number(newVal) > 100) {
          openMessageBar({
            type: 'error',
            content: t(
              'gtng.msg.OK 기준은 100점 이하로 입력하세요.',
              'OK 기준은 100점 이하로 입력하세요.'
            ),
          });
          e.cancel = true;
          return;
        }
        if (!(Number(newVal) > 0)) {
          openMessageBar({
            type: 'error',
            content: t(
              'gtng.msg.OK 기준은 0점 초과로 입력하세요.',
              'OK 기준은 0점 초과로 입력하세요.'
            ),
          });
          e.cancel = true;
          return;
        }
      }
    }
    // 결과 기준 > C-OK 기준
    else if ('levelScore' === binding) {
      if (!_.isNull(newVal) && newVal != '') {
        if (
          !_.isNull(data.goodBasisScore) &&
          data.goodBasisScore !== '' &&
          Number(newVal) >= Number(data.goodBasisScore)
        ) {
          openMessageBar({
            type: 'error',
            content: t('gtng.msg.OK 기준 이하로 입력하세요.', 'OK 기준 이하로 입력하세요.'),
          });
          e.cancel = true;
          return;
        }
        if (!(Number(newVal) > 0)) {
          openMessageBar({
            type: 'error',
            content: t('gtng.msg.0점 초과로 입력하세요.', '0점 초과로 입력하세요.'),
          });
          e.cancel = true;
          return;
        }
      }
    }
    // 중간점검 결과 > 점수, 최종점검 결과 > 점수
    else if (['nevaluationScore', 'yevaluationScore'].includes(binding)) {
      const newValue = parseFloat(newVal);
      if (isNaN(newValue)) {
        e.cancel = true;
        return;
      }
      if (data.prorateScore < newValue) {
        openMessageBar({
          type: 'error',
          content: `${t('gtng.msg.기준 배점', '기준 배점')} ( ${data.prorateScore} ) ${t(
            'gtng.msg.이하로 입력하세요.',
            '이하로 입력하세요.'
          )}`,
        });
        e.cancel = true;
        return;
      }

      const resultCode = getInspectionResultCode(
        newValue,
        data.prorateScore,
        data.goodBasisScore,
        data.levelScore
      );
      // 중간점검 결과 > OK여부 판단
      if ('nevaluationScore' === binding) {
        data.ninspectionResultCode = resultCode;
      }
      // 최종점검 결과 > OK여부 판단
      else if ('yevaluationScore' === binding) {
        data.yinspectionResultCode = resultCode;
      }
      data.crudKey = CrudCode.UPDATE;
    }
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 결과 기준 > 비고
      if ('noteContent' === binding) {
        cell.ariaReadOnly =
          progressStatus.current !== 'B1' || isCancel.current || isStatusRequest.current;
      }
      // 실시담당자, 점검담당자, 실시T/L editable css 변경
      if (['actionContactName', 'checkerName', 'completionCheckerName'].includes(binding)) {
        cell.ariaReadOnly = !activeChangeContact || isCancel.current || isStatusRequest.current;
      }
      // 중간점검 결과 > 점수, OK여부, 점검결과, 파일첨부, 점검완료체크
      else if (
        [
          'nevaluationScore',
          'ninspectionResultCode',
          'ncheckResultContent',
          'fileCountTl',
          'ncpltYn',
        ].includes(binding)
      ) {
        const isReadOnly =
          progressStatus.current !== 'B2' ||
          item?.neditableFlag !== 'Y' ||
          isCancel.current ||
          isStatusRequest.current;
        cell.ariaReadOnly = isReadOnly;
        if (['ninspectionResultCode', 'ncpltYn'].includes(binding)) {
          toggleClass(cell, 'WijmoSelect', !isReadOnly);
          if (isReadOnly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
      // 최종점검 결과 > 점수, OK여부, 점검결과, 파일첨부, 점검완료체크, Advice
      else if (
        [
          'yevaluationScore',
          'yinspectionResultCode',
          'ycheckResultContent',
          'fileCountPmo',
          'ycpltYn',
          'ypmoComment',
        ].includes(binding)
      ) {
        const isReadOnly =
          progressStatus.current !== 'B3' ||
          item?.yeditableFlag !== 'Y' ||
          isCancel.current ||
          isStatusRequest.current;
        cell.ariaReadOnly = isReadOnly;
        if (['yinspectionResultCode', 'ycpltYn'].includes(binding)) {
          toggleClass(cell, 'WijmoSelect', !isReadOnly);
          if (isReadOnly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
    }
  });

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const binding = grid.columns[ht.col].binding;
      const item = grid.rows[ht.row].dataItem;

      if (ht.panel === grid.cells) {
        if (!isCancel.current && !isStatusRequest.current) {
          setHitTest(ht);
          // 비고
          if ('noteContent' === binding) {
            if (progressStatus.current === 'B1') {
              setOpenCellTextarea(true);
            }
          }
          // 중간점검 결과 > 점검결과
          else if ('ncheckResultContent' === binding) {
            if (progressStatus.current === 'B2' && item.neditableFlag === 'Y') {
              setOpenCellTextarea(true);
            }
          }
          // 최종점검 결과 > 점검결과, Advice
          else if (['ycheckResultContent', 'ypmoComment'].includes(binding)) {
            if (progressStatus.current === 'B3' && item.yeditableFlag === 'Y') {
              setOpenCellTextarea(true);
            }
          }
        }
        // 중간점검 결과 > 중간점검첨부
        if ('fileCountTl' === binding) {
          handleFileUpload(item, ht.row, ht.col, 'GTNG_S_RST');
        }
        // 최종점검 결과 > 최종점검첨부
        else if ('fileCountPmo' === binding) {
          handleFileUpload(item, ht.row, ht.col, 'GTNG_F_RST');
        }
      }

      const col = grid.columns[ht.col];
      setFieldId(binding);
      switch (e.target.id) {
        // 담당자 단순 조회용 팝업
        case 'btnContPop':
          empPopupOpen({ binding: binding, header: col.header, item });
          break;
        // 담당자 수정 팝업
        case 'btnElmEmpPopup':
          elmEmpPopupOpen({ binding: binding, header: col.header, row: ht.row, col: ht.col, item });
          break;
        case 'openLinkChkRstPopUp':
          setGatingCheckItemId(item.gatingCheckItemId);
          setOpenChkRstPopUp(true);
          break;
      }
    });
  };

  /**
   * 점검일자 변경 요청 버튼 클릭 이벤트
   */
  const handleChangeDate = () => {
    if (!rowData?.gatingId || !rowDataEquip?.equipmentId) {
      openMessageBar({
        type: 'error',
        content: t('com.label.잘못된 접근입니다.', '잘못된 접근입니다.'),
      });
      return;
    }
    checkAvailableRequestEquipment(
      rowData?.gatingId,
      rowDataEquip?.equipmentId,
      GatingReqType.DATE_CHANGE
    ).then((res) => {
      if (res.successOrNot === 'Y' && res.data) {
        setRequestModalCondition({
          pageId: ApproveRequestPageType.GATING_DATE_CHANGE,
          gatingId: rowData?.gatingId,
          equipmentId: rowDataEquip?.equipmentId,
        });
        setOpenRequestModal(true);
      } else {
        openMessageBar({
          type: 'error',
          content:
            res.data && typeof res.data === 'string'
              ? res.data
              : t('gtng.msg.일정변경 요청할 수 없습니다.', '일정변경 요청할 수 없습니다.'),
        });
      }
    });
  };

  /**
   * 점검단계 변경 요청 버튼 클릭 이벤트
   */
  const handleChangeStep = () => {
    if (!rowData?.gatingId || !rowDataEquip?.equipmentId) {
      openMessageBar({
        type: 'error',
        content: t('com.label.잘못된 접근입니다.', '잘못된 접근입니다.'),
      });
      return;
    }
    checkAvailableRequestEquipment(
      rowData?.gatingId,
      rowDataEquip?.equipmentId,
      GatingReqType.STATUS_CHANGE
    ).then((res) => {
      if (res.successOrNot === 'Y' && res.data) {
        setRequestModalCondition({
          pageId: ApproveRequestPageType.GATING_STATUS_CHANGE,
          gatingId: rowData?.gatingId,
          equipmentId: rowDataEquip?.equipmentId,
        });
        setOpenRequestModal(true);
      } else {
        openMessageBar({
          type: 'error',
          content:
            res.data && typeof res.data === 'string'
              ? res.data
              : t(
                  'gtng.msg.점검단계 변경 요청할 수 없습니다.',
                  '점검단계 변경 요청할 수 없습니다.'
                ),
        });
      }
    });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>
                  <span className="dot">{t('gtng.label.Gating No', 'Gating No')}</span>
                </label>
                <CustomInputWithSearch
                  name="gatingNo"
                  className="find"
                  placeholder={String(
                    t('gtng.msg.Gating No를 선택해 주세요.', 'Gating No를 선택해 주세요.')
                  )}
                  readOnly={true}
                  value={gatingCheckResultParamCondition.gatingNo}
                  onChange={handleParamConditionChange}
                  onSearchClick={() => setOpenGatingNoPopUp(true)}
                  isError={errors?.gatingNo}
                  msgError={String(
                    t('gtng.msg.Gating No를 선택해 주세요.', 'Gating No를 선택해 주세요.')
                  )}
                />
              </SearchCols>
              <SearchCols>
                <label>
                  <span className="dot">{t('gtng.label.점검대상 장비', '점검대상 장비')}</span>
                </label>
                <CustomInputWithSearch
                  name="equipmentName"
                  className="find"
                  placeholder={String(
                    t('gtng.msg.점검대상장비를 선택해 주세요.', '점검대상장비를 선택해 주세요.')
                  )}
                  value={gatingCheckResultParamCondition.equipmentName}
                  readOnly={true}
                  onChange={handleParamConditionChange}
                  onSearchClick={() => {
                    const valid = !!gatingCheckResultParamCondition.gatingNo;
                    setErrors((prev) => ({
                      ...prev,
                      gatingNo: !valid,
                    }));
                    if (valid) {
                      setOpenEquipmentPopUp(true);
                      setEquipEditYn(true);
                    }
                  }}
                  isError={errors?.equipmentName}
                  msgError={String(
                    t('gtng.msg.점검대상장비를 선택해 주세요.', '점검대상장비를 선택해 주세요.')
                  )}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Eqip.ID', 'Eqip.ID')}</label>
                <CustomInputWithSearch
                  name="equipmentId"
                  value={gatingCheckResultParamCondition.equipmentId}
                  readOnly={true}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            setReset={setGatingCheckResultParamCondition}
            onReset={() => {
              setErrors((prev) =>
                Object.keys(prev).reduce((acc, cur) => Object.assign(acc, { [cur]: false }), {})
              );
            }}
            onSearch={() => handleSearch(gatingCheckResultParamCondition)}
          />
        </SearchBoxRow>
      </SearchBox>
      {/*gating process step start */}
      <ProcessBarWrap className="mt24">
        {(processStepsB || []).map((o, i) => (
          <ProcessStepBox key={o.cmnCd} className={o.cmnCd == 'B' ? 'none' : o.cmnCdDesc}>
            {/* done, now, disable o.cmnCd == 'B' ? 'done' : o.cmnCdDesc */}
            <StepBox>
              <ProcessStep>
                <ProcessNum>{i + 1}</ProcessNum>
                <ProcessText>{o.cmnCdNm}</ProcessText>
              </ProcessStep>
            </StepBox>
          </ProcessStepBox>
        ))}
        <ProgressBar></ProgressBar>
      </ProcessBarWrap>
      {/*gating process step end1  */}
      {/*gating master  info start */}
      <ContentSection>
        <TableContainer css={tb.table}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('gtng.label.Gating Task명', 'Gating Task명')}</TableCell>
                <TableCell>{rowData && rowData?.gatingName}</TableCell>
                <TableCell>{t('gtng.label.Gating 유형', 'Gating 유형')}</TableCell>
                <TableCell>{rowData && rowData?.gatingTypeName}</TableCell>
                <TableCell>{t('gtng.label.Gating 단계', 'Gating 단계')}</TableCell>
                <TableCell>{rowData && rowData?.gatingStepName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('gtng.label.점검일자', '점검일자')}</TableCell>
                <TableCell>
                  <CustomDatepicker
                    isRange={true} // 기간선택인 경우 생략가능
                    value={{
                      startDate: rowDataEquip?.startDate || '',
                      endDate: rowDataEquip?.endDate || '',
                    }}
                    readOnly={rowDataEquip?.progressStatusCode !== 'B1'}
                    disabled={rowDataEquip?.progressStatusCode !== 'B1'}
                    onChange={(newValue) => {
                      setRowDataEquip((prev) => ({
                        ...prev,
                        startDate:
                          ((newValue as DateRangeType)?.startDate as string) || prev?.startDate,
                        endDate: ((newValue as DateRangeType)?.endDate as string) || prev?.endDate,
                      }));
                    }}
                  />
                </TableCell>
                <TableCell>{t('gtng.label.점검담당자', '점검담당자')}</TableCell>
                <TableCell>
                  <CustomInputWithSearch
                    name="contCheckName"
                    className="find"
                    value={rowData?.contCheckName}
                    readOnly={true}
                    onSearchClick={() => {
                      if (rowData?.contCheckIds) {
                        setContPopTitle(String(t('gtng.label.점검담당자', '점검담당자')));
                        setContUserIds(rowData?.contCheckIds || '');
                        setOpenContEmpPopUp(true);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{t('gtng.label.실시(T/L)', '실시(T/L)')}</TableCell>
                <TableCell>
                  <CustomInputWithSearch
                    name="contExecuteTlName"
                    className="find"
                    value={rowData?.contExecuteTlName}
                    readOnly={true}
                    onSearchClick={() => {
                      if (rowData?.contExecuteTlIds) {
                        setContPopTitle(String(t('gtng.label.실시(T/L)', '실시(T/L)')));
                        setContUserIds(rowData?.contExecuteTlIds || '');
                        setOpenContEmpPopUp(true);
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('gtng.label.실시담당자', '실시담당자')}</TableCell>
                <TableCell>
                  <CustomInputWithSearch
                    name="contExecuteName"
                    className="find"
                    value={rowData?.contExecuteName}
                    readOnly={true}
                    onSearchClick={() => {
                      if (rowData?.contExecuteIds) {
                        setContPopTitle(String(t('gtng.label.실시담당자', '실시담당자')));
                        setContUserIds(rowData?.contExecuteIds || '');
                        setOpenContEmpPopUp(true);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{t('gtng.label.점검장비', '점검장비')}</TableCell>
                <TableCell>
                  <CustomInputWithSearch
                    name="gatingEquipName"
                    className="find"
                    value={rowData?.gatingEquipName}
                    readOnly={true}
                    onSearchClick={() => {
                      if (rowData?.gatingEquipName) {
                        setEquipEditYn(false);
                        setOpenEquipmentPopUp(true);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>{t('com.label.진행상태', '진행상태')}</TableCell>
                {/* //TODO 다국어 처리를 위해 수정 필요 */}
                <TableCell>{rowData?.progressStatusName2}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ContentSection>
      {/*gating master info end */}
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating Information', 'Gating Information')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {rowData && rowData?.equipmentName && (
            <div className="info primary">{`${rowData?.equipmentName} Check List`}</div>
          )}
        </SubTitleGroup>

        <ControlBtnGroup>
          {/* [24.02.28] 중간점검/최종점검에서도 변경 가능 */}
          {activeChangeContact && !isCancel.current && !isStatusRequest.current && (
            <>
              <label>{t('com.label.담당자', '담당자')}</label>
              <ComboBox
                options={getGatingMgrTp}
                placeholder={String(t('com.label.선택', '선택'))}
                defaultValue={getGatingMgrTpSelected}
                onChange={(value) => setGatingMgrTpSelected(value)}
              />
              <CustomInputWithSearch
                name="allContDataNm"
                className="find"
                placeholder={String(
                  t('com.label.담당자를 선택해 주세요.', '담당자를 선택해 주세요.')
                )}
                value={getAllContDataNm}
                readOnly={true}
                onSearchClick={() => {
                  setOpenAllContEmpPopUp(true);
                }}
              />
              <Button
                css={IconButton.button}
                className="setting"
                onClick={onBtContAll}
                disableRipple
              >
                {t('com.button.일괄적용', '일괄적용')}
              </Button>
            </>
          )}
          {rowData && (
            <>
              <Button
                css={IconButton.button}
                className="docu"
                onClick={() => setOpenGuidePopUp(true)}
                disableRipple
              >
                {t('gtng.button.배점/가중치 가이드', '배점/가중치 가이드')}
              </Button>
              <Button
                css={IconButton.button}
                className="Exceldown"
                onClick={onBtExport}
                disableRipple
              >
                {t('com.button.다운로드', '다운로드')}
              </Button>
            </>
          )}
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowDataCheckList}
        height={420}
        frozenColumns={5}
        selectionMode={SelectionMode.Row}
        align={'center'}
        isReadOnly={isCancel.current || isStatusRequest.current}
        initialized={onInitialized}
        beginningEdit={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const item = grid.rows[e.row].dataItem;
          // readonly 설정
          // 중간점검 결과 > 점수, OK여부, 점검완료체크
          if (['nevaluationScore', 'ninspectionResultCode', 'ncpltYn'].includes(binding)) {
            if (
              progressStatus.current !== 'B2' ||
              item?.neditableFlag !== 'Y' ||
              isCancel.current ||
              isStatusRequest.current
            ) {
              e.cancel = true;
            }
          }
          // 최종점검 결과 > 점수, OK여부, 점검완료체크
          if (['yevaluationScore', 'yinspectionResultCode', 'ycpltYn'].includes(binding)) {
            if (
              progressStatus.current !== 'B3' ||
              item?.yeditableFlag !== 'Y' ||
              isCancel.current ||
              isStatusRequest.current
            ) {
              e.cancel = true;
            }
          }
        }}
        cellEditEnding={onCellEditEnding}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
      />
      {isOpenCellTextarea && (
        <WJCellTextarea
          grid={gridRef.current}
          hitTest={hitTest}
          close={() => setOpenCellTextarea(false)}
        />
      )}
      <GlobalBtnGroup>
        {activeChangeDate && (
          <Button css={IconButton.button} className="date" onClick={handleChangeDate} disableRipple>
            {t('gtng.button.점검일자 변경', '점검일자 변경')}
          </Button>
        )}
        {activeChangeStep && (
          <Button
            css={IconButton.button}
            className="request"
            onClick={handleChangeStep}
            disableRipple
          >
            {t('gtng.button.점검단계 변경', '점검단계 변경')}
          </Button>
        )}
        {isBtnSave && (
          <Button css={IconButton.button} className="draft" onClick={handleSave} disableRipple>
            {t('com.button.저장', '저장')}
          </Button>
        )}
        {/* 완료 버튼 */}
        {isStatusChange && (
          <Button css={IconButton.button} className="confirm" onClick={handleConfirm} disableRipple>
            {'B1' === rowDataEquip?.progressStatusCode
              ? t('gtng.label.점검준비완료', '점검준비완료')
              : 'B2' === rowDataEquip?.progressStatusCode
              ? t('gtng.label.중간점검완료', '중간점검완료')
              : 'B3' === rowDataEquip?.progressStatusCode
              ? t('gtng.label.최종점검완료', '최종점검완료')
              : ''}
          </Button>
        )}
      </GlobalBtnGroup>

      {/* 게이팅 찾기 팝업 */}
      {isOpenGatingNoPopUp && (
        <GatingNoPopUp
          open={isOpenGatingNoPopUp}
          close={() => setOpenGatingNoPopUp(false)}
          condition={{
            progressStatusCode: 'B', // 점검 상태 기본값 설정
          }}
          onCallback={(value) => {
            // 검색조건 - Gating No 설정
            setErrors((prev) => ({
              ...prev,
              gatingNo: _.isEmpty(value?.gatingNo || ''),
            }));
            setGatingCheckResultParamCondition({
              ...gatingCheckResultParamCondition,
              gatingNo: value?.gatingNo || '',
              gatingId: value?.gatingId || '',
              equipmentId: '',
              equipmentName: '',
            });
          }}
        />
      )}

      {/* 가중치 가이드 팝업 */}
      {isOpenGuidePopUp && (
        <GatingCheckGuidePopUp
          open={isOpenGuidePopUp}
          initParam={{
            gatingId: rowData?.gatingId || '',
            equipmentId: rowData?.equipmentId || '',
            guideText: rowData?.cont1 || '',
          }}
          close={() => setOpenGuidePopUp(false)}
        />
      )}

      {/* 설비 팝업 */}
      {isOpenEquipmentPopUp && (
        <GatingEquipmentListPopUp
          open={isOpenEquipmentPopUp}
          condition={{ gatingId: gatingCheckResultParamCondition?.gatingId || '' }}
          isSelector={equipEditYn}
          close={() => setOpenEquipmentPopUp(false)}
          onCallback={(value) => {
            // 검색조건 - 점검대상 장비 설정
            setErrors((prev) => ({
              ...prev,
              equipmentName: _.isEmpty(value?.equipmentName || ''),
            }));
            setGatingCheckResultParamCondition({
              ...gatingCheckResultParamCondition,
              equipmentId: value?.equipmentId || '',
              equipmentName: value?.equipmentName || '',
            });
          }}
        />
      )}

      {/* 담당자 팝업 (단순 조회용) */}
      {isOpenContEmpPopUp && (
        <GatingContListPopUp
          open={isOpenContEmpPopUp}
          setReadonly={true}
          title={contPopTitle}
          initParam={getContUserIds}
          close={() => setOpenContEmpPopUp(false)}
        />
      )}

      {/* 첨부파일 팝업 */}
      {isOpenFileUploadPopUp && (
        <FileUploadPopUp
          open={isOpenFileUploadPopUp}
          close={() => setOpenFileUploadPopUp(false)}
          downloadOnly={initParamFileUpload?.downloadOnly}
          initParam={{
            atchFileGrId: initParamFileUpload?.atchFileGrId,
            atchFileTpCd: initParamFileUpload?.atchFileTpCd,
            optValCtn1: initParamFileUpload?.tableName,
            bizName: initParamFileUpload?.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            const target = initParamFileUpload?.target;
            if (target) {
              gridRef.current.setCellData(target.row, target.col, fileCount);
            }
            setOpenFileUploadPopUp(false);
            // [24.03.12] 변경된 첨부파일 수 재조회를 위해 사용한 것으로 보임
            // handleSearch(gatingCheckResultParamCondition);
          }}
        />
      )}

      {/* 담당자 일괄 변경 */}
      {isOpenAllContEmpPopUp && (
        <UserModal
          open={isOpenAllContEmpPopUp}
          close={() => setOpenAllContEmpPopUp(false)}
          title={t('gtng.label.Gating 담당자 조회', 'Gating 담당자 조회')}
          defaultUserId={getAllContDataId}
          singleSelect={false}
          onCallback={(result) => {
            const users = (result || []) as Employee[];
            const contactId = users.map((o) => o.userId).join(',');
            const empNm = users.map((o) => o.empNm).join(',');
            setAllContDataId(contactId);
            setAllContDataNm(empNm);
          }}
        />
      )}

      {/* 담당자 변경 */}
      {isOpenGatingContPopUp && (
        <UserModal
          open={isOpenGatingContPopUp}
          close={() => setOpenGatingContPopUp(false)}
          title={t('gtng.label.Gating 담당자 조회', 'Gating 담당자 조회')}
          defaultUserId={userModalCondition?.defaultUserId}
          singleSelect={false}
          onCallback={(result) => {
            const users = (result || []) as Employee[];
            const target = userModalCondition?.target;
            if (target && target?.binding && target?.row > -1) {
              const contactId = users.map((o) => o.userId).join(',');
              const empNm = users.map((o) => o.empNm).join(',');
              const item = gridRef.current?.rows[target.row]?.dataItem;
              item[target.binding] = empNm;
              item[target.binding.replace('Name', 'Id')] = contactId;
              item['crudKey'] = CrudCode.UPDATE;
              gridRef.current?.invalidate();
            }
          }}
        />
      )}

      {/* 항목별 점검결과 등록 */}
      {isOpenChkRstPopUp && (
        <GatingCheckResultPopUp
          open={isOpenChkRstPopUp}
          initParam={{
            gatingId: rowData?.gatingId || '',
            equipmentId: rowData?.equipmentId || '',
            gatingCheckItemId: getGatingCheckItemId || '',
            rowItemList: rowDataCheckList,
          }}
          close={() => {
            setOpenChkRstPopUp(false);
            setGatingCheckItemId('');
            handleSearch(gatingCheckResultParamCondition);
          }}
          onCallback={(value) => {
            setOpenChkRstPopUp(false);
            setGatingCheckItemId('');
            handleSearch(gatingCheckResultParamCondition);
          }}
        />
      )}

      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={requestModalCondition?.pageId}
          // aprReqId={}
          condition={{
            gatingId: requestModalCondition?.gatingId,
            equipmentId: requestModalCondition?.equipmentId,
          }}
        />
      )}
    </>
  );
};
export default GatingCheckResultPage;
