/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DataType } from '@grapecity/wijmo';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { Button } from '@mui/material';
import { GatingCheckSheetDtl } from 'models/gtng/GatingCheckSheet';
import { CrudCode } from 'models/common/Edit';
import { CommonYN } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { getGatingCheckSheetDtl, saveGatingCheckSheetDtl } from 'apis/gtng/GatingCheckSheet';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { Code } from 'models/common/CommonCode';
import { IconButton } from 'components/buttons/IconSVG';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import WJCellTextarea from 'components/inputs/WJCellTextarea';
import CustomGrid from 'components/grids/CustomGrid';

interface SearchParamData {
  sGatingTemplateId?: string;
  sVersionNo?: string;
  editable?: boolean;
  callBack?: any;
  sSearchDetailBtn?: boolean;
  sTitleTxt?: string;
  gridDtlTitle?: string;
}
const GatingCheckSheetDtlGrid = (props: SearchParamData) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<GatingCheckSheetDtl[]>([]);
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const [getUseYn, setUseYn] = useState<Code[]>([]);
  const [checkItemGradeCode, setCheckItemGradeCode] = useState<Code[]>([]);
  const [getTitleText, setTitleText] = useState<string>('');
  const [flexRef, setFlexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
  const [colWidthMap, setColWidthMap] = useState<any>({
    subSeqNo: 120,
    gatingCheckItemGradeCode: 120,
    gatingType1Value: 150,
    gatingType2Value: 150,
    gatingType3Value: 150,
    gatingCheckItem1Value: 300,
    gatingCheckItem2Value: 300,
    gatingCheckItem3Value: 300,
    prorateScore: 80,
    goodBasisScore: 80,
    levelScore: 80,
    noteContent: 200,
    useYn: 100,
  });

  const getCommonCodesForGrid = async () => {
    const checkItemGradeCode: Code[] = await getCommonCodeNames('GATING_CHECK_ITEM_GRADE_CODE');
    const getUseYn: Code[] = await getCommonCodeNames('YN_FLAG');
    setCheckItemGradeCode(checkItemGradeCode);
    setUseYn(getUseYn);
  };

  const OnInitialized = (grid) => {
    setFlexRef(grid);

    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        const item = panel.rows[row].dataItem;
        if ('noteContent' === binding) {
          cell.ariaReadOnly = false;
        }
      }
    };

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      switch (ht.panel.columns[ht.col].binding) {
        case 'noteContent': {
          setHitTest(ht);
          setOpenCellTextarea(true);
          break;
        }
      }
    });

    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.pastingCell.addHandler((s, e) => {
      const col = s.columns[e.col];
      const data = s.rows[e.row].dataItem;
      if (
        col.binding == 'levelScore' ||
        col.binding == 'goodBasisScore' ||
        col.binding == 'prorateScore'
      ) {
        if (typeof data !== 'number') {
          alert(t('gtng.msg.숫자로 입력해 주세요.', '숫자로 입력해 주세요.'));
          e.cancel = true;
        }
      }
    });

    grid.cellEditEnding.addHandler((s, e) => {
      const col = s.columns[e.col];
      const newValue = s.activeEditor.value;
      const data = s.rows[e.row].dataItem;

      if (col.binding === 'levelScore') {
        if (data.goodBasisScore != '' && newValue >= data.goodBasisScore) {
          alert(t('gtng.msg.OK 기준 이하로 입력하세요.', 'OK 기준 이하로 입력하세요.'));
          e.cancel = true;
          e.stayInEditMode = true;
        }
      }
      if (col.binding === 'goodBasisScore') {
        if (data.levelScore != '' && newValue <= data.levelScore) {
          alert(t('gtng.msg.C-OK 기준 이상으로 입력하세요.', 'C-OK 기준 이상으로 입력하세요.'));
          e.cancel = true;
          e.stayInEditMode = true;
        }
      }
    });
  };

  const layoutDefinition = useMemo(() => {
    const useYn = new DataMap(getUseYn, 'cmnCd', 'cmnCdNm');
    const gatingCheckItemGradeCode = new DataMap(checkItemGradeCode, 'cmnCd', 'cmnCdNm');
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 40,
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'subSeqNo',
        header: String(t('gtng.grid.항목순번', '항목순번')),
        width: colWidthMap.subSeqNo,
      },
      {
        binding: 'gatingCheckItemGradeCode',
        header: String(t('gtng.grid.Gating 등급', 'Gating 등급')),
        width: colWidthMap.gatingCheckItemGradeCode,
        dataMap: gatingCheckItemGradeCode,
      },
      {
        header: String(t('gtng.grid.점검구분', '점검구분')),
        columns: [
          {
            binding: 'gatingType1Value',
            header: '1',
            width: colWidthMap.gatingType1Value,
            align: 'left',
            maxLength: 150,
          },
          {
            binding: 'gatingType2Value',
            header: '2',
            width: colWidthMap.gatingType2Value,
            align: 'left',
            maxLength: 150,
          },
          {
            binding: 'gatingType3Value',
            header: '3',
            width: colWidthMap.gatingType3Value,
            align: 'left',
            maxLength: 150,
          },
        ],
      },
      {
        header: String(t('gtng.label.점검항목', '점검항목')),
        align: 'center',
        columns: [
          {
            binding: 'gatingCheckItem1Value',
            header: String(t('gtng.grid.Check List', 'Check List')),
            width: colWidthMap.gatingCheckItem1Value,
            align: 'left',
            maxLength: 500,
          },
          {
            binding: 'gatingCheckItem2Value',
            header: String(t('gtng.grid.Check 방법', 'Check 방법')),
            width: colWidthMap.gatingCheckItem2Value,
            align: 'left',
            maxLength: 500,
          },
          {
            binding: 'gatingCheckItem3Value',
            header: String(t('gtng.grid.판단 기준', '판단 기준')),
            width: colWidthMap.gatingCheckItem3Value,
            align: 'left',
            maxLength: 500,
          },
        ],
      },
      {
        header: String(t('gtng.label.결과 기준', '결과 기준')),
        columns: [
          {
            binding: 'prorateScore',
            header: String(t('gtng.grid.배점', '배점')),
            width: colWidthMap.prorateScore,
            dataType: DataType.Number,
          },
          {
            binding: 'goodBasisScore',
            header: String(t('gtng.grid.OK 기준', 'OK 기준')),
            width: colWidthMap.goodBasisScore,
            dataType: DataType.Number,
          },
          {
            binding: 'levelScore',
            header: String(t('gtng.grid.C-OK 기준', 'C-OK 기준')),
            width: colWidthMap.levelScore,
            dataType: DataType.Number,
          },
          {
            binding: 'noteContent',
            header: String(t('com.label.비고', '비고')),
            width: colWidthMap.noteContent,
            align: 'left',
            isReadOnly: true, // textarea 팝업인 경우 클릭이벤트에서 제어
          },
        ],
      },
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        width: colWidthMap.useYn,
        dataMap: useYn,
      },
    ];
  }, [getUseYn, checkItemGradeCode]);

  const btnAddRow = useCallback(() => {
    if (_.isEmpty(props.sGatingTemplateId) || _.isEmpty(props.sVersionNo)) {
      openMessageBar({
        type: 'warning',
        content: t(
          'gtng.msg.check sheet 정보를 먼저 선택해 주세요.',
          'check sheet 정보를 먼저 선택해 주세요.'
        ),
      });
      return;
    }
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
      versionNo: props.sVersionNo,
      gatingTemplateId: props.sGatingTemplateId,
    } as GatingCheckSheetDtl;
    setRowData([newRow, ...rowData]);
  }, [rowData]);

  const btnCopyRow = useCallback(() => {
    if (_.isEmpty(props.sGatingTemplateId) || _.isEmpty(props.sVersionNo)) {
      openMessageBar({
        type: 'warning',
        content: t(
          'gtng.msg.check sheet 정보를 먼저 선택해 주세요.',
          'check sheet 정보를 먼저 선택해 주세요.'
        ),
      });
      return;
    }

    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'warning',
        content: t('gtng.msg.복사할 항목을 선택해 주세요.', '복사할 항목을 선택해 주세요.'),
      });
      return;
    }

    const newRow = [] as GatingCheckSheetDtl[];

    selectedRowNodes.forEach((item) => {
      const copyData = item.dataItem;

      newRow.push({
        crudKey: CrudCode.CREATE,
        useYn: copyData?.useYn,
        versionNo: copyData?.versionNo,
        gatingTemplateId: copyData?.gatingTemplateId,
        gatingCheckItemGradeCode: copyData?.gatingCheckItemGradeCode,
        gatingType1Value: copyData?.gatingType1Value,
        gatingType2Value: copyData?.gatingType2Value,
        gatingType3Value: copyData?.gatingType3Value,
        gatingCheckItem1Value: copyData?.gatingCheckItem1Value,
        gatingCheckItem2Value: copyData?.gatingCheckItem2Value,
        gatingCheckItem3Value: copyData?.gatingCheckItem3Value,
        prorateScore: copyData?.prorateScore,
        goodBasisScore: copyData?.goodBasisScore,
        levelScore: copyData?.levelScore,
        minWeightingRate: copyData?.minWeightingRate,
        maxWeightingRate: copyData?.maxWeightingRate,
        noteContent: copyData?.noteContent,
        sameInspectionTargetYn: copyData?.sameInspectionTargetYn,
        emsConnectYn: copyData?.emsConnectYn,
      } as GatingCheckSheetDtl);
    });

    setRowData([...newRow, ...rowData]);
  }, [rowData]);

  const btnDelRow = useCallback(() => {
    if (_.isEmpty(props.sGatingTemplateId) || _.isEmpty(props.sVersionNo)) {
      openMessageBar({
        type: 'warning',
        content: t(
          'gtng.msg.check sheet 정보를 먼저 선택해 주세요.',
          'check sheet 정보를 먼저 선택해 주세요.'
        ),
      });
      return;
    }
    let idx = 0;

    const selectedRowNodes = flexRef.selectedRows;

    if (selectedRowNodes.length < 1) {
      openMessageBar({
        type: 'warning',
        content: t(
          'com.label.삭제할 항목을 1건 이상 선택해 주세요.',
          '삭제할 항목을 1건 이상 선택해 주세요.'
        ),
      });
      return;
    }

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode._idx!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item] && rowData[item].delYn == 'N') {
        idx++;
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    if (idx > 0) {
      openMessageBar({
        type: 'warning',
        content: t(
          'gtng.msg.진행중인 점검항목이 존재하는 Gating Check Sheet 항목은 삭제 할수 없습니다.',
          '진행중인 점검항목이 존재하는 Gating Check Sheet 항목은 삭제 할수 없습니다.'
        ),
      });
      return;
    }

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData]);

  const btnSave = () => {
    const saveData = rowData.filter((o) => o.crudKey !== CrudCode.READ && o.crudKey !== null);

    const saveDataChk = rowData.filter(
      (o) => o.crudKey !== CrudCode.READ && o.crudKey !== CrudCode.DELETE && o.crudKey !== null
    );

    const valid = saveDataChk
      .map((o, index) => {
        if (_.isEmpty(o.subSeqNo))
          return `${index + 1} : ${t(
            'gtng.msg.항목순번을 입력해주세요.',
            '항목순번을 입력해주세요.'
          )}\n`;
        if (_.isEmpty(o.gatingCheckItemGradeCode))
          return `${index + 1} : ${t(
            'gtng.msg.Gating 등급을 선택해주세요.',
            'Gating 등급을 선택해주세요.'
          )}\n`;
        if (_.isEmpty(o.gatingType1Value))
          return `${index + 1} : ${t(
            'gtng.msg.점검구분1을 입력해주세요.',
            '점검구분1을 입력해주세요.'
          )}\n`;
        if (_.isEmpty(o.gatingType2Value))
          return `${index + 1} : ${t(
            'gtng.msg.점검구분2을 입력해주세요.',
            '점검구분2을 입력해주세요.'
          )}\n`;
        if (_.isEmpty(o.gatingType3Value))
          return `${index + 1} : ${t(
            'gtng.msg.점검구분3을 입력해주세요.',
            '점검구분3을 입력해주세요.'
          )}\n`;

        if (_.isEmpty(o.gatingCheckItem1Value))
          return `${index + 1} : ${t(
            'gtng.msg.점검항목 Check List를 입력해주세요.',
            '점검항목 Check List를 입력해주세요.'
          )}\n`;
        if (_.isEmpty(o.gatingCheckItem2Value))
          return `${index + 1} : ${t(
            'gtng.msg.점검항목 Check 방법을 입력해주세요.',
            '점검항목 Check 방법을 입력해주세요.'
          )}\n`;
        if (_.isEmpty(o.gatingCheckItem3Value))
          return `${index + 1} : ${t(
            'gtng.msg.점검항목 판단기준을 입력해주세요.',
            '점검항목 판단기준을 입력해주세요.'
          )}\n`;
        if (_.isNull(o.prorateScore) || o.prorateScore == undefined)
          return `${index + 1} : ${t(
            'gtng.msg.결과기준 배점을 입력해주세요.',
            '결과기준 배점을 입력해주세요.'
          )}\n`;
        if (_.isNull(o.goodBasisScore) || o.goodBasisScore == undefined)
          return `${index + 1} : ${t(
            'gtng.msg.결과기준 OK기준을 입력해주세요.',
            '결과기준 OK기준을 입력해주세요.'
          )}\n`;
        if (_.isNull(o.levelScore) || o.levelScore == undefined)
          return `${index + 1} : ${t(
            'gtng.msg.결과기준 C-OK기준을 입력해주세요.',
            '결과기준 C-OK기준을 입력해주세요.'
          )}\n`;
        if (typeof o.prorateScore !== 'number')
          return `${index + 1} : ${t(
            'gtng.msg.결과기준 배점은 숫자로 입력해주세요.',
            '결과기준 배점은 숫자로 입력해주세요.'
          )}\n`;
        if (typeof o.goodBasisScore !== 'number')
          return `${index + 1} : ${t(
            'gtng.msg.결과기준 OK기준은 숫자로 입력해주세요.',
            '결과기준 OK기준은 숫자로 입력해주세요.'
          )}\n`;
        if (typeof o.levelScore !== 'number')
          return `${index + 1} : ${t(
            'gtng.msg.결과기준 C-OK기준은 숫자로 입력해주세요.',
            '결과기준 C-OK기준은 숫자로 입력해주세요.'
          )}\n`;
      })
      .filter((element) => element !== undefined);

    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'warning', content: content || '' });
      return;
    }

    if (!saveData || saveData.length < 1) {
      openMessageBar({
        type: 'warning',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        saveGatingCheckSheetDtl(saveData).then((response) => {
          if (response != null && response.successOrNot == 'N') {
            openCommonModal({
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return;
          }
          openCommonModal({
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
            yesCallback: () =>
              fnSearchGatingCheckSheetDtl(
                props.sGatingTemplateId || '',
                props.sVersionNo || '',
                true
              ),
          });
        });
      },
    });
  };

  useEffect(() => {
    getCommonCodesForGrid();

    setRowData([]); // 데이터 초기화
    setTitleText('');
    if (props.sGatingTemplateId != null && props.sVersionNo != null && props.sSearchDetailBtn) {
      fnSearchGatingCheckSheetDtl(props.sGatingTemplateId, props.sVersionNo, true);
    }
  }, [props]);

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = String(
      t('gtng.label.Gating Check Sheet Detail', 'Gating Check Sheet Detail')
    );
    book.saveAsync(String(t('gtng.label.Gating Check Sheet Detail', 'Gating Check Sheet Detail')));
  };

  const fnSearchGatingCheckSheetDtl = useCallback(
    (sGatingTemplateId: string, sVersionNo: string, searchDetailBtn: boolean) => {
      if (searchDetailBtn) {
        getGatingCheckSheetDtl(sGatingTemplateId, sVersionNo).then(
          (result: GatingCheckSheetDtl[]) => {
            setTitleText(
              _.isEmpty(result) && _.isEmpty(props.gridDtlTitle)
                ? ''
                : _.isEmpty(result)
                ? '( ' + props.gridDtlTitle + ' )'
                : '( ' +
                  result[0]?.gatingTypeName +
                  ' / ' +
                  result[0]?.gatingStepName +
                  ' / ' +
                  props.sVersionNo +
                  ' )'
            );
            setRowData(result); //123
          }
        );
      }
    },
    [props]
  );
  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating Check Sheet Detail', 'Gating Check Sheet Detail')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
          {/* 안내문 class 종류는 default,error,warning,confirm,primary가 있습니다. */}
          {!_.isEmpty(getTitleText) && <div className="info warning">{getTitleText}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          {props.editable && (
            <>
              <Button
                css={IconButton.button}
                className="copyRow"
                disableRipple
                onClick={btnCopyRow}
              >
                {t('com.button.행복사', '행복사')}
              </Button>
              <Button css={IconButton.button} className="addRow" disableRipple onClick={btnAddRow}>
                {t('com.button.행추가', '행추가')}
              </Button>
              <Button css={IconButton.button} className="delRow" disableRipple onClick={btnDelRow}>
                {t('com.button.행삭제', '행삭제')}
              </Button>
            </>
          )}
        </ControlBtnGroup>
      </SubTitleLayout>

      <CustomGrid
        rowData={rowData}
        layoutDefinition={layoutDefinition}
        align="center"
        allowPinning={false}
        initialized={OnInitialized}
      />
      {isOpenCellTextarea && (
        <WJCellTextarea grid={flexRef} hitTest={hitTest} close={() => setOpenCellTextarea(false)} />
      )}

      {props.editable && (
        <GlobalBtnGroup>
          <Button css={IconButton.button} className="save" disableRipple onClick={btnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        </GlobalBtnGroup>
      )}
    </>
  );
};

export default GatingCheckSheetDtlGrid;
