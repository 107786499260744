/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContentLayout, cs } from 'components/layouts/ContentLayout';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import PageHeaderLayout from 'components/layouts/PageHeaderLayout';
import { SideMenuBar } from 'components/layouts/menu/SideMenuBar';
import StacksMenuBar from 'components/layouts/menu/StacksMenuBar';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { MenuContext } from 'App';
import { Menu, MenuContextType, MenuEnum } from 'models/admin/Menu';
import { getRoute } from 'routers/Router';
import MainPage from 'pages/main/MainPage';
import NotFoundPage from 'pages/common/NotFoundPage';
import useSessionStore from 'stores/useSessionStore';
interface MainLayoutProps {
  children: ReactNode;
  showMenu: boolean;
}

export const ApproveLayout = ({ children, showMenu }: MainLayoutProps) => {
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { userId, menus } = useSessionStore();
  const { pathname, state } = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const getComponent = (mnuUrl: string) => {
    const route = getRoute((mnuUrl || '').startsWith('/') ? mnuUrl : `/${mnuUrl}`);
    return route?.[0]?.element ?? null;
  };

  useEffect(() => {
    const mnuUrl = pathname.startsWith('/') ? pathname.replace('/', '') : pathname;
    const idx = menuContext.stacksMenu.findIndex((o) => o.mnuUrl === mnuUrl);
    // 이미 열려있는 메뉴인 경우
    if (idx > -1) {
      menuContext.handleMenuChange({
        ...menuContext,
        currentMenu: menuContext.stacksMenu[idx],
      });
      return;
    }

    // 등록된 메뉴인 경우
    const menu = menus.find((item) => item.mnuUrl === mnuUrl) as Menu;
    if (menu) {
      menuContext.handleMenuChange({
        ...menuContext,
        currentMenu: menu,
      });
      return;
    }
    let upprMnuId = '';
    if (state?.upprMnuUrl) {
      const upprMnuUrl = state.upprMnuUrl.startsWith('/')
        ? state.upprMnuUrl.replace('/', '')
        : state.upprMnuUrl;
      const upprMnu = menus.find((item) => item.mnuUrl === upprMnuUrl) as Menu;
      if (upprMnu) {
        upprMnuId = upprMnu.mnuId || '';
      }
    }
    // 기타 화면
    const etcMenu = {
      mnuId: state?.mnuId || mnuUrl,
      mnuUrl: state?.mnuUrl || mnuUrl,
      mnuNm: state?.mnuNm || 'ETC',
      upprMnuId: upprMnuId,
      relateMnuId: upprMnuId,
    } as Menu;
    menuContext.handleMenuChange({
      ...menuContext,
      currentMenu: etcMenu,
    });
  }, [pathname]);

  useEffect(() => {
    const menu = menuContext.currentMenu;
    if (menu) {
      const stacksMenu = menuContext.stacksMenu.some((o) => o.mnuId === menu.mnuId)
        ? menuContext.stacksMenu
        : [...menuContext.stacksMenu, menu];

      const idx = stacksMenu.findIndex((o) => o.mnuId === menu.mnuId);
      const tabIndex = idx < 0 ? stacksMenu.length - 1 : idx;

      const newParentMenus: Menu[] = [];
      let cur = menus.find((item) => item.mnuId === menu.upprMnuId) as Menu;
      if (cur) {
        while (cur.mnuId !== '000000') {
          newParentMenus.unshift(cur);
          cur = menus.find((item) => item.mnuId === cur.upprMnuId) as Menu;
          if (!cur) break;
        }
      }
      setCurrentTabIndex(tabIndex);
      menuContext.handleMenuChange({
        ...menuContext,
        stacksMenu: stacksMenu,
        tabIndex: tabIndex,
        selectedHeaderMenu: newParentMenus.length
          ? newParentMenus[0].mnuId
          : menuContext.selectedHeaderMenu,
      });
    }
  }, [menuContext.currentMenu]);

  return (
    <>
      {menuContext.stacksMenu.map((it: any, idx) => (
        <TabPanel value={currentTabIndex} key={it.mnuId} forceRender={false} index={idx}>
          <ContainerLayout>
            {userId ? (
              it.mnuId === MenuEnum.root ? (
                <MainPage />
              ) : (
                getComponent(it.mnuUrl) || <NotFoundPage />
              )
            ) : (
              children
            )}
          </ContainerLayout>
        </TabPanel>
      ))}
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props: { [x: string]: any; children: any; value: any; index: any }) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} css={cs.root}>
      <Box sx={{ display: value === index ? 'block' : 'none' }}>{children}</Box>
    </div>
  );
}

const st = {
  root: css`
    margin-top: 52px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    height: calc(100vh - 52px) !important;
    overflow: hidden;
  `,
};
