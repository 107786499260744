import {
  EquipmentClassificationCondition,
  EquipmentClassificationDetail,
  EquipmentClassificationMaster,
} from 'models/mp/MpEquipment';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

/**
 * 설비분류체계 조회
 * @param condition
 */
export const findMpStandard = async (condition?: EquipmentClassificationCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/standard`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<EquipmentClassificationMaster> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as EquipmentClassificationMaster[];
};

/**
 * 설비분류체계 조회
 * @param condition
 */
export const findMpClass = async (condition?: EquipmentClassificationCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/class`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<EquipmentClassificationDetail> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : []) as EquipmentClassificationDetail[];
};

/**
 * 설비분류체계 - Master 조회
 * @param condition
 */
export const findMpEqiupmentClassificationMaster = async (
  condition?: EquipmentClassificationCondition
) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/equipment-clsf/master`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as [];
};

/**
 * 설비분류체계 - Detail 조회
 * @param condition
 */
export const findMpEqiupmentClassificationDetail = async (
  condition?: EquipmentClassificationCondition
) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/equipment-clsf/detail`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : []) as EquipmentClassificationDetail[];
};

/**
 * 설비분류체계번호 채번
 * @param eqclId
 */
export const findMpEqiupmentClassificationNewNo = async (eqclId?: string) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/equipment-clsf/master/seq`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { eqclId: eqclId },
  };

  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : undefined;
};

/**
 * 설비분류체계상세 설비분류구조번호 다음 시퀀스 조회
 * @param eqpClsfNo 설비분류번호
 * @param eqpLvCd 설비레벨
 */
export const findMpEqiupmentClassificationDetailNewNo = async (
  eqpClsfNo?: string,
  eqpLvCd?: string
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/equipment-clsf/detail/seq`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: {
      eqpClsfNo: eqpClsfNo,
      eqpLvCd: eqpLvCd,
    },
  };

  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : undefined;
};

/**
 * 설비분류체계 요청 가능여부 확인
 * @param masterList
 */
export const checkAvailableRequest = async (masterList: EquipmentClassificationMaster[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/equipment-clsf/check-request`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: {
      requestMasterList: masterList,
    },
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 설비분류체계 삭제 요청 가능여부 확인
 * @param masterList
 */
export const checkAvailableDeleteRequest = async (masterList: EquipmentClassificationMaster[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/equipment-clsf/check-delete-request`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: {
      requestMasterList: masterList,
    },
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 설비분류번호 조회 (이미 등록됐거나 결재진행중인 번호 확인)
 * @param masterList
 */
export const checkExistsRequest = async (eqpClsfNo?: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/equipment-clsf/check-exists-request`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ eqpClsfNo: eqpClsfNo || '' }),
  };

  const response: CommonResponse = await callApi(request);
  return response;
};
