/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { ContentSection } from 'components/layouts/ContentSection';
import GatingRegistGrid from './gatingregist/GatingRegistGrid';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { getYearList } from 'apis/gtng/GatingRegist';
import { ComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { SelectGroup } from '../../components/selects/CustomSelect';

export interface SearchParam {
  gatingTypeCode?: string;
  gatingStepCode?: string;
  progressStatusCode?: string;
  productTypeCode?: string;
  gatingName?: string;
  startDate?: string;
  endDate?: string;
}

/**
 * Gating 생성/조회 Page
 * @param
 * @constructor
 */
const GatingRegistPage = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const [searchState, setSearchState] = useState<any>(false);
  const [searchParam, setSearchParam] = useState<SearchParam>({});
  const [searchParamData, setSearchParamData] = useState<SearchParam>();
  const [gatingTypeConditionList, setGatingTypeConditionList] = useState<Code[]>([]);
  const [gatingStepCodeConditionList, setGatingStepCodeConditionList] = useState<Code[]>([]);
  const [gatingProgressStatusCodeConditionList, setGatingProgressStatusCodeConditionList] =
    useState<Code[]>([]);
  const [gatingProductConditionList, setGatingProductConditionList] = useState<Code[]>([]);
  const [gatingStartEndYearList, setGatingStartEndYearList] = useState<any>();
  const optionsGatingStartEndYearList = useMemo(() => {
    return (gatingStartEndYearList || []).reduce(
      (acc, cur) => [
        ...acc,
        {
          cmnCd: String(cur.STARTYEAR),
          cmnCdNm: String(cur.STARTYEAR),
        },
      ],
      [] as Code[]
    );
  }, [gatingStartEndYearList]);

  useEffect(() => {
    initCondition();
  }, []);

  //콤보박스 공통코드 호출
  const initCondition = async () => {
    //Gating 유형
    const gatingTypeCodeConditions = await getCommonCodeNames('GATING_TYPE_CODE');
    setGatingTypeConditionList(gatingTypeCodeConditions);
    //Gating 단계 - 게이팅 유형에 따라 변동 (전체 -> 출력 x)
    // const gatingStepCodeConditions = await getCommonCodeNames('GATING_STEP_CODE');
    // setGatingStepCodeConditionList(gatingStepCodeConditions);
    //Gating 상태
    const gatingProgressStatusCodeConditions = await getCommonCodeNames(
      'GATING_PROGRESS_STATUS_CODE'
    );
    setGatingProgressStatusCodeConditionList(gatingProgressStatusCodeConditions);
    //Product Type
    const gatingProductTypeConditions = await getCommonCodeNames('GATING_PRODUCT_TYPE_CODE');
    setGatingProductConditionList(gatingProductTypeConditions);

    const gatingStartEndYearConditions = await getYearList();
    setGatingStartEndYearList(gatingStartEndYearConditions);
  };

  const handleSearchParam = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  };

  const handleStartDate = (value) => {
    setSearchParam({
      ...searchParam,
      startDate: value,
    });
  };

  const handleEndDate = (value) => {
    setSearchParam({
      ...searchParam,
      endDate: value,
    });
  };

  const handleGatingTypeCode = (value) => {
    setSearchParam({
      ...searchParam,
      gatingTypeCode: value,
    });

    getGatingStepCode(value);
  };

  const getGatingStepCode = async (value) => {
    if (value == '') {
      setGatingStepCodeConditionList([]);
    } else {
      const gatingStepCode = await getCommonCodeNamesCondition({
        optValCtn1: value,
        cmnGrCd: 'GATING_STEP_CODE',
      });
      setGatingStepCodeConditionList(gatingStepCode);
    }
  };

  useEffect(() => {
    if (searchParam.startDate != null && searchParam.endDate != null) {
      const startDate = searchParam.startDate;
      const endDate = searchParam.endDate;
      if (startDate > endDate) {
        openCommonModal({
          content: t(
            'gtng.msg.시작년도가 종료년도보다 작습니다.',
            '시작년도가 종료년도보다 작습니다.'
          ),
        });
      }
    }
  }, [searchParam]);

  const btnSearch = () => {
    setSearchParamData(searchParam);
    if (searchState == true) {
      setSearchState(false);
    }
    if (searchState == false) {
      setSearchState(true);
    }
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce((acc, cur) => Object.assign(acc, { [cur]: '' }), {}) as SearchParam
    );
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.실시년도', '실시년도')}</label>
                <SelectGroup>
                  <ComboBox
                    options={optionsGatingStartEndYearList}
                    placeholder={String(t('com.label.전체', '전체'))}
                    defaultValue={searchParam.startDate}
                    onChange={(value) => handleStartDate(value)}
                  />
                  <span className="tilde">~</span>
                  <ComboBox
                    options={optionsGatingStartEndYearList}
                    placeholder={String(t('com.label.전체', '전체'))}
                    defaultValue={searchParam.endDate}
                    onChange={(value) => handleEndDate(value)}
                  />
                </SelectGroup>
              </SearchCols>
              <SearchCols>
                <label>{String(t('gtng.label.Gating 유형', 'Gating 유형'))}</label>
                <ComboBox
                  options={gatingTypeConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.gatingTypeCode}
                  onChange={(value) => handleGatingTypeCode(value)}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 단계', 'Gating 단계')}</label>
                <ComboBox
                  options={gatingStepCodeConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.gatingStepCode}
                  onChange={(value) => {
                    setSearchParam({
                      ...searchParam,
                      gatingStepCode: value,
                    });
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.Gating Task명', 'Gating Task명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="gatingName"
                  placeholder={String(
                    t('gtng.msg.Gating Task명을 입력해 주세요.', 'Gating Task명을 입력해 주세요.')
                  )}
                  value={searchParam.gatingName}
                  onChange={handleSearchParam}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 상태', 'Gating 상태')}</label>
                <ComboBox
                  options={gatingProgressStatusCodeConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.progressStatusCode}
                  onChange={(value) => {
                    setSearchParam({
                      ...searchParam,
                      progressStatusCode: value,
                    });
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Product Type', 'Product Type')}</label>
                <ComboBox
                  options={gatingProductConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam.productTypeCode}
                  onChange={(value) =>
                    setSearchParam({
                      ...searchParam,
                      productTypeCode: value,
                    })
                  }
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>

      <GatingRegistGrid
        editable={true}
        searchParamData={searchParamData}
        setSearchState={setSearchState}
        searchState={searchState}
      />
    </>
  );
};

export default GatingRegistPage;
