/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useLoading } from 'components/process/Loading';
import { getUserInfo } from 'apis/common/Employee';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import {
  approveApprovalRequestResult,
  cancelApprovalRequestResult,
  findApproveRequestDetail,
  getMasterId,
  rejectApprovalRequestResult,
  saveApproveRequest,
} from 'apis/approves/approves';
import {
  RequestDetail,
  SaveApprover,
  adapterApproveToEmp,
  ApprovalAppd,
  clearApprovalAppd,
  ApproveRequestDetail,
} from 'models/approves/ReferenceOpinion';
import ApproveBasicInfo from './ApproveBasicInfo';
import ApprovalLineInfo from './ApproveLineInfo';
import ApproveRequestDetailCase, { ApproveRequestPageType } from './ApproveRequestDetailCase';
import { IconButton } from 'components/buttons/IconSVG';
import CustomDialog from 'components/modals/common/CustomDialog';
import useSessionStore from 'stores/useSessionStore';
import ApproveModal from 'components/modals/approves/ApproveModal';
import ApproveDetailLineInfo from './ApproveDetailLineInfo';
import ApproveDetailBasicInfo from './ApproveDetailBasicInfo';

type Props = {
  open: boolean;
  close: () => void;
  pageId: ApproveRequestPageType;
  aprReqId?: string;
  condition?: any;
};

const ApproveRequestModal = (props: Props) => {
  const { pageId } = props;
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { userId } = useSessionStore();
  const { openLoading } = useLoading();
  const basicRef = useRef<any>();
  const childRef = useRef<any>(null);
  const [detail, setDetail] = useState<RequestDetail>({
    aprPvnDdlnCd: 'APPRCODE_5Y', // 결재보존기한코드
    // apprSecurityType: '0', // 문서종류 (비밀문서:1,일반문서:0)
    apprLineType: '0', // 협의유형 (0:순차협의, 1:병렬협의)
    aprPageId: pageId,
  });
  const [approveLine, setApproveLine] = useState({
    approvalAppd: [] as ApprovalAppd[],
    approvalInfr: [] as ApprovalAppd[],
  });
  const approvalsRef = useRef<SaveApprover[]>([]);
  const notifiersRef = useRef<SaveApprover[]>([]);
  const [condition, setCondition] = useState<any>(props.condition);
  const [aprReqId, setAprReqId] = useState<string>(props.aprReqId || '');
  const [waitApprovalAppd, setWaitApprovalAppd] = useState<ApprovalAppd>(clearApprovalAppd);
  const [isApprover, setIsApprover] = useState<boolean>(false);
  const [isNewApprove, setNewApprove] = useState<boolean>(false);
  const [approveModalOpen, setApproveModalOpen] = useState<boolean | 'APPROVE' | 'REJECT'>(false);
  const isEditable = useMemo(
    () => isNewApprove || 'TMP' === detail?.aprReqProgStatCd,
    [isNewApprove, detail?.aprReqProgStatCd]
  );

  const init = () => {
    if (aprReqId) {
      searchRequest();
    } else {
      newRequest();
    }
  };

  useEffect(() => {
    openLoading(true);
    init();
    openLoading(false);
  }, []);

  const newRequest = async () => {
    const result = await getMasterId();
    setNewApprove(true);
    if (!result.data) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.결재ID 생성에 실패했습니다.', '결재ID 생성에 실패했습니다.'),
      });
      handleClose();
      return;
    }

    const userInfo = await getUserInfo();
    setAprReqId(result.data ?? '');
    setDetail({
      ...detail,
      aprReqId: result.data ?? '',
      aprReqUserNm: userInfo,
      dataInsDtm: dayjs().format('YYYY.MM.DD').toString(),
      // approvalInfr: props.condition.approvalInfr ? props.condition.approvalInfr : null,
    });

    // 통보처가 있는 경우
    if (props.condition.approvalInfr != null && props.condition.approvalInfr != '') {
      notifiersRef.current = props.condition.approvalInfr;
      setApproveLine({
        approvalAppd: props.condition.approvalAppd,
        approvalInfr: props.condition.approvalInfr,
      });
    }
  };

  const searchRequest = async () => {
    const response = await findApproveRequestDetail(aprReqId);
    if (!response) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.결재 정보가 없습니다.', '결재 정보가 없습니다.'),
      });
      handleClose();
      return;
    }
    setNewApprove(false);
    const approvalAppd = adapterApproveToEmp(response.approvalAppd); // 결재자
    const approvalInfr = adapterApproveToEmp(response.approvalInfr); // 통보자
    setDetail({
      ...response,
      approvalAppd: approvalAppd,
      approvalInfr: approvalInfr,
    });
    if ('condition' in response && response?.condition && Object.keys(response?.condition).length) {
      setCondition(response?.condition);
    }
    approvalsRef.current = approvalAppd;
    notifiersRef.current = approvalInfr;
    setApproveLine({
      approvalAppd: response.approvalAppd,
      approvalInfr: response.approvalInfr,
    });
    const nowApprove = response.approvalAppd?.find((item) => item.aprPsgStatCd == 'WAIT');
    if (nowApprove && nowApprove.aprAprvUserId === userId) {
      setWaitApprovalAppd(nowApprove);
      setIsApprover(true);
    }
  };

  const validation = async () => {
    if (!(await basicRef.current?.validate())) return false;
    if (!(await childRef.current?.validate())) return false;

    if (!approvalsRef.current?.length) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.승인자 정보를 추가해 주세요.', '승인자 정보를 추가해 주세요.'),
      });
      return false;
    }
    return true;
  };

  /**
   * 결재 요청 처리
   */
  const handleConfirm = async () => {
    if (!(await validation())) return;

    console.log(' notifiersRef.current1 111  ', notifiersRef.current);

    openCommonModal({
      modalType: 'confirm',
      content: t('com.msg.결재요청하시겠습니까?', '결재요청하시겠습니까?'),
      yesCallback: async () => {
        openLoading(true);
        const response = await saveApproveRequest({
          ...detail,
          approvalAppd: approvalsRef.current,
          approvalInfr: notifiersRef.current,
          aprReqProgStatCd: 'REQ',
          optValCtn1: pageId, // // 결재요청 호출페이지 유형
          optValCtn2: JSON.stringify(
            pageId === ApproveRequestPageType.IP_APPROVE_REQ
              ? detail
              : pageId === ApproveRequestPageType.UT_REVIEW_REQ
              ? { utmId: props.condition.utmId }
              : condition
          ),
          bizReqData: childRef.current?.getBizReqData(detail.aprReqId, detail.trnmBdyCtn),
        });
        openLoading(false);
        if (response.successOrNot === 'Y') {
          openMessageBar({
            type: 'confirm',
            content: t('com.msg.결재요청되었습니다.', '결재요청되었습니다.'),
          });
          handleClose();
          return;
        }
        openMessageBar({
          type: 'error',
          content:
            response.data || t('com.msg.결재 요청에 실패했습니다.', '결재 요청에 실패했습니다.'),
        });
      },
    });
  };

  /**
   * 임시 저장 처리
   */
  const handleSave = async () => {
    if (!(await validation())) return;

    console.log('condition', condition);

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        openLoading(true);
        const response = await saveApproveRequest({
          ...detail,
          approvalAppd: approvalsRef.current,
          approvalInfr: notifiersRef.current,
          optValCtn1: pageId, // // 결재요청 호출페이지 유형
          optValCtn2: JSON.stringify(
            pageId === ApproveRequestPageType.IP_APPROVE_REQ
              ? detail
              : pageId === ApproveRequestPageType.UT_REVIEW_REQ
              ? { utmId: condition.utmId }
              : condition
          ),
          // optValCtn2: JSON.stringify(
          //   pageId === ApproveRequestPageType.IP_APPROVE_REQ ? detail : condition
          // ),
          bizReqData: childRef.current?.getBizReqData(detail.aprReqId, detail.trnmBdyCtn),
        });
        openLoading(false);
        if (response.successOrNot === 'Y') {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          setDetail({
            ...detail,
            aprReqProgStatNm: '임시저장',
          });
          return;
        }
        openMessageBar({
          type: 'error',
          content: response.data || t('com.msg.저장 실패했습니다.', '저장 실패했습니다.'),
        });
      },
    });
  };

  const handleChange = (name: string, value: string) => {
    if (name) {
      setDetail({ ...detail, [name]: value });
    }
  };

  const handleClose = () => {
    props.close();
  };

  const handleCancelApprove = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t(`com.msg.결재취소하시겠습니까?`, `결재취소하시겠습니까?`),
      yesCallback: async () => {
        openLoading(true);
        const response = await cancelApprovalRequestResult(aprReqId);
        openLoading(false);

        if (response.successOrNot === 'Y') {
          openMessageBar({
            type: 'confirm',
            content: t('com.msg.결재취소되었습니다.', '결재취소되었습니다.'),
          });
          handleClose();
        } else {
          openMessageBar({
            type: 'error',
            content:
              typeof response.data === 'string'
                ? response.data
                : t('com.msg.오류가 발생하였습니다.', '오류가 발생하였습니다.'),
          });
        }
      },
    });
  };

  const handleApprove = async (opinion: string) => {
    openLoading(true);
    const response = await approveApprovalRequestResult({
      ...detail,
      resultApprovalOpinion: opinion,
      resultAprLnSnb: waitApprovalAppd?.aprLnSnb ?? '',
    } as ApproveRequestDetail);
    openLoading(false);

    if (response.successOrNot === 'Y') {
      openMessageBar({
        type: 'confirm',
        content: t('com.label.승인되었습니다.', '승인되었습니다.'),
      });
      handleClose();
    } else {
      openMessageBar({
        type: 'error',
        content:
          typeof response.data === 'string'
            ? response.data
            : t('com.label.요청에 실패했습니다.', '요청에 실패했습니다.'),
      });
    }
  };

  const handleRejectApprove = async (opinion: string) => {
    openLoading(true);
    const response = await rejectApprovalRequestResult({
      ...detail,
      resultApprovalOpinion: opinion,
      resultAprLnSnb: waitApprovalAppd?.aprLnSnb ?? '',
    } as ApproveRequestDetail);

    openLoading(false);
    if (response.successOrNot === 'Y') {
      openMessageBar({
        type: 'confirm',
        content: t('com.msg.반려 완료되었습니다.', '반려 완료되었습니다.'),
      });
      handleClose();
    } else {
      openMessageBar({
        type: 'error',
        content:
          typeof response.data === 'string'
            ? response.data
            : t('com.label.요청에 실패했습니다.', '요청에 실패했습니다.'),
      });
    }
  };

  const dialogButtons = useMemo(() => {
    if (!aprReqId || !detail?.aprReqId) return [];
    if (isNewApprove || 'TMP' === detail.aprReqProgStatCd) {
      return [
        <Button key={'save'} onClick={handleSave}>
          {t('com.button.임시저장', '임시저장')}
        </Button>,
        <Button
          key={'req'}
          css={IconButton.button}
          className="confirm"
          onClick={handleConfirm}
          disableRipple
        >
          {t('com.button.결재요청', '결재요청')}
        </Button>,
      ];
    }
    const buttons = [] as ReactElement[];
    if ('REQ' === detail.aprReqProgStatCd && detail.aprReqUserId === userId) {
      buttons.push(
        <Button key={'cancel'} className="cancel" onClick={handleCancelApprove} disableRipple>
          {t('com.button.상신취소', '상신취소')}
        </Button>
      );
    }
    if (['REQ', 'INP'].includes(detail?.aprReqProgStatCd || '') && isApprover) {
      buttons.push(
        <Button
          key={'reject'}
          className="cancel"
          onClick={() => {
            setApproveModalOpen('REJECT');
          }}
          disableRipple
        >
          {t('com.button.반려', '반려')}
        </Button>
      );
      buttons.push(
        <Button
          key={'approve'}
          css={IconButton.button}
          className="ok"
          onClick={() => {
            setApproveModalOpen('APPROVE');
          }}
          disableRipple
        >
          {t('com.button.승인', '승인')}
        </Button>
      );
    }
    return buttons;
  }, [detail, isApprover]);

  return (
    <CustomDialog
      title={t('com.label.결재 요청', '결재 요청')}
      open={props.open}
      size={'xl'}
      buttons={dialogButtons}
      onClose={handleClose}
      onCancel={(dialogButtons || []).length ? handleClose : undefined}
    >
      <>
        {isEditable ? (
          <ApproveBasicInfo ref={basicRef} detail={detail} onChange={handleChange} />
        ) : (
          <ApproveDetailBasicInfo approveInfo={detail as ApproveRequestDetail} />
        )}

        <ApproveRequestDetailCase
          ref={childRef}
          pageId={pageId}
          condition={condition}
          aprReqId={aprReqId}
          isReadOnly={!isEditable}
        />

        {isEditable ? (
          <ApprovalLineInfo
            approvalAppd={approvalsRef.current || []}
            approvalInfr={notifiersRef.current || []}
            onApprovalsChange={(list) => (approvalsRef.current = list)}
            onNotifiersChange={(list) => (notifiersRef.current = list)}
          />
        ) : (
          <ApproveDetailLineInfo
            approvalAppd={approveLine?.approvalAppd || []}
            approvalInfr={approveLine?.approvalInfr || []}
          />
        )}

        {approveModalOpen && (
          <ApproveModal
            open={approveModalOpen}
            title={t('com.label.결재의견', '결재의견')}
            approvalType={'0'}
            close={() => setApproveModalOpen(false)}
            approveCallback={(msg) => {
              setApproveModalOpen(false);
              handleApprove(msg);
            }}
            rejectCallback={(msg) => {
              setApproveModalOpen(false);
              handleRejectApprove(msg);
            }}
          />
        )}
      </>
    </CustomDialog>
  );
};

export default ApproveRequestModal;
