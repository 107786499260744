/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button, Select } from '@mui/material';
import { Checkbox } from '@mui/material';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { toggleClass } from '@grapecity/wijmo';
import {
  ContentLayout,
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import Datepicker from 'react-tailwindcss-datepicker';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';
import { UtMatrixDetailList, UtMatrixMasterList } from 'models/ut/UtMatrixList';
import {
  deleteUtMatrixMaster,
  getUtMatrixDetailList,
  getUtMatrixMasterList,
} from 'apis/ut/UtMatrixList';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import { useNavigate } from 'react-router-dom';
import { Code } from 'models/common/CommonCode';
import { addClass } from '@grapecity/wijmo';
import { CellType, DataMap } from '@grapecity/wijmo.grid';
import moment from 'moment';
import useSessionStore from 'stores/useSessionStore';
import { hasRole } from 'utils/SessionUtil';
import { UseToggle, st } from 'components/inputs/CustomInput';
import CustomSwitch from 'components/selects/CustomSwitch';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { SearchParam, UtMatrixManagerList } from 'models/ut/UtMatrixManager';
import { CrudCode } from 'models/common/Edit';
import { Employee } from 'models/admin/Employee';
import UserMultiModal from 'components/modals/common/UserMultiModal';
import useEvent from 'react-use-event-hook';
import {
  getDtalProcCodes,
  getUtMatrixManagerMasterList,
  saveUtMatrixManagerList,
} from 'apis/ut/UtMatrixManager';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import { d } from 'msw/lib/glossary-de6278a9';
import { Create } from '@mui/icons-material';
import _ from 'lodash';

const UtMatrixManagerPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const navigate = useNavigate();
  const userSession = useSessionStore();

  const gridRef = useRef<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();

  const [rowData, setRowData] = useState<UtMatrixManagerList[]>([]);

  const [bildPlntCode, setBildPlntCode] = useState<Code[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [prdnProcSearchCode, setPrdnProcSearchCode] = useState<string[]>([]);
  const [dtalProcGridCode, setDtalProcGridCode] = useState<Code[]>([]);
  const [dtalProcSearchCode, setDtalProcSearchCode] = useState<Code[]>([]);
  const useYn = [
    { cmnCd: '', cmnCdNm: String(t('com.label.전체', '전체')) },
    { cmnCd: 'Y', cmnCdNm: String(t('com.label.사용', '사용')) },
    { cmnCd: 'N', cmnCdNm: String(t('com.label.미사용', '미사용')) },
  ];

  const [gridUseYn, setGridUseYn] = useState<Code[]>([]);

  const [userIds, setUserIds] = useState<string>('');
  const [wrtUserNm, setWrtUserNm] = useState<string>('');
  const initSearchParam = {
    bildPlntCds: [],
    prdnProcCds: [],
    dtalProcCds: [],
    insTgtYn: '',
    useYn: '',
  };
  const [searchParam, setSearchParam] = useState<SearchParam>(initSearchParam);

  const [mode, setMode] = useState<string>('');

  const [isOpenUserModal, setOpenUserModal] = useState<boolean>(false);
  const [isOpenUserMultiModal, setOpenUserMultiModal] = useState<boolean>(false);
  const [isOpenUserReadModal, setOpenUserReadModal] = useState<boolean>(false);

  const [rowItem, setRowItem] = useState<any>();
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const [delData, setDelData] = useState<UtMatrixManagerList[]>([]);

  const isReadOnly = useMemo(() => {
    return hasAuth;
  }, [hasAuth]);

  useEffect(() => {
    initCondition();
    if (hasRole('ADM') || hasRole('UT_MANAGER')) {
      setHasAuth(true);
    }
  }, []);

  const initCondition = async () => {
    const bildPlntCode = await getCommonCodeNames('BILD_PLNT_CD');
    const prdnProcCd = await getCommonCodeNames('PRDN_PROC_CD');
    const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');
    const getUseYn: Code[] = await getCommonCodeNames('YN_FLAG');
    setGridUseYn(getUseYn);
    setBildPlntCode(bildPlntCode);
    setPrdnProcCode(prdnProcCd);
    setDtalProcGridCode(dtalProcCd);
  };

  const layoutDefinition = useMemo(() => {
    const bildPlntCodeMap = new DataMap(bildPlntCode, 'cmnCd', 'cmnCdNm');
    const dtalProcCodeMap = new DataMap(dtalProcGridCode, 'cmnCd', 'cmnCdNm');
    const prdnProcCodeMap = new DataMap(prdnProcCode, 'cmnCd', 'cmnCdNm');
    const useYnCodeMap = new DataMap(gridUseYn, 'cmnCd', 'cmnCdNm');
    return [
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        isReadOnly: true,
        width: 50,
        align: 'center',
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'planProcId',
        visible: false,
      },
      {
        binding: 'insTgtYn',
        header: String(t('ut.label.작성대상', '작성대상')),
        isReadOnly: true,
        width: 100,
        align: 'center',
        cssClass: 'WijmoCheck',
        cellTemplate: (params) => {
          const checked = params.value === 'Y' ? 'checked' : '';
          return `<div class="checkbox">
                  <input type="checkbox" id="insTgtYn" ${checked} />
                  <label />
                </div>
          `;
        },
      },
      {
        binding: 'bildPlntCd',
        header: String(t('ut.label.공장', '공장')),
        width: 170,
        isReadOnly: !hasAuth,
        cssClass: 'WijmoSelect',
        dataMap: bildPlntCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey === CrudCode.CREATE) {
            if (bildPlntCodeMap.getDisplayValue(item.value) == undefined) {
              return `<span>${item.text}</span>`;
            } else {
              return `<span>${bildPlntCodeMap.getDisplayValue(item.text)}</span>`;
            }
          } else {
            return `<span>${item.item.bildPlntCdNm}</span>`;
          }
        },
      },
      {
        binding: 'bildPlntCdNm',
        width: 80,
        align: 'center',
        isReadOnly: true,
        visible: false,
      },
      {
        binding: 'prdnProcCd',
        header: String(t('ut.label.공정', '공정')),
        width: 170,
        isReadOnly: !hasAuth,
        cssClass: 'WijmoSelect',
        dataMap: prdnProcCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey === CrudCode.CREATE) {
            if (prdnProcCodeMap.getDisplayValue(item.value) == undefined) {
              return `<span>${item.text}</span>`;
            } else {
              return `<span>${prdnProcCodeMap.getDisplayValue(item.text)}</span>`;
            }
          } else {
            return `<span>${item.item.prdnProcCdNm}</span>`;
          }
        },
      },
      {
        binding: 'prdnProcCdNm',
        width: 80,
        align: 'center',
        isReadOnly: true,
        visible: false,
      },
      {
        binding: 'dtalProcCd',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 180,
        isReadOnly: !hasAuth,
        cssClass: 'WijmoSelect',
        dataMap: dtalProcCodeMap,
        cellTemplate: (item) => {
          if (item.item.crudKey === CrudCode.CREATE) {
            if (dtalProcCodeMap.getDisplayValue(item.value) == undefined) {
              return `<span>${item.text}</span>`;
            } else {
              return `<span>${dtalProcCodeMap.getDisplayValue(item.text)}</span>`;
            }
          } else {
            return `<span>${item.item.dtalProcCdNm}</span>`;
          }
        },
      },
      {
        binding: 'dtalProcCdNm',
        width: 80,
        align: 'center',
        isReadOnly: true,
        visible: false,
      },
      {
        binding: 'totalUseCnt',
        width: 80,
        align: 'center',
        isReadOnly: true,
        visible: false,
      },
      {
        binding: 'wrtUserId',
        header: String(t('ut.label.담당자', '담당자')),
        width: '*',
        minWidth: 160,
        isReadOnly: !hasAuth,
        align: 'center',
        cssClass: 'WijmoFind',
        cellTemplate: (item) => {
          if (item.item.crudKey == CrudCode.CREATE) {
            if (item.item.wrtUserId === undefined) {
              return `<span>${item.text}</span><button id="${
                hasAuth === true ? 'gridWrtUserId' : 'gridReadWrtUserId'
              }" />`;
            } else {
              return `<span>${item.item.wrtUserNm}</span><button id="${
                hasAuth === true ? 'gridWrtUserId' : 'gridReadWrtUserId'
              }" />`;
            }
          } else {
            if (item.item.wrtUserId === null) {
              return `<span>${item.text}</span><button id="${
                hasAuth === true ? 'gridWrtUserId' : 'gridReadWrtUserId'
              }" />`;
            } else {
              return `<span>${item.item.wrtUserNm}</span><button id="${
                hasAuth === true ? 'gridWrtUserId' : 'gridReadWrtUserId'
              }" />`;
            }
          }
        },
      },
      {
        binding: 'wrtUserNm',
        visible: false,
      },
      {
        binding: 'useYn',
        header: String(t('ut.label.사용여부', '사용여부')),
        width: 100,
        align: 'center',
        isReadOnly: !hasAuth,
        dataMap: useYnCodeMap,
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('ut.label.등록자', '등록자')),
        width: 120,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'dataInsDtm',
        header: String(t('ut.label.등록일자', '등록일자')),
        width: 140,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('ut.label.수정자', '수정자')),
        width: 120,
        isReadOnly: true,
        align: 'center',
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('ut.label.수정일자', '수정일자')),
        width: 140,
        isReadOnly: true,
        align: 'center',
      },
    ];
  }, [rowData]);

  const callbackUserData = (callbackData) => {
    if (mode === 'searchWrtUserId') {
      setSearchParam({
        ...searchParam,
        wrtUserId: callbackData.userId,
        wrtUserNm: callbackData.empNm,
      });
      setWrtUserNm(callbackData.empNm);
    }

    if (mode === 'gridWrtUserId') {
      flexItem['wrtUserId'] = callbackData.userId;
      flexItem['wrtUserNm'] = callbackData.empNm;
      if (flexItem['crudKey'] !== CrudCode.CREATE && flexItem['crudKey'] !== CrudCode.DELETE) {
        flexItem['crudKey'] = CrudCode.UPDATE;
      }
      gridRef.current.refresh();
    }
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);
    gridRef.current = grid;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        setFlexItem(item);
        setHitTest(ht);
        switch (e.target.id) {
          case 'gridWrtUserId':
            setOpenUserMultiModal(true);
            setUserIds(item.wrtUserId);
            setMode('gridWrtUserId');
            break;
          case 'gridReadWrtUserId':
            setOpenUserReadModal(true);
            setUserIds(item.wrtUserId);
            break;
        }
      }

      if (e.target instanceof HTMLInputElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정
        setFlexItem(item);
        setHitTest(ht);
        switch (e.target.id) {
          case 'insTgtYn':
            item.insTgtYn = e.target.checked ? 'Y' : 'N';
            if (item.crudKey !== CrudCode.CREATE) {
              item.crudKey = CrudCode.UPDATE;
            }
            gridRef.current?.refresh();
            break;
        }
      }
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // Gating 유형, Gating 단계
      if (item.crudKey !== CrudCode.CREATE) {
        if (['bildPlntCd', 'prdnProcCd', 'dtalProcCd'].includes(binding)) {
          cell.ariaReadOnly = true;
          toggleClass(cell, 'WijmoSelect', !isReadOnly);
          if (isReadOnly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
    }
  });

  const beginningEdit = useEvent((grid, e) => {
    const col = grid.columns[e.col];
    const binding = col.binding;
    const item = grid.rows[e.row].dataItem;

    // readonly 설정
    if (['wrtUserId'].includes(binding)) {
      e.cancel = true;
    }
    if (item.crudKey !== CrudCode.CREATE) {
      if (['bildPlntCd', 'prdnProcCd', 'dtalProcCd'].includes(binding)) {
        e.cancel = true;
      }
    }
    if (item.crudKey == CrudCode.CREATE && binding === 'prdnProcCd') {
      if (item.dtalProcCd !== null && item.dtalProcCd !== undefined && item.dtalProcCd !== '') {
        item.dtalProcCd = '';
      }
    }
    if (binding === 'dtalProcCd') {
      const selectedCode = dtalProcGridCode.filter(
        (dtalCode) => dtalCode.optValCtn1 === item.prdnProcCd
      );
      col.dataMap = new DataMap(selectedCode, 'cmnCd', 'cmnCdNm');
    }
  });

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
    if (name === 'prdnProcCd') {
      getDtalProcCode(value);
      setSearchParam({ ...searchParam, [name]: value, dtalProcCd: '' });
    }
  };

  const getDtalProcCode = async (value) => {
    if (value == '') {
      setDtalProcSearchCode([]);
    } else {
      const dtalProcCode: Code[] = await getDtalProcCodes({
        optValCtn1: value,
        cmnGrCd: 'DTAL_PROC_CD',
      });
      setDtalProcSearchCode(dtalProcCode);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Ut Matrix 대상 기준정보';
    book.saveAsync(
      getExcelFileName(t('ut.label.UT Matrix 대상 기준정보', 'UT Matrix 대상 기준정보'))
    );
  };

  const btnSearch = () => {
    getUtMatrixManagerMasterList(searchParam).then((result: UtMatrixManagerList[]) => {
      if (result !== null) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: initSearchParam[cur] || '' }),
          {}
        ) as SearchParam
    );
    setWrtUserNm('');
  };

  const btnAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      insTgtYn: 'Y',
      useYn: 'Y',
      totalUseCnt: '',
    } as UtMatrixManagerList;
    setRowData([newRow, ...rowData]);
  };

  const btnDelRow = () => {
    if (flexRef === null) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.기준정보를 선택해 주세요.', '기준정보를 선택해 주세요.'),
      });
      return false;
    }

    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    const selectedData = isSelected.map(({ dataItem }) => dataItem);

    const valid = selectedData
      .map((item) => {
        if (item.totalUseCnt !== null && item.crudKey !== CrudCode.CREATE) {
          openMessageBar({
            type: 'error',
            content: t(
              'ut.label.사용중인 기준정보 입니다. 삭제할 수 없습니다.',
              '사용중인 기준정보 입니다. 삭제할 수 없습니다.'
            ),
          });
          return false;
        }
      })
      .filter((element) => element !== undefined);

    if (valid.length) return false;

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.기준정보를 선택해 주세요.', '기준정보를 선택해 주세요.'),
      });
      return false;
    } else {
      const selectedIds = selectedRowNodes
        .map((rowNode) => {
          return parseInt(rowNode._idx!);
        })
        .reverse();

      selectedIds.forEach((idx) => {
        if (rowData[idx].crudKey === CrudCode.CREATE) {
          delete rowData[idx];
        } else {
          rowData[idx].crudKey = CrudCode.DELETE;
        }
      });

      const filteredData = rowData.filter((element) => element !== undefined);
      setRowData(filteredData);
    }
  };

  const btnCopyRow = () => {
    if (flexRef == null) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.기준정보를 선택해 주세요.', '기준정보를 선택해 주세요.'),
      });
      return false;
    }

    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.기준정보를 선택해 주세요.', '기준정보를 선택해 주세요.'),
      });
      return false;
    } else {
      const selectNo = 0; //단일 선택 복사 시 0번째
      const copiedData = isSelected[selectNo].dataItem;
      const newRow = {
        crudKey: CrudCode.CREATE,
        insTgtYn: copiedData?.insTgtYn,
        bildPlntCd: copiedData?.bildPlntCd,
        prdnProcCd: copiedData?.prdnProcCd,
        wrtUserId: copiedData?.wrtUserId,
        wrtUserNm: copiedData?.wrtUserNm,
        useYn: copiedData?.useYn,
      } as UtMatrixManagerList;
      setRowData([newRow, ...rowData]);
    }
  };

  const btnSave = () => {
    const validStatusCorU = rowData.filter(
      (element) => element.crudKey == CrudCode.CREATE || element.crudKey == CrudCode.UPDATE
    );
    const valid = validStatusCorU
      .map((rowNode, index) => {
        if (rowNode.crudKey != CrudCode.DELETE) {
          if (rowNode.bildPlntCd == null || rowNode.bildPlntCd == '')
            return `${t('ut.label.공장을 선택해 주세요.', '공장을 선택해 주세요.')}`;
          if (rowNode.prdnProcCd == null || rowNode.prdnProcCd == '')
            return `${t('ut.label.공정을 선택해 주세요.', '공정을 선택해 주세요.')}`;
          if (rowNode.dtalProcCd == null || rowNode.dtalProcCd == '')
            return `${t('ut.label.세부공정을 선택해 주세요.', '세부공정을 선택해 주세요.')}`;
          if (
            rowNode.crudKey !== CrudCode.CREATE &&
            rowNode.totalUseCnt !== null &&
            rowNode.useYn === 'N'
          )
            return `${t(
              'ut.label.사용중인 기준정보 입니다. 삭제할 수 없습니다.',
              '사용중인 기준정보 입니다. 삭제할 수 없습니다.'
            )}`;
        }
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({ type: 'error', content: content || '' });
      return false;
    }

    // 중복 체크
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: () => {
        const saveRows = rowData
          .map((rowNode) => {
            return rowNode.crudKey ? rowNode : null;
          })
          .filter((element) => element !== null) as UtMatrixManagerList[];

        const saveRowsData = [...saveRows, ...delData];

        saveUtMatrixManagerList(saveRowsData).then((result) => {
          if (!result) {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return false;
          } else if (result.insertedRows === -1) {
            openMessageBar({
              type: 'error',
              content: t(
                'com.label.공장, 공정, 공정군은 중복 등록할 수 없습니다.',
                '공장, 공정, 공정군은 중복 등록할 수 없습니다.'
              ),
            });
            return false;
          } else {
            openMessageBar({
              type: 'confirm',
              content: t('com.label.저장되었습니다.', '저장되었습니다.'),
            });
            setDelData([]);
            btnSearch();
          }
        });
      },
      noCallback: () => {
        return false;
      },
    });
  };

  return (
    <ContainerLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{String(t('ut.label.공장', '공장'))}</label>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.bildPlntCds || []}
                  onChange={(value) => {
                    handleOnChange('bildPlntCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.공정', '공정'))}</label>
                <MultiComboBox
                  id="prdnProcCd"
                  options={prdnProcCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.prdnProcCds || []}
                  onChange={(value) => {
                    handleOnChange('prdnProcCd', value.join());
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('ut.label.세부공정', '세부공정'))}</label>
                <MultiComboBox
                  id="dtalProcSearchCode"
                  options={dtalProcSearchCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.dtalProcCds || []}
                  onChange={(value) => {
                    handleOnChange('dtalProcCd', value.join());
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('com.label.담당자', '담당자')}</label>
                <CustomInputWithSearch
                  name="userId"
                  className="find"
                  value={wrtUserNm || ''}
                  defaultValue={wrtUserNm || ''}
                  placeholder={String(
                    t('com.label.담당자를 선택해 주세요.', '담당자를 선택해 주세요.')
                  )}
                  onChange={(e) => {
                    setWrtUserNm(e.target.value);
                    setSearchParam({ ...searchParam, wrtUserNm: e.target.value, wrtUserId: '' });
                  }}
                  onSearchClick={(e) => {
                    setOpenUserModal(true);
                    setMode('searchWrtUserId');
                  }}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ut.label.작성대상', '작성대상')}</label>
                <ComboBox
                  options={useYn}
                  defaultValue={searchParam.insTgtYn}
                  onChange={(value) => {
                    setSearchParam({
                      ...searchParam,
                      insTgtYn: value,
                    });
                  }}
                />
                {/* <UseToggle className="switch">
                  <input
                    type="checkbox"
                    name="insTgtYn"
                    value={searchParam.insTgtYn}
                    checked={searchParam.insTgtYn === 'Y'}
                    onChange={() => {
                      setSearchParam({
                        ...searchParam,
                        insTgtYn: searchParam.insTgtYn === 'Y' ? 'N' : 'Y',
                      });
                    }}
                  />
                  <span className="slider"></span>
                  <span
                    className="labels"
                    data-on={t('com.label.사용', '사용')}
                    data-off={t('com.label.미사용', '미사용')}
                  ></span>
                </UseToggle> */}
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.사용여부', '사용여부')}</label>
                <ComboBox
                  options={useYn}
                  defaultValue={searchParam.useYn}
                  onChange={(value) => {
                    setSearchParam({
                      ...searchParam,
                      useYn: value,
                    });
                  }}
                />
                {/* <UseToggle className="switch">
                  <input
                    type="checkbox"
                    name="useYn"
                    value={searchParam.useYn}
                    checked={searchParam.useYn === 'Y'}
                    onChange={() => {
                      setSearchParam({
                        ...searchParam,
                        useYn: searchParam.useYn === 'Y' ? 'N' : 'Y',
                      });
                    }}
                  />
                  <span className="slider"></span>
                  <span
                    className="labels"
                    data-on={t('com.label.사용', '사용')}
                    data-off={t('com.label.미사용', '미사용')}
                  ></span>
                </UseToggle> */}
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={btnReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.UT Matrix 대상 기준정보', 'UT Matrix 대상 기준정보')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          {hasAuth && (
            <Button css={IconButton.button} className="addRow" onClick={btnAddRow} disableRipple>
              {t('com.button.행추가', '행추가')}
            </Button>
          )}
          {hasAuth && (
            <Button css={IconButton.button} className="delRow" onClick={btnDelRow} disableRipple>
              {t('com.button.행삭제', '행삭제')}
            </Button>
          )}
          {hasAuth && (
            <Button css={IconButton.button} className="copyRow" onClick={btnCopyRow} disableRipple>
              {t('com.button.행복사', '행복사')}
            </Button>
          )}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={btnSearch} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        initialized={onInitialized}
        height={520}
        beginningEdit={beginningEdit}
        itemFormatter={onItemFormatter}
        excludeFilter={['insTgtYn', 'useYn']}
        excludePin={['insTgtYn', 'useYn']}
        autoCheck={true}
        selectionMode={SelectionMode.Row}
      />
      <GlobalBtnGroup>
        {hasAuth && (
          <Button css={IconButton.button} className="save" onClick={btnSave}>
            {t('com.button.저장', '저장')}
          </Button>
        )}
      </GlobalBtnGroup>
      {isOpenUserModal && (
        <UserMultiModal
          open={isOpenUserModal}
          close={() => setOpenUserModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          mode={mode}
          condition={{ empNm: wrtUserNm }}
          singleSelect={true}
          onCallback={(callbackData) => {
            if (callbackData === null) {
              openMessageBar({
                type: 'warning',
                content: t('com.msg.선택된 사용자가 없습니다.', '선택된 사용자가 없습니다.'),
              });
              return false;
            }
            callbackUserData(callbackData);
          }}
        />
      )}
      {isOpenUserMultiModal && (
        <UserMultiModal
          open={isOpenUserMultiModal}
          close={() => setOpenUserMultiModal(false)}
          title={t('com.label.담당자 조회', '담당자 조회')}
          mode={mode}
          defaultUserId={userIds}
          singleSelect={false}
          onCallback={(callbackData: any) => {
            if (callbackData.userId === '') {
              openMessageBar({
                type: 'warning',
                content: t('com.msg.선택된 사용자가 없습니다.', '선택된 사용자가 없습니다.'),
              });
              return false;
            }
            callbackUserData(callbackData);
          }}
        />
      )}
      {isOpenUserReadModal && (
        <GatingContListPopUp
          open={isOpenUserReadModal}
          close={() => setOpenUserReadModal(false)}
          title={t('com.label.담당자', '담당자')}
          initParam={userIds}
        />
      )}
    </ContainerLayout>
  );
};

export default UtMatrixManagerPage;
