/** @jsxImportSource @emotion/react */
import { useState, useRef, useEffect, useCallback, memo } from 'react';
import { ApprovalDelegate } from 'models/admin/Approval';
import { CommonYN } from 'models/common/Common';
import { Employee } from 'models/admin/Employee';
import { getApprovalDelegates, saveApprovalDelegates } from 'apis/admin/Approval';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DataType } from '@grapecity/wijmo';
import EmployeeModal from 'components/modals/common/EmployeeModal';
import dayjs from 'dayjs';
import { useCommonModal } from 'hooks/useCommonModal';
import useSessionStore from 'stores/useSessionStore';
import { getEmployeeBySearchCondition } from 'apis/admin/Employee';
import { CrudCode } from 'models/common/Edit';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import CustomGrid from 'components/grids/CustomGrid';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as input from '@grapecity/wijmo.input';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { getExcelFileName } from 'utils/ExcelUtil';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { useMessageBar } from 'hooks/useMessageBar';
import { commonYNList } from 'models/common/CommonCode';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import useEvent from 'react-use-event-hook';

interface SearchParam {
  DlgtUserId?: string;
  status?: string;
}

interface Props {
  searchParam: SearchParam;
  callBack?: any;
}

const ApprovalDelegateGrid = (props: Props) => {
  const gridRef = useRef<any>();
  const [flexRef, setflexRef] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();

  const [rowData, setRowData] = useState<ApprovalDelegate[]>([]);
  const [rowIndex, setRowIndex] = useState<string>('');
  const [colIndex, setColIndex] = useState<string>('');
  const [userInfo, setUserInfo] = useState<Employee>({} as Employee);

  const [userIds, setUserIds] = useState<string>('');
  const [title, setTitle] = useState<any>('');
  const [mode, setMode] = useState<any>('');

  const [EmployeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);
  const [isOpenUserMultiReadModal, setOpenUserMultiReadModal] = useState<boolean>(false);

  const userSession = useSessionStore();

  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();
  const { t } = useTranslation();

  console.log('rowData', rowData);

  const layoutDefinition = () => {
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 45,
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'aprDlgtUserId',
        visible: false,
      },
      {
        binding: 'aprDlgtUserDisplay',
        header: String(t('admin.grid.위임자', '위임자')),
        align: 'left',
        width: 180,
        cssClass: 'WijmoFind',
        cellTemplate: (params) => {
          if (
            params.item.crudKey === CrudCode.CREATE ||
            params.item.aprDlgtUserDisplay === undefined
          ) {
            return `<span>${params.text}</span><button id="btnAprDlgtUserDisplay" />`;
          }
          return `<span>${params.item.aprDlgtUserDisplay}</span><button id="btnAprDlgtUserDisplay" />`;
        },
      },
      {
        binding: 'dlgtUserDept',
        header: String(t('admin.grid.위임자부서', '위임자부서')),
        align: 'left',
        width: 200,
        isReadOnly: true,
      },
      {
        binding: 'aprDeleUserId',
        visible: false,
      },
      {
        binding: 'aprDeleUserDisplay',
        header: String(t('admin.grid.수임자', '수임자')),
        align: 'left',
        width: 180,
        cssClass: 'WijmoFind',
        cellTemplate: (params) => {
          if (
            params.item.crudKey === CrudCode.CREATE ||
            params.item.aprDeleUserDisplay === undefined
          ) {
            return `<span>${params.text}</span><button id="btnAprDeleUserDisplay" />`;
          }
          return `<span>${params.item.aprDeleUserDisplay}</span><button id="btnAprDeleUserDisplay" />`;
        },
      },
      {
        binding: 'deleUserDept',
        header: String(t('admin.grid.수임자부서', '수임자부서')),
        align: 'left',
        width: 200,
        isReadOnly: true,
      },
      {
        binding: 'aprDlgtStDt',
        header: String(t('admin.grid.수임시작일', '수임시작일')),
        width: 140,
        align: 'center',
        cssClass: 'WijmoDate',
        format: 'yyyy.MM.dd',
        editor: new input.InputDate(document.createElement('div'), {
          format: 'yyyy.MM.dd',
          min: new Date(),
        }),
      },
      {
        binding: 'aprDlgtEndDt',
        header: String(t('admin.grid.수임종료일', '수임종료일')),
        width: 140,
        align: 'center',
        cssClass: 'WijmoDate',
        format: 'yyyy.MM.dd',
        editor: new input.InputDate(document.createElement('div'), {
          format: 'yyyy.MM.dd',
          min: new Date(),
        }),
      },
      {
        binding: 'status',
        header: String(t('com.label.상태', '상태')),
        align: 'center',
        width: 150,
        isReadOnly: true,
      },
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        width: 120,
        align: 'center',
        dataMap: new DataMap(commonYNList, 'cmnCd', 'cmnCdNm'),
      },
      {
        binding: 'dataInsUserId',
        header: String(t('com.label.등록자', '등록자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.등록일', '등록일')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
    ];
  };

  const onInitialized = (grid) => {
    setflexRef(grid);

    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        const item = panel.rows[row].dataItem;

        if (item.crudKey !== CrudCode.CREATE) {
          if ('aprDlgtUserDisplay' === binding || 'aprDeleUserDisplay' === binding) {
            cell.ariaReadOnly = true;
          }
        }
      }
    };

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;
      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;
      setflexItem(item);
      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnAprDlgtUserDisplay':
            if (item.crudKey === CrudCode.CREATE) {
              setMode('btnAprDlgtUserDisplay');
              setEmployeeModalOpen(true);
            } else {
              setUserIds(item.aprDlgtUserId);
              setTitle(t('com.label.위임자', '위임자'));
              setOpenUserMultiReadModal(true);
            }
            break;
          case 'btnAprDeleUserDisplay':
            if (item.crudKey === CrudCode.CREATE) {
              setMode('btnAprDeleUserDisplay');
              setEmployeeModalOpen(true);
            } else {
              setUserIds(item.aprDeleUserId);
              setTitle(t('com.label.수임자', '수임자'));
              setOpenUserMultiReadModal(true);
            }
            break;
        }
      }
    });
  };

  const beginningEdit = useEvent((s, e) => {
    const col = s.columns[e.col];
    const row = s.rows[e.row];

    if (col.binding === 'aprDlgtUserDisplay' || col.binding === 'aprDeleUserDisplay') {
      e.cancel = true;
    }
  });

  const fnSearch = useCallback((params: SearchParam) => {
    getApprovalDelegates(params.DlgtUserId || '', params.status || '').then(
      (result: ApprovalDelegate[]) => {
        setRowData(result);
      }
    );
  }, []);

  const fnSelectUser = (emp: Employee[]) => {
    if (mode === 'btnAprDlgtUserDisplay') {
      flexItem['aprDlgtUserId'] = emp[0].userId;
      flexItem['aprDlgtUserDisplay'] = `${emp[0].empNm}(${emp[0].userId})`;
      flexItem['dlgtUserDept'] = emp[0].deptNm;
    }
    if (mode === 'btnAprDeleUserDisplay') {
      flexItem['aprDeleUserId'] = emp[0].userId;
      flexItem['aprDeleUserDisplay'] = `${emp[0].empNm}(${emp[0].userId})`;
      flexItem['deleUserDept'] = emp[0].deptNm;
    }
    // const selectedRow = gridRef.current?.api.getRowNode(rowIndex);
    // if (colIndex == 'Dlgt') {
    //   selectedRow!.setDataValue('aprDlgtUserId', emp[0].userId);
    //   selectedRow!.setDataValue('aprDlgtUserDisplay', `${emp[0].empNm}(${emp[0].userId})`);
    //   selectedRow!.setDataValue('dlgtUserDept', emp[0].deptNm);
    // } else if (colIndex == 'Dele') {
    //   selectedRow!.setDataValue('aprDeleUserId', emp[0].userId);
    //   selectedRow!.setDataValue('aprDeleUserDisplay', `${emp[0].empNm}(${emp[0].userId})`);
    //   selectedRow!.setDataValue('deleUserDept', emp[0].deptNm);
    // }
    setEmployeeModalOpen(false);
  };

  const fnValidate = (): boolean => {
    //Validation Start
    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.aprDlgtUserId == null || rowNode.aprDlgtUserId === '')
          return `\nLine${index + 1}: ${t(
            'admin.msg.위임자를 입력해 주세요.',
            '위임자를 입력해 주세요.'
          )}`;
        if (rowNode.aprDeleUserId == null || rowNode.aprDeleUserId === '')
          return `\nLine${index + 1}: ${t(
            'admin.msg.수임자를 입력해 주세요.',
            '수임자를 입력해 주세요.'
          )}`;
        if (
          rowNode.aprDlgtStDt == null ||
          rowNode.aprDlgtStDt === '' ||
          rowNode.aprDlgtStDt === 'Invalid Date'
        )
          return `\nLine${index + 1}: ${t(
            'admin.msg.수임 시작일을 입력해 주세요.',
            '수임시작일을 입력해 주세요.'
          )}`;
        if (
          rowNode.aprDlgtEndDt == null ||
          rowNode.aprDlgtEndDt === '' ||
          rowNode.aprDlgtEndDt === 'Invalid Date'
        )
          return `\nLine${index + 1}: ${t(
            'admin.msg.수임 종료일을 입력해 주세요.',
            '수임종료일을 입력해 주세요.'
          )}`;
        if (rowNode.aprDlgtUserId == rowNode.aprDeleUserId)
          return `\nLine${index + 1}: ${t(
            'admin.msg.수임자와 위임자가 같을 수 없습니다.',
            '수임자와 위임자가 같을 수 없습니다.'
          )}`;
      })
      .filter((element) => element !== undefined);
    //Validation End

    if (valid.length) {
      openMessageBar({
        type: 'error',
        content:
          String(t('admin.msg.입력값을 확인해 주세요.', '입력값을 확인해 주세요.')) +
          valid.toString(),
      });
      return false;
    }
    return true;
  };

  const btnAddRow = useCallback(() => {
    if (userSession.roleCodes.some((e) => e == 'ADM')) {
      const newRow = {
        crudKey: CrudCode.CREATE,
        // aprDlgtStDt: dayjs('').format('YYYYMMDD'),
        // aprDlgtEndDt: dayjs('').format('YYYYMMDD'),
        useYn: CommonYN.Y,
      } as ApprovalDelegate;
      setRowData([newRow, ...rowData]);
    } else {
      const newRow = {
        crudKey: CrudCode.CREATE,
        // aprDlgtStDt: dayjs('').format('YYYYMMDD'),
        // aprDlgtEndDt: dayjs('').format('YYYYMMDD'),
        useYn: CommonYN.Y,
        aprDlgtUserId: userInfo.userId,
        aprDlgtUserDisplay: `${userInfo.empNm}(${userInfo.userId})`,
        dlgtUserDept: userInfo.deptNm,
      } as ApprovalDelegate;
      setRowData([newRow, ...rowData]);
    }
  }, [rowData, userInfo]);

  const btnDelRow = useCallback(() => {
    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if ((isSelected || []).length < 1) {
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.삭제할 항목을 1건 이상 선택해 주세요.',
          '삭제할 항목을 1건 이상 선택해 주세요.'
        ),
      });
      return;
    }

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode._idx!);
      })
      .reverse();

    selectedIds.forEach((idx) => {
      if (rowData[idx].crudKey === CrudCode.CREATE) {
        delete rowData[idx];
      } else {
        rowData[idx].crudKey = CrudCode.DELETE;
        rowData[idx].useYn = CommonYN.N;
      }
    });
    // const selectedIds = selectedRows
    //   .map((rowNode) => {
    //     return parseInt(rowNode.id!);
    //   })
    //   .reverse();

    // selectedIds.forEach((item) => {
    //   if (rowData[item].crudKey === CrudCode.CREATE) delete rowData[item];
    //   else {
    //     rowData[item].crudKey = CrudCode.DELETE;
    //     rowData[item].useYn = CommonYN.N;
    //   }
    // });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData, gridRef]);

  const btnSave = () => {
    if (fnValidate())
      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
        yesCallback: () => {
          const saveRows = rowData
            .map((rowNode) => {
              return rowNode.crudKey ? rowNode : null;
            })
            .filter((element) => element !== null) as ApprovalDelegate[];

          if (saveRows.length == 0)
            return openMessageBar({
              type: 'error',
              content: t('com.label.변경된 행이 없습니다.', '변경된 행이 없습니다.'),
            });
          saveApprovalDelegates(saveRows).then((result) => {
            if (result.successOrNot === 'N')
              return openMessageBar({
                type: 'error',
                content: t(
                  'com.label.저장 중 오류가 발생했습니다.',
                  '저장 중 오류가 발생했습니다.'
                ),
              });

            openMessageBar({
              type: 'confirm',
              content: t('com.label.저장되었습니다.', '저장되었습니다.'),
            });
            fnSearch(props.searchParam);
          });
        },
      });
  };

  useEffect(() => {
    if (userSession.roleCodes.some((e) => e == 'ADM')) fnSearch(props.searchParam);
    else
      fnSearch({
        DlgtUserId: userSession.userId,
        status: props.searchParam.status,
      });
  }, [props]);

  useEffect(() => {
    getEmployeeBySearchCondition(userSession.userId).then((result) => {
      setUserInfo(result[0]);
    });
  }, [userSession.userId]);

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'ApproveDelegate';
    book.saveAsync(getExcelFileName(t('com.label.결재위임목록', '결재위임목록')));
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.결재위임목록', '결재위임목록')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="addRow" onClick={btnAddRow} disableRipple>
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button css={IconButton.button} className="delRow" onClick={btnDelRow} disableRipple>
            {t('com.button.행삭제', '행삭제')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition()}
        rowData={rowData}
        align="center"
        initialized={onInitialized}
        beginningEdit={beginningEdit}
        height={500}
        loadedRows={(grid, e) => {
          grid.rows.forEach((r) => {
            if (r.dataItem.status === '종료') {
              r.isReadOnly = true;
            }
          });
        }}
      />
      <GlobalBtnGroup>
        <Button css={IconButton.button} className="save" disableRipple onClick={btnSave}>
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>
      <EmployeeModal
        open={EmployeeModalOpen}
        close={() => {
          setEmployeeModalOpen(false);
        }}
        save={(emp: Employee[]) => fnSelectUser(emp)}
        singleSelect={true}
      />
      {isOpenUserMultiReadModal && (
        <GatingContListPopUp
          open={isOpenUserMultiReadModal}
          close={() => setOpenUserMultiReadModal(false)}
          title={title}
          initParam={userIds}
        />
        // <UserModal
        //   open={isOpenUserMultiModal}
        //   close={() => setOpenUserMultiModal(false)}
        //   title={t('com.label.사용자 조회', '사용자 조회')}
        //   defaultUserId={recvUserIds}
        //   singleSelect={false}
        //   onCallback={(callbackData) => callbackUserData(callbackData)}
        // />
      )}
    </>
  );
};

export default ApprovalDelegateGrid;
