import {
  NotificationCondition,
  NotificationGroup,
  NotificationGroupDivision,
  NotificationGroupUser,
} from '../../models/admin/Notification';
import { CommonRequest, CommonResponse, Method, ServiceName } from '../../models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const getNotificationGroups = async (notificationCondition: NotificationCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/notification/groups`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ntdkNm: notificationCondition.ntdkNm ?? '' }),
  };
  const response: CommonResponse<NotificationGroup[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as NotificationGroup[];
};

export const getNotificationGroupDivisions = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/notification/groupDivisions`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<NotificationGroupDivision[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as NotificationGroupDivision[];
};

export const getNotificationGroupUsers = async (notificationCondition: NotificationCondition) => {
  console.log('notificationCondition', notificationCondition);
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/notification/groupUsers`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ntdkId: notificationCondition.ntdkId ?? '',
    }),
  };
  const response: CommonResponse<NotificationGroupUser[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as NotificationGroupUser[];
};

export const saveNotificationGroups = async (notificationGroups: NotificationGroup[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/notification/groups`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: notificationGroups,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const deleteNotificationGroup = async (notificationGroup: NotificationGroup) => {
  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/notification/groups`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: notificationGroup,
  };

  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};

export const saveNotificationGroupUsers = async (
  notificationGroupUsers: NotificationGroupUser[]
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/notification/groupUsers`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: notificationGroupUsers,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};
