/**
 * 점검 결과
 */
export enum InspectionResultCodes {
  OK = 'OK', // 합격
  C_OK = 'C-OK', // 조건부합격
  NG = 'NG', // 불합격
}

/**
 * 등급
 *
 * Critical 등급인 항목
 * - 불합격인 경우 다음 진행 단계 불가능
 * - 조건부합격인 경우 점검결과내용 필수 입력
 * 환산점수로 나온 점검결과(OK여부)를 사용자가 변경한 경우 점검결과내용 필수 입력
 * (다른 결과를 선택한 경우 사유 적도록 안내)
 */
export enum GatingCheckItemGradeCodes {
  CRITICAL = 'C', // Critical
  REQUIRED = 'R', // 필수
  OPTIONAL = 'O', // 선택
}

/**
 * 환산 점수 계산
 * @param evaluationScore   점수
 * @param prorateScore      배점
 * @param isRound           Math.round 처리 여부 (default: true)
 *                          - 그리드 내에서 환산점수 단순 노출할때는 round 처리
 *                          - 점검완료 결과코드 판단 식에서는 round 미처리한 상태로 사용
 */
export const convertPercentScore = (evaluationScore = 0, prorateScore = 0, isRound = true) => {
  const percent = ((evaluationScore * 1) / prorateScore) * 100;
  return isRound ? Math.round(percent) : percent;
};

/**
 * 점검 결과 판단 처리
 * @param evaluationScore 점수
 * @param prorateScore    배점
 * @param goodBasisScore  합격 기준 점수 (OK)
 * @param levelScore      조건부합격 기준 점수 (C-OK)
 * @return 'OK': 합격, 'C-OK': 조건부합격, 'NG': 불합격, '': 판단불가
 */
export const getInspectionResultCode = (
  evaluationScore,
  prorateScore = 0,
  goodBasisScore,
  levelScore
) => {
  const evalScore = parseFloat(evaluationScore);
  if (isNaN(evalScore)) return '';
  const goodScore = parseFloat(goodBasisScore);
  if (isNaN(goodScore)) return '';
  const lvScore = parseFloat(levelScore);
  if (isNaN(lvScore)) return '';

  const percentScore = convertPercentScore(evalScore, prorateScore, false);
  if (goodScore <= percentScore) {
    return InspectionResultCodes.OK;
  } else if (lvScore <= percentScore) {
    return InspectionResultCodes.C_OK;
  }
  return InspectionResultCodes.NG;
};
