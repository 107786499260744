/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import {
  TabButtonGroup,
  TabControlButton,
  ScrollingWrap,
  TabCloseButton01,
} from 'components/layouts/MDI';
import { MenuContextType, Menu, HomeMenu, MenuEnum } from 'models/admin/Menu';
import { MenuContext } from 'App';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface Props {
  currentTabIndex: number;
  onChange: (tabIndex: number) => void;
}
const StacksMenuBar = ({ currentTabIndex, onChange }: Props) => {
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const apiRef = useRef({} as scrollVisibilityApiType);

  useEffect(() => {
    scrollToItem(currentTabIndex);
  }, [currentTabIndex]);

  const handleDelete = (e: any, menu: Menu) => {
    e.preventDefault();
    e.stopPropagation();

    const stacksMenu = menuContext.stacksMenu.filter((o) => o.mnuId !== menu.mnuId);
    const tabIndex = Math.max(0, stacksMenu.length - 1);
    onChange(tabIndex);
    menuContext.handleMenuChange({
      ...menuContext,
      currentMenu: stacksMenu[tabIndex],
      stacksMenu: stacksMenu,
      tabIndex: tabIndex,
    });
    setTimeout(() => navigate(stacksMenu[tabIndex].mnuUrl || '/'), 100);
  };

  const handleTabClick = (tabIndex: number) => {
    handleMenuChange(tabIndex);
  };

  const handleReset = () => {
    const stacksMenu = [menuContext.stacksMenu[0]];
    const tabIndex = 0;
    onChange(tabIndex);
    menuContext.handleMenuChange({
      ...menuContext,
      currentMenu: stacksMenu[tabIndex],
      stacksMenu: stacksMenu,
      tabIndex: tabIndex,
    });
    setTimeout(() => navigate(stacksMenu[tabIndex].mnuUrl || '/'), 100);
  };

  const handleMoveLeft = () => {
    handleMenuChange(Math.max(0, currentTabIndex - 1));
  };

  const handleMoveRight = () => {
    handleMenuChange(Math.min(menuContext.stacksMenu.length - 1, currentTabIndex + 1));
  };

  const scrollToItem = (idx) => {
    const item = apiRef.current.getItemByIndex(idx);
    apiRef.current.scrollToItem(
      item
        ? item
        : apiRef.current.getItemByIndex(apiRef.current.items.toItemsWithoutSeparators().length - 1),
      'auto',
      item ? 'center' : 'start'
    );
  };

  const handleOpenWindow = () => {
    let mnuUrl = menuContext.currentMenu.mnuUrl;
    mnuUrl = (mnuUrl || '').startsWith('/') ? mnuUrl : `/${mnuUrl}`;
    window.open(`${window.location.origin}${mnuUrl}`);
  };

  const handleMenuChange = (tabIndex) => {
    onChange(tabIndex);
    navigate(menuContext.stacksMenu[tabIndex].mnuUrl || '/');
  };

  return (
    <>
      <ScrollingWrap>
        <ScrollMenu
          apiRef={apiRef}
          options={{
            ratio: 0.9,
            rootMargin: '5px',
            threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1],
          }}
        >
          {menuContext.stacksMenu.map((it: Menu, idx: number) => (
            <div
              className={`ButtonWrap ${idx === currentTabIndex ? 'tabOn' : 'tabOff'} ${
                it.mnuId === HomeMenu.mnuId ? 'home' : ''
              }`}
              key={it.mnuId || idx}
            >
              <Button role="button" onClick={() => handleTabClick(idx)}>
                <span>
                  {it.mnuId === HomeMenu.mnuId
                    ? t('com.menu.home', HomeMenu.mnuNm || MenuEnum.home)
                    : it.mnuNm}
                </span>
              </Button>
              {it.mnuId !== HomeMenu.mnuId && (
                <TabCloseButton01 onClick={(e) => handleDelete(e, it)} />
              )}
            </div>
          ))}
        </ScrollMenu>
        <TabButtonGroup>
          <Button css={TabControlButton.button} className="left" onClick={handleMoveLeft}></Button>
          <Button
            css={TabControlButton.button}
            className="right"
            onClick={handleMoveRight}
          ></Button>
          <Button
            css={TabControlButton.button}
            className="link"
            onClick={handleOpenWindow}
          ></Button>
          <Button css={TabControlButton.button} className="close" onClick={handleReset}></Button>
        </TabButtonGroup>
      </ScrollingWrap>
    </>
  );
};

export default React.memo(StacksMenuBar);
