import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from '../../models/common/RestApi';
import { callApi } from '../../utils/ApiUtil';
import { MyBookmarkDtl, MyBookmarkMst } from '../../models/admin/MyBookmark';
import { GatingCheckSheetVer } from '../../models/gtng/popup/GatingCheckSheetVer';

/**
 * 나의 관심항목 조회
 * @param searchBmkGrDesc
 */
export const getMyBookmarkMst = async (searchBmkGrDesc: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/MyBookmarkMst`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      searchBmkGrDesc: searchBmkGrDesc,
    }),
  };
  const response: CommonResponse<MyBookmarkMst[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as MyBookmarkMst[];
};

export const saveMyBookmarkMst = async (saveData: MyBookmarkMst[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveMyBookmarkMst`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  // return (response.successOrNot === 'Y' ? response.data : response.statusCode) as DmlResponse;
  return response;
};

export const getMyBookmarkDtl = async (searchBmkGrId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/MyBookmarkDtl`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      searchBmkGrId: searchBmkGrId,
    }),
  };
  const response: CommonResponse<MyBookmarkDtl[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as MyBookmarkDtl[];
};

export const saveMyBookmarkDtl = async (oldData: string, newData: string, bmkGrId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/saveMyBookmarkDtl`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      oldData: oldData,
      newData: newData,
      bmkGrId: bmkGrId,
    }),
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return response;
};

export const saveGatingCheckSheetVersionUp = async (saveData: GatingCheckSheetVer) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveGatingCheckSheetVersionUp`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return response;
};
