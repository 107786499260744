/** @jsxImportSource @emotion/react */
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { DataType } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { CrudCode } from 'models/common/Edit';
import { ParameterDetailRequest, ParameterMasterRequest } from 'models/ip/EquipmentParameter';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { commonYNcodes, ManagementMode } from 'models/common/Common';
import _ from 'lodash';
import { generateParameterId } from 'apis/ip/EquipmentParameter';
import ParameterPointManagementModal from 'components/modals/ip/ParameterPointManagementModal';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { useLoading } from 'components/process/Loading';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import useEvent from 'react-use-event-hook';
import { version } from 'os';

interface Props {
  masterRequest: ParameterMasterRequest;
  mode?: ManagementMode;
  aprReqProgStatCd?: string;
  onChange: (eqpParaDReqList: ParameterDetailRequest[]) => void;
  versionNoUp: string;
}

const ParameterDetailGrid = (
  { masterRequest, mode = ManagementMode.READ, aprReqProgStatCd, onChange, versionNoUp }: Props,
  ref
) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openLoading, isLoading } = useLoading();
  const gridRef = useRef<any>();
  const [rowData, setRowData] = useState<ParameterDetailRequest[]>([]);
  const startParaId = useRef<string>('');
  const [code, setCode] = useState<any>();
  const [isOpenParaPoint, setOpenParaPoint] = useState<boolean>(false);
  const [paraPointTarget, setParaPointTarget] = useState<ParameterDetailRequest>({});
  const editable = useMemo(() => mode !== ManagementMode.DELETE, [mode]);
  const readable = useMemo(() => mode !== ManagementMode.READ, [mode]);
  const isExcelMode = useMemo(
    () =>
      [ManagementMode.CREATE_EXCEL, ManagementMode.MODIFY_EXCEL].includes(mode) ||
      (rowData || []).filter(
        (o) =>
          o.reqType?.startsWith(ManagementMode.CREATE_EXCEL) ||
          o.reqType?.startsWith(ManagementMode.MODIFY_EXCEL)
      ).length > 0,
    [mode, rowData]
  );

  const isReadOnly =
    (mode === ManagementMode.CREATE && aprReqProgStatCd === 'TMP') ||
    (mode === ManagementMode.MODIFY && aprReqProgStatCd === 'TMP') ||
    (mode === ManagementMode.READ && aprReqProgStatCd === 'TMP');

  // // isReadOnly = false
  // mode === ManagementMode.CREATE && aprReqProgStatCd === 'TMP'
  // mode === ManagementMode.MODIFY && aprReqProgStatCd === 'TMP'
  // aprReqProgStatCd === 'TMP'

  // // isReadOny = true
  // mode === ManagementMode.READ
  // mode === ManagementMode.CREATE_EXCEL
  // mode === ManagementMode.MODIFY_EXCEL
  // mode === ManagementMode.DELETE
  // aprReqProgStatCd !== 'TMP'
  // mode === ManagementMode.MODIFY

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (!editable) {
        return true;
      }

      if (!(rowData || []).filter((o) => o.crudKey !== CrudCode.DELETE).length) {
        openMessageBar({
          type: 'error',
          content: t('ip.msg.IP List를 입력해 주세요.', 'IP List를 입력해 주세요.'),
        });
        return false;
      }

      const valid = (rowData || [])
        .filter((o) => o.crudKey !== CrudCode.DELETE)
        .map((o, index) => {
          if (_.isEmpty(o.machineName)) {
            return (
              `${index + 1} :` +
              t('ip.msg.Machine을 입력해 주세요.', `Machine을 입력해 주세요.`) +
              '\n'
            );
          }
          if (_.isEmpty(o.paraType)) {
            return (
              `${index + 1} :` +
              t('ip.msg.파라미터유형을 선택해 주세요.', `파라미터유형을 선택해 주세요.`) +
              '\n'
            );
          }
          if (_.isEmpty(o.ipCategory)) {
            return (
              `${index + 1} :` +
              t('ip.msg.IP구분을 선택해 주세요.', `IP구분을 선택해 주세요.`) +
              '\n'
            );
          }
          if (_.isEmpty(o.checkStep)) {
            return (
              `${index + 1} :` +
              t('ip.msg.점검단계를 선택해 주세요.', `점검단계를 선택해 주세요.`) +
              '\n'
            );
          }
          if (_.isEmpty(o.paraItem)) {
            return (
              `${index + 1} :` +
              t('ip.msg.파라미터항목을 선택해 주세요.', `파라미터항목을 선택해 주세요.`) +
              '\n'
            );
          }
          // 시스템 자동
          if (_.isEmpty(o.paraId)) {
            return (
              `${index + 1} :` +
              t('ip.msg.파라미터ID을 입력해 주세요.', `파라미터ID을 입력해 주세요.`) +
              '\n'
            );
          }
          if (_.isEmpty(o.paraName)) {
            return (
              `${index + 1} :` +
              t('ip.msg.파라미터명을 입력해 주세요.', `파라미터명을 입력해 주세요.`) +
              '\n'
            );
          }
          const paraPointCnt = (o.parameterPointList || []).filter(
            (o) => o.crudKey !== CrudCode.DELETE
          );
          if (o.paraPointType === 'POINT' && paraPointCnt.length < 1) {
            return (
              `${index + 1} :` +
              t('ip.msg.측정개소 정보를 입력해 주세요.', `측정개소 정보를 입력해 주세요.`) +
              '\n'
            );
          }
          // 측정개소 수는 자연수여야함
          if (
            !_.isNil(o.paraPointCnt) &&
            (o.paraPointCnt < 0 || !Number.isInteger(Number(o.paraPointCnt)))
          ) {
            return (
              `${index + 1} :` +
              t(
                'ip.msg.측정개소 수는 숫자(자연수)로 입력해 주세요.',
                `측정개소 수는 숫자(자연수)로 입력해 주세요.`
              ) +
              '\n'
            );
          }

          // 측정유형 - 정량인 경우
          if (o.meterType === 'METER') {
            if (!_.isEmpty(o.lowerBound) && !_.isEmpty(o.upperBound)) {
              const lowerBound = Number(o.lowerBound);
              const upperBound = Number(o.upperBound);
              if (_.isNaN(lowerBound) || _.isNaN(upperBound)) {
                return (
                  `${index + 1} :` +
                  t('ip.msg.하한/상한 값을 확인해 주세요.', `하한/상한 값을 확인해 주세요.`) +
                  '\n'
                );
              }
              if (lowerBound > upperBound) {
                return (
                  `${index + 1} :` +
                  t('ip.msg.하한/상한 값을 확인해 주세요.', `하한/상한 값을 확인해 주세요.`) +
                  '\n'
                );
              }
            }
          }
          // 측정유형 - 정성인 경우
          else if (o.meterType === 'OKNG') {
            if (!_.isNil(o.lowerBound) || !_.isNil(o.upperBound)) {
              return (
                `${index + 1} :` +
                t(
                  'ip.msg.측정유형이 OKNG(정성)인 경우 하한,상한은 값이 없어야 합니다.',
                  `측정유형이 OKNG(정성)인 경우 하한,상한은 값이 없어야 합니다.`
                ) +
                '\n'
              );
            }
          }
        })
        .filter((element) => element !== undefined);

      if (valid.length) {
        openMessageBar({ type: 'error', content: valid.toString() });
        return false;
      }
      return true;
    },
    getDataList: () => {
      return rowData;
    },
  }));

  useEffect(() => {
    if (masterRequest?.eqpParaNo && editable && !isExcelMode) {
      openLoading(true);
    }
    setRowData(masterRequest?.eqpParaDReqList || []);
    if (masterRequest.eqpParaNo && editable && !isExcelMode) {
      generateParameterId(masterRequest.eqpParaNo)
        .then((result) => {
          startParaId.current = result.paraId || '';
        })
        .finally(() => openLoading(false));
    }
    // console.log('masterRequest', masterRequest);
    // if (masterRequest.eqpParaNo && masterRequest.aprReqId && editable && !isExcelMode) {
    //   generateParameterIds(masterRequest.eqpParaNo, masterRequest.aprReqId)
    //     .then((result) => {
    //       console.log('masterRequest', masterRequest);
    //       startParaId.current = result.paraId || '';
    //     })
    //     .finally(() => openLoading(false));
    // }
  }, [masterRequest]);

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  const getCommonCodesForGrid = async () => {
    const ipCheckStep: Code[] = await getCommonCodeNames('IP_CHECK_STEP');
    const ipCategory: Code[] = await getCommonCodeNames('IP_CATEGORY');
    const paraType: Code[] = await getCommonCodeNames('PARA_TYPE');
    const paraItem: Code[] = await getCommonCodeNames('PARA_ITEM');
    const meterType: Code[] = await getCommonCodeNames('METER_TYPE');
    const ipUom: Code[] = await getCommonCodeNames('IP_UOM');
    const ipTool: Code[] = await getCommonCodeNames('IP_TOOL');
    const electrodeType: Code[] = await getCommonCodeNames('ELECTRODE_TYPE');
    const paraPointType: Code[] = await getCommonCodeNames('PARA_POINT_TYPE');
    const digitalType: Code[] = await getCommonCodeNames('DIGITAL_TYPE');
    const ctqctpType: Code[] = await getCommonCodeNames('CTQCTP_TYPE');
    const abnormalType: Code[] = await getCommonCodeNames('ABNORMAL_TYPE');
    const executionOwner: Code[] = await getCommonCodeNames('EXECUTION_OWNER');

    setCode({
      ipCheckStep: ipCheckStep,
      ipCategory: ipCategory,
      paraType: paraType,
      paraItem: (paraItem || []).sort((a, b) => {
        const ac = (a.cmnCdNm || '').toUpperCase(),
          bc = (b.cmnCdNm || '').toUpperCase();
        return ac.localeCompare(bc);
      }),
      meterType: meterType,
      ipUom: (ipUom || []).sort((a, b) => {
        const ac = (a.cmnCdNm || '').toUpperCase(),
          bc = (b.cmnCdNm || '').toUpperCase();
        return ac.localeCompare(bc);
      }),
      ipTool: (ipTool || []).sort((a, b) => {
        const ac = (a.cmnCdNm || '').toUpperCase(),
          bc = (b.cmnCdNm || '').toUpperCase();
        return ac.localeCompare(bc);
      }),
      electrodeType: electrodeType,
      paraPointType: paraPointType,
      digitalType: digitalType,
      ctqctpType: ctqctpType,
      abnormalType: abnormalType,
      executionOwner: executionOwner,
    });
  };

  const handleAddRow = () => {
    if (!masterRequest.eqpParaNo || !startParaId.current || !masterRequest.versionNo) {
      openMessageBar({
        type: 'error',
        content: t(
          'ip.msg.설비군/설비파라미터번호를 입력해 주세요.',
          '설비군/설비파라미터번호를 입력해 주세요.'
        ),
      });
      return;
    }

    const newRow = {
      crudKey: CrudCode.CREATE,
      eqpParaNo: masterRequest.eqpParaNo,
      versionNo: masterRequest.versionNo,
      paraType: 'IP', // [23.10.14] IP 고정
      paraPointType: 'ATTACH', // 기본값
      paraId: startParaId.current.replace(/\d+$/, (n) =>
        String(Number(n) + rowData.filter((o) => o.crudKey === CrudCode.CREATE).length).padStart(
          4,
          '0'
        )
      ),
    } as ParameterDetailRequest;
    const rows = [newRow, ...rowData];
    setRowData(rows);
    onChange(rows);
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current?.rows.filter((r) => r.isSelected);
    if (!selectedRows) return;

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
    onChange(filteredData);
  };

  const layoutDefinition = [
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      width: 45,
      align: 'center',
      isReadOnly: true,
      cellTemplate: GridStatusCellTemplate,
      visible: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP' ? false : true,
    },
    {
      header: String(t('ip.grid.설비', '설비')),
      align: 'center',
      columns: [
        {
          binding: 'electrodeType',
          header: String(t('ip.grid.Anode/Cathode', 'Anode/Cathode')),
          width: 120,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(code?.electrodeType || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'machineName',
          header: String(t('ip.grid.Machine', 'Machine')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 80,
        },
        {
          binding: 'unitName',
          header: String(t('ip.grid.Unit', 'Unit')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 150,
        },
        {
          binding: 'assemblyName',
          header: String(t('ip.grid.Assembly', 'Assembly')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 150,
        },
      ],
    },
    {
      binding: 'paraType',
      header: String(t('ip.grid.파라미터 유형', '파라미터 유형')),
      width: 160,
      cssClass: '', // editable ? 'WijmoSelect' : '', // [23.10.14] IP 고정
      isReadOnly: true, // [23.10.14] IP 고정
      dataMap: new DataMap(code?.paraType || [], 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'ipCategory',
      header: String(t('ip.grid.IP구분', 'IP구분')),
      width: 90,
      cssClass: editable || readable ? 'WijmoSelect' : '',
      // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
      // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
      isReadOnly: !isReadOnly,
      dataMap: new DataMap(code?.ipCategory || [], 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'checkStep',
      header: String(t('ip.grid.점검단계', '점검단계')),
      width: 100,
      cssClass: editable || readable ? 'WijmoSelect' : '',
      // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
      // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
      isReadOnly: !isReadOnly,
      dataMap: new DataMap(code?.ipCheckStep || [], 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'paraItem',
      header: String(t('ip.grid.파라미터 항목', '파라미터 항목')),
      width: 120,
      cssClass: editable || readable ? 'WijmoSelect' : '',
      // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
      // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
      isReadOnly: !isReadOnly,
      dataMap: new DataMap(code?.paraItem || [], 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'paraId',
      header: String(t('ip.grid.파라미터ID', '파라미터ID')),
      width: 150,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'paraName',
      header: String(t('ip.grid.파라미터명', '파라미터명')),
      // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
      // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
      isReadOnly: !isReadOnly,
      width: 200,
    },
    {
      binding: 'paraDesc',
      header: String(t('ip.grid.파라미터 설명', '파라미터 설명')),
      // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
      // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
      isReadOnly: !isReadOnly,
      width: 250,
    },
    {
      header: String(t('ip.grid.사양', '사양')),
      align: 'center',
      columns: [
        {
          binding: 'specDataum',
          header: String(t('ip.grid.측정기준', '측정기준')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          width: 130,
        },
        {
          binding: 'meterType',
          header: String(t('ip.grid.미터유형', '미터유형')),
          width: 80,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(code?.meterType || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'lowerBound',
          header: String(t('ip.grid.하한', '하한')),
          width: 70,
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataType: DataType.Number,
        },
        {
          binding: 'upperBound',
          header: String(t('ip.grid.상한', '상한')),
          width: 70,
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataType: DataType.Number,
        },
        {
          binding: 'uom',
          header: String(t('ip.grid.UOM', 'UOM')),
          width: 80,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(code?.ipUom || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'rmk',
          header: String(t('ip.grid.주석', '주석')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 100,
        },
      ],
    },
    {
      header: String(t('ip.grid.측정', '측정')),
      align: 'center',
      columns: [
        {
          binding: 'measureTool',
          header: String(t('ip.grid.도구', '도구')),
          width: 100,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(code?.ipTool || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'measureMethod',
          header: String(t('ip.grid.방법', '방법')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 500,
        },
        {
          binding: 'measureGuide',
          header: String(t('ip.grid.가이드', '가이드')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 100,
        },
        {
          binding: 'digitalType',
          header: String(t('ip.grid.A/D', 'A/D')),
          width: 100,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(code?.digitalType || [], 'cmnCd', 'cmnCdNm'),
        },
      ],
    },
    {
      header: String(t('ip.grid.측정개소', '측정개소')),
      align: 'center',
      columns: [
        {
          binding: 'paraPointType',
          header: String(t('com.label.유형', '유형')),
          width: 130,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(code?.paraPointType || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'paraPointCnt',
          header: String(t('ip.grid.개소 수', '개소 수')),
          width: 100,
          dataType: DataType.Number,
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          cssClass: 'WijmoPopup',
          // cellTemplate: (params) => `
          //   ${(params.item.paraPointCnt || 0).toLocaleString()}
          //   ${params.item.paraPointType === 'POINT' ? '<Button />' : ''}
          // `,
          cellTemplate: (params) => `
            ${
              // 개소관리인 경우 측정개소 등록/수정 화면에서 데이터 관리
              params.item.paraPointType === 'POINT'
                ? `${(params.item.parameterPointList || [])
                    .filter((o) => o.crudKey !== CrudCode.DELETE)
                    .length.toLocaleString()} <Button />`
                : !_.isNil(params.value) && !_.isNaN(Number(params.value))
                ? params.value
                : ''
            }
          `,
        },
      ],
    },
    {
      header: String(t('ip.grid.CTQ/CTP', 'CTQ/CTP')),
      align: 'center',
      columns: [
        {
          binding: 'ctqctpType',
          header: String(t('com.label.유형', '유형')),
          width: 100,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(code?.ctqctpType || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'ctqctpDetail',
          header: String(t('com.label.상세', '상세')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 100,
        },
      ],
    },
    {
      binding: 'mainIpFlag',
      header: String(t('ip.grid.핵심IP여부', '핵심IP여부')),
      width: 90,
      cssClass: editable || readable ? 'WijmoSelect' : '',
      // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
      // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
      isReadOnly: !isReadOnly,
      dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
    },
    {
      header: String(t('ip.grid.이상발생', '이상발생')),
      align: 'center',
      columns: [
        {
          binding: 'abnormalType',
          header: String(t('com.label.유형', '유형')),
          width: 90,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(code?.abnormalType || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'abnormalCause',
          header: String(t('ip.grid.원인', '원인')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 90,
        },
        {
          binding: 'abnormalDetectFlag',
          header: String(t('ip.grid.감지여부', '감지여부')),
          width: 90,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'abnormalDetectMethod',
          header: String(t('ip.grid.감지방법', '감지방법')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 90,
        },
        {
          binding: 'abnormalDetectMntFlag',
          header: String(t('ip.grid.모니터링여부', '모니터링여부')),
          width: 120,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
        },
      ],
    },
    {
      header: String(t('ip.grid.디지털화', '디지털화')),
      align: 'center',
      columns: [
        {
          binding: 'digitalNeedFlag',
          header: String(t('ip.grid.필요여부', '필요여부')),
          width: 150,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'digitalImprovement',
          header: String(t('ip.grid.개선방안', '개선방안')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 80,
        },
        {
          binding: 'digitalOwner',
          header: String(t('ip.grid.주체', '주체')),
          width: 150,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(code?.executionOwner || [], 'cmnCd', 'cmnCdNm'),
        },
        {
          binding: 'digitalPlan',
          header: String(t('ip.grid.계획', '계획')),
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: !readable && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          width: 80,
        },
        {
          binding: 'digitalCompleteFlag',
          header: String(t('ip.grid.완료여부', '완료여부')),
          width: 80,
          cssClass: editable || readable ? 'WijmoSelect' : '',
          // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
          // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
          isReadOnly: !isReadOnly,
          dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
        },
      ],
    },
    {
      binding: 'reqSeqId',
      visible: false,
    },
    {
      binding: 'aprReqId',
      visible: false,
    },
    {
      binding: 'eqpParaNo',
      visible: false,
    },
    {
      binding: 'versionNo',
      visible: false,
    },
    {
      binding: 'parameterPointList',
      visible: false,
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;

    grid?.hostElement.addEventListener('click', (e) => {
      grid.startEditing(true);
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const binding = grid.columns[ht.col].binding;
        const item = grid.rows[ht.row].dataItem;
        // 측정 개소 수 클릭 이벤트
        // 개소관리인 경우 측정개소 등록/수정 화면에서 데이터 관리
        if ('paraPointCnt' === binding && item.paraPointType === 'POINT') {
          setParaPointTarget(item);
          setOpenParaPoint(true);
        }
      }
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    const column = panel.columns[col];
    const binding = column.binding || '';
    if (rowData.length > 0 && mode === ManagementMode.MODIFY && versionNoUp !== undefined) {
      panel.rows.forEach((r) => {
        if (r.dataItem !== null) {
          r.dataItem.versionNo = (Number(versionNoUp) + 0.1).toFixed(1).toString();
        }
      });
    }
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 상한, 하한은 정량인 경우에만 입력 가능 (readonly css 적용)
      if ('lowerBound' === binding || 'upperBound' === binding) {
        if (!editable || 'METER' !== item.meterType) {
          cell.ariaReadOnly = true;
        }
      }
    }
  });

  const onBeginningEdit = (grid, e) => {
    const binding = grid.columns[e.col].binding;
    const item = grid.rows[e.row].dataItem;
    // 개소관리인 경우 측정개소 등록/수정 화면에서 데이터 관리
    if ('paraPointCnt' === binding) {
      if (!editable || item.paraPointType === 'POINT') {
        e.cancel = true;
      }
    }
    // 상한, 하한은 정량인 경우에만 입력 가능
    if ('lowerBound' === binding || 'upperBound' === binding) {
      if (!editable || item.meterType !== 'METER') {
        e.cancel = true;
      }
    }
  };

  const onCellEditEnded = (grid, e) => {
    const binding = grid.columns[e.col].binding;
    // 미터유형이 변경된 경우
    if ('meterType' === binding) {
      const data = grid.rows[e.row].dataItem;
      // 정성인 경우 상한, 하한 값 변경
      if ('OKNG' === data.meterType) {
        data.lowerBound = null;
        data.upperBound = null;
        grid.refreshCells();
      }
    }
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.IP List', 'IP List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>
              {(rowData || []).filter((o) => o.crudKey !== CrudCode.DELETE).length.toLocaleString()}
            </span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        {/* CREATE, MODIFY, READ , TMP 버튼 노출 */}
        {mode === ManagementMode.CREATE ||
        mode === ManagementMode.MODIFY ||
        aprReqProgStatCd === 'TMP' ? (
          <ControlBtnGroup>
            <Button
              css={IconButton.button}
              className="plus"
              onClick={handleAddRow}
              disabled={isLoading}
              disableRipple
            >
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button
              css={IconButton.button}
              className="minus"
              onClick={handleDelRow}
              disabled={isLoading}
              disableRipple
            >
              {t('com.button.행삭제', '행삭제')}
            </Button>
          </ControlBtnGroup>
        ) : (
          ''
        )}
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={300}
        beginningEdit={onBeginningEdit}
        cellEditEnded={onCellEditEnded}
        allowPinning={true}
        itemFormatter={onItemFormatter}
        initialized={onInitialized}
        // itemFormatter={(panel, row, col, cell) => {
        //   if (CellType.Cell === panel.cellType) {
        //     const binding = panel.columns[col].binding;
        //     const item = panel.rows[row].dataItem;
        //     // 상한, 하한은 정량인 경우에만 입력 가능 (readonly css 적용)
        //     if ('lowerBound' === binding || 'upperBound' === binding) {
        //       if (!editable || 'METER' !== item.meterType) {
        //         cell.ariaReadOnly = true;
        //       }
        //     }
        //   }
        // }}
      />
      {isOpenParaPoint && (
        <ParameterPointManagementModal
          open={isOpenParaPoint}
          close={() => setOpenParaPoint(false)}
          mode={mode}
          code={code}
          aprReqProgStatCd={aprReqProgStatCd}
          versionNoUp={versionNoUp}
          detailRequest={{ ...paraPointTarget, equipmentGroup: masterRequest?.equipmentGroup }}
          onCallback={(result) => {
            const idx = rowData.findIndex((value) => value.paraId === paraPointTarget.paraId);
            const selectedRow = gridRef.current?.rows[idx]?.dataItem;
            if (selectedRow) {
              selectedRow.parameterPointList = result;
            }
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(ParameterDetailGrid);
