/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { CustomInputWrap, CustomInputText, AllInputWrap } from 'components/inputs/CustomInput';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import { Button } from '@mui/material';

// 프로퍼티 타입 지정
TableCellInputText.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  colSpan: PropTypes.number,
  disabled: PropTypes.oneOfType([() => null, PropTypes.any]).isRequired,
  msgError: PropTypes.string,
  required: PropTypes.bool,

  onChange: PropTypes.any,
  findOnClick: PropTypes.any,
};

TableCellInputText.defaultProps = {
  disabled: false,
};

export default function TableCellInputText(props) {
  const { t } = useTranslation();
  const [current, setCurrent] = useState<any>();
  const [isReadOnly, setReadOnly] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const labelId = 'com.label.' + props.name;
  const labelText = '' + props.name;

  const userIdRegex = /^[A-Za-z0-9+]{5,}$/;
  useEffect(() => {
    setCurrent(props?.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    setReadOnly(props.className == 'find' ? true : false);
  }, [props.className]);

  const handleOnChange = (e: any) => {
    let bools = false;
    if (props.required && (!e.target.value || userIdRegex.test(e.target.value))) bools = true;

    setShowError(bools);

    setCurrent(e.target.value);
    const result = {
      id: props.id,
      value: e.target.value,
      validation: bools,
    };
    props.onChange(result);
  };

  const delBtnOnClick = (e: any) => {
    if (isReadOnly) return;
    setCurrent('');
  };

  return (
    <>
      <TableCell>
        <span className={props.required ? 'dot' : ''}>{t(labelId, labelText)}</span>
      </TableCell>
      <TableCell colSpan={props.colSpan}>
        <AllInputWrap className={showError ? 'error' : ''}>
          <CustomInputWrap>
            <CustomInputText
              className={props.className}
              placeholder={props.placeholder}
              value={current}
              onChange={handleOnChange}
              disabled={props.disabled}
              readOnly={isReadOnly}
            ></CustomInputText>
            {/* {current && (
              <Button className="buttonDel" disableRipple onClick={delBtnOnClick}></Button>
            )}*/}
            {props.className == 'find' && (
              <Button
                className="buttonFind"
                disableRipple
                id={props.id}
                onClick={props.findOnClick}
              ></Button>
            )}
          </CustomInputWrap>
          {showError && <span>{props.msgError}</span>}
        </AllInputWrap>
      </TableCell>
    </>
  );
}
