/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { useLoading } from 'components/process/Loading';
import { getUserInfo } from 'apis/common/Employee';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { findApproveRequestDetail, getMasterId, saveApproveRequest } from 'apis/approves/approves';
import { RequestDetail, SaveApprover, adapterApproveToEmp } from 'models/approves/ReferenceOpinion';
import ApproveBasicInfo from './ApproveBasicInfo';
import ApprovalLineInfo from './ApproveLineInfo';
import ApproveRequestDetailCase, { ApproveRequestPageType } from './ApproveRequestDetailCase';
import { GlobalBtnGroup } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';
import { convertJson } from 'utils/FormatUtil';

interface LocationState {
  upprMnuUrl?: string;
}
const ApproveRequestPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openLoading } = useLoading();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const basicRef = useRef<any>();
  const childRef = useRef<any>();
  const [detail, setDetail] = useState<RequestDetail>({
    aprPvnDdlnCd: 'APPRCODE_5Y', // 결재보존기한코드
    apprSecurityType: '0', // 문서종류 (비밀문서:1,일반문서:0)
    apprLineType: '0', // 협의유형 (0:순차협의, 1:병렬협의)
  });
  const [approvals, setApprovals] = useState<SaveApprover[]>([]);
  const [notifiers, setNotifiers] = useState<SaveApprover[]>([]);
  const [searchParams] = useSearchParams();
  const [aprReqId, setAprReqId] = useState<string>(searchParams.get('aprReqId') || '');

  const init = () => {
    if (aprReqId) {
      searchRequest();
    } else {
      newRequest();
    }
  };

  useEffect(() => {
    openLoading(true);
    init();
    openLoading(false);
  }, []);

  const newRequest = async () => {
    const result = await getMasterId();
    if (!result.data) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.결재ID 생성에 실패했습니다.', '결재ID 생성에 실패했습니다.'),
      });
      menuContext.closeCurrentTab(menuContext);
      return;
    }

    const userInfo = await getUserInfo();
    setAprReqId(result.data);
    setDetail({
      ...detail,
      aprReqId: result.data ?? '',
      aprReqUserNm: userInfo,
      dataInsDtm: dayjs().format('YYYY.MM.DD').toString(),
    });
  };

  const searchRequest = async () => {
    const response = await findApproveRequestDetail(aprReqId);
    if (!response) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.결재 정보가 없습니다.', '결재 정보가 없습니다.'),
      });
      menuContext.closeCurrentTab(menuContext);
      return;
    }

    const approvalAppd = adapterApproveToEmp(response.approvalAppd); // 결재자
    const approvalInfr = adapterApproveToEmp(response.approvalInfr); // 통보자
    setDetail({
      ...response,
      approvalAppd: approvalAppd,
      approvalInfr: approvalInfr,
    });

    setApprovals(approvalAppd);
    setNotifiers(approvalInfr);
  };

  const validation = async () => {
    if (!(await basicRef.current?.validate())) return false;
    if (!(await childRef.current?.validate())) return false;

    if (!detail.approvalAppd?.length) {
      openMessageBar({
        type: 'error',
        content: t('com.msg.승인자 정보를 추가해 주세요.', '승인자 정보를 추가해 주세요.'),
      });
      return false;
    }
    return true;
  };

  const navigateToListPage = () => {
    menuContext.closeCurrentTab(menuContext);
    navigate(searchParams.get('rtnUrl') || '/approves/approve-management-page', { replace: true });
  };

  /**
   * 결재 요청 처리
   */
  const handleConfirm = async () => {
    if (!(await validation())) return;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.msg.결재요청하시겠습니까?', '결재요청하시겠습니까?'),
      yesCallback: async () => {
        openLoading(true);
        const response = await saveApproveRequest({
          ...detail,
          approvalAppd: approvals,
          approvalInfr: notifiers,
          aprReqProgStatCd: 'REQ',
          // optValCtn1: detail?.optValCtn1, // // 결재요청 호출페이지 유형
          optValCtn2: JSON.stringify(convertJson(detail?.optValCtn2)),
          bizReqData: childRef.current?.getBizReqData(detail.aprReqId, detail.trnmBdyCtn),
        });
        openLoading(false);
        if (response.successOrNot === 'Y') {
          openMessageBar({
            type: 'confirm',
            content: t('com.msg.결재요청되었습니다.', '결재요청되었습니다.'),
          });
          navigateToListPage();
          return;
        }
        openMessageBar({
          type: 'error',
          content:
            response.data || t('com.msg.결재 요청에 실패했습니다.', '결재 요청에 실패했습니다.'),
        });
      },
    });
  };

  /**
   * 임시 저장 처리
   */
  const handleSave = async () => {
    if (!(await validation())) return;

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        openLoading(true);
        const response = await saveApproveRequest({
          ...detail,
          approvalAppd: approvals,
          approvalInfr: notifiers,
          // optValCtn1: detail?.optValCtn1, // // 결재요청 호출페이지 유형
          optValCtn2: JSON.stringify(convertJson(detail?.optValCtn2)),
          bizReqData: childRef.current?.getBizReqData(detail.aprReqId, detail.trnmBdyCtn),
        });
        openLoading(false);
        if (response.successOrNot === 'Y') {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          setDetail({
            ...detail,
            aprReqProgStatNm: '임시저장',
          });
          return;
        }
        openMessageBar({
          type: 'error',
          content: response.data || t('com.msg.저장 실패했습니다.', '저장 실패했습니다.'),
        });
      },
    });
  };

  const handleChange = (name: string, value: string) => {
    if (name) {
      setDetail({ ...detail, [name]: value });
    }
  };

  const drawButtons = () => {
    return (
      <>
        <Button css={IconButton.button} className="list" onClick={navigateToListPage}>
          {t('com.button.목록', '목록')}
        </Button>
        <Button css={IconButton.button} className="draft" onClick={handleSave}>
          {t('com.button.임시저장', '임시저장')}
        </Button>
        <Button css={IconButton.button} className="confirm" onClick={handleConfirm}>
          {t('com.button.결재요청', '결재요청')}
        </Button>
      </>
    );
  };
  return (
    <ContainerLayout>
      <GlobalBtnGroup className="top">{drawButtons()}</GlobalBtnGroup>

      <ApproveBasicInfo ref={basicRef} detail={detail} onChange={handleChange} />

      {detail?.optValCtn1 && (
        <ApproveRequestDetailCase
          ref={childRef}
          pageId={detail.optValCtn1}
          condition={detail.condition}
          aprReqId={aprReqId}
        />
      )}

      <ApprovalLineInfo
        approvalAppd={detail?.approvalAppd ?? []}
        approvalInfr={detail?.approvalInfr ?? []}
        onApprovalsChange={(list) => setApprovals(list)}
        onNotifiersChange={(list) => setNotifiers(list)}
      />

      <GlobalBtnGroup>{drawButtons()}</GlobalBtnGroup>
    </ContainerLayout>
  );
};

export default ApproveRequestPage;
