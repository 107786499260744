/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { tb } from 'components/layouts/Table';
import { IconButton } from '../../../components/buttons/IconSVG';
import DialogContent from '@mui/material/DialogContent';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from '../../../components/layouts/Dialog';
import { useTranslation } from 'react-i18next';
import { SearchButtonWrap } from '../../../components/layouts/SearchBox';
import { CloseBtn } from '../../../components/buttons/CustomButton';
import { CustomInputText, CustomInputWrap, st } from 'components/inputs/CustomInput';
import { useCommonModal } from 'hooks/useCommonModal';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'hooks/useMessageBar';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { hasRole } from 'utils/SessionUtil';
import { saveExcpPartner, compNmCheck, rejectExcpStat, getExcpList } from 'apis/admin/ExcpPartner';
import { ManagementMode } from 'models/common/Common';
import { ExcpPartnerCondition } from 'models/admin/ExcpPartner';

interface SearchParam {
  userId?: string;
  empNm?: string;
  deptNm?: string;
  useYn?: string;
  isEditableFlag?: boolean;
}

type Props = {
  open: boolean;
  excpId: string;
  close: () => void;
  //singleSelect?: boolean;
};

export const ExcpManagementReadPopUp = (props: Props) => {
  const { t } = useTranslation();
  const [forDelete, setForDelete] = useState(false);
  const [popCondition, setPopCondition] = useState<any>({
    excpId: props.excpId,
    excpNm: '',
    rpsnNm: '',
    rpsnEml: '',
    rpsnPhn: '',
    snsrYn: '',
    ipYn: '',
  });
  const cmpNmRef = useRef<any>();
  const rpsnNmRef = useRef<any>();
  const rpsnEmlRef = useRef<any>();
  const rpsnPhnRef = useRef<any>();
  const rmkRef = useRef<any>();
  const snsrYnRef = useRef<any>();
  const ipYnRef = useRef<any>();

  const { openMessageBar } = useMessageBar();

  useEffect(() => {
    if (props.excpId) {
      init();
    }
  }, []);

  const init = () => {
    getExcpList(popCondition).then((result: any) => {
      console.log('result', result);
      if (result !== null && result !== undefined) {
        setPopCondition({
          excpId: result[0].excpId,
          excpNm: result[0].excpNm,
          rpsnNm: result[0].rpsnNm,
          rpsnEml: result[0].rpsnEml,
          rpsnPhn: result[0].rpsnPhn,
          snsrYn: result[0].snsrYn,
          ipYn: result[0].ipYn,
        });
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
        return false;
      }
    });
  };

  return (
    <>
      <Dialog open={true} css={di.dialog} fullWidth className="md" maxWidth={false}>
        <TitleWrap>
          <h2>{t('com.label.협력업체정보', '협력업체정보')}</h2>
          <CloseBtn
            onClick={() => {
              props.close();
            }}
          ></CloseBtn>
        </TitleWrap>
        <DialogContent>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('com.label.기본정보', '기본정보')}</h3>
            </SubTitleGroup>
          </SubTitleLayout>
          <TableContainer css={tb.table}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ verticalAlign: 'middle' }}>
                    <span className="dot">{t('com.label.업체명', '업체명')}</span>
                  </TableCell>
                  <TableCell>
                    <CustomInputWrap>
                      <span className="dot">{popCondition?.excpNm}</span>
                      {/* <CustomInputText
                        id="excpNm"
                        defaultValue={popCondition?.excpNm}
                        ref={cmpNmRef}
                        disabled={true}
                      ></CustomInputText> */}
                    </CustomInputWrap>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className="dot">{t('com.label.대표자명', '대표자명')}</span>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <CustomInputWrap>
                      <span className="dot">{popCondition?.rpsnNm}</span>
                      {/* <CustomInputText
                        id="rpsnNm"
                        ref={rpsnNmRef}
                        disabled={true}
                        defaultValue={popCondition?.rpsnNm}
                      ></CustomInputText> */}
                    </CustomInputWrap>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className="dot">{t('com.label.이메일주소', '이메일주소')}</span>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <CustomInputWrap>
                      <span className="dot">{popCondition?.rpsnEml}</span>
                      {/* <CustomInputText
                        id="rpsnEml"
                        ref={rpsnEmlRef}
                        disabled={true}
                        defaultValue={popCondition?.rpsnEml}
                      ></CustomInputText> */}
                    </CustomInputWrap>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('com.label.대표전화번호', '대표전화번호')}</TableCell>
                  <TableCell colSpan={2}>
                    <CustomInputWrap>
                      <span className="dot">{popCondition?.rpsnPhn}</span>
                      {/* <CustomInputText
                        id="rpsnPhn"
                        ref={rpsnPhnRef}
                        disabled={true}
                        defaultValue={popCondition?.rpsnPhn}
                      ></CustomInputText> */}
                    </CustomInputWrap>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        {!forDelete && (
          <DialogContent style={{ marginTop: '10px' }}>
            <SubTitleLayout>
              <SubTitleGroup>
                <h3>{t('com.label.업무 권한', '업무 권한')}</h3>
              </SubTitleGroup>
            </SubTitleLayout>
            <TableContainer>
              <Table css={tb.table}>
                <TableBody>
                  <TableRow>
                    <TableCell>{t('com.label.업체구분', '업체구분')}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <Checkbox
                        id="snsrYn"
                        name="snsrYn"
                        css={st.checkbox}
                        // disabled={true}
                        disableRipple
                        checked={popCondition.snsrYn == 'Y' && true}
                      />
                      <span>{' ' + t('com.label.센서', '센서') + ' '}</span>
                      <Checkbox
                        id="ipYn"
                        name="ipYn"
                        css={st.checkbox}
                        // disabled={true}
                        disableRipple
                        checked={popCondition.ipYn == 'Y' && true}
                      />
                      <span>{' ' + t('com.label.설비업체', '설비업체')}</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        )}
        <PopupBtnGroup></PopupBtnGroup>
      </Dialog>
    </>
  );
};
