/** @jsxImportSource @emotion/react */
import dayjs from 'dayjs';
import { Employee } from 'models/admin/Employee';
import { FileInfo } from 'models/admin/FileInfo';
import { PaginationRequest } from 'models/common/Pagination';
import { useState } from 'react';

export interface AprBtnParams {
  P0?: boolean;
  P1?: boolean;
  P6?: boolean;
  P7?: boolean;
  P8?: boolean;
  PC?: boolean;
}

export interface SaveApprover extends Employee {
  status: '0' | '1' | '6' | '7' | '8' | '9' | 'C';
  deletable?: boolean;
  aprAprvUserId: string;
}

export interface ApproverManagementProps {
  open: boolean;
  approverData: SaveApprover[];
  aprBtnPram: AprBtnParams;
  title: string;
}

export interface ApprovalAppd {
  aprReqId?: string;
  aprLnSeq?: string;
  aprLnSnb?: string;
  grnrAprTpDivsCd: '0' | '1' | '6' | '7' | '8' | '9' | 'C';
  aprFeilTpCd?: string;
  prlYn?: string;
  aprAprvUserId: string;
  aprPsgStatCd?: string;
  aprAprvDtm?: string;
  aprAprvOpinUtf8Ctn?: string;
  aprDlgtUserId?: string;
  aprLnDivsCd?: string;
  emlSndoSeq: string;

  aprvUserNm?: string;
  aprvUserJikwiNm?: string;
  aprvUserdeptNm?: string;
  grnrAprTpDivsNm?: string;
  aprPsgStatNm?: string;

  deletable?: boolean;
}

export const clearApprovalAppd: ApprovalAppd = {
  aprReqId: '',
  aprLnSeq: '',
  aprLnSnb: '',
  grnrAprTpDivsCd: '0',
  aprFeilTpCd: '',
  prlYn: '',
  aprAprvUserId: '',
  aprPsgStatCd: '',
  aprAprvDtm: '',
  aprAprvOpinUtf8Ctn: '',
  aprDlgtUserId: '',
  aprLnDivsCd: '',
  emlSndoSeq: '',

  aprvUserNm: '',
  aprvUserJikwiNm: '',
  aprvUserdeptNm: '',
  grnrAprTpDivsNm: '',
  aprPsgStatNm: '',

  deletable: true,
};

export interface ApprovalInfr {
  aprReqId?: string;
  aprLnSnb?: string;
  grnrAprTpDivsCd: 'C';
  grnrAprTpDivsNm?: string;
  notfUserId: string;
  notfUserNm?: string;
  notfUserJikwiNm?: string;
  notfUserdeptNm?: string;
  deletable?: boolean;
}

export interface ApprovalBasic {
  dataInsUserId: string;
  dataInsDtm?: string;
  aprPvnDdlnCd?: string;
  aprReqTitNm: string;
}

export interface RequestDetail {
  dataInsDtm?: string;
  aprPvnDdlnCd?: string;
  aprReqTitNm?: string;
  trnmBdyCtn?: string;
  apprSecurityType?: string;
  apprLineType?: string;
  nctrMgtId?: string;
  aprReqDtm?: string;
  docTitNm?: string;
  nctrDlwhNm?: string;
  nctrUsegCtn?: string;
  aprReqDtlCtn?: string;

  atchFileGrId?: string;
  aprReqId?: string;
  aprTplNm?: string;
  aprReqProgStatCd?: string;
  aprReqProgStatNm?: string;
  atchFiles?: FileInfo[];
  approvalAppd?: SaveApprover[];
  approvalInfr?: SaveApprover[];

  optValCtn1?: string;
  optValCtn2?: string;
  optValCtn3?: string;
  optValCtn4?: string;
  optValCtn5?: string;

  aprReqUserId?: string;
  aprReqUserNm?: string;
  bizReqData?: any; // 업무별 결재 테이블에 저장할 데이터
  condition?: any; // 업무별 결재 테이블에 사용되는 키 정보

  aprPageId?: string;
}

export interface SearchCondition extends PaginationRequest {
  progStat?: string;
  progStatDetail?: string;
  fromDate?: string;
  toDate?: string;
  nctrMgtId?: string;
  insUserNm?: string;
  pageNo?: string;

  aprReqId?: string;
  dataInsUserId?: string;
  aprReqProgStatCd?: string;
  aprReqTitNm?: string;
  aprTplId?: string;
}

export const clearSearchCondition: SearchCondition = {
  aprReqId: '',
  aprReqTitNm: '',
  aprReqProgStatCd: '',
  dataInsUserId: '',
};

//=====================================================================================
export interface Anchor {
  dataInsUserId: string;
  dataInsDtm: string;
  dataInsUserIp: string;

  dataUpdUserId: string;
  dataUpdDtm: string;
  dataUpdUserIp: string;

  dataInsUserNm: string;
  dataUpdUserNm: string;
}

export interface APR_REQ_M extends Anchor {
  aprReqId: string;
  aprTplId: string;
  aprTplNm: string;
  eaprDocNo: string;
  aprReqUserId: string;
  aprPvnDdlnCd: string;
  aprPvnDdlnNm: string;
  deptCd: string;
  deptNm: string;
  onduRegnCd: string;
  onduRegnNm: string;
  aprReqTitNm: string;
  trnmBdyCtn: any;
  aprReqDesc: string;
  aprReqDtm: string;
  wcstCncYn: string;
  aprReqProgStatCd: string;
  aprReqProgStatNm: string;
  wktScrnUrl: string;
  aprWaitScrnUrl: string;
  mbilWktScrnUrl: string;
  aprFileGrId: string;
  refAprReqId: string;
  optValCtn1: string;
  optValCtn2: string;
  optValCtn3: string;
  optValCtn4: string;
  optValCtn5: string;

  apprSecurityType: string;
  apprLineType: string;
  aprReqUserNm: string;
}

export interface ApproveRequestDetail extends APR_REQ_M {
  atchFiles?: FileInfo[];
  approvalAppd?: ApprovalAppd[];
  approvalInfr?: ApprovalAppd[];

  condition?: any; // 업무별 결재 테이블에 사용되는 키 정보

  //결재 결과 전송시 사용필드
  resultApprovalOpinion?: string; // 결재 의견
  resultAprLnSnb?: string; // 현재 결재 순번
}

export const clearApproveRequestDetail: ApproveRequestDetail = {
  aprReqId: '',
  aprTplId: '',
  aprTplNm: '',
  eaprDocNo: '',
  aprReqUserId: '',
  aprPvnDdlnCd: 'APPRCODE_5Y',
  aprPvnDdlnNm: '',
  deptCd: '',
  deptNm: '',
  onduRegnCd: '',
  onduRegnNm: '',
  aprReqTitNm: '결재를 요청합니다.',
  trnmBdyCtn: '',
  aprReqDesc: '',
  aprReqDtm: '',
  wcstCncYn: '',
  aprReqProgStatCd: '',
  aprReqProgStatNm: '',
  wktScrnUrl: '',
  aprWaitScrnUrl: '',
  mbilWktScrnUrl: '',
  aprFileGrId: '',
  refAprReqId: '',
  optValCtn1: '',
  optValCtn2: '',
  optValCtn3: '',
  optValCtn4: '',
  optValCtn5: '',
  approvalAppd: [],
  approvalInfr: [],

  apprSecurityType: '0',
  apprLineType: '0',
  aprReqUserNm: '',

  dataInsUserId: '',
  dataInsDtm: dayjs().format('YYYY-MM-DD').toString(),
  dataInsUserIp: '',
  dataUpdUserId: '',
  dataUpdDtm: dayjs().format('YYYY-MM-DD').toString(),
  dataUpdUserIp: '',

  dataInsUserNm: '',
  dataUpdUserNm: '',
};

export const adapterApproveToEmp = (approve: ApprovalAppd[]) => {
  return approve.map((row) => {
    return {
      ...row,
      status: row.grnrAprTpDivsCd,
      userId: row.aprAprvUserId,
      empNm: row.aprvUserNm,
      jtiNm: row.aprvUserJikwiNm,
      deptNm: row.aprvUserdeptNm,
    } as Employee;
  }) as SaveApprover[];
};
