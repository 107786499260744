export enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum ServiceName {
  ELM_BE = 'elm-be',
}

export interface CommonRequest {
  url: string;
  method: Method;
  serviceName: ServiceName;
  queryParams?: URLSearchParams;
  bodyParams?: object;
  headers?: object;
  responseType?: XMLHttpRequestResponseType;
  timeout?: number;
}
export interface jsonData {
  data?: any;
}

export interface CommonResponse<T = any> {
  successOrNot: SuccessOrNot;
  statusCode: string;
  data?: T;
  headers?: any;
}

export interface DmlResponse {
  insertedRows?: number;
  updatedRows?: number;
  deletedRows?: number;
}

export enum SuccessOrNot {
  Y = 'Y',
  N = 'N',
}

export enum StatusCode {
  SUCCESS = 'SUCCESS',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  SESSION_EXPIRE = 'SESSION_EXPIRE',
  MANDATORY_PARAM_ERROR = 'MANDATORY_PARAM_ERROR',
  BAD_REQUEST_ERROR = 'BAD_REQUEST_ERROR',
  TIMEOUT = 'TIMEOUT',
  NOT_AUTHORIZED_EXCEPTION = 'NOT_AUTHORIZED_EXCEPTION',
}

export interface DmlResponse {
  insertedRows?: number;
  updatedRows?: number;
  deletedRows?: number;
}
