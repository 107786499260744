import { DefaultTFuncReturn } from 'i18next';

export type CommonModalType = 'confirm' | 'alert';
export type CommonModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export interface CommonModalInitialState {
  modalType?: CommonModalType;
  title?: string | DefaultTFuncReturn;
  content: string; // JSX.Element | string | DefaultTFuncReturn;
  size?: CommonModalSize;
  yesButtonText?: string;
  yesCallback?: () => any;
  noCallback?: () => any;
  showCallbackResult?: boolean;
}

export interface CommonModalState extends CommonModalInitialState {
  isOpen: boolean;
  animation: CommonModalAnimateClassName;
}

export enum CommonModalAnimateClassName {
  OPEN = 'open-common-modal',
  CLOSE = 'close-common-modal',
}
