/** @jsxImportSource @emotion/react */
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useRef, useState } from 'react';
import { CrudCode } from 'models/common/Edit';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import 'ui/css/multiSelect.css';
import { useNavigate } from 'react-router-dom';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

import { GuideBlock } from 'pages/sample/guide/GuideComponent';
import useEvent from 'react-use-event-hook';

const SampleWijmo = () => {
  const navigate = useNavigate();
  const flexRef = useRef(null);
  const selectedRowRef = useRef(null);
  const [rowData, setRowData] = useState([
    {
      crudKey: CrudCode.READ,
      id: 1,
      desc: '사용자 1',
      name: '박문수',
      address: ['대한민국', '미국'],
      phone: '2024-10-13',
    },
    {
      crudKey: CrudCode.READ,
      id: 2,
      desc: '사용자 2',
      name: '홍길동',
      address: ['미국'],
      phone: new Date(),
    },
    {
      crudKey: CrudCode.READ,
      id: 3,
      desc: '사용자 3',
      name: '일지매',
      address: ['일본'],
      phone: new Date(),
    },
    {
      crudKey: CrudCode.READ,
      id: 4,
      desc: '사용자 4',
      name: '이몽룡',
      address: ['중국'],
      phone: new Date(),
    },
    {
      crudKey: CrudCode.READ,
      id: 5,
      desc: '사용자 5',
      name: '성춘향',
      address: ['에스토니아'],
      phone: new Date(),
    },
  ]);

  const LayoutDefinition = () => {
    return [
      {
        binding: 'id',
        align: 'left',
        width: 50,
        isReadOnly: true,
      },
      {
        header: '개인정보',
        align: 'center',
        columns: [
          {
            binding: 'name',
            header: '이름',
            width: 150,
            isReadOnly: true,
          },
          {
            binding: 'address', //주의: rowData의 필드가 배열형태 이어야 함.
            header: '주소',
            width: 150,
            isReadOnly: true,
          },
        ],
      },
      {
        binding: 'desc',
        header: 'Link Sample',
        align: 'left',
        width: 350,
        cellTemplate: CellMaker.makeLink({
          text: '${item.desc}',
          click: (e, ctx) => {
            navigate('/mp/item-management', {
              state: {
                ITEM_TP_CD: ctx.item.desc,
              },
            }); //매개변수
          },
        }),
      },
      {
        binding: 'phone',
        align: 'center',
        width: 150,
        isReadOnly: true,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onFlexGridSelectionChanged = useEvent((s, e) => {
    if (s.rows == 0) return;
    selectedRowRef.current = s.rows[e.row].dataItem;
    console.log(selectedRowRef.current);
  });

  const onInitialized = useEvent((grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    flexRef.current = grid;

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;
  });

  return (
    <div>
      <GuideBlock title={'Import'} isCode={true}>
        {`
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import 'ui/css/multiSelect.css';
import { useNavigate } from 'react-router-dom';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
`}
      </GuideBlock>
      <GuideBlock title={'Layout'} isCode={true}>
        {`
const navigate = useNavigate();
const flexRef = useRef(null);
const selectedRowRef = useRef(null);
const [rowData, setRowData] = useState([
  {
    crudKey: CrudCode.READ,
    id: 1,
    desc: '사용자 1',
    name: '박문수',
    address: ['대한민국', '미국'],
    phone: '2024-10-13',
  },
  {
    crudKey: CrudCode.READ,
    id: 2,
    desc: '사용자 2',
    name: '홍길동',
    address: ['미국'],
    phone: new Date(),
  },
  {
    crudKey: CrudCode.READ,
    id: 3,
    desc: '사용자 3',
    name: '일지매',
    address: ['일본'],
    phone: new Date(),
  },
  {
    crudKey: CrudCode.READ,
    id: 4,
    desc: '사용자 4',
    name: '이몽룡',
    address: ['중국'],
    phone: new Date(),
  },
  {
    crudKey: CrudCode.READ,
    id: 5,
    desc: '사용자 5',
    name: '성춘향',
    address: ['에스토니아'],
    phone: new Date(),
  },
]);

const LayoutDefinition = () => {
  return [
    {
      binding: 'id',
      align: 'left',
      width: 50,
      isReadOnly: true,
    },
    {
      header: '개인정보',
      align: 'center',
      columns: [
        {
          binding: 'name',
          header: '이름',
          width: 150,
          isReadOnly: true,
        },
        {
          binding: 'address', //주의: rowData의 필드가 배열형태 이어야 함.
          header: '주소',
          width: 150,
          isReadOnly: true,
        },
      ],
    },
    {
      binding: 'desc',
      header: 'Link Sample',
      align: 'left',
      width: 350,
      cellTemplate: CellMaker.makeLink({
        text: '\${item.desc}',
        click: (e, ctx) => {
          navigate('/mp/item-management', {
            state: {
              ITEM_TP_CD: ctx.item.desc,
            },
          }); //매개변수
        },
      }),
    },
    {
      binding: 'phone',
      align: 'center',
      width: 150,
      isReadOnly: true,
    },
  ];
};

const state = {
  itemsSource: rowData,
  layoutDefinition: LayoutDefinition(),
};
`}
      </GuideBlock>
      <GuideBlock title={'Method'} isCode={true}>
        {`
const onFlexGridSelectionChanged = (s, e) => {
  if (s.rows == 0) return;
  const row = s.rows[e.row].dataItem;
  setSelectedRow(row);
};

const onInitialized = (grid) => {
  new Selector(grid);
  new FlexGridFilter(grid);
  setFlexRef(grid);

  //셀렉트 방식
  grid.selectionMode = SelectionMode.Row;
};
`}
      </GuideBlock>
      <GuideBlock title={'Render'} isCode={true}>
        {`
<ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
  <FlexGrid
    columns={state.layoutDefinition}
    itemsSource={state.itemsSource}
    showMarquee={true}
    autoGenerateColumns={false}
    stickyHeaders={true}
    //isReadOnly={true}
    formatItem={flexGridTooltip}
    selectionChanged={onFlexGridSelectionChanged}
    initialized={onInitialized}
    style={{ height: '300px' }}
/>
</ContentGrid>
`}
      </GuideBlock>

      <GuideBlock title={'Example'} isCode={true}>
        <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
          <FlexGrid
            columns={state.layoutDefinition}
            itemsSource={state.itemsSource}
            showMarquee={true}
            autoGenerateColumns={false}
            stickyHeaders={true}
            //isReadOnly={true}
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: '300px' }}
          />
        </ContentGrid>
      </GuideBlock>
      <GuideBlock title={'selected Row Data'} isCode={true}>
        <pre>{JSON.stringify(selectedRowRef, null, 2)}</pre>
      </GuideBlock>
    </div>
  );
};
export default SampleWijmo;
