/** @jsxImportSource @emotion/react */
import React, { useRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EquipmentClassificationCondition,
  EquipmentClassificationDetail,
} from 'models/mp/MpEquipment';
import { findMpEqiupmentClassificationDetail } from 'apis/mp/MpEquipment';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import CustomGrid from 'components/grids/CustomGrid';
import { downloadExcelTemplatesPost } from 'apis/common/Excel';
import { FileTypeName } from 'models/common/Common';

export const MpEquipmentClassificationDetailGrid = (props: any, ref) => {
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const [subTitle, setSubTitle] = useState<string>('');
  const [rowData, setRowData] = useState<EquipmentClassificationDetail[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    init: () => {
      setTotalCount(0);
      setRowData([]);
      setSubTitle('');
    },
    search: async (condition) => {
      await handleSearch(condition);
    },
  }));

  const handleSearch = async (condition) => {
    const response = await findMpEqiupmentClassificationDetail(condition);
    setSubTitle(condition?.eqpClsfNo || '');
    setTotalCount(response?.length || 0);

    if (response) {
      const findItemParent = (items: any[], upprEqpClsfStrcNo, parent?: any) => {
        if (parent && parent.eqpClsfStrcNo === upprEqpClsfStrcNo) {
          return parent;
        }

        for (const item of items) {
          if (item.eqpClsfStrcNo === upprEqpClsfStrcNo) {
            return findItemParent(items, upprEqpClsfStrcNo, item);
          }
          if (Object.prototype.hasOwnProperty.call(item, 'machines')) {
            return findItemParent(item?.machines, upprEqpClsfStrcNo, item);
          }
        }

        return parent;
      };

      const rowData = (response || []).reduce((acc, cur) => {
        // 상위분류코드가 없는 경우 최상위 객체 추가
        if (!cur.upprEqpClsfStrcNo) {
          return [...acc, cur];
        }
        const parent = findItemParent(acc, cur.upprEqpClsfStrcNo);
        if (parent) {
          const key = cur.eqpLvNm === 'Machine' ? 'machines' : 'units';
          if (!parent[key]) {
            parent[key] = [];
          }
          parent[key].push(cur);
        }
        return acc;
      }, [] as EquipmentClassificationDetail[]);

      setRowData(rowData);
    }
  };

  const layoutDefinition = [
    {
      binding: 'eqpClsfStrcNo',
      header: String(t('mp.grid.설비분류체계 구조 번호', '설비분류체계 구조 번호')),
      align: 'center',
      width: 180,
    },
    {
      binding: 'eqpClsfStrcNm',
      header: String(t('mp.grid.설비분류체계 구조명', '설비분류체계 구조명')),
      align: 'left',
      minWidth: 160,
      width: '*',
    },
    {
      binding: 'eqpLvNm',
      header: String(t('mp.grid.설비레벨', '설비레벨')),
      align: 'center',
      width: 90,
    },
    {
      binding: 'upprEqpClsfStrcNo',
      header: String(t('mp.grid.상위표준설비 구조도 번호', '상위표준설비 구조도 번호')),
      align: 'center',
      width: 180,
    },
    {
      binding: 'dataInsUserNm',
      header: String(t('mp.grid.등록자', '등록자')),
      align: 'center',
      width: 90,
    },
    {
      binding: 'dataInsDtm',
      header: String(t('mp.grid.최초등록일자', '최초등록일자')),
      align: 'center',
      width: 110,
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('mp.grid.수정자', '수정자')),
      align: 'center',
      width: 90,
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('mp.grid.최종수정일자', '최종수정일자')),
      align: 'center',
      width: 110,
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;
  };

  const exportFormat = (args) => {
    const p = args.panel;
    const row = args.row;
    const col = args.col;
    const xlsxCell = args.xlsxCell;
    if (p.columns[col].binding === 'gatingContIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingContTlIds') {
      const cell = args.getFormattedCell();
      xlsxCell.value = cell.textContent;
    }
    if (p.columns[col].binding === 'gatingEqpContData') {
      const cell = args.getFormattedCell();
      xlsxCell.value = '';
    }
  };

  const handleExportExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
      formatItem: exportFormat,
    });
    book.sheets[0].name = '설비분류체계구조';
    book.saveAsync(getExcelFileName(t('mp.label.설비분류체계구조 List', '설비분류체계구조 List')));
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('mp.label.설비분류체계구조 List', '설비분류체계구조 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {subTitle && <div className="info warning">{subTitle}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          {/*
          // [24.05.16] 설비분류체계 업로드 기능 작업 후 노출
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => {
              downloadExcelTemplatesPost(FileTypeName.TPL_EQP_LIST, String(props?.props.mpClsfCd));
            }}
            disableRipple
          >
            {t('com.button.템플릿 다운로드', '템플릿 다운로드')}
          </Button>
          */}
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleExportExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isSelector={false}
        isFilter={false}
        autoCheck={true}
        isReadOnly={true}
        allowSorting={false}
        allowPinning={false}
        allowDragging={false}
        height={570}
        childItemsPath={['machines', 'units']}
        initialized={onInitialized}
      />
    </>
  );
};

export default React.forwardRef(MpEquipmentClassificationDetailGrid);
