/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { DataType } from '@grapecity/wijmo';
import { DataMap, Row } from '@grapecity/wijmo.grid';
import { IpCheckResultPoint } from 'models/ip/IpCheckResultPoint';
import {
  findCheckResultPointRecord,
  getIpCheckValOkNg,
  saveCheckResultPointRecord,
} from 'apis/ip/IpCheckResult';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { CheckResultCellTemplate } from 'pages/ip/maintenance/grid/CheckResultCellRenderer';
import { IconButton } from 'components/buttons/IconSVG';
import { useCommonModal } from 'hooks/useCommonModal';
import { CrudCode } from 'models/common/Edit';
import { SuccessOrNot } from 'models/common/RestApi';
import { IpCheckResult } from 'models/ip/IpCheckResult';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import WJCellTextarea from 'components/inputs/WJCellTextarea';

type ParameterPointResultRecordModalProps = {
  open: boolean;
  close: (repResult?: IpCheckResult) => void;
  condition: {
    ipCheckPlanNo: string;
    ipCheckTargetSeqId: string;
    ipCheckStep: string;
    ipCheckStatus: string;
    isReadOnly: boolean;
    hasAuth: boolean;
  };
};

const ParameterPointResultRecordModal = ({
  open,
  close,
  condition,
}: ParameterPointResultRecordModalProps) => {
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
  const [hitTest, setHitTest] = useState<any>();
  const [rowData, setRowData] = useState<IpCheckResultPoint[]>([]);
  const [resultOkNg, setResultOkNg] = useState<Code[]>([]);
  const isEnsolStep = useMemo(
    () => (condition?.ipCheckStatus || '').endsWith('ENSOL'),
    [condition]
  );

  console.log('condition', condition);

  useEffect(() => {
    findCheckResultPointRecord(condition).then((result: IpCheckResultPoint[]) => {
      setRowData(result);
    });

    getCommonCodes();
  }, []);

  console.log('condition', condition);

  const getCommonCodes = async () => {
    const resultOkNg: Code[] = await getCommonCodeNames('RESULT_OKNG');
    setResultOkNg(resultOkNg);
  };

  /**
   * 결과복사 (다건)
   */
  const handleCopy = () => {
    const rows = gridRef.current.rows;
    if (!rows) return;
    copyValues(rows);
  };

  /**
   * 결과값 복사
   * @param results
   */
  const copyValues = (rows: Row[]) => {
    // const ensolYn = currentPlan.ensolYn || CommonYN.N;
    // FAT엔솔검증(FAT_ENSOL), SAT엔솔검증(SAT_ENSOL) 단계에서만 복사 가능 (엔솔대상여부가 N인 경우 복사 불가)
    // TODO 엔솔대상여부가 Y인데, 업체대상여부가 N인 케이스는 없음 (추후 실데이터 확인 필요)
    rows.map((row) => {
      const item = row.dataItem || {};
      item.crudKey = CrudCode.UPDATE;
      item.ensolValue = item.makerValue;
      item.ensolResult = item.makerResult;
    });
    gridRef.current?.refresh();
  };

  /**
   * 저장 처리
   */
  const handleSave = () => {
    const saveData = rowData.filter((o) => o.crudKey === CrudCode.UPDATE);
    if (!saveData || saveData.length < 1) {
      openMessageBar({
        type: 'warning',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        saveCheckResultPointRecord(saveData)
          .then((response) => {
            if (response.successOrNot === SuccessOrNot.Y) {
              openMessageBar({
                type: 'confirm',
                content: t('com.label.저장되었습니다.', '저장되었습니다.'),
              });
              close(response.data as IpCheckResult);
            } else {
              openMessageBar({
                type: 'error',
                content:
                  (response.data as string) ||
                  t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
              });
            }
          })
          .catch((err) => {
            openMessageBar({
              type: 'error',
              content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
          });
      },
    });
  };

  // 업체검증단계인 경우 업체만 노출, 엔솔검증단계인 경우 업체/엔솔 모두 노출
  // FAT단계인 경우 FAT, SAT단계인 경우 SAT
  const layoutDefinition = [
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      width: 45,
      align: 'center',
      isReadOnly: true,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'paraName',
      header: String(t('ip.grid.파라미터명', '파라미터명')),
      width: 150,
      isReadOnly: true,
    },
    {
      binding: 'paraPointNo',
      header: String(t('ip.grid.포인터 번호', '포인터 번호')),
      width: 110,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'paraPointName',
      header: String(t('ip.grid.측정개소명', '측정개소명')),
      width: '*',
      minWidth: 170,
      isReadOnly: true,
    },
    {
      binding: 'paraPointDesc',
      header: String(t('ip.grid.측정개소 설명', '측정개소 설명')),
      width: 150,
      isReadOnly: true,
    },
    {
      binding: 'meterTypeNm',
      header: String(t('ip.grid.미터유형', '미터유형')),
      width: 80,
      isReadOnly: true,
    },
    {
      binding: 'lowerBound',
      header: String(t('ip.grid.하한', '하한')),
      width: 70,
      isReadOnly: true,
    },
    {
      binding: 'upperBound',
      header: String(t('ip.grid.상한', '상한')),
      width: 70,
      isReadOnly: true,
    },
    { binding: 'uomNm', header: String(t('ip.grid.UOM', 'UOM')), width: 80, isReadOnly: true },
    { binding: 'rmk', header: String(t('ip.grid.주석', '주석')), width: 100, isReadOnly: true },
    {
      header: String(t('ip.grid.Maker', 'Maker')),
      align: 'center',
      columns: [
        {
          binding: 'makerValue',
          header: String(t('ip.grid.Value', 'Value')),
          width: 100,
          dataType: DataType.Number,
          isReadOnly: isEnsolStep || condition?.isReadOnly,
          isRequired: false,
        },
        {
          binding: 'makerResult',
          header: String(t('ip.grid.Result', 'Result')),
          width: 100,
          align: 'center',
          isReadOnly: isEnsolStep || condition?.isReadOnly,
          cssClass: isEnsolStep || condition?.isReadOnly ? '' : 'WijmoSelect',
          dataMap: new DataMap(resultOkNg, 'cmnCd', 'cmnCdNm'),
          cellTemplate: (params) =>
            CheckResultCellTemplate({
              ...params,
              curValue: params.item.makerValue,
              resultOkngCodes: resultOkNg,
              isReadOnly: isEnsolStep || condition?.isReadOnly,
            }),
        },
        {
          binding: 'makerComment',
          header: String(t('ip.grid.Comment', 'Comment')),
          width: 100,
          isReadOnly: isEnsolStep || condition?.isReadOnly,
          /*
          cellEditor: 'agLargeTextCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            useFormatter: true,
            maxLength: 1000,
          },
          onCellValueChanged: (params) => {
            params.node?.setDataValue('crudKey', CrudCode.UPDATE);
          },
          */
        },
      ],
    },
    {
      header: String(t('ip.grid.Ensol', 'Ensol')),
      align: 'center',
      visible: !(!isEnsolStep && !condition.isReadOnly),
      columns: [
        {
          binding: 'copyMaker',
          header: String(t('ip.grid.결과복사', '결과복사')),
          width: 100,
          align: 'center',
          isReadOnly: true,
          visible: !(condition.isReadOnly || !isEnsolStep),
          cssClass: 'WijmoCopy',
          /* 클릭시 Maker의 결과 (Value, Result)를 그대로 복사 */
          cellTemplate: condition.hasAuth ? '<Button />' : '',
        },
        {
          binding: 'ensolValue',
          header: String(t('ip.grid.Value', 'Value')),
          width: 100,
          dataType: DataType.Number,
          isReadOnly: !(isEnsolStep && !condition.isReadOnly && condition.hasAuth),
          visible: !(!isEnsolStep && !condition.isReadOnly),
          isRequired: false,
        },
        {
          binding: 'ensolResult',
          header: String(t('ip.grid.Result', 'Result')),
          width: 100,
          align: 'center',
          cssClass: isEnsolStep && !condition.isReadOnly ? 'WijmoSelect' : '',
          isReadOnly: !condition.hasAuth,
          visible: !(!isEnsolStep && !condition.isReadOnly),
          dataMap: new DataMap(resultOkNg, 'cmnCd', 'cmnCdNm'),
          cellTemplate: (params) =>
            CheckResultCellTemplate({
              ...params,
              curValue: params.item.ensolValue,
              resultOkngCodes: resultOkNg,
              // isReadOnly: isEnsolStep || condition?.isReadOnly,
            }),
        },
        {
          binding: 'ensolComment',
          header: String(t('ip.grid.Comment', 'Comment')),
          width: 100,
          isReadOnly: !(isEnsolStep && !condition.isReadOnly && condition.hasAuth),
          visible: !(!isEnsolStep && !condition.isReadOnly),
          /*
          cellEditor: 'agLargeTextCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            useFormatter: true,
            maxLength: 1000,
          },
          onCellValueChanged: (params) => {
            params.node?.setDataValue('crudKey', CrudCode.UPDATE);
          },
          */
        },
      ],
    },
  ];

  const onInitialized = (grid) => {
    gridRef.current = grid;

    grid?.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const binding = grid.columns[ht.col].binding;
        const item = grid.rows[ht.row].dataItem;
        // Comment 클릭 이벤트
        if ('ensolComment' === binding || 'makerComment' === binding) {
          if (!grid.columns[ht.col].isReadOnly) {
            setHitTest(ht);
            setOpenCellTextarea(true);
          }
        }
        // 결과복사 클릭 이벤트
        if ('copyMaker' === binding && condition.hasAuth) {
          // 클릭시 Maker의 결과 (Value, Result)를 그대로 복사
          copyValues([grid.rows[ht.row]]);
        }
      }
    });
  };

  const onCellEditEnded = (grid, e) => {
    const binding = grid.columns[e.col].binding;
    const data = grid.rows[e.row].dataItem;
    // Value가 변경된 경우
    if ('makerValue' === binding || 'ensolValue' === binding) {
      const okng = getIpCheckValOkNg(
        data.meterType,
        data.lowerBound,
        data.upperBound,
        data[binding]
      );
      if (okng) {
        // 결과값 설정
        data[binding.replace('Value', 'Result')] = okng || '';
      }
    }
  };

  const dialogButtons = [
    <Button
      key={'save'}
      css={IconButton.button}
      className="save"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  console.log('isEnsolStep', isEnsolStep);
  return (
    <CustomDialog
      open={open}
      title={t('ip.label.측정개소 결과 입력', '측정개소 결과 입력')}
      size={'xl'}
      // hasAuth == false && !isEnsolStep
      buttons={condition.hasAuth === false && isEnsolStep ? [] : dialogButtons}
      onCancel={close}
      onClose={close}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.측정개소 대상 계획', '측정개소 대상 계획')}</h3>
        </SubTitleGroup>
        {/* 엔솔 점검 단계에서만 결과복사 사용 (FAT_ENSOL, SAT_ENSOL) */}
        {isEnsolStep && condition.hasAuth === true && (
          <ControlBtnGroup>
            <Button css={IconButton.button} className="copy" onClick={handleCopy} disableRipple>
              {t('ip.button.결과복사', '결과복사')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>
      <CustomGrid
        rowData={rowData}
        layoutDefinition={layoutDefinition}
        height={500}
        isSelector={false}
        initialized={onInitialized}
        cellEditEnded={onCellEditEnded}
      />
      {isOpenCellTextarea && (
        <WJCellTextarea
          grid={gridRef.current}
          hitTest={hitTest}
          close={() => setOpenCellTextarea(false)}
        />
      )}
    </CustomDialog>
  );
};

export default ParameterPointResultRecordModal;
