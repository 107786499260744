/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ContentGrid } from '../../../components/layouts/ContentGrid';
import {
  SubTitleLayout,
  SubTitleGroup,
  Chart2Div,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { GatingResultReportAct } from 'models/gtng/GatingResultReportAct';
import { FlexRadar, FlexRadarAxis, FlexRadarSeries } from '@grapecity/wijmo.react.chart.radar';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
} from 'components/layouts/SearchBox';
import {
  confirmGatingJudgementAct,
  findGatingJudgementReportDtlEquipment,
  findGatingjudgementReportReqSummary,
  findGatingjudgementReportSummary,
  retrieveGtngEquipRsltChart,
  retrieveGtngEquipRsltChartSub,
} from 'apis/gtng/GatingJudgementResult';
import { GatingJudgementResultSummary } from 'models/gtng/GatingJudgementResult';
import {
  FlexChartCore,
  SeriesBase,
  SeriesRenderingEventArgs,
  FlexChartBase,
  RenderEventArgs,
} from '@grapecity/wijmo.chart';
import {
  FlexChart,
  FlexChartLegend,
  FlexChartSeries,
  FlexChartAxis,
  FlexChartDataLabel,
} from '@grapecity/wijmo.react.chart';
import { useCommonModal } from 'hooks/useCommonModal';
import { GatingJudgementResultDtl } from 'models/gtng/GatingJudgementResultDtl';
import { GatingJudgementResultDtlEquipment } from '../../../models/gtng/GatingJudgementResultDtlEquipment';
import _ from 'lodash';
import { TooltipComponent, TooltipValueGetter } from 'components/grids/Tooltip';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { findGatingResultReportAct } from 'apis/gtng/GatingResultReport';
import useSessionStore from 'stores/useSessionStore';
import { CustomInputWrap } from 'components/inputs/CustomInput';
import { GatingContListPopUp } from './GatingContListPopUp';
import Button from '@mui/material/Button';
// Wijmo library
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { ContentSection } from 'components/layouts/ContentSection';
import CustomGrid from '../../../components/grids/CustomGrid';
type Props = {
  condition?: {
    gatingId: any;
  };
};

/**
 * Gating 판정 결과 Report -> 승인요청미리보기 판정 요청서
 * @param condition
 * @constructor
 */
const GatingJudgeResultReqPopUp = forwardRef(({ condition }: Props, ref) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { gridNoRowsTemplate } = useSessionStore();
  const [gatingActgridRef, setGatingActgridRef] = useState<any>();
  const [gatingReqEquipDtlgridRef, setGatingReqEquipDtlgridRef] = useState<any>();
  const [gatingDtlgridRefEquip, setGatingEquipgridRef] = useState<any>();
  const [rowData, setRowData] = useState<GatingResultReportAct[]>([]);
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  //dd
  const [summaryData2, setSummaryData2] = useState<GatingJudgementResultDtlEquipment[]>([]);
  const [rowDataChart1, setRowDataChart1] = useState<GatingResultReportAct[]>([]);
  const [rowDataChart2, setRowDataChart2] = useState<GatingResultReportAct[]>([]);
  const [summaryData, setSummaryData] = useState<GatingJudgementResultSummary[]>([]);
  const [judgeReqSummary, setJudgeReqSummary] = useState<any>([]);
  const barChartRef = useRef<FlexChartCore>();
  const [newColumns, setNewColumns] = useState<any>([]);

  const [initUserId, setInitUserId] = useState<string>(''); //검색할 파라미터 상태관리
  const [popup, setPopup] = useState<any>(false); //팝업창 오픈, 클로즈 상태관리
  const [contPopTitle, setContPopTitle] = useState<string>(''); //검색할 파라미터 상태관리
  const [initParam, setInitParam] = useState<string>(''); //검색할 파라미터 상태관리
  const [colWidthMapReqEquip, setColWidthMapReqEquip] = useState<any>({
    no: 40,
    equipmentId: '*',
    equipmentName: '*',
    evaluationScore: '*',
    inspectionResultName: '*',
  });

  useImperativeHandle(ref, () => ({
    handleSave,
  }));

  // const onGridReady = (params: any) => {
  //   params.api.sizeColumnsToFit();
  // };

  const radarItemsSource = useMemo(() => {
    const items = (rowDataChart1 || []).reduce((acc, cur) => {
      if ('gatingType2Value' in cur) {
        const ravg = Number(cur['ravg']);
        const goodBasisScore = Number(cur['goodBasisScore']);
        acc.push({
          gatingId: cur['gatingId'],
          gatingType2Value: cur['gatingType2Value'],
          ravg: 'ravg' in cur && !_.isNaN(ravg) ? ravg : 0,
          goodBasisScore: 'goodBasisScore' in cur && !_.isNaN(goodBasisScore) ? goodBasisScore : 0,
        });
      }
      return acc;
    }, [] as any[]);
    return items;
  }, [rowDataChart1]);
  const barItemsSource = useMemo(() => {
    const items = (rowDataChart2 || []).reduce((acc, cur) => {
      if ('equipmentId' in cur) {
        const ravg = Number(cur['ravg']);
        const goodBasisScore = Number(cur['goodBasisScore']);
        acc.push({
          equipmentId: cur['equipmentId'],
          ravg: 'ravg' in cur && !_.isNaN(ravg) ? ravg : 0,
          // [cur['equipmentId']]: 'ravg' in cur && !_.isNaN(ravg) ? ravg : 0,
          goodBasisScore: 'goodBasisScore' in cur && !_.isNaN(goodBasisScore) ? goodBasisScore : 0,
        });
      }
      return acc;
    }, [] as any[]);
    return items;
  }, [rowDataChart2]);

  // useEffect(() => {
  //   setRowData(condition?.rowData);
  //   setSummaryData(condition?.summaryData);
  //   setSummaryData2(condition?.summaryData2);
  //   setRowDataChart1(condition?.rowDataChart1);
  //   setRowDataChart2(condition?.rowDataChart2);
  //   setJudgeReqSummary(condition?.judgeReqSummary);
  // }, []);

  const fnSearchMst = () => {
    // 판정결과 보완이력
    findGatingResultReportAct(condition?.gatingId).then((result: GatingResultReportAct[]) => {
      const actData = result.filter((element) => element.itemInspectionResultCode !== 'OK');
      setRowData(actData);
    });

    // 판정결과(요약)
    findGatingjudgementReportSummary(condition?.gatingId).then(
      (result: GatingJudgementResultSummary[]) => {
        if (result.length == 0) {
          setSummaryData([
            {
              gatingId: '',
              gatingNo: '',
              gatingName: '',
              gatingTypeCode: '',
              gatingTypeName: '',
              gatingStepCode: '',
              gatingStepName: '',
              taskLeaderUserId: '',
              taskLeaderUserName: '',
              pmoApproverId: '',
              pmoApproverName: '',
              progressStatusCode: '',
              progressStatusName: '',
              approvalRequestYn: '',
              evaluationScore: '',
              inspectionResultCode: '',
              inspectionResultName: '',
              decisionResult: '',
              aprReqId: '',
              gatingContIds: '',
              gatingContNames: '',
              gatingContDepartments: '',
            },
          ]);
        } else {
          setSummaryData(result);
        }
      }
    );

    if (barChartRef.current) {
      barChartRef.current.header = `${t('gtng.label.설비별 점수', '설비별 점수')}`;
    }
    //차트1 : 방사형
    retrieveGtngEquipRsltChart(condition?.gatingId).then((result: GatingResultReportAct[]) => {
      setRowDataChart1(result);
    });
    // 차트2: 막대형
    retrieveGtngEquipRsltChartSub(condition?.gatingId).then((result: GatingResultReportAct[]) => {
      setRowDataChart2(result);
    });

    // 가변 그리드 용
    findGatingJudgementReportDtlEquipment(condition?.gatingId).then((result: any) => {
      setSummaryData2(result.data);
    });

    //판정요청서 설비별 판정상세요약
    findGatingjudgementReportReqSummary(condition?.gatingId).then((result: any) => {
      setJudgeReqSummary(result);
    });
  };

  useEffect(() => {
    fnSearchMst();
  }, []);

  // const valueFormatter = (params: any) => {
  //   if (params.colDef.field == 'equipmentId') {
  //     return params.node.data.equipmentName;
  //   }
  //   if (params.colDef.field == 'itemInspectionResultCode') {
  //     return params.node.data.itemInspectionResultName;
  //   }
  //   if (params.colDef.field == 'gatingCheckItemGradeCode') {
  //     return params.node.data.gatingCheckItemGradeName;
  //   }
  //   //실시담당자
  //   if (params.colDef.field == 'actionContactId') {
  //     return params.node.data.actionContact;
  //   }
  // };
  //사용자 검색 팝업
  const btnElmEmpPopup = ({ value }) => `<span>${value ? value : ''}</span>
  <Button id="empPopBtn"></Button>
  `;

  const openPopup = (params: any) => {
    const fieldName = params.binding;
    const id = fieldName === 'actionContact' ? 'actionContactId' : '';
    if (fieldName != null && fieldName != '') {
      setInitUserId(params.item[id]);
    }

    //실시담당자
    if (fieldName == 'actionContact') {
      setContPopTitle(String(t('gtng.label.실시담당자', '실시담당자')));
    }

    setInitParam(params.item[id]);
    setPopup(true);
  };

  //시작일자, 종료일자 YY-MM-DD 형태로 변환
  const dateValueFommater = (params: any) => {
    const date = params.value;
    if (date != null && date != undefined) {
      const subStrDate = date.substr(0, 10);
      const formmattedDate = subStrDate.replaceAll('-', '.');
      return formmattedDate;
    }
  };

  // 최종완료 :: 임시승인요청 (임시저장)
  const handleSave = (aprReqId) => {
    summaryData[0].aprReqId = aprReqId;

    confirmGatingJudgementAct(summaryData[0]);
  };

  // // 최종완료 :: 임시승인 (진행상태 = 'D' or 'B')
  // const btnConfirm2 = () => {
  //   openCommonModal({
  //     modalType: 'confirm',
  //     content: t('com.label.승인하시겠습니까?', '승인하시겠습니까?'),
  //     yesCallback: () => {
  //       confirmGatingJudgementAct(summaryData[0]).then((result) => {
  //         if (!result) {
  //           openCommonModal({ content: '결재승인이 실패하였습니다.' });
  //           return false;
  //         }
  //         openCommonModal({ content: '결재승인이 완료되었습니다.' });
  //         fnSearchMst();
  //       });
  //     },
  //   });
  // };

  // // 반려 : 임시반려 - > APR_REQ_PROG_STATA_CD 를 REJ상태로 전환
  // const btnConfirm3 = () => {
  //   openCommonModal({
  //     modalType: 'confirm',
  //     content: t('com.label.반려하시겠습니까?', '반려하시겠습니까?'),
  //     yesCallback: () => {
  //       updateTmpApprovalLineAfterReject(summaryData[0]).then((result) => {
  //         if (!result) {
  //           openCommonModal({ content: '결재반려가 실패하였습니다.' });
  //           return false;
  //         }
  //         openCommonModal({ content: '결재반려가 완료되었습니다.' });
  //         fnSearchMst();
  //       });
  //     },
  //     noCallback: () => {
  //       return false;
  //     },
  //   });
  // };

  const LayoutDefinitionEquip2 = () => {
    const columnDefsEquip2 = [
      {
        header: String(t('com.label.NO', 'NO')),
        width: 70,
        //  filter: false,
        isReadOnly: true,
        //  suppressSizeToFit: true,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      { header: 'GatingId', binding: 'GATING_ID', visible: false },
      { header: 'GatingId', binding: 'GATING_NO', visible: false },
      { header: 'sub_seq_no', binding: 'SUB_SEQ_NO', visible: false },
      {
        header: String(t('gtng.grid.등급', '등급')),
        binding: 'GATING_CHECK_ITEM_GRADE_NAME',
        isReadOnly: true,
        align: 'center',
        width: 100,
      },
      {
        header: String(t('gtng.grid.점검구분1', '점검구분1')),
        binding: 'GATING_TYPE1_VALUE',
        isReadOnly: true,
        //  cellStyle: { textAlign: 'left' },
        width: 130,
      },
      {
        header: String(t('gtng.grid.점검구분2', '점검구분2')),
        binding: 'GATING_TYPE2_VALUE',
        isReadOnly: true,
        //  cellStyle: { textAlign: 'left' },
        width: 130,
      },
      {
        header: String(t('gtng.grid.점검구분3', '점검구분3')),
        binding: 'GATING_TYPE3_VALUE',
        isReadOnly: true,
        //  cellStyle: { textAlign: 'left' },
        width: 130,
      },
      {
        header: String(t('gtng.grid.Check List', 'Check List')),
        binding: 'GATING_CHECK_ITEM1_VALUE',
        align: 'center',
        isReadOnly: true,
        //  cellStyle: { textAlign: 'left' },
        width: 130,
      },
      {
        header: String(t('gtng.grid.Check 방법/계측/검사', 'Check 방법/계측/검사')),
        binding: 'GATING_CHECK_ITEM2_VALUE',
        align: 'center',
        isReadOnly: true,
        minWidth: 160,
        //  cellStyle: { textAlign: 'left' },
        width: '*',
      },
      {
        header: String(t('gtng.grid.판단기준/Spec/목표', '판단기준/Spec/목표')),
        binding: 'GATING_CHECK_ITEM3_VALUE',
        align: 'center',
        isReadOnly: true,
        minWidth: 160,
        //  cellStyle: { textAlign: 'left' },
        width: '*',
      },
    ];

    if (summaryData2.length > 0) {
      const sampleData = summaryData2[0]; // 첫 번째2 2데이터 항목을 기반으로 33컬럼 생성
      for (const key in sampleData) {
        if (!checkIfColumnExists(newColumns, key)) {
          // 1. 값 추출
          // 2. 컬럼 그룹 헤더 생성
          // 3. 컬럼 그룹에 컬럼 매핑
          // if (key.includes('RESULT_CODE'))

          let columnNameH;
          if (key.includes('_SCORE')) {
            columnNameH = key.replace('_SCORE', '');
            // if (newColumns.length > 0 && columnNameH === newColumns[newColumns.length - 1].header)
            //   continue;
            newColumns.push({
              header: columnNameH,
              align: 'center',
              columns: [
                {
                  header: String(t('gtng.grid.점수', '점수')),
                  width: '*',
                  isReadOnly: true,
                  align: 'center',
                  binding: columnNameH + '_SCORE',
                },
                {
                  header: String(t('gtng.grid.결과', '결과')),
                  binding: columnNameH + '_RESULT_CODE',
                  align: 'center',
                  isReadOnly: true,
                  width: '*',
                },
              ],
            });
          }
        }
      }
    }
    return [...columnDefsEquip2, ...newColumns];
  };

  const checkIfColumnExists = (columnDefs, columnName) => {
    for (const columnDef of columnDefs) {
      if (Object.keys(columnDef).includes('columns')) {
        for (const item of columnDef.columns) {
          if (item.binding === columnName) {
            return true; // 컬럼이 존재함
          }
        }
      }
    }
    return false; // 컬럼이 존재하지 않음122
  };
  // const columnDefsEquip = useMemo(() => {
  //   // 동적 컬럼을 생성하는 로직
  //   const newColumns = [...columnDefsEquip2];
  //   if (_.isNull(summaryData2)) return newColumns;

  //   if (summaryData2.length > 0) {
  //     const sampleData = summaryData2[0]; // 첫 번째2 2데이터 항목을 기반으로 33컬럼 생성
  //     for (const key in sampleData) {
  //       if (!checkIfColumnExists(columnDefsEquip2, key)) {
  //         // 1. 값 추출
  //         // 2. 컬럼 그룹 헤더 생성
  //         // 3. 컬럼 그룹에 컬럼 매핑
  //         // if (key.includes('RESULT_CODE'))

  //         let columnNameH;
  //         if (key.includes('_SCORE')) {
  //           columnNameH = key.replace('_SCORE', '');

  //           const newColumn = {
  //             header: columnNameH,
  //             columns: [
  //               {
  //                 header: String(t('gtng.grid.점수', '점수')),
  //                 width: 80,
  //                 binding: columnNameH + '_SCORE',
  //               },
  //               {
  //                 header: String(t('gtng.grid.결과', '결과')),
  //                 binding: columnNameH + '_RESULT_CODE',
  //                 width: 80,
  //               },
  //             ],
  //           };

  //           newColumns.push(newColumn);
  //         }
  //       }
  //     }
  //   }
  //   return newColumns;
  // }, [summaryData2]);

  /**
   * grid
   * */
  // const defaultColDef: ColDef = useMemo(
  //   () => ({
  //     sortable: true,
  //     resizable: true,
  //     isReadOnly: true,
  //     cellStyle: { textAlign: 'center' },
  //     tooltipComponent: TooltipComponent,
  //     tooltipValueGetter: TooltipValueGetter,
  //   }),
  //   []
  // );

  const LayoutDefinitionAct = () => {
    return [
      {
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        //  filter: false,
        isReadOnly: true,
        align: 'center',
        //  suppressSizeToFit: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('gtng.grid.장비명', '장비명')),
        binding: 'equipmentId',
        visible: false,
        // cellTemplate: valueFormatter,
        //  cellStyle: { textAlign: 'left' },
      },
      {
        header: String(t('gtng.grid.장비명', '장비명')),
        binding: 'equipmentName',
        isReadOnly: true,
        width: 150,
      },
      {
        header: String(t('gtng.grid.점검차수', '점검차수')),
        binding: 'inspectionSeqs',
        isReadOnly: true,
        width: 75,
      },
      {
        header: String(t('gtng.grid.점검점수', '점검점수')),
        binding: 'itemEvaluationScore',
        isReadOnly: true,
        width: 75,
      },
      {
        header: String(t('gtng.grid.점검결과', '점검결과')),
        binding: 'itemInspectionResultCode',
        visible: false,
        //  cellStyle: { textAlign: 'left' },
        // cellTemplate: valueFormatter,
        // filter: true,
      },
      {
        header: String(t('gtng.grid.점검결과', '점검결과')),
        binding: 'itemInspectionResultName',
        isReadOnly: true,
        width: 100,
      },
      {
        header: String(t('gtng.grid.미비점(NG항목)', '미비점(NG항목)')),
        align: 'center',
        columns: [
          {
            header: String(t('gtng.grid.점검구분1', '점검구분1')),
            binding: 'gatingType1Value',
            isReadOnly: true,
            width: 140,
            //  cellStyle: { textAlign: 'left' },
          },
          {
            header: String(t('gtng.grid.점검구분2', '점검구분2')),
            binding: 'gatingType2Value',
            isReadOnly: true,
            width: 120,
            //  cellStyle: { textAlign: 'left' },
          },
          {
            header: String(t('gtng.grid.점검구분3', '점검구분3')),
            binding: 'gatingType3Value',
            isReadOnly: true,
            width: 120,
            //  cellStyle: { textAlign: 'left' },
          },
          {
            header: String(t('gtng.grid.Check List', 'Check List')),
            binding: 'gatingCheckItem1Value',
            isReadOnly: true,
            width: 120,
            //  cellStyle: { textAlign: 'left' },
          },
        ],
      },
      {
        header: String(t('gtng.grid.점검결과', '점검결과')),
        binding: 'checkResultContent',
        isReadOnly: true,
        width: 100,
      },
      {
        header: String(t('gtng.label.실시(T/L)', '실시(T/L)')),
        align: 'center',
        columns: [
          // {
          //   header: String(t('gtng.grid.Advice', 'Advice')),
          //   binding: 'pmoCommentCount',
          //   width: 85,
          // },
          {
            header: String(t('gtng.grid.Advice', 'Advice')),
            binding: 'pmoComment',
            isReadOnly: true,
            width: 85,
          },
        ],
      },
      {
        header: String(t('gtng.grid.조치/실행', '조치/실행')),
        align: 'center',
        columns: [
          {
            header: String(t('gtng.label.실시담당자', '실시담당자')),
            binding: 'actionContact',
            isReadOnly: true,
            width: 100,
            cssClass: 'WijmoFind',
            cellTemplate: (params) => btnElmEmpPopup(params),
          },
          {
            header: String(t('gtng.label.실시담당자', '실시담당자')),
            binding: 'actionContactId',
            visible: false,
          },
          {
            header: String(t('gtng.grid.보완계획', '보완계획')),
            binding: 'actionPlanDesc',
            isReadOnly: true,
            width: 300,
            //  cellStyle: { textAlign: 'left' },
          },
          {
            header: String(t('gtng.grid.계획일자', '계획일자')),
            binding: 'planDate',
            isReadOnly: true,
            width: 90,
            cellTemplate: (params) => dateValueFommater(params),
          },
          {
            header: String(t('com.label.수정일', '수정일')),
            binding: 'dataUpdDtm',
            isReadOnly: true,
            width: 90,
            cellTemplate: (params) => dateValueFommater(params),
          },
        ],
      },
    ];
  };

  const LayoutDefinitionReqEquip = () => {
    return [
      {
        header: String(t('com.label.NO', 'NO')),
        width: colWidthMapReqEquip.no,
        minWidth: 70,
        align: 'center',
        //  filter: false,
        isReadOnly: true,
        //  suppressSizeToFit: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('gtng.grid.설비', '설비')),
        binding: 'equipmentId',
        width: colWidthMapReqEquip.equipmentId,
        isReadOnly: true,
        //  cellStyle: { textAlign: 'left' },
      },
      {
        header: String(t('com.label.설비명', '설비명')),
        binding: 'equipmentName',
        width: colWidthMapReqEquip.equipmentName,
        isReadOnly: true,
        //  cellStyle: { textAlign: 'left' },
      },
      {
        header: String(t('gtng.grid.판정점수', '판정점수')),
        binding: 'evaluationScore',
        width: colWidthMapReqEquip.evaluationScore,
        align: 'center',
        isReadOnly: true,
      },
      {
        header: String(t('gtng.grid.결과', '결과')),
        binding: 'inspectionResultName',
        align: 'center',
        width: colWidthMapReqEquip.inspectionResultName,
        isReadOnly: true,
        //  cellStyle: { textAlign: 'left' },
      },
    ];
  };

  const stateAct = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinitionAct(),
  };

  const stateReqEquip = {
    itemsSource: judgeReqSummary,
    layoutDefinition: LayoutDefinitionReqEquip(),
  };

  const stateEquip2 = {
    itemsSource: summaryData2,
    layoutDefinition: LayoutDefinitionEquip2(),
  };

  const onInitializedAct = (grid) => {
    setGatingActgridRef(grid);
    grid.rowHeaders.columns.clear();

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);
      switch (e.target.id) {
        case 'empPopBtn':
          openPopup({ binding: col.binding, item });
          break;
      }

      grid.refresh();
    });
  };

  const onInitializedEquip2 = (grid) => {
    setGatingEquipgridRef(grid);
    grid.rowHeaders.columns.clear();

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);

      grid.refresh();
    });
  };

  const onInitializedReqEquip = (grid) => {
    setGatingReqEquipDtlgridRef(grid);
    grid.rowHeaders.columns.clear();

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const col = e.panel.columns[e.col];
      colWidthMapReqEquip[col.binding] = col.width;
      setColWidthMapReqEquip(colWidthMapReqEquip);
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);

      grid.refresh();
    });
  };

  const [showDiv, setShowDiv] = useState(false);
  const [showDiv2, setShowDiv2] = useState(false);

  //방사형
  useEffect(() => {
    // 2초 후에 div를 화면에 보이게 만듭니다.
    const timeoutId = setTimeout(() => {
      setShowDiv(true);
    }, 2000);

    // 컴포넌트가 언마운트될 때 타임아웃을 정리합니다.
    return () => clearTimeout(timeoutId);
  }, [rowDataChart1]);

  //막대
  useEffect(() => {
    // 2초 후에 div를 화면에 보이게 만듭니다.

    const timeoutId = setTimeout(() => {
      setShowDiv2(true);
    }, 1000);

    // 컴포넌트가 언마운트될 때 타임아웃을 정리합니다.
    return () => clearTimeout(timeoutId);
  }, [rowDataChart2]);

  return (
    <>
      <SubTitleLayout style={{ height: 'fit-content' }}>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating판정결과(요약)', 'Gating판정결과(요약)')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{t('gtng.label.Gating No', 'Gating No')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="gatingNo"
                  placeholder=""
                  disabled
                  value={summaryData[0]?.gatingNo}
                ></CustomInputWithSearch>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating Task명', 'Gating Task명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="gatingName"
                  placeholder=""
                  disabled
                  value={summaryData[0]?.gatingName}
                ></CustomInputWithSearch>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.판정점수', '판정점수')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="evaluationScore"
                  placeholder=""
                  disabled
                  value={summaryData[0]?.evaluationScore}
                ></CustomInputWithSearch>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.판정결과', '판정결과')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="inspectionResultName"
                  placeholder=""
                  disabled
                  value={summaryData[0]?.inspectionResultName}
                ></CustomInputWithSearch>
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{t('gtng.label.Gating 유형', 'Gating 유형')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="gatingTypeName"
                  placeholder=""
                  disabled
                  value={summaryData[0]?.gatingTypeName}
                ></CustomInputWithSearch>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 단계', 'Gating 단계')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="gatingStepName"
                  placeholder=""
                  disabled
                  value={summaryData[0]?.gatingStepName}
                ></CustomInputWithSearch>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.등록 담당자', '등록담당자')}</label>
                <CustomInputWithSearch
                  name="gatingContNames"
                  className="find"
                  value={summaryData[0]?.gatingContName}
                  readOnly={true}
                  disabled={true}
                  onSearchClick={(value) => {
                    if (summaryData[0] == null || summaryData[0]?.gatingContIds == '') return false;
                    setInitUserId(summaryData[0]?.gatingContIds || '');
                    setContPopTitle(String(t('gtng.label.등록 담당자', '등록 담당자')));
                    setPopup(true);
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.등록 T/L', '등록T/L')}</label>
                <CustomInputWithSearch
                  name="pmoApproverName"
                  className="find"
                  value={summaryData[0]?.pmoApproverName}
                  readOnly={true}
                  disabled={true}
                  onSearchClick={(param) => {
                    if (summaryData[0] == null || summaryData[0]?.pmoApproverId == '') return false;
                    setInitUserId(summaryData[0]?.pmoApproverId || '');
                    setContPopTitle(String(t('gtng.label.등록 T/L', '등록T/L')));
                    setPopup(true);
                  }}
                ></CustomInputWithSearch>
              </SearchCols>
            </SearchRows>
          </InputBox>
        </SearchBoxRow>
      </SearchBox>
      <ContentGrid
        className={judgeReqSummary.length < 1 ? 'noData' : 'ag-theme-alpine'}
        style={{ height: '230px' }}
      >
        <FlexGrid
          columnGroups={stateReqEquip.layoutDefinition}
          itemsSource={stateReqEquip.itemsSource}
          selectionMode={'Row'}
          deferResizing={false}
          initialized={onInitializedReqEquip}
          style={{ height: (judgeReqSummary || []).length < 1 ? '' : '200px' }}
        />
        <div
          className="noData"
          style={{ height: (judgeReqSummary || []).length < 1 ? '200px' : '' }}
        >
          <span>
            {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
          </span>
        </div>
      </ContentGrid>
      <Chart2Div>
        {/* 차트1 : 방사형 그래프 */}
        {showDiv2 && (
          <div>
            <FlexRadar
              bindingX="gatingType2Value"
              itemsSource={radarItemsSource}
              palette={['#B4B5F3', '#FDC1CA']}
              rendered={(c: FlexChartBase, e: RenderEventArgs) => {
                // axisY 배경선
                const yNodes = c.axisY.hostElement.childNodes;
                yNodes.forEach((o) => {
                  if ('polygon' === o.tagName.toLowerCase()) {
                    o.setAttribute('stroke', '#b9bcbb');
                    o.setAttribute('stroke-dasharray', '2,5');
                  }
                });
                const eNodes = e.engine.element.childNodes;
                eNodes.forEach((o) => {
                  // 하단 Legend
                  if (o.classList.contains('wj-legend')) {
                    const g = o.childNodes[1];
                    if (g) {
                      const gcNodes = g.childNodes;
                      gcNodes.forEach((gc) => {
                        // LineSymbols 투명하지 않게 설정
                        if ('ellipse' === gc.tagName.toLowerCase()) {
                          gc.setAttribute('fill', gc.getAttribute('stroke'));
                        }
                      });
                    }
                  }
                  // series 마크업 순서 변경
                  if (o.classList.contains('wj-series-group')) {
                    const gcNodes = o.children;
                    for (const gc of gcNodes) {
                      // 취득점수 (polygon, ellipse x N)
                      if (gc.children.length > 1) {
                        const pNode = gc.parentNode;
                        // 합격기준점수 -> 취득점수 순으로 마크업
                        if (pNode.firstChild === gc) {
                          pNode.removeChild(gc);
                          pNode.appendChild(gc);
                          break;
                        }
                      }
                    }
                  }
                });
              }}
              selectionMode="Point"
              selectionChanged={(s, e) => {
                if (s.selection) {
                  const point = s.selection.collectionView.currentItem;
                  if (point) {
                    if (barChartRef.current) {
                      barChartRef.current.header = `${point?.gatingType2Value} ${t(
                        'gtng.label.설비별 점수',
                        '설비별 점수'
                      )}`;
                    }
                    // 차트2: 막대형
                    retrieveGtngEquipRsltChartSub(point?.gatingId, point?.gatingType2Value).then(
                      (result: GatingResultReportAct[]) => {
                        setRowDataChart2(result);
                      }
                    );
                  }
                }
              }}
            >
              <FlexChartLegend position="Bottom"></FlexChartLegend>
              <FlexRadarAxis
                wjProperty="axisX"
                itemFormatter={(engine, label) => {
                  const item = radarItemsSource[label.val];
                  if (item) {
                    /**
                     <tspan x="0" dy={idx === 0 ? 0 : '1.2em'}>
                     {textRow}
                     </tspan>
                     */
                    label.text = `${item.gatingType2Value}(${item.ravg} ${t(
                      'gtng.label.점',
                      '점'
                    )})`;
                  }

                  return label;
                }}
              />
              <FlexRadarAxis wjProperty="axisY" min={0} max={100} majorUnit={20} />
              <FlexRadarSeries
                chartType="LineSymbols"
                binding="ravg"
                name={t('gtng.label.취득점수', '취득점수')}
                rendered={(c: SeriesBase, e: SeriesRenderingEventArgs) => {
                  const nodes = c.hostElement.childNodes;
                  if (nodes) {
                    nodes.forEach((o) => {
                      const obj = o as any as HTMLElement;
                      if ('ellipse' === obj.tagName.toLowerCase()) {
                        // LineSymbols 투명하지 않게 설정
                        obj.setAttribute('fill', obj.getAttribute('stroke') || '');
                      }
                    });
                  }
                }}
              />
              <FlexRadarSeries
                chartType="Area"
                binding="goodBasisScore"
                name={`${t('gtng.label.합격기준점수', '합격기준점수')}${
                  radarItemsSource.length > 0
                    ? `(${radarItemsSource[0].goodBasisScore}${t('gtng.label.점', '점')} ${t(
                        'gtng.label.이상',
                        '이상'
                      )})`
                    : ''
                }`}
              />
              {/*<FlexChartAnimation easing="Swing" animationMode="Point" duration={500} />*/}
            </FlexRadar>
          </div>
        )}
        {/* 차트2: 막대형 그래프 */}
        {showDiv && (
          <div>
            <FlexChart
              header={t('gtng.label.설비별 점수', '설비별 점수')}
              chartType="Bar"
              bindingX="equipmentId"
              itemsSource={barItemsSource}
              palette={['#B4B5F3', '#FDC1CA']}
              initialized={(grid) => {
                barChartRef.current = grid;
              }}
            >
              <FlexChartLegend position="Bottom"></FlexChartLegend>
              <FlexChartAxis wjProperty="axisX" min={0} max={100} />
              <FlexChartAxis
                wjProperty="axisY"
                reversed={true}
                itemFormatter={(engine, label) => {
                  const nodes = engine.element.childNodes;
                  if (nodes) {
                    nodes.forEach((node) => {
                      if (node.classList.contains('wj-axis-y')) {
                        const c = node.childNodes;
                        c.forEach((o) => {
                          const obj = o as any as HTMLElement;
                          if ('line' === obj.tagName.toLowerCase()) {
                            obj.setAttribute('stroke', '#b9bcbb');
                            obj.setAttribute('stroke-dasharray', '2,5');
                          }
                        });
                      }
                    });
                  }
                  return label;
                }}
              />
              <FlexChartDataLabel
                content="{y}"
                position="Left"
                rendering={(d, e) => {
                  if (e.hitTestInfo.series.binding === 'goodBasisScore') {
                    e.cancel = true;
                  } else {
                    e.point.x = e.point.x - 10;
                  }
                }}
              />
              <FlexChartSeries
                name={t('gtng.label.취득점수', '취득점수')}
                binding="ravg"
                rendered={(c: SeriesBase, e: SeriesRenderingEventArgs) => {
                  const nodes = c.hostElement.childNodes;
                  if (nodes) {
                    // 투명하지 않게 설정
                    nodes.forEach((o) => o.setAttribute('fill', o.getAttribute('stroke')));
                  }
                }}
              />
              <FlexChartSeries
                binding="goodBasisScore"
                name={`${t('gtng.label.합격기준점수', '합격기준점수')}${
                  barItemsSource.length > 0
                    ? `(${barItemsSource[0].goodBasisScore}${t('gtng.label.점', '점')} ${t(
                        'gtng.label.이상',
                        '이상'
                      )})`
                    : ''
                }`}
                chartType="Line"
                style={{
                  d: 'M5 20 l215 0',
                  'stroke-dasharray': '5,5',
                  'stroke-width': 2,
                }}
                rendered={(c: SeriesBase, e: SeriesRenderingEventArgs) => {
                  const hostElement = c.hostElement;

                  if (hostElement.children && hostElement.children.length) {
                    const points = hostElement.children[0]?.points;
                    if (points && points.length) {
                      const svg = hostElement.viewportElement;
                      if (svg) {
                        const p1 = svg.createSVGPoint();
                        p1.x = points[0].x;
                        p1.y = 0;
                        const p2 = svg.createSVGPoint();
                        p2.x = points[0].x;
                        p2.y = 500;
                        hostElement.children[0].points.insertItemBefore(p1, 0);
                        hostElement.children[0].points.appendItem(p2);
                      }
                    }
                  }
                }}
              />
            </FlexChart>
          </div>
        )}
      </Chart2Div>

      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 판정결과 및 보완이력', 'Gating 판정결과 및 보완이력')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span> {rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={stateAct.layoutDefinition}
        rowData={stateAct.itemsSource}
        height={300}
        initialized={onInitializedAct}
      />
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating 판정상세', 'Gating 판정상세')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span> {summaryData2.length}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={stateEquip2.layoutDefinition}
        rowData={stateEquip2.itemsSource}
        height={250}
        initialized={onInitializedEquip2}
      />
      {/*담당자 팝업*/}
      {popup && (
        <GatingContListPopUp
          open={popup}
          // rowId={rowId}
          title={contPopTitle}
          setReadonly={true}
          initParam={initUserId}
          close={() => setPopup(false)}
        />
      )}
    </>
  );
});

GatingJudgeResultReqPopUp.displayName = 'GatingJudgeResultReqPopUp';
export default GatingJudgeResultReqPopUp;
