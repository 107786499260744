/** @jsxImportSource @emotion/react */

import { getDepartments } from 'apis/admin/Department';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useCallback, useRef, useState } from 'react';
import { Department } from 'models/admin/Department';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';

import {
  BlueButton,
  GreyButton,
  IconButton,
  GreyLineButton,
} from 'components/buttons/CustomButton';
import { CustomInputText } from 'components/inputs/CustomInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { ContentSection, GridInfoSection, GridInfo } from 'components/layouts/ContentSection';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { SearchBox, SearchButtons, SearchRows } from 'components/layouts/SearchBox';
import { di } from 'components/layouts/Dialog';
import { useMessageBar } from 'hooks/useMessageBar';
import { CrudCode } from 'models/common/Edit';

type DepartmentModalProps = {
  open: boolean;
  close: () => void;
  save: (item: Department) => void;
};

const emptyDepartment: Department = {
  deptCd: '',
  copCd: '',
  deptNm: '',
  deptEngNm: '',
  temLdrUserId: '',
  upprDeptCd: '',
  useYn: '',
  deptCngNm: '',
};

const DepartmentModal = (props: DepartmentModalProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact<Department>>(null);
  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<Department>(emptyDepartment);
  const [searchItem, setSearchItem] = useState<string>('');
  const [gridHeight, setGridHeight] = useState<string>('100%');
  const { openMessageBar } = useMessageBar();

  const columns: ColDef[] = [
    {
      headerName: 'No',
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        return params.node.rowIndex + 1;
      },
    },
    {
      headerCheckboxSelection: false,
      width: 50,
      checkboxSelection: true,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'deptCd',
      headerName: String(t('com.label.부서코드', '부서코드')),
      width: 150,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'copCd',
      headerName: String(t('com.label.법인 코드', '법인 코드')),
      width: 150,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'deptNm',
      headerName: String(t('com.label.부서명', '부서명')),
      flex: 1,
    },
  ];

  const getDepartmentList = useCallback(async () => {
    const response = await getDepartments(searchItem);
    setDepartmentList(response.departmentList);
  }, [searchItem]);

  const handleClose = () => {
    setDepartmentList([]);
    props.close();
  };
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(e.target.value);
  };

  const handleOnSelectionChange = useCallback(() => {
    const selectedRows = gridRef.current?.api.getSelectedRows();
    if (selectedRows && selectedRows.length > 0) {
      setSelectedDepartment(selectedRows[0]);
    } else {
      setSelectedDepartment(emptyDepartment);
    }
  }, []);

  const handleSearch = useCallback(() => {
    getDepartmentList();
    setGridHeight('400px');
  }, [searchItem]);

  const handleOnSave = () => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    if (selectedRows.length === 0) {
      openMessageBar({
        type: 'error',
        content: t('com.label.한 부서 이상 선택해야 합니다.', '한 부서 이상 선택해야 합니다.'),
      });
      return;
    }
    props.save(selectedDepartment);
    props.close();
    setDepartmentList([]);
  };

  return (
    <Dialog open={props.open} onClose={handleClose} css={di.dialog} fullWidth maxWidth="lg">
      <DialogTitle className="popupTitle">
        {t('com.label.부서목록 검색', '부서목록 검색')}
        <IconButton className="buttonClose" onClick={handleClose}>
          <CloseIcon fontSize="large"></CloseIcon>
        </IconButton>
      </DialogTitle>

      <DialogContent className="popupContent">
        <SearchBox>
          <SearchRows>
            <div className="searchCol fullWidth">
              <label> {t('com.label.검색어', '검색어')}</label>
              <CustomInputText onChange={handleOnChange}></CustomInputText>
            </div>
          </SearchRows>
          <SearchButtons>
            <GreyButton className="buttonSrch" onClick={handleSearch}>
              <SearchIcon />
              {t('com.button.조회', '조회')}
            </GreyButton>
          </SearchButtons>
        </SearchBox>
        <ContentSection className="marginT0">
          <GridInfoSection>
            <GridInfo>
              <span>{t('com.label.부서', '부서')}</span>
              <span className="primary">
                {departmentList ? departmentList.length : 0} {t('com.label.건', '건')}
              </span>
            </GridInfo>
          </GridInfoSection>
          <ContentGrid className="ag-theme-alpine" style={{ height: '400px' }}>
            <AgGridReact
              overlayNoRowsTemplate="No rows to show"
              ref={gridRef}
              rowData={departmentList}
              columnDefs={columns}
              suppressRowClickSelection={true}
              onSelectionChanged={handleOnSelectionChange}
            />
          </ContentGrid>
        </ContentSection>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GreyLineButton onClick={handleClose}>{t('com.button.취소', '취소')}</GreyLineButton>
        <BlueButton onClick={handleOnSave}>{t('com.button.확인', '확인')}</BlueButton>
      </DialogActions>
    </Dialog>
  );
};

export default DepartmentModal;
