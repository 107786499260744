/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { UseToggle } from 'components/inputs/CustomInput';

interface Props {
  id: string;
  name?: string; // default: id
  onValue: string; // 스위치 on 값
  onLabel?: string; // 스위치 on 문구
  offValue: string; // 스위치 off 값
  offLabel?: string; // 스위치 off 문구
  defaultValue?: string; // 기본값 (default: onValue)
  isSmall?: boolean; // small switch 여부 (default: false)
  onChange: (value: string) => void; // 변경이벤트 (선택된 값 반환)
}

/**
 * CustomSwitch 컴포넌트
 *
 * 2가지 선택사항이 있을 경우 사용 (ex. 조회조건 > 사용/미사용)
 * @param id
 * @param name
 * @param onValue 스위치 on 값
 * @param onLabel 스위치 on 문구
 * @param offValue 스위치 off 값
 * @param offLabel 스위치 off 문구
 * @param defaultValue 기본값 (default: onValue)
 * @param onChange 변경이벤트 (선택된 값 반환)
 * @constructor
 */
const CustomChip = ({
  id,
  name = id,
  onValue,
  onLabel = '',
  offValue,
  offLabel = '',
  defaultValue,
  isSmall = false,
  onChange,
}: Props) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    // 초기값이 없는 경우 onValue를 초기값으로 사용
    const value = defaultValue || onValue;
    // 초기화 시 값 변경을 위해 추가
    setCurrentValue(value);
    if (!defaultValue) {
      onChange(value);
    }
  }, [defaultValue]);

  /**
   * 값 변경 이벤트
   * @param e
   */
  const handleChange = () => {
    const value = currentValue === onValue ? offValue : onValue;
    setCurrentValue(value);
    onChange(value);
  };

  return (
    <>
      {isSmall ? (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch size="small" onChange={handleChange} checked={currentValue === onValue} />
            }
            label={onLabel || offLabel || ''}
          />
        </FormGroup>
      ) : (
        <UseToggle className="switch">
          <input
            type="checkbox"
            id={id}
            name={name}
            value={currentValue}
            checked={currentValue === onValue}
            onChange={handleChange}
          />
          <span className="slider"></span>
          <span className="labels" data-on={onLabel} data-off={offLabel}></span>
        </UseToggle>
      )}
    </>
  );
};

export default CustomChip;
