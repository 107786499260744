/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useMemo } from 'react';
import { Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CustomSwitch from 'components/selects/CustomSwitch';
import { IconButton } from 'components/buttons/IconSVG';
import { GuideBlock } from '../GuideComponent';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import { BizName, FileTypeName } from 'models/common/Common';
import Dropzone from 'components/dropzone/Dropzone';
import { ComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';

const FileUploadGuide = () => {
  const bizNames = useMemo(
    () =>
      Object.keys(BizName).reduce(
        (acc, cur) => [...acc, { cmnCd: BizName[cur], cmnCdNm: cur }],
        [] as Code[]
      ),
    [BizName]
  );
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const [atchFileGrId, setAtchFileGrId] = useState<string>('');
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({
    singleSelect: 'N',
    downloadOnly: 'N',
    bizName: BizName.ETC,
    atchFileTpCd: FileTypeName.NORMAL,
  });
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);

  const [atchFileTpCdList, setAtchFileTpCdList] = useState<Code[]>([]);

  useEffect(() => {
    getCommonCodeNames('ATCH_FILE_TP_CD').then((value) => setAtchFileTpCdList(value));
  }, []);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAtchFileGrId(e.target.value);
  };

  const handleFileUploadModalCondition = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileUploadModalCondition({ ...fileUploadModalCondition, [e.target.name]: e.target.value });
  };

  return (
    <>
      <GuideBlock title={'<FileUploadPopUp> Import'} isCode={true}>
        {`
// 업무, 파일유형
import { BizName, FileTypeName } from 'models/common/Common';

import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
`}
      </GuideBlock>
      <GuideBlock title={'<FileUploadPopUp> 속성'} isCode={true}>
        {`
interface Props {
  open: boolean;
  close: () => void;
  initParam: {
    atchFileGrId?: string; // 첨부파일 그룹ID
    atchFileTpCd: string; // 첨부파일 유형
    optValCtn1: string; // 관련 테이블 명 (확인용) 
    bizName?: BizName; // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
  };
  downloadOnly?: boolean; // 다운로드 팝업여부 (default: false)
  singleSelect?: boolean; // 단건 선택여부 (default: false)
  showAllDownload?: boolean; // 전체다운로드 버튼 노출여부 (default: true)
  onCallback: (atchFileGrId: string | null, fileCount: number) => void;
  isRequire?: boolean; // 필수여부체크 (default: false)
};
        `}
      </GuideBlock>
      <AccodianWrap className="marginTop">
        <Accordion expanded={true}>
          <AccordionSummary>FileUploadPopUp</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <SearchBox>
                <SearchBoxRow>
                  <InputBox>
                    <SearchRows>
                      <SearchCols>
                        <label>
                          <span>그룹ID</span>
                        </label>
                        <CustomInputWithSearch
                          name="atchFileGrId"
                          placeholder="atchFileGrId"
                          value={atchFileGrId}
                          onChange={onInputChange}
                        />
                      </SearchCols>
                      <SearchCols>
                        <label>
                          <span>유형</span>
                        </label>
                        <ComboBox
                          options={atchFileTpCdList.filter((o) => !o.cmnCd?.startsWith('TPL_'))}
                          defaultValue={fileUploadModalCondition.atchFileTpCd}
                          onChange={(value: string) =>
                            setFileUploadModalCondition({
                              ...fileUploadModalCondition,
                              atchFileTpCd: value,
                            })
                          }
                        />
                      </SearchCols>
                      <SearchCols>
                        <label>
                          <span>tableName</span>
                        </label>
                        <CustomInputWithSearch
                          name="tableName"
                          placeholder="업무 테이블명(확인용)"
                          value={fileUploadModalCondition.tableName}
                          onChange={handleFileUploadModalCondition}
                        />
                      </SearchCols>
                    </SearchRows>
                    <SearchRows>
                      <SearchCols>
                        <label>
                          <span>업무</span>
                        </label>
                        <ComboBox
                          options={bizNames}
                          defaultValue={fileUploadModalCondition.bizName}
                          onChange={(value: string) =>
                            setFileUploadModalCondition({
                              ...fileUploadModalCondition,
                              bizName: value,
                            })
                          }
                        />
                      </SearchCols>
                      <SearchCols>
                        <label htmlFor="singleSelect">1건/N건</label>
                        <CustomSwitch
                          id={'downloadOnly'}
                          onValue={'Y'}
                          onLabel={'1건'}
                          offValue={'N'}
                          offLabel={'N건'}
                          defaultValue={fileUploadModalCondition.singleSelect}
                          onChange={(value) => {
                            setFileUploadModalCondition((prev) => ({
                              ...prev,
                              singleSelect: value,
                            }));
                          }}
                        />
                      </SearchCols>
                      <SearchCols>
                        <label htmlFor="downloadOnly">D/U</label>
                        <CustomSwitch
                          id={'downloadOnly'}
                          onValue={'Y'}
                          onLabel={'다운로드'}
                          offValue={'N'}
                          offLabel={'업로드'}
                          defaultValue={fileUploadModalCondition.downloadOnly}
                          onChange={(value) => {
                            setFileUploadModalCondition((prev) => ({
                              ...prev,
                              downloadOnly: value,
                            }));
                          }}
                        />
                      </SearchCols>
                    </SearchRows>
                  </InputBox>
                  <SearchButtonWrap>
                    <Button
                      css={IconButton.button}
                      className="find"
                      onClick={() => {
                        setOpenFileUploadModal(true);
                      }}
                      disableRipple
                    >
                      팝업열기
                    </Button>
                  </SearchButtonWrap>
                </SearchBoxRow>
              </SearchBox>
            </GuideBlock>

            <GuideBlock isCode={true}>
              {`
<FileUploadPopUp
  open={isOpenFileUploadModal}
  close={() => setOpenFileUploadModal(false)}
  initParam={{
    atchFileGrId: atchFileGrId, // 파일그룹아이디, 
    atchFileTpCd: FileTypeName.NORMAL, // 파일그룹아이디 (각 업무에서 정의한 값으로 사용, 없는 경우 NORMAL)
    optValCtn1: 'TB_ELM_SAMPLE', // 관련 테이블명 (확인용), 
    bizName: BizName.IP, // 파일저장경로 (업무명으로 사용, 없는 경우 etc)
  }}
  singleSelect={false} // 단일, 다중
  downloadOnly={false} // 다운로드 전용으로 열기
  showAllDownload={true} // 전체 다운로드 기능 제공
  onCallback={(value) => {
    if (value) {
      setAtchFileGrId(value); 
    }
  }}
  isRequire={false} // 파일 필수 첨부 체크 (설정 값이 true인 경우 파일이 없는 상태로 저장할 시 메시지 표출) 
            `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>

      <br />
      <br />

      <GuideBlock title={'<Dropzone> Import'} isCode={true}>
        {`
// 업무, 파일유형
// import { BizName, FileTypeName } from 'models/common/Common';

import Dropzone from 'components/dropzone/Dropzone';
`}
      </GuideBlock>
      <GuideBlock title={'<FileUploadPopUp> 속성'} isCode={true}>
        {`
interface DropzoneProps {
  atchFileGrIdInput?: string; // 첨부파일 그룹ID
  acceptExtensions?: Accept; // 파일 확장자
  newGrId?: boolean; // 미사용
  atchFileTpCd?: string; // 첨부파일유형
  downloadOnly?: boolean; // 다운로드만 사용여부
  maxSize?: number; // 최대 첨부용량 (default: FileConst.maxSize)
  singleSelect?: boolean; // 단건 선택여부 (default: false)
  tableName?: string; // 관련 테이블 명 (확인용)
  bizName?: BizName; // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
  onChange?: (files: FileInfo[]) => void;
  errorMsg?: string;
}
        `}
      </GuideBlock>
      <AccodianWrap className="marginTop">
        <Accordion expanded={true}>
          <AccordionSummary>Dropzone</AccordionSummary>
          <AccordionDetails>
            <SearchBox>
              <SearchBoxRow>
                <InputBox>
                  <SearchRows>
                    <SearchCols>
                      <label>
                        <span>그룹ID</span>
                      </label>
                      <CustomInputWithSearch
                        name="atchFileGrId"
                        placeholder="atchFileGrId"
                        value={atchFileGrId}
                        onChange={onInputChange}
                      />
                    </SearchCols>
                    <SearchCols>
                      <label>
                        <span>유형</span>
                      </label>
                      <ComboBox
                        options={atchFileTpCdList.filter((o) => !o.cmnCd?.startsWith('TPL_'))}
                        defaultValue={'NORMAL'}
                        onChange={(value: string) =>
                          setFileUploadModalCondition({
                            ...fileUploadModalCondition,
                            atchFileTpCd: value,
                          })
                        }
                      />
                    </SearchCols>
                    <SearchCols>
                      <label>
                        <span className="dot">tableName</span>
                      </label>
                      <CustomInputWithSearch
                        name="tableName"
                        placeholder="업무 테이블명(확인용)"
                        value={fileUploadModalCondition.tableName}
                        onChange={handleFileUploadModalCondition}
                      />
                    </SearchCols>
                  </SearchRows>
                  <SearchRows>
                    <SearchCols>
                      <label>
                        <span>업무</span>
                      </label>
                      <ComboBox
                        options={bizNames}
                        defaultValue={fileUploadModalCondition.bizName}
                        onChange={(value: string) =>
                          setFileUploadModalCondition({
                            ...fileUploadModalCondition,
                            bizName: value,
                          })
                        }
                      />
                    </SearchCols>
                    <SearchCols>
                      <label htmlFor="singleSelect">1건/N건</label>
                      <CustomSwitch
                        id={'downloadOnly'}
                        onValue={'Y'}
                        onLabel={'1건'}
                        offValue={'N'}
                        offLabel={'N건'}
                        defaultValue={fileUploadModalCondition.singleSelect}
                        onChange={(value) => {
                          setFileUploadModalCondition((prev) => ({
                            ...prev,
                            singleSelect: value,
                          }));
                        }}
                      />
                    </SearchCols>
                    <SearchCols>
                      <label htmlFor="downloadOnly">D/U</label>
                      <CustomSwitch
                        id={'downloadOnly'}
                        onValue={'Y'}
                        onLabel={'다운로드'}
                        offValue={'N'}
                        offLabel={'업로드'}
                        defaultValue={fileUploadModalCondition.downloadOnly}
                        onChange={(value) => {
                          setFileUploadModalCondition((prev) => ({
                            ...prev,
                            downloadOnly: value,
                          }));
                        }}
                      />
                    </SearchCols>
                  </SearchRows>
                </InputBox>
              </SearchBoxRow>
            </SearchBox>
            <GuideBlock>
              <Dropzone
                atchFileGrIdInput={atchFileGrId}
                atchFileTpCd={fileUploadModalCondition.atchFileTpCd}
                tableName={fileUploadModalCondition.tableName}
                bizName={BizName.ETC}
                singleSelect={fileUploadModalCondition.singleSelect === 'Y'}
                downloadOnly={fileUploadModalCondition.downloadOnly === 'Y'}
                onChange={(files) => {
                  console.log(files);
                }}
              />
            </GuideBlock>

            <GuideBlock isCode={true}>
              {`
<Dropzone
  atchFileGrIdInput={atchFileGrId} // 첨부파일 그룹ID
  atchFileTpCd={fileUploadModalCondition.atchFileTpCd} // 첨부파일유형
  tableName={fileUploadModalCondition.tableName} // 관련 테이블명
  bizName={fileUploadModalCondition.bizName} // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
  singleSelect={fileUploadModalCondition.singleSelect === 'Y'}
  downloadOnly={fileUploadModalCondition.downloadOnly === 'Y'}
  onChange={(files) => {
    console.log(files);
  }}
/>
            `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>

      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          initParam={{
            atchFileGrId: atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          singleSelect={fileUploadModalCondition.singleSelect === 'Y'}
          downloadOnly={fileUploadModalCondition.downloadOnly === 'Y'}
          showAllDownload={true}
          onCallback={(value) => {
            if (value) {
              setAtchFileGrId(value);
            }
          }}
          isRequire={false}
        />
      )}
    </>
  );
};

export default FileUploadGuide;
