/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { ApproveRequestDetail } from 'models/approves/ReferenceOpinion';
import { tb } from 'components/layouts/Table';
import { useEffect, useState } from 'react';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { getApprovalTemplate } from 'apis/admin/Approval';
import { ApprovalTemplate, aprTplNm } from 'models/admin/ApprovalTemplate';

type propsType = {
  approveInfo: ApproveRequestDetail;
};

const ApproveDetailBasicInfo = ({ approveInfo }: propsType) => {
  const { t } = useTranslation();

  return (
    <TableContainer css={tb.table}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>{t('com.label.요청 ID', '요청 ID')}</TableCell>
            <TableCell className="w500">{approveInfo.aprReqId}</TableCell>
            <TableCell>{t('com.label.진행상태', '진행상태')}</TableCell>
            <TableCell className="w500">{approveInfo.aprReqProgStatNm}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('com.label.작성자', '작성자')}</TableCell>
            <TableCell className="w500">{approveInfo.aprReqUserNm}</TableCell>
            <TableCell>{t('com.label.결재요청양식', '요청양식')}</TableCell>
            <TableCell className="w500">{approveInfo.aprTplNm}</TableCell>
            {/*
            // TODO [24.03.22] 전자결재 시 일반문서(0) 고정으로 사용 (따로 저장하지 않음)
            <TableCell>{t('com.label.문서종류', '문서종류')}</TableCell>
            <TableCell className="w500">{approveInfo.apprSecurityType}</TableCell>
            */}
          </TableRow>
          <TableRow>
            <TableCell>{t('com.label.작성일시', '작성일시')}</TableCell>
            <TableCell className="w500">{approveInfo.aprReqDtm ?? ''}</TableCell>
            <TableCell>{t('com.label.보존년한', '보존년한')}</TableCell>
            <TableCell className="w500">{approveInfo.aprPvnDdlnNm}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('com.label.제목', '제목')}</TableCell>
            <TableCell colSpan={3}>{approveInfo.aprReqTitNm}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('com.label.요청내용', '요청내용')}</TableCell>
            <TableCell colSpan={3}>
              {approveInfo.trnmBdyCtn
                ? approveInfo.trnmBdyCtn.split('\n').map((line) => (
                    <>
                      {line}
                      <br />
                    </>
                  ))
                : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApproveDetailBasicInfo;
