import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import { SearchParam, UtMatrixDetailList, UtMatrixMasterList } from 'models/ut/UtMatrixList';
import { callApi } from 'utils/ApiUtil';

export const getUtMatrixDetailProcessList = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/detail-process`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<UtMatrixMasterList[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as UtMatrixMasterList[];
};

/**
 * 라인 설정 복사
 * @param itemId
 */
export const copyUtMatrixDetailProcess = async (
  copyUtmId?: string,
  copyPlanProcId?: string,
  utmId?: string,
  planProcId?: string
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ut/detail-process/copy`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: {
      copyUtmId: copyUtmId,
      copyPlanProcId: copyPlanProcId,
      utmId: utmId,
      planProcId: planProcId,
    },
  };

  const response: CommonResponse = await callApi(request);
  return response;
};
