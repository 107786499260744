import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { MpResult, MpResultCondition } from 'models/mp/MpResult';
import { Code } from 'models/common/CommonCode';
import { Line } from 'models/common/Line';
import { MpItem } from 'models/mp/MpItem';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { downloadFile } from 'apis/common/Excel';

/**
 * 결과 목록 조회
 * @param itemId
 * @param itemTpCd
 * @param copCd
 * @param plntCd
 * @param lnId
 */
export const findMpResults = async (
  itemId: string,
  itemTpCd: string,
  copCd: string,
  plntCd: string,
  lnId?: string
) => {
  const condition = {
    itemId,
    itemTpCd,
    copCd,
    plntCd,
    lnId,
  } as MpResultCondition;

  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/${condition?.itemId}/result`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<MpResult[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MpResult[];
};

/**
 * 결과 목록 조회 (validation 체크용)
 * @param itemId
 * @param itemTpCd
 */
export const findMpResultsForValid = async (itemId: string, itemTpCd: string) => {
  const condition = {
    itemId,
    itemTpCd,
  } as MpResultCondition;

  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/${condition?.itemId}/result`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<MpResult[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as MpResult[];
};

/**
 * 결과정보 저장
 * @param mpItem
 * @param resultList
 */
export const saveMpResult = async (mpItem: MpItem, resultList: MpResult[]) => {
  const condition = {
    ...mpItem,
    resultList: resultList,
  } as MpItem;

  const request: CommonRequest = {
    method: Method.PUT,
    url: `/v1/mp/${condition?.itemId}/result`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<string> = await callApi(request);
  return response;
};

/**
 * 공장(동) 목록 조회
 * @param itemId
 * @param copCd
 * @param itemTpChoCd
 */
export const findFactory = async (itemId: string, copCd: string, itemTpChoCd: string) => {
  const condition = {
    itemId: itemId,
    copCd: copCd,
    itemTpChoCd: itemTpChoCd,
  };

  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/${itemId}/factory`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<Code[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as Code[];
};

/**
 * 라인 목록 조회
 * @param copCd
 * @param plntCd
 */
export const findLine = async (copCd: string, plntCd: string) => {
  const condition = {
    copCd: copCd,
    plntCd: plntCd,
  };

  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/mp/line`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<Line[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as Line[];
};

/**
 * 첨부파일 그룹ID 생성
 * @param execPlanId
 */
export const generateAtchFileGrId = async (execPlanId: string) => {
  const condition = {
    execPlanId: execPlanId,
  };

  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/result/${execPlanId}/attach`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<string> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * 검토 대상 목록 엑셀 다운로드
 * @param condition
 */
export const downloadExcelResult = async (condition: ExcelDownloadRequest<MpResultCondition>) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/mp/result/excel-download`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, `${condition.fileName}`);
};
