/* eslint-disable prettier/prettier */
/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DatePickerWrap } from 'components/inputs/DatePicker';
import Datepicker from 'react-tailwindcss-datepicker';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import { ContentGrid } from 'components/layouts/ContentGrid';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';
import { IconButton } from 'components/buttons/IconSVG';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { getExcelFileName } from 'utils/ExcelUtil';
import { FileTypeName, ManagementMode } from 'models/common/Common';
import { downloadExcelTemplates } from 'apis/common/Excel';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { hasRole } from 'utils/SessionUtil';
import { SensorCondition, Sensor } from 'models/sensor/Sensor';
import { AgGridReact } from 'ag-grid-react';
import { TooltipComponent, TooltipValueGetter } from 'components/grids/Tooltip';
import { findSensorList, getSensorListDownload, setSensorFile } from 'apis/sensor/Sensor';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { ComboBox } from 'components/selects/ComboBox';
import { deleteSensorRequest } from 'apis/sensor/popup/SensorReq';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import useSessionStore from 'stores/useSessionStore';
import { UseToggle } from 'components/inputs/CustomInput';
import { InputDate } from '@grapecity/wijmo.input';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';

interface SensorPageLocationState {
  snsrMgtNo?: string;
  snsrNm?: string;
  useYn?: string;
  condition?: any;
  pageNo?: number;
}

interface DisplayCondition {
  dataInsUserNm?: string; // 등록자명
}

export const SensorListPage = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();
  const { gridNoRowsTemplate } = useSessionStore();
  const gridRef = useRef<AgGridReact<Sensor>>(null);
  const [sensorCondition, setSensorCondition] = useState<SensorCondition>({
    useYn: 'Y',
  });
  const locationState: SensorPageLocationState = useLocation().state;
  const [userData, setUserData] = useState<any>('');
  const [isOpenElmEmpPopup, setOpenElmEmpPopup] = useState<boolean>(false);
  const [empPopupCondition, setEmpPopupCondition] = useState({
    fieldId: '',
    initParam: '',
  });
  const [rowData, setRowData] = useState<Sensor[]>([]);
  const [displayCondition, setDisplayCondition] = useState<DisplayCondition>({});
  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  const [mode, setMode] = useState<ManagementMode>(ManagementMode.CREATE);
  const [hasAuth, setHasAuth] = useState<boolean>(false);
  const [code, setCode] = useState<any>({});
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});

  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [isOpenElmEmp, setOpenElmEmp] = useState<boolean>(false);
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    getSnsrLv2Code(sensorCondition?.snsrLv1Cd);
  }, [sensorCondition?.snsrLv1Cd]);

  useEffect(() => {
    getSnsrLv3Code(sensorCondition?.snsrLv2Cd);
  }, [sensorCondition?.snsrLv2Cd]);

  const getCommonCodes = async () => {
    const snsrLv1Cd = await getCommonCodeNames('SNSR_LV1_CD');

    setCode({
      snsrLv1Cd: snsrLv1Cd,
    });
  };

  const handleChange = (name: string, value: string) => {
    setSensorCondition({
      ...sensorCondition,
      [name]: value,
    });
  };

  //등록일자 일자(YYYY.MM.DD)까지만 조회
  const dateValueFommater = (params: any) => {
    const date = params.value;
    if (date != null && date != undefined) {
      const subStrDate = date.substr(0, 10);
      const formmattedDate = subStrDate;
      return formmattedDate;
    }
  };

  const handleDisplayConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const name = e.target.name;
    setDisplayCondition({
      ...displayCondition,
      [name]: e.target.value,
    });

    setSensorCondition({
      ...sensorCondition,
      [name.replace('Nm', 'Id')]: '',
    });
  };

  useEffect(() => {
    if (userData != null && userData != '') {
      const fieldId = userData.fieldId;
      const userInfo = userData.userInfo[0] || {};
      if (fieldId) {
        setSensorCondition({
          ...sensorCondition,
          [fieldId.replace('Nm', 'Id')]: userInfo.userId,
        });
        setDisplayCondition({
          ...displayCondition,
          [fieldId]: userInfo.empNm,
        });
      }
    }
  }, [userData]);

  const handleDownloadExcel = () => {
    const snsrClassification = String(t('snsr.label.센서 분류', '센서 분류'));

    const excelData: ExcelDownloadRequest<SensorCondition> = {
      fileName: getExcelFileName(t('snsr.label.센서 조회', '센서 조회')), // + '.xlsx',
      sheetName: t('snsr.label.센서 조회', '센서 조회'),
      header: [
        `${snsrClassification} ${String(t('snsr.grid.센서레벨1', '센서레벨1'))}`,
        `${snsrClassification} ${String(t('snsr.grid.센서레벨2', '센서레벨2'))}`,
        `${snsrClassification} ${String(t('snsr.grid.센서레벨3', '센서레벨3'))}`,
        String(t('snsr.label.센서관리번호', '센서관리번호')),
        String(t('snsr.grid.센서명', '센서명')),
        String(t('snsr.grid.센서 Maker', '센서 Maker')),
        String(t('snsr.grid.IO 링크', 'IO 링크')),
        String(t('snsr.grid.방폭', '방폭')),
        String(t('snsr.grid.IP 등급', 'IP 등급')),
        String(t('snsr.grid.국제인증', '국제인증')),
        String(t('snsr.grid.안전인증', '안전인증')),
        String(t('snsr.grid.Scan/Sample주파수', 'Scan/Sample주파수')),
        String(t('snsr.grid.통신 종류', '통신 종류')),
        String(t('snsr.grid.통신 주기(주파수)', '통신 주기(주파수)')),
        String(t('snsr.grid.구동전원', '구동전원')),
        String(t('snsr.grid.분해능', '분해능')),
        String(t('snsr.grid.응답시간', '응답시간')),
        String(t('snsr.grid.배선사양', '배선사양')),
        String(t('snsr.grid.커넥터사양', '커넥터사양')),
        String(t('snsr.grid.구동온도', '구동온도')),
        String(t('snsr.grid.구동습도', '구동습도')),
        String(t('snsr.grid.감도조절', '감도조절')),
        String(t('snsr.grid.표시등', '표시등')),
        String(t('snsr.grid.관리파라미터1', '관리파라미터1')),
        String(t('snsr.grid.관리파라미터2', '관리파라미터2')),
        String(t('snsr.grid.관리파라미터3', '관리파라미터3')),
        String(t('snsr.grid.관리파라미터4', '관리파라미터4')),
        String(t('snsr.grid.관리파라미터5', '관리파라미터5')),
        // String(t('com.label.첨부', '첨부')),
        String(t('com.label.등록자', '등록자')),
        String(t('com.label.등록일', '등록일자')),
      ],
      searchCondition: sensorCondition,
    };
    getSensorListDownload(excelData);
  };

  const searchBtn = async () => {
    const result = await findSensorList(sensorCondition);
    setRowData(result);
  };

  const handleResetCondition = () => {
    setSensorCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as SensorCondition
    );

    setDisplayCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as DisplayCondition
    );
  };

  const getSnsrLv2Code = async (value) => {
    // 센서 레벨3 코드 목록 초기화
    setCode((prev) => ({
      ...prev,
      snsrLv3Cd: [],
    }));

    // 검색조건 초기화
    setSensorCondition((prev) => ({
      ...prev,
      snsrLv2Cd: '',
      snsrLv3Cd: '',
    }));

    if (value) {
      const snsrLv2Cd = await getCommonCodeNamesCondition({
        optValCtn1: value,
        cmnGrCd: 'SNSR_LV2_CD',
      });

      setCode((prev) => ({
        ...prev,
        snsrLv2Cd: snsrLv2Cd,
      }));
    }
  };

  const getSnsrLv3Code = async (value) => {
    // 센서 레벨3 코드값 조기화
    setSensorCondition((prev) => ({
      ...prev,
      snsrLv3Cd: '',
    }));

    if (value) {
      const snsrLv3Cd = await getCommonCodeNamesCondition({
        optValCtn1: value,
        cmnGrCd: 'SNSR_LV3_CD',
      });
      setCode((prev) => ({
        ...prev,
        snsrLv3Cd: snsrLv3Cd,
      }));
    }
  };

  const downloadExcel = () => {
    const rows = flexRef.selectedRows;
    const id = (rows || [])
      .reduce((acc, cur) => [...acc, cur.dataItem.snsrMgtNo as string], [] as string[])
      .join();
    downloadExcelTemplates(FileTypeName.TPL_SNSR_LIST, id);
  };

  // const handleFileUpload = (params: ICellRendererParams) => {
  //   const popUpOpen = (cell) => {
  //     setFileUploadModalCondition({
  //       atchFileGrId: cell.node.data.atchFileGrId,
  //       atchFileTpCd: 'NORMAL',
  //       optValCtn1: 'TB_ELM_SNSR_M', // 관련 테이블 명 (확인용)
  //       bizName: 'snsr', // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
  //       target: cell,
  //     });
  //     setOpenFileUploadModal(true);
  //   };
  //   return (
  //     <>
  //       <div className="fileDiv">
  //         <span onClick={() => popUpOpen(params)}>
  //           <em>{(params.value || 0).toLocaleString()}</em>
  //         </span>
  //       </div>
  //     </>
  //   );
  // };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchBtn();
    }
  };

  const handleOpenEmpPopup = () => {
    setEmpPopupCondition({
      fieldId: 'dataInsUserNm',
      initParam: displayCondition.dataInsUserNm || '',
    });
    setOpenElmEmpPopup(true);
  };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) {
      return;
    }

    s.refresh();
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setflexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.ListBox;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);
      // console.log(ht.row, ht.com);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;
      grid.refresh();

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);
    });
    //console.log(col, col.binding, grid.rows[ht.row]);

    //   if (e.target instanceof HTMLButtonElement) {
    //     switch (e.target.id) {
    //       case 'btnEmp':
    //         setOpenElmEmp(true);
    //         break;
    //     }
    //   }

    //   switch (ht.panel.columns[ht.col].binding) {
    //     case 'H': {
    //       setOpenCellTextarea(true);
    //       break;
    //     }
    //   }
    // });

    // grid.hostElement.addEventListener('dblclick', (e) => {
    //   const len = grid.rows.length;
    //   if (len == 0) return;

    //   const ht = grid.hitTest(e);
    //   if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.
    // });
  };

  /* Ag-grid Tooltip */
  // const defaultColumnDefs: ColDef = {
  //   cellStyle: { textAlign: 'center' },
  //   resizable: true,
  //   filter: true,
  //   tooltipComponent: TooltipComponent,
  //   tooltipValueGetter: TooltipValueGetter,
  // };

  const LayoutDefinition = () => {
    return [
      {
        header: String(t('snsr.label.센서 분류', '센서분류')),
        align: 'center',
        // collapseTo: 'satPlanStartDate',
        columns: [
          {
            binding: 'snsrLv1Nm',
            header: String(t('snsr.grid.Lv1', 'Lv1')),
            align: 'left',
            width: 150,
            allowMerging: true,
          },
          {
            binding: 'snsrLv2Nm',
            header: String(t('snsr.grid.Lv2', 'Lv2')),
            align: 'left',
            allowMerging: true,
            width: 150,
          },
          {
            binding: 'snsrLv3Nm',
            header: String(t('snsr.grid.Lv3', 'Lv3')),
            align: 'left',
            allowMerging: true,
            width: 150,
          },
        ],
      },
      {
        binding: 'snsrMgtNo',
        header: String(t('snsr.label.센서관리번호', '센서관리번호')),
        align: 'center',
        allowMerging: true,
        width: 130,
      },
      {
        binding: 'snsrNm',
        header: String(t('snsr.label.센서명', '센서명')),
        allowMerging: true,
        width: 140,
      },
      {
        binding: 'snsrMkrNm',
        header: String(t('snsr.grid.센서 Maker', '센서Maker')),
        align: 'left',
        allowMerging: true,
        width: 120,
      },
      {
        binding: 'ioLnkYn',
        header: String(t('snsr.grid.IO 링크', 'IO링크')),
        align: 'center',
        allowMerging: true,
        width: 100,
      },
      {
        binding: 'expvYn',
        header: String(t('snsr.grid.방폭', '방폭')),
        align: 'center',
        allowMerging: true,
        width: 100,
      },
      {
        binding: 'ipGrdCtn',
        header: String(t('snsr.grid.IP 등급', 'IP등급')),
        align: 'center',
        allowMerging: true,
        width: 100,
      },
      {
        binding: 'intnCertCtn',
        header: String(t('snsr.grid.국제인증', '국제인증')),
        align: 'center',
        allowMerging: true,
        width: 100,
      },
      {
        binding: 'safeCertCtn',
        header: String(t('snsr.grid.안전인증', '안전인증')),
        align: 'center',
        allowMerging: true,
        width: 100,
      },
      {
        binding: 'wvlCtn',
        header: String(t('snsr.grid.Scan/Sample주파수', 'Scan/Sample주파수')),
        align: 'center',
        allowMerging: true,
        width: 170,
      },
      {
        binding: 'cmcKndCtn',
        header: String(t('snsr.grid.통신 종류', '통신종류')),
        align: 'center',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'cmcPrdCtn',
        header: String(t('snsr.grid.통신 주기(주파수)', '통신주기(주파수)')),
        align: 'center',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'motnPwrCtn',
        header: String(t('snsr.grid.구동전원', '구동전원')),
        align: 'left',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'rslnCtn',
        align: 'center',
        header: String(t('snsr.grid.분해능', '분해능')),
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'rspnTm',
        header: String(t('snsr.grid.응답시간', '응답 시간')),
        align: 'left',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'wireSpecDesc',
        header: String(t('snsr.grid.배선사양', '배선사양')),
        align: 'center',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'cnntSpecDesc',
        header: String(t('snsr.grid.커넥터사양', '커넥터사양')),
        align: 'center',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'motnTmpr',
        header: String(t('snsr.grid.구동온도', '구동온도')),
        align: 'left',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'motnHmd',
        header: String(t('snsr.grid.구동습도', '구동 습도')),
        align: 'left',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'snstChgYn',
        header: String(t('snsr.grid.감도조절', '감도 조절')),
        align: 'center',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'lampYn',
        header: String(t('snsr.grid.표시등', '표시등')),
        align: 'center',
        allowMerging: true,
        width: 100,
      },
      {
        binding: 'mgtPram1Ctn',
        header: String(t('snsr.grid.관리파라미터1', '관리파라미터1')),
        align: 'center',
        allowMerging: true,
        width: 140,
      },
      {
        binding: 'mgtPram2Ctn',
        header: String(t('snsr.grid.관리파라미터2', '관리파라미터2')),
        align: 'center',
        allowMerging: true,
        width: 140,
      },
      {
        binding: 'mgtPram3Ctn',
        header: String(t('snsr.grid.관리파라미터3', '관리파라미터3')),
        align: 'center',
        allowMerging: true,
        width: 140,
      },
      {
        binding: 'mgtPram4Ctn',
        header: String(t('snsr.grid.관리파라미터4', '관리파라미터4')),
        align: 'center',
        allowMerging: true,
        width: 140,
      },
      {
        binding: 'mgtPram5Ctn',
        header: String(t('snsr.grid.관리파라미터5', '관리파라미터5')),
        align: 'center',
        allowMerging: true,
        width: 140,
      },
      {
        binding: 'atchFileCnt', // atchFileCnt
        header: String(t('com.label.첨부', '첨부')),
        align: 'center',
        allowMerging: true,
        width: 70,
        cellTemplate: CellMaker.makeLink({
          text: '${item.atchFileCnt}',
          click: (e, ctx) => {
            setFileUploadModalCondition({
              atchFileGrId: ctx.item.atchFileGrId, //node.data.atchFileGrId,
              atchFileTpCd: 'NORMAL',
              optValCtn1: 'TB_ELM_SNSR_M', // 관련 테이블 명 (확인용)
              bizName: 'snsr', // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
              target: ctx,
            });
            setOpenFileUploadModal(true);
          },
        }),
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('com.label.등록자', '등록자')),
        align: 'center',
        allowMerging: true,
        width: 110,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.등록일', '등록일자')),
        align: 'center',
        allowMerging: true,
        width: 140,
        cellTemplate: (params) => dateValueFommater(params),
        // editor: new InputDate(document.createElement('div')),
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols className="colspan31">
                <label>{t('snsr.label.센서 분류', '센서 분류')}</label>
                <ComboBox
                  placeholder={String(t('com.label.전체', '전체'))}
                  options={code?.snsrLv1Cd}
                  defaultValue={sensorCondition?.snsrLv1Cd}
                  onChange={(value) => handleChange('snsrLv1Cd', value)}
                />
                <ComboBox
                  placeholder={String(t('com.label.전체', '전체'))}
                  options={code?.snsrLv2Cd}
                  defaultValue={sensorCondition?.snsrLv2Cd}
                  onChange={(value) => handleChange('snsrLv2Cd', value)}
                />
                <ComboBox
                  placeholder={String(t('com.label.전체', '전체'))}
                  options={code?.snsrLv3Cd}
                  defaultValue={sensorCondition?.snsrLv3Cd}
                  onChange={(value) => handleChange('snsrLv3Cd', value)}
                />
              </SearchCols>
              <SearchCols className="width33">
                <label>{t('snsr.grid.센서 Maker', '센서 Maker')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="snsrMkrNm"
                  placeholder={String(
                    t('snsr.msg.센서 Maker를 입력해 주세요.', '센서 Maker를 입력해 주세요.')
                  )}
                  value={sensorCondition.snsrMkrNm}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols className="width33">
                <label>{t('com.label.등록일', '등록일')}</label>
                <DatePickerWrap className="range">
                  <Datepicker
                    inputClassName="datepickerInput"
                    value={{
                      startDate: sensorCondition?.startDate || null,
                      endDate: sensorCondition?.endDate || null,
                    }}
                    onChange={(value) => {
                      setSensorCondition({
                        ...sensorCondition,
                        startDate: String(value?.startDate || ''),
                        endDate: String(value?.endDate || ''),
                      });
                    }}
                    i18n={'ko'}
                    useRange={true}
                    placeholder="YYYY.MM.DD - YYYY.MM.DD"
                    displayFormat={'YYYY.MM.DD'}
                  />
                </DatePickerWrap>
              </SearchCols>
            </SearchRows>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>{t('snsr.label.센서관리번호', '센서관리번호')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="snsrMgtNo"
                  placeholder={String(
                    t('snsr.msg.센서관리번호를 입력해 주세요.', '센서관리번호를 입력해 주세요.')
                  )}
                  value={sensorCondition.snsrMgtNo}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols className="right">
                <label>{t('snsr.label.센서명', '센서명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="snsrNm"
                  placeholder={String(
                    t('snsr.msg.센서명을 입력해 주세요.', '센서명을 입력해 주세요.')
                  )}
                  value={sensorCondition.snsrNm}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label htmlFor="useYn">{t('com.label.사용여부', '사용여부')}</label>
                <UseToggle className="switch">
                  <input
                    type="checkbox"
                    id="useYn"
                    name="useYn"
                    value={sensorCondition.useYn}
                    checked={sensorCondition.useYn === 'Y'}
                    onChange={() => {
                      setSensorCondition({
                        ...sensorCondition,
                        useYn: sensorCondition.useYn === 'Y' ? 'N' : 'Y',
                      });
                    }}
                  />
                  <span className="slider"></span>
                  <span
                    className="labels"
                    data-on={t('com.label.사용', '사용')}
                    data-off={t('com.label.미사용', '미사용')}
                  ></span>
                </UseToggle>
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.등록자', '등록자')}</label>
                <CustomInputWithSearch
                  name="dataInsUserNm"
                  className="find"
                  placeholder={String(
                    t('com.label.등록자를 선택해 주세요.', '등록자를 선택해 주세요.')
                  )}
                  value={displayCondition.dataInsUserNm}
                  onChange={handleDisplayConditionChange}
                  onSearchClick={handleOpenEmpPopup}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      handleOpenEmpPopup();
                    }
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={searchBtn}>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.센서 List', '센서 List')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="Exceldown" onClick={downloadExcel}>
            {t('com.button.템플릿 다운로드', '템플릿 다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={handleDownloadExcel}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button css={IconButton.button} className="refresh" onClick={searchBtn} disableRipple>
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <ContentGrid
        className={`ag-theme-alpine ${(rowData || []).length < 1 ? 'noData' : ''}`}
        style={{ height: '500px' }}
      >
        <FlexGrid
          columns={state.layoutDefinition}
          itemsSource={state.itemsSource}
          allowPinning="ColumnRange" // 열 고정핀
          // frozenColumns={1}
          //headersVisibility="Column"
          //showSelectedHeaders="All"
          //showMarquee={true}
          selectionMode={'Row'}
          //alternatingRowStep={0}
          // allowMerging={true}
          allowMerging="ColumnHeaders"
          autoGenerateColumns={false}
          stickyHeaders={true}
          isReadOnly={true}
          formatItem={flexGridTooltip}
          selectionChanged={onFlexGridSelectionChanged}
          initialized={onInitialized}
          style={{ height: (rowData || []).length < 1 ? '' : '500px' }}
        />
        <div className="noData" style={{ height: (rowData || []).length < 1 ? '500px' : '' }}>
          <span>
            {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
          </span>
        </div>

        {/* <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColumnDefs}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          rowSelection="multiple"
          groupHeaderHeight={32}
          rowHeight={32}
          overlayNoRowsTemplate={gridNoRowsTemplate}
        /> */}
        <GlobalBtnGroup>
          <Button
            css={IconButton.button}
            className="write"
            disableRipple
            onClick={(e) => {
              setMode(ManagementMode.CREATE);
              navigate('/sensor/sensor-management-page', {
                replace: true,
                state: {
                  upprMnuUrl: location.pathname,
                  mnuId: location.pathname,
                  mnuNm: String(t('snsr.label.센서 등록/수정', '센서등록')),
                  mode: ManagementMode.CREATE_EXCEL,
                },
              });
            }}
          >
            {t('com.button.신규', '신규')}
          </Button>
          <Button
            css={IconButton.button}
            className="write"
            disableRipple
            onClick={(e) => {
              setMode(ManagementMode.MODIFY);
              navigate('/sensor/sensor-management-page', {
                replace: true,
                state: {
                  upprMnuUrl: location.pathname,
                  mnuId: location.pathname,
                  mnuNm: String(t('snsr.label.센서 등록/수정', '센서수정')),
                  mode: ManagementMode.MODIFY_EXCEL,
                },
              });
            }}
          >
            {t('com.button.수정', '수정')}
          </Button>
          <Button
            css={IconButton.button}
            className="delete"
            onClick={() => {
              // const selectedRowNodes = gridRef.current!.api.getSelectedNodes();
              const selectedRowNodes = flexRef.selectedItems;
              if (selectedRowNodes.length < 1) {
                openMessageBar({
                  type: 'error',
                  content: t(
                    'com.label.삭제할 대상을 선택해 주세요.',
                    '삭제할 대상을 선택해 주세요.'
                  ),
                });
                return;
              }

              openCommonModal({
                modalType: 'confirm',
                content: t(
                  'com.label.삭제하시겠습니까? 기존 작업 내용이 사라집니다.',
                  '삭제하시겠습니까? 기존 작업 내용이 사라집니다.'
                ),
                yesCallback: () => {
                  const row = [] as any;
                  selectedRowNodes.forEach((item) => {
                    row.push(item);
                  });

                  deleteSensorRequest({ sensorReqList: row }).then(() => {
                    searchBtn();
                  });
                },
              });
            }}
            disableRipple
          >
            {t('com.label.삭제', '삭제')}
          </Button>
        </GlobalBtnGroup>
      </ContentGrid>

      {isOpenElmEmpPopup && (
        <ElmEmpPopup
          setUserData={setUserData}
          setPopup={setOpenElmEmpPopup}
          close={() => setOpenElmEmpPopup(false)}
          initParam={empPopupCondition.initParam}
          fieldId={empPopupCondition.fieldId}
        />
      )}

      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          singleSelect={false}
          downloadOnly={false}
          initParam={fileUploadModalCondition}
          onCallback={async (atchFileGrId, fileCount) => {
            if (fileUploadModalCondition.target) {
              await setSensorFile(
                atchFileGrId || '',
                fileUploadModalCondition.target.item.snsrMgtNo || ''
              );
              await searchBtn();
            }
            setOpenFileUploadModal(false);
          }}
          isRequire={true}
        />
      )}
    </>
  );
};
export default SensorListPage;
