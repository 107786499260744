/** @jsxImportSource @emotion/react */
import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { di, TitleWrap } from 'components/layouts/Dialog';
import { ImgBox } from 'components/layouts/Board';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  close: () => void;
};

/**
 * Gating 생성/조회 화면 taskNamingRule 팝업
 * @param props
 * @constructor
 */
export const GatingTaskNamingRulePopUp = ({ open, close }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} css={di.dialog} fullWidth className="lg" maxWidth={false}>
      <TitleWrap>
        <h2>{t('gtng.label.Task명 Naming Rule', 'Task명 Naming Rule')}</h2>
        <Button onClick={() => close()} disableRipple />
      </TitleWrap>
      <DialogContent>
        <ImgBox>
          <img src="/assets/gatingTaskNamingRule.png"></img>
        </ImgBox>
      </DialogContent>
    </Dialog>
  );
};
