/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { di, TitleWrap, PopupBtnGroup } from 'components/layouts/Dialog';
import {
  SearchBox,
  SearchRows,
  SearchCols,
  SearchBoxRow,
  InputBox,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { getExcpListWithoutSession } from 'apis/admin/ExcpPartner';
import _ from 'lodash';
import useSessionStore from 'stores/useSessionStore';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { useTranslation } from 'react-i18next';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';

type Props = {
  open: boolean;
  close: () => void;
  onCallback: (item) => void;
};
/**
 * @param open
 * @param close
 * @param onCallback
 * @constructor
 */
export const ExcpListPopUp = ({ open, close, onCallback }: Props) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<any[]>([]);
  const { gridNoRowsTemplate } = useSessionStore();
  const [partnerCondition, setPartnerCondition] = useState<any>({
    // excpId: '',
    excpNm: '',
    // rmk: '',
    // useYn: 'Y',
    rpsnNm: '',
  });
  /**
   * init
   */
  useEffect(() => {
    initCondition();
  }, []);

  const initCondition = async () => {
    await getExcpListWithoutSession(partnerCondition).then((result: any) => {
      if (result != null && result != '') {
        setRowData(result);
      }
    });
  };

  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    grid.rowHeaders.columns.splice(0, 1);
    grid.rowHeaders.columns.splice(1, 1);
    setflexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      setHitTest(ht);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
      setflexItem(item);
      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnSelect':
            btnQuickSelect(item);
            break;
        }
      }
    });
    grid.hostElement.addEventListener('dblclick', (e) => {
      const ht = grid.hitTest(e);
      if (grid.rows[ht.row] !== undefined) {
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
        btnQuickSelect(item);
      }
    });
  };

  const handleSearch = async (condition: any) => {
    await getExcpListWithoutSession(condition).then((result: any) => {
      if (result != null && result != '') {
        setRowData(result);
      }
    });
  };

  const handleResetCondition = () => {
    setPartnerCondition({
      // excpId: '',
      excpNm: '',
      // rmk: '',
      // useYn: 'Y',
      rpsnNm: '',
    });
  };

  const handleConditionChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setPartnerCondition({ ...partnerCondition, [e.target.name]: e.target.value });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(partnerCondition);
    }
  };

  const LayoutDefinition = () => {
    return [
      {
        header: String(t('com.label.선택', '선택')),
        cssClass: 'WijmoRelay',
        cellTemplate: '<Button id="btnSelect"/>',
        width: 100,
        visible: true,
      },
      {
        binding: 'excpNm',
        header: String(t('com.label.협력업체명', '협력업체명')),
        align: 'left',
        width: '*',
        isReadOnly: true,
      },
      {
        binding: 'rpsnNm',
        header: String(t('com.label.대표자명', '대표자명')),
        align: 'center',
        isReadOnly: true,
        width: '*',
      },
      {
        binding: 'excpId',
        visible: false,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const handleConfirm = () => {
    // const rows = gridRef.current?.api.getSelectedRows() || [];
    // onCallback(rows.length > 0 ? rows[0] : null);
    onCallback(flexItem);
    close();
  };

  // const btnQuickSelect = (params) => {
  //   const handleButtonClick = () => {
  //     // data에 현재 행의 데이터가 포함되어 있음123345
  //     onCallback(!_.isNull(params.data) ? params.data : null);
  //     close();
  //   };

  //   return <button className={'cellRelay'} onClick={handleButtonClick} />;
  // };

  const btnQuickSelect = (item) => {
    onCallback(!_.isNull(item) ? item : null);
    close();
  };

  return (
    <>
      <Dialog open={open} css={di.dialog} fullWidth className="md" maxWidth={false}>
        <TitleWrap>
          <h2>{t('com.label.협력업체', '협력업체')}</h2>
          <Button onClick={() => close()} disableRipple></Button>
        </TitleWrap>
        <DialogContent>
          <SearchBox>
            <SearchBoxRow>
              <InputBox>
                <SearchRows className="twoCol">
                  <SearchCols>
                    <label>{t('com.label.협력업체명', '협력업체명')}</label>
                    <CustomInputWithSearch
                      name="excpNm"
                      placeholder={String(
                        t('com.label.협력업체명을 입력해 주세요.', '협력업체명을 입력해 주세요.')
                      )}
                      value={partnerCondition.excpNm}
                      onChange={handleConditionChange}
                      onKeyDown={handleKeyDown}
                    />
                  </SearchCols>
                  <SearchCols>
                    <label>{t('com.label.대표자명', '대표자명')}</label>
                    <CustomInputWithSearch
                      name="rpsnNm"
                      placeholder={String(
                        t('com.label.대표자명을 입력해주세요.', '대표자명을 입력해주세요.')
                      )}
                      value={partnerCondition.rpsnNm}
                      onChange={handleConditionChange}
                      onKeyDown={handleKeyDown}
                    />
                  </SearchCols>
                </SearchRows>
              </InputBox>
              <SearchButtonWrap>
                <Button
                  css={IconButton.button}
                  className="reload"
                  onClick={handleResetCondition}
                  disableRipple
                ></Button>
                <Button
                  css={IconButton.button}
                  className="find"
                  onClick={() => handleSearch(partnerCondition)}
                  disableRipple
                >
                  {t('com.button.조회', '조회')}
                </Button>
              </SearchButtonWrap>
            </SearchBoxRow>
          </SearchBox>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('com.label.협력업체', '협력업체')}</h3>
              <span className="total">
                {t('com.label.총', '총')} <span>{(rowData || []).length.toLocaleString()}</span>
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
          </SubTitleLayout>
          <ContentGrid
            className={rowData.length < 1 ? 'noData' : ''}
            style={{ height: '300px', marginBottom: '32px' }}
          >
            <FlexGrid
              itemsSource={state.itemsSource}
              columnGroups={state.layoutDefinition}
              // allowPinning="ColumnRange" // 열 고정 방식
              showMarquee={true} // 선택 윤곽선 (default: false)
              alternatingRowStep={0}
              // stickyHeaders={true} // 헤더 고정
              style={{ height: (rowData || []).length < 1 ? '' : '300px' }}
              isReadOnly={true}
              align="center"
              allowMerging="Cells"
              initialized={onInitialized}
            />
            <div className="noData" style={{ height: (rowData || []).length < 1 ? '300px' : '' }}>
              <span>
                {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
              </span>
            </div>
          </ContentGrid>
        </DialogContent>
        <PopupBtnGroup>
          <Button css={IconButton.button} className="select" onClick={handleConfirm} disableRipple>
            {t('com.button.선택', '선택')}
          </Button>
        </PopupBtnGroup>
      </Dialog>
    </>
  );
};
export default ExcpListPopUp;
