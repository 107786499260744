/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from 'components/layouts/Dialog';
import { InputBox, SearchBox, SearchBoxRow, SearchRows } from 'components/layouts/SearchBox';
import { ViewTable } from 'components/tables/ViewTable';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { EquipRowData } from 'pages/common/popup/GatingEquipPopUp';
import { IconButton } from 'components/buttons/IconSVG';
import _ from 'lodash';
import useSessionStore from 'stores/useSessionStore';
import { useTranslation } from 'react-i18next';
import CustomSwitch from 'components/selects/CustomSwitch';
import { useCommonModal } from 'hooks/useCommonModal';
import { setExcpUserIdvlIfoCncY } from 'apis/admin/ExcpUser';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {
  SubTitleGroup,
  SubTitleLayout,
  GlobalBtnGroup,
  cs,
} from 'components/layouts/ContentLayout';
import { tb } from '../../components/layouts/Table';

type Props = {
  open: boolean;
  close: () => void;
  userId: string;
  onCallback: (isAgreed) => void;
};
/**
 * @param open
 * @param close
 * @param isEditable
 * @param condition
 * @param onCallback
 * @constructor
 */
export const IdvlIfoPopUp = ({ open, close, userId, onCallback }: Props) => {
  const { t } = useTranslation();
  const [isAgreed, setAgreed] = useState<boolean>(false);
  const { openCommonModal } = useCommonModal();

  useEffect(() => {
    initCondition();
  }, []);

  const initCondition = async () => {
    //await getExcpList().then((result: any) => {
    //  if (result != null && result != '') {
    //    console.log('##########');
    //  }
    //});
  };

  const handleConfirm = async () => {
    if (isAgreed) {
      const response = await setExcpUserIdvlIfoCncY(userId);
      const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
      if (response.updatedRows) {
        onCallback(isAgreed);
        close();
      }
    } else {
      openCommonModal({
        content: '개인정보활용에 동의 하지 않으시면 사이트 이용이 불가 합니다. 진행 하시겠습니까?',
      });
    }
  };

  return (
    <>
      <Dialog open={open} css={di.dialog} fullWidth className="md" maxWidth={false}>
        <TitleWrap>
          <h2>{t('com.label.개인정보활용동의', '개인정보활용동의')}</h2>
          <Button onClick={() => close()} disableRipple></Button>
        </TitleWrap>
        <DialogContent>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3> 개인정보의 수집·이용 동의(필수)</h3>
            </SubTitleGroup>
            <CustomSwitch
              id={'idvlIfoCncYn'}
              onValue={'Y'} // 스위치 on 값
              onLabel={'동의'} // 스위치 on 문구
              offValue={'N'} // 스위치 off 값
              offLabel={'미동의'} // 스위치 off 문구
              defaultValue={'N'} // 기본값 생략가능 (default: onValue)
              onChange={(value) => (value == 'Y' ? setAgreed(true) : setAgreed(false))}
            />
          </SubTitleLayout>
          <div css={cs.persnal}>
            <span>
              {t(
                'com.msg.개인정보활용동의내용',
                'LG에너지솔루션(이하 "회사")은 [설비라이프사이클](이하 "서비스")를 이용하는 경우 아래와 같이 개인정보를 수집·이용 및 제공합니다. 회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 및 훼손되지 않도록 다양한 기술적 보호조치를 강구하고 있습니다. 기타 상세한 사항은 홈페이지에 게시된 "개인정보 처리방침"을 참조하시기 바랍니다.'
              )}
            </span>
            <h4>개인정보 수집 및 이용에 대한 동의</h4>
            <TableContainer css={tb.table}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>수집·이용 항목</TableCell>
                    <TableCell>설비라이프사이클 등의 회사 업무시스템 이용</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>수집·이용 항목</TableCell>
                    <TableCell>이름, 휴대폰 번호, 이메일, 회사명</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>보유 및 이용 기간</TableCell>
                    <TableCell>회원 탈퇴 후 2년동안 보관</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>동의 거부 권리 안내</TableCell>
                    <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                      {t(
                        'com.msg.개인정보수집동의확인',
                        '이용자는 개인정보 수집 및 이용과 관련하여 동의를 거부할 권리가 있으며, 동의 거부 시 회사의 업무시스템 접속이 불가 합니다.'
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <PopupBtnGroup>
          <Button css={IconButton.button} className="confirm" onClick={handleConfirm} disableRipple>
            {t('com.button.확인', '확인')}
          </Button>
        </PopupBtnGroup>
      </Dialog>
    </>
  );
};
export default IdvlIfoPopUp;
