/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { di, TitleWrap, PopupBtnGroup, AlertBtnGroup, diAlert } from 'components/layouts/Dialog';
import { ContentGrid } from 'components/layouts/ContentGrid';
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
} from 'ag-grid-community';
import { CustomInputWrap, CustomInputText, AllInputWrap } from 'components/inputs/CustomInput';
import {
  SubTitleLayout,
  SubTitleGroup,
  HalfContetntLayout,
  ControlBtnGroup,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import { Crud, CrudCode } from 'models/common/Edit';
import {
  getEquipments,
  getGatingEquipmentMst,
  saveEquipments,
  getEquipments2,
  updateGatingDate,
} from 'apis/gtng/popup/GatingEquipment';
import { useCommonModal } from 'hooks/useCommonModal';
import _ from 'lodash';
import { GridStatusCellRenderer } from 'components/grids/GridStatusCellRenderer';
import { ComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import useSessionStore from 'stores/useSessionStore';
import { tb } from 'components/layouts/Table';
import { ContentFlex } from 'components/layouts/ContentFlex';
import { GatingContPopUp } from 'pages/gtng/popup/GatingContPopUp';
import { GatingContListPopUp } from 'pages/gtng/popup/GatingContListPopUp';
import { GatingRegist } from 'models/gtng/GatingRegist';
import { findGatingRegistMst } from 'apis/gtng/GatingRegist';
import { useTranslation } from 'react-i18next';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as input from '@grapecity/wijmo.input';
import 'ui/css/multiSelect.css';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import useEvent from 'react-use-event-hook';
import moment from 'moment';

export interface EquipRowData extends Crud {
  gatingId?: string; //게이팅ID
  eqpType?: string; //설비유형
  equipmentId?: string; // 설비ID/설비군
  equipmentName?: string; // 설비명/설비군명
  startDate?: string;
  endDate?: string;
  progressStatusName?: string; //상태명
  progressStatusCode?: string; //상태
  gatingEqpCheckContIds: string; //설비 점검담당자 ID
  gatingEqpCheckContName: string; //상태
  gatingEqpCheckContNames: string; //상태
  gatingEqpExecuteContIds: string; //상태
  gatingEqpExecuteContName: string; //상태
  gatingEqpExecuteContNames: string; //상태
  gatingEqpExecuteTlContIds: string; //상태
  gatingEqpExecuteTlContName: string; //상태
  gatingEqpExecuteTlContNames: string; //상태
}

export interface SearchParam {
  eqpType?: string; // 설비유형
  equipmentId?: string; // 설비ID/설비군
  equipmentName?: string; // 설비명/설비군명
}

export const GatingEquipPopUp = (props: any) => {
  const { t } = useTranslation();
  const equipRetrieveGridRef = useRef<AgGridReact<EquipRowData>>(null);
  const equipChangeGridRef = useRef<AgGridReact<EquipRowData>>(null);
  const [equipRetrieveList, setEquipRetrieveList] = useState<EquipRowData[]>([]);
  // 게이팅이 점검 상태일때 list CRUD상태관리
  const [equipChangeList, setEquipChangeList] = useState<EquipRowData[]>([]);
  const [equipInfo, setEquipInfo] = useState<EquipRowData[]>([]);
  const [delData, setDelData] = useState<EquipRowData[]>([]);
  const { openCommonModal } = useCommonModal();
  const { gridNoRowsTemplate } = useSessionStore();
  const [open, setOpen] = React.useState(false);
  const [validation, setValidation] = useState<any>();
  const [gatingEqpCode, setGatingEqpCode] = useState<Code[]>([]);
  const [searchParam, setSearchParam] = useState<SearchParam>({});
  const [progressStatusCode, setProgressStatusCode] = useState<any>();
  const [contData, setContData] = useState<any>(null);
  const [duplicationData, setDuplicationData] = useState<GatingRegist[]>([]);
  const [newRowData, setNewRowData] = useState<GatingRegist[]>([]);
  const [gatingData, setGatingData] = useState<GatingRegist[]>([]);

  const [equipChangeFlexRef, setEquipChangeFlexRef] = useState<any>();
  const [equipRetrieveFlexRef, setEquipRetrieveFlexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();

  const equipRetrieveOnInitialized = (grid) => {
    new Selector(grid);
    // new FlexGridFilter(grid);
    setEquipRetrieveFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      setHitTest(ht);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // cell 선택 시 checkBox 체크
      if (grid.rows[ht.row].isSelected == true) {
        grid.rows[ht.row].isSelected = false;
      } else {
        grid.rows[ht.row].isSelected = true;
      }

      const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
    });
  };

  const equipRetrieveLayoutDefinition = () => {
    return [
      {
        binding: 'eqpTypeName',
        header: String(t('com.label.유형', '유형')),
        align: 'center',
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'equipmentId',
        header: String(t('gtng.grid.Equipment', 'Equipment')),
        align: 'left',
        width: '*',
        isReadOnly: true,
      },
      {
        binding: 'equipmentName',
        header: String(t('gtng.grid.Equipment Desc', 'Equipment Desc')),
        align: 'left',
        width: '*',
        isReadOnly: true,
      },
    ];
  };

  const equipRetrieveState = {
    itemsSource: equipRetrieveList,
    layoutDefinition: equipRetrieveLayoutDefinition(),
  };

  const [initParam, setInitParam] = useState('');
  const [isOpenGatingContListPopUp, setGatingContListPopUp] = useState(false);
  const [isOpenGatingContPopUp, setGatingContPopUp] = useState(false);
  const [contPopTitle, setContPopTitle] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<any>();
  const flexGridRef = React.useRef<any>(null);

  const equipChangeOnInitialized = (grid) => {
    new Selector(grid);
    // new FlexGridFilter(grid);
    setEquipChangeFlexRef(grid);

    const exclude = ['crudKey'];
    const convertFlat = (acc, cur) => {
      if ('columns' in cur && Array.isArray(cur?.columns)) {
        return cur.columns.reduce(convertFlat, acc);
      }
      return !exclude.includes(cur?.binding) ? [...acc, cur?.binding] : acc;
    };

    const filterColumns = (equipChangeLayoutDefinition || []).reduce(convertFlat, []);

    new FlexGridFilter(grid, {
      filterColumns: filterColumns,
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      // for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      // grid.rows[ht.row].isSelected = true;
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      if (e.target instanceof HTMLButtonElement) {
        const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
        const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
        setHitTest(ht);
        setflexItem(item);
        setFieldId(col.binding);
        switch (e.target.id) {
          case 'gatingEqpExecuteContIds':
            setInitParam(item.gatingEqpExecuteContIds);
            if (item.progressStatusCode == null) {
              setGatingContPopUp(true);
            } else {
              setGatingContListPopUp(true);
              setContPopTitle(String(t('gtng.label.등록 담당자', '등록담당자')));
            }
            break;
          case 'gatingEqpCheckContIds':
            setInitParam(item.gatingEqpCheckContIds);
            if (item.progressStatusCode == null) {
              setGatingContPopUp(true);
            } else {
              setGatingContListPopUp(true);
              setContPopTitle(String(t('gtng.label.실시담당자', '실시담당자')));
            }
            break;
          case 'gatingEqpExecuteTlContIds':
            setInitParam(item.gatingEqpExecuteTlContIds);
            if (item.progressStatusCode == null) {
              setGatingContPopUp(true);
            } else {
              setGatingContListPopUp(true);
              setContPopTitle(String(t('gtng.label.실시(T/L)', '실시T/L')));
            }
            break;
        }
      } else {
        grid.startEditing(true);
      }
    });

    grid.cellEditEnding.addHandler((s, e) => {
      const data = s.rows[e.row].dataItem;

      if (data.crudKey !== CrudCode.CREATE) {
        data.crudKey = CrudCode.UPDATE;
      }
    });

    grid.itemFormatter = (panel, r, c, cell) => {
      const binding = panel.grid.columns[c].binding;
      if ('crudKey' === binding) {
        const pin = cell.getElementsByClassName('wj-elem-pin');
        pin && pin.length && pin[0].remove();
      }

      grid.rows.forEach((row) => {
        grid.columns.forEach((col) => {
          if (row.dataItem.progressStatusCode === 'B1') {
            if (
              (col.binding === 'equipmentId' ||
                col.binding === 'startDate' ||
                col.binding === 'endDate' ||
                col.binding === 'gatingEqpExecuteContIds' ||
                col.binding === 'gatingEqpCheckContIds' ||
                col.binding === 'gatingEqpExecuteTlContIds' ||
                col.binding === 'progressStatusCode') &&
              r === row.dataIndex &&
              c === col._idx
            ) {
              cell.setAttribute('aria-readonly', true);
              // cell = true;
              // '<div class="wj-cell wj-align-left" tabindex="-1" role="gridcell" aria-readonly="true" style="left: 80px; top: 31px; width: 150px; height: 31px;">?</div>';
            }
          }
          if (
            row.dataItem.progressStatusCode === 'B2' ||
            row.dataItem.progressStatusCode === 'B3' ||
            row.dataItem.progressStatusCode === 'B4'
          ) {
            if (r === row.dataIndex && c === col._idx) {
              cell.setAttribute('aria-readonly', true);
            }
          }
        });
      });
    };
  };

  const beginningEdit = useEvent((s, e) => {
    const col = s.columns[e.col];
    const row = s.rows[e.row];
    if (
      row.dataItem.progressStatusCode === 'B2' ||
      row.dataItem.progressStatusCode === 'B3' ||
      row.dataItem.progressStatusCode === 'B4'
    ) {
      e.cancel = true;
    }
    if (
      col.binding === 'gatingEqpExecuteContIds' ||
      col.binding === 'gatingEqpCheckContIds' ||
      col.binding === 'gatingEqpExecuteTlContIds'
    ) {
      e.cancel = true;
    }
    if (row.dataItem.progressStatusCode === 'B1') {
      if (col.binding !== 'equipmentName') {
        e.cancel = true;
      }
    }
  });

  const resultContPopup = (fieldId, result) => {
    let contactId = '';
    let empNm = '';
    for (let i = 0; i < result.contInfo.length; i++) {
      contactId += result.contInfo[i].userId + ',';
      empNm += result.contInfo[i].empNm + ',';
    }
    if (fieldId == 'gatingEqpExecuteContIds') {
      flexItem[fieldId] = contactId;
      flexItem['gatingEqpExecuteContNames'] = empNm;
    }
    if (fieldId == 'gatingEqpCheckContIds') {
      flexItem[fieldId] = contactId;
      flexItem['gatingEqpCheckContNames'] = empNm;
    }
    if (fieldId == 'gatingEqpExecuteTlContIds') {
      flexItem[fieldId] = contactId;
      flexItem['gatingEqpExecuteTlContNames'] = empNm;
    }
  };

  const equipChangeLayoutDefinition = [
    {
      binding: 'gatingId',
      header: 'GatingId',
      align: 'center',
      visible: false,
    },
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      cellTemplate: ({ value }) =>
        value
          ? `<span class="${
              GridStatusCellRenderer({ data: { crudKey: value } })?.props.className
            }"></span>`
          : '',
      width: 50,
      isReadOnly: true,
    },
    {
      binding: 'equipmentId',
      header: String(t('gtng.grid.Equipment', 'Equipment')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'equipmentName',
      header: String(t('gtng.grid.Equipment Desc', 'Equipment Desc')),
      align: 'left',
      width: 200,
    },
    {
      binding: 'startDate',
      header: String(t('gtng.grid.시작일', '시작일')),
      cssClass: 'WijmoDate',
      format: 'yyyy.MM.dd',
      editor: new input.InputDate(document.createElement('div'), {
        min: new Date(),
        format: 'yyyy.MM.dd',
      }),
    },
    {
      binding: 'endDate',
      header: String(t('gtng.grid.종료일', '종료일')),
      cssClass: 'WijmoDate',
      format: 'yyyy.MM.dd',
      editor: new input.InputDate(document.createElement('div'), {
        min: new Date(),
        format: 'yyyy.MM.dd',
      }),
    },
    {
      binding: 'gatingEqpExecuteContIds',
      header: String(t('gtng.label.실시담당자', '실시담당자')),
      align: 'left',
      width: 150,
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (item.item.gatingEqpExecuteContIds === undefined) {
          return `<span>${item.text}</span><button id="gatingEqpExecuteContIds" />`;
        } else {
          return `<span>${item.item.gatingEqpExecuteContNames}</span><button id="gatingEqpExecuteContIds" />`;
        }
      },
    },
    {
      binding: 'gatingEqpExecuteContNames',
      header: String(t('gtng.grid.실시담당자명', '실시담당자명')),
      visible: false,
    },
    {
      binding: 'gatingEqpCheckContIds',
      header: String(t('gtng.label.점검담당자', '점검담당자')),
      align: 'left',
      width: 150,
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (item.item.gatingEqpCheckContIds === undefined) {
          return `<span>${item.text}</span><button id="gatingEqpCheckContIds" />`;
        } else {
          return `<span>${item.item.gatingEqpCheckContNames}</span><button id="gatingEqpCheckContIds" />`;
        }
      },
    },
    {
      binding: 'gatingEqpCheckContNames',
      header: String(t('gtng.grid.점검담당자명', '점검담당자명')),
      visible: false,
    },
    {
      binding: 'gatingEqpExecuteTlContIds',
      header: String(t('gtng.label.실시(T/L)', '실시(T/L)')),
      align: 'left',
      cssClass: 'WijmoFind',
      cellTemplate: (item) => {
        if (item.item.gatingEqpExecuteTlContIds === undefined) {
          return `<span>${item.text}</span><button id="gatingEqpExecuteTlContIds" />`;
        } else {
          return `<span>${item.item.gatingEqpExecuteTlContNames}</span><button id="gatingEqpExecuteTlContIds" />`;
        }
      },
    },
    {
      binding: 'gatingEqpExecuteTlContNames',
      header: String(t('gtng.grid.실시(T/L)명', '실시(T/L)명')),
      visible: false,
    },
    {
      binding: 'progressStatusCode',
      header: String(t('gtng.grid.단계', '단계')),
      align: 'center',
      width: 100,
      isReadOnly: true,
      cellTemplate: (item) => {
        if (item.item.progressStatusCode === undefined) {
          return `<span>${item.text}</span>`;
        } else {
          return `<span>${item.item.progressStatusName}</span>`;
        }
      },
    },
    {
      binding: 'progressStatusName',
      header: String(t('gtng.grid.단계명', '단계명')),
      visible: false,
    },
  ];

  const equipChangeState = {
    itemsSource: equipChangeList,
    layoutDefinition: equipChangeLayoutDefinition,
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * 조회조건 영역 공통코드 가져오기
   */
  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  const getCommonCodesForGrid = async () => {
    const gatingEqpCode = await getCommonCodeNames('GATING_EQP_CLSF');
    setGatingEqpCode(gatingEqpCode);
  };

  const handleEquipType = (value) => {
    setSearchParam({
      ...searchParam,
      eqpType: value,
    });
  };

  const handleSearchParam = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce((acc, cur) => Object.assign(acc, { [cur]: '' }), {}) as SearchParam
    );
  };

  // const getEquipContList = async () => {
  //   // const equipContsList = await getEquipments2(props.initParam);
  //   const equipContsList = await getEquipments2(props.gatingId);
  //   setEquipChangeList(equipContsList);
  // };

  // const getEquipmentInfo = async (gatingId, value) => {
  //   await getEquipments2(gatingId).then((result: any) => {
  //     // DB에 등록된 정보가있으면 가져온다. (Gating 등록(A), 점검(B-점검중장비변경))
  //     if (result != null && result != '') {
  //       // DB에 등록된 정보를 보여주되 수정하고 재확인할때
  //       // (장비를 수정하면 Gating 생성/조회 그리드에 gatingEqpContData 컬럼에 수정된 정보가 입력됨)
  //       if (props.gatingEqpContData != null && props.statusCode != 'B') {
  //         setEquipChangeList(props.gatingEqpContData);
  //       } else if (props.gatingEqpContData != null && props.stausCode == undefined) {
  //         setEquipChangeList(result);
  //       }
  //       // DB에 등록된 정보가 없지만 그리드에 정보가 입력되었을때 (Gating 신규생성시)
  //     } else if (value != null && value != '') {
  //       // 장비 및 등록담당자를 수정하고 재확인할때
  //       // (장비를 수정하면 Gating 생성/조회 그리드에 gatingEqpContData 컬럼에 수정된 정보가 입력됨)
  //       if (props.gatingEqpContData != null) {
  //         setEquipChangeList(props.gatingEqpContData);
  //       } else if (
  //         props.gatingEqpContData == null &&
  //         props.params.data.crudKey == CrudCode.CREATE
  //       ) {
  //         const gatingId = props.initParam;
  //         const equipmentId = props.params.value.split(',');
  //         const equipmentName = props.params.valueFormatted.split(',');
  //         setEquipChangeList((preData) => {
  //           const newData = [...preData];
  //           for (let i = 0; i < equipmentId.length; i++) {
  //             newData.push({
  //               gatingId: gatingId,
  //               equipmentId: equipmentId[i],
  //               equipmentName: equipmentName[i],
  //               gatingEqpCheckContIds: '',
  //               gatingEqpCheckContName: '',
  //               gatingEqpCheckContNames: '',
  //               gatingEqpExecuteContIds: '',
  //               gatingEqpExecuteContName: '',
  //               gatingEqpExecuteContNames: '',
  //               gatingEqpExecuteTlContIds: '',
  //               gatingEqpExecuteTlContName: '',
  //               gatingEqpExecuteTlContNames: '',
  //             });
  //           }
  //           return newData;
  //         });
  //       } else {
  //         getEquipContList();
  //       }
  //     }
  //   });
  // };hangeList

  // useEffect(() => {
  //   const gatingId = props.initParam;
  //   const value = props.value;
  //   getEquipmentInfo(gatingId, value);
  // }, [props.initParam]);

  useEffect(() => {
    const data = Object.assign({}, props.params);
    getEquipmentInfo(data);
  }, [props]);

  const getEquipmentInfo = async (data) => {
    //신규 및 점검장비 데이터가 없을때
    if (data.crudKey === CrudCode.CREATE && data.gatingEqpContData === undefined) {
      setEquipChangeList([]);
    }
    //신규 및 점검장비 데이터가 있을때
    if (data.crudKey === CrudCode.CREATE && data.gatingEqpContData !== undefined) {
      setEquipChangeList((preData) => {
        const newData = data.gatingEqpContData;
        for (let i = 0; i < newData.length; i++) {
          delete newData[i].progressStatusCode;
          delete newData[i].progreessStatusName;
          newData[i].crudKey = CrudCode.CREATE;
        }
        return newData;
      });
    }
    //등록단계
    if (data.progressStatusCode === 'A') {
      setEquipChangeList((preData) => {
        const newData = data.gatingEqpContData;
        for (let i = 0; i < newData.length; i++) {
          delete newData[i].progressStatusCode;
          delete newData[i].progreessStatusName;
          newData[i].crudKey = CrudCode.CREATE;
        }
        return newData;
      });
    }
    //점검 단계 - 점검중 장비변경
    if (data.progressStatusCode === 'B') {
      await getEquipments2(data.gatingId).then((result: any) => {
        setEquipChangeList(result);
      });
    }
    // if (props.crudKey === CrudCode.CREATE && props.gatingEqpContData !== undefined) {
    //   setEquipChangeList(props.gatingEqpContData);
    // }
    // if (props.crudKey !== CrudCode.CREATE && props.gatingEqpContData !== undefined) {
    //   setEquipChangeList(props.gatingEqpContData);
    // }

    // await getEquipments2(props.gatingId).then((result: any) => {
    //   // DB에 등록된 정보가있으면 가져온다. (Gating 등록(A), 점검(B-점검중장비변경))
    //   if (result != null && result != '') {
    //     // DB에 등록된 정보를 보여주되 수정하고 재확인할때
    //     // (장비를 수정하면 Gating 생성/조회 그리드에 gatingEqpContData 컬럼에 수정된 정보가 입력됨)
    //     if (props.gatingEqpContData != null && props.progressStautsCode != 'B') {
    //       setEquipChangeList(props.gatingEqpContData);
    //     } else if (props.gatingEqpContData != null && props.progressStautsCode == undefined) {
    //       setEquipChangeList(result);
    //     }
    //     // DB에 등록된 정보가 없지만 그리드에 정보가 입력되었을때 (Gating 신규생성시)
    //   } else if (props.gatingEquipIds != null && props.gatingEquipIds != '') {
    //     // 장비 및 등록담당자를 수정하고 재확인할때
    //     // (장비를 수정하면 Gating 생성/조회 그리드에 gatingEqpContData 컬럼에 수정된 정보가 입력됨)
    //     if (props.gatingEqpContData != null) {
    //       setEquipChangeList(props.gatingEqpContData);
    //     } else if (props.gatingEqpContData == null && props.crudKey == CrudCode.CREATE) {
    //       const gatingId = props.gatingId;
    //       const equipmentId = props.gatingEquipIds.split(',');
    //       const equipmentName = props.gatingEquipNames.split(',');
    //       setEquipChangeList((preData) => {
    //         const newData = [...preData];
    //         for (let i = 0; i < equipmentId.length; i++) {
    //           newData.push({
    //             gatingId: gatingId,
    //             equipmentId: equipmentId[i],
    //             equipmentName: equipmentName[i],
    //             gatingEqpCheckContIds: '',
    //             gatingEqpCheckContName: '',
    //             gatingEqpCheckContNames: '',
    //             gatingEqpExecuteContIds: '',
    //             gatingEqpExecuteContName: '',
    //             gatingEqpExecuteContNames: '',
    //             gatingEqpExecuteTlContIds: '',
    //             gatingEqpExecuteTlContName: '',
    //             gatingEqpExecuteTlContNames: '',
    //           });
    //         }
    //         return newData;
    //       });
    //     } else {
    //       getEquipContList();
    //     }
    //   }
    // });
  };

  // 1. 중복체크. DB데이터 가져옴
  useEffect(() => {
    const searchParam = {
      gatingTypeCode: '',
      gatingStepCode: '',
      progressStatusCode: '',
      productTypeCode: '',
      gatingName: '',
      startDate: '',
      endDate: '',
    };

    findGatingRegistMst(searchParam).then((result: GatingRegist[]) => {
      if (result != null && result != undefined) {
        const duplicationResult: any = [];

        for (let i = 0; i < result.length; i++) {
          const equipmentId = result[i].gatingEquipIds;
          const productTypeCode = result[i].productTypeCode;
          const gatingName = result[i].gatingName;
          const gatingTypeCode = result[i].gatingTypeCode;
          const gatingStepCode = result[i].gatingStepCode;
          const versionNo = result[i].versionNo;

          const gatingData = {
            productTypeCode: productTypeCode,
            gatingName: gatingName,
            gatingTypeCode: gatingTypeCode,
            gatingStepCode: gatingStepCode,
            versionNo: versionNo,
          };

          const splitEquipId = equipmentId?.split(',');

          const newDataArr: any = [];
          for (let i = 0; i < splitEquipId?.length; i++) {
            const newEquipment = { equipmentId: splitEquipId[i] };
            const newData: any = _.merge({}, gatingData, newEquipment);
            newDataArr.push(newData);
          }
          duplicationResult.push(...newDataArr);
        }
        setGatingData(result);
        setDuplicationData(duplicationResult);
      }
    });
  }, [equipChangeList]);

  //점검중 장비변경 시
  //추가는 equipmentId, equipmentName 둘 다 가능
  //수정은 equipmentName 만 수정 가능
  //그리드에 gating 등록 후 : progressStatusCode == B 일 때 적용 시 데이터베이스 적용
  //그리드에 gating 등록 중 : progressStatusCode != B 일 때 적용시 그리드에 적용 후 저장해야 데이터베이스적용
  const btnEquipSelectInfo = () => {
    const gatingProgressStatusCode = props.params.progressStatusCode;

    // 삭제 상태가 아닌 row들에 대한 validation check
    const filteredNotDelData: any = equipChangeList.filter(
      (element) => element.crudKey !== CrudCode.DELETE
    );

    const validUndefined = filteredNotDelData
      .map((element) => {
        if (
          element.equipmentId == '' ||
          element.equipmentName == '' ||
          element.equipmentId == null ||
          element.equipmentName == null ||
          element.equipmentId == undefined ||
          element.equipmentName == undefined
        ) {
          return `EQPU`;
        }
        if (
          element.gatingEqpExecuteContIds == '' ||
          element.gatingEqpExecuteTlContIds == '' ||
          element.gatingEqpCheckContIds == '' ||
          element.gatingEqpExecuteContIds == null ||
          element.gatingEqpExecuteTlContIds == null ||
          element.gatingEqpCheckContIds == null ||
          element.gatingEqpExecuteContIds == undefined ||
          element.gatingEqpExecuteTlContIds == undefined ||
          element.gatingEqpCheckContIds == undefined
        ) {
          return `CONT`;
        }
        if (
          element.startDate == '' ||
          element.endDate == '' ||
          element.startDate == null ||
          element.endDate == null ||
          element.startDate == undefined ||
          element.endDate == undefined
        ) {
          return `DATE`;
        }
        if (element.startDate > element.endDate) return `DATE2`;
      })
      .filter((element) => element !== undefined);
    if (validUndefined.length > 0) {
      const content = validUndefined[0]?.toString();
      setOpen(true);
      setValidation(content);
      return false;
    }

    if (filteredNotDelData.length > 1) {
      const arrEquipIdDuplication: any = [];
      const arrEquipNameDuplication: any = [];
      for (let i = 0; i < filteredNotDelData.length; i++) {
        arrEquipIdDuplication.push(filteredNotDelData[i].equipmentId);
        arrEquipNameDuplication.push(filteredNotDelData[i].equipmentName);
      }

      const setEquipIdDuplication = new Set([...arrEquipIdDuplication]);
      const setEquipNameDuplication = new Set([...arrEquipNameDuplication]);
      if (
        arrEquipIdDuplication.length !== setEquipIdDuplication.size ||
        arrEquipNameDuplication.length !== setEquipNameDuplication.size
      ) {
        setOpen(true);
        setValidation('DUPLICATION');
        return false;
      }
    }

    // setEquipChangeList(_.uniqBy(equipChangeList, 'equipmentId'));

    if (gatingProgressStatusCode == 'B') {
      const equipData = {
        equipChangeList: equipChangeList,
      };
      const createRowData = equipData.equipChangeList.filter(
        (element) => element.crudKey == CrudCode.CREATE
      );
      if (createRowData.length > 0) {
        const gatingId = props.params.gatingId;
        const newRowDatas = gatingData.filter((element) => gatingId?.includes(element.gatingId));

        for (let i = 0; i < newRowDatas.length; i++) {
          const productTypeCode = newRowDatas[i].productTypeCode;
          const gatingName = newRowDatas[i].gatingName;
          const gatingTypeCode = newRowDatas[i].gatingTypeCode;
          const gatingStepCode = newRowDatas[i].gatingStepCode;
          const versionNo = newRowDatas[i].versionNo;

          const gatingData = {
            productTypeCode: productTypeCode,
            gatingName: gatingName,
            gatingTypeCode: gatingTypeCode,
            gatingStepCode: gatingStepCode,
            versionNo: versionNo,
          };

          const newDataArr: any = [];
          for (let i = 0; i < createRowData?.length; i++) {
            const newEquipment = { equipmentId: createRowData[i].equipmentId };
            const newData: any = _.merge({}, gatingData, newEquipment);
            newDataArr.push(newData);
          }
          duplicationData.push(...newDataArr);
        }

        const stringDuplicationResult = duplicationData.map((element) => {
          return Object.entries(element).toString();
        });

        const setDuplicationResult = new Set(stringDuplicationResult);
        if (duplicationData.length !== setDuplicationResult.size) {
          openCommonModal({
            content: t('gtng.label.중복된 Gating이 있습니다.', '중복된 Gating이 있습니다.'),
          });
          return false;
        }
      }

      const newEquipData = equipData.equipChangeList.filter((data) => {
        return data.crudKey === CrudCode.CREATE;
      });
      const preEquipdata = equipData.equipChangeList.filter((data) => {
        return data.crudKey !== CrudCode.CREATE && data.crudKey !== CrudCode.DELETE;
      });

      const startDate: any = [];
      const endDate: any = [];

      for (let i = 0; i < preEquipdata.length; i++) {
        startDate.push(preEquipdata[i].startDate);
        endDate.push(preEquipdata[i].endDate);
      }

      for (let i = 0; i < newEquipData.length; i++) {
        startDate.push(moment(newEquipData[i].startDate).format('YYYY.MM.DD'));
        endDate.push(moment(newEquipData[i].endDate).format('YYYY.MM.DD'));
      }

      startDate.sort((a, b) => {
        return moment(a).diff(moment(b));
      });

      endDate.sort((a, b) => {
        return moment(a).diff(moment(b));
      });
      endDate.reverse();

      console.log('startDate', startDate);
      console.log('endDate', endDate);

      const gatingDate = {
        gatingId: props.params.gatingId,
        startDate: startDate[0],
        endDate: endDate[0],
      };

      console.log('gatingDate', gatingDate);

      openCommonModal({
        modalType: 'confirm',
        content: t(
          'gtng.msg.점검중인 Gating에 장비를 변경하시겠습니까?',
          '점검중인 Gating에 장비를 변경하시겠습니까?'
        ),
        yesCallback: () => {
          setEquipChangeList(_.uniqBy(equipChangeList, 'equipmentId'));
          const saveRows = _.uniqBy(equipChangeList, 'equipmentId')
            .map((rowNode) => {
              return rowNode.crudKey ? rowNode : null;
            })
            .filter((element) => element !== null) as EquipRowData[];
          _.uniqBy(saveRows, 'equipmentId');
          console.log('saveRows', saveRows);

          const data = saveRows;
          for (let i = 0; i < data.length; i++) {
            data[i].startDate = moment(data[i].startDate).format('YYYY.MM.DD');
            data[i].endDate = moment(data[i].endDate).format('YYYY.MM.DD');
          }

          saveEquipments(saveRows).then((result) => {
            if (!result) {
              openCommonModal({
                content: t(
                  'com.label.저장 중 오류가 발생했습니다.',
                  '저장 중 오류가 발생했습니다.'
                ),
              });
              return false;
            } else {
              updateGatingDate(gatingDate).then((result) => {
                if (result < 1) {
                  openCommonModal({
                    content: t(
                      'com.label.저장 중 오류가 발생했습니다.',
                      '저장 중 오류가 발생했습니다.'
                    ),
                  });
                  return false;
                }
              });
              props.setGatingProgressStatusCode('B');
              props.setPopup(false);
              setDelData([]);
            }
          });
        },
        noCallback: () => {
          return false;
        },
      });
    } else {
      const noneDelData = equipChangeList.filter((element) => {
        return element.crudKey !== CrudCode.DELETE;
      });

      const data = noneDelData;
      for (let i = 0; i < noneDelData.length; i++) {
        data[i].startDate = moment(data[i].startDate).format('YYYY.MM.DD');
        data[i].endDate = moment(data[i].endDate).format('YYYY.MM.DD');
      }

      console.log('data', data);
      const equipData = { equipChangeList: data };
      props.setEquipData(equipData);
      props.setPopup(false);
    }
  };

  // const onCellValueChanged = useCallback((e: CellValueChangedEvent) => {
  //   !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  // }, []);

  // const onSelectionchange = (params) => {
  //   const selectedRetriveRows = equipRetrieveGridRef.current!.api.getSelectedRows();
  //   setEquipInfo(selectedRetriveRows);
  // };

  // const onRowSelected = () => {
  //   const selectedChangeRows = equipChangeGridRef.current!.api.getSelectedRows();
  //   if (selectedChangeRows.length > 0) {
  //     setProgressStatusCode(selectedChangeRows[0].progressStatusCode);
  //   }
  // };

  const getEquipmentList = async (searchEquMasterData) => {
    await getGatingEquipmentMst(searchEquMasterData).then((result: any) => {
      setEquipRetrieveList(result);
    });
  };

  const btnAddRow = () => {
    // const selectedRowNodes = equipRetrieveGridRef.current!.api.getSelectedNodes();
    const selectedRowNodes = equipRetrieveFlexRef.selectedRows;

    if (selectedRowNodes.length > 0) {
      const addData = selectedRowNodes.map((data) => {
        const newRow = {
          gatingId: props.params.gatingId,
          crudKey: CrudCode.CREATE,
          equipmentId: data.dataItem.equipmentId,
          equipmentName: data.dataItem.equipmentName,
        } as EquipRowData;
        return newRow;
      });

      const eqiupLenght = (equipChangeList || []).length + addData.length;

      if (eqiupLenght > 100) {
        setOpen(true);
        setValidation('ADD');
      } else {
        setEquipChangeList(_.uniqBy(equipChangeList?.concat(addData), 'equipmentId'));
      }
    }
  };

  const btnAddBlanckRow = () => {
    // 데이터가 없는 Row를 crudKey: C 를 주어 생성함.
    const newRow = {
      crudKey: CrudCode.CREATE,
      gatingId: props.params.gatingId,
      // equipmentId: '',
      // equipmentName: '',
      // gatingEqpCheckContIds: '',
      // gatingEqpCheckContNames: '',
      // gatingEqpExecuteContIds: '',
      // gatingEqpExecuteContNames: '',
      // gatingEqpExecuteTlContIds: '',
      // gatingEqpExecuteTlContNames: '',
    } as EquipRowData;
    setEquipChangeList([newRow, ...(equipChangeList || [])]);
  };

  const btnDelRow = () => {
    // const selectedRowNodes = equipChangeGridRef.current!.api.getSelectedNodes();
    const selectedRowNodes = equipChangeFlexRef.selectedRows;
    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode._idx!);
      })
      .reverse();
    selectedIds.forEach((idx) => {
      if (
        equipChangeList[idx].progressStatusCode == 'B1' ||
        equipChangeList[idx].crudKey == CrudCode.CREATE ||
        equipChangeList[idx].progressStatusCode == null
      ) {
        equipChangeList[idx].crudKey = CrudCode.DELETE;
        const filteredDelData = [
          ...delData,
          ...equipChangeList.filter((data) => data.crudKey == CrudCode.DELETE),
        ];
        setDelData(filteredDelData);

        const filteredData = equipChangeList.filter((element) => element !== undefined);
        setEquipChangeList(filteredData);
      } else {
        setOpen(true);
        setValidation('DELETE');
      }
    });
  };

  return (
    <>
      <Dialog
        disableEnforceFocus
        open={true}
        css={di.dialog}
        fullWidth
        className="xl"
        maxWidth={false}
      >
        <TitleWrap>
          <h2>{t('gtng.label.Gating 장비 찾기', 'Gating 장비 찾기')}</h2>
          <Button
            onClick={() => {
              props.setPopup(false);
            }}
          ></Button>
        </TitleWrap>
        <DialogContent>
          <SearchBox>
            <SearchBoxRow>
              <InputBox>
                <SearchRows>
                  <SearchCols>
                    <label>{t('com.label.유형', '유형')}</label>
                    <ComboBox
                      options={gatingEqpCode}
                      placeholder={String(t('com.label.전체', '전체'))}
                      defaultValue={searchParam.eqpType}
                      onChange={(value) => handleEquipType(value)}
                    />
                  </SearchCols>
                  <SearchCols>
                    <label>{t('com.label.설비', '설비')}</label>
                    <CustomInputWrap>
                      <CustomInputText
                        onChange={handleSearchParam}
                        name="equipmentId"
                        value={searchParam.equipmentId}
                        placeholder={String(
                          t('gtng.msg.설비ID를 입력해 주세요.', '설비ID를 입력해 주세요.')
                        )}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            getEquipmentList(searchParam);
                          }
                        }}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </SearchCols>
                  <SearchCols>
                    <label>{t('com.label.설비명', '설비명')}</label>
                    <CustomInputWrap>
                      <CustomInputText
                        onChange={handleSearchParam}
                        name="equipmentName"
                        placeholder={String(
                          t('gtng.msg.설비명을 입력해 주세요.', '설비명을 입력해 주세요.')
                        )}
                        value={searchParam.equipmentName}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            getEquipmentList(searchParam);
                          }
                        }}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </SearchCols>
                </SearchRows>
              </InputBox>
              <SearchButtonWrap>
                <Button
                  css={IconButton.button}
                  className="reload"
                  onClick={btnReload}
                  disableRipple
                ></Button>
                <Button
                  css={IconButton.button}
                  className="find"
                  onClick={() => {
                    getEquipmentList(searchParam);
                  }}
                  disableRipple
                >
                  {t('com.button.조회', '조회')}
                </Button>
              </SearchButtonWrap>
            </SearchBoxRow>
          </SearchBox>
          <ContentFlex>
            <div style={{ width: '33%' }}>
              <SubTitleLayout>
                <SubTitleGroup>
                  <h3>{t('gtng.label.Gating Equipment', 'Gating Equipment')}</h3>
                  <span className="total">
                    {t('com.label.총', '총')}
                    <span>{equipRetrieveList?.length ?? 0}</span>
                    {t('com.label.건', '건')}
                  </span>
                </SubTitleGroup>
                <ControlBtnGroup>
                  <Button
                    css={IconButton.button}
                    className="plus"
                    onClick={btnAddRow}
                    disableRipple
                  >
                    {t('com.label.추가', '추가')}
                  </Button>
                </ControlBtnGroup>
              </SubTitleLayout>
              <ContentGrid
                className={equipRetrieveList.length < 1 ? 'noData' : ''}
                style={{ height: '450px' }}
              >
                <FlexGrid
                  itemsSource={equipRetrieveState.itemsSource}
                  columnGroups={equipRetrieveState.layoutDefinition}
                  // allowPinning="ColumnRange" // 열 고정 방식
                  deferResizing={false}
                  selectionMode={'Row'}
                  showMarquee={true} // 선택 윤곽선 (default: false)
                  align="center"
                  initialized={equipRetrieveOnInitialized}
                  style={{ height: (equipRetrieveList || []).length < 1 ? '' : '450px' }}
                />
                <div
                  className="noData"
                  style={{ height: (equipRetrieveList || []).length < 1 ? '410px' : '' }}
                >
                  <span>
                    {t(
                      'com.label.조회 가능한 데이터가 없습니다.',
                      '조회 가능한 데이터가 없습니다.'
                    )}
                  </span>
                </div>
              </ContentGrid>
            </div>
            <div style={{ width: '65%' }}>
              <SubTitleLayout>
                <h3></h3>
                <ControlBtnGroup>
                  <Button
                    css={IconButton.button}
                    className="addRow"
                    onClick={btnAddBlanckRow}
                    disableRipple
                  >
                    {t('com.button.행추가', '행추가')}
                  </Button>
                  {/* 행 삭제는 Gating 신규생성, 게이팅 상태 A단계, 설비 B1(점검준비) 단계에서 활성화 */}
                  {props.statusCode == 'A' ||
                  props.statusCode == null ||
                  progressStatusCode == 'B1' ||
                  progressStatusCode == null ? (
                    <Button
                      css={IconButton.button}
                      className="delRow"
                      onClick={btnDelRow}
                      disableRipple
                    >
                      {t('com.button.행삭제', '행삭제')}
                    </Button>
                  ) : null}
                  {/* <Button
                    css={IconButton.button}
                    className="minus"
                    onClick={btnDelRow}
                    disableRipple
                  >
                    행삭제
                  </Button> */}
                </ControlBtnGroup>
              </SubTitleLayout>
              <ContentGrid
                className={(equipChangeList || []).length < 1 ? 'noData' : ''}
                style={{ height: '450px' }}
              >
                <FlexGrid
                  ref={flexGridRef}
                  itemsSource={equipChangeState.itemsSource}
                  columnGroups={equipChangeState.layoutDefinition}
                  allowPinning="ColumnRange" // 열 고정 방식
                  showMarquee={true} // 선택 윤곽선 (default: false)
                  alternatingRowStep={0}
                  selectionMode={SelectionMode.Row}
                  // stickyHeaders={true} // 헤더 고정
                  align="center"
                  allowMerging="Cells"
                  beginningEdit={beginningEdit}
                  initialized={equipChangeOnInitialized}
                  autoGenerateColumns={false}
                  // isReadOnly={true}
                  style={{ height: (equipChangeList || []).length < 1 ? '' : '450px' }}
                />
                <div
                  className="noData"
                  style={{ height: (equipChangeList || []).length < 1 ? '410px' : '' }}
                >
                  <span>
                    {t(
                      'com.label.조회 가능한 데이터가 없습니다.',
                      '조회 가능한 데이터가 없습니다.'
                    )}
                  </span>
                </div>
              </ContentGrid>
              {/* <ContentGrid
                className={`ag-theme-alpine ${(equipChangeList || []).length < 1 ? 'noScrol' : ''}`}
                style={{ height: '450px' }}
              >
                <AgGridReact
                  ref={equipChangeGridRef}
                  rowData={equipChangeList}
                  columnDefs={EquipChangeColumnDefs}
                  onCellValueChanged={onCellValueChanged}
                  onRowSelected={onRowSelected}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  stopEditingWhenCellsLoseFocus={true}
                  rowMultiSelectWithClick={true}
                  overlayNoRowsTemplate={gridNoRowsTemplate}
                  groupHeaderHeight={32}
                  rowHeight={32}
                ></AgGridReact>
              </ContentGrid> */}
            </div>
          </ContentFlex>
        </DialogContent>
        <PopupBtnGroup>
          <Button
            css={IconButton.button}
            className="cancel"
            onClick={() => {
              props.setPopup(false);
            }}
            disableRipple
          >
            {t('com.button.취소', '취소')}
          </Button>
          <Button
            css={IconButton.button}
            className="confirm"
            onClick={btnEquipSelectInfo}
            disableRipple
          >
            {t('com.button.적용', '적용')}
          </Button>
        </PopupBtnGroup>
      </Dialog>
      {open ? (
        <Dialog open={true} onClose={handleClose} css={diAlert.dialog} fullWidth maxWidth={false}>
          <TitleWrap>
            <h2>{t('com.label.알림', '알림')}</h2>
            {/* 취소/확인을 하는 Alert는 "확인" 타이틀, "확인"만 하는 Alert는 "알림" 타이틀을 사용해주세요 */}
          </TitleWrap>
          <DialogContent>
            {validation == 'DELETE' && (
              <div className="AlertContent">
                {t(
                  'gtng.msg.점검준비 상태의 장비만 삭제할 수 있습니다.',
                  '점검준비 상태의 장비만 삭제할 수 있습니다.'
                )}
              </div>
            )}
            {validation == 'ADD' && (
              <div className="AlertContent">
                {t(
                  'gtng.msg.장비를 더 이상 추가 할 수 없습니다.(100개 제한)',
                  '장비를 더 이상 추가 할 수 없습니다.(100개 제한)'
                )}
              </div>
            )}
            {validation == 'EQPU' && (
              <div className="AlertContent">
                {t(
                  'gtng.msg.장비ID 또는 장비명을 입력해 주세요.',
                  '장비ID 또는 장비명을 입력해 주세요.'
                )}
              </div>
            )}
            {validation == 'CONT' && (
              <div className="AlertContent">
                {t('gtng.msg.설비별 담당자를 입력해 주세요.', '설비별 담당자를 입력해 주세요.')}
              </div>
            )}
            {validation == 'DUPLICATION' && (
              <div className="AlertContent">
                {t(
                  'gtng.msg.장비ID 또는 장비명이 중복되었습니다.',
                  '장비ID 또는 장비명이 중복되었습니다.'
                )}
              </div>
            )}
            {validation == 'DATE' && (
              <div className="AlertContent">
                {t(
                  'gtng.msg.시작일 또는 종료일을 입력해 주세요.',
                  '시작일 또는 종료일을 입력해 주세요.'
                )}
              </div>
            )}
            {validation == 'DATE2' && (
              <div className="AlertContent">
                {t(
                  'gtng.msg.종료일자가 시작일자보다 빠릅니다.',
                  '종료일자가 시작일자보다 빠릅니다.'
                )}
              </div>
            )}
          </DialogContent>
          <AlertBtnGroup>
            <Button className="cancel" onClick={handleClose} disableRipple>
              {t('com.button.확인', '확인')}
            </Button>
          </AlertBtnGroup>
        </Dialog>
      ) : null}
      {isOpenGatingContPopUp && (
        <GatingContPopUp
          setPopup={setGatingContPopUp}
          title={contPopTitle}
          setReadonly={false}
          setContData={(result) => resultContPopup(fieldId, result)}
          initParam={initParam}
          close={() => setGatingContPopUp(false)}
        />
      )}
      {isOpenGatingContListPopUp && (
        <GatingContListPopUp
          setPopup={setGatingContListPopUp}
          open={setGatingContListPopUp}
          close={() => setGatingContListPopUp(false)}
          initParam={initParam}
        />
      )}
    </>
  );
};
