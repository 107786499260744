/** @jsxImportSource @emotion/react */
import React, { useState, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { getExcelFileName } from 'utils/ExcelUtil';

interface SearchParam {
  totalStandard?: string;
}

interface Props {
  machineRowData?: any;
}

const MachineSummaryContent = ({ machineRowData }: Props, ref) => {
  const [flexRef, setFlexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();

  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();

  const [rowData, setRowData] = useState<any[]>([]);

  useImperativeHandle(ref, () => ({
    btnMachineExcelExport: () => {
      btnMachineExcelExport();
    },
  }));

  const flexLayoutDefinition = useMemo(() => {
    const prevHeaderNames = [
      '_LINE_QTY',
      '_EQP_QTY',
      '_CDA',
      '_N2',
      '_IW',
      '_IW2',
      '_PCW',
      '_WW',
      '_STEAM',
      '_NG',
      '_EX_RETURN',
      '_EX_VENT',
      '_SA',
    ];

    const parentHeaderNames = [
      String(t('ut.label.라인', '라인')),
      String(t('ut.label.설비', '설비')),
      String(t('ut.label.CDA[l/min]', 'CDA[l/min]')),
      String(t('ut.label.N₂[l/min]', 'N₂[l/min]')),
      String(t('ut.label.IW[l/min]', 'IW[l/min]')),
      String(t('ut.label.IW2[l/min]', 'IW2[l/min]')),
      String(t('ut.label.PCW[l/min]', 'PCW[l/min]')),
      String(t('ut.label.WW[l/min]', 'WW[l/min]')),
      String(t('ut.label.Steam[Ton/hr]', 'Steam[Ton/hr]')),
      String(t('ut.label.NG[N㎥/hr]', 'NG[N㎥/hr]')),
      String(t('ut.label.Ex.Return[CMH]', 'Ex.Return[CMH]')),
      String(t('ut.label.Ex.Vent[CMH]', 'Ex.Vent[CMH]')),
      String(t('ut.label.Supply Air', 'Supply Air')),
    ];

    const flexLayout = [] as any[];

    if (machineRowData !== undefined) {
      const childHeader: string[] = [];

      if (machineRowData?.col.length > 0) {
        for (let i = 0; i < prevHeaderNames.length; i++) {
          for (let j = 0; j < machineRowData.col.length; j++) {
            const data = "'" + machineRowData.col[j].KEY + "'" + prevHeaderNames[i];
            childHeader.push(data);
          }
        }
      }

      const result = {};

      for (let i = 0; i < childHeader.length; i += machineRowData.col.length) {
        const key = Math.floor(i / machineRowData.col.length);
        result[key] = childHeader.slice(i, i + machineRowData.col.length);
      }

      let prevHeaderName = '';
      let prevHeaderIdx = 0;
      if (parentHeaderNames.length > 0) {
        parentHeaderNames.forEach((item, index) => {
          // Header 추가
          if (prevHeaderName !== item) {
            prevHeaderIdx = index;
            prevHeaderName = item;
            flexLayout.push({ header: item, align: 'center', columns: [] });
          }
          machineRowData.col.forEach((item, i) => {
            flexLayout[flexLayout.length - 1]['columns'].push({
              header: i + 1,
              align: 'right',
              width: 100,
              binding: result[index][i],
            });
          });
        });
      }
    }

    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'PRDN_PROC_NM',
        header: String(t('ut.label.공정', '공정')),
        width: 150,
      },
      {
        binding: 'DTAL_PROC_NM',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: '*',
        minWidth: 150,
      },
      {
        binding: 'DTAL_PROC_CD',
        visible: false,
      },
      ...flexLayout,
    ];
  }, [machineRowData]);

  const onInitialized = (grid) => {
    setFlexRef(grid);
  };

  const btnMachineExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = '기계요약';
    book.saveAsync(getExcelFileName(t('ut.label.기계요약', '기계요약')));
  };

  return (
    <>
      <CustomGrid
        layoutDefinition={flexLayoutDefinition}
        rowData={machineRowData?.list || []}
        height={388}
        initialized={onInitialized}
        isSelector={false}
        isReadOnly={true}
        isFilter={false}
        allowPinning={false}
      />
    </>
  );
};

export default React.forwardRef(MachineSummaryContent);
