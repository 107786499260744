import { callApi } from 'utils/ApiUtil';
import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import { Employee } from '../../models/admin/Employee';

export const findUtMatrixReferrer = async (utmId: string) => {
  console.log('utmId======================-------------------------------->>> ', utmId);
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ut/review-referrer`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      utmId: utmId,
    }),
  };
  const response: CommonResponse<Employee[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as Employee[];
};
