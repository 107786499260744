/** @jsxImportSource @emotion/react */
import React, { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { BlueButton } from 'components/buttons/CustomButton';
import Dropzone from 'components/dropzone/Dropzone';
import { CustomInputText, UseToggle } from 'components/inputs/CustomInput';
import { useCommonModal } from 'hooks/useCommonModal';
import FileGrid from 'components/dropzone/FileGrid';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import LicenseFileGrid from 'components/dropzone/LicenseFileGrid';
import OrderSaveFileGrid from 'components/dropzone/OrderSaveFileGrid';
import { Button } from '@mui/material';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { IconButton } from 'components/buttons/IconSVG';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { ComboBox } from 'components/selects/ComboBox';
import FileUploadPopUp from '../common/components/FileUploadPopUp';

const DropzonSamplePage = () => {
  const { openCommonModal } = useCommonModal();
  const dropzoneRef = useRef<any>();
  const [atchFileGrId, setAtchFileGrId] = useState<string>('');
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [atchFileTpCdList, setAtchFileTpCdList] = useState<Code[]>([]);

  useEffect(() => {
    getCommonCodeNames('ATCH_FILE_TP_CD').then((value) => setAtchFileTpCdList(value));
  }, []);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAtchFileGrId(e.target.value);
  };

  const handleFileUploadModalCondition = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileUploadModalCondition({ ...fileUploadModalCondition, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    if (fileSaveResponse.fileSaveResult == FileSaveResult.FAIL) return;
    const atchFileGrId = fileSaveResponse.atchFileGrId;

    setAtchFileGrId(atchFileGrId);
    openCommonModal({
      content: '저장완료',
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardContainer>
          <CardTitle>Dropzone Sample</CardTitle>
          <hr />
          <CustomInputText
            type="text"
            value={atchFileGrId}
            placeholder="File Group Id"
            onChange={onInputChange}
          ></CustomInputText>{' '}
          <BlueButton onClick={handleSave}>저장</BlueButton>
          <Dropzone
            ref={dropzoneRef}
            singleSelect={fileUploadModalCondition.singleSelect}
            downloadOnly={fileUploadModalCondition.downloadOnly}
            atchFileGrIdInput={atchFileGrId}
            atchFileTpCd="DROP_ZONE_SAMPLE"
          />
        </CardContainer>
        <CardContainer>
          <CardTitle>FileGrid Sample</CardTitle>
          <hr />
          <FileGrid atchFileGrId={atchFileGrId} />
        </CardContainer>
        <CardContainer>
          <CardTitle>LicenseFileGrid Sample</CardTitle>
          <hr />
          <LicenseFileGrid atchFileGrId={'9e78b760-fbbb-44f4-9dbe-33b4ade846c3'} />
        </CardContainer>
        <CardContainer>
          <CardTitle>OrderSaveFileGrid Sample</CardTitle>
          <hr />
          <OrderSaveFileGrid
            atchFileGrId={'23419837-30b6-4a23-b446-2e40be76392d'}
            showAllDownload={true}
          />
        </CardContainer>
        <CardContainer>
          <CardTitle>파일업로드 Sample</CardTitle>
          <hr />
          <SearchBox>
            <SearchBoxRow>
              <InputBox>
                <SearchRows>
                  <SearchCols>
                    <label>
                      <span>그룹ID</span>
                    </label>
                    <CustomInputWithSearch
                      name="atchFileGrId"
                      placeholder="atchFileGrId"
                      value={atchFileGrId}
                      onChange={onInputChange}
                    />
                  </SearchCols>
                  <SearchCols>
                    <label>
                      <span>유형</span>
                    </label>
                    <ComboBox
                      options={atchFileTpCdList.filter((o) => !o.cmnCd?.startsWith('TPL_'))}
                      defaultValue={'NORMAL'}
                      onChange={(value: string) =>
                        setFileUploadModalCondition({
                          ...fileUploadModalCondition,
                          atchFileTpCd: value,
                        })
                      }
                    />
                  </SearchCols>
                  <SearchCols>
                    <label>
                      <span className="dot">tableName</span>
                    </label>
                    <CustomInputWithSearch
                      name="tableName"
                      placeholder="업무 테이블명(확인용)"
                      value={fileUploadModalCondition.tableName}
                      onChange={handleFileUploadModalCondition}
                    />
                  </SearchCols>
                </SearchRows>
                <SearchRows>
                  <SearchCols>
                    <label>
                      <span>업무</span>
                    </label>
                    <ComboBox
                      options={[
                        { cmnCd: 'etc', cmnCdNm: 'etc' },
                        { cmnCd: 'ip', cmnCdNm: 'ip' },
                        { cmnCd: 'gtng', cmnCdNm: 'gtng' },
                      ]}
                      defaultValue={'etc'}
                      onChange={(value: string) =>
                        setFileUploadModalCondition({
                          ...fileUploadModalCondition,
                          bizName: value,
                        })
                      }
                    />
                  </SearchCols>
                  <SearchCols>
                    <label htmlFor="singleSelect">1건/N건</label>
                    <UseToggle className="switch">
                      <input
                        type="checkbox"
                        id="singleSelect"
                        name="singleSelect"
                        value={fileUploadModalCondition.singleSelect}
                        checked={fileUploadModalCondition.singleSelect}
                        onChange={() => {
                          setFileUploadModalCondition({
                            ...fileUploadModalCondition,
                            singleSelect: !fileUploadModalCondition.singleSelect,
                          });
                        }}
                      />
                      <span className="slider"></span>
                      <span className="labels" data-on="1건" data-off="N건"></span>
                    </UseToggle>
                  </SearchCols>
                  <SearchCols>
                    <label htmlFor="downloadOnly">D/U</label>
                    <UseToggle className="switch">
                      <input
                        type="checkbox"
                        id="downloadOnly"
                        name="downloadOnly"
                        value={fileUploadModalCondition.downloadOnly}
                        checked={fileUploadModalCondition.downloadOnly}
                        onChange={() => {
                          setFileUploadModalCondition({
                            ...fileUploadModalCondition,
                            downloadOnly: !fileUploadModalCondition.downloadOnly,
                          });
                        }}
                      />
                      <span className="slider"></span>
                      <span className="labels" data-on="다운로드" data-off="업로드"></span>
                    </UseToggle>
                  </SearchCols>
                </SearchRows>
              </InputBox>
              <SearchButtonWrap>
                <Button
                  css={IconButton.button}
                  className="find"
                  onClick={() => {
                    setOpenFileUploadModal(true);
                  }}
                  disableRipple
                >
                  팝업열기
                </Button>
              </SearchButtonWrap>
            </SearchBoxRow>
          </SearchBox>
        </CardContainer>
      </div>

      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          singleSelect={fileUploadModalCondition.singleSelect}
          initParam={{
            atchFileGrId: atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(value) => {
            if (value) {
              setAtchFileGrId(value);
            }
          }}
        />
      )}
    </>
  );
};

const CardContainer = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
  padding: 20px;
  align-content: space-around;
  hr {
    width: 100%;
  }
  justify-items: start;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
export default DropzonSamplePage;
