import {
  SensorApprovalRequest,
  SensorMasterBase,
  SensorRequestCondition,
} from 'models/sensor/SensorRequest';
import { PaginationResponse } from 'models/common/Pagination';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

/**
 * 센서 요청 목록 조회
 * @param condition
 */
export const findSensorReqList = async (condition?: SensorRequestCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/sensor/sensor-request`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<PaginationResponse<SensorMasterBase>> = await callApi(request);

  return (
    response.successOrNot === 'Y' ? response.data : null
  ) as PaginationResponse<SensorMasterBase>;
};

/**
 * 센서사양 결재요청 저장
 * @param approvalRequest
 */
export const createSensorRequest = async (approvalRequest?: SensorApprovalRequest) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/sensor-request`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: approvalRequest,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 센서사양 결재요청 삭제
 * @param approvalRequest
 */
export const deleteSensorRequest = async (approvalRequest?: SensorApprovalRequest) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/sensor-request/delete`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: approvalRequest,
  };
  const response: CommonResponse = await callApi(request);
  return response;
};
