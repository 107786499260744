/** @jsxImportSource @emotion/react */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, FormControl } from '@mui/material';
import { getCommonCodes } from 'apis/admin/CommonCode';
import { findMessagesMsgCtn, saveMessages } from 'apis/admin/Message';
import { useMessageModalStore } from 'stores/useMessageModalStore';
import { Message, MessageInfo } from 'models/admin/Message';
import { CommonYN } from 'models/common/Common';
import { Code } from 'models/common/CommonCode';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { CrudCode } from 'models/common/Edit';
import { ViewTable } from 'components/tables/ViewTable';
import {
  CustomInputText,
  CustomInputWrap,
  CustomTextarea,
  UseToggle,
} from 'components/inputs/CustomInput';
import { IconButton } from 'components/buttons/IconSVG';
import CustomDialog from 'components/modals/common/CustomDialog';

const getEmptyMessageInfo = () => {
  return {
    msgCtn: '',
    msgTxtCtn1: '',
    msgTxtCtn2: '',
    msgTxtCtn3: '',
    msgTxtCtn4: '',
    msgTxtCtn5: '',
    rmk: '',
    useYn: CommonYN.Y,
  } as MessageInfo;
};

export const MessageModal = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const {
    isOpen,
    msgCtn,
    animation,
    koMessage,
    yesCallback,
    noCallback,
    setMessageModalStateWhenModalClose,
  } = useMessageModalStore();

  const [languageCodes, setLanguageCodes] = useState<Code[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [msgInfo, setMsgInfo] = useState<MessageInfo>(getEmptyMessageInfo());

  useEffect(() => {
    if (isOpen) {
      if (_.isUndefined(msgCtn)) {
        openMessageBar({
          type: 'error',
          content: t('com.label.다시 시도해주세요.', '다시 시도해주세요.'),
        });
        setMessageModalStateWhenModalClose();
        return;
      }

      (async () => {
        const languageCodesApiResult = [...((await getCommonCodes('LANG_CD')) ?? [])];
        const messagesApiResult = [...((await findMessagesMsgCtn({ msgCtn: msgCtn })) ?? [])];

        setLanguageCodes([...languageCodesApiResult]);
        setMessages([...messagesApiResult]);

        if (_.isEmpty(languageCodesApiResult) || _.isEmpty(messagesApiResult)) {
          openCommonModal({
            modalType: 'confirm',
            content: t(
              'com.label.해당하는 메시지코드가 없습니다. 추가하시겠습니까?',
              '해당하는 메시지코드가 없습니다. 추가하시겠습니까?'
            ),
            noCallback: () => {
              setMessageModalStateWhenModalClose();
              return;
            },
          });
        }

        setMsgInfo(() => {
          const msgInfoResult = {
            msgCtn: msgCtn ?? '',
            useYn: CommonYN.Y,
            rmk: messagesApiResult[0]?.rmk ?? '',
          } as MessageInfo;

          for (let i = 0; i < languageCodesApiResult.length; i++) {
            const languageCode = languageCodesApiResult[i].cmnCd ?? '';
            const findMessages = messagesApiResult.filter(
              (message) => message.langCd === languageCode
            );
            if (findMessages.length !== 0) {
              msgInfoResult[`msgTxtCtn${i + 1}`] = findMessages[0].msgTxtCtn;
            } else if (koMessage && koMessage !== '') {
              msgInfoResult['msgTxtCtn1'] = koMessage;
              msgInfoResult[`msgTxtCtn${i + 1}`] = '';
            }
          }
          // if (koMessage && koMessage !== '') {
          //   msgInfoResult['msgTxtCtn1'] = koMessage;
          // }

          return msgInfoResult;
        });
      })();

      document.body.style.top = `-${window.pageYOffset}px`;
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
    } else {
      setMsgInfo(getEmptyMessageInfo());
    }

    return () => {
      const heightScroll = parseInt(document.body.style.top || '0', 10) * -1;
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('width');
      document.body.style.removeProperty('top');
      window.scrollTo(0, heightScroll);
    };
  }, [isOpen]);

  const handleNoButtonClick = useCallback(async () => {
    setMessageModalStateWhenModalClose();
    if (noCallback) {
      noCallback();
    }
  }, [noCallback, setMessageModalStateWhenModalClose]);

  const handleYesButtonClick = useCallback(async () => {
    const { rmk, useYn, msgCtn, ...restOfMsgTxtCtn } = msgInfo;

    const messagesToSave = [] as Message[];

    for (let i = 0; i < languageCodes.length; i++) {
      const targetLangCd = languageCodes[i].cmnCd || '';
      const targetMsgTxtCtn = _.get(restOfMsgTxtCtn, `msgTxtCtn${i + 1}`);
      if (_.isEmpty(targetMsgTxtCtn)) {
        openMessageBar({
          type: 'error',
          content: t('com.label.메시지 내용을 입력하세요.', '메시지 내용을 입력하세요.'),
        });
        return;
      } else {
        messagesToSave.push({
          crudKey: _.isEmpty(messages.find((message) => message.langCd === targetLangCd))
            ? CrudCode.CREATE
            : CrudCode.UPDATE,
          msgCtn: msgCtn,
          langCd: targetLangCd,
          msgTxtCtn: targetMsgTxtCtn || '',
          rmk: rmk,
          useYn: useYn,
          optValCtn1: null,
          optValCtn2: null,
          optValCtn3: null,
          dataInsUserId: null,
          dataInsUserIp: null,
          dataInsDtm: null,
          dataUpdUserId: null,
          dataUpdUserIp: null,
          dataUpdDtm: null,
        });
      }
    }

    const saveApiResult = await saveMessages(messagesToSave);

    if (saveApiResult) {
      setMessageModalStateWhenModalClose();
      if (yesCallback) {
        yesCallback();
      }
    } else {
      openMessageBar({
        type: 'error',
        content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
      });
    }
  }, [yesCallback, setMessageModalStateWhenModalClose, msgInfo, languageCodes]);

  const dialogButtons = [
    <Button
      key={'save'}
      css={IconButton.button}
      className="save"
      onClick={handleYesButtonClick}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  return (
    <>
      {isOpen && (
        <CustomDialog
          title={t('com.label.메시지 관리', '메시지 관리')}
          open={isOpen}
          onClose={handleNoButtonClick}
          onCancel={handleNoButtonClick}
          buttons={dialogButtons}
        >
          <FormControl>
            <ViewTable>
              <colgroup>
                <col width="15%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">{t('com.label.메시지코드', '메시지코드')}</th>
                  <td>
                    <CustomInputWrap>
                      <CustomInputText
                        id="msgCtn"
                        name="msgCtn"
                        type="text"
                        value={msgInfo.msgCtn}
                        disabled
                      ></CustomInputText>
                    </CustomInputWrap>
                  </td>
                </tr>
                {languageCodes.map((languageCode, index) => {
                  const langName = languageCode.cmnCdNm;
                  return (
                    <tr key={`msgTxtCtn${index + 1}`}>
                      <th scope="row">
                        <label htmlFor={`msgTxtCtn${index + 1}`}>{langName}</label>
                      </th>
                      <td>
                        <CustomTextarea
                          id={`msgTxtCtn${index + 1}`}
                          className="fullWidth"
                          name={`msgTxtCtn${index + 1}`}
                          key={`msgTxtCtn${index + 1}`}
                          // type="text"
                          value={msgInfo[`msgTxtCtn${index + 1}`]}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                            setMsgInfo((prevState) => {
                              _.set(prevState, `msgTxtCtn${index + 1}`, e.target.value);
                              return { ...prevState };
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th scope="row">
                    <label htmlFor="rmk">{t('com.label.비고', '비고')}</label>
                  </th>
                  <td>
                    <CustomTextarea
                      id="rmk"
                      name="rmk"
                      value={msgInfo.rmk}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setMsgInfo((prevState) => {
                          return { ...prevState, rmk: e.target.value };
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label htmlFor="useYn">{t('com.label.사용여부', '사용여부')}</label>
                  </th>
                  <td>
                    {/* <CustomSelect
                        id="useYn"
                        defaultValue={msgInfo.useYn}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          setMsgInfo((prevState) => {
                            return { ...prevState, useYn: e.target.value as CommonYN };
                          });
                        }}
                      >
                        {commonYNList.map((useYnCode) => (
                          <option key={useYnCode.cmnCd} value={useYnCode.cmnCd}>
                            {useYnCode.cmnCdNm}
                          </option>
                        ))}
                      </CustomSelect> */}
                    <UseToggle className="switch">
                      <input
                        type="checkbox"
                        name="useYn"
                        value={msgInfo.useYn}
                        checked={msgInfo.useYn === CommonYN.Y}
                        onChange={(e) => {
                          setMsgInfo((prevState) => {
                            return {
                              ...prevState,
                              useYn: e.target.value === CommonYN.Y ? CommonYN.N : CommonYN.Y,
                            };
                          });
                        }}
                      />
                      <span className="slider"></span>
                      <span
                        className="labels"
                        data-on={t('com.label.사용', '사용')}
                        data-off={t('com.label.미사용', '미사용')}
                      ></span>
                    </UseToggle>
                  </td>
                </tr>
              </tbody>
            </ViewTable>
          </FormControl>
        </CustomDialog>
      )}
    </>
  );
};
