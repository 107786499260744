/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from 'components/layouts/Dialog';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import CustomSwitch from 'components/selects/CustomSwitch';
import { useCommonModal } from 'hooks/useCommonModal';
import { setExcpUserSecrPldgY, getLatestSecrPldgDesc } from 'apis/admin/ExcpUser';
import CustomTextarea from 'components/inputs/CustomTextarea';
import dayjs from 'dayjs';

type Props = {
  open: boolean;
  close: () => void;
  userId: string;
  empNm: string;
  deptNm: string;
  onCallback: (isAgreed) => void;
};
/**
 * @param open
 * @param close
 * @param isEditable
 * @param condition
 * @param onCallback
 * @constructor
 */
export const SecrPldgPopUp = ({ open, close, userId, empNm, deptNm, onCallback }: Props) => {
  const { t } = useTranslation();
  const [secrPldgDesc, setSecrPldgDesc] = useState<string>('');
  const [isAgreed, setAgreed] = useState<boolean>(false);
  const [vEmpNm, setEmpNm] = useState<string>(empNm);
  const [vDeptNm, setDeptNm] = useState<string>(deptNm);
  const { openCommonModal } = useCommonModal();

  useEffect(() => {
    initCondition();
  }, []);

  const initCondition = async () => {
    await getLatestSecrPldgDesc().then((result: any) => {
      setSecrPldgDesc(result);
    });
  };

  const handleConfirm = async () => {
    if (isAgreed) {
      const response = await setExcpUserSecrPldgY(userId);
      const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
      if (response.updatedRows) {
        onCallback(isAgreed);
        close();
      }
    } else {
      openCommonModal({
        content: t(
          'com.label.보안 서약서에 동의 하지 않으시면 사이트 이용이 불가 합니다. 진행 하시겠습니까?',
          '보안 서약서에 동의 하지 않으시면 사이트 이용이 불가 합니다. 진행 하시겠습니까?'
        ),
      });
    }
  };

  return (
    <>
      <Dialog open={open} css={di.dialog} fullWidth className="md" maxWidth={false}>
        <TitleWrap>
          <h2>{t('gtng.label.보안 서약서', '보안 서약서')}</h2>
          <Button onClick={() => close()} disableRipple></Button>
        </TitleWrap>
        <DialogContent>
          <ContentGrid className={'noData'} style={{ height: '300px', marginBottom: '22px' }}>
            <CustomTextarea
              className="height200"
              value={secrPldgDesc
                .replaceAll('&amp;lsquo;', "'")
                .replaceAll('&amp;rsquo;', "'")
                .replaceAll('&amp;rsquo;', "'")
                .replaceAll('[계약 상대방의 상호를 기재]', ' ' + vDeptNm)
                .replaceAll('[서약자]', ' ' + vEmpNm)
                .replaceAll('[서약일자]', dayjs(new Date()).format('YYYY.MM.DD').toString())}
              readOnly={true}
              wrapStyle={{ height: '300px' }}
            />
          </ContentGrid>
          <CustomSwitch
            id={'test'}
            onValue={'Y'} // 스위치 on 값
            onLabel={'동의'} // 스위치 on 문구
            offValue={'N'} // 스위치 off 값
            offLabel={'미동의'} // 스위치 off 문구
            defaultValue={'N'} // 기본값 생략가능 (default: onValue)
            onChange={(value) => (value == 'Y' ? setAgreed(true) : setAgreed(false))}
          />
        </DialogContent>
        <PopupBtnGroup>
          <Button css={IconButton.button} className="confirm" onClick={handleConfirm} disableRipple>
            {t('com.button.확인', '확인')}
          </Button>
        </PopupBtnGroup>
      </Dialog>
    </>
  );
};
export default SecrPldgPopUp;
