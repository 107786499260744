/** @jsxImportSource @emotion/react */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ContentSection } from 'components/layouts/ContentSection';
import { BlueButton, GreyButton } from 'components/buttons/CustomButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, DialogActions } from '@mui/material';
import { simpleRequest } from 'apis/ip/IpCheckPlan';
import { useMessageBar } from 'hooks/useMessageBar';
import { TitleWrap, di } from 'components/layouts/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCellInputText from 'pages/common/components/TableCellInputText';
import { tb } from 'components/layouts/Table';
import TableCellDateRangePicker from 'pages/common/components/TableCellDateRangePicker';

type Props = {
  open: boolean;
  close: any;
  params: any;
  //save: (item: Employee[]) => void;
  singleSelect?: boolean;
};

const ChangePlanModal = (props: Props) => {
  const { openMessageBar } = useMessageBar();
  const { t } = useTranslation();
  const [generic, setGeneric] = useState({
    fatDate: {
      startDate: null,
      endDate: null,
    },
    satDate: {
      startDate: null,
      endDate: null,
    },
    rmk: '',
    ipCheckPlanName: '',
    ipCheckPlanNo: '',
  });

  const listner = (received) => {
    setGeneric({ ...generic, [received.id]: received.value });
  };

  const handlOnClose = () => {
    props.close();
  };

  const handlOnSave = async () => {
    generic.ipCheckPlanNo = props.params?.rowSingle?.ipCheckPlanNo;
    generic.ipCheckPlanName = props.params?.rowSingle?.ipCheckPlanName;
    if (!generic?.rmk.length || generic?.rmk.trim().length === 0) {
      openMessageBar({
        type: 'error',
        content: t('ip.msg.변경 사유를 입력해 주세요.', '변경 사유를 입력해 주세요.'),
      });
      return false;
    }

    await simpleRequest(generic, `/v1/updateIpCheckPlan`).then((result: any[]) => {
      if (!result) {
        openMessageBar({
          type: 'error',
          content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
        });
        return;
      }
      openMessageBar({
        type: 'confirm',
        content: t('com.label.저장되었습니다.', '저장되었습니다.'),
      });
      props.params?.callBack();
      handlOnClose();
    });
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handlOnClose}
        css={di.dialog}
        fullWidth
        className="md noScroll"
        maxWidth={false}
      >
        <TitleWrap>
          <h2>{String(t('com.label.IP점검계획 변경', 'IP점검계획 변경'))}</h2>
          <Button disableRipple onClick={handlOnClose}></Button>
        </TitleWrap>

        <DialogContent className="popupContent">
          <TableContainer css={tb.table}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCellInputText
                    name={t('ip.label.IP점검계획명', 'IP점검계획명')} // "IP점검계획명"
                    id="ipCheckPlanName"
                    onChange={listner}
                    defaultValue={props.params?.rowSingle?.ipCheckPlanName}
                    colSpan={3}
                    disabled={true}
                  ></TableCellInputText>
                </TableRow>
                <TableRow>
                  <TableCellDateRangePicker
                    name={t('ip.label.FAT', 'FAT')} // "FAT"
                    id="fatDate"
                    defaultValue={props.params?.rowSingle?.fatDate}
                    onChange={listner}
                  ></TableCellDateRangePicker>
                  <TableCellDateRangePicker
                    name={t('ip.label.SAT', 'SAT')} //"SAT"
                    id="satDate"
                    defaultValue={props.params?.rowSingle?.satDate}
                    onChange={listner}
                  ></TableCellDateRangePicker>
                </TableRow>
                <TableRow>
                  <TableCellInputText
                    name={t('ip.label.변경사유', '변경사유')} //"변경사유"
                    id="rmk"
                    onChange={listner}
                    colSpan={3}
                    required={true}
                  ></TableCellInputText>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <ContentSection>
            <DialogActions className="popupBottom">
              <BlueButton onClick={handlOnSave}>{t('com.button.저장', '저장')}</BlueButton>
              <GreyButton onClick={handlOnClose}>{t('com.button.취소', '취소')}</GreyButton>
            </DialogActions>
          </ContentSection>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChangePlanModal;
