/** @jsxImportSource @emotion/react */
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useState } from 'react';
import { ControlBtnGroup } from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import 'ui/css/multiSelect.css';

import { GuideBlock } from 'pages/sample/guide/GuideComponent';

const SampleWijmo = () => {
  const [flexRef, setflexRef] = useState<any>();
  const [rowData, setRowData] = useState([
    { id: 1, desc: '사용자 1', name: '박문수', address: '대한민국' },
    { id: 2, desc: '사용자 2', name: '홍길동', address: '미국' },
    { id: 3, desc: '사용자 3', name: '일지매', address: '일본' },
    { id: 4, desc: '사용자 4', name: '이몽룡', address: '중국' },
    { id: 5, desc: '사용자 5', name: '성춘향', address: '에스토니아' },
  ]);

  const LayoutDefinition = () => {
    return [
      {
        binding: 'id',
        align: 'left',
        width: 240,
      },
      {
        header: '개인정보',
        align: 'center',
        columns: [
          {
            binding: 'name',
            header: '이름',
            width: 150,
          },
          {
            binding: 'address',
            header: '주소',
            width: 150,
          },
        ],
      },
      {
        binding: 'desc',
        align: 'center',
        width: 150,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) return;
    const row = s.rows[e.row].dataItem;
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setflexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;
  };

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = '테스트 페이지';
    book.saveAsync('sampleExcel.xlsx');
  };

  return (
    <div>
      <GuideBlock title={'Import'} isCode={true}>
        {`
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
`}
      </GuideBlock>

      <GuideBlock title={'Layout'} isCode={true}>
        {`
const [rowData, setRowData] = useState([
  { id: 1, desc: '사용자 1', name: '박문수', address: '대한민국' },
  { id: 2, desc: '사용자 2', name: '홍길동', address: '미국' },
  { id: 3, desc: '사용자 3', name: '일지매', address: '일본' },
  { id: 4, desc: '사용자 4', name: '이몽룡', address: '중국' },
  { id: 5, desc: '사용자 5', name: '성춘향', address: '에스토니아' },
]);

const LayoutDefinition = () => {
  return [
    {
      binding: 'id',
      align: 'left',
      width: 240,
    },
    {
      header: '개인정보',
      align: 'center',
      columns: [
        {
          binding: 'name',
          header: '이름',
          width: 150,
        },
        {
          binding: 'address',
          header: '주소',
          width: 150,
        },
      ],
    },
    {
      binding: 'desc',
      align: 'center',
      width: 150,
    },
  ];
};

const state = {
  itemsSource: rowData,
  layoutDefinition: LayoutDefinition(),
};
`}
      </GuideBlock>
      <GuideBlock title={'Method'} isCode={true}>
        {`
const onFlexGridSelectionChanged = (s, e) => {
  if (s.rows == 0) return;
  const row = s.rows[e.row].dataItem;
};

const onInitialized = (grid) => {
  new Selector(grid);
  new FlexGridFilter(grid);
  setflexRef(grid);

  //셀렉트 방식
  grid.selectionMode = SelectionMode.Row;
};

//다운로드 버튼
const btnExcelExport = () => {
  const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
    includeColumnHeaders: true,
    includeRowHeaders: true,
  });
  book.sheets[0].name = '테스트 페이지';
  book.saveAsync('sampleExcel.xlsx');
};
`}
      </GuideBlock>
      <GuideBlock title={'Render'} isCode={true}>
        {`
<ControlBtnGroup>
  <Button
    css={IconButton.button}
    className="Exceldown"
    disableRipple
    onClick={btnExcelExport}
  >
    다운로드
  </Button>
</ControlBtnGroup>

<ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
  <FlexGrid
    columns={state.layoutDefinition}
    itemsSource={state.itemsSource}
    showMarquee={true}
    autoGenerateColumns={false}
    stickyHeaders={true}
    isReadOnly={true}
    formatItem={flexGridTooltip}
    selectionChanged={onFlexGridSelectionChanged}
    initialized={onInitialized}
    style={{ height: '300px' }}
  />
</ContentGrid>
`}
      </GuideBlock>
      <GuideBlock title={'Example'} isCode={true}>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            disableRipple
            onClick={btnExcelExport}
          >
            다운로드
          </Button>
        </ControlBtnGroup>

        <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
          <FlexGrid
            columns={state.layoutDefinition}
            itemsSource={state.itemsSource}
            showMarquee={true}
            autoGenerateColumns={false}
            stickyHeaders={true}
            isReadOnly={true}
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: '300px' }}
          />
        </ContentGrid>
      </GuideBlock>
    </div>
  );
};
export default SampleWijmo;
