/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { findNoticePost, createNotice, deleteNotice, modifyNotice } from 'apis/admin/Notice';
import { BbsEmployee, NoticePostDetail } from 'models/admin/Notice';
import { FileInfo, FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { useCommonModal } from 'hooks/useCommonModal';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import dayjs, { Dayjs } from 'dayjs';

import { ContentSection, GridInfoSection, GridTop } from 'components/layouts/ContentSection';
import { BlueButton, GreyButton, GreyLineButton } from 'components/buttons/CustomButton';
import { ViewTable } from 'components/tables/ViewTable';
import { CustomInputText } from 'components/inputs/CustomInput';
import DatePicker from 'components/inputs/DatePicker';
import CrossEditor from 'components/crosseditor/CrossEditor';
import { CustomSelect } from 'components/selects/CustomSelect';
import Dropzone from 'components/dropzone/Dropzone';
import { useTranslation } from 'react-i18next';
import FileGrid from 'components/dropzone/FileGrid';
import DOMPurify from 'dompurify';
import { MenuContextType } from 'models/admin/Menu';
import { MenuContext } from 'App';
import { ComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';

const mockNoticeEmployee: BbsEmployee = {
  userId: '',
  empNm: '',
  deptNm: '',
  jtiNm: '',
  jpsNm: '',
  ofcTanoPhn: '',
  email: '',
};

const emptyPostDetail: NoticePostDetail = {
  bbmNo: '',
  dataInsUser: mockNoticeEmployee,
  dataInsDtm: '',
  dataUpdUser: mockNoticeEmployee,
  dataUpdDtm: '',
  bbsTpCd: 'NOTI',
  bbsTpNm: '공지',
  ptupTgtCopCd: 'C100',
  bbmTitNm: '',
  bbmCtn: '',
  atchFiles: [],
  atchFileGrId: '',
  ptupEndDt: dayjs().add(7, 'day').format('YYYYMMDD'),
  poupStDt: dayjs().format('YYYY-MM-DD'),
  poupEndDt: dayjs().add(7, 'day').format('YYYY-MM-DD'),
  poupStTm: dayjs().format('HH:mm:ss'),
  poupEndTm: dayjs().format('HH:mm:ss'),
  poupStDtm: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  poupEndDtm: dayjs().add(7, 'day').format('YYYY-MM-DD HH:mm:ss'),
  poupEpsNuseDdn: '',
};

interface NoticeEditProps {
  editType?: 'UPDATE' | 'CREATE' | 'SELECT';
  bbmNo?: string;
  bbmCtn?: string;
  atchFileGrId?: string;
  upprMnuUrl?: string;
}

const NoticeManagementDetailPage = () => {
  const { t } = useTranslation();
  const locationState: NoticeEditProps = useLocation().state;
  const [noticeCondition, setNoticeCondition] = useState<NoticeEditProps>({
    editType: locationState?.editType,
    bbmNo: locationState?.bbmNo,
    bbmCtn: locationState?.bbmCtn,
    atchFileGrId: locationState?.atchFileGrId,
    upprMnuUrl: locationState?.upprMnuUrl,
  });
  const [noticeDetail, setNoticeDetail] = useState<NoticePostDetail>(emptyPostDetail);
  const [ptupTgtCopCd, setPtupTgtCopCd] = useState<Code[]>([]);
  const [noticeDivisionCode, setNoticeDivisionCode] = useState<Code[]>([]);
  const [refreshPtupEndDtKey, setRefreshPtupEndDtKey] = useState<number>(0);
  const [refreshPoupStDtKey, setRefreshPoupStDtKey] = useState<number>(0);
  const [refreshPoupEndDtKey, setRefreshPoupEndDtKey] = useState<number>(0);
  const editorRef = useRef<CrossEditor>(null);
  const navigate = useNavigate();
  const { openCommonModal } = useCommonModal();
  const dropzoneRef = useRef<any>();
  const menuContext = useContext<MenuContextType>(MenuContext);

  useEffect(() => {
    if (locationState) {
      setNoticeCondition(locationState);
    }
  }, [locationState]);

  const returnByteLength = (str: string) => {
    let byteLength = 0;

    for (let i = 0; i < str.length; ++i) {
      str.charCodeAt(i) > 127 ? (byteLength += 3) : byteLength++;
    }

    return byteLength;
  };

  const handleOnChange = (e) => {
    setNoticeDetail({ ...noticeDetail, [e.target.id]: e.target.value });
  };

  const handleOnDelete = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('admin.msg.게시글을 삭제하시겠습니까?', '게시글을 삭제하시겠습니까?'),
      yesCallback: async () => {
        const response = await deleteNotice(noticeDetail.bbmNo);
        if (response != null)
          openCommonModal({
            content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
            yesCallback: () => {
              navigate('/system/admin/sample/notice', { replace: true });
            },
          });
      },
    });
  };

  const handleOnCancel = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t(
        'com.label.취소하시겠습니까? 작성된 내용이 사라집니다.',
        '취소하시겠습니까? 작성된 내용이 사라집니다.'
      ),
      yesCallback() {
        navigate('/system/admin/sample/notice', { replace: true });
      },
    });
  };

  const handleOnConfirm = () => {
    if (noticeCondition.upprMnuUrl) {
      menuContext.closeCurrentTab(menuContext);
    } else {
      navigate('/system/admin/sample/notice', { replace: true });
    }
  };

  const handleEditerOnLoad = () => {
    if (noticeCondition.editType == 'UPDATE' || noticeCondition.editType == 'CREATE')
      editorRef.current?.editorRef.SetReadonly(false);
    if (noticeCondition.bbmCtn && noticeCondition.bbmCtn.length > 0)
      editorRef.current?.editorRef.SetBodyValue(noticeCondition.bbmCtn);
  };

  const saveValidation = () => {
    if (noticeDetail.bbmTitNm === '') {
      openCommonModal({
        content: String(t('admin.msg.제목을 입력해 주세요.', '제목을 입력해 주세요.')),
      });
      return false;
    }
    if (returnByteLength(noticeDetail.bbmTitNm) > 300) {
      openCommonModal({
        content: String(
          t(
            'admin.msg.제목은 300바이트를 초과할 수 없습니다.',
            '제목은 300바이트를 초과할 수 없습니다.'
          )
        ),
      });
      return false;
    }
    if (editorRef.current?.editorRef.GetBodyValue() === '<p><br></p>') {
      openCommonModal({
        content: String(t('admin.msg.본문을 입력해 주세요.', '본문을 입력해 주세요.')),
      });
      return false;
    }
    if (noticeDetail.ptupEndDt === '') {
      openCommonModal({
        content: String(t('admin.msg.게시기한을 입력해 주세요.', '게시기한을 입력해 주세요.')),
      });
      return false;
    }
    if (noticeDetail.poupStDt === '') {
      openCommonModal({
        content: String(t('admin.msg.팝업시작일을 입력해 주세요.', '팝업시작일을 입력해 주세요.')),
      });
      return false;
    }
    if (noticeDetail.poupStTm === '') {
      openCommonModal({
        content: String(
          t('admin.msg.팝업시작시간을 입력해 주세요.', '팝업시작시간을 입력해 주세요.')
        ),
      });
      return false;
    }
    if (noticeDetail.poupEndDt === '') {
      openCommonModal({
        content: String(t('admin.msg.팝업종료일을 입력해 주세요.', '팝업종료일을 입력해 주세요.')),
      });
      return false;
    }
    if (noticeDetail.poupEndTm === '') {
      openCommonModal({
        content: String(
          t('admin.msg.팝업종료시간을 입력해 주세요.', '팝업종료시간을 입력해 주세요.')
        ),
      });
      return false;
    }
    if (noticeDetail.poupEndTm === '') {
      openCommonModal({
        content: String(
          t('admin.msg.팝업종료시간을 입력해 주세요.', '팝업종료시간을 입력해 주세요.')
        ),
      });
      return false;
    }
    if (noticeDetail.poupEpsNuseDdn === '') {
      openCommonModal({
        content: String(
          t('admin.msg.다시 안보기 설정을 입력해 주세요.', '다시 안보기 설정을 입력해 주세요.')
        ),
      });
      return false;
    }
    if (!/^[0-9]+$/.test(noticeDetail.poupEpsNuseDdn as string)) {
      openCommonModal({
        content: String(
          t(
            'admin.msg.다시 안보기 설정은 숫자만 입력 가능합니다.',
            '다시 안보기 설정은 숫자만 입력 가능합니다.'
          )
        ),
      });
      return false;
    }
    return true;
  };

  const handleOnSave = async () => {
    if (!saveValidation()) return;

    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    if (fileSaveResponse.fileSaveResult == FileSaveResult.FAIL) return;
    const atchFileGrId = fileSaveResponse.atchFileGrId;

    const content = DOMPurify.sanitize(editorRef.current?.editorRef.GetBodyValue());
    let response;
    if (noticeCondition.editType === 'CREATE') {
      response = await createNotice({
        ...noticeDetail,
        bbmCtn: content,
        atchFileGrId: atchFileGrId,
      });
    } else if (noticeCondition.editType === 'UPDATE') {
      response = await modifyNotice({
        ...noticeDetail,
        bbmCtn: content,
        atchFileGrId: atchFileGrId,
      });
    }
    if (response != null) {
      openCommonModal({
        content: t('com.label.저장되었습니다.', '저장되었습니다.'),
        yesCallback: () => {
          navigate('/system/admin/sample/notice', { replace: true });
        },
      });
    } else {
      openCommonModal({
        content: t(
          'com.label.권한이 없거나 잘못된 경로입니다.',
          '권한이 없거나 잘못된 경로입니다.'
        ),
        yesCallback: () => {
          navigate('/system/admin/sample/notice', { replace: true });
        },
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      const noticePostDetail = await findNoticePost(noticeCondition.bbmNo || '');
      noticePostDetail &&
        setNoticeDetail({
          ...noticePostDetail,
          poupStDt: noticePostDetail?.poupStDtm?.substring(0, 10),
          poupStTm: noticePostDetail?.poupStDtm?.substring(11),
          poupEndDt: noticePostDetail?.poupEndDtm?.substring(0, 10),
          poupEndTm: noticePostDetail?.poupEndDtm?.substring(11),
        });
    };
    if (
      (noticeCondition.editType == 'SELECT' || noticeCondition.editType == 'UPDATE') &&
      noticeCondition.bbmNo
    )
      init();

    (async function () {
      const noticeTpCd: object[] = [];
      noticeTpCd.push(JSON.parse('{ "cmnCd": "NOTI", "cmnCdNm": "공지" }'));
      if (noticeTpCd != null) setNoticeDivisionCode(noticeTpCd);

      const copCD = await getCommonCodeNames('COP_CD');
      if (copCD != null) setPtupTgtCopCd(copCD);
    })();
  }, [noticeCondition.bbmNo]);

  return (
    <>
      <ContentSection className="marginT0">
        <ViewTable>
          <colgroup>
            <col width="13%" />
            <col />
            <col width="13%" />
            <col />
            <col width="13%" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label>{t('admin.label.게시판분류', '게시판분류')}</label>
              </th>
              <td>
                {noticeCondition.editType === 'SELECT' ? (
                  noticeDetail.bbsTpNm
                ) : (
                  <ComboBox
                    options={noticeDivisionCode}
                    defaultValue={noticeDetail.bbsTpCd}
                    onChange={handleOnChange}
                  />
                )}
              </td>
              <th scope="row">
                <label>{t('admin.label.게시 법인', '게시 법인')}</label>
              </th>
              <td>
                {noticeCondition.editType === 'SELECT' ? (
                  noticeDetail.ptupTgtCopNm
                ) : (
                  <ComboBox
                    options={ptupTgtCopCd}
                    placeholder={String('전체')}
                    defaultValue={noticeDetail.ptupTgtCopCd}
                    onChange={handleOnChange}
                  />
                )}
              </td>
              <th scope="row">
                <label>{t('admin.label.게시기한', '게시기한')}</label>
              </th>
              <td>
                {noticeCondition.editType === 'SELECT' ? (
                  noticeDetail.ptupEndDt?.substring(0, 4) +
                  '-' +
                  noticeDetail.ptupEndDt?.substring(4, 6) +
                  '-' +
                  noticeDetail.ptupEndDt?.substring(6, 8)
                ) : (
                  <DatePicker
                    key={refreshPtupEndDtKey}
                    date={dayjs(noticeDetail.ptupEndDt as string)}
                    changeDate={(date: Dayjs) => {
                      if (
                        date.format('YYYY-MM-DD') < (noticeDetail.poupStDt as string) ||
                        date.format('YYYY-MM-DD') < (noticeDetail.poupEndDt as string)
                      ) {
                        openCommonModal({
                          content: String(
                            t(
                              'admin.msg.게시기한은 팝업시작일 혹은 팝업종료일보다 이후 날짜로 선택해주세요.',
                              '게시기한은 팝업시작일 혹은 팝업종료일보다 이후 날짜로 선택해주세요.'
                            )
                          ),
                        });
                        setRefreshPtupEndDtKey((prevKey) => prevKey + 1);
                        return;
                      }
                      date &&
                        setNoticeDetail({ ...noticeDetail, ptupEndDt: date.format('YYYYMMDD') });
                    }}
                    mindate={dayjs().add(1, 'day')}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('admin.label.제목', '제목')}</label>
              </th>
              <td colSpan={5}>
                {noticeCondition.editType === 'SELECT' ? (
                  noticeDetail.bbmTitNm
                ) : (
                  <CustomInputWithSearch
                    id="bbmTitNm"
                    placeholder={String(
                      t('admin.msg.제목을 입력해 주세요.', '제목을 입력해 주세요.')
                    )}
                    value={noticeDetail?.bbmTitNm}
                    onChange={handleOnChange}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('admin.label.본문', '본문')}</label>
              </th>
              <td colSpan={5}>
                {noticeCondition.editType === 'SELECT' ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(noticeDetail?.bbmCtn + ''),
                    }}
                  ></div>
                ) : (
                  <div>
                    <CrossEditor
                      ref={editorRef}
                      name="crosseditor"
                      params={{
                        Width: '100%',
                        Height: 500,
                        Chevron: true,
                        Readonly: true,
                      }}
                      value={noticeDetail.bbmCtn}
                      onLoaded={handleEditerOnLoad}
                    />
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('com.label.첨부', '첨부')}</label>
              </th>
              <td colSpan={5}>
                {noticeCondition.editType === 'SELECT' ? (
                  <FileGrid atchFileGrId={noticeCondition.atchFileGrId ?? ''} />
                ) : (
                  <Dropzone ref={dropzoneRef} atchFileGrIdInput={noticeCondition.atchFileGrId} />
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label>{t('admin.label.Popup 시작일시', 'Popup 시작일시')}</label>
              </th>
              <td>
                {noticeCondition.editType === 'SELECT' ? (
                  <div>
                    <span>{noticeDetail.poupStDt}</span> <span>{noticeDetail.poupStTm}</span>
                  </div>
                ) : (
                  <div>
                    <span style={{ display: 'inline-block' }}>
                      <DatePicker
                        key={refreshPoupStDtKey}
                        date={dayjs(noticeDetail.poupStDt as string)}
                        changeDate={(date: Dayjs) => {
                          if (
                            date.format('YYYY-MM-DD') > (noticeDetail.poupEndDt as string) ||
                            date.format('YYYYMMDD') > (noticeDetail.ptupEndDt as string)
                          ) {
                            openCommonModal({
                              content: String(
                                t(
                                  'admin.msg.팝업시작일은 게시기한 혹은 팝업종료일보다 이전 날짜로 선택해주세요.',
                                  '팝업시작일은 게시기한 혹은 팝업종료일보다 이전 날짜로 선택해주세요.'
                                )
                              ),
                            });
                            setRefreshPoupStDtKey((prevKey) => prevKey + 1);
                            return;
                          }
                          date &&
                            setNoticeDetail({
                              ...noticeDetail,
                              poupStDt: date.format('YYYY-MM-DD'),
                              poupStDtm: date.format('YYYY-MM-DD') + ' ' + noticeDetail.poupStTm,
                            });
                        }}
                        mindate="today"
                      />
                    </span>
                    <span> </span>
                    <span style={{ display: 'inline-block' }}>
                      <CustomInputText
                        type="time"
                        value={noticeDetail.poupStTm}
                        min="00:00:00"
                        max="23:59:59"
                        onChange={(e) => {
                          e.target.value &&
                            setNoticeDetail({
                              ...noticeDetail,
                              poupStTm: e.target.value,
                              poupStDtm: noticeDetail.poupStDt + ' ' + e.target.value,
                            });
                        }}
                      />
                    </span>
                  </div>
                )}
              </td>
              <th scope="row">
                <label>{t('admin.label.Popup 종료일시', 'Popup 종료일시')}</label>
              </th>
              <td>
                {noticeCondition.editType === 'SELECT' ? (
                  <div>
                    <span>{noticeDetail.poupStDt}</span> <span>{noticeDetail.poupStTm}</span>
                  </div>
                ) : (
                  <div>
                    <span style={{ display: 'inline-block' }}>
                      <DatePicker
                        key={refreshPoupEndDtKey}
                        date={dayjs(noticeDetail.poupEndDt as string)}
                        changeDate={(date: Dayjs) => {
                          if (
                            date.format('YYYY-MM-DD') < (noticeDetail.poupStDt as string) ||
                            date.format('YYYYMMDD') > (noticeDetail.ptupEndDt as string)
                          ) {
                            openCommonModal({
                              content: String(
                                t(
                                  'admin.msg.팝업종료일은 게시기한 이전 혹은 팝업시작일 이후 날짜로 선택해주세요.',
                                  '팝업종료일은 게시기한 이전 혹은 팝업시작일 이후 날짜로 선택해주세요.'
                                )
                              ),
                            });
                            setRefreshPoupEndDtKey((prevKey) => prevKey + 1);
                            return;
                          }
                          date &&
                            setNoticeDetail({
                              ...noticeDetail,
                              poupEndDt: date.format('YYYY-MM-DD'),
                              poupEndDtm: date.format('YYYY-MM-DD') + ' ' + noticeDetail.poupEndTm,
                            });
                        }}
                        mindate="today"
                      />
                    </span>
                    <span> </span>
                    <span style={{ display: 'inline-block' }}>
                      <CustomInputText
                        type="time"
                        value={noticeDetail.poupEndTm}
                        min="00:00:00"
                        max="23:59:59"
                        onChange={(e) => {
                          e.target.value &&
                            setNoticeDetail({
                              ...noticeDetail,
                              poupEndTm: e.target.value,
                              poupEndDtm: noticeDetail.poupEndDt + ' ' + e.target.value,
                            });
                        }}
                      />
                    </span>
                  </div>
                )}
              </td>
              <th scope="row">
                <label>{t('admin.label.다시 안보기 설정', '다시 안보기 설정')}</label>
              </th>
              <td>
                {noticeCondition.editType === 'SELECT' ? (
                  noticeDetail.poupEpsNuseDdn
                ) : (
                  <CustomInputText
                    id="poupEpsNuseDdn"
                    type="number"
                    min="0"
                    value={noticeDetail.poupEpsNuseDdn ?? ''}
                    onChange={handleOnChange}
                    style={{
                      textAlign: 'right',
                    }}
                  />
                )}
                {t('admin.label.일', '일')}
              </td>
            </tr>
          </tbody>
        </ViewTable>
        <GridInfoSection className="contentEnd">
          <div className="gridbtns">
            {noticeCondition.editType == 'SELECT' && (
              <GreyButton onClick={handleOnConfirm}>{t('com.button.확인', '확인')}</GreyButton>
            )}
            {(noticeCondition.editType == 'CREATE' || noticeCondition.editType == 'UPDATE') && (
              <GreyButton onClick={handleOnCancel}>{t('com.button.취소', '취소')}</GreyButton>
            )}
            {noticeCondition.editType === 'UPDATE' && (
              <GreyLineButton onClick={handleOnDelete}>
                {t('com.label.삭제', '삭제')}
              </GreyLineButton>
            )}
            {(noticeCondition.editType == 'CREATE' || noticeCondition.editType == 'UPDATE') && (
              <BlueButton onClick={handleOnSave}>{t('com.button.저장', '저장')}</BlueButton>
            )}
          </div>
        </GridInfoSection>
      </ContentSection>
    </>
  );
};

export default NoticeManagementDetailPage;
