/** @jsxImportSource @emotion/react */
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableContainer from '@mui/material/TableContainer';
import { ComboBox, MultiComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { st, Textarea } from 'components/inputs/CustomInput';
import { tb } from 'components/layouts/Table';
import { FileuploadBtn } from 'components/buttons/CustomButton';
import { findMpClass, findMpStandard } from 'apis/mp/MpEquipment';
import { countByItemId, generateAtchFileGrId } from 'apis/mp/MpItem';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { MpItem, MpItemAtchFileTpCd, MpItemTpCho, TapButtonType } from 'models/mp/MpItem';
import { EqpLvCd, EquipmentClassificationMaster } from 'models/mp/MpEquipment';
import { Code } from 'models/common/CommonCode';
import { ManagementMode } from 'models/common/Common';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { useMessageBar } from 'hooks/useMessageBar';
import { EquipmentGroupPopUp } from 'pages/common/popup/EquipmentGroupPopUp';
import FileUploadPopUp from 'pages/common/components/FileUploadPopUp';
import MpItemCopModal from 'components/modals/mp/MpItemCopModal';
import { FileImgInfo } from 'models/admin/FileImgInfo';
import { findFileImgList } from 'apis/file/File';
import CustomFileImageList from 'components/layouts/CustomFileImageList';

interface Props {
  defaultMpItem: MpItem;
  mode?: ManagementMode;
  isCopy?: boolean;
}
const MpItemInfoContent = (
  { defaultMpItem, mode = ManagementMode.CREATE, isCopy = false }: Props,
  ref
) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const loadRef = useRef(false);
  const [code, setCode] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [mpItem, setMpItem] = useState<MpItem>(defaultMpItem);
  const [isOpenMpItemCopModal, setOpenMpItemCopModal] = useState<boolean>(false);
  const [isOpenEquipmentGroupPopUp, setOpenEequipmentGroupPopUp] = useState<boolean>(false);
  const [isOpenFileUploadModal, setOpenFileUploadModal] = useState<boolean>(false);
  const [fileUploadModalCondition, setFileUploadModalCondition] = useState<any>({});

  const [fieldDisableItemTpChoCd, setFieldDisableItemTpChoCd] = useState<boolean>(false);
  const [fieldDisableItemStatCd, setFieldDisableItemStatCd] = useState<boolean>(false);

  const isHe = useMemo(
    () =>
      [MpItemTpCho.HE.toString(), MpItemTpCho.MPHE.toString()].includes(mpItem?.itemTpChoCd || ''),
    [mpItem?.itemTpChoCd]
  );
  const isMp = useMemo(
    () =>
      [MpItemTpCho.MP.toString(), MpItemTpCho.MPHE.toString()].includes(mpItem?.itemTpChoCd || ''),
    [mpItem?.itemTpChoCd]
  );
  const isUt = useMemo(() => (mpItem?.mpClsfCds || '').includes('UT'), [mpItem?.mpClsfCds]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      const err = {};
      // MP 유형
      if (!mpItem?.itemTpChoCd) {
        Object.assign(err, { itemTpChoCd: true });
      }
      // 상태
      if (!mpItem?.itemStatCd) {
        Object.assign(err, { itemStatCd: true });
      }
      // 개선목적 Level 1
      if (!mpItem?.iprvClsfLv1Cd) {
        Object.assign(err, { iprvClsfLv1Cd: true });
      }
      // MP분류
      if (!(mpItem?.mpClsfCds || '').length) {
        Object.assign(err, { mpClsfCds: true });
      }
      // 공정
      if (!mpItem?.procCd) {
        Object.assign(err, { procCd: true });
      }
      // 설비군
      if (!mpItem?.eqclId) {
        Object.assign(err, { eqclId: true });
      }
      // 작업구분
      if (!mpItem?.wkDivsCd) {
        Object.assign(err, { wkDivsCd: true });
      }
      // 설비분류체계
      if (!mpItem?.eqpClsfNo) {
        Object.assign(err, { eqpClsfNo: true });
      }
      // Main
      if (!mpItem?.eqpLv1Cd) {
        Object.assign(err, { eqpLv1Cd: true });
      }
      /*
      // 대상법인 ( 기본정보 저장 후 입력하는 방식이라 팝업에서 검증)
      if (isHe) {
        // 수평전개인 경우 대상법인 1개 이상 필수 설정
        if (!mpItem?.copCdNm) {
          Object.assign(err, { copCdNm: true });
        }
      }
      */
      setErrors(err);
      if (!Object.keys(err).filter((k) => err[k]).length) {
        return true;
      }
      return false;
    },
    validateItemStatCd: (stat) => {
      const err = {};
      const mpItemRptAtchFileCnt = mpItem?.mpItemRptAtchFileCnt || 0;
      const vaidEvlRptFileCnt = mpItem?.vaidEvlRptFileCnt || 0;
      const scmmMintAtchFileCnt = mpItem?.scmmMintAtchFileCnt || 0;

      if (stat === TapButtonType.ButtonMpReview && mpItemRptAtchFileCnt == 0) {
        Object.assign(err, { mpItemRptAtchFileCnt: true });
        handleFileUpload(MpItemAtchFileTpCd.MP_ITEM_RPT);
      } else if (stat === TapButtonType.ButtonMpVal && vaidEvlRptFileCnt == 0) {
        Object.assign(err, { vaidEvlRptFileCnt: true });
        handleFileUpload(MpItemAtchFileTpCd.VAID_EVL_RPT);
      } else if (stat === TapButtonType.ButtonMpValComp && scmmMintAtchFileCnt == 0) {
        Object.assign(err, { scmmMintAtchFileCnt: true });
        handleFileUpload(MpItemAtchFileTpCd.SCMM_MINT);
      } else if (stat === TapButtonType.ButtonHeUnitVal) {
        // 대표호기검증
        if (mpItemRptAtchFileCnt == 0) {
          Object.assign(err, { mpItemRptAtchFileCnt: true });
          handleFileUpload(MpItemAtchFileTpCd.MP_ITEM_RPT);
        } else if (vaidEvlRptFileCnt == 0) {
          Object.assign(err, { vaidEvlRptFileCnt: true });
          handleFileUpload(MpItemAtchFileTpCd.VAID_EVL_RPT);
        } else if (scmmMintAtchFileCnt == 0) {
          Object.assign(err, { scmmMintAtchFileCnt: true });
          handleFileUpload(MpItemAtchFileTpCd.SCMM_MINT);
        }
      } else if (stat === TapButtonType.ButtonMpHeReview && mpItemRptAtchFileCnt == 0) {
        // 검토 -> 검토중
        Object.assign(err, { mpItemRptAtchFileCnt: true });
        handleFileUpload(MpItemAtchFileTpCd.MP_ITEM_RPT);
      } else if (stat === TapButtonType.ButtonMpHeVal && vaidEvlRptFileCnt == 0) {
        // 검증 -> 검증중
        Object.assign(err, { vaidEvlRptFileCnt: true });
        handleFileUpload(MpItemAtchFileTpCd.VAID_EVL_RPT);
      } else if (stat === TapButtonType.ButtonMpHeValComp && scmmMintAtchFileCnt == 0) {
        // 검증중 -> 검증완료
        Object.assign(err, { scmmMintAtchFileCnt: true });
        handleFileUpload(MpItemAtchFileTpCd.SCMM_MINT);
      }

      setErrors(err);
      if (!Object.keys(err).filter((k) => err[k]).length) {
        return true;
      }

      return false;
    },
    getMpItem: () => {
      return mpItem;
    },
    handleItemChange: (key, value) => {
      handleItemChange(key, value);
    },
    refresh: () => {
      if (_.isEmpty(mpItem?.itemTpChoCd)) {
        setFieldDisableItemTpChoCd(false);
      } else {
        setFieldDisableItemTpChoCd(true);
      }
      setFieldDisableItemStatCd(true);
    },
  }));

  useEffect(() => {
    setMpItem(defaultMpItem);
    loadRef.current = false;
    getCommonCodesForGrid();
  }, [defaultMpItem?.itemId]);

  useEffect(() => {
    setMpItem((prev) => ({
      ...prev,
      atchFileCnt: defaultMpItem.atchFileCnt,
      specSheetAtchFileCnt: defaultMpItem.specSheetAtchFileCnt,
    }));
  }, [defaultMpItem.atchFileCnt, defaultMpItem.specSheetAtchFileCnt]);

  useEffect(() => {
    if (loadRef.current) {
      init();
    }
  }, [loadRef.current]);

  const init = () => {
    if (ManagementMode.MODIFY === mode || isCopy) {
      changeSearchConditionByItemTpChoCd(defaultMpItem?.itemTpChoCd, true);
      changeSearchConditionByMpClsfCd(defaultMpItem?.mpClsfCds, defaultMpItem?.eqclId, true);
      changeSearchConditionByIprvClsfLv1Cd(defaultMpItem?.iprvClsfLv1Cd, true);
      changeSearchConditionByEqclId(defaultMpItem?.mpClsfCds, defaultMpItem?.eqclId, true); // 24.10.01 Mp개선사항 설비분류체계는 Mp분류 선택에 따라 List up
      changeSearchConditionByEqpClsfNo(EqpLvCd.MAIN, defaultMpItem?.eqpClsfNo);
      changeSearchConditionByEqpClsfNo(EqpLvCd.MACHINE, defaultMpItem?.eqpLv1Cd);
      changeSearchConditionByEqpClsfNo(EqpLvCd.UNIT, defaultMpItem?.eqpLv2Cd);

      // Mp 유형, Mp 상태 ReadOnly
      if (_.isEmpty(mpItem?.itemTpChoCd)) {
        setFieldDisableItemTpChoCd(false);
      } else {
        setFieldDisableItemTpChoCd(true);
      }
      setFieldDisableItemStatCd(true);
    } else {
      setFieldDisableItemTpChoCd(false);
      setFieldDisableItemStatCd(true);
    }

    getPreviewImage();
  };

  const [groupsTitle, setGroupsTitle] = useState<any>([]);
  const [groupsData, setGroupsData] = useState<{ [key: string]: FileImgInfo[] }>({});

  const getPreviewImage = () => {
    mpItem?.atchFileGrId &&
      findFileImgList(mpItem?.atchFileGrId).then((data) => {
        if (data) {
          const groupedData = data.reduce((groups, item) => {
            const key = JSON.stringify({ groupId: item.atchFileGrId, id: item.atchFileId });
            if (!groups[key]) {
              groups[key] = [];
            }
            groups[key].push(item);
            return groups;
          }, {});

          const groupedTitleData = data.reduce((groups, item) => {
            const key = JSON.stringify({ groupId: item.atchFileGrId, id: item.atchFileId });
            if (!groups[key]) {
              groups[key] = [];
            } else {
              return groups;
            }
            groups[key].push({ key: key, atchFileNm: item.atchFileNm });
            return groups;
          }, {});

          const resultGroupTitle = Object.values(groupedTitleData);

          setGroupsTitle(resultGroupTitle);
          setGroupsData(groupedData);
        }
      });
  };

  /**
   * 설비군 변경에 따른 검색조건 값 설정 변경
   *
   * 재설정 항목
   * 1. 설비분류체계
   * 24.10.01 Mp개선사항 - 설비분류체계는 Mp분류 선택에 따라 List up. 설비군 변경시 설비분류체계 조회 로직 제거
   */
  const changeSearchConditionByEqclId = async (
    mpClsfCds?: string,
    eqclId?: string,
    isFirst = false
  ) => {
    if (!isFirst) {
      setMpItem((prev) => ({
        ...prev,
        eqpClsfNo: '', // 설비분류번호
        eqpLv1Cd: '',
        eqpLv2Cd: '',
        eqpLv3Cd: '',
      }));

      changeSearchConditionByEqpClsfNo(EqpLvCd.MAIN, '');
      changeSearchConditionByEqpClsfNo(EqpLvCd.MACHINE, '');
      changeSearchConditionByEqpClsfNo(EqpLvCd.UNIT, '');
    }

    let eqpClsfNoCodes = [] as Code[];
    if (mpClsfCds && mpClsfCds.length && eqclId) {
      const condition = {
        mpClsfCds: mpClsfCds,
        eqclId: eqclId,
      };
      const result = await findMpStandard(condition);
      if ((result || []).length) {
        eqpClsfNoCodes = (result || []).reduce(
          (acc, cur) => [
            ...acc,
            {
              cmnCd: cur.eqpClsfNo,
              cmnCdNm: cur.eqpClsfNm,
            } as Code,
          ],
          [] as Code[]
        );
      }
    }
    setCode((prev) => ({
      ...prev,
      eqpClsfNoCodes: eqpClsfNoCodes,
    }));
  };

  /**
   * MP분류 변경에 따른 검색조건 값 설정 변경
   *
   * 재설정 항목
   * 1. 공정
   * 2. 개선목적 Level1
   * 3. 작업구분
   * 4. 설비분류체계
   */
  const changeSearchConditionByMpClsfCd = async (
    mpClsfCds?: string,
    eqclId?: string,
    isFirst = false
  ) => {
    let optValCtn1 = '';
    if (mpClsfCds) {
      const c = (code?.mpClsfType || []).filter((o) => (mpClsfCds || '').includes(o.cmnCd));
      if (c.length) {
        optValCtn1 = c[0].optValCtn1;
      }
    }

    if (!isFirst) {
      setMpItem((prev) => ({
        ...prev,
        procCd: '', // 공정
        iprvClsfLv1Cd: '', // 개선목적 Level1
        wkDivsCd: '', // 작업구분
        eqpClsfNo: '', // 설비분류번호
        eqpLv1Cd: '',
        eqpLv2Cd: '',
        eqpLv3Cd: '',
      }));

      changeSearchConditionByEqpClsfNo(EqpLvCd.MAIN, '');
      changeSearchConditionByEqpClsfNo(EqpLvCd.MACHINE, '');
      changeSearchConditionByEqpClsfNo(EqpLvCd.UNIT, '');
    }

    let processCode = [] as Code[];
    let mpImprTypeLvl1 = [] as Code[];
    let mpWorkType = [] as Code[];
    let eqpClsfNoCodes = [] as Code[];

    // MP 분류에 따른 검색조건 코드 조회
    if (optValCtn1) {
      // 1. 공정
      processCode = await getCommonCodeNamesCondition({
        cmnGrCd: 'PROCESS_CODE',
        optValCtn1: optValCtn1,
      });

      // 2. 개선목적 Level1 (iprvClsfLv1Cd)
      mpImprTypeLvl1 = await getCommonCodeNamesCondition({
        cmnGrCd: 'MP_IMPR_TYPE_LVL1',
        optValCtn1: optValCtn1,
      });

      // 3. 작업구분
      mpWorkType = await getCommonCodeNamesCondition({
        cmnGrCd: 'MP_WK_DIVS_CD',
        optValCtn1: optValCtn1,
      });
    }

    if (mpClsfCds && mpClsfCds.length) {
      const condition = {
        mpClsfCds: mpClsfCds,
        eqclId: '', // 24.10.01 Mp개선사항 설비분류체계는 Mp분류 선택에 따라 List up
      };
      const result = await findMpStandard(condition);
      if ((result || []).length) {
        eqpClsfNoCodes = (result || []).reduce(
          (acc, cur) => [
            ...acc,
            {
              cmnCd: cur.eqpClsfNo,
              cmnCdNm: cur.eqpClsfNm,
            } as Code,
          ],
          [] as Code[]
        );
      }
    }

    setCode((prev) => ({
      ...prev,
      mpImprTypeLvl1: mpImprTypeLvl1,
      processCode: processCode,
      mpWorkType: mpWorkType,
      eqpClsfNoCodes: eqpClsfNoCodes,
    }));
  };

  /**
   * 개선목적 Level1 변경에 따른 검색조건 값 설정 변경
   *
   * 재설정 항목
   * 1. 개선목적 Level2
   */
  const changeSearchConditionByIprvClsfLv1Cd = async (iprvClsfLv1Cd?: string, isFirst = false) => {
    if (!isFirst) {
      setMpItem((prev) => ({
        ...prev,
        iprvClsfLv2Cd: '', // 개선목적 Level2
      }));
    }

    let mpImprTypeLvl2 = [] as Code[];
    // 개선목적 Level1에 따른 검색조건 코드 조회
    if (iprvClsfLv1Cd) {
      // 1. 개선목적 Level2 (iprvClsfLv2Cd)
      mpImprTypeLvl2 = await getCommonCodeNamesCondition({
        cmnGrCd: 'MP_IMPR_TYPE_LVL2',
        optValCtn1: iprvClsfLv1Cd,
      });
    }
    setCode((prev) => ({
      ...prev,
      mpImprTypeLvl2: mpImprTypeLvl2,
    }));
  };

  /**
   * MP유형 변경에 따른 검색조건 값 설정 변경
   *
   * 재설정 항목
   * 1. Item 상태
   */
  const changeSearchConditionByItemTpChoCd = async (itemTpChoCd?: string, isFirst = false) => {
    if (!isFirst) {
      setMpItem((prev) => ({
        ...prev,
        itemStatCd: '', // Item 상태
      }));
    }

    let mpItemStatus = [] as Code[];
    // MP 유형에 따른 검색조건 코드 조회
    if (itemTpChoCd) {
      // 1. Item 상태
      mpItemStatus = await getCommonCodeNamesCondition({
        cmnGrCd: 'MP_ITEM_STATUS',
        optValCtn1: itemTpChoCd,
      });
    }
    setCode((prev) => ({
      ...prev,
      mpItemStatus: mpItemStatus,
    }));

    // 신규 등록일 경우, MP유형에 따라 상태값 자동변경
    if (ManagementMode.CREATE === mode) {
      let itemStatCd = '';
      switch (itemTpChoCd) {
        case 'HE':
          itemStatCd = 'HE01';
          break;
        case 'MP':
          itemStatCd = 'MP01';
          break;
        case 'MPHE':
          itemStatCd = 'MPHE01';
          break;
        case 'NI':
          itemStatCd = 'NI01';
          break;
      }

      setMpItem((prev) => ({
        ...prev,
        itemStatCd: itemStatCd, // Item 상태
      }));
    }
  };

  /**
   * 설비분류체계 변경에 따른 검색조건 값 설정 변경
   *
   * 재설정 항목
   * 1. 설비 분류 Level1 (Main)
   * 2. 설비 분류 Level2 (Machine)
   * 3. 설비 분류 Level3 (Unit)
   * 4. 설비군 (24.10.01 MP 개선사항에 따른 추가)
   */
  const changeSearchConditionByEqpClsfNo = (eqpLvCd: EqpLvCd, eqpClsfNo?: string) => {
    if (eqpClsfNo) {
      const conditionKey = EqpLvCd.MAIN === eqpLvCd ? 'eqpClsfNo' : 'upprEqpClsfStrcNo';
      const condition = {
        [conditionKey]: eqpClsfNo,
        eqpLvCd: eqpLvCd,
      };
      findMpClass(condition).then((result) => {
        if ((result || []).length) {
          const eqpClsfNoCodes: Code[] = (result || []).reduce(
            (acc, cur) => [
              ...acc,
              {
                cmnCd: cur.eqpClsfStrcNo,
                cmnCdNm: cur.eqpClsfStrcNm,
              } as Code,
            ],
            [] as Code[]
          );

          const codeKey =
            EqpLvCd.MAIN === eqpLvCd
              ? 'eqpLv1Codes'
              : EqpLvCd.MACHINE === eqpLvCd
              ? 'eqpLv2Codes'
              : 'eqpLv3Codes';
          setCode((prev) => ({
            ...prev,
            [codeKey]: eqpClsfNoCodes,
          }));
        }
      });

      const substrEqpClsfNo = eqpClsfNo.substring(0, 3);

      setMpItem((prev) => ({
        ...prev,
        eqclId: substrEqpClsfNo || '',
      }));
    } else {
      const codeKey =
        EqpLvCd.MAIN === eqpLvCd
          ? 'eqpLv1Codes'
          : EqpLvCd.MACHINE === eqpLvCd
          ? 'eqpLv2Codes'
          : 'eqpLv3Codes';
      setCode((prev) => ({
        ...prev,
        [codeKey]: [],
      }));
    }
  };

  const getCommonCodesForGrid = async () => {
    const mpClsfType: Code[] = await getCommonCodeNames('MP_CLSF_TYPE'); // MP분류
    const mpItemTpCho: Code[] = await getCommonCodeNames('MP_ITEM_TP_CHO'); // MP유형

    setCode((prev) => ({
      ...prev,
      mpClsfType: mpClsfType,
      mpItemTpCho: mpItemTpCho,
    }));

    if (!loadRef.current) {
      loadRef.current = true;
    }
  };

  const handleItemChange = (name: string, value: string | string[]) => {
    setMpItem((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const handleFileUpload = async (atchFileTpCd: string) => {
    let atchFileGrId = mpItem?.atchFileGrId;
    if (_.isEmpty(atchFileGrId)) {
      atchFileGrId = await generateAtchFileGrId(mpItem?.itemId || '');
      if (atchFileGrId) {
        setMpItem((prev) => ({
          ...prev,
          atchFileGrId: atchFileGrId,
        }));
      } else {
        openMessageBar({
          type: 'error',
          content:
            t(
              'com.label.첨부파일그룹ID 생성에 실패했습니다.',
              '첨부파일그룹ID 생성에 실패했습니다.'
            ) +
            <br /> +
            t('com.label.새로고침 후 다시 시도해 주세요.', '새로고침 후 다시 시도해 주세요.'),
        });
        return;
      }
    }

    setFileUploadModalCondition({
      atchFileGrId: atchFileGrId,
      atchFileTpCd: atchFileTpCd,
      tableName: 'tb_elm_mp_item_m',
      bizName: 'mp',
    });
    setOpenFileUploadModal(true);
  };

  /**
   * Item 정보가 존재하는지 확인 후 동작 처리
   *
   * 대상법인, 표준사양서, 첨부파일은 item 저장 후 설정 가능
   */
  const handleOperationAfterSave = () => {
    return new Promise<void>((resolve) => {
      countByItemId(defaultMpItem?.itemId || '').then((result) => {
        if (result > 0) {
          resolve();
        } else if (result === 0) {
          openMessageBar({
            type: 'error',
            content: String(t('mp.msg.저장 후 설정해 주세요.', '저장 후 설정해 주세요.')),
          });
        } else {
          openMessageBar({
            type: 'error',
            content: String(
              t('mp.msg.등록 확인을 실패했습니다.', '등록 확인을 실패했습니다.') +
                <br /> +
                t('com.label.잠시 후 다시 시도해 주세요.', '잠시 후 다시 시도해 주세요.')
            ),
          });
        }
      });
    });
  };

  return (
    <>
      <TableContainer css={tb.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{String(t('mp.label.MP 유형', 'MP 유형'))}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={code?.mpItemTpCho}
                  defaultValue={mpItem?.itemTpChoCd}
                  onChange={(value) => {
                    handleItemChange('itemTpChoCd', value);
                    changeSearchConditionByItemTpChoCd(value);
                  }}
                  isError={errors?.itemTpChoCd}
                  msgError={String(
                    t('mp.msg.MP 유형을 선택해 주세요.', 'MP 유형을 선택해 주세요.')
                  )}
                  disabled={fieldDisableItemTpChoCd}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{String(t('mp.label.MP 분류', 'MP 분류'))}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  // id="mpClsfCds"
                  options={code?.mpClsfType}
                  placeholder={String(t('com.label.선택', '선택'))}
                  defaultValue={mpItem?.mpClsfCds || ''}
                  // uniqueValue={'UT'} // UT는 다른 항목과 함께 선택불가
                  onChange={(value) => {
                    handleItemChange('mpClsfCds', value as string);
                    changeSearchConditionByMpClsfCd(value, mpItem?.eqclId);
                  }}
                  isError={errors?.mpClsfCds}
                  msgError={String(
                    t('mp.msg.MP 분류를 선택해 주세요.', 'MP 분류를 선택해 주세요.')
                  )}
                />
                {/*<MultiComboBox*/}
                {/*  id="mpClsfCds"*/}
                {/*  options={code?.mpClsfType}*/}
                {/*  placeholder={String(t('com.label.선택', '선택'))}*/}
                {/*  defaultValue={mpItem?.mpClsfCds || []}*/}
                {/*  uniqueValue={'UT'} // UT는 다른 항목과 함께 선택불가*/}
                {/*  onChange={(value) => {*/}
                {/*    handleItemChange('mpClsfCds', value as string[]);*/}
                {/*    changeSearchConditionByMpClsfCd(value, mpItem?.eqclId);*/}
                {/*  }}*/}
                {/*  isError={errors?.mpClsfCds}*/}
                {/*  msgError={String(*/}
                {/*    t('mp.msg.MP 분류를 선택해 주세요.', 'MP 분류를 선택해 주세요.')*/}
                {/*  )}*/}
                {/*/>*/}
              </TableCell>
              <TableCell>
                <span className="dot">{String(t('mp.label.개선목적 LV 1', '개선목적 LV 1'))}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={code?.mpImprTypeLvl1}
                  defaultValue={mpItem?.iprvClsfLv1Cd}
                  onChange={(value) => {
                    handleItemChange('iprvClsfLv1Cd', value);
                    changeSearchConditionByIprvClsfLv1Cd(value);
                  }}
                  isError={errors?.iprvClsfLv1Cd}
                  msgError={String(
                    t('mp.msg.개선목적 LV1을 선택해 주세요.', '개선목적 LV1을 선택해 주세요.')
                  )}
                />
              </TableCell>
              <TableCell>{String(t('mp.label.개선목적 LV 2', '개선목적 LV 2'))}</TableCell>
              <TableCell>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={code?.mpImprTypeLvl2}
                  defaultValue={mpItem?.iprvClsfLv2Cd}
                  onChange={(value) => handleItemChange('iprvClsfLv2Cd', value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{String(t('mp.label.작업구분', '작업구분'))}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  placeholder={`${String(t('mp.grid.MP 유형', 'MP 유형'))}${String(
                    t('admin.msg.을 먼저 선택해 주세요.', '을 먼저 선택해 주세요.')
                  )}`}
                  options={code?.mpWorkType}
                  defaultValue={mpItem?.wkDivsCd}
                  onChange={(value) => handleItemChange('wkDivsCd', value)}
                  isError={errors?.wkDivsCd}
                  msgError={String(
                    t('mp.msg.작업구분을 선택해 주세요.', '작업구분을 선택해 주세요.')
                  )}
                />
              </TableCell>

              <TableCell>
                <span className="dot">{String(t('mp.label.공정', '공정'))}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={code?.processCode}
                  defaultValue={mpItem?.procCd}
                  onChange={(value) => handleItemChange('procCd', value)}
                  isError={errors?.procCd}
                  msgError={String(t('mp.msg.공정을 선택해 주세요.', '공정을 선택해 주세요.'))}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{String(t('mp.label.설비분류체계', '설비분류체계'))}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  placeholder={`${String(t('mp.grid.MP 분류', 'MP 분류'))}${String(
                    t('admin.msg.를 먼저 선택해 주세요.', '를 먼저 선택해 주세요.')
                  )}`}
                  options={code?.eqpClsfNoCodes}
                  defaultValue={mpItem?.eqpClsfNo}
                  onChange={(value) => {
                    handleItemChange('eqpClsfNo', value);
                    setMpItem((prev) => ({
                      ...prev,
                      eqpLv1Cd: '', // 설비 분류 Level1 (Main)
                    }));

                    setCode((prev) => ({
                      ...prev,
                      eqpLv1Codes: [],
                    }));
                    changeSearchConditionByEqpClsfNo(EqpLvCd.MAIN, value);
                  }}
                  isError={errors?.eqpClsfNo}
                  msgError={String(
                    t('mp.msg.설비분류체계를 선택해 주세요.', '설비분류체계를 선택해 주세요.')
                  )}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{String(t('com.label.설비군', '설비군'))}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  name="eqclId"
                  placeholder={String(
                    t('mp.msg.설비분류체계를 선택해 주세요.', '설비분류체계를 선택해 주세요.')
                  )}
                  value={mpItem?.eqclId}
                  onChange={(e) => {
                    handleItemChange('eqclId', e.target.value);
                    // changeSearchConditionByEqclId(mpItem?.mpClsfCds, e.target.value);
                  }}
                  // onSearchClick={() => setOpenEequipmentGroupPopUp(true)}
                  isError={errors?.eqclId}
                  msgError={String(
                    t('mp.msg.설비분류체계를 선택해 주세요.', '설비분류체계를 선택해 주세요.')
                  )}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    // if (e.key === 'Enter') {
                    //   setOpenEequipmentGroupPopUp(true);
                    // }
                  }}
                  disabled={true}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{String(t('mp.label.Main', 'Main'))}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  placeholder={`${String(t('mp.label.설비분류체계', '설비분류체계'))}${String(
                    t('admin.msg.를 먼저 선택해 주세요.', '를 먼저 선택해 주세요.')
                  )}`}
                  options={code?.eqpLv1Codes}
                  defaultValue={mpItem?.eqpLv1Cd}
                  onChange={(value) => {
                    handleItemChange('eqpLv1Cd', value);
                    setMpItem((prev) => ({
                      ...prev,
                      eqpLv2Cd: '', // 설비 분류 Level2 (Machine)
                    }));

                    setCode((prev) => ({
                      ...prev,
                      eqpLv2Codes: [],
                    }));
                    changeSearchConditionByEqpClsfNo(EqpLvCd.MACHINE, value);
                  }}
                  isError={errors?.eqpLv1Cd}
                  msgError={String(t('mp.msg.Main을 선택해 주세요.', 'Main을 선택해 주세요.'))}
                />
              </TableCell>
              <TableCell>{String(t('mp.label.Machine', 'Machine'))}</TableCell>
              <TableCell>
                <ComboBox
                  placeholder={`${String(t('mp.label.Main', 'Main'))}${String(
                    t('admin.msg.을 먼저 선택해 주세요.', '을 먼저 선택해 주세요.')
                  )}`}
                  options={code?.eqpLv2Codes}
                  defaultValue={mpItem?.eqpLv2Cd}
                  onChange={(value) => {
                    handleItemChange('eqpLv2Cd', value);
                    setMpItem((prev) => ({
                      ...prev,
                      eqpLv3Cd: '', // 설비 분류 Level3 (Unit)
                    }));

                    setCode((prev) => ({
                      ...prev,
                      eqpLv3Codes: [],
                    }));
                    changeSearchConditionByEqpClsfNo(EqpLvCd.UNIT, value);
                  }}
                />
              </TableCell>
              <TableCell>{String(t('mp.label.Unit', 'Unit'))}</TableCell>
              <TableCell>
                <ComboBox
                  placeholder={`${String(t('mp.label.Machine', 'Machine'))}${String(
                    t('admin.msg.을 먼저 선택해 주세요.', '을 먼저 선택해 주세요.')
                  )}`}
                  options={code?.eqpLv3Codes}
                  defaultValue={mpItem?.eqpLv3Cd}
                  onChange={(value) => handleItemChange('eqpLv3Cd', value)}
                />
              </TableCell>
              <TableCell>
                <span className="dot">{String(t('com.label.상태', '상태'))}</span>
              </TableCell>
              <TableCell>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={code?.mpItemStatus}
                  defaultValue={mpItem?.itemStatCd}
                  onChange={(value) => handleItemChange('itemStatCd', value)}
                  isError={errors?.itemStatCd}
                  msgError={String(t('mp.msg.상태를 선택해 주세요.', '상태를 선택해 주세요.'))}
                  readOnly={fieldDisableItemStatCd}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={isHe && !isUt ? '' : 'disabled'}>
                {String(t('mp.label.4M 여부', '4M 여부'))}
              </TableCell>
              <TableCell className={isHe && !isUt ? '' : 'disabled'}>
                <Checkbox
                  id="m4ChgYn"
                  css={st.checkbox}
                  disabled={!(isHe && !isUt)}
                  checked={mpItem?.m4ChgYn === 'Y'}
                  onChange={(e, checked) => handleItemChange('m4ChgYn', checked ? 'Y' : 'N')}
                />
              </TableCell>
              <TableCell className={isHe ? '' : 'disabled'}>
                <span className={isHe ? 'dot' : ''}>
                  {String(t('mp.label.대상법인', '대상법인'))}
                </span>
              </TableCell>
              <TableCell
                className={isHe ? '' : 'disabled'}
                onClick={() =>
                  isHe ? handleOperationAfterSave().then(() => setOpenMpItemCopModal(true)) : null
                }
              >
                <CustomInputWithSearch
                  name="copCds"
                  className="find"
                  placeholder={String(
                    t('mp.msg.대상법인을 선택해 주세요.', '대상법인을 선택해 주세요.')
                  )}
                  value={mpItem?.copCdNm || ''}
                  onChange={(e) => handleItemChange('copCdNm', e.target.value)}
                  onSearchClick={() => null}
                  isError={errors?.copCdNm}
                  msgError={String(
                    t('mp.msg.대상법인을 선택해 주세요.', '대상법인을 선택해 주세요.')
                  )}
                  readOnly={true}
                />
              </TableCell>
              <TableCell className={isMp && !isUt ? '' : 'disabled'}>
                {String(t('mp.label.표준사양서 반영여부', '표준사양서 반영여부'))}
              </TableCell>
              <TableCell className={isMp && !isUt ? '' : 'disabled'}>
                <Checkbox
                  id="spshRvwYn"
                  css={st.checkbox}
                  disabled={!(isMp && !isUt)}
                  checked={mpItem?.spshRvwYn === 'Y'}
                  onChange={(e, checked) => handleItemChange('spshRvwYn', checked ? 'Y' : 'N')}
                />
              </TableCell>
              <TableCell className={isMp && !isUt ? '' : 'disabled'}>
                {String(t('mp.label.표준사양서', '표준사양서'))}
              </TableCell>
              <TableCell className={isMp && !isUt ? '' : 'disabled'}>
                <FileuploadBtn
                  disabled={!(isMp && !isUt)}
                  onClick={() =>
                    handleOperationAfterSave().then(() =>
                      handleFileUpload(MpItemAtchFileTpCd.SPEC_SHEET)
                    )
                  }
                >
                  <span>{(mpItem?.specSheetAtchFileCnt || 0).toLocaleString()}</span>
                </FileuploadBtn>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {String(t('mp.label.Issue사항/개선목적', 'Issue사항/개선목적'))}
              </TableCell>
              <TableCell colSpan={7}>
                <Textarea
                  name="isuDesc"
                  value={mpItem?.isuDesc || ''}
                  onChange={(e) => handleItemChange(e.target.name, e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{String(t('mp.label.개조/개선 항목', '개조/개선 항목'))}</TableCell>
              <TableCell colSpan={7}>
                <Textarea
                  name="iprvCtn"
                  value={mpItem?.iprvCtn || ''}
                  onChange={(e) => handleItemChange(e.target.name, e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{String(t('com.label.첨부', '첨부'))}</TableCell>
              <TableCell>
                <FileuploadBtn
                  onClick={() =>
                    handleOperationAfterSave().then(() => {
                      handleFileUpload(MpItemAtchFileTpCd.MP_ITEM);
                    })
                  }
                >
                  <span>{(mpItem?.atchFileCnt || 0).toString()}</span>
                </FileuploadBtn>
              </TableCell>
              <TableCell>
                {String(t('mp.label.개조/개선 Item 보고서', '개조/개선 Item 보고서'))}
              </TableCell>
              <TableCell>
                <FileuploadBtn
                  onClick={() =>
                    handleOperationAfterSave().then(() => {
                      handleFileUpload(MpItemAtchFileTpCd.MP_ITEM_RPT);
                    })
                  }
                >
                  <span>{(mpItem?.mpItemRptAtchFileCnt || 0).toString()}</span>
                </FileuploadBtn>
              </TableCell>
              <TableCell>{String(t('mp.label.유효성 평가보고서', '유효성 평가보고서'))}</TableCell>
              <TableCell>
                <FileuploadBtn
                  onClick={() =>
                    handleOperationAfterSave().then(() => {
                      handleFileUpload(MpItemAtchFileTpCd.VAID_EVL_RPT);
                    })
                  }
                >
                  <span>{(mpItem?.vaidEvlRptFileCnt || 0).toString()}</span>
                </FileuploadBtn>
              </TableCell>
              <TableCell>{String(t('mp.label.분과위원회 회의록', '분과위원회 회의록'))}</TableCell>
              <TableCell>
                <FileuploadBtn
                  onClick={() =>
                    handleOperationAfterSave().then(() => {
                      handleFileUpload(MpItemAtchFileTpCd.SCMM_MINT);
                    })
                  }
                >
                  <span>{(mpItem?.scmmMintAtchFileCnt || 0).toString()}</span>
                </FileuploadBtn>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{String(t('com.label.비고', '비고'))}</TableCell>
              <TableCell colSpan={7}>
                <Textarea
                  name="rmk"
                  value={mpItem?.rmk || ''}
                  onChange={(e) => handleItemChange(e.target.name, e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{String(t('mp.label.첨부미리보기', '첨부미리보기'))}</TableCell>
              <TableCell colSpan={7}>
                {groupsTitle.map((item, index) => (
                  <CustomFileImageList
                    key={index}
                    listIndex={index}
                    itemTitle={item[0].atchFileNm}
                    itemList={groupsData[item[0].key]}
                    imageCols={2}
                    imageGaps={5}
                  />
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {isOpenEquipmentGroupPopUp && (
        <EquipmentGroupPopUp
          open={isOpenEquipmentGroupPopUp}
          close={() => setOpenEequipmentGroupPopUp(false)}
          type={'mp'}
          condition={{
            equipmentGroup: mpItem.eqclId || '',
          }}
          onCallback={(value) => {
            const eqclId = (value as EquipmentGroupCondition).equipmentGroup;
            setMpItem((prev) => ({
              ...prev,
              eqclId: eqclId,
            }));
            changeSearchConditionByEqclId(mpItem?.mpClsfCds, eqclId);
          }}
        />
      )}

      {isOpenFileUploadModal && (
        <FileUploadPopUp
          open={isOpenFileUploadModal}
          close={() => setOpenFileUploadModal(false)}
          initParam={{
            atchFileGrId: fileUploadModalCondition.atchFileGrId,
            atchFileTpCd: fileUploadModalCondition.atchFileTpCd,
            optValCtn1: fileUploadModalCondition.tableName,
            bizName: fileUploadModalCondition.bizName,
          }}
          onCallback={(atchFileGrId, fileCount) => {
            let key = '';
            switch (fileUploadModalCondition.atchFileTpCd) {
              case MpItemAtchFileTpCd.MP_ITEM:
                key = 'atchFileCnt';
                break;
              case MpItemAtchFileTpCd.MP_MINUTES:
                key = 'minuteAtchFileCnt';
                break;
              case MpItemAtchFileTpCd.SPEC_SHEET:
                key = 'specSheetAtchFileCnt';
                break;
              case MpItemAtchFileTpCd.MP_ITEM_RPT:
                key = 'mpItemRptAtchFileCnt';
                break;
              case MpItemAtchFileTpCd.VAID_EVL_RPT:
                key = 'vaidEvlRptFileCnt';
                break;
              case MpItemAtchFileTpCd.SCMM_MINT:
                key = 'scmmMintAtchFileCnt';
                break;
            }
            setMpItem((prev) => ({
              ...prev,
              [key]: fileCount,
            }));
            setOpenFileUploadModal(false);

            // 미리보기 이미지가 있는지 조회
            getPreviewImage();
          }}
        />
      )}

      {isOpenMpItemCopModal && (
        <MpItemCopModal
          open={isOpenMpItemCopModal}
          close={() => setOpenMpItemCopModal(false)}
          itemId={defaultMpItem?.itemId || ''}
          isOptional={!isHe}
          onCallback={(value) => {
            setMpItem((prev) => ({
              ...prev,
              copCdNm: (value || [])
                .filter((o) => o.tgtYn === 'Y')
                .map((o) => o.copCd)
                .join(', '),
            }));
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(MpItemInfoContent);
