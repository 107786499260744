import { AutoSendEmail, AutoSendEmailSearchCondition } from 'models/common/AutoSendEmail';
import { CommonRequest, Method, ServiceName, CommonResponse } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

/**
 * 자동메일발송 LIST 조회
 */
export const getAutoSendEmailList = async (condition: AutoSendEmailSearchCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/email/getAutoSendEmailList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<AutoSendEmail[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as AutoSendEmail[];
};

export const saveAutoSendEmailInfo = async (saveData: AutoSendEmail[]) => {
  console.log('saveData', saveData);
  const request: CommonRequest = {
    method: Method.POST,
    url: '/v1/email/saveAutoSendEmailInfo',
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<any> = await callApi(request);

  return response;
};
