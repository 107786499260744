/** @jsxImportSource @emotion/react */
import { ColDef, ColGroupDef, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Generic, emptyGeneric } from 'models/ip/Generic';
import { selectFromIpEqpParaMaster } from 'apis/ip/IpEqpPara';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import SearchInputText from 'pages/common/components/SearchInputText';
import SearchButtonGroup from 'pages/common/components/SearchButtonGroup';
import { commonColDef } from 'models/ip/Public';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { IconButton } from 'components/buttons/IconSVG';
import { Button } from '@mui/material';
import useSessionStore from 'stores/useSessionStore';

interface paramData {
  condition: any;
  editable: boolean;
  onSelectionChanged: any;
}

export interface FacilityGroupCondition {
  equipmentGroup?: string;
  eqpParaNo?: string;
  eqpParaName?: string;
}

const FacilityGroupModalGrid = forwardRef((props: paramData, ref) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<any[]>([]);
  const [condition, setCondition] = useState<FacilityGroupCondition>({
    equipmentGroup: '',
    eqpParaNo: '',
    eqpParaName: '',
  });
  const [isRefresh, setRefrash] = useState(false);
  const { gridNoRowsTemplate } = useSessionStore();

  const defaultColDef = useMemo(() => {
    return commonColDef;
  }, []);

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      headerName: 'No',
      field: 'No',
      width: 60,
      filter: false,
      editable: false,
      valueFormatter: (params: ValueFormatterParams) => {
        return `${parseInt(params.node!.id!) + 1}`;
      },
      hide: true,
    },
    {
      width: 37,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressSizeToFit: true,
      headerClass: 'headerCheck',
      cellClass: 'cellCheck',
      // headerCheckboxSelectionFilteredOnly: true,
      showDisabledCheckboxes: true,
      editable: false,
    },
    {
      headerName: '',
      hide: true,
      children: [
        {
          field: '',
          headerName: '',
          hide: true,
        },
        {
          field: '',
          headerName: '',
          hide: true,
        },
      ],
    },
    {
      headerName: String(t('com.label.설비군', '설비군')),
      field: 'equipmentGroup',
      width: 100,
    },
    {
      headerName: String(t('ip.label.설비 ParameterNo', '설비 ParameterNo')),
      field: 'eqpParaNo',
      width: 140,
    },
    {
      headerName: String(t('ip.label.설비 Parameter명', '설비 Parameter명')),
      field: 'eqpParaName',
      flex: 1,
      cellStyle: { textAlign: 'left' },
    },
  ];

  // const listner = (received) => {
  //   setCondition({ ...condition, [received.name]: received.value });
  // };

  const listner = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const name = e.target.name;
    setCondition({ ...condition, [name]: e.target.value });
  };

  const handleSearch = () => {
    selectFromIpEqpParaMaster(condition).then((result: any[]) => {
      setRowData(result);
      if (result) {
        //setAprTplInfoData(result[0]);
        // prsDescEditorRef.current?.editorRef.SetBodyValue(result[0].prsDesc ?? '');
        // wcstDescEditorRef.current?.editorRef.SetBodyValue(result[0].wcstDesc ?? '');
      }
    });
  };

  const fnOnCellClicked = useCallback((event: any) => {
    props.onSelectionChanged && props.onSelectionChanged(event.data);
  }, []);

  const onSelectionChanged = (event: any) => {
    props.onSelectionChanged(event);
  };

  const reOnClick = () => {
    setCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as FacilityGroupCondition
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  //임시
  // useEffect(() => {
  //   searchButtonOnClick();
  // }, []);

  return (
    <div>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.설비Parameter', '설비Parameter')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>

      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="twoCol">
              <SearchCols>
                <label>{t('com.label.설비군', '설비군')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="equipmentGroup"
                  placeholder={String(
                    t('com.label.설비군을 입력해 주세요.', '설비군을 입력해 주세요.')
                  )}
                  value={condition?.equipmentGroup}
                  onChange={listner}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('ip.label.설비 ParameterNo', '설비 ParameterNo')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="eqpParaNo"
                  placeholder={String(
                    t(
                      'com.label.설비파라미터번호를 입력해 주세요.',
                      '설비파라미터번호를 입력해 주세요.'
                    )
                  )}
                  value={condition?.eqpParaNo}
                  onChange={listner}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              {/* <SearchInputText
                name={t('ip.label.설비군', '설비군')}
                id="equipmentGroup"
                isRefreah={isRefresh}
                onChange={listner}
              ></SearchInputText> */}
              {/* <SearchInputText
                name={t('ip.label.설비ParameterNo', '설비ParameterNo')}
                id="eqpParaNo"
                isRefreah={isRefresh}
                onChange={listner}
              ></SearchInputText> */}
            </SearchRows>
            <SearchRows className="twoCol">
              <SearchCols>
                <label>{t('ip.label.설비 Parameter명', '설비 Parameter명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="eqpParaName"
                  placeholder={String(
                    t(
                      'com.label.설비파라미터명을 입력해 주세요.',
                      '설비파라미터명을 입력해 주세요.'
                    )
                  )}
                  value={condition?.eqpParaName}
                  onChange={listner}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              {/* <SearchInputText
                name={t('ip.label.설비 Parameter명', '설비 Parameter명')}
                id="eqpParaName"
                isRefreah={isRefresh}
                onChange={listner}
              ></SearchInputText> */}
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={reOnClick}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={handleSearch} disableRipple>
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <ContentGrid className="ag-theme-alpine" style={{ height: '300px', marginTop: '27px' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          groupHeaderHeight={32}
          rowHeight={32}
          //suppressRowDeselection={true}
          //onCellClicked={fnOnCellClicked}+
          //animateRows={true}
          //suppressRowClickSelection={true}
          //isRowSelectable={isRowSelectable}
          //stopEditingWhenCellsLoseFocus={false}
          //singleClickEdit={false}
          rowSelection="single"
          onSelectionChanged={onSelectionChanged}
          overlayNoRowsTemplate={gridNoRowsTemplate}
        />
      </ContentGrid>
    </div>
  );
});

FacilityGroupModalGrid.displayName = 'FacilityGroupModalGrid';
export default memo(FacilityGroupModalGrid);
