import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { GatingCheckSheetDtl, GatingCheckSheetMst } from '../../models/gtng/GatingCheckSheet';
import { GatingCheckSheetVer } from '../../models/gtng/popup/GatingCheckSheetVer';
import { BbsPostDetail } from '../../models/admin/Bbs';

/**
 * 게이팅 체크 시트 마스터 조회
 * @param searchGatingTypeCode
 * @param searchUseYn
 */
export const getGatingCheckSheetMst = async (searchGatingTypeCode: string, searchUseYn: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/gatingCheckSheetMst`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      searchGatingTypeCode: searchGatingTypeCode,
      searchUseYn: searchUseYn,
    }),
  };
  const response: CommonResponse<GatingCheckSheetMst[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckSheetMst[];
};

export const saveGatingCheckSheetMst = async (saveData: GatingCheckSheetMst[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveGatingCheckSheetMst`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  // return (response.successOrNot === 'Y' ? response.data : response.statusCode) as DmlResponse;
  return response;
};

export const getGatingCheckSheetDtl = async (sGatingTemplateId: string, sVersionNo: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/gatingCheckSheetDtl`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      searchGatingTemplateId: sGatingTemplateId,
      searchVersionNo: sVersionNo,
    }),
  };
  const response: CommonResponse<GatingCheckSheetDtl[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingCheckSheetDtl[];
};

export const saveGatingCheckSheetDtl = async (saveData: GatingCheckSheetDtl[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveGatingCheckSheetDtl`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  // return (response.successOrNot === 'Y' ? response.data : response.statusCode) as DmlResponse;
  return response;
};

export const saveGatingCheckSheetVersionUp = async (saveData: GatingCheckSheetVer) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveGatingCheckSheetVersionUp`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);
  return response;
};
