import { ICellRendererParams } from 'ag-grid-community';
import { CrudCode } from 'models/common/Edit';
import CommonSelectBox from 'pages/common/components/CommonSelectBox';

export interface Props extends ICellRendererParams {
  keyword?: any;
}
export default function CellRendererCommonSelect(props: Props) {
  const cellValue = props?.value;

  const listner = (result) => {
    props.node?.setDataValue(result.id, result.value);
    props.node?.setDataValue('crudKey', CrudCode.UPDATE);
  };

  return (
    <div>
      <CommonSelectBox
        id={String(props.colDef?.field)}
        defaultValue={cellValue}
        //placeholder="선택해 주세요"
        onCallback={(value) => listner(value)}
        keyword={props?.keyword}
      />
    </div>
  );
}
