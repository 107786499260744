/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Alert from '@mui/material/Alert';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccodianWrap } from 'components/layouts/ContentLayout';
import { GuideBlock } from '../GuideComponent';
import CustomDialog from 'components/modals/common/CustomDialog';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { tb } from 'components/layouts/Table';
import { IconButton } from 'components/buttons/IconSVG';

const DialogGuide = () => {
  const [expanded, setExpanded] = useState<string>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  const [isOpen3, setOpen3] = useState(false);

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      disableRipple
      onClick={() => console.log('confirm button click')}
    >
      Confirm
    </Button>,
    <Button
      key={'save'}
      css={IconButton.button}
      className="save"
      onClick={() => console.log('save button click')}
      disableRipple
    >
      Save
    </Button>,
  ];
  return (
    <>
      <GuideBlock title={'<CustomDialog> Import'} isCode={true}>
        {`import CustomDialog from 'components/modals/common/CustomDialog';`}
      </GuideBlock>
      <GuideBlock title={'<CustomDialog> 속성'} isCode={true}>
        {`
interface Props {
  title: string; // 제목
  open: boolean; // dialog 노출여부
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'; // dialog 크기 (default: lg)
  className?: string; // dialog 크기를 제외한 별도 클래스가 있는 경우 (ex. noScroll)
  children: ReactNode; // Dialog 내용 (cf. <DialogContent> 내 노출)
  buttons?: ReactElement[] | null; // Dialog 하단 버튼
  onCancel?: () => void; // 취소 이벤트 (설정 시 취소버튼 노출)
  onClose: () => void; // 닫기 이벤트
}
        `}
      </GuideBlock>
      <Alert severity="info">
        className {'>'} noScroll은 팝업 내 Datepicker가 팝업 크기에 잘릴 경우에만 추가해주세요. [
        ex) IP List 점검 현황/계획 {'>'} 계획 변경 popup] <br />
        그 외의 팝업에 넣을 경우 Layout이 틀어질 수 있으니 주의해서 사용해주세요.
        <br />
        <br />
        Dialog 크기
        <br />
        - xs : 400px
        <br />
        - sm : 600px
        <br />
        - md : 800px
        <br />
        - lg : 1200px
        <br />- xl : 1600px
      </Alert>
      <AccodianWrap className="marginTop">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary>CustomDialog - 단순 조회인 경우 (버튼없는 경우)</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <MuiIconButton aria-label="play" color="success" onClick={() => setOpen(true)}>
                <PlayCircleOutlinedIcon />
              </MuiIconButton>
              <CustomDialog
                open={isOpen}
                size={'sm'} // lg인 경우 생략 가능(default: lg)
                title={'버튼이 없는 단순 조회 Dialog 제목'}
                onClose={() => setOpen(false)}
              >
                {/* <DialogContent> 내용 입력 - 시작 */}
                {/* 아래 Table 내용은 확인을 위해 예시로 추가한 내용입니다 */}
                <TableContainer css={tb.table}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>최종버전</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>신규버전</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>변경사유</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <DialogContent> 내용 입력 - 종료 */}
              </CustomDialog>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomDialog
  open={isOpen}
  size={'sm'} // lg인 경우 생략 가능(default: lg)
  title={'버튼이 없는 단순 조회 Dialog 제목'}
  onClose={() => setOpen(false)}
>
  {/* <DialogContent> 내용 입력 - 시작 */}
  {/* 아래 Table 내용은 확인을 위해 예시로 추가한 내용입니다 */}
  <TableContainer css={tb.table}> 
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>최종버전</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>신규버전</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>변경사유</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  {/* <DialogContent> 내용 입력 - 종료 */}
</CustomDialog>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary>
            CustomDialog - 사용자 정의 버튼이 있는 경우 (ex. 적용, 선택 등)
          </AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <MuiIconButton aria-label="play" color="success" onClick={() => setOpen2(true)}>
                <PlayCircleOutlinedIcon />
              </MuiIconButton>
              <CustomDialog
                open={isOpen2}
                size={'sm'} // lg인 경우 생략 가능(default: lg)
                title={'사용자 정의 버튼이 있는 경우 Dialog 제목'}
                buttons={dialogButtons} // Dialog 하단 버튼 (Button ReactElement 목록)
                onClose={() => setOpen2(false)}
              >
                {/* <DialogContent> 내용 입력 - 시작 */}
                {/* 아래 Table 내용은 확인을 위해 예시로 추가한 내용입니다 */}
                <TableContainer css={tb.table}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>최종버전</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>신규버전</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>변경사유</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <DialogContent> 내용 입력 - 종료 */}
              </CustomDialog>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
// Dialog 하단 버튼 (Button ReactElement 목록)
const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      disableRipple
      onClick={() => console.log('confirm button click')}
    >
      Confirm
    </Button>,
    <Button
      key={'save'}
      css={IconButton.button}
      className="save"
      onClick={() => console.log('save button click')}
      disableRipple
    >
      Save
    </Button>,
  ];

<CustomDialog
  open={isOpen2}
  size={'sm'} // lg인 경우 생략 가능(default: lg)
  title={'사용자 정의 버튼이 있는 경우 Dialog 제목'}
  buttons={dialogButtons} // Dialog 하단 버튼 (Button ReactElement 목록)
  onClose={() => setOpen2(false)}
>
  {/* <DialogContent> 내용 입력 - 시작 */}
  {/* 아래 Table 내용은 확인을 위해 예시로 추가한 내용입니다 */}
  <TableContainer css={tb.table}>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>최종버전</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>신규버전</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>변경사유</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  {/* <DialogContent> 내용 입력 - 종료 */}
</CustomDialog>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary>CustomDialog - 취소 버튼이 있는 경우</AccordionSummary>
          <AccordionDetails>
            <GuideBlock>
              <MuiIconButton aria-label="play" color="success" onClick={() => setOpen3(true)}>
                <PlayCircleOutlinedIcon />
              </MuiIconButton>
              <CustomDialog
                open={isOpen3}
                size={'sm'} // lg인 경우 생략 가능(default: lg)
                title={'취소 버튼이 있는 경우 Dialog 제목'}
                buttons={dialogButtons} // Dialog 하단 버튼 (Button ReactElement 목록)
                onCancel={() => {
                  // 취소 이벤트 (설정 시 취소버튼 노출)
                  setOpen3(false);
                  console.log('cancel button click');
                }}
                onClose={() => setOpen3(false)}
              >
                {/* <DialogContent> 내용 입력 - 시작 */}
                {/* 아래 Table 내용은 확인을 위해 예시로 추가한 내용입니다 */}
                <TableContainer css={tb.table}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>최종버전</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>신규버전</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>변경사유</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <DialogContent> 내용 입력 - 종료 */}
              </CustomDialog>
            </GuideBlock>
            <GuideBlock isCode={true}>
              {`
<CustomDialog
  open={isOpen3}
  size={'sm'} // lg인 경우 생략 가능(default: lg)
  title={'사용자 정의 버튼이 있는 경우 Dialog 제목'}
  buttons={dialogButtons} // Dialog 하단 버튼 (Button ReactElement 목록)
  onCancel={() => { // 취소 이벤트 (설정 시 취소버튼 노출)
    setOpen3(false);
    console.log('cancel button click');
  }}
  onClose={() => setOpen3(false)} 
>
  {/* <DialogContent> 내용 입력 - 시작 */}
  {/* 아래 Table 내용은 확인을 위해 예시로 추가한 내용입니다 */}
  <TableContainer css={tb.table}>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>최종버전</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>신규버전</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>변경사유</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
  {/* <DialogContent> 내용 입력 - 종료 */}
</CustomDialog>
              `}
            </GuideBlock>
          </AccordionDetails>
        </Accordion>
      </AccodianWrap>
    </>
  );
};

export default DialogGuide;
