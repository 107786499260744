/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { di, TitleWrap, PopupBtnGroup, AlertBtnGroup, diAlert } from 'components/layouts/Dialog';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { CustomInputWrap, CustomInputText } from 'components/inputs/CustomInput';
import {
  SubTitleLayout,
  SubTitleGroup,
  HalfContetntLayout,
  ControlBtnGroup,
} from 'components/layouts/ContentLayout';
import {
  SearchBox,
  SearchBoxRow,
  InputBox,
  SearchRows,
  SearchCols,
  SearchButtonWrap,
} from 'components/layouts/SearchBox';
import { IconButton } from 'components/buttons/IconSVG';
import { useCommonModal } from 'hooks/useCommonModal';
import { getElmEmpList } from 'apis/common/ElmEmp';
import { getContInfoList } from 'apis/gtng/popup/GatingCont';
import _ from 'lodash';
import useSessionStore from 'stores/useSessionStore';
import { useTranslation } from 'react-i18next';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { Selector } from '@grapecity/wijmo.grid.selector';

export interface contSearchParam {
  rowId?: string;
  gatingId?: string;
  userId?: string;
  empNm?: string;
  deptNm?: string;
  useYn?: string;
}

interface SearchParam {
  userId?: string;
  empNm?: string;
  deptNm?: string;
  useYn?: string;
}

/**
 * 사용자 찾기 팝업 : 등록/수정/삭제
 * 사용화면 : 게이팅 생성
 *
 * @param props
 * @constructor
 */
export const GatingContPopUp = (props: any) => {
  const { t } = useTranslation();
  const contRetrieveGridRef = useRef<AgGridReact<contSearchParam>>(null);
  const contChangeGridRef = useRef<AgGridReact<contSearchParam>>(null);
  const [contRetrieveList, setContRetrieveList] = useState<contSearchParam[]>([]);
  const [contChangeList, setContChangeList] = useState<contSearchParam[]>([]);
  const [contInfo, setContInfo] = useState<contSearchParam[]>([]);
  const [searchParam, setSearchParam] = useState<SearchParam>({});
  const { gridNoRowsTemplate } = useSessionStore();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  // gatingId는 존재하고 담당자는 등록 안되어있을때 실행되지않게 해야함.
  useEffect(() => {
    if (props.initParam != null && props.initParam != '') {
      const value = props.initParam;
      getContInfo(value);
    }
  }, [props.initParam]);

  const getElmEmpInfo = async (searchParam) => {
    await getElmEmpList(searchParam).then((result: any) => {
      if (result != null && result != undefined) {
        setContRetrieveList(result);
      }
    });
  };

  const getContInfo = async (value) => {
    await getContInfoList(value).then((result: any) => {
      if (result != null && result != '') {
        setContChangeList(result);
      }
    });
  };

  const handleSearchParam = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  };

  // const onSelectionchange = () => {
  //   const selectedRows = contRetrieveGridRef.current!.api.getSelectedRows();
  //   const selectedRows2 = contChangeGridRef.current!.api.getSelectedRows();
  //   setContInfo(selectedRows);
  // };

  const btnAddRow = () => {
    // const selectedRowNodes = contRetrieveGridRef.current!.api.getSelectedNodes();
    const selectedRowNodes = contRetrieveListFlexRef.selectedRows;
    if (selectedRowNodes.length > 0) {
      const addData = selectedRowNodes.map((data) => {
        const newRow = {
          rowId: '',
          gatingId: props.initParam,
          userId: data.dataItem.userId,
          empNm: data.dataItem.empNm,
          deptNm: data.dataItem.deptNm,
          useYn: '',
        } as contSearchParam;
        return newRow;
      });
      const contLenght = contChangeList.length + addData.length;
      if (contLenght > 100) {
        setOpen(true);
      } else {
        setContChangeList(_.uniqBy(contChangeList.concat(addData), 'userId'));
      }
    }
  };

  const btnDelRow = useCallback(() => {
    // const selectedRowNodes = contChangeGridRef.current!.api.getSelectedNodes();
    const selectedRowNodes = contChangeListFlexRef.selectedRows;
    selectedRowNodes.forEach(function (item) {
      contChangeListFlexRef.collectionView.remove(item.dataItem);
    });
    const filteredData = contChangeList.filter((element) => element !== undefined);
    setContChangeList(filteredData);
    // const selectedIds = selectedRowNodes
    //   .map((rowNode) => {
    //     return parseInt(rowNode.id!);
    //   })
    //   .reverse();

    // selectedIds.forEach((item) => {
    //   delete contChangeList[item];
    // });

    // const filteredData = contChangeList.filter((element) => element !== undefined);
    // setContChangeList(filteredData);
  }, [contChangeList, contChangeGridRef]);

  const btnUserSelectInfo = () => {
    const rowId = props.rowId; //props로 받아온 rowId
    const fieldId = props.fieldId; //props로 받아온 fieldId
    const contData = { contInfo: contChangeList, rowId: rowId, fieldId: fieldId };
    props.setContData(contData);
    props.setPopup(false);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      getElmEmpInfo(searchParam);
    }
  };

  const btnReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce((acc, cur) => Object.assign(acc, { [cur]: '' }), {}) as SearchParam
    );
  };

  // 위즈모
  const [contRetrieveListFlexRef, setContRetrieveListFlexRef] = useState<any>();
  const [contChangeListFlexRef, setContChangeListFlexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();

  const contRetrieveListLayoutDefinition = () => {
    return [
      {
        header: String(t('com.label.NO', 'NO')),
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
        width: 40,
      },
      {
        binding: 'userId',
        header: String(t('com.label.사용자ID', '사용자ID')),
        align: 'center',
        width: '*',
      },
      {
        binding: 'empNm',
        header: String(t('com.label.사용자명', '사용자명')),
        align: 'center',
        width: '*',
      },
      {
        binding: 'deptNm',
        header: String(t('com.label.부서명', '부서명')),
        align: 'left',
        width: '*',
      },
    ];
  };

  const contChangeListLayoutDefinition = () => {
    return [
      {
        header: 'id',
        binding: 'id',
        align: 'center',
        width: 40,
        visible: false,
      },
      {
        header: String(t('com.label.NO', 'NO')),
        align: 'center',
        cellTemplate: (grid) => grid.row._idx + 1,
        width: 40,
      },
      {
        binding: 'userId',
        header: String(t('com.label.사용자ID', '사용자ID')),
        align: 'center',
        width: '*',
      },
      {
        binding: 'empNm',
        header: String(t('com.label.사용자명', '사용자명')),
        align: 'center',
        width: '*',
      },
      {
        binding: 'deptNm',
        header: String(t('com.label.부서명', '부서명')),
        align: 'left',
        width: '*',
      },
    ];
  };

  const contRetrieveListState = {
    itemsSource: contRetrieveList,
    layoutDefinition: contRetrieveListLayoutDefinition(),
  };

  const contChangeListState = {
    itemsSource: contChangeList,
    layoutDefinition: contChangeListLayoutDefinition(),
  };

  const contRetrieveListOnInitialized = (grid) => {
    new Selector(grid);
    // new FlexGridFilter(grid);
    setContRetrieveListFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      setHitTest(ht);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // cell 선택 시 checkBox 체크
      if (grid.rows[ht.row].isSelected == true) {
        grid.rows[ht.row].isSelected = false;
      } else {
        grid.rows[ht.row].isSelected = true;
      }

      const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
      setflexItem(item);
      grid.refresh();
    });
  };

  const contChangeListOnInitialized = (grid) => {
    new Selector(grid);
    // new FlexGridFilter(grid);
    setContChangeListFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      setHitTest(ht);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // // row 선택시 체크박스 체크
      // for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      // grid.rows[ht.row].isSelected = true;

      // cell 선택 시 checkBox 체크
      if (grid.rows[ht.row].isSelected == true) {
        grid.rows[ht.row].isSelected = false;
      } else {
        grid.rows[ht.row].isSelected = true;
      }

      const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보
      setflexItem(item);
      grid.refresh();
    });
  };

  return (
    <>
      <Dialog open={true} css={di.dialog} fullWidth className="lg" maxWidth={false}>
        <TitleWrap>
          <h2>{t('gtng.label.Gating 담당자 조회', 'Gating 담당자 조회')}</h2>
          <Button onClick={() => props.close()}></Button>
        </TitleWrap>
        <DialogContent>
          <SearchBox>
            <SearchBoxRow>
              <InputBox>
                <SearchRows>
                  <SearchCols>
                    <label>{t('com.label.사용자ID', '사용자ID')}</label>
                    <CustomInputWrap>
                      <CustomInputText
                        name="userId"
                        value={searchParam.userId}
                        placeholder={String(
                          t('com.label.사용자ID를 입력해 주세요.', '사용자ID를 입력해 주세요.')
                        )}
                        onChange={handleSearchParam}
                        onKeyDown={onKeyDown}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </SearchCols>
                  <SearchCols>
                    <label>{t('com.label.사용자명', '사용자명')}</label>
                    <CustomInputWrap>
                      <CustomInputText
                        name="empNm"
                        value={searchParam.empNm}
                        placeholder={String(
                          t('com.label.사용자명을 입력해 주세요.', '사용자명을 입력해 주세요.')
                        )}
                        onChange={handleSearchParam}
                        onKeyDown={onKeyDown}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </SearchCols>
                  <SearchCols>
                    <label>{t('gtng.label.부서명', '부서명')}</label>
                    <CustomInputWrap>
                      <CustomInputText
                        name="deptNm"
                        placeholder={String(
                          t('gtng.msg.부서명을 입력해 주세요.', '부서명을 입력해 주세요.')
                        )}
                        value={searchParam.deptNm}
                        onChange={handleSearchParam}
                        onKeyDown={onKeyDown}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </SearchCols>
                </SearchRows>
                {/* <SearchCols>
                    <label>{t('com.label.사용여부', '사용여부')}</label>
                    <CustomSelect
                      value={searchParam.useYn}
                      name="useYn"
                      onChange={handleSearchParam}
                    >
                      <option value="">{t('com.label.전체', '전체')}</option>
                      <option value={CommonYN.Y}>{t('com.label.사용', '사용')}</option>
                      <option value={CommonYN.N}>{t('com.label.사용안함', '사용안함')}</option>
                    </CustomSelect>
                  </SearchCols> */}
              </InputBox>
              <SearchButtonWrap>
                <Button
                  css={IconButton.button}
                  className="reload"
                  onClick={btnReload}
                  disableRipple
                ></Button>
                <Button
                  css={IconButton.button}
                  className="find"
                  onClick={() => {
                    getElmEmpInfo(searchParam);
                  }}
                  disableRipple
                >
                  {t('com.button.조회', '조회')}
                </Button>
              </SearchButtonWrap>
            </SearchBoxRow>
          </SearchBox>
          <HalfContetntLayout style={{ marginTop: '24px' }}>
            <div>
              <SubTitleLayout>
                <SubTitleGroup>
                  <h3>{t('gtng.label.담당자 조회 결과', '담당자 조회 결과')}</h3>
                  <span className="total">
                    {t('com.label.총', '총')}
                    <span>{contRetrieveList?.length ?? 0}</span>
                    {t('com.label.건', '건')}
                  </span>
                </SubTitleGroup>
                <ControlBtnGroup>
                  <Button
                    css={IconButton.button}
                    className="addRow"
                    onClick={btnAddRow}
                    disableRipple
                  >
                    {t('com.button.행추가', '행추가')}
                  </Button>
                </ControlBtnGroup>
              </SubTitleLayout>
              <ContentGrid
                className={contRetrieveList.length < 1 ? 'noData' : ''}
                style={{ height: '450px' }}
              >
                <FlexGrid
                  columnGroups={contRetrieveListState.layoutDefinition}
                  itemsSource={contRetrieveListState.itemsSource}
                  deferResizing={false}
                  selectionMode={'Row'}
                  stickyHeaders={true}
                  isReadOnly={true}
                  // itemsSourceChanging={onCellValueChanged}
                  initialized={contRetrieveListOnInitialized}
                  style={{ height: (contRetrieveList || []).length < 1 ? '' : '410px' }}
                />
                <div
                  className="noData"
                  style={{ height: (contRetrieveList || []).length < 1 ? '410px' : '' }}
                >
                  <span>
                    {t(
                      'com.label.조회 가능한 데이터가 없습니다.',
                      '조회 가능한 데이터가 없습니다.'
                    )}
                  </span>
                </div>
              </ContentGrid>
            </div>
            <div>
              <SubTitleLayout>
                <h3></h3>
                <ControlBtnGroup>
                  <Button
                    css={IconButton.button}
                    className="delRow"
                    onClick={btnDelRow}
                    disableRipple
                  >
                    {t('com.button.행삭제', '행삭제')}
                  </Button>
                </ControlBtnGroup>
              </SubTitleLayout>
              <ContentGrid
                className={contChangeList.length < 1 ? 'noData' : ''}
                style={{ height: '450px' }}
              >
                <FlexGrid
                  itemsSource={contChangeListState.itemsSource}
                  columnGroups={contChangeListState.layoutDefinition}
                  selectionMode={'Row'}
                  isReadOnly={true}
                  initialized={contChangeListOnInitialized}
                  deferResizing={false}
                  style={{ height: (contChangeList || []).length < 1 ? '' : '410px' }}
                />
                <div
                  className="noData"
                  style={{ height: (contChangeList || []).length < 1 ? '410px' : '' }}
                >
                  <span>
                    {t(
                      'com.label.조회 가능한 데이터가 없습니다.',
                      '조회 가능한 데이터가 없습니다.'
                    )}
                  </span>
                </div>
              </ContentGrid>
              {/* <ContentGrid className="ag-theme-alpine" style={{ height: '450px' }}>
                <AgGridReact
                  ref={contChangeGridRef}
                  rowData={contChangeList}
                  columnDefs={contChangeColumnDefs}
                  onSelectionChanged={onSelectionchange}
                  rowMultiSelectWithClick={true}
                  rowSelection="multiple"
                  overlayNoRowsTemplate={gridNoRowsTemplate}
                  groupHeaderHeight={32}
                  rowHeight={32}
                ></AgGridReact>
              </ContentGrid> */}
            </div>
          </HalfContetntLayout>
        </DialogContent>
        <PopupBtnGroup>
          <Button className="cancel" onClick={() => props.close()} disableRipple>
            {t('com.button.취소', '취소')}
          </Button>
          {!props.setReadonly && (
            <Button css={IconButton.button} className="select" onClick={btnUserSelectInfo}>
              {t('com.button.선택', '선택')}
            </Button>
          )}
        </PopupBtnGroup>
      </Dialog>
      {open ? (
        <Dialog open={true} onClose={handleClose} css={diAlert.dialog} fullWidth maxWidth={false}>
          <TitleWrap>
            <h2>{t('com.label.알림', '알림')}</h2>
            {/* 취소/확인을 하는 Alert는 "확인" 타이틀, "확인"만 하는 Alert는 "알림" 타이틀을 사용해주세요 */}
          </TitleWrap>
          <DialogContent>
            <div className="AlertContent">
              {t(
                'com.label.담당자를 더 이상 추가 할 수 없습니다.(100명 제한)',
                '담당자를 더 이상 추가 할 수 없습니다.(100명 제한)'
              )}
            </div>
          </DialogContent>
          <AlertBtnGroup>
            <Button className="ok" onClick={handleClose} disableRipple>
              <h2>{t('com.button.확인', '확인')}</h2>
            </Button>
          </AlertBtnGroup>
        </Dialog>
      ) : null}
    </>
  );
};
