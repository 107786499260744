import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SVGIcon } from 'components/buttons/IconSVG';
import { CommonBG, CommonText, InfoBorder } from 'ui/theme/Color';
import { ButtonSize, ButtonStyle, ButtonDesign } from 'components/buttons/ButtonStyle';

export const di = {
  dialog: css`
    &.xs {
      .MuiDialog-container {
        .MuiDialog-paperFullWidth {
          width: 400px;
          max-width: 400px;
          min-width: 400px;
        }
      }
    }
    &.sm {
      .MuiDialog-container {
        .MuiDialog-paperFullWidth {
          width: 600px;
          max-width: 600px;
          min-width: 600px;
        }
      }
    }
    &.md {
      .MuiDialog-container {
        .MuiDialog-paperFullWidth {
          width: 800px;
          max-width: 800px;
          min-width: 800px;
        }
      }
    }
    &.lg {
      .MuiDialog-container {
        .MuiDialog-paperFullWidth {
          width: 1200px;
          max-width: 1200px;
          min-width: 1200px;
        }
      }
    }
    &.xl {
      .MuiDialog-container {
        .MuiDialog-paperFullWidth {
          width: 1600px;
          max-width: 1600px;
          min-width: 1600px;
        }
      }
    }
    &.noScroll {
      .MuiPaper-root,
      .MuiDialogContent-root {
        overflow-y: visible !important;
      }
    }

    .MuiDialog-paper {
      padding-top: 24px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.22), 0px 7px 10px rgba(0, 0, 0, 0.12);
      border-radius: 3px;
      width: auto;
      height: auto;
      max-height: 90%;
    }

    .MuiDialogContent-root {
      &.overnone {
        overflow: visible !important;
      }
      padding: 0px 24px !important;
      & > div:first-child {
        margin-top: 0px !important;
      }
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: rgba(60, 62, 61, 0.4);
        width: 6px;
        border-radius: 3px;
      }
    }
    .AlertContent {
      font-size: 13px;
      line-height: 1.5;
      font-weight: 400;
    }
  `,
};

export const diAlert = {
  dialog: css`
    position: absolute;
    .MuiModal-backdrop {
      background: none !important;
    }
    .MuiDialog-container {
      .MuiDialog-paperFullWidth {
        width: 400px;
        max-width: 400px;
        min-width: 400px;
      }
    }
    .MuiDialog-paper {
      padding-top: 24px;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.22), 0px 7px 10px rgba(0, 0, 0, 0.12);
      border-radius: 3px;
      width: auto;
      height: auto;
      max-height: 90%;
    }

    .MuiDialogContent-root {
      &.overnone {
        overflow: visible !important;
      }
      padding: 0px 24px !important;
      & > div:first-child {
        margin-top: 0px !important;
      }
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: rgba(60, 62, 61, 0.4);
        width: 6px;
        border-radius: 3px;
      }
    }
    .AlertContent {
      font-size: 13px;
      line-height: 1.5;
      font-weight: 400;
    }
  `,
};

export const TitleWrap = styled.div`
  height: 32px;
  margin: 0 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.25;
    color: ${CommonText.PrimaryExtreme};
    font-family: 'Spoqa Han Sans Neo';
    padding-left: 16px;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background: ${CommonText.Primary};
    }
  }
  button {
    min-width: 32px;
    width: 32px;
    height: 32px;
    padding: 0px !important;
    &:before {
      content: '';
      display: inline-block;
      clear: both;
      width: 32px;
      height: 32px;
      background: ${CommonText.Light};
      -webkit-mask: ${SVGIcon.IcoClose};
      mask-image: ${SVGIcon.IcoClose};
    }
  }
`;

export const PopupBtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 4px 24px 12px;
  &.blank {
    margin: 0px !important;
    height: 24px;
  }
  button {
    & + button {
      margin-left: 4px;
    }
    ${ButtonSize.md}
    ${ButtonStyle.outlineNormal}
      ${ButtonDesign.labelMD}
    &.select,
    &.ok,
    &.save,
    &.confirm,
    &.plus {
      ${ButtonSize.md}
      ${ButtonStyle.containedPrimary}
      ${ButtonDesign.IconLfMD}
    }
  }
`;

export const AlertBtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 24px 12px;
  &.blank {
    margin: 0px !important;
    height: 12px;
  }
  button {
    min-width: 56px;
    width: 56px;
    padding: 0px !important;
    & + button {
      margin-left: 4px;
    }
    &.Cancel {
      ${ButtonSize.lg}
      ${ButtonStyle.unfiledNormal}
    }
    &.Confirm {
      ${ButtonSize.lg}
      ${ButtonStyle.unfiledPrimary}
    }
  }
`;

export const st = {
  popup: css`
    background: ${CommonBG.Deep};
    padding: 12px 12px 12px 42px;
    position: relative;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      position: absolute;
      top: 12px;
      left: 12px;
      width: 18px;
      height: 18px;
      background: ${CommonText.Primary};
      -webkit-mask: ${SVGIcon.IcoInfo};
      mask-image: ${SVGIcon.IcoInfo};
    }
    & > p {
      position: relative;
      padding-left: 19px;
      line-height: 1.7;
      font-size: 12px;
      color: ${CommonText.Basic};
      &:before {
        content: '';
        clear: both;
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 6px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: ${InfoBorder.Done};
      }
    }
  `,
};
