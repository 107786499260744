import { ApproveRequestDetail, RequestDetail } from 'models/approves/ReferenceOpinion';
import { PaginationResponse } from 'models/common/Pagination';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const getMasterId = async () => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/approval/getMasterId`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse<string> = await callApi(request);

  return response;
};

export const saveApproveRequest = async (params: RequestDetail) => {
  console.log(params);
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/approval/generalRequest`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: params,
  };
  const response: CommonResponse<string> = await callApi(request);
  return response;
};

export const findApproveRequestList = async (searchCondition?: any) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/approval/getRequestList`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...searchCondition }),
  };

  const response: CommonResponse<PaginationResponse<any>> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as PaginationResponse<any>;
};

export const findApproveRequestDetail = async (aprReqId: string) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/approval/generalRequestDetail`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { aprReqId },
    //queryParams: new URLSearchParams({ aprReqId }),
  };

  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as any;
};

// 결재 승인
export const approveApprovalRequestResult = async (approveInfo: ApproveRequestDetail) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/approval/approveRequestResult`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: approveInfo,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return response;
};

// 결재 반려
export const rejectApprovalRequestResult = async (approveInfo: ApproveRequestDetail) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/approval/rejectRequestResult`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: approveInfo,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return response;
};

// 결재 취소
export const cancelApprovalRequestResult = async (aprReqId: string) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/approval/cancelRequestResult`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { aprReqId },
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return response;
};
