import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { GatingRegist } from '../../models/gtng/GatingRegist';
import { SearchParam } from 'pages/gtng/GatingRegistPage';

export const findGatingRegistMst = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/findGatingRegistMst`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<GatingRegist[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingRegist[];
};

export const retrieveGatingInfo = async (searchParam: SearchParam) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/retrieveGatingInfo`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      ...searchParam,
    }),
  };
  const response: CommonResponse<GatingRegist[]> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as GatingRegist[];
};

export const saveGatingRegistMst = async (saveData: GatingRegist[]) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/saveGatingRegistMst`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse<DmlResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as DmlResponse;
};

export const getYearList = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/getYearList`,
    serviceName: ServiceName.ELM_BE,
  };
  const response: CommonResponse = await callApi(request);
  return response.successOrNot === 'Y' ? response.data : null;
};

export const insertGatingChkEquip = async (saveData: GatingRegist) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/insertGatingChkEquip`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: saveData,
  };
  const response: CommonResponse = await callApi(request);
  return response;
};
