/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import { tb } from 'components/layouts/Table';
import { IconButton } from '../../../components/buttons/IconSVG';
import DialogContent from '@mui/material/DialogContent';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from '../../../components/layouts/Dialog';
import { useTranslation } from 'react-i18next';
import { SearchButtonWrap } from '../../../components/layouts/SearchBox';
import { CloseBtn } from '../../../components/buttons/CustomButton';
import { AllInputWrap, CustomInputText, CustomInputWrap, st } from 'components/inputs/CustomInput';
import { useCommonModal } from 'hooks/useCommonModal';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'hooks/useMessageBar';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { hasRole } from 'utils/SessionUtil';
import {
  saveExcpPartner,
  saveExcpPartnerNoSession,
  compNmCheck,
  rejectExcpStat,
} from 'apis/admin/ExcpPartner';
import { ManagementMode } from 'models/common/Common';
import { SubLayout } from 'components/layouts/SubLayout';

interface SearchParam {
  userId?: string;
  empNm?: string;
  deptNm?: string;
  useYn?: string;
  isEditableFlag?: boolean;
}

type Props = {
  open: boolean;
  condition: object;
  close: () => void;
  isFromRegistPage?: boolean;
  isFromUserEditPage?: boolean;
  //singleSelect?: boolean;
};

export const ExcpManagementPopUp = (props: any) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const { openLoading } = useLoading();
  const [isEditableFlag, setEditableFlag] = useState<boolean>(true);
  const [isUserNonEditableFlag, setUserNonEditableFlag] = useState<boolean>(false);
  const [hasAuth, setAuth] = useState(false);
  const [forDelete, setForDelete] = useState(false);
  const [hasCompleteCheck, setCompleteCheck] = useState(false);
  const [popCondition, setPopCondition] = useState<any>({
    excpId: '',
    excpNm: '',
    rpsnNm: '',
    rpsnEml: '',
    rpsnPhn: '',
    snsrYn: 'N',
    ipYn: 'N',
  });
  const cmpNmRef = useRef<any>();
  const rpsnNmRef = useRef<any>();
  const rpsnEmlRef = useRef<any>();
  const rpsnPhnRef = useRef<any>();
  const rmkRef = useRef<any>();
  const snsrYnRef = useRef<any>();
  const ipYnRef = useRef<any>();
  const { openMessageBar } = useMessageBar();
  const emailRegEx =
    /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
  const phnRegEx = /^[0-9+\-()]{8,18}$/;

  useEffect(() => {
    setPopCondition({
      excpNm: props.condition?.excpNm,
      rpsnNm: props.condition?.rpsnNm,
      rpsnEml: props.condition?.rpsnEml,
      rpsnPhn: props.condition?.rpsnPhn,
      snsrYn: props.condition?.snsrYn,
      ipYn: props.condition?.ipYn,
    });
    setUserNonEditableFlag(props?.isFromUserEditPage);

    // 관리자 권한 여부
    setAuth(hasRole('ADM'));

    if (props.condition?.mode === ManagementMode.CREATE) {
      // 협력업체 사용자로부터 신규등록
      setAuth(true);
    } else if (props.condition?.mode === ManagementMode.MODIFY) {
      // 수정
      if (props.condition?.staffEdit) {
        setAuth(true); //협력업체 사용자로부터 수정
      }
      setCompleteCheck(true);
    } else if (props.condition?.mode === ManagementMode.DELETE) {
      // 반려
      setForDelete(true); // 각 컴포넌트 제어
      setAuth(false); // 중복체크 제거용
    }
  }, []);

  const handleItemSave = async () => {
    // 반려
    if (forDelete) {
      if (rmkRef.current?.value.length > 0) {
        openCommonModal({
          modalType: 'confirm',
          content: t('com.label.반려하시겠습니까?', '반려하시겠습니까?'),
          yesCallback: () => {
            rejectExcpStat({ ...props.condition, rmk: rmkRef.current?.value })
              .then(() => {
                openMessageBar({
                  type: 'confirm',
                  content: t('approve.msg.반려 완료되었습니다.', '반려 완료되었습니다.'),
                });
                props.close();
              })
              .catch(() => {
                openMessageBar({
                  type: 'error',
                  content: t('com.label.요청에 실패했습니다.', '요청에 실패했습니다.'),
                });
              });
          },
        });
      } else {
        openMessageBar({
          type: 'error',
          content:
            t('com.label.반려 사유', '반려 사유') +
            ' ' +
            t('admin.msg.내용을 입력해 주세요.', '내용을 입력해 주세요.'),
        });
      }
      return;
    }
    if (!hasCompleteCheck) {
      openMessageBar({
        type: 'error',
        content: t('com.label.중복 체크를 해주세요.', '중복 체크를 해주세요.'),
      });
      return;
    }

    // 필수값 검증
    if (!rpsnNmRef.current?.value || rpsnNmRef.current?.value.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.대표자명을 입력해주세요.', '대표자명을 입력해주세요.'),
      });
      return;
    }
    if (!rpsnEmlRef.current?.value || !emailRegEx.test(rpsnEmlRef.current?.value)) {
      openMessageBar({
        type: 'error',
        content: t('com.label.이메일을 입력해 주세요.', '이메일을 입력해 주세요.'),
      });
      return;
    }
    if (rpsnPhnRef.current?.value.length > 0 && !phnRegEx.test(rpsnPhnRef.current?.value)) {
      openMessageBar({
        type: 'error',
        content: t(
          'com.label.전화번호는 8~18자리 숫자,-,),+ 조합만 사용가능합니다.',
          '전화번호는 8~18자리 숫자,-,),+ 조합만 사용가능합니다.'
        ),
      });
      return;
    }
    // 업체구분 하나는 필수
    if (!(snsrYnRef.current?.children[0].checked || ipYnRef.current?.children[0].checked)) {
      openMessageBar({
        type: 'error',
        content: t('admin.msg.업체 구분을 선택해 주세요.', '업체 구분을 선택해 주세요.'),
      });
      return;
    }
    if (props.isFromRegistPage) {
      openCommonModal({
        modalType: 'confirm',
        content: t(
          'com.label.업체 등록 요청을 진행하시겠습니까?',
          '업체 등록 요청을 진행하시겠습니까?'
        ),
        yesCallback: async () => {
          saveExcpPartnerNoSession({
            excpId: props.condition?.excpId,
            excpNm: cmpNmRef.current?.value,
            rpsnEml: rpsnEmlRef.current?.value,
            rpsnPhn: rpsnPhnRef.current?.value,
            rpsnNm: rpsnNmRef.current?.value,
            snsrYn: snsrYnRef.current?.children[0].checked ? 'Y' : 'N',
            ipYn: ipYnRef.current?.children[0].checked ? 'Y' : 'N',
            rmk: rmkRef.current?.value,
          })
            .then(() => {
              openMessageBar({
                type: 'confirm',
                content: t(
                  'com.label.요청 되었습니다. 업체등록이 완료되면 등록하신 메일로 알려드립니다. 감사합니다.',
                  '요청 되었습니다. 업체등록이 완료되면 등록하신 메일로 알려드립니다. 감사합니다.'
                ),
              });
              props.close();
            })
            .catch(() => {
              openMessageBar({
                type: 'error',
                content: t(
                  'admin.msg.저장에 실패하였습니다. 입력값을 확인하세요.',
                  '저장에 실패하였습니다. 입력값을 확인하세요.'
                ),
              });
            });
        },
      });
    } else {
      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
        yesCallback: async () => {
          saveExcpPartner({
            excpId: props.condition?.excpId,
            excpNm: cmpNmRef.current?.value,
            rpsnEml: rpsnEmlRef.current?.value,
            rpsnPhn: rpsnPhnRef.current?.value,
            rpsnNm: rpsnNmRef.current?.value,
            snsrYn: snsrYnRef.current?.children[0].checked ? 'Y' : 'N',
            ipYn: ipYnRef.current?.children[0].checked ? 'Y' : 'N',
            rmk: rmkRef.current?.value,
          })
            .then(() => {
              openMessageBar({
                type: 'confirm',
                content: t('com.label.저장되었습니다.', '저장되었습니다.'),
              });
              props.close();
            })
            .catch(() => {
              openMessageBar({
                type: 'error',
                content: t(
                  'admin.msg.저장에 실패하였습니다. 입력값을 확인하세요.',
                  '저장에 실패하였습니다. 입력값을 확인하세요.'
                ),
              });
            });
        },
      });
    }
  };

  const duplicateChecker = async () => {
    if (!cmpNmRef.current?.value || cmpNmRef.current?.value.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.업체명을 입력해 주세요.', '업체명을 입력해 주세요.'),
      });
      return;
    } else {
      compNmCheck(cmpNmRef.current?.value, props.condition?.excpId).then((data) => {
        if (data === 'N') {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.등록 가능한 업체명입니다.', '등록 가능한 업체명입니다.'),
          });
          setCompleteCheck(true);
        } else {
          openMessageBar({
            type: 'error',
            content: t('admin.msg.기존 업체명과 동일합니다.', '기존 업체명과 동일합니다.'),
          });
          setCompleteCheck(false);
        }
      });
    }
  };

  return (
    <SubLayout>
      <Dialog open={true} css={di.dialog} fullWidth className="md" maxWidth={false}>
        <TitleWrap>
          <h2>
            {hasRole('ADM') || hasRole('PTN')
              ? t('com.label.협력업체정보', '협력업체정보')
              : t('com.label.협력업체등록', '협력업체등록')}
          </h2>
          <CloseBtn
            onClick={() => {
              props.setPopup(false);
            }}
          ></CloseBtn>
        </TitleWrap>
        <DialogContent>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('com.label.기본정보', '기본정보')}</h3>
            </SubTitleGroup>
          </SubTitleLayout>
          <TableContainer css={tb.table}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ verticalAlign: 'middle' }}>
                    <span className="dot">{t('com.label.업체명', '업체명')}</span>
                  </TableCell>
                  <TableCell className="duplication">
                    <div>
                      <AllInputWrap>
                        <CustomInputWrap>
                          <CustomInputText
                            id="excpNm"
                            defaultValue={popCondition?.excpNm}
                            ref={cmpNmRef}
                            disabled={!hasAuth || forDelete || isUserNonEditableFlag}
                            onChange={() => setCompleteCheck(false)}
                          ></CustomInputText>
                        </CustomInputWrap>
                      </AllInputWrap>
                      {hasAuth && (
                        <Button
                          // style={{ marginBottom: '-6px' }}
                          onClick={duplicateChecker}
                          disabled={hasCompleteCheck}
                          onChange={() => setCompleteCheck(false)}
                        >
                          {t('com.button.중복체크', '중복체크')}
                        </Button>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className="dot">{t('com.label.대표자명', '대표자명')}</span>
                  </TableCell>
                  <TableCell>
                    <CustomInputWrap>
                      <CustomInputText
                        id="rpsnNm"
                        ref={rpsnNmRef}
                        disabled={forDelete}
                        defaultValue={popCondition?.rpsnNm}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <span className="dot">{t('com.label.이메일주소', '이메일주소')}</span>
                  </TableCell>
                  <TableCell>
                    <CustomInputWrap>
                      <CustomInputText
                        id="rpsnEml"
                        ref={rpsnEmlRef}
                        disabled={forDelete}
                        defaultValue={popCondition?.rpsnEml}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('admin.grid.대표전화번호', '대표전화번호')}</TableCell>
                  <TableCell>
                    <CustomInputWrap>
                      <CustomInputText
                        id="rpsnPhn"
                        ref={rpsnPhnRef}
                        disabled={forDelete}
                        defaultValue={popCondition?.rpsnPhn}
                      ></CustomInputText>
                    </CustomInputWrap>
                  </TableCell>
                </TableRow>
                {forDelete && (
                  <TableRow>
                    <TableCell>
                      <span className="dot">{t('com.label.반려 사유', '반려 사유')}</span>
                    </TableCell>
                    <TableCell>
                      <CustomInputWrap>
                        <CustomInputText id="rmk" ref={rmkRef} defaultValue={''}></CustomInputText>
                      </CustomInputWrap>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!forDelete && (
            <>
              <SubTitleLayout>
                <SubTitleGroup>
                  <h3>{t('admin.label.업무 권한', '업무 권한')}</h3>
                </SubTitleGroup>
              </SubTitleLayout>
              <TableContainer css={tb.table}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <span className="dot">{t('admin.grid.업체구분', '업체구분')}</span>
                      </TableCell>
                      <TableCell>
                        <div className="flex">
                          <Checkbox
                            id="ipYn"
                            css={st.checkbox}
                            ref={ipYnRef}
                            disabled={isUserNonEditableFlag}
                            defaultChecked={props.condition?.ipYn === 'Y'}
                          />
                          <label>{t('admin.grid.설비업체', '설비업체')}</label>
                          <Checkbox
                            id="snsrYn"
                            css={st.checkbox}
                            ref={snsrYnRef}
                            disabled={isUserNonEditableFlag}
                            defaultChecked={props.condition?.snsrYn === 'Y'}
                          />
                          <label>{t('admin.grid.센서', '센서')}</label>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <span>{t('com.label.비고(사유)', '비고(사유)')}</span>
                      </TableCell>
                      <TableCell>
                        <CustomInputWrap>
                          <CustomInputText
                            id="rmk"
                            ref={rmkRef}
                            defaultValue={''}
                          ></CustomInputText>
                        </CustomInputWrap>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        {isEditableFlag && (
          <PopupBtnGroup>
            <SearchButtonWrap>
              {/* <Button className="cancel" onClick={() => close()} disableRipple> */}
              <Button
                onClick={() => {
                  props.setPopup(false);
                }}
              >
                {t('com.button.취소', '취소')}
              </Button>
              <Button
                css={IconButton.button}
                className="save"
                onClick={handleItemSave}
                disableRipple
              >
                {hasRole('ADM') || hasRole('PTN')
                  ? t('com.button.저장', '저장')
                  : t('com.button.등록요청', '등록요청')}
              </Button>
            </SearchButtonWrap>
          </PopupBtnGroup>
        )}
      </Dialog>
    </SubLayout>
  );
};
