/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { tb } from 'components/layouts/Table';
import CustomTextarea from 'components/inputs/CustomTextarea';
import { form, st } from 'components/inputs/CustomInput';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { ComboBox } from 'components/selects/ComboBox';
import { getCommonCodeNames, getCommonCodes } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { RequestDetail } from 'models/approves/ReferenceOpinion';
import _ from 'lodash';
import { getApprovalTemplate } from 'apis/admin/Approval';
import { ApprovalTemplate, aprTplNm } from 'models/admin/ApprovalTemplate';

type Props = {
  detail: RequestDetail;
  onChange?: (name: string, value: string) => void;
  isReadOnly?: boolean;
};

const ApproveBasicInfo = ({ detail, onChange, isReadOnly = false }: Props, ref) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<any>({});
  const [apprSecurityTypeCds, setApprSecurityTypeCds] = useState<Code[]>([
    { cmnCd: '0', cmnCdNm: String(t('com.label.일반문서', '일반문서')) },
    { cmnCd: '1', cmnCdNm: String(t('com.label.비밀문서', '비밀문서')) },
  ]);
  /*
  const [apprLineTypeCds, setApprLineTypeCds] = useState<Code[]>([
    { cmnCd: '0', cmnCdNm: String(t('com.label.순차', '순차')) },
    { cmnCd: '1', cmnCdNm: String(t('com.label.협의', '협의')) },
  ]);
  */
  const [code, setCode] = useState<any>();
  const [aprTplNm, setAprTplNm] = useState<any>();

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (isReadOnly) return true;

      const error = {};
      if (!detail?.aprReqTitNm) {
        Object.assign(error, { aprReqTitNm: true });
      }
      if (!detail?.trnmBdyCtn) {
        Object.assign(error, { trnmBdyCtn: true });
      }

      setErrors(error);
      if (!Object.keys(error).filter((k) => error[k]).length) {
        return true;
      }
      return false;
    },
  }));

  useEffect(() => {
    getCommonCode();
  }, [detail]);

  const getCommonCode = async () => {
    const aprPvnDdlnCd: Code[] = await getCommonCodeNames('APR_PVN_DDLN_CD');

    //pageId로 공통코드를 가져옴
    const aprReqPgeTpCd = await getCommonCodeNames('APR_REQ_PAGE_TP_CD');
    if (detail?.aprTplNm) {
      setAprTplNm(detail.aprTplNm);
    } else {
      // 업무화면 -> 신규 / 수정 / 삭제
      const aprPageId = aprReqPgeTpCd.filter((item) => item.cmnCd === detail.aprPageId);
      setAprTplNm(aprPageId[0]?.cmnCdNm);
    }

    setCode({
      aprPvnDdlnCd: aprPvnDdlnCd,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setErrors({ ...errors, [e.target.name]: false });
    onChange && onChange(e.target.name, e.target.value);
  };

  return (
    <>
      <TableContainer css={tb.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{t('com.label.요청 ID', '요청 ID')}</TableCell>
              <TableCell className="w500">{detail.aprReqId}</TableCell>
              <TableCell>{t('com.label.진행상태', '진행상태')}</TableCell>
              <TableCell className="w500">{detail.aprReqProgStatNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('com.label.작성자', '작성자')}</TableCell>
              <TableCell className="w500">{detail.aprReqUserNm}</TableCell>
              <TableCell>{t('com.label.결재요청양식', '요청양식')}</TableCell>
              <TableCell className="w500">{aprTplNm}</TableCell>
              {/*
              // TODO [24.03.22] 전자결재 시 일반문서(0) 고정으로 사용 (따로 저장하지 않음)
              <TableCell>{t('com.label.문서종류', '문서종류')}</TableCell>
              <TableCell className="w500">
                <FormControl>
                  <RadioGroup
                    css={form.radioGroup}
                    row
                    aria-labelledby="radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    {apprSecurityTypeCds.map((o) => (
                      <FormControlLabel
                        key={o.cmnCd}
                        id={`appr_security_type_cd_${o.cmnCd}`}
                        value={o.cmnCd}
                        name={'apprSecurityType'}
                        control={<Radio />}
                        label={o.cmnCdNm}
                        checked={detail?.apprSecurityType === o.cmnCd}
                        disabled={true} // 문서종류 미사용으로 disabled 처리
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </TableCell>*/}
            </TableRow>
            <TableRow>
              <TableCell>{t('com.label.작성일시', '작성일시')}</TableCell>
              <TableCell className="w500">{detail.dataInsDtm ?? ''}</TableCell>
              <TableCell>{t('com.label.보존년한', '보존년한')}</TableCell>
              <TableCell className="w500">
                <ComboBox
                  options={code?.aprPvnDdlnCd}
                  defaultValue={detail.aprPvnDdlnCd}
                  classNameWrap={'w450'}
                  onChange={(value: string) => {
                    onChange?.('aprPvnDdlnCd', value);
                  }}
                  readOnly={isReadOnly}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.요청 제목', '요청 제목')}</span>
              </TableCell>
              <TableCell colSpan={3}>
                <CustomInputWithSearch
                  type="text"
                  name="aprReqTitNm"
                  placeholder={String(
                    t('com.msg.요청제목을 입력해 주세요.', '요청제목을 입력해 주세요.')
                  )}
                  value={detail.aprReqTitNm}
                  onChange={handleChange}
                  isError={errors?.aprReqTitNm}
                  msgError={String(
                    t('com.msg.요청제목을 입력해 주세요.', '요청제목을 입력해 주세요.')
                  )}
                  readOnly={isReadOnly}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.요청 내용', '요청 내용')}</span>
              </TableCell>
              <TableCell colSpan={3}>
                <CustomTextarea
                  name="trnmBdyCtn"
                  placeholder={String(
                    t('com.msg.요청 내용을 입력해 주세요.', '요청 내용을 입력해 주세요.')
                  )}
                  value={detail.trnmBdyCtn}
                  onChange={handleChange}
                  isError={errors?.trnmBdyCtn}
                  msgError={String(
                    t('com.msg.요청 내용을 입력해 주세요.', '요청 내용을 입력해 주세요.')
                  )}
                  readOnly={isReadOnly}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default React.forwardRef(ApproveBasicInfo);
