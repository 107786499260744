/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import _ from 'lodash';
import { IconButton } from 'components/buttons/IconSVG';
import { GlobalBtnGroup } from 'components/layouts/ContentLayout';
import { useMessageBar } from 'hooks/useMessageBar';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { ComboBox } from 'components/selects/ComboBox';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { EquipmentClassificationCondition } from 'models/mp/MpEquipment';
import { ContentSection } from 'components/layouts/ContentSection';
import { ContentFlex } from 'components/layouts/ContentFlex';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { checkAvailableDeleteRequest, checkAvailableRequest } from 'apis/mp/MpEquipment';
import MpEquipmentClassificationMasterGrid from 'pages/mp/equipmentmanagement/MpEquipmentClassificationMasterGrid';
import MpEquipmentClassificationDetailGrid from 'pages/mp/equipmentmanagement/MpEquipmentClassificationDetailGrid';
import { EquipmentGroupPopUp } from '../common/popup/EquipmentGroupPopUp';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { ManagementMode } from 'models/common/Common';
import { ApproveRequestPageType } from '../approves/ApproveRequestDetailCase';
import ApproveRequestModal from '../approves/ApproveRequestModal';
import CustomSwitch from 'components/selects/CustomSwitch';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import { hasRole } from 'utils/SessionUtil';

const MpEquipmentClassificationPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const masterGridRef = useRef<any>();
  const detailGridRef = useRef<any>();
  const [errors, setErrors] = useState<any>({});
  const [code, setCode] = useState<any>();
  const [equipmentClassificationCondition, setEquipmentClassificationCondition] =
    useState<EquipmentClassificationCondition>({ useYn: 'Y' });
  const [requestModalCondition, setRequestModalCondition] = useState<any>();
  const [isOpenEquipmentGroupPopUp, setOpenEequipmentGroupPopUp] = useState<boolean>(false);
  const [isOpenRequestModal, setOpenRequestModal] = useState<boolean>(false);
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  useEffect(() => {
    // 관리자 권한 여부
    setHasAuth(hasRole('MP_MANAGER') || hasRole('ADM'));
    getCommonCodesForGrid();
  }, []);

  const handleSearch = async (condition) => {
    if (_.isEmpty(condition.mpClsfCd)) {
      setErrors({ mpClsfCd: true });
      return;
    }

    detailGridRef.current.init();
    masterGridRef.current.search(condition);
  };

  const getCommonCodesForGrid = async () => {
    const mpClsfType: Code[] = await getCommonCodeNames('MP_CLSF_TYPE'); // MP분류
    setCode({
      mpClsfType: mpClsfType,
    });
  };

  const handleResetCondition = () => {
    setEquipmentClassificationCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as EquipmentClassificationCondition
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(equipmentClassificationCondition);
    }
  };

  const handleItemChange = (name: string, value: string | string[]) => {
    setEquipmentClassificationCondition((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows className="FourCol">
              <SearchCols>
                <label>
                  <span className={'dot'}>{String(t('mp.label.MP 분류', 'MP 분류'))}</span>
                </label>
                <ComboBox
                  placeholder={String(t('mp.msg.분류', '분류'))}
                  options={code?.mpClsfType}
                  defaultValue={equipmentClassificationCondition?.mpClsfCd}
                  isError={errors?.mpClsfCd}
                  msgError={String(t('mp.msg.MP분류를 선택해 주세요.', 'MP분류를 선택해 주세요.'))}
                  onChange={(value) => {
                    handleItemChange('mpClsfCd', value);
                  }}
                  // onChange={(value) => {
                  //   setEquipmentClassificationCondition({
                  //     ...equipmentClassificationCondition,
                  //     mpClsfCd: value as string,
                  //   });
                  // }}
                />
              </SearchCols>
              <SearchCols>
                <label>{String(t('mp.label.설비분류체계명', '설비분류체계명'))}</label>
                <CustomInputWithSearch
                  type="text"
                  name="eqpClsfNm"
                  placeholder={String(
                    t('mp.msg.설비분류체계명을 입력해 주세요.', '설비분류체계명을 입력해 주세요.')
                  )}
                  value={equipmentClassificationCondition.eqpClsfNm}
                  onChange={(e) => {
                    handleItemChange('eqpClsfNm', e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>
                  <span>{t('com.label.설비군', '설비군')}</span>
                </label>
                <CustomInputWithSearch
                  name="eqclId"
                  className="find"
                  placeholder={String(
                    t('mp.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.')
                  )}
                  value={equipmentClassificationCondition.eqclId}
                  onChange={(e) => {
                    handleItemChange('eqclId', e.target.value);
                  }}
                  onSearchClick={() => setOpenEequipmentGroupPopUp(true)}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label htmlFor="useYn">{t('com.label.사용여부', '사용여부')}</label>
                <CustomSwitch
                  id="useYn"
                  onValue={'Y'}
                  onLabel={String(t('com.label.사용', '사용'))}
                  offValue={'N'}
                  offLabel={String(t('com.label.미사용', '미사용'))}
                  defaultValue={equipmentClassificationCondition?.useYn}
                  onChange={(value) => {
                    setEquipmentClassificationCondition((prev) => ({
                      ...prev,
                      useYn: value,
                    }));
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            defaultCondition={{ useYn: 'Y' }}
            onReset={() => handleResetCondition()}
            onSearch={() => handleSearch(equipmentClassificationCondition)}
          />
        </SearchBoxRow>
      </SearchBox>

      <ContentFlex>
        <ContentSection className="section width35p marginT0">
          <MpEquipmentClassificationMasterGrid
            ref={masterGridRef}
            onClickDetail={(item) => {
              detailGridRef.current.search(item);
            }}
            onClickApprove={(aprReqId) => {
              if (aprReqId) {
                setRequestModalCondition({
                  pageId: ApproveRequestPageType.MP_CLSF_REQ,
                  aprReqId: aprReqId,
                });
                setOpenRequestModal(true);
              }
            }}
          />
        </ContentSection>
        <ContentSection className="section width65p">
          <MpEquipmentClassificationDetailGrid
            ref={detailGridRef}
            props={equipmentClassificationCondition}
          />

          {hasAuth && (
            <GlobalBtnGroup>
              <Button
                css={IconButton.button}
                className="create"
                onClick={() => {
                  setRequestModalCondition({
                    pageId: ApproveRequestPageType.MP_CLSF_REQ,
                    mode: ManagementMode.CREATE,
                  });
                  setOpenRequestModal(true);
                }}
                disableRipple
              >
                {t('com.button.등록', '등록')}
              </Button>
              <Button
                css={IconButton.button}
                className="edit"
                onClick={() => {
                  const selectedMasterRows = masterGridRef.current.getSelectedRow();
                  if (selectedMasterRows.length < 1) {
                    openMessageBar({
                      type: 'error',
                      content: t(
                        'mp.msg.수정할 항목을 선택해주세요.',
                        '수정할 항목을 선택해주세요.'
                      ),
                    });
                    return;
                  }

                  const masterList = selectedMasterRows.map((o) => ({ ...o }));
                  checkAvailableRequest(masterList).then((res) => {
                    if (res.successOrNot === 'Y' && res.data) {
                      setRequestModalCondition({
                        pageId: ApproveRequestPageType.MP_CLSF_UPDATE_REQ,
                        mode: ManagementMode.MODIFY,
                        requestMasterList: masterList,
                      });
                      setOpenRequestModal(true);
                    } else {
                      openMessageBar({
                        type: 'error',
                        content:
                          res.data && typeof res.data === 'string'
                            ? res.data
                            : t('mp.msg.수정 요청할 수 없습니다.', '수정 요청할 수 없습니다.'),
                      });
                    }
                  });
                }}
                disableRipple
              >
                {t('com.button.수정', '수정')}
              </Button>
              <Button
                css={IconButton.button}
                className="delete"
                onClick={() => {
                  let isSuccess = false;
                  const selectedMasterRows = masterGridRef.current.getSelectedRow();
                  if (selectedMasterRows.length < 1) {
                    openMessageBar({
                      type: 'error',
                      content: t(
                        'mp.msg.삭제할 항목을 선택해주세요.',
                        '삭제할 항목을 선택해주세요.'
                      ),
                    });
                    return;
                  }

                  const masterList = selectedMasterRows.map((o) => ({ ...o }));

                  checkAvailableRequest(masterList).then((res) => {
                    if (res.successOrNot === 'Y' && res.data) {
                      isSuccess = true;
                    } else {
                      openMessageBar({
                        type: 'error',
                        content:
                          res.data && typeof res.data === 'string'
                            ? res.data
                            : t('mp.msg.삭제 요청할 수 없습니다.', '삭제 요청할 수 없습니다.'),
                      });
                    }
                  });

                  checkAvailableDeleteRequest(masterList).then((res) => {
                    if (res.successOrNot === 'Y' && res.data) {
                      isSuccess = true;
                    } else {
                      isSuccess = false;
                      openMessageBar({
                        type: 'error',
                        content:
                          res.data && typeof res.data === 'string'
                            ? res.data
                            : t(
                                'mp.msg.item에 등록되어 있는 항목은 삭제 요청할 수 없습니다.',
                                'item에 등록되어 있는 항목은 삭제 요청할 수 없습니다.'
                              ),
                      });
                    }

                    if (isSuccess) {
                      setRequestModalCondition({
                        pageId: ApproveRequestPageType.MP_CLSF_DELETE_REQ,
                        mode: ManagementMode.DELETE,
                        requestMasterList: masterList,
                      });
                      setOpenRequestModal(true);
                    }
                  });
                }}
                disableRipple
              >
                {t('com.label.삭제', '삭제')}
              </Button>
            </GlobalBtnGroup>
          )}
        </ContentSection>
      </ContentFlex>
      {isOpenEquipmentGroupPopUp && (
        <EquipmentGroupPopUp
          open={isOpenEquipmentGroupPopUp}
          close={() => setOpenEequipmentGroupPopUp(false)}
          type={'mp'}
          condition={{
            equipmentGroup: equipmentClassificationCondition.eqclId || '',
          }}
          onCallback={(value) =>
            setEquipmentClassificationCondition({
              ...equipmentClassificationCondition,
              eqclId: (value as EquipmentGroupCondition).equipmentGroup,
            })
          }
        />
      )}
      {isOpenRequestModal && (
        <ApproveRequestModal
          open={isOpenRequestModal}
          close={() => setOpenRequestModal(false)}
          pageId={requestModalCondition?.pageId}
          aprReqId={requestModalCondition?.aprReqId}
          condition={{
            mode: requestModalCondition?.mode,
            requestMasterList: requestModalCondition?.requestMasterList,
          }}
        />
      )}
    </>
  );
};

export default MpEquipmentClassificationPage;
