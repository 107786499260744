import React from 'react';
import { css } from '@emotion/react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { CommonBG, CommonText, CommonBorder } from 'ui/theme/Color';
import { SVGIcon } from 'components/buttons/IconSVG';

export const tb01 = {
  tab: css`
    min-height: 32px;
    width: 100%;
    position: relative;

    .tabs {
      height: 32px;
      min-height: 32px;
      padding: 0 36px 0 12px !important;
      background-color: ${CommonBG.Deep};
      transition: 0.3s;
      font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: ${CommonText.Lighter};
      border-right: 1px solid ${CommonBorder.Bright} !important;
      border-top: none !important;
      border-bottom: 1px solid ${CommonBorder.Bright};
      &.home {
        padding: 0px !important;
        min-width: 40px !important;
      }

      &.Mui-selected {
        color: ${CommonText.Basic};
        font-weight: 700;
        background-color: ${CommonBG.Basic};
        border-bottom: 1px solid ${CommonBorder.Bright};
      }
    }

    .MuiTabs-scroller {
      min-width: 100%;
      width: 100%;
      position: relative;
    }
    .MuiTabs-indicator {
      display: none;
    }

    .MuiTabs-flexContainer {
      background-color: ${CommonBG.Deep};
      min-width: calc(100% - 128px);
      width: calc(100% - 128px);
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      position: absolute !important;
      top: 0;
      left: 0 !important;
      border-bottom: 1px solid ${CommonBorder.Light};
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .MuiTabs-indicator {
      background-color: ${CommonBG.Deep};
    }
  `,
};

export const TabWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const TabButtonGroup = styled.div`
  height: 32px;
  width: 128px !important;
  display: inline-flex;
  align-items: center;
  padding-right: 32px;
  background-color: ${CommonBG.Deep};
`;

export const TabControlButton = {
  button: css`
    min-width: 24px;
    width: 24px;
    height: 24px;
    padding: 0px !important;
    border: none;
    background: transparent;
    &:active {
      background: none !important;
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: ${CommonText.Light};
    }
    &.close {
      &:before {
        -webkit-mask: ${SVGIcon.IcoClose};
        mask-image: ${SVGIcon.IcoClose};
      }
    }
    &.link {
      &:before {
        -webkit-mask: ${SVGIcon.IcoPopup};
        mask-image: ${SVGIcon.IcoPopup};
      }
    }
    &.right {
      &:before {
        transform: translate(-50%, -50%) rotate(180deg);
        -webkit-mask: ${SVGIcon.IcoFillChevron};
        mask-image: ${SVGIcon.IcoFillChevron};
      }
    }
    &.left {
      &:before {
        -webkit-mask: ${SVGIcon.IcoFillChevron};
        mask-image: ${SVGIcon.IcoFillChevron};
      }
    }
  `,
};

export const TabCloseButton = styled(Box)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  min-width: 16px;
  width: 16px;
  height: 16px;
  border-radius: 0px;
  padding: 0 !important;
  -webkit-mask: ${SVGIcon.IcoClose};
  mask-image: ${SVGIcon.IcoClose};
  &.tabon {
    background: ${CommonText.Light};
  }
  &.taboff {
    background: ${CommonText.Lighter};
  }
`;

export const TabCloseButton01 = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  min-width: 16px;
  width: 16px;
  height: 16px;
  border-radius: 0px;
  padding: 0 !important;
  -webkit-mask: ${SVGIcon.IcoClose};
  mask-image: ${SVGIcon.IcoClose};
  cursor: pointer;
`;

export const ScrollingWrap = styled.div`
  width: 100% !important;
  z-index: 900;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .react-horizontal-scrolling-menu--wrapper {
    width: calc(100% - 128px);
    height: 32px;
    background: ${CommonBG.Deep};
    border-bottom: 1px solid ${CommonBorder.Bright};
  }
  .react-horizontal-scrolling-menu--header,
  .react-horizontal-scrolling-menu--footer {
    display: none;
  }
  .react-horizontal-scrolling-menu--scroll-container {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ButtonWrap {
    height: 32px;
    position: relative;
    border-right: 1px solid ${CommonBorder.Bright};
    padding: 0 36px 0 12px !important;
    button {
      font-size: 12px;
      font-weight: 400 !important;
      padding: 3px 0px 0px 0px !important;
      min-width: 0px !important;
      white-space: nowrap;
    }
    &.tabOn {
      background: ${CommonBG.Elevation};
      button {
        color: ${CommonText.Basic};
        font-weight: 500 !important;
      }
      div {
        background: ${CommonText.Light};
      }
    }
    &.tabOff {
      button {
        color: ${CommonText.Lighter};
      }
      div {
        background: ${CommonText.Lighter};
      }
    }
    &.home {
      text-align: center !important;
      padding: 0px !important;
      min-width: 40px !important;
    }
  }
  .react-horizontal-scrolling-menu--arrow-left,
  .react-horizontal-scrolling-menu--arrow-right {
    display: none;
  }
`;
