import { List } from 'lodash';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { PaginationResponse } from 'models/common/Pagination';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { NewSensorSolutionReq } from 'models/sensor/NewSensorSolutionReq';
import { callApi } from 'utils/ApiUtil';

/**신규센서솔루션요청 진행 현황 엑셀 다운로드 */
export const getNewSensorSolReqStatusListDownload = async (
  condition: ExcelDownloadRequest<NewSensorSolutionReq>
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/sensor/newSensorReqStatusListDownload`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };
  const response: CommonResponse<Blob> = await callApi(request);

  if (response?.data) {
    downloadFile(response.data, `${condition.fileName}`); //'data.xlsx' `${condition.fileName}`
  }
};

export const downloadFile = (data: Blob, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * 신규센서솔루션요청 진행 현황 조회
 * @param condition
 */
export const findSensorSolReqProgressStatusList = async (condition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/sensor/findSensorSolReqProgressStatusList`,
    serviceName: ServiceName.ELM_BE,
    // bodyParams: condition,
    queryParams: new URLSearchParams({ ...condition }),
  };
  const response: CommonResponse<PaginationResponse<NewSensorSolutionReq>> = await callApi(request);
  return response;
};
