import { Code } from 'models/common/CommonCode';

export enum CommonYN {
  Y = 'Y',
  N = 'N',
}

export enum ManagementMode {
  CREATE = 'C',
  CREATE_EXCEL = 'CE',
  MODIFY = 'M',
  MODIFY_EXCEL = 'ME',
  DELETE = 'D',
  READ = 'R',
}

/**
 * 업무구분
 *
 * 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
 */
export enum BizName {
  ETC = 'etc',
  GTNG = 'gtng',
  IP = 'ip',
  MP = 'mp',
  SNSR = 'snsr',
  TPL = 'tpl',
}

/**
 * 파일유형
 */
export enum FileTypeName {
  NORMAL = 'NORMAL',
  GTNG_MASTER = 'GTNG_MASTER',
  GTNG_EVIDENCE = 'GTNG_EVIDENCE',
  GTNG_S_RST = 'GTNG_S_RST',
  GTNG_F_RST = 'GTNG_F_RST',
  TPL_IP_LIST = 'TPL_IP_LIST',
  TPL_IP_RST = 'TPL_IP_RST',
  TPL_SNSR_LIST = 'TPL_SNSR_LIST',
  TPL_MP_LIST = 'TPL_MP_LIST',
  TPL_EQP_LIST = 'TPL_EQP_LIST',
  TPL_UT_REGIST = 'TPL_UT_REGIST',
  GUIDE_UT_REGIST = 'GUIDE_UT_REGIST',
}

export const commonYNcodes: Code[] = [
  { cmnCd: 'Y', cmnCdNm: 'Y' },
  { cmnCd: 'N', cmnCdNm: 'N' },
];

export const commonYNarr: Option[] = [
  { optionName: 'Y', optionValue: 'Y' },
  { optionName: 'N', optionValue: 'N' },
];

export interface Option {
  optionName: string;
  optionValue: string;
}

export enum FileUpload {
  maxSize = 1073741824, // 1GB
}
