import { Crud } from 'models/common/Edit';

export interface Menu {
  mnuId: string;
  mnuNm?: string;
  mnuUrl?: string;
  mnuLvNo?: number;
  upprMnuId?: string;
  sortOrd?: number;
  useYn?: string;
  mnuEpsYn?: string;
  mnuOptValCtn1?: string;
  mnuOptValCtn2?: string;
  mnuOptValCtn3?: string;
  mnuOptValCtn4?: string;
  mnuOptValCtn5?: string;
  msgCtn?: string;
  mnuDesc?: string;
  childrens?: Menu[];
  relateMnuId?: string; // 관련 메뉴ID (ex. 조회 -> 등록)
}

export interface MenuVO extends Menu {
  isChecked?: boolean;
  optionName?: string;
  optionValue?: string;
}

export interface MenuRequest extends Crud {
  mnuId: string;
  mnuNm: string;
  targetMenuId: string;
  menuLocation: string; //'1' : up, '2' : down, '3':inner, '4':none
  useYn: string;
  mnuEpsYn?: string;
  mnuOptValCtn1?: string;
  mnuOptValCtn2?: string;
  mnuOptValCtn3?: string;
  mnuOptValCtn4?: string;
  mnuOptValCtn5?: string;
  msgCtn?: string;
  mnuUrl: string;
  mnuDesc: string;
}
export enum MenuEnum {
  root = '000000',
  home = '홈',
}

export interface MenuContextType {
  selectedHeaderMenu: string;
  currentMenu: Menu;
  stacksMenu: Menu[];
  tabIndex: number;
  clickedByHeaderMenu: boolean;
  handleMenuChange: (item: MenuContextType) => void;
  closeCurrentTab: (item: MenuContextType) => void;
}

/**
 * 메뉴기본 테이블의 루트메뉴 데이터와 동일
 */
export const HomeMenu: Menu = {
  mnuId: MenuEnum.root,
  mnuNm: MenuEnum.home,
  msgCtn: 'com.menu.000000',
  mnuUrl: '/',
};

export const defaultMenuContext: MenuContextType = {
  selectedHeaderMenu: '',
  currentMenu: HomeMenu,
  clickedByHeaderMenu: false,
  stacksMenu: [HomeMenu],
  tabIndex: 0,
  handleMenuChange: (item: MenuContextType) => {
    return;
  },
  closeCurrentTab: (item: MenuContextType) => {
    return;
  },
};
