/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useMemo, useRef, useImperativeHandle } from 'react';
import useEvent from 'react-use-event-hook';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button } from '@mui/material';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { DataType, addClass, toggleClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { findUtMatrix, saveUtMatrix } from 'apis/ut/UtMatrixRegist';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import ExcelUploadButton from 'components/buttons/ExcelUploadButton';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { Code } from 'models/common/CommonCode';
import { FileTypeName, commonYNcodes } from 'models/common/Common';
import {
  downloadExcelUtMatrixTemplatesPost,
  uploadExcelUtMatrixTemplates,
} from 'apis/common/Excel';
import {
  calculateBrkeCapa,
  calculateCspSumCapa,
  calculateElpwSumCapa,
  calculateOd1EcNvl,
  PWFT_NVL_DEFAULT,
} from 'utils/UtMatrixUtil';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLoading } from 'components/process/Loading';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { CrudCode } from 'models/common/Edit';
import ExcelValidModal from 'components/modals/common/ExcelValidModal';
import { downloadFileByType } from 'apis/file/File';
import { hasRole } from 'utils/SessionUtil';
import UtMatrixDetailProcessPopup from '../popup/UtMatrixDetailProcessPopup';
import { useCommonModal } from 'hooks/useCommonModal';
import { copyUtMatrixDetailProcess } from 'apis/ut/UtMatrixDetailProcess';
import { UtMatrixDetailList } from 'models/ut/UtMatrixList';
import { SuccessOrNot } from 'models/common/RestApi';
import { getExcelFileName } from '../../../utils/ExcelUtil';

const UtMatrixContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const [code, setCode] = useState<any>();

  const [isUtMatrixDetailProcessModalOpen, setUtMatrixDetailProcessModalOpen] =
    useState<boolean>(false);
  const [isExcelValidModalOpen, setExcelValidModalOpen] = useState<boolean>(false);
  const [excelValidCondition, setExcelValidCondition] = useState<string | null>(null);
  const isUtWriter = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return (p.length > 0 && p[0].utmWriteYn === 'Y') || hasRole('ADM');
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);
  const isWrite = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP02';
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);

  const isWriteComplete = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP03';
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);

  useImperativeHandle(ref, () => ({
    searchRegistMatrix: (params) => {
      searchRegistMatrix(params);
    },
    saveRegistMatrix: (showMsg = true) => {
      return new Promise((resolve) => {
        handleSave(showMsg).finally(() => {
          resolve(true);
        });
      });
    },
  }));

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  const getCommonCodesForGrid = async () => {
    const bldgFloCd: Code[] = await getCommonCodeNames('BLDG_FLO_CD');
    const utVltgList: Code[] = await getCommonCodeNames('UT_VLTG_LIST');
    const elpwPhasCd: Code[] = await getCommonCodeNames('PSN_PHAS_CD'); // TODO 컬럼명 변경됨 (elpwPhasCd)
    const ctwTpCd: Code[] = await getCommonCodeNames('CTW_TP_CD');
    const utFrqList: Code[] = await getCommonCodeNames('UT_FRQ_LIST');
    const utPwftList: Code[] = await getCommonCodeNames('UT_PWFT_LIST');
    const elpwEqpCnctTpCd: Code[] = await getCommonCodeNames('PSN_CNCT_TP_CD'); // TODO 컬럼명 변경됨 (elpwEqpCnctTpCd)
    const devcCnctTpCd: Code[] = await getCommonCodeNames('DEVC_CNCT_TP_CD');
    const cdaPresList: Code[] = await getCommonCodeNames('CDA_PRES_LIST');
    const n2PresList: Code[] = await getCommonCodeNames('N2_PRES_LIST');
    const exhaCnctTpCd: Code[] = await getCommonCodeNames('EXHA_CNCT_TP_CD');
    const exhaCllrTpCd: Code[] = await getCommonCodeNames('EXHA_CLLR_TP_CD');
    // const utQtyList: Code[] = await getCommonCodeNames('UT_QTY_LIST');
    const utBrkeCapaList: Code[] = await getCommonCodeNames('UT_BRKE_CAPA_LIST');
    const bildPlntCd: Code[] = await getCommonCodeNames('BILD_PLNT_CD');
    const suarCllrTpCd: Code[] = await getCommonCodeNames('SUAR_CLLR_TP_CD');

    setCode({
      bldgFloCd: bldgFloCd,
      utVltgList: utVltgList,
      elpwPhasCd: elpwPhasCd,
      ctwTpCd: ctwTpCd,
      utFrqList: utFrqList,
      utPwftList: utPwftList,
      elpwEqpCnctTpCd: elpwEqpCnctTpCd,
      devcCnctTpCd: devcCnctTpCd,
      cdaPresList: cdaPresList,
      n2PresList: n2PresList,
      exhaCnctTpCd: exhaCnctTpCd,
      exhaCllrTpCd: exhaCllrTpCd,
      utBrkeCapaList: utBrkeCapaList,
      bildPlntCd: bildPlntCd,
      suarCllrTpCd: suarCllrTpCd,
    });
  };

  const searchRegistMatrix = (params) => {
    findUtMatrix('write', params).then((result) => {
      const p = (processData || []).filter(
        (o) => o.prdnProcCd === params?.prdnProcCd && o.dtalProcCd === params?.dtalProcCd
      );
      let isWt = false,
        isUtWt = false;
      if (p.length > 0) {
        isWt = p[0].utmWrtProcProgStatCd === 'UTP02';
        isUtWt = p[0].utmWriteYn === 'Y' || hasRole('ADM');
      }
      let rows = result || [];
      // 편집가능한 상태면서 데이터가 없는 경우 행추가
      if (rows.length < 1 && isWt && isUtWt) {
        const newRow = {
          crudKey: CrudCode.CREATE,
          utmId: params.utmId,
          planProcId: params.planProcId,
          prdnProcCd: params.prdnProcCd,
          prdnProcCdNm: params.prdnProcCdNm,
          dtalProcCd: params.dtalProcCd,
          dtalProcCdNm: params.dtalProcCdNm,
        } as UtMatrixDetail;
        rows = [newRow];
      }
      setRowData(rows);
    });
  };

  const layoutDefinition = useMemo(() => {
    const bildPlntCd = (code?.bildPlntCd || []).filter((o) => o.cmnCd === condition?.bildPlntCd);
    const usNusType = bildPlntCd.length > 0 ? bildPlntCd[0].optValCtn2 : 'US';
    const mapUtVltgList = new DataMap(code?.utVltgList || [], 'cmnCd', 'cmnCdNm');
    mapUtVltgList.getDisplayValues = (item) => {
      const col = usNusType === 'US' ? 'optValCtn1' : 'optValCtn2';
      return (code?.utVltgList || [])
        .filter((o) => o[col] === usNusType)
        .filter((o) => !!o.cmnCdNm)
        .map((o) => o.cmnCdNm || '');
    };

    return [
      {
        binding: 'utmSeq',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        // cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'dtalProcCdNm',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 110,
        isReadOnly: true,
      },
      {
        header: String(t('ut.label.Facility Location', 'Facility Location')),
        columns: [
          {
            binding: 'bldgFloCd',
            header: String(t('ut.label.Floor', 'Floor')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.bldgFloCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'istlLocNm',
            header: String(t('ut.label.Room', 'Room')),
            width: 100,
          },
        ],
      },
      {
        binding: 'prdnLnNm',
        header: String(t('ut.label.Line', 'Line')),
        width: 100,
      },
      {
        binding: 'prdnPrlLnNm',
        header: String(t('ut.label.Sub Line', 'Sub Line')),
        width: 100,
      },
      {
        binding: 'eqpMainNm',
        header: String(t('ut.label.Main', 'Main')),
        width: 100,
      },
      {
        binding: 'eqpMchNm',
        header: String(t('ut.label.Machine', 'Machine')),
        width: 100,
      },
      {
        binding: 'eqpUntNm',
        header: String(t('ut.label.Unit', 'Unit')),
        width: 100,
      },
      {
        header: String(t('ut.label.EQP Code', 'EQP Code')),
        columns: [
          {
            binding: 'machEqpNm',
            header: String(t('ut.label.Mech', 'Mech')),
            width: 100,
          },
          {
            binding: 'elpwEqpNm',
            header: String(t('ut.label.Elec', 'Elec')),
            width: 100,
          },
        ],
      },
      {
        header: String(t('ut.label.Qty(Total)', 'Qty(Total)')),
        columns: [
          {
            binding: 'machEqpQty',
            header: String(t('ut.label.Mech', 'Mech')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'elpwEqpQty',
            header: String(t('ut.label.Elec', 'Elec')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.EQP Spec(Dimension_mm)', 'EQP Spec(Dimension:mm)')),
        columns: [
          {
            binding: 'eqpDimWthLen',
            header: String(t('ut.label.Width', 'Width')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'eqpDimDpthNvl',
            header: String(t('ut.label.Depth', 'Depth')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'eqpDimHigtNvl',
            header: String(t('ut.label.Height', 'Height')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        binding: 'eqpWgt',
        header: String(t('ut.label.EQP Spec(Weight_kg)', 'EQP Spec(Weight:kg)')),
        width: 100,
        isRequired: false,
        dataType: DataType.Number,
      },
      {
        header: String(t('ut.label.Electricity', 'Electricity')),
        collapseTo: 'vltgNvl',
        columns: [
          {
            binding: 'elpwEqpGrNm',
            header: String(t('ut.label.Group 명', 'Group 명')),
            width: 100,
          },
          {
            binding: 'vltgNvl', // 전기필수 (ELTR_EQP_QTY 값이 1 이상일때 필수)
            header: String(t('ut.label.Voltage[V]', 'Voltage[V]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: mapUtVltgList,
          },
          {
            binding: 'elpwPhasCd', // 전기필수
            header: String(t('ut.label.Phase[Φ]', 'Phase[Φ]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.elpwPhasCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'ctwTpCd',
            header: String(t('ut.label.Wires[EA]', 'Wires[EA]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.ctwTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            // UT Matrix (UT Matrix No)별로 동일해야 함
            binding: 'frqNvl', // 전기필수
            header: String(t('ut.label.Frequency[Hz]', 'Frequency[Hz]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.utFrqList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            // 0.9로 Default 입력
            binding: 'pwftNvl', // 전기필수
            header: String(t('ut.label.Power Factor-', 'Power Factor-')),
            width: 100,
            align: 'right',
            dataType: DataType.String,
          },
          {
            // [Current(계산값)] = "Phase ="3P“  이면   ＂Capacity[KW]＂*1000/SQRT(3)/＂Voltage[V]＂/＂Power factor＂
            //                                아니면  , "Capacity[KW]"*1000/"Voltage[V]"/"Power factor"
            binding: 'od1EcNvl', // 수식 시스템 계산
            header: String(t('ut.label.Current(계산값)[A]', 'Current(계산값)[A]')),
            width: 100,
            align: 'right',
            isReadOnly: true,
            cellTemplate: (params) =>
              Number(params.item.elpwEqpQty || 0) < 1
                ? ''
                : params.item.od1EcNvl
                ? `${params.item.od1EcNvl}`
                : calculateOd1EcNvl(
                    params.item.elpwEqpQty,
                    params.item.elpwPhasCd,
                    params.item.elpwCapa,
                    params.item.vltgNvl,
                    params.item.pwftNvl
                  ),
          },
          {
            binding: 'od2EcNvl',
            header: String(t('ut.label.Current(입력값)[A]', 'Current(입력값)[A]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            /**
             * 조건부 계산
             * 1. 사용자 입력일때 입력 값 사용
             * 2. 입력이 아닐때 자동 계산
             *
             * Current (계산값)[A] 과 Current(입력값)[A]  두 값 중 더 큰값을 기준으로 1.25배한 값보다 큰 가장 근접한 값 ( 대상 List : UT_BRKE_CAPA_LIST)을 자동 입력
             * 참고 :
             * pkg_elm_xls_upload.upload_ut_matrix_list의 tb_eelmb_utm_d.brke_capa  계산로직 참고
             */
            binding: 'brkeCapa',
            header: String(t('ut.label.Breaker(MCCB)-', 'Breaker(MCCB)-')),
            width: 100,
            isRequired: false,
            align: 'right',
            cellTemplate: (params) =>
              Number(params.item.elpwEqpQty || 0) < 1
                ? ''
                : params.item.brkeCapa
                ? `${params.item.brkeCapa}`
                : calculateBrkeCapa(
                    code?.utBrkeCapaList || [],
                    params.item.od1EcNvl
                      ? params.item.od1EcNvl
                      : calculateOd1EcNvl(
                          params.item.elpwEqpQty,
                          params.item.elpwPhasCd,
                          params.item.elpwCapa,
                          params.item.vltgNvl,
                          params.item.pwftNvl
                        ),
                    params.item.od2EcNvl
                  ),
          },
          {
            binding: 'plcClctYn', // 전기필수
            header: String(t('ut.label.PLC접지유무-', 'PLC접지유무-')),
            width: 100,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
          },
          {
            // Voltage[V] = 120 이면 “OUTLET” 자동 아니면 사용자 선택
            binding: 'elpwEqpCnctTpCd', // 전기필수
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.elpwEqpCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'elpwCapa', // 전기필수
            header: String(t('ut.label.Capacity[KW]', 'Capacity[KW]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'elpwSumCapa', // 수식계산 (= Qty(Total) Elec * Capacity[KW])
            header: String(t('ut.label.Capacity Sum', 'Capacity Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateElpwSumCapa(params.item?.elpwEqpQty, params.item?.elpwCapa),
          },
          {
            binding: 'elpwStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'elpwHeatCapa', // 전기필수
            header: String(t('ut.label.Heat Capacity[KW]', 'Heat Capacity[KW]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'dmdElpwTarfNvl', // 검토화면에서 UT Manager 권한인 경우 입력
            header: String(t('ut.label.Demand Factor[%]', 'Demand Factor[%]')),
            width: 100,
            dataType: DataType.Number,
            isReadOnly: true,
          },
          {
            binding: 'dmdElpwCapa', // 검토화면에서 자동계산 (= Capacity Sum * Demand Factor[%])
            header: String(
              t('ut.label.Capacity_Sum(수용율 적용)[KW]', 'Capacity_Sum(수용율 적용)[KW]')
            ),
            width: 100,
            isReadOnly: true,
          },
          // 개선요청사항(김중열) 57번 - 13. Static Condenser Capa. 해당 열 삭제
          // {
          //   binding: 'cdsCapa', // 검토화면에서 UT Manager 권한인 경우 입력
          //   header: String(
          //     t(
          //       'ut.label.Static Condenser Capa. (p.f. = 0.92)[KVAR]',
          //       'Static Condenser Capa. (p.f. = 0.92)[KVAR]'
          //     )
          //   ),
          //   width: 100,
          //   dataType: DataType.Number,
          //   isReadOnly: true,
          // },
        ],
      },
      {
        header: String(t('ut.label.CDA', 'CDA (Press: 6barG, Temp: -)')),
        collapseTo: 'cdaSize',
        columns: [
          {
            binding: 'cdaSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'cdaDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },

          {
            binding: 'cdaPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'cdaPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.cdaPresList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'cdaCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'cdaCspSumCapa', // 수식계산 (= Qty(Total) Mech * CDA Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item?.machEqpQty, params.item?.cdaCspCapa),
          },
          {
            binding: 'cdaStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.N2', 'N2 (Press: 8 barG, Temp: -)')),
        collapseTo: 'nitrSize',
        columns: [
          {
            binding: 'nitrSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'nitrPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.n2PresList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'nitrPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrCspCapa',
            header: String(t('ut.label.AVG Consumption[ℓ/min]', 'AVG Consumption[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrCspSumCapa',
            header: String(t('ut.label.AVG Consumption Sum', 'AVG Consumption Sum')),
            width: 100,
            isRequired: false,
          },
          {
            binding: 'nitrPeakCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Peak Consumption[ℓ/min]', 'Peak Consumption[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrPeakCspSumCapa', // 수식 (= Qty(Total) Mech * N2 Consumption[ℓ/min])
            header: String(t('ut.label.Peak Consumption Sum', 'Peak Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.nitrPeakCspCapa),
          },
          {
            binding: 'nitrStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.IW', 'IW (Press: 3 barG, Temp: 10 ~ 32℃)')),
        collapseTo: 'iwSize',
        columns: [
          {
            binding: 'iwSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'iwDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'iwTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'iwPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'iwDrnCapa',
            header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'iwDrnSumCapa',
            header: String(t('ut.label.Drain Sum', 'Drain Sum')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'iwCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'iwCspSumCapa', // 수식 (= Qty(Total) Mech * IW Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.iwCspCapa),
          },
          {
            binding: 'iwStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(
          t('ut.label.IW for Emergency fire', 'IW for Emergency fire (Press: 3 barG, Temp:20~25℃)')
        ),
        collapseTo: 'frwtSize',
        columns: [
          {
            binding: 'frwtSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'frwtTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtDrnCapa',
            header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtDrnSumCapa',
            header: String(t('ut.label.Drain Sum', 'Drain Sum')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtCspSumCapa', // 수식 (= Qty(Total) Mech * IW2 Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.frwtCspCapa),
          },
          {
            binding: 'frwtStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.PCW', 'PCW (Press: 3 barG, Temp:8~13℃)')),
        collapseTo: 'coolSize',
        columns: [
          {
            binding: 'coolSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'coolDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'coolTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'coolPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'coolCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'coolCspSumCapa', // 수식 (= Qty(Total) Mech * PCW Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.coolCspCapa),
          },
          {
            binding: 'coolStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.WW', 'WW (Press: 3 barG, Temp: 8℃(Δ5))')),
        collapseTo: 'wwSize',
        columns: [
          {
            binding: 'wwSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'wwDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'wwTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'wwPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'wwCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'wwCspSumCapa', // 수식 (= Qty(Total) Mech * WW Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.wwCspCapa),
          },
          {
            binding: 'wwStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.Steam', 'Steam (Press: 3 barG, Temp)')),
        collapseTo: 'stemSize',
        columns: [
          {
            binding: 'stemSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'stemDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'stemCwtSize',
            header: String(
              t('ut.label.Condenstaor connection size[Φ]', 'Condenstaor connection size[Φ]')
            ),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'stemCwtDevcCnctTpCd',
            header: String(
              t('ut.label.Condensator connection[Type]', 'Condensator connection[Type]')
            ),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'stemPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'stemCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[t/hr]', 'Consumption[t/hr]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'stemCspSumCapa', // 수식 (= Qty(Total) Mech * Steam Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.stemCspCapa),
          },
          {
            binding: 'stemStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.NG', 'NG (Press: 2 barG, Temp: -)')),
        collapseTo: 'lngSize',
        columns: [
          {
            binding: 'lngSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'lngDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'lngPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'lngCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'lngCspSumCapa', // 수식 (= Qty(Total) Mech * NG Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.lngCspCapa),
          },
          {
            binding: 'lngStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(
          t(
            'ut.label.Exhaust air [Return(to Air Handling Unit)]',
            'Exhaust air [Return(to Air Handling Unit)]'
          )
        ),
        collapseTo: 'insdExhaSize',
        columns: [
          {
            binding: 'insdExhaSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'insdExhaCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.exhaCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'insdExhaCllrTpCd',
            header: String(t('ut.label.Collector Type[Type]', 'Collector Type[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.exhaCllrTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'insdExhaTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'insdExhaCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'insdExhaCspSumCapa', // 수식 (= Qty(Total) Mech * Exhaust air Return Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.insdExhaCspCapa),
          },
          {
            binding: 'insdExhaStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(
          t(
            'ut.label.Exhaust air [Ventilation(to Outside)]',
            'Exhaust air [Ventilation(to Outside)]'
          )
        ),
        collapseTo: 'otsdExhaSize',
        columns: [
          {
            binding: 'otsdExhaSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'otsdExhaCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.exhaCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'otsdExhaCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'otsdExhaCspSumCapa', // 수식 (= Qty(Total) Mech * Exhaust air [Ventilation Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.otsdExhaCspCapa),
          },
          {
            binding: 'otsdExhaStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.Supply air', 'Supply air')),
        collapseTo: 'suarSize',
        columns: [
          {
            binding: 'suarSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'suarDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.exhaCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'suarCllrTpCd',
            header: String(t('ut.label.Connection Type[Duct/Pipe]', 'Connection Type[Duct/Pipe]')),
            width: 100,
            isRequired: false,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.suarCllrTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'suarCnctCnt',
            header: String(t('ut.label.Connection Quantity[EA]', 'Connection Quantity[EA]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'suarCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'suarCspSumCapa', // 수식 (= Qty(Total) Mech * Supply air Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
            align: 'right',
            cellTemplate: (params) =>
              calculateCspSumCapa(params.item.machEqpQty, params.item.suarCspCapa),
          },
          {
            binding: 'suarStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        binding: 'rmk',
        header: String(t('ut.label.Remark', 'Remark')),
        width: 100,
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('com.label.작성자', '작성자')),
        width: 100,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.작성일자', '작성일자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('com.label.수정자', '수정자')),
        width: 100,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('com.label.수정일자', '수정일자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'utmId',
        visible: false,
      },
      {
        binding: 'planProcId',
        visible: false,
      },
      {
        binding: 'utmSeq',
        visible: false,
      },
    ];
  }, [code, condition]);

  const onInitialized = (grid) => {
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
  };

  const downloadExcel = async (templateType) => {
    openLoading(true);
    downloadExcelUtMatrixTemplatesPost(templateType, condition).finally(() => openLoading(false));
  };

  const onCellEditEnding = useEvent((grid, e) => {
    const newVal = grid.activeEditor?.value;
    const data = grid.rows[e.row].dataItem;
    const binding = grid.columns[e.col].binding;

    // 전압값수치
    if ('vltgNvl' === binding) {
      const utVltg = (code?.utVltgList || []).filter((o) => o.cmnCd === newVal);
      if (utVltg.length) {
        // 전기위상코드 선택 (ref. 전압값수치)
        const elpwPhasCd = (code?.elpwPhasCd || []).filter(
          (o) => o.cmnCd === utVltg[0]?.optValCtn3
        );
        data.elpwPhasCd = elpwPhasCd.length ? elpwPhasCd[0].cmnCd : '';
        // 단선유형코드 선택 (ref. 전기위상코드)
        if (elpwPhasCd.length) {
          const ctwTpCd = (code?.ctwTpCd || []).filter(
            (o) => o.cmnCd === elpwPhasCd[0]?.optValCtn1
          );
          data.ctwTpCd = ctwTpCd.length ? ctwTpCd[0].cmnCd : '';
        }
        // 전기연결유형코드 선택 (ref. 전압값수치)
        const elpwEqpCnctTpCd = (code?.elpwEqpCnctTpCd || []).filter(
          (o) => o.optValCtn1 === utVltg[0].cmnCd
        );
        data.elpwEqpCnctTpCd = elpwEqpCnctTpCd.length ? elpwEqpCnctTpCd[0].cmnCd : '';
      }
    } else if ('pwftNvl' === binding) {
      const regex = /^[0-9.-]*$/;
      if (newVal && !regex.test(newVal)) {
        e.cancel = true;
      }
    } else if ('elpwEqpQty' === binding) {
      const elpwEqpQty = Number(newVal);
      if (!_.isNaN(elpwEqpQty) && elpwEqpQty > 0) {
        data.pwftNvl = data.pwftNvl
          ? String(data.pwftNvl).replace(/[^0-9.-]/g, '')
          : PWFT_NVL_DEFAULT;

        const bildPlntCd = (code?.bildPlntCd || []).filter(
          (o) => o.cmnCd === condition?.bildPlntCd
        );

        // 'US'인경우 기본값 60
        const usNusType = bildPlntCd.length > 0 ? bildPlntCd[0].optValCtn2 : 'US';
        if (usNusType === 'US') {
          data.frqNvl = '60';
        }
      } else {
        data.pwftNvl = '';
      }
    }
  });

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.ColumnHeader === panel.cellType) {
      const binding = panel.columns[col].binding;
      // 필수항목
      if (
        ['bldgFloCd', 'istlLocNm', 'prdnLnNm', 'eqpMchNm', 'machEqpQty', 'elpwEqpQty'].includes(
          binding
        )
      ) {
        addClass(cell, 'dot');
      }
    } else if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // Electricity 하위 항목 readonly 설정
      if (
        [
          'vltgNvl',
          'elpwPhasCd',
          'ctwTpCd',
          'frqNvl',
          'pwftNvl',
          'od2EcNvl',
          'brkeCapa',
          'plcClctYn',
          'elpwEqpCnctTpCd',
          'elpwCapa',
          'elpwHeatCapa',
          'elpwStndUseQty',
        ].includes(binding)
      ) {
        // 전력설비수량(elpwEqpQty)이 0인 경우 readonly
        const readonly = Number(item.elpwEqpQty || 0) < 1 || !isWrite || !isUtWriter;
        cell.ariaReadOnly = readonly;
        if (
          ['vltgNvl', 'elpwPhasCd', 'ctwTpCd', 'frqNvl', 'plcClctYn', 'elpwEqpCnctTpCd'].includes(
            binding
          )
        ) {
          toggleClass(cell, 'WijmoSelect', !readonly);
          if (readonly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
      // 기계 하위 항목 readonly 설정 (machEqpQty)
      if (
        [
          'cdaSize',
          'cdaDevcCnctTpCd',
          'cdaPntCnt',
          'cdaPres',
          'cdaCspCapa',
          'cdaStndUseQty',
          'nitrSize',
          'nitrDevcCnctTpCd',
          'nitrPres',
          'nitrPntCnt',
          'nitrCspCapa',
          'nitrCspSumCapa',
          'nitrPeakCspCapa',
          'nitrStndUseQty',
          'iwSize',
          'iwDevcCnctTpCd',
          'iwTmpr',
          'iwPres',
          'iwDrnCapa',
          'iwDrnSumCapa',
          'iwCspCapa',
          'iwStndUseQty',
          'frwtSize',
          'frwtDevcCnctTpCd',
          'frwtTmpr',
          'frwtPres',
          'frwtDrnCapa',
          'frwtDrnSumCapa',
          'frwtCspCapa',
          'frwtStndUseQty',
          'coolSize',
          'coolDevcCnctTpCd',
          'coolTmpr',
          'coolPres',
          'coolCspCapa',
          'coolStndUseQty',
          'wwSize',
          'wwDevcCnctTpCd',
          'wwTmpr',
          'wwPres',
          'wwCspCapa',
          'wwStndUseQty',
          'stemSize',
          'stemDevcCnctTpCd',
          'stemCwtSize',
          'stemCwtDevcCnctTpCd',
          'stemPres',
          'stemCspCapa',
          'stemStndUseQty',
          'lngSize',
          'lngDevcCnctTpCd',
          'lngPres',
          'lngCspCapa',
          'lngStndUseQty',
          'insdExhaSize',
          'insdExhaCnctTpCd',
          'insdExhaCllrTpCd',
          'insdExhaTmpr',
          'insdExhaCspCapa',
          'insdExhaStndUseQty',
          'otsdExhaSize',
          'otsdExhaCnctTpCd',
          'otsdExhaCspCapa',
          'otsdExhaStndUseQty',
          'suarSize',
          'suarDevcCnctTpCd',
          'suarCllrTpCd',
          'suarCnctCnt',
          'suarCspCapa',
          'suarStndUseQty',
        ].includes(binding)
      ) {
        // 기계설비수량(machEqpQty)이 0인 경우 readonly
        const readonly = Number(item.machEqpQty || 0) < 1 || !isWrite || !isUtWriter;
        cell.ariaReadOnly = readonly;
        if (
          [
            'cdaDevcCnctTpCd',
            'cdaPres',
            'nitrDevcCnctTpCd',
            'nitrPres',
            'iwDevcCnctTpCd',
            'frwtDevcCnctTpCd',
            'coolDevcCnctTpCd',
            'wwDevcCnctTpCd',
            'stemDevcCnctTpCd',
            'stemCwtDevcCnctTpCd',
            'lngDevcCnctTpCd',
            'insdExhaCnctTpCd',
            'insdExhaCllrTpCd',
            'otsdExhaCnctTpCd',
            'suarDevcCnctTpCd',
            'suarCllrTpCd',
          ].includes(binding)
        ) {
          toggleClass(cell, 'WijmoSelect', !readonly);
          if (readonly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
      }
    }
  });

  const handleAddRow = () => {
    if (
      !condition.utmId ||
      !condition.planProcId ||
      !condition.prdnProcCd ||
      !condition.dtalProcCd
    ) {
      openMessageBar({
        type: 'error',
        content: t(
          'ut.label.조회 후 행추가 버튼을 눌러주세요.',
          '조회 후 행추가 버튼을 눌러주세요.'
        ),
      });
      return;
    }

    const newRow = {
      crudKey: CrudCode.CREATE,
      utmId: condition.utmId,
      planProcId: condition.planProcId,
      prdnProcCd: condition.prdnProcCd,
      prdnProcCdNm: condition.prdnProcCdNm,
      dtalProcCd: condition.dtalProcCd,
      dtalProcCdNm: condition.dtalProcCdNm,
    } as UtMatrixDetail;
    const rows = [newRow, ...rowData];
    setRowData(rows);
  };

  const handleDelRow = () => {
    const selectedRows = gridRef.current.rows.filter((r) => r.isSelected);
    if (!selectedRows) return;

    const selectedIds = selectedRows
      .map((row) => {
        return parseInt(row.index!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  };

  const handleSave = (showMsg = true) => {
    return new Promise((resolve) => {
      if (!(rowData || []).filter((o) => o.crudKey).length) {
        /*
        // [24.08.19] 탭 이동 시 변경사항 없는 경우 자동저장 안내문구 미노출
        if (showMsg) {
          openMessageBar({
            type: 'warning',
            content: t('ut.label.변경사항이 없습니다.', '변경사항이 없습니다.'),
          });
        }
        */
        resolve(true);
        return;
      }

      saveUtMatrix('write', rowData)
        .then((res) => {
          if (showMsg) {
            openMessageBar({
              type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res?.successOrNot === 'Y'
                  ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                  : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
          }
          if (res?.successOrNot === 'Y') {
            searchRegistMatrix(condition);
          }
        })
        .finally(() => resolve(true));
    });
  };

  const handleSubmit = () => {
    /*
    // [24.09.02] 인터락/알림 안내문구 통일을 위해 패키지에서 검증
    // 빈도값수치 – UT Matrix (UT Matrix No)별로 동일
    const rows = (rowData || [])
      .filter((o) => !_.isNaN(Number(o.elpwEqpQty)) && Number(o.elpwEqpQty) > 0)
      .map((o) => o.frqNvl);
    if (new Set(rows).size > 1) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Frequency 재확인 바랍니다.', 'Frequency 재확인 바랍니다.'),
      });
      return;
    }
    */
    /*
    // [24.08.14] 인터락/알림 안내문구 통일을 위해 패키지에서 검증
    const brkeRows = (rowData || []).filter((o) => {
      if (!_.isNaN(Number(o.elpwEqpQty)) && Number(o.elpwEqpQty) > 0) {
        const od1EcNvl1 =
          o.od1EcNvl ||
          calculateOd1EcNvl(
            Number(o.elpwEqpQty || 0),
            o.elpwPhasCd,
            Number(o.elpwCapa || 0),
            Number(o.vltgNvl || 0),
            o.pwftNvl
          );
        const brkeCapa =
          o.brkeCapa || calculateBrkeCapa(code?.utBrkeCapaList || [], od1EcNvl1, o.od2EcNvl);
        const target = Math.max(Number(od1EcNvl1 || 0), Number(o.od2EcNvl || 0)) * 1.25;
        // Breaker보다 Current x 1.25이 큰 경우 팝업으로 알람제공 또는 제출 불가
        return Number(brkeCapa || 0) < target;
      }
      return false;
    });

    if (brkeRows.length) {
      openMessageBar({
        type: 'error',
        content: t('ut.label.Breaker 재확인 바랍니다.', 'Breaker 재확인 바랍니다.'),
      });
      return;
    }
    */
    props?.onSubmit?.();
  };

  const handleModify = () => {
    props?.onModify?.();
  };

  const handleDownloadExcel = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(gridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = t('ut.label.UT Matrix', 'UT Matrix');
    book.saveAsync(getExcelFileName(t('ut.label.UT Matrix 작성', 'UT Matrix 작성')));
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.UT Matrix', 'UT Matrix')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && condition?.prdnProcCdNm && condition?.dtalProcCdNm && (
            <div className="info warning">
              {condition?.utmNo} / {condition?.prdnProcCdNm} / {condition?.dtalProcCdNm}
            </div>
          )}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="info"
            onClick={() => downloadFileByType(FileTypeName.GUIDE_UT_REGIST)}
          >
            {t('ut.label.작성가이드', '작성가이드')}
          </Button>
          {isUtWriter && (
            <>
              {condition?.utmId && condition?.planProcId && (
                <>
                  {isWrite && (
                    <>
                      <Button
                        css={IconButton.button}
                        className="info"
                        onClick={() => {
                          if ((rowData || []).length) {
                            openCommonModal({
                              modalType: 'confirm',
                              content: t(
                                'ut.label.저장된 데이터가 있으면 기존 데이터는 삭제됩니다. 진행하시겠습니까?',
                                '저장된 데이터가 있으면 기존 데이터는 삭제됩니다. 진행하시겠습니까?'
                              ),
                              yesCallback: () => {
                                setUtMatrixDetailProcessModalOpen(true);
                              },
                            });
                          } else {
                            setUtMatrixDetailProcessModalOpen(true);
                          }
                        }}
                      >
                        {t('ut.label.참조하기', '참조하기')}
                      </Button>
                      <ExcelUploadButton
                        msgConfirm={
                          t(
                            'ut.label.저장된 데이터가 있으면 기존 데이터는 삭제됩니다.\n 새로 Upload 하시겠습니까?',
                            '저장된 데이터가 있으면 기존 데이터는 삭제됩니다.\n 새로 Upload 하시겠습니까?'
                          ) || ''
                        }
                        onCallback={(file) => {
                          openLoading(true);
                          uploadExcelUtMatrixTemplates(
                            file,
                            'TPL_UT_REGIST',
                            'NORMAL',
                            'tb_eelmb_utm_d',
                            condition?.utmId,
                            condition?.planProcId
                          )
                            .then((response) => {
                              openLoading(false);
                              if (response?.data.x_result == 'OK') {
                                openMessageBar({
                                  type: 'confirm',
                                  content: t(
                                    'ut.label.검증이 완료되었습니다.',
                                    '검증이 완료되었습니다.'
                                  ),
                                });
                                searchRegistMatrix(condition);
                              } else {
                                setExcelValidCondition(response?.data.p_xls_upload_id);
                                setExcelValidModalOpen(true);
                              }
                            })
                            .finally(() => openLoading(false));
                        }}
                      />
                      <Button css={IconButton.button} className="addRow" onClick={handleAddRow}>
                        {t('com.button.행추가', '행추가')}
                      </Button>
                      <Button css={IconButton.button} className="delRow" onClick={handleDelRow}>
                        {t('com.button.행삭제', '행삭제')}
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {condition?.utmId && (
            <>
              <Button
                css={IconButton.button}
                className="Exceldown"
                onClick={() => downloadExcel(FileTypeName.TPL_UT_REGIST)}
              >
                {t('ut.label.양식 다운로드', '양식 다운로드')}
              </Button>
            </>
          )}
          <Button css={IconButton.button} className="Exceldown" onClick={handleDownloadExcel}>
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchRegistMatrix(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        isReadOnly={!isWrite || !isUtWriter}
        excludePin={['utmSeq']}
        excludeFilter={['utmSeq', 'prdnProcCdNm', 'dtalProcCdNm']}
        initialized={onInitialized}
        cellEditEnding={onCellEditEnding}
        beginningEdit={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const item = grid.rows[e.row].dataItem;
          // Electricity 하위 항목 readonly 설정
          if (
            [
              'vltgNvl',
              'elpwPhasCd',
              'ctwTpCd',
              'frqNvl',
              'pwftNvl',
              'od2EcNvl',
              'brkeCapa',
              'plcClctYn',
              'elpwEqpCnctTpCd',
              'elpwCapa',
              'elpwHeatCapa',
              'elpwStndUseQty',
            ].includes(binding) &&
            Number(item.elpwEqpQty || 0) < 1
          ) {
            // 전력설비수량(elpwEqpQty)이 0인 경우 readonly
            e.cancel = true;
          }
          // 기계 하위 항목 readonly 설정 (machEqpQty)
          if (
            [
              'cdaSize',
              'cdaDevcCnctTpCd',
              'cdaPntCnt',
              'cdaPres',
              'cdaCspCapa',
              'cdaStndUseQty',
              'nitrSize',
              'nitrDevcCnctTpCd',
              'nitrPres',
              'nitrPntCnt',
              'nitrCspCapa',
              'nitrCspSumCapa',
              'nitrPeakCspCapa',
              'nitrStndUseQty',
              'iwSize',
              'iwDevcCnctTpCd',
              'iwTmpr',
              'iwPres',
              'iwDrnCapa',
              'iwDrnSumCapa',
              'iwCspCapa',
              'iwStndUseQty',
              'frwtSize',
              'frwtDevcCnctTpCd',
              'frwtTmpr',
              'frwtPres',
              'frwtDrnCapa',
              'frwtDrnSumCapa',
              'frwtCspCapa',
              'frwtStndUseQty',
              'coolSize',
              'coolDevcCnctTpCd',
              'coolTmpr',
              'coolPres',
              'coolCspCapa',
              'coolStndUseQty',
              'wwSize',
              'wwDevcCnctTpCd',
              'wwTmpr',
              'wwPres',
              'wwCspCapa',
              'wwStndUseQty',
              'stemSize',
              'stemDevcCnctTpCd',
              'stemCwtSize',
              'stemCwtDevcCnctTpCd',
              'stemPres',
              'stemCspCapa',
              'stemStndUseQty',
              'lngSize',
              'lngDevcCnctTpCd',
              'lngPres',
              'lngCspCapa',
              'lngStndUseQty',
              'insdExhaSize',
              'insdExhaCnctTpCd',
              'insdExhaCllrTpCd',
              'insdExhaTmpr',
              'insdExhaCspCapa',
              'insdExhaStndUseQty',
              'otsdExhaSize',
              'otsdExhaCnctTpCd',
              'otsdExhaCspCapa',
              'otsdExhaStndUseQty',
              'suarSize',
              'suarDevcCnctTpCd',
              'suarCllrTpCd',
              'suarCnctCnt',
              'suarCspCapa',
              'suarStndUseQty',
            ].includes(binding) &&
            Number(item.machEqpQty || 0) < 1
          ) {
            e.cancel = true;
          }
        }}
      />
      {isExcelValidModalOpen && (
        <ExcelValidModal
          open={isExcelValidModalOpen}
          close={() => {
            setExcelValidCondition(null);
            setExcelValidModalOpen(false);
          }}
          condition={{
            valReqId: excelValidCondition || '',
          }}
        />
      )}
      {isUtMatrixDetailProcessModalOpen && (
        <UtMatrixDetailProcessPopup
          open={isUtMatrixDetailProcessModalOpen}
          close={() => setUtMatrixDetailProcessModalOpen(false)}
          condition={condition}
          onCallback={(checkedItems: UtMatrixDetailList[]) => {
            copyUtMatrixDetailProcess(
              checkedItems[0]?.utmId,
              checkedItems[0]?.planProcId,
              condition?.utmId,
              condition?.planProcId
            ).then((response) => {
              if (response.successOrNot === SuccessOrNot.Y) {
                openMessageBar({
                  type: 'confirm',
                  content: t('ut.label.복사되었습니다.', '복사되었습니다.'),
                });
                searchRegistMatrix(condition);
              } else {
                openMessageBar({
                  type: 'error',
                  content:
                    (response.data as string) ||
                    t('ut.label.복사 중 오류가 발생했습니다.', '복사 중 오류가 발생했습니다.'),
                });
              }
            });
          }}
        />
      )}
      <GlobalBtnGroup>
        {isWrite && isUtWriter && (
          <>
            <Button css={IconButton.button} className="icoNone" onClick={() => handleSave()}>
              {t('com.button.임시저장', '임시저장')}
            </Button>
            <Button css={IconButton.button} className="save" onClick={handleSubmit}>
              {t('ut.label.제출', '제출')}
            </Button>
          </>
        )}
        {isWriteComplete && isUtWriter && (
          <Button css={IconButton.button} className="save" onClick={handleModify}>
            {t('com.button.수정', '수정')}
          </Button>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default React.forwardRef(UtMatrixContent);
