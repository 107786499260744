import styled from '@emotion/styled';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import {
  GrayColor,
  CommonBG,
  CommonBorder,
  InputBorder,
  InputBG,
  CommonText,
  InfoBG,
} from 'ui/theme/Color';
import { SVGIcon } from 'components/buttons/IconSVG';
import dayjs, { Dayjs } from 'dayjs';
import useSessionStore from 'stores/useSessionStore';

import 'dayjs/locale/en';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/pl';
interface DatePickerProps {
  id?: string;
  date?: Dayjs | null;
  mindate?: Dayjs | 'today';
  maxdate?: Dayjs | 'today';
  changeDate?: (date: Dayjs, id: string) => void;
  readonly?: boolean;
}

export default function BasicDatePicker({
  id,
  date,
  mindate,
  maxdate,
  changeDate,
  readonly,
}: DatePickerProps) {
  const min = mindate == 'today' ? dayjs() : mindate;
  const max = maxdate == 'today' ? dayjs() : maxdate;
  const lang = useSessionStore.getState().langCd || 'ko';

  //datepicker locale 초기값 설정
  const getLocaleCd = (langCd: string) => {
    switch (langCd) {
      case 'zhC':
        return 'zh-cn';
      case 'zhT':
        return 'zh-tw';
      default:
        return langCd;
    }
  };

  //datepicker 캘린더 헤더 날짜 포맷 설정
  const setLocaleDateFormat = (langCd: string) => {
    switch (langCd) {
      case 'en':
      case 'pl':
        return 'MMMM YYYY';
      case 'zhC':
      case 'zhT':
        return 'YYYY年 MM月';
      default:
        return 'YYYY년 MM월';
    }
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={getLocaleCd(lang)}
      dateFormats={{ monthAndYear: setLocaleDateFormat(lang) }}
    >
      <DemoContainer
        components={['DatePicker']}
        sx={{
          overflow: 'hidden',
          padding: '0',
          flex: '1 auto',
          width: 'auto',
        }}
      >
        <DatePicker
          readOnly={readonly}
          value={date ?? null}
          onChange={(value) => changeDate && changeDate(value as Dayjs, id ?? '')}
          minDate={min}
          maxDate={max}
          format="YYYY.MM.DD"
          components={{
            OpenPickerIcon: CalendarMonthOutlinedIcon,
          }}
          sx={{
            '&': {
              minWidth: '0 !important',
              backgroundColor: 'white',

              '.MuiOutlinedInput-input': {
                width: '110px !important',
                marginLeft: '0 !important',
                padding: '5px 0 5px 14px',
                fontSize: '13px',
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: `${GrayColor.Gray400} !important`,
                borderRadius: '2px',
              },

              '.MuiSvgIcon-root': {
                fontSize: '20px',
                color: '#444',
              },
            },

            '& .MuiOutlinedInput-root': {
              borderRadius: '0',
              color: '#666 !important',
              fontWeight: 'inherit',
              height: '32px',
            },

            '& .Mui-focused': {
              '.MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
              },
            },
            '& .': {
              '.MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px !important',
              },
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export const DatePickerWrap = styled.div`
  padding: 0;
  position: relative;
  &.single {
    width: 128px;
  }
  &.range {
    width: 210px;
  }
  .message {
    display: none;
  }
  &.error {
    .datepickerInput {
      border: 1px solid ${CommonBorder.Error};
    }
    .message {
      display: inline-block;
      font-size: 12px;
      line-height: 1.5;
      margin-top: 4px;
      color: ${CommonText.Error};
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 99;
      background: ${CommonText.Error};
      -webkit-mask: ${SVGIcon.IcoError};
      mask-image: ${SVGIcon.IcoError};
    }
  }
  .datepickerInput {
    border: 1px solid ${CommonBorder.Strong};
    width: 100%;
    height: 32px;
    padding: 4px 8px 4px 28px;
    font-size: 13px;
    line-height: 32px;
    font-weight: 400;
    border-radius: 2px;
    &:focus,
    &:active {
      outline: none !important;
      border: 1px solid ${InputBorder.Focus};
      --tw-ring-color: transparent !important;
    }
    &:before,
    &:after {
      border-color: transparent !important;
    }
    &:readonly {
      border: 1px solid ${CommonBorder.Strong};
      background: ${InputBG.Disabled};
      color: ${CommonText.Basic};
    }
    &:disabled {
      border: 1px solid ${CommonBorder.Strong};
      background: ${InputBG.Disabled};
      color: ${CommonText.Basic};
    }
  }
  .text-gray-700 {
    outline: none !important;
    *,
    ::before,
    ::after {
      --tw-ring-offset-shadow: none !important;
      --tw-ring-color: transparent !important;
    }
    .rounded-md {
      border-radius: 0px !important;
    }
    & > button {
      padding: 0px;
      left: 6px;
      top: 50%;
      transform: translateY(-50%);
      right: initial;
      position: absolute;
      width: 16px;
      height: 16px;
      &:before {
        content: '';
        clear: both;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        background: ${CommonText.Basic};
        -webkit-mask: ${SVGIcon.IcoCal};
        mask-image: ${SVGIcon.IcoCal};
      }
      &:disabled {
        padding: 0px;
        left: 6px;
        top: 50%;
        transform: translateY(-50%);
        right: initial;
        position: absolute;
        width: 16px;
        height: 16px;
        opacity: 1 !important;
        &:before {
          content: '';
          clear: both;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          background: ${CommonText.Basic};
          -webkit-mask: ${SVGIcon.IcoCal};
          mask-image: ${SVGIcon.IcoCal};
        }
      }
      & > svg {
        display: none !important;
      }
    }
    .rotate-45 {
      display: none !important;
    }
    .transition-all.ease-out {
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.17), 0 4px 5px rgba(0, 0, 0, 0.11);
      border-radius: 2px;
      margin-top: 2px !important;
      z-index: 999 !important;
      .Mui-focusVisible {
        background: red !important;
      }
      & > div {
        margin-top: 0px !important;
        padding: 8px 20px 20px !important;
        border-radius: 2px !important;
        .flex {
          padding: 0px !important;
          & > .bg-blue-500 {
            background: transparent !important;
          }
          & > div:nth-child(2) {
            width: 8px !important;
          }
          & > .w-full {
            width: 252px !important;
            min-width: 252px !important;
            & > .items-center {
              height: 32px;
              border-radius: 0px !important;
              display: block !important;
              position: relative;
              & > div {
                position: absolute;
                top: 0;
                margin: 0px !important;
                &:first-child,
                &:nth-child(3) {
                  width: 32px;
                  height: 32px;
                  & > button {
                    width: 32px;
                    height: 32px;
                    position: relative;
                    &:before {
                      content: '';
                      clear: both;
                      display: inline-block;
                      position: absolute;
                      width: 16px;
                      height: 16px;
                      top: 50%;
                      left: 50%;
                      background: ${CommonText.Light};
                      -webkit-mask: ${SVGIcon.IcoLineChevron};
                      mask-image: ${SVGIcon.IcoLineChevron};
                    }
                    &:hover,
                    &:focus {
                      background-color: transparent !important;
                    }
                  }
                  svg {
                    display: none;
                  }
                }
                &.flex-none {
                  right: 32px;
                  & > button {
                    &:before {
                      transform: translate(-50%, -50%) rotate(90deg);
                    }
                  }
                }
                &:nth-child(3) {
                  right: 0;
                  & > button {
                    &:before {
                      transform: translate(-50%, -50%) rotate(270deg);
                    }
                  }
                }
                &:nth-child(2) {
                  width: 80px !important;
                  height: 32px;
                  position: relative;
                  button {
                    display: block;
                    text-align: left;
                    height: 32px;
                    border-radius: 0px !important;
                    padding: 0px !important;
                    font-size: 15px;
                    line-height: 1.5;
                    font-weight: bold;
                    color: ${CommonText.Basic};
                    &:hover,
                    &:focus,
                    &:active {
                      border: none !important;
                      background-color: transparent !important;
                    }
                    &.focus:focus {
                      --tw-ring-offset-shadow: none !important;
                      --tw-ring-shadow: transparent !important;
                      box-shadow: none !important;
                    }
                    &:before {
                      content: '';
                      clear: both;
                      display: inline-block;
                      width: 10px;
                      height: 10px;
                      position: absolute;
                      right: 6px;
                      top: 50%;
                      transform: translateY(-50%) rotate(270deg);
                      background: ${CommonText.Light};
                      -webkit-mask: ${SVGIcon.IcoFillChevron};
                      mask-image: ${SVGIcon.IcoFillChevron};
                    }
                    &:active {
                      &:before {
                        transform: translateY(-50%) rotate(90deg);
                      }
                    }
                  }
                  & > div:first-child {
                    padding-left: 6px !important;
                    margin: 0px !important;
                    position: absolute;
                    left: 84px;
                    width: 60px;
                    height: 32px;
                  }
                  & > div:nth-child(2) {
                    padding-left: 6px !important;
                    margin: 0px !important;
                    position: absolute;
                    left: 0px;
                    width: 80px !important;
                    height: 32px;
                  }
                }
              }
            }
            & > div:nth-child(2) {
              padding: 0px !important;
              height: 252px;
              min-height: 252px;
              & > div:first-child {
                height: 36px;
                padding: 0px !important;
                & > div {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  width: calc(252px / 7) !important;
                  height: 36px;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 1.5;
                  letter-spacing: 0 !important;
                  color: ${CommonText.Basic};
                }
              }
              & > div:nth-child(2) {
                row-gap: 0px !important;
                & > button {
                  width: calc(252px / 7) !important;
                  height: 36px !important;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 1.5;
                  letter-spacing: 0 !important;
                  color: ${CommonText.Basic};
                  &.bg-blue-500 {
                    background-color: transparent;
                    color: ${CommonBG.Elevation};
                    font-weight: 700;
                    position: relative;
                    z-index: 1;
                    &:before {
                      content: '';
                      clear: both;
                      display: inline-block;
                      width: 36px;
                      height: 36px;
                      border-radius: 18px;
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: -1;
                      background: ${CommonBG.Primary};
                    }
                    &:after {
                      content: '';
                      clear: both;
                      display: inline-block;
                      width: 18px;
                      height: 36px;
                      position: absolute;
                      top: 0;
                      left: 50%;
                      z-index: -2;
                      background: ${InfoBG.Primary};
                    }
                  }
                  &.bg-blue-500.duration-500 {
                    &:after {
                      left: 0px !important;
                      right: 50% !important;
                    }
                  }
                  &.bg-blue-100 {
                    color: ${CommonText.Basic} !important;
                    border-radius: 0px !important;
                    background-color: ${InfoBG.Primary};
                  }
                  &.rounded-l-full,
                  &.rounded-r-full {
                    border-radius: 50% !important;
                  }
                  &.transition-all {
                    transition-property: none;
                  }
                  &.text-gray-400 {
                    opacity: 0.4;
                  }
                }
              }
            }
            .border-gray-300 {
              & > .flex.flex-1.items-center {
                & > div:first-child {
                  padding-left: 6px !important;
                  margin: 0px !important;
                  position: absolute;
                  left: 84px;
                  width: 60px;
                  height: 32px;
                  & > button {
                    width: 60px;
                    height: 32px;
                    text-align: left;
                    font-size: 15px;
                    font-weight: 700;
                    color: ${CommonText.Basic};
                    &:hover,
                    &:focus {
                      background-color: transparent !important;
                    }
                    &:before {
                      content: '';
                      clear: both;
                      display: inline-block;
                      width: 10px;
                      height: 10px;
                      position: absolute;
                      right: 6px;
                      top: 50%;
                      transform: translateY(-50%) rotate(270deg);
                      background: ${CommonText.Light};
                      -webkit-mask: ${SVGIcon.IcoFillChevron};
                      mask-image: ${SVGIcon.IcoFillChevron};
                    }
                  }
                }
                & > div:nth-child(2) {
                  padding-left: 6px !important;
                  margin: 0px !important;
                  position: absolute;
                  left: 0px;
                  width: 80px !important;
                  height: 32px;
                  & > button {
                    width: 80px !important;
                    height: 32px;
                    text-align: left;
                    font-size: 15px;
                    font-weight: 700;
                    color: ${CommonText.Basic};
                    &:before {
                      content: '';
                      clear: both;
                      display: inline-block;
                      width: 10px;
                      height: 10px;
                      position: absolute;
                      right: 6px;
                      top: 50%;
                      transform: translateY(-50%) rotate(270deg);
                      background: ${CommonText.Light};
                      -webkit-mask: ${SVGIcon.IcoFillChevron};
                      mask-image: ${SVGIcon.IcoFillChevron};
                    }
                  }
                }
              }
            }
          }
          button {
            padding: 0px !important;
            &:focus,
            &:active {
              border: none !important;
              outline: none !important;
            }
            &.text-blue-500 {
              border-radius: 50%;
              background: ${CommonBG.Inverse};
              font-size: 12px;
              font-weight: 700;
              color: ${CommonBG.Elevation} !important;
            }
          }
        }
      }
      .shadow-sm {
        --tw-shadow: none !important;
        --tw-shadow-colored: transparent !important;
        box-shadow: none !important;
      }
      div.w-full.grid {
        display: flex;
        flex-wrap: wrap;
        height: 192px !important;
        gap: 0 !important;
        button {
          width: Calc(100% / 3) !important;
          height: 32px;
          margin: 0px !important;
          border-radius: 0px;
          font-size: 13px;
          font-weight: 400;
          &:hover {
            background-color: transparent;
          }
          &.font-semibold {
            background: ${CommonBG.Primary};
            color: ${CommonBG.Elevation};
          }
        }
      }
    }
  }
`;
