/** @jsxImportSource @emotion/react */
import React, { useCallback, MouseEvent, ChangeEvent, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonModal } from 'hooks/useCommonModal';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GridReadyEvent,
  NewValueParams,
  GridSizeChangedEvent,
  GridApi,
  RowDataUpdatedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import { useApiUrlManagementStore } from 'stores/useApiUrlManagementStore';
import { ShowingApiUrl } from 'models/admin/ApiUrl';
import { CrudCode } from 'models/common/Edit';
import { crudKeyRowStyle } from 'utils/GridUtil';
import { css } from '@emotion/react';
import { BasicColor, BgColor, BorderColor, ButtonColor } from 'ui/theme/Color';
import { Button, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import { Code, commonYNList, httpCode } from 'models/common/CommonCode';
import useSessionStore from 'stores/useSessionStore';
import _ from 'lodash';
import {
  GridStatusCellRenderer,
  GridStatusCellTemplate,
} from 'components/grids/GridStatusCellRenderer';
import dayjs from 'dayjs';
import { flexGridShortDate } from 'models/ip/Public';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import useEvent from 'react-use-event-hook';
import { getExcelFileName } from 'utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as input from '@grapecity/wijmo.input';
import 'ui/css/multiSelect.css';

export const ApiManagementGrid = () => {
  const gridRef = useRef<any>(null);
  const [flexRef, setflexRef] = useState<any>();
  const { showingApiUrls, setApiUrlManagementGridApi, setShowingUrls } = useApiUrlManagementStore();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const { addApiUrl, removeApiUrls, saveApiUrls } = useApiUrlManagementStore();
  const { t } = useTranslation();
  const { roleCds, roles } = useApiUrlManagementStore();
  const [useYn, setUseYn] = useState<Code[]>([]);

  useEffect(() => {
    getCommonCodeNames('YN_FLAG').then((result: Code[]) => {
      setUseYn(result);
    });
  }, []);

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      isReadOnly: true,
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      width: 45,
      isReadOnly: true,
      cellTemplate: GridStatusCellTemplate,
    },
    {
      binding: 'uuid',
      visible: false,
    },
    {
      binding: 'apiId',
      header: String(t('admin.grid.API ID', 'API ID')),
      align: 'left',
      width: 150,
    },
    {
      binding: 'apiNm',
      header: String(t('admin.grid.API 명', 'API 명')),
      align: 'left',
      width: 200,
    },
    {
      binding: 'apiUrl',
      header: String(t('admin.grid.API URL', 'API URL')),
      align: 'left',
      width: 400,
    },
    {
      binding: 'httpMthdCd',
      header: String(t('admin.grid.API Method', 'API Method')),
      align: 'left',
      width: 150,
      cssClass: 'WijmoSelect',
      dataMap: new DataMap(httpCode, 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'apiRoles',
      header: String(t('admin.grid.API 역할', 'API 역할')),
      align: 'left',
      width: 250,
      editor: new input.MultiSelect(document.createElement('div'), {
        placeholder: String(t('com.label.선택', '선택')),
        headerFormat: '{count:n0} items', //Cell에 비해 데이터 내용이 많을 경우....처리.
        itemsSource: roleCds, //Data 배열
        showSelectAllCheckbox: true, //전체 선택 버튼
      }),
    },
    {
      binding: 'useYn',
      header: String(t('com.label.사용여부', '사용여부')),
      width: 80,
      align: 'left',
      cssClass: 'WijmoSelect',
      dataMap: new DataMap(commonYNList, 'cmnCd', 'cmnCdNm'),
    },
    {
      binding: 'dataInsUserNm',
      header: String(t('admin.grid.작성자', '작성자')),
      width: 120,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'dataInsDtm',
      header: String(t('admin.grid.작성일시', '작성일시')),
      align: 'center',
      width: 140,
      isReadOnly: true,
      cellTemplate: (ctx) => flexGridShortDate(ctx),
    },
    {
      binding: 'dataUpdUserNm',
      header: String(t('com.label.수정자', '수정자')),
      width: 120,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('com.label.수정일', '수정일')),
      align: 'center',
      width: 140,
      isReadOnly: true,
      cellTemplate: (ctx) => flexGridShortDate(ctx),
    },
  ];

  const onInitialized = (grid) => {
    setflexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const item = grid.rows[ht.row].dataItem;

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnCode':
            console.log('btn');
        }
      }
    });
  };

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      const isReadOnly = item.crudKey !== CrudCode.CREATE;
      if ('apiId' === binding) {
        cell.ariaReadOnly = isReadOnly;
      }
    }
  });

  const btnExcelExport = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'api';
    book.saveAsync(getExcelFileName(t('admin.label.API목록', 'API목록')));
  };

  const onApiUrlGridReady = (e: GridReadyEvent) => {
    e.api.sizeColumnsToFit();
    setApiUrlManagementGridApi(e.api as GridApi);
  };

  const onApiUrlRowDataUpdated = useCallback((e: RowDataUpdatedEvent) => {
    e.api.refreshCells();
  }, []);

  const onMessageCellValueChange = (e: NewValueParams) => {
    const { data, newValue, oldValue, api } = e;

    if (newValue === oldValue) {
      return;
    }

    const storedApiUrl = showingApiUrls.find((showingApi) => showingApi.uuid === data['uuid']);

    if (storedApiUrl) {
      if (data.crudKey !== CrudCode.CREATE) {
        data.crudKey = CrudCode.UPDATE;
      }

      api.applyTransaction({
        update: [data],
      });

      api.refreshCells({
        force: true,
        suppressFlash: true,
      });
    }
  };

  const handleAddbuttonClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    addApiUrl();
  }, []);

  const handleSelectChange = (e: ChangeEvent<any>, idx: number) => {
    if (idx === -1) return;
    const newShowingUrls: ShowingApiUrl[] = [...showingApiUrls];
    newShowingUrls[idx][e.target.id] = e.target.value;

    if (showingApiUrls[idx].crudKey !== CrudCode.CREATE)
      newShowingUrls[idx].crudKey = CrudCode.UPDATE;
    setShowingUrls(newShowingUrls);
  };

  const handleMultiSelectChange = (e: SelectChangeEvent<string[]>, idx: number) => {
    if (idx === -1) return;
    const newApiRoles =
      typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
    const newShowingUrls: ShowingApiUrl[] = [...showingApiUrls];
    newShowingUrls[idx].apiRoles = newApiRoles;
    if (showingApiUrls[idx].crudKey !== CrudCode.CREATE)
      newShowingUrls[idx].crudKey = CrudCode.UPDATE;
    setShowingUrls(newShowingUrls);
  };

  const handleRemoveButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    const selectedRowNodes = flexRef.selectedRows;
    const isSelected = selectedRowNodes.filter((item) => item.isSelected);
    if (isSelected.length < 1) {
      openMessageBar({
        type: 'error',
        content: t('com.label.삭제할 데이터를 선택해 주세요.', '삭제할 데이터를 선택해 주세요.'),
      });
      return;
    }

    setApiUrlManagementGridApi(flexRef);

    openCommonModal({
      modalType: 'confirm',
      content: t(
        'com.label.삭제하시겠습니까? 기존 작업 내용이 사라집니다.',
        '삭제하시겠습니까? 기존 작업 내용이 사라집니다.'
      ),
      yesCallback: async () => {
        const result = await removeApiUrls();
        if (result) {
          openMessageBar({
            type: 'confirm',
            content: t('com.label.삭제되었습니다.', '삭제되었습니다.'),
          });
        } else {
          openMessageBar({
            type: 'error',
            content: t('com.label.삭제 중 오류가 발생했습니다.', '삭제 중 오류가 발생했습니다.'),
          });
        }
      },
    });
  };

  const handleSaveButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    const rowData = gridRef.current?.props.rowData;
    if (showingApiUrls != undefined) {
      const vaildCandU = showingApiUrls.filter(
        (element) => element.crudKey == CrudCode.CREATE || element.crudKey == CrudCode.UPDATE
      );
      const valid = vaildCandU
        .map((rowNode, index) => {
          if (_.isEmpty(rowNode.apiId))
            return `${t('admin.msg.API ID를 입력해 주세요.', 'API ID를 입력해 주세요.')}`;
          if (_.isEmpty(rowNode.apiNm))
            return `${t('admin.msg.API 명을 입력해 주세요.', 'API 명을 입력해 주세요.')}`;
          if (_.isEmpty(rowNode.apiUrl))
            return `${t('admin.msg.API URL을 입력해 주세요.', 'API URL을 입력해 주세요.')}`;
        })
        .filter((element) => element !== undefined);
      if (valid.length) {
        openMessageBar({
          type: 'error',
          content: valid.toString(),
        });
        return false;
      }

      const noneFixedRow = showingApiUrls.filter(
        (element) => element.crudKey !== null && element.crudKey !== 'R'
      );
      if (noneFixedRow.length == 0) {
        openMessageBar({
          type: 'error',
          content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
        });
        return true;
      }
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        const result = await saveApiUrls();
        result &&
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
        result ||
          openMessageBar({
            type: 'error',
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
      },
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.API 목록', 'API 목록')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{showingApiUrls?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExport}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="addRow"
            disableRipple
            onClick={handleAddbuttonClick}
          >
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button
            css={IconButton.button}
            className="delRow"
            disableRipple
            onClick={handleRemoveButtonClick}
          >
            {t('com.button.행삭제', '행삭제')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={showingApiUrls}
        height={300}
        deferResizing={false}
        initialized={onInitialized}
        itemFormatter={onItemFormatter}
        align={'center'}
        beginningEdit={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const item = grid.rows[e.row].dataItem;
          if (['apiId'].includes(binding)) {
            if (CrudCode.CREATE !== item.crudKey) {
              e.cancel = true;
            }
          }
        }}
      />
      <GlobalBtnGroup>
        <Button
          css={IconButton.button}
          className="save"
          disableRipple
          onClick={handleSaveButtonClick}
        >
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>
    </>
  );
};
