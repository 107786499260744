/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { Button } from '@mui/material';
import { toggleClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import _, { toNumber } from 'lodash';
import { CrudCode } from 'models/common/Edit';
import { CommonYN } from 'models/common/Common';
import { useCommonModal } from 'hooks/useCommonModal';
import { GatingCheckSheetMst } from 'models/gtng/GatingCheckSheet';
import { getGatingCheckSheetMst, saveGatingCheckSheetMst } from 'apis/gtng/GatingCheckSheet';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { getCommonCodeNames, getCommonCodes } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { IconButton } from 'components/buttons/IconSVG';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { VersionUpPopUp } from '../popup/VersionUpPopUp';
import { VersionHistoryPopUp } from '../popup/VersionHistoryPopUp';
import WJCellTextarea from 'components/inputs/WJCellTextarea';
import { useMessageBar } from 'hooks/useMessageBar';
import CustomGrid from 'components/grids/CustomGrid';

interface SearchParamData {
  sGatingTypeCode?: string;
  sUseYn?: string;
  editable?: boolean;
  sSearchBtn?: boolean;
  callBack?: any;
  sSearchDetailBtn?: boolean;
  gridTitle?: string;
}
const GatingCheckSheetMstGrid = (props: SearchParamData) => {
  const { t } = useTranslation();

  const [rowData, setRowData] = useState<GatingCheckSheetMst[]>([]);
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();

  const [isOpenVersionPopup, setOpenVersionPopup] = useState<boolean>(false); //체크시트버전팝업 버전정보
  const [isOpenVersionNoPopup, setOpenVersionNoPopup] = useState<boolean>(false); //체크시트버전팝업 버전정보

  const [versionNo, setVersionNo] = useState<any>(''); //체크시트버전팝업 버전정보
  const [gatingTemplateId, setGatingTemplateId] = useState<any>(''); //체크시트버전팝업 버전정보

  const [getGatingStepCode, setGatingStepCode] = useState<Code[]>([]);
  const [getGatingTypeCode, setGatingTypeCode] = useState<Code[]>([]);
  const [getUseYn, setUseYn] = useState<Code[]>([]);

  const getCommonCodesForGrid = async () => {
    const gatingTypeCode: Code[] = await getCommonCodeNames('GATING_TYPE_CODE');
    const getUseYn: Code[] = await getCommonCodeNames('YN_FLAG');
    const gatingStepCode: Code[] = await getCommonCodes('GATING_STEP_CODE');
    setGatingStepCode(gatingStepCode);
    setGatingTypeCode(gatingTypeCode);
    setUseYn(getUseYn);
  };

  const [flexRef, setFlexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [initParam, setInitParam] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [isOpenCellTextarea, setOpenCellTextarea] = useState<boolean>(false);
  const [colWidthMap, setColWidthMap] = useState<any>({
    gatingTypeCode: 150,
    gatingStepCode: 150,
    versionNo: 100,
    dataOccurSerialNo: 110,
    contentText: '*',
    useYn: 100,
    dataUpdUserNm: 120,
    dataUpdDtm: 120,
  });

  const OnInitialized = useEvent((grid) => {
    setFlexRef(grid);
    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      setHitTest(ht);
      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const col = grid.columns[ht.col]; // 선택한 컬럼의 정보
      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정

      setflexItem(item);
      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnPlay':
            fnSearchDetailBtn(item);
            break;
          case 'btnCheckSheetPopup':
            setOpenVersionNoPopup(true);
            setInitParam(item.gatingTemplateId);
            setFieldId(col.binding);
            break;
        }
      }
      switch (ht.panel.columns[ht.col].binding) {
        case 'contentText': {
          setOpenCellTextarea(true);
          break;
        }
      }
      setFieldId(col.binding);
    });

    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.itemFormatter = (panel, row, col, cell) => {
      if (CellType.Cell === panel.cellType) {
        const binding = panel.columns[col].binding;
        const item = panel.rows[row].dataItem;
        // Gating 유형, Gating 단계
        if ('gatingTypeCode' === binding || 'gatingStepCode' === binding) {
          const isReadOnly = !!item.versionNo;
          cell.ariaReadOnly = isReadOnly;
          toggleClass(cell, 'WijmoSelect', !isReadOnly);
          if (isReadOnly && cell.firstElementChild instanceof HTMLButtonElement) {
            cell.firstChild.remove();
          }
        }
        // Guide
        if ('contentText' === binding) {
          cell.ariaReadOnly = false;
        }
      }
    };
  });

  const beginningEdit = useEvent((s, e) => {
    const col = s.columns[e.col];
    const row = s.rows[e.row];
    const crudKey = s.rows[e.row].dataItem.crudKey;
    const versionNo = s.rows[e.row].dataItem.versionNo;
    if (col.binding === 'gatingTypeCode' || col.binding === 'gatingStepCode') {
      if (versionNo !== null && versionNo !== undefined) {
        e.cancel = true;
      }
    }
    const gatingTypeCode = s.rows[e.row].dataItem.gatingTypeCode;

    const code = getGatingStepCode.filter((code) => {
      return code.cmnCd?.substring(0, 1) == gatingTypeCode;
    });

    const gatingStepCode = new DataMap(code, 'cmnCd', 'cmnCdNm');
    if (col.binding == 'gatingTypeCode' && crudKey == CrudCode.CREATE) {
      if (
        s.rows[e.row].dataItem.gatingStepCode !== null &&
        s.rows[e.row].dataItem.gatingStepCode !== undefined &&
        s.rows[e.row].dataItem.gatingStepCode !== ''
      ) {
        s.rows[e.row].dataItem.gatingStepCode = '';
      }
    }
    if (col.binding == 'gatingStepCode') {
      switch (gatingTypeCode) {
        case gatingTypeCode:
          col.dataMap = gatingStepCode;
          break;
      }
    }
  });

  const layoutDefinition = useMemo(() => {
    const gatingTypeCode = new DataMap(getGatingTypeCode, 'cmnCd', 'cmnCdNm');
    const gatingStepCode = new DataMap(getGatingStepCode, 'cmnCd', 'cmnCdNm');
    const useYn = new DataMap(getUseYn, 'cmnCd', 'cmnCdNm');
    return [
      {
        binding: 'no',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'crudKey',
        header: String(t('com.label.상태', '상태')),
        width: 45,
        isReadOnly: true,
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'gatingTypeCode',
        header: String(t('gtng.label.Gating 유형', 'Gating 유형')),
        width: colWidthMap.gatingTypeCode,
        cssClass: 'WijmoSelect',
        dataMap: gatingTypeCode,
        cellTemplate: (item) => {
          if (item.item.versionNo !== null && item.item.versionNo !== undefined) {
            return `<span>${gatingTypeCode.getDisplayValue(item.value)}</span>`;
          } else {
            return `<span>${item.text}</span>`;
          }
        },
      },
      {
        binding: 'gatingStepCode',
        header: String(t('gtng.label.Gating 단계', 'Gating 단계')),
        width: colWidthMap.gatingStepCode,
        cssClass: 'WijmoSelect',
        dataMap: gatingStepCode,
        cellTemplate: (item) => {
          if (gatingStepCode.getDisplayValue(item.value) == undefined) {
            return `<span>${item.text}</span>`;
          } else {
            return `<span>${gatingStepCode.getDisplayValue(item.value)}</span>`;
          }
        },
      },
      {
        binding: 'versionNo',
        header: String(t('gtng.grid.버전', '버전')),
        width: colWidthMap.versionNo,
        align: 'center',
        cssClass: 'WijmoPopup',
        cellTemplate: (item) => {
          if (item.item.crudKey == CrudCode.CREATE || item.item.versionNo == undefined) {
            return '';
          } else {
            return `<span>${item.text}</span><button id="btnCheckSheetPopup" />`;
          }
        },
        isReadOnly: true,
      },
      {
        binding: 'dataOccurSerialNo',
        header: String(t('gtng.grid.Sheet 상세', 'Sheet 상세')),
        width: colWidthMap.dataOccurSerialNo,
        cssClass: 'WijmoPlay',
        cellTemplate: (item) => {
          if (item.item.crudKey == CrudCode.CREATE || item.item.versionNo == undefined) {
            return '';
          } else {
            return '<span></span><button id="btnPlay"></button>';
          }
        },
        isReadOnly: true,
      },
      {
        binding: 'contentText',
        header: String(t('gtng.grid.Guide', 'Guide')),
        width: colWidthMap.contentText,
        align: 'left',
        isReadOnly: true, // textarea 팝업인 경우 클릭이벤트에서 제어
      },
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        width: colWidthMap.useYn,
        align: 'center',
        dataMap: useYn,
        isReadOnly: false,
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('com.label.수정자', '수정자')),
        width: colWidthMap.dataUpdUserNm,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('com.label.수정일', '수정일')),
        width: colWidthMap.dataUpdUserNm,
        align: 'center',
        isReadOnly: true,
      },
    ];
  }, [getGatingTypeCode, getGatingStepCode, getUseYn]);

  const fnSearchDetailBtn = (event: any) => {
    props.callBack &&
      event.crudKey != CrudCode.CREATE &&
      props.callBack(
        event.versionNo,
        event.gatingTemplateId,
        true,
        event.gatingTypeName + ' / ' + event.gatingStepName + ' / ' + event.versionNo
      );
  };

  const resultVersionNoPopup = (fieldId, result) => {
    flexItem[fieldId] = result;
    if (flexItem['crudKey'] !== CrudCode.CREATE) {
      flexItem['crudKey'] = CrudCode.UPDATE;
    }
    flexRef?.collectionView.refresh();
  };

  const btnAddRow = () => {
    props.callBack && props.callBack('', '', true);
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
    } as GatingCheckSheetMst;
    setRowData([newRow, ...rowData]);
    // flexRef.refresh();
  };

  const btnDelRow = useCallback(() => {
    props.callBack && props.callBack('', '', true);
    let idx = 0;
    const selectedRowNodes = flexRef.selectedRows;

    const isSelected = selectedRowNodes.filter((item) => item.isSelected);

    if (isSelected.length < 1) {
      alert(
        t(
          'com.label.삭제할 항목을 1건 이상 선택해 주세요.',
          '삭제할 항목을 1건 이상 선택해 주세요.'
        )
      );
      return;
    }

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode._idx!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item] && toNumber(rowData[item].dtlCount) > 0) {
        idx++;
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });
    if (idx > 0) {
      openMessageBar({
        type: 'warning',
        content: t(
          'gtng.msg.점검항목이 있는 Gating Check Sheet는 삭제 할수 없습니다.',
          '점검항목이 있는 Gating Check Sheet는 삭제 할수 없습니다.'
        ),
      });
      return;
    }

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData]);

  const fnSearchGatingCheckSheetMst = useCallback(
    (searchGatingTypeCode: string, searchUseYn: string, searchBtn: boolean) => {
      if (searchBtn) {
        getGatingCheckSheetMst(searchGatingTypeCode, searchUseYn).then(
          (result: GatingCheckSheetMst[]) => {
            setRowData(result);
            props.callBack && props.callBack('', '', false, 'txt');
          }
        );
      }
    },
    []
  );

  useEffect(() => {
    getCommonCodesForGrid();
    if (props.sSearchBtn) {
      fnSearchGatingCheckSheetMst(props.sGatingTypeCode || '', props.sUseYn || '', true);
    }
  }, [props.sSearchBtn]);

  /**
   * 게이팅 체크 시트 버전 up 클릭 이벤
   * @param params
   */
  const btnVersionUp = (params: any) => {
    const selectedRowNodes = flexRef.selectedRows;

    if (selectedRowNodes.length < 1) {
      openMessageBar({
        type: 'warning',
        content: t(
          'gtng.msg.버전업 할 게이팅 체크 시트를 선택해 주세요.',
          '버전업 할 게이팅 체크 시트를 선택해 주세요.'
        ),
      });
    }

    for (let i = 0; i < selectedRowNodes.length; i++) {
      if (selectedRowNodes[i].isSelected) {
        setGatingTemplateId(selectedRowNodes[i].dataItem.gatingTemplateId);
        setVersionNo(selectedRowNodes[i].dataItem.versionNo);

        if (_.isEmpty(gatingTemplateId) || _.isEmpty(versionNo)) return;
        setOpenVersionPopup(true);
      } else {
        openMessageBar({
          type: 'warning',
          content: t(
            'gtng.msg.버전업 할 게이팅 체크 시트를 선택해 주세요.',
            '버전업 할 게이팅 체크 시트를 선택해 주세요.'
          ),
        });
      }
    }
  };

  /**
   * 체크시트버전 검색 팝업
   * @param params
   */
  const handleSave = () => {
    const saveData = rowData.filter((o) => o.crudKey !== CrudCode.READ && o.crudKey !== null);
    const saveDataChk = rowData.filter(
      (o) => o.crudKey !== CrudCode.READ && o.crudKey !== CrudCode.DELETE && o.crudKey !== null
    );

    const valid = saveDataChk
      .map((o, index) => {
        if (_.isEmpty(o.gatingTypeCode))
          return `${index + 1} : ${t(
            'gtng.msg.Gating 유형을 선택해주세요.',
            'Gating 유형을 선택해주세요.'
          )}\n`;
        if (_.isEmpty(o.gatingStepCode))
          return `${index + 1} : ${t(
            'gtng.msg.Gating 단계를 선택해주세요.',
            'Gating 단계를 선택해주세요.'
          )}\n`;
      })
      .filter((element) => element !== undefined);

    if (valid.length) {
      const content = valid[0]?.toString();
      openMessageBar({
        type: 'warning',
        content: content || '',
      });
      return;
    }

    if (!saveData || saveData.length < 1) {
      openMessageBar({
        type: 'warning',
        content: t('com.label.수정된 항목이 없습니다.', '수정된 항목이 없습니다.'),
      });
      return;
    }

    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      yesCallback: async () => {
        saveGatingCheckSheetMst(saveData).then((response) => {
          if (response != null && response.successOrNot == 'N') {
            openMessageBar({
              type: 'warning',
              content:
                typeof response.data === 'string' && response.data
                  ? response.data
                  : t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
            return;
          }
          openMessageBar({
            type: 'confirm',
            content: t('com.label.저장되었습니다.', '저장되었습니다.'),
          });
          fnSearchGatingCheckSheetMst(props.sGatingTypeCode || '', props.sUseYn || '', true);
        });
      },
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating Check Sheet 등록', 'Gating Check Sheet 등록')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        {props.editable && (
          <ControlBtnGroup>
            <Button css={IconButton.button} className="addRow" disableRipple onClick={btnAddRow}>
              {t('com.button.행추가', '행추가')}
            </Button>
            <Button css={IconButton.button} className="delRow" disableRipple onClick={btnDelRow}>
              {t('com.button.행삭제', '행삭제')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        rowSelection={'single'}
        height={350}
        align="center"
        excludeFilter={['dataOccurSerialNo']}
        deferResizing={false}
        allowPinning={false}
        initialized={OnInitialized}
        beginningEdit={beginningEdit}
      />

      {isOpenCellTextarea && (
        <WJCellTextarea grid={flexRef} hitTest={hitTest} close={() => setOpenCellTextarea(false)} />
      )}
      {props.editable && (
        <GlobalBtnGroup>
          <Button css={IconButton.button} className="write" disableRipple onClick={btnVersionUp}>
            {t('com.button.Version Up', 'Version Up')}
          </Button>
          <Button css={IconButton.button} className="save" disableRipple onClick={handleSave}>
            {t('com.button.저장', '저장')}
          </Button>
        </GlobalBtnGroup>
      )}
      {isOpenVersionPopup && (
        <VersionUpPopUp
          open={isOpenVersionPopup}
          close={() => setOpenVersionPopup(false)}
          initParam={{ gatingTemplateId: gatingTemplateId, versionNo: versionNo }}
          onCallback={() =>
            fnSearchGatingCheckSheetMst(
              props.sGatingTypeCode as string,
              props.sUseYn as string,
              true
            )
          }
        />
      )}
      {isOpenVersionNoPopup && (
        <VersionHistoryPopUp
          setPopup={setOpenVersionNoPopup}
          setVersionData={(result) => resultVersionNoPopup(fieldId, result)}
          suppressRowTransform={true}
          isEditable={true}
          gatingTemplateId={initParam}
          close={() => setOpenVersionNoPopup(false)}
        />
      )}
    </>
  );
};
export default GatingCheckSheetMstGrid;
