/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentGrid } from '../../../components/layouts/ContentGrid';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import { ContentSection } from 'components/layouts/ContentSection';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { CustomInputWrap, CustomInputText } from 'components/inputs/CustomInput';
import { findGatingResultReportMst } from 'apis/gtng/GatingResultReport';
import { GatingResultReport } from 'models/gtng/GatingResultReport';
import { useNavigate } from 'react-router-dom';
import { S } from 'msw/lib/glossary-de6278a9';
import { CrudCode } from '../../../models/common/Edit';
import { saveGtngCheckItem } from '../../../apis/gtng/GatingCheckResult';
import { Button } from '@mui/material';
import FileUploadPopUp from '../../common/components/FileUploadPopUp';
import useSessionStore from 'stores/useSessionStore';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { GatingContListPopUp } from '../popup/GatingContListPopUp';
// Wijmo library
import 'ui/css/multiSelect.css';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { CellMaker } from '@grapecity/wijmo.grid.cellmaker';
import GatingResultReportActGrid from './GatingResultReportActGrid';
import CustomGrid from 'components/grids/CustomGrid';

/**
 * Gating 결과 Report Library -> Gating Library 그리드 및 판정결과
 * @param props
 * @constructor
 */
const GatingResultReportGrid = (props: any) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<GatingResultReport[]>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [selectedRowData, setSelectedRowData] = useState<GatingResultReport>();
  const [pop, setPop] = useState('close');
  const btnJudge = () => {
    setPop('open');
  };

  useEffect(() => {
    fnSearchGatingRegistMst(props.searchParamData);
  }, [props.searchParamData, props.searchState]);

  const fnSearchGatingRegistMst = useCallback((searchParamData) => {
    setSelectedRowData(selectedRowData);
    if (searchParamData != null && searchParamData != '') {
      findGatingResultReportMst(searchParamData).then((result: GatingResultReport[]) => {
        setRowData([...result]);
      });
      const reload = {
        gatingId: '', //게이팅아이디
        gatingName: '', //게이팅태스크명
        gatingTypeCode: '', //게이팅유형
        gatingTypeName: '', //게이팅유형명
        gatingStepCode: '', //게이팅단계
        gatingStepName: '', //게이팅단계명
        taskLeaderUserId: '', //태스크리더
        taskLeaderUserName: '', //태스크리더명
        gatingContIds: '',
        gatingContNames: '',
        gatingContName: '',
        pmoApproverId: '', //PMO
        pmoApproverNames: '', //PMO명
        pmoApproverName: '', //PMO명
        startDate: '', //시작일자
        endDate: '', //종료일자
        gatingNo: '', //게이팅장비(점검장비)
        progressStatusCode: '', //진행상태
        progressStatusName: '', //진행상태명
        productTypeCode: '', //장비유형
        productTypeCodeName: '', //장비유형명
        resultDesc: '', //판정결과
        fileCount: '',
      };
      setSelectedRowData(reload);
    }
  }, []);

  //셀렉한 rowData를 input? 에 뿌려주기
  const onSelectionChanged = () => {
    // const selectedRows = gridRef.current!.api.getSelectedRows();
    const selectedRows = flexRef.selectedRows;
    if (selectedRows == null) {
      setSelectedRowData(selectedRows);
    }
    setSelectedRowData(selectedRows[0]);
  };

  const dateValueFommater = (params: any) => {
    const date = params.value;
    if (date != null && date != undefined) {
      const subStrDate = date.substr(0, 10);
      const formmattedDate = subStrDate.replaceAll('-', '.');
      return formmattedDate;
    }
  };

  const fnSearchJudgeResultBtn = (params: any) => {
    const searchParam = {
      gatingId: params.gatingId,
      gatingNo: params.gatingNo,
    };
    navigate('/gating/result/gating-judge-report', { state: { sJudgeResultParam: searchParam } });
  };

  const [isOpenFileUploadPopUp, setOpenFileUploadPopUp] = useState<boolean>(false);
  const [initParamFileUpload, setInitParamFileUpload] = useState<any>();
  const [initUserId, setInitUserId] = useState<string>(''); //검색할 파라미터 상태관리
  const [popup, setPopup] = useState<any>(false); //팝업창 오픈, 클로즈 상태관리
  const [contPopTitle, setContPopTitle] = useState<string>(''); //검색할 파라미터 상태관리
  const [initParam, setInitParam] = useState<string>(''); //검색할 파라미터 상태관리
  const [colWidthMap, setColWidthMap] = useState<any>({
    no: 40,
    point: 80,
    startDate: 90,
    productTypeCode: 140,
    productTypeName: 140,
    gatingName: 200,
    gatingContNames: 130,
    pmoApproverNames: 130,
    gatingTypeName: 140,
    gatingStepName: 140,
    gatingNo: 200,
    endDate: 120,
    resultDesc: 100,
    fileCount: 70,
  });

  /**
   * 파일
   * @param params
   */
  const popUpOpen = (params) => {
    // if (_.isEmpty(colAtchFileTpCd)) return;
    setInitParamFileUpload({
      atchFileGrId: params.atchFileGrId,
      atchFileTpCd: 'GTNG_MASTER',
      optValCtn1: 'TB_ELM_GTNG_M', // 관련 테이블 명 (확인용)1
      bizName: 'GTNG', // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
    });

    setOpenFileUploadPopUp(true);
  };

  const openPopup = (params: any) => {
    const fieldName = params.binding;
    const id =
      fieldName === 'gatingContNames'
        ? 'gatingContIds'
        : fieldName === 'pmoApproverNames'
        ? 'pmoApproverId'
        : '';
    if (fieldName != null && fieldName != '') {
      setInitUserId(params.item[id]);
    }

    //실시담당자
    if (fieldName == 'pmoApproverNames') {
      setContPopTitle(String(t('gtng.label.등록 T/L', '등록T/L')));
    }
    //점검담당자
    if (fieldName == 'gatingContNames') {
      setContPopTitle(String(t('gtng.label.등록 담당자', '등록담당자')));
    }

    setInitParam(params.item[id]);
    setPopup(true);
  };

  //사용자 검색 팝업
  const btnElmEmpPopup = ({ value }) => `<span>${value ? value : ''}</span>
        <Button id="empPopBtn"></Button>
        `;

  /**
   * grid
   * */
  // const defaultColDef: ColDef = {
  //   sortable: true,
  //   resizable: true,
  //   filter: true,
  //   align: 'center',
  // };

  const LayoutDefinition = () => {
    return [
      {
        header: String(t('com.label.NO', 'NO')),
        width: colWidthMap.no,
        // filter: false,
        isReadOnly: true,
        align: 'center',
        //  suppressSizeToFit: true,
        // cssClass: 'cellStatus',
        cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        binding: 'point',
        header: String(t('com.label.상세', '상세')),
        isReadOnly: true,
        // filter: false,
        // sortable: false,
        width: colWidthMap.point,
        cellTemplate: (params) => {
          const checked = params.value === 'Y' ? 'checked' : '';
          return (
            '<input id="detailChk" type="checkbox" className="customStringCheckbox"' +
            checked +
            '/>'
          );
        },
        // hide: !props.editable,
        align: 'center',
      },
      {
        binding: 'startDate',
        header: String(t('gtng.label.실시년도', '실시년도')),
        align: 'center',
        width: colWidthMap.startDate,
      },
      {
        binding: 'productTypeCode',
        header: 'productTypeCode',
        width: colWidthMap.productTypeCode,
        visible: false,
      },
      {
        binding: 'productTypeName',
        header: String(t('gtng.grid.Product', 'Product')),
        width: colWidthMap.productTypeName,
      },
      {
        binding: 'gatingName',
        header: String(t('gtng.label.Gating Task명', 'Gating Task명')),
        width: colWidthMap.gatingName,
      },
      {
        binding: 'gatingContIds',
        header: String(t('gtng.label.등록 담당자', '담당자')),
        visible: false,
      },
      {
        binding: 'gatingContNames',
        header: String(t('gtng.label.등록 담당자', '담당자')),
        width: colWidthMap.gatingContNames,
        cssClass: 'WijmoFind',
        cellTemplate: (params) => btnElmEmpPopup(params),
      },
      {
        binding: 'pmoApproverId',
        header: '등록T/L',
        visible: false,
      },
      {
        binding: 'pmoApproverNames',
        header: String(t('gtng.label.등록 T/L', '등록 T/L')),
        cssClass: 'WijmoFind',
        cellTemplate: (params) => btnElmEmpPopup(params),
        width: colWidthMap.pmoApproverNames,
      },
      {
        binding: 'gatingTypeName',
        header: String(t('gtng.label.Gating 유형', 'Gating 유형')),
        width: colWidthMap.gatingTypeName,
      },
      {
        binding: 'gatingStepName',
        header: String(t('gtng.label.Gating 단계', 'Gating 단계')),
        width: colWidthMap.gatingStepName,
      },
      {
        binding: 'gatingNo',
        header: String(t('gtng.grid.Gating No', 'Gating No')),
        width: colWidthMap.gatingNo,
        align: 'center',
      },
      {
        binding: 'endDate',
        header: String(t('gtng.grid.완료일자', '완료일자')),
        align: 'center',
        width: colWidthMap.endDate,
        // cellTemplate: dateValueFommater,
      },
      {
        binding: 'resultDesc',
        header: String(t('gtng.label.판정결과', '판정결과')),
        width: colWidthMap.resultDesc,
        align: 'center',
        cellTemplate: CellMaker.makeLink({
          text: '${item.resultDesc}',
          click: (e, ctx) => {
            fnSearchJudgeResultBtn(ctx.item);
          },
        }),
      },
      {
        binding: 'fileCount',
        header: String(t('gtng.grid.유첨', '유첨')),
        align: 'center',
        cellTemplate: (params) =>
          `<button id="fileAttach" class='fileDiv'>
          ${params.value || 0}
        </button>
        `,
        width: colWidthMap.fileCount,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setflexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      grid.startEditing(true);
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);

      switch (e.target.id) {
        case 'detailChk':
          grid.setCellData(ht.row, ht.col, e.target.checked ? 'Y' : 'N');
          setSelectedRowData(item);
          break;
        case 'empPopBtn':
          openPopup({ binding: col.binding, item });
          break;
        case 'fileAttach':
          popUpOpen(item);
          break;
      }
      grid.refresh();
    });
  };

  return (
    <>
      {/*<ContentSection>*/}
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating Library', 'Gating Library')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>

      <CustomGrid
        layoutDefinition={state.layoutDefinition}
        rowData={state.itemsSource}
        height={320}
        frozenColumns={5}
        selectionMode={SelectionMode.Row}
        align={'center'}
        initialized={onInitialized}
        deferResizing={false}
      />

      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('gtng.label.Gating판정결과(요약)', 'Gating판정결과(요약)')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.Gating Task명', 'Gating Task명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="gatingName"
                  placeholder=""
                  disabled
                  value={selectedRowData?.gatingName}
                ></CustomInputWithSearch>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 유형', 'Gating 유형')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="gatingTypeName"
                  placeholder=""
                  disabled
                  value={selectedRowData?.gatingTypeName}
                ></CustomInputWithSearch>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.Gating 단계', 'Gating 단계')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="gatingStepName"
                  placeholder=""
                  disabled
                  value={selectedRowData?.gatingStepName}
                ></CustomInputWithSearch>
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.판정결과', '판정결과')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="resultDesc"
                  placeholder=""
                  disabled
                  value={selectedRowData?.resultDesc}
                ></CustomInputWithSearch>
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.등록 담당자', '등록담당자')}</label>
                <CustomInputWithSearch
                  name="gatingContNames"
                  className="find"
                  value={selectedRowData?.gatingContName}
                  readOnly={true}
                  disabled={true}
                  onSearchClick={(value) => {
                    if (selectedRowData == null || selectedRowData?.gatingContIds == '')
                      return false;
                    setInitUserId(selectedRowData?.gatingContIds || '');
                    setContPopTitle(String(t('gtng.label.등록 담당자', '등록담당자')));
                    setPopup(true);
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('gtng.label.등록 T/L', '등록T/L')}</label>
                <CustomInputWithSearch
                  name="pmoApproverName"
                  className="find"
                  value={selectedRowData?.pmoApproverName}
                  readOnly={true}
                  disabled={true}
                  onSearchClick={(param) => {
                    if (selectedRowData == null || selectedRowData?.pmoApproverId == '')
                      return false;
                    setInitUserId(selectedRowData?.pmoApproverId || '');
                    setContPopTitle(String(t('gtng.label.등록 T/L', '등록T/L')));
                    setPopup(true);
                  }}
                ></CustomInputWithSearch>
              </SearchCols>
            </SearchRows>
          </InputBox>
        </SearchBoxRow>
      </SearchBox>

      <GatingResultReportActGrid gatingId={selectedRowData?.gatingId} />

      {isOpenFileUploadPopUp && (
        <FileUploadPopUp
          open={isOpenFileUploadPopUp}
          close={() => setOpenFileUploadPopUp(false)}
          onCallback={(value) => {
            setOpenFileUploadPopUp(false);
            fnSearchGatingRegistMst(props.searchParamData);
          }}
          initParam={initParamFileUpload}
          downloadOnly={false}
          singleSelect={false}
          showAllDownload={true}
        />
      )}

      {/*담당자 팝업*/}
      {popup && (
        <GatingContListPopUp
          open={popup}
          // rowId={rowId}
          title={contPopTitle}
          setReadonly={true}
          initParam={initUserId}
          close={() => setPopup(false)}
        />
      )}
    </>
  );
};
export default GatingResultReportGrid;
