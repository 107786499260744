/** @jsxImportSource @emotion/react */
import React, { ReactNode, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DataBind from './features/DataBind';
import AddRemove from './features/AddRemove';
import ExcelExport from './features/ExcelExport';
import Popup from './features/Popup';
import MultiSelect from './features/MultiSelect';
import InputNumber from './features/InputNumber';
import InputDate from './features/InputDate';
import InputTextArea from './features/InputTextArea';
import LinkButton from './features/LinkButton';
import SelectBox from './features/SelectBox';
import InputCheckbox from './features/InputCheckbox';
const drawerWidth = 240;

interface Props {
  window?: () => Window;
}

const GuideWijmo = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [selectedMenu, setSelectedMenu] = useState<string | null>('Input');

  const handleMenuClick = (menu: string) => {
    setSelectedMenu(menu);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <List>
        {[
          'Data Bind',
          'Add/Remove',
          'Excel Export',
          'Popup',
          'SelectBox',
          'Multi Select',
          'Input[Number]',
          'Input[Date]',
          'Input[TextArea]',
          'Input[Checkbox]',
          'Link',
          'Test',
        ].map((menu, index) => (
          <ListItem key={menu} onClick={() => handleMenuClick(menu)}>
            <ListItemButton selected={selectedMenu === menu}>
              <ListItemText primary={menu} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiPaper-root': { display: 'contents' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <div style={{ flexGrow: 1, backgroundColor: '#f8f9fa' }}>
            {selectedMenu === 'Data Bind' && <DataBind />}
            {selectedMenu === 'Add/Remove' && <AddRemove />}
            {selectedMenu === 'Excel Export' && <ExcelExport />}
            {selectedMenu === 'Popup' && <Popup />}
            {selectedMenu === 'SelectBox' && <SelectBox />}
            {selectedMenu === 'Multi Select' && <MultiSelect />}
            {selectedMenu === 'Input[Number]' && <InputNumber />}
            {selectedMenu === 'Input[Date]' && <InputDate />}
            {selectedMenu === 'Input[TextArea]' && <InputTextArea />}
            {selectedMenu === 'Input[Checkbox]' && <InputCheckbox />}
            {selectedMenu === 'Link' && <LinkButton />}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default GuideWijmo;
