/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { getSample } from 'apis/common/Sample';
import ExcelReaderSample from 'components/excel/ExcelReadSample';
import { getExcelSample } from 'apis/common/Excel';
import ExcelUploadSample from 'components/excel/ExcelUploadSample';
import { useCommonModal } from 'hooks/useCommonModal';
import EmployeeModal from 'components/modals/common/EmployeeModal';
import DepartmentModal from 'components/modals/common/DepartmentModal';
import { Employee } from 'models/admin/Employee';
import { Department } from 'models/admin/Department';
import { BlueButton, BlueLineButton, GreyButton } from 'components/buttons/CustomButton';
import MailSample from 'components/mail/MailSample';
import { getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { multiLangTest } from 'apis/admin/MultiLang';
import { CommonCodeCondition } from 'models/common/CommonCode';

const SamplePage = () => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const [employeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee[]>([]);
  const [departmentModalOpen, setDepartmentModalOpen] = useState<boolean>(false);
  const [selectedDepartment, setSelectedDepartment] = useState<Department>();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContainer style={{ height: '204px', display: 'grid' }}>
        <CardTitle>{t('com.label.공통 페이지', '공통 페이지')}</CardTitle>
        <hr />
        <Link to="/dev/login">
          <GreyButton>{t('com.label.Dev Login', 'Dev Login')}</GreyButton>
        </Link>
        <Link to="/accessible">{t('com.label.접근 가능 페이지', '접근 가능 페이지')}</Link>
        <Link to="/inaccessible">{t('com.label.접근 불가능 페이지', '접근 불가능 페이지')}</Link>
      </CardContainer>

      <CardContainer style={{ height: '180px', display: 'grid' }}>
        <CardTitle>다국어 Sample</CardTitle>
        <hr />
        <div style={{ margin: '10px' }}>{t('com.label.elm', 'elm')}</div>
        <div>
          <GreyButton>{t('com.button.조회', '조회')}</GreyButton>
          <BlueButton>{t('com.button.저장', '저장')}</BlueButton>
          <br></br>
          안녕하세요.
        </div>
      </CardContainer>

      <CardContainer style={{ height: '150px', display: 'grid' }}>
        <CardTitle>Mock Service Worker Sample</CardTitle>
        <hr />
        <GreyButton onClick={getSample} style={{ width: '350px' }}>
          Mock Service Worker Test
        </GreyButton>
      </CardContainer>

      <CardContainer style={{ height: '300px', display: 'grid' }}>
        <CardTitle>Excel Sample</CardTitle>
        <hr />
        <button onClick={getExcelSample}>Excel Download Test</button>
        <br></br>
        <h3>Excel Read</h3>
        <div>
          <ExcelReaderSample />
        </div>

        <br></br>
        <h3>Excel Upload</h3>
        <div>
          <ExcelUploadSample />
        </div>
      </CardContainer>

      <CardContainer style={{ height: '140px', display: 'grid' }}>
        <CardTitle>{t('com.label.공통 모달 예시', '공통 모달 예시')}</CardTitle>
        <hr />
        <div style={{ display: 'flex' }}>
          <BlueButton
            onClick={(e) => {
              openCommonModal({
                content: 'alert content',
              });
            }}
          >
            alert
          </BlueButton>
          <BlueLineButton
            onClick={(e) => {
              openCommonModal({
                modalType: 'confirm',
                content: 'confirm content',
                yesCallback: () => {
                  openCommonModal({ content: 'im applied!' });
                },
                noCallback: () => {
                  openCommonModal({ content: 'im cancelled!' });
                },
              });
            }}
          >
            confirm
          </BlueLineButton>
          <GreyButton
            onClick={(e) => {
              openCommonModal({
                modalType: 'confirm',
                showCallbackResult: true,
                title: 'confirm',
                content: t(
                  'com.label.확인을 누르면, 결과 모달이 발생합니다.',
                  '확인을 누르면, 결과 모달이 발생합니다.'
                ),
                yesCallback: () => {
                  return 'applied';
                },
              });
            }}
          >
            confirm after api output is string
          </GreyButton>
        </div>
      </CardContainer>

      <CardContainer style={{ minHeight: '200px', display: 'grid', height: 'fit-content' }}>
        <CardTitle>{t('com.label.공통 팝업', '공통 팝업')}</CardTitle>
        <hr />
        <h1>{t('com.label.사용자 팝업', '사용자 팝업')}</h1>
        <BlueButton
          onClick={() => {
            setEmployeeModalOpen(true);
          }}
        >
          {t('com.button.사용자 팝업', '사용자 팝업')}
        </BlueButton>

        <EmployeeModal
          open={employeeModalOpen}
          close={() => setEmployeeModalOpen(false)}
          save={(employee) => {
            setSelectedEmployee(employee);
            setEmployeeModalOpen(false);
          }}
        />

        <div>
          {t('com.label.선택한 사용자', '선택한 사용자')} <br />
          {selectedEmployee.map((item, index) => (
            <p key={index}>{item.empNm}</p>
          ))}
        </div>

        <hr />
        <h1>{t('com.label.부서 조회 팝업', '부서 조회 팝업')}</h1>
        <BlueButton
          onClick={() => {
            setDepartmentModalOpen(true);
          }}
        >
          {t('com.label.부서 조회 팝업', '부서 조회 팝업')}
        </BlueButton>

        {departmentModalOpen && (
          <DepartmentModal
            open={departmentModalOpen}
            close={() => setDepartmentModalOpen(false)}
            save={(department: Department) => {
              setSelectedDepartment(department);
            }}
          />
        )}
        <div>
          <div>
            {t('com.label.선택한 부서', '선택한 부서')} :{selectedDepartment?.deptNm}
          </div>
        </div>
      </CardContainer>
      <hr />

      <CardContainer>
        <CardTitle>{t('com.label.Mail 발송 Sample', 'Mail 발송 Sample')}</CardTitle>
        <hr />
        <div>
          <MailSample />
        </div>
      </CardContainer>
      <CardContainer>
        <CardTitle>{t('com.label.다국어테스트', '다국어테스트')}</CardTitle>
        <hr />
        <BlueButton
          style={{ width: '150px' }}
          onClick={async () => {
            const response = await multiLangTest();
            alert(response.toString());
          }}
        >
          {t('com.label.다국어테스트', '다국어테스트')}
        </BlueButton>
      </CardContainer>
      <CardContainer>
        <CardTitle>
          {t(
            'com.label.공통 코드 조건 포함(OPT_VAL_CTN1~15) 조회',
            '공통 코드 조건 포함(OPT_VAL_CTN1~15) 조회'
          )}
        </CardTitle>
        <hr />
        <BlueButton
          style={{ width: '150px' }}
          onClick={async () => {
            const condition = {
              cmnGrCd: 'COP_CD',
              optValCtn1: '',
              optValCtn2: 'E',
              optValCtn3: '',
            } as CommonCodeCondition;
            const response = await getCommonCodeNamesCondition(condition);
            alert(response.map((item) => item.cmnCdNm));
          }}
        >
          optValCtn2:E
        </BlueButton>
        <BlueButton
          style={{ width: '150px' }}
          onClick={async () => {
            const condition = {
              cmnGrCd: 'COP_CD',
            } as CommonCodeCondition;
            const response = await getCommonCodeNamesCondition(condition);
            alert(response.map((item) => item.cmnCdNm));
          }}
        >
          {t('com.button.전체', '전체')}
        </BlueButton>
      </CardContainer>
      <hr />
    </div>
  );
};

const CardContainer = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
  padding: 20px;
  align-content: space-around;
  hr {
    width: 100%;
  }
  justify-items: start;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
export default SamplePage;
