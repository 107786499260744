/** @jsxImportSource @emotion/react */
import { AgGridReact } from 'ag-grid-react';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Generic } from 'models/ip/Generic';
import { CrudCode } from 'models/common/Edit';
import { CommonYN } from 'models/common/Common';
import { SubTitleLayout, SubTitleGroup, ControlBtnGroup } from 'components/layouts/ContentLayout';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { tb } from 'components/layouts/Table';
import TableCellInputText from 'pages/common/components/TableCellInputText';
import { Button } from '@mui/material';
import { ElmEmpPopup } from 'pages/common/ElmEmpPopup';
import PartnerUserModal from 'components/modals/common/PartnerUserModal';
import { Partner, PartnerUser } from 'models/common/Partner';
import PartnerModal from 'components/modals/common/PartnerModal';
import { IconButton } from 'components/buttons/IconSVG';

import { commonColDef, flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import 'ui/css/multiSelect.css';

interface paramData {
  condition: any;
  editable: boolean;
  callBackRowData?: any;
}

const TemplateValue = {
  maker: '',
  makerCd: '',
  makerInspector: '',
  makerInspectorNm: '',
  fatEnsolInspector: '',
  fatEnsolInspectorNm: '',
  satEnsolInspector: '',
  satEnsolInspectorNm: '',
};

const FacilityTargetGrid = forwardRef((props: paramData, ref) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<Generic[]>([]);
  const [templateValue, setTemplateValue] = useState(TemplateValue);

  const [isOpenElmEmpPopup, setOpenElmEmpPopup] = useState<boolean>(false);
  const [isOpenPartnerUserModal, setOpenPartnerUserModal] = useState<boolean>(false);
  const [isOpenPartnerModal, setIsOpenPartnerModal] = useState<boolean>(false);
  const [isOpenElmEmp, setOpenElmEmp] = useState<boolean>(false);
  const [isOpenPartnerEmp, setOpenPartnerEmp] = useState<boolean>(false);

  const [flexRef, setflexRef] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  const [isSaveAttempted, setSaveAttempted] = useState<boolean>(false);
  const cellRules = {
    'border: 3px solid red !important;': (params) => isSaveAttempted && !params.value,
  };

  console.log('paramData', props);
  // useEffect(() => {
  //   if (userData != '' && userData != null) {
  //     const userInfo = userData.userInfo[0] || {};
  //     setTemplateValue({
  //       ...templateValue,
  //       fatEnsolInspector: userInfo.userId,
  //       fatEnsolInspectorNm: userInfo.empNm,
  //     });
  //   }
  // }, [userData]);

  // const defaultColDef = useMemo(() => {
  //   return commonColDef;
  // }, []);

  // const columnDefs: (ColDef | ColGroupDef)[] = [
  //   {
  //     field: 'crudKey',
  //     headerName: String(t('com.label.상태', '상태')),
  //     width: 20,
  //     hide: true,
  //   },
  //   {
  //     headerName: String(t('ip.grid.No', 'No')),
  //     field: 'No',
  //     width: 60,
  //     filter: false,
  //     editable: false,
  //     valueFormatter: (params: ValueFormatterParams) => {
  //       return `${parseInt(params.node!.id!) + 1}`;
  //     },
  //     hide: true,
  //   },
  //   {
  //     width: 50,
  //     headerCheckboxSelection: true,
  //     headerCheckboxSelectionFilteredOnly: true,
  //     checkboxSelection: true,
  //     showDisabledCheckboxes: true,
  //     editable: false,
  //     pinned: 'left',
  //   },
  //   {
  //     headerName: String(t('ip.grid.Main설비ID', 'Main설비ID')),
  //     field: 'equipmentId',
  //     minWidth: 240,
  //     cellClass: (params) => ['cellEditing'],
  //     //cellClassRules: cellRules,
  //     //cellRenderer: CellCustomInputText,
  //     editable: true,
  //   },
  //   {
  //     headerName: String(t('ip.grid.설비명', '설비명')),
  //     field: 'equipmentName',
  //     cellClass: (params) => ['cellEditing'],
  //     editable: true,
  //   },
  //   {
  //     headerName: String(t('ip.grid.Maker', 'Maker')),
  //     field: 'maker',
  //     editable: false,
  //   },
  //   {
  //     field: 'fatMakerInspector',
  //     hide: true,
  //   },
  //   {
  //     field: 'fatEnsolInspector',
  //     hide: true,
  //   },
  //   {
  //     field: 'satMakerInspector',
  //     hide: true,
  //   },
  //   {
  //     field: 'satEnsolInspector',
  //     hide: true,
  //   },
  //   {
  //     headerName: String(t('ip.grid.FAT', 'FAT')),
  //     children: [
  //       {
  //         field: 'fatMakerInspectorNm',
  //         headerName: String(t('ip.grid.Maker 담당자', 'Maker 담당자')),
  //         editable: false,
  //         cellClass: 'cellFind',
  //         cellStyle: { textAlign: 'left' },
  //         cellRenderer: btnFindPartnerEmployee,
  //         cellRendererParams: (params: ICellRendererParams) => {
  //           return {
  //             ...params,
  //             fieldNameUserId: 'fatMakerInspector',
  //           };
  //         },
  //       },
  //       {
  //         field: 'fatEnsolInspectorNm',
  //         headerName: String(t('com.label.엔솔 담당자', '엔솔 담당자')),
  //         editable: false,
  //         cellClass: 'cellFind',
  //         cellStyle: { textAlign: 'left' },
  //         cellRenderer: btnFindEmployee,
  //         cellRendererParams: (params: ICellRendererParams) => {
  //           return {
  //             ...params,
  //             fieldNameUserId: 'fatEnsolInspector',
  //           };
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     headerName: String(t('ip.grid.SAT', 'SAT')),
  //     children: [
  //       {
  //         field: 'satMakerInspectorNm',
  //         headerName: String(t('ip.grid.Maker 담당자', 'Maker 담당자')),
  //         editable: false,
  //         cellClass: 'cellFind',
  //         cellStyle: { textAlign: 'left' },
  //         cellRenderer: btnFindPartnerEmployee,
  //         cellRendererParams: (params: ICellRendererParams) => {
  //           return {
  //             ...params,
  //             fieldNameUserId: 'satMakerInspector',
  //           };
  //         },
  //       },
  //       {
  //         field: 'satEnsolInspectorNm',
  //         headerName: String(t('com.label.엔솔 담당자', '엔솔 담당자')),
  //         editable: false,
  //         cellClass: 'cellFind',
  //         cellStyle: { textAlign: 'left' },
  //         cellRenderer: btnFindEmployee,
  //         cellRendererParams: (params: ICellRendererParams) => {
  //           return {
  //             ...params,
  //             fieldNameUserId: 'satEnsolInspector',
  //           };
  //         },
  //       },
  //     ],
  //   },
  // ];

  const listner = (received) => {
    setTemplateValue((templateValue) => ({ ...templateValue, [received.id]: received.value }));
  };

  const btnAddRow = () => {
    const newRow = {
      crudKey: CrudCode.CREATE,
      useYn: CommonYN.Y,
      equipmentId: '',
      equipmentName: '',
      maker: templateValue.maker,
      makerCd: templateValue.makerCd,
      fatMakerInspector: templateValue.makerInspector,
      fatMakerInspectorNm: templateValue.makerInspectorNm,
      fatEnsolInspector: templateValue.fatEnsolInspector,
      fatEnsolInspectorNm: templateValue.fatEnsolInspectorNm,
      satMakerInspector: templateValue.makerInspector,
      satMakerInspectorNm: templateValue.makerInspectorNm,
      satEnsolInspector: templateValue.satEnsolInspector,
      satEnsolInspectorNm: templateValue.satEnsolInspectorNm,
    } as Generic;
    setRowData([newRow, ...rowData]);
  };

  const btnDelRow = () => {
    flexRef.selectedRows.forEach(function (item) {
      flexRef.collectionView.remove(item.dataItem);
    });
  };

  const resultPopup = (fieldId, result) => {
    //setTemplateValue({ ...templateValue, [fieldId]: result.userInfo[0].userId });
    if (fieldId == 'fatEnsolInspector') {
      //setTemplateValue({ ...templateValue, fatEnsolInspector: result.userInfo[0].userId });
      setTemplateValue({
        ...templateValue,
        [fieldId]: result.userInfo[0].userId,
        fatEnsolInspectorNm: result.userInfo[0].empNm,
      });
    } else if (fieldId == 'satEnsolInspector') {
      //setTemplateValue({ ...templateValue, satEnsolInspector: result.userInfo[0].userId });
      setTemplateValue({
        ...templateValue,
        [fieldId]: result.userInfo[0].userId,
        satEnsolInspectorNm: result.userInfo[0].empNm,
      });
    }
  };

  const LayoutDefinition = () => {
    return [
      {
        binding: 'equipmentId',
        header: String(t('ip.grid.Main설비ID', 'Main설비ID')),
        align: 'left',
        width: 240,
      },
      {
        binding: 'equipmentName',
        header: String(t('com.label.설비명', '설비명')),
        align: 'left',
        width: 150,
      },
      {
        binding: 'maker',
        header: String(t('ip.grid.Maker', 'Maker')),
        align: 'left',
        width: 150,
        isReadOnly: true,
      },
      {
        header: String(t('ip.label.FAT', 'FAT')),
        align: 'center',
        columns: [
          {
            binding: 'fatMakerInspectorNm',
            header: String(t('ip.label.Maker 담당자', 'Maker 담당자')),
            width: 150,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: '<span>${item.fatMakerInspectorNm}</span><button id="btnPartnerEmp" />',
            // //cellTemplate: (cell) => CellTemplateBtnFindEmployee,
            // //cellTemplate: (cell) => 'abv',
            // cellTemplate: CellMaker.makeLink({
            //   text: '<span>${item.fatMakerInspectorNm}</span><button />',
            //   click: (e, ctx) => {
            //     BtnFindEmployee(e, ctx);
            //     // ctx.value = 'dddd';
            //     // ctx.text = 'ddfff';
            //     // ctx.item.fatMakerInspectorNm = 'dddddfffee';
            //     // console.log(ctx);
            //   },
            // }),
          },
          {
            binding: 'fatEnsolInspectorNm',
            header: String(t('com.label.엔솔 담당자', '엔솔 담당자')),
            width: 150,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: '<span>${item.fatEnsolInspectorNm}</span><button id="btnElmEmp" />',
            //cellTemplate: (ctx) => flexGridShortDate(ctx),
          },
        ],
      },
      {
        header: String(t('ip.label.SAT', 'SAT')),
        align: 'center',
        columns: [
          {
            binding: 'satMakerInspectorNm',
            header: String(t('ip.label.Maker 담당자', 'Maker 담당자')),
            width: 150,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: '<span>${item.satMakerInspectorNm}</span><button id="btnPartnerEmp" />',
          },
          {
            binding: 'satEnsolInspectorNm',
            header: String(t('com.label.엔솔 담당자', '엔솔 담당자')),
            width: 150,
            isReadOnly: true,
            cssClass: 'WijmoFind',
            cellTemplate: '<span>${item.satEnsolInspectorNm}</span><button id="btnElmEmp" />',
            //cellTemplate: (ctx) => flexGridShortDate(ctx),
          },
        ],
      },
    ];
  };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) return;
    const row = s.rows[e.row].dataItem;
  };

  const onInitialized = (grid) => {
    const selector = new Selector(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      // for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      // grid.rows[ht.row].isSelected = true;

      setflexRef(grid);
      setflexItem(item);
      setFieldId(col.binding);
      console.log(col, col.binding, grid.rows[ht.row]);

      if (e.target instanceof HTMLButtonElement) {
        switch (e.target.id) {
          case 'btnPartnerEmp':
            setOpenPartnerEmp(true);
            break;
          case 'btnElmEmp':
            setOpenElmEmp(true);
            break;
        }
      }

      //grid.refresh();
    });

    // // handle button clicks
    // grid.addEventListener(grid.hostElement, 'click', (e) => {
    //   const ht = grid.hitTest(e);

    //   const col = grid.columns[ht.col];
    //   const item = grid.rows[ht.row].dataItem;

    // });

    // // custom formatter to paint buttons and editors
    // grid.formatItem.addHandler(function (s, e) {
    //   if (e.panel == s.cells) {
    //     const col = s.columns[e.col];
    //     const item = s.rows[e.row].dataItem;
    //     switch (col.binding) {
    //       case 'fatMakerInspectorNm':
    //         e.cell.innerHTML = CellTemplateBtnFindEmployee(item);
    //         e.cell['dataItem'] = item;
    //         break;
    //     }
    //   }
    // });

    // grid.hostElement.addEventListener('click', (e) => {
    //   const len = grid.rows.length;
    //   if (len == 0) return;

    //   const ht = grid.hitTest(e);
    //   for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
    //   grid.rows[ht.row].isSelected = true;
    //   grid.refresh();
    // });
  };

  const resultEmpPopup = (userId, UserNm) => {
    //console.log(result);
    flexItem['crudKey'] = CrudCode.UPDATE;
    flexItem[fieldId.replace('Nm', '')] = userId;
    flexItem[fieldId] = UserNm;
    flexRef?.invalidate();
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  useEffect(() => {
    props.callBackRowData(rowData);
  });

  return (
    <div>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.대상설비', '대상설비')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <TableContainer css={tb.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCellInputText
                className="find"
                name={String(t('ip.label.Maker', 'Maker'))}
                id="maker"
                defaultValue={templateValue.maker}
                onChange={listner}
                findOnClick={() => setIsOpenPartnerModal(true)}
              ></TableCellInputText>
              <TableCellInputText
                className="find"
                name={String(t('ip.label.Maker담당자', 'Maker담당자'))}
                id="makerInspector"
                defaultValue={templateValue.makerInspectorNm}
                onChange={listner}
                findOnClick={() => setOpenPartnerUserModal(true)}
              ></TableCellInputText>
            </TableRow>
            <TableRow>
              <TableCellInputText
                className="find"
                name={String(t('ip.label.FAT 엔솔담당자', 'FAT 엔솔담당자'))}
                id="fatEnsolInspector"
                defaultValue={templateValue.fatEnsolInspectorNm}
                onChange={listner}
                findOnClick={() => {
                  setOpenElmEmpPopup(true);
                  setFieldId('fatEnsolInspector');
                }}
              ></TableCellInputText>
              <TableCellInputText
                className="find"
                name={String(t('ip.label.SAT 엔솔담당자', 'SAT 엔솔담당자'))}
                id="satEnsolInspector"
                defaultValue={templateValue.satEnsolInspectorNm}
                onChange={listner}
                findOnClick={() => {
                  setOpenElmEmpPopup(true);
                  setFieldId('satEnsolInspector');
                }}
              ></TableCellInputText>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <SubTitleLayout>
        <SubTitleGroup>
          <div className="info warning">
            {t(
              'ip.label.대상설비형식',
              'FAT 엔솔담당자! Main설비 ID : A1ASTK201, 설비명 : STK설비 형식으로 작성'
            )}
          </div>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="plus" disableRipple onClick={btnAddRow}>
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button css={IconButton.button} className="minus" disableRipple onClick={btnDelRow}>
            {t('com.button.행삭제', '행삭제')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
        <FlexGrid
          columns={state.layoutDefinition}
          itemsSource={state.itemsSource}
          selectionMode={'ListBox'}
          //headersVisibility="Column"
          //showSelectedHeaders="All"
          showMarquee={true}
          //showAlternatingRows={false}
          //alternatingRowStep={0}
          //allowMerging="ColumnHeaders"
          autoGenerateColumns={false}
          stickyHeaders={true}
          //isReadOnly={true}
          formatItem={flexGridTooltip}
          selectionChanged={onFlexGridSelectionChanged}
          initialized={onInitialized}
          style={{ height: '300px' }}
        />
      </ContentGrid>
      {isOpenElmEmpPopup && (
        <ElmEmpPopup
          fieldId={fieldId}
          //userData={userData}
          setUserData={(result) => resultPopup(fieldId, result)}
          setPopup={setOpenElmEmpPopup}
          close={() => setOpenElmEmpPopup(false)}
        />
      )}
      {isOpenPartnerUserModal && (
        <PartnerUserModal
          open={isOpenPartnerUserModal}
          condition={{
            userId: templateValue.makerInspector || '',
            excpNm: templateValue.maker || '',
            excpId: templateValue.makerCd || '',
          }}
          onCallback={(value) => {
            listner({ id: 'makerInspector', value: (value as PartnerUser).userId });
            listner({ id: 'makerInspectorNm', value: (value as PartnerUser).userNm });
          }}
          close={() => setOpenPartnerUserModal(false)}
        />
      )}
      {isOpenPartnerModal && (
        <PartnerModal
          open={isOpenPartnerUserModal}
          condition={{
            ptnrId: templateValue.makerCd || '',
          }}
          onCallback={(value) => {
            listner({ id: 'maker', value: (value as Partner).excpNm });
            listner({ id: 'makerCd', value: (value as Partner).excpId });
          }}
          close={() => setIsOpenPartnerModal(false)}
        />
      )}
      {isOpenElmEmp && (
        <ElmEmpPopup
          fieldId={fieldId}
          condition={{
            empNm: flexItem[fieldId] || '',
          }}
          setUserData={(result) =>
            resultEmpPopup(result.userInfo[0].userId, result.userInfo[0].empNm)
          }
          setPopup={setOpenElmEmp}
          close={() => setOpenElmEmp(false)}
        />
      )}
      {isOpenPartnerEmp && (
        <PartnerUserModal
          open={isOpenPartnerEmp}
          condition={{
            userNm: flexItem[fieldId] || '',
          }}
          close={() => setOpenPartnerEmp(false)}
          onCallback={(result) =>
            resultEmpPopup((result as PartnerUser).userId, (result as PartnerUser).userNm)
          }
        />
      )}
    </div>
  );
});

FacilityTargetGrid.displayName = '대상 설비';
export default memo(FacilityTargetGrid);
