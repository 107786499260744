/** @jsxImportSource @emotion/react */
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DOMPurify from 'dompurify';
import { di, TitleWrap, AlertBtnGroup } from 'components/layouts/Dialog';
import { CloseBtn } from 'components/buttons/CustomButton';
import 'components/modals/common/CommonModal.css';
import { Undefinedable } from 'models/common/FalsyGeneric';
import { useCommonModal } from 'hooks/useCommonModal';

export const CommonModal = () => {
  const { t } = useTranslation();
  const {
    commonModalState: {
      modalType,
      animation,
      isOpen,
      title,
      content,
      size,
      yesButtonText,
      yesCallback,
      noCallback,
      showCallbackResult,
    },
    openCommonModal,
    closeCommonModal,
  } = useCommonModal();

  useEffect(() => {
    if (isOpen) {
      document.body.style.top = `-${window.pageYOffset}px`;
      // common modal 발생 시 최상단으로 스크롤 업됨 우선 주석처리.
      // document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
    }
    return () => {
      const heightScroll = parseInt(document.body.style.top || '0', 10) * -1;
      document.body.style.removeProperty('position');
      document.body.style.removeProperty('overflow');
      document.body.style.removeProperty('width');
      document.body.style.removeProperty('top');
      // common modal 닫을 시 최상단으로 스크롤 업됨 우선 주석처리.
      // window.scrollTo(0, heightScroll);
    };
  }, [isOpen]);

  const handleCallbackResult = useCallback(
    async (callback: Undefinedable<() => any>, showCallbackResult = false) => {
      setTimeout(async () => {
        if (callback) {
          const callbackResult = await callback();
          // 결과값 있는 경우 modal 노출
          if (showCallbackResult && callbackResult) {
            openCommonModal({
              content: callbackResult,
            });
          }
        }
      }, 300);
    },
    [openCommonModal]
  );

  const handleNoButtonClick = useCallback(async () => {
    closeCommonModal();
    await handleCallbackResult(noCallback, showCallbackResult);
  }, [closeCommonModal, noCallback, showCallbackResult, handleCallbackResult]);

  const handleYesButtonClick = useCallback(async () => {
    closeCommonModal();
    await handleCallbackResult(yesCallback, showCallbackResult);
  }, [closeCommonModal, yesCallback, showCallbackResult, handleCallbackResult]);

  return (
    <>
      {isOpen && (
        <Dialog
          open={isOpen}
          css={di.dialog}
          fullWidth
          className={`${size} bgNone`}
          maxWidth={false}
        >
          <TitleWrap>
            <h2>
              {modalType === 'confirm' ? t('com.label.확인', '확인') : t('com.label.알림', '알림')}
            </h2>
            {/* 취소/확인을 하는 Alert는 "확인" 타이틀, "확인"만 하는 Alert는 "알림" 타이틀을 사용해주세요 */}
            <CloseBtn
              onClick={modalType === 'confirm' ? handleNoButtonClick : handleYesButtonClick}
            />
          </TitleWrap>
          <DialogContent>
            <div
              className="AlertContent"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
            ></div>
          </DialogContent>
          <AlertBtnGroup>
            {modalType === 'confirm' && (
              <Button className="Cancel" onClick={handleNoButtonClick} disableRipple>
                {t('com.button.취소', '취소')}
              </Button>
            )}
            <Button className="Confirm" onClick={handleYesButtonClick} disableRipple>
              {yesButtonText || t('com.button.확인', '확인')}
            </Button>
          </AlertBtnGroup>
        </Dialog>
      )}
    </>
  );
};
