/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from 'components/layouts/Dialog';
import { UseToggle } from 'components/inputs/CustomInput';
import { IconButton } from 'components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { ContentGrid } from 'components/layouts/ContentGrid';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
// import { findExcpUser } from 'apis/common/Partner';
import useSessionStore from 'stores/useSessionStore';
// Wijmo library
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import { ExcpPartnerUserSimpleSearchCondition } from 'models/admin/ExcpPartner';
import { getExcpList, getExcpUserList, getSimpleExcpUserList } from 'apis/admin/ExcpPartner';
import { ExcpUser } from 'models/admin/ExcpUser';
import { ExcpUserConditionSave } from 'models/admin/ExcpUser';
import { ExcpPartnerUser } from 'models/admin/ExcpPartner';

type Props = {
  open: boolean;
  close: () => void;
  condition?: {
    userId?: string;
    userNm?: string;
    excpId?: string;
    excpNm?: string;
  };
  onCallback: (item: any) => void;
  singleSelect?: boolean;
};

const ExcpUserModal = ({ open, close, condition, onCallback, singleSelect = true }: Props) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const [rowData, setRowData] = useState<ExcpPartnerUser[]>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();
  // const [partnerCondition, setPartnerCondition] = useState<ExcpUserConditionSave>();
  // useYn: 'Y',
  // userId: condition?.userId || '',
  // userNm: condition?.userNm || '',
  // ptnrId: condition?.ptnrId || '',
  // ptnrNm: condition?.ptnrNm || '',
  // });
  const [partnerCondition, setPartnerCondition] = useState<ExcpPartnerUserSimpleSearchCondition>({
    userId: '',
    userNm: '',
    excpNm: '',
    useYn: 'Y',
  });

  const [colWidthMap, setColWidthMap] = useState<any>({
    qselect: 120,
    userId: 359,
    userNm: 200,
    ptnrNm: '*',
    useYn: 100,
  });

  useEffect(() => {
    if (!_.isEmpty(condition?.userId) || !_.isEmpty(condition?.userNm)) {
      handleSearch(partnerCondition, true);
    }
    setPartnerCondition({ ...partnerCondition, excpNm: condition?.excpNm });
  }, []);

  const handleButtonClick = (params) => {
    onCallback(!_.isNull(params.data) ? params.data : null);
    close();
  };

  const handleConditionChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setPartnerCondition({ ...partnerCondition, [e.target.name]: e.target.value });
  };

  const LayoutDefinition = () => {
    return [
      // {
      //   width: 37,
      //   checkboxSelection: true,
      //   headerCheckboxSelection: true,
      // },
      {
        header: String(t('com.label.Quick Select', 'Quick Select')),
        width: colWidthMap.qselect,
        align: 'center',
        isReadOnly: true,
        cellTemplate: (params) => `<button id='qSelect'/>`,
        cssClass: 'WijmoRelay',
      },
      {
        binding: 'userId',
        header: String(t('com.label.사용자ID', '사용자ID')),
        isReadOnly: true,
        width: colWidthMap.userId,
        align: 'center',
      },
      {
        binding: 'userNm',
        header: String(t('com.label.사용자명', '사용자명')),
        isReadOnly: true,
        width: colWidthMap.userNm,
        align: 'center',
      },
      {
        binding: 'excpNm',
        header: String(t('com.label.협력업체명', '협력업체명')),
        isReadOnly: true,
        width: colWidthMap.ptnrNm,
        // flex: 1,
        align: 'center',
      },
      {
        binding: 'useYn',
        header: String(t('com.label.사용여부', '사용여부')),
        isReadOnly: true,
        width: colWidthMap.useYn,
        align: 'center',
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setflexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    //columnGroups속성 사용할 경우 -> Column 너비 수동 지정
    grid.resizingColumn.addHandler((s, e) => {
      const cell = s.columnHeaders.getCellElement(0, e.col);
      const col = e.panel.columns[e.col];
      colWidthMap[col.binding] = col.width;
      setColWidthMap(colWidthMap);
    });

    grid.hostElement.addEventListener('dblclick', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      handleButtonClick({ data: item });
    });

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;

      setflexItem(item);
      setFieldId(col.binding);

      switch (e.target.id) {
        case 'qSelect':
          handleButtonClick({ data: item });
          break;
      }

      grid.refresh();
    });
  };

  const handleSearch = async (condition: ExcpPartnerUserSimpleSearchCondition, isFirst = false) => {
    getSimpleExcpUserList(condition).then((result) => {
      setRowData(result);
    });
  };

  const handleResetCondition = () => {
    setPartnerCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) =>
            Object.assign(acc, {
              [cur]:
                cur === 'useYn'
                  ? 'Y'
                  : !!condition?.userId && cur === 'excpNm'
                  ? condition?.userNm
                  : '',
            }),
          {}
        ) as ExcpPartnerUserSimpleSearchCondition
    );
  };

  const handleConfirm = () => {
    const rows = flexRef.selectedRows.length > 0 ? flexRef.selectedRows : [];
    onCallback(rows.length > 0 ? rows[0].dataItem : rows);
    close();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(partnerCondition, true);
    }
  };

  return (
    <Dialog open={open} css={di.dialog} fullWidth className="lg" maxWidth={false}>
      <TitleWrap>
        <h2>{t('com.label.협력사 담당자 조회', '협력사 담당자 조회')}</h2>
        <Button onClick={() => close()} disableRipple></Button>
      </TitleWrap>
      <DialogContent>
        <SearchBox>
          <SearchBoxRow>
            <InputBox>
              <SearchRows className="popup">
                <SearchCols>
                  <label>{t('com.label.사용자ID', '사용자ID')}</label>
                  <CustomInputWithSearch
                    name="userId"
                    placeholder={String(
                      t('com.label.사용자 ID를 입력해 주세요.', '사용자 ID를 입력해 주세요.')
                    )}
                    value={partnerCondition.userId}
                    onChange={handleConditionChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
                <SearchCols>
                  <label>{t('com.label.사용자명', '사용자명')}</label>
                  <CustomInputWithSearch
                    name="userNm"
                    placeholder={String(
                      t('com.label.사용자명을 입력해 주세요.', '사용자명을 입력해 주세요.')
                    )}
                    value={partnerCondition.userNm}
                    onChange={handleConditionChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
                <SearchCols>
                  <label>{t('com.label.협력업체명', '협력업체명')}</label>
                  <CustomInputWithSearch
                    name="excpNm"
                    placeholder={String(
                      t('com.label.협력업체명을 입력해 주세요.', '협력업체명을 입력해 주세요.')
                    )}
                    value={partnerCondition.excpNm}
                    readOnly={!!condition?.userId}
                    onChange={handleConditionChange}
                    onKeyDown={handleKeyDown}
                  />
                </SearchCols>
              </SearchRows>
              {/* <SearchRows>
                <SearchCols>
                  <label>{t('com.label.사용여부', '사용여부')}</label>
                  <UseToggle className="switch">
                    <input
                      type="checkbox"
                      name="useYn"
                      value={partnerCondition.useYn}
                      checked={partnerCondition.useYn === 'Y'}
                      onChange={() => {
                        setPartnerCondition({
                          ...partnerCondition,
                          useYn: partnerCondition.useYn === 'Y' ? 'N' : 'Y',
                        });
                      }}
                    />
                    <span className="slider"></span>
                    <span className="labels" data-on="사용" data-off="미사용"></span>
                  </UseToggle>
                </SearchCols>
              </SearchRows> */}
            </InputBox>
            <SearchButtonWrap>
              <Button
                css={IconButton.button}
                className="reload"
                onClick={handleResetCondition}
                disableRipple
              ></Button>
              <Button
                css={IconButton.button}
                className="find"
                onClick={() => handleSearch(partnerCondition, true)}
                disableRipple
              >
                {t('com.button.조회', '조회')}
              </Button>
            </SearchButtonWrap>
          </SearchBoxRow>
        </SearchBox>
        <SubTitleLayout>
          <SubTitleGroup>
            <h3>{t('com.label.사용자', '사용자')}</h3>
            <span className="total">
              {t('com.label.총', '총')} <span>{rowData.length}</span> {t('com.label.건', '건')}
            </span>
          </SubTitleGroup>
        </SubTitleLayout>
        <ContentGrid
          className={rowData.length < 1 ? 'noData' : ''}
          style={{ height: '500px', marginBottom: rowData.length < 1 ? 0 : '32px' }}
        >
          <FlexGrid
            columnGroups={state.layoutDefinition}
            itemsSource={state.itemsSource}
            deferResizing={false}
            selectionMode={'Row'}
            stickyHeaders={true}
            formatItem={flexGridTooltip}
            initialized={onInitialized}
            style={{ height: (rowData || []).length < 1 ? '' : '500px' }}
          />
          <div className="noData" style={{ height: (rowData || []).length < 1 ? '500px' : '' }}>
            <span>
              {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
            </span>
          </div>
        </ContentGrid>
      </DialogContent>
      <PopupBtnGroup>
        <Button className="cancel" onClick={() => close()} disableRipple>
          {t('com.button.취소', '취소')}
        </Button>
        <Button css={IconButton.button} className="confirm" onClick={handleConfirm} disableRipple>
          {t('com.button.선택', '선택')}
        </Button>
      </PopupBtnGroup>
    </Dialog>
  );
};

export default ExcpUserModal;
