/** @jsxImportSource @emotion/react */
import React, { useState, useMemo, useEffect } from 'react';
import { Button } from '@mui/material';
import CustomTextarea from 'components/inputs/CustomTextarea';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/buttons/IconSVG';
import CustomDialog from 'components/modals/common/CustomDialog';

type ApprovalModalProps = {
  open: boolean | 'APPROVE' | 'REJECT' | 'AGREE' | 'DISAGREE';
  title: string;
  approvalType: '0' | '1' | '6' | '7' | '8';
  close: () => void;
  approveCallback: (item: string) => void;
  rejectCallback: (item: string) => void;
};

const ApproveModal = (props: ApprovalModalProps) => {
  const { t } = useTranslation();
  const [textAreaData, setTextAreaData] = useState<string>('');

  useEffect(() => setTextAreaData(''), [props.open]);

  const handleClose = () => {
    props.close();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaData(e.target.value);
  };

  const handleOnApprove = () => {
    props.approveCallback(textAreaData);
    handleClose();
  };
  const handleOnReject = () => {
    props.rejectCallback(textAreaData);
    handleClose();
  };

  const dialogButtons = useMemo(() => {
    if ('0' === props.approvalType) {
      if ('REJECT' === props.open) {
        return [
          <Button
            key={'reject'}
            css={IconButton.button}
            className="ok"
            onClick={handleOnReject}
            disableRipple
          >
            {t('com.button.반려', '반려')}
          </Button>,
        ];
      }
      if ('APPROVE' === props.open) {
        return [
          <Button
            key={'approve'}
            css={IconButton.button}
            className="ok"
            onClick={handleOnApprove}
            disableRipple
          >
            {t('com.button.승인', '승인')}
          </Button>,
        ];
      }
    }
    return null;
  }, [props.open, props.approvalType]);

  return (
    <CustomDialog
      title={props.title}
      open={props.open != false}
      size={'sm'}
      buttons={dialogButtons}
      onClose={handleClose}
      onCancel={handleClose}
    >
      <CustomTextarea
        placeholder={String(t('com.msg.결재의견을 입력해 주세요.', '결재의견을 입력해 주세요.'))}
        value={textAreaData}
        onChange={handleOnChange}
        showMaxLength={true}
        maxLength={1000}
      />
    </CustomDialog>
  );
};

export default ApproveModal;

/*
// [24.03.27] 승인/반려를 제외한 버튼 미사용
// (approvalType={'0'}으로 고정해서 사용 중 / 아래 참고용으로 주석 처리함)
{
      0: (
        <>
          {props.open === 'REJECT' && (
            <Button
              css={IconButton.button}
              className="confirmPrimary"
              disableRipple
              onClick={handleOnReject}
            >
              {t('com.button.반려', '반려')}
            </Button>
          )}
          {props.open === 'APPROVE' && (
            <Button
              css={IconButton.button}
              className="confirmPrimary"
              disableRipple
              onClick={handleOnApprove}
            >
              {t('com.button.승인', '승인')}
            </Button>
          )}
        </>
      ),
      1: (
        <>
          <Button
            css={IconButton.button}
            className="confirm"
            disableRipple
            onClick={handleOnReject}
          >
            {t('com.button.반대', '반대')}
          </Button>
          <Button
            css={IconButton.button}
            className="confirmPrimary"
            disableRipple
            onClick={handleOnApprove}
          >
            {t('com.button.찬성', '찬성')}
          </Button>
        </>
      ),
      6: (
        <>
          {props.open === 'DISAGREE' && (
            <Button
              css={IconButton.button}
              className="confirmPrimary"
              disableRipple
              onClick={handleOnReject}
            >
              {t('com.button.거부', '거부')}
            </Button>
          )}
          {props.open === 'AGREE' && (
            <Button
              css={IconButton.button}
              className="confirmPrimary"
              disableRipple
              onClick={handleOnApprove}
            >
              {t('com.button.합의', '합의')}
            </Button>
          )}
        </>
      ),
      7: (
        <Button
          css={IconButton.button}
          className="confirmPrimary"
          disableRipple
          onClick={handleOnApprove}
        >
          {t('com.button.확인', '확인')}
        </Button>
      ),
      8: (
        <>
          <Button
            css={IconButton.button}
            className="confirmPrimary"
            disableRipple
            onClick={handleOnReject}
          >
            {t('com.button.반려', '반려')}
          </Button>
          <Button
            css={IconButton.button}
            className="confirmPrimary"
            disableRipple
            onClick={handleOnApprove}
          >
            {t('com.button.확정', '확정')}
          </Button>
        </>
      ),
    }[props.approvalType]
*/
