/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLanguageStore from '../../stores/useLanguageStore';
import { useMessageBar } from '../../hooks/useMessageBar';

import { Cookies } from 'react-cookie';
import CustomInputWithSearch from '../../components/inputs/CustomInputWithSearch';
import { ContentGrid } from '../../components/layouts/ContentGrid';
import { ViewTable } from '../../components/tables/ViewTable';
import { BlueButton, GreyButton, GreyLineButton } from '../../components/buttons/CustomButton';
import { useCommonModal } from '../../hooks/useCommonModal';
import { ExcpUser } from '../../models/admin/ExcpUser';
import { editExcpUser, getExcpUserInfo } from '../../apis/admin/ExcpUser';
import * as CryptoJS from 'crypto-js';
import useSessionStore from 'stores/useSessionStore';
import { JoinLayout } from 'components/layouts/MainLayout';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { tb } from '../../components/layouts/Table';
import { IconButton } from '../../components/buttons/IconSVG';
import {
  CustomInputText,
  CustomInputWrap,
  AllInputWrap,
  UseToggle,
} from '../../components/inputs/CustomInput';
import { GlobalBtnGroup } from 'components/layouts/ContentLayout';

import styled from '@emotion/styled';
import { GridInfoSection } from 'components/layouts/ContentSection';
import { ExcpManagementPopUp } from 'pages/partner/excpmanagement/ExcpManagementPopUp';
import { ManagementMode } from 'models/common/Common';
import { FormatAlignCenter } from '@mui/icons-material';

const excpUser: ExcpUser = {
  excpId: '',
  excpNm: '',
  rpsnNm: '',
  userId: '',
  userNm: '',
  userPwd: '',
  userEml: '',
  userTel: '',
  mgrUserId: '',
  mgrUserNm: '',
  mgrUserDeptNm: '',
  secrPldgYn: '',
  secrPldgDtm: '',
  pwdChgDtm: '',
  idvlIfoCncYn: '',
  idvlIfoCncDtm: '',
  rpsnEml: '',
  rpsnPhn: '',
  snsrYn: '',
  ipYn: '',
};

interface DisplayCondition {
  dataInsUserNm?: string; // 등록자명
}

const ExcpUserEditPage = () => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();
  const [onLoad, setOnload] = useState<any>(true);

  const { userId, empNm, deptNm, deptCd } = useSessionStore();
  const [excpUserInfo, setExcpUserInfo] = useState<ExcpUser>(excpUser);
  const [langCd, setLangCd] = useState<string>('ko');
  const [upd, setUpd] = useState<string>('');
  const [updr, setUpdr] = useState<string>('');
  const [orgUserTel, setOrgUserTel] = useState<string>('');
  const { openCommonModal } = useCommonModal();
  const [isOpenExcpManagementPopUp, setOpenExcpManagementPopUp] = useState<boolean>(false);
  const [flexItem, setflexItem] = useState<any>();
  const cookies = new Cookies();

  const handleUserInfoOnLoad = async () => {
    if (userId) {
      const response = await getExcpUserInfo(userId);
      if (response) {
        setOrgUserTel(response.userTel || '');
        setExcpUserInfo({
          ...excpUserInfo,
          userId: response.userId,
          rpsnNm: response.rpsnNm,
          userTel: response.userTel,
          mgrUserId: response.mgrUserId,
          mgrUserNm: response.mgrUserNm,
          mgrUserDeptNm: response.mgrUserDeptNm,
          excpId: response.excpId,
          excpNm: response.excpNm,
          rpsnEml: response.rpsnEml,
          rpsnPhn: response.rpsnPhn,
          snsrYn: response.snsrYn,
          ipYn: response.ipYn,
        });
      }
      setOnload(false);
    }
  };

  useEffect(() => {
    if (onLoad == true) {
      handleUserInfoOnLoad();
    }
  });

  const key = CryptoJS.enc.Utf8.parse('s302lks823klasde0fasdfal322kj439');
  const iv = CryptoJS.enc.Utf8.parse('2983923827611212');

  /*
  const key = CryptoJS.enc.Utf8.parse('s302lks823klasde0fasdfal322kj439');
  const iv = CryptoJS.enc.Utf8.parse('2983923827611212');
  const pwd = 'My Name Is Sam';

  const encryptedCP = CryptoJS.AES.encrypt(pwd, key, { iv: iv });
  const decryptedWA = CryptoJS.AES.decrypt(encryptedCP, key, { iv: iv });
  const cryptText = encryptedCP.toString();

  //Decode from pwd
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Base64.parse(cryptText),
  });
  const decryptedFromText = CryptoJS.AES.decrypt(cipherParams, key, { iv: iv });

  const showPassword = (e) => {
    console.log(encryptedCP);
    console.log(cryptText);
    console.log(decryptedWA.toString(CryptoJS.enc.Utf8));
    console.log(decryptedFromText.toString(CryptoJS.enc.Utf8));
  };*/

  const handleRefresh = () => {
    setOpenExcpManagementPopUp(false);
    openMessageBar({
      type: 'confirm',
      content: t(
        'com.label.업체정보가 변경되어 메인페이지로 이동합니다.',
        '업체정보가 변경되어 메인페이지로 이동합니다.'
      ),
    });
    navigate('/', { replace: true });
    window.location.reload();
  };

  const handleChangePwd = (e) => {
    setUpd(e.target.value);
  };

  const handleChangeRePwd = (e) => {
    setUpdr(e.target.value);
    const encryptedCP = CryptoJS.AES.encrypt(e.target.value, key, { iv: iv });
    setExcpUserInfo({
      ...excpUserInfo,
      userPwd: encryptedCP.toString(),
    });
  };

  const handleOnChangeUserTel = (e) => {
    setExcpUserInfo({
      ...excpUserInfo,
      userTel: e.target.value,
    });
  };

  const handleOnSave = async () => {
    let noProblem = true;

    const validatePwrd = (pwrd: any) => {
      return String(pwrd).match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,16}$/
      );
    };

    const validateTel = (tel: any) => {
      return String(tel).match(/^[-0-9]{8,16}$/);
    };

    if (upd != '') {
      if (validatePwrd(upd) == null) {
        noProblem = false;
        openCommonModal({
          modalType: 'alert',
          content: t(
            'com.label.비밀번호는 8자이상 16자이하이어야 하고, 최소 하나의 숫자, 대문자, 소문자, 특수문자를 포함해야 됩니다.',
            '비밀번호는 8자이상 16자이하이어야 하고, 최소 하나의 숫자, 대문자, 소문자, 특수문자를 포함해야 됩니다.'
          ),
        });
        return false;
      }

      if (upd !== updr) {
        noProblem = false;
        openCommonModal({
          modalType: 'alert',
          content: t(
            'com.label.입력하신 비밀번호가 일치하지 않습니다.',
            '입력하신 비밀번호가 일치하지 않습니다.'
          ),
        });
        setExcpUserInfo({
          ...excpUserInfo,
          userPwd: '',
        });
        setUpdr('');
        return false;
      }
    }

    if (excpUserInfo.userTel !== '' && !validateTel(excpUserInfo.userTel)) {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.형식에 맞지 않은 연락처입니다.', '형식에 맞지 않은 연락처입니다.'),
      });
      return false;
    }
    if (upd == '' && orgUserTel == excpUserInfo.userTel) {
      noProblem = false;
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.수정사항이 없습니다.', '수정사항이 없습니다.'),
      });
      return false;
    }

    if (noProblem) {
      openCommonModal({
        modalType: 'confirm',
        content: t('com.label.수정하시겠습니까?', '수정하시겠습니까?'),
        yesCallback: async () => {
          const response = await editExcpUser({
            ...excpUserInfo,
          });
          if (response.successOrNot === 'Y') {
            openMessageBar({
              type: 'confirm',
              content: t(
                'com.label.저장되었습니다. 정보가 변경되어 메인페이지로 이동합니다.',
                '저장되었습니다. 정보가 변경되어 메인페이지로 이동합니다.'
              ),
            });
            navigate('/', { replace: true });
          } else {
            openMessageBar({
              type: 'error',
              content: t(
                'admin.msg.저장에 실패하였습니다. 입력값을 확인하세요.',
                '저장에 실패하였습니다. 입력값을 확인하세요.'
              ),
            });
          }
        },
      });
    }
  };

  return (
    <JoinLayout>
      <TableContainer css={tb.table}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.이름', '이름')}</span>
              </TableCell>
              <TableCell>{empNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.아이디(이메일)', '아이디(이메일)')}</span>
              </TableCell>
              <TableCell>{userId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.비밀번호', '비밀번호')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch type="password" onChange={handleChangePwd} maxLength={20} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.비밀번호 확인', '비밀번호 확인')}</span>
              </TableCell>
              <TableCell>
                <CustomInputWithSearch
                  type="password"
                  onChange={handleChangeRePwd}
                  maxLength={20}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.업체정보', '업체정보')}</span>
              </TableCell>
              <TableCell>{deptNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.대표자명', '대표자명')}</span>
              </TableCell>
              <TableCell>{excpUserInfo.rpsnNm}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('com.label.연락처', '연락처')}</TableCell>
              <TableCell>
                <CustomInputWithSearch
                  id="userTel"
                  value={excpUserInfo.userTel}
                  onChange={handleOnChangeUserTel}
                  maxLength={16}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <span className="dot">{t('com.label.업무(엔솔) 담당자', '업무(엔솔) 담당자')}</span>
              </TableCell>
              <TableCell>
                {excpUserInfo.mgrUserNm}({excpUserInfo.mgrUserId})/{excpUserInfo.mgrUserDeptNm}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <GlobalBtnGroup className="between">
        <Button
          css={IconButton.button}
          className="confirm"
          onClick={() => {
            setflexItem({
              excpId: excpUserInfo.excpId,
              excpNm: excpUserInfo.excpNm,
              rpsnNm: excpUserInfo.rpsnNm,
              rpsnEml: excpUserInfo.rpsnEml,
              rpsnPhn: excpUserInfo.rpsnPhn,
              snsrYn: excpUserInfo.snsrYn,
              ipYn: excpUserInfo.ipYn,
              mode: ManagementMode.MODIFY,
              staffEdit: true,
            });
            setOpenExcpManagementPopUp(true);
          }}
        >
          {t('com.label.업체정보수정', '업체정보수정')}
        </Button>
        <Button css={IconButton.button} className="save" onClick={handleOnSave}>
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>
      {isOpenExcpManagementPopUp && (
        <ExcpManagementPopUp
          open={isOpenExcpManagementPopUp}
          setPopup={setOpenExcpManagementPopUp}
          condition={flexItem}
          isFromUserEditPage={true}
          close={handleRefresh}
        />
      )}
    </JoinLayout>
  );
};

export default ExcpUserEditPage;
