import {
  EquipmentParameterCondition,
  EquipmentParameter,
  ParameterPointCondition,
  ParameterPoint,
  ParameterApprovalRequest,
  ParameterMasterRequest,
  ParameterDetailRequest,
} from 'models/ip/EquipmentParameter';
import { ExcelDownloadRequest } from 'models/common/Excel';
import { downloadFile } from 'apis/common/Excel';
import { PaginationResponse } from 'models/common/Pagination';
import { CommonRequest, CommonResponse, Method, ServiceName } from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';
import { ManagementMode } from 'models/common/Common';

/**
 * 설비별 IP 목록 조회
 * @param condition
 */
export const findEquipmentParameter = async (condition?: EquipmentParameterCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/equipment-parameter`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<PaginationResponse<EquipmentParameter>> = await callApi(request);

  return (
    response.successOrNot === 'Y' ? response.data : null
  ) as PaginationResponse<EquipmentParameter>;
};

/**
 * 설비별 IP 목록 엑셀 다운로드
 * @param condition
 */
export const downloadExcelEquipmentParameter = async (
  condition: ExcelDownloadRequest<EquipmentParameterCondition>
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/equipment-parameter/excel-download`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, `${condition.fileName}`);
};

export const generateAtchFileGrId = async (
  eqpParaNo: string,
  versionNo: string,
  paraId: string
) => {
  const condition = {
    eqpParaNo,
    versionNo,
    paraId,
  };

  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/equipment-parameter/attach`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
  };

  const response: CommonResponse<string> = await callApi(request);
  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * 측정개소 조회
 * @param condition
 */
export const findParameterPoint = async (condition: ParameterPointCondition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/parameter-point`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<ParameterPoint[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ParameterPoint[];
};

/**
 * 측정개소 목록 엑셀 다운로드
 * @param condition
 */
export const downloadExcelParameterPoint = async (
  condition: ExcelDownloadRequest<ParameterPointCondition>
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/parameter-point/excel-download`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: condition,
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const response: CommonResponse<Blob> = await callApi(request);
  if (response?.data) downloadFile(response.data, `${condition.fileName}`);
};

/**
 * 설비 파라미터 번호 생성
 * @param equipmentGroup
 * @return {eqpParaNo: 설비파라미터번호}
 */
export const generateEquipmentParameterNo = async (equipmentGroup: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/equipment-parameter-no`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ equipmentGroup: equipmentGroup }),
  };

  const response: CommonResponse<EquipmentParameter> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as EquipmentParameter;
};

/**
 * 파라미터 ID생성
 * @param eqpParaNo
 * @return {paraId: 파라미터ID}
 */
export const generateParameterId = async (eqpParaNo: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/parameter-id`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ eqpParaNo: eqpParaNo }),
  };

  const response: CommonResponse<EquipmentParameter> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as EquipmentParameter;
};

// /**
//  * 파라미터 ID생성
//  * @param eqpParaNo
//  * @return {paraId: 파라미터ID}
//  */
// export const generateParameterIds = async (eqpParaNo: string, aprReqId: string) => {
//   const request: CommonRequest = {
//     method: Method.GET,
//     url: `/v1/ip/parameter-ids`,
//     serviceName: ServiceName.ELM_BE,
//     queryParams: new URLSearchParams({ eqpParaNo: eqpParaNo, aprReqId: aprReqId }),
//   };

//   const response: CommonResponse<EquipmentParameter> = await callApi(request);

//   return (response.successOrNot === 'Y' ? response.data : null) as EquipmentParameter;
// };

/**
 * 결재요청ID 생성 (임시)
 *
 * [23.10.04] 결재테이블에 데이터 생성하지 않고 id만 생성해서 사용하도록함
 * TODO 결재작업 완료 후 삭제 예정
 */
export const generateApprovalRequestId = async () => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/approval-request-id`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<EquipmentParameter> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

/**
 * 설비 파라미터 결재요청 저장
 * @param approvalRequest
 */
export const createEquipmentParameterRequest = async (
  approvalRequest?: ParameterApprovalRequest
) => {
  console.log('결재임시저장');
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/equipment-parameter/request`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: approvalRequest,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

/**
 * 설비 파라미터 결재요청 초기 데이터 등록
 * @param aprReqId
 * @param versionNo
 * @param mode
 */
export const createEquipmentParameterRequestByEqpParaNo = async (
  eqpParaNo: string,
  versionNo: string,
  mode: ManagementMode
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/equipment-parameter/${eqpParaNo}/request`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: { mode: mode, versionNo: versionNo },
  };

  const response: CommonResponse<string> = await callApi(request);
  return response;
};

/**
 * 작성중인 문서 목록 조회
 * @param condition
 */
export const findEquipmentParameterRequestList = async (
  condition?: EquipmentParameterCondition
) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/equipment-parameter/request`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ ...condition }),
  };

  const response: CommonResponse<PaginationResponse<ParameterApprovalRequest>> = await callApi(
    request
  );

  return (
    response.successOrNot === 'Y' ? response.data : null
  ) as PaginationResponse<ParameterApprovalRequest>;
};

/**
 * 설비 파라미터 요청 조회
 * @param aprReqId
 */
export const findEquipmentParameterRequest = async (aprReqId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/equipment-parameter/request/${aprReqId}`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<ParameterApprovalRequest> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ParameterApprovalRequest;
};

/**
 * 설비 파라미터 마스터 요청 목록 조회
 * @param aprReqId
 */
export const findEquipmentParameterRequestMasterList = async (aprReqId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/equipment-parameter/request/${aprReqId}/master`,
    serviceName: ServiceName.ELM_BE,
  };

  const response: CommonResponse<ParameterMasterRequest[]> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ParameterMasterRequest[];
};

/**
 * 설비 파라미터 요청 정보 결재 처리
 * @param approvalRequest
 */
export const approveEquipmentParameterRequest = async (
  approvalRequest?: ParameterApprovalRequest
) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/ip/equipment-parameter/request/${approvalRequest?.aprReqId}/approval`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: approvalRequest,
  };

  const response: CommonResponse = await callApi(request);
  return response;
};

export const getEquipmentParameterRecentAprReqId = async (condition) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/equipment-parameter/request/getEquipmentParameterRecentAprReqId`,
    serviceName: ServiceName.ELM_BE,
    queryParams: condition,
  };

  const response: CommonResponse<string> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as string;
};

export const getEquipmentParameterRecentVersion = async (eqpParaNo) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/ip/equipment-parameter/request/getEquipmentParameterRecentVersion`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({ eqpParaNo: eqpParaNo }),
  };

  const response: CommonResponse<string> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as string;
};
