/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// Wijmo library
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { DataMap, SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';

import { Button } from '@mui/material';
import { di, TitleWrap, PopupBtnGroup } from 'components/layouts/Dialog';
import { SubTitleGroup, SubTitleLayout, ControlBtnGroup } from 'components/layouts/ContentLayout';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { IpCheckResult, IpCheckResultCondition } from 'models/ip/IpCheckResult';
import { findCheckResultParaPoint } from 'apis/ip/IpCheckResult';
import useSessionStore from 'stores/useSessionStore';
import { CloseBtn } from 'components/buttons/CustomButton';

type CheckResultParaPointModalProps = {
  close: () => void;
  condition: IpCheckResultCondition;
  info?: any;
};
const CheckResultParaPointModal = (props: CheckResultParaPointModalProps) => {
  const { t } = useTranslation();
  const { gridNoRowsTemplate } = useSessionStore();
  const { info, condition, close } = props;
  const [rowData, setRowData] = useState<IpCheckResult[]>([]);
  const [flexRef, setflexRef] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [flexItem, setflexItem] = useState<any>();
  const [fieldId, setFieldId] = useState<any>();

  useEffect(() => {
    handleSearch(condition);
  }, []);

  const handleSearch = async (condition: IpCheckResultCondition) => {
    const response = await findCheckResultParaPoint(condition);
    if (response) {
      const rowData = response || [];
      setRowData(rowData);
    }
  };

  const LayoutDefinition = () => {
    return [
      {
        binding: 'paraName',
        header: String(t('ip.grid.파라미터명', '파라미터명')),
        isReadOnly: true,
        width: 100,
      },
      {
        binding: 'paraPointNo',
        header: String(t('ip.grid.포인터 번호', '포인터 번호')),
        isReadOnly: true,
        align: 'center',
        width: 100,
      },
      {
        binding: 'paraPointName',
        header: String(t('ip.grid.측정개소명', '측정개소명')),
        isReadOnly: true,
        width: 100,
      },
      {
        binding: 'paraPointDesc',
        header: String(t('ip.grid.측정개소 설명', '측정개소 설명')),
        isReadOnly: true,
        width: 100,
      },
      {
        binding: 'lowerBound',
        header: String(t('ip.grid.하한', '하한')),
        isReadOnly: true,
        width: 80,
      },
      {
        binding: 'upperBound',
        header: String(t('ip.grid.상한', '상한')),
        isReadOnly: true,
        width: 80,
      },
      {
        header: String(t('ip.grid.FAT Maker', 'FAT Maker')),
        columns: [
          {
            binding: 'fatrpMakerTargetFlag',
            isReadOnly: true,
            align: 'center',
            header: String(t('ip.grid.대상여부', '대상여부')),
            width: 80,
          },
          {
            binding: 'fatrpMakerValue',
            isReadOnly: true,
            header: String(t('ip.grid.Value', 'Value')),
            width: 80,
          },
          {
            binding: 'fatrpMakerResult',
            isReadOnly: true,
            header: String(t('ip.grid.Result', 'Result')),
            width: 80,
          },
          {
            binding: 'fatrpMakerComment',
            isReadOnly: true,
            header: String(t('ip.grid.Comment', 'Comment')),
            width: 80,
          },
        ],
      },
      {
        header: String(t('ip.grid.FAT 엔솔', 'FAT 엔솔')),
        columns: [
          {
            binding: 'fatrpEnsolTargetFlag',
            isReadOnly: true,
            align: 'center',
            header: String(t('ip.grid.대상여부', '대상여부')),
            width: 80,
          },
          {
            binding: 'fatrpEnsolValue',
            isReadOnly: true,
            header: String(t('ip.grid.Value', 'Value')),
            width: 80,
          },
          {
            binding: 'fatrpEnsolComment',
            isReadOnly: true,
            header: String(t('ip.grid.Result', 'Result')),
            width: 80,
          },
          {
            binding: 'fatrpEnsolComment',
            isReadOnly: true,
            header: String(t('ip.grid.Comment', 'Comment')),
            width: 80,
          },
        ],
      },
      {
        header: String(t('ip.grid.SAT Maker', 'SAT Maker')),
        columns: [
          {
            binding: 'satrpMakerTargetFlag',
            isReadOnly: true,
            align: 'center',
            header: String(t('ip.grid.대상여부', '대상여부')),
            width: 80,
          },
          {
            binding: 'satrpMakerValue',
            isReadOnly: true,
            header: String(t('ip.grid.Value', 'Value')),
            width: 80,
          },
          {
            binding: 'satrpMakerResult',
            isReadOnly: true,
            header: String(t('ip.grid.Result', 'Result')),
            width: 80,
          },
          {
            binding: 'satrpMakerComment',
            isReadOnly: true,
            header: String(t('ip.grid.Comment', 'Comment')),
            width: 80,
          },
        ],
      },
      {
        header: String(t('ip.grid.SAT 엔솔', 'SAT 엔솔')),
        columns: [
          {
            binding: 'satrpEnsolTargetFlag',
            isReadOnly: true,
            align: 'center',
            header: String(t('ip.grid.대상여부', '대상여부')),
            width: 80,
          },
          {
            binding: 'satrpEnsolValue',
            isReadOnly: true,
            header: String(t('ip.grid.Value', 'Value')),
            width: 80,
          },
          {
            binding: 'satrpEnsolResult',
            isReadOnly: true,
            header: String(t('ip.grid.Result', 'Result')),
            width: 80,
          },
          {
            binding: 'satrpEnsolComment',
            isReadOnly: true,
            header: String(t('ip.grid.Comment', 'Comment')),
            width: 80,
          },
        ],
      },
    ];
  };
  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onInitialized = (grid) => {
    new FlexGridFilter(grid);
    new Selector(grid);
    setflexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;

      const ht = grid.hitTest(e);
      setHitTest(ht);

      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      // row 선택시 체크박스 체크
      for (let i = 0; i < len; i++) grid.rows[i].isSelected = false;
      grid.rows[ht.row].isSelected = true;

      const col = grid.columns[ht.col];
      const item = grid.rows[ht.row].dataItem;
      grid.refresh();
    });
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={() => close()}
        css={di.dialog}
        fullWidth
        className="lg"
        maxWidth={false}
      >
        <TitleWrap>
          <h2>{t('ip.label.IP 점검 측정개소 결과 조회', 'IP 점검 측정개소 결과 조회')}</h2>
          <CloseBtn onClick={() => close()} />
        </TitleWrap>
        <DialogContent>
          <SubTitleLayout>
            <SubTitleGroup>
              <h3>{t('ip.label.측정개소 결과 조회', '측정개소 결과 조회')}</h3>
              <span className="total">
                {t('com.label.총', '총')} <span>{(rowData.length || 0).toLocaleString()}</span>{' '}
                {t('com.label.건', '건')}
              </span>
            </SubTitleGroup>
          </SubTitleLayout>
          <ContentGrid
            className={`ag-theme-alpine ${rowData.length < 1 ? 'noData' : ''}`}
            style={{ height: '380px', marginBottom: rowData.length < 1 ? 0 : '32px' }}
          >
            <FlexGrid
              columns={state.layoutDefinition}
              itemsSource={state.itemsSource}
              //selectionMode={'ListBox'}
              //headersVisibility="Column"
              //showSelectedHeaders="All"
              //showMarquee={false}
              //showAlternatingRows={false}
              //alternatingRowStep={0}
              // allowPinning="ColumnRange" // 열 고정 방식
              //allowMerging="ColumnHeaders"
              // autoGenerateColumns={false}
              selectionMode={'Row'}
              stickyHeaders={true}
              //isReadOnly={true}
              // itemsSourceChanging={onCellValueChanged}
              formatItem={flexGridTooltip}
              // selectionChanged={onFlexGridSelectionChanged}
              initialized={onInitialized}
              style={{ height: (rowData || []).length < 1 ? '' : '380px' }}
            />
            <div className="noData" style={{ height: (rowData || []).length < 1 ? '380px' : '' }}>
              <span>
                {t('com.label.조회 가능한 데이터가 없습니다.', '조회 가능한 데이터가 없습니다.')}
              </span>
            </div>
            {/* <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              groupHeaderHeight={32}
              rowHeight={32}
              rowSelection={'multiple'}
              overlayNoRowsTemplate={gridNoRowsTemplate}
              onGridReady={(params: GridReadyEvent) => {
                params.api.sizeColumnsToFit();
              }}
            /> */}
          </ContentGrid>
        </DialogContent>
        <PopupBtnGroup>
          <Button className="cancel" disableRipple onClick={() => close()}>
            {t('com.button.닫기', '닫기')}
          </Button>
        </PopupBtnGroup>
      </Dialog>
    </>
  );
};
export default CheckResultParaPointModal;
