/** @jsxImportSource @emotion/react */
import { css } from '@mui/material';
import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { SVGIcon } from 'components/buttons/IconSVG';
import {
  CommonBG,
  CommonText,
  CommonBorder,
  ControlFilldBG,
  InputBorder,
  TableBG,
  InputBG,
  ControlLinedBG,
} from 'ui/theme/Color';

export const LoginWrap = styled.div`
  width: 468px;
  padding: 64px;
  background: ${CommonBG.Basic};
  font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
  & > div:first-child {
    margin-bottom: 32px;
  }
  button.login {
    margin-top: 24px;
    width: 100%;
    height: 54px;
    border-radius: 2px;
    background: ${CommonBG.Inverse};
    font-size: 18px;
    line-height: 0.25;
    font-weight: 700;
    color: ${CommonText.Inverse};
    outline: none;
    font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', Sans-Serif;
    &:hover,
    &:focus {
      background: ${ControlFilldBG.Primary};
    }
  }
  button.down {
    width: 100%;
    height: 36px;
    margin-bottom: 8px;
    position: relative;
    border-radius: 3px;
    background: ${CommonBorder.Bright};
    font-size: 13px;
    font-weight: 700;
    line-height: 1.5;
    color: ${CommonText.Light};
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 92px;
      background: ${CommonText.Light};
      -webkit-mask: ${SVGIcon.IcoDown};
      mask-image: ${SVGIcon.IcoDown};
    }
  }
`;

export const LoginInputBox = styled.div`
  height: 48px;
  position: relative;
  & + span {
    & + div {
      margin-top: 16px;
    }
  }
  &.error {
    input {
      border-bottom: 1px solid ${CommonBorder.Error};
    }
  }
`;

export const LoginInput = styled.input`
  width: 100%;
  font-size: 20px;
  height: 48px;
  padding: 0 10px;
  color: ${CommonText.Basic};
  background: ${CommonBG.Basic};
  outline: none;
  appearance: none;
  border-bottom: 1px solid ${CommonBorder.Strong};
  &::placeholder {
    font-size: 20px;
    color: ${CommonText.Lighter};
  }
  &::-moz-placeholder {
    font-size: 20px;
    opacity: 1;
    color: ${CommonText.Lighter};
  }
  &:-ms-input-placeholder {
    font-size: 20px;
    color: ${CommonText.Lighter};
  }
  &:focus,
  &:active {
    border-bottom: 1px solid ${CommonBorder.Stronger};
  }
  & + .MuiFormControl-root {
    position: absolute;
    top: 14px;
    right: 10px;
  }
`;

export const Message = styled.span`
  display: inline-block;
  margin-top: 8px;
  height: 18px;
  font-size: 12px;
  color: ${CommonText.Error};
`;

export const SelectWrap = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectBox = styled.div`
  width: calc((100% - 8px) / 2);
  height: 48px;
  border-radius: 2px;
  background: ${CommonBG.Elevation};
  border: 1px solid ${CommonBorder.Strong};
  vertical-align: top !important;
  position: relative;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: ${CommonText.Light};
    -webkit-mask: ${SVGIcon.IcoSelect};
    mask-image: ${SVGIcon.IcoSelect};
  }
  &:focus,
  &:active {
    border: 1px solid ${InputBorder.Focus};
  }
  &:active,
  &.active {
    ul {
      display: block;
    }
    &:before {
      transform: translateY(-50%) rotate(180deg);
      transition: all ease-out 0.2s;
    }
  }
`;

export const list = {
  ul: (active: boolean) => css`
    display: ${active ? 'block' : 'none'};
    position: absolute;
    top: 50px;
    left: -1px;
    right: -1px;
    z-index: 999;
    border: 1px solid ${CommonBorder.Strong};
    background: ${CommonBG.Elevation};
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.11);
    max-height: 176px;
    overflow: overlay;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(60, 62, 61, 0.4);
      width: 6px;
      border-radius: 3px;
    }
  `,
  li: (active: boolean) => css`
    width: 100% !important;
    height: 32px;
    padding-left: 6px;
    font-size: 13px;
    position: relative;
    display: flex;
    align-items: center;
    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 8px;
    }
    &:hover {
      cursor: pointer;
      background: ${TableBG.CellSelected};
    }
    ${active &&
    `
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 8px;
      right: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.5497 4.25058C14.7059 4.40679 14.7059 4.66006 14.5497 4.81627L7.42435 11.9416C6.87769 12.4883 5.99139 12.4883 5.44463 11.9418L1.4507 7.94924C1.29447 7.79305 1.29442 7.53979 1.4506 7.38355C1.60679 7.22731 1.86005 7.22727 2.01629 7.38345L6.01021 11.376C6.24454 11.6102 6.62438 11.6102 6.85867 11.3759L13.984 4.25058C14.1402 4.09437 14.3935 4.09437 14.5497 4.25058Z' fill='%23554596'/%3E%3C/svg%3E%0A");
    }
    `}
  `,
};

export const Selected = styled.input`
  cursor: pointer;
  border: none !important;
  pointer-events: none !important;
  width: 100%;
  height: 46px;
  padding: 0 10px !important;
  outline: none !important;
  font-size: 13px;
  line-height: initial;
  font-weight: 400;
  color: ${CommonText.Basic};
  &::-webkit-input-placeholder,
  &::-ms-input-placeholder {
    color: ${CommonText.Lighter};
    font-size: 13px;
    font-weight: 400;
  }
  &:read-only {
    background: ${InputBG.Disabled};
    pointer-events: none !important;
    color: ${CommonText.Basic};
  }
  &:disabled {
    background: ${InputBG.Disabled};
    pointer-events: none !important;
    color: ${CommonText.Lighter};
    & + ul {
      display: none !important;
    }
  }
`;

export const CheckWrap = styled.div`
  margin-top: 24px;
  .Mui-checked {
    border: 1px solid ${CommonBG.Secondary};
    background-color: ${CommonBG.Secondary};
  }
`;

export const LoginMenu = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: ${CommonText.Light};
  a {
    display: inline-block;
    padding: 5px 8px;
    border-radius: 2px;
    text-decoration: none !important;
    &:hover {
      background: ${ControlLinedBG.BasicHover};
    }
    &:focus,
    &:active {
      background: ${ControlLinedBG.BasicActive};
      color: ${CommonText.Basic};
    }
    &:disabled {
      opacity: 0.4;
    }
  }
`;

export const HelpDesk = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: ${CommonText.Lighter};
`;

export const FormStyle = styled(FormControl)`
  width: 20px;
  height: 20px;
  .MuiFormGroup-root {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .MuiFormGroup-root {
    width: 20px !important;
    height: 20px !important;
    position: absolute;
    top: 0;
    left: 0;
    .MuiFormControlLabel-root {
      margin: 0 !important;
      width: 20px;
      height: 20px;
      position: relative;
    }
  }
  .MuiButtonBase-root {
    width: 20px;
    height: 20px !important;
    padding: 0 !important;
    border-radius: 0px !important;
    top: 0;
    left: 0;
    margin: 0 !important;
    &:before {
      display: none;
    }
    .PrivateSwitchBase-input {
      height: 20px !important;
    }
  }
`;

const EyeIcon = styled('span')`
  padding: 0 !important;
  margin: 0 !important;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: ${CommonText.Light};
    -webkit-mask: ${SVGIcon.IcoEye};
    mask-image: ${SVGIcon.IcoEye};
  }
`;

const EyeCloseIcon = styled(EyeIcon)`
  width: 20px;
  height: 20px;
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: ${CommonText.Light};
    -webkit-mask: ${SVGIcon.IcoCloseEye};
    mask-image: ${SVGIcon.IcoCloseEye};
  }
`;

export function EyeCheckbox(props: CheckboxProps) {
  return <Checkbox checkedIcon={<EyeCloseIcon />} icon={<EyeIcon />} {...props} />;
}

export const EyeBtn = () => {
  return (
    <FormStyle>
      <EyeCheckbox />
    </FormStyle>
  );
};
