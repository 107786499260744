/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';
import useEvent from 'react-use-event-hook';
import * as wjInput from '@grapecity/wijmo.react.input';
import CustomTextarea from 'components/inputs/CustomTextarea';

import { ContentSection } from 'components/layouts/ContentSection';
import Typography from '@mui/material/Typography/Typography';
import { PopupBtnGroup } from 'components/layouts/Dialog';
import Button from '@mui/material/Button/Button';
import { IconButton } from 'components/buttons/IconSVG';
import { useTranslation } from 'react-i18next';
import { CrudCode } from 'models/common/Edit';

export interface Props {
  grid: any;
  hitTest: any;
  close: () => void;
  size?: 'sm' | 'md' | 'lg';
  textAreaByteMax?: number;
}

const WJCellTextarea = (props: Props) => {
  const { size = 'sm' } = props;
  const { textAreaByteMax = 2000 } = props;
  const { t } = useTranslation();
  const popupRef = useRef<any>();
  const [textAreaData, setTextAreaData] = useState<string>('');
  const [textAreaByte, setTextAreaByte] = useState<number>(0);

  useEffect(() => {
    const handleKeyEsc = (e) => {
      if (e.keyCode === 27) {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleKeyEsc);
    return () => {
      window.removeEventListener('keydown', handleKeyEsc);
    };
  }, [props]);

  const handleClose = () => {
    setTextAreaData('');
    setTextAreaByte(0);
    props.close();
    popupRef.current?.hide();
  };

  const changeData = (text, b?: number, i?: number, c?: number) => {
    text = text || '';
    for (
      b = 0, i = 0;
      (c = text.charCodeAt(i++)) && b < textAreaByteMax;
      b += c >> 11 ? 3 : c >> 7 ? 2 : 1
    );
    setTextAreaByte(b);
    setTextAreaData(text.slice(0, i - 1));
  };

  const handleOnChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    b?: number,
    i?: number,
    c?: number
  ) => {
    changeData(e.target.value, b, i, c);
  };

  const initPopup = useEvent((sender) => {
    popupRef.current = sender;
    popupRef.current.owner = props.grid.cells.getCellElement(props.hitTest.row, props.hitTest.col);

    // setTextAreaData(props.grid.getCellData(props.hitTest.row, props.hitTest.col));
    changeData(props.grid.getCellData(props.hitTest.row, props.hitTest.col));
    popupRef.current?.show(true);
  });

  useEffect(() => {
    // console.log('cellValue', textAreaData);
    setTextAreaData((textAreaData) => textAreaData || '');
  }, [textAreaData]);

  const onOkClick = useEvent(() => {
    props.grid.setCellData(props.hitTest.row, props.hitTest.col, textAreaData);
    // 셀 값 변경 시 상태 값 변경 (crudKey)
    const item = props.grid.rows[props.hitTest.row]?.dataItem;
    if (item.crudKey !== CrudCode.CREATE && item.crudKey !== CrudCode.DELETE) {
      item.crudKey = CrudCode.UPDATE;
    }
    handleClose();
  });

  const onCancleClick = () => {
    handleClose();
  };

  return (
    <wjInput.Popup initialized={initPopup} hideTrigger={'None'} className={size}>
      <ContentSection>
        <CustomTextarea value={textAreaData} onChange={handleOnChange} />
        <Typography fontSize="13px" fontWeight={400} align="right">
          {textAreaByte}/{textAreaByteMax} Bytes
        </Typography>
      </ContentSection>
      <PopupBtnGroup className="textarea_btn">
        <Button css={IconButton.button} className="cancel" disableRipple onClick={onCancleClick}>
          {t('com.button.취소', '취소')}
        </Button>
        <Button css={IconButton.button} className="confirm" disableRipple onClick={onOkClick}>
          {t('com.button.확인', '확인')}
        </Button>
      </PopupBtnGroup>
    </wjInput.Popup>
  );
};
WJCellTextarea.displayName = 'wjCellTextarea';
export default WJCellTextarea;
