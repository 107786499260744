/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { GlobalBtnGroup } from 'components/layouts/ContentLayout';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchButtonWrap,
  SearchCols,
  SearchRows,
} from 'components/layouts/SearchBox';
import MpTargetMasterGrid from './base/management/MpTargetMasterGrid';
import MpTargetDetailGrid from './base/management/MpTargetDetailGrid';
import { ComboBox } from 'components/selects/ComboBox';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames, getCommonCodeNamesCondition } from 'apis/admin/CommonCode';
import { useTranslation } from 'react-i18next';
import { MpTargetCondition, MpTargetLineCondition } from 'models/mp/MpTarget';
import CustomInputWithSearch from 'components/inputs/CustomInputWithSearch';
import { EquipmentGroupPopUp } from '../common/popup/EquipmentGroupPopUp';

const MpTargetManagementPage = () => {
  const { t } = useTranslation();
  const [code, setCode] = useState<any>();
  const [errors, setErrors] = useState<any>({});

  const detailRef = useRef<any>(null);
  const masterRef = useRef<any>(null);

  const [mpTargetCondition, setMpTargetCondition] = useState<MpTargetCondition>({});
  const mptargetLineConditonRef = useRef<MpTargetLineCondition>({});

  const [isOpenEquipmentGroupPopUp, setOpenEequipmentGroupPopUp] = useState<boolean>(false);

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  useEffect(() => {
    setMpTargetCondition({
      ...mpTargetCondition,
      factoryCode: '', // 공장(동)코드
    });

    if (mpTargetCondition.elmCopCd) {
      getCommonCodeNamesCondition({
        cmnGrCd: 'FACTORY_CODE',
        optValCtn1: mpTargetCondition.elmCopCd,
      }).then((result) => setCode({ ...code, factoryCode: result }));
    } else {
      setCode({ ...code, factoryCode: [] });
    }
  }, [mpTargetCondition.elmCopCd]);

  const masterlistner = useMemo(
    () => (condition) => {
      if (condition) {
        mptargetLineConditonRef.current = {
          idnNo: condition.idnNo,
          copCd: condition.copCd,
          plntCd: condition.plntCd,
          factoryNm: condition.factoryNm,
        };
        detailRef.current.search();
      }
    },
    []
  );

  const getCommonCodesForGrid = async () => {
    const mpClsfType: Code[] = await getCommonCodeNames('MP_CLSF_TYPE'); // MP분류
    const elmCopCd: Code[] = await getCommonCodeNames('ELM_COP_CD'); // 법인코드
    const ynFlag: Code[] = await getCommonCodeNames('YN_FLAG'); // 대상여부
    setCode({
      mpClsfType: mpClsfType,
      elmCopCd: elmCopCd,
      ynFlag: ynFlag,
    });
  };

  const handleParamConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setMpTargetCondition({
      ...mpTargetCondition,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: false });
  };

  const handleResetCondition = () => {
    setMpTargetCondition(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) => Object.assign(acc, { [cur]: '' }),
          {}
        ) as MpTargetCondition
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      detailRef.current.init();
      masterRef.current.search(mpTargetCondition, true);
    }
  };

  const handleSaveTarget = () => {
    masterRef.current.save();
  };

  const handleSaveTargetLine = () => {
    detailRef.current.save();
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>
                  <span className="dot">{t('mp.label.MP 분류', 'MP 분류')}</span>
                </label>
                <ComboBox
                  placeholder={String(
                    t('mp.msg.MP 유형을 선택해 주세요.', 'MP 유형을 선택해 주세요.')
                  )}
                  options={code?.mpClsfType}
                  defaultValue={mpTargetCondition?.mpClsfType}
                  isError={errors?.mpClsfType}
                  msgError={String(
                    t('mp.msg.MP 유형을 선택해 주세요.', 'MP 유형을 선택해 주세요.')
                  )}
                  onChange={(value) => {
                    setMpTargetCondition({ ...mpTargetCondition, mpClsfType: value });
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('mp.label.법인', '법인')}</label>
                <ComboBox
                  placeholder={String(t('mp.msg.법인을 선택해 주세요.', '법인을 선택해 주세요.'))}
                  options={code?.elmCopCd}
                  defaultValue={mpTargetCondition?.elmCopCd}
                  onChange={(value) => {
                    setMpTargetCondition({ ...mpTargetCondition, elmCopCd: value as string });
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('mp.label.공장(동)', '공장(동)')}</label>
                <ComboBox
                  placeholder={String(
                    t('mp.msg.공장(동)을 선택해 주세요.', '공장(동)을 선택해 주세요.')
                  )}
                  options={code?.factoryCode}
                  defaultValue={mpTargetCondition?.factoryCode}
                  onChange={(value) => {
                    setMpTargetCondition({ ...mpTargetCondition, factoryCode: value as string });
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols>
                <label>{t('com.label.설비군', '설비군')}</label>
                <CustomInputWithSearch
                  name="equipmentGroup"
                  className="find"
                  placeholder={String(
                    t('mp.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.')
                  )}
                  value={mpTargetCondition.equipmentGroup}
                  onChange={handleParamConditionChange}
                  onSearchClick={() => setOpenEequipmentGroupPopUp(true)}
                  isError={errors?.equipmentGroup}
                  msgError={String(t('mp.msg.설비군을 선택해 주세요.', '설비군을 선택해 주세요.'))}
                  onKeyDown={handleKeyDown}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('mp.label.대상 여부', '대상 여부')}</label>
                <ComboBox
                  placeholder={String(
                    t('mp.msg.대상 여부를 선택해 주세요.', '대상 여부를 선택해 주세요.')
                  )}
                  options={code?.ynFlag}
                  defaultValue={mpTargetCondition?.ynFlag}
                  onChange={(value) => {
                    setMpTargetCondition({ ...mpTargetCondition, ynFlag: value as string });
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleResetCondition}
              disableRipple
            ></Button>
            <Button
              css={IconButton.button}
              className="find"
              onClick={() => {
                detailRef.current.init();
                masterRef.current.search(mpTargetCondition);
              }}
              disableRipple
            >
              {t('com.button.조회', '조회')}
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>

      <MpTargetMasterGrid
        ref={masterRef}
        condition={mpTargetCondition}
        setErrors={setErrors}
        callback={masterlistner}
      />
      <GlobalBtnGroup>
        <Button css={IconButton.button} className="save" onClick={handleSaveTarget}>
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>

      {isOpenEquipmentGroupPopUp && (
        <EquipmentGroupPopUp
          open={isOpenEquipmentGroupPopUp}
          close={() => setOpenEequipmentGroupPopUp(false)}
          type={'mp'}
          condition={{
            equipmentGroup: mpTargetCondition.equipmentGroup || '',
          }}
          onCallback={(value) =>
            setMpTargetCondition({
              ...mpTargetCondition,
              equipmentGroup: (value as MpTargetCondition).equipmentGroup,
            })
          }
        />
      )}

      <MpTargetDetailGrid
        ref={detailRef}
        conditionRef={mptargetLineConditonRef}
        condition={{ copCd: mpTargetCondition.elmCopCd, plntCd: mpTargetCondition.factoryCode }}
      />

      <GlobalBtnGroup>
        <Button css={IconButton.button} className="save" onClick={handleSaveTargetLine}>
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>
    </>
  );
};

export default MpTargetManagementPage;
