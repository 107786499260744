/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import CustomGrid from 'components/grids/CustomGrid';
import { findEquipmentParameterRequestList } from 'apis/ip/EquipmentParameter';
import {
  ParameterApprovalRequest,
  EquipmentParameterCondition,
} from 'models/ip/EquipmentParameter';
import { IconButton } from 'components/buttons/IconSVG';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { ManagementMode } from 'models/common/Common';
import ParameterManagementModal from 'components/modals/ip/ParameterManagementModal';

/**
 * IP 기준정보 작성중 화면
 * @constructor
 */
const EquipmentParameterRequestPage = () => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<ParameterApprovalRequest[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [requestCondition, setRequestCondition] = useState<EquipmentParameterCondition>({});
  const [isOpenParameterManagementModal, setOpenParameterManagementModal] =
    useState<boolean>(false);
  const [mode, setMode] = useState<ManagementMode>(ManagementMode.CREATE);
  const [requestModalCondition, setRequestModalCondition] = useState<any>();

  useEffect(() => {
    handleSearch(requestCondition);
  }, []);

  const handleSearch = async (condition: EquipmentParameterCondition) => {
    const response = await findEquipmentParameterRequestList(condition);
    if (response) {
      setRowData(response.list || []);
      setTotalCount(response.totalCount || 0);
    }
  };

  const handleOpenRequest = (aprReqId: string, mode: ManagementMode) => {
    if (!aprReqId) return;
    setMode(mode);
    setRequestModalCondition({
      aprReqId: aprReqId,
    });
    setOpenParameterManagementModal(true);
  };

  const layoutDefinition = [
    {
      binding: 'aprReqId',
      header: String(t('com.label.요청 ID', '요청 ID')),
      width: 220,
      align: 'center',
    },
    {
      binding: 'aprTplId',
      header: String(t('ip.grid.결재양식ID', '결재양식ID')),
      width: 150,
      align: 'center',
    },
    {
      binding: 'aprReqTitNm',
      header: String(t('com.label.요청제목', '요청제목')),
      width: '*',
      cssClass: 'WijmoPopup',
      cellTemplate: (params) => `${params.item.aprReqTitNm || ''}<Button />`,
    },
    {
      binding: 'aprReqProgStatCdNm',
      header: String(t('com.label.진행상태', '진행상태')),
      width: 150,
      align: 'center',
    },
    {
      binding: 'dataUpdDtm',
      header: String(t('com.label.수정일', '수정일')),
      width: 200,
      align: 'center',
    },
  ];

  const onInitialized = (grid) => {
    grid?.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const item = grid.rows[ht.row].dataItem;
        // 요청 제목 클릭 시 팝업 열기
        if (grid.columns[ht.col].binding === 'aprReqTitNm') {
          if (item.aprReqId && item.mode) {
            handleOpenRequest(item.aprReqId, item.mode);
          }
        }
      }
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.작성중인 문서목록', '작성중인 문서목록')}</h3>
          <span className="total">
            {t('com.label.총', '총')} <span>{totalCount.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => handleSearch(requestCondition)}
            disableRipple
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        isReadOnly={true}
        isSelector={false}
        initialized={onInitialized}
        height={600}
        allowPinning={false}
      />
      {isOpenParameterManagementModal && (
        <ParameterManagementModal
          open={isOpenParameterManagementModal}
          mode={mode}
          condition={requestModalCondition}
          close={(isDone = false) => {
            if (isDone) {
              handleSearch(requestCondition);
            }
            setOpenParameterManagementModal(false);
          }}
        />
      )}
    </>
  );
};
export default EquipmentParameterRequestPage;
