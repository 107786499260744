import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translation_en from 'locales/locale_en.json';
import translation_ko from 'locales/locale_ko.json';
import translation_zh from 'locales/locale_zh.json';
import translation_id from 'locales/locale_id.json';
import translation_pl from 'locales/locale_pl.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: translation_en },
      ko: { translation: translation_ko },
      zh: { translation: translation_zh },
      id: { translation: translation_id },
      pl: { translation: translation_pl },
    },
    fallbackLng: 'ko',
    defaultNS: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
