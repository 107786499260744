import styled from '@emotion/styled';
import { CommonBG, CommonBorder, ControlFilldBG, CommonText } from 'ui/theme/Color';
import { SVGIcon } from 'components/buttons/IconSVG';

export const ProcessBarWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  position: relative;
  width: 100%;
  height: 40px;
  &.mt24 {
    margin-top: 24px;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 3px;
  background: ${CommonBorder.Stronger};
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 2px;
    height: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: ${CommonBorder.Stronger};
  }
  &:after {
    content: '';
    clear: both;
    display: inline-block;
    width: 2px;
    height: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background: ${CommonBorder.Stronger};
  }
`;

export const ProcessStepBox = styled.div`
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  z-index: 2;
  &:first-child {
    &:before {
      opacity: 0;
    }
  }
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    width: 50%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    background: ${CommonBG.Basic};
    opacity: 0.6;
  }
  &:after {
    content: '';
    clear: both;
    display: inline-block;
    width: 50%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 50%;
    background: ${CommonBG.Basic};
    opacity: 0.6;
  }
  &:nth-last-of-type(2) {
    &.done {
      & + div {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  &.done {
    &:after {
      opacity: 0;
    }
    & > div {
      span {
        background: ${ControlFilldBG.BasicActive};
        color: ${ControlFilldBG.BasicActive};
        border: none;
        position: relative;
        &:before {
          content: '';
          clear: both;
          display: inline-block;
          width: 12px;
          height: 12px;
          position: absolute;
          top: calc(50% - 6px);
          left: calc(50% - 6px);
          background: ${CommonText.Inverse};
          -webkit-mask: ${SVGIcon.IcoCheck};
          mask-image: ${SVGIcon.IcoCheck};
        }
      }
    }
    & + div {
      &:before {
        opacity: 0;
      }
    }
  }
  &.now {
    & > div {
      span {
        background: ${CommonBG.Primary};
        position: relative;
        border: none;
        color: ${CommonText.Inverse};
      }
      div {
        color: ${CommonBG.Primary};
      }
    }
  }
  &.disable {
    & > div {
      span {
        border: 1px solid ${CommonBorder.Strong};
        color: ${CommonText.Light};
      }
      div {
        pointer-events: none;
        color: ${CommonText.Light};
      }
    }
  }
`;

export const StepBox = styled.div`
  padding: 0 11px;
  z-index: 3;
  background: ${CommonBG.Basic};
`;

export const ProcessStep = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
`;

export const ProcessNum = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid ${CommonBorder.Strong};
  color: ${CommonText.Light};
`;

export const ProcessText = styled.div`
  padding-left: 8px;
  font-size: 15px;
  line-height: 1.5;
  font-weight: 700;
  white-space: nowrap;
`;

export const SubStep = styled.ol`
  display: inline-flex;
  margin-left: 12px;
  height: 39px;
  align-items: center;
  padding: 0px 12px;
  background: ${CommonBG.Deeper};
  li {
    list-style: decimal;
    font-size: 15px;
    font-weight: 700;
    margin: 8px 36px 8px 18px;
    position: relative;
    color: ${CommonText.Lighter};
    &:after {
      content: '';
      clear: both;
      display: block;
      width: 36px;
      height: 39px;
      position: absolute;
      right: -36px;
      top: -8px;
      background: ${CommonBorder.Stronger};
      -webkit-mask: ${SVGIcon.IcoLineChevronDouble};
      mask-image: ${SVGIcon.IcoLineChevronDouble};
      mask-position: center center;
      mask-size: 18px 20px;
      mask-repeat: no-repeat;
    }
    &:last-child {
      margin: 8px 0px 8px 20px;
      &:after {
        contetn: '';
        display: none;
      }
    }
    &.now {
      color: ${CommonText.Primary};
    }
    &.done {
      color: ${CommonText.Light};
    }
  }
  &.subPer {
    list-style: none;
    background: none;
    position: relative;
    li {
      position: static;
      list-style: none;
      margin-left: 0;
      margin-right: 0;
      strong {
        margin-right: 6px;
      }
      > span {
        font-size: 13px;
        color: ${CommonText.Light};
        background: none;
      }
      > div {
        position: absolute;
        top: 0;
        left: 4px;
        right: 4px;
        background: ${CommonBG.Deeper};
        z-index: -1;
        > span {
          display: block;
          height: 38px;
          background: ${ControlFilldBG.PrimaryHover};
          opacity: 0.2;
        }
      }
      &:after {
        height: 5px;
        background: none;
      }
      &:first-child {
        color: ${CommonText.Primary};
        border-radius: 50%;
        padding: 4px 4px 4px 0;
      }
      &:nth-child(2) {
        padding-left: 2px;
        font-weight: normal;
        color: ${CommonText.Light};
        em {
          font-weight: bold;
          color: ${CommonText.Primary};
        }
      }
      &:nth-child(3) {
        padding-left: 12px;
        color: ${CommonText.Error};
      }
    }
  }
`;
