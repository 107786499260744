/** @jsxImportSource @emotion/react */
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { CrudCode } from 'models/common/Edit';
import { ParameterMasterRequest } from 'models/ip/EquipmentParameter';
import { EquipmentGroupPopUp } from 'pages/common/popup/EquipmentGroupPopUp';
import { EquipmentGroupCondition } from 'models/common/popup/EquipmentGroup';
import { ManagementMode } from 'models/common/Common';
import { getEquipmentParameterRecentVersion } from 'apis/ip/EquipmentParameter';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import CustomGrid from 'components/grids/CustomGrid';
import { useMessageBar } from 'hooks/useMessageBar';
import useEvent from 'react-use-event-hook';
import { version } from 'os';

interface Props {
  rowData: ParameterMasterRequest[];
  mode?: ManagementMode;
  aprReqProgStatCd: string;
  onChange: (ParameterMasterRequest) => void;
  versionNoUp: string;
}

interface TargetCondition {
  row: number;
  col: number;
}
const ParameterMasterGrid = (
  { rowData, mode = ManagementMode.READ, aprReqProgStatCd, onChange, versionNoUp }: Props,
  ref
) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const gridRef = useRef<any>();
  const [isOpenEquipmentGroupPopup, setOpenEquipmentGroupPopup] = useState<boolean>(false);
  const [targetCondition, setTargetCondition] = useState<TargetCondition>();
  const editable = useMemo(() => mode !== ManagementMode.DELETE, [mode]);
  const readable = useMemo(() => mode !== ManagementMode.READ, [mode]);
  const isReadOnly =
    (mode === ManagementMode.CREATE && aprReqProgStatCd === 'TMP') ||
    (mode === ManagementMode.MODIFY && aprReqProgStatCd === 'TMP') ||
    (mode === ManagementMode.READ && aprReqProgStatCd === 'TMP');

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (!editable) {
        return true;
      }
      const valid = (rowData || [])
        .filter((o) => o.crudKey !== CrudCode.DELETE)
        .map((o, index) => {
          if (_.isEmpty(o.equipmentGroup)) {
            return (
              `${index + 1} : ` +
              t('ip.msg.설비군을 입력해 주세요.', `설비군을 입력해 주세요.`) +
              '\n'
            );
          }
          if (_.isEmpty(o.eqpParaNo)) {
            return (
              `${index + 1} : ` +
              t('ip.msg.설비파라미터번호를 입력해 주세요.', `설비파라미터번호을 입력해 주세요.`) +
              '\n'
            );
          }
          if (_.isEmpty(o.eqpParaName)) {
            return (
              `${index + 1} : ` +
              t('ip.msg.설비파라미터명을 입력해 주세요.', `설비파라미터명을 입력해 주세요.`) +
              '\n'
            );
          }
        })
        .filter((element) => element !== undefined);

      if (valid.length) {
        openMessageBar({ type: 'error', content: valid.toString() });
        return false;
      }
      return true;
    },
  }));

  const layoutDefinition = [
    {
      binding: 'crudKey',
      header: String(t('com.label.상태', '상태')),
      width: 45,
      align: 'center',
      isReadOnly: true,
      cellTemplate: GridStatusCellTemplate,
      visible: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP' ? false : true,
    },
    {
      binding: 'equipmentGroup',
      header: String(t('com.label.설비군', '설비군')),
      width: 100,
      align: ManagementMode.CREATE === mode ? 'left' : 'center',
      isReadOnly: true,
      cssClass: ManagementMode.CREATE === mode ? 'WijmoFind' : '',
      cellTemplate: (params) => `
        <span>${params.value || ''}</span>
        ${ManagementMode.CREATE === mode ? '<Button />' : ''} 
      `,
    },
    {
      binding: 'eqpParaNo',
      header: String(t('ip.grid.설비파라미터번호', '설비파라미터번호')),
      width: 200,
      align: 'center',
      isReadOnly: ManagementMode.CREATE !== mode,
    },
    {
      binding: 'eqpParaName',
      header: String(t('ip.grid.설비파라미터명', '설비파라미터명')),
      width: 200,
      align: 'left',
      // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
      isReadOnly: !isReadOnly,
    },
    {
      binding: 'versionNo',
      header: String(t('ip.grid.버전', '버전')),
      width: 100,
      align: 'center',
      isReadOnly: true,
    },
    {
      binding: 'detail',
      header: String(t('ip.grid.IP List 조회', 'IP List 조회')),
      width: 90,
      align: 'center',
      isReadOnly: true,
      cssClass: 'WijmoPlay',
      cellTemplate: '<Button />',
    },
    {
      binding: 'eqpParaDesc',
      header: String(t('ip.grid.설비 파라미터 설명', '설비 파라미터 설명')),
      width: '*',
      align: 'left',
      // isReadOnly: (!editable || !readable) && aprReqProgStatCd !== 'TMP',
      // isReadOnly: mode === ManagementMode.READ && aprReqProgStatCd !== 'TMP',
      isReadOnly: !isReadOnly,
    },
    {
      binding: 'reqSeqId',
      width: 40,
      visible: false,
    },
    {
      binding: 'reqType',
      width: 40,
      visible: false,
    },
  ];

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    const column = panel.columns[col];
    const binding = column.binding || '';
    if (rowData.length > 0 && mode === ManagementMode.MODIFY && versionNoUp !== undefined) {
      panel.rows.forEach((r) => {
        if (r.dataItem !== null) {
          r.dataItem.versionNo = (Number(versionNoUp) + 0.1).toFixed(1).toString();
        }
      });
    }
  });

  const onInitialized = (grid) => {
    gridRef.current = grid;

    grid?.hostElement.addEventListener('click', (e) => {
      if (grid.rows.length == 0) return;
      grid.startEditing(true);

      const ht = grid.hitTest(e);
      if (ht.row < 0 || ht.col < 0) return;

      if (ht.panel === grid.cells) {
        const binding = grid.columns[ht.col].binding;
        // 설비군 클릭 이벤트
        if ('equipmentGroup' === binding) {
          // 신규인 경우에만 설비군 변경 가능 (엑셀신규 변경 불가)
          if (ManagementMode.CREATE === mode) {
            setOpenEquipmentGroupPopup(true);
            setTargetCondition({
              row: ht.row,
              col: ht.col,
            });
          }
        }
        // 상세 버튼 클릭 이벤트
        if ('detail' === binding) {
          const item = grid.rows[ht.row].dataItem;
          onChange(item);
        }
      }
    });
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ip.label.IP Master', 'IP Master')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>
              {(rowData || []).filter((o) => o.crudKey !== CrudCode.DELETE).length.toLocaleString()}
            </span>
            {t('com.label.건', '건')}
          </span>
          <div className="info warning">
            {t(
              'ip.label.설비 파라미터명은 OOO설비 OOO세대 IP List 형식으로 작성해 주세요.',
              '설비 파라미터명은 OOO설비 OOO세대 IP List 형식으로 작성해 주세요.'
            )}
          </div>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={100}
        isSelector={false}
        excludeFilter={['versionNo', 'eqpParaDesc', 'detail']}
        itemFormatter={onItemFormatter}
        cellEditEnded={(grid, e) => {
          const binding = grid.columns[e.col].binding;
          const value = grid.getCellData(e.row, e.col);
          // 설비파라미터번호 변경한 경우
          if ('eqpParaNo' === binding && value) {
            const data = grid.rows[e.row].dataItem;
            if (data.equipmentGroup) {
              onChange({
                equipmentGroup: data.equipmentGroup,
                eqpParaNo: value,
                versionNo: 1,
              });
            }
          }
        }}
        initialized={onInitialized}
      />

      {isOpenEquipmentGroupPopup && (
        <EquipmentGroupPopUp
          open={isOpenEquipmentGroupPopup}
          close={() => setOpenEquipmentGroupPopup(false)}
          condition={{
            equipmentGroup: '',
          }}
          onCallback={(value) => {
            const equipmentGroup = (value as EquipmentGroupCondition).equipmentGroup;
            gridRef.current.setCellData(targetCondition?.row, targetCondition?.col, equipmentGroup);

            // 설비군 변경한 경우
            if (typeof targetCondition?.row === 'number') {
              const data = gridRef.current.rows[targetCondition?.row].dataItem;
              data.versionNo = 1;
              if (data.eqpParaNo) {
                onChange({
                  equipmentGroup: equipmentGroup,
                  eqpParaNo: data.eqpParaNo,
                  versionNo: 1,
                });
                /*
                // [23.11.06] 설비파라미터번호 자동채번에서 직접입력으로 변경됨
                generateEquipmentParameterNo(equipmentGroup).then((result) => {
                  data.eqpParaNo = result.eqpParaNo;
                  data.versionNo = 1;
                  onChange({
                    equipmentGroup: equipmentGroup,
                    eqpParaNo: result.eqpParaNo,
                    versionNo: 1,
                  });
                });
                */
              }
            }
          }}
        />
      )}
    </>
  );
};

export default React.forwardRef(ParameterMasterGrid);
