/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext } from 'react';

import { ContainerLayout } from 'components/layouts/ContainerLayout';
import { useTranslation } from 'react-i18next';
import {
  approveApprovalRequestResult,
  cancelApprovalRequestResult,
  findApproveRequestDetail,
  rejectApprovalRequestResult,
} from 'apis/approves/approves';
import { useLoading } from 'components/process/Loading';
import ApproveDetailLineInfo from './ApproveDetailLineInfo';
import ApproveDetailBasicInfo from './ApproveDetailBasicInfo';
import {
  ApprovalAppd,
  ApproveRequestDetail,
  clearApprovalAppd,
  clearApproveRequestDetail,
} from 'models/approves/ReferenceOpinion';
import useSessionStore from 'stores/useSessionStore';
import ApproveModal from 'components/modals/approves/ApproveModal';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ApproveRequestDetailCase, { ApproveRequestPageType } from './ApproveRequestDetailCase';
import { GlobalBtnGroup } from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { MenuContext } from 'App';
import { MenuContextType } from 'models/admin/Menu';
import { ManagementMode } from 'models/common/Common';

interface LocationState {
  upprMnuUrl?: string;
}

const ApproveRequestDetailPage = () => {
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const { userId } = useSessionStore();
  const [approveInfo, setApproveInfo] = useState<ApproveRequestDetail>(clearApproveRequestDetail);
  const [waitApprovalAppd, setWaitApprovalAppd] = useState<ApprovalAppd>(clearApprovalAppd);
  const [isApprover, setIsApprover] = useState<boolean>(false);
  const [approveModalOpen, setApproveModalOpen] = useState<boolean | 'APPROVE' | 'REJECT'>(false);
  const navigate = useNavigate();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const locationState: LocationState = useLocation().state;
  const [isLocationState, setLocationState] = useState<LocationState>({
    upprMnuUrl: locationState?.upprMnuUrl,
  });
  const [paramDatail, setParamDetail] = useState<any>({
    pageSeqId: '',
    pageId: '',
    aprReqId: '',
    mode: '',
  });

  const [searchParams] = useSearchParams();
  const [searchCondition, setSearchCondition] = useState({
    aprReqId: searchParams?.get('aprReqId') || '',
    appintUrl: searchParams?.get('appint_url') || '',
    rtnUrl: searchParams?.get('rtnUrl') || '',
    isShowMenu: searchParams?.get('showMenu') === 'N' ? false : true,
  });

  const init = async () => {
    if (!searchCondition.aprReqId) {
      openMessageBar({
        type: 'error',
        content: t('com.label.잘못된 접근입니다.', '잘못된 접근입니다.'),
      });
      return;
    }

    openLoading(true);
    const response = await findApproveRequestDetail(searchCondition.aprReqId);
    openLoading(false);
    console.log('response', response);
    setApproveInfo(response);
    const nowApprove = response.approvalAppd?.find((item) => item.aprPsgStatCd == 'WAIT');
    if (nowApprove && nowApprove.aprAprvUserId === userId) {
      setWaitApprovalAppd(nowApprove);
      setIsApprover(true);
    }
  };

  useEffect(() => {
    if (searchParams?.get('aprReqId')) {
      setSearchCondition({
        aprReqId: searchParams?.get('aprReqId') || '',
        appintUrl: searchParams?.get('appint_url') || '',
        rtnUrl: searchParams?.get('rtnUrl') || '',
        isShowMenu: searchParams?.get('showMenu') === 'N' ? false : true,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    init();
  }, [searchCondition.aprReqId]);

  const navigateToListPage = () => {
    if (isLocationState.upprMnuUrl) menuContext.closeCurrentTab(menuContext);
    navigate(searchCondition.rtnUrl || '/approves/approve-management-page');
  };

  const handleApprove = async (opinion: string) => {
    openLoading(true);
    const response = await approveApprovalRequestResult({
      ...approveInfo,
      resultApprovalOpinion: opinion,
      resultAprLnSnb: waitApprovalAppd?.aprLnSnb ?? '',
    });
    openLoading(false);

    if (response.successOrNot === 'Y') {
      openMessageBar({
        type: 'confirm',
        content: t('com.label.승인되었습니다.', '승인되었습니다.'),
      });
      //전자결재 화면에서 접근했을 경우 appint_url 값으로 이동
      if (searchCondition.appintUrl) {
        window.location.href = searchCondition.appintUrl;
      } else {
        navigateToListPage();
      }
    } else {
      openMessageBar({
        type: 'error',
        content:
          typeof response.data === 'string'
            ? response.data
            : t('com.label.요청에 실패했습니다.', '요청에 실패했습니다.'),
      });
    }
  };

  const handleRejectApprove = async (opinion: string) => {
    openLoading(true);
    const response = await rejectApprovalRequestResult({
      ...approveInfo,
      resultApprovalOpinion: opinion,
      resultAprLnSnb: waitApprovalAppd?.aprLnSnb ?? '',
    });

    openLoading(false);
    if (response.successOrNot === 'Y') {
      openMessageBar({
        type: 'confirm',
        content: t('com.msg.반려 완료되었습니다.', '반려 완료되었습니다.'),
      });
      // 전자결재 화면에서 접근했을 경우 appint_url 값으로 이동
      if (searchCondition.appintUrl) {
        window.location.href = searchCondition.appintUrl;
      } else {
        navigateToListPage();
      }
    } else {
      openMessageBar({
        type: 'error',
        content:
          typeof response.data === 'string'
            ? response.data
            : t('com.label.요청에 실패했습니다.', '요청에 실패했습니다.'),
      });
    }
  };

  const handleCancelApprove = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t(`com.msg.결재취소하시겠습니까?`, `결재취소하시겠습니까?`),
      yesCallback: async () => {
        openLoading(true);
        const response = await cancelApprovalRequestResult(approveInfo.aprReqId);
        openLoading(false);

        if (response.successOrNot === 'Y') {
          openMessageBar({
            type: 'confirm',
            content: t('com.msg.결재취소되었습니다.', '결재취소되었습니다.'),
          });
          navigateToListPage();
        } else {
          openMessageBar({
            type: 'error',
            content:
              typeof response.data === 'string'
                ? response.data
                : t('com.msg.오류가 발생하였습니다.', '오류가 발생하였습니다.'),
          });
        }
      },
    });
  };

  const drawButtons = () => {
    return (
      <>
        {!searchCondition.appintUrl && searchCondition.isShowMenu && (
          <Button
            css={IconButton.button}
            className="list"
            disableRipple
            onClick={navigateToListPage}
          >
            {t('com.button.목록', '목록')}
          </Button>
        )}

        {['REQ'].includes(approveInfo.aprReqProgStatCd) && approveInfo.aprReqUserId === userId && (
          <Button
            css={IconButton.button}
            className="cancel"
            disableRipple
            onClick={handleCancelApprove}
          >
            {t('com.button.상신취소', '상신취소')}
          </Button>
        )}

        {['REQ', 'INP'].includes(approveInfo.aprReqProgStatCd) && isApprover && (
          <>
            <Button
              css={IconButton.button}
              className="cancel"
              onClick={() => {
                setApproveModalOpen('REJECT');
              }}
              disableRipple
            >
              {t('com.button.반려', '반려')}
            </Button>

            <Button
              css={IconButton.button}
              className="ok"
              onClick={() => {
                setApproveModalOpen('APPROVE');
              }}
              disableRipple
            >
              {t('com.button.승인', '승인')}
            </Button>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <ContainerLayout>
        <GlobalBtnGroup className="top">{drawButtons()}</GlobalBtnGroup>

        <ApproveDetailBasicInfo approveInfo={approveInfo} />

        {approveInfo?.optValCtn1 && (
          <ApproveRequestDetailCase
            pageId={approveInfo.optValCtn1}
            condition={approveInfo.condition}
            isReadOnly={true}
            aprReqId={searchCondition.aprReqId}
          />
        )}

        <ApproveDetailLineInfo
          approvalAppd={approveInfo.approvalAppd || []}
          approvalInfr={approveInfo.approvalInfr || []}
        />

        <GlobalBtnGroup>{drawButtons()}</GlobalBtnGroup>
      </ContainerLayout>

      {approveModalOpen && (
        <ApproveModal
          open={approveModalOpen}
          title={t('com.label.결재의견', '결재의견')}
          approvalType={'0'}
          close={() => setApproveModalOpen(false)}
          approveCallback={(msg) => {
            setApproveModalOpen(false);
            handleApprove(msg);
          }}
          rejectCallback={(msg) => {
            setApproveModalOpen(false);
            handleRejectApprove(msg);
          }}
        />
      )}
    </>
  );
};

export default ApproveRequestDetailPage;
