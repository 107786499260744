import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ExUpload } from 'components/buttons/CustomButton';
import { useCommonModal } from 'hooks/useCommonModal';

interface Props {
  msgConfirm?: string; // confirm 문구 (문구 있는 경우 파일브라우저 전 confirm 노출)
  onCallback: (files: FileList | null) => void;
}
const ExcelUploadButton = ({ msgConfirm, onCallback }: Props) => {
  const inputRef = useRef<any>();
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.currentTarget.files?.[0]) {
      onCallback(event.currentTarget.files);
      event.currentTarget.value = '';
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (msgConfirm) {
      openCommonModal({
        modalType: 'confirm',
        content: msgConfirm,
        yesCallback: () => {
          inputRef.current?.click();
        },
      });
    } else {
      inputRef.current?.click();
    }
  };

  return (
    <>
      <ExUpload onClick={handleClick}>
        <label htmlFor="uploadFile">{t('com.label.업로드', '업로드')}</label>
      </ExUpload>
      <input
        ref={inputRef}
        type="file"
        id="uploadFile"
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default ExcelUploadButton;
