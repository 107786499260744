/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useMemo, useRef, useImperativeHandle } from 'react';
import useEvent from 'react-use-event-hook';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button } from '@mui/material';
import { DataType, addClass } from '@grapecity/wijmo';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import {
  SubTitleLayout,
  SubTitleGroup,
  GlobalBtnGroup,
  ControlBtnGroup,
} from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';
import { IconButton } from 'components/buttons/IconSVG';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { commonYNcodes } from 'models/common/Common';
import { downloadExcelTemplates } from 'apis/common/Excel';
import { findUtMatrix, saveUtMatrix } from 'apis/ut/UtMatrixRegist';
import { useLoading } from 'components/process/Loading';
import { useCommonModal } from 'hooks/useCommonModal';
import { useMessageBar } from 'hooks/useMessageBar';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { calculateDmdElpwCapa } from 'utils/UtMatrixUtil';
import { CommonUtil } from '../../../utils/CommonUtil';

const DetailSummaryContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const { t } = useTranslation();
  const { openLoading } = useLoading();
  const { openCommonModal } = useCommonModal();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const [code, setCode] = useState<any>();

  const isAprText = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP05');

    return condition.utmWkProgStatCd === 'UTM04' && p.length > 0
      ? String(t('com.button.결재정보', '결재정보'))
      : String(t('com.button.결재요청', '결재요청'));
  }, [processData, condition.utmWkProgStatCd]);

  //검토중 <재작성요청><보류><검증><임시저장><결재요청>
  const isUtReview = useMemo(() => {
    return condition.utmWkProgStatCd === 'UTM04' && condition.openMode !== 'appr';
  }, [condition.utmWkProgStatCd]);

  // 작성완료 : <검토진행> <재작성요청> <보류>
  const isUtComplete = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP05');
    return condition.utmWkProgStatCd === 'UTM03' && p.length === 0;
  }, [processData, condition.utmWkProgStatCd]);

  // 작성중 : <검증> <임시저장>
  const isUtWriter = useMemo(() => {
    return condition.utmWkProgStatCd === 'UTM02'; //작성중
  }, [processData, condition.utmWkProgStatCd]);

  useImperativeHandle(ref, () => ({
    searchRegistMatrix: (params) => {
      searchRegistMatrix(params);
    },
    saveRegistMatrix: (showMsg = true) => {
      return new Promise((resolve) => {
        handleSave(showMsg).finally(() => {
          resolve(true);
        });
      });
    },
  }));

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  const getCommonCodesForGrid = async () => {
    const bldgFloCd: Code[] = await getCommonCodeNames('BLDG_FLO_CD');
    const utVltgList: Code[] = await getCommonCodeNames('UT_VLTG_LIST');
    const elpwPhasCd: Code[] = await getCommonCodeNames('PSN_PHAS_CD'); // TODO 컬럼명 변경됨 (elpwPhasCd)
    const ctwTpCd: Code[] = await getCommonCodeNames('CTW_TP_CD');
    const utFrqList: Code[] = await getCommonCodeNames('UT_FRQ_LIST');
    const utPwftList: Code[] = await getCommonCodeNames('UT_PWFT_LIST');
    const elpwEqpCnctTpCd: Code[] = await getCommonCodeNames('PSN_CNCT_TP_CD'); // TODO 컬럼명 변경됨 (elpwEqpCnctTpCd)
    const devcCnctTpCd: Code[] = await getCommonCodeNames('DEVC_CNCT_TP_CD');
    const cdaPresList: Code[] = await getCommonCodeNames('CDA_PRES_LIST');
    const n2PresList: Code[] = await getCommonCodeNames('N2_PRES_LIST');
    const exhaCnctTpCd: Code[] = await getCommonCodeNames('EXHA_CNCT_TP_CD');
    const exhaCllrTpCd: Code[] = await getCommonCodeNames('EXHA_CLLR_TP_CD');
    // const utQtyList: Code[] = await getCommonCodeNames('UT_QTY_LIST');
    const utBrkeCapaList: Code[] = await getCommonCodeNames('UT_BRKE_CAPA_LIST');
    const suarCllrTpCd: Code[] = await getCommonCodeNames('SUAR_CLLR_TP_CD');

    setCode({
      bldgFloCd: bldgFloCd,
      utVltgList: utVltgList,
      elpwPhasCd: elpwPhasCd,
      ctwTpCd: ctwTpCd,
      utFrqList: utFrqList,
      utPwftList: utPwftList,
      elpwEqpCnctTpCd: elpwEqpCnctTpCd,
      devcCnctTpCd: devcCnctTpCd,
      cdaPresList: cdaPresList,
      n2PresList: n2PresList,
      exhaCnctTpCd: exhaCnctTpCd,
      exhaCllrTpCd: exhaCllrTpCd,
      utBrkeCapaList: utBrkeCapaList,
      suarCllrTpCd: suarCllrTpCd,
    });
  };

  const layoutDefinition = useMemo(() => {
    return [
      {
        binding: 'utmSeq',
        header: String(t('com.label.NO', 'NO')),
        width: 40,
        isReadOnly: true,
        align: 'center',
        // cellTemplate: (grid) => grid.row._idx + 1,
      },
      {
        header: String(t('com.label.상태', '상태')),
        binding: 'crudKey',
        width: 40,
        isReadOnly: true,
        align: 'center',
        cellTemplate: GridStatusCellTemplate,
      },
      {
        binding: 'prdnProcCdNm',
        header: String(t('ut.label.공정', '공정')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'dtalProcCdNm',
        header: String(t('ut.label.세부공정', '세부공정')),
        width: 110,
        isReadOnly: true,
      },
      {
        header: String(t('ut.label.Facility Location', 'Facility Location')),
        columns: [
          {
            binding: 'bldgFloCd',
            header: String(t('ut.label.Floor', 'Floor')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.bldgFloCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'istlLocNm',
            header: String(t('ut.label.Room', 'Room')),
            width: 100,
            isReadOnly: true,
          },
        ],
      },
      {
        binding: 'prdnLnNm',
        header: String(t('ut.label.Line', 'Line')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'prdnPrlLnNm',
        header: String(t('ut.label.Sub Line', 'Sub Line')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'eqpMainNm',
        header: String(t('ut.label.Main', 'Main')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'eqpMchNm',
        header: String(t('ut.label.Machine', 'Machine')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'eqpUntNm',
        header: String(t('ut.label.Unit', 'Unit')),
        width: 100,
        isReadOnly: true,
      },
      {
        header: String(t('ut.label.EQP Code', 'EQP Code')),
        columns: [
          {
            binding: 'machEqpNm',
            header: String(t('ut.label.Mech', 'Mech')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'elpwEqpNm',
            header: String(t('ut.label.Elec', 'Elec')),
            width: 100,
            isReadOnly: true,
          },
        ],
      },
      {
        header: String(t('ut.label.Qty(Total)', 'Qty(Total)')),
        columns: [
          {
            binding: 'machEqpQty',
            header: String(t('ut.label.Mech', 'Mech')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'elpwEqpQty',
            header: String(t('ut.label.Elec', 'Elec')),
            width: 100,
            isReadOnly: true,
          },
        ],
      },
      {
        header: String(t('ut.label.EQP Spec(Dimension_mm)', 'EQP Spec(Dimension:mm)')),
        columns: [
          {
            binding: 'eqpDimWthLen',
            header: String(t('ut.label.Width', 'Width')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'eqpDimDpthNvl',
            header: String(t('ut.label.Depth', 'Depth')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'eqpDimHigtNvl',
            header: String(t('ut.label.Height', 'Height')),
            width: 100,
            isReadOnly: true,
          },
        ],
      },
      {
        binding: 'eqpWgt',
        header: String(t('ut.label.EQP Spec(Weight_kg)', 'EQP Spec(Weight:kg)')),
        width: 100,
        isReadOnly: true,
      },
      {
        header: String(t('ut.label.Electricity', 'Electricity')),
        collapseTo: 'vltgNvl',
        columns: [
          {
            binding: 'elpwEqpGrNm',
            header: String(t('ut.label.Group 명', 'Group 명')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'vltgNvl', // 전기필수 (ELPW_EQP_QTY 값이 1 이상일때 필수)
            header: String(t('ut.label.Voltage[V]', 'Voltage[V]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.utVltgList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'elpwPhasCd', // 전기필수
            header: String(t('ut.label.Phase[Φ]', 'Phase[Φ]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.elpwPhasCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'ctwTpCd',
            header: String(t('ut.label.Wires[EA]', 'Wires[EA]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.ctwTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            // UT Matrix (UT Matrix No)별로 동일해야 함
            binding: 'frqNvl', // 전기필수
            header: String(t('ut.label.Frequency[Hz]', 'Frequency[Hz]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.utFrqList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            // 0.9로 Default 입력
            binding: 'pwftNvl', // 전기필수
            header: String(t('ut.label.Power Factor-', 'Power Factor-')),
            width: 100,
            align: 'right',
            dataType: DataType.String,
            isReadOnly: true,
          },
          {
            binding: 'od1EcNvl', // 수식 시스템 계산
            header: String(t('ut.label.Current(계산값)[A]', 'Current(계산값)[A]')),
            width: 100,
            align: 'right',
            isReadOnly: true,
          },
          {
            binding: 'od2EcNvl',
            header: String(t('ut.label.Current(입력값)[A]', 'Current(입력값)[A]')),
            width: 100,
            dataType: DataType.Number,
            isReadOnly: true,
          },
          {
            binding: 'brkeCapa',
            header: String(t('ut.label.Breaker(MCCB)-', 'Breaker(MCCB)-')),
            width: 100,
            align: 'right',
            isReadOnly: true,
          },
          {
            binding: 'plcClctYn', // 전기필수
            header: String(t('ut.label.PLC접지유무-', 'PLC접지유무-')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(commonYNcodes, 'cmnCd', 'cmnCdNm'),
          },
          {
            // Voltage[V] = 120 이면 “OUTLET” 자동 아니면 사용자 선택
            binding: 'elpwEqpCnctTpCd', // 전기필수
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.elpwEqpCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'elpwCapa', // 전기필수
            header: String(t('ut.label.Capacity[KW]', 'Capacity[KW]')),
            width: 100,
            dataType: DataType.Number,
            isReadOnly: true,
          },
          {
            binding: 'elpwSumCapa', // 수식계산 (= Qty(Total) Elec * Capacity[KW])
            header: String(t('ut.label.Capacity Sum', 'Capacity Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'elpwStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'elpwHeatCapa', // 전기필수
            header: String(t('ut.label.Heat Capacity[KW]', 'Heat Capacity[KW]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'dmdElpwTarfNvl', // TODO 검토화면에서 UT Manager 권한인 경우 입력
            header: String(t('ut.label.Demand Factor[%]', 'Demand Factor[%]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'dmdElpwCapa', // 검토화면에서 자동계산 (= Capacity Sum * Demand Factor[%])
            header: String(
              t('ut.label.Capacity_Sum(수용율 적용)[KW]', 'Capacity_Sum(수용율 적용)[KW]')
            ),
            width: 100,
            align: 'right',
            isReadOnly: true,
            cellTemplate: (params) =>
              calculateDmdElpwCapa(
                params.item.elpwEqpQty,
                params.item.elpwCapa,
                params.item.dmdElpwTarfNvl
              ),
          },
          {
            binding: 'cdsCapa', // TODO 검토화면에서 UT Manager 권한인 경우 입력
            header: String(
              t(
                'ut.label.Static Condenser Capa. (p.f. = 0.92)[KVAR]',
                'Static Condenser Capa. (p.f. = 0.92)[KVAR]'
              )
            ),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.CDA', 'CDA (Press: 6barG, Temp: -)')),
        collapseTo: 'cdaSize',
        columns: [
          {
            binding: 'cdaSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            dataType: DataType.Number,
            isReadOnly: true,
          },
          {
            binding: 'cdaDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
            isReadOnly: true,
          },
          {
            binding: 'cdaPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            dataType: DataType.Number,
            isReadOnly: true,
          },
          {
            binding: 'cdaPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.cdaPresList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'cdaCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'cdaCspSumCapa', // 수식계산 (= Qty(Total) Mech * CDA Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'cdaStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.N2', 'N2 (Press: 8 barG, Temp: -)')),
        collapseTo: 'nitrSize',
        columns: [
          {
            binding: 'nitrSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'nitrPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.n2PresList || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'nitrPntCnt',
            header: String(t("ut.label.Q'ty[Point]", "Q'ty[Point]")),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrCspCapa',
            header: String(t('ut.label.AVG Consumption[ℓ/min]', 'AVG Consumption[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrCspSumCapa',
            header: String(t('ut.label.AVG Consumption Sum', 'AVG Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'nitrPeakCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Peak Consumption[ℓ/min]', 'Peak Consumption[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'nitrPeakCspSumCapa', // 수식 (= Qty(Total) Mech * N2 Consumption[ℓ/min])
            header: String(t('ut.label.Peak Consumption Sum', 'Peak Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'nitrStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.IW', 'IW (Press: 3 barG, Temp: 10 ~ 32℃)')),
        collapseTo: 'iwSize',
        columns: [
          {
            binding: 'iwSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'iwTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwDrnCapa',
            header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwDrnSumCapa',
            header: String(t('ut.label.Drain Sum', 'Drain Sum')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'iwCspSumCapa', // 수식 (= Qty(Total) Mech * IW Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'iwStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(
          t('ut.label.IW for Emergency fire', 'IW for Emergency fire (Press: 3 barG, Temp:20~25℃)')
        ),
        collapseTo: 'frwtSize',
        columns: [
          {
            binding: 'frwtSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'frwtTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtDrnCapa',
            header: String(t('ut.label.Drain[ℓ/min]', 'Drain[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtDrnSumCapa',
            header: String(t('ut.label.Drain Sum', 'Drain Sum')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'frwtCspSumCapa', // 수식 (= Qty(Total) Mech * IW2 Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'frwtStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.PCW', 'PCW (Press: 3 barG, Temp:8~13℃)')),
        collapseTo: 'coolSize',
        columns: [
          {
            binding: 'coolSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'coolDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'coolTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'coolPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'coolCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'coolCspSumCapa', // 수식 (= Qty(Total) Mech * PCW Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'coolStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.WW', 'WW (Press: 3 barG, Temp: 8℃(Δ5))')),
        collapseTo: 'wwSize',
        columns: [
          {
            binding: 'wwSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'wwDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'wwTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'wwPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'wwCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'wwCspSumCapa', // 수식 (= Qty(Total) Mech * WW Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'wwStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.Steam', 'Steam (Press: 3 barG, Temp)')),
        collapseTo: 'stemSize',
        columns: [
          {
            binding: 'stemSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'stemDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'stemCwtSize',
            header: String(
              t('ut.label.Condenstaor connection size[Φ]', 'Condenstaor connection size[Φ]')
            ),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'stemCwtDevcCnctTpCd',
            header: String(
              t('ut.label.Condensator connection[Type]', 'Condensator connection[Type]')
            ),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'stemPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'stemCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[t/hr]', 'Consumption[t/hr]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'stemCspSumCapa', // 수식 (= Qty(Total) Mech * Steam Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'stemStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.NG', 'NG (Press: 2 barG, Temp: -)')),
        collapseTo: 'lngSize',
        columns: [
          {
            binding: 'lngSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'lngDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.devcCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'lngPres',
            header: String(t('ut.label.Pressure[Bar]', 'Pressure[Bar]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'lngCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[ℓ/min]', 'Consumption[ℓ/min]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'lngCspSumCapa', // 수식 (= Qty(Total) Mech * NG Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'lngStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(
          t(
            'ut.label.Exhaust air [Return(to Air Handling Unit)]',
            'Exhaust air [Return(to Air Handling Unit)]'
          )
        ),
        collapseTo: 'insdExhaSize',
        columns: [
          {
            binding: 'insdExhaSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'insdExhaCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.exhaCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'insdExhaCllrTpCd',
            header: String(t('ut.label.Collector Type[Type]', 'Collector Type[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.exhaCllrTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'insdExhaTmpr',
            header: String(t('ut.label.Tempurature[℃]', 'Tempurature[℃]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'insdExhaCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
            width: 100,
            dataType: DataType.Number,
            isReadOnly: true,
          },
          {
            binding: 'insdExhaCspSumCapa', // 수식 (= Qty(Total) Mech * Exhaust air Return Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'insdExhaStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(
          t(
            'ut.label.Exhaust air [Ventilation(to Outside)]',
            'Exhaust air [Ventilation(to Outside)]'
          )
        ),
        collapseTo: 'otsdExhaSize',
        columns: [
          {
            binding: 'otsdExhaSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'otsdExhaCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.exhaCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'otsdExhaCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'otsdExhaCspSumCapa', // 수식 (= Qty(Total) Mech * Exhaust air [Ventilation Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'otsdExhaStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        header: String(t('ut.label.Supply air', 'Supply air')),
        collapseTo: 'suarSize',
        columns: [
          {
            binding: 'suarSize',
            header: String(t('ut.label.Size[Φ]', 'Size[Φ]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'suarDevcCnctTpCd',
            header: String(t('ut.label.Connection[Type]', 'Connection[Type]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.exhaCnctTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'suarCllrTpCd',
            header: String(t('ut.label.Connection Type[Duct/Pipe]', 'Connection Type[Duct/Pipe]')),
            width: 100,
            isReadOnly: true,
            cssClass: 'WijmoSelect',
            dataMap: new DataMap(code?.suarCllrTpCd || [], 'cmnCd', 'cmnCdNm'),
          },
          {
            binding: 'suarCnctCnt',
            header: String(t('ut.label.Connection Quantity[EA]', 'Connection Quantity[EA]')),
            width: 100,
            isReadOnly: true,
            dataType: DataType.Number,
          },
          {
            binding: 'suarCspCapa', // 기계 조건부 필수 대상값
            header: String(t('ut.label.Consumption[CHM]', 'Consumption[CHM]')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
          {
            binding: 'suarCspSumCapa', // 수식 (= Qty(Total) Mech * Supply air Consumption[ℓ/min])
            header: String(t('ut.label.Consumption Sum', 'Consumption Sum')),
            width: 100,
            isReadOnly: true,
          },
          {
            binding: 'suarStndUseQty',
            header: String(t('ut.label.표준사용량', '표준사용량')),
            width: 100,
            isRequired: false,
            dataType: DataType.Number,
          },
        ],
      },
      {
        binding: 'rmk',
        header: String(t('ut.label.Remark', 'Remark')),
        width: 100,
        isReadOnly: true,
      },
      {
        binding: 'dataInsUserNm',
        header: String(t('com.label.작성자', '작성자')),
        width: 100,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataInsDtm',
        header: String(t('com.label.작성일자', '작성일자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdUserNm',
        header: String(t('com.label.수정자', '수정자')),
        width: 100,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'dataUpdDtm',
        header: String(t('com.label.수정일자', '수정일자')),
        width: 120,
        align: 'center',
        isReadOnly: true,
      },
      {
        binding: 'utmId',
        visible: false,
      },
      {
        binding: 'planProcId',
        visible: false,
      },
      {
        binding: 'utmSeq',
        visible: false,
      },
    ];
  }, [code]);

  const searchRegistMatrix = (params) => {
    findUtMatrix('review', params).then((result) => {
      setRowData(result || []);
    });
  };

  const handleSave = (showMsg = true) => {
    return new Promise((resolve) => {
      if (!(rowData || []).filter((o) => o.crudKey).length) {
        /*
        // [24.08.19] 탭 이동 시 변경사항 없는 경우 자동저장 안내문구 미노출
        if (showMsg) {
          openMessageBar({
            type: 'warning',
            content: t('ut.label.변경사항이 없습니다.', '변경사항이 없습니다.'),
          });
        }
        */
        resolve(true);
        return;
      }

      saveUtMatrix('review', rowData)
        .then((res) => {
          if (showMsg) {
            openMessageBar({
              type: res?.successOrNot === 'Y' ? 'confirm' : 'error',
              content:
                res?.successOrNot === 'Y'
                  ? t('com.msg.저장되었습니다.', '저장되었습니다.')
                  : t('com.msg.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
            });
          }
          if (res?.successOrNot === 'Y') {
            searchRegistMatrix(condition);
          }
        })
        .finally(() => resolve(true));
    });
  };

  const onInitialized = (grid) => {
    setFlexRef(grid);
    gridRef.current = grid;
    grid.itemFormatter = onItemFormatter;
  };

  const downloadExcel = async (templateType) => {
    downloadExcelTemplates(templateType, '1');
  };

  const onBeginningEdit = useEvent((grid, e) => {
    const binding = grid.columns[e.col].binding;
    const item = grid.rows[e.row].dataItem;
    // 표준사용량
    if (
      [
        'elpwStndUseQty',
        'cdaStndUseQty',
        'nitrStndUseQty',
        'iwStndUseQty',
        'frwtStndUseQty',
        'coolStndUseQty',
        'wwStndUseQty',
        'stemStndUseQty',
        'lngStndUseQty',
        'insdExhaStndUseQty',
        'otsdExhaStndUseQty',
        'suarStndUseQty',
      ].includes(binding)
    ) {
      // 작성/검토 상태가 아닌 경우 readonly
      if (!(isUtWriter || isUtComplete || isUtReview)) {
        e.cancel = true;
      }
      // Electricity > 표준사용량 - 전력설비수량(elpwEqpQty)이 0인 경우 readonly
      if ('elpwStndUseQty' === binding && Number(item.elpwEqpQty || 0) < 1) {
        e.cancel = true;
      }
    }
    // TODO 검토화면에서 UT Manager 권한인 경우 입력
    // 수용율
    if (['dmdElpwTarfNvl', 'cdsCapa'].includes(binding)) {
      // Electricity > 수용율 - 전력설비수량(elpwEqpQty)이 0인 경우 readonly
      if (Number(item.elpwEqpQty || 0) < 1) {
        e.cancel = true;
      }
    }
  });

  const onItemFormatter = useEvent((panel, row, col, cell) => {
    if (CellType.Cell === panel.cellType) {
      const binding = panel.columns[col].binding;
      const item = panel.rows[row].dataItem;
      // 표준사용량
      if (
        [
          'elpwStndUseQty',
          'cdaStndUseQty',
          'nitrStndUseQty',
          'iwStndUseQty',
          'frwtStndUseQty',
          'coolStndUseQty',
          'wwStndUseQty',
          'stemStndUseQty',
          'lngStndUseQty',
          'insdExhaStndUseQty',
          'otsdExhaStndUseQty',
          'suarStndUseQty',
        ].includes(binding)
      ) {
        // 작성/검토 상태가 아닌 경우 readonly
        if (!(isUtWriter || isUtComplete || isUtReview)) {
          cell.ariaReadOnly = true;
        }
        // Electricity > 표준사용량 - 전력설비수량(elpwEqpQty)이 0인 경우 readonly
        if ('elpwStndUseQty' === binding && Number(item.elpwEqpQty || 0) < 1) {
          cell.ariaReadOnly = true;
        }
      }
      // TODO 검토화면에서 UT Manager 권한인 경우 입력
      // 수용율
      if (['dmdElpwTarfNvl', 'cdsCapa'].includes(binding)) {
        // Electricity > 수용율 - 전력설비수량(elpwEqpQty)이 0인 경우 readonly
        if (Number(item.elpwEqpQty || 0) < 1) {
          cell.ariaReadOnly = true;
        }
      }
    }
  });

  //검토진행
  const handleSubmitReview = () => {
    props?.onSubmit?.();
  };

  //검증
  const handleModify = () => {
    props?.onModify?.();
  };
  //보류 링크
  const handleRequestLinkHold = () => {
    props?.onLinkHold?.();
  };
  //재작성 링크
  const handleRequestLinkRewrite = () => {
    props?.onLinkRewrite?.();
  };

  const handleApproval = () => {
    props?.onAppr?.();
  };

  const onCellEditEnding = useEvent((grid, e) => {
    const newVal = grid.activeEditor?.value;
    const data = grid.rows[e.row].dataItem;
    const binding = grid.columns[e.col].binding;
    // Demand Factor
    if ('dmdElpwTarfNvl' === binding) {
      if (!_.isNull(newVal) && newVal != '') {
        if (Number(newVal) > 100 || Number(newVal) < 0) {
          openMessageBar({
            type: 'error',
            content: t('ut.label.0~100점 사이로 입력하세요.', '0~100점 사이로 입력하세요.'),
          });
          e.cancel = true;
          return;
        }
      }
    }
  });

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.상세보기', '상세보기')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && <div className="info warning">{condition?.utmNo}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={() => {
              CommonUtil.exportWijmoExcelGrid(
                flexRef,
                t('ut.label.Ut Matrix 작성 대상', 'Ut Matrix 작성 대상'),
                t('ut.label.Ut Matrix 작성 대상', 'Ut Matrix 작성 대상')
              );
            }}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>

          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchRegistMatrix(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>

      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        isSelector={false}
        frozenColumns={4}
        isReadOnly={!isUtWriter && !isUtComplete && !isUtReview}
        excludePin={['utmSeq']}
        excludeFilter={['utmSeq']}
        initialized={onInitialized}
        beginningEdit={onBeginningEdit}
        cellEditEnding={onCellEditEnding}
      />

      <GlobalBtnGroup>
        {/* 작성중 : <검증> <임시저장> <새로고침> UTM02  */}
        {/* 작성완료 : <검토진행> <재작성요청> <보류> <목록> <다운로드>  UTM05 */}
        {/* 검토중 : <결재요청> <목록> <다운로드>  UTM04 */}
        {/* 그외 (결재중) : <목록> <다운로드>  */}

        {isUtWriter && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={() => handleSave()}>
              {t('com.button.임시저장', '임시저장')}
            </Button>
          </>
        )}
        {isUtComplete && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleSubmitReview}>
              {t('com.button.검토진행', '검토진행')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={() => handleSave()}>
              {t('com.button.임시저장', '임시저장')}
            </Button>
          </>
        )}
        {isUtReview && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={() => handleSave()}>
              {t('com.button.임시저장', '임시저장')}
            </Button>
            <Button css={IconButton.button} className="save" onClick={handleApproval}>
              {isAprText}
            </Button>
          </>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default React.forwardRef(DetailSummaryContent);
