/** @jsxImportSource @emotion/react */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMessageBar } from 'hooks/useMessageBar';
import { ManagementMode } from 'models/common/Common';
import {
  EquipmentApprovalRequest,
  EquipmentClassificationMaster,
  ReqChgTpCd,
} from 'models/mp/MpEquipment';
import { CrudCode } from 'models/common/Edit';
import EditMpEquipmentClassificationMasterGrid from './equipmentmanagement/EditMpEquipmentClassificationMasterGrid';
import EditMpEquipmentClassificationDetailGrid from './equipmentmanagement/EditMpEquipmentClassificationDetailGrid';
import { ContentSection } from 'components/layouts/ContentSection';
import { ContentFlex } from 'components/layouts/ContentFlex';
import { checkAvailableDeleteRequest } from '../../apis/mp/MpEquipment';

interface Props {
  aprReqId: string; // 결재요청ID
  mode: ManagementMode;
  requestMasterList: EquipmentClassificationMaster[];
  isReadOnly?: boolean;
}

/**
 * MP 설비분류체계 등록/수정/삭제 요청 팝업 화면
 *
 * @param open
 * @param mode
 * @param condition
 * @param close
 * @constructor
 */
const MpEquipmentClassificationRequest = (props: Props, ref) => {
  const { mode, isReadOnly = false } = props;
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const [rowData, setRowData] = useState<EquipmentClassificationMaster[]>(
    props?.requestMasterList || []
  );
  const [detailTarget, setDetailTarget] = useState<EquipmentClassificationMaster>({});
  const isEditable = useMemo(
    () => [ManagementMode.CREATE, ManagementMode.MODIFY].includes(mode) && !isReadOnly,
    [mode, isReadOnly]
  );

  useEffect(() => {
    init(mode, props?.requestMasterList);
  }, [mode, isReadOnly, props?.requestMasterList]);

  const init = (mode, masterRows) => {
    if (ManagementMode.CREATE === mode && !(masterRows || []).length && !isReadOnly) {
      const newRow = {
        crudKey: CrudCode.CREATE,
        reqChgTpCd: ReqChgTpCd.CREATE,
      };
      setRowData([newRow]);
    } else {
      const rows = (masterRows || []).reduce((acc, cur) => {
        const keys =
          ManagementMode.DELETE === mode
            ? { crudKey: CrudCode.DELETE, reqChgTpCd: ReqChgTpCd.DELETE }
            : ManagementMode.MODIFY === mode
            ? { crudKey: CrudCode.UPDATE, reqChgTpCd: ReqChgTpCd.UPDATE }
            : ManagementMode.CREATE === mode
            ? { crudKey: CrudCode.CREATE, reqChgTpCd: ReqChgTpCd.CREATE }
            : {};
        return [
          ...acc,
          {
            ...cur,
            ...keys,
          },
        ];
      }, [] as EquipmentClassificationMaster[]);
      setRowData(rows);
      if (rows.length) {
        setDetailTarget(rows[0]);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    validate: async () => {
      // 변경 가능한 항목이 없는 경우
      let isSuccess = true;
      if (!isEditable) {
        if (ManagementMode.DELETE === mode) {
          const masterList = rowData.map((o) => ({ ...o }));
          const res = await checkAvailableDeleteRequest(masterList);
          if (res.successOrNot !== 'Y' && res.data) {
            isSuccess = false;
            openMessageBar({
              type: 'error',
              content:
                res.data && typeof res.data === 'string'
                  ? res.data
                  : t(
                      'mp.msg.item에 등록되어 있는 항목은 삭제 요청할 수 없습니다.',
                      'item에 등록되어 있는 항목은 삭제 요청할 수 없습니다.'
                    ),
            });
          }
          return isSuccess;
        }
        return true;
      }

      let valid = true;
      let message = '';
      let createRow = 0;
      let updateRow = 0;
      (rowData || []).every((master) => {
        if (CrudCode.CREATE === master.crudKey) {
          createRow++;
        } else if (CrudCode.UPDATE === master.crudKey) {
          updateRow++;
        }
        // master grid validate start
        if (!(master.mpClsfCd || '').length) {
          message = t('mp.label.MP분류는 필수 항목입니다.', 'MP분류는 필수 항목입니다.');
          valid = false;
        } else if (!(master.eqclId || '').length) {
          message = t('mp.label.설비군은 필수 항목입니다.', '설비군은 필수 항목입니다.');
          valid = false;
        } else if (!(master.eqpClsfNo || '').length) {
          message = t(
            'mp.label.설비분류체계번호는 필수 항목입니다.',
            '설비분류체계번호는 필수 항목입니다.'
          );
          valid = false;
        } else if (!(master.eqpClsfNm || '').length) {
          message = t(
            'mp.label.설비분류체계명은 필수 항목입니다.',
            '설비분류체계명은 필수 항목입니다.'
          );
          valid = false;
        }
        if (!valid) {
          return false;
        }
        // master grid validate end

        // detail grid validate start
        if ('detailList' in master && Array.isArray(master?.detailList)) {
          master?.detailList.every((main) => {
            // detail grid > main validate start
            if (CrudCode.CREATE === main.crudKey) {
              createRow++;
            } else if (CrudCode.UPDATE === main.crudKey || CrudCode.DELETE === main.crudKey) {
              updateRow++;
            }
            if (!(main.eqpClsfStrcNo || '').length) {
              message = `${master.eqpClsfNo} : ${t(
                'mp.label.설비분류체계 구조 번호는 필수 항목입니다.',
                '설비분류체계 구조 번호는 필수 항목입니다.'
              )}`;
              valid = false;
            } else if (!(main.eqpClsfStrcNm || '').length) {
              message = `${master.eqpClsfNo} : ${t(
                'mp.label.설비분류체계 구조명은 필수 항목입니다.',
                '설비분류체계 구조명은 필수 항목입니다.'
              )}`;
              valid = false;
            } else if (!(main.eqpLvCd || '').length) {
              message = `${master.eqpClsfNo} : ${t(
                'mp.label.설비레벨은 필수 항목입니다.',
                '설비레벨은 필수 항목입니다.'
              )}`;
              valid = false;
            }
            if (!valid) {
              return false;
            }
            if ('machines' in main && Array.isArray(main?.machines)) {
              main.machines.every((machine) => {
                // detail grid > main > machine validate start
                if (CrudCode.CREATE === machine.crudKey) {
                  createRow++;
                } else if (
                  CrudCode.UPDATE === machine.crudKey ||
                  CrudCode.DELETE === machine.crudKey
                ) {
                  updateRow++;
                }
                if (!(machine.eqpClsfStrcNo || '').length) {
                  message = `${master.eqpClsfNo} > ${main.eqpClsfStrcNo} : ${t(
                    'mp.label.설비분류체계 구조 번호는 필수 항목입니다.',
                    '설비분류체계 구조 번호는 필수 항목입니다.'
                  )}`;
                  valid = false;
                } else if (!(machine.eqpClsfStrcNm || '').length) {
                  message = `${master.eqpClsfNo} > ${main.eqpClsfStrcNo} : ${t(
                    'mp.label.설비분류체계 구조명는 필수 항목입니다.',
                    '설비분류체계 구조명는 필수 항목입니다.'
                  )}`;
                  valid = false;
                } else if (!(machine.eqpLvCd || '').length) {
                  message = `${master.eqpClsfNo} > ${main.eqpClsfStrcNo} : ${t(
                    'mp.label.설비레벨은 필수 항목입니다.',
                    '설비레벨은 필수 항목입니다.'
                  )}`;
                  valid = false;
                } else if (!(machine.upprEqpClsfStrcNo || '').length) {
                  message = `${master.eqpClsfNo} > ${main.eqpClsfStrcNo} : ${t(
                    'mp.label.상위표준설비 구조도 번호는 필수 항목입니다.',
                    '상위표준설비 구조도 번호는 필수 항목입니다.'
                  )}`;
                  valid = false;
                }
                if (!valid) {
                  return false;
                }
                if ('units' in machine && Array.isArray(machine?.units)) {
                  machine.units.every((unit) => {
                    // detail grid > main > machine > unit validate start
                    if (CrudCode.CREATE === unit.crudKey) {
                      createRow++;
                    } else if (
                      CrudCode.UPDATE === unit.crudKey ||
                      CrudCode.DELETE === unit.crudKey
                    ) {
                      updateRow++;
                    }
                    if (!(unit.eqpClsfStrcNo || '').length) {
                      message = `${master.eqpClsfNo} > ${main.eqpClsfStrcNo} > ${
                        machine.eqpClsfStrcNo
                      } : ${t(
                        'mp.label.설비분류체계 구조 번호는 필수 항목입니다.',
                        '설비분류체계 구조 번호는 필수 항목입니다.'
                      )}`;
                      valid = false;
                    } else if (!(unit.eqpClsfStrcNm || '').length) {
                      message = `${master.eqpClsfNo} > ${main.eqpClsfStrcNo} > ${
                        machine.eqpClsfStrcNo
                      } : ${t(
                        'mp.label.설비분류체계 구조명는 필수 항목입니다.',
                        '설비분류체계 구조명는 필수 항목입니다.'
                      )}`;
                      valid = false;
                    } else if (!(unit.eqpLvCd || '').length) {
                      message = `${master.eqpClsfNo} > ${main.eqpClsfStrcNo} > ${
                        machine.eqpClsfStrcNo
                      } : ${t(
                        'mp.label.설비레벨은 필수 항목입니다.',
                        '설비레벨은 필수 항목입니다.'
                      )}`;
                      valid = false;
                    } else if (!(unit.upprEqpClsfStrcNo || '').length) {
                      message = `${master.eqpClsfNo} > ${main.eqpClsfStrcNo} > ${
                        machine.eqpClsfStrcNo
                      } : ${t(
                        'mp.label.상위표준설비 구조도 번호는 필수 항목입니다.',
                        '상위표준설비 구조도 번호는 필수 항목입니다.'
                      )}`;
                      valid = false;
                    }
                    if (!valid) {
                      return false;
                    }
                    // detail grid > main > machine > unit validate end
                    return true;
                  });
                }
                // detail grid > main > machine validate end
                return true;
              });
            }
            // detail grid > main validate end
            return true;
          });
        }
        return true;
        // detail grid validate end
      });

      if (!valid) {
        openMessageBar({
          type: 'error',
          content: message,
        });
        return false;
      }

      if (ManagementMode.CREATE === mode && createRow < 1) {
        openMessageBar({
          type: 'warning',
          content: t('mp.label.등록 요청할 항목이 없습니다.', '등록 요청할 항목이 없습니다.'),
        });
        return false;
      }
      if (ManagementMode.MODIFY === mode && updateRow < 1) {
        openMessageBar({
          type: 'warning',
          content: t('mp.label.수정 요청할 항목이 없습니다.', '수정 요청할 항목이 없습니다.'),
        });
        return false;
      }
      return true;
    },
    getBizReqData: (aprReqId: string, reqRsn: string) => {
      const request = {
        castType: 'MpEquipmentClsfRequest', // 서버측 결재요청상세vo > bizReqData cast 타입
        aprReqId: aprReqId,
        reqRsn: reqRsn,
        requestMasterList: rowData,
      } as EquipmentApprovalRequest;

      return request;
    },
  }));

  return (
    <>
      <ContentFlex>
        <ContentSection className="section width50p marginT0">
          <EditMpEquipmentClassificationMasterGrid
            mode={mode}
            rowData={rowData}
            isReadOnly={isReadOnly}
            onRefresh={() => {
              init(mode, props?.requestMasterList);
              setDetailTarget({});
            }}
            onChange={(masterList) => {
              setRowData(masterList);
            }}
            onClickDetail={(target) => {
              setDetailTarget((prev) => target);
            }}
          />
        </ContentSection>
        <ContentSection className="section width50p">
          <EditMpEquipmentClassificationDetailGrid
            mode={mode}
            aprReqId={props.aprReqId}
            masterData={detailTarget}
            isReadOnly={isReadOnly}
            onChange={(detailList) => {
              setRowData((prev) => {
                return (prev || []).reduce((acc, cur) => {
                  if (cur.eqpClsfNo === detailTarget.eqpClsfNo) {
                    cur.detailList = detailList;
                  }
                  acc.push(cur);
                  return acc;
                }, [] as EquipmentClassificationMaster[]);
              });
            }}
          />
        </ContentSection>
      </ContentFlex>
    </>
  );
};

export default forwardRef(MpEquipmentClassificationRequest);
