/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useMessageBar } from 'hooks/useMessageBar';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';

export const MessageBar = () => {
  const {
    messageBarState: { type, isOpen, content },
    openMessageBar,
    closeMessageBar,
  } = useMessageBar();

  const handleClose = useCallback(async () => {
    closeMessageBar();
  }, [closeMessageBar]);

  return (
    <>
      {isOpen && (
        <Snackbar
          key={uuidv4()}
          open={isOpen}
          message={<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}></span>}
          className={type}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      )}
    </>
  );
};
