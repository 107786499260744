/** @jsxImportSource @emotion/react */
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useEffect, useState } from 'react';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import 'ui/css/multiSelect.css';
import '@grapecity/wijmo.cultures/wijmo.culture.ko'; //wijmo 한글적용.  다른 문화권 어케 설정하지 고민.

import { EquipmentParameterCondition } from 'models/ip/EquipmentParameter';
import { findEquipmentParameterRequestList } from 'apis/ip/EquipmentParameter';
import { GuideBlock } from 'pages/sample/guide/GuideComponent';

const DataBind = () => {
  const [rowData, setRowData] = useState<any>();
  const [flexRef, setFlexRef] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [requestCondition, setRequestCondition] = useState<EquipmentParameterCondition>({});

  useEffect(() => {
    handleSearch(requestCondition, true);
  }, []);

  const handleSearch = async (condition: EquipmentParameterCondition, isFirst = false) => {
    const response = await findEquipmentParameterRequestList({
      ...condition,
    });
    if (response) {
      const rowData = response.list || [];
      setRowData(rowData);
      console.log(rowData);
    }
  };

  const LayoutDefinition = () => {
    return [
      {
        binding: 'aprPvnDdlnCd',
        header: 'aprPvnDdlnCd',
        align: 'left',
        width: 240,
      },
      {
        binding: 'aprReqTitNm',
        header: 'aprReqTitNm',
        align: 'left',
        width: 150,
      },
      {
        binding: 'aprReqId',
        header: 'aprReqId',
        align: 'left',
        width: 150,
        isReadOnly: true,
      },
      {
        header: '헤더 머지',
        align: 'center',
        columns: [
          {
            binding: 'aprReqProgStatCd',
            header: 'aprReqProgStatCd',
            width: 150,
            isReadOnly: true,
          },
          {
            binding: 'aprReqProgStatCdNm',
            header: 'aprReqProgStatCdNm',
            width: 150,
          },
        ],
      },
      {
        binding: 'aprTplId',
        header: 'aprTplId',
        align: 'left',
        width: 150,
        isReadOnly: true,
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) return;
    const row = s.rows[e.row].dataItem;
    setSelectedRow(row);
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setFlexRef(grid);

    grid.selectionMode = SelectionMode.Row;
  };

  return (
    <div>
      <GuideBlock title={'Import'} isCode={true}>
        {`
import { flexGridTooltip } from 'models/ip/Public';         // ToolTip
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import 'ui/css/multiSelect.css';
import '@grapecity/wijmo.cultures/wijmo.culture.ko'; //wijmo 한글적용.
`}
      </GuideBlock>

      <GuideBlock title={'Layout'} isCode={true}>
        {`
//그리드에 바인딩 될 데이터
useEffect(() => {
    handleSearch(requestCondition, true);
  }, []);

  const handleSearch = async (condition: EquipmentParameterCondition, isFirst = false) => {
    const response = await findEquipmentParameterRequestList({
      ...condition,
    });
    if (response) {
      const rowData = response.list || [];
      setRowData(rowData);
      console.log(rowData);
    }
  };


  //그리드에 바인딩될 데이터 필드 및 헤더 설정
  const LayoutDefinition = () => {
    return [
      {
        binding: 'aprPvnDdlnCd',
        header: 'aprPvnDdlnCd',
        align: 'left',
        width: 240,
      },
      {
        binding: 'aprReqTitNm',
        header: 'aprReqTitNm',
        align: 'left',
        width: 150,
      },
      {
        binding: 'aprReqId',
        header: 'aprReqId',
        align: 'left',
        width: 150,
        isReadOnly: true,    //필드 읽기 전용, 
      },
      {
        header: '헤더 머지',
        align: 'center',
        columns: [
          {
            binding: 'aprReqProgStatCd',
            header: 'aprReqProgStatCd',
            width: 150,
            isReadOnly: true,
          },
          {
            binding: 'aprReqProgStatCdNm',
            header: 'aprReqProgStatCdNm',
            width: 150,
          },
        ],
      },
      {
        binding: 'aprTplId',
        header: 'aprTplId',
        align: 'left',
        width: 150,
        isReadOnly: true,
      },
    ];
  };


  // 데이터와 헤더 레이아웃을 메모리에 로딩.
  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

`}
      </GuideBlock>
      <GuideBlock title={'Method'} isCode={true}>
        {`
//Row 변경시 마다 호출 됨
const onFlexGridSelectionChanged = (s, e) => {
  if (s.rows == 0) return;
  const row = s.rows[e.row].dataItem;
};

//그리드가 다 그려지고 최초 실행 됨
const onInitialized = (grid) => {
  new Selector(grid);         //Column헤드에 체크셀렉트 추가,
  new FlexGridFilter(grid);   //그리드 기본 필터 추가,

  grid.selectionMode = SelectionMode.Row; //셀렉트 방식 지정
};
`}
      </GuideBlock>
      <GuideBlock title={'Render'} isCode={true}>
        {`
  <FlexGrid
  columns={state.layoutDefinition}   //그리드 레이아웃
  itemsSource={state.itemsSource}     //바이딩 될 데이터
  showMarquee={true}          //선택된 셀의 모양 false >> 셀전체 백그라운드
  showAlternatingRows={true}  //row라인별 색상구분 사용여부, [퍼블에서 강제 적용되어 Alternating 기능은 wijmo 그리드에서 무효화 됨.]
  alternatingRowStep={2}      //row라인별 색상구분, {2} => 2라인 단위로 교대로 백그라운드 색상.
  autoGenerateColumns={false}   //false >> 컬럼 사용자 정의 
  stickyHeaders={true}        //헤더 고정
  selectionMode={'ListBox'}   //row 선택 형태, default: Cell,  option: Row, RowRange, Cell, CellRange, ListBox, None
  headersVisibility="Column"   //헤더열 표시여부, None, Column, Row, All  ;  wijmo.grid.HeadersVisibility.Column
  showSelectedHeaders="Row"    //Cell 이나 Row 선택 할 경우 헤드에 색상,
  deferResizing={true}        //Cell 이나 Row 넓이 변경 할 경우, default :false,    True >> 마우스 드래그 = 라인이 표시, 드롭 = 넓이 변경,
  isReadOnly={true}           //읽기 전용,  헤드 레이아웃에서의 설정도 무효화 됨,     
  frozenColumns={2}           //좌우 스크롤,  고정될 컬럼(왼쪽에서부터)
  formatItem={flexGridTooltip}  //툴팁 적용 메서드
  selectionChanged={onFlexGridSelectionChanged} //로우가 변경될때 마다 실행 될 메서드
  initialized={onInitialized}     //데이터가 그리드에 바인딩 되고 나서 실행 될 메서드
  style={{ height: '300px' }}
  />

`}
      </GuideBlock>
      <GuideBlock title={'Example'} isCode={true}>
        <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
          <FlexGrid
            columns={state.layoutDefinition}
            itemsSource={state.itemsSource}
            showMarquee={true}
            autoGenerateColumns={false}
            stickyHeaders={true}
            showSelectedHeaders="Row"
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: '300px' }}
          />
        </ContentGrid>
      </GuideBlock>

      <GuideBlock title={'Selected Row Data'} isCode={true}>
        <pre>{JSON.stringify(selectedRow, null, 2)}</pre>
      </GuideBlock>
    </div>
  );
};
export default DataBind;
