/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useImperativeHandle, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import {
  SubTitleLayout,
  SubTitleGroup,
  ControlBtnGroup,
  GlobalBtnGroup,
} from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { findElectricSummary } from 'apis/ut/UtMatrixRegist';
import { UtMatrixDetail, UtMatrixRegistSearchCondition } from 'models/ut/UtMatrix';
import { hasRole } from 'utils/SessionUtil';
import { getExcelFileName } from '../../../utils/ExcelUtil';
import * as wjGridXlsx from '@grapecity/wijmo.grid.xlsx';

const ElectricSummaryContent = (props: any, ref) => {
  const {
    onSubmit,
    condition = {} as UtMatrixRegistSearchCondition,
    processData = [] as UtMatrixDetail[],
  } = props;
  const gridRef = useRef<any>();
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<UtMatrixDetail[]>([]);
  const [flexRef, setFlexRef] = useState<any>();

  const isAprText = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP05');
    return condition.utmWkProgStatCd === 'UTM04' && p.length > 0
      ? String(t('com.button.결재정보', '결재정보'))
      : String(t('com.button.결재요청', '결재요청'));
  }, [processData, condition.utmWkProgStatCd]);

  //검토중
  const isUtReview = useMemo(() => {
    return condition.utmWkProgStatCd === 'UTM04' && condition.openMode !== 'appr';
  }, [condition.utmWkProgStatCd]);

  // 작성완료 : <검토진행> <재작성요청> <보류> <목록> <다운로드>
  const isUtComplete = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP03');

    return condition.utmWkProgStatCd === 'UTM03';
  }, [processData, condition.utmWkProgStatCd]);

  // 작성중 : <검증> <임시저장> <새로고침>
  const isUtWriter = useMemo(() => {
    const p = processData.filter((o) => o.utmWrtProcProgStatCd === 'UTP02');
    return condition.utmWkProgStatCd === 'UTM02'; //작성중
  }, [processData, condition.utmWkProgStatCd]);

  const isUtWriter2 = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return (p.length > 0 && p[0].utmWriteYn === 'Y') || hasRole('ADM');
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);

  const isWrite = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP02';
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);

  const isWriteComplete = useMemo(() => {
    const p = processData.filter(
      (o) => o.prdnProcCd === condition?.prdnProcCd && o.dtalProcCd === condition?.dtalProcCd
    );
    return p.length > 0 && p[0].utmWrtProcProgStatCd === 'UTP03';
  }, [processData, condition?.prdnProcCd, condition?.dtalProcCd]);

  useImperativeHandle(ref, () => ({
    searchElectricSummary: (condition) => {
      searchElectricSummary(condition);
    },
  }));

  const searchElectricSummary = (condition) => {
    findElectricSummary(condition).then((result) => {
      setRowData(result);
    });
  };

  const layoutDefinition = [
    {
      binding: 'no',
      header: String(t('com.label.NO', 'NO')),
      width: 40,
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      minWidth: 120,
      width: '*',
    },
    {
      binding: 'eqpPrdnLnQty',
      header: String(t('ut.label.라인수량', '라인수량')),
      minWidth: 120,
      width: '*',
    },
    {
      binding: 'vltgNvl',
      header: String(t('ut.label.전압', '전압')),
      align: 'center',
      minWidth: 120,
      width: '*',
    },
    {
      binding: 'elpwEqpQty',
      header: String(t('ut.label.설비수량', '설비수량')),
      align: 'right',
      minWidth: 120,
      width: '*',
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'od2EcNvl',
      header: String(t('ut.label.수용율 적용전', '수용율 적용전')),
      align: 'right',
      minWidth: 120,
      width: '*',
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      binding: 'elecDmdRate',
      header: String(t('ut.label.수용율 적용 후', '수용율 적용 후')),
      align: 'right',
      minWidth: 120,
      width: '*',
      cellTemplate: (params) => Number(params.value || 0).toLocaleString(),
    },
    {
      header: String(t('ut.label.차이', '차이')),
      align: 'right',
      minWidth: 120,
      width: '*',
      cellTemplate: (params) =>
        `${((params.item.od2EcNvl || 0) - (params.item.elecDmdRate || 0)).toLocaleString()}`,
    },
  ];

  const handleSubmit = () => {
    props?.onSubmit?.();
  };

  //검토진행
  const handleSubmitReview = () => {
    props?.onSubmit?.();
  };

  //검증
  const handleModify = () => {
    props?.onModify?.();
  };
  //보류 링크
  const handleRequestLinkHold = () => {
    props?.onLinkHold?.();
  };
  //재작성 링크
  const handleRequestLinkRewrite = () => {
    props?.onLinkRewrite?.();
  };

  const handleApproval = () => {
    props?.onAppr?.();
  };

  const btnExcelExportGrid = () => {
    const book = wjGridXlsx.FlexGridXlsxConverter.saveAsync(flexRef, {
      includeColumnHeaders: true,
      includeRowHeaders: true,
    });
    book.sheets[0].name = 'Ut Matrix 검토 전기 요약';
    book.saveAsync(
      getExcelFileName(t('ut.label.Ut Matrix 검토 전기 요약', 'Ut Matrix 검토 전기 요약'))
    );
  };

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('ut.label.전기요약', '전기요약')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{(rowData || []).length.toLocaleString()}</span>
            {t('com.label.건', '건')}
          </span>
          {condition?.utmNo && <div className="info warning">{condition?.utmNo}</div>}
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="Exceldown"
            onClick={btnExcelExportGrid}
            disableRipple
          >
            {t('com.button.다운로드', '다운로드')}
          </Button>
          <Button
            css={IconButton.button}
            className="refresh"
            onClick={() => searchElectricSummary(condition)}
          >
            {t('com.button.새로고침', '새로고침')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        height={388}
        isReadOnly={true}
        isSelector={false}
        frozenColumns={3}
        excludePin={['utmSeq']}
        excludeFilter={['utmSeq']}
        allowPinning={false}
      />
      <GlobalBtnGroup>
        {/* 작성중 : <검증> <임시저장> <새로고침> UTM02  */}
        {/* 작성완료 : <검토진행> <재작성요청> <보류> <목록> <다운로드>  UTM05 */}
        {/* 검토중 : <결재요청> <목록> <다운로드>  UTM04 */}
        {/* 그외 (결재중) : <목록> <다운로드>  */}

        {isUtWriter && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            {/*<Button css={IconButton.button} className="draft" onClick={() => handleSave()}>*/}
            {/*  임시저장*/}
            {/*</Button>*/}
          </>
        )}
        {isUtComplete && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleSubmitReview}>
              {t('com.button.검토진행', '검토진행')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            {/*<Button css={IconButton.button} className="draft" onClick={() => handleSave()}>*/}
            {/*  임시저장*/}
            {/*</Button>*/}
          </>
        )}
        {isUtReview && (
          <>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkRewrite}>
              {t('com.button.재작성요청', '재작성요청')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleRequestLinkHold}>
              {t('com.button.보류', '보류')}
            </Button>
            <Button css={IconButton.button} className="draft" onClick={handleModify}>
              {t('com.button.검증', '검증')}
            </Button>
            {/*<Button css={IconButton.button} className="draft" onClick={() => handleSave()}>
              임시저장
            </Button>*/}
            <Button css={IconButton.button} className="save" onClick={handleApproval}>
              {isAprText}
            </Button>
          </>
        )}
      </GlobalBtnGroup>
    </>
  );
};

export default React.forwardRef(ElectricSummaryContent);
