/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getContInfoList } from 'apis/gtng/popup/GatingCont';
import CustomDialog from 'components/modals/common/CustomDialog';
import CustomGrid from 'components/grids/CustomGrid';

export interface contSearchParam {
  rowId?: string;
  gatingId?: string;
  userId?: string;
  empNm?: string;
  deptNm?: string;
  useYn?: string;
}

/**
 * 담당자 조회 팝업 (읽기전용)
 * @param props
 * @constructor
 */
export const GatingContListPopUp = (props: any) => {
  const { t } = useTranslation();
  const [rowData, setRowData] = useState<contSearchParam[]>([]);
  const [popTitle, setPopTitle] = useState<string>('');

  const layoutDefinition = [
    {
      header: String(t('com.label.NO', 'NO')),
      align: 'center',
      cellTemplate: (grid) => grid.row._idx + 1,
      width: 40,
    },
    {
      binding: 'userId',
      header: String(t('com.label.사용자ID', '사용자ID')),
      align: 'left',
      width: 120,
    },
    {
      binding: 'empNm',
      header: String(t('com.label.사용자명', '사용자명')),
      align: 'center',
      width: 120,
    },
    {
      binding: 'deptNm',
      header: String(t('com.label.부서명', '부서명')),
      align: 'left',
      width: '*',
    },
  ];

  useEffect(() => {
    if (props.initParam != null && props.initParam != '') {
      const value = props.initParam;
      getContInfo(value);
      setPopTitle(props.title != null ? props.title : String(t('com.label.담당자', '담당자')));
    }
  }, [props.initParam]);

  const getContInfo = async (value) => {
    await getContInfoList(value).then((result: any) => {
      if (result != null && result != '') {
        setRowData(result);
      }
    });
  };

  return (
    <>
      <CustomDialog title={popTitle} open={props.open} onClose={props.close} size={'sm'}>
        <CustomGrid
          layoutDefinition={layoutDefinition}
          rowData={rowData}
          height={200}
          isReadOnly={true}
          isFilter={false}
          isSelector={false}
          allowPinning={false}
        />
      </CustomDialog>
    </>
  );
};
