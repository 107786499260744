/** @jsxImportSource @emotion/react  */
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { SearchBox, SearchRows, SearchBoxRow, SearchCols } from 'components/layouts/SearchBox';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import GatingCheckSheetMstGrid from './gatingchecksheet/GatingCheckSheetMstGrid';
import GatingCheckSheetDtlGrid from './gatingchecksheet/GatingCheckSheetDtlGrid';
import { InputBox } from 'components/layouts/SearchBox';
import { ComboBox } from 'components/selects/ComboBox';
import SearchBoxButtons from 'components/buttons/SearchBoxButtons';
import { hasRole } from 'utils/SessionUtil';
export interface ConditionCheckSheetPage {
  useYn?: string;
  gatingTypeCode?: string;
}

/**
 * 게이팅 체크시트 관리
 * @constructor
 */
const GatingCheckSheetPage = () => {
  const { t } = useTranslation();
  const [textboxUseYn, setTextboxUseYn] = useState<string>('');
  const [textboxGatingTypeCode, setTextboxGatingTypeCode] = useState<string>('');
  const [getGatingTypeConditionList, setGatingTypeConditionList] = useState<Code[]>([]);
  const [getUseYnCodeList, setUseYnCodeList] = useState<Code[]>([]);
  const [searchClick, setSearchClick] = useState<boolean>(false);
  const [searchDetail, setSearchDetail] = useState<boolean>(false);
  const [searchVersion, setSearchVersion] = useState<string>('');
  const [errors, setErrors] = useState<any>({});
  const [searchGatingTemplateId, setSearchGatingTemplateId] = useState<string>('');
  const [gridDtlTitle, setGridDtlTitle] = useState<string>('');
  const [isNigsRoleYn, setNigsRoleYn] = useState<boolean>(true);
  const [conditionCheckSheetPage, setConditionCheckSheetPage] = useState<ConditionCheckSheetPage>(
    {}
  );

  useEffect(() => {
    initCondition();
  }, []);
  const initCondition = async () => {
    // 관리자 권한 여부 확인
    setNigsRoleYn(hasRole('NIGS_MANAGER') || hasRole('ADM'));

    const categoryConditions = await getCommonCodeNames('GATING_TYPE_CODE');
    const ipUseYnCode: Code[] = await getCommonCodeNames('YN_FLAG');
    setGatingTypeConditionList(categoryConditions);
    setUseYnCodeList(ipUseYnCode);
  };

  // search button event
  const btnSearch = () => {
    setSearchClick(true);
    setSearchDetail(false);
  };

  //111 333
  const listner = useCallback(
    (
      verionNo: string,
      gatingTemplateId: string,
      btnSearchDetail: boolean,
      gridDtlTitle: string
    ) => {
      setSearchGatingTemplateId(gatingTemplateId);
      setSearchVersion(verionNo);
      setSearchClick(false);
      setGridDtlTitle(_.isEmpty(gridDtlTitle) ? '' : gridDtlTitle);

      if (btnSearchDetail) {
        setSearchDetail(true);
      }
    },
    []
  );

  const listnerDetail = useCallback(
    (verionNo: string, gatingTemplateId: string, btnSearchDetail: boolean) => {
      setSearchDetail(false);
    },
    []
  );

  const handleParamConditionChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setConditionCheckSheetPage({
      ...conditionCheckSheetPage,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: false });
  };

  return (
    <>
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols>
                <label>{t('gtng.label.Gating 유형', 'Gating 유형')}</label>
                <ComboBox
                  options={getGatingTypeConditionList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={textboxGatingTypeCode}
                  disabled={false}
                  onChange={(value) => {
                    setTextboxGatingTypeCode(value);
                  }}
                />
              </SearchCols>
              <SearchCols>
                <label>{t('com.label.사용여부', '사용여부')}</label>
                <ComboBox
                  options={getUseYnCodeList}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={textboxUseYn}
                  disabled={false}
                  onChange={(value) => {
                    setTextboxUseYn(value);
                  }}
                />
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchBoxButtons
            setReset={setConditionCheckSheetPage}
            onReset={() => {
              setTextboxUseYn('');
              setTextboxGatingTypeCode('');
            }}
            onSearch={() => btnSearch()}
          />
        </SearchBoxRow>
      </SearchBox>

      <GatingCheckSheetMstGrid
        sGatingTypeCode={textboxGatingTypeCode}
        sUseYn={textboxUseYn}
        editable={isNigsRoleYn}
        sSearchBtn={searchClick}
        gridTitle={gridDtlTitle}
        callBack={listner}
      />

      <GatingCheckSheetDtlGrid
        sVersionNo={searchVersion}
        sGatingTemplateId={searchGatingTemplateId}
        editable={isNigsRoleYn}
        callBack={listnerDetail}
        sSearchDetailBtn={searchDetail}
        gridDtlTitle={gridDtlTitle}
      />
    </>
  );
};

export default GatingCheckSheetPage;
