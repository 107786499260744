/** @jsxImportSource @emotion/react */
import { useEffect, useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { CommonBG, PrimaryColor, CommonText } from 'ui/theme/Color';
import { Menu, MenuContextType, MenuEnum } from 'models/admin/Menu';
import { FavoriteButton } from 'components/buttons/FavoriteButton';
import { SVGIcon } from 'components/buttons/IconSVG';
import { MenuContext } from 'App';
import useSessionStore from 'stores/useSessionStore';
import { useLocation } from 'react-router';

type PropType = {
  showMenu: boolean;
};

const PageHeaderLayout = ({ showMenu }: PropType) => {
  const [parentMenus, setParentMenus] = useState<Menu[]>([]);
  const [curMenuName, setCurMenuName] = useState<string>('');
  const { menus } = useSessionStore();
  const menuContext = useContext<MenuContextType>(MenuContext);
  const { t } = useTranslation();
  const { state } = useLocation();

  useEffect(() => {
    const menu = menuContext.currentMenu;
    if (!menu) return;
    if (Object.keys({ ...state }).includes('mnuNm') && state.mnuNm.length > 1) {
      setCurMenuName(state.mnuNm);
    } else {
      setCurMenuName('');
    }
    setParentMenus(findParentMenu(menu) ?? []);
  }, [menuContext.currentMenu, curMenuName]);

  const findParentMenu = useCallback(
    (menu: Menu) => {
      const newParentMenus: Menu[] = [];
      if (menu.mnuUrl === '') {
        menuContext.handleMenuChange({ ...menuContext, selectedHeaderMenu: '' });
        setParentMenus(newParentMenus);
        return;
      }
      let cur = menus.find((item) => item.mnuId === menu.upprMnuId) as Menu;
      if (cur) {
        while (cur.mnuId !== '000000') {
          newParentMenus.unshift(cur);
          cur = menus.find((item) => item.mnuId === cur.upprMnuId) as Menu;
          if (!cur) break;
        }
        return newParentMenus;
      }
    },
    [menuContext]
  );

  return (
    <HeaderSection>
      {menuContext.currentMenu && (
        <>
          <h2>
            {/* <FavoriteButton /> */}
            {curMenuName.length > 0
              ? curMenuName
              : t(`${menuContext.currentMenu?.msgCtn}`, `${menuContext.currentMenu?.mnuNm}`)}
          </h2>
          {showMenu && (
            <div className="breadcrumb">
              {parentMenus && parentMenus.map((item) => <a key={item.mnuId}>{item.mnuNm}</a>)}
              <span>{menuContext.currentMenu.mnuNm}</span>
            </div>
          )}
        </>
      )}
    </HeaderSection>
  );
};

export default PageHeaderLayout;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 24px;
  background-color: ${CommonBG.Elevation};
  h2 {
    font-family: 'Spoqa Han Sans Neo';
    display: inline-block;
    padding-left: 16px;
    color: ${CommonText.Basic};
    font-size: 18px;
    font-weight: bold;
    position: relative;
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: ${CommonText.Primary};
    }
  }

  .breadcrumb {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: ${CommonText.Primary};
    a {
      display: inline-block;
      padding: 0 3px;
      margin-right: 12px;
      position: relative;
      &:after {
        content: '';
        clear: both;
        display: inline-block;
        width: 12px;
        height: 12px;
        position: absolute;
        top: calc(50% - 6px);
        right: -12px;
        transform: rotate(270deg);
        background: ${CommonText.Light};
        -webkit-mask: ${SVGIcon.IcoLineChevron};
        mask-image: ${SVGIcon.IcoLineChevron};
      }
    }
    span {
      display: inline-block;
      padding-left: 3px;
      font-weight: bold;
    }
  }
`;
