/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CustomDialog from 'components/modals/common/CustomDialog';
import { IconButton } from 'components/buttons/IconSVG';
import { FileSaveResponse, FileSaveResult } from 'models/admin/FileInfo';
import Dropzone from 'components/dropzone/Dropzone';
import { downloadAllFiles } from 'apis/admin/FileUpload';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { Code } from 'models/common/CommonCode';
import { useLoading } from 'components/process/Loading';
import { BizName, FileUpload as FileConst } from 'models/common/Common';
import { formatBytes } from 'utils/FormatUtil';
import { useMessageBar } from 'hooks/useMessageBar';

interface Props {
  open: boolean;
  close: () => void;
  initParam: {
    atchFileGrId?: string; // 첨부파일 그룹ID
    atchFileTpCd: string; // 첨부파일 유형
    optValCtn1: string; // 관련 테이블 명 (확인용)
    bizName?: BizName; // 파일저장경로 중 업무 폴더명으로 사용 (없는 경우 etc)
  };
  downloadOnly?: boolean; // 다운로드만 사용여부 (default: false)
  singleSelect?: boolean; // 단건 선택여부 (default: false)
  showAllDownload?: boolean; // 전체다운로드 버튼 노출여부 (default: true)
  onCallback: (atchFileGrId: string | null, fileCount: number) => void;
  isRequire?: boolean; // 필수여부체크 (default: false)
}
export const FileUploadPopUp = ({
  open,
  close,
  initParam,
  downloadOnly = false,
  singleSelect = false,
  showAllDownload = true,
  onCallback,
  isRequire = false,
}: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();
  const { openLoading } = useLoading();
  const [atchFileTpCdList, setAtchFileTpCdList] = useState<Code[]>([]);
  const [atchFileGrId, setAtchFileGrId] = useState<string>(initParam?.atchFileGrId || '');
  const [atchFileTpCd, setAtchFileTpCd] = useState<string>(initParam?.atchFileTpCd || '');
  const dropzoneRef = useRef<any>();
  const title = useMemo(() => {
    const tp = atchFileTpCdList.find(
      (o) => o.cmnCd !== 'NORMAL' && o.cmnCd === initParam.atchFileTpCd
    );
    const tpNm = tp ? `${tp.cmnCdNm} ` : '';
    if (downloadOnly) {
      return tpNm + t('com.label.다운로드', `다운로드`);
    }
    return tpNm + t('com.label.업로드', `업로드`); // `${tpNm}파일 업로드`;
  }, [downloadOnly, atchFileTpCdList]);

  useEffect(() => {
    getCommonCodeNames('ATCH_FILE_TP_CD').then((result) => setAtchFileTpCdList(result));
  }, []);
  /*const fnGetAtchFileGrId = async () => {
    // const response = await getAtchFileGrId();
    // if (response) {
    //   setAtchFileGrId(response.atchFileGrId);
    // }
  };*/

  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const handleSaveFile = async () => {
    if (isBtnDisabled) return;
    dropzoneRef.current.setErrorMsg('');
    openLoading(true);
    setBtnDisabled(true);

    const maxSize = FileConst.maxSize;
    const totalFileSize = dropzoneRef.current.getTotalFileSize();

    if (totalFileSize > maxSize) {
      dropzoneRef.current.setErrorMsg(
        t(
          `com.label.checkfilesize`,
          `첨부파일 크기는 ${formatBytes(maxSize)}를 초과할 수 없습니다.`
        )
      );
      openLoading(false);
      setBtnDisabled(false);
      return;
    }

    const fileSaveResponse: FileSaveResponse = await dropzoneRef.current.saveFiles();
    if (fileSaveResponse.fileSaveResult === FileSaveResult.NONE) {
      isRequire &&
        dropzoneRef.current.setErrorMsg(
          t('com.label.첨부파일을 확인해 주세요.', '첨부파일을 확인해 주세요.')
        );
      openLoading(false);
      setBtnDisabled(false);

      if (isRequire) return;
      // return;
    } else if (fileSaveResponse.fileSaveResult === FileSaveResult.SUCCESS) {
      setAtchFileGrId(fileSaveResponse.atchFileGrId);
    }

    await dropzoneRef.current.checkPPTFiles(fileSaveResponse.atchFileGrId, initParam.bizName);

    let msg = '';
    switch (fileSaveResponse.fileSaveResult) {
      case FileSaveResult.SUCCESS:
        msg = t('com.label.저장되었습니다.', '저장되었습니다.');
        break;
      case FileSaveResult.NONE:
        // msg = t(com.label.저장되었습니다.', '저장되었습니다.');
        break;
      default:
        msg = t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.');
        break;
    }

    if (msg) {
      openLoading(false);

      openMessageBar({
        type: FileSaveResult.SUCCESS === fileSaveResponse.fileSaveResult ? 'confirm' : 'error',
        content: msg,
      });

      if (typeof onCallback === 'function') {
        onCallback(fileSaveResponse.atchFileGrId, fileSaveResponse.fileCount);
      }
    } else {
      onCallback(fileSaveResponse.atchFileGrId, fileSaveResponse.fileCount);
    }

    openLoading(false);
    setBtnDisabled(false);
  };

  const handleDownload = async () => {
    if (atchFileGrId) {
      await downloadAllFiles(atchFileGrId || '', atchFileTpCd || '');
    }
  };

  const dialogButtons = [
    <Button
      key={'save'}
      css={IconButton.button}
      className="save"
      onClick={handleSaveFile}
      disableRipple
    >
      {t('com.button.저장', '저장')}
    </Button>,
  ];

  return (
    <CustomDialog
      open={open}
      title={title}
      size={'sm'}
      buttons={downloadOnly ? [] : dialogButtons}
      onCancel={downloadOnly ? undefined : close}
      onClose={close}
    >
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{title}</h3>
        </SubTitleGroup>
        {showAllDownload && (
          <ControlBtnGroup>
            <Button
              css={IconButton.button}
              className="download"
              onClick={handleDownload}
              disableRipple
            >
              {t('com.button.전체 다운로드', '전체 다운로드')}
            </Button>
          </ControlBtnGroup>
        )}
      </SubTitleLayout>
      <Dropzone
        ref={dropzoneRef}
        singleSelect={singleSelect}
        downloadOnly={downloadOnly}
        atchFileGrIdInput={initParam?.atchFileGrId}
        atchFileTpCd={initParam?.atchFileTpCd}
        tableName={initParam?.optValCtn1}
        bizName={initParam?.bizName}
      />
    </CustomDialog>
  );
};
export default FileUploadPopUp;
