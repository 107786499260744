/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovalAppd } from 'models/approves/ReferenceOpinion';
import { SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import CustomGrid from 'components/grids/CustomGrid';

type PropType = {
  approvalAppd: ApprovalAppd[];
  approvalInfr: ApprovalAppd[];
};

const ApproveDetailLineInfo = ({ approvalAppd, approvalInfr }: PropType) => {
  const { t } = useTranslation();
  const [rowDataAppd, setRowDataAppd] = useState<ApprovalAppd[]>(approvalAppd);
  const [rowDataInfr, setRowDataInfr] = useState<ApprovalAppd[]>(approvalInfr);

  useEffect(() => {
    setRowDataAppd(approvalAppd);
    setRowDataInfr(approvalInfr);
  }, [approvalAppd, approvalInfr]);

  const layoutDefinitionAppd = [
    {
      binding: 'grnrAprTpDivsNm',
      header: `${t('com.grid.구분', '구분')}`,
      align: 'center',
      width: 100,
    },
    {
      binding: 'aprvUserNm',
      header: `${t('com.grid.성명', '성명')}`,
      align: 'center',
      width: 100,
    },
    {
      binding: 'aprvUserJikwiNm',
      header: `${t('com.grid.직책/직급호칭', '직책/직급호칭')}`,
      align: 'center',
      width: 150,
    },
    {
      binding: 'aprvUserdeptNm',
      header: `${t('com.grid.부서', '부서')}`,
      width: 200,
      align: 'left',
    },
    {
      binding: 'aprAprvDtm',
      header: `${t('com.grid.결재시간', '결재시간')}`,
      align: 'center',
      width: 180,
    },
    {
      binding: 'aprPsgStatNm',
      header: `${t('com.grid.결재결과', '결재결과')}`,
      align: 'center',
      width: 120,
    },
    {
      binding: 'aprAprvOpinUtf8Ctn',
      header: `${t('com.grid.결재의견', '결재의견')}`,
      align: 'left',
      width: '*',
    },
  ];

  const layoutDefinitionInfr = [
    {
      binding: 'grnrAprTpDivsCd',
      header: `${t('com.grid.구분', '구분')}`,
      align: 'center',
      width: 100,
      cellTemplate: (params) => {
        return `${t('com.label.통보', '통보')}`;
      },
    },
    {
      binding: 'aprvUserNm',
      header: `${t('com.grid.성명', '성명')}`,
      align: 'center',
      width: 200,
    },
    {
      binding: 'aprvUserJikwiNm',
      header: `${t('com.grid.직책/직급호칭', '직책/직급호칭')}`,
      align: 'center',
      width: 200,
    },
    {
      binding: 'aprvUserdeptNm',
      header: `${t('com.grid.부서', '부서')}`,
      width: '*',
      align: 'left',
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.승인자 정보', '승인자 정보')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinitionAppd}
        rowData={rowDataAppd}
        height={250}
        isReadOnly={true}
        isFilter={false}
        isSelector={false}
        allowPinning={false}
      />
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.통보자 정보', '통보자 정보')}</h3>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinitionInfr}
        rowData={rowDataInfr}
        height={250}
        isReadOnly={true}
        isFilter={false}
        isSelector={false}
        allowPinning={false}
      />
    </>
  );
};
export default ApproveDetailLineInfo;
