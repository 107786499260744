import styled from '@emotion/styled';
import { css } from '@mui/material';
import {
  GrayColor,
  CommonBG,
  StatusErrorColor,
  StatusWarningMajorColor,
  StatusConfirmColor,
  InputBG,
  CommonText,
  CommonBorder,
  TableBG,
  InputBorder,
} from 'ui/theme/Color';
import { SVGIcon } from 'components/buttons/IconSVG';

export const AllSelectWrap = styled.div`
  position: relative;
  &.multiSelect {
    overflow: hidden;
  }
  span {
    display: none;
    padding-top: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    max-width: 100%;
  }
  &.w450 {
    max-width: 450px !important;
  }
  &.error {
    & > div {
      border: 1px solid ${StatusErrorColor.StatusError300};
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      right: 21px;
      z-index: 1;
      background: ${StatusErrorColor.StatusError500};
      -webkit-mask: ${SVGIcon.IcoError};
      mask-image: ${SVGIcon.IcoError};
    }
    span {
      display: inline-block;
      color: ${StatusErrorColor.StatusError500};
    }
  }
  &.warning {
    & > div {
      border: 1px solid ${StatusWarningMajorColor.StatusWarningMinor500};
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      right: 21px;
      z-index: 999;
      background: ${StatusWarningMajorColor.StatusWarningMinor500};
      -webkit-mask: ${SVGIcon.IcoWarning};
      mask-image: ${SVGIcon.IcoWarning};
    }
    span {
      display: inline-block;
      color: ${StatusWarningMajorColor.StatusWarningMinor500};
    }
  }
  &.comfirm {
    & > div {
      border: 1px solid ${StatusConfirmColor.StatusConfirm300};
    }
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 8px;
      right: 21px;
      z-index: 999;
      background: ${StatusConfirmColor.StatusConfirm500};
      -webkit-mask: ${SVGIcon.IcoCofirm};
      mask-image: ${SVGIcon.IcoCofirm};
    }
    span {
      display: inline-block;
      color: ${StatusConfirmColor.StatusConfirm500};
    }
  }
  .MuiFormControl-root {
    background-color: ${CommonBG.Basic};
    label {
      padding-top: 0;
      width: auto !important;
      color: ${CommonText.Lighter};
      font-weight: 400;
    }
  }
`;

export const SelectGroup = styled.div`
  display: inline-flex;
  align-items: center;
  .tilde {
    display: block;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    width: 24px !important;
  }
  & > div:first-child {
    width: calc((100% - 24px) / 2) !important;
  }
  & > div:last-child {
    width: calc((100% - 24px) / 2) !important;
  }
`;

export const SelectBox = styled.div`
  height: 32px;
  border-radius: 2px;
  background: ${CommonBG.Elevation};
  border: 1px solid ${CommonBorder.Strong};
  vertical-align: top !important;
  position: relative;
  display: flex;
  align-items: flex-start;
  &:before {
    content: '';
    clear: both;
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    right: 11px;
    transform: translateY(-50%);
    background: ${CommonText.Light};
    -webkit-mask: ${SVGIcon.IcoSelect};
    mask-image: ${SVGIcon.IcoSelect};
  }
  &:focus,
  &:active {
    border: 1px solid ${InputBorder.Focus};
  }
  &:active,
  &.active {
    ul {
      display: block;
    }
    &:before {
      transform: translateY(-50%) rotate(180deg);
      transition: all ease-out 0.2s;
    }
  }
  &.small {
    height: 28px;
    width: 60px;
    &:before {
      right: 8px;
    }
    input {
      height: 26px;
      padding: 0px 10px;
    }
    ul {
      top: 30px;
    }
  }
  &.language {
    display: inline-block;
    border: none !important;
    background: none !important;
    width: 72px;
    input {
      background: none !important;
    }
    ul {
      left: initial !important;
      right: -1px !important;
      width: 72px;
      li {
        min-height: 32px;
        padding-top: 6px;
        margin-right: 24px;
      }
    }
  }
  &.board {
    border: none !important;
    width: 100px;
    height: 26px !important;
    input {
      height: 26px !important;
    }
    ul {
      top: 28px !important;
    }
  }
  &.translation {
    border: none !important;
    width: 90px;
    height: 30px !important;
    input {
      height: 30px !important;
    }
    ul {
      top: 32px !important;
    }
  }
  &.time {
    display: inline-block;
    input {
      border: none !important;
    }
    ul {
      min-width: 180px;
      left: initial !important;
      right: -1px !important;
      width: fit-content !important;
      li {
        min-height: 32px;
        padding-top: 6px;
        text-overflow: initial;
        white-space: nowrap;
        word-break: keep-all;
        margin-right: 28px;
      }
    }
  }
`;

export const list = {
  ul: (active: boolean) => css`
    display: ${active ? 'block' : 'none'};
    position: absolute;
    top: 34px;
    left: -1px;
    right: -1px;
    z-index: 999;
    border: 1px solid ${CommonBorder.Strong};
    background: ${CommonBG.Elevation};
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.11);
    max-height: 182px;
    overflow-y: overlay;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(60, 62, 61, 0.4);
      width: 6px;
      border-radius: 3px;
    }
  `,
  li: (active: boolean) => css`
    width: 100% !important;
    height: 32px;
    padding-left: 6px;
    padding-top: 4px;
    font-size: 13px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 8px;
    }
    &:hover {
      cursor: pointer;
      background: ${TableBG.CellSelected};
    }
    ${active &&
    `
    background: ${TableBG.CellSelected};
    &:before {
      content: '';
      clear: both;
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 8px;
      right: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.5497 4.25058C14.7059 4.40679 14.7059 4.66006 14.5497 4.81627L7.42435 11.9416C6.87769 12.4883 5.99139 12.4883 5.44463 11.9418L1.4507 7.94924C1.29447 7.79305 1.29442 7.53979 1.4506 7.38355C1.60679 7.22731 1.86005 7.22727 2.01629 7.38345L6.01021 11.376C6.24454 11.6102 6.62438 11.6102 6.85867 11.3759L13.984 4.25058C14.1402 4.09437 14.3935 4.09437 14.5497 4.25058Z' fill='%23554596'/%3E%3C/svg%3E%0A");
    }
    `}
  `,
};

export const Selected = styled.input`
  cursor: pointer;
  border: none !important;
  pointer-events: none !important;
  height: 30px;
  padding: 0 24px 0 10px !important;
  outline: none !important;
  font-size: 13px;
  line-height: initial;
  font-weight: 400;
  min-width: 0px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  color: ${CommonText.Basic};
  &::-webkit-input-placeholder,
  &::-ms-input-placeholder {
    color: ${CommonText.Lighter};
    font-size: 13px;
    font-weight: 400;
  }
  &:read-only {
    background: ${InputBG.Disabled};
    pointer-events: none !important;
    color: ${CommonText.Basic};
  }
  &:disabled {
    background: ${InputBG.Disabled};
    pointer-events: none !important;
    color: ${CommonText.Lighter};
    & + ul {
      display: none !important;
    }
  }
  &.table {
    background: ${InputBG.Disabled};
    pointer-events: none !important;
    color: ${CommonText.Basic};
    & + ul {
      display: none !important;
    }
  }
`;

export const sel = {
  select: css`
    .MuiSelect-root {
      border: 0;
    }

    .MuiSelect-select {
      height: 32px;
      border-radius: 0;
      padding: 4px 10px 2px;
      border-radius: 3px;
      border: solid 1px #b9bcbb;
      font-size: 13px;
      color: #1f1f1f;
      background-color: ${CommonBG.Elevation};
      box-sizing: border-box;

      &:focus {
        border-radius: 3px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
    }
  `,
};

export const multi = {
  select: css`
    width: 100% !important;
    .MuiFormLabel-root.MuiInputLabel-root.MuiFormLabel-root.MuiInputLabel-outlined {
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-size: 13px;
      color: ${CommonText.Lighter};
      font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', sans-serif;
      /* &[data-shrink="true"] {
      display: none;
      } */
    }
    .MuiOutlinedInput-root.MuiInputBase-colorPrimary {
      width: 100% !important;
      height: 32px;
      border: 1px solid ${CommonBorder.Strong};
      border-radius: 2px !important;
      font-size: 13px;
      font-weight: 400 !important;
      color: ${CommonText.Basic};
      font-family: 'Malgun Gothic', 'Segoe UI', 'MS YaHei', sans-serif;
      position: relative;
      &:focus,
      &:active {
        border: 1px solid ${InputBorder.Focus};
      }
      &:before {
        content: '';
        clear: both;
        display: inline-block;
        width: 10px;
        height: 10px;
        position: absolute;
        border: 2px solid red;
        z-index: 99;
        top: 50%;
        right: 11px;
        transform: translateY(-50%);
        background: ${CommonText.Light};
        -webkit-mask: ${SVGIcon.IcoSelect};
        mask-image: ${SVGIcon.IcoSelect};
      }
      &.Mui-focused {
        &:before {
          transform: translateY(-50%) rotate(180deg) !important;
        }
      }
    }
    .MuiSelect-select.MuiSelect-outlined {
      border: none;
      border-radius: 0px !important;
      height: 32px;
      padding: 8px !important;
      box-sizing: border-box;
    }
    .MuiOutlinedInput-notchedOutline {
      display: none !important;
      border: none !important;
    }
    .MuiSvgIcon-root {
      display: none;
    }
  `,
};

/** TODO 기존 소스에서 사용중으로 삭제안함 (확인필요) */
export const CustomSelect = styled.select`
  width: 100%;
  min-width: 80px;
  height: 24px;
  padding-left: 6px;
  border-radius: 3px;
  border: solid 1px ${GrayColor.Gray400};
  background-color: #fff;
  font-size: 13px;
  color: ${GrayColor.Gray700};

  &.widthAuto {
    width: auto;
    min-width: 50px;
  }

  & + &,
  input + & {
    margin-left: 5px;
  }

  &:focus {
    outline: 0;
  }

  &.width280 {
    min-width: 280px;
    width: 280px;
  }

  &.width30p {
    min-width: 30%;
    width: 30%;
  }

  & + .unit {
    margin: 0 15px 0 5px;
  }

  label + & {
    margin-left: 10px;
  }

  & + label {
    margin-left: 20px;
  }

  &.marginL0 {
    margin-left: 0;
  }

  & + .inline-flex {
    margin: 0 5px;
  }
`;
