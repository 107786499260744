/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { CloseBtn } from 'components/buttons/CustomButton';
import { st } from 'components/inputs/CustomInput';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button, Checkbox } from '@mui/material';
import { ContentSection } from 'components/layouts/ContentSection';
import { di, PopupBtnGroup, TitleWrap } from 'components/layouts/Dialog';
import { useTranslation } from 'react-i18next';
import { CommonUtil } from 'utils/CommonUtil';

interface NoticeModalProps {
  bbmNo: string;
  bbmTitNm: string;
  bbmCtn: string;
  poupEpsNuseDdn: number;
}

export const NoticeModal = ({ bbmNo, bbmTitNm, bbmCtn, poupEpsNuseDdn }: NoticeModalProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(false);
  const [appCookies, setAppCookies] = useCookies();

  const decodeHTML = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = CommonUtil.safeHtmlContents(html);
    return txt.value;
  };

  const handleClose = () => {
    if (checked) {
      closeModalUntilExpires();
    }
    setOpen(false);
  };

  const getExpiredDate = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  const closeModalUntilExpires = () => {
    if (!appCookies) return;

    const expires = getExpiredDate(poupEpsNuseDdn);
    setAppCookies('NOTICE_EXPIRES_' + bbmNo, true, { path: '/', expires });
  };

  useEffect(() => {
    if (appCookies['NOTICE_EXPIRES_' + bbmNo]) {
      setOpen(false);
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} css={di.dialog} fullWidth={false} className="md">
      <TitleWrap>
        <h2>{bbmTitNm}</h2>
        <CloseBtn onClick={handleClose} />
      </TitleWrap>
      <DialogContent className="popupContent">
        <ContentSection className="marginT0">
          <div
            dangerouslySetInnerHTML={{ __html: decodeHTML(bbmCtn) }}
            style={{ border: '1px solid #dddddd', padding: '15px' }}
          ></div>
        </ContentSection>
      </DialogContent>

      <PopupBtnGroup>
        <Checkbox
          id={`check_${bbmNo}`}
          onChange={() => setChecked(!checked)}
          css={st.checkbox}
          checked={checked}
          value={checked}
        />
        <label htmlFor={`check_${bbmNo}`} style={{ marginRight: '10px', marginLeft: '2px' }}>
          {poupEpsNuseDdn}
          {t('admin.msg.일 동안 보지 않기', '일 동안 보지 않기')}
        </label>
        <Button className="cancel" disableRipple onClick={handleClose}>
          {t('com.button.확인', '확인')}
        </Button>
      </PopupBtnGroup>
    </Dialog>
  );
};
