/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { DataMap } from '@grapecity/wijmo.grid';
import { SaveApprover } from 'models/approves/ReferenceOpinion';
import { ControlBtnGroup, SubTitleGroup, SubTitleLayout } from 'components/layouts/ContentLayout';
import { IconButton } from 'components/buttons/IconSVG';
import CustomGrid from 'components/grids/CustomGrid';
import { Code } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import UserModal from 'components/modals/common/UserModal';
import { Employee } from 'models/admin/Employee';

type PropType = {
  approvalAppd: SaveApprover[];
  approvalInfr: SaveApprover[];
  onApprovalsChange?: (list: SaveApprover[]) => void;
  onNotifiersChange?: (list: SaveApprover[]) => void;
};

const ApprovalLineInfo = (props: PropType) => {
  const { approvalAppd, approvalInfr, onApprovalsChange, onNotifiersChange } = props;
  const { t } = useTranslation();
  const [approverManagementModalOpen0, setApproverManagementModalOpen0] = useState(false);
  const [approverManagementModalOpenC, setApproverManagementModalOpenC] = useState(false);
  const [rowDataAppd, setRowDataAppd] = useState<SaveApprover[]>(approvalAppd);
  const [rowDataInfr, setRowDataInfr] = useState<SaveApprover[]>(approvalInfr);
  const [aprTpDivsList, setAprTpDivsList] = useState<Code[]>();

  useEffect(() => {
    getCommonCodesForGrid();
  }, []);

  useEffect(() => {
    setRowDataAppd(approvalAppd);
    setRowDataInfr(approvalInfr);
  }, [approvalAppd, approvalInfr]);

  const getCommonCodesForGrid = async () => {
    setAprTpDivsList((await getCommonCodeNames('APR_TP_DIVS_CD')) ?? []);
  };

  const layoutDefinitionAppd = useMemo(
    () => [
      {
        binding: 'grnrAprTpDivsCd',
        header: `${t('com.grid.구분', '구분')}`,
        align: 'center',
        width: 100,
        dataMap: new DataMap(aprTpDivsList || [], 'cmnCd', 'cmnCdNm'),
      },
      {
        binding: 'empNm',
        header: `${t('com.grid.성명', '성명')}`,
        align: 'center',
        width: 100,
      },
      {
        binding: 'jtiNm',
        header: `${t('com.grid.직책/직급호칭', '직책/직급호칭')}`,
        align: 'center',
        width: 150,
      },
      {
        binding: 'deptNm',
        header: `${t('com.grid.부서', '부서')}`,
        width: 200,
        align: 'left',
      },
      {
        header: `${t('com.grid.결재시간', '결재시간')}`,
        align: 'center',
        width: 180,
      },
      {
        header: `${t('com.grid.결재결과', '결재결과')}`,
        align: 'center',
        width: 120,
      },
      {
        header: `${t('com.grid.결재의견', '결재의견')}`,
        align: 'left',
        width: '*',
      },
    ],
    [aprTpDivsList]
  );

  const layoutDefinitionInfr = [
    {
      binding: 'status',
      header: `${t('com.grid.구분', '구분')}`,
      align: 'center',
      width: 100,
      cellTemplate: (params) => {
        return `${t('com.label.통보', '통보')}`;
      },
    },
    {
      binding: 'empNm',
      header: `${t('com.grid.성명', '성명')}`,
      align: 'center',
      width: 200,
    },
    {
      binding: 'jtiNm',
      header: `${t('com.grid.직책/직급호칭', '직책/직급호칭')}`,
      align: 'center',
      width: 200,
    },
    {
      binding: 'deptNm',
      header: `${t('com.grid.부서', '부서')}`,
      width: '*',
      align: 'left',
    },
  ];

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.승인자 정보', '승인자 정보')}</h3>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="check"
            onClick={() => setApproverManagementModalOpen0(true)}
          >
            {t('com.button.결재자 지정', '결재자 지정')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinitionAppd}
        rowData={rowDataAppd}
        height={250}
        isReadOnly={true}
        isFilter={false}
        isSelector={false}
        allowPinning={false}
      />
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('com.label.통보자 정보', '통보자 정보')}</h3>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button
            css={IconButton.button}
            className="check"
            onClick={() => setApproverManagementModalOpenC(true)}
          >
            {t('com.button.통보자 지정', '통보자 지정')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinitionInfr}
        rowData={rowDataInfr}
        height={250}
        isReadOnly={true}
        isFilter={false}
        isSelector={false}
        allowPinning={false}
      />

      {approverManagementModalOpen0 && (
        <UserModal
          open={approverManagementModalOpen0}
          close={() => setApproverManagementModalOpen0(false)}
          title={t('com.label.결재자 지정', '결재자 지정')}
          defaultUserId={(rowDataAppd || [])
            .map((o) => o.userId)
            .filter((o) => !!o)
            .join(',')}
          singleSelect={false}
          sortable={true}
          onCallback={(result) => {
            // 'C' : 통보
            const saveApprover = ((result || []) as Employee[]).map((o) => {
              return {
                ...o,
                status: '0', // 결재
                aprAprvUserId: o.userId,
                grnrAprTpDivsCd: '0', // 결재
              } as SaveApprover;
            });
            setRowDataAppd(saveApprover);
            onApprovalsChange?.(saveApprover);
          }}
        />
      )}
      {approverManagementModalOpenC && (
        <UserModal
          open={approverManagementModalOpenC}
          close={() => setApproverManagementModalOpenC(false)}
          title={t('com.label.통보자 지정', '통보자 지정')}
          defaultUserId={(rowDataInfr || [])
            .map((o) => o.userId)
            .filter((o) => !!o)
            .join(',')}
          singleSelect={false}
          sortable={true}
          onCallback={(result) => {
            const saveApprover = ((result || []) as Employee[]).map((o) => {
              return {
                ...o,
                status: 'C', // 통보
                aprAprvUserId: o.userId,
                grnrAprTpDivsCd: 'C', // 통보
              } as SaveApprover;
            });
            setRowDataInfr(saveApprover);
            onNotifiersChange?.(saveApprover);
          }}
        />
      )}

      {/*
      // TODO 테스트 완료 후 삭제예정
      <ApproverManagementModal
        open={approverManagementModalOpen0}
        approverData={rowDataAppd}
        close={() => setApproverManagementModalOpen0(false)}
        callback={(approver) => {
          setRowDataAppd(approver);
          onApprovalsChange?.(approver);
          setApproverManagementModalOpen0(false);
        }}
        aprBtnParam={{
          P0: true, //결재
          P6: true, //합의
        }}
        title={t('com.label.결재자 지정', '결재자 지정')}
      />
      <ApproverManagementModal
        open={approverManagementModalOpenC}
        approverData={rowDataInfr}
        close={() => setApproverManagementModalOpenC(false)}
        callback={(approver) => {
          setRowDataInfr(approver);
          onNotifiersChange?.(approver);
          setApproverManagementModalOpenC(false);
        }}
        aprBtnParam={{
          PC: true, //통보
        }}
        title={t('com.label.통보자 지정', '통보자 지정')}
      />
      */}
    </>
  );
};

export default React.memo(ApprovalLineInfo);
