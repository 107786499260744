/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { useState, useEffect } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SearchCols } from 'components/layouts/SearchBox';
import { DatePickerWrap } from 'components/inputs/DatePicker';

// 프로퍼티 타입 지정
SearchDateRangePicker.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,

  isRefreah: PropTypes.bool,
  onChange: PropTypes.any,
};

export default function SearchDateRangePicker(props) {
  const { t } = useTranslation();

  const labelId = 'common.label.' + props.name;
  const labelText = '' + props.name;

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    setValue({
      startDate: null,
      endDate: null,
    });
    const result = {
      id: props.id,
      value: '',
    };
    props.onChange(result);
  }, [props.isRefreah]);

  const handleOnChange = (newValue) => {
    setValue(newValue);
    const result = {
      id: props.id,
      value: newValue,
    };
    props.onChange(result);
  };

  return (
    <>
      <label>{t(labelId, labelText)}</label>
      <DatePickerWrap>
        <Datepicker
          inputClassName="datepickerInput"
          readOnly={true}
          value={value}
          onChange={handleOnChange}
          i18n={'ko'}
        />
      </DatePickerWrap>
    </>
  );
}
