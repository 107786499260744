import styled from '@emotion/styled';
import { BorderColor, FontColor, BgColor } from 'ui/theme/Color';

export const TreeBox = styled.div`
  max-height: 650px;
  min-height: 300px;
  padding: 10px 0;
  border: 1px solid ${BorderColor.Primary};
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Tree = styled.div`
  margin-top: 5px;
  label {
    display: inline-block;
    position: relative;
    padding: 4px 0 6px;
    cursor: pointer;
    color: ${FontColor.Gray400};
    width: 100%;
    height: 30px;
    font-size: 13px;

    &.checked {
      background-color: ${BgColor.Secondary50};
    }

    &.root {
      color: ${FontColor.Default};
    }
    &.root:before {
      background-image: url(/assets/icon/ic-tree-close.png);
    }

    &:hover,
    &:focus,
    &.on {
      background-color: ${BgColor.Secondary50};

      :after {
        content: '';
        display: inline-block;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${BgColor.Secondary100};
      }
    }

    &:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: -6px;
      background: url(/assets/icon/ic-tree-close.png) no-repeat center;
    }

    &.lastTree:before {
      background-image: none;
    }
  }

  li {
    position: relative;
    list-style: none;

    &:before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 10px;
    }

    &.lastList {
      :before {
        display: none;
      }
    }

    &.hasCheckbox > label:before {
      margin-right: 28px;
    }

    .MuiCheckbox-root {
      position: absolute;
      left: 26px;
      top: 8px;
      z-index: 2;
      input {
        display: block;
      }
      .Mui-checked {
        color: #3ec2cf;
      }
    }
  }

  ul {
    display: none;
  }

  *:before {
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  input[type='checkbox'] {
    display: none;
    &:checked ~ ul {
      display: block;
    }
    &:checked ~ ul > li {
      label {
        padding-left: 20px;
      }
    }

    &:checked ~ ul > li ul > li {
      label {
        padding-left: 40px;
      }
    }

    &:checked ~ ul > li ul > li ul > li {
      label {
        padding-left: 60px;
      }
    }

    &:checked + label:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }

    &:checked + label.lastTree:before {
      background-image: none;
    }

    &:checked + label.root:before {
      background-image: url(/assets/icon/ic-tree-open.png);
    }
  }

  [datatype='N'] {
    color: ${FontColor.Gray200} !important;
  }
`;
