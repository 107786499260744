/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo, useCallback, memo } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {
  ColDef,
  ValueFormatterParams,
  ISelectCellEditorParams,
  EditableCallbackParams,
  GridReadyEvent,
  NewValueParams,
  ICellRendererParams,
  CellValueChangedEvent,
} from 'ag-grid-community';
import { CommonYN } from 'models/common/Common';
import SearchIcon from '@mui/icons-material/Search';
import { ContentGrid } from 'components/layouts/ContentGrid';
import { getApprovalRuleDetails, setApprovalRuleDetails } from 'apis/admin/ApprovalRule';
import { ApprovalRuleDetail } from 'models/admin/Approval';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import { CrudCode } from 'models/common/Edit';
import { CustomTag } from 'components/buttons/CustomTag';
import EmployeeModal from 'components/modals/common/EmployeeModal';
import { Employee } from 'models/admin/Employee';
import { CustomInputText } from 'components/inputs/CustomInput';
import { Code, commonYNList } from 'models/common/CommonCode';
import { getCommonCodeNames } from 'apis/admin/CommonCode';
import { CustomSelect } from 'components/selects/CustomSelect';
import { Department } from 'models/admin/Department';
import DepartmentModal from 'components/modals/common/DepartmentModal';
import {
  ControlBtnGroup,
  GlobalBtnGroup,
  SubTitleGroup,
  SubTitleLayout,
} from 'components/layouts/ContentLayout';
import { Button } from '@mui/material';
import { IconButton } from 'components/buttons/IconSVG';
import { GridStatusCellTemplate } from 'components/grids/GridStatusCellRenderer';
import { DataMap, CellType } from '@grapecity/wijmo.grid';
import CustomGrid from 'components/grids/CustomGrid';
import useEvent from 'react-use-event-hook';
import { toggleClass } from '@grapecity/wijmo';

interface SearchParamData {
  sRuleId: string;
  callBackFunc?: () => void;
}

const ApprovalLineGrid = (props: SearchParamData) => {
  const { t } = useTranslation();
  const { openCommonModal } = useCommonModal();

  const gridRef = useRef<AgGridReact<ApprovalRuleDetail>>(null);
  const [rowData, setRowData] = useState<ApprovalRuleDetail[]>([]);

  const [rowIndex, setRowIndex] = useState<string>('');
  const [employeeModalOpen, setEmployeeModalOpen] = useState<boolean>(false);
  const [departmentModalOpen, setDepartmentModalOpen] = useState<boolean>(false);
  const [aprTpDivsList, setAprTpDivsList] = useState<Code[]>([]);
  const [aprLnRoleList, setAprLnRoleList] = useState<Code[]>([]);

  const [flexRef, setFlexRef] = useState<any>();
  const defaultColDef: ColDef = {
    sortable: false,
    filter: false,
  };

  // const onInitialized = (grid) => {
  //   setFlexRef(grid);

  //   grid.itemFormatter = (panel, r, c, cell) => {
  //     if (CellType.Cell === panel.cellType) {
  //       grid.rows.forEach((row) => {
  //         if (row._data.aprTpDivsCd !== '6') {
  //           grid.columns.forEach((col) => {
  //             if (col.binding === 'prlYn' && r === row.dataIndex && c === col._idx) {
  //               toggleClass(cell, 'WijmoSelect', true);
  //               cell.ariaReadOnly = true;
  //               if (cell.firstElementChild instanceof HTMLButtonElement) {
  //                 cell.firstChild.remove();
  //               }
  //             }
  //           });
  //         }

  //         if (row._data.aprTpDivsCd === '9') {
  //           grid.columns.forEach((col) => {
  //             if (
  //               (col.binding === 'aprLnRoleCd' ||
  //                 col.binding === 'userInfo' ||
  //                 col.binding === 'deptNm') &&
  //               r === row.dataIndex &&
  //               c === col._idx
  //             ) {
  //               toggleClass(cell, 'WijmoSelect', true);
  //               cell.ariaReadOnly = true;
  //               if (cell.firstElementChild instanceof HTMLButtonElement) {
  //                 cell.firstChild.remove();
  //               }
  //             }
  //           });
  //         }
  //       });
  //     }
  //   };

  //   grid.hostElement.addEventListener('click', (e) => {
  //     if (grid.rows.length == 0) return;

  //     const ht = grid.hitTest(e);
  //     if (ht.row < 0 || ht.col < 0) return;

  //     const item = grid.rows[ht.row].dataItem;
  //     console.log('item', item);
  //     if (e.target instanceof HTMLButtonElement) {
  //       switch (e.target.id) {
  //         // 상세보기
  //         case 'btnUserInfo':
  //           setEmployeeModalOpen(true);
  //           break;
  //         case 'btnDeptNm':
  //           setDepartmentModalOpen(true);
  //           break;
  //       }
  //     }
  //   });
  // };

  // const aprLnRoleDataMap = new DataMap(aprLnRoleList, 'cmnCd', 'cmnCdNm');
  // const aprTpDivsDataMap = new DataMap(aprTpDivsList, 'cmnCd', 'cmnCdNm');
  // const useYn = new DataMap(commonYNList, 'cmnCd', 'cmnCdNm');

  // const layoutDefinition = [
  //   {
  //     binding: 'no',
  //     header: String(t('com.label.NO', 'NO')),
  //     width: 40,
  //     isReadOnly: true,
  //     cellTemplate: (grid) => grid.row._idx + 1,
  //   },
  //   {
  //     header: String(t('com.label.상태', '상태')),
  //     width: 50,
  //     binding: 'crudKey',
  //     isReadOnly: true,
  //     align: 'center',
  //     cellTemplate: GridStatusCellTemplate,
  //   },
  //   {
  //     binding: 'aprLnId',
  //     header: String(t('admin.grid.라인ID', '라인ID')),
  //     width: 70,
  //     isReadOnly: true,
  //   },
  //   {
  //     binding: 'aprLnSnb',
  //     header: String(t('admin.grid.차수', '차수')),
  //     width: 70,
  //     isReadOnly: true,
  //   },
  //   {
  //     binding: 'aprLnSeq',
  //     header: String(t('admin.grid.순번', '순번')),
  //     width: 70,
  //     isReadOnly: true,
  //   },
  //   {
  //     binding: 'aprTpDivsCd',
  //     header: String(t('admin.grid.구분', '구분')),
  //     width: 120,
  //     cssClass: 'WijmoSelect',
  //     dataMap: aprTpDivsDataMap,
  //     cellTemplate: (item) => {
  //       if (aprTpDivsDataMap.getDisplayValue(item.value) == undefined) {
  //         return `<span>${item.text}</span>`;
  //       } else {
  //         return `<span>${aprTpDivsDataMap.getDisplayValue(item.text)}</span>`;
  //       }
  //     },
  //   },
  //   {
  //     binding: 'prlYn',
  //     header: String(t('admin.grid.병렬', '병렬')),
  //     width: 200,
  //     cssClass: 'WijmoSelect',
  //     dataMap: useYn,
  //     cellTemplate: (item) => {
  //       if (useYn.getDisplayValue(item.value) == undefined) {
  //         return `<span>${item.text}</span>`;
  //       } else {
  //         return `<span>${useYn.getDisplayValue(item.value)}</span>`;
  //       }
  //     },
  //   },
  //   {
  //     binding: 'aprLnRoleCd',
  //     header: String(t('admin.grid.대상구분', '대상구분')),
  //     width: 120,
  //     cssClass: 'WijmoSelect',
  //     dataMap: aprLnRoleDataMap,
  //     cellTemplate: (item) => {
  //       if (item.item.aprTpDivsCd !== '9') {
  //         if (aprLnRoleDataMap.getDisplayValue(item.value) == undefined) {
  //           return `<span>${item.text}</span>`;
  //         } else {
  //           return `<span>${aprLnRoleDataMap.getDisplayValue(item.text)}</span>`;
  //         }
  //       } else {
  //         return '';
  //       }
  //     },
  //   },
  //   {
  //     binding: 'userId',
  //     visible: false,
  //   },
  //   {
  //     binding: 'userInfo',
  //     header: String(t('admin.grid.사용자', '사용자')),
  //     width: 250,
  //     cssClass: 'WijmoFind',
  //     cellTemplate: (item) => {
  //       if (item.item.aprLnRoleCd === 'USER') {
  //         if (item.item.userInfo === undefined) {
  //           return `<span>${item.text}</span><button id="btnUserInfo" />`;
  //         } else {
  //           return `<span>${item.item.userInfo}</span><button id="btnUserInfo" />`;
  //         }
  //       } else {
  //         return '';
  //       }
  //     },
  //   },
  //   {
  //     binding: 'deptCd',
  //     visible: false,
  //   },
  //   {
  //     binding: 'deptNm',
  //     header: String(t('admin.grid.부서', '부서')),
  //     width: 120,
  //     cssClass: 'WijmoFind',
  //     cellTemplate: (item) => {
  //       if (item.item.aprLnRoleCd === 'DEPT') {
  //         if (item.item.deptNm === undefined) {
  //           return `<span>${item.text}</span><button id="btnDeptNm" />`;
  //         } else {
  //           return `<span>${item.item.deptNm}</span><button id="btnDeptNm" />`;
  //         }
  //       } else {
  //         return '';
  //       }
  //     },
  //   },
  //   {
  //     binding: 'aprLnChgPsblYn',
  //     header: String(t('admin.grid.결재자 변경가능', '결재자 변경가능')),
  //     width: 120,
  //     cssClass: 'WijmoSelect',
  //     dataMap: useYn,
  //     // cellTemplate: (item) => {
  //     //   if (useYn.getDisplayValue(item.value) == undefined) {
  //     //     return `<span>${item.text}</span>`;
  //     //   } else {
  //     //     return `<span>${useYn.getDisplayValue(item.value)}</span>`;
  //     //   }
  //     // },
  //   },
  //   {
  //     binding: 'aprLnDelPsblYn',
  //     header: String(t('admin.grid.결재라인 삭제가능', '결재라인 삭제가능')),
  //     width: 120,
  //     cssClass: 'WijmoSelect',
  //     dataMap: useYn,
  //     // cellTemplate: (item) => {
  //     //   if (useYn.getDisplayValue(item.value) == undefined) {
  //     //     return `<span>${item.text}</span>`;
  //     //   } else {
  //     //     return `<span>${useYn.getDisplayValue(item.value)}</span>`;
  //     //   }
  //     // },
  //   },
  // ];

  // const beginningEdit = useEvent((s, e) => {
  //   const col = s.columns[e.col];
  //   const row = s.rows[e.row];
  //   if (col.binding === 'prlYn') {
  //     if (row.dataItem.aprTpDivsCd !== '6') {
  //       e.cancel = true;
  //     }
  //   }
  //   if (col.binding === 'userInfo' || col.binding === 'deptNm') {
  //     e.cancel = true;
  //   }
  //   if (row.dataItem.aprTpDivsCd === '9' && col.binding === 'aprLnRoleCd') {
  //     e.cancel = true;
  //   }
  // });

  // const onCellEditEnded = (grid, e) => {
  //   const binding = grid.columns[e.col].binding;
  //   const data = grid.rows[e.row].dataItem;
  //   if (data.aprTpDivsCd === '9') {
  //     const data = grid.rows[e.row].dataItem;
  //     data.aprLnRoleCd = '';
  //     data.userInfo = '';
  //     data.deptNm = '';
  //     grid.refreshCells();
  //   }
  // };

  const columnDefs: ColDef[] = [
    {
      width: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerClass: 'headerCheck',
      cellClass: 'cellCheck',
    },
    {
      headerName: String(t('com.label.상태', '상태')),
      field: 'crudKey',
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellRenderer: function (params: any) {
        if (params.data.crudKey === CrudCode.DELETE) {
          return <CustomTag className="red">{t('com.label.삭제', '삭제')}</CustomTag>;
        } else if (params.data.crudKey === CrudCode.UPDATE) {
          return <CustomTag className="yellow">{t('com.label.변경', '변경')}</CustomTag>;
        } else if (params.data.crudKey === CrudCode.CREATE) {
          return <CustomTag>{t('com.label.추가', '추가')}</CustomTag>;
        } else {
          return;
        }
      },
    },
    {
      headerName: 'No',
      width: 50,
      cellStyle: { textAlign: 'center' },
      valueFormatter: (params: ValueFormatterParams) => {
        params.data.seq = '' + (parseInt(params.node!.id!) + 1);
        return `${parseInt(params.node!.id!) + 1}`;
      },
    },
    {
      headerName: String(t('admin.grid.라인ID', '라인ID')),
      field: 'aprLnId',
      width: 70,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: String(t('admin.grid.차수', '차수')),
      field: 'aprLnSnb',
      width: 70,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: String(t('admin.grid.순번', '순번')),
      field: 'aprLnSeq',
      width: 70,
      cellStyle: { textAlign: 'center' },
    },
    {
      headerName: String(t('admin.grid.구분', '구분')),
      field: 'aprTpDivsCd',
      width: 120,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => (
        <div>
          <CustomSelect
            onChange={(it) => {
              params.setValue(it.target.value);
            }}
            value={params.value}
          >
            {aprTpDivsList?.map((code) => (
              <option value={code.cmnCd} key={code.cmnCd}>
                {code.cmnCdNm}
              </option>
            ))}
          </CustomSelect>
        </div>
      ),
      onCellValueChanged(event: NewValueParams) {
        event.data.prlYn = CommonYN.N;
        event.data.aprLnRoleCd = 'USER';
        if (event.newValue == '9') {
          event.data.aprLnRoleCd = '';
          event.data.userId = '';
          event.data.deptCd = '';
        }

        event.api.redrawRows();
      },
    },
    {
      headerName: String(t('admin.grid.병렬', '병렬')),
      field: 'prlYn',
      width: 80,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        if (params.data.aprTpDivsCd === '6') {
          const useYnCds = [CommonYN.Y, CommonYN.N];
          return (
            <div>
              <CustomSelect
                className="widthAuto"
                onChange={(it) => {
                  params.setValue(it.target.value);
                }}
                value={params.value}
              >
                {useYnCds?.map((code) => (
                  <option value={code} key={code}>
                    {code}
                  </option>
                ))}
              </CustomSelect>
            </div>
          );
        }
        return <div>{params.value}</div>;
      },
    },
    {
      headerName: String(t('admin.grid.대상구분', '대상구분')),
      field: 'aprLnRoleCd',
      width: 120,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => (
        <div>
          {params.data.aprTpDivsCd !== '9' && (
            <CustomSelect
              onChange={(it) => {
                params.setValue(it.target.value);
              }}
              value={params.value}
            >
              {aprLnRoleList?.map((code) => (
                <option value={code.cmnCd} key={code.cmnCd}>
                  {code.cmnCdNm}
                </option>
              ))}
            </CustomSelect>
          )}
        </div>
      ),

      onCellValueChanged(event: NewValueParams) {
        event.data.userId = '';
        event.data.deptCd = '';
        event.api.redrawRows();
      },
    },
    {
      field: 'userId',
      hide: true,
    },
    {
      headerName: String(t('admin.grid.사용자', '사용자')),
      field: 'userInfo',
      width: 250,
      cellClass: 'cellFind',
      cellStyle: { textAlign: 'left' },
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <div>
            {params.data.aprLnRoleCd === 'USER' && (
              <>
                <span>{params.value ?? ''}</span>
                <button
                  onClick={() => {
                    setRowIndex(params.node.rowIndex + '');
                    setEmployeeModalOpen(true);
                  }}
                >
                  {/*  <SearchIcon></SearchIcon> */}
                </button>
              </>
            )}
          </div>
        );
      },
    },
    {
      field: 'deptCd',
      hide: true,
    },
    {
      headerName: String(t('admin.grid.부서', '부서')),
      field: 'deptNm',
      flex: 1,
      resizable: true,
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <div>
            {params.data.aprLnRoleCd === 'DEPT' && (
              <>
                <CustomInputText className="widhButton" value={params.value ?? ''} readOnly />
                <Button
                  onClick={() => {
                    setRowIndex(params.node.rowIndex + '');
                    setDepartmentModalOpen(true);
                  }}
                >
                  <SearchIcon></SearchIcon>
                </Button>
              </>
            )}
          </div>
        );
      },
    },
    {
      headerName: String(t('admin.grid.결재자 변경가능', '결재자 변경가능')),
      field: 'aprLnChgPsblYn',
      width: 120,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        const useYnCds = [CommonYN.Y, CommonYN.N];
        return (
          <div>
            <CustomSelect
              className="widthAuto"
              onChange={(it) => {
                params.setValue(it.target.value);
              }}
              value={params.value}
            >
              {useYnCds?.map((code) => (
                <option value={code} key={code}>
                  {code}
                </option>
              ))}
            </CustomSelect>
          </div>
        );
      },
    },
    {
      headerName: String(t('admin.grid.결재라인 삭제가능', '결재라인 삭제가능')),
      field: 'aprLnDelPsblYn',
      width: 120,
      cellStyle: { textAlign: 'center' },
      cellRenderer: (params) => {
        const useYnCds = [CommonYN.Y, CommonYN.N];
        return (
          <div>
            <CustomSelect
              className="widthAuto"
              onChange={(it) => {
                params.setValue(it.target.value);
              }}
              value={params.value}
            >
              {useYnCds?.map((code) => (
                <option value={code} key={code}>
                  {code}
                </option>
              ))}
            </CustomSelect>
          </div>
        );
      },
    },
  ];

  const fnSearchApprovalDetails = useCallback((params: string) => {
    getApprovalRuleDetails(params).then((result: ApprovalRuleDetail[]) => {
      setRowData(result);
    });
  }, []);

  const btnAddRow = useCallback(() => {
    if (!props.sRuleId) {
      openCommonModal({
        content: t('admin.msg.결재규칙을 선택해주세요.', '결재규칙을 선택해주세요.'),
      });
      return;
    }
    if (!props.sRuleId) return false;
    const newRow = {
      aprRuleId: props.sRuleId,
      aprTpDivsCd: '',
      prlYn: 'N',
      aprLnRoleCd: 'USER',
      aprLnChgPsblYn: 'N',
      aprLnDelPsblYn: 'N',
      crudKey: CrudCode.CREATE,
    } as ApprovalRuleDetail;
    setRowData([...rowData, newRow]);
  }, [rowData]);

  const btnDelRow = useCallback(() => {
    const selectedRowNodes = gridRef.current!.api.getSelectedNodes();

    const selectedIds = selectedRowNodes
      .map((rowNode) => {
        return parseInt(rowNode.id!);
      })
      .reverse();

    selectedIds.forEach((item) => {
      if (rowData[item].crudKey === CrudCode.CREATE) {
        delete rowData[item];
      } else {
        rowData[item].crudKey = CrudCode.DELETE;
      }
    });

    const filteredData = rowData.filter((element) => element !== undefined);
    setRowData(filteredData);
  }, [rowData, gridRef]);

  const btnSave = () => {
    openCommonModal({
      modalType: 'confirm',
      content: t('com.label.저장하시겠습니까?', '저장하시겠습니까?'),
      noCallback: () => {
        return false;
      },
    });

    //Validation Start
    const valid = rowData
      .map((rowNode, index) => {
        if (rowNode.aprRuleId != props.sRuleId)
          return `\nLine${index + 1}: ${t(
            'admin.msg.aprRuleId data ERROR!',
            'aprRuleId data ERROR!'
          )}`;
        if (rowNode.aprTpDivsCd == null || rowNode.aprTpDivsCd === '')
          return `\nLine${index + 1}: ${t(
            'admin.msg.Please enter an item:구분값',
            'Please enter an item:구분값'
          )}`;
        if (rowNode.prlYn == null || rowNode.prlYn === '')
          return `\nLine${index + 1}: ${t(
            'admin.msg.Please enter an item:병렬',
            'Please enter an item:병렬'
          )}`;
        if (
          rowNode.aprTpDivsCd != '9' &&
          (rowNode.aprLnRoleCd == null || rowNode.aprLnRoleCd === '')
        )
          return `\nLine${index + 1}: ${t(
            'admin.msg.Please enter an item:대상구분',
            'Please enter an item:대상구분'
          )}`;
        if (rowNode.aprLnRoleCd == 'USER' && (rowNode.userId == null || rowNode.userId === ''))
          return `\nLine${index + 1}: ${t(
            'admin.msg.Please enter an item:사용자',
            'Please enter an item:사용자'
          )}`;
        if (rowNode.aprLnRoleCd == 'TEAM' && (rowNode.deptCd == null || rowNode.deptCd === ''))
          return `\nLine${index + 1}: ${t(
            'admin.msg.Please enter an item:부서',
            'Please enter an item:부서'
          )}`;
        if (rowNode.aprTpDivsCd != '6' && rowNode.prlYn == 'Y')
          return `\nLine${index + 1}: ${t('admin.msg.prlYn data ERROR!', 'prlYn data ERROR!')}`;
      })
      .filter((element) => element !== undefined);
    if (valid.length) {
      openCommonModal({ content: '입력값을 확인해주세요.' + valid.toString() });
      return false;
    }
    //Validation End

    const saveRows = rowData;
    const changedData = saveRows.filter((data) => data.crudKey);
    if (changedData.length === 0) {
      openCommonModal({
        content: t('com.label.변경된 내용이 없습니다.', '변경된 내용이 없습니다.'),
      });
    } else {
      setApprovalRuleDetails(saveRows).then((result) => {
        if (result.successOrNot === 'N') {
          openCommonModal({
            content: t('com.label.저장 중 오류가 발생했습니다.', '저장 중 오류가 발생했습니다.'),
          });
          return false;
        }
        openCommonModal({
          content: t('com.label.저장되었습니다.', '저장되었습니다.'),
        });
        fnSearchApprovalDetails(props.sRuleId);
      });
    }
  };

  useEffect(() => {
    fnSearchApprovalDetails(props.sRuleId);
  }, [props]);

  const getCommonCodes = async () => {
    setAprTpDivsList((await getCommonCodeNames('APR_TP_DIVS_CD')) ?? []);
    setAprLnRoleList((await getCommonCodeNames('APR_LN_ROLE_CD')) ?? []);
  };
  useEffect(() => {
    getCommonCodes();
  }, []);

  const fnSelectUser = (emp: Employee[]) => {
    const selectedRow = gridRef.current?.api.getRowNode(rowIndex);
    selectedRow!.setDataValue('userId', emp[0].userId);
    selectedRow!.setDataValue(
      'userInfo',
      emp[0].empNm + '/' + (emp[0].jtiNm ? emp[0].jtiNm : emp[0].jpsNm) + '/' + emp[0].deptNm
    );

    setEmployeeModalOpen(false);
  };

  const fnSelectDepartment = (department: Department) => {
    const selectedRow = gridRef.current?.api.getRowNode(rowIndex);
    selectedRow!.setDataValue('deptNm', department.deptNm);
    selectedRow!.setDataValue('deptCd', department.deptCd);

    setDepartmentModalOpen(false);
  };
  const onCellValueChanged = useCallback((e: CellValueChangedEvent) => {
    !e.data.crudKey && e.node.setDataValue('crudKey', CrudCode.UPDATE);
  }, []);

  return (
    <>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>{t('admin.label.결재라인', '결재라인')}</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
        <ControlBtnGroup>
          <Button css={IconButton.button} className="plus" disableRipple onClick={btnAddRow}>
            {t('com.button.행추가', '행추가')}
          </Button>
          <Button css={IconButton.button} className="minus" disableRipple onClick={btnDelRow}>
            {t('com.button.행삭제', '행삭제')}
          </Button>
        </ControlBtnGroup>
      </SubTitleLayout>
      {/* <CustomGrid
        rowData={rowData}
        height={300}
        deferResizing={false}
        align={'center'}
        layoutDefinition={layoutDefinition}
        initialized={onInitialized}
        isSelector={false}
        excludeFilter={['detail']}
        excludePin={['detail']}
        beginningEdit={beginningEdit}
        cellEditEnded={onCellEditEnded}
      /> */}
      <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
        <AgGridReact<ApprovalRuleDetail>
          overlayNoRowsTemplate="No rows to show"
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          rowSelection="multiple"
          onCellValueChanged={onCellValueChanged}
        />
      </ContentGrid>
      <GlobalBtnGroup>
        <Button css={IconButton.button} className="save" disableRipple onClick={btnSave}>
          {t('com.button.저장', '저장')}
        </Button>
      </GlobalBtnGroup>
      <EmployeeModal
        open={employeeModalOpen}
        close={() => {
          setEmployeeModalOpen(false);
        }}
        save={(emp: Employee[]) => fnSelectUser(emp)}
        singleSelect={true}
      />
      <DepartmentModal
        open={departmentModalOpen}
        close={() => setDepartmentModalOpen(false)}
        save={(department: Department) => fnSelectDepartment(department)}
      />
    </>
  );
};

export default memo(ApprovalLineGrid);
