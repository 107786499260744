/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomInputWrap, CustomInputText, st } from 'components/inputs/CustomInput';
import { IconButton } from 'components/buttons/IconSVG';
import {
  InputBox,
  SearchBox,
  SearchBoxRow,
  SearchCols,
  SearchButtonWrap,
  SearchRows,
} from 'components/layouts/SearchBox';
import { SubTitleLayout, SubTitleGroup } from 'components/layouts/ContentLayout';
import { Checkbox } from '@mui/material';
import { Selector } from '@grapecity/wijmo.grid.selector';
import CustomDialog from 'components/modals/common/CustomDialog';
import { useMessageBar } from 'hooks/useMessageBar';
import {
  SearchParam,
  UtMatrixDetailList,
  UtMatrixMasterList,
} from 'models/ut/UtMatrixDetailProcess';
import CustomGrid from '../../../components/grids/CustomGrid';
import { ComboBox, MultiComboBox } from '../../../components/selects/ComboBox';
import { Code } from '../../../models/common/CommonCode';
import { getCommonCodeNames } from '../../../apis/admin/CommonCode';
import CustomInputWithSearch from '../../../components/inputs/CustomInputWithSearch';
import { getUtMatrixDetailProcessList } from 'apis/ut/UtMatrixDetailProcess';
import { UtMatrixDetail } from '../../../models/ut/UtMatrix';

type Props = {
  open: boolean;
  close: () => void;
  condition: any;
  onCallback: (result) => void;
};
const UtMatrixDetailProcessPopup = ({ open, close, condition, onCallback }: Props) => {
  const { t } = useTranslation();
  const { openMessageBar } = useMessageBar();

  const [flexRef, setFlexRef] = useState<any>();

  const [hitTest, setHitTest] = useState<any>();
  const [value, setValue] = React.useState(0);
  const [searchParam, setSearchParam] = useState<SearchParam>({
    bildPlntCds: [],
    askPurpCds: [],
    utmWkProgStatCds: [],
    curYn: 'N',
  });

  const [rowData, setRowData] = useState<UtMatrixMasterList[]>([]);
  const [processData, setProcessData] = useState<UtMatrixDetail[]>([]);
  const [bildPlntCode, setBildPlntCode] = useState<Code[]>([]);
  const [askPurpCode, setAskPurpCode] = useState<Code[]>([]);
  const [prdnProcCode, setPrdnProcCode] = useState<Code[]>([]);
  const [dtalProcCode, setDtalProcCode] = useState<Code[]>([]);
  const [checkedItems, setCheckedItems] = useState<UtMatrixDetailList[]>();
  const [isRefresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    initCondition();
  }, []);

  const initCondition = async () => {
    const bildPlntCode = await getCommonCodeNames('BILD_PLNT_CD');
    const askPurpCode = await getCommonCodeNames('ASK_PURP_CD');
    const prdnProcCd = await getCommonCodeNames('PRDN_PROC_CD');
    const dtalProcCd = await getCommonCodeNames('DTAL_PROC_CD');

    setBildPlntCode(bildPlntCode);
    setAskPurpCode(askPurpCode);
    setPrdnProcCode(prdnProcCd);
    setDtalProcCode(dtalProcCd);
  };

  useEffect(() => {
    setSearchParam((prev) => ({
      ...prev,
      targetUtmNo: condition?.utmNo || '',
      bildPlntCds: (condition?.bildPlntCd || '').split(',').map((s) => s.trim()),
      prdnProcCd: condition?.prdnProcCd || '',
      dtalProcCd: condition?.dtalProcCd || '',
    }));
    if (condition?.bildPlntCd && condition?.prdnProcCd && condition?.dtalProcCd) {
      setRefresh(true);
    }
  }, [condition?.bildPlntCd, condition?.prdnProcCd, condition?.dtalProcCd]);

  useEffect(() => {
    if (isRefresh) {
      setRefresh(false);
      btnSearch();
    }
  }, [isRefresh]);

  const onInitialized = (grid) => {
    new Selector(grid);
    setFlexRef(grid);

    grid.hostElement.addEventListener('click', (e) => {
      const len = grid.rows.length;
      if (len == 0) return;
      const ht = grid.hitTest(e);
      grid.startEditing(true);

      if (ht.row < 0 || ht.col < 0) return;
      if (ht.panel.cellType != 1) return; // 선택된 영역이 셀이 아니면 나가리.

      const item = grid.rows[ht.row].dataItem; // 선택한 row의 정보

      // // row 단일 선택 + 체크박스 체크
      grid.rows.forEach((r) => {
        if (r.isSelected === true) {
          r.isSelected = false;
        }
      });
    });
  };

  const layoutDefinition = [
    {
      binding: 'bildPlntCdNm',
      header: String(t('ut.label.공장', '공장')),
      isReadOnly: true,
      width: 150,
      align: 'center',
    },
    {
      binding: 'askPurpCdNm',
      header: String(t('ut.label.목적', '목적')),
      isReadOnly: true,
      width: 100,
      align: 'center',
    },
    {
      binding: 'utmNo',
      header: String(t('ut.label.UT Matrix 번호', 'UT Matrix 번호')),
      width: 180,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'utmNm',
      header: String(t('ut.label.UT Matrix 명', 'UT Matrix 명')),
      width: 340,
      isReadOnly: true,
      align: 'left',
    },
    {
      binding: 'utmWkProgStatCdNm',
      header: String(t('ut.label.상태', '상태')),
      width: 100,
      isReadOnly: true,
      align: 'center',
      cssClass: 'WijmoTag',
      cellTemplate: (item) => {
        if (item.item.utmWkProgStatCd === 'UTM01') {
          return `<span class="grey">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM02') {
          return `<span class="green">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM03') {
          return `<span class="yellow">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM04') {
          return `<span class="blue">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM05') {
          return `<span class="orange">${item.value}</span>`;
        } else if (item.item.utmWkProgStatCd === 'UTM06') {
          return `<span class="red">${item.value}</span>`;
        }
      },
    },
    {
      binding: 'verNo',
      header: String(t('ut.label.버전', '버전')),
      width: 80,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'prdnProcCdNm',
      header: String(t('ut.label.공정', '공정')),
      width: 100,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'dtalProcCdNm',
      header: String(t('ut.label.세부공정', '세부공정')),
      width: 110,
      isReadOnly: true,
    },
    {
      binding: 'dataInsDtm',
      header: String(t('ut.label.요청일자', '요청일자')),
      width: 120,
      isReadOnly: true,
      align: 'center',
    },
    {
      binding: 'planProcId',
      header: String(t('ut.label.계획공정id', '계획공정id')),
      width: 120,
      isReadOnly: true,
      align: 'center',
      visible: false,
    },
  ];

  const handleOnChange = (name, value) => {
    setSearchParam({ ...searchParam, [name]: value });
  };

  const handleReload = () => {
    setSearchParam(
      (prev) =>
        Object.keys(prev).reduce(
          (acc, cur) =>
            Object.assign(acc, { [cur]: ['bildPlntCds', 'askPurpCds'].includes(cur) ? [] : '' }),
          {}
        ) as SearchParam
    );
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      btnSearch();
    }
  };

  const handleSave = () => {
    console.log('checkedItems ====>', checkedItems);

    if (checkedItems && checkedItems.length > 0) {
      if (checkedItems.length > 1) {
        openMessageBar({
          type: 'error',
          content: t(
            'ut.label.복사할 대상을 1건만 선택해 주세요.',
            '복사할 대상을 1건만 선택해 주세요.'
          ),
        });
        return;
      }

      onCallback(checkedItems);
      close();
    } else {
      openMessageBar({
        type: 'error',
        content: t('ut.label.복사할 대상을 선택해 주세요.', '복사할 대상을 선택해 주세요.'),
      });
    }
  };

  const dialogButtons = [
    <Button
      key={'confirm'}
      css={IconButton.button}
      className="confirm"
      onClick={handleSave}
      disableRipple
    >
      {t('com.button.선택', '선택')}
    </Button>,
  ];

  // 검색조건 > 공정 (ComboBox 사용을 위해 변환)
  const optionsProcess = useMemo(() => {
    return (processData || []).reduce((acc, cur) => {
      if (acc.findIndex(({ cmnCd }) => cmnCd === cur.prdnProcCd) < 0) {
        acc.push({
          cmnCd: cur.prdnProcCd,
          cmnCdNm: cur.prdnProcCdNm,
        });
      }
      return acc;
    }, [] as Code[]);
  }, [processData]);

  // 검색조건 > 세부공정 (ComboBox 사용을 위해 변환)
  const optionsDtlProcess = useMemo(() => {
    if (!searchParam?.prdnProcCd) return [];

    return (dtalProcCode || []).reduce((acc, cur) => {
      if (cur.optValCtn1 === searchParam?.prdnProcCd) {
        if (acc.findIndex(({ cmnCd }) => cmnCd === cur.cmnCd) < 0) {
          acc.push({
            cmnCd: cur.cmnCd,
            cmnCdNm: cur.cmnCdNm,
          });
        }
      }
      return acc;
    }, [] as Code[]);
  }, [dtalProcCode, searchParam?.prdnProcCd]);

  const btnSearch = () => {
    getUtMatrixDetailProcessList(searchParam).then((result: UtMatrixMasterList[]) => {
      if (result) {
        setRowData(result);
      } else {
        openMessageBar({
          type: 'error',
          content: t(
            'com.msg.요청 정보 조회 중 오류가 발생했습니다.',
            '요청 정보 조회 중 오류가 발생했습니다.'
          ),
        });
      }
    });
  };

  return (
    <CustomDialog
      title={t('com.label.UT Matrix 세부 공정 선택', 'UT Matrix 세부 공정 선택')}
      size="lg"
      open={open}
      onClose={close}
      onCancel={close}
      buttons={dialogButtons}
    >
      <SearchBox>
        <SearchBoxRow>
          <InputBox>
            <SearchRows>
              <SearchCols className="width25">
                <label>{String(t('ut.label.공장', '공장'))}</label>
                <MultiComboBox
                  id="bildPlntCd"
                  options={bildPlntCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.bildPlntCds || []}
                  onChange={(values: string[]) => {
                    handleOnChange('bildPlntCds', values);
                  }}
                  className="width25"
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>{String(t('ut.label.목적', '목적'))}</label>
                <MultiComboBox
                  id="askPurpCd"
                  options={askPurpCode}
                  placeholder={String(t('com.label.전체', '전체'))}
                  defaultValue={searchParam?.askPurpCds || []}
                  onChange={(values: string[]) => {
                    handleOnChange('askPurpCds', values);
                  }}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>{String(t('ut.label.공정', '공정'))}</label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={prdnProcCode}
                  defaultValue={searchParam?.prdnProcCd}
                  onChange={(value) => {
                    setSearchParam({
                      ...searchParam,
                      dtalProcCd: '',
                      prdnProcCd: value,
                    });
                  }}
                />
              </SearchCols>
              <SearchCols className="width25">
                <label>{String(t('ut.label.세부공정', '세부공정'))}</label>
                <ComboBox
                  placeholder={String(t('com.label.선택', '선택'))}
                  options={optionsDtlProcess}
                  defaultValue={searchParam?.dtalProcCd}
                  onChange={(value) => {
                    setSearchParam({
                      ...searchParam,
                      dtalProcCd: value,
                    });
                  }}
                />
              </SearchCols>
            </SearchRows>
            <SearchRows>
              <SearchCols className="width25">
                <label>{t('ut.label.UT Matrix 번호', 'UT Matrix 번호')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNo"
                  placeholder={String(
                    t('ut.label.UT Matrix 번호를 입력해 주세요.', 'UT Matrix 번호를 입력해 주세요.')
                  )}
                  value={searchParam?.utmNo}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols className="colspan41">
                <label>{t('ut.label.UT Matrix 명', 'UT Matrix 명')}</label>
                <CustomInputWithSearch
                  type="text"
                  name="utmNm"
                  placeholder={String(
                    t('ut.label.UT Matrix 명을 입력해 주세요.', 'UT Matrix 명을 입력해 주세요.')
                  )}
                  value={searchParam?.utmNm}
                  onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                  onKeyDown={onKeyDown}
                />
              </SearchCols>
              <SearchCols className="width25">
                <div style={{ display: 'inline-flex', width: '200px', paddingTop: '5px' }}>
                  <label style={{ paddingTop: '0px' }}>
                    {t('ut.label.이전버전보기', '이전버전보기')}
                  </label>
                  <Checkbox
                    id="showPrevVersion"
                    name="showPrevVersion"
                    css={st.checkbox}
                    className="custom"
                    checked={searchParam?.curYn === 'Y'}
                    onClick={(e) => {
                      setSearchParam({
                        ...searchParam,
                        curYn: searchParam?.curYn === 'Y' ? 'N' : 'Y',
                      });
                    }}
                    disableRipple
                  />
                </div>
              </SearchCols>
            </SearchRows>
          </InputBox>
          <SearchButtonWrap>
            <Button
              css={IconButton.button}
              className="reload"
              onClick={handleReload}
              disableRipple
            ></Button>
            <Button css={IconButton.button} className="find" onClick={btnSearch} disableRipple>
              조회
            </Button>
          </SearchButtonWrap>
        </SearchBoxRow>
      </SearchBox>
      <SubTitleLayout>
        <SubTitleGroup>
          <h3>UT Matrix List</h3>
          <span className="total">
            {t('com.label.총', '총')}
            <span>{rowData?.length ?? 0}</span>
            {t('com.label.건', '건')}
          </span>
        </SubTitleGroup>
      </SubTitleLayout>
      <CustomGrid
        layoutDefinition={layoutDefinition}
        rowData={rowData}
        rowSelection={'single'}
        onChangeCheckedItem={(items) => setCheckedItems(items)}
        alternatingRowStep={0}
        initialized={onInitialized}
        height={400}
      />
    </CustomDialog>
  );
};

export default UtMatrixDetailProcessPopup;
