import {
  ApproveExcludeDetail,
  ApproveExcludeMaster,
  ApproveExcludeRequest,
  ApproveExcludeResponse,
} from 'models/admin/ApproveExclude';
import {
  CommonRequest,
  CommonResponse,
  DmlResponse,
  Method,
  ServiceName,
} from 'models/common/RestApi';
import { callApi } from 'utils/ApiUtil';

export const getAllApproveExcludeList = async (aprExcNm: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/approve/exclude`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      aprExcNm: aprExcNm ?? '',
    }),
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ApproveExcludeMaster[];
};

export const getAllApproveExcludeDetails = async (aprExcTgtId: string) => {
  const request: CommonRequest = {
    method: Method.GET,
    url: `/v1/approve/exclude/detail`,
    serviceName: ServiceName.ELM_BE,
    queryParams: new URLSearchParams({
      aprExcTgtId: aprExcTgtId ?? '',
    }),
  };
  const response: CommonResponse<any> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ApproveExcludeDetail[];
};

export const saveApproveExcludeCud = async (crudRequest: ApproveExcludeRequest) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/approve/exclude`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: crudRequest,
  };

  const response: CommonResponse<ApproveExcludeResponse> = await callApi(request);

  return (response.successOrNot === 'Y' ? response.data : null) as ApproveExcludeResponse;
};

export const deleteApproveExcludeMaster = async (delData) => {
  const request: CommonRequest = {
    method: Method.POST,
    url: `/v1/approve/exclude/delete`,
    serviceName: ServiceName.ELM_BE,
    bodyParams: delData,
    // queryParams: new URLSearchParams({ aprExcTgtId: aprExcTgtId ?? '' }),
  };

  const response: CommonResponse<ApproveExcludeResponse> = await callApi(request);

  return response.successOrNot === 'Y' ? response.data : null;
};
