/** @jsxImportSource @emotion/react */
import { ContentGrid } from 'components/layouts/ContentGrid';
import { useState } from 'react';
import { CrudCode } from 'models/common/Edit';

import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import * as input from '@grapecity/wijmo.input';
import 'ui/css/multiSelect.css';

import { GuideBlock } from 'pages/sample/guide/GuideComponent';

const SampleWijmo = () => {
  const [flexRef, setFlexRef] = useState<any>();
  const [flexItem, setFlexItem] = useState<any>();
  const [hitTest, setHitTest] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [dataSelect, setDataSelect] = useState([
    { id: 1, name: '대한민국', desc: 'KOREA' },
    { id: 2, name: '미국', desc: 'USA' },
    { id: 3, name: '일본', desc: 'JAPAN' },
    { id: 4, name: '중국', desc: 'CHINA' },
    { id: 5, name: '에스토니아', desc: 'ESTONIA' },
  ]);

  const convSingle = dataSelect.map((item) => item.name);

  const [rowData, setRowData] = useState([
    {
      crudKey: CrudCode.READ,
      id: 1,
      desc: '사용자 1',
      name: '박문수',
      address: ['대한민국', '미국'],
      phone: [],
    },
    {
      crudKey: CrudCode.READ,
      id: 2,
      desc: '사용자 2',
      name: '홍길동',
      address: ['미국'],
      phone: [],
    },
    {
      crudKey: CrudCode.READ,
      id: 3,
      desc: '사용자 3',
      name: '일지매',
      address: ['일본'],
      phone: [],
    },
    {
      crudKey: CrudCode.READ,
      id: 4,
      desc: '사용자 4',
      name: '이몽룡',
      address: ['중국'],
      phone: [],
    },
    {
      crudKey: CrudCode.READ,
      id: 5,
      desc: '사용자 5',
      name: '성춘향',
      address: ['에스토니아'],
      phone: [],
    },
  ]);

  const LayoutDefinition = () => {
    return [
      {
        binding: 'id',
        align: 'left',
        width: 50,
        isReadOnly: true,
      },
      {
        header: '개인정보',
        align: 'center',
        columns: [
          {
            binding: 'name',
            header: '이름',
            width: 150,
            isReadOnly: true,
          },
          {
            binding: 'address', //주의: rowData의 필드가 배열형태 이어야 함.
            header: '주소',
            width: 150,
            editor: new input.MultiSelect(document.createElement('div'), {
              placeholder: '선택',
              headerFormat: '{count:n0} items', //Cell에 비해 데이터 내용이 많을 경우....처리.
              itemsSource: convSingle, //Data 배열
              showSelectAllCheckbox: true, //전체 선택 버튼
              //checkedMemberPath: 'checked',
            }),
          },
        ],
      },
      {
        binding: 'desc',
        align: 'center',
        width: 150,
      },
      {
        binding: 'phone',
        align: 'center',
        width: 150,
        editor: new input.MultiSelect(document.createElement('div'), {
          placeholder: '선택',
          headerFormat: '{count:n0} items', //Cell에 비해 데이터 내용이 많을 경우....원하는 형태 처리.
          itemsSource: dataSelect, //배열
          displayMemberPath: 'name', //목록에 표시되는 항목의 속성
          //checkedMemberPath: 'checked',
        }),
      },
    ];
  };

  const state = {
    itemsSource: rowData,
    layoutDefinition: LayoutDefinition(),
  };

  const onFlexGridSelectionChanged = (s, e) => {
    if (s.rows == 0) return;
    const row = s.rows[e.row].dataItem;
    setSelectedRow(row);
  };

  const onInitialized = (grid) => {
    new Selector(grid);
    new FlexGridFilter(grid);
    setFlexRef(grid);

    //셀렉트 방식
    grid.selectionMode = SelectionMode.Row;
  };

  return (
    <div>
      <GuideBlock title={'Import'} isCode={true}>
        {`
import { flexGridTooltip } from 'models/ip/Public';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { SelectionMode } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.grid.filter';
import * as input from '@grapecity/wijmo.input';
import 'ui/css/multiSelect.css';
`}
      </GuideBlock>
      <GuideBlock title={'Layout'} isCode={true}>
        {`
const [flexRef, setFlexRef] = useState<any>();
const [flexItem, setFlexItem] = useState<any>();
const [hitTest, setHitTest] = useState<any>();

//select box용 데이터
const [dataSelect, setDataSelect] = useState([
  { id: 1, name: '대한민국', desc: 'KOREA' },
  { id: 2, name: '미국', desc: 'USA' },
  { id: 3, name: '일본', desc: 'JAPAN' },
  { id: 4, name: '중국', desc: 'CHINA' },
  { id: 5, name: '에스토니아', desc: 'ESTONIA' },
]);

//Value Type Multi Select Data
const convSingle = dataSelect.map((item) => item.name);

//Row Data
const [rowData, setRowData] = useState([
  {
    crudKey: CrudCode.READ,
    id: 1,
    desc: '사용자 1',
    name: '박문수',
    address: ['대한민국', '미국'],
    phone: [],
  },
  {
    crudKey: CrudCode.READ,
    id: 2,
    desc: '사용자 2',
    name: '홍길동',
    address: ['미국'],
    phone: [],
  },
  {
    crudKey: CrudCode.READ,
    id: 3,
    desc: '사용자 3',
    name: '일지매',
    address: ['일본'],
    phone: [],
  },
  {
    crudKey: CrudCode.READ,
    id: 4,
    desc: '사용자 4',
    name: '이몽룡',
    address: ['중국'],
    phone: [],
  },
  {
    crudKey: CrudCode.READ,
    id: 5,
    desc: '사용자 5',
    name: '성춘향',
    address: ['에스토니아'],
    phone: [],
  },
]);

const LayoutDefinition = () => {
  return [
    {
      binding: 'id',
      align: 'left',
      width: 50,
      isReadOnly: true,
    },
    {
      header: '개인정보',
      align: 'center',
      columns: [
        {
          binding: 'name',
          header: '이름',
          width: 150,
          isReadOnly: true,
        },
        {
          binding: 'address', //주의: rowData의 필드가 배열형태 이어야 함.
          header: '주소',
          width: 150,
          editor: new input.MultiSelect(document.createElement('div'), {
            placeholder: '선택',
            headerFormat: '{count:n0} items', //Cell에 비해 데이터 내용이 많을 경우....처리.
            itemsSource: convSingle, //Data 배열
            showSelectAllCheckbox: true, //전체 선택 버튼
            //checkedMemberPath: 'checked',
          }),
        },
      ],
    },
    {
      binding: 'desc',
      align: 'center',
      width: 150,
    },
    {
      binding: 'phone',
      align: 'center',
      width: 150,
      editor: new input.MultiSelect(document.createElement('div'), {
        placeholder: '선택',
        headerFormat: '{count:n0} items', //Cell에 비해 데이터 내용이 많을 경우....원하는 형태 처리.
        itemsSource: dataSelect, //배열
        displayMemberPath: 'name', //목록에 표시되는 항목의 속성
        //checkedMemberPath: 'checked',
      }),
    },
  ];
};

const state = {
  itemsSource: rowData,
  layoutDefinition: LayoutDefinition(),
};

`}
      </GuideBlock>
      <GuideBlock title={'Method'} isCode={true}>
        {`
const onFlexGridSelectionChanged = (s, e) => {
  if (s.rows == 0) return;
  const row = s.rows[e.row].dataItem;
};

const onInitialized = (grid) => {
  new Selector(grid);         //Column헤드에 체크셀렉트 추가,
  new FlexGridFilter(grid);   //그리드 기본 필터 추가,
  setFlexRef(grid); //grid 잠조를 위해

  grid.selectionMode = SelectionMode.Row; //셀렉트 방식 지정
};
`}
      </GuideBlock>
      <GuideBlock title={'Render'} isCode={true}>
        {`
<ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
  <FlexGrid
    columns={state.layoutDefinition}
    itemsSource={state.itemsSource}
    showMarquee={true}
    autoGenerateColumns={false}
    stickyHeaders={true}
    //isReadOnly={true}    //True >> 모든 필드가 읽기전용으로 변함. 주의.
    formatItem={flexGridTooltip}
    selectionChanged={onFlexGridSelectionChanged}
    initialized={onInitialized}
    style={{ height: '300px' }}
/>
</ContentGrid>
`}
      </GuideBlock>

      <GuideBlock title={'Example'} isCode={true}>
        <ContentGrid className="ag-theme-alpine" style={{ height: '300px' }}>
          <FlexGrid
            columns={state.layoutDefinition}
            itemsSource={state.itemsSource}
            showMarquee={true}
            autoGenerateColumns={false}
            stickyHeaders={true}
            //isReadOnly={true}
            formatItem={flexGridTooltip}
            selectionChanged={onFlexGridSelectionChanged}
            initialized={onInitialized}
            style={{ height: '300px' }}
          />
        </ContentGrid>
      </GuideBlock>
      <GuideBlock title={'selected Row Data'} isCode={true}>
        <pre>{JSON.stringify(selectedRow, null, 2)}</pre>
      </GuideBlock>
    </div>
  );
};
export default SampleWijmo;
